var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GmapMap',{staticStyle:{"width":"100%","height":"400px"},attrs:{"center":{lat:Number(this.latitude), lng:Number(this.longitude)},"zoom":_vm.getDefaultZoom(),"options":{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false
        },"map-type-id":"satellite"}}),_c('div',{staticClass:"main"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"icao-flag"},[_c('h2',[_vm._v(_vm._s(_vm.icao_code))]),_c('country-flag',{attrs:{"rounded":true,"country":_vm.getCountryId(_vm.airport_country),"size":"small"}})],1),_c('h1',[_vm._v(_vm._s(_vm.airport_name))]),_c('h4',[_vm._v(_vm._s(_vm.airport_city)+", "+_vm._s(_vm.airport_country)),_c('span',[_vm._v(_vm._s(_vm.latitude)+", "+_vm._s(_vm.longitude))])])]),_c('div',{staticClass:"runways"},_vm._l((_vm.runways),function(runway){return _c('div',{key:runway.ident,staticClass:"runway",on:{"click":function($event){return _vm.selectRunway(runway.ident)}}},[_c('p',{staticClass:"ident"},[_vm._v(_vm._s(runway.ident))]),(_vm.getIls(runway) != null)?_c('div',{staticClass:"ils"},[_vm._v(" "+_vm._s(_vm.getIls(runway) / 1000000)+" ")]):_c('div',{staticClass:"no-ils"},[_vm._v(" No ILS ")]),(_vm.selected_runway === runway.ident)?_c('div',{staticClass:"runway-details"},[_c('Runway',{attrs:{"runway":runway,"airport":_vm.airport},on:{"close-modal":function($event){return _vm.closeSelectedRunway()}}})],1):_vm._e()])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }