/*eslint no-undef: 0*/
/* eslint-disable */

const airports = [{
    "City": "Postville",
    "DBTZ": "A",
    "Name": "Postville Airport",
    "Country": "Canada",
    "IATA/FAA": "YSO",
    "Longitude": "-59.785278",
    "ICAO": "CCD4",
    "Airport ID": "7252",
    "Latitude": "54.910278",
    "Timezone": "223",
    "DST": "-4",
    "destinations": ["5492", "188", "5502"]
}, {
    "City": "Osubi",
    "DBTZ": "U",
    "Name": "Warri Airport",
    "Country": "Nigeria",
    "IATA/FAA": "QRW",
    "Longitude": "5.45",
    "ICAO": "DNSU",
    "Airport ID": "6972",
    "Latitude": "5.31",
    "Timezone": "50",
    "DST": "1",
    "destinations": ["260", "273"]
}, {
    "City": "Rockland",
    "DBTZ": "A",
    "Name": "Knox County Regional Airport",
    "Country": "United States",
    "IATA/FAA": "RKD",
    "Longitude": "-69.0992303",
    "ICAO": "KRKD",
    "Airport ID": "4026",
    "Latitude": "44.0601111",
    "Timezone": "56",
    "DST": "-5",
    "destinations": ["3448"]
}, {
    "City": "Jacksn Hole",
    "DBTZ": "A",
    "Name": "Jackson Hole Airport",
    "Country": "United States",
    "IATA/FAA": "JAC",
    "Longitude": "-110.73775",
    "ICAO": "KJAC",
    "Airport ID": "4027",
    "Latitude": "43.607333333",
    "Timezone": "6451",
    "DST": "-7",
    "destinations": ["3536", "3751"]
}, {
    "City": "Flores",
    "DBTZ": "U",
    "Name": "Mundo Maya International",
    "Country": "Guatemala",
    "IATA/FAA": "FRS",
    "Longitude": "-89.866383",
    "ICAO": "MGTK",
    "Airport ID": "4024",
    "Latitude": "16.913819",
    "Timezone": "427",
    "DST": "-6",
    "destinations": ["1957", "1767"]
}, {
    "City": "Bar Harbor",
    "DBTZ": "A",
    "Name": "Hancock County - Bar Harbor",
    "Country": "United States",
    "IATA/FAA": "BHB",
    "Longitude": "-68.3615653",
    "ICAO": "KBHB",
    "Airport ID": "4025",
    "Latitude": "44.4497689",
    "Timezone": "83",
    "DST": "-5",
    "destinations": ["3448"]
}, {
    "City": "Butte",
    "DBTZ": "A",
    "Name": "Bert Mooney Airport",
    "Country": "United States",
    "IATA/FAA": "BTM",
    "Longitude": "-112.497472",
    "ICAO": "KBTM",
    "Airport ID": "4022",
    "Latitude": "45.954806",
    "Timezone": "5550",
    "DST": "-7",
    "destinations": ["3536"]
}, {
    "City": "Traverse City",
    "DBTZ": "A",
    "Name": "Cherry Capital Airport",
    "Country": "United States",
    "IATA/FAA": "TVC",
    "Longitude": "-85.582235",
    "ICAO": "KTVC",
    "Airport ID": "4023",
    "Latitude": "44.741445",
    "Timezone": "624",
    "DST": "-5",
    "destinations": ["3830", "3645", "3858"]
}, {
    "City": "Bozeman",
    "DBTZ": "A",
    "Name": "Gallatin Field",
    "Country": "United States",
    "IATA/FAA": "BZN",
    "Longitude": "-111.160151",
    "ICAO": "KBZN",
    "Airport ID": "4020",
    "Latitude": "45.777643",
    "Timezone": "4500",
    "DST": "-7",
    "destinations": ["3577", "3858", "3536", "3751", "6505", "3877", "3830"]
}, {
    "City": "Billings",
    "DBTZ": "A",
    "Name": "Billings Logan International Airport",
    "Country": "United States",
    "IATA/FAA": "BIL",
    "Longitude": "-108.537654",
    "ICAO": "KBIL",
    "Airport ID": "4021",
    "Latitude": "45.80921",
    "Timezone": "3652",
    "DST": "-7",
    "destinations": ["7070", "5739", "3604", "7071", "7065", "3720", "3577", "3858", "3536", "6505", "3877", "3751"]
}, {
    "City": "Rockford",
    "DBTZ": "A",
    "Name": "Chicago Rockford International Airport ",
    "Country": "United States",
    "IATA/FAA": "RFD",
    "Longitude": "-89.0972222",
    "ICAO": "KRFD",
    "Airport ID": "4028",
    "Latitude": "42.1953611",
    "Timezone": "742",
    "DST": "-6",
    "destinations": ["6505", "3877", "7056", "3617", "4167"]
}, {
    "City": "Moscow",
    "DBTZ": "N",
    "Name": "Domododevo",
    "Country": "Russia",
    "IATA/FAA": "DME",
    "Longitude": "37.906111",
    "ICAO": "UUDD",
    "Airport ID": "4029",
    "Latitude": "55.408611",
    "Timezone": "588",
    "DST": "4",
    "destinations": ["2990", "6969", "6160", "2966", "2980", "6156", "6149", "6153", "6150", "6485", "2981", "1743", "2960", "2948", "2925", "6092", "4363", "2979", "6147", "2920", "6162", "6125", "6122", "6141", "2961", "6114", "6165", "6166", "7003", "6159", "2987", "1735", "3941", "1460", "1452", "1458", "1472", "1486", "3971", "345", "340", "346", "351", "421", "2954", "507", "7447", "3953", "1555", "3885", "3077", "6111", "6734", "2914", "4367", "7563", "2188", "2179", "2191", "2912", "1613", "6148", "6151", "2983", "6154", "6152", "6146", "1230", "1212", "1218", "1229", "3998", "2958", "2922", "2279", "1606", "2913", "1665", "1678", "1590", "1128", "4317", "2939", "2947", "2241", "6164", "2991", "2170", "4353", "2955", "2965", "2976", "2956", "1191", "2936", "2968", "3964", "1528", "4274", "2935", "2937", "2957", "2952", "4374", "4118", "1197", "1638", "2962", "2972", "6119", "4352", "4364", "6143", "6124", "4078", "2973", "1198", "1203", "2917", "8740", "2964", "2974", "2942", "2963", "2975", "3973", "4111", "4297", "2992", "6086", "2938", "1196", "1246", "2967", "1550", "2923", "302", "3550", "3316", "4166", "348", "1354", "287", "502", "478", "344", "8775", "6089", "8774", "3965", "1546", "3024", "2908", "2926", "1715", "2941", "2940", "8076", "6098", "2930", "3007", "3179", "6138", "1130", "2944", "4375", "2927", "6083", "1524", "1584", "2932", "1760", "3205", "4057", "4030", "1056", "2910", "2933", "3959", "1923", "2934", "6189", "3199", "4365", "6137", "1741", "7863"]
}, {
    "City": "Losuia",
    "DBTZ": "U",
    "Name": "Losuia Airport",
    "Country": "Papua New Guinea",
    "IATA/FAA": "LSA",
    "Longitude": "151.081",
    "ICAO": "",
    "Airport ID": "5980",
    "Latitude": "-8.50582",
    "Timezone": "27",
    "DST": "10",
    "destinations": ["5422"]
}, {
    "City": "Tufi",
    "DBTZ": "U",
    "Name": "Tufi Airport",
    "Country": "Papua New Guinea",
    "IATA/FAA": "TFI",
    "Longitude": "149.32",
    "ICAO": "",
    "Airport ID": "5984",
    "Latitude": "-9.07595",
    "Timezone": "85",
    "DST": "10",
    "destinations": ["5423"]
}, {
    "City": "Cologne",
    "DBTZ": "E",
    "Name": "Koln Bonn",
    "Country": "Germany",
    "IATA/FAA": "CGN",
    "Longitude": "7.142744",
    "ICAO": "EDDK",
    "Airport ID": "344",
    "Latitude": "50.865917",
    "Timezone": "302",
    "DST": "1",
    "destinations": ["5673", "210", "737", "3941", "1688", "1218", "1321", "1538", "1501", "1489", "1519", "1460", "1322", "1509", "1200", "338", "599", "535", "1626", "1555", "382", "342", "5557", "1452", "1225", "1468", "1479", "1458", "4322", "1462", "348", "507", "1638", "1054", "478", "1524", "1561", "1354", "1520", "3998", "1512", "1587", "1562", "1203", "1075", "1472", "1205", "772", "4317", "1646", "1486", "1206", "548", "1508", "1612", "1590", "287", "351", "1551", "1613", "1550", "679", "1226", "1209", "1208", "1678", "1055", "1230", "1051", "1130", "1231", "346", "7447", "1056", "580", "469", "1212", "644", "1222", "4330", "1682", "1676", "1701", "502", "4029", "668", "671", "1737", "2944", "1103", "4091", "1706"]
}, {
    "City": "Duesseldorf",
    "DBTZ": "E",
    "Name": "Dusseldorf",
    "Country": "Germany",
    "IATA/FAA": "DUS",
    "Longitude": "6.766775",
    "ICAO": "EDDL",
    "Airport ID": "345",
    "Latitude": "51.289453",
    "Timezone": "147",
    "DST": "1",
    "destinations": ["382", "4309", "737", "1218", "1321", "1216", "1501", "4053", "1519", "1509", "488", "1200", "338", "599", "1665", "5557", "1452", "1225", "671", "348", "1335", "1229", "1359", "1524", "481", "347", "1520", "3998", "674", "1587", "1742", "1486", "1206", "1508", "1526", "1551", "679", "680", "1209", "3941", "1456", "1418", "2179", "3797", "3484", "507", "3576", "3830", "3793", "1055", "1230", "1212", "1688", "1739", "1460", "1322", "609", "1852", "2897", "293", "4029", "1626", "1555", "1563", "4091", "1051", "498", "410", "342", "421", "1130", "499", "1458", "1462", "1054", "1231", "1469", "346", "1561", "7447", "1354", "1633", "1761", "1760", "1470", "1472", "3953", "4197", "4317", "1476", "1053", "548", "350", "1612", "1056", "1590", "351", "1613", "1923", "1678", "1488", "3682", "1382", "580", "608", "503", "469", "552", "478", "3364", "1064", "1474", "1715", "1197", "1226", "2188", "340", "2948", "629", "1606", "1489", "3494", "534", "687", "1609", "1611", "521", "2279", "644", "1657", "2988", "1246", "517", "1706", "1696", "1682", "3989", "5952", "7821", "2985", "1701", "1638", "287", "502", "4330", "1473", "1741"]
}, {
    "City": "Munich",
    "DBTZ": "E",
    "Name": "Franz Josef Strauss",
    "Country": "Germany",
    "IATA/FAA": "MUC",
    "Longitude": "11.786086",
    "ICAO": "EDDM",
    "Airport ID": "346",
    "Latitude": "48.353783",
    "Timezone": "1487",
    "DST": "1",
    "destinations": ["1558", "373", "3941", "1452", "1456", "1486", "507", "3752", "1055", "1230", "1212", "2170", "1459", "2179", "1688", "1506", "1519", "1460", "344", "1509", "4029", "345", "1626", "4091", "1051", "410", "342", "421", "1225", "1458", "1462", "1054", "1229", "1231", "1469", "1561", "7447", "1520", "3998", "1760", "1470", "1472", "3953", "4317", "1476", "1056", "1590", "351", "1613", "1488", "1194", "146", "156", "193", "3682", "1382", "2997", "3093", "1074", "1075", "1555", "608", "293", "490", "3364", "3406", "340", "1197", "1064", "1474", "1715", "1130", "1053", "1226", "737", "644", "599", "596", "2188", "1501", "1563", "1551", "1550", "16", "1218", "2564", "1569", "1742", "580", "1606", "629", "1706", "4309", "1739", "469", "1216", "3885", "1538", "3448", "353", "302", "4053", "1489", "1128", "1652", "3876", "609", "1200", "2940", "338", "1682", "3494", "341", "668", "1528", "687", "1609", "1665", "352", "3077", "2359", "3714", "3930", "1701", "3797", "813", "2939", "1735", "669", "3484", "2948", "348", "1638", "2945", "1335", "478", "1824", "1353", "1524", "1354", "347", "2947", "3830", "1657", "371", "1512", "674", "1587", "1562", "772", "591", "2082", "1658", "3469", "1646", "1206", "350", "1508", "3973", "1190", "1273", "1526", "1545", "1663", "1246", "679", "680", "1209", "1208", "1678", "3959", "2241", "1636", "4078", "3316", "3989", "5952", "2985", "1676", "287", "535", "502", "548", "2960", "2975", "3550", "1264", "1418", "2194", "1103", "2987"]
}, {
    "City": "Nuernberg",
    "DBTZ": "E",
    "Name": "Nurnberg",
    "Country": "Germany",
    "IATA/FAA": "NUE",
    "Longitude": "11.066897",
    "ICAO": "EDDN",
    "Airport ID": "347",
    "Latitude": "49.4987",
    "Timezone": "1046",
    "DST": "1",
    "destinations": ["345", "342", "351", "1486", "1688", "293", "1051", "1452", "1130", "1225", "1458", "1054", "7447", "1520", "3998", "1472", "4317", "1476", "1206", "1056", "1613", "1382", "1230", "1212", "548", "1218", "580", "340", "346", "1678", "1701", "6510"]
}, {
    "City": "Frankfurt",
    "DBTZ": "E",
    "Name": "Frankfurt Main",
    "Country": "Germany",
    "IATA/FAA": "FRA",
    "Longitude": "8.543125",
    "ICAO": "EDDF",
    "Airport ID": "340",
    "Latitude": "50.026421",
    "Timezone": "364",
    "DST": "1",
    "destinations": ["5557", "1735", "3941", "1452", "1486", "3876", "3670", "3304", "507", "3752", "1212", "2170", "2179", "1739", "1460", "1509", "4029", "498", "421", "1225", "503", "1229", "3998", "3953", "351", "1613", "2241", "2072", "1197", "1561", "1246", "3959", "100", "146", "156", "178", "193", "3682", "1382", "3645", "3797", "210", "3131", "2997", "3093", "3144", "3017", "1074", "5673", "2709", "1555", "3199", "1529", "3205", "2954", "7447", "490", "609", "3395", "3388", "3364", "3406", "4144", "2276", "3077", "1055", "1064", "1688", "1474", "1852", "1200", "293", "1715", "4091", "1051", "1909", "1910", "1130", "1458", "3877", "1054", "1145", "1780", "3156", "893", "4059", "1761", "1760", "2610", "1472", "1762", "3577", "994", "2890", "1053", "1206", "2621", "2901", "1056", "1923", "1226", "73", "599", "2188", "1550", "1107", "580", "737", "1218", "302", "1489", "345", "687", "1665", "1638", "1335", "1353", "346", "1524", "644", "1657", "1587", "1273", "679", "1678", "1194", "16", "2057", "2983", "4330", "2922", "2560", "2564", "2279", "1569", "1742", "1190", "2910", "3930", "1606", "2176", "629", "260", "532", "248", "1230", "2908", "2177", "636", "469", "1321", "1216", "3885", "608", "1538", "3448", "353", "4053", "1128", "2851", "3751", "2064", "338", "3989", "535", "3494", "3988", "1626", "382", "1563", "341", "668", "4274", "1609", "410", "352", "342", "2359", "3714", "3550", "1610", "1701", "813", "2939", "3992", "669", "671", "4118", "951", "3484", "2948", "348", "1611", "273", "478", "3878", "1824", "3576", "1359", "1354", "3942", "347", "1520", "1636", "3830", "1512", "674", "1203", "2082", "675", "3469", "3316", "350", "666", "1612", "415", "1590", "1526", "287", "1551", "2988", "680", "1209", "1208", "4317", "1633", "4078", "2985", "4105", "2979", "2976", "1682", "3024", "2194", "1103", "1473", "1231", "1706", "1741"]
}, {
    "City": "Munster",
    "DBTZ": "E",
    "Name": "Munster Osnabruck",
    "Country": "Germany",
    "IATA/FAA": "FMO",
    "Longitude": "7.684831",
    "ICAO": "EDDG",
    "Airport ID": "341",
    "Latitude": "52.134642",
    "Timezone": "160",
    "DST": "1",
    "destinations": ["1688", "1460", "1225", "3998", "1230", "1222", "340", "346", "353", "350", "1678"]
}, {
    "City": "Darwin",
    "DBTZ": "N",
    "Name": "Darwin Intl",
    "Country": "Australia",
    "IATA/FAA": "DRW",
    "Longitude": "130.87266",
    "ICAO": "YPDN",
    "Airport ID": "3999",
    "Latitude": "-12.4083333",
    "Timezone": "103",
    "DST": "9.5",
    "destinations": ["3316", "3320", "3361", "6270", "3319", "6316", "3341", "3322", "3940", "3339", "3304", "2397", "4319", "3310", "3324", "3347", "3351", "3330", "6297", "6296"]
}, {
    "City": "Palma de Mallorca",
    "DBTZ": "E",
    "Name": "Son Sant Joan",
    "Country": "Spain",
    "IATA/FAA": "PMI",
    "Longitude": "2.727778",
    "ICAO": "LEPA",
    "Airport ID": "3998",
    "Latitude": "39.55361",
    "Timezone": "24",
    "DST": "1",
    "destinations": ["5673", "344", "373", "345", "352", "342", "350", "351", "1230", "1212", "1216", "353", "4053", "609", "338", "1626", "382", "4166", "341", "340", "1609", "1225", "1610", "1213", "348", "1611", "1231", "346", "347", "371", "349", "1253", "1612", "1613", "1246", "1226", "1678", "1386", "210", "1218", "1229", "503", "507", "552", "495", "478", "337", "628", "737", "636", "421", "502", "644", "664", "467", "599", "596", "1194", "607", "1525", "469", "608", "1538", "494", "490", "302", "1745", "1367", "1553", "304", "535", "585", "523", "3986", "1222", "586", "691", "355", "3956", "517", "364", "491", "1353", "582", "4198", "699", "1636", "537", "1236", "657", "1243", "1251", "603", "548", "8414", "309", "310", "580", "591", "1264", "4029", "1223", "7459", "1335", "1214", "1273", "629", "465", "514", "534", "521", "1665", "1587", "687", "339", "400", "772", "1676", "488", "5562", "492", "1524", "508", "1252", "1418", "6510"]
}, {
    "City": "Aasiaat",
    "DBTZ": "E",
    "Name": "Aasiaat",
    "Country": "Greenland",
    "IATA/FAA": "JEG",
    "Longitude": "-52.75",
    "ICAO": "BGEM",
    "Airport ID": "3997",
    "Latitude": "68.7",
    "Timezone": "2",
    "DST": "-3",
    "destinations": ["3995", "5447", "9"]
}, {
    "City": "Ilulissat",
    "DBTZ": "E",
    "Name": "Ilulissat",
    "Country": "Greenland",
    "IATA/FAA": "JAV",
    "Longitude": "-51.05111",
    "ICAO": "BGJN",
    "Airport ID": "3995",
    "Latitude": "69.23444",
    "Timezone": "2",
    "DST": "-3",
    "destinations": ["8", "3997", "5449", "5448", "9"]
}, {
    "City": "Tagbilaran",
    "DBTZ": "N",
    "Name": "Tagbilaran",
    "Country": "Philippines",
    "IATA/FAA": "TAG",
    "Longitude": "123.853247",
    "ICAO": "RPVT",
    "Airport ID": "3994",
    "Latitude": "9.66408056",
    "Timezone": "38",
    "DST": "8",
    "destinations": ["2397"]
}, {
    "City": "Leipzig",
    "DBTZ": "E",
    "Name": "Leipzig Halle",
    "Country": "Germany",
    "IATA/FAA": "LEJ",
    "Longitude": "12.241633",
    "ICAO": "EDDP",
    "Airport ID": "348",
    "Latitude": "51.432447",
    "Timezone": "465",
    "DST": "1",
    "destinations": ["344", "345", "350", "1055", "1688", "4091", "1051", "1452", "1130", "1458", "3998", "1056", "1064", "1460", "293", "1054", "1472", "580", "1382", "1678", "548", "1218", "340", "346", "1613", "353", "4029", "1701"]
}, {
    "City": "Osaka",
    "DBTZ": "U",
    "Name": "Kansai",
    "Country": "Japan",
    "IATA/FAA": "KIX",
    "Longitude": "135.244167",
    "ICAO": "RJBB",
    "Airport ID": "3992",
    "Latitude": "34.4347222",
    "Timezone": "49",
    "DST": "9",
    "destinations": ["2397", "2276", "2378", "3930", "2359", "3406", "3728", "1382", "3077", "421", "1555", "2372", "3404", "3386", "3364", "3390", "4108", "3797", "2264", "2268", "3370", "3400", "4144", "3304", "2246", "2188", "3275", "3940", "2287", "2305", "2279", "2384", "3885", "2370", "3322", "2241", "3199", "3388", "3321", "3205", "3392", "580", "340", "2387", "2307", "2333", "2313", "2347", "3385", "2288", "1701", "3121", "3469", "3316", "6390", "2244", "2005"]
}, {
    "City": "Zhijiang",
    "DBTZ": "U",
    "Name": "Zhijiang Airport",
    "Country": "China",
    "IATA/FAA": "HJJ",
    "Longitude": "109.699722",
    "ICAO": "",
    "Airport ID": "7519",
    "Latitude": "27.441389",
    "Timezone": "1000",
    "DST": "8",
    "destinations": ["3371", "3370"]
}, {
    "City": "Emerald",
    "DBTZ": "O",
    "Name": "Emerald",
    "Country": "Australia",
    "IATA/FAA": "EMD",
    "Longitude": "148.179167",
    "ICAO": "YEML",
    "Airport ID": "3990",
    "Latitude": "-23.5675",
    "Timezone": "624",
    "DST": "10",
    "destinations": ["3320"]
}, {
    "City": "Minacu",
    "DBTZ": "S",
    "Name": "Minacu Airport",
    "Country": "Brazil",
    "IATA/FAA": "MQH",
    "Longitude": "-48.220556",
    "ICAO": "SBMC",
    "Airport ID": "7375",
    "Latitude": "-13.526944",
    "Timezone": "1053",
    "DST": "-3",
    "destinations": ["2531"]
}, {
    "City": "Imperial",
    "DBTZ": "A",
    "Name": "Imperial Co",
    "Country": "United States",
    "IATA/FAA": "IPL",
    "Longitude": "-115.578744",
    "ICAO": "KIPL",
    "Airport ID": "3689",
    "Latitude": "32.834219",
    "Timezone": "-54",
    "DST": "-8",
    "destinations": ["3644", "3731"]
}, {
    "City": "Namangan",
    "DBTZ": "U",
    "Name": "Namangan Airport",
    "Country": "Uzbekistan",
    "IATA/FAA": "NMA",
    "Longitude": "71.5567",
    "ICAO": "UTKN",
    "Airport ID": "6150",
    "Latitude": "40.9846",
    "Timezone": "1555",
    "DST": "5",
    "destinations": ["4029", "2948", "4078", "2975", "2983", "2968", "4274", "4374", "2960"]
}, {
    "City": "Nukus",
    "DBTZ": "U",
    "Name": "Nukus Airport",
    "Country": "Uzbekistan",
    "IATA/FAA": "NCU",
    "Longitude": "59.6233",
    "ICAO": "UTNN",
    "Airport ID": "6151",
    "Latitude": "42.4884",
    "Timezone": "246",
    "DST": "5",
    "destinations": ["2988", "4029", "2983"]
}, {
    "City": "Urgench",
    "DBTZ": "U",
    "Name": "Urgench Airport",
    "Country": "Uzbekistan",
    "IATA/FAA": "UGC",
    "Longitude": "60.6417",
    "ICAO": "UTNU",
    "Airport ID": "6152",
    "Latitude": "41.5843",
    "Timezone": "320",
    "DST": "5",
    "destinations": ["2980", "4029", "2948", "2983", "2967"]
}, {
    "City": "Khanabad",
    "DBTZ": "U",
    "Name": "Karshi Khanabad Airport",
    "Country": "Uzbekistan",
    "IATA/FAA": "KSQ",
    "Longitude": "65.9215",
    "ICAO": "UTSL",
    "Airport ID": "6153",
    "Latitude": "38.8336",
    "Timezone": "1365",
    "DST": "5",
    "destinations": ["4029", "2948", "2983"]
}, {
    "City": "Termez",
    "DBTZ": "U",
    "Name": "Termez Airport",
    "Country": "Uzbekistan",
    "IATA/FAA": "TMJ",
    "Longitude": "67.31",
    "ICAO": "UTST",
    "Airport ID": "6154",
    "Latitude": "37.286667",
    "Timezone": "1027",
    "DST": "5",
    "destinations": ["4029", "2983"]
}, {
    "City": "Belgorod",
    "DBTZ": "N",
    "Name": "Belgorod International Airport",
    "Country": "Russia",
    "IATA/FAA": "EGO",
    "Longitude": "36.5901",
    "ICAO": "UUOB",
    "Airport ID": "6156",
    "Latitude": "50.6438",
    "Timezone": "735",
    "DST": "4",
    "destinations": ["2952", "2990", "4029", "4118", "2964", "2912", "2960", "4274", "2948"]
}, {
    "City": "Kursk",
    "DBTZ": "N",
    "Name": "Kursk East Airport",
    "Country": "Russia",
    "IATA/FAA": "URS",
    "Longitude": "36.2956",
    "ICAO": "UUOK",
    "Airport ID": "6157",
    "Latitude": "51.7506",
    "Timezone": "686",
    "DST": "4",
    "destinations": ["2988"]
}, {
    "City": "Vorkuta",
    "DBTZ": "N",
    "Name": "Vorkuta Airport",
    "Country": "Russia",
    "IATA/FAA": "VKT",
    "Longitude": "63.9931",
    "ICAO": "UUYW",
    "Airport ID": "6159",
    "Latitude": "67.4886",
    "Timezone": "604",
    "DST": "4",
    "destinations": ["4029", "2989"]
}, {
    "City": "Chifeng",
    "DBTZ": "U",
    "Name": "Chifeng Airport",
    "Country": "China",
    "IATA/FAA": "CIF",
    "Longitude": "118.908",
    "ICAO": "ZBCF",
    "Airport ID": "6342",
    "Latitude": "42.235",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3364", "6346", "3404", "6345", "3366", "4144", "3368", "6341", "3400", "3388"]
}, {
    "City": "Taree",
    "DBTZ": "U",
    "Name": "Taree Airport",
    "Country": "Australia",
    "IATA/FAA": "TRO",
    "Longitude": "152.514",
    "ICAO": "YTRE",
    "Airport ID": "6794",
    "Latitude": "-31.8886",
    "Timezone": "38",
    "DST": "10",
    "destinations": ["6792", "3361"]
}, {
    "City": "Choibalsan",
    "DBTZ": "U",
    "Name": "Choibalsan Airport",
    "Country": "Mongolia",
    "IATA/FAA": "COQ",
    "Longitude": "114.646",
    "ICAO": "ZMCD",
    "Airport ID": "6795",
    "Latitude": "48.1357",
    "Timezone": "2457",
    "DST": "8",
    "destinations": ["3380", "3366", "6954"]
}, {
    "City": "Grafton",
    "DBTZ": "U",
    "Name": "Grafton Airport",
    "Country": "Australia",
    "IATA/FAA": "GFN",
    "Longitude": "153.03",
    "ICAO": "YGFN",
    "Airport ID": "6792",
    "Latitude": "-29.7594",
    "Timezone": "110",
    "DST": "10",
    "destinations": ["6794"]
}, {
    "City": "Orange",
    "DBTZ": "U",
    "Name": "Orange Airport",
    "Country": "Australia",
    "IATA/FAA": "OAG",
    "Longitude": "149.133",
    "ICAO": "YORG",
    "Airport ID": "6793",
    "Latitude": "-33.3817",
    "Timezone": "3115",
    "DST": "10",
    "destinations": ["3361"]
}, {
    "City": "Hamadan",
    "DBTZ": "E",
    "Name": "Hamadan Airport",
    "Country": "Iran",
    "IATA/FAA": "HDM",
    "Longitude": "48.5525",
    "ICAO": "OIHH",
    "Airport ID": "6790",
    "Latitude": "34.869167",
    "Timezone": "5755",
    "DST": "3.5",
    "destinations": ["4331"]
}, {
    "City": "Amami",
    "DBTZ": "U",
    "Name": "Amami",
    "Country": "Japan",
    "IATA/FAA": "ASJ",
    "Longitude": "129.712542",
    "ICAO": "RJKA",
    "Airport ID": "2316",
    "Latitude": "28.430633",
    "Timezone": "27",
    "DST": "9",
    "destinations": ["2359", "2334"]
}, {
    "City": "Kumamoto",
    "DBTZ": "U",
    "Name": "Kumamoto",
    "Country": "Japan",
    "IATA/FAA": "KMJ",
    "Longitude": "130.85505",
    "ICAO": "RJFT",
    "Airport ID": "2312",
    "Latitude": "32.837319",
    "Timezone": "642",
    "DST": "9",
    "destinations": ["5997", "2359", "2334", "3930", "3942", "2384"]
}, {
    "City": "Nagasaki",
    "DBTZ": "U",
    "Name": "Nagasaki",
    "Country": "Japan",
    "IATA/FAA": "NGS",
    "Longitude": "129.913611",
    "ICAO": "RJFU",
    "Airport ID": "2313",
    "Latitude": "32.916944",
    "Timezone": "15",
    "DST": "9",
    "destinations": ["3943", "2359", "2334", "3406", "3930", "3992", "2304", "2295", "3942", "2384", "2297"]
}, {
    "City": "Oita",
    "DBTZ": "U",
    "Name": "Oita",
    "Country": "Japan",
    "IATA/FAA": "OIT",
    "Longitude": "131.737222",
    "ICAO": "RJFO",
    "Airport ID": "2310",
    "Latitude": "33.479444",
    "Timezone": "19",
    "DST": "9",
    "destinations": ["2334", "3942", "2279", "2359", "3930"]
}, {
    "City": "Kitakyushu",
    "DBTZ": "U",
    "Name": "New Kitakyushu",
    "Country": "Japan",
    "IATA/FAA": "KKJ",
    "Longitude": "131.034689",
    "ICAO": "RJFR",
    "Airport ID": "2311",
    "Latitude": "33.845942",
    "Timezone": "21",
    "DST": "9",
    "destinations": ["2359"]
}, {
    "City": "Long Banga",
    "DBTZ": "N",
    "Name": "Long Banga Airport",
    "Country": "Malaysia",
    "IATA/FAA": "LBP",
    "Longitude": "115.454",
    "ICAO": "",
    "Airport ID": "5855",
    "Latitude": "3.18495",
    "Timezone": "750",
    "DST": "8",
    "destinations": ["3265"]
}, {
    "City": "Arthur's Town",
    "DBTZ": "U",
    "Name": "Arthurs Town Airport",
    "Country": "Bahamas",
    "IATA/FAA": "ATC",
    "Longitude": "-75.673775",
    "ICAO": "MYCA",
    "Airport ID": "5857",
    "Latitude": "24.629417",
    "Timezone": "18",
    "DST": "-5",
    "destinations": ["1953"]
}, {
    "City": "Lome",
    "DBTZ": "N",
    "Name": "Gnassingbe Eyadema Intl",
    "Country": "Togo",
    "IATA/FAA": "LFW",
    "Longitude": "1.254511",
    "ICAO": "DXXX",
    "Airport ID": "298",
    "Latitude": "6.165611",
    "Timezone": "72",
    "DST": "0",
    "destinations": ["246", "1382", "248", "253", "260", "1107", "1044", "4162", "245", "897", "2560", "970", "273", "302"]
}, {
    "City": "Antwerp",
    "DBTZ": "E",
    "Name": "Deurne",
    "Country": "Belgium",
    "IATA/FAA": "ANR",
    "Longitude": "4.460278",
    "ICAO": "EBAW",
    "Airport ID": "299",
    "Latitude": "51.189444",
    "Timezone": "39",
    "DST": "1",
    "destinations": ["503"]
}, {
    "City": "Tozeur",
    "DBTZ": "E",
    "Name": "Nefta",
    "Country": "Tunisia",
    "IATA/FAA": "TOE",
    "Longitude": "8.110556",
    "ICAO": "DTTZ",
    "Airport ID": "296",
    "Latitude": "33.939722",
    "Timezone": "287",
    "DST": "1",
    "destinations": ["1386", "287"]
}, {
    "City": "Sfax",
    "DBTZ": "E",
    "Name": "Thyna",
    "Country": "Tunisia",
    "IATA/FAA": "SFA",
    "Longitude": "10.690972",
    "ICAO": "DTTX",
    "Airport ID": "295",
    "Latitude": "34.717953",
    "Timezone": "85",
    "DST": "1",
    "destinations": ["5804", "1157", "1382", "1154", "287"]
}, {
    "City": "Djerba",
    "DBTZ": "E",
    "Name": "Zarzis",
    "Country": "Tunisia",
    "IATA/FAA": "DJE",
    "Longitude": "10.775461",
    "ICAO": "DTTJ",
    "Airport ID": "293",
    "Latitude": "33.875031",
    "Timezone": "19",
    "DST": "1",
    "destinations": ["345", "347", "1382", "1399", "1335", "1423", "1353", "346", "1418", "340", "348", "1157", "629", "7447", "1386", "302", "4053", "1665", "342", "1354", "1435", "337", "1678", "287"]
}, {
    "City": "Gafsa",
    "DBTZ": "E",
    "Name": "Gafsa",
    "Country": "Tunisia",
    "IATA/FAA": "GAF",
    "Longitude": "8.822503",
    "ICAO": "DTTF",
    "Airport ID": "290",
    "Latitude": "34.422022",
    "Timezone": "1060",
    "DST": "1",
    "destinations": ["291", "296"]
}, {
    "City": "Gabes",
    "DBTZ": "E",
    "Name": "Gabes",
    "Country": "Tunisia",
    "IATA/FAA": "GAE",
    "Longitude": "10.103333",
    "ICAO": "DTTG",
    "Airport ID": "291",
    "Latitude": "33.876919",
    "Timezone": "26",
    "DST": "1",
    "destinations": ["296", "287"]
}, {
    "City": "Plattsburgh",
    "DBTZ": "A",
    "Name": "Plattsburgh Intl",
    "Country": "United States",
    "IATA/FAA": "PBG",
    "Longitude": "-73.468139",
    "ICAO": "KPBG",
    "Airport ID": "3771",
    "Latitude": "44.650944",
    "Timezone": "234",
    "DST": "-5",
    "destinations": ["3448", "3533", "3617", "4167", "3515"]
}, {
    "City": "Texarkana",
    "DBTZ": "A",
    "Name": "Texarkana Rgnl Webb Fld",
    "Country": "United States",
    "IATA/FAA": "TXK",
    "Longitude": "-93.991028",
    "ICAO": "KTXK",
    "Airport ID": "3770",
    "Latitude": "33.453722",
    "Timezone": "390",
    "DST": "-6",
    "destinations": ["3670"]
}, {
    "City": "Bellingham",
    "DBTZ": "A",
    "Name": "Bellingham Intl",
    "Country": "United States",
    "IATA/FAA": "BLI",
    "Longitude": "-122.537528",
    "ICAO": "KBLI",
    "Airport ID": "3777",
    "Latitude": "48.792694",
    "Timezone": "170",
    "DST": "-8",
    "destinations": ["3728", "3877", "3720", "3577", "6505", "3484", "3453", "3839", "3731"]
}, {
    "City": "Killeen",
    "DBTZ": "A",
    "Name": "Robert Gray Aaf",
    "Country": "United States",
    "IATA/FAA": "GRK",
    "Longitude": "-97.828917",
    "ICAO": "KGRK",
    "Airport ID": "3775",
    "Latitude": "31.06725",
    "Timezone": "1015",
    "DST": "-6",
    "destinations": ["3670", "3682", "3550"]
}, {
    "City": "Anchorage",
    "DBTZ": "A",
    "Name": "Ted Stevens Anchorage Intl",
    "Country": "United States",
    "IATA/FAA": "ANC",
    "Longitude": "-149.996361",
    "ICAO": "PANC",
    "Airport ID": "3774",
    "Latitude": "61.174361",
    "Timezone": "152",
    "DST": "-9",
    "destinations": ["3531", "5967", "3599", "3610", "3628", "3832", "3664", "6718", "7088", "3865", "3484", "3462", "5959", "3794", "3571", "3854", "3460", "3728", "3492", "3877", "5965", "3615", "3830", "3693", "3720", "3620", "6742", "3577", "3426", "7247", "3858", "3536", "3429", "3751"]
}, {
    "City": "Kano",
    "DBTZ": "N",
    "Name": "Mallam Aminu Intl",
    "Country": "Nigeria",
    "IATA/FAA": "KAN",
    "Longitude": "8.524622",
    "ICAO": "DNKN",
    "Airport ID": "270",
    "Latitude": "12.047589",
    "Timezone": "1562",
    "DST": "1",
    "destinations": ["2177", "1128", "1175", "2072", "999", "260", "273"]
}, {
    "City": "Lagos",
    "DBTZ": "N",
    "Name": "Murtala Muhammed",
    "Country": "Nigeria",
    "IATA/FAA": "LOS",
    "Longitude": "3.321156",
    "ICAO": "DNMM",
    "Airport ID": "273",
    "Latitude": "6.577369",
    "Timezone": "135",
    "DST": "1",
    "destinations": ["248", "1084", "897", "1063", "1382", "1074", "507", "1044", "891", "3682", "2188", "253", "1107", "970", "298", "2179", "1229", "580", "245", "4059", "340", "275", "2177", "1128", "2241", "2170", "813", "1701", "3550", "260", "262", "263", "264", "1059", "3797", "268", "269", "270", "6730", "6972", "1165"]
}, {
    "City": "Port Hartcourt",
    "DBTZ": "N",
    "Name": "Port Harcourt Intl",
    "Country": "Nigeria",
    "IATA/FAA": "PHC",
    "Longitude": "6.949594",
    "ICAO": "DNPO",
    "Airport ID": "275",
    "Latitude": "5.015494",
    "Timezone": "87",
    "DST": "1",
    "destinations": ["260", "273"]
}, {
    "City": "Sokoto",
    "DBTZ": "N",
    "Name": "Sadiq Abubakar Iii Intl",
    "Country": "Nigeria",
    "IATA/FAA": "SKO",
    "Longitude": "5.207189",
    "ICAO": "DNSO",
    "Airport ID": "276",
    "Latitude": "12.916322",
    "Timezone": "1010",
    "DST": "1",
    "destinations": ["260"]
}, {
    "City": "Yola",
    "DBTZ": "N",
    "Name": "Yola",
    "Country": "Nigeria",
    "IATA/FAA": "YOL",
    "Longitude": "12.430422",
    "ICAO": "DNYO",
    "Airport ID": "277",
    "Latitude": "9.257553",
    "Timezone": "599",
    "DST": "1",
    "destinations": ["260"]
}, {
    "City": "Ittoqqortoormiit",
    "DBTZ": "U",
    "Name": "Ittoqqortoormiit Heliport",
    "Country": "Greenland",
    "IATA/FAA": "OBY",
    "Longitude": "-21.966667",
    "ICAO": "BGSC",
    "Airport ID": "8208",
    "Latitude": "70.485278",
    "Timezone": "238",
    "DST": "-1",
    "destinations": ["5439"]
}, {
    "City": "Vinnitsa",
    "DBTZ": "E",
    "Name": "Vinnitsa",
    "Country": "Ukraine",
    "IATA/FAA": "VIN",
    "Longitude": "28.6063",
    "ICAO": "UKWW",
    "Airport ID": "8209",
    "Latitude": "49.2433",
    "Timezone": "900",
    "DST": "2",
    "destinations": ["1590"]
}, {
    "City": "Fagernes",
    "DBTZ": "E",
    "Name": "Leirin",
    "Country": "Norway",
    "IATA/FAA": "VDB",
    "Longitude": "9.288056",
    "ICAO": "ENFG",
    "Airport ID": "642",
    "Latitude": "61.015556",
    "Timezone": "2697",
    "DST": "1",
    "destinations": ["644"]
}, {
    "City": "Fengnin",
    "DBTZ": "U",
    "Name": "Fengnin",
    "Country": "Taiwan",
    "IATA/FAA": "TTT",
    "Longitude": "121.101681",
    "ICAO": "RCFN",
    "Airport ID": "2262",
    "Latitude": "22.754986",
    "Timezone": "143",
    "DST": "8",
    "destinations": ["2275"]
}, {
    "City": "Conson",
    "DBTZ": "U",
    "Name": "Co Ong Airport",
    "Country": "Vietnam",
    "IATA/FAA": "VCS",
    "Longitude": "106.632589",
    "ICAO": "VVCS",
    "Airport ID": "6190",
    "Latitude": "8.731831",
    "Timezone": "20",
    "DST": "7",
    "destinations": ["3205", "6191"]
}, {
    "City": "Kaohsiung",
    "DBTZ": "U",
    "Name": "Kaohsiung Intl",
    "Country": "Taiwan",
    "IATA/FAA": "KHH",
    "Longitude": "120.350006",
    "ICAO": "RCKH",
    "Airport ID": "2264",
    "Latitude": "22.577094",
    "Timezone": "31",
    "DST": "8",
    "destinations": ["3406", "2279", "3371", "3386", "2278", "3930", "3383", "3385", "3199", "3382", "3205", "3390", "3370", "3375", "3121", "3387", "4108", "3368", "2372", "3885", "3393", "3077", "3384", "3992", "2397", "2384", "3364", "3316", "3374", "3376", "3395", "3373", "3389", "2259", "2272", "4149", "3388"]
}, {
    "City": "Buenos Aires",
    "DBTZ": "N",
    "Name": "Aeroparque Jorge Newbery",
    "Country": "Argentina",
    "IATA/FAA": "AEP",
    "Longitude": "-58.415606",
    "ICAO": "SABE",
    "Airport ID": "2442",
    "Latitude": "-34.559175",
    "Timezone": "18",
    "DST": "-3",
    "destinations": ["2816", "6777", "2508", "2439", "2440", "2441", "2454", "2699", "2501", "2513", "2443", "2517", "2487", "2455", "2545", "2488", "2555", "2470", "4061", "2560", "2564", "2471", "2458", "2477", "2467", "2452", "2509", "2599", "2474", "2490", "2469", "2495", "2496", "8914", "2512", "2650", "2456", "2476", "2460", "2461", "2497", "2491", "2492"]
}, {
    "City": "Cordoba",
    "DBTZ": "N",
    "Name": "Ambrosio L V Taravella",
    "Country": "Argentina",
    "IATA/FAA": "COR",
    "Longitude": "-64.207953",
    "ICAO": "SACO",
    "Airport ID": "2443",
    "Latitude": "-31.323619",
    "Timezone": "1604",
    "DST": "-3",
    "destinations": ["2452", "2440", "2442", "2513", "3988", "2477", "2476", "1871", "2564", "2789", "2650"]
}, {
    "City": "Rosario",
    "DBTZ": "N",
    "Name": "Rosario",
    "Country": "Argentina",
    "IATA/FAA": "ROS",
    "Longitude": "-60.785",
    "ICAO": "SAAR",
    "Airport ID": "2440",
    "Latitude": "-32.903611",
    "Timezone": "85",
    "DST": "-3",
    "destinations": ["2442", "2443", "6777", "3988", "2564"]
}, {
    "City": "Santa Fe",
    "DBTZ": "N",
    "Name": "Sauce Viejo",
    "Country": "Argentina",
    "IATA/FAA": "SFN",
    "Longitude": "-60.811668",
    "ICAO": "SAAV",
    "Airport ID": "2441",
    "Latitude": "-31.711666",
    "Timezone": "56",
    "DST": "-3",
    "destinations": ["2442"]
}, {
    "City": "Bandar Mahshahr",
    "DBTZ": "E",
    "Name": "Mahshahr",
    "Country": "Iran",
    "IATA/FAA": "MRX",
    "Longitude": "49.151879",
    "ICAO": "OIAM",
    "Airport ID": "2103",
    "Latitude": "30.556192",
    "Timezone": "8",
    "DST": "3.5",
    "destinations": ["4150", "2131"]
}, {
    "City": "Prince Pupert",
    "DBTZ": "A",
    "Name": "Prince Rupert",
    "Country": "Canada",
    "IATA/FAA": "YPR",
    "Longitude": "-130.444722",
    "ICAO": "CYPR",
    "Airport ID": "108",
    "Latitude": "54.28611",
    "Timezone": "116",
    "DST": "-8",
    "destinations": ["156"]
}, {
    "City": "Fort Chipewyan",
    "DBTZ": "A",
    "Name": "Fort Chipewyan",
    "Country": "Canada",
    "IATA/FAA": "YPY",
    "Longitude": "-111.117222",
    "ICAO": "CYPY",
    "Airport ID": "109",
    "Latitude": "58.767223",
    "Timezone": "761",
    "DST": "-7",
    "destinations": ["90", "136"]
}, {
    "City": "Sevastopol",
    "DBTZ": "U",
    "Name": "Belbek Sevastopol International Airport",
    "Country": "Ukraine",
    "IATA/FAA": "UKS",
    "Longitude": "33.57567",
    "ICAO": "UKFB",
    "Airport ID": "7863",
    "Latitude": "44.691431",
    "Timezone": "344",
    "DST": "2",
    "destinations": ["4029", "2939"]
}, {
    "City": "Tengchong",
    "DBTZ": "N",
    "Name": "Tengchong Tuofeng Airport",
    "Country": "China",
    "IATA/FAA": "TCZ",
    "Longitude": "98.483591",
    "ICAO": "ZUTC",
    "Airport ID": "7862",
    "Latitude": "24.938651",
    "Timezone": "5500",
    "DST": "8",
    "destinations": ["3395", "3382", "4055", "4033"]
}, {
    "City": "Ottawa",
    "DBTZ": "A",
    "Name": "Ottawa Macdonald Cartier Intl",
    "Country": "Canada",
    "IATA/FAA": "YOW",
    "Longitude": "-75.669167",
    "ICAO": "CYOW",
    "Airport ID": "100",
    "Latitude": "45.3225",
    "Timezone": "374",
    "DST": "-5",
    "destinations": ["196", "55", "3876", "3752", "3448", "3520", "340", "3714", "3697", "507", "3830", "49", "56", "73", "111", "117", "146", "156", "160", "174", "178", "189", "193", "3645", "144", "3494"]
}, {
    "City": "Port Menier",
    "DBTZ": "A",
    "Name": "Port Menier",
    "Country": "Canada",
    "IATA/FAA": "YPN",
    "Longitude": "-64.288611",
    "ICAO": "CYPN",
    "Airport ID": "106",
    "Latitude": "49.836389",
    "Timezone": "167",
    "DST": "-5",
    "destinations": ["7255", "202"]
}, {
    "City": "Pickle Lake",
    "DBTZ": "A",
    "Name": "Pickle Lake",
    "Country": "Canada",
    "IATA/FAA": "YPL",
    "Longitude": "-90.214167",
    "ICAO": "CYPL",
    "Airport ID": "105",
    "Latitude": "51.446388",
    "Timezone": "1267",
    "DST": "-5",
    "destinations": ["121"]
}, {
    "City": "Westport",
    "DBTZ": "Z",
    "Name": "Westport",
    "Country": "New Zealand",
    "IATA/FAA": "WSZ",
    "Longitude": "171.580833",
    "ICAO": "NZWS",
    "Airport ID": "2046",
    "Latitude": "-41.738056",
    "Timezone": "13",
    "DST": "12",
    "destinations": ["2042"]
}, {
    "City": "Wanganui",
    "DBTZ": "Z",
    "Name": "Wanganui",
    "Country": "New Zealand",
    "IATA/FAA": "WAG",
    "Longitude": "175.025278",
    "ICAO": "NZWU",
    "Airport ID": "2047",
    "Latitude": "-39.962222",
    "Timezone": "27",
    "DST": "12",
    "destinations": ["2006"]
}, {
    "City": "Whangarei",
    "DBTZ": "Z",
    "Name": "Whangarei",
    "Country": "New Zealand",
    "IATA/FAA": "WRE",
    "Longitude": "174.365",
    "ICAO": "NZWR",
    "Airport ID": "2045",
    "Latitude": "-35.768333",
    "Timezone": "133",
    "DST": "12",
    "destinations": ["2006", "2042"]
}, {
    "City": "Wellington",
    "DBTZ": "Z",
    "Name": "Wellington Intl",
    "Country": "New Zealand",
    "IATA/FAA": "WLG",
    "Longitude": "174.805278",
    "ICAO": "NZWN",
    "Airport ID": "2042",
    "Latitude": "-41.327221",
    "Timezone": "41",
    "DST": "12",
    "destinations": ["3339", "3361", "2006", "2009", "2037", "3320", "2011", "2012", "2015", "2025", "4095", "2023", "2024", "2028", "2031", "2035", "2034", "2007", "2045", "2046", "2030"]
}, {
    "City": "Whakatane",
    "DBTZ": "Z",
    "Name": "Whakatane",
    "Country": "New Zealand",
    "IATA/FAA": "WHK",
    "Longitude": "176.914167",
    "ICAO": "NZWK",
    "Airport ID": "2041",
    "Latitude": "-37.920556",
    "Timezone": "20",
    "DST": "12",
    "destinations": ["2006"]
}, {
    "City": "Herat",
    "DBTZ": "U",
    "Name": "Herat",
    "Country": "Afghanistan",
    "IATA/FAA": "HEA",
    "Longitude": "62.2283",
    "ICAO": "OAHR",
    "Airport ID": "2048",
    "Latitude": "34.210017",
    "Timezone": "3206",
    "DST": "4.5",
    "destinations": ["3093", "2223", "2050", "4331"]
}, {
    "City": "Bushehr",
    "DBTZ": "E",
    "Name": "Bushehr",
    "Country": "Iran",
    "IATA/FAA": "BUZ",
    "Longitude": "50.834637",
    "ICAO": "OIBB",
    "Airport ID": "2106",
    "Latitude": "28.944811",
    "Timezone": "68",
    "DST": "3.5",
    "destinations": ["2188", "4331", "2131", "2157"]
}, {
    "City": "Zhaotong",
    "DBTZ": "U",
    "Name": "Zhaotong Airport",
    "Country": "China",
    "IATA/FAA": "ZAT",
    "Longitude": "103.755",
    "ICAO": "ZPZT",
    "Airport ID": "6378",
    "Latitude": "27.3256",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3382", "3393"]
}, {
    "City": "Ganzhou",
    "DBTZ": "U",
    "Name": "Ganzhou Airport",
    "Country": "China",
    "IATA/FAA": "KOW",
    "Longitude": "114.912",
    "ICAO": "ZSGZ",
    "Airport ID": "6379",
    "Latitude": "25.8258",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3395", "3383", "3386", "3382", "3364", "3370", "3374", "3384", "6355", "3391"]
}, {
    "City": "Altai",
    "DBTZ": "U",
    "Name": "Altai Airport",
    "Country": "Mongolia",
    "IATA/FAA": "LTI",
    "Longitude": "96.2211",
    "ICAO": "ZMAT",
    "Airport ID": "6370",
    "Latitude": "46.3764",
    "Timezone": "7260",
    "DST": "8",
    "destinations": ["6371"]
}, {
    "City": "Bayankhongor",
    "DBTZ": "U",
    "Name": "Bayankhongor Airport",
    "Country": "Mongolia",
    "IATA/FAA": "BYN",
    "Longitude": "100.704",
    "ICAO": "ZMBH",
    "Airport ID": "6371",
    "Latitude": "46.1633",
    "Timezone": "6085",
    "DST": "8",
    "destinations": ["6370", "3380"]
}, {
    "City": "Khovd",
    "DBTZ": "U",
    "Name": "Khovd Airport",
    "Country": "Mongolia",
    "IATA/FAA": "HVD",
    "Longitude": "91.6282",
    "ICAO": "ZMKD",
    "Airport ID": "6373",
    "Latitude": "47.9541",
    "Timezone": "4898",
    "DST": "7",
    "destinations": ["3380"]
}, {
    "City": "Muren",
    "DBTZ": "U",
    "Name": "Muren Airport",
    "Country": "Mongolia",
    "IATA/FAA": "MXV",
    "Longitude": "100.099",
    "ICAO": "ZMMN",
    "Airport ID": "6374",
    "Latitude": "49.6633",
    "Timezone": "4272",
    "DST": "8",
    "destinations": ["3380"]
}, {
    "City": "Shangri-La",
    "DBTZ": "U",
    "Name": "Diqing Airport",
    "Country": "China",
    "IATA/FAA": "DIG",
    "Longitude": "99.6772",
    "ICAO": "ZPDQ",
    "Airport ID": "6375",
    "Latitude": "27.7936",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3382", "3370", "3395", "4055", "4097"]
}, {
    "City": "Luxi",
    "DBTZ": "U",
    "Name": "Mangshi Airport",
    "Country": "China",
    "IATA/FAA": "LUM",
    "Longitude": "98.5317",
    "ICAO": "ZPLX",
    "Airport ID": "6376",
    "Latitude": "24.4011",
    "Timezone": "2890",
    "DST": "8",
    "destinations": ["3395", "3382", "3364", "3370"]
}, {
    "City": "Simao",
    "DBTZ": "U",
    "Name": "Simao Airport",
    "Country": "China",
    "IATA/FAA": "SYM",
    "Longitude": "100.959",
    "ICAO": "ZPSM",
    "Airport ID": "6377",
    "Latitude": "22.7933",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3382"]
}, {
    "City": "Comiso",
    "DBTZ": "E",
    "Name": "Comiso",
    "Country": "Italy",
    "IATA/FAA": "CIY",
    "Longitude": "14.6144",
    "ICAO": "LICB",
    "Airport ID": "9327",
    "Latitude": "37",
    "Timezone": "756",
    "DST": "1",
    "destinations": ["1529", "1553", "304", "599", "355", "3956", "1562", "548"]
}, {
    "City": "Bulawayo",
    "DBTZ": "U",
    "Name": "J M Nkomo Intl",
    "Country": "Zimbabwe",
    "IATA/FAA": "BUQ",
    "Longitude": "28.617869",
    "ICAO": "FVBU",
    "Airport ID": "1001",
    "Latitude": "-20.017431",
    "Timezone": "4359",
    "DST": "2",
    "destinations": ["813", "1005", "1004"]
}, {
    "City": "Bodaibo",
    "DBTZ": "N",
    "Name": "Bodaibo",
    "Country": "Russia",
    "IATA/FAA": "ODO",
    "Longitude": "114.1348",
    "ICAO": "UIKB",
    "Airport ID": "8944",
    "Latitude": "57.5137",
    "Timezone": "940",
    "DST": "9",
    "destinations": ["2937"]
}, {
    "City": "Lake Charles",
    "DBTZ": "A",
    "Name": "Lake Charles Rgnl",
    "Country": "United States",
    "IATA/FAA": "LCH",
    "Longitude": "-93.223335",
    "ICAO": "KLCH",
    "Airport ID": "3513",
    "Latitude": "30.126112",
    "Timezone": "15",
    "DST": "-6",
    "destinations": ["3670", "3550"]
}, {
    "City": "Rainbow Lake",
    "DBTZ": "A",
    "Name": "Rainbow Lake Airport",
    "Country": "Canada",
    "IATA/FAA": "YOP",
    "Longitude": "-119.407778",
    "ICAO": "CYOP",
    "Airport ID": "7266",
    "Latitude": "58.491389",
    "Timezone": "1756",
    "DST": "-7",
    "destinations": ["49", "99"]
}, {
    "City": "Nantucket",
    "DBTZ": "A",
    "Name": "Nantucket Mem",
    "Country": "United States",
    "IATA/FAA": "ACK",
    "Longitude": "-70.060181",
    "ICAO": "KACK",
    "Airport ID": "3517",
    "Latitude": "41.253053",
    "Timezone": "48",
    "DST": "-5",
    "destinations": ["3448", "5737", "4278", "4265", "3589", "3624"]
}, {
    "City": "Myrtle Beach",
    "DBTZ": "A",
    "Name": "Myrtle Beach Intl",
    "Country": "United States",
    "IATA/FAA": "MYR",
    "Longitude": "-78.928333",
    "ICAO": "KMYR",
    "Airport ID": "3515",
    "Latitude": "33.67975",
    "Timezone": "25",
    "DST": "-5",
    "destinations": ["3876", "3520", "3752", "3682", "3448", "3645", "3697", "4355", "4041", "5742", "3881", "3524", "3849", "4285", "3670", "3533", "3630", "5747", "3830", "3771", "144", "3494", "193"]
}, {
    "City": "Kona",
    "DBTZ": "N",
    "Name": "Kona Intl At Keahole",
    "Country": "United States",
    "IATA/FAA": "KOA",
    "Longitude": "-156.045631",
    "ICAO": "PHKO",
    "Airport ID": "3514",
    "Latitude": "19.738767",
    "Timezone": "47",
    "DST": "-10",
    "destinations": ["3484", "3453", "3462", "3748", "3577", "3728", "3456", "3796", "3751", "3469", "156"]
}, {
    "City": "Waikabubak-Sumba Island",
    "DBTZ": "N",
    "Name": "Tambolaka Airport",
    "Country": "Indonesia",
    "IATA/FAA": "TMC",
    "Longitude": "119.244494",
    "ICAO": "WADT",
    "Airport ID": "6204",
    "Latitude": "-9.409717",
    "Timezone": "161",
    "DST": "8",
    "destinations": ["3940", "3916"]
}, {
    "City": "Quito",
    "DBTZ": "U",
    "Name": "Mariscal Sucre Intl",
    "Country": "Ecuador",
    "IATA/FAA": "UIO",
    "Longitude": "-78.488214",
    "ICAO": "SEQU",
    "Airport ID": "2688",
    "Latitude": "-0.141144",
    "Timezone": "9228",
    "DST": "-5",
    "destinations": ["2709", "2673", "2670", "3576", "1824", "2789", "1892", "1871", "3682", "2671", "6047", "2564", "1909", "6750", "6046", "2683", "2697", "2680", "1229", "2715", "2745", "3550"]
}, {
    "City": "Tanjung Redep-Borneo Island",
    "DBTZ": "N",
    "Name": "Barau(Kalimaru) Airport",
    "Country": "Indonesia",
    "IATA/FAA": "BEJ",
    "Longitude": "117.432256",
    "ICAO": "WALK",
    "Airport ID": "6209",
    "Latitude": "2.155497",
    "Timezone": "59",
    "DST": "8",
    "destinations": ["3919"]
}, {
    "City": "Manta",
    "DBTZ": "U",
    "Name": "Eloy Alfaro Intl",
    "Country": "Ecuador",
    "IATA/FAA": "MEC",
    "Longitude": "-80.678808",
    "ICAO": "SEMT",
    "Airport ID": "2683",
    "Latitude": "-0.946078",
    "Timezone": "48",
    "DST": "-5",
    "destinations": ["2688"]
}, {
    "City": "Shanghai",
    "DBTZ": "U",
    "Name": "Hongqiao Intl",
    "Country": "China",
    "IATA/FAA": "SHA",
    "Longitude": "121.336319",
    "ICAO": "ZSSS",
    "Airport ID": "3391",
    "Latitude": "31.197875",
    "Timezone": "10",
    "DST": "8",
    "destinations": ["9107", "3370", "6352", "3393", "3371", "3395", "8781", "6386", "3382", "3394", "6431", "6347", "4302", "4030", "3374", "3390", "3399", "3379", "3383", "6355", "2275", "2359", "6380", "3121", "3364", "4108", "3368", "3392", "3077", "3375", "3385", "3373", "6359", "3376", "6346", "7503", "2378", "6345", "6428", "6944", "3384", "3372", "6383", "6385", "4144", "6387", "3369", "6392", "6361", "9025", "6343", "4085", "7504", "6341", "6427", "8407", "7898", "6381", "6379", "4033", "4148", "6356", "6391", "4149", "6393"]
}, {
    "City": "Hamburg",
    "DBTZ": "E",
    "Name": "Hamburg",
    "Country": "Germany",
    "IATA/FAA": "HAM",
    "Longitude": "9.988228",
    "ICAO": "EDDH",
    "Airport ID": "342",
    "Latitude": "53.630389",
    "Timezone": "53",
    "DST": "1",
    "destinations": ["382", "580", "737", "1218", "636", "469", "1321", "1489", "1382", "344", "1509", "1200", "1626", "1555", "4166", "3986", "1665", "1452", "4322", "507", "1229", "478", "1524", "1561", "1354", "347", "1520", "644", "3998", "1587", "1742", "1205", "4317", "1486", "1206", "350", "1273", "1551", "1613", "1550", "1678", "410", "1055", "1230", "1212", "1688", "1460", "345", "1051", "421", "1225", "1458", "1054", "346", "3953", "1612", "1056", "490", "1715", "4091", "1130", "1472", "1226", "599", "2188", "4330", "1606", "349", "302", "4053", "3494", "340", "1638", "679", "609", "2948", "2985", "1676", "1706", "1701", "293", "7447", "3941", "535", "502", "492", "1231"]
}, {
    "City": "Yakutat",
    "DBTZ": "A",
    "Name": "Yakutat",
    "Country": "United States",
    "IATA/FAA": "YAK",
    "Longitude": "-139.3937",
    "ICAO": "PAYA",
    "Airport ID": "6803",
    "Latitude": "59.3012",
    "Timezone": "33",
    "DST": "-9",
    "destinations": ["3610", "3492"]
}, {
    "City": "Escanaba",
    "DBTZ": "A",
    "Name": "Delta County Airport",
    "Country": "United States",
    "IATA/FAA": "ESC",
    "Longitude": "-87.093611",
    "ICAO": "KESC",
    "Airport ID": "6800",
    "Latitude": "45.722778",
    "Timezone": "609",
    "DST": "-5",
    "destinations": ["3645"]
}, {
    "City": "High Level",
    "DBTZ": "A",
    "Name": "High Level",
    "Country": "Canada",
    "IATA/FAA": "YOJ",
    "Longitude": "-117.164722",
    "ICAO": "CYOJ",
    "Airport ID": "99",
    "Latitude": "58.621389",
    "Timezone": "1110",
    "DST": "-7",
    "destinations": ["49", "7266"]
}, {
    "City": "Yantai",
    "DBTZ": "U",
    "Name": "Laishan",
    "Country": "China",
    "IATA/FAA": "YNT",
    "Longitude": "121.371667",
    "ICAO": "ZSYT",
    "Airport ID": "3392",
    "Latitude": "37.401667",
    "Timezone": "59",
    "DST": "8",
    "destinations": ["3404", "3368", "3370", "4380", "3395", "3386", "3400", "3930", "3388", "3364", "3406", "3391", "6347", "3374", "4108", "3376", "3379", "3371", "4144", "3992", "3077", "3389", "2276"]
}, {
    "City": "Moosonee",
    "DBTZ": "A",
    "Name": "Moosonee",
    "Country": "Canada",
    "IATA/FAA": "YMO",
    "Longitude": "-80.607778",
    "ICAO": "CYMO",
    "Airport ID": "91",
    "Latitude": "51.291111",
    "Timezone": "30",
    "DST": "-5",
    "destinations": ["5490", "5507", "143"]
}, {
    "City": "Fort Mcmurray",
    "DBTZ": "A",
    "Name": "Fort Mcmurray",
    "Country": "Canada",
    "IATA/FAA": "YMM",
    "Longitude": "-111.221944",
    "ICAO": "CYMM",
    "Airport ID": "90",
    "Latitude": "56.653333",
    "Timezone": "1211",
    "DST": "-7",
    "destinations": ["3751", "49", "156", "178", "193", "109", "112"]
}, {
    "City": "Natashquan",
    "DBTZ": "A",
    "Name": "Natashquan",
    "Country": "Canada",
    "IATA/FAA": "YNA",
    "Longitude": "-61.789167",
    "ICAO": "CYNA",
    "Airport ID": "94",
    "Latitude": "50.19",
    "Timezone": "39",
    "DST": "-5",
    "destinations": ["202", "5503", "8217"]
}, {
    "City": "Huahine Island",
    "DBTZ": "U",
    "Name": "Huahine",
    "Country": "French Polynesia",
    "IATA/FAA": "HUH",
    "Longitude": "-151.021667",
    "ICAO": "NTTH",
    "Airport ID": "1991",
    "Latitude": "-16.687242",
    "Timezone": "7",
    "DST": "-10",
    "destinations": ["1989", "1992", "4075", "1995"]
}, {
    "City": "Rangiroa",
    "DBTZ": "U",
    "Name": "Rangiroa",
    "Country": "French Polynesia",
    "IATA/FAA": "RGI",
    "Longitude": "-147.6608",
    "ICAO": "NTTG",
    "Airport ID": "1990",
    "Latitude": "-14.954283",
    "Timezone": "10",
    "DST": "-10",
    "destinations": ["1977", "1985", "4075", "1975", "1978"]
}, {
    "City": "Hao Island",
    "DBTZ": "U",
    "Name": "Hao",
    "Country": "French Polynesia",
    "IATA/FAA": "HOI",
    "Longitude": "-140.945886",
    "ICAO": "NTTO",
    "Airport ID": "1993",
    "Latitude": "-18.074814",
    "Timezone": "10",
    "DST": "-10",
    "destinations": ["1979", "1981"]
}, {
    "City": "Moorea",
    "DBTZ": "U",
    "Name": "Moorea",
    "Country": "French Polynesia",
    "IATA/FAA": "MOZ",
    "Longitude": "-149.761869",
    "ICAO": "NTTM",
    "Airport ID": "1992",
    "Latitude": "-17.489972",
    "Timezone": "9",
    "DST": "-10",
    "destinations": ["1989", "1991", "4075", "1995"]
}, {
    "City": "Graciosa Island",
    "DBTZ": "E",
    "Name": "Graciosa",
    "Country": "Portugal",
    "IATA/FAA": "GRW",
    "Longitude": "-28.029847",
    "ICAO": "LPGR",
    "Airport ID": "1627",
    "Latitude": "39.092169",
    "Timezone": "86",
    "DST": "-1",
    "destinations": ["1629"]
}, {
    "City": "Faro",
    "DBTZ": "E",
    "Name": "Faro",
    "Country": "Portugal",
    "IATA/FAA": "FAO",
    "Longitude": "-7.965911",
    "ICAO": "LPFR",
    "Airport ID": "1626",
    "Latitude": "37.014425",
    "Timezone": "24",
    "DST": "0",
    "destinations": ["344", "342", "350", "345", "346", "3998", "351", "502", "507", "552", "495", "737", "609", "644", "467", "599", "596", "603", "469", "608", "494", "353", "490", "1367", "304", "373", "535", "585", "523", "3986", "355", "601", "517", "468", "1638", "491", "478", "582", "600", "4198", "1636", "537", "548", "1230", "580", "586", "591", "1102", "629", "1226", "340", "465", "514", "534", "521", "302", "492", "1386", "4053", "1665", "508", "352", "1382"]
}, {
    "City": "Flores",
    "DBTZ": "E",
    "Name": "Flores",
    "Country": "Portugal",
    "IATA/FAA": "FLW",
    "Longitude": "-31.131361",
    "ICAO": "LPFL",
    "Airport ID": "1625",
    "Latitude": "39.455272",
    "Timezone": "112",
    "DST": "-1",
    "destinations": ["5793", "1628", "1633", "1629"]
}, {
    "City": "Koumac",
    "DBTZ": "U",
    "Name": "Koumac",
    "Country": "New Caledonia",
    "IATA/FAA": "KOC",
    "Longitude": "164.255625",
    "ICAO": "NWWK",
    "Airport ID": "1999",
    "Latitude": "-20.546314",
    "Timezone": "42",
    "DST": "11",
    "destinations": ["5920", "2001"]
}, {
    "City": "Kone",
    "DBTZ": "U",
    "Name": "Kone",
    "Country": "New Caledonia",
    "IATA/FAA": "KNQ",
    "Longitude": "164.837806",
    "ICAO": "NWWD",
    "Airport ID": "1998",
    "Latitude": "-21.053428",
    "Timezone": "23",
    "DST": "11",
    "destinations": ["2001"]
}, {
    "City": "Lajes (terceira Island)",
    "DBTZ": "E",
    "Name": "Lajes",
    "Country": "Portugal",
    "IATA/FAA": "TER",
    "Longitude": "-27.090797",
    "ICAO": "LPLA",
    "Airport ID": "1629",
    "Latitude": "38.761842",
    "Timezone": "180",
    "DST": "-1",
    "destinations": ["1638", "1636", "1625", "1627", "1628", "1633", "1634", "1639"]
}, {
    "City": "Horta",
    "DBTZ": "E",
    "Name": "Horta",
    "Country": "Portugal",
    "IATA/FAA": "HOR",
    "Longitude": "-28.715872",
    "ICAO": "LPHR",
    "Airport ID": "1628",
    "Latitude": "38.519894",
    "Timezone": "118",
    "DST": "-1",
    "destinations": ["1638", "5793", "1625", "1633", "1629"]
}, {
    "City": "Saarbruecken",
    "DBTZ": "E",
    "Name": "Saarbrucken",
    "Country": "Germany",
    "IATA/FAA": "SCN",
    "Longitude": "7.109508",
    "ICAO": "EDDR",
    "Airport ID": "349",
    "Latitude": "49.214553",
    "Timezone": "1058",
    "DST": "1",
    "destinations": ["3998", "351", "342", "629"]
}, {
    "City": "Khajuraho",
    "DBTZ": "N",
    "Name": "Khajuraho",
    "Country": "India",
    "IATA/FAA": "HJR",
    "Longitude": "79.918597",
    "ICAO": "VAKJ",
    "Airport ID": "3012",
    "Latitude": "24.817197",
    "Timezone": "728",
    "DST": "5.5",
    "destinations": ["3084"]
}, {
    "City": "Jamnagar",
    "DBTZ": "N",
    "Name": "Jamnagar",
    "Country": "India",
    "IATA/FAA": "JGA",
    "Longitude": "70.012556",
    "ICAO": "VAJM",
    "Airport ID": "3010",
    "Latitude": "22.465522",
    "Timezone": "69",
    "DST": "5.5",
    "destinations": ["2997"]
}, {
    "City": "Lublin",
    "DBTZ": "E",
    "Name": "Lublin",
    "Country": "Poland",
    "IATA/FAA": "LUZ",
    "Longitude": "22.714083",
    "ICAO": "EPLB",
    "Airport ID": "8832",
    "Latitude": "51.239333",
    "Timezone": "203",
    "DST": "1",
    "destinations": ["548", "668", "1524", "1555", "492", "664"]
}, {
    "City": "Santo Domingo",
    "DBTZ": "U",
    "Name": "Mayor Buenaventura Vivas",
    "Country": "Venezuela",
    "IATA/FAA": "STD",
    "Longitude": "-72.035125",
    "ICAO": "SVSO",
    "Airport ID": "2861",
    "Latitude": "7.565111",
    "Timezone": "1083",
    "DST": "-4.5",
    "destinations": ["2851", "2850"]
}, {
    "City": "San Tome",
    "DBTZ": "U",
    "Name": "San Tome",
    "Country": "Venezuela",
    "IATA/FAA": "SOM",
    "Longitude": "-64.151083",
    "ICAO": "SVST",
    "Airport ID": "2864",
    "Latitude": "8.945147",
    "Timezone": "837",
    "DST": "-4.5",
    "destinations": ["2851"]
}, {
    "City": "Surgut",
    "DBTZ": "N",
    "Name": "Surgut",
    "Country": "Russia",
    "IATA/FAA": "SGC",
    "Longitude": "73.401842",
    "ICAO": "USRR",
    "Airport ID": "2974",
    "Latitude": "61.343694",
    "Timezone": "200",
    "DST": "6",
    "destinations": ["2979", "6147", "1735", "2912", "2913", "4029", "2985", "4078", "2975", "4111", "2992", "4297"]
}, {
    "City": "Valencia",
    "DBTZ": "U",
    "Name": "Arturo Michelena Intl",
    "Country": "Venezuela",
    "IATA/FAA": "VLN",
    "Longitude": "-67.9284",
    "ICAO": "SVVA",
    "Airport ID": "2868",
    "Latitude": "10.149733",
    "Timezone": "1417",
    "DST": "-4.5",
    "destinations": ["2895", "2897", "2821", "2848", "1871", "2850"]
}, {
    "City": "Mamuju",
    "DBTZ": "N",
    "Name": "Tampa Padang",
    "Country": "Indonesia",
    "IATA/FAA": "MJU",
    "Longitude": "119.033333",
    "ICAO": "WAWJ",
    "Airport ID": "9181",
    "Latitude": "-2.583333",
    "Timezone": "2",
    "DST": "8",
    "destinations": ["3240"]
}, {
    "City": "Exeter",
    "DBTZ": "E",
    "Name": "Exeter",
    "Country": "United Kingdom",
    "IATA/FAA": "EXT",
    "Longitude": "-3.413889",
    "ICAO": "EGTE",
    "Airport ID": "552",
    "Latitude": "50.734444",
    "Timezone": "102",
    "DST": "0",
    "destinations": ["1382", "1265", "1415", "1230", "1212", "580", "467", "599", "345", "535", "1626", "498", "534", "499", "478", "3998", "4347", "481", "1606"]
}, {
    "City": "Kirakira",
    "DBTZ": "U",
    "Name": "Ngorangora Airport",
    "Country": "Solomon Islands",
    "IATA/FAA": "IRA",
    "Longitude": "161.898",
    "ICAO": "AGGK",
    "Airport ID": "5409",
    "Latitude": "-10.4497",
    "Timezone": "0",
    "DST": "11",
    "destinations": ["4074", "7618", "7616"]
}, {
    "City": "Paphos",
    "DBTZ": "E",
    "Name": "Pafos Intl",
    "Country": "Cyprus",
    "IATA/FAA": "PFO",
    "Longitude": "32.485731",
    "ICAO": "LCPH",
    "Airport ID": "1198",
    "Latitude": "34.718039",
    "Timezone": "41",
    "DST": "2",
    "destinations": ["502", "1194", "3941", "1474", "304", "1473", "669", "3956", "478", "699", "1486", "548", "302", "580", "523", "534", "517", "521", "4029", "469", "490", "492", "535", "2960", "2988"]
}, {
    "City": "Learmonth",
    "DBTZ": "O",
    "Name": "Learmonth",
    "Country": "Australia",
    "IATA/FAA": "LEA",
    "Longitude": "114.088611",
    "ICAO": "YPLM",
    "Airport ID": "3348",
    "Latitude": "-22.235556",
    "Timezone": "19",
    "DST": "8",
    "destinations": ["3351"]
}, {
    "City": "Port Hedland",
    "DBTZ": "O",
    "Name": "Port Hedland Intl",
    "Country": "Australia",
    "IATA/FAA": "PHE",
    "Longitude": "118.626389",
    "ICAO": "YPPD",
    "Airport ID": "3349",
    "Latitude": "-20.377778",
    "Timezone": "33",
    "DST": "8",
    "destinations": ["4319", "3320", "3339", "3351", "3940"]
}, {
    "City": "Show Low",
    "DBTZ": "A",
    "Name": "Show Low Regional Airport",
    "Country": "United States",
    "IATA/FAA": "SOW",
    "Longitude": "-110.005556",
    "ICAO": "KSOW",
    "Airport ID": "7078",
    "Latitude": "34.265556",
    "Timezone": "6415",
    "DST": "-7",
    "destinations": ["3743", "3462"]
}, {
    "City": "Bourgas",
    "DBTZ": "E",
    "Name": "Burgas",
    "Country": "Bulgaria",
    "IATA/FAA": "BOJ",
    "Longitude": "27.515236",
    "ICAO": "LBBG",
    "Airport ID": "1191",
    "Latitude": "42.569583",
    "Timezone": "135",
    "DST": "2",
    "destinations": ["609", "421", "644", "599", "2948", "1194", "2985", "1196", "302", "4029", "492"]
}, {
    "City": "Tirana",
    "DBTZ": "E",
    "Name": "Tirana Rinas",
    "Country": "Albania",
    "IATA/FAA": "TIA",
    "Longitude": "19.720561",
    "ICAO": "LATI",
    "Airport ID": "1190",
    "Latitude": "41.414742",
    "Timezone": "126",
    "DST": "1",
    "destinations": ["3941", "1538", "1528", "1524", "1562", "1551", "1550", "1501", "1555", "1526", "502", "1525", "340", "1569", "346", "1613", "4317", "1701"]
}, {
    "City": "Plovdiv",
    "DBTZ": "E",
    "Name": "Plovdiv",
    "Country": "Bulgaria",
    "IATA/FAA": "PDV",
    "Longitude": "24.850833",
    "ICAO": "LBPD",
    "Airport ID": "1193",
    "Latitude": "42.067806",
    "Timezone": "597",
    "DST": "2",
    "destinations": ["355", "548"]
}, {
    "City": "Adelaide",
    "DBTZ": "O",
    "Name": "Adelaide Intl",
    "Country": "Australia",
    "IATA/FAA": "ADL",
    "Longitude": "138.530556",
    "ICAO": "YPAD",
    "Airport ID": "3341",
    "Latitude": "-34.945",
    "Timezone": "20",
    "DST": "9.5",
    "destinations": ["3320", "3361", "3077", "3304", "2188", "3316", "2006", "3322", "3940", "3999", "3339", "3321", "3351", "3319", "3355", "3346", "6311", "6318", "6312", "6241", "6256", "6253", "4051", "6303", "6298", "6334"]
}, {
    "City": "Kalgoorlie",
    "DBTZ": "O",
    "Name": "Kalgoorlie Boulder",
    "Country": "Australia",
    "IATA/FAA": "KGI",
    "Longitude": "121.461667",
    "ICAO": "YPKG",
    "Airport ID": "3346",
    "Latitude": "-30.789444",
    "Timezone": "1203",
    "DST": "8",
    "destinations": ["3351", "3341", "3339"]
}, {
    "City": "Sofia",
    "DBTZ": "E",
    "Name": "Sofia",
    "Country": "Bulgaria",
    "IATA/FAA": "SOF",
    "Longitude": "23.406167",
    "ICAO": "LBSF",
    "Airport ID": "1194",
    "Latitude": "42.695194",
    "Timezone": "1742",
    "DST": "2",
    "destinations": ["3941", "507", "351", "1382", "1555", "1524", "1197", "340", "1230", "580", "1218", "1191", "302", "1229", "1657", "1198", "3998", "1587", "2985", "1590", "1196", "1613", "679", "1678", "1739", "346", "1701", "502", "478", "548", "337", "1525", "1538", "1367", "304", "373", "8076", "585", "355", "492", "1246"]
}, {
    "City": "Larnaca",
    "DBTZ": "E",
    "Name": "Larnaca",
    "Country": "Cyprus",
    "IATA/FAA": "LCA",
    "Longitude": "33.62485",
    "ICAO": "LCLK",
    "Airport ID": "1197",
    "Latitude": "34.875117",
    "Timezone": "8",
    "DST": "2",
    "destinations": ["1678", "1735", "3941", "1486", "507", "1613", "340", "1382", "2954", "502", "3953", "580", "2179", "2177", "1452", "346", "1657", "1194", "2985", "1590", "345", "352", "337", "737", "644", "2188", "1606", "1128", "2057", "1739", "679", "517", "478", "2940", "2939", "2945", "2947", "2241", "2960", "2170", "4029", "2948", "488", "523", "534", "4053", "491", "1524", "2975", "1489", "2944", "469", "492"]
}, {
    "City": "Varna",
    "DBTZ": "E",
    "Name": "Varna",
    "Country": "Bulgaria",
    "IATA/FAA": "VAR",
    "Longitude": "27.825106",
    "ICAO": "LBWN",
    "Airport ID": "1196",
    "Latitude": "43.232072",
    "Timezone": "230",
    "DST": "2",
    "destinations": ["1191", "1194", "2985", "302", "1739", "629", "1613", "4029", "492"]
}, {
    "City": "La Romana",
    "DBTZ": "U",
    "Name": "Casa De Campo Intl",
    "Country": "Dominican Republic",
    "IATA/FAA": "LRM",
    "Longitude": "-68.911833",
    "ICAO": "MDLR",
    "Airport ID": "1759",
    "Latitude": "18.450711",
    "Timezone": "240",
    "DST": "-4",
    "destinations": ["2895", "3576", "3797", "2890", "1555", "1524", "73", "1229"]
}, {
    "City": "South Caicos",
    "DBTZ": "U",
    "Name": "South Caicos",
    "Country": "Turks and Caicos Islands",
    "IATA/FAA": "XSC",
    "Longitude": "-71.528528",
    "ICAO": "MBSC",
    "Airport ID": "1755",
    "Latitude": "21.515739",
    "Timezone": "6",
    "DST": "-5",
    "destinations": ["5807", "1754"]
}, {
    "City": "Providenciales",
    "DBTZ": "U",
    "Name": "Providenciales",
    "Country": "Turks and Caicos Islands",
    "IATA/FAA": "PLS",
    "Longitude": "-72.265886",
    "ICAO": "MBPV",
    "Airport ID": "1754",
    "Latitude": "21.773625",
    "Timezone": "15",
    "DST": "-5",
    "destinations": ["5807", "3876", "3670", "3576", "3752", "146", "193", "3448", "3797", "1953", "3682", "1895", "1779", "1897", "1761", "1762", "2890", "1764", "1755", "3494"]
}, {
    "City": "Dar Es Salaam",
    "DBTZ": "U",
    "Name": "Mwalimu Julius K Nyerere Intl",
    "Country": "Tanzania",
    "IATA/FAA": "DAR",
    "Longitude": "39.202625",
    "ICAO": "HTDA",
    "Airport ID": "1177",
    "Latitude": "-6.878111",
    "Timezone": "182",
    "DST": "3",
    "destinations": ["580", "2188", "1107", "1016", "1186", "4059", "1128", "1005", "1176", "1187", "912", "1180", "1183", "1182", "2241", "813", "1701", "985", "1165", "2194", "915"]
}, {
    "City": "Arusha",
    "DBTZ": "U",
    "Name": "Arusha",
    "Country": "Tanzania",
    "IATA/FAA": "ARK",
    "Longitude": "36.633333",
    "ICAO": "HTAR",
    "Airport ID": "1176",
    "Latitude": "-3.367794",
    "Timezone": "4550",
    "DST": "3",
    "destinations": ["1186"]
}, {
    "City": "Khartoum",
    "DBTZ": "U",
    "Name": "Khartoum",
    "Country": "Sudan",
    "IATA/FAA": "KRT",
    "Longitude": "32.553161",
    "ICAO": "HSSS",
    "Airport ID": "1175",
    "Latitude": "15.589497",
    "Timezone": "1265",
    "DST": "3",
    "destinations": ["2179", "2188", "1107", "8076", "2191", "2057", "3980", "6768", "1169", "5702", "5701", "4059", "1128", "2241", "2170", "2072", "1173", "999", "2082", "1701", "3967", "2074"]
}, {
    "City": "Juba",
    "DBTZ": "U",
    "Name": "Juba",
    "Country": "South Sudan",
    "IATA/FAA": "JUB",
    "Longitude": "31.601117",
    "ICAO": "HSSJ",
    "Airport ID": "1173",
    "Latitude": "4.872006",
    "Timezone": "1513",
    "DST": "3",
    "destinations": ["4059", "1107", "2188", "1128", "1175", "1187", "1165"]
}, {
    "City": "Vail",
    "DBTZ": "A",
    "Name": "Eagle Co Rgnl",
    "Country": "United States",
    "IATA/FAA": "EGE",
    "Longitude": "-106.917694",
    "ICAO": "KEGE",
    "Airport ID": "4250",
    "Latitude": "39.642556",
    "Timezone": "6540",
    "DST": "-7",
    "destinations": ["3751"]
}, {
    "City": "Stokmarknes",
    "DBTZ": "E",
    "Name": "Skagen",
    "Country": "Norway",
    "IATA/FAA": "SKN",
    "Longitude": "15.026111",
    "ICAO": "ENSK",
    "Airport ID": "4252",
    "Latitude": "68.580833",
    "Timezone": "11",
    "DST": "1",
    "destinations": ["631", "635", "5587", "663"]
}, {
    "City": "Columbus",
    "DBTZ": "A",
    "Name": "Columbus Metropolitan Airport",
    "Country": "United States",
    "IATA/FAA": "CSG",
    "Longitude": "-84.9388611",
    "ICAO": "KCSG",
    "Airport ID": "4255",
    "Latitude": "32.5163333",
    "Timezone": "397",
    "DST": "-5",
    "destinations": ["3682"]
}, {
    "City": "Scammon Bay",
    "DBTZ": "A",
    "Name": "Scammon Bay Airport",
    "Country": "United States",
    "IATA/FAA": "SCM",
    "Longitude": "-165.571389",
    "ICAO": "PACM",
    "Airport ID": "7209",
    "Latitude": "61.845278",
    "Timezone": "14",
    "DST": "-9",
    "destinations": ["3599"]
}, {
    "City": "Lawton",
    "DBTZ": "A",
    "Name": "Lawton-Fort Sill Regional Airport",
    "Country": "United States",
    "IATA/FAA": "LAW",
    "Longitude": "-98.4166367",
    "ICAO": "KLAW",
    "Airport ID": "4256",
    "Latitude": "34.5677144",
    "Timezone": "1110",
    "DST": "-6",
    "destinations": ["3670"]
}, {
    "City": "Webequie",
    "DBTZ": "A",
    "Name": "Webequie Airport",
    "Country": "Canada",
    "IATA/FAA": "YWP",
    "Longitude": "-87.3689",
    "ICAO": "CYWP",
    "Airport ID": "5533",
    "Latitude": "52.9597",
    "Timezone": "685",
    "DST": "-5",
    "destinations": ["5510", "105"]
}, {
    "City": "Deer Lake",
    "DBTZ": "A",
    "Name": "Deer Lake Airport",
    "Country": "Canada",
    "IATA/FAA": "YVZ",
    "Longitude": "-94.0614",
    "ICAO": "CYVZ",
    "Airport ID": "5532",
    "Latitude": "52.6558",
    "Timezone": "1092",
    "DST": "-6",
    "destinations": ["5548"]
}, {
    "City": "Big Trout Lake",
    "DBTZ": "A",
    "Name": "Big Trout Lake Airport",
    "Country": "Canada",
    "IATA/FAA": "YTL",
    "Longitude": "-89.8969",
    "ICAO": "CYTL",
    "Airport ID": "5531",
    "Latitude": "53.8178",
    "Timezone": "729",
    "DST": "-6",
    "destinations": ["5468", "5480", "5489", "169"]
}, {
    "City": "Koyukuk",
    "DBTZ": "A",
    "Name": "Koyukuk Airport",
    "Country": "United States",
    "IATA/FAA": "KYU",
    "Longitude": "-157.730556",
    "ICAO": "PFKU",
    "Airport ID": "7208",
    "Latitude": "64.875833",
    "Timezone": "149",
    "DST": "-9",
    "destinations": ["3764", "3832"]
}, {
    "City": "Salluit",
    "DBTZ": "A",
    "Name": "Salluit Airport",
    "Country": "Canada",
    "IATA/FAA": "YZG",
    "Longitude": "-75.6672",
    "ICAO": "CYZG",
    "Airport ID": "5535",
    "Latitude": "62.1794",
    "Timezone": "743",
    "DST": "-5",
    "destinations": ["5504", "7253"]
}, {
    "City": "Whale Cove",
    "DBTZ": "A",
    "Name": "Whale Cove Airport",
    "Country": "Canada",
    "IATA/FAA": "YXN",
    "Longitude": "-92.5981",
    "ICAO": "CYXN",
    "Airport ID": "5534",
    "Latitude": "62.24",
    "Timezone": "40",
    "DST": "-6",
    "destinations": ["132", "50"]
}, {
    "City": "Eastmain River",
    "DBTZ": "A",
    "Name": "Eastmain River Airport",
    "Country": "Canada",
    "IATA/FAA": "ZEM",
    "Longitude": "-78.5225",
    "ICAO": "CZEM",
    "Airport ID": "5539",
    "Latitude": "52.2264",
    "Timezone": "24",
    "DST": "-5",
    "destinations": ["5507", "5516"]
}, {
    "City": "Bathurst",
    "DBTZ": "A",
    "Name": "Bathurst Airport",
    "Country": "Canada",
    "IATA/FAA": "ZBF",
    "Longitude": "-65.7389",
    "ICAO": "CZBF",
    "Airport ID": "5538",
    "Latitude": "47.6297",
    "Timezone": "193",
    "DST": "-4",
    "destinations": ["146"]
}, {
    "City": "Castres",
    "DBTZ": "E",
    "Name": "Mazamet",
    "Country": "France",
    "IATA/FAA": "DCM",
    "Longitude": "2.289183",
    "ICAO": "LFCK",
    "Airport ID": "1285",
    "Latitude": "43.55625",
    "Timezone": "788",
    "DST": "1",
    "destinations": ["1262", "1324", "1386"]
}, {
    "City": "Biarritz-bayonne",
    "DBTZ": "E",
    "Name": "Anglet",
    "Country": "France",
    "IATA/FAA": "BIQ",
    "Longitude": "-1.523325",
    "ICAO": "LFBZ",
    "Airport ID": "1280",
    "Latitude": "43.468419",
    "Timezone": "245",
    "DST": "1",
    "destinations": ["1665", "1399", "1335", "1354", "1353", "1386", "304", "599", "699", "548", "609", "1382", "1435"]
}, {
    "City": "Rodez",
    "DBTZ": "E",
    "Name": "Marcillac",
    "Country": "France",
    "IATA/FAA": "RDZ",
    "Longitude": "2.482672",
    "ICAO": "LFCR",
    "Airport ID": "1289",
    "Latitude": "44.407869",
    "Timezone": "1910",
    "DST": "1",
    "destinations": ["1386", "304", "599", "548"]
}, {
    "City": "White Mountain",
    "DBTZ": "A",
    "Name": "White Mountain Airport",
    "Country": "United States",
    "IATA/FAA": "WMO",
    "Longitude": "-163.412778",
    "ICAO": "PAWM",
    "Airport ID": "7188",
    "Latitude": "64.689167",
    "Timezone": "267",
    "DST": "-9",
    "destinations": ["7185", "3615"]
}, {
    "City": "Karlovy Vary",
    "DBTZ": "E",
    "Name": "Karlovy Vary",
    "Country": "Czech Republic",
    "IATA/FAA": "KLV",
    "Longitude": "12.914983",
    "ICAO": "LKKV",
    "Airport ID": "1579",
    "Latitude": "50.202978",
    "Timezone": "1989",
    "DST": "1",
    "destinations": ["2948", "2985", "2975"]
}, {
    "City": "Joenkoeping",
    "DBTZ": "E",
    "Name": "Jonkoping",
    "Country": "Sweden",
    "IATA/FAA": "JKG",
    "Longitude": "14.068731",
    "ICAO": "ESGJ",
    "Airport ID": "688",
    "Latitude": "57.757594",
    "Timezone": "741",
    "DST": "1",
    "destinations": ["737", "709"]
}, {
    "City": "Kobuk",
    "DBTZ": "A",
    "Name": "Kobuk Airport",
    "Country": "United States",
    "IATA/FAA": "OBU",
    "Longitude": "-156.897222",
    "ICAO": "PAOB",
    "Airport ID": "7180",
    "Latitude": "66.912222",
    "Timezone": "137",
    "DST": "-9",
    "destinations": ["7177", "3693"]
}, {
    "City": "Noorvik",
    "DBTZ": "A",
    "Name": "Robert Curtis Memorial Airport",
    "Country": "United States",
    "IATA/FAA": "ORV",
    "Longitude": "-161.022222",
    "ICAO": "PFNO",
    "Airport ID": "7181",
    "Latitude": "66.8175",
    "Timezone": "55",
    "DST": "-9",
    "destinations": ["7179", "3693"]
}, {
    "City": "Selawik",
    "DBTZ": "A",
    "Name": "Selawik Airport",
    "Country": "United States",
    "IATA/FAA": "WLK",
    "Longitude": "-159.985833",
    "ICAO": "PASK",
    "Airport ID": "7182",
    "Latitude": "66.6",
    "Timezone": "17",
    "DST": "-9",
    "destinations": ["3693"]
}, {
    "City": "Brevig Mission",
    "DBTZ": "A",
    "Name": "Brevig Mission Airport",
    "Country": "United States",
    "IATA/FAA": "KTS",
    "Longitude": "-166.465833",
    "ICAO": "PFKT",
    "Airport ID": "7183",
    "Latitude": "65.331389",
    "Timezone": "35",
    "DST": "-9",
    "destinations": ["7186", "7187", "3615"]
}, {
    "City": "Elim",
    "DBTZ": "A",
    "Name": "Elim Airport",
    "Country": "United States",
    "IATA/FAA": "ELI",
    "Longitude": "-162.270556",
    "ICAO": "PFEL",
    "Airport ID": "7184",
    "Latitude": "64.615",
    "Timezone": "162",
    "DST": "-9",
    "destinations": ["7190", "3615", "7185"]
}, {
    "City": "Golovin",
    "DBTZ": "A",
    "Name": "Golovin Airport",
    "Country": "United States",
    "IATA/FAA": "GLV",
    "Longitude": "-163.007222",
    "ICAO": "PAGL",
    "Airport ID": "7185",
    "Latitude": "64.550556",
    "Timezone": "59",
    "DST": "-9",
    "destinations": ["3615", "7188", "7184"]
}, {
    "City": "Teller",
    "DBTZ": "A",
    "Name": "Teller Airport",
    "Country": "United States",
    "IATA/FAA": "TLA",
    "Longitude": "-166.339444",
    "ICAO": "PATE",
    "Airport ID": "7186",
    "Latitude": "65.240278",
    "Timezone": "294",
    "DST": "-9",
    "destinations": ["7183", "3615"]
}, {
    "City": "Wales",
    "DBTZ": "A",
    "Name": "Wales Airport",
    "Country": "United States",
    "IATA/FAA": "WAA",
    "Longitude": "-168.095",
    "ICAO": "PAIW",
    "Airport ID": "7187",
    "Latitude": "65.6225",
    "Timezone": "22",
    "DST": "-9",
    "destinations": ["7183", "3615", "6723"]
}, {
    "City": "Muan",
    "DBTZ": "U",
    "Name": "Muan",
    "Country": "South Korea",
    "IATA/FAA": "MWX",
    "Longitude": "126.382778",
    "ICAO": "RKJB",
    "Airport ID": "4189",
    "Latitude": "34.991389",
    "Timezone": "51",
    "DST": "9",
    "destinations": ["3364", "3368", "3406", "3121", "2370"]
}, {
    "City": "Dhangarhi",
    "DBTZ": "N",
    "Name": "Dhangarhi",
    "Country": "Nepal",
    "IATA/FAA": "DHI",
    "Longitude": "80.581944",
    "ICAO": "VNDH",
    "Airport ID": "4188",
    "Latitude": "28.753333",
    "Timezone": "690",
    "DST": "5.75",
    "destinations": ["3125"]
}, {
    "City": "Kotlik",
    "DBTZ": "A",
    "Name": "Kotlik Airport",
    "Country": "United States",
    "IATA/FAA": "KOT",
    "Longitude": "-163.532778",
    "ICAO": "PFKO",
    "Airport ID": "7207",
    "Latitude": "63.030556",
    "Timezone": "15",
    "DST": "-9",
    "destinations": ["7087", "6718", "7193"]
}, {
    "City": "Al-Ula",
    "DBTZ": "N",
    "Name": "Prince Abdul Majeed Airport",
    "Country": "Saudi Arabia",
    "IATA/FAA": "ULH",
    "Longitude": "37.908018",
    "ICAO": "OEAO",
    "Airport ID": "8381",
    "Latitude": "26.636718",
    "Timezone": "2047",
    "DST": "3",
    "destinations": ["2074", "2082"]
}, {
    "City": "Quinhagak",
    "DBTZ": "A",
    "Name": "Quinhagak Airport",
    "Country": "United States",
    "IATA/FAA": "KWN",
    "Longitude": "-161.845278",
    "ICAO": "PAQH",
    "Airport ID": "7206",
    "Latitude": "59.755",
    "Timezone": "42",
    "DST": "-9",
    "destinations": ["3599", "7093", "6767"]
}, {
    "City": "Raivavae",
    "DBTZ": "N",
    "Name": "Raivavae Airport",
    "Country": "French Polynesia",
    "IATA/FAA": "RVV",
    "Longitude": "-147.67",
    "ICAO": "NTAV",
    "Airport ID": "7456",
    "Latitude": "-23.87",
    "Timezone": "25",
    "DST": "-10",
    "destinations": ["4075", "1972"]
}, {
    "City": "Foshan",
    "DBTZ": "N",
    "Name": "Foshan",
    "Country": "China",
    "IATA/FAA": "FUO",
    "Longitude": "113.28333",
    "ICAO": "",
    "Airport ID": "7457",
    "Latitude": "23.133333",
    "Timezone": "8",
    "DST": "8",
    "destinations": ["6341"]
}, {
    "City": "Forde",
    "DBTZ": "E",
    "Name": "Forde Bringeland",
    "Country": "Norway",
    "IATA/FAA": "FDE",
    "Longitude": "5.763",
    "ICAO": "",
    "Airport ID": "4389",
    "Latitude": "61.392",
    "Timezone": "1034",
    "DST": "1",
    "destinations": ["636", "644"]
}, {
    "City": "Dubuque IA",
    "DBTZ": "A",
    "Name": "Dubuque Rgnl",
    "Country": "United States",
    "IATA/FAA": "DBQ",
    "Longitude": "-90.709472",
    "ICAO": "KDBQ",
    "Airport ID": "4388",
    "Latitude": "42.402",
    "Timezone": "1076",
    "DST": "-6",
    "destinations": ["3830"]
}, {
    "City": "Hatay",
    "DBTZ": "U",
    "Name": "Hatay Airport",
    "Country": "Turkey",
    "IATA/FAA": "HTY",
    "Longitude": "36.282222",
    "ICAO": "LTDA",
    "Airport ID": "7453",
    "Latitude": "36.362778",
    "Timezone": "25",
    "DST": "2",
    "destinations": ["1706", "5780", "4317", "1682", "1701", "2072", "2074", "2082"]
}, {
    "City": "Pakuba",
    "DBTZ": "U",
    "Name": "Pakuba Airport",
    "Country": "Uganda",
    "IATA/FAA": "PAF",
    "Longitude": "31.5",
    "ICAO": "HUPA",
    "Airport ID": "7450",
    "Latitude": "2.3275",
    "Timezone": "2297",
    "DST": "3",
    "destinations": ["1187"]
}, {
    "City": "Niigata",
    "DBTZ": "U",
    "Name": "Niigata",
    "Country": "Japan",
    "IATA/FAA": "KIJ",
    "Longitude": "139.0646",
    "ICAO": "RJSN",
    "Airport ID": "4381",
    "Latitude": "37.5711",
    "Timezone": "1",
    "DST": "9",
    "destinations": ["3400", "2287", "2305", "5997", "3930", "2334", "3406", "3942", "2279", "2384"]
}, {
    "City": "Changchun",
    "DBTZ": "U",
    "Name": "Changchun",
    "Country": "China",
    "IATA/FAA": "CGQ",
    "Longitude": "125.1201",
    "ICAO": "ZYCC",
    "Airport ID": "4380",
    "Latitude": "43.5412",
    "Timezone": "227",
    "DST": "8",
    "destinations": ["3375", "3388", "4108", "3368", "3406", "3370", "3395", "4085", "3364", "3374", "3390", "3376", "3392", "2370", "3393", "6345", "3389", "3386", "3930", "3384", "8826", "3387", "2279", "2276", "3369", "6414", "3385", "6347", "3379"]
}, {
    "City": "Timaru",
    "DBTZ": "Z",
    "Name": "Timaru",
    "Country": "New Zealand",
    "IATA/FAA": "TIU",
    "Longitude": "171.225278",
    "ICAO": "NZTU",
    "Airport ID": "2035",
    "Latitude": "-44.302778",
    "Timezone": "89",
    "DST": "12",
    "destinations": ["2042"]
}, {
    "City": "Austin TX",
    "DBTZ": "A",
    "Name": "Camp Mabry Austin City",
    "Country": "United States",
    "IATA/FAA": "ATT",
    "Longitude": "-97.7666",
    "ICAO": "KATT",
    "Airport ID": "4385",
    "Latitude": "30.31666",
    "Timezone": "0",
    "DST": "-6",
    "destinations": ["7094", "3599"]
}, {
    "City": "Lleida",
    "DBTZ": "E",
    "Name": "Lleida-Alguaire Airport",
    "Country": "Spain",
    "IATA/FAA": "ILD",
    "Longitude": "0.535833",
    "ICAO": "LEDA",
    "Airport ID": "7459",
    "Latitude": "41.727778",
    "Timezone": "1148",
    "DST": "1",
    "destinations": ["3998"]
}, {
    "City": "Le Havre",
    "DBTZ": "E",
    "Name": "Octeville",
    "Country": "France",
    "IATA/FAA": "LEH",
    "Longitude": "0.088056",
    "ICAO": "LFOH",
    "Airport ID": "1371",
    "Latitude": "49.533889",
    "Timezone": "312",
    "DST": "1",
    "destinations": ["1335"]
}, {
    "City": "Lephalale",
    "DBTZ": "U",
    "Name": "Ellisras",
    "Country": "South Africa",
    "IATA/FAA": "ELL",
    "Longitude": "27.75",
    "ICAO": "FAEA",
    "Airport ID": "8492",
    "Latitude": "-23.666667",
    "Timezone": "2700",
    "DST": "2",
    "destinations": ["813"]
}, {
    "City": "Pasto",
    "DBTZ": "U",
    "Name": "Antonio Narino",
    "Country": "Colombia",
    "IATA/FAA": "PSO",
    "Longitude": "-77.291478",
    "ICAO": "SKPS",
    "Airport ID": "2742",
    "Latitude": "1.396247",
    "Timezone": "5951",
    "DST": "-5",
    "destinations": ["2709", "2715"]
}, {
    "City": "Chalons",
    "DBTZ": "E",
    "Name": "Vatry",
    "Country": "France",
    "IATA/FAA": "XCR",
    "Longitude": "4.184492",
    "ICAO": "LFOK",
    "Airport ID": "1374",
    "Latitude": "48.776072",
    "Timezone": "587",
    "DST": "1",
    "destinations": ["1636", "1075"]
}, {
    "City": "Tours",
    "DBTZ": "E",
    "Name": "Val De Loire",
    "Country": "France",
    "IATA/FAA": "TUF",
    "Longitude": "0.727606",
    "ICAO": "LFOT",
    "Airport ID": "1376",
    "Latitude": "47.432222",
    "Timezone": "357",
    "DST": "1",
    "destinations": ["599", "1353", "1636", "1075", "548"]
}, {
    "City": "Badajoz",
    "DBTZ": "E",
    "Name": "Talavera La Real",
    "Country": "Spain",
    "IATA/FAA": "BJZ",
    "Longitude": "-6.821333",
    "ICAO": "LEBZ",
    "Airport ID": "1219",
    "Latitude": "38.89125",
    "Timezone": "609",
    "DST": "1",
    "destinations": ["1218", "1229"]
}, {
    "City": "Tumling Tar",
    "DBTZ": "N",
    "Name": "Tumling Tar",
    "Country": "Nepal",
    "IATA/FAA": "TMI",
    "Longitude": "87.193333",
    "ICAO": "VNTR",
    "Airport ID": "4183",
    "Latitude": "27.315",
    "Timezone": "1700",
    "DST": "5.75",
    "destinations": ["3125"]
}, {
    "City": "New York",
    "DBTZ": "A",
    "Name": "John F Kennedy Intl",
    "Country": "United States",
    "IATA/FAA": "JFK",
    "Longitude": "-73.778925",
    "ICAO": "KJFK",
    "Airport ID": "3797",
    "Latitude": "40.639751",
    "Timezone": "13",
    "DST": "-5",
    "destinations": ["3988", "1824", "3861", "2170", "2874", "2179", "3673", "1218", "4069", "3690", "3448", "3849", "2851", "1382", "3486", "3876", "3759", "1852", "3488", "3520", "3670", "2241", "599", "345", "1555", "2560", "2564", "2673", "421", "3077", "3585", "3877", "3484", "503", "507", "2789", "1229", "478", "3878", "3576", "1524", "2279", "3830", "3611", "1386", "1897", "3462", "3570", "3626", "3731", "2650", "3577", "3469", "1885", "2890", "2883", "2899", "3646", "351", "146", "178", "193", "1678", "3682", "302", "340", "1354", "3093", "1074", "2709", "2745", "1892", "1772", "1551", "4019", "2895", "5810", "2875", "2885", "3711", "3820", "3644", "3806", "2714", "3751", "2188", "3533", "1926", "3566", "3714", "3712", "813", "1779", "3582", "1881", "1759", "1780", "1953", "3453", "3722", "3720", "1754", "1761", "2902", "2889", "1760", "3862", "3622", "3793", "3613", "1762", "3748", "3536", "3817", "4067", "1764", "3745", "2894", "2276", "4304", "2882", "2901", "3364", "3992", "1871", "156", "248", "580", "1084", "3645", "1767", "3930", "3858", "3752", "3406", "3608", "3621", "4014", "3678", "2985", "1590", "737", "636", "609", "644", "603", "16", "3728", "3953", "2176", "346", "679", "1665", "1128", "1613", "2207", "2939", "2072", "2082", "1701", "2988", "273"]
}, {
    "City": "Chicago",
    "DBTZ": "A",
    "Name": "Chicago Ohare Intl",
    "Country": "United States",
    "IATA/FAA": "ORD",
    "Longitude": "-87.904842",
    "ICAO": "KORD",
    "Airport ID": "3830",
    "Latitude": "41.978603",
    "Timezone": "668",
    "DST": "-6",
    "destinations": ["5726", "4042", "4019", "5718", "2170", "3838", "3682", "2179", "3673", "4039", "3825", "4037", "3690", "3448", "3820", "3849", "1382", "3578", "4015", "4043", "3486", "3876", "3759", "4049", "3719", "1852", "3488", "4045", "3627", "4388", "3520", "3751", "3670", "2241", "3729", "3645", "599", "345", "3559", "4018", "3494", "4292", "1555", "3533", "4089", "4009", "4041", "3653", "3685", "3077", "3589", "4113", "3550", "3457", "3585", "3712", "3797", "3877", "3484", "4017", "3697", "507", "3660", "4044", "1229", "478", "1780", "3458", "3878", "3542", "3473", "1824", "4050", "3576", "3717", "4072", "6838", "3459", "3858", "3861", "2279", "3863", "3454", "3722", "3720", "3364", "3752", "3462", "4046", "3570", "3839", "3406", "1836", "3626", "3608", "3807", "3622", "4048", "3793", "3731", "3621", "4014", "3577", "3469", "4348", "3748", "1840", "2890", "3536", "3867", "3678", "3753", "3745", "4040", "3646", "3855", "3636", "4023", "351", "3676", "4356", "79", "146", "156", "178", "193", "49", "73", "100", "111", "120", "160", "174", "3093", "1804", "3774", "1892", "1871", "580", "3930", "1760", "1926", "340", "346", "679", "1678", "3524", "3515", "3453", "1613", "737", "609", "302", "1701", "4355", "3864", "4047", "4007", "4286", "3811", "3495", "3711", "4020", "3561", "4112", "3806", "5732", "3819", "4285", "3598", "5735", "3946", "3843", "2564", "4008", "4034", "3728", "3714", "3580", "3544", "3543", "4128", "4011", "5754", "3782", "1825", "1953", "3456", "3611", "4117", "3564", "3641", "3862", "4087", "4016", "3613", "4359", "4318", "1885", "3817", "4336", "4067", "4271", "2883", "2899", "166"]
}, {
    "City": "Rotorua",
    "DBTZ": "Z",
    "Name": "Rotorua",
    "Country": "New Zealand",
    "IATA/FAA": "ROT",
    "Longitude": "176.317222",
    "ICAO": "NZRO",
    "Airport ID": "2031",
    "Latitude": "-38.109167",
    "Timezone": "935",
    "DST": "12",
    "destinations": ["2006", "2009", "2042"]
}, {
    "City": "Fairbanks",
    "DBTZ": "A",
    "Name": "Fairbanks Intl",
    "Country": "United States",
    "IATA/FAA": "FAI",
    "Longitude": "-147.856267",
    "ICAO": "PAFA",
    "Airport ID": "3832",
    "Latitude": "64.815114",
    "Timezone": "434",
    "DST": "-9",
    "destinations": ["3417", "7116", "7105", "3420", "7246", "7112", "7113", "7115", "3774", "3764", "6722", "3620", "6839", "6712", "7118", "3577", "7242"]
}, {
    "City": "San Sebastian",
    "DBTZ": "E",
    "Name": "San Sebastian",
    "Country": "Spain",
    "IATA/FAA": "EAS",
    "Longitude": "-1.790611",
    "ICAO": "LESO",
    "Airport ID": "1242",
    "Latitude": "43.356519",
    "Timezone": "15",
    "DST": "1",
    "destinations": ["1218", "1229"]
}, {
    "City": "Queenstown International",
    "DBTZ": "Z",
    "Name": "Queenstown",
    "Country": "New Zealand",
    "IATA/FAA": "ZQN",
    "Longitude": "168.739167",
    "ICAO": "NZQN",
    "Airport ID": "2030",
    "Latitude": "-45.021111",
    "Timezone": "1171",
    "DST": "12",
    "destinations": ["3361", "2006", "2009", "2042"]
}, {
    "City": "Watertown",
    "DBTZ": "A",
    "Name": "Watertown Intl",
    "Country": "United States",
    "IATA/FAA": "ART",
    "Longitude": "-76.021739",
    "ICAO": "KART",
    "Airport ID": "3838",
    "Latitude": "43.991922",
    "Timezone": "325",
    "DST": "-5",
    "destinations": ["3830", "3752"]
}, {
    "City": "Palm Springs",
    "DBTZ": "A",
    "Name": "Palm Springs Intl",
    "Country": "United States",
    "IATA/FAA": "PSP",
    "Longitude": "-116.506694",
    "ICAO": "KPSP",
    "Airport ID": "3839",
    "Latitude": "33.829667",
    "Timezone": "477",
    "DST": "-8",
    "destinations": ["3670", "3830", "3462", "3469", "3720", "3577", "3817", "3858", "3536", "3751", "3777", "3550", "3877", "3484", "49", "156", "178"]
}, {
    "City": "Kushiro",
    "DBTZ": "U",
    "Name": "Kushiro Airport",
    "Country": "Japan",
    "IATA/FAA": "KUH",
    "Longitude": "144.193",
    "ICAO": "RJCK",
    "Airport ID": "5994",
    "Latitude": "43.041",
    "Timezone": "327",
    "DST": "9",
    "destinations": ["2276", "2359", "2287"]
}, {
    "City": "Nagoya",
    "DBTZ": "U",
    "Name": "Nagoya Airport",
    "Country": "Japan",
    "IATA/FAA": "NKM",
    "Longitude": "136.924",
    "ICAO": "RJNA",
    "Airport ID": "5997",
    "Latitude": "35.255",
    "Timezone": "52",
    "DST": "9",
    "destinations": ["2340", "2341", "2344", "2332", "4381", "2312"]
}, {
    "City": "Saga",
    "DBTZ": "N",
    "Name": "Saga Airport",
    "Country": "Japan",
    "IATA/FAA": "HSG",
    "Longitude": "130.302",
    "ICAO": "RJFS",
    "Airport ID": "5996",
    "Latitude": "33.1497",
    "Timezone": "6",
    "DST": "9",
    "destinations": ["3406", "2359", "3930"]
}, {
    "City": "Greenville",
    "DBTZ": "A",
    "Name": "Greenville-Spartanburg International",
    "Country": "United States",
    "IATA/FAA": "GSP",
    "Longitude": "-82.218889",
    "ICAO": "KGSP",
    "Airport ID": "4034",
    "Latitude": "34.895556",
    "Timezone": "964",
    "DST": "-5",
    "destinations": ["3682", "3876", "3520", "3670", "3752", "3645", "3697", "3747", "3533", "7056", "3617", "4167", "3486", "3494", "3714", "3550", "3830", "3690", "3849", "3566"]
}, {
    "City": "Bloomington",
    "DBTZ": "A",
    "Name": "Central Illinois Rgnl",
    "Country": "United States",
    "IATA/FAA": "BMI",
    "Longitude": "-88.915917",
    "ICAO": "KBMI",
    "Airport ID": "4037",
    "Latitude": "40.477111",
    "Timezone": "871",
    "DST": "-6",
    "destinations": ["3670", "3830", "3682", "3645", "3858", "3751", "3878", "4167"]
}, {
    "City": "Kalamazoo",
    "DBTZ": "A",
    "Name": "Kalamazoo",
    "Country": "United States",
    "IATA/FAA": "AZO",
    "Longitude": "-85.552058",
    "ICAO": "KAZO",
    "Airport ID": "4039",
    "Latitude": "42.234875",
    "Timezone": "874",
    "DST": "-5",
    "destinations": ["3682", "3830", "3645", "3858"]
}, {
    "City": "Gulfport",
    "DBTZ": "A",
    "Name": "Gulfport-Biloxi",
    "Country": "United States",
    "IATA/FAA": "GPT",
    "Longitude": "-89.070111",
    "ICAO": "KGPT",
    "Airport ID": "4038",
    "Latitude": "30.407278",
    "Timezone": "28",
    "DST": "-6",
    "destinations": ["3876", "3670", "3682", "3550"]
}, {
    "City": "Fukushima",
    "DBTZ": "U",
    "Name": "Fukushima Airport",
    "Country": "Japan",
    "IATA/FAA": "FKS",
    "Longitude": "140.431",
    "ICAO": "RJSF",
    "Airport ID": "5999",
    "Latitude": "37.2274",
    "Timezone": "1221",
    "DST": "9",
    "destinations": ["2334", "2287"]
}, {
    "City": "Iwami",
    "DBTZ": "U",
    "Name": "Iwami Airport",
    "Country": "Japan",
    "IATA/FAA": "IWJ",
    "Longitude": "131.79",
    "ICAO": "RJOW",
    "Airport ID": "5998",
    "Latitude": "34.6764",
    "Timezone": "184",
    "DST": "9",
    "destinations": ["2359"]
}, {
    "City": "Lincang",
    "DBTZ": "U",
    "Name": "Lincang Airport",
    "Country": "China",
    "IATA/FAA": "LNJ",
    "Longitude": "100.025",
    "ICAO": "ZPLC",
    "Airport ID": "7527",
    "Latitude": "23.738333",
    "Timezone": "1500",
    "DST": "8",
    "destinations": ["3382"]
}, {
    "City": "Paderborn",
    "DBTZ": "E",
    "Name": "Paderborn Lippstadt",
    "Country": "Germany",
    "IATA/FAA": "PAD",
    "Longitude": "8.616317",
    "ICAO": "EDLP",
    "Airport ID": "371",
    "Latitude": "51.614089",
    "Timezone": "699",
    "DST": "1",
    "destinations": ["1688", "1225", "3998", "1055", "1051", "1054", "1056", "346"]
}, {
    "City": "Dortmund",
    "DBTZ": "E",
    "Name": "Dortmund",
    "Country": "Germany",
    "IATA/FAA": "DTM",
    "Longitude": "7.612242",
    "ICAO": "EDLW",
    "Airport ID": "373",
    "Latitude": "51.518314",
    "Timezone": "425",
    "DST": "1",
    "destinations": ["5557", "346", "3998", "4317", "1206", "1230", "1517", "1626", "1222", "669", "1636", "548", "1218", "7821", "492", "1739", "1489", "1652", "668", "671", "1657", "1737", "1194", "1663", "3959", "2944", "2945", "1706"]
}, {
    "City": "Augsburg",
    "DBTZ": "E",
    "Name": "Augsburg",
    "Country": "Germany",
    "IATA/FAA": "AGB",
    "Longitude": "10.931764",
    "ICAO": "EDMA",
    "Airport ID": "374",
    "Latitude": "48.425158",
    "Timezone": "1515",
    "DST": "1",
    "destinations": ["1353"]
}, {
    "City": "Kitale",
    "DBTZ": "U",
    "Name": "Kitale",
    "Country": "Kenya",
    "IATA/FAA": "KTL",
    "Longitude": "34.958556",
    "ICAO": "HKKT",
    "Airport ID": "1141",
    "Latitude": "0.971989",
    "Timezone": "6070",
    "DST": "3",
    "destinations": ["1147"]
}, {
    "City": "Belem",
    "DBTZ": "S",
    "Name": "Val De Cans Intl",
    "Country": "Brazil",
    "IATA/FAA": "BEL",
    "Longitude": "-48.476292",
    "ICAO": "SBBE",
    "Airport ID": "2526",
    "Latitude": "-1.37925",
    "Timezone": "54",
    "DST": "-4",
    "destinations": ["2566", "7532", "2537", "2559", "2572", "2584", "2551", "2591", "4092", "2531", "2560", "2564", "2616", "3576", "7370", "2774", "2773"]
}, {
    "City": "Narvik",
    "DBTZ": "E",
    "Name": "Framnes",
    "Country": "Norway",
    "IATA/FAA": "NVK",
    "Longitude": "17.388056",
    "ICAO": "ENNK",
    "Airport ID": "4349",
    "Latitude": "68.435833",
    "Timezone": "97",
    "DST": "1",
    "destinations": ["635"]
}, {
    "City": "Nadym",
    "DBTZ": "N",
    "Name": "Nadym Airport",
    "Country": "Russia",
    "IATA/FAA": "NYM",
    "Longitude": "72.6989",
    "ICAO": "USMM",
    "Airport ID": "6143",
    "Latitude": "65.4809",
    "Timezone": "49",
    "DST": "6",
    "destinations": ["6140", "2988", "4029", "6137", "2975", "4111", "2992"]
}, {
    "City": "Kirov",
    "DBTZ": "N",
    "Name": "Pobedilovo Airport",
    "Country": "Russia",
    "IATA/FAA": "KVX",
    "Longitude": "49.3483",
    "ICAO": "USKK",
    "Airport ID": "6142",
    "Latitude": "58.5033",
    "Timezone": "479",
    "DST": "4",
    "destinations": ["2948", "4371", "2988"]
}, {
    "City": "Izhevsk",
    "DBTZ": "N",
    "Name": "Izhevsk Airport",
    "Country": "Russia",
    "IATA/FAA": "IJK",
    "Longitude": "53.4575",
    "ICAO": "USII",
    "Airport ID": "6141",
    "Latitude": "56.8281",
    "Timezone": "531",
    "DST": "4",
    "destinations": ["4029", "2948", "2975"]
}, {
    "City": "Sovetskiy",
    "DBTZ": "N",
    "Name": "Sovetsky Tyumenskaya Airport",
    "Country": "Russia",
    "IATA/FAA": "OVS",
    "Longitude": "63.6044",
    "ICAO": "USHS",
    "Airport ID": "6140",
    "Latitude": "61.32",
    "Timezone": "351",
    "DST": "6",
    "destinations": ["6143", "2975", "2988", "8428", "4111"]
}, {
    "City": "Khudzhand",
    "DBTZ": "U",
    "Name": "Khudzhand Airport",
    "Country": "Tajikistan",
    "IATA/FAA": "LBD",
    "Longitude": "69.6947",
    "ICAO": "UTDL",
    "Airport ID": "6147",
    "Latitude": "40.2154",
    "Timezone": "1450",
    "DST": "5",
    "destinations": ["4029", "2948", "4078", "2974", "3399", "2991", "2968", "2964", "2965", "6138", "4374", "2960", "2990", "2972", "2975", "4111", "4274", "4118", "2992"]
}, {
    "City": "Kurgan",
    "DBTZ": "N",
    "Name": "Kurgan Airport",
    "Country": "Russia",
    "IATA/FAA": "KRO",
    "Longitude": "65.4156",
    "ICAO": "USUU",
    "Airport ID": "6146",
    "Latitude": "55.4753",
    "Timezone": "240",
    "DST": "6",
    "destinations": ["2975", "4029"]
}, {
    "City": "Fergana",
    "DBTZ": "U",
    "Name": "Fergana Airport",
    "Country": "Uzbekistan",
    "IATA/FAA": "FEG",
    "Longitude": "71.745",
    "ICAO": "UTKF",
    "Airport ID": "6149",
    "Latitude": "40.3588",
    "Timezone": "1980",
    "DST": "5",
    "destinations": ["4029", "2990", "2948", "2983", "2960", "4078"]
}, {
    "City": "Andizhan",
    "DBTZ": "U",
    "Name": "Andizhan Airport",
    "Country": "Uzbekistan",
    "IATA/FAA": "AZN",
    "Longitude": "72.294",
    "ICAO": "UTKA",
    "Airport ID": "6148",
    "Latitude": "40.7277",
    "Timezone": "1515",
    "DST": "5",
    "destinations": ["4029", "2948", "2983"]
}, {
    "City": "Canakkale",
    "DBTZ": "E",
    "Name": "Canakkale Airport",
    "Country": "Turkey",
    "IATA/FAA": "CKZ",
    "Longitude": "26.426777",
    "ICAO": "LTBH",
    "Airport ID": "6781",
    "Latitude": "40.137722",
    "Timezone": "23",
    "DST": "2",
    "destinations": ["1682"]
}, {
    "City": "Miyazaki",
    "DBTZ": "U",
    "Name": "Miyazaki",
    "Country": "Japan",
    "IATA/FAA": "KMI",
    "Longitude": "131.448611",
    "ICAO": "RJFM",
    "Airport ID": "2308",
    "Latitude": "31.877222",
    "Timezone": "20",
    "DST": "9",
    "destinations": ["2276", "2305", "2359", "2334", "3930", "3942", "2384"]
}, {
    "City": "Sinop",
    "DBTZ": "E",
    "Name": "Sinop Airport",
    "Country": "Turkey",
    "IATA/FAA": "SIC",
    "Longitude": "35.0664",
    "ICAO": "LTCM",
    "Airport ID": "6783",
    "Latitude": "42.0158",
    "Timezone": "20",
    "DST": "2",
    "destinations": ["1869"]
}, {
    "City": "Mus",
    "DBTZ": "E",
    "Name": "Mus Airport",
    "Country": "Turkey",
    "IATA/FAA": "MSR",
    "Longitude": "41.661236",
    "ICAO": "LTCK",
    "Airport ID": "6782",
    "Latitude": "38.747769",
    "Timezone": "4157",
    "DST": "2",
    "destinations": ["4317", "1682", "1701"]
}, {
    "City": "Corlu",
    "DBTZ": "E",
    "Name": "Tekirdag Corlu Airport",
    "Country": "Turkey",
    "IATA/FAA": "TEQ",
    "Longitude": "27.919094",
    "ICAO": "LTBU",
    "Airport ID": "6784",
    "Latitude": "41.13825",
    "Timezone": "574",
    "DST": "2",
    "destinations": ["1682"]
}, {
    "City": "St-Augustin",
    "DBTZ": "A",
    "Name": "St Augustin Airport",
    "Country": "Canada",
    "IATA/FAA": "YIF",
    "Longitude": "-58.6583",
    "ICAO": "CYIF",
    "Airport ID": "6789",
    "Latitude": "51.2117",
    "Timezone": "20",
    "DST": "-4",
    "destinations": ["5485", "5503", "5474"]
}, {
    "City": "Vieques Island",
    "DBTZ": "U",
    "Name": "Vieques Airport",
    "Country": "Puerto Rico",
    "IATA/FAA": "VQS",
    "Longitude": "-65.4227",
    "ICAO": "TJCG",
    "Airport ID": "6788",
    "Latitude": "18.1158",
    "Timezone": "19",
    "DST": "-4",
    "destinations": ["2890", "2884"]
}, {
    "City": "Fukuoka",
    "DBTZ": "U",
    "Name": "Fukuoka",
    "Country": "Japan",
    "IATA/FAA": "FUK",
    "Longitude": "130.450686",
    "ICAO": "RJFF",
    "Airport ID": "2305",
    "Latitude": "33.585942",
    "Timezone": "32",
    "DST": "9",
    "destinations": ["3930", "2359", "2279", "2287", "2281", "2384", "2347", "2276", "2372", "3404", "3406", "3077", "3370", "4144", "3728", "2334", "2308", "2322", "3942", "3992", "6476", "4381", "2280", "3885", "3199", "2344", "3205", "3390", "580", "2370", "2304", "2246", "2387", "3316", "2297", "3121", "2397"]
}, {
    "City": "Fukue",
    "DBTZ": "U",
    "Name": "Fukue",
    "Country": "Japan",
    "IATA/FAA": "FUJ",
    "Longitude": "128.832808",
    "ICAO": "RJFE",
    "Airport ID": "2304",
    "Latitude": "32.666269",
    "Timezone": "273",
    "DST": "9",
    "destinations": ["2305", "2313"]
}, {
    "City": "Kagoshima",
    "DBTZ": "U",
    "Name": "Kagoshima",
    "Country": "Japan",
    "IATA/FAA": "KOJ",
    "Longitude": "130.719408",
    "ICAO": "RJFK",
    "Airport ID": "2307",
    "Latitude": "31.803397",
    "Timezone": "906",
    "DST": "9",
    "destinations": ["2359", "3943", "2276", "3942", "2279", "3077", "6476", "3930", "2334", "3406", "3992", "2384"]
}, {
    "City": "Puerto Jimenez",
    "DBTZ": "U",
    "Name": "Puerto Jimenez Airport",
    "Country": "Costa Rica",
    "IATA/FAA": "PJM",
    "Longitude": "-83.3",
    "ICAO": "MRPJ",
    "Airport ID": "5847",
    "Latitude": "8.53333",
    "Timezone": "7",
    "DST": "-6",
    "destinations": ["1885", "1879"]
}, {
    "City": "Nicoya",
    "DBTZ": "U",
    "Name": "Cabo Velas Airport",
    "Country": "Costa Rica",
    "IATA/FAA": "TNO",
    "Longitude": "-85.852892",
    "ICAO": "MRCV",
    "Airport ID": "5844",
    "Latitude": "10.3557",
    "Timezone": "33",
    "DST": "-6",
    "destinations": ["1881", "1885"]
}, {
    "City": "Roxana",
    "DBTZ": "U",
    "Name": "Aerotortuguero Airport",
    "Country": "Costa Rica",
    "IATA/FAA": "TTQ",
    "Longitude": "-83.5148",
    "ICAO": "MRAO",
    "Airport ID": "5842",
    "Latitude": "10.569",
    "Timezone": "82",
    "DST": "-6",
    "destinations": ["1885"]
}, {
    "City": "Hancock",
    "DBTZ": "A",
    "Name": "Houghton County Memorial Airport",
    "Country": "United States",
    "IATA/FAA": "CMX",
    "Longitude": "-88.4891",
    "ICAO": "KCMX",
    "Airport ID": "5732",
    "Latitude": "47.1684",
    "Timezone": "1095",
    "DST": "-5",
    "destinations": ["3830"]
}, {
    "City": "Jaque",
    "DBTZ": "U",
    "Name": "Jaque Airport",
    "Country": "Panama",
    "IATA/FAA": "JQE",
    "Longitude": "-78.1572",
    "ICAO": "MPJE",
    "Airport ID": "5840",
    "Latitude": "7.51778",
    "Timezone": "29",
    "DST": "-5",
    "destinations": ["1869"]
}, {
    "City": "San Jose",
    "DBTZ": "U",
    "Name": "Tobias Bolanos International Airport",
    "Country": "Costa Rica",
    "IATA/FAA": "SYQ",
    "Longitude": "-84.139797",
    "ICAO": "MRPV",
    "Airport ID": "5848",
    "Latitude": "9.957053",
    "Timezone": "3287",
    "DST": "-6",
    "destinations": ["1881"]
}, {
    "City": "Cayo Coco",
    "DBTZ": "U",
    "Name": "Cayo Coco Airport",
    "Country": "Cuba",
    "IATA/FAA": "CCC",
    "Longitude": "-78.511",
    "ICAO": "MUOC",
    "Airport ID": "5852",
    "Latitude": "22.5132",
    "Timezone": "6",
    "DST": "-5",
    "destinations": ["193", "1909", "1923", "73", "146"]
}, {
    "City": "San Jose",
    "DBTZ": "A",
    "Name": "Norman Y Mineta San Jose Intl",
    "Country": "United States",
    "IATA/FAA": "SJC",
    "Longitude": "-121.929022",
    "ICAO": "KSJC",
    "Airport ID": "3748",
    "Latitude": "37.3626",
    "Timezone": "62",
    "DST": "-8",
    "destinations": ["3670", "1804", "3728", "3514", "3484", "3602", "3456", "3830", "3720", "3462", "3577", "1840", "3495", "3807", "3448", "3797", "3682", "3858", "3536", "3751", "3877", "3731", "2279", "3550", "3673", "3644", "3747", "3734", "3867"]
}, {
    "City": "Hobbs",
    "DBTZ": "A",
    "Name": "Lea Co Rgnl",
    "Country": "United States",
    "IATA/FAA": "HOB",
    "Longitude": "-103.217028",
    "ICAO": "KHOB",
    "Airport ID": "3749",
    "Latitude": "32.687528",
    "Timezone": "3661",
    "DST": "-7",
    "destinations": ["3550"]
}, {
    "City": "Chicago",
    "DBTZ": "A",
    "Name": "Chicago Midway Intl",
    "Country": "United States",
    "IATA/FAA": "MDW",
    "Longitude": "-87.752417",
    "ICAO": "KMDW",
    "Airport ID": "3747",
    "Latitude": "41.785972",
    "Timezone": "620",
    "DST": "-6",
    "destinations": ["3682", "3645", "3858", "3751", "3859", "3447", "4019", "3864", "3673", "3825", "3811", "3690", "3448", "3820", "3849", "3806", "3486", "3876", "3759", "1852", "3729", "3494", "4034", "3566", "3714", "3457", "3580", "3877", "3484", "3697", "3660", "1780", "3458", "3878", "3473", "4011", "3861", "3453", "3863", "3454", "3734", "3611", "3720", "3752", "3462", "3570", "1760", "3641", "3626", "3622", "3793", "3731", "3621", "4014", "3577", "3469", "3748", "3536", "3817", "3678", "3646", "3855", "3636", "3681", "7054", "144", "6962", "3533", "3712", "3807", "1815", "1804", "1821", "1855"]
}, {
    "City": "Corpus Christi",
    "DBTZ": "A",
    "Name": "Corpus Christi Intl",
    "Country": "United States",
    "IATA/FAA": "CRP",
    "Longitude": "-97.501222",
    "ICAO": "KCRP",
    "Airport ID": "3744",
    "Latitude": "27.770361",
    "Timezone": "44",
    "DST": "-6",
    "destinations": ["3670", "3829", "3550", "3566"]
}, {
    "City": "Syracuse",
    "DBTZ": "A",
    "Name": "Syracuse Hancock Intl",
    "Country": "United States",
    "IATA/FAA": "SYR",
    "Longitude": "-76.106311",
    "ICAO": "KSYR",
    "Airport ID": "3745",
    "Latitude": "43.111187",
    "Timezone": "421",
    "DST": "-5",
    "destinations": ["3682", "3448", "3876", "3520", "3830", "3752", "193", "3797", "3878", "3645", "3697", "3858", "3617", "3486", "3494", "3714"]
}, {
    "City": "Farmington",
    "DBTZ": "A",
    "Name": "Four Corners Rgnl",
    "Country": "United States",
    "IATA/FAA": "FMN",
    "Longitude": "-108.229944",
    "ICAO": "KFMN",
    "Airport ID": "3743",
    "Latitude": "36.74125",
    "Timezone": "5506",
    "DST": "-7",
    "destinations": ["7073", "3751", "7078"]
}, {
    "City": "Cotonou",
    "DBTZ": "N",
    "Name": "Cadjehoun",
    "Country": "Benin",
    "IATA/FAA": "COO",
    "Longitude": "2.384353",
    "ICAO": "DBBB",
    "Airport ID": "245",
    "Latitude": "6.357228",
    "Timezone": "19",
    "DST": "1",
    "destinations": ["298", "246", "253", "1382", "273", "890", "891", "1044", "970", "1084", "897", "260", "1107", "280", "248", "4059", "883", "886"]
}, {
    "City": "Zarzaitine",
    "DBTZ": "N",
    "Name": "In Amenas",
    "Country": "Algeria",
    "IATA/FAA": "IAM",
    "Longitude": "9.642911",
    "ICAO": "DAUZ",
    "Airport ID": "244",
    "Latitude": "28.05155",
    "Timezone": "1847",
    "DST": "1",
    "destinations": ["238", "243"]
}, {
    "City": "Bobo-dioulasso",
    "DBTZ": "N",
    "Name": "Bobo Dioulasso",
    "Country": "Burkina Faso",
    "IATA/FAA": "BOY",
    "Longitude": "-4.330969",
    "ICAO": "DFOO",
    "Airport ID": "247",
    "Latitude": "11.160056",
    "Timezone": "1511",
    "DST": "0",
    "destinations": ["253", "246"]
}, {
    "City": "Ouagadougou",
    "DBTZ": "N",
    "Name": "Ouagadougou",
    "Country": "Burkina Faso",
    "IATA/FAA": "OUA",
    "Longitude": "-1.512417",
    "ICAO": "DFFD",
    "Airport ID": "246",
    "Latitude": "12.353194",
    "Timezone": "1037",
    "DST": "0",
    "destinations": ["253", "248", "1044", "247", "245", "1084", "298", "280", "302", "1382", "210", "1074", "1107", "1701", "287"]
}, {
    "City": "Touggourt",
    "DBTZ": "N",
    "Name": "Sidi Mahdi",
    "Country": "Algeria",
    "IATA/FAA": "TGR",
    "Longitude": "6.088672",
    "ICAO": "DAUK",
    "Airport ID": "240",
    "Latitude": "33.067803",
    "Timezone": "279",
    "DST": "1",
    "destinations": ["210"]
}, {
    "City": "Ouargla",
    "DBTZ": "N",
    "Name": "Ouargla",
    "Country": "Algeria",
    "IATA/FAA": "OGX",
    "Longitude": "5.412778",
    "ICAO": "DAUU",
    "Airport ID": "243",
    "Latitude": "31.917223",
    "Timezone": "492",
    "DST": "1",
    "destinations": ["210", "234", "236", "244", "231"]
}, {
    "City": "Tamale",
    "DBTZ": "N",
    "Name": "Tamale",
    "Country": "Ghana",
    "IATA/FAA": "TML",
    "Longitude": "-0.863214",
    "ICAO": "DGLE",
    "Airport ID": "249",
    "Latitude": "9.557192",
    "Timezone": "553",
    "DST": "0",
    "destinations": ["248"]
}, {
    "City": "Accra",
    "DBTZ": "N",
    "Name": "Kotoka Intl",
    "Country": "Ghana",
    "IATA/FAA": "ACC",
    "Longitude": "-0.166786",
    "ICAO": "DGAA",
    "Airport ID": "248",
    "Latitude": "5.605186",
    "Timezone": "205",
    "DST": "0",
    "destinations": ["246", "1084", "273", "1063", "5556", "507", "1074", "249", "1555", "3797", "253", "1059", "2188", "1107", "298", "1062", "245", "1229", "580", "4059", "340", "2177", "1128", "251", "252", "813", "4105", "1701", "1638", "260"]
}, {
    "City": "Taipei",
    "DBTZ": "U",
    "Name": "Sungshan",
    "Country": "Taiwan",
    "IATA/FAA": "TSA",
    "Longitude": "121.5525",
    "ICAO": "RCSS",
    "Airport ID": "2275",
    "Latitude": "25.069722",
    "Timezone": "18",
    "DST": "8",
    "destinations": ["3393", "3395", "3385", "2259", "2272", "2262", "6392", "3406", "3383", "2378", "2359", "3391", "3368", "3373", "3369", "3389", "3386", "2278", "3376"]
}, {
    "City": "Taipei",
    "DBTZ": "U",
    "Name": "Taoyuan Intl",
    "Country": "Taiwan",
    "IATA/FAA": "TPE",
    "Longitude": "121.232822",
    "ICAO": "RCTP",
    "Airport ID": "2276",
    "Latitude": "25.077731",
    "Timezone": "106",
    "DST": "8",
    "destinations": ["3992", "3316", "3382", "2397", "3406", "6347", "2279", "3375", "3371", "4033", "3387", "3388", "4144", "3383", "6393", "580", "3269", "3093", "3393", "3404", "3385", "3374", "3390", "3379", "2299", "3885", "3320", "3370", "1382", "3275", "2287", "3395", "3940", "2305", "2246", "3199", "6345", "3386", "2288", "3077", "3400", "3930", "3797", "2322", "3304", "3372", "3484", "3121", "2327", "3364", "3034", "2347", "3577", "3469", "3205", "3928", "4108", "3368", "6387", "156", "193", "2372", "6392", "340", "6476", "4120", "2326", "2359", "3728", "2387", "3384", "2308", "2307", "3942", "2384", "3308", "3239", "2256", "3361", "4030", "2337", "2324", "3399", "1613", "3376", "6390", "4380", "6353", "3394", "3373", "2188", "3389", "4085", "2370", "3931", "5994", "3035", "4149", "6431", "6386", "3369", "2430", "3392"]
}, {
    "City": "Tainan",
    "DBTZ": "U",
    "Name": "Tainan",
    "Country": "Taiwan",
    "IATA/FAA": "TNN",
    "Longitude": "120.205778",
    "ICAO": "RCNN",
    "Airport ID": "2270",
    "Latitude": "22.950361",
    "Timezone": "63",
    "DST": "8",
    "destinations": ["3077"]
}, {
    "City": "Makung",
    "DBTZ": "U",
    "Name": "Magong",
    "Country": "Taiwan",
    "IATA/FAA": "MZG",
    "Longitude": "119.628311",
    "ICAO": "RCQC",
    "Airport ID": "2272",
    "Latitude": "23.568669",
    "Timezone": "103",
    "DST": "8",
    "destinations": ["2268", "2275", "2264", "2259"]
}, {
    "City": "Tokyo",
    "DBTZ": "U",
    "Name": "Narita Intl",
    "Country": "Japan",
    "IATA/FAA": "NRT",
    "Longitude": "140.386389",
    "ICAO": "RJAA",
    "Airport ID": "2279",
    "Latitude": "35.764722",
    "Timezone": "141",
    "DST": "9",
    "destinations": ["2397", "3930", "2997", "3885", "3448", "2287", "3670", "2305", "3199", "3077", "3728", "2334", "3797", "2264", "3304", "3484", "3942", "3830", "2372", "3731", "3205", "3316", "2276", "156", "178", "193", "1382", "2244", "3093", "1824", "421", "1555", "1524", "1551", "507", "2384", "2330", "3370", "3395", "3404", "3386", "3364", "3406", "4144", "3390", "3383", "4380", "3682", "3645", "2246", "3858", "3720", "2256", "3577", "2188", "2179", "2326", "2322", "3275", "3940", "3992", "2307", "2333", "2310", "2337", "2983", "2933", "3269", "2370", "3322", "4029", "2241", "340", "2927", "3388", "3321", "4075", "3361", "2934", "3339", "580", "345", "1678", "2006", "3751", "3494", "3714", "3550", "1701", "4381", "3121", "3239", "2347", "3469", "3748", "1613", "3380", "4206", "5", "2005", "609", "2985", "3024"]
}, {
    "City": "Hualien",
    "DBTZ": "U",
    "Name": "Hualien",
    "Country": "Taiwan",
    "IATA/FAA": "HUN",
    "Longitude": "121.616906",
    "ICAO": "RCYU",
    "Airport ID": "2278",
    "Latitude": "24.023725",
    "Timezone": "52",
    "DST": "8",
    "destinations": ["2264", "2268", "2275", "4108"]
}, {
    "City": "Colonel Hill",
    "DBTZ": "U",
    "Name": "Colonel Hill Airport",
    "Country": "Bahamas",
    "IATA/FAA": "CRI",
    "Longitude": "-74.182353",
    "ICAO": "MYCI",
    "Airport ID": "5859",
    "Latitude": "22.745561",
    "Timezone": "5",
    "DST": "-5",
    "destinations": ["1932", "1953"]
}, {
    "City": "Najaf",
    "DBTZ": "U",
    "Name": "Al Najaf International Airport",
    "Country": "Iraq",
    "IATA/FAA": "NJF",
    "Longitude": "44.404167",
    "ICAO": "ORNI",
    "Airport ID": "7490",
    "Latitude": "31.991667",
    "Timezone": "500",
    "DST": "3",
    "destinations": ["2188", "2191", "2057", "4330", "2176", "2177", "2241", "2170", "1701"]
}, {
    "City": "La Rioja",
    "DBTZ": "N",
    "Name": "La Rioja",
    "Country": "Argentina",
    "IATA/FAA": "IRJ",
    "Longitude": "-66.795839",
    "ICAO": "SANL",
    "Airport ID": "2458",
    "Latitude": "-29.381636",
    "Timezone": "1436",
    "DST": "-3",
    "destinations": ["2442"]
}, {
    "City": "Catamarca",
    "DBTZ": "N",
    "Name": "Catamarca",
    "Country": "Argentina",
    "IATA/FAA": "CTC",
    "Longitude": "-65.750925",
    "ICAO": "SANC",
    "Airport ID": "2455",
    "Latitude": "-28.593214",
    "Timezone": "1522",
    "DST": "-3",
    "destinations": ["2442"]
}, {
    "City": "San Rafael",
    "DBTZ": "N",
    "Name": "San Rafael",
    "Country": "Argentina",
    "IATA/FAA": "AFA",
    "Longitude": "-68.403854",
    "ICAO": "SAMR",
    "Airport ID": "2454",
    "Latitude": "-34.588314",
    "Timezone": "2470",
    "DST": "-3",
    "destinations": ["2442", "2467"]
}, {
    "City": "Santiago Del Estero",
    "DBTZ": "N",
    "Name": "Santiago Del Estero",
    "Country": "Argentina",
    "IATA/FAA": "SDE",
    "Longitude": "-64.310122",
    "ICAO": "SANE",
    "Airport ID": "2456",
    "Latitude": "-27.765617",
    "Timezone": "656",
    "DST": "-3",
    "destinations": ["2442"]
}, {
    "City": "Mendoza",
    "DBTZ": "N",
    "Name": "El Plumerillo",
    "Country": "Argentina",
    "IATA/FAA": "MDZ",
    "Longitude": "-68.792856",
    "ICAO": "SAME",
    "Airport ID": "2452",
    "Latitude": "-32.831717",
    "Timezone": "2310",
    "DST": "-3",
    "destinations": ["2443", "2509", "2442", "2513", "3988", "2476", "2650"]
}, {
    "City": "Smithers",
    "DBTZ": "A",
    "Name": "Smithers",
    "Country": "Canada",
    "IATA/FAA": "YYD",
    "Longitude": "-127.182778",
    "ICAO": "CYYD",
    "Airport ID": "179",
    "Latitude": "54.824722",
    "Timezone": "1712",
    "DST": "-8",
    "destinations": ["172", "156", "173"]
}, {
    "City": "Calgary",
    "DBTZ": "A",
    "Name": "Calgary Intl",
    "Country": "Canada",
    "IATA/FAA": "YYC",
    "Longitude": "-114.020278",
    "ICAO": "CYYC",
    "Airport ID": "178",
    "Latitude": "51.113888",
    "Timezone": "3557",
    "DST": "-7",
    "destinations": ["49", "85", "172", "3670", "3797", "3877", "3484", "507", "3830", "3462", "3731", "1852", "3751", "3494", "340", "3550", "2279", "3720", "1836", "3469", "1840", "1923", "34", "78", "87", "90", "100", "112", "116", "120", "122", "146", "156", "160", "164", "166", "167", "184", "193", "196", "3577", "580", "3858", "3536", "534", "502", "478", "3878", "3839", "31", "33", "70", "73", "79", "113", "119", "168", "174", "175"]
}, {
    "City": "North Bay",
    "DBTZ": "A",
    "Name": "North Bay",
    "Country": "Canada",
    "IATA/FAA": "YYB",
    "Longitude": "-79.422778",
    "ICAO": "CYYB",
    "Airport ID": "177",
    "Latitude": "46.363611",
    "Timezone": "1215",
    "DST": "-5",
    "destinations": ["193", "133"]
}, {
    "City": "Whitehorse",
    "DBTZ": "A",
    "Name": "Whitehorse Intl",
    "Country": "Canada",
    "IATA/FAA": "YXY",
    "Longitude": "-135.067269",
    "ICAO": "CYXY",
    "Airport ID": "176",
    "Latitude": "60.709553",
    "Timezone": "2317",
    "DST": "-8",
    "destinations": ["87", "156", "178", "196"]
}, {
    "City": "Abbotsford",
    "DBTZ": "A",
    "Name": "Abbotsford",
    "Country": "Canada",
    "IATA/FAA": "YXX",
    "Longitude": "-122.360556",
    "ICAO": "CYXX",
    "Airport ID": "175",
    "Latitude": "49.025278",
    "Timezone": "195",
    "DST": "-8",
    "destinations": ["49", "178"]
}, {
    "City": "London",
    "DBTZ": "A",
    "Name": "London",
    "Country": "Canada",
    "IATA/FAA": "YXU",
    "Longitude": "-81.151111",
    "ICAO": "CYXU",
    "Airport ID": "174",
    "Latitude": "43.033056",
    "Timezone": "912",
    "DST": "-5",
    "destinations": ["3830", "100", "193", "160", "178"]
}, {
    "City": "Terrace",
    "DBTZ": "A",
    "Name": "Terrace",
    "Country": "Canada",
    "IATA/FAA": "YXT",
    "Longitude": "-128.576219",
    "ICAO": "CYXT",
    "Airport ID": "173",
    "Latitude": "54.468508",
    "Timezone": "713",
    "DST": "-8",
    "destinations": ["172", "156", "179"]
}, {
    "City": "Prince George",
    "DBTZ": "A",
    "Name": "Prince George",
    "Country": "Canada",
    "IATA/FAA": "YXS",
    "Longitude": "-122.678889",
    "ICAO": "CYXS",
    "Airport ID": "172",
    "Latitude": "53.889444",
    "Timezone": "2267",
    "DST": "-8",
    "destinations": ["78", "87", "168", "173", "178", "179", "180", "156"]
}, {
    "City": "Pangnirtung",
    "DBTZ": "A",
    "Name": "Pangnirtung",
    "Country": "Canada",
    "IATA/FAA": "YXP",
    "Longitude": "-65.713611",
    "ICAO": "CYXP",
    "Airport ID": "170",
    "Latitude": "66.145",
    "Timezone": "75",
    "DST": "-5",
    "destinations": ["55", "152"]
}, {
    "City": "Kandahar",
    "DBTZ": "U",
    "Name": "Kandahar",
    "Country": "Afghanistan",
    "IATA/FAA": "KDH",
    "Longitude": "65.847822",
    "ICAO": "OAKN",
    "Airport ID": "2051",
    "Latitude": "31.505756",
    "Timezone": "3337",
    "DST": "4.5",
    "destinations": ["4331", "3093", "2188", "2050"]
}, {
    "City": "Kabul",
    "DBTZ": "U",
    "Name": "Kabul Intl",
    "Country": "Afghanistan",
    "IATA/FAA": "KBL",
    "Longitude": "69.212328",
    "ICAO": "OAKB",
    "Airport ID": "2050",
    "Latitude": "34.565853",
    "Timezone": "5877",
    "DST": "4.5",
    "destinations": ["3093", "2188", "2048", "2223", "2053", "4330", "4331", "1682", "2922", "2051", "2176", "8076", "2219", "2979", "1701"]
}, {
    "City": "Mazar-i-sharif",
    "DBTZ": "U",
    "Name": "Mazar I Sharif",
    "Country": "Afghanistan",
    "IATA/FAA": "MZR",
    "Longitude": "67.209678",
    "ICAO": "OAMS",
    "Airport ID": "2053",
    "Latitude": "36.706914",
    "Timezone": "1284",
    "DST": "4.5",
    "destinations": ["2050", "4330", "1701"]
}, {
    "City": "Bahrain",
    "DBTZ": "U",
    "Name": "Bahrain Intl",
    "Country": "Bahrain",
    "IATA/FAA": "BAH",
    "Longitude": "50.63361",
    "ICAO": "OBBI",
    "Airport ID": "2057",
    "Latitude": "26.270834",
    "Timezone": "6",
    "DST": "3",
    "destinations": ["2997", "2241", "507", "2179", "2188", "8076", "2191", "1107", "2170", "2177", "4130", "3885", "1128", "1382", "3136", "3093", "2064", "340", "4330", "2223", "1701", "2072", "2206", "1175", "2176", "1197", "2207", "3144", "2194", "2074", "4331", "2397", "7490", "2219", "2082", "3980", "6776", "3153", "3143", "3024"]
}, {
    "City": "Abha",
    "DBTZ": "U",
    "Name": "Abha",
    "Country": "Saudi Arabia",
    "IATA/FAA": "AHB",
    "Longitude": "42.656625",
    "ICAO": "OEAB",
    "Airport ID": "2059",
    "Latitude": "18.240367",
    "Timezone": "6858",
    "DST": "3",
    "destinations": ["3977", "3980", "2188", "2191", "1128", "2064", "2072", "2074", "2082", "2090", "2089"]
}, {
    "City": "Laredo",
    "DBTZ": "A",
    "Name": "Laredo Intl",
    "Country": "United States",
    "IATA/FAA": "LRD",
    "Longitude": "-99.461556",
    "ICAO": "KLRD",
    "Airport ID": "3691",
    "Latitude": "27.54375",
    "Timezone": "508",
    "DST": "-6",
    "destinations": ["3670", "3877", "3550"]
}, {
    "City": "Kotzebue",
    "DBTZ": "A",
    "Name": "Ralph Wien Mem",
    "Country": "United States",
    "IATA/FAA": "OTZ",
    "Longitude": "-162.59855",
    "ICAO": "PAOT",
    "Airport ID": "3693",
    "Latitude": "66.884678",
    "Timezone": "11",
    "DST": "-9",
    "destinations": ["7177", "7178", "6755", "7179", "7181", "6721", "7117", "7182", "6725", "6719", "3615", "3774"]
}, {
    "City": "Altoona",
    "DBTZ": "A",
    "Name": "Altoona Blair Co",
    "Country": "United States",
    "IATA/FAA": "AOO",
    "Longitude": "-78.320022",
    "ICAO": "KAOO",
    "Airport ID": "3694",
    "Latitude": "40.296372",
    "Timezone": "1504",
    "DST": "-5",
    "destinations": ["3714", "4169"]
}, {
    "City": "El Dorado",
    "DBTZ": "A",
    "Name": "South Arkansas Rgnl At Goodwin Fld",
    "Country": "United States",
    "IATA/FAA": "ELD",
    "Longitude": "-92.813278",
    "ICAO": "KELD",
    "Airport ID": "3696",
    "Latitude": "33.220972",
    "Timezone": "277",
    "DST": "-6",
    "destinations": ["3502", "5741"]
}, {
    "City": "New York",
    "DBTZ": "A",
    "Name": "La Guardia",
    "Country": "United States",
    "IATA/FAA": "LGA",
    "Longitude": "-73.872608",
    "ICAO": "KLGA",
    "Airport ID": "3697",
    "Latitude": "40.777245",
    "Timezone": "22",
    "DST": "-5",
    "destinations": ["3861", "3690", "3448", "4015", "3876", "3759", "3627", "3520", "3670", "3645", "4008", "3845", "3660", "3576", "3830", "3611", "3722", "3752", "3570", "3626", "3608", "4016", "4014", "3678", "3676", "4356", "146", "193", "100", "3682", "3533", "3878", "3793", "4067", "3646", "2895", "3463", "3811", "3711", "3820", "3561", "3806", "3486", "3488", "3751", "3729", "3875", "3685", "4034", "3550", "3585", "3712", "4017", "3458", "3473", "4011", "3717", "3459", "3858", "3515", "3454", "3862", "3622", "3613", "3745", "73", "4112", "3747", "3714", "3566"]
}, {
    "City": "Yulin",
    "DBTZ": "U",
    "Name": "Yulin Airport",
    "Country": "China",
    "IATA/FAA": "UYN",
    "Longitude": "109.731",
    "ICAO": "ZLYL",
    "Airport ID": "6368",
    "Latitude": "38.2692",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3379", "3382", "3368", "3395", "3375", "3364", "6341"]
}, {
    "City": "Mackenzie British Columbia",
    "DBTZ": "A",
    "Name": "Mackenzie Airport",
    "Country": "Canada",
    "IATA/FAA": "YZY",
    "Longitude": "-123.08333",
    "ICAO": "CYZY",
    "Airport ID": "7987",
    "Latitude": "55.29944",
    "Timezone": "2264",
    "DST": "-8",
    "destinations": ["6431", "3379"]
}, {
    "City": "Golmud",
    "DBTZ": "U",
    "Name": "Golmud Airport",
    "Country": "China",
    "IATA/FAA": "GOQ",
    "Longitude": "98.867",
    "ICAO": "ZLGM",
    "Airport ID": "6363",
    "Latitude": "34.633",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3379", "6366"]
}, {
    "City": "Mosjoen",
    "DBTZ": "E",
    "Name": "Kjaerstad",
    "Country": "Norway",
    "IATA/FAA": "MJF",
    "Longitude": "13.214914",
    "ICAO": "ENMS",
    "Airport ID": "652",
    "Latitude": "65.783997",
    "Timezone": "237",
    "DST": "1",
    "destinations": ["635", "5582", "5581", "662", "665"]
}, {
    "City": "Yichang",
    "DBTZ": "U",
    "Name": "Yichang Airport",
    "Country": "China",
    "IATA/FAA": "YIH",
    "Longitude": "111.441",
    "ICAO": "ZHYC",
    "Airport ID": "6361",
    "Latitude": "30.671",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3393", "3395", "3077", "3382", "3387", "3364", "3374", "3370", "3383", "3391", "3379", "3386", "3394", "4030"]
}, {
    "City": "Xiangfan",
    "DBTZ": "U",
    "Name": "Xiangfan Airport",
    "Country": "China",
    "IATA/FAA": "XFN",
    "Longitude": "112.291",
    "ICAO": "ZHXF",
    "Airport ID": "6360",
    "Latitude": "32.1506",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3364", "3374", "3370", "3383", "3393", "3386", "3406", "6341", "3376"]
}, {
    "City": "Yan'an",
    "DBTZ": "U",
    "Name": "Yan'an Airport",
    "Country": "China",
    "IATA/FAA": "ENY",
    "Longitude": "109.554",
    "ICAO": "ZLYA",
    "Airport ID": "6367",
    "Latitude": "36.6369",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3393", "3364", "6341", "3379"]
}, {
    "City": "Xining",
    "DBTZ": "U",
    "Name": "Xining Caojiabu Airport",
    "Country": "China",
    "IATA/FAA": "XNN",
    "Longitude": "102.043",
    "ICAO": "ZLXN",
    "Airport ID": "6366",
    "Latitude": "36.5275",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3393", "3395", "4097", "6400", "3379", "8043", "3382", "3399", "3368", "3375", "3364", "3376", "3371", "3394", "6363", "3406", "7894", "4085", "3388"]
}, {
    "City": "Qingyang",
    "DBTZ": "U",
    "Name": "Qingyang Airport",
    "Country": "China",
    "IATA/FAA": "IQN",
    "Longitude": "107.603",
    "ICAO": "ZLQY",
    "Airport ID": "6365",
    "Latitude": "35.7997",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["6431", "3379", "6341"]
}, {
    "City": "Pescara",
    "DBTZ": "E",
    "Name": "Pescara",
    "Country": "Italy",
    "IATA/FAA": "PSR",
    "Longitude": "14.181067",
    "ICAO": "LIBP",
    "Airport ID": "1505",
    "Latitude": "42.431656",
    "Timezone": "48",
    "DST": "1",
    "destinations": ["1529", "1525", "1367", "1519", "304", "1222", "355", "4198", "548"]
}, {
    "City": "Glasgow",
    "DBTZ": "A",
    "Name": "Wokal Field Glasgow International Airport",
    "Country": "United States",
    "IATA/FAA": "GGW",
    "Longitude": "-106.615",
    "ICAO": "KGGW",
    "Airport ID": "5739",
    "Latitude": "48.2125",
    "Timezone": "2296",
    "DST": "-7",
    "destinations": ["4021"]
}, {
    "City": "Pueblo",
    "DBTZ": "A",
    "Name": "Pueblo Memorial",
    "Country": "United States",
    "IATA/FAA": "PUB",
    "Longitude": "-104.496572",
    "ICAO": "KPUB",
    "Airport ID": "3527",
    "Latitude": "38.289085",
    "Timezone": "4726",
    "DST": "-7",
    "destinations": ["3751"]
}, {
    "City": "Atlantic City",
    "DBTZ": "A",
    "Name": "Atlantic City Intl",
    "Country": "United States",
    "IATA/FAA": "ACY",
    "Longitude": "-74.577167",
    "ICAO": "KACY",
    "Airport ID": "3524",
    "Latitude": "39.457583",
    "Timezone": "75",
    "DST": "-5",
    "destinations": ["3448", "3645", "3533", "3878", "3515", "3830", "3793", "3646", "3550"]
}, {
    "City": "Washington",
    "DBTZ": "A",
    "Name": "Ronald Reagan Washington Natl",
    "Country": "United States",
    "IATA/FAA": "DCA",
    "Longitude": "-77.037722",
    "ICAO": "KDCA",
    "Airport ID": "3520",
    "Latitude": "38.852083",
    "Timezone": "15",
    "DST": "-5",
    "destinations": ["3658", "3864", "3825", "3463", "3811", "3690", "3448", "3711", "3820", "3561", "4112", "3578", "3806", "3876", "3759", "4285", "3488", "3627", "3670", "3729", "3645", "5738", "3533", "4008", "4034", "7002", "3589", "4113", "3845", "3585", "3857", "3580", "3712", "3797", "3877", "3484", "3697", "3660", "3458", "3878", "3473", "4011", "3576", "3858", "3861", "3515", "1953", "4386", "3454", "3830", "3611", "3722", "3720", "3752", "3462", "3570", "3564", "3641", "3862", "3626", "3622", "3793", "3731", "3613", "4014", "4067", "3678", "3745", "3698", "3646", "3676", "3873", "73", "146", "193", "100", "3682", "3577", "2890", "4017", "3459", "3536", "3751", "3717", "3544", "3486", "3494", "3550", "3469", "3673", "3566"]
}, {
    "City": "Bari",
    "DBTZ": "E",
    "Name": "Bari",
    "Country": "Italy",
    "IATA/FAA": "BRI",
    "Longitude": "16.760594",
    "ICAO": "LIBD",
    "Airport ID": "1501",
    "Latitude": "41.138856",
    "Timezone": "177",
    "DST": "1",
    "destinations": ["344", "345", "350", "351", "1529", "1555", "1190", "1526", "502", "1509", "1512", "346", "1525", "1538", "1367", "1519", "1553", "304", "599", "4166", "1528", "355", "1606", "582", "4198", "1562", "548", "1253", "1545", "1539", "1246", "580", "1218", "1563", "1382", "1524", "1551", "1550", "1489", "1657", "1587"]
}, {
    "City": "Presque Isle",
    "DBTZ": "A",
    "Name": "Northern Maine Rgnl At Presque Isle",
    "Country": "United States",
    "IATA/FAA": "PQI",
    "Longitude": "-68.044797",
    "ICAO": "KPQI",
    "Airport ID": "3528",
    "Latitude": "46.688958",
    "Timezone": "534",
    "DST": "-5",
    "destinations": ["3448"]
}, {
    "City": "Tuluksak",
    "DBTZ": "A",
    "Name": "Tuluksak Airport",
    "Country": "United States",
    "IATA/FAA": "TLT",
    "Longitude": "-160.969444",
    "ICAO": "",
    "Airport ID": "7218",
    "Latitude": "61.096944",
    "Timezone": "30",
    "DST": "-9",
    "destinations": ["3599", "7217"]
}, {
    "City": "Grayling",
    "DBTZ": "A",
    "Name": "Grayling Airport",
    "Country": "United States",
    "IATA/FAA": "KGX",
    "Longitude": "-160.065",
    "ICAO": "",
    "Airport ID": "7219",
    "Latitude": "62.894444",
    "Timezone": "99",
    "DST": "-9",
    "destinations": ["6713", "7090"]
}, {
    "City": "Nunapitchuk",
    "DBTZ": "A",
    "Name": "Nunapitchuk Airport",
    "Country": "United States",
    "IATA/FAA": "NUP",
    "Longitude": "-162.439167",
    "ICAO": "",
    "Airport ID": "7212",
    "Latitude": "60.905833",
    "Timezone": "12",
    "DST": "-9",
    "destinations": ["3599", "4385"]
}, {
    "City": "Kongiganak",
    "DBTZ": "A",
    "Name": "Kongiganak Airport",
    "Country": "United States",
    "IATA/FAA": "KKH",
    "Longitude": "-162.881111",
    "ICAO": "PADY",
    "Airport ID": "7213",
    "Latitude": "59.960833",
    "Timezone": "30",
    "DST": "-9",
    "destinations": ["7096", "3599"]
}, {
    "City": "Nikolai",
    "DBTZ": "A",
    "Name": "Nikolai Airport",
    "Country": "United States",
    "IATA/FAA": "NIB",
    "Longitude": "-154.383889",
    "ICAO": "PAFS",
    "Airport ID": "7214",
    "Latitude": "63.010833",
    "Timezone": "427",
    "DST": "-9",
    "destinations": ["5965"]
}, {
    "City": "Takotna",
    "DBTZ": "A",
    "Name": "Takotna Airport",
    "Country": "United States",
    "IATA/FAA": "TCT",
    "Longitude": "-156.082778",
    "ICAO": "",
    "Airport ID": "7215",
    "Latitude": "62.971944",
    "Timezone": "825",
    "DST": "-9",
    "destinations": ["3423"]
}, {
    "City": "Pilot Station",
    "DBTZ": "A",
    "Name": "Pilot Station Airport",
    "Country": "United States",
    "IATA/FAA": "PQS",
    "Longitude": "-162.899444",
    "ICAO": "",
    "Airport ID": "7216",
    "Latitude": "61.934444",
    "Timezone": "305",
    "DST": "-9",
    "destinations": ["3599", "5966"]
}, {
    "City": "Akiak",
    "DBTZ": "A",
    "Name": "Akiak Airport",
    "Country": "United States",
    "IATA/FAA": "AKI",
    "Longitude": "-161.230556",
    "ICAO": "PFAK",
    "Airport ID": "7217",
    "Latitude": "60.902778",
    "Timezone": "30",
    "DST": "-9",
    "destinations": ["7218"]
}, {
    "City": "Mukah",
    "DBTZ": "N",
    "Name": "Mukah Airport",
    "Country": "Malaysia",
    "IATA/FAA": "MKM",
    "Longitude": "112.08",
    "ICAO": "WBGK",
    "Airport ID": "6217",
    "Latitude": "2.90639",
    "Timezone": "13",
    "DST": "8",
    "destinations": ["3263", "3266"]
}, {
    "City": "Long Seridan",
    "DBTZ": "N",
    "Name": "Long Seridan Airport",
    "Country": "Malaysia",
    "IATA/FAA": "ODN",
    "Longitude": "115.05",
    "ICAO": "WBGI",
    "Airport ID": "6216",
    "Latitude": "3.967",
    "Timezone": "607",
    "DST": "8",
    "destinations": ["3265", "3266"]
}, {
    "City": "Long Datih",
    "DBTZ": "N",
    "Name": "Long Lellang Airport",
    "Country": "Malaysia",
    "IATA/FAA": "LGL",
    "Longitude": "115.154",
    "ICAO": "WBGF",
    "Airport ID": "6215",
    "Latitude": "3.421",
    "Timezone": "1400",
    "DST": "8",
    "destinations": ["3265", "3266"]
}, {
    "City": "Langgur-Kei Islands",
    "DBTZ": "N",
    "Name": "Dumatubun Airport",
    "Country": "Indonesia",
    "IATA/FAA": "LUV",
    "Longitude": "132.731431",
    "ICAO": "WAPL",
    "Airport ID": "6212",
    "Latitude": "-5.661619",
    "Timezone": "10",
    "DST": "9",
    "destinations": ["3256"]
}, {
    "City": "Asuncion",
    "DBTZ": "S",
    "Name": "Silvio Pettirossi Intl",
    "Country": "Paraguay",
    "IATA/FAA": "ASU",
    "Longitude": "-57.519133",
    "ICAO": "SGAS",
    "Airport ID": "2699",
    "Latitude": "-25.23985",
    "Timezone": "292",
    "DST": "-4",
    "destinations": ["2816", "3576", "2442", "3988", "2789", "1871", "2564", "4305", "2650", "2771"]
}, {
    "City": "Lawas",
    "DBTZ": "N",
    "Name": "Lawas Airport",
    "Country": "Malaysia",
    "IATA/FAA": "LWY",
    "Longitude": "115.408",
    "ICAO": "WBGW",
    "Airport ID": "6219",
    "Latitude": "4.84917",
    "Timezone": "5",
    "DST": "8",
    "destinations": ["3269", "6218", "3266"]
}, {
    "City": "Bakalalan",
    "DBTZ": "N",
    "Name": "Bakalalan Airport",
    "Country": "Malaysia",
    "IATA/FAA": "BKM",
    "Longitude": "115.618",
    "ICAO": "WBGQ",
    "Airport ID": "6218",
    "Latitude": "3.974",
    "Timezone": "2900",
    "DST": "8",
    "destinations": ["6219"]
}, {
    "City": "Culebra Island",
    "DBTZ": "U",
    "Name": "Culebra Airport",
    "Country": "Puerto Rico",
    "IATA/FAA": "CPX",
    "Longitude": "-65.3034",
    "ICAO": "TJCP",
    "Airport ID": "6814",
    "Latitude": "18.3127",
    "Timezone": "12",
    "DST": "-4",
    "destinations": ["2890"]
}, {
    "City": "Myeik",
    "DBTZ": "U",
    "Name": "Myeik",
    "Country": "Burma",
    "IATA/FAA": "MGZ",
    "Longitude": "98.621478",
    "ICAO": "VYME",
    "Airport ID": "3223",
    "Latitude": "12.439797",
    "Timezone": "75",
    "DST": "6.5",
    "destinations": ["6198", "6197"]
}, {
    "City": "Mandalay",
    "DBTZ": "U",
    "Name": "Mandalay Intl",
    "Country": "Burma",
    "IATA/FAA": "MDL",
    "Longitude": "95.977928",
    "ICAO": "VYMD",
    "Airport ID": "3222",
    "Latitude": "21.702156",
    "Timezone": "300",
    "DST": "6.5",
    "destinations": ["3213", "4001", "3237", "3885", "3157", "3382", "3224", "3239"]
}, {
    "City": "Putao",
    "DBTZ": "U",
    "Name": "Putao",
    "Country": "Burma",
    "IATA/FAA": "PBU",
    "Longitude": "97.426269",
    "ICAO": "VYPT",
    "Airport ID": "3232",
    "Latitude": "27.329922",
    "Timezone": "1500",
    "DST": "6.5",
    "destinations": ["3224"]
}, {
    "City": "Thandwe",
    "DBTZ": "U",
    "Name": "Thandwe",
    "Country": "Burma",
    "IATA/FAA": "SNW",
    "Longitude": "94.300119",
    "ICAO": "VYTD",
    "Airport ID": "3236",
    "Latitude": "18.460731",
    "Timezone": "20",
    "DST": "6.5",
    "destinations": ["3235", "3239"]
}, {
    "City": "Tachilek",
    "DBTZ": "U",
    "Name": "Tachileik",
    "Country": "Burma",
    "IATA/FAA": "THL",
    "Longitude": "99.935353",
    "ICAO": "VYTL",
    "Airport ID": "3237",
    "Latitude": "20.483831",
    "Timezone": "1280",
    "DST": "6.5",
    "destinations": ["3222", "3213", "3215", "3239"]
}, {
    "City": "Sittwe",
    "DBTZ": "U",
    "Name": "Sittwe",
    "Country": "Burma",
    "IATA/FAA": "AKY",
    "Longitude": "92.872628",
    "ICAO": "VYSW",
    "Airport ID": "3235",
    "Latitude": "20.132708",
    "Timezone": "27",
    "DST": "6.5",
    "destinations": ["3239", "3236"]
}, {
    "City": "Tarawa",
    "DBTZ": "U",
    "Name": "Bonriki Intl",
    "Country": "Kiribati",
    "IATA/FAA": "TRW",
    "Longitude": "173.147036",
    "ICAO": "NGTA",
    "Airport ID": "1965",
    "Latitude": "1.381636",
    "Timezone": "9",
    "DST": "12",
    "destinations": ["1960", "4076", "2249"]
}, {
    "City": "Yangon",
    "DBTZ": "U",
    "Name": "Yangon Intl",
    "Country": "Burma",
    "IATA/FAA": "RGN",
    "Longitude": "96.133222",
    "ICAO": "VYYY",
    "Airport ID": "3239",
    "Latitude": "16.907305",
    "Timezone": "109",
    "DST": "6.5",
    "destinations": ["3316", "3235", "3213", "4001", "3236", "6197", "3885", "3370", "3930", "3304", "3034", "3043", "3076", "3382", "3364", "2276", "3157", "3077", "3373", "2279", "2241", "3199", "3205", "3931", "3217", "3222", "3224", "3237"]
}, {
    "City": "Nandi",
    "DBTZ": "U",
    "Name": "Nadi Intl",
    "Country": "Fiji",
    "IATA/FAA": "NAN",
    "Longitude": "177.443378",
    "ICAO": "NFFN",
    "Airport ID": "1960",
    "Latitude": "-17.755392",
    "Timezone": "59",
    "DST": "12",
    "destinations": ["3484", "3077", "2006", "1969", "3320", "2009", "2252", "3728", "5869", "5874", "3339", "5877", "1961", "5878", "3361", "1963", "1965", "5875", "1997", "4074", "3930", "5", "2005", "1968"]
}, {
    "City": "Nausori",
    "DBTZ": "U",
    "Name": "Nausori Intl",
    "Country": "Fiji",
    "IATA/FAA": "SUV",
    "Longitude": "178.559228",
    "ICAO": "NFNA",
    "Airport ID": "1961",
    "Latitude": "-18.043267",
    "Timezone": "17",
    "DST": "12",
    "destinations": ["2006", "4077", "5867", "5869", "5874", "5873", "1960", "5878", "3361", "1963", "5875", "5883"]
}, {
    "City": "Innsbruck",
    "DBTZ": "E",
    "Name": "Innsbruck",
    "Country": "Austria",
    "IATA/FAA": "INN",
    "Longitude": "11.343964",
    "ICAO": "LOWI",
    "Airport ID": "1610",
    "Latitude": "47.260219",
    "Timezone": "1906",
    "DST": "1",
    "destinations": ["3998", "580", "585", "340", "1613", "502"]
}, {
    "City": "Tongatapu",
    "DBTZ": "U",
    "Name": "Fua Amotu Intl",
    "Country": "Tonga",
    "IATA/FAA": "TBU",
    "Longitude": "-175.149644",
    "ICAO": "NFTF",
    "Airport ID": "1963",
    "Latitude": "-21.241214",
    "Timezone": "126",
    "DST": "13",
    "destinations": ["1960", "1961", "2006", "3361"]
}, {
    "City": "Yongzhou",
    "DBTZ": "U",
    "Name": "Yongzhou Lingling Airport",
    "Country": "China",
    "IATA/FAA": "LLF",
    "Longitude": "111.612222",
    "ICAO": "",
    "Airport ID": "8845",
    "Latitude": "26.345556",
    "Timezone": "428",
    "DST": "8",
    "destinations": ["3371", "3382", "3374"]
}, {
    "City": "Fort Hope",
    "DBTZ": "A",
    "Name": "Fort Hope Airport",
    "Country": "Canada",
    "IATA/FAA": "YFH",
    "Longitude": "-87.9078",
    "ICAO": "CYFH",
    "Airport ID": "5491",
    "Latitude": "51.5619",
    "Timezone": "899",
    "DST": "-5",
    "destinations": ["5510", "5470", "121"]
}, {
    "City": "Valera",
    "DBTZ": "U",
    "Name": "Dr Antonio Nicolas Briceno",
    "Country": "Venezuela",
    "IATA/FAA": "VLV",
    "Longitude": "-70.584089",
    "ICAO": "SVVL",
    "Airport ID": "2870",
    "Latitude": "9.340797",
    "Timezone": "1893",
    "DST": "-4.5",
    "destinations": ["2851"]
}, {
    "City": "Dominica",
    "DBTZ": "U",
    "Name": "Melville Hall",
    "Country": "Dominica",
    "IATA/FAA": "DOM",
    "Longitude": "-61.3",
    "ICAO": "TDPD",
    "Airport ID": "2877",
    "Latitude": "15.547028",
    "Timezone": "73",
    "DST": "-4",
    "destinations": ["2881", "2899", "2890", "2874", "2875", "2907", "2903", "2883"]
}, {
    "City": "Bridgetown",
    "DBTZ": "U",
    "Name": "Grantley Adams Intl",
    "Country": "Barbados",
    "IATA/FAA": "BGI",
    "Longitude": "-59.492456",
    "ICAO": "TBPB",
    "Airport ID": "2875",
    "Latitude": "13.074603",
    "Timezone": "169",
    "DST": "-4",
    "destinations": ["3576", "193", "3797", "502", "2874", "1779", "2902", "2899", "340", "478", "2564", "2877", "2878", "2882", "4306", "2893", "2907"]
}, {
    "City": "Antigua",
    "DBTZ": "U",
    "Name": "V C Bird Intl",
    "Country": "Antigua and Barbuda",
    "IATA/FAA": "ANU",
    "Longitude": "-61.792667",
    "ICAO": "TAPA",
    "Airport ID": "2874",
    "Latitude": "17.136749",
    "Timezone": "62",
    "DST": "-4",
    "destinations": ["2881", "6079", "3876", "3797", "3576", "193", "502", "1760", "2891", "2901", "2875", "1779", "340", "2900", "2877", "1762", "2890", "2893", "2899", "6460", "3494", "2894"]
}, {
    "City": "St. Martin",
    "DBTZ": "U",
    "Name": "Grand Case",
    "Country": "Guadeloupe",
    "IATA/FAA": "SFG",
    "Longitude": "-63.047197",
    "ICAO": "TFFG",
    "Airport ID": "2879",
    "Latitude": "18.099914",
    "Timezone": "7",
    "DST": "-4",
    "destinations": ["2881", "6460"]
}, {
    "City": "Fort-de-france",
    "DBTZ": "U",
    "Name": "Le Lamentin",
    "Country": "Martinique",
    "IATA/FAA": "FDF",
    "Longitude": "-61.003175",
    "ICAO": "TFFF",
    "Airport ID": "2878",
    "Latitude": "14.591033",
    "Timezone": "16",
    "DST": "-4",
    "destinations": ["2774", "2881", "1762", "2893", "3576", "2890", "146", "1386", "1909", "2875", "1382"]
}, {
    "City": "Panama City",
    "DBTZ": "A",
    "Name": "Panama City-NW Florida Bea.",
    "Country": "United States",
    "IATA/FAA": "ECP",
    "Longitude": "-85.7973",
    "ICAO": "KECP",
    "Airport ID": "7669",
    "Latitude": "30.3417",
    "Timezone": "69",
    "DST": "-6",
    "destinations": ["3682", "3690", "3849", "3566", "3678"]
}, {
    "City": "La Chorrera",
    "DBTZ": "U",
    "Name": "La Chorrera Airport",
    "Country": "Colombia",
    "IATA/FAA": "LCR",
    "Longitude": "-73.016667",
    "ICAO": "",
    "Airport ID": "7356",
    "Latitude": "-0.733333",
    "Timezone": "10",
    "DST": "-5",
    "destinations": ["6740", "2728"]
}, {
    "City": "Coff's Harbour",
    "DBTZ": "O",
    "Name": "Coffs Harbour",
    "Country": "Australia",
    "IATA/FAA": "CFS",
    "Longitude": "153.116389",
    "ICAO": "YSCH",
    "Airport ID": "3356",
    "Latitude": "-30.320556",
    "Timezone": "18",
    "DST": "10",
    "destinations": ["3361", "3339"]
}, {
    "City": "Perth",
    "DBTZ": "N",
    "Name": "Perth Intl",
    "Country": "Australia",
    "IATA/FAA": "PER",
    "Longitude": "115.966944",
    "ICAO": "YPPH",
    "Airport ID": "3351",
    "Latitude": "-31.940278",
    "Timezone": "67",
    "DST": "8",
    "destinations": ["3316", "3320", "3339", "3361", "3077", "3370", "3304", "3319", "2188", "3275", "3940", "3341", "3322", "8401", "3269", "893", "2006", "6235", "9090", "6266", "6268", "3346", "3347", "3348", "6314", "7577", "4319", "3355", "3999", "813", "3345", "3349", "6310", "2241", "3885", "3179", "3353"]
}, {
    "City": "Christmas Island",
    "DBTZ": "U",
    "Name": "Christmas Island",
    "Country": "Christmas Island",
    "IATA/FAA": "XCH",
    "Longitude": "105.690278",
    "ICAO": "YPXM",
    "Airport ID": "3353",
    "Latitude": "-10.450556",
    "Timezone": "916",
    "DST": "7",
    "destinations": ["6315", "3351"]
}, {
    "City": "Norfolk Island",
    "DBTZ": "U",
    "Name": "Norfolk Island Intl",
    "Country": "Norfolk Island",
    "IATA/FAA": "NLK",
    "Longitude": "167.938742",
    "ICAO": "YSNF",
    "Airport ID": "3359",
    "Latitude": "-29.041625",
    "Timezone": "371",
    "DST": "11.5",
    "destinations": ["2006", "3320", "3361"]
}, {
    "City": "Dubbo",
    "DBTZ": "O",
    "Name": "Dubbo",
    "Country": "Australia",
    "IATA/FAA": "DBO",
    "Longitude": "148.574722",
    "ICAO": "YSDU",
    "Airport ID": "3358",
    "Latitude": "-32.216667",
    "Timezone": "935",
    "DST": "10",
    "destinations": ["3361", "6241"]
}, {
    "City": "Uummannaq",
    "DBTZ": "E",
    "Name": "Qaarsut Airport",
    "Country": "Greenland",
    "IATA/FAA": "JQA",
    "Longitude": "-52.6962",
    "ICAO": "BGUQ",
    "Airport ID": "5449",
    "Latitude": "70.7342",
    "Timezone": "289",
    "DST": "-3",
    "destinations": ["3995", "8628"]
}, {
    "City": "Upernavik",
    "DBTZ": "E",
    "Name": "Upernavik Airport",
    "Country": "Greenland",
    "IATA/FAA": "JUV",
    "Longitude": "-56.1306",
    "ICAO": "BGUK",
    "Airport ID": "5448",
    "Latitude": "72.7902",
    "Timezone": "414",
    "DST": "-3",
    "destinations": ["3995", "5446"]
}, {
    "City": "McCook",
    "DBTZ": "A",
    "Name": "McCook Regional Airport",
    "Country": "United States",
    "IATA/FAA": "MCK",
    "Longitude": "-100.592222",
    "ICAO": "KMCK",
    "Airport ID": "7068",
    "Latitude": "40.206389",
    "Timezone": "2583",
    "DST": "-6",
    "destinations": ["3751"]
}, {
    "City": "Alliance",
    "DBTZ": "A",
    "Name": "Alliance Municipal Airport",
    "Country": "United States",
    "IATA/FAA": "AIA",
    "Longitude": "-102.803889",
    "ICAO": "KAIA",
    "Airport ID": "7067",
    "Latitude": "42.053333",
    "Timezone": "3931",
    "DST": "-7",
    "destinations": ["7066"]
}, {
    "City": "Chadron",
    "DBTZ": "A",
    "Name": "Chadron Municipal Airport",
    "Country": "United States",
    "IATA/FAA": "CDR",
    "Longitude": "-103.095556",
    "ICAO": "KCDR",
    "Airport ID": "7066",
    "Latitude": "42.8375",
    "Timezone": "3297",
    "DST": "-7",
    "destinations": ["3751"]
}, {
    "City": "Sidney",
    "DBTZ": "A",
    "Name": "Sidney-Richland Municipal Airport",
    "Country": "United States",
    "IATA/FAA": "SDY",
    "Longitude": "-104.1925",
    "ICAO": "KSDY",
    "Airport ID": "7065",
    "Latitude": "47.706944",
    "Timezone": "1984",
    "DST": "-7",
    "destinations": ["4021"]
}, {
    "City": "Dickinson",
    "DBTZ": "A",
    "Name": "Dickinson Theodore Roosevelt Regional Airport",
    "Country": "United States",
    "IATA/FAA": "DIK",
    "Longitude": "-102.801944",
    "ICAO": "KDIK",
    "Airport ID": "7064",
    "Latitude": "46.7975",
    "Timezone": "2592",
    "DST": "-7",
    "destinations": ["3858", "3751"]
}, {
    "City": "Sisimiut",
    "DBTZ": "E",
    "Name": "Sisimiut Airport",
    "Country": "Greenland",
    "IATA/FAA": "JHS",
    "Longitude": "-53.7293",
    "ICAO": "BGSS",
    "Airport ID": "5447",
    "Latitude": "66.9513",
    "Timezone": "33",
    "DST": "-3",
    "destinations": ["8", "3997", "9"]
}, {
    "City": "Weyers Cave",
    "DBTZ": "A",
    "Name": "Shenandoah Valley Regional Airport",
    "Country": "United States",
    "IATA/FAA": "SHD",
    "Longitude": "-78.896389",
    "ICAO": "KSHD",
    "Airport ID": "7062",
    "Latitude": "38.263889",
    "Timezone": "1201",
    "DST": "-5",
    "destinations": ["3714"]
}, {
    "City": "Narsaq",
    "DBTZ": "E",
    "Name": "Narsaq Heliport",
    "Country": "Greenland",
    "IATA/FAA": "JNS",
    "Longitude": "-46.0586",
    "ICAO": "BGNS",
    "Airport ID": "5445",
    "Latitude": "60.9167",
    "Timezone": "0",
    "DST": "-3",
    "destinations": ["5442", "7"]
}, {
    "City": "Nanortalik",
    "DBTZ": "E",
    "Name": "Nanortalik Heliport",
    "Country": "Greenland",
    "IATA/FAA": "JNN",
    "Longitude": "-45.2317",
    "ICAO": "BGNN",
    "Airport ID": "5444",
    "Latitude": "60.14",
    "Timezone": "0",
    "DST": "-3",
    "destinations": ["5442", "5438"]
}, {
    "City": "Santo Domingo",
    "DBTZ": "U",
    "Name": "Las Americas Intl",
    "Country": "Dominican Republic",
    "IATA/FAA": "SDQ",
    "Longitude": "-69.668925",
    "ICAO": "MDSD",
    "Airport ID": "1762",
    "Latitude": "18.429664",
    "Timezone": "59",
    "DST": "-4",
    "destinations": ["2881", "2899", "2897", "3576", "3752", "1382", "2709", "2789", "3448", "3533", "3797", "3878", "2890", "1871", "1909", "1905", "340", "1885", "3682", "2560", "2564", "1779", "1754", "2874", "1386", "3494", "1229"]
}, {
    "City": "Punta Cana",
    "DBTZ": "U",
    "Name": "Punta Cana Intl",
    "Country": "Dominican Republic",
    "IATA/FAA": "PUJ",
    "Longitude": "-68.363431",
    "ICAO": "MDPC",
    "Airport ID": "1760",
    "Latitude": "18.567367",
    "Timezone": "47",
    "DST": "-4",
    "destinations": ["3988", "3576", "3876", "3752", "345", "346", "146", "193", "1382", "1762", "2709", "3448", "3533", "3797", "2890", "2874", "1871", "340", "3682", "3488", "3645", "3570", "1229", "3486", "3830", "3678", "3849", "3747", "2851", "2789", "2650", "1678", "580", "1780", "1335", "1418", "1273", "2985", "3858", "502", "73", "111", "3494", "3714", "4029", "2948"]
}, {
    "City": "Puerto Plata",
    "DBTZ": "U",
    "Name": "Gregorio Luperon Intl",
    "Country": "Dominican Republic",
    "IATA/FAA": "POP",
    "Longitude": "-70.570033",
    "ICAO": "MDPP",
    "Airport ID": "1761",
    "Latitude": "19.7579",
    "Timezone": "15",
    "DST": "-4",
    "destinations": ["3576", "345", "73", "193", "3797", "2874", "340", "1754", "2890", "1760", "1382", "5810", "1759", "3494"]
}, {
    "City": "Guatemala City",
    "DBTZ": "U",
    "Name": "La Aurora",
    "Country": "Guatemala",
    "IATA/FAA": "GUA",
    "Longitude": "-90.527475",
    "ICAO": "MGGT",
    "Airport ID": "1767",
    "Latitude": "14.583272",
    "Timezone": "4952",
    "DST": "-6",
    "destinations": ["1824", "4024", "1772", "1776", "3670", "3576", "3682", "1851", "2709", "3484", "1892", "1885", "1863", "1871", "3797", "3533", "3494", "3714", "3550"]
}, {
    "City": "Santiago",
    "DBTZ": "U",
    "Name": "Cibao Intl",
    "Country": "Dominican Republic",
    "IATA/FAA": "STI",
    "Longitude": "-70.604689",
    "ICAO": "MDST",
    "Airport ID": "1764",
    "Latitude": "19.406092",
    "Timezone": "565",
    "DST": "-4",
    "destinations": ["3576", "3448", "3494", "3797", "2890", "1871", "1754", "3533"]
}, {
    "City": "Chefornak",
    "DBTZ": "A",
    "Name": "Chefornak Airport",
    "Country": "United States",
    "IATA/FAA": "CYF",
    "Longitude": "-164.285556",
    "ICAO": "PACK",
    "Airport ID": "6960",
    "Latitude": "60.149167",
    "Timezone": "40",
    "DST": "-9",
    "destinations": ["3599", "7201"]
}, {
    "City": "Tongren",
    "DBTZ": "U",
    "Name": "Tongren",
    "Country": "China",
    "IATA/FAA": "TEN",
    "Longitude": "109.31",
    "ICAO": "ZUTR",
    "Airport ID": "6963",
    "Latitude": "27.884",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3371", "3370", "3364", "3374", "3394"]
}, {
    "City": "Branson",
    "DBTZ": "A",
    "Name": "Branson LLC",
    "Country": "United States",
    "IATA/FAA": "BKG",
    "Longitude": "-93.200556",
    "ICAO": "KBBG",
    "Airport ID": "6962",
    "Latitude": "36.531994",
    "Timezone": "1302",
    "DST": "-6",
    "destinations": ["3751", "3502", "3566", "3747"]
}, {
    "City": "Uummannaq",
    "DBTZ": "E",
    "Name": "Uummannaq Heliport",
    "Country": "Greenland",
    "IATA/FAA": "UMD",
    "Longitude": "52.0702",
    "ICAO": "BGUM",
    "Airport ID": "8628",
    "Latitude": "70.4047",
    "Timezone": "5",
    "DST": "-4",
    "destinations": ["5449"]
}, {
    "City": "Nairobi",
    "DBTZ": "U",
    "Name": "Nairobi Wilson",
    "Country": "Kenya",
    "IATA/FAA": "WIL",
    "Longitude": "36.814833",
    "ICAO": "HKNW",
    "Airport ID": "1147",
    "Latitude": "-1.321719",
    "Timezone": "5546",
    "DST": "3",
    "destinations": ["5692", "1141", "1144", "5693", "7424", "7976", "1149", "1180", "5695", "6773"]
}, {
    "City": "Lamu",
    "DBTZ": "U",
    "Name": "Lamu Manda",
    "Country": "Kenya",
    "IATA/FAA": "LAU",
    "Longitude": "40.913097",
    "ICAO": "HKLU",
    "Airport ID": "1144",
    "Latitude": "-2.252417",
    "Timezone": "20",
    "DST": "3",
    "destinations": ["5694", "1147"]
}, {
    "City": "Mombasa",
    "DBTZ": "U",
    "Name": "Mombasa Moi Intl",
    "Country": "Kenya",
    "IATA/FAA": "MBA",
    "Longitude": "39.59425",
    "ICAO": "HKMO",
    "Airport ID": "1145",
    "Latitude": "-4.034833",
    "Timezone": "200",
    "DST": "3",
    "destinations": ["4059", "1186", "912", "340", "1107", "1180", "1701", "2188", "1165"]
}, {
    "City": "Nizhnekamsk",
    "DBTZ": "N",
    "Name": "Begishevo",
    "Country": "Russia",
    "IATA/FAA": "NBC",
    "Longitude": "52.06",
    "ICAO": "UWKE",
    "Airport ID": "6969",
    "Latitude": "55.34",
    "Timezone": "50",
    "DST": "4",
    "destinations": ["4029", "2922", "2948", "2975", "1701", "2985", "2979"]
}, {
    "City": "Belmopan",
    "DBTZ": "U",
    "Name": "Hector Silva Airstrip",
    "Country": "Belize",
    "IATA/FAA": "BCV",
    "Longitude": "-88.776111",
    "ICAO": "",
    "Airport ID": "8227",
    "Latitude": "17.269444",
    "Timezone": "50",
    "DST": "-6",
    "destinations": ["1957", "5454", "4279", "6463"]
}, {
    "City": "Wajir",
    "DBTZ": "U",
    "Name": "Wajir",
    "Country": "Kenya",
    "IATA/FAA": "WJR",
    "Longitude": "40.091606",
    "ICAO": "HKWJ",
    "Airport ID": "1149",
    "Latitude": "1.733239",
    "Timezone": "770",
    "DST": "3",
    "destinations": ["1121", "5687", "4059", "1147"]
}, {
    "City": "Aupaluk",
    "DBTZ": "A",
    "Name": "Aupaluk Airport",
    "Country": "Canada",
    "IATA/FAA": "YPJ",
    "Longitude": "-69.5997",
    "ICAO": "CYLA",
    "Airport ID": "5508",
    "Latitude": "59.2967",
    "Timezone": "119",
    "DST": "-5",
    "destinations": ["5481", "6728", "154"]
}, {
    "City": "Kimmirut",
    "DBTZ": "A",
    "Name": "Kimmirut Airport",
    "Country": "Canada",
    "IATA/FAA": "YLC",
    "Longitude": "-69.8833",
    "ICAO": "CYLC",
    "Airport ID": "5509",
    "Latitude": "62.85",
    "Timezone": "175",
    "DST": "-5",
    "destinations": ["55"]
}, {
    "City": "Akulivik",
    "DBTZ": "A",
    "Name": "Akulivik Airport",
    "Country": "Canada",
    "IATA/FAA": "AKV",
    "Longitude": "-78.1486",
    "ICAO": "CYKO",
    "Airport ID": "5506",
    "Latitude": "60.8186",
    "Timezone": "75",
    "DST": "-5",
    "destinations": ["5504", "6727"]
}, {
    "City": "Waskaganish",
    "DBTZ": "A",
    "Name": "Waskaganish Airport",
    "Country": "Canada",
    "IATA/FAA": "YKQ",
    "Longitude": "-78.7583",
    "ICAO": "CYKQ",
    "Airport ID": "5507",
    "Latitude": "51.4733",
    "Timezone": "80",
    "DST": "-5",
    "destinations": ["5472", "91", "5514", "5501", "153", "5539"]
}, {
    "City": "Ivujivik",
    "DBTZ": "A",
    "Name": "Ivujivik Airport",
    "Country": "Canada",
    "IATA/FAA": "YIK",
    "Longitude": "-77.9253",
    "ICAO": "CYIK",
    "Airport ID": "5504",
    "Latitude": "62.4173",
    "Timezone": "126",
    "DST": "-5",
    "destinations": ["5506", "5535"]
}, {
    "City": "Hopedale",
    "DBTZ": "A",
    "Name": "Hopedale Airport",
    "Country": "Canada",
    "IATA/FAA": "YHO",
    "Longitude": "-60.2286",
    "ICAO": "CYHO",
    "Airport ID": "5502",
    "Latitude": "55.4483",
    "Timezone": "39",
    "DST": "-4",
    "destinations": ["5492", "7252"]
}, {
    "City": "Chevery",
    "DBTZ": "A",
    "Name": "Chevery Airport",
    "Country": "Canada",
    "IATA/FAA": "YHR",
    "Longitude": "-59.6367",
    "ICAO": "CYHR",
    "Airport ID": "5503",
    "Latitude": "50.4689",
    "Timezone": "39",
    "DST": "-4",
    "destinations": ["6789", "94", "5473"]
}, {
    "City": "Nemiscau",
    "DBTZ": "A",
    "Name": "Nemiscau Airport",
    "Country": "Canada",
    "IATA/FAA": "YNS",
    "Longitude": "-76.1356",
    "ICAO": "CYHH",
    "Airport ID": "5501",
    "Latitude": "51.6911",
    "Timezone": "802",
    "DST": "-5",
    "destinations": ["5507", "5514"]
}, {
    "City": "Rimini",
    "DBTZ": "E",
    "Name": "Rimini",
    "Country": "Italy",
    "IATA/FAA": "RMI",
    "Longitude": "12.611747",
    "ICAO": "LIPR",
    "Airport ID": "1546",
    "Latitude": "44.020292",
    "Timezone": "41",
    "DST": "1",
    "destinations": ["2960", "4029"]
}, {
    "City": "Trollhattan",
    "DBTZ": "E",
    "Name": "Trollhattan Vanersborg",
    "Country": "Sweden",
    "IATA/FAA": "THN",
    "Longitude": "12.345",
    "ICAO": "ESGT",
    "Airport ID": "693",
    "Latitude": "58.318056",
    "Timezone": "137",
    "DST": "1",
    "destinations": ["738"]
}, {
    "City": "Ronchi De Legionari",
    "DBTZ": "E",
    "Name": "Ronchi Dei Legionari",
    "Country": "Italy",
    "IATA/FAA": "TRS",
    "Longitude": "13.472222",
    "ICAO": "LIPQ",
    "Airport ID": "1545",
    "Latitude": "45.8275",
    "Timezone": "37",
    "DST": "1",
    "destinations": ["1555", "1529", "1561", "1517", "1501", "1367", "304", "548", "1515", "1246", "346"]
}, {
    "City": "Mora",
    "DBTZ": "E",
    "Name": "Mora",
    "Country": "Sweden",
    "IATA/FAA": "MXX",
    "Longitude": "14.511383",
    "ICAO": "ESKM",
    "Airport ID": "698",
    "Latitude": "60.957908",
    "Timezone": "634",
    "DST": "1",
    "destinations": ["737", "714"]
}, {
    "City": "Stockholm",
    "DBTZ": "E",
    "Name": "Skavsta",
    "Country": "Sweden",
    "IATA/FAA": "NYO",
    "Longitude": "16.912189",
    "ICAO": "ESKN",
    "Airport ID": "699",
    "Latitude": "58.788636",
    "Timezone": "140",
    "DST": "1",
    "destinations": ["1230", "1517", "1212", "1218", "1525", "1280", "353", "1367", "1360", "1474", "1553", "304", "669", "1606", "1198", "3998", "1562", "1472", "1205", "1486", "548", "1539", "8414", "1209", "1739", "1489", "668", "671", "674", "1737", "679"]
}, {
    "City": "Taichung",
    "DBTZ": "N",
    "Name": "Ching Chuang Kang",
    "Country": "Taiwan",
    "IATA/FAA": "RMQ",
    "Longitude": "120.62058",
    "ICAO": "RCMQ",
    "Airport ID": "2268",
    "Latitude": "24.264668",
    "Timezone": "663",
    "DST": "8",
    "destinations": ["3375", "3199", "3386", "3077", "2259", "6354", "2272", "3387", "2384", "3205", "3370", "3371", "3388", "3374", "3383", "3121", "3992", "3385", "2278", "3373", "3406", "3390", "4108"]
}, {
    "City": "Tiree",
    "DBTZ": "E",
    "Name": "Tiree",
    "Country": "United Kingdom",
    "IATA/FAA": "TRE",
    "Longitude": "-6.869167",
    "ICAO": "EGPU",
    "Airport ID": "542",
    "Latitude": "56.499167",
    "Timezone": "38",
    "DST": "0",
    "destinations": ["534"]
}, {
    "City": "Montego Bay",
    "DBTZ": "U",
    "Name": "Sangster Intl",
    "Country": "Jamaica",
    "IATA/FAA": "MBJ",
    "Longitude": "-77.913358",
    "ICAO": "MKJS",
    "Airport ID": "1780",
    "Latitude": "18.503717",
    "Timezone": "4",
    "DST": "-5",
    "destinations": ["3876", "3670", "3576", "3830", "3752", "73", "146", "193", "3682", "3533", "3797", "3878", "1779", "1871", "340", "3645", "3585", "3678", "3849", "3747", "1926", "580", "3494", "3714", "3550", "502"]
}, {
    "City": "Alakanuk",
    "DBTZ": "A",
    "Name": "Alakanuk Airport",
    "Country": "United States",
    "IATA/FAA": "AUK",
    "Longitude": "-164.66",
    "ICAO": "PAUK",
    "Airport ID": "7199",
    "Latitude": "62.68",
    "Timezone": "10",
    "DST": "-9",
    "destinations": ["7087", "7200"]
}, {
    "City": "Stornoway",
    "DBTZ": "E",
    "Name": "Stornoway",
    "Country": "United Kingdom",
    "IATA/FAA": "SYY",
    "Longitude": "-6.331111",
    "ICAO": "EGPO",
    "Airport ID": "541",
    "Latitude": "58.215556",
    "Timezone": "26",
    "DST": "0",
    "destinations": ["538", "535", "534", "533", "532"]
}, {
    "City": "Norwich",
    "DBTZ": "E",
    "Name": "Norwich",
    "Country": "United Kingdom",
    "IATA/FAA": "NWI",
    "Longitude": "1.282778",
    "ICAO": "EGSH",
    "Airport ID": "547",
    "Latitude": "52.675833",
    "Timezone": "117",
    "DST": "0",
    "destinations": ["580", "535", "498", "499", "478", "532", "1606", "515"]
}, {
    "City": "Stebbins",
    "DBTZ": "A",
    "Name": "Stebbins Airport",
    "Country": "United States",
    "IATA/FAA": "WBB",
    "Longitude": "-162.278056",
    "ICAO": "",
    "Airport ID": "7193",
    "Latitude": "63.515833",
    "Timezone": "14",
    "DST": "-9",
    "destinations": ["7207", "3615", "7191", "7088"]
}, {
    "City": "Shaktoolik",
    "DBTZ": "A",
    "Name": "Shaktoolik Airport",
    "Country": "United States",
    "IATA/FAA": "SKK",
    "Longitude": "-161.223889",
    "ICAO": "PFSH",
    "Airport ID": "7192",
    "Latitude": "64.371111",
    "Timezone": "24",
    "DST": "-9",
    "destinations": ["7190", "3615", "7088"]
}, {
    "City": "St. Michael",
    "DBTZ": "A",
    "Name": "St. Michael Airport",
    "Country": "United States",
    "IATA/FAA": "SMK",
    "Longitude": "-162.110278",
    "ICAO": "PAMK",
    "Airport ID": "7191",
    "Latitude": "63.49",
    "Timezone": "98",
    "DST": "-9",
    "destinations": ["7088", "7193"]
}, {
    "City": "Koyuk",
    "DBTZ": "A",
    "Name": "Koyuk Alfred Adams Airport",
    "Country": "United States",
    "IATA/FAA": "KKA",
    "Longitude": "-161.154167",
    "ICAO": "PAKK",
    "Airport ID": "7190",
    "Latitude": "64.939444",
    "Timezone": "154",
    "DST": "-9",
    "destinations": ["7184", "7192", "7088", "3615"]
}, {
    "City": "Nikolski",
    "DBTZ": "A",
    "Name": "Nikolski Air Station",
    "Country": "United States",
    "IATA/FAA": "IKO",
    "Longitude": "-168.848889",
    "ICAO": "PAKO",
    "Airport ID": "7196",
    "Latitude": "52.941667",
    "Timezone": "77",
    "DST": "-9",
    "destinations": ["3860"]
}, {
    "City": "Atka",
    "DBTZ": "A",
    "Name": "Atka Airport",
    "Country": "United States",
    "IATA/FAA": "AKB",
    "Longitude": "-174.206389",
    "ICAO": "PAAK",
    "Airport ID": "7195",
    "Latitude": "52.220278",
    "Timezone": "56",
    "DST": "-9",
    "destinations": ["3860"]
}, {
    "City": "Tin City",
    "DBTZ": "A",
    "Name": "Tin City LRRS Airport",
    "Country": "United States",
    "IATA/FAA": "TNC",
    "Longitude": "-167.921667",
    "ICAO": "PATC",
    "Airport ID": "7194",
    "Latitude": "65.563056",
    "Timezone": "269",
    "DST": "-9",
    "destinations": ["7187"]
}, {
    "City": "Akureyri",
    "DBTZ": "N",
    "Name": "Akureyri",
    "Country": "Iceland",
    "IATA/FAA": "AEY",
    "Longitude": "-18.072703",
    "ICAO": "BIAR",
    "Airport ID": "11",
    "Latitude": "65.659994",
    "Timezone": "6",
    "DST": "0",
    "destinations": ["18"]
}, {
    "City": "Acapulco",
    "DBTZ": "S",
    "Name": "General Juan N Alvarez Intl",
    "Country": "Mexico",
    "IATA/FAA": "ACA",
    "Longitude": "-99.753953",
    "ICAO": "MMAA",
    "Airport ID": "1783",
    "Latitude": "16.757061",
    "Timezone": "16",
    "DST": "-6",
    "destinations": ["1824", "1850", "3550", "1825", "1847"]
}, {
    "City": "Wanxian",
    "DBTZ": "U",
    "Name": "Wanxian Airport",
    "Country": "China",
    "IATA/FAA": "WXN",
    "Longitude": "108.406",
    "ICAO": "ZUWX",
    "Airport ID": "6403",
    "Latitude": "30.8361",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3395", "3382", "3364", "3374", "3370", "3379", "3383", "3406"]
}, {
    "City": "Aguascalientes",
    "DBTZ": "S",
    "Name": "Jesus Teran Intl",
    "Country": "Mexico",
    "IATA/FAA": "AGU",
    "Longitude": "-102.317858",
    "ICAO": "MMAS",
    "Airport ID": "1785",
    "Latitude": "21.705558",
    "Timezone": "6112",
    "DST": "-6",
    "destinations": ["1824", "3670", "1825", "3550", "1852", "3484", "1847"]
}, {
    "City": "Nanchong",
    "DBTZ": "U",
    "Name": "Nanchong Airport",
    "Country": "China",
    "IATA/FAA": "NAO",
    "Longitude": "106.062",
    "ICAO": "ZUNC",
    "Airport ID": "6401",
    "Latitude": "30.754",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3370", "3364", "3374", "3379", "3382", "3406"]
}, {
    "City": "Kumejima",
    "DBTZ": "U",
    "Name": "Kumejima",
    "Country": "Japan",
    "IATA/FAA": "UEO",
    "Longitude": "126.713806",
    "ICAO": "ROKJ",
    "Airport ID": "2388",
    "Latitude": "26.363506",
    "Timezone": "23",
    "DST": "9",
    "destinations": ["2384"]
}, {
    "City": "Isafjordur",
    "DBTZ": "N",
    "Name": "Isafjordur",
    "Country": "Iceland",
    "IATA/FAA": "IFJ",
    "Longitude": "-23.135278",
    "ICAO": "BIIS",
    "Airport ID": "15",
    "Latitude": "66.058056",
    "Timezone": "8",
    "DST": "0",
    "destinations": ["18"]
}, {
    "City": "Korla",
    "DBTZ": "U",
    "Name": "Korla Airport",
    "Country": "China",
    "IATA/FAA": "KRL",
    "Longitude": "86.1289",
    "ICAO": "ZWKL",
    "Airport ID": "6407",
    "Latitude": "41.6978",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3395", "6743", "3399", "3375", "3393"]
}, {
    "City": "Apartado",
    "DBTZ": "U",
    "Name": "Apartado Airport",
    "Country": "Colombia",
    "IATA/FAA": "APO",
    "Longitude": "-77.2",
    "ICAO": "",
    "Airport ID": "5476",
    "Latitude": "7.033",
    "Timezone": "0",
    "DST": "-5",
    "destinations": ["2709", "2729"]
}, {
    "City": "Daru",
    "DBTZ": "U",
    "Name": "Daru Airport",
    "Country": "Papua New Guinea",
    "IATA/FAA": "DAU",
    "Longitude": "143.208",
    "ICAO": "AYDU",
    "Airport ID": "5421",
    "Latitude": "-9.08676",
    "Timezone": "20",
    "DST": "10",
    "destinations": ["4", "5", "5425"]
}, {
    "City": "Enfidha",
    "DBTZ": "U",
    "Name": "Enfidha - Zine El Abidine Ben Ali International Airport",
    "Country": "Tunisia",
    "IATA/FAA": "NBE",
    "Longitude": "10.438611",
    "ICAO": "DTNZ",
    "Airport ID": "7447",
    "Latitude": "36.075833",
    "Timezone": "500",
    "DST": "1",
    "destinations": ["344", "338", "345", "346", "347", "351", "4029", "340", "2948", "302", "580", "629", "353", "490", "523", "534", "502", "478", "521", "469", "488", "342", "337", "1678", "2985"]
}, {
    "City": "Coolangatta",
    "DBTZ": "N",
    "Name": "Gold Coast",
    "Country": "Australia",
    "IATA/FAA": "OOL",
    "Longitude": "153.504722",
    "ICAO": "YBCG",
    "Airport ID": "3321",
    "Latitude": "-28.164444",
    "Timezone": "21",
    "DST": "10",
    "destinations": ["3304", "3992", "2279", "3341", "2006", "2009", "3322", "3339", "4320", "3361", "3324", "3316", "3355"]
}, {
    "City": "Chipata",
    "DBTZ": "U",
    "Name": "Chipata Airport",
    "Country": "Zambia",
    "IATA/FAA": "CIP",
    "Longitude": "32.5872",
    "ICAO": "FLCP",
    "Airport ID": "5612",
    "Latitude": "-13.5583",
    "Timezone": "3360",
    "DST": "2",
    "destinations": ["907"]
}, {
    "City": "Solwesi",
    "DBTZ": "U",
    "Name": "Solwesi Airport",
    "Country": "Zambia",
    "IATA/FAA": "SLI",
    "Longitude": "26.3651",
    "ICAO": "FLSW",
    "Airport ID": "5613",
    "Latitude": "-12.1737",
    "Timezone": "4551",
    "DST": "2",
    "destinations": ["907", "910"]
}, {
    "City": "Waterloo",
    "DBTZ": "A",
    "Name": "Waterloo Regional Airport",
    "Country": "United States",
    "IATA/FAA": "ALO",
    "Longitude": "-92.4003",
    "ICAO": "KALO",
    "Airport ID": "5718",
    "Latitude": "42.5571",
    "Timezone": "873",
    "DST": "-6",
    "destinations": ["3830"]
}, {
    "City": "La Tabatiere",
    "DBTZ": "A",
    "Name": "La Tabatiere Airport",
    "Country": "Canada",
    "IATA/FAA": "ZLT",
    "Longitude": "-58.9756",
    "ICAO": "CTU5",
    "Airport ID": "5474",
    "Latitude": "50.8308",
    "Timezone": "102",
    "DST": "-4",
    "destinations": ["6789", "5473"]
}, {
    "City": "Coari",
    "DBTZ": "S",
    "Name": "Coari Airport",
    "Country": "Brazil",
    "IATA/FAA": "CIZ",
    "Longitude": "-63.140833",
    "ICAO": "SWKO",
    "Airport ID": "7398",
    "Latitude": "-4.085",
    "Timezone": "33",
    "DST": "-4",
    "destinations": ["2551"]
}, {
    "City": "Tallinn-ulemiste International",
    "DBTZ": "E",
    "Name": "Tallinn",
    "Country": "Estonia",
    "IATA/FAA": "TLL",
    "Longitude": "24.832844",
    "ICAO": "EETN",
    "Airport ID": "415",
    "Latitude": "59.413317",
    "Timezone": "131",
    "DST": "2",
    "destinations": ["421", "3953", "644", "1525", "353", "1222", "478", "657", "548", "580", "340", "679", "3959", "737", "302", "609", "2939", "2948", "1354", "1206", "2985", "665", "1701", "502", "1452", "6737", "5596", "413"]
}, {
    "City": "Tartu",
    "DBTZ": "E",
    "Name": "Tartu",
    "Country": "Estonia",
    "IATA/FAA": "TAY",
    "Longitude": "26.690428",
    "ICAO": "EETU",
    "Airport ID": "416",
    "Latitude": "58.307461",
    "Timezone": "219",
    "DST": "2",
    "destinations": ["421"]
}, {
    "City": "Nantong",
    "DBTZ": "U",
    "Name": "Nantong Airport",
    "Country": "China",
    "IATA/FAA": "NTG",
    "Longitude": "120.976",
    "ICAO": "",
    "Airport ID": "5475",
    "Latitude": "32.0708",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3376", "3370", "3404", "3364", "4144", "3374", "3368", "3383", "3393"]
}, {
    "City": "Westerland",
    "DBTZ": "E",
    "Name": "Westerland Sylt",
    "Country": "Germany",
    "IATA/FAA": "GWT",
    "Longitude": "8.340472",
    "ICAO": "EDXW",
    "Airport ID": "410",
    "Latitude": "54.91325",
    "Timezone": "51",
    "DST": "1",
    "destinations": ["342", "345", "346", "350", "351", "1678", "340"]
}, {
    "City": "Kuressaare",
    "DBTZ": "E",
    "Name": "Kuressaare",
    "Country": "Estonia",
    "IATA/FAA": "URE",
    "Longitude": "22.509494",
    "ICAO": "EEKE",
    "Airport ID": "413",
    "Latitude": "58.229883",
    "Timezone": "14",
    "DST": "2",
    "destinations": ["415"]
}, {
    "City": "Chisasibi",
    "DBTZ": "A",
    "Name": "Chisasibi Airport",
    "Country": "Canada",
    "IATA/FAA": "YKU",
    "Longitude": "-78.9169",
    "ICAO": "CSU2",
    "Airport ID": "5472",
    "Latitude": "53.8056",
    "Timezone": "43",
    "DST": "-5",
    "destinations": ["5496", "5507", "5516", "153"]
}, {
    "City": "Paris",
    "DBTZ": "E",
    "Name": "Orly",
    "Country": "France",
    "IATA/FAA": "ORY",
    "Longitude": "2.359444",
    "ICAO": "LFPO",
    "Airport ID": "1386",
    "Latitude": "48.725278",
    "Timezone": "291",
    "DST": "1",
    "destinations": ["1262", "1344", "1278", "1412", "1285", "1416", "1276", "6493", "1409", "1356", "1289", "1417", "3494", "3797", "507", "351", "1324", "1403", "1321", "1280", "1264", "2774", "1330", "1322", "488", "2878", "503", "1529", "1335", "1229", "1606", "1423", "1359", "1353", "1354", "1418", "3998", "2881", "1274", "916", "495", "1435", "1438", "1273", "220", "210", "209", "5552", "235", "221", "231", "6492", "230", "1064", "1074", "5671", "1066", "1070", "1077", "1075", "1072", "1080", "1905", "737", "636", "609", "421", "644", "1230", "1212", "1218", "1216", "1538", "1555", "1563", "1225", "1638", "1054", "1636", "1253", "1057", "1056", "1246", "4330", "4317", "1633", "253", "1084", "915", "2899", "146", "1270", "1279", "3941", "1688", "4315", "1489", "1509", "1200", "293", "1626", "1452", "286", "1561", "1512", "1587", "1562", "1590", "287", "1551", "296", "1897", "1665", "337", "1313", "2988", "1323", "1044", "4091"]
}, {
    "City": "Albany",
    "DBTZ": "A",
    "Name": "Southwest Georgia Regional Airport",
    "Country": "United States",
    "IATA/FAA": "ABY",
    "Longitude": "-84.1945",
    "ICAO": "KABY",
    "Airport ID": "5715",
    "Latitude": "31.5355",
    "Timezone": "197",
    "DST": "-5",
    "destinations": ["3682"]
}, {
    "City": "Paris",
    "DBTZ": "E",
    "Name": "Charles De Gaulle",
    "Country": "France",
    "IATA/FAA": "CDG",
    "Longitude": "2.55",
    "ICAO": "LFPG",
    "Airport ID": "1382",
    "Latitude": "49.012779",
    "Timezone": "392",
    "DST": "1",
    "destinations": ["342", "1701", "3941", "1452", "1472", "1486", "3973", "3876", "3670", "3797", "3304", "507", "3576", "3830", "3752", "1613", "146", "193", "253", "260", "532", "1230", "210", "2170", "580", "737", "3682", "2179", "1218", "1739", "1403", "2177", "888", "469", "1216", "3885", "1044", "608", "1538", "3131", "1264", "2709", "2997", "3448", "353", "2531", "4053", "1489", "883", "1128", "3370", "2851", "1330", "4162", "1322", "1074", "245", "609", "3488", "3093", "1084", "897", "3645", "599", "345", "2188", "535", "3964", "3494", "552", "3988", "1555", "1020", "1563", "340", "2560", "1528", "687", "2564", "1665", "2922", "352", "3199", "1909", "421", "3077", "2359", "3714", "3550", "3930", "2072", "4119", "813", "2939", "3992", "951", "3484", "970", "1197", "2948", "298", "2789", "1529", "1638", "1569", "273", "629", "1335", "1229", "478", "1824", "1606", "1359", "1353", "893", "3858", "346", "1561", "4059", "1354", "521", "999", "280", "1094", "2279", "4161", "1418", "347", "644", "1657", "246", "3364", "3570", "886", "1587", "1871", "1274", "1760", "3406", "1072", "3953", "1415", "1063", "2082", "2650", "3577", "3469", "3205", "3316", "3536", "1194", "350", "666", "2985", "2899", "1273", "1590", "918", "1526", "287", "351", "1551", "1250", "1246", "1550", "679", "3376", "1208", "1678", "220", "221", "231", "1509", "1512", "2954", "293", "286", "2276", "596", "1107", "302", "348", "16", "295", "2057", "1706", "2983", "6152", "1214", "1243", "1520", "680", "523", "517", "3959", "1132", "1200", "1206", "2223", "2207", "1524", "2241", "1582", "1103", "1852", "2878", "1054", "1761", "2881", "1102", "499", "4317", "111", "1064", "1324", "465", "1321", "1280", "1501", "490", "534", "669", "502", "491", "492", "1636", "1075", "1056", "2975", "3024", "916", "5674", "1106", "2194", "1741", "1743", "1626"]
}, {
    "City": "Tete-a-la-Baleine",
    "DBTZ": "A",
    "Name": "Tete-a-la-Baleine Airport",
    "Country": "Canada",
    "IATA/FAA": "ZTB",
    "Longitude": "-59.3836",
    "ICAO": "CTB6",
    "Airport ID": "5473",
    "Latitude": "50.6744",
    "Timezone": "107",
    "DST": "-4",
    "destinations": ["5503", "5474"]
}, {
    "City": "Mcallen",
    "DBTZ": "A",
    "Name": "Mc Allen Miller Intl",
    "Country": "United States",
    "IATA/FAA": "MFE",
    "Longitude": "-98.238611",
    "ICAO": "KMFE",
    "Airport ID": "3826",
    "Latitude": "26.175833",
    "Timezone": "107",
    "DST": "-6",
    "destinations": ["3670", "3877", "3550", "1824", "1842"]
}, {
    "City": "Windsor Locks",
    "DBTZ": "A",
    "Name": "Bradley Intl",
    "Country": "United States",
    "IATA/FAA": "BDL",
    "Longitude": "-72.683222",
    "ICAO": "KBDL",
    "Airport ID": "3825",
    "Latitude": "41.938889",
    "Timezone": "173",
    "DST": "-5",
    "destinations": ["3876", "3520", "3670", "3484", "3576", "3830", "3752", "3570", "146", "193", "3682", "3533", "3878", "3722", "2890", "3646", "3488", "3645", "3858", "3626", "3849", "3747", "3714", "3751", "3877", "3793"]
}, {
    "City": "Cedar City",
    "DBTZ": "A",
    "Name": "Cedar City Rgnl",
    "Country": "United States",
    "IATA/FAA": "CDC",
    "Longitude": "-113.098847",
    "ICAO": "KCDC",
    "Airport ID": "3824",
    "Latitude": "37.700967",
    "Timezone": "5622",
    "DST": "-7",
    "destinations": ["3536"]
}, {
    "City": "Ogoki Post",
    "DBTZ": "A",
    "Name": "Ogoki Post Airport",
    "Country": "Canada",
    "IATA/FAA": "YOG",
    "Longitude": "-85.9017",
    "ICAO": "CNT3",
    "Airport ID": "5470",
    "Latitude": "51.6586",
    "Timezone": "594",
    "DST": "-5",
    "destinations": ["5491"]
}, {
    "City": "Buffalo",
    "DBTZ": "A",
    "Name": "Buffalo Niagara Intl",
    "Country": "United States",
    "IATA/FAA": "BUF",
    "Longitude": "-78.732167",
    "ICAO": "KBUF",
    "Airport ID": "3820",
    "Latitude": "42.940525",
    "Timezone": "724",
    "DST": "-5",
    "destinations": ["3448", "3876", "3520", "3830", "3752", "3682", "3797", "3878", "3645", "3697", "3858", "3849", "3533", "3747", "3486", "3494", "3714", "3877", "3462", "3646"]
}, {
    "City": "Long Beach",
    "DBTZ": "A",
    "Name": "Long Beach",
    "Country": "United States",
    "IATA/FAA": "LGB",
    "Longitude": "-118.151611",
    "ICAO": "KLGB",
    "Airport ID": "3582",
    "Latitude": "33.817722",
    "Timezone": "60",
    "DST": "-8",
    "destinations": ["3720", "3462", "3577", "3673", "3448", "3714", "3797", "3877", "3453", "3469", "3536", "3817"]
}, {
    "City": "Lubbock",
    "DBTZ": "A",
    "Name": "Lubbock Preston Smith Intl",
    "Country": "United States",
    "IATA/FAA": "LBB",
    "Longitude": "-101.822778",
    "ICAO": "KLBB",
    "Airport ID": "3829",
    "Latitude": "33.663639",
    "Timezone": "3282",
    "DST": "-6",
    "destinations": ["3670", "3502", "3560", "3877", "3751", "3550", "3673"]
}, {
    "City": "Gheshm",
    "DBTZ": "E",
    "Name": "Gheshm Airport",
    "Country": "Iran",
    "IATA/FAA": "GSM",
    "Longitude": "56.2688",
    "ICAO": "",
    "Airport ID": "5712",
    "Latitude": "26.9487",
    "Timezone": "100",
    "DST": "3.5",
    "destinations": ["4331", "2157", "2131", "2188", "2109"]
}, {
    "City": "Coldfoot",
    "DBTZ": "A",
    "Name": "Coldfoot Airport",
    "Country": "United States",
    "IATA/FAA": "CXF",
    "Longitude": "-150.176111",
    "ICAO": "",
    "Airport ID": "7119",
    "Latitude": "67.251389",
    "Timezone": "1014",
    "DST": "-9",
    "destinations": ["6712"]
}, {
    "City": "Bhubaneswar",
    "DBTZ": "N",
    "Name": "Bhubaneshwar",
    "Country": "India",
    "IATA/FAA": "BBI",
    "Longitude": "85.817781",
    "ICAO": "VEBS",
    "Airport ID": "3042",
    "Latitude": "20.244364",
    "Timezone": "138",
    "DST": "5.5",
    "destinations": ["3131", "2997", "3043", "3093", "3141", "3066", "3146"]
}, {
    "City": "Scranton",
    "DBTZ": "A",
    "Name": "Wilkes Barre Scranton Intl",
    "Country": "United States",
    "IATA/FAA": "AVP",
    "Longitude": "-75.723403",
    "ICAO": "KAVP",
    "Airport ID": "4286",
    "Latitude": "41.338478",
    "Timezone": "962",
    "DST": "-5",
    "destinations": ["3876", "3752", "3682", "3645", "4167", "3830"]
}, {
    "City": "Bemidji",
    "DBTZ": "A",
    "Name": "Bemidji Regional Airport",
    "Country": "United States",
    "IATA/FAA": "BJI",
    "Longitude": "-94.934722",
    "ICAO": "KBJI",
    "Airport ID": "4287",
    "Latitude": "47.510722",
    "Timezone": "1391",
    "DST": "-6",
    "destinations": ["3858"]
}, {
    "City": "Morgantown",
    "DBTZ": "A",
    "Name": "Morgantown Muni Walter L Bill Hart Fld",
    "Country": "United States",
    "IATA/FAA": "MGW",
    "Longitude": "-79.916314",
    "ICAO": "KMGW",
    "Airport ID": "4284",
    "Latitude": "39.642908",
    "Timezone": "1248",
    "DST": "-5",
    "destinations": ["5730", "3714"]
}, {
    "City": "Charleston",
    "DBTZ": "A",
    "Name": "Yeager",
    "Country": "United States",
    "IATA/FAA": "CRW",
    "Longitude": "-81.593189",
    "ICAO": "KCRW",
    "Airport ID": "4285",
    "Latitude": "38.373147",
    "Timezone": "981",
    "DST": "-5",
    "destinations": ["3682", "3645", "3515"]
}, {
    "City": "London",
    "DBTZ": "E",
    "Name": "Gatwick",
    "Country": "United Kingdom",
    "IATA/FAA": "LGW",
    "Longitude": "-0.190278",
    "ICAO": "EGKK",
    "Airport ID": "502",
    "Latitude": "51.148056",
    "Timezone": "202",
    "DST": "0",
    "destinations": ["1059", "1074", "1080", "3877", "3878", "3646", "1612", "1509", "1512", "1075", "2954", "1055", "1230", "1212", "210", "580", "2874", "1218", "4069", "2875", "1264", "1501", "1852", "1200", "535", "1626", "1555", "534", "1528", "499", "1779", "1197", "1606", "3156", "893", "1561", "1354", "481", "1520", "1198", "1562", "1486", "1056", "1190", "1526", "2894", "1551", "1550", "3953", "2902", "3364", "628", "630", "737", "636", "1489", "609", "1051", "687", "421", "1225", "1479", "1054", "644", "3998", "1206", "666", "663", "665", "664", "467", "599", "600", "2188", "16", "3956", "596", "603", "1253", "498", "1563", "2939", "1638", "1633", "339", "1742", "1688", "1715", "1130", "7447", "4057", "1701", "4317", "1706", "1064", "4315", "1103", "1460", "1474", "4091", "1452", "1468", "1458", "1231", "1760", "1470", "1472", "1102", "1488", "1636", "146", "156", "178", "193", "532", "1324", "3941", "465", "1321", "1538", "302", "4053", "1382", "344", "4029", "345", "1455", "1738", "1665", "342", "1610", "533", "520", "1456", "669", "1213", "1529", "629", "1335", "1229", "1227", "1359", "1353", "346", "1524", "521", "1418", "1657", "1587", "1243", "1194", "1435", "337", "415", "1273", "1590", "1613", "1246", "1208", "1678", "1909", "1780", "2072", "1743", "238"]
}, {
    "City": "White Plains",
    "DBTZ": "A",
    "Name": "Westchester Co",
    "Country": "United States",
    "IATA/FAA": "HPN",
    "Longitude": "-73.707575",
    "ICAO": "KHPN",
    "Airport ID": "3589",
    "Latitude": "41.066959",
    "Timezone": "439",
    "DST": "-5",
    "destinations": ["4278", "5749", "4265", "3876", "3520", "3830", "3752", "3682", "3533", "3878", "3722", "3793", "3646", "3645", "3517"]
}, {
    "City": "Itaituba",
    "DBTZ": "S",
    "Name": "Cachimbo",
    "Country": "Brazil",
    "IATA/FAA": "ITB",
    "Longitude": "-56.00384",
    "ICAO": "",
    "Airport ID": "7534",
    "Latitude": "-4.2446",
    "Timezone": "0",
    "DST": "-4",
    "destinations": ["4092"]
}, {
    "City": "Luebeck",
    "DBTZ": "E",
    "Name": "Lubeck Blankensee",
    "Country": "Germany",
    "IATA/FAA": "LBC",
    "Longitude": "10.719222",
    "ICAO": "EDHL",
    "Airport ID": "364",
    "Latitude": "53.805367",
    "Timezone": "53",
    "DST": "1",
    "destinations": ["1525", "3998", "1562", "668"]
}, {
    "City": "Hamburg",
    "DBTZ": "E",
    "Name": "Hamburg Finkenwerder",
    "Country": "Germany",
    "IATA/FAA": "XFW",
    "Longitude": "9.837025",
    "ICAO": "EDHI",
    "Airport ID": "362",
    "Latitude": "53.535886",
    "Timezone": "22",
    "DST": "1",
    "destinations": ["1273"]
}, {
    "City": "Sao Gabriel da Cachoeira",
    "DBTZ": "S",
    "Name": "Sao Gabriel da Cachoeira Airport",
    "Country": "Brazil",
    "IATA/FAA": "SJL",
    "Longitude": "-66.9858",
    "ICAO": "",
    "Airport ID": "7530",
    "Latitude": "-0.148056",
    "Timezone": "251",
    "DST": "-4",
    "destinations": ["2624"]
}, {
    "City": "Parauapebas",
    "DBTZ": "S",
    "Name": "Carajas Airport",
    "Country": "Brazil",
    "IATA/FAA": "CKS",
    "Longitude": "-50.0035",
    "ICAO": "SBCJ",
    "Airport ID": "7532",
    "Latitude": "-6.11781",
    "Timezone": "2064",
    "DST": "-4",
    "destinations": ["2526", "2537", "2584"]
}, {
    "City": "Friedrichshafen",
    "DBTZ": "E",
    "Name": "Friedrichshafen",
    "Country": "Germany",
    "IATA/FAA": "FDH",
    "Longitude": "9.511486",
    "ICAO": "EDNY",
    "Airport ID": "382",
    "Latitude": "47.671317",
    "Timezone": "1367",
    "DST": "1",
    "destinations": ["345", "1558", "342", "1520", "351", "1209", "344", "1225", "3998", "340", "1688", "1701"]
}, {
    "City": "Jamestown",
    "DBTZ": "A",
    "Name": "Chautauqua County-Jamestown",
    "Country": "United States",
    "IATA/FAA": "JHW",
    "Longitude": "-79.258056",
    "ICAO": "KJHW",
    "Airport ID": "7059",
    "Latitude": "42.153333",
    "Timezone": "525",
    "DST": "-5",
    "destinations": ["5722", "3486"]
}, {
    "City": "Bharatpur",
    "DBTZ": "N",
    "Name": "Bharatpur Airport",
    "Country": "Nepal",
    "IATA/FAA": "BHR",
    "Longitude": "84.4294",
    "ICAO": "VNBP",
    "Airport ID": "6179",
    "Latitude": "27.6781",
    "Timezone": "600",
    "DST": "5.75",
    "destinations": ["3125"]
}, {
    "City": "Barisal",
    "DBTZ": "U",
    "Name": "Barisal Airport",
    "Country": "Bangladesh",
    "IATA/FAA": "BZL",
    "Longitude": "90.3012",
    "ICAO": "VGBR",
    "Airport ID": "6176",
    "Latitude": "22.801",
    "Timezone": "23",
    "DST": "6",
    "destinations": ["3076"]
}, {
    "City": "Dimapur",
    "DBTZ": "N",
    "Name": "Dimapur Airport",
    "Country": "India",
    "IATA/FAA": "DMU",
    "Longitude": "93.7711",
    "ICAO": "VEMR",
    "Airport ID": "6174",
    "Latitude": "25.8839",
    "Timezone": "487",
    "DST": "5.5",
    "destinations": ["3043", "6443"]
}, {
    "City": "Shillong",
    "DBTZ": "N",
    "Name": "Shillong Airport",
    "Country": "India",
    "IATA/FAA": "SHL",
    "Longitude": "91.9787",
    "ICAO": "VEBI",
    "Airport ID": "6172",
    "Latitude": "25.7036",
    "Timezone": "2910",
    "DST": "5.5",
    "destinations": ["3043"]
}, {
    "City": "Guwahati",
    "DBTZ": "N",
    "Name": "Lokpriya Gopinath Bordoloi International Airport",
    "Country": "India",
    "IATA/FAA": "GAU",
    "Longitude": "91.585939",
    "ICAO": "VEGT",
    "Airport ID": "6173",
    "Latitude": "26.106092",
    "Timezone": "162",
    "DST": "5.5",
    "destinations": ["3131", "2997", "3043", "3093", "3050", "3038", "3040", "3039", "6443", "3055", "3885", "3155"]
}, {
    "City": "Walvis Bay",
    "DBTZ": "S",
    "Name": "Walvis Bay Airport",
    "Country": "Namibia",
    "IATA/FAA": "WVB",
    "Longitude": "14.6453",
    "ICAO": "FYWB",
    "Airport ID": "6778",
    "Latitude": "-22.9799",
    "Timezone": "299",
    "DST": "1",
    "destinations": ["797", "813", "4105"]
}, {
    "City": "Mpacha",
    "DBTZ": "S",
    "Name": "Katima Mulilo Airport",
    "Country": "Namibia",
    "IATA/FAA": "MPA",
    "Longitude": "24.1767",
    "ICAO": "FYKM",
    "Airport ID": "6779",
    "Latitude": "-17.6344",
    "Timezone": "3144",
    "DST": "1",
    "destinations": ["5645", "7634"]
}, {
    "City": "Bonaventure",
    "DBTZ": "A",
    "Name": "Bonaventure Airport",
    "Country": "Canada",
    "IATA/FAA": "YVB",
    "Longitude": "-65.4603",
    "ICAO": "CYVB",
    "Airport ID": "6775",
    "Latitude": "48.0711",
    "Timezone": "123",
    "DST": "-5",
    "destinations": ["65", "192", "5538"]
}, {
    "City": "Sialkot",
    "DBTZ": "N",
    "Name": "Sialkot Airport",
    "Country": "Pakistan",
    "IATA/FAA": "SKT",
    "Longitude": "74.3639",
    "ICAO": "OPST",
    "Airport ID": "6776",
    "Latitude": "32.5356",
    "Timezone": "810",
    "DST": "5",
    "destinations": ["2188", "2191", "2057", "2064", "2223", "2072", "2176", "2194", "2082"]
}, {
    "City": "Punta del Este",
    "DBTZ": "S",
    "Name": "Capitan Corbeta C A Curbelo International Airport",
    "Country": "Uruguay",
    "IATA/FAA": "PDP",
    "Longitude": "-55.094278",
    "ICAO": "SULS",
    "Airport ID": "6777",
    "Latitude": "-34.855139",
    "Timezone": "95",
    "DST": "-3",
    "destinations": ["2442", "2440"]
}, {
    "City": "Tanjung Manis",
    "DBTZ": "N",
    "Name": "Tanjung Manis Airport",
    "Country": "Malaysia",
    "IATA/FAA": "TGC",
    "Longitude": "111.202",
    "ICAO": "WBTM",
    "Airport ID": "6770",
    "Latitude": "2.17784",
    "Timezone": "15",
    "DST": "8",
    "destinations": ["3263"]
}, {
    "City": "Walaha",
    "DBTZ": "U",
    "Name": "Walaha Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "WLH",
    "Longitude": "167.691",
    "ICAO": "NVSW",
    "Airport ID": "6771",
    "Latitude": "-15.412",
    "Timezone": "151",
    "DST": "11",
    "destinations": ["5908"]
}, {
    "City": "Chaoyang",
    "DBTZ": "U",
    "Name": "Chaoyang Airport",
    "Country": "China",
    "IATA/FAA": "CHG",
    "Longitude": "120.435",
    "ICAO": "ZYCY",
    "Airport ID": "6772",
    "Latitude": "41.5381",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3364", "3404"]
}, {
    "City": "Samburu South",
    "DBTZ": "U",
    "Name": "Samburu South Airport",
    "Country": "Kenya",
    "IATA/FAA": "UAS",
    "Longitude": "37.5342",
    "ICAO": "HKSB",
    "Airport ID": "6773",
    "Latitude": "0.530583",
    "Timezone": "3295",
    "DST": "3",
    "destinations": ["5695", "1147"]
}, {
    "City": "Lakeba Island",
    "DBTZ": "U",
    "Name": "Lakeba Island Airport",
    "Country": "Fiji",
    "IATA/FAA": "LKB",
    "Longitude": "-178.817",
    "ICAO": "NFNK",
    "Airport ID": "5873",
    "Latitude": "-18.1992",
    "Timezone": "280",
    "DST": "12",
    "destinations": ["1961"]
}, {
    "City": "Rotuma",
    "DBTZ": "U",
    "Name": "Rotuma Airport",
    "Country": "Fiji",
    "IATA/FAA": "RTA",
    "Longitude": "177.071",
    "ICAO": "NFNR",
    "Airport ID": "5877",
    "Latitude": "-12.4825",
    "Timezone": "22",
    "DST": "12",
    "destinations": ["1960"]
}, {
    "City": "Lambasa",
    "DBTZ": "U",
    "Name": "Labasa Airport",
    "Country": "Fiji",
    "IATA/FAA": "LBS",
    "Longitude": "179.33986",
    "ICAO": "NFNL",
    "Airport ID": "5874",
    "Latitude": "-16.466749",
    "Timezone": "44",
    "DST": "12",
    "destinations": ["1960", "1961"]
}, {
    "City": "Matei",
    "DBTZ": "U",
    "Name": "Matei Airport",
    "Country": "Fiji",
    "IATA/FAA": "TVU",
    "Longitude": "-179.877",
    "ICAO": "NFNM",
    "Airport ID": "5875",
    "Latitude": "-16.6906",
    "Timezone": "60",
    "DST": "12",
    "destinations": ["1960", "1961", "5878"]
}, {
    "City": "Juliaca",
    "DBTZ": "U",
    "Name": "Juliaca",
    "Country": "Peru",
    "IATA/FAA": "JUL",
    "Longitude": "-70.158169",
    "ICAO": "SPJL",
    "Airport ID": "2792",
    "Latitude": "-15.467103",
    "Timezone": "12552",
    "DST": "-5",
    "destinations": ["2802", "2812", "2789"]
}, {
    "City": "Savusavu",
    "DBTZ": "U",
    "Name": "Savusavu Airport",
    "Country": "Fiji",
    "IATA/FAA": "SVU",
    "Longitude": "179.341",
    "ICAO": "NFNS",
    "Airport ID": "5878",
    "Latitude": "-16.8028",
    "Timezone": "17",
    "DST": "12",
    "destinations": ["1960", "1961", "5875"]
}, {
    "City": "Kundiawa",
    "DBTZ": "U",
    "Name": "Chimbu Airport",
    "Country": "Papua New Guinea",
    "IATA/FAA": "CMU",
    "Longitude": "144.971",
    "ICAO": "AYCH",
    "Airport ID": "5420",
    "Latitude": "-6.02429",
    "Timezone": "4974",
    "DST": "10",
    "destinations": ["5"]
}, {
    "City": "Columbus",
    "DBTZ": "A",
    "Name": "Port Columbus Intl",
    "Country": "United States",
    "IATA/FAA": "CMH",
    "Longitude": "-82.891889",
    "ICAO": "KCMH",
    "Airport ID": "3759",
    "Latitude": "39.997972",
    "Timezone": "815",
    "DST": "-5",
    "destinations": ["3876", "3520", "3670", "3797", "3484", "3697", "3576", "3830", "3752", "3462", "193", "3682", "3448", "3645", "3878", "3858", "3626", "3793", "3646", "3849", "3747", "3807", "3678", "3486", "3751", "3494", "3714", "3550", "1948", "3690", "3533", "3877"]
}, {
    "City": "Santa Rosa",
    "DBTZ": "S",
    "Name": "Santa Rosa Airport",
    "Country": "Brazil",
    "IATA/FAA": "SRA",
    "Longitude": "-54.5204",
    "ICAO": "SSZR",
    "Airport ID": "6069",
    "Latitude": "-27.9067",
    "Timezone": "984",
    "DST": "-3",
    "destinations": ["2596"]
}, {
    "City": "Ponta Delgada",
    "DBTZ": "E",
    "Name": "Ponta Delgada",
    "Country": "Portugal",
    "IATA/FAA": "PDL",
    "Longitude": "-25.69787",
    "ICAO": "LPPD",
    "Airport ID": "1633",
    "Latitude": "37.741184",
    "Timezone": "259",
    "DST": "-1",
    "destinations": ["345", "4091", "580", "737", "3448", "340", "502", "1638", "1636", "1386", "193", "1625", "1628", "1634", "1639", "1617", "1629"]
}, {
    "City": "Denver",
    "DBTZ": "A",
    "Name": "Denver Intl",
    "Country": "United States",
    "IATA/FAA": "DEN",
    "Longitude": "-104.673178",
    "ICAO": "KDEN",
    "Airport ID": "3751",
    "Latitude": "39.861656",
    "Timezone": "5431",
    "DST": "-7",
    "destinations": ["3876", "3670", "3484", "507", "3576", "3830", "3752", "3462", "3577", "3682", "3861", "49", "90", "120", "146", "156", "160", "166", "178", "193", "3448", "3797", "3488", "3645", "3697", "3473", "3858", "3536", "7067", "7073", "3673", "4083", "6962", "4037", "3690", "4020", "4043", "3486", "1852", "3804", "1800", "3520", "3729", "4099", "4292", "3687", "3533", "3743", "4009", "3467", "4008", "3550", "3859", "3585", "3877", "1881", "3660", "3458", "3878", "3542", "3747", "3717", "3498", "3459", "3863", "3454", "3720", "3633", "5761", "3839", "1836", "3793", "3731", "3949", "3469", "1840", "1885", "3867", "3678", "3646", "3676", "16", "4019", "3840", "3849", "3759", "3734", "3611", "3621", "3817", "3636", "340", "2279", "3774", "7001", "3558", "3811", "4021", "3495", "3644", "4344", "3819", "3872", "3627", "7064", "3713", "4250", "3559", "3494", "4127", "4296", "4063", "3685", "3880", "7051", "4339", "3504", "3728", "4113", "3714", "3457", "4100", "3659", "4027", "3514", "5746", "3829", "3602", "3543", "3652", "1824", "4101", "4072", "3948", "4216", "6880", "3456", "4046", "3570", "6133", "3527", "4087", "4103", "5766", "3807", "3784", "4014", "4348", "4064", "3609", "3748", "3855", "4356", "3825", "4112", "3566", "3712", "3453", "3626", "5723", "4338", "5733", "6129", "5748", "4341", "7068", "4124", "6881", "5769"]
}, {
    "City": "Punta Gorda",
    "DBTZ": "A",
    "Name": "Charlotte County-Punta Gorda Airport",
    "Country": "United States",
    "IATA/FAA": "PGD",
    "Longitude": "-81.990556",
    "ICAO": "KPGD",
    "Airport ID": "7056",
    "Latitude": "26.919722",
    "Timezone": "26",
    "DST": "-5",
    "destinations": ["4355", "4007", "4043", "3488", "3729", "4041", "4034", "3630", "3857", "4017", "4046", "4028", "4359", "4348", "4336", "4040", "3676", "3881"]
}, {
    "City": "Sioux City",
    "DBTZ": "A",
    "Name": "Sioux Gateway Col Bud Day Fld",
    "Country": "United States",
    "IATA/FAA": "SUX",
    "Longitude": "-96.384367",
    "ICAO": "KSUX",
    "Airport ID": "3753",
    "Latitude": "42.402603",
    "Timezone": "1098",
    "DST": "-6",
    "destinations": ["3830"]
}, {
    "City": "Philadelphia",
    "DBTZ": "A",
    "Name": "Philadelphia Intl",
    "Country": "United States",
    "IATA/FAA": "PHL",
    "Longitude": "-75.241139",
    "ICAO": "KPHL",
    "Airport ID": "3752",
    "Latitude": "39.871944",
    "Timezone": "36",
    "DST": "-5",
    "destinations": ["3941", "4355", "3864", "580", "3838", "3682", "2895", "3673", "4286", "1218", "4069", "3825", "4129", "3463", "3811", "3690", "3448", "302", "3711", "3820", "3849", "3561", "4112", "1382", "4015", "3806", "3486", "3876", "3759", "1852", "3488", "3627", "3520", "3751", "3670", "2241", "3645", "599", "3946", "4276", "3494", "1555", "3533", "340", "1926", "534", "4008", "4034", "3589", "4006", "3550", "3845", "3585", "3584", "3857", "3947", "3712", "3877", "3484", "3697", "507", "1638", "1229", "478", "1780", "3458", "3878", "3542", "4011", "3576", "3717", "3858", "3861", "346", "3515", "1953", "3830", "3611", "3722", "3720", "3633", "3462", "3570", "1754", "1760", "3641", "3862", "3626", "3608", "4016", "3622", "3793", "3731", "3621", "3613", "3642", "4318", "4014", "1762", "3577", "3469", "2890", "3536", "3678", "2883", "3661", "2899", "3745", "1590", "3646", "3676", "1551", "73", "100", "111", "146", "193", "1678", "3797", "3457", "3747", "3714"]
}, {
    "City": "Macon",
    "DBTZ": "A",
    "Name": "Middle Georgia Rgnl",
    "Country": "United States",
    "IATA/FAA": "MCN",
    "Longitude": "-83.649211",
    "ICAO": "KMCN",
    "Airport ID": "3754",
    "Latitude": "32.69285",
    "Timezone": "354",
    "DST": "-5",
    "destinations": ["3682", "3878"]
}, {
    "City": "Sharjah",
    "DBTZ": "U",
    "Name": "Sharjah Intl",
    "Country": "United Arab Emirates",
    "IATA/FAA": "SHJ",
    "Longitude": "55.51715",
    "ICAO": "OMSJ",
    "Airport ID": "2191",
    "Latitude": "25.328575",
    "Timezone": "111",
    "DST": "4",
    "destinations": ["2979", "3136", "3137", "3141", "3144", "3153", "3977", "2059", "2908", "2994", "2170", "5691", "2057", "2177", "4130", "3131", "2997", "2234", "1128", "3069", "2914", "3135", "3024", "3076", "3093", "4029", "2064", "2241", "2940", "3989", "2067", "3964", "3007", "2069", "3410", "8235", "2060", "6105", "4330", "3098", "2072", "2939", "2206", "1175", "3125", "2176", "2990", "5934", "2194", "2074", "4331", "3015", "4059", "7490", "2947", "2219", "2964", "2082", "4317", "6776", "2195", "2975", "2157", "2090", "2910", "2992", "2096", "2983", "3143", "3017", "5942", "2223", "2207", "2214", "5951", "2221", "2179", "1121"]
}, {
    "City": "Khasab",
    "DBTZ": "U",
    "Name": "Khasab",
    "Country": "Oman",
    "IATA/FAA": "KHS",
    "Longitude": "56.240569",
    "ICAO": "OOKB",
    "Airport ID": "2192",
    "Latitude": "26.170986",
    "Timezone": "100",
    "DST": "4",
    "destinations": ["2194"]
}, {
    "City": "Muscat",
    "DBTZ": "U",
    "Name": "Seeb Intl",
    "Country": "Oman",
    "IATA/FAA": "MCT",
    "Longitude": "58.284444",
    "ICAO": "OOMS",
    "Airport ID": "2194",
    "Latitude": "23.593278",
    "Timezone": "48",
    "DST": "4",
    "destinations": ["3069", "3076", "2997", "3136", "3153", "2179", "2241", "3093", "3141", "3144", "2188", "2157", "2166", "1107", "8076", "2191", "2057", "3137", "3143", "2176", "3304", "3024", "1128", "2207", "2219", "6776", "2203", "2223", "2206", "5951", "2170", "2994", "2074", "2082", "1701", "1678", "2177", "3885", "3131", "1382", "2064", "340", "4330", "3098", "2072", "2192", "3125", "507", "3105", "3156", "346", "1524", "2195", "1186"]
}, {
    "City": "Salalah",
    "DBTZ": "U",
    "Name": "Salalah",
    "Country": "Oman",
    "IATA/FAA": "SLL",
    "Longitude": "54.091297",
    "ICAO": "OOSA",
    "Airport ID": "2195",
    "Latitude": "17.038719",
    "Timezone": "73",
    "DST": "4",
    "destinations": ["2188", "2191", "3137", "2241", "2072", "2194"]
}, {
    "City": "Takoradi",
    "DBTZ": "N",
    "Name": "Takoradi",
    "Country": "Ghana",
    "IATA/FAA": "TKD",
    "Longitude": "-1.774756",
    "ICAO": "DGTK",
    "Airport ID": "252",
    "Latitude": "4.896056",
    "Timezone": "21",
    "DST": "0",
    "destinations": ["248"]
}, {
    "City": "Abidjan",
    "DBTZ": "N",
    "Name": "Abidjan Felix Houphouet Boigny Intl",
    "Country": "Cote d'Ivoire",
    "IATA/FAA": "ABJ",
    "Longitude": "-3.926294",
    "ICAO": "DIAP",
    "Airport ID": "253",
    "Latitude": "5.261386",
    "Timezone": "21",
    "DST": "0",
    "destinations": ["247", "246", "302", "1382", "210", "1074", "245", "1084", "248", "1059", "1063", "4162", "298", "273", "1044", "897", "970", "4059", "1386", "1701", "287"]
}, {
    "City": "Sunyani",
    "DBTZ": "N",
    "Name": "Sunyani",
    "Country": "Ghana",
    "IATA/FAA": "NYI",
    "Longitude": "-2.328756",
    "ICAO": "DGSN",
    "Airport ID": "251",
    "Latitude": "7.361828",
    "Timezone": "1014",
    "DST": "0",
    "destinations": ["248"]
}, {
    "City": "Angling Lake",
    "DBTZ": "A",
    "Name": "Wapekeka Airport",
    "Country": "Canada",
    "IATA/FAA": "YAX",
    "Longitude": "-89.5794",
    "ICAO": "CKB6",
    "Airport ID": "5465",
    "Latitude": "53.8492",
    "Timezone": "712",
    "DST": "-6",
    "destinations": ["5468", "5531"]
}, {
    "City": "Faisalabad",
    "DBTZ": "N",
    "Name": "Faisalabad Intl",
    "Country": "Pakistan",
    "IATA/FAA": "LYP",
    "Longitude": "72.994842",
    "ICAO": "OPFA",
    "Airport ID": "2202",
    "Latitude": "31.365014",
    "Timezone": "591",
    "DST": "5",
    "destinations": ["2206"]
}, {
    "City": "Gwadar",
    "DBTZ": "N",
    "Name": "Gwadar",
    "Country": "Pakistan",
    "IATA/FAA": "GWD",
    "Longitude": "62.329494",
    "ICAO": "OPGD",
    "Airport ID": "2203",
    "Latitude": "25.233308",
    "Timezone": "36",
    "DST": "5",
    "destinations": ["2206", "2194", "5951"]
}, {
    "City": "Gilgit",
    "DBTZ": "N",
    "Name": "Gilgit",
    "Country": "Pakistan",
    "IATA/FAA": "GIL",
    "Longitude": "74.333644",
    "ICAO": "OPGT",
    "Airport ID": "2204",
    "Latitude": "35.918786",
    "Timezone": "4796",
    "DST": "5",
    "destinations": ["2223"]
}, {
    "City": "Karachi",
    "DBTZ": "N",
    "Name": "Jinnah Intl",
    "Country": "Pakistan",
    "IATA/FAA": "KHI",
    "Longitude": "67.160797",
    "ICAO": "OPKC",
    "Airport ID": "2206",
    "Latitude": "24.906547",
    "Timezone": "100",
    "DST": "5",
    "destinations": ["2179", "3395", "3885", "2188", "2191", "2057", "5939", "2223", "2207", "2202", "2214", "2219", "2221", "4330", "3024", "2997", "3076", "5942", "3093", "2064", "2203", "2072", "3125", "3304", "507", "2194", "2074", "2212", "2217", "2082", "2222", "2227", "5951", "193", "2241", "1701"]
}, {
    "City": "Lahore",
    "DBTZ": "N",
    "Name": "Allama Iqbal Intl",
    "Country": "Pakistan",
    "IATA/FAA": "LHE",
    "Longitude": "74.403594",
    "ICAO": "OPLA",
    "Airport ID": "2207",
    "Latitude": "31.521564",
    "Timezone": "712",
    "DST": "5",
    "destinations": ["2179", "2188", "2057", "5939", "2206", "2221", "2176", "2064", "2072", "2194", "4331", "2082", "1218", "5942", "3093", "2241", "2223", "3304", "507", "478", "2074", "2214", "1524", "644", "2219", "2222", "2191", "2227", "193", "3885", "1701"]
}, {
    "City": "Lethbridge",
    "DBTZ": "A",
    "Name": "Lethbridge",
    "Country": "Canada",
    "IATA/FAA": "YQL",
    "Longitude": "-112.799722",
    "ICAO": "CYQL",
    "Airport ID": "116",
    "Latitude": "49.630278",
    "Timezone": "3047",
    "DST": "-7",
    "destinations": ["178"]
}, {
    "City": "Iloilo",
    "DBTZ": "N",
    "Name": "Iloilo",
    "Country": "Philippines",
    "IATA/FAA": "ILO",
    "Longitude": "122.545297",
    "ICAO": "RPVI",
    "Airport ID": "2429",
    "Latitude": "10.713044",
    "Timezone": "27",
    "DST": "8",
    "destinations": ["4206", "2400", "4090", "6011", "3077", "2397", "2433", "3316"]
}, {
    "City": "Tacloban",
    "DBTZ": "N",
    "Name": "Daniel Z Romualdez",
    "Country": "Philippines",
    "IATA/FAA": "TAC",
    "Longitude": "125.027758",
    "ICAO": "RPVA",
    "Airport ID": "2422",
    "Latitude": "11.227628",
    "Timezone": "10",
    "DST": "8",
    "destinations": ["4206", "2397"]
}, {
    "City": "Bacolod",
    "DBTZ": "N",
    "Name": "Bacolod",
    "Country": "Philippines",
    "IATA/FAA": "BCD",
    "Longitude": "122.929617",
    "ICAO": "RPVB",
    "Airport ID": "2423",
    "Latitude": "10.642511",
    "Timezone": "25",
    "DST": "8",
    "destinations": ["4206", "2400", "4090", "2397"]
}, {
    "City": "Dumaguete",
    "DBTZ": "N",
    "Name": "Dumaguete",
    "Country": "Philippines",
    "IATA/FAA": "DGT",
    "Longitude": "123.300472",
    "ICAO": "RPVD",
    "Airport ID": "2425",
    "Latitude": "9.333714",
    "Timezone": "15",
    "DST": "8",
    "destinations": ["2397", "4206"]
}, {
    "City": "Garoua",
    "DBTZ": "N",
    "Name": "Garoua",
    "Country": "Cameroon",
    "IATA/FAA": "GOU",
    "Longitude": "13.370103",
    "ICAO": "FKKR",
    "Airport ID": "901",
    "Latitude": "9.335892",
    "Timezone": "794",
    "DST": "1",
    "destinations": ["999", "4161"]
}, {
    "City": "Fort Saint John",
    "DBTZ": "A",
    "Name": "Fort St John",
    "Country": "Canada",
    "IATA/FAA": "YXJ",
    "Longitude": "-120.740278",
    "ICAO": "CYXJ",
    "Airport ID": "168",
    "Latitude": "56.238056",
    "Timezone": "2280",
    "DST": "-7",
    "destinations": ["49", "172", "180", "156", "178"]
}, {
    "City": "Sioux Lookout",
    "DBTZ": "A",
    "Name": "Sioux Lookout",
    "Country": "Canada",
    "IATA/FAA": "YXL",
    "Longitude": "-91.905278",
    "ICAO": "CYXL",
    "Airport ID": "169",
    "Latitude": "50.113889",
    "Timezone": "1258",
    "DST": "-6",
    "destinations": ["5479", "67", "121", "5527", "5469", "5544", "5466", "5468", "5480", "5478", "5465", "5489", "5531", "5546", "5547", "5548"]
}, {
    "City": "Cranbrook",
    "DBTZ": "A",
    "Name": "Canadian Rockies Intl",
    "Country": "Canada",
    "IATA/FAA": "YXC",
    "Longitude": "-115.781944",
    "ICAO": "CYXC",
    "Airport ID": "164",
    "Latitude": "49.612222",
    "Timezone": "3084",
    "DST": "-7",
    "destinations": ["87", "156", "178"]
}, {
    "City": "Saskatoon",
    "DBTZ": "N",
    "Name": "Saskatoon J G Diefenbaker Intl",
    "Country": "Canada",
    "IATA/FAA": "YXE",
    "Longitude": "-106.699722",
    "ICAO": "CYXE",
    "Airport ID": "166",
    "Latitude": "52.170834",
    "Timezone": "1653",
    "DST": "-6",
    "destinations": ["3751", "49", "156", "160", "178", "193", "3858", "3830", "3877"]
}, {
    "City": "Medicine Hat",
    "DBTZ": "A",
    "Name": "Medicine Hat",
    "Country": "Canada",
    "IATA/FAA": "YXH",
    "Longitude": "-110.720833",
    "ICAO": "CYXH",
    "Airport ID": "167",
    "Latitude": "50.01889",
    "Timezone": "2352",
    "DST": "-7",
    "destinations": ["178"]
}, {
    "City": "Winnipeg",
    "DBTZ": "A",
    "Name": "Winnipeg Intl",
    "Country": "Canada",
    "IATA/FAA": "YWG",
    "Longitude": "-97.239886",
    "ICAO": "CYWG",
    "Airport ID": "160",
    "Latitude": "49.910036",
    "Timezone": "783",
    "DST": "-6",
    "destinations": ["132", "187", "3751", "3830", "49", "100", "120", "121", "146", "156", "166", "178", "193", "3858", "58", "5524", "115", "5527", "5497", "5529", "141", "3877", "174"]
}, {
    "City": "Wabush",
    "DBTZ": "A",
    "Name": "Wabush",
    "Country": "Canada",
    "IATA/FAA": "YWK",
    "Longitude": "-66.864444",
    "ICAO": "CYWK",
    "Airport ID": "161",
    "Latitude": "52.921944",
    "Timezone": "1808",
    "DST": "-4",
    "destinations": ["80", "202", "188", "27", "28", "6775", "36", "146", "5550", "192"]
}, {
    "City": "Williams Lake",
    "DBTZ": "A",
    "Name": "Williams Lake",
    "Country": "Canada",
    "IATA/FAA": "YWL",
    "Longitude": "-122.054167",
    "ICAO": "CYWL",
    "Airport ID": "162",
    "Latitude": "52.183056",
    "Timezone": "3085",
    "DST": "-8",
    "destinations": ["156", "127"]
}, {
    "City": "Liuzhou",
    "DBTZ": "U",
    "Name": "Bailian Airport",
    "Country": "China",
    "IATA/FAA": "LZH",
    "Longitude": "109.391",
    "ICAO": "ZGZH",
    "Airport ID": "6356",
    "Latitude": "24.2075",
    "Timezone": "295",
    "DST": "8",
    "destinations": ["3364", "3370", "3395", "3382", "3374", "3383", "3393", "6355", "3371", "3406", "3391", "4030", "3376"]
}, {
    "City": "Zhanjiang",
    "DBTZ": "U",
    "Name": "Zhanjiang Airport",
    "Country": "China",
    "IATA/FAA": "ZHA",
    "Longitude": "110.358",
    "ICAO": "ZGZJ",
    "Airport ID": "6357",
    "Latitude": "21.2144",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3393", "3077", "3406", "3371", "3395", "3364", "3374", "3370", "4030", "4120", "3382", "4302", "6341"]
}, {
    "City": "Meixian",
    "DBTZ": "U",
    "Name": "Meixian Airport",
    "Country": "China",
    "IATA/FAA": "MXZ",
    "Longitude": "116.133",
    "ICAO": "ZGMX",
    "Airport ID": "6354",
    "Latitude": "24.35",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["2268", "3370", "3077", "3374", "6355"]
}, {
    "City": "Zhuhai",
    "DBTZ": "U",
    "Name": "Zhuhai Airport",
    "Country": "China",
    "IATA/FAA": "ZUH",
    "Longitude": "113.376",
    "ICAO": "ZGSD",
    "Airport ID": "6355",
    "Latitude": "22.0064",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["4109", "4120", "3406", "3391", "4030", "3368", "3393", "3395", "3385", "6384", "3384", "3394", "3373", "3364", "6392", "3383", "3375", "3371", "3389", "3386", "3382", "6354", "4302", "3376", "6394", "6356", "6744", "6379", "3372", "3387"]
}, {
    "City": "Changde",
    "DBTZ": "U",
    "Name": "Changde Airport",
    "Country": "China",
    "IATA/FAA": "CGD",
    "Longitude": "111.64",
    "ICAO": "ZGCD",
    "Airport ID": "6352",
    "Latitude": "28.9189",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3382", "3391", "3370", "3364", "3374", "4120", "3379", "3383"]
}, {
    "City": "Dayong",
    "DBTZ": "U",
    "Name": "Dayong Airport",
    "Country": "China",
    "IATA/FAA": "DYG",
    "Longitude": "110.443",
    "ICAO": "ZGDY",
    "Airport ID": "6353",
    "Latitude": "29.1028",
    "Timezone": "692",
    "DST": "8",
    "destinations": ["3393", "3388", "3406", "3371", "3368", "3364", "3370", "3374", "2276", "3369", "3379", "4109"]
}, {
    "City": "Xilinhot",
    "DBTZ": "U",
    "Name": "Xilinhot Airport",
    "Country": "China",
    "IATA/FAA": "XIL",
    "Longitude": "115.964",
    "ICAO": "ZBXH",
    "Airport ID": "6350",
    "Latitude": "43.9156",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3364", "6345", "6341"]
}, {
    "City": "Beihai",
    "DBTZ": "U",
    "Name": "Beihai Airport",
    "Country": "China",
    "IATA/FAA": "BHY",
    "Longitude": "109.294",
    "ICAO": "ZGBH",
    "Airport ID": "6351",
    "Latitude": "21.5394",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3379", "3386", "3364", "3370", "3375", "3393", "3371", "3382", "6347", "3395", "3372", "4120", "4148", "3406", "3374"]
}, {
    "City": "Atlanta",
    "DBTZ": "A",
    "Name": "Hartsfield Jackson Atlanta Intl",
    "Country": "United States",
    "IATA/FAA": "ATL",
    "Longitude": "-84.428067",
    "ICAO": "KATL",
    "Airport ID": "3682",
    "Latitude": "33.636719",
    "Timezone": "1026",
    "DST": "-5",
    "destinations": ["6958", "3754", "4335", "5756", "5759", "5773", "4039", "3578", "4043", "4285", "3488", "4018", "4041", "4008", "4034", "3544", "4017", "4128", "3858", "4016", "3745", "3676", "4356", "3876", "3670", "507", "3576", "3830", "3752", "3462", "3751", "193", "4355", "4019", "5715", "3852", "3658", "3864", "4047", "3673", "4007", "4286", "3825", "3811", "4037", "3690", "3448", "5725", "302", "3846", "3711", "3820", "3849", "3561", "4112", "1382", "4015", "3806", "3486", "3759", "3819", "4255", "1852", "1800", "3950", "3502", "3627", "3520", "3739", "3729", "3645", "599", "345", "7669", "3559", "3730", "3494", "3875", "5738", "1555", "3533", "4089", "340", "3437", "1804", "3472", "4038", "3653", "3775", "3685", "4273", "3589", "4113", "3714", "3550", "3457", "3845", "3585", "3580", "3712", "3797", "3877", "3484", "3493", "3697", "3660", "1229", "478", "3458", "3878", "3542", "3747", "3473", "1824", "4115", "4011", "3717", "3671", "4072", "3732", "3782", "3459", "3861", "1825", "346", "3515", "1953", "4386", "3863", "3454", "3611", "3722", "3720", "3633", "4046", "3570", "3564", "1871", "3641", "3862", "3626", "3608", "3622", "3793", "3731", "3621", "3613", "4359", "4014", "3577", "3469", "4348", "3609", "1885", "2890", "3536", "3817", "3867", "4067", "3678", "350", "3698", "3646", "4116", "3855", "3636", "5776", "3873", "146", "1836", "1840", "3566", "580", "2895", "4069", "1815", "2709", "2896", "2531", "1957", "2851", "2188", "3988", "4292", "1948", "4009", "1926", "1941", "2560", "2564", "1767", "3728", "3930", "813", "1779", "2789", "1881", "273", "1780", "1863", "2279", "1897", "1754", "1760", "1774", "1892", "1772", "2650", "1762", "3748", "2883", "2899", "1776", "2688", "2894", "1678", "3447"]
}, {
    "City": "Cincinnati",
    "DBTZ": "A",
    "Name": "Cincinnati Muni Lunken Fld",
    "Country": "United States",
    "IATA/FAA": "LUK",
    "Longitude": "-84.418611",
    "ICAO": "KLUK",
    "Airport ID": "3681",
    "Latitude": "39.103333",
    "Timezone": "483",
    "DST": "-5",
    "destinations": ["3876", "3747", "7657"]
}, {
    "City": "Wichita Falls",
    "DBTZ": "A",
    "Name": "Sheppard Afb Wichita Falls Muni",
    "Country": "United States",
    "IATA/FAA": "SPS",
    "Longitude": "-98.491894",
    "ICAO": "KSPS",
    "Airport ID": "3680",
    "Latitude": "33.988797",
    "Timezone": "1019",
    "DST": "-6",
    "destinations": ["3670"]
}, {
    "City": "Fresno",
    "DBTZ": "A",
    "Name": "Fresno Yosemite Intl",
    "Country": "United States",
    "IATA/FAA": "FAT",
    "Longitude": "-119.71814",
    "ICAO": "KFAT",
    "Airport ID": "3687",
    "Latitude": "36.776194",
    "Timezone": "336",
    "DST": "-8",
    "destinations": ["3670", "3484", "3462", "1804", "3720", "3731", "3577", "3536", "3751", "3877", "3469"]
}, {
    "City": "Enshi",
    "DBTZ": "U",
    "Name": "Enshi Airport",
    "Country": "China",
    "IATA/FAA": "ENH",
    "Longitude": "109.485",
    "ICAO": "ZHES",
    "Airport ID": "6358",
    "Latitude": "30.3203",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3376"]
}, {
    "City": "Nanyang",
    "DBTZ": "U",
    "Name": "Nanyang Airport",
    "Country": "China",
    "IATA/FAA": "NNY",
    "Longitude": "112.615",
    "ICAO": "ZHNY",
    "Airport ID": "6359",
    "Latitude": "32.9808",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3370", "3375", "3395", "3386", "3391", "3374", "6341"]
}, {
    "City": "Pleiku",
    "DBTZ": "U",
    "Name": "Pleiku Airport",
    "Country": "Vietnam",
    "IATA/FAA": "PXU",
    "Longitude": "108.017158",
    "ICAO": "VVPK",
    "Airport ID": "6194",
    "Latitude": "14.004522",
    "Timezone": "2434",
    "DST": "7",
    "destinations": ["3196", "3199", "3205"]
}, {
    "City": "Vinh",
    "DBTZ": "U",
    "Name": "Vinh Airport",
    "Country": "Vietnam",
    "IATA/FAA": "VII",
    "Longitude": "105.670764",
    "ICAO": "VVVH",
    "Airport ID": "6195",
    "Latitude": "18.737569",
    "Timezone": "23",
    "DST": "7",
    "destinations": ["6187", "3205", "3196", "3199", "3120"]
}, {
    "City": "Dawei",
    "DBTZ": "U",
    "Name": "Dawei Airport",
    "Country": "Burma",
    "IATA/FAA": "TVY",
    "Longitude": "98.203636",
    "ICAO": "VYDW",
    "Airport ID": "6197",
    "Latitude": "14.103886",
    "Timezone": "84",
    "DST": "6.5",
    "destinations": ["3223", "3239"]
}, {
    "City": "Conceicao Do Araguaia",
    "DBTZ": "S",
    "Name": "Conceicao Do Araguaia",
    "Country": "Brazil",
    "IATA/FAA": "CDJ",
    "Longitude": "-49.301528",
    "ICAO": "SBAA",
    "Airport ID": "2518",
    "Latitude": "-8.348347",
    "Timezone": "653",
    "DST": "-4",
    "destinations": ["7369"]
}, {
    "City": "Can Tho",
    "DBTZ": "U",
    "Name": "Tra Noc Airport",
    "Country": "Vietnam",
    "IATA/FAA": "VCA",
    "Longitude": "105.711922",
    "ICAO": "VVCT",
    "Airport ID": "6191",
    "Latitude": "10.085119",
    "Timezone": "9",
    "DST": "7",
    "destinations": ["3199", "4168", "6190"]
}, {
    "City": "Dienbienphu",
    "DBTZ": "U",
    "Name": "Dien Bien Phu Airport",
    "Country": "Vietnam",
    "IATA/FAA": "DIN",
    "Longitude": "103.007831",
    "ICAO": "VVDB",
    "Airport ID": "6192",
    "Latitude": "21.397481",
    "Timezone": "1611",
    "DST": "7",
    "destinations": ["3199"]
}, {
    "City": "Phucat",
    "DBTZ": "U",
    "Name": "Phu Cat Airport",
    "Country": "Vietnam",
    "IATA/FAA": "UIH",
    "Longitude": "109.042267",
    "ICAO": "VVPC",
    "Airport ID": "6193",
    "Latitude": "13.954986",
    "Timezone": "80",
    "DST": "7",
    "destinations": ["3205", "3199"]
}, {
    "City": "San Martin Des Andes",
    "DBTZ": "N",
    "Name": "Aviador C Campos",
    "Country": "Argentina",
    "IATA/FAA": "CPC",
    "Longitude": "-71.137294",
    "ICAO": "SAZY",
    "Airport ID": "2517",
    "Latitude": "-40.075383",
    "Timezone": "2569",
    "DST": "-3",
    "destinations": ["2442"]
}, {
    "City": "Kawthoung",
    "DBTZ": "U",
    "Name": "Kawthoung Airport",
    "Country": "Burma",
    "IATA/FAA": "KAW",
    "Longitude": "98.538006",
    "ICAO": "VYKT",
    "Airport ID": "6198",
    "Latitude": "10.049258",
    "Timezone": "180",
    "DST": "6.5",
    "destinations": ["3223"]
}, {
    "City": "Santa Rosa",
    "DBTZ": "N",
    "Name": "Santa Rosa",
    "Country": "Argentina",
    "IATA/FAA": "RSA",
    "Longitude": "-64.275694",
    "ICAO": "SAZR",
    "Airport ID": "2512",
    "Latitude": "-36.588322",
    "Timezone": "630",
    "DST": "-3",
    "destinations": ["2454"]
}, {
    "City": "San Carlos De Bariloche",
    "DBTZ": "N",
    "Name": "San Carlos De Bariloche",
    "Country": "Argentina",
    "IATA/FAA": "BRC",
    "Longitude": "-71.157542",
    "ICAO": "SAZS",
    "Airport ID": "2513",
    "Latitude": "-41.151172",
    "Timezone": "2776",
    "DST": "-3",
    "destinations": ["2487", "2488", "2442", "2443", "3988", "2452"]
}, {
    "City": "Neiva",
    "DBTZ": "U",
    "Name": "Benito Salas",
    "Country": "Colombia",
    "IATA/FAA": "NVA",
    "Longitude": "-75.294",
    "ICAO": "SKNV",
    "Airport ID": "2734",
    "Latitude": "2.95015",
    "Timezone": "1464",
    "DST": "-5",
    "destinations": ["2709"]
}, {
    "City": "San Jose Del Cabo",
    "DBTZ": "S",
    "Name": "Los Cabos Intl",
    "Country": "Mexico",
    "IATA/FAA": "SJD",
    "Longitude": "-109.721044",
    "ICAO": "MMSD",
    "Airport ID": "1840",
    "Latitude": "23.15185",
    "Timezone": "374",
    "DST": "-7",
    "destinations": ["1804", "1824", "1850", "3876", "3670", "3484", "3830", "3462", "178", "3682", "3469", "3748", "3536", "3731", "3751", "3867", "3494", "3550", "1792", "1825", "156", "1847"]
}, {
    "City": "Monteria",
    "DBTZ": "U",
    "Name": "Los Garzones",
    "Country": "Colombia",
    "IATA/FAA": "MTR",
    "Longitude": "-75.825831",
    "ICAO": "SKMR",
    "Airport ID": "2731",
    "Latitude": "8.823744",
    "Timezone": "36",
    "DST": "-5",
    "destinations": ["2709", "2729"]
}, {
    "City": "Mitu",
    "DBTZ": "U",
    "Name": "Fabio Alberto Leon Bentley",
    "Country": "Colombia",
    "IATA/FAA": "MVP",
    "Longitude": "-70.233878",
    "ICAO": "SKMU",
    "Airport ID": "2732",
    "Latitude": "1.253664",
    "Timezone": "680",
    "DST": "-5",
    "destinations": ["2709", "2756"]
}, {
    "City": "Manizales",
    "DBTZ": "U",
    "Name": "La Nubia",
    "Country": "Colombia",
    "IATA/FAA": "MZL",
    "Longitude": "-75.464708",
    "ICAO": "SKMZ",
    "Airport ID": "2733",
    "Latitude": "5.029597",
    "Timezone": "6871",
    "DST": "-5",
    "destinations": ["2709"]
}, {
    "City": "Puerto Carreno",
    "DBTZ": "U",
    "Name": "Puerto Carreno",
    "Country": "Colombia",
    "IATA/FAA": "PCR",
    "Longitude": "-67.493164",
    "ICAO": "SKPC",
    "Airport ID": "2738",
    "Latitude": "6.184717",
    "Timezone": "177",
    "DST": "-5",
    "destinations": ["2709", "2756"]
}, {
    "City": "Pereira",
    "DBTZ": "U",
    "Name": "Matecana",
    "Country": "Colombia",
    "IATA/FAA": "PEI",
    "Longitude": "-75.739519",
    "ICAO": "SKPE",
    "Airport ID": "2739",
    "Latitude": "4.812675",
    "Timezone": "4416",
    "DST": "-5",
    "destinations": ["2709", "2749", "1871", "2729"]
}, {
    "City": "Kodiak",
    "DBTZ": "A",
    "Name": "Kodiak",
    "Country": "United States",
    "IATA/FAA": "ADQ",
    "Longitude": "-152.493856",
    "ICAO": "PADQ",
    "Airport ID": "3531",
    "Latitude": "57.749967",
    "Timezone": "78",
    "DST": "-9",
    "destinations": ["7167", "7168", "7162", "7164", "7163", "3774", "7160", "7165"]
}, {
    "City": "Fort Lauderdale",
    "DBTZ": "A",
    "Name": "Fort Lauderdale Hollywood Intl",
    "Country": "United States",
    "IATA/FAA": "FLL",
    "Longitude": "-80.15275",
    "ICAO": "KFLL",
    "Airport ID": "3533",
    "Latitude": "26.072583",
    "Timezone": "9",
    "DST": "-5",
    "destinations": ["1937", "1943", "3875", "1948", "1941", "1944", "3878", "1930", "1934", "3646", "3876", "3520", "3670", "3830", "1897", "3752", "3462", "146", "193", "3682", "3577", "2709", "3673", "3825", "3448", "1852", "3494", "3589", "3797", "1779", "3484", "3697", "2789", "1780", "2745", "1953", "6426", "2902", "1760", "3641", "3626", "3608", "1762", "3469", "1885", "2890", "3661", "3488", "3645", "3858", "737", "609", "644", "3751", "3447", "3849", "3566", "3717", "3861", "4007", "4034", "4017", "3771", "3676", "3524", "2895", "2705", "2885", "2714", "1767", "3630", "3877", "5747", "1863", "3515", "1871", "1892", "1772", "1764", "2883", "2899", "1850", "1956", "3486", "3714", "3550", "3864", "3690", "3820", "3759", "3585", "3857", "3712", "3458", "3747", "3570", "3678"]
}, {
    "City": "Trail",
    "DBTZ": "A",
    "Name": "Trail Airport",
    "Country": "Canada",
    "IATA/FAA": "YZZ",
    "Longitude": "-117.609167",
    "ICAO": "CAD4",
    "Airport ID": "8223",
    "Latitude": "49.055556",
    "Timezone": "1427",
    "DST": "-8",
    "destinations": ["156"]
}, {
    "City": "International Falls",
    "DBTZ": "A",
    "Name": "Falls Intl",
    "Country": "United States",
    "IATA/FAA": "INL",
    "Longitude": "-93.403067",
    "ICAO": "KINL",
    "Airport ID": "3535",
    "Latitude": "48.566186",
    "Timezone": "1185",
    "DST": "-6",
    "destinations": ["6957", "3858"]
}, {
    "City": "Salt Lake City",
    "DBTZ": "A",
    "Name": "Salt Lake City Intl",
    "Country": "United States",
    "IATA/FAA": "SLC",
    "Longitude": "-111.977772",
    "ICAO": "KSLC",
    "Airport ID": "3536",
    "Latitude": "40.788389",
    "Timezone": "4227",
    "DST": "-7",
    "destinations": ["3876", "3670", "3484", "3830", "3752", "3462", "3682", "1382", "1852", "1836", "1840", "3488", "3861", "3720", "3731", "3577", "3797", "3582", "4019", "3774", "3673", "4021", "3690", "3495", "3448", "4022", "3644", "3849", "4020", "3824", "7074", "4344", "3819", "3872", "3520", "3751", "3645", "5736", "4099", "3494", "4292", "3687", "4127", "4296", "1804", "3467", "4063", "3880", "3504", "3728", "3550", "4100", "3585", "4027", "3877", "3945", "3458", "3878", "3473", "4101", "3459", "4216", "3858", "3453", "3863", "3454", "3734", "5760", "6133", "3839", "4087", "4103", "3626", "5766", "3807", "3621", "3469", "4064", "3748", "3817", "3867", "3678", "3855", "3636", "4263", "7076", "156", "178", "3747", "4011"]
}, {
    "City": "Warsaw",
    "DBTZ": "E",
    "Name": "Okecie",
    "Country": "Poland",
    "IATA/FAA": "WAW",
    "Longitude": "20.967122",
    "ICAO": "EPWA",
    "Airport ID": "679",
    "Latitude": "52.16575",
    "Timezone": "362",
    "DST": "1",
    "destinations": ["344", "345", "3941", "507", "351", "609", "193", "1382", "421", "1555", "1529", "2954", "3953", "3364", "1230", "1218", "644", "666", "599", "2188", "340", "1194", "1569", "1739", "1200", "1206", "1209", "580", "342", "346", "737", "302", "1489", "3964", "668", "1665", "1701", "3797", "2939", "669", "671", "1197", "2948", "1638", "2945", "1229", "1524", "1354", "2947", "3830", "1657", "674", "1587", "675", "2985", "676", "3973", "415", "1590", "1613", "3959", "680", "1678", "2241", "1525", "1367", "304", "5562", "585", "534", "691", "6105", "3972", "491", "492", "708", "699", "664"]
}, {
    "City": "Los Mochis",
    "DBTZ": "S",
    "Name": "Valle Del Fuerte Intl",
    "Country": "Mexico",
    "IATA/FAA": "LMM",
    "Longitude": "-109.080806",
    "ICAO": "MMLM",
    "Airport ID": "1814",
    "Latitude": "25.685194",
    "Timezone": "16",
    "DST": "-7",
    "destinations": ["1807", "1824", "1826", "1804", "1847"]
}, {
    "City": "Del Bajio",
    "DBTZ": "S",
    "Name": "Guanajuato Intl",
    "Country": "Mexico",
    "IATA/FAA": "BJX",
    "Longitude": "-101.480847",
    "ICAO": "MMLO",
    "Airport ID": "1815",
    "Latitude": "20.993464",
    "Timezone": "5956",
    "DST": "-6",
    "destinations": ["3670", "1824", "1825", "3469", "1847", "3682", "3484", "3550", "1852", "3747"]
}, {
    "City": "La Paz",
    "DBTZ": "S",
    "Name": "General Manuel Marquez De Leon Intl",
    "Country": "Mexico",
    "IATA/FAA": "LAP",
    "Longitude": "-110.362475",
    "ICAO": "MMLP",
    "Airport ID": "1816",
    "Latitude": "24.072694",
    "Timezone": "69",
    "DST": "-7",
    "destinations": ["1824", "1804", "1792", "1825", "1826", "1847"]
}, {
    "City": "Loreto",
    "DBTZ": "S",
    "Name": "Loreto Intl",
    "Country": "Mexico",
    "IATA/FAA": "LTO",
    "Longitude": "-111.348361",
    "ICAO": "MMLT",
    "Airport ID": "1817",
    "Latitude": "25.989194",
    "Timezone": "34",
    "DST": "-7",
    "destinations": ["3484"]
}, {
    "City": "Saltillo",
    "DBTZ": "S",
    "Name": "Plan De Guadalupe Intl",
    "Country": "Mexico",
    "IATA/FAA": "SLW",
    "Longitude": "-100.928669",
    "ICAO": "MMIO",
    "Airport ID": "1810",
    "Latitude": "25.549497",
    "Timezone": "4778",
    "DST": "-6",
    "destinations": ["1824", "3550"]
}, {
    "City": "Lazard Cardenas",
    "DBTZ": "S",
    "Name": "Lazaro Cardenas",
    "Country": "Mexico",
    "IATA/FAA": "LZC",
    "Longitude": "-102.220525",
    "ICAO": "MMLC",
    "Airport ID": "1813",
    "Latitude": "18.001731",
    "Timezone": "39",
    "DST": "-6",
    "destinations": ["1824"]
}, {
    "City": "Nelson Lagoon",
    "DBTZ": "A",
    "Name": "Nelson Lagoon",
    "Country": "United States",
    "IATA/FAA": "NLG",
    "Longitude": "-161.160278",
    "ICAO": "PAOU",
    "Airport ID": "7203",
    "Latitude": "56.0075",
    "Timezone": "14",
    "DST": "-9",
    "destinations": ["3854"]
}, {
    "City": "False Pass",
    "DBTZ": "A",
    "Name": "False Pass Airport",
    "Country": "United States",
    "IATA/FAA": "KFP",
    "Longitude": "-163.410278",
    "ICAO": "PAKF",
    "Airport ID": "7202",
    "Latitude": "54.8475",
    "Timezone": "20",
    "DST": "-9",
    "destinations": ["3854"]
}, {
    "City": "Kipnuk",
    "DBTZ": "A",
    "Name": "Kipnuk Airport",
    "Country": "United States",
    "IATA/FAA": "KPN",
    "Longitude": "-164.030556",
    "ICAO": "PAKI",
    "Airport ID": "7201",
    "Latitude": "59.933056",
    "Timezone": "11",
    "DST": "-9",
    "destinations": ["6960", "3599"]
}, {
    "City": "Nunam Iqua",
    "DBTZ": "A",
    "Name": "Sheldon Point Airport",
    "Country": "United States",
    "IATA/FAA": "SXP",
    "Longitude": "-164.847778",
    "ICAO": "",
    "Airport ID": "7200",
    "Latitude": "62.520556",
    "Timezone": "12",
    "DST": "-9",
    "destinations": ["7199", "6718"]
}, {
    "City": "Matamoros",
    "DBTZ": "S",
    "Name": "General Servando Canales Intl",
    "Country": "Mexico",
    "IATA/FAA": "MAM",
    "Longitude": "-97.525311",
    "ICAO": "MMMA",
    "Airport ID": "1818",
    "Latitude": "25.769894",
    "Timezone": "25",
    "DST": "-6",
    "destinations": ["1824"]
}, {
    "City": "Merida",
    "DBTZ": "S",
    "Name": "Licenciado Manuel Crescencio Rejon Int",
    "Country": "Mexico",
    "IATA/FAA": "MID",
    "Longitude": "-89.657672",
    "ICAO": "MMMD",
    "Airport ID": "1819",
    "Latitude": "20.936981",
    "Timezone": "38",
    "DST": "-6",
    "destinations": ["1824", "1852", "1853", "1957", "3576", "3550", "1804", "1825"]
}, {
    "City": "Klawock",
    "DBTZ": "A",
    "Name": "Klawock Airport",
    "Country": "United States",
    "IATA/FAA": "KLW",
    "Longitude": "-133.076111",
    "ICAO": "PAKW",
    "Airport ID": "7205",
    "Latitude": "55.579167",
    "Timezone": "80",
    "DST": "-9",
    "destinations": ["3808", "7144"]
}, {
    "City": "Kudat",
    "DBTZ": "N",
    "Name": "Kudat Airport",
    "Country": "Malaysia",
    "IATA/FAA": "KUD",
    "Longitude": "116.836",
    "ICAO": "WBKT",
    "Airport ID": "6222",
    "Latitude": "6.9225",
    "Timezone": "10",
    "DST": "8",
    "destinations": ["3269", "4141"]
}, {
    "City": "Bandar Lampung-Sumatra Island",
    "DBTZ": "N",
    "Name": "Radin Inten II (Branti) Airport",
    "Country": "Indonesia",
    "IATA/FAA": "TKG",
    "Longitude": "105.178939",
    "ICAO": "WICT",
    "Airport ID": "6223",
    "Latitude": "-5.242339",
    "Timezone": "282",
    "DST": "7",
    "destinations": ["3903", "3275"]
}, {
    "City": "Bario",
    "DBTZ": "N",
    "Name": "Bario Airport",
    "Country": "Malaysia",
    "IATA/FAA": "BBN",
    "Longitude": "115.479",
    "ICAO": "WBGZ",
    "Airport ID": "6220",
    "Latitude": "3.73389",
    "Timezone": "3350",
    "DST": "8",
    "destinations": ["3265", "3266"]
}, {
    "City": "Bearskin Lake",
    "DBTZ": "A",
    "Name": "Bearskin Lake Airport",
    "Country": "Canada",
    "IATA/FAA": "XBE",
    "Longitude": "-91.0272",
    "ICAO": "CNE3",
    "Airport ID": "5468",
    "Latitude": "53.9656",
    "Timezone": "800",
    "DST": "-6",
    "destinations": ["5544", "5465", "5531", "169"]
}, {
    "City": "Ranai-Natuna Besar Island",
    "DBTZ": "N",
    "Name": "Ranai Airport",
    "Country": "Indonesia",
    "IATA/FAA": "NTX",
    "Longitude": "108.387897",
    "ICAO": "WION",
    "Airport ID": "6225",
    "Latitude": "3.908714",
    "Timezone": "7",
    "DST": "7",
    "destinations": ["3903"]
}, {
    "City": "Szczecin",
    "DBTZ": "E",
    "Name": "Goleniow",
    "Country": "Poland",
    "IATA/FAA": "SZZ",
    "Longitude": "14.902206",
    "ICAO": "EPSC",
    "Airport ID": "676",
    "Latitude": "53.584731",
    "Timezone": "154",
    "DST": "1",
    "destinations": ["644", "599", "491", "548", "679", "666", "664"]
}, {
    "City": "Ozamis",
    "DBTZ": "N",
    "Name": "Ozamis",
    "Country": "Philippines",
    "IATA/FAA": "OZC",
    "Longitude": "123.841731",
    "ICAO": "RPMO",
    "Airport ID": "4204",
    "Latitude": "8.178508",
    "Timezone": "75",
    "DST": "8",
    "destinations": ["2397", "4206"]
}, {
    "City": "Santa Rosa",
    "DBTZ": "A",
    "Name": "Charles M Schulz Sonoma Co",
    "Country": "United States",
    "IATA/FAA": "STS",
    "Longitude": "-122.81288",
    "ICAO": "KSTS",
    "Airport ID": "6992",
    "Latitude": "38.508978",
    "Timezone": "125",
    "DST": "-8",
    "destinations": ["3484", "3731", "3720", "3577"]
}, {
    "City": "Ghat",
    "DBTZ": "N",
    "Name": "Ghat",
    "Country": "Libya",
    "IATA/FAA": "GHT",
    "Longitude": "10.142647",
    "ICAO": "HLGT",
    "Airport ID": "1152",
    "Latitude": "25.145564",
    "Timezone": "2296",
    "DST": "2",
    "destinations": ["1157"]
}, {
    "City": "Graz",
    "DBTZ": "E",
    "Name": "Graz",
    "Country": "Austria",
    "IATA/FAA": "GRZ",
    "Longitude": "15.439628",
    "ICAO": "LOWG",
    "Airport ID": "1609",
    "Latitude": "46.991067",
    "Timezone": "1115",
    "DST": "1",
    "destinations": ["1678", "1452", "3998", "1056", "351", "1688", "345", "340", "346", "350", "1613"]
}, {
    "City": "Totegegie",
    "DBTZ": "U",
    "Name": "Totegegie",
    "Country": "French Polynesia",
    "IATA/FAA": "GMR",
    "Longitude": "-134.890333",
    "ICAO": "NTGJ",
    "Airport ID": "1979",
    "Latitude": "-23.079861",
    "Timezone": "7",
    "DST": "-9",
    "destinations": ["4075"]
}, {
    "City": "Manihi",
    "DBTZ": "U",
    "Name": "Manihi",
    "Country": "French Polynesia",
    "IATA/FAA": "XMH",
    "Longitude": "-146.070056",
    "ICAO": "NTGI",
    "Airport ID": "1978",
    "Latitude": "-14.436764",
    "Timezone": "14",
    "DST": "-10",
    "destinations": ["5889", "4075", "1990"]
}, {
    "City": "Fakarava",
    "DBTZ": "U",
    "Name": "Fakarava",
    "Country": "French Polynesia",
    "IATA/FAA": "FAV",
    "Longitude": "-145.656994",
    "ICAO": "NTGF",
    "Airport ID": "1977",
    "Latitude": "-16.05415",
    "Timezone": "13",
    "DST": "-10",
    "destinations": ["4075", "1990"]
}, {
    "City": "Myitkyina",
    "DBTZ": "U",
    "Name": "Myitkyina",
    "Country": "Burma",
    "IATA/FAA": "MYT",
    "Longitude": "97.351919",
    "ICAO": "VYMK",
    "Airport ID": "3224",
    "Latitude": "25.383636",
    "Timezone": "475",
    "DST": "6.5",
    "destinations": ["3222", "3232", "3239"]
}, {
    "City": "Tikehau",
    "DBTZ": "U",
    "Name": "Tikehau",
    "Country": "French Polynesia",
    "IATA/FAA": "TIH",
    "Longitude": "-148.230697",
    "ICAO": "NTGC",
    "Airport ID": "1975",
    "Latitude": "-15.119617",
    "Timezone": "6",
    "DST": "-10",
    "destinations": ["4075", "1990"]
}, {
    "City": "Ovda",
    "DBTZ": "U",
    "Name": "Ovda",
    "Country": "Israel",
    "IATA/FAA": "VDA",
    "Longitude": "34.93585",
    "ICAO": "LLOV",
    "Airport ID": "1602",
    "Latitude": "29.94025",
    "Timezone": "1492",
    "DST": "2",
    "destinations": ["1382"]
}, {
    "City": "Tel-aviv",
    "DBTZ": "U",
    "Name": "Sde Dov",
    "Country": "Israel",
    "IATA/FAA": "SDV",
    "Longitude": "34.782239",
    "ICAO": "LLSD",
    "Airport ID": "1605",
    "Latitude": "32.114661",
    "Timezone": "43",
    "DST": "2",
    "destinations": ["1594", "1596"]
}, {
    "City": "Tubuai",
    "DBTZ": "U",
    "Name": "Tubuai",
    "Country": "French Polynesia",
    "IATA/FAA": "TUB",
    "Longitude": "-149.524072",
    "ICAO": "NTAT",
    "Airport ID": "1972",
    "Latitude": "-23.365353",
    "Timezone": "7",
    "DST": "-10",
    "destinations": ["4075", "1971", "7456"]
}, {
    "City": "Rurutu",
    "DBTZ": "U",
    "Name": "Rurutu",
    "Country": "French Polynesia",
    "IATA/FAA": "RUR",
    "Longitude": "-151.360614",
    "ICAO": "NTAR",
    "Airport ID": "1971",
    "Latitude": "-22.434069",
    "Timezone": "18",
    "DST": "-10",
    "destinations": ["4075", "6926"]
}, {
    "City": "Pago Pago",
    "DBTZ": "U",
    "Name": "Pago Pago Intl",
    "Country": "American Samoa",
    "IATA/FAA": "PPG",
    "Longitude": "-170.7105",
    "ICAO": "NSTU",
    "Airport ID": "1970",
    "Latitude": "-14.331",
    "Timezone": "32",
    "DST": "-11",
    "destinations": ["3728"]
}, {
    "City": "Rome",
    "DBTZ": "E",
    "Name": "Fiumicino",
    "Country": "Italy",
    "IATA/FAA": "FCO",
    "Longitude": "12.250797",
    "ICAO": "LIRF",
    "Airport ID": "1555",
    "Latitude": "41.804475",
    "Timezone": "15",
    "DST": "1",
    "destinations": ["344", "352", "342", "350", "351", "1590", "1157", "1735", "3941", "1264", "1335", "1435", "3876", "3797", "507", "3830", "3752", "345", "1613", "193", "3682", "1382", "503", "1359", "1353", "1354", "1273", "210", "3988", "1074", "421", "1230", "2170", "580", "4309", "2179", "1218", "1506", "1739", "2177", "1216", "1538", "3448", "1501", "302", "1489", "1536", "1519", "1128", "2851", "3024", "609", "1509", "3093", "3964", "1563", "340", "2560", "1528", "2564", "1665", "2922", "1655", "3930", "4330", "1701", "2072", "2939", "3992", "2176", "3484", "2948", "1529", "1638", "273", "629", "1229", "3576", "1606", "346", "1524", "1561", "2279", "1636", "231", "1657", "1512", "1587", "1562", "3406", "1514", "3953", "2082", "1486", "1737", "1194", "1508", "2985", "3973", "1741", "1190", "1526", "1545", "287", "1551", "1246", "1550", "679", "1678", "2954", "502", "3076", "4029", "1909", "1759", "3364", "3077", "737", "636", "687", "644", "599", "2188", "1107", "591", "2983", "1212", "1418", "1386", "1051", "1520", "1517", "675", "3494", "534", "517", "478", "521", "4053", "1200", "1206", "4317", "2241", "3316", "3885", "146", "490", "337", "2975", "3714", "2988", "8832", "668", "3959", "2945", "469", "492"]
}, {
    "City": "Tangshan",
    "DBTZ": "N",
    "Name": "Sannvhe",
    "Country": "China",
    "IATA/FAA": "TVS",
    "Longitude": "118.002389",
    "ICAO": "ZBSN",
    "Airport ID": "8858",
    "Latitude": "39.717444",
    "Timezone": "25",
    "DST": "8",
    "destinations": ["3369", "3406", "3404", "6347", "3370"]
}, {
    "City": "East London",
    "DBTZ": "U",
    "Name": "East London",
    "Country": "South Africa",
    "IATA/FAA": "ELS",
    "Longitude": "27.825939",
    "ICAO": "FAEL",
    "Airport ID": "800",
    "Latitude": "-33.035569",
    "Timezone": "435",
    "DST": "2",
    "destinations": ["813", "797", "799", "838"]
}, {
    "City": "George",
    "DBTZ": "U",
    "Name": "George",
    "Country": "South Africa",
    "IATA/FAA": "GRJ",
    "Longitude": "22.378889",
    "ICAO": "FAGG",
    "Airport ID": "804",
    "Latitude": "-34.005553",
    "Timezone": "648",
    "DST": "2",
    "destinations": ["813", "797", "799"]
}, {
    "City": "Sao Paulo de Olivenca",
    "DBTZ": "S",
    "Name": "Senadora Eunice Micheles Airport",
    "Country": "Brazil",
    "IATA/FAA": "OLC",
    "Longitude": "-68.918889",
    "ICAO": "SDCG",
    "Airport ID": "8256",
    "Latitude": "-3.465556",
    "Timezone": "335",
    "DST": "-4",
    "destinations": ["2624"]
}, {
    "City": "Maracaibo",
    "DBTZ": "U",
    "Name": "La Chinita Intl",
    "Country": "Venezuela",
    "IATA/FAA": "MAR",
    "Longitude": "-71.727856",
    "ICAO": "SVMC",
    "Airport ID": "2848",
    "Latitude": "10.558208",
    "Timezone": "235",
    "DST": "-4.5",
    "destinations": ["2895", "2897", "2851", "2868", "3576", "1871", "2854", "2850"]
}, {
    "City": "Merida",
    "DBTZ": "U",
    "Name": "Alberto Carnevalli",
    "Country": "Venezuela",
    "IATA/FAA": "MRD",
    "Longitude": "-71.161186",
    "ICAO": "SVMD",
    "Airport ID": "2849",
    "Latitude": "8.582294",
    "Timezone": "5007",
    "DST": "-4.5",
    "destinations": ["2851"]
}, {
    "City": "La Fria",
    "DBTZ": "U",
    "Name": "La Fria",
    "Country": "Venezuela",
    "IATA/FAA": "LFR",
    "Longitude": "-72.271028",
    "ICAO": "SVLF",
    "Airport ID": "2846",
    "Latitude": "8.239167",
    "Timezone": "323",
    "DST": "-4.5",
    "destinations": ["2851"]
}, {
    "City": "Paraguana",
    "DBTZ": "U",
    "Name": "Josefa Camejo",
    "Country": "Venezuela",
    "IATA/FAA": "LSP",
    "Longitude": "-70.151497",
    "ICAO": "SVJC",
    "Airport ID": "2844",
    "Latitude": "11.780775",
    "Timezone": "75",
    "DST": "-4.5",
    "destinations": ["2895", "2897", "2851"]
}, {
    "City": "Brisbane",
    "DBTZ": "N",
    "Name": "Brisbane Intl",
    "Country": "Australia",
    "IATA/FAA": "BNE",
    "Longitude": "153.1175",
    "ICAO": "YBBN",
    "Airport ID": "3320",
    "Latitude": "-27.384167",
    "Timezone": "13",
    "DST": "10",
    "destinations": ["3341", "2006", "3355", "3322", "3999", "3484", "3339", "3351", "3361", "3330", "3316", "3077", "2276", "5", "3370", "4217", "2188", "6289", "3329", "1960", "3940", "3728", "4074", "3336", "6242", "3337", "3326", "4320", "3328", "3930", "3304", "5908", "1997", "2009", "6255", "2011", "3359", "2042", "2030", "4076", "6324", "7128", "3319", "6238", "4218", "4319", "3323", "3990", "6269", "4052", "3324", "3345", "6285", "6301", "2005", "3349", "6320", "3885", "1969"]
}, {
    "City": "Tarapaca",
    "DBTZ": "U",
    "Name": "Tarapaca Airport",
    "Country": "Colombia",
    "IATA/FAA": "TCD",
    "Longitude": "-69.733",
    "ICAO": "",
    "Airport ID": "5477",
    "Latitude": "-2.867",
    "Timezone": "0",
    "DST": "-5",
    "destinations": ["2728"]
}, {
    "City": "Cairns",
    "DBTZ": "N",
    "Name": "Cairns Intl",
    "Country": "Australia",
    "IATA/FAA": "CNS",
    "Longitude": "145.755278",
    "ICAO": "YBCS",
    "Airport ID": "3322",
    "Latitude": "-16.885833",
    "Timezone": "10",
    "DST": "10",
    "destinations": ["3320", "3077", "6270", "6316", "3992", "2279", "3341", "3999", "3339", "3321", "3351", "3361", "2006", "5", "5435", "3318", "6237", "6260", "6319", "6286", "3324", "6281", "6309", "3331", "3319", "4010", "6272", "6242", "3330", "2246"]
}, {
    "City": "Charlieville",
    "DBTZ": "O",
    "Name": "Charleville",
    "Country": "Australia",
    "IATA/FAA": "CTL",
    "Longitude": "146.2625",
    "ICAO": "YBCV",
    "Airport ID": "3323",
    "Latitude": "-26.413334",
    "Timezone": "1003",
    "DST": "10",
    "destinations": ["7128", "6322", "3320", "6324"]
}, {
    "City": "Mount Isa",
    "DBTZ": "O",
    "Name": "Mount Isa",
    "Country": "Australia",
    "IATA/FAA": "ISA",
    "Longitude": "139.488611",
    "ICAO": "YBMA",
    "Airport ID": "3324",
    "Latitude": "-20.663889",
    "Timezone": "1121",
    "DST": "10",
    "destinations": ["6248", "3322", "6262", "3320", "6255", "3999", "3321", "3330", "6276"]
}, {
    "City": "Maroochydore",
    "DBTZ": "O",
    "Name": "Sunshine Coast",
    "Country": "Australia",
    "IATA/FAA": "MCY",
    "Longitude": "153.091111",
    "ICAO": "YBMC",
    "Airport ID": "3325",
    "Latitude": "-26.603333",
    "Timezone": "15",
    "DST": "10",
    "destinations": ["3339", "3361"]
}, {
    "City": "Mackay",
    "DBTZ": "O",
    "Name": "Mackay",
    "Country": "Australia",
    "IATA/FAA": "MKY",
    "Longitude": "149.179722",
    "ICAO": "YBMK",
    "Airport ID": "3326",
    "Latitude": "-21.171667",
    "Timezone": "19",
    "DST": "10",
    "destinations": ["3320", "3329", "3330", "3339", "3361"]
}, {
    "City": "Poplar Hill",
    "DBTZ": "A",
    "Name": "Poplar Hill Airport",
    "Country": "Canada",
    "IATA/FAA": "YHP",
    "Longitude": "-94.2556",
    "ICAO": "CPV7",
    "Airport ID": "5471",
    "Latitude": "52.1133",
    "Timezone": "1095",
    "DST": "-6",
    "destinations": ["5467", "5527"]
}, {
    "City": "Prosserpine",
    "DBTZ": "O",
    "Name": "Proserpine Whitsunday Coast",
    "Country": "Australia",
    "IATA/FAA": "PPP",
    "Longitude": "148.552222",
    "ICAO": "YBPN",
    "Airport ID": "3328",
    "Latitude": "-20.495",
    "Timezone": "82",
    "DST": "10",
    "destinations": ["3320", "3361"]
}, {
    "City": "Rockhampton",
    "DBTZ": "O",
    "Name": "Rockhampton",
    "Country": "Australia",
    "IATA/FAA": "ROK",
    "Longitude": "150.475278",
    "ICAO": "YBRK",
    "Airport ID": "3329",
    "Latitude": "-23.381944",
    "Timezone": "34",
    "DST": "10",
    "destinations": ["3320", "6269", "3326"]
}, {
    "City": "Cat Lake",
    "DBTZ": "A",
    "Name": "Cat Lake Airport",
    "Country": "Canada",
    "IATA/FAA": "YAC",
    "Longitude": "-91.8244",
    "ICAO": "CYAC",
    "Airport ID": "5478",
    "Latitude": "51.7272",
    "Timezone": "1344",
    "DST": "-6",
    "destinations": ["169", "5547"]
}, {
    "City": "Fort Frances",
    "DBTZ": "A",
    "Name": "Fort Frances Municipal Airport",
    "Country": "Canada",
    "IATA/FAA": "YAG",
    "Longitude": "-93.4397",
    "ICAO": "CYAG",
    "Airport ID": "5479",
    "Latitude": "48.6542",
    "Timezone": "1125",
    "DST": "-6",
    "destinations": ["115", "121"]
}, {
    "City": "Roatan",
    "DBTZ": "U",
    "Name": "Juan Manuel Galvez Intl",
    "Country": "Honduras",
    "IATA/FAA": "RTB",
    "Longitude": "-86.522961",
    "ICAO": "MHRO",
    "Airport ID": "1774",
    "Latitude": "16.316814",
    "Timezone": "18",
    "DST": "-6",
    "destinations": ["1767", "1957", "3670", "3576", "1892", "1772", "1776", "3682", "1771", "3550"]
}, {
    "City": "Tegucigalpa",
    "DBTZ": "U",
    "Name": "Toncontin Intl",
    "Country": "Honduras",
    "IATA/FAA": "TGU",
    "Longitude": "-87.217197",
    "ICAO": "MHTG",
    "Airport ID": "1776",
    "Latitude": "14.060883",
    "Timezone": "3294",
    "DST": "-6",
    "destinations": ["1767", "3576", "1892", "1885", "5832", "1774", "1772", "1871", "3682", "1771", "3550"]
}, {
    "City": "La Ceiba",
    "DBTZ": "U",
    "Name": "Goloson Intl",
    "Country": "Honduras",
    "IATA/FAA": "LCE",
    "Longitude": "-86.853036",
    "ICAO": "MHLC",
    "Airport ID": "1771",
    "Latitude": "15.742481",
    "Timezone": "49",
    "DST": "-6",
    "destinations": ["1926", "1773", "5832", "1774", "1772", "1776", "4125"]
}, {
    "City": "Guanaja",
    "DBTZ": "U",
    "Name": "Guanaja",
    "Country": "Honduras",
    "IATA/FAA": "GJA",
    "Longitude": "-85.906611",
    "ICAO": "MHNJ",
    "Airport ID": "1773",
    "Latitude": "16.445367",
    "Timezone": "49",
    "DST": "-6",
    "destinations": ["1771"]
}, {
    "City": "San Pedro Sula",
    "DBTZ": "U",
    "Name": "La Mesa Intl",
    "Country": "Honduras",
    "IATA/FAA": "SAP",
    "Longitude": "-87.923556",
    "ICAO": "MHLM",
    "Airport ID": "1772",
    "Latitude": "15.452639",
    "Timezone": "91",
    "DST": "-6",
    "destinations": ["1767", "1957", "3576", "1824", "3797", "1892", "1885", "1774", "1776", "1871", "3682", "3533", "1771", "3494", "3550"]
}, {
    "City": "Gunnison",
    "DBTZ": "A",
    "Name": "Gunnison - Crested Butte",
    "Country": "United States",
    "IATA/FAA": "GUC",
    "Longitude": "-106.933056",
    "ICAO": "KGUC",
    "Airport ID": "7051",
    "Latitude": "38.533889",
    "Timezone": "7678",
    "DST": "-7",
    "destinations": ["3751"]
}, {
    "City": "Kingston",
    "DBTZ": "U",
    "Name": "Norman Manley Intl",
    "Country": "Jamaica",
    "IATA/FAA": "KIN",
    "Longitude": "-76.7875",
    "ICAO": "MKJP",
    "Airport ID": "1779",
    "Latitude": "17.935667",
    "Timezone": "10",
    "DST": "-5",
    "destinations": ["2897", "3576", "193", "3533", "3797", "502", "2874", "2875", "1780", "3878", "1953", "2902", "2899", "1871", "3682", "1754", "1762", "1926", "4304"]
}, {
    "City": "Manistee",
    "DBTZ": "A",
    "Name": "Manistee County-Blacker Airport",
    "Country": "United States",
    "IATA/FAA": "MBL",
    "Longitude": "-86.246944",
    "ICAO": "KMBL",
    "Airport ID": "7054",
    "Latitude": "44.2725",
    "Timezone": "621",
    "DST": "-5",
    "destinations": ["3747"]
}, {
    "City": "Yushu",
    "DBTZ": "U",
    "Name": "Yushu Batang",
    "Country": "China",
    "IATA/FAA": "YUS",
    "Longitude": "97.125",
    "ICAO": "ZLYS",
    "Airport ID": "7894",
    "Latitude": "32.825",
    "Timezone": "13000",
    "DST": "8",
    "destinations": ["6366"]
}, {
    "City": "Bhuj",
    "DBTZ": "N",
    "Name": "Bhuj",
    "Country": "India",
    "IATA/FAA": "BHJ",
    "Longitude": "69.670147",
    "ICAO": "VABJ",
    "Airport ID": "2999",
    "Latitude": "23.287828",
    "Timezone": "268",
    "DST": "5.5",
    "destinations": ["2997"]
}, {
    "City": "Benghazi",
    "DBTZ": "N",
    "Name": "Benina",
    "Country": "Libya",
    "IATA/FAA": "BEN",
    "Longitude": "20.269472",
    "ICAO": "HLLB",
    "Airport ID": "1154",
    "Latitude": "32.096786",
    "Timezone": "433",
    "DST": "2",
    "destinations": ["1153", "2170", "1128", "1074", "1701", "2072", "5804", "1157", "287", "1606", "3410", "295"]
}, {
    "City": "Tripoli",
    "DBTZ": "N",
    "Name": "Tripoli Intl",
    "Country": "Libya",
    "IATA/FAA": "TIP",
    "Longitude": "13.159011",
    "ICAO": "HLLT",
    "Airport ID": "1157",
    "Latitude": "32.663544",
    "Timezone": "263",
    "DST": "2",
    "destinations": ["2170", "1154", "1128", "1074", "1555", "3410", "1701", "2072", "295", "5697", "287", "210", "507", "2188", "293", "286", "1606", "1153", "1152", "5699", "1162", "1229", "478", "5805", "5696"]
}, {
    "City": "Kufra",
    "DBTZ": "N",
    "Name": "Kufra",
    "Country": "Libya",
    "IATA/FAA": "AKF",
    "Longitude": "23.313958",
    "ICAO": "HLKF",
    "Airport ID": "1153",
    "Latitude": "24.178728",
    "Timezone": "1367",
    "DST": "2",
    "destinations": ["1154", "1157"]
}, {
    "City": "Bayannur",
    "DBTZ": "U",
    "Name": "Bayannur",
    "Country": "China",
    "IATA/FAA": "RLK",
    "Longitude": "107.738889",
    "ICAO": "",
    "Airport ID": "8630",
    "Latitude": "40.926389",
    "Timezone": "3389",
    "DST": "8",
    "destinations": ["3364", "6345", "4085", "3379"]
}, {
    "City": "Lutselk'e",
    "DBTZ": "A",
    "Name": "Lutselk'e Airport",
    "Country": "Canada",
    "IATA/FAA": "YSG",
    "Longitude": "-110.682",
    "ICAO": "CYLK",
    "Airport ID": "5511",
    "Latitude": "62.4183",
    "Timezone": "596",
    "DST": "-7",
    "destinations": ["196"]
}, {
    "City": "Lansdowne House",
    "DBTZ": "A",
    "Name": "Lansdowne House Airport",
    "Country": "Canada",
    "IATA/FAA": "YLH",
    "Longitude": "-87.9342",
    "ICAO": "CYLH",
    "Airport ID": "5510",
    "Latitude": "52.1956",
    "Timezone": "834",
    "DST": "-5",
    "destinations": ["5491", "5533"]
}, {
    "City": "Kangiqsualujjuaq",
    "DBTZ": "A",
    "Name": "Kangiqsualujjuaq (Georges River) Airport",
    "Country": "Canada",
    "IATA/FAA": "XGR",
    "Longitude": "-65.9928",
    "ICAO": "CYLU",
    "Airport ID": "5512",
    "Latitude": "58.7114",
    "Timezone": "215",
    "DST": "-5",
    "destinations": ["154"]
}, {
    "City": "Umiujaq",
    "DBTZ": "A",
    "Name": "Umiujaq Airport",
    "Country": "Canada",
    "IATA/FAA": "YUD",
    "Longitude": "-76.5183",
    "ICAO": "CYMU",
    "Airport ID": "5515",
    "Latitude": "56.5361",
    "Timezone": "250",
    "DST": "-5",
    "destinations": ["5496", "5520", "5529"]
}, {
    "City": "Chibougamau",
    "DBTZ": "A",
    "Name": "Chapais Airport",
    "Country": "Canada",
    "IATA/FAA": "YMT",
    "Longitude": "-74.5281",
    "ICAO": "CYMT",
    "Airport ID": "5514",
    "Latitude": "49.7719",
    "Timezone": "1270",
    "DST": "-5",
    "destinations": ["5507", "5501", "146"]
}, {
    "City": "Wemindji",
    "DBTZ": "A",
    "Name": "Wemindji Airport",
    "Country": "Canada",
    "IATA/FAA": "YNC",
    "Longitude": "-78.8311",
    "ICAO": "CYNC",
    "Airport ID": "5516",
    "Latitude": "53.0106",
    "Timezone": "66",
    "DST": "-5",
    "destinations": ["5472", "5539"]
}, {
    "City": "Venice",
    "DBTZ": "E",
    "Name": "Venezia Tessera",
    "Country": "Italy",
    "IATA/FAA": "VCE",
    "Longitude": "12.351944",
    "ICAO": "LIPZ",
    "Airport ID": "1551",
    "Latitude": "45.505278",
    "Timezone": "7",
    "DST": "1",
    "destinations": ["344", "345", "352", "342", "1735", "1335", "507", "3752", "350", "351", "1382", "1353", "1354", "1273", "3941", "609", "1509", "2948", "1512", "1587", "1508", "1190", "580", "1555", "3797", "1638", "2279", "3953", "2985", "503", "502", "2241", "737", "644", "599", "2188", "346", "585", "1218", "302", "1229", "1561", "340", "535", "517", "478", "521", "1678", "1590", "1613", "1200", "1701", "1399", "1418", "1386", "146", "193", "287", "4053", "1665", "492", "508", "337", "2988", "1216", "1264", "1501", "1479", "1510", "1520", "1511", "1514", "469"]
}, {
    "City": "Villafranca",
    "DBTZ": "E",
    "Name": "Villafranca",
    "Country": "Italy",
    "IATA/FAA": "VRN",
    "Longitude": "10.888533",
    "ICAO": "LIPX",
    "Airport ID": "1550",
    "Latitude": "45.395706",
    "Timezone": "239",
    "DST": "1",
    "destinations": ["344", "342", "351", "1735", "1382", "1509", "1512", "1190", "1555", "502", "599", "340", "346", "580", "1519", "1051", "1561", "1520", "1056", "4029", "478", "1501", "1657"]
}, {
    "City": "Rome",
    "DBTZ": "E",
    "Name": "Ciampino",
    "Country": "Italy",
    "IATA/FAA": "CIA",
    "Longitude": "12.594936",
    "ICAO": "LIRA",
    "Airport ID": "1553",
    "Latitude": "41.799361",
    "Timezone": "427",
    "DST": "1",
    "destinations": ["1230", "1517", "1218", "1506", "608", "1264", "1501", "1745", "1489", "1367", "1519", "1460", "1474", "9327", "304", "599", "535", "585", "523", "4166", "3986", "691", "355", "1225", "669", "1229", "478", "1353", "4198", "699", "1636", "537", "3998", "674", "1075", "657", "1251", "1486", "548", "1253", "1515", "1246", "3959", "8414", "680", "1652", "1735", "671", "1657", "1663"]
}, {
    "City": "Agartala",
    "DBTZ": "N",
    "Name": "Agartala",
    "Country": "India",
    "IATA/FAA": "IXA",
    "Longitude": "91.24045",
    "ICAO": "VEAT",
    "Airport ID": "3038",
    "Latitude": "23.886978",
    "Timezone": "46",
    "DST": "5.5",
    "destinations": ["3043", "6173", "3050"]
}, {
    "City": "Aizwal",
    "DBTZ": "N",
    "Name": "Aizawl",
    "Country": "India",
    "IATA/FAA": "AJL",
    "Longitude": "92.802767",
    "ICAO": "VEAZ",
    "Airport ID": "3039",
    "Latitude": "23.746603",
    "Timezone": "1001",
    "DST": "5.5",
    "destinations": ["3043", "6173", "3050"]
}, {
    "City": "Phnom-penh",
    "DBTZ": "U",
    "Name": "Phnom Penh Intl",
    "Country": "Cambodia",
    "IATA/FAA": "PNH",
    "Longitude": "104.844139",
    "ICAO": "VDPP",
    "Airport ID": "3034",
    "Latitude": "11.546556",
    "Timezone": "40",
    "DST": "7",
    "destinations": ["3316", "3239", "3304", "2276", "3370", "3157", "3885", "3199", "3406", "3035", "3205", "3077", "3930", "3373", "3120"]
}, {
    "City": "Siem-reap",
    "DBTZ": "U",
    "Name": "Siem Reap",
    "Country": "Cambodia",
    "IATA/FAA": "REP",
    "Longitude": "103.81284",
    "ICAO": "VDSR",
    "Airport ID": "3035",
    "Latitude": "13.410666",
    "Timezone": "60",
    "DST": "7",
    "destinations": ["3316", "2397", "3406", "3199", "3304", "2372", "3364", "3370", "3930", "3157", "2276", "3885", "4093", "3034", "3205", "3077", "3196", "3382", "3115", "3116"]
}, {
    "City": "Ufa",
    "DBTZ": "N",
    "Name": "Ufa",
    "Country": "Russia",
    "IATA/FAA": "UFA",
    "Longitude": "55.874417",
    "ICAO": "UWUU",
    "Airport ID": "2992",
    "Latitude": "54.557511",
    "Timezone": "449",
    "DST": "6",
    "destinations": ["4364", "2975", "8076", "2191", "2983", "1587", "4029", "2948", "2985", "1701", "2979", "6147", "2990", "2974", "4111", "2922", "4352", "4365", "6143"]
}, {
    "City": "Fredericton",
    "DBTZ": "A",
    "Name": "Fredericton",
    "Country": "Canada",
    "IATA/FAA": "YFC",
    "Longitude": "-66.537222",
    "ICAO": "CYFC",
    "Airport ID": "56",
    "Latitude": "45.868889",
    "Timezone": "68",
    "DST": "-4",
    "destinations": ["73", "100", "146", "193"]
}, {
    "City": "Trinciomalee",
    "DBTZ": "U",
    "Name": "China Bay",
    "Country": "Sri Lanka",
    "IATA/FAA": "TRR",
    "Longitude": "81.181853",
    "ICAO": "VCCT",
    "Airport ID": "3030",
    "Latitude": "8.538514",
    "Timezone": "6",
    "DST": "5.5",
    "destinations": ["9229"]
}, {
    "City": "Eskimo Point",
    "DBTZ": "A",
    "Name": "Arviat",
    "Country": "Canada",
    "IATA/FAA": "YEK",
    "Longitude": "-94.070833",
    "ICAO": "CYEK",
    "Airport ID": "50",
    "Latitude": "61.094166",
    "Timezone": "32",
    "DST": "-6",
    "destinations": ["5534", "187", "132"]
}, {
    "City": "Aurangabad",
    "DBTZ": "N",
    "Name": "Aurangabad",
    "Country": "India",
    "IATA/FAA": "IXU",
    "Longitude": "75.398114",
    "ICAO": "VAAU",
    "Airport ID": "2996",
    "Latitude": "19.862728",
    "Timezone": "1911",
    "DST": "5.5",
    "destinations": ["2997", "3093"]
}, {
    "City": "Mumbai",
    "DBTZ": "N",
    "Name": "Chhatrapati Shivaji Intl",
    "Country": "India",
    "IATA/FAA": "BOM",
    "Longitude": "72.867919",
    "ICAO": "VABB",
    "Airport ID": "2997",
    "Latitude": "19.088686",
    "Timezone": "37",
    "DST": "5.5",
    "destinations": ["2994", "3042", "3001", "3131", "3043", "3135", "3136", "3093", "2188", "6173", "3007", "3141", "3008", "3089", "3100", "3098", "3105", "3144", "2194", "3015", "3061", "3112", "3153", "2179", "2057", "2999", "3002", "3003", "3885", "302", "3137", "3024", "3076", "5711", "2064", "2241", "3077", "3143", "2996", "3097", "2072", "813", "3125", "3304", "2176", "507", "4059", "2279", "3017", "3019", "3020", "2082", "3316", "3023", "3066", "1382", "1107", "3079", "1128", "3494", "340", "3094", "1701", "3080", "3010", "893", "346", "3084", "1678", "3395", "580", "2191", "4330", "3977", "3980", "3930", "1590", "2206", "2170", "3081", "6168", "3009", "3022"]
}, {
    "City": "Prestwick",
    "DBTZ": "E",
    "Name": "Prestwick",
    "Country": "United Kingdom",
    "IATA/FAA": "PIK",
    "Longitude": "-4.586667",
    "ICAO": "EGPK",
    "Airport ID": "537",
    "Latitude": "55.509444",
    "Timezone": "65",
    "DST": "0",
    "destinations": ["1055", "1230", "1212", "1218", "1352", "1460", "1553", "599", "1626", "1051", "1225", "468", "1054", "1227", "1606", "3998", "1236", "3953", "1056", "8414", "680"]
}, {
    "City": "Islay",
    "DBTZ": "E",
    "Name": "Islay",
    "Country": "United Kingdom",
    "IATA/FAA": "ILY",
    "Longitude": "-6.256667",
    "ICAO": "EGPI",
    "Airport ID": "536",
    "Latitude": "55.681944",
    "Timezone": "56",
    "DST": "0",
    "destinations": ["534"]
}, {
    "City": "Edinburgh",
    "DBTZ": "E",
    "Name": "Edinburgh",
    "Country": "United Kingdom",
    "IATA/FAA": "EDI",
    "Longitude": "-3.3725",
    "ICAO": "EGPH",
    "Airport ID": "535",
    "Latitude": "55.95",
    "Timezone": "135",
    "DST": "0",
    "destinations": ["344", "507", "1382", "488", "503", "502", "467", "469", "523", "552", "499", "529", "530", "478", "547", "495", "541", "531", "737", "636", "609", "644", "599", "596", "603", "1055", "1230", "1212", "1218", "1538", "1264", "353", "1745", "1360", "1460", "1553", "304", "1626", "1051", "668", "691", "355", "669", "3956", "1054", "1606", "1353", "4198", "3998", "674", "1562", "657", "1251", "1056", "580", "3494", "340", "1489", "1200", "1231", "1227", "1587", "1551", "666", "302", "1701", "3941", "465", "4315", "490", "4053", "1715", "1665", "342", "1452", "16", "1638", "492", "1335", "1229", "346", "1524", "1561", "1354", "1198", "508", "548", "337"]
}, {
    "City": "Glasgow",
    "DBTZ": "E",
    "Name": "Glasgow",
    "Country": "United Kingdom",
    "IATA/FAA": "GLA",
    "Longitude": "-4.433056",
    "ICAO": "EGPF",
    "Airport ID": "534",
    "Latitude": "55.871944",
    "Timezone": "26",
    "DST": "0",
    "destinations": ["507", "3752", "488", "580", "503", "502", "538", "467", "469", "5575", "5563", "5577", "523", "552", "536", "499", "529", "517", "530", "478", "495", "541", "542", "1055", "1852", "3878", "599", "596", "2188", "16", "1606", "345", "3494", "1230", "1212", "1218", "4315", "1715", "1626", "1555", "1051", "1452", "1225", "1054", "1198", "3998", "1472", "1056", "1688", "7447", "1197", "4091", "178", "193", "465", "490", "1382", "1458", "492", "548", "337", "668", "679"]
}, {
    "City": "Inverness",
    "DBTZ": "E",
    "Name": "Inverness",
    "Country": "United Kingdom",
    "IATA/FAA": "INV",
    "Longitude": "-4.0475",
    "ICAO": "EGPE",
    "Airport ID": "533",
    "Latitude": "57.5425",
    "Timezone": "31",
    "DST": "0",
    "destinations": ["580", "467", "469", "535", "529", "530", "478", "541", "490", "502", "492"]
}, {
    "City": "Aberdeen",
    "DBTZ": "E",
    "Name": "Dyce",
    "Country": "United Kingdom",
    "IATA/FAA": "ABZ",
    "Longitude": "-2.197778",
    "ICAO": "EGPD",
    "Airport ID": "532",
    "Latitude": "57.201944",
    "Timezone": "215",
    "DST": "0",
    "destinations": ["507", "1382", "503", "467", "469", "529", "530", "478", "490", "610", "647", "547", "644", "3953", "599", "580", "340", "636", "609", "666", "523", "515", "517", "522", "521", "541", "531", "502", "492"]
}, {
    "City": "Wick",
    "DBTZ": "E",
    "Name": "Wick",
    "Country": "United Kingdom",
    "IATA/FAA": "WIC",
    "Longitude": "-3.093056",
    "ICAO": "EGPC",
    "Airport ID": "531",
    "Latitude": "58.458889",
    "Timezone": "126",
    "DST": "0",
    "destinations": ["535", "532"]
}, {
    "City": "Sumburgh",
    "DBTZ": "E",
    "Name": "Sumburgh",
    "Country": "United Kingdom",
    "IATA/FAA": "LSI",
    "Longitude": "-1.295556",
    "ICAO": "EGPB",
    "Airport ID": "530",
    "Latitude": "59.878889",
    "Timezone": "20",
    "DST": "0",
    "destinations": ["532", "535", "534", "533", "529"]
}, {
    "City": "Ziguinchor",
    "DBTZ": "N",
    "Name": "Ziguinchor",
    "Country": "Senegal",
    "IATA/FAA": "ZIG",
    "Longitude": "-16.281783",
    "ICAO": "GOGG",
    "Airport ID": "1081",
    "Latitude": "12.555617",
    "Timezone": "75",
    "DST": "0",
    "destinations": ["1082", "1084"]
}, {
    "City": "Benbecula",
    "DBTZ": "E",
    "Name": "Benbecula",
    "Country": "United Kingdom",
    "IATA/FAA": "BEB",
    "Longitude": "-7.362778",
    "ICAO": "EGPL",
    "Airport ID": "538",
    "Latitude": "57.481111",
    "Timezone": "19",
    "DST": "0",
    "destinations": ["534", "541"]
}, {
    "City": "Marina Di Campo",
    "DBTZ": "E",
    "Name": "Marina Di Campo",
    "Country": "Italy",
    "IATA/FAA": "EBA",
    "Longitude": "10.239445",
    "ICAO": "LIRJ",
    "Airport ID": "1558",
    "Latitude": "42.760277",
    "Timezone": "31",
    "DST": "1",
    "destinations": ["1679", "382", "346", "1678", "1676"]
}, {
    "City": "Ahmedabad",
    "DBTZ": "N",
    "Name": "Ahmedabad",
    "Country": "India",
    "IATA/FAA": "AMD",
    "Longitude": "72.63465",
    "ICAO": "VAAH",
    "Airport ID": "2994",
    "Latitude": "23.077242",
    "Timezone": "189",
    "DST": "5.5",
    "destinations": ["3131", "2997", "3043", "3093", "3007", "3141", "3098", "3144", "3017", "2179", "2176", "2188", "8076", "2191", "3304", "2241", "2194", "3316"]
}, {
    "City": "Mpumalanga",
    "DBTZ": "U",
    "Name": "Kruger Mpumalanga International Airport",
    "Country": "South Africa",
    "IATA/FAA": "MQP",
    "Longitude": "31.1056",
    "ICAO": "FAKN",
    "Airport ID": "5604",
    "Latitude": "-25.3832",
    "Timezone": "2829",
    "DST": "2",
    "destinations": ["797", "799", "813", "906"]
}, {
    "City": "Charlo",
    "DBTZ": "A",
    "Name": "Charlo",
    "Country": "Canada",
    "IATA/FAA": "YCL",
    "Longitude": "-66.330278",
    "ICAO": "CYCL",
    "Airport ID": "36",
    "Latitude": "47.990833",
    "Timezone": "132",
    "DST": "-4",
    "destinations": ["73", "161"]
}, {
    "City": "Shire Indasilase",
    "DBTZ": "U",
    "Name": "Shire Inda Selassie Airport",
    "Country": "Ethiopia",
    "IATA/FAA": "SHC",
    "Longitude": "38.2725",
    "ICAO": "",
    "Airport ID": "5601",
    "Latitude": "14.0781",
    "Timezone": "6207",
    "DST": "3",
    "destinations": ["1118"]
}, {
    "City": "Tanger",
    "DBTZ": "N",
    "Name": "Ibn Batouta",
    "Country": "Morocco",
    "IATA/FAA": "TNG",
    "Longitude": "-5.916889",
    "ICAO": "GMTT",
    "Airport ID": "1080",
    "Latitude": "35.726917",
    "Timezone": "62",
    "DST": "0",
    "destinations": ["580", "1218", "302", "502", "1074", "507", "1229", "1386", "1367", "304", "1353", "1638"]
}, {
    "City": "Alor Setar",
    "DBTZ": "N",
    "Name": "Sultan Abdul Halim",
    "Country": "Malaysia",
    "IATA/FAA": "AOR",
    "Longitude": "100.398183",
    "ICAO": "WMKA",
    "Airport ID": "3296",
    "Latitude": "6.189667",
    "Timezone": "15",
    "DST": "8",
    "destinations": ["3304", "3408"]
}, {
    "City": "Zweibruecken",
    "DBTZ": "E",
    "Name": "Zweibruecken",
    "Country": "Germany",
    "IATA/FAA": "ZQW",
    "Longitude": "7.401323",
    "ICAO": "",
    "Airport ID": "6510",
    "Latitude": "49.209445",
    "Timezone": "1132",
    "DST": "1",
    "destinations": ["1688", "1051", "1054", "3998", "1056"]
}, {
    "City": "Joensuu",
    "DBTZ": "E",
    "Name": "Joensuu",
    "Country": "Finland",
    "IATA/FAA": "JOE",
    "Longitude": "29.60755",
    "ICAO": "EFJO",
    "Airport ID": "429",
    "Latitude": "62.662906",
    "Timezone": "398",
    "DST": "2",
    "destinations": ["421"]
}, {
    "City": "Ivalo",
    "DBTZ": "E",
    "Name": "Ivalo",
    "Country": "Finland",
    "IATA/FAA": "IVL",
    "Longitude": "27.405328",
    "ICAO": "EFIV",
    "Airport ID": "428",
    "Latitude": "68.607269",
    "Timezone": "481",
    "DST": "2",
    "destinations": ["438"]
}, {
    "City": "Lille",
    "DBTZ": "E",
    "Name": "Lesquin",
    "Country": "France",
    "IATA/FAA": "LIL",
    "Longitude": "3.089444",
    "ICAO": "LFQQ",
    "Airport ID": "1399",
    "Latitude": "50.561942",
    "Timezone": "157",
    "DST": "1",
    "destinations": ["1321", "1280", "1264", "1323", "1335", "1359", "1418", "1435", "1273", "1353", "1354", "210", "231", "293", "1636", "1324", "1460", "1200", "1626", "1051", "1452", "309", "1472", "1206", "1218", "1064", "1070", "1075", "1551", "1665"]
}, {
    "City": "Helsinki",
    "DBTZ": "E",
    "Name": "Helsinki Vantaa",
    "Country": "Finland",
    "IATA/FAA": "HEL",
    "Longitude": "24.963333",
    "ICAO": "EFHK",
    "Airport ID": "421",
    "Latitude": "60.317222",
    "Timezone": "179",
    "DST": "2",
    "destinations": ["351", "3797", "507", "478", "345", "340", "342", "346", "1613", "1678", "1382", "1230", "580", "737", "1688", "1218", "3885", "738", "302", "1489", "3393", "609", "1200", "3093", "4029", "1555", "687", "1665", "8266", "3077", "3930", "428", "429", "430", "433", "437", "16", "432", "3992", "435", "669", "439", "2948", "1638", "1569", "1229", "442", "2954", "1524", "1354", "3942", "743", "2279", "644", "446", "3364", "1587", "3406", "3953", "453", "3316", "455", "2985", "2975", "416", "460", "415", "1590", "458", "462", "3959", "679", "3379", "6111", "1212", "3941", "1191", "1474", "599", "502", "1386", "3998", "1206", "668", "2939", "1701", "448", "1706"]
}, {
    "City": "Birmingham",
    "DBTZ": "A",
    "Name": "Birmingham Intl",
    "Country": "United States",
    "IATA/FAA": "BHM",
    "Longitude": "-86.75355",
    "ICAO": "KBHM",
    "Airport ID": "3811",
    "Latitude": "33.562942",
    "Timezone": "644",
    "DST": "-6",
    "destinations": ["3876", "3520", "3670", "3576", "3752", "3682", "3645", "3697", "3878", "3858", "3849", "3502", "3877", "3646", "3751", "3550", "3830", "1948", "3566", "3747"]
}, {
    "City": "Modesto",
    "DBTZ": "A",
    "Name": "Modesto City Co Harry Sham",
    "Country": "United States",
    "IATA/FAA": "MOD",
    "Longitude": "-120.954422",
    "ICAO": "KMOD",
    "Airport ID": "3816",
    "Latitude": "37.625817",
    "Timezone": "97",
    "DST": "-8",
    "destinations": ["3469"]
}, {
    "City": "Sacramento",
    "DBTZ": "A",
    "Name": "Sacramento Intl",
    "Country": "United States",
    "IATA/FAA": "SMF",
    "Longitude": "-121.590778",
    "ICAO": "KSMF",
    "Airport ID": "3817",
    "Latitude": "38.695417",
    "Timezone": "27",
    "DST": "-8",
    "destinations": ["3876", "3670", "3484", "3456", "3462", "3577", "3682", "1804", "3495", "3720", "3839", "3797", "3582", "3858", "3536", "3751", "3877", "3731", "3867", "3728", "4384", "3714", "3550", "3830", "3469", "3644", "3747", "3734"]
}, {
    "City": "Colorado Springs",
    "DBTZ": "A",
    "Name": "City Of Colorado Springs Muni",
    "Country": "United States",
    "IATA/FAA": "COS",
    "Longitude": "-104.700778",
    "ICAO": "KCOS",
    "Airport ID": "3819",
    "Latitude": "38.805805",
    "Timezone": "6187",
    "DST": "-7",
    "destinations": ["3670", "3577", "3682", "3536", "3877", "3751", "3714", "3550", "3484", "3830", "3469"]
}, {
    "City": "San Luis Potosi",
    "DBTZ": "S",
    "Name": "Ponciano Arriaga Intl",
    "Country": "Mexico",
    "IATA/FAA": "SLP",
    "Longitude": "-100.930806",
    "ICAO": "MMSP",
    "Airport ID": "1842",
    "Latitude": "22.254303",
    "Timezone": "6035",
    "DST": "-6",
    "destinations": ["3670", "1824", "3550", "3826", "1852", "1847"]
}, {
    "City": "Worcester",
    "DBTZ": "A",
    "Name": "Worcester Regional Airport",
    "Country": "United States",
    "IATA/FAA": "ORH",
    "Longitude": "-71.8757",
    "ICAO": "KORH",
    "Airport ID": "6426",
    "Latitude": "42.2673",
    "Timezone": "1009",
    "DST": "-5",
    "destinations": ["3533", "3878"]
}, {
    "City": "Anqing",
    "DBTZ": "N",
    "Name": "Anqing Airport",
    "Country": "China",
    "IATA/FAA": "AQG",
    "Longitude": "117.0502",
    "ICAO": "ZSAQ",
    "Airport ID": "6427",
    "Latitude": "30.5822",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3370", "3393", "3383", "4120", "3364", "3391", "3376"]
}, {
    "City": "Tomsk",
    "DBTZ": "N",
    "Name": "Tomsk Bogashevo Airport",
    "Country": "Russia",
    "IATA/FAA": "TOF",
    "Longitude": "85.208333",
    "ICAO": "UNTT",
    "Airport ID": "4297",
    "Latitude": "56.380278",
    "Timezone": "597",
    "DST": "7",
    "destinations": ["4029", "2985", "4374", "2974"]
}, {
    "City": "Gillette",
    "DBTZ": "A",
    "Name": "Gillette-Campbell County Airport",
    "Country": "United States",
    "IATA/FAA": "GCC",
    "Longitude": "-105.5393611",
    "ICAO": "KGCC",
    "Airport ID": "4296",
    "Latitude": "44.3489167",
    "Timezone": "4365",
    "DST": "-7",
    "destinations": ["5766", "3536", "3751"]
}, {
    "City": "Ballina Byron Bay",
    "DBTZ": "O",
    "Name": "Ballina Byron Gateway",
    "Country": "Australia",
    "IATA/FAA": "BNK",
    "Longitude": "153.5625",
    "ICAO": "YBNA",
    "Airport ID": "4291",
    "Latitude": "-28.833889",
    "Timezone": "7",
    "DST": "10",
    "destinations": ["3339", "3361", "4320"]
}, {
    "City": "Qinhuangdao",
    "DBTZ": "N",
    "Name": "Shanhaiguan Airport",
    "Country": "China",
    "IATA/FAA": "SHP",
    "Longitude": "119.731",
    "ICAO": "ZBSH",
    "Airport ID": "6429",
    "Latitude": "39.9681",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3406", "3404", "6347", "3390"]
}, {
    "City": "Fargo",
    "DBTZ": "A",
    "Name": "Hector International Airport",
    "Country": "United States",
    "IATA/FAA": "FAR",
    "Longitude": "-96.8157639",
    "ICAO": "KFAR",
    "Airport ID": "4292",
    "Latitude": "46.92065",
    "Timezone": "902",
    "DST": "-6",
    "destinations": ["3670", "3830", "3682", "3858", "3536", "3751", "6505", "3877", "3617", "4167"]
}, {
    "City": "Mattala",
    "DBTZ": "N",
    "Name": "Mattala Rajapaksa Intl.",
    "Country": "Sri Lanka",
    "IATA/FAA": "HRI",
    "Longitude": "81.124128",
    "ICAO": "VCRI",
    "Airport ID": "8949",
    "Latitude": "6.284467",
    "Timezone": "157",
    "DST": "5.5",
    "destinations": ["3024", "2072", "3364", "3406"]
}, {
    "City": "Jinzhou",
    "DBTZ": "U",
    "Name": "Jinzhou Airport",
    "Country": "China",
    "IATA/FAA": "JNZ",
    "Longitude": "121.062",
    "ICAO": "ZYJZ",
    "Airport ID": "6412",
    "Latitude": "41.1014",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3390", "3406", "3386"]
}, {
    "City": "Khalije Fars",
    "DBTZ": "U",
    "Name": "Persian Gulf Airport",
    "Country": "Iran",
    "IATA/FAA": "PGU",
    "Longitude": "52.7375",
    "ICAO": "OIBP",
    "Airport ID": "7546",
    "Latitude": "27.379444",
    "Timezone": "27",
    "DST": "3.5",
    "destinations": ["2104", "4150", "2123", "2157", "2131"]
}, {
    "City": "Yasuj",
    "DBTZ": "U",
    "Name": "Yasuj Airport",
    "Country": "Iran",
    "IATA/FAA": "YES",
    "Longitude": "51.545",
    "ICAO": "OISY",
    "Airport ID": "7547",
    "Latitude": "30.700556",
    "Timezone": "5939",
    "DST": "3.5",
    "destinations": ["2131"]
}, {
    "City": "Koggala",
    "DBTZ": "U",
    "Name": "Koggala Airport",
    "Country": "Sri Lanka",
    "IATA/FAA": "KCT",
    "Longitude": "80.3203",
    "ICAO": "VCCK",
    "Airport ID": "6169",
    "Latitude": "5.99368",
    "Timezone": "10",
    "DST": "5.5",
    "destinations": ["3024", "7562"]
}, {
    "City": "Hubli",
    "DBTZ": "N",
    "Name": "Hubli Airport",
    "Country": "India",
    "IATA/FAA": "HBX",
    "Longitude": "75.0849",
    "ICAO": "VAHB",
    "Airport ID": "6168",
    "Latitude": "15.3617",
    "Timezone": "2171",
    "DST": "5.5",
    "destinations": ["3131", "2997"]
}, {
    "City": "Bugulma",
    "DBTZ": "N",
    "Name": "Bugulma Airport",
    "Country": "Russia",
    "IATA/FAA": "UUA",
    "Longitude": "52.8017",
    "ICAO": "UWKB",
    "Airport ID": "6160",
    "Latitude": "54.64",
    "Timezone": "991",
    "DST": "4",
    "destinations": ["4029", "2948", "2972"]
}, {
    "City": "Cheboksary",
    "DBTZ": "N",
    "Name": "Cheboksary Airport",
    "Country": "Russia",
    "IATA/FAA": "CSY",
    "Longitude": "47.3473",
    "ICAO": "UWKS",
    "Airport ID": "6162",
    "Latitude": "56.0903",
    "Timezone": "558",
    "DST": "4",
    "destinations": ["4029", "2948"]
}, {
    "City": "Penza",
    "DBTZ": "N",
    "Name": "Penza Airport",
    "Country": "Russia",
    "IATA/FAA": "PEZ",
    "Longitude": "45.0211",
    "ICAO": "UWPP",
    "Airport ID": "6165",
    "Latitude": "53.1106",
    "Timezone": "614",
    "DST": "4",
    "destinations": ["4029"]
}, {
    "City": "Orsk",
    "DBTZ": "N",
    "Name": "Orsk Airport",
    "Country": "Russia",
    "IATA/FAA": "OSW",
    "Longitude": "58.5956",
    "ICAO": "UWOR",
    "Airport ID": "6164",
    "Latitude": "51.0725",
    "Timezone": "909",
    "DST": "6",
    "destinations": ["4029"]
}, {
    "City": "Saransk",
    "DBTZ": "N",
    "Name": "Saransk Airport",
    "Country": "Russia",
    "IATA/FAA": "SKX",
    "Longitude": "45.2123",
    "ICAO": "UWPS",
    "Airport ID": "6166",
    "Latitude": "54.1251",
    "Timezone": "676",
    "DST": "4",
    "destinations": ["4029", "2948"]
}, {
    "City": "Long Akah",
    "DBTZ": "N",
    "Name": "Long Akah Airport",
    "Country": "Malaysia",
    "IATA/FAA": "LKH",
    "Longitude": "114.783",
    "ICAO": "WBGL",
    "Airport ID": "6769",
    "Latitude": "3.3",
    "Timezone": "289",
    "DST": "8",
    "destinations": ["3265", "3266"]
}, {
    "City": "Geneina",
    "DBTZ": "U",
    "Name": "Geneina Airport",
    "Country": "Sudan",
    "IATA/FAA": "EGN",
    "Longitude": "22.4672",
    "ICAO": "HSGN",
    "Airport ID": "6768",
    "Latitude": "13.4817",
    "Timezone": "2650",
    "DST": "3",
    "destinations": ["1175"]
}, {
    "City": "Togiak Village",
    "DBTZ": "A",
    "Name": "Togiak Airport",
    "Country": "United States",
    "IATA/FAA": "TOG",
    "Longitude": "-160.397",
    "ICAO": "PATG",
    "Airport ID": "6767",
    "Latitude": "59.0528",
    "Timezone": "21",
    "DST": "-9",
    "destinations": ["7206"]
}, {
    "City": "Port Heiden",
    "DBTZ": "A",
    "Name": "Port Heiden Airport",
    "Country": "United States",
    "IATA/FAA": "PTH",
    "Longitude": "-158.633",
    "ICAO": "PAPH",
    "Airport ID": "6766",
    "Latitude": "56.9591",
    "Timezone": "95",
    "DST": "-9",
    "destinations": ["3794"]
}, {
    "City": "King Cove",
    "DBTZ": "A",
    "Name": "King Cove Airport",
    "Country": "United States",
    "IATA/FAA": "KVC",
    "Longitude": "-162.266",
    "ICAO": "PAVC",
    "Airport ID": "6765",
    "Latitude": "55.1163",
    "Timezone": "155",
    "DST": "-9",
    "destinations": ["3854"]
}, {
    "City": "Igiugig",
    "DBTZ": "A",
    "Name": "Igiugig Airport",
    "Country": "United States",
    "IATA/FAA": "IGG",
    "Longitude": "-155.902",
    "ICAO": "PAIG",
    "Airport ID": "6763",
    "Latitude": "59.324",
    "Timezone": "90",
    "DST": "-9",
    "destinations": ["3794"]
}, {
    "City": "Kangra",
    "DBTZ": "N",
    "Name": "Kangra Airport",
    "Country": "India",
    "IATA/FAA": "DHM",
    "Longitude": "76.2634",
    "ICAO": "VIGG",
    "Airport ID": "6760",
    "Latitude": "32.1651",
    "Timezone": "2525",
    "DST": "5.5",
    "destinations": ["3093"]
}, {
    "City": "Vunisea",
    "DBTZ": "U",
    "Name": "Vunisea Airport",
    "Country": "Fiji",
    "IATA/FAA": "KDV",
    "Longitude": "178.157",
    "ICAO": "NFKD",
    "Airport ID": "5869",
    "Latitude": "-19.0581",
    "Timezone": "6",
    "DST": "12",
    "destinations": ["1960", "1961"]
}, {
    "City": "Mitiaro Island",
    "DBTZ": "U",
    "Name": "Mitiaro Island Airport",
    "Country": "Cook Islands",
    "IATA/FAA": "MOI",
    "Longitude": "-157.703",
    "ICAO": "NCMR",
    "Airport ID": "5865",
    "Latitude": "-19.8425",
    "Timezone": "25",
    "DST": "-10",
    "destinations": ["1959"]
}, {
    "City": "Mauke Island",
    "DBTZ": "U",
    "Name": "Mauke Airport",
    "Country": "Cook Islands",
    "IATA/FAA": "MUK",
    "Longitude": "-157.345",
    "ICAO": "NCMK",
    "Airport ID": "5864",
    "Latitude": "-20.1361",
    "Timezone": "26",
    "DST": "-10",
    "destinations": ["1959"]
}, {
    "City": "Cicia",
    "DBTZ": "U",
    "Name": "Cicia Airport",
    "Country": "Fiji",
    "IATA/FAA": "ICI",
    "Longitude": "-179.342",
    "ICAO": "NFCI",
    "Airport ID": "5867",
    "Latitude": "-17.7433",
    "Timezone": "13",
    "DST": "12",
    "destinations": ["1961"]
}, {
    "City": "Atiu Island",
    "DBTZ": "U",
    "Name": "Atiu Island Airport",
    "Country": "Cook Islands",
    "IATA/FAA": "AIU",
    "Longitude": "-158.119",
    "ICAO": "NCAT",
    "Airport ID": "5861",
    "Latitude": "-19.9678",
    "Timezone": "36",
    "DST": "-10",
    "destinations": ["1959"]
}, {
    "City": "Mangaia Island",
    "DBTZ": "U",
    "Name": "Mangaia Island Airport",
    "Country": "Cook Islands",
    "IATA/FAA": "MGS",
    "Longitude": "-157.905",
    "ICAO": "NCMG",
    "Airport ID": "5862",
    "Latitude": "-21.8956",
    "Timezone": "45",
    "DST": "-10",
    "destinations": ["1959"]
}, {
    "City": "Klagenfurt",
    "DBTZ": "E",
    "Name": "Woerthersee International Airport",
    "Country": "Austria",
    "IATA/FAA": "KLU",
    "Longitude": "14.337739",
    "ICAO": "LOWK",
    "Airport ID": "4322",
    "Latitude": "46.642514",
    "Timezone": "452",
    "DST": "1",
    "destinations": ["344", "342", "351", "1613"]
}, {
    "City": "Khanty-Mansiysk",
    "DBTZ": "N",
    "Name": "Khanty Mansiysk Airport",
    "Country": "Russia",
    "IATA/FAA": "HMA",
    "Longitude": "69.086067",
    "ICAO": "USHH",
    "Airport ID": "6138",
    "Latitude": "61.028479",
    "Timezone": "76",
    "DST": "6",
    "destinations": ["6147", "4029", "2972", "2958", "4078", "2975", "4111", "4377"]
}, {
    "City": "Abu Dhabi",
    "DBTZ": "U",
    "Name": "Bateen",
    "Country": "United Arab Emirates",
    "IATA/FAA": "AZI",
    "Longitude": "54.458084",
    "ICAO": "OMAD",
    "Airport ID": "2180",
    "Latitude": "24.428333",
    "Timezone": "16",
    "DST": "4",
    "destinations": ["2188", "2189", "8961", "8962"]
}, {
    "City": "Fujeirah",
    "DBTZ": "U",
    "Name": "Fujairah Intl",
    "Country": "United Arab Emirates",
    "IATA/FAA": "FJR",
    "Longitude": "56.323964",
    "ICAO": "OMFJ",
    "Airport ID": "2189",
    "Latitude": "25.112225",
    "Timezone": "152",
    "DST": "4",
    "destinations": ["2180"]
}, {
    "City": "Dubai",
    "DBTZ": "U",
    "Name": "Dubai Intl",
    "Country": "United Arab Emirates",
    "IATA/FAA": "DXB",
    "Longitude": "55.364444",
    "ICAO": "OMDB",
    "Airport ID": "2188",
    "Latitude": "25.252778",
    "Timezone": "62",
    "DST": "4",
    "destinations": ["3076", "2050", "2397", "2997", "3136", "3093", "3141", "3144", "3153", "1157", "3143", "507", "1382", "210", "3137", "3007", "580", "3448", "3670", "3714", "3550", "3797", "3484", "1524", "3577", "3469", "3069", "3074", "3272", "3364", "3077", "3370", "1121", "3682", "951", "248", "1107", "3341", "2994", "2170", "737", "3941", "2057", "1218", "2177", "4130", "469", "3885", "3131", "3320", "2234", "1128", "3043", "3275", "3024", "1074", "609", "797", "1177", "1084", "4029", "2064", "2241", "599", "799", "345", "1187", "3989", "1555", "340", "2560", "534", "2564", "1665", "342", "3179", "2359", "3930", "4330", "2223", "1701", "2072", "813", "2939", "2206", "3992", "1175", "3304", "2176", "1197", "2948", "502", "2207", "1638", "273", "907", "1335", "1229", "478", "2194", "2074", "3339", "1606", "3156", "893", "346", "4059", "1354", "521", "2279", "3351", "2219", "1587", "3406", "2082", "3980", "994", "3205", "3316", "6776", "3361", "2276", "287", "1551", "1613", "679", "193", "1678", "2110", "2134", "2106", "5934", "4331", "2157", "2162", "2167", "2166", "2051", "2059", "2976", "1739", "2979", "2067", "3964", "2912", "2922", "2069", "3410", "6105", "5952", "4119", "1173", "2960", "3125", "3105", "2962", "2214", "7490", "1657", "5702", "2964", "4317", "1737", "2195", "3973", "2090", "2089", "2967", "2096", "2983", "2104", "4150", "3977", "3974", "1122", "2985", "3382", "5712", "2180", "8961", "4078", "4118", "2975", "1165", "1145", "4374", "2109"]
}, {
    "City": "Tebessa",
    "DBTZ": "N",
    "Name": "Cheikh Larbi Tebessi",
    "Country": "Algeria",
    "IATA/FAA": "TEE",
    "Longitude": "8.120717",
    "ICAO": "DABS",
    "Airport ID": "222",
    "Latitude": "35.431611",
    "Timezone": "2661",
    "DST": "1",
    "destinations": ["210"]
}, {
    "City": "Constantine",
    "DBTZ": "N",
    "Name": "Mohamed Boudiaf Intl",
    "Country": "Algeria",
    "IATA/FAA": "CZL",
    "Longitude": "6.620386",
    "ICAO": "DABC",
    "Airport ID": "221",
    "Latitude": "36.276028",
    "Timezone": "2265",
    "DST": "1",
    "destinations": ["210", "234", "1382", "237", "238", "1701", "1335", "1423", "1353", "1354", "231", "1386"]
}, {
    "City": "Annaba",
    "DBTZ": "N",
    "Name": "Annaba",
    "Country": "Algeria",
    "IATA/FAA": "AAE",
    "Longitude": "7.809167",
    "ICAO": "DABB",
    "Airport ID": "220",
    "Latitude": "36.822225",
    "Timezone": "16",
    "DST": "1",
    "destinations": ["210", "1382", "1701", "1335", "1353", "231", "1386"]
}, {
    "City": "Moenjodaro",
    "DBTZ": "N",
    "Name": "Moenjodaro",
    "Country": "Pakistan",
    "IATA/FAA": "MJD",
    "Longitude": "68.143053",
    "ICAO": "OPMJ",
    "Airport ID": "2212",
    "Latitude": "27.335156",
    "Timezone": "154",
    "DST": "5",
    "destinations": ["2206", "2227"]
}, {
    "City": "Panjgur",
    "DBTZ": "N",
    "Name": "Panjgur",
    "Country": "Pakistan",
    "IATA/FAA": "PJG",
    "Longitude": "64.132517",
    "ICAO": "OPPG",
    "Airport ID": "2217",
    "Latitude": "26.954547",
    "Timezone": "3289",
    "DST": "5",
    "destinations": ["2206"]
}, {
    "City": "Multan",
    "DBTZ": "N",
    "Name": "Multan Intl",
    "Country": "Pakistan",
    "IATA/FAA": "MUX",
    "Longitude": "71.419111",
    "ICAO": "OPMT",
    "Airport ID": "2214",
    "Latitude": "30.203222",
    "Timezone": "403",
    "DST": "5",
    "destinations": ["2188", "2206", "2074", "2223", "2072", "2207", "2191", "2221"]
}, {
    "City": "Peshawar",
    "DBTZ": "N",
    "Name": "Peshawar Intl",
    "Country": "Pakistan",
    "IATA/FAA": "PEW",
    "Longitude": "71.514581",
    "ICAO": "OPPS",
    "Airport ID": "2219",
    "Latitude": "33.993911",
    "Timezone": "1158",
    "DST": "5",
    "destinations": ["2188", "2179", "2191", "2057", "2206", "5937", "2072", "2194", "2082", "5940", "2241", "5943", "2223", "2050", "3304", "2176", "2207"]
}, {
    "City": "Val D'or",
    "DBTZ": "A",
    "Name": "Val D Or",
    "Country": "Canada",
    "IATA/FAA": "YVO",
    "Longitude": "-77.782778",
    "ICAO": "CYVO",
    "Airport ID": "153",
    "Latitude": "48.053333",
    "Timezone": "1107",
    "DST": "-5",
    "destinations": ["146", "149", "71", "5507", "5472"]
}, {
    "City": "Broughton Island",
    "DBTZ": "A",
    "Name": "Qikiqtarjuaq",
    "Country": "Canada",
    "IATA/FAA": "YVM",
    "Longitude": "-64.031389",
    "ICAO": "CYVM",
    "Airport ID": "152",
    "Latitude": "67.545833",
    "Timezone": "21",
    "DST": "-5",
    "destinations": ["170"]
}, {
    "City": "Norman Wells",
    "DBTZ": "A",
    "Name": "Norman Wells",
    "Country": "Canada",
    "IATA/FAA": "YVQ",
    "Longitude": "-126.798219",
    "ICAO": "CYVQ",
    "Airport ID": "155",
    "Latitude": "65.281617",
    "Timezone": "238",
    "DST": "-7",
    "destinations": ["54", "196", "4239", "4238"]
}, {
    "City": "Quujjuaq",
    "DBTZ": "A",
    "Name": "Kuujjuaq",
    "Country": "Canada",
    "IATA/FAA": "YVP",
    "Longitude": "-68.426944",
    "ICAO": "CYVP",
    "Airport ID": "154",
    "Latitude": "58.096111",
    "Timezone": "129",
    "DST": "-5",
    "destinations": ["5512", "5496", "5481", "80", "5520", "5508", "6727", "6728", "55", "146"]
}, {
    "City": "Vancouver",
    "DBTZ": "A",
    "Name": "Vancouver Intl",
    "Country": "Canada",
    "IATA/FAA": "YVR",
    "Longitude": "-123.184444",
    "ICAO": "CYVR",
    "Airport ID": "156",
    "Latitude": "49.193889",
    "Timezone": "14",
    "DST": "-8",
    "destinations": ["4144", "87", "176", "6738", "30", "5523", "119", "162", "164", "184", "200", "8223", "5545", "48", "78", "127", "3670", "3877", "3484", "507", "2279", "3830", "3720", "3462", "3577", "2006", "3751", "3494", "340", "3077", "2359", "3728", "3930", "1824", "346", "3456", "3364", "3406", "1836", "3469", "3361", "33", "34", "49", "90", "100", "108", "120", "146", "160", "166", "168", "172", "173", "178", "179", "181", "193", "198", "2276", "3797", "3370", "580", "3858", "3536", "3867", "2397", "1852", "502", "478", "3550", "3514", "3602", "3839", "1840"]
}, {
    "City": "Puerto Princesa",
    "DBTZ": "N",
    "Name": "Puerto Princesa",
    "Country": "Philippines",
    "IATA/FAA": "PPS",
    "Longitude": "118.758731",
    "ICAO": "RPVP",
    "Airport ID": "2433",
    "Latitude": "9.742119",
    "Timezone": "71",
    "DST": "8",
    "destinations": ["2397", "4206", "4090", "2429", "3269"]
}, {
    "City": "Sheridan",
    "DBTZ": "A",
    "Name": "Sheridan County Airport",
    "Country": "United States",
    "IATA/FAA": "SHR",
    "Longitude": "-106.98",
    "ICAO": "KSHR",
    "Airport ID": "5769",
    "Latitude": "44.7692",
    "Timezone": "4021",
    "DST": "-7",
    "destinations": ["3751"]
}, {
    "City": "San Jose",
    "DBTZ": "N",
    "Name": "Antique",
    "Country": "Philippines",
    "IATA/FAA": "SJI",
    "Longitude": "121.933439",
    "ICAO": "RPVS",
    "Airport ID": "2435",
    "Latitude": "10.766044",
    "Timezone": "23",
    "DST": "8",
    "destinations": ["2397"]
}, {
    "City": "Laoag",
    "DBTZ": "N",
    "Name": "Laoag Intl",
    "Country": "Philippines",
    "IATA/FAA": "LAO",
    "Longitude": "120.531522",
    "ICAO": "RPLI",
    "Airport ID": "4202",
    "Latitude": "18.178092",
    "Timezone": "25",
    "DST": "8",
    "destinations": ["2397"]
}, {
    "City": "Legazpi",
    "DBTZ": "N",
    "Name": "Legazpi",
    "Country": "Philippines",
    "IATA/FAA": "LGP",
    "Longitude": "123.746247",
    "ICAO": "RPLP",
    "Airport ID": "4203",
    "Latitude": "13.157064",
    "Timezone": "66",
    "DST": "8",
    "destinations": ["2397", "4206"]
}, {
    "City": "Beijing",
    "DBTZ": "U",
    "Name": "Beijing Nanyuan Airport",
    "Country": "China",
    "IATA/FAA": "NAY",
    "Longitude": "116.387778",
    "ICAO": "ZBBB",
    "Airport ID": "6341",
    "Latitude": "39.7825",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["7506", "6346", "9025", "3370", "6342", "6343", "3393", "3371", "3395", "4109", "6344", "6434", "6367", "7457", "6345", "3366", "6349", "3400", "6395", "6365", "6382", "3384", "6383", "6385", "6413", "3387", "6359", "6954", "3391", "3374", "6368", "6392", "6955", "6360", "6350", "3383", "6357"]
}, {
    "City": "Changzhi",
    "DBTZ": "U",
    "Name": "Changzhi Airport",
    "Country": "China",
    "IATA/FAA": "CIH",
    "Longitude": "113.126",
    "ICAO": "ZBCZ",
    "Airport ID": "6343",
    "Latitude": "36.2475",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3395", "3370", "6344", "3364", "3369", "3391", "4120", "3368", "3383", "6341", "3376"]
}, {
    "City": "Dipolog",
    "DBTZ": "N",
    "Name": "Dipolog",
    "Country": "Philippines",
    "IATA/FAA": "DPL",
    "Longitude": "123.334481",
    "ICAO": "RPMG",
    "Airport ID": "4201",
    "Latitude": "8.601261",
    "Timezone": "12",
    "DST": "8",
    "destinations": ["4206", "4090", "2397"]
}, {
    "City": "Hohhot",
    "DBTZ": "U",
    "Name": "Baita Airport",
    "Country": "China",
    "IATA/FAA": "HET",
    "Longitude": "111.824103",
    "ICAO": "ZBHH",
    "Airport ID": "6345",
    "Latitude": "40.851422",
    "Timezone": "3556",
    "DST": "8",
    "destinations": ["3395", "3369", "6347", "3368", "2276", "3370", "3386", "3366", "3364", "3406", "4144", "4108", "3376", "4380", "3371", "3404", "4085", "3388", "3390", "6348", "3399", "6350", "3391", "3375", "6342", "6434", "6349", "6954", "8630", "6955", "3379", "3400", "3384", "6341", "3393"]
}, {
    "City": "Datong",
    "DBTZ": "U",
    "Name": "Datong Airport",
    "Country": "China",
    "IATA/FAA": "DAT",
    "Longitude": "113.482",
    "ICAO": "ZBDT",
    "Airport ID": "6344",
    "Latitude": "40.0603",
    "Timezone": "3442",
    "DST": "8",
    "destinations": ["3369", "3364", "6343", "4144", "3379", "6341", "3406"]
}, {
    "City": "Shijiazhuang",
    "DBTZ": "U",
    "Name": "Shijiazhuang Daguocun International Airport",
    "Country": "China",
    "IATA/FAA": "SJW",
    "Longitude": "114.6973",
    "ICAO": "ZBSJ",
    "Airport ID": "6347",
    "Latitude": "38.280686",
    "Timezone": "233",
    "DST": "8",
    "destinations": ["3395", "3400", "3372", "3370", "3393", "3404", "6345", "3386", "3382", "3391", "4144", "3374", "2276", "3383", "4085", "3390", "3392", "6346", "6351", "3371", "3399", "6429", "8858", "6434", "3384", "4380", "4033", "4030", "3379", "6431", "3388", "3406", "3387", "6392"]
}, {
    "City": "Baotou",
    "DBTZ": "U",
    "Name": "Baotou Airport",
    "Country": "China",
    "IATA/FAA": "BAV",
    "Longitude": "109.997",
    "ICAO": "ZBOW",
    "Airport ID": "6346",
    "Latitude": "40.56",
    "Timezone": "3321",
    "DST": "8",
    "destinations": ["3379", "3370", "3364", "3368", "3369", "3375", "3371", "3395", "4144", "6347", "3399", "3376", "3391", "6342", "3406", "6341", "3388"]
}, {
    "City": "Ulanhot",
    "DBTZ": "U",
    "Name": "Ulanhot Airport",
    "Country": "China",
    "IATA/FAA": "HLH",
    "Longitude": "122.017",
    "ICAO": "ZBUL",
    "Airport ID": "6349",
    "Latitude": "46.083",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3364", "6345", "4144", "6341"]
}, {
    "City": "Pellston",
    "DBTZ": "A",
    "Name": "Pellston Regional Airport of Emmet County Airport",
    "Country": "United States",
    "IATA/FAA": "PLN",
    "Longitude": "-84.7967",
    "ICAO": "KPLN",
    "Airport ID": "5762",
    "Latitude": "45.5709",
    "Timezone": "720",
    "DST": "-5",
    "destinations": ["3645"]
}, {
    "City": "Buonmethuot",
    "DBTZ": "U",
    "Name": "Buon Ma Thuot Airport",
    "Country": "Vietnam",
    "IATA/FAA": "BMV",
    "Longitude": "108.120272",
    "ICAO": "VVBM",
    "Airport ID": "6187",
    "Latitude": "12.668311",
    "Timezone": "1729",
    "DST": "7",
    "destinations": ["3205", "6195", "3199", "3196"]
}, {
    "City": "Tak",
    "DBTZ": "U",
    "Name": "Mae Sot Airport",
    "Country": "Thailand",
    "IATA/FAA": "MAQ",
    "Longitude": "98.545056",
    "ICAO": "VTPM",
    "Airport ID": "6186",
    "Latitude": "16.699856",
    "Timezone": "690",
    "DST": "7",
    "destinations": ["3157"]
}, {
    "City": "Laamu Atoll",
    "DBTZ": "U",
    "Name": "Kadhdhoo Airport",
    "Country": "Maldives",
    "IATA/FAA": "KDO",
    "Longitude": "73.5219",
    "ICAO": "VRMK",
    "Airport ID": "6185",
    "Latitude": "1.85917",
    "Timezone": "4",
    "DST": "5",
    "destinations": ["8892", "3156"]
}, {
    "City": "Haa Dhaalu Atoll",
    "DBTZ": "U",
    "Name": "Hanimaadhoo Airport",
    "Country": "Maldives",
    "IATA/FAA": "HAQ",
    "Longitude": "73.1705",
    "ICAO": "VRMH",
    "Airport ID": "6184",
    "Latitude": "6.74423",
    "Timezone": "4",
    "DST": "5",
    "destinations": ["3153"]
}, {
    "City": "Gan Island",
    "DBTZ": "U",
    "Name": "Gan Island Airport",
    "Country": "Maldives",
    "IATA/FAA": "GAN",
    "Longitude": "73.1556",
    "ICAO": "VRMG",
    "Airport ID": "6183",
    "Latitude": "-0.693342",
    "Timezone": "6",
    "DST": "5",
    "destinations": ["3024", "4235", "3156"]
}, {
    "City": "Nepalgunj",
    "DBTZ": "N",
    "Name": "Nepalgunj Airport",
    "Country": "Nepal",
    "IATA/FAA": "KEP",
    "Longitude": "81.667006",
    "ICAO": "VNNG",
    "Airport ID": "6182",
    "Latitude": "28.103633",
    "Timezone": "540",
    "DST": "5.75",
    "destinations": ["3125"]
}, {
    "City": "Ghadames",
    "DBTZ": "N",
    "Name": "Ghadames East",
    "Country": "Libya",
    "IATA/FAA": "LTD",
    "Longitude": "9.715305",
    "ICAO": "HLTD",
    "Airport ID": "1162",
    "Latitude": "30.151695",
    "Timezone": "1122",
    "DST": "2",
    "destinations": ["1157"]
}, {
    "City": "Chandragarhi",
    "DBTZ": "N",
    "Name": "Chandragadhi Airport",
    "Country": "Nepal",
    "IATA/FAA": "BDP",
    "Longitude": "88.079578",
    "ICAO": "VNCG",
    "Airport ID": "6180",
    "Latitude": "26.570822",
    "Timezone": "300",
    "DST": "5.75",
    "destinations": ["3125"]
}, {
    "City": "Nha Trang",
    "DBTZ": "U",
    "Name": "Cam Ranh Airport",
    "Country": "Vietnam",
    "IATA/FAA": "CXR",
    "Longitude": "109.219372",
    "ICAO": "VVCR",
    "Airport ID": "6189",
    "Latitude": "11.998153",
    "Timezone": "40",
    "DST": "7",
    "destinations": ["3205", "3199", "3196", "4029"]
}, {
    "City": "Haiphong",
    "DBTZ": "U",
    "Name": "Cat Bi International Airport",
    "Country": "Vietnam",
    "IATA/FAA": "HPH",
    "Longitude": "106.724989",
    "ICAO": "VVCI",
    "Airport ID": "6188",
    "Latitude": "20.819386",
    "Timezone": "6",
    "DST": "7",
    "destinations": ["3205", "3196"]
}, {
    "City": "Ipiales",
    "DBTZ": "U",
    "Name": "San Luis",
    "Country": "Colombia",
    "IATA/FAA": "IPI",
    "Longitude": "-77.671764",
    "ICAO": "SKIP",
    "Airport ID": "2725",
    "Latitude": "0.861925",
    "Timezone": "9765",
    "DST": "-5",
    "destinations": ["2709"]
}, {
    "City": "Ibague",
    "DBTZ": "U",
    "Name": "Perales",
    "Country": "Colombia",
    "IATA/FAA": "IBE",
    "Longitude": "-75.1333",
    "ICAO": "SKIB",
    "Airport ID": "2724",
    "Latitude": "4.421608",
    "Timezone": "2999",
    "DST": "-5",
    "destinations": ["2709", "2729"]
}, {
    "City": "Guapi",
    "DBTZ": "U",
    "Name": "Juan Casiano",
    "Country": "Colombia",
    "IATA/FAA": "GPI",
    "Longitude": "-77.8986",
    "ICAO": "SKGP",
    "Airport ID": "2722",
    "Latitude": "2.570133",
    "Timezone": "164",
    "DST": "-5",
    "destinations": ["2715"]
}, {
    "City": "Medellin",
    "DBTZ": "U",
    "Name": "Olaya Herrera",
    "Country": "Colombia",
    "IATA/FAA": "EOH",
    "Longitude": "-75.590519",
    "ICAO": "SKMD",
    "Airport ID": "2729",
    "Latitude": "6.219958",
    "Timezone": "4940",
    "DST": "-5",
    "destinations": ["5476", "2709", "2711", "6053", "2753", "2724", "2731", "2739"]
}, {
    "City": "Leticia",
    "DBTZ": "U",
    "Name": "Alfredo Vasquez Cobo",
    "Country": "Colombia",
    "IATA/FAA": "LET",
    "Longitude": "-69.943163",
    "ICAO": "SKLT",
    "Airport ID": "2728",
    "Latitude": "-4.193549",
    "Timezone": "277",
    "DST": "-5",
    "destinations": ["7356", "6052", "5477", "2709"]
}, {
    "City": "Circle",
    "DBTZ": "A",
    "Name": "Circle City Airport",
    "Country": "United States",
    "IATA/FAA": "IRC",
    "Longitude": "-144.076111",
    "ICAO": "PACR",
    "Airport ID": "7236",
    "Latitude": "65.827778",
    "Timezone": "613",
    "DST": "-9",
    "destinations": ["3832"]
}, {
    "City": "Coffman Cove",
    "DBTZ": "A",
    "Name": "Coffman Cove Seaplane Base",
    "Country": "United States",
    "IATA/FAA": "KCC",
    "Longitude": "-132.833889",
    "ICAO": "",
    "Airport ID": "7237",
    "Latitude": "56.014722",
    "Timezone": "0",
    "DST": "-9",
    "destinations": ["7152"]
}, {
    "City": "Lourdes-De-Blanc-Sablon",
    "DBTZ": "A",
    "Name": "Lourdes De Blanc Sablon Airport",
    "Country": "Canada",
    "IATA/FAA": "YBX",
    "Longitude": "-57.1853",
    "ICAO": "CYBX",
    "Airport ID": "5485",
    "Latitude": "51.4436",
    "Timezone": "121",
    "DST": "-4",
    "destinations": ["24", "188", "6789"]
}, {
    "City": "Beckley",
    "DBTZ": "A",
    "Name": "Raleigh County Memorial Airport",
    "Country": "United States",
    "IATA/FAA": "BKW",
    "Longitude": "-81.1242",
    "ICAO": "KBKW",
    "Airport ID": "5724",
    "Latitude": "37.7873",
    "Timezone": "2504",
    "DST": "-5",
    "destinations": ["3714", "7062"]
}, {
    "City": "Crooked Creek",
    "DBTZ": "A",
    "Name": "Crooked Creek Airport",
    "Country": "United States",
    "IATA/FAA": "CKD",
    "Longitude": "-158.135",
    "ICAO": "",
    "Airport ID": "7238",
    "Latitude": "61.867778",
    "Timezone": "178",
    "DST": "-9",
    "destinations": ["7239", "7240"]
}, {
    "City": "Red Devil",
    "DBTZ": "A",
    "Name": "Red Devil Airport",
    "Country": "United States",
    "IATA/FAA": "RDV",
    "Longitude": "-157.350278",
    "ICAO": "",
    "Airport ID": "7239",
    "Latitude": "61.788056",
    "Timezone": "174",
    "DST": "-9",
    "destinations": ["7240"]
}, {
    "City": "Mobile",
    "DBTZ": "A",
    "Name": "Mobile Rgnl",
    "Country": "United States",
    "IATA/FAA": "MOB",
    "Longitude": "-88.242814",
    "ICAO": "KMOB",
    "Airport ID": "3782",
    "Latitude": "30.691231",
    "Timezone": "219",
    "DST": "-6",
    "destinations": ["3876", "3670", "3682", "3550", "3830"]
}, {
    "City": "Guadalajara",
    "DBTZ": "S",
    "Name": "Don Miguel Hidalgo Y Costilla Intl",
    "Country": "Mexico",
    "IATA/FAA": "GDL",
    "Longitude": "-103.311167",
    "ICAO": "MMGL",
    "Airport ID": "1804",
    "Latitude": "20.5218",
    "Timezone": "5016",
    "DST": "-6",
    "destinations": ["1824", "1825", "3621", "1840", "3867", "1847", "3670", "3484", "3462", "3748", "3682", "1794", "1792", "1797", "3687", "1807", "1816", "1826", "3734", "3830", "1871", "1836", "3469", "3817", "1850", "1845", "3536", "3550", "1796", "1852", "1819", "1839", "1846", "1854", "1853", "3877", "1814", "3747", "1820", "3453", "1831"]
}, {
    "City": "Tepic",
    "DBTZ": "S",
    "Name": "Tepic",
    "Country": "Mexico",
    "IATA/FAA": "TPQ",
    "Longitude": "-104.842581",
    "ICAO": "MMEP",
    "Airport ID": "1802",
    "Latitude": "21.419453",
    "Timezone": "3020",
    "DST": "-7",
    "destinations": ["1824", "1847"]
}, {
    "City": "Santa Fe",
    "DBTZ": "A",
    "Name": "Santa Fe Muni",
    "Country": "United States",
    "IATA/FAA": "SAF",
    "Longitude": "-106.089422",
    "ICAO": "KSAF",
    "Airport ID": "3784",
    "Latitude": "35.617108",
    "Timezone": "6348",
    "DST": "-7",
    "destinations": ["3670", "3484", "3751"]
}, {
    "City": "Cozumel",
    "DBTZ": "S",
    "Name": "Cozumel Intl",
    "Country": "Mexico",
    "IATA/FAA": "CZM",
    "Longitude": "-86.925644",
    "ICAO": "MMCZ",
    "Airport ID": "1800",
    "Latitude": "20.522403",
    "Timezone": "15",
    "DST": "-6",
    "destinations": ["1824", "1852", "3876", "3670", "3576", "146", "3682", "3751", "3550"]
}, {
    "City": "Lycksele",
    "DBTZ": "E",
    "Name": "Lycksele",
    "Country": "Sweden",
    "IATA/FAA": "LYC",
    "Longitude": "18.716219",
    "ICAO": "ESNL",
    "Airport ID": "719",
    "Latitude": "64.548322",
    "Timezone": "705",
    "DST": "1",
    "destinations": ["730", "737"]
}, {
    "City": "Colima",
    "DBTZ": "S",
    "Name": "Colima",
    "Country": "Mexico",
    "IATA/FAA": "CLQ",
    "Longitude": "-103.577397",
    "ICAO": "MMIA",
    "Airport ID": "1808",
    "Latitude": "19.277011",
    "Timezone": "2467",
    "DST": "-6",
    "destinations": ["1824", "1847"]
}, {
    "City": "Barcaldine",
    "DBTZ": "O",
    "Name": "Barcaldine Airport",
    "Country": "Australia",
    "IATA/FAA": "BCI",
    "Longitude": "145.307",
    "ICAO": "YBAR",
    "Airport ID": "6238",
    "Latitude": "-23.5653",
    "Timezone": "878",
    "DST": "10",
    "destinations": ["6289"]
}, {
    "City": "Albany",
    "DBTZ": "O",
    "Name": "Albany Airport",
    "Country": "Australia",
    "IATA/FAA": "ALH",
    "Longitude": "117.809",
    "ICAO": "YABA",
    "Airport ID": "6235",
    "Latitude": "-34.9433",
    "Timezone": "233",
    "DST": "8",
    "destinations": ["3351", "9090"]
}, {
    "City": "Aurukun",
    "DBTZ": "O",
    "Name": "Aurukun Airport",
    "Country": "Australia",
    "IATA/FAA": "AUU",
    "Longitude": "141.721",
    "ICAO": "YAUR",
    "Airport ID": "6237",
    "Latitude": "-13.3539",
    "Timezone": "31",
    "DST": "10",
    "destinations": ["3322", "6260"]
}, {
    "City": "Laut Island",
    "DBTZ": "N",
    "Name": "Stagen Airport",
    "Country": "Indonesia",
    "IATA/FAA": "KBU",
    "Longitude": "116.165",
    "ICAO": "WRBK",
    "Airport ID": "6231",
    "Latitude": "-3.29472",
    "Timezone": "4",
    "DST": "8",
    "destinations": ["3908", "3240"]
}, {
    "City": "Aspen",
    "DBTZ": "A",
    "Name": "Aspen Pitkin County Sardy Field",
    "Country": "United States",
    "IATA/FAA": "ASE",
    "Longitude": "-106.869",
    "ICAO": "KASE",
    "Airport ID": "7001",
    "Latitude": "39.2232",
    "Timezone": "7820",
    "DST": "-7",
    "destinations": ["3751", "3484"]
}, {
    "City": "Kuqa",
    "DBTZ": "U",
    "Name": "Kuqa Airport",
    "Country": "China",
    "IATA/FAA": "KCA",
    "Longitude": "82.9869",
    "ICAO": "ZWKC",
    "Airport ID": "6406",
    "Latitude": "41.7181",
    "Timezone": "3524",
    "DST": "8",
    "destinations": ["3399"]
}, {
    "City": "Kaimana",
    "DBTZ": "N",
    "Name": "Kaimana",
    "Country": "Indonesia",
    "IATA/FAA": "KNG",
    "Longitude": "133.695553",
    "ICAO": "WASK",
    "Airport ID": "3258",
    "Latitude": "-3.644517",
    "Timezone": "19",
    "DST": "9",
    "destinations": ["3256", "3257", "3242"]
}, {
    "City": "Babo",
    "DBTZ": "N",
    "Name": "Babo",
    "Country": "Indonesia",
    "IATA/FAA": "BXB",
    "Longitude": "133.438894",
    "ICAO": "WASO",
    "Airport ID": "3259",
    "Latitude": "-2.532242",
    "Timezone": "10",
    "DST": "9",
    "destinations": ["3261"]
}, {
    "City": "Freeport",
    "DBTZ": "U",
    "Name": "Grand Bahama Intl",
    "Country": "Bahamas",
    "IATA/FAA": "FPO",
    "Longitude": "-78.695553",
    "ICAO": "MYGF",
    "Airport ID": "1948",
    "Latitude": "26.558686",
    "Timezone": "7",
    "DST": "-5",
    "destinations": ["3533", "3876", "3576", "3682", "1953", "3811", "3849", "3759", "3494", "3608"]
}, {
    "City": "Lensk",
    "DBTZ": "U",
    "Name": "Lensk",
    "Country": "Russia",
    "IATA/FAA": "ULK",
    "Longitude": "114.825",
    "ICAO": "UERL",
    "Airport ID": "9026",
    "Latitude": "60.723",
    "Timezone": "843",
    "DST": "10",
    "destinations": ["2937", "2923"]
}, {
    "City": "Manado",
    "DBTZ": "N",
    "Name": "Sam Ratulangi",
    "Country": "Indonesia",
    "IATA/FAA": "MDC",
    "Longitude": "124.925878",
    "ICAO": "WAMM",
    "Airport ID": "3250",
    "Latitude": "1.549447",
    "Timezone": "264",
    "DST": "8",
    "destinations": ["3919", "3275", "3316", "3253", "3240", "3247", "3886", "3261", "3928"]
}, {
    "City": "North Eleuthera",
    "DBTZ": "U",
    "Name": "North Eleuthera",
    "Country": "Bahamas",
    "IATA/FAA": "ELH",
    "Longitude": "-76.683489",
    "ICAO": "MYEH",
    "Airport ID": "1943",
    "Latitude": "25.474861",
    "Timezone": "13",
    "DST": "-5",
    "destinations": ["3533", "3722", "3576", "1953"]
}, {
    "City": "Great Exuma",
    "DBTZ": "U",
    "Name": "Exuma Intl",
    "Country": "Bahamas",
    "IATA/FAA": "GGT",
    "Longitude": "-75.877958",
    "ICAO": "MYEF",
    "Airport ID": "1941",
    "Latitude": "23.562631",
    "Timezone": "9",
    "DST": "-5",
    "destinations": ["3533", "1944", "3576", "193", "3682", "1953"]
}, {
    "City": "Rock Sound",
    "DBTZ": "U",
    "Name": "Rock Sound",
    "Country": "Bahamas",
    "IATA/FAA": "RSD",
    "Longitude": "-76.177739",
    "ICAO": "MYER",
    "Airport ID": "1946",
    "Latitude": "24.8917",
    "Timezone": "10",
    "DST": "-5",
    "destinations": ["1953"]
}, {
    "City": "Ambon",
    "DBTZ": "N",
    "Name": "Pattimura",
    "Country": "Indonesia",
    "IATA/FAA": "AMQ",
    "Longitude": "128.089136",
    "ICAO": "WAPP",
    "Airport ID": "3256",
    "Latitude": "-3.710264",
    "Timezone": "33",
    "DST": "9",
    "destinations": ["3275", "3928", "3240", "3257", "6212", "3260", "3242", "3261"]
}, {
    "City": "Fak Fak",
    "DBTZ": "N",
    "Name": "Fak Fak",
    "Country": "Indonesia",
    "IATA/FAA": "FKQ",
    "Longitude": "132.267031",
    "ICAO": "WASF",
    "Airport ID": "3257",
    "Latitude": "-2.920192",
    "Timezone": "462",
    "DST": "9",
    "destinations": ["3258", "3260", "3261"]
}, {
    "City": "Athens",
    "DBTZ": "A",
    "Name": "Athens Ben Epps Airport",
    "Country": "United States",
    "IATA/FAA": "AHN",
    "Longitude": "-83.3263",
    "ICAO": "KAHN",
    "Airport ID": "5716",
    "Latitude": "33.9486",
    "Timezone": "808",
    "DST": "-5",
    "destinations": ["3690"]
}, {
    "City": "Hoedspruit",
    "DBTZ": "U",
    "Name": "Hoedspruit Afb",
    "Country": "South Africa",
    "IATA/FAA": "HDS",
    "Longitude": "31.048744",
    "ICAO": "FAHS",
    "Airport ID": "811",
    "Latitude": "-24.368642",
    "Timezone": "1743",
    "DST": "2",
    "destinations": ["797", "813"]
}, {
    "City": "Johannesburg",
    "DBTZ": "U",
    "Name": "Johannesburg Intl",
    "Country": "South Africa",
    "IATA/FAA": "JNB",
    "Longitude": "28.246",
    "ICAO": "FAJS",
    "Airport ID": "813",
    "Latitude": "-26.139166",
    "Timezone": "5558",
    "DST": "2",
    "destinations": ["8492", "827", "2997", "507", "1382", "2179", "3797", "797", "799", "1005", "906", "893", "838", "1004", "4105", "879", "877", "881", "880", "1038", "3077", "910", "3682", "951", "2188", "1107", "1013", "994", "804", "1186", "2564", "580", "4059", "340", "346", "1678", "1590", "918", "800", "1128", "3316", "3351", "3361", "2241", "979", "248", "984", "974", "794", "3885", "1001", "883", "1177", "1084", "1187", "1020", "811", "1165", "815", "970", "1016", "273", "907", "5604", "1018", "887", "840", "886", "985", "841", "843", "849", "988", "865", "866", "990", "6778", "2072", "1701", "976", "916"]
}, {
    "City": "Kimberley",
    "DBTZ": "U",
    "Name": "Kimberley",
    "Country": "South Africa",
    "IATA/FAA": "KIM",
    "Longitude": "24.765167",
    "ICAO": "FAKM",
    "Airport ID": "815",
    "Latitude": "-28.802834",
    "Timezone": "3950",
    "DST": "2",
    "destinations": ["797", "813"]
}, {
    "City": "San Antonio",
    "DBTZ": "U",
    "Name": "San Antonio Del Tachira",
    "Country": "Venezuela",
    "IATA/FAA": "SVZ",
    "Longitude": "-72.439742",
    "ICAO": "SVSA",
    "Airport ID": "2858",
    "Latitude": "7.840831",
    "Timezone": "1312",
    "DST": "-4.5",
    "destinations": ["2851"]
}, {
    "City": "Caracas",
    "DBTZ": "U",
    "Name": "Simon Bolivar Intl",
    "Country": "Venezuela",
    "IATA/FAA": "CCS",
    "Longitude": "-66.990583",
    "ICAO": "SVMI",
    "Airport ID": "2851",
    "Latitude": "10.603117",
    "Timezone": "235",
    "DST": "-4.5",
    "destinations": ["2895", "2897", "2821", "2822", "2848", "2849", "2856", "2870", "3670", "3797", "3576", "2890", "1382", "1824", "3988", "2709", "2789", "1555", "2902", "2745", "1871", "1909", "3682", "2564", "1760", "1229", "2673", "2650", "340", "2850", "2861", "6072", "2824", "2844", "2852", "1057", "4091", "1638", "1636", "3550", "2835", "2833", "2846", "2853", "2864", "2858"]
}, {
    "City": "Porlamar",
    "DBTZ": "U",
    "Name": "Del Caribe Intl Gen Santiago Marino",
    "Country": "Venezuela",
    "IATA/FAA": "PMV",
    "Longitude": "-63.967581",
    "ICAO": "SVMG",
    "Airport ID": "2850",
    "Latitude": "10.912926",
    "Timezone": "74",
    "DST": "-4.5",
    "destinations": ["2821", "2851", "1909", "2868", "2856", "2824", "2882", "2551", "2848", "2852", "2854", "2902", "2861", "6072"]
}, {
    "City": "Puerto Ayacucho",
    "DBTZ": "U",
    "Name": "Casique Aramare",
    "Country": "Venezuela",
    "IATA/FAA": "PYH",
    "Longitude": "-67.606103",
    "ICAO": "SVPA",
    "Airport ID": "2853",
    "Latitude": "5.619992",
    "Timezone": "245",
    "DST": "-4.5",
    "destinations": ["2851"]
}, {
    "City": "Maturin",
    "DBTZ": "U",
    "Name": "Maturin",
    "Country": "Venezuela",
    "IATA/FAA": "MUN",
    "Longitude": "-63.1534",
    "ICAO": "SVMT",
    "Airport ID": "2852",
    "Latitude": "9.749067",
    "Timezone": "224",
    "DST": "-4.5",
    "destinations": ["2851", "2850"]
}, {
    "City": "Ho Chi Minh City",
    "DBTZ": "U",
    "Name": "Tansonnhat Intl",
    "Country": "Vietnam",
    "IATA/FAA": "SGN",
    "Longitude": "106.651856",
    "ICAO": "VVTS",
    "Airport ID": "3205",
    "Latitude": "10.818797",
    "Timezone": "33",
    "DST": "7",
    "destinations": ["3316", "3373", "2397", "2279", "2268", "1382", "3304", "340", "2264", "6187", "6189", "3196", "3199", "6188", "4168", "6195", "2276", "3364", "3077", "3370", "2188", "2179", "3157", "2305", "2359", "3992", "3034", "3035", "2908", "3930", "2372", "3406", "3339", "3361", "2241", "3116", "2985", "3885", "4029", "4153", "4082", "6193", "4156", "3275", "3385", "3942", "6194", "3239", "4158", "9135", "4157", "6190", "4154", "4155"]
}, {
    "City": "Guayana",
    "DBTZ": "U",
    "Name": "General Manuel Carlos Piar",
    "Country": "Venezuela",
    "IATA/FAA": "PZO",
    "Longitude": "-62.760361",
    "ICAO": "SVPR",
    "Airport ID": "2856",
    "Latitude": "8.288528",
    "Timezone": "472",
    "DST": "-4.5",
    "destinations": ["2821", "2851", "2850", "2830", "4304", "4126"]
}, {
    "City": "Albury",
    "DBTZ": "O",
    "Name": "Albury",
    "Country": "Australia",
    "IATA/FAA": "ABX",
    "Longitude": "146.958056",
    "ICAO": "YMAY",
    "Airport ID": "3333",
    "Latitude": "-36.067778",
    "Timezone": "539",
    "DST": "10",
    "destinations": ["3361", "3339"]
}, {
    "City": "Avalon",
    "DBTZ": "O",
    "Name": "Avalon",
    "Country": "Australia",
    "IATA/FAA": "AVV",
    "Longitude": "144.469444",
    "ICAO": "YMAV",
    "Airport ID": "3332",
    "Latitude": "-38.039444",
    "Timezone": "35",
    "DST": "10",
    "destinations": ["3361"]
}, {
    "City": "Weipa",
    "DBTZ": "O",
    "Name": "Weipa",
    "Country": "Australia",
    "IATA/FAA": "WEI",
    "Longitude": "141.925278",
    "ICAO": "YBWP",
    "Airport ID": "3331",
    "Latitude": "-12.678611",
    "Timezone": "63",
    "DST": "10",
    "destinations": ["3322"]
}, {
    "City": "Townsville",
    "DBTZ": "N",
    "Name": "Townsville",
    "Country": "Australia",
    "IATA/FAA": "TSV",
    "Longitude": "146.765278",
    "ICAO": "YBTL",
    "Airport ID": "3330",
    "Latitude": "-19.2525",
    "Timezone": "18",
    "DST": "10",
    "destinations": ["3320", "3339", "3361", "6255", "3322", "3999", "3324", "3326", "6301", "6337"]
}, {
    "City": "Gelendzik",
    "DBTZ": "N",
    "Name": "Gelendzik",
    "Country": "Russia",
    "IATA/FAA": "GDZ",
    "Longitude": "38.016666666667",
    "ICAO": "",
    "Airport ID": "7041",
    "Latitude": "44.566666666667",
    "Timezone": "50",
    "DST": "4",
    "destinations": ["2985"]
}, {
    "City": "Hobart",
    "DBTZ": "O",
    "Name": "Hobart",
    "Country": "Australia",
    "IATA/FAA": "HBA",
    "Longitude": "147.510278",
    "ICAO": "YMHB",
    "Airport ID": "3336",
    "Latitude": "-42.836111",
    "Timezone": "13",
    "DST": "10",
    "destinations": ["3320", "3339", "3361"]
}, {
    "City": "North Spirit Lake",
    "DBTZ": "A",
    "Name": "North Spirit Lake Airport",
    "Country": "Canada",
    "IATA/FAA": "YNO",
    "Longitude": "-92.9711",
    "ICAO": "CKQ3",
    "Airport ID": "5467",
    "Latitude": "52.49",
    "Timezone": "1082",
    "DST": "-6",
    "destinations": ["5471", "5527"]
}, {
    "City": "Wunnumin Lake",
    "DBTZ": "A",
    "Name": "Wunnumin Lake Airport",
    "Country": "Canada",
    "IATA/FAA": "WNN",
    "Longitude": "-89.2892",
    "ICAO": "CKL3",
    "Airport ID": "5466",
    "Latitude": "52.8939",
    "Timezone": "819",
    "DST": "-5",
    "destinations": ["5469", "5464", "169"]
}, {
    "City": "Kingfisher Lake",
    "DBTZ": "A",
    "Name": "Kingfisher Lake Airport",
    "Country": "Canada",
    "IATA/FAA": "KIF",
    "Longitude": "-89.8553",
    "ICAO": "CNM5",
    "Airport ID": "5469",
    "Latitude": "53.0125",
    "Timezone": "866",
    "DST": "-5",
    "destinations": ["5466", "169"]
}, {
    "City": "Raiatea Island",
    "DBTZ": "U",
    "Name": "Raiatea",
    "Country": "French Polynesia",
    "IATA/FAA": "RFP",
    "Longitude": "-151.465856",
    "ICAO": "NTTR",
    "Airport ID": "1995",
    "Latitude": "-16.722861",
    "Timezone": "3",
    "DST": "-10",
    "destinations": ["1989", "1991", "1994", "4075"]
}, {
    "City": "Melbourne",
    "DBTZ": "O",
    "Name": "Melbourne Intl",
    "Country": "Australia",
    "IATA/FAA": "MEL",
    "Longitude": "144.843333",
    "ICAO": "YMML",
    "Airport ID": "3339",
    "Latitude": "-37.673333",
    "Timezone": "434",
    "DST": "10",
    "destinations": ["3395", "3341", "3320", "3355", "3484", "3351", "3361", "3093", "3077", "2179", "3272", "3406", "3370", "3304", "2006", "2188", "3316", "2042", "1960", "3275", "3940", "3885", "4291", "2009", "3322", "3999", "3336", "3179", "3728", "6242", "3337", "3325", "2279", "4320", "3321", "3330", "2030", "2397", "3319", "4319", "6264", "3345", "6298", "3349", "3205", "2241", "3326", "3356", "3346", "3333", "6338", "6278", "6303", "6294", "3363"]
}, {
    "City": "Jacksonville NC",
    "DBTZ": "A",
    "Name": "Albert J Ellis",
    "Country": "United States",
    "IATA/FAA": "OAJ",
    "Longitude": "-77.612139",
    "ICAO": "KOAJ",
    "Airport ID": "4386",
    "Latitude": "34.829164",
    "Timezone": "94",
    "DST": "-5",
    "destinations": ["3876", "3520", "3682"]
}, {
    "City": "Maupiti",
    "DBTZ": "U",
    "Name": "Maupiti",
    "Country": "French Polynesia",
    "IATA/FAA": "MAU",
    "Longitude": "-152.243669",
    "ICAO": "NTTP",
    "Airport ID": "1994",
    "Latitude": "-16.426486",
    "Timezone": "15",
    "DST": "-10",
    "destinations": ["1989", "4075", "1995"]
}, {
    "City": "Istanbul",
    "DBTZ": "E",
    "Name": "Ataturk",
    "Country": "Turkey",
    "IATA/FAA": "IST",
    "Longitude": "28.814606",
    "ICAO": "LTBA",
    "Airport ID": "1701",
    "Latitude": "40.976922",
    "Timezone": "163",
    "DST": "2",
    "destinations": ["2979", "1685", "1706", "1688", "4315", "1382", "1722", "1715", "5780", "1724", "1721", "9044", "8266", "1689", "1695", "2947", "1726", "1154", "5804", "1157", "1735", "3941", "507", "193", "1353", "220", "210", "221", "231", "2997", "3093", "1074", "1555", "2954", "3370", "3364", "3406", "3399", "2188", "4330", "2179", "3316", "2057", "2983", "2922", "1646", "1569", "2908", "4357", "4367", "2910", "3930", "3989", "5952", "492", "6969", "3973", "580", "1606", "2176", "340", "346", "679", "1678", "2177", "3304", "1128", "3410", "1130", "2939", "4057", "3992", "2279", "1613", "1208", "4059", "2941", "2940", "2912", "2913", "2241", "2170", "1657", "628", "737", "609", "687", "421", "644", "2965", "2948", "2964", "2985", "2064", "2072", "2074", "2082", "2976", "3885", "248", "1107", "5800", "1230", "5799", "2172", "1696", "1729", "1218", "1739", "9272", "4130", "469", "1216", "608", "1538", "353", "302", "4053", "2234", "1489", "3971", "344", "1651", "3076", "1177", "897", "1699", "599", "345", "535", "2067", "1723", "1682", "382", "1020", "1528", "2564", "1665", "352", "342", "3077", "7453", "3714", "3550", "4150", "9043", "2223", "5801", "3797", "4119", "813", "1180", "270", "2050", "5798", "9273", "1165", "2206", "1175", "2114", "5796", "3125", "6089", "1691", "2990", "9045", "3484", "348", "502", "2207", "1638", "273", "629", "2945", "1335", "1229", "478", "2194", "4331", "3156", "6753", "6782", "1524", "1693", "2053", "6090", "1561", "3987", "1354", "280", "7490", "4161", "347", "3830", "4078", "1587", "1742", "3953", "591", "3980", "1486", "1737", "1194", "350", "2975", "2157", "1612", "2162", "1741", "1190", "2090", "415", "1273", "1590", "1526", "287", "351", "2992", "1728", "1694", "1551", "2988", "1246", "3959", "2096"]
}, {
    "City": "Port-vila",
    "DBTZ": "U",
    "Name": "Port Vila Bauerfield",
    "Country": "Vanuatu",
    "IATA/FAA": "VLI",
    "Longitude": "168.319794",
    "ICAO": "NVVV",
    "Airport ID": "1997",
    "Latitude": "-17.699325",
    "Timezone": "70",
    "DST": "11",
    "destinations": ["4074", "1960", "2006", "3320", "5897", "5916", "5896", "5918", "5902", "5904", "5901", "2005", "5905", "5900", "5908", "5912", "3361", "4242", "5909", "5911"]
}, {
    "City": "Izmir",
    "DBTZ": "E",
    "Name": "Adnan Menderes",
    "Country": "Turkey",
    "IATA/FAA": "ADB",
    "Longitude": "27.156953",
    "ICAO": "LTBJ",
    "Airport ID": "1706",
    "Latitude": "38.292392",
    "Timezone": "412",
    "DST": "2",
    "destinations": ["1701", "599", "1382", "580", "5780", "629", "346", "1685", "1696", "3941", "1688", "302", "4053", "1722", "345", "1682", "1721", "9044", "1689", "7453", "6753", "4317", "548", "350", "1726", "1694", "342", "469", "502", "478", "737", "353", "344", "609", "373", "340", "352", "421", "1335", "1418", "1435", "351", "1613", "1678"]
}, {
    "City": "Faleolo",
    "DBTZ": "U",
    "Name": "Faleolo Intl",
    "Country": "Samoa",
    "IATA/FAA": "APW",
    "Longitude": "-172.008336",
    "ICAO": "NSFA",
    "Airport ID": "1969",
    "Latitude": "-13.829969",
    "Timezone": "58",
    "DST": "13",
    "destinations": ["3728", "1960", "2006"]
}, {
    "City": "Williston",
    "DBTZ": "A",
    "Name": "Sloulin Fld Intl",
    "Country": "United States",
    "IATA/FAA": "ISN",
    "Longitude": "-103.642347",
    "ICAO": "KISN",
    "Airport ID": "3659",
    "Latitude": "48.177939",
    "Timezone": "1982",
    "DST": "-6",
    "destinations": ["3858", "3751"]
}, {
    "City": "Cairo",
    "DBTZ": "U",
    "Name": "Cairo Intl",
    "Country": "Egypt",
    "IATA/FAA": "CAI",
    "Longitude": "31.405556",
    "ICAO": "HECA",
    "Airport ID": "1128",
    "Latitude": "30.121944",
    "Timezone": "382",
    "DST": "2",
    "destinations": ["1590", "1154", "1157", "3941", "1382", "210", "2997", "1074", "2082", "1555", "1524", "507", "3370", "3364", "2188", "2067", "2090", "2089", "2096", "1107", "1197", "2179", "2191", "2057", "3977", "3974", "3980", "3975", "2176", "580", "340", "346", "5804", "1665", "1678", "1136", "5691", "3410", "8235", "1130", "1132", "4057", "2177", "3885", "260", "248", "2059", "2170", "3967", "1218", "4130", "302", "1489", "3275", "609", "1177", "4029", "2064", "2241", "1187", "3989", "1701", "2072", "3797", "813", "1173", "270", "1175", "273", "1229", "478", "2194", "2074", "4059", "4197", "337", "287", "1613", "193", "5702", "2985"]
}, {
    "City": "St. Augustine Airport",
    "DBTZ": "A",
    "Name": "St. Augustine Airport",
    "Country": "United States",
    "IATA/FAA": "UST",
    "Longitude": "-81.339722",
    "ICAO": "KSGJ",
    "Airport ID": "6989",
    "Latitude": "29.959167",
    "Timezone": "10",
    "DST": "-5",
    "destinations": ["3447"]
}, {
    "City": "Bujumbura",
    "DBTZ": "U",
    "Name": "Bujumbura Intl",
    "Country": "Burundi",
    "IATA/FAA": "BJM",
    "Longitude": "29.318519",
    "ICAO": "HBBA",
    "Airport ID": "1120",
    "Latitude": "-3.324019",
    "Timezone": "2582",
    "DST": "2",
    "destinations": ["4059", "1165", "1187"]
}, {
    "City": "Hargeisa",
    "DBTZ": "U",
    "Name": "Egal Intl",
    "Country": "Somalia",
    "IATA/FAA": "HGA",
    "Longitude": "44.088758",
    "ICAO": "HCMH",
    "Airport ID": "1121",
    "Latitude": "9.518167",
    "Timezone": "4423",
    "DST": "3",
    "destinations": ["1149", "4119", "5687", "1107", "1122"]
}, {
    "City": "Berbera",
    "DBTZ": "U",
    "Name": "Berbera",
    "Country": "Somalia",
    "IATA/FAA": "BBO",
    "Longitude": "44.941106",
    "ICAO": "HCMI",
    "Airport ID": "1122",
    "Latitude": "10.389167",
    "Timezone": "30",
    "DST": "3",
    "destinations": ["5686", "4119", "3977", "2188", "5687", "2191"]
}, {
    "City": "Abu Simbel",
    "DBTZ": "U",
    "Name": "Abu Simbel",
    "Country": "Egypt",
    "IATA/FAA": "ABS",
    "Longitude": "31.611722",
    "ICAO": "HEBL",
    "Airport ID": "1127",
    "Latitude": "22.375953",
    "Timezone": "616",
    "DST": "2",
    "destinations": ["1136"]
}, {
    "City": "Syktyvkar",
    "DBTZ": "N",
    "Name": "Syktyvkar",
    "Country": "Russia",
    "IATA/FAA": "SCW",
    "Longitude": "50.84505",
    "ICAO": "UUYY",
    "Airport ID": "2989",
    "Latitude": "61.64705",
    "Timezone": "342",
    "DST": "4",
    "destinations": ["2948", "2985", "2975", "4371", "4368", "6159"]
}, {
    "City": "Moscow",
    "DBTZ": "N",
    "Name": "Vnukovo",
    "Country": "Russia",
    "IATA/FAA": "VKO",
    "Longitude": "37.261486",
    "ICAO": "UUWW",
    "Airport ID": "2988",
    "Latitude": "55.591531",
    "Timezone": "685",
    "DST": "4",
    "destinations": ["8428", "6151", "4364", "6143", "6140", "4368", "3972", "3973", "3953", "6111", "345", "340", "351", "6103", "1613", "3964", "2944", "2945", "2936", "2960", "2931", "2923", "2948", "1688", "1682", "1701", "2965", "1230", "2908", "2914", "1852", "1715", "8076", "1555", "4357", "1452", "3179", "1130", "2937", "3797", "4374", "6088", "4118", "3484", "507", "1229", "3576", "3156", "893", "2962", "1524", "4352", "2947", "1386", "4078", "3364", "1198", "4367", "994", "4057", "2975", "2983", "1590", "2910", "1551", "193", "4274", "6142", "6466", "6157", "3959", "2987", "1489"]
}, {
    "City": "Novosibirsk",
    "DBTZ": "N",
    "Name": "Tolmachevo",
    "Country": "Russia",
    "IATA/FAA": "OVB",
    "Longitude": "82.650656",
    "ICAO": "UNNT",
    "Airport ID": "4078",
    "Latitude": "55.012622",
    "Timezone": "365",
    "DST": "7",
    "destinations": ["2968", "2975", "2936", "2937", "2960", "2948", "2925", "6092", "2979", "6147", "3399", "3364", "6150", "2983", "4374", "2972", "6954", "2958", "6137", "2922", "2910", "2912", "2913", "2939", "2908", "3885", "2914", "4029", "2188", "3964", "6149", "340", "3077", "2935", "2927", "346", "4352", "1587", "2938", "2934", "2923", "2985", "1701", "3400", "3179", "1130", "2988", "6138", "4364", "2974", "4111"]
}, {
    "City": "Tashkent",
    "DBTZ": "U",
    "Name": "Yuzhny",
    "Country": "Uzbekistan",
    "IATA/FAA": "TAS",
    "Longitude": "69.281186",
    "ICAO": "UTTT",
    "Airport ID": "2983",
    "Latitude": "41.257861",
    "Timezone": "1417",
    "DST": "5",
    "destinations": ["2985", "3953", "3364", "3399", "2965", "2908", "3081", "6148", "2980", "3885", "1382", "3093", "4029", "2188", "1555", "6149", "340", "2912", "2922", "3930", "1701", "2939", "2952", "4374", "2960", "6153", "4118", "3304", "2990", "2948", "507", "2962", "1524", "6151", "6150", "2279", "6485", "4078", "1587", "2964", "2191", "3316", "2981", "2975", "4111", "1590", "6154", "2910", "2992", "6152", "2967", "2958", "4330", "2937", "2968", "4274", "2988"]
}, {
    "City": "Samarkand",
    "DBTZ": "U",
    "Name": "Samarkand",
    "Country": "Uzbekistan",
    "IATA/FAA": "SKD",
    "Longitude": "66.983829",
    "ICAO": "UTSS",
    "Airport ID": "2981",
    "Latitude": "39.700547",
    "Timezone": "2224",
    "DST": "5",
    "destinations": ["4029", "2990", "2948", "2983", "4374", "2975"]
}, {
    "City": "Colombo",
    "DBTZ": "U",
    "Name": "Bandaranaike Intl Colombo",
    "Country": "Sri Lanka",
    "IATA/FAA": "CMB",
    "Longitude": "79.884117",
    "ICAO": "VCBI",
    "Airport ID": "3024",
    "Latitude": "7.180756",
    "Timezone": "30",
    "DST": "5.5",
    "destinations": ["2997", "2179", "3093", "3144", "1555", "3156", "3885", "3316", "3304", "2188", "8076", "2191", "3930", "2176", "2206", "2057", "3131", "3275", "3136", "3076", "2064", "8949", "3142", "2072", "2194", "2082", "994", "3153", "3152", "3382", "6183", "2241", "1382", "4029", "340", "9229", "6169", "507", "2279", "3364", "3406"]
}, {
    "City": "Udaipur",
    "DBTZ": "N",
    "Name": "Udaipur",
    "Country": "India",
    "IATA/FAA": "UDR",
    "Longitude": "73.8961",
    "ICAO": "VAUD",
    "Airport ID": "3023",
    "Latitude": "24.617697",
    "Timezone": "1684",
    "DST": "5.5",
    "destinations": ["2997", "3093"]
}, {
    "City": "Surat",
    "DBTZ": "N",
    "Name": "Surat",
    "Country": "India",
    "IATA/FAA": "STV",
    "Longitude": "72.741792",
    "ICAO": "VASU",
    "Airport ID": "3022",
    "Latitude": "21.114061",
    "Timezone": "16",
    "DST": "5.5",
    "destinations": ["3093", "2997"]
}, {
    "City": "Moscow",
    "DBTZ": "N",
    "Name": "Sheremetyevo",
    "Country": "Russia",
    "IATA/FAA": "SVO",
    "Longitude": "37.414589",
    "ICAO": "UUEE",
    "Airport ID": "2985",
    "Latitude": "55.972642",
    "Timezone": "622",
    "DST": "4",
    "destinations": ["4362", "2949", "2989", "1688", "1382", "1353", "210", "1509", "1512", "1562", "1074", "421", "1538", "1555", "2912", "1524", "2983", "1551", "3953", "3364", "1909", "1197", "3370", "3399", "3714", "3797", "3484", "3576", "1191", "1194", "1196", "2922", "4330", "1569", "1739", "2908", "2910", "3930", "580", "1606", "2188", "679", "2177", "3406", "1579", "1587", "351", "3380", "415", "1657", "737", "609", "644", "4353", "2955", "2965", "1230", "2966", "3941", "2956", "1218", "3885", "302", "1489", "1128", "2968", "3093", "2941", "2940", "338", "345", "3964", "340", "7041", "4274", "1665", "352", "342", "3199", "1452", "3077", "6105", "2935", "2937", "1701", "2939", "2957", "2952", "4375", "2927", "4374", "669", "2960", "4118", "2990", "2948", "507", "1229", "3156", "2969", "2962", "2954", "346", "6969", "1354", "2972", "6119", "2279", "4364", "2947", "2958", "4078", "2973", "2932", "1760", "2991", "2964", "2974", "3205", "2942", "1486", "350", "2963", "2975", "337", "1056", "4111", "1590", "4297", "2992", "2938", "2933", "1613", "3959", "2967", "2934", "2923", "193", "1208", "1678", "1130", "7447", "1472", "4057"]
}, {
    "City": "Raipur",
    "DBTZ": "N",
    "Name": "Raipur",
    "Country": "India",
    "IATA/FAA": "RPR",
    "Longitude": "81.738753",
    "ICAO": "VARP",
    "Airport ID": "3020",
    "Latitude": "21.180406",
    "Timezone": "1041",
    "DST": "5.5",
    "destinations": ["3043", "3093", "3141", "3008", "3002", "2997", "3066"]
}, {
    "City": "Maseru",
    "DBTZ": "U",
    "Name": "Moshoeshoe I Intl",
    "Country": "Lesotho",
    "IATA/FAA": "MSU",
    "Longitude": "27.552503",
    "ICAO": "FXMM",
    "Airport ID": "1018",
    "Latitude": "-29.462256",
    "Timezone": "5348",
    "DST": "2",
    "destinations": ["813"]
}, {
    "City": "Newcastle",
    "DBTZ": "E",
    "Name": "Newcastle",
    "Country": "United Kingdom",
    "IATA/FAA": "NCL",
    "Longitude": "-1.691667",
    "ICAO": "EGNT",
    "Airport ID": "521",
    "Latitude": "55.0375",
    "Timezone": "266",
    "DST": "0",
    "destinations": ["507", "1382", "467", "495", "302", "599", "596", "2188", "1222", "580", "1606", "345", "1055", "1230", "1212", "4315", "1200", "1715", "1626", "1555", "1452", "1225", "669", "1054", "1231", "1227", "1198", "3998", "1587", "1562", "1236", "1472", "1056", "1551", "609", "666", "532", "469", "488", "1051", "7447", "4057", "1218", "465", "490", "499", "502", "1354", "520"]
}, {
    "City": "Teesside",
    "DBTZ": "E",
    "Name": "Durham Tees Valley Airport",
    "Country": "United Kingdom",
    "IATA/FAA": "MME",
    "Longitude": "-1.429406",
    "ICAO": "EGNV",
    "Airport ID": "522",
    "Latitude": "54.509189",
    "Timezone": "120",
    "DST": "0",
    "destinations": ["580", "532"]
}, {
    "City": "East Midlands",
    "DBTZ": "E",
    "Name": "Nottingham East Midlands",
    "Country": "United Kingdom",
    "IATA/FAA": "EMA",
    "Longitude": "-1.328056",
    "ICAO": "EGNX",
    "Airport ID": "523",
    "Latitude": "52.831111",
    "Timezone": "306",
    "DST": "0",
    "destinations": ["580", "467", "535", "534", "499", "302", "1055", "1230", "1212", "1218", "1525", "1352", "1460", "1474", "1553", "1405", "599", "1265", "1626", "1051", "1222", "1225", "669", "1213", "1270", "1054", "6493", "1231", "1227", "600", "596", "3998", "1562", "1236", "1472", "3953", "675", "337", "1056", "1539", "1246", "8414", "680", "498", "4315", "1489", "1382", "1200", "1715", "4091", "1452", "1198", "1587", "532", "7447", "1197", "1606"]
}, {
    "City": "Lilongwe",
    "DBTZ": "U",
    "Name": "Kamuzu Intl",
    "Country": "Malawi",
    "IATA/FAA": "LLW",
    "Longitude": "33.781",
    "ICAO": "FWKI",
    "Airport ID": "1016",
    "Latitude": "-13.789378",
    "Timezone": "4035",
    "DST": "2",
    "destinations": ["907", "4059", "1013", "1177", "1005", "813"]
}, {
    "City": "Pomalaa",
    "DBTZ": "N",
    "Name": "Pomalaa",
    "Country": "Indonesia",
    "IATA/FAA": "PUM",
    "Longitude": "121.61667",
    "ICAO": "",
    "Airport ID": "8735",
    "Latitude": "-4.183333",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3240"]
}, {
    "City": "Kirkwall",
    "DBTZ": "E",
    "Name": "Kirkwall",
    "Country": "United Kingdom",
    "IATA/FAA": "KOI",
    "Longitude": "-2.905",
    "ICAO": "EGPA",
    "Airport ID": "529",
    "Latitude": "58.957778",
    "Timezone": "50",
    "DST": "0",
    "destinations": ["532", "535", "534", "533", "530", "5564", "5569", "5566", "5567", "5568", "5571"]
}, {
    "City": "Blantyre",
    "DBTZ": "U",
    "Name": "Chileka Intl",
    "Country": "Malawi",
    "IATA/FAA": "BLZ",
    "Longitude": "34.974014",
    "ICAO": "FWCL",
    "Airport ID": "1013",
    "Latitude": "-15.679053",
    "Timezone": "2555",
    "DST": "2",
    "destinations": ["1107", "813", "1016", "4059"]
}, {
    "City": "Pamplona",
    "DBTZ": "E",
    "Name": "Pamplona",
    "Country": "Spain",
    "IATA/FAA": "PNA",
    "Longitude": "-1.646331",
    "ICAO": "LEPP",
    "Airport ID": "1234",
    "Latitude": "42.770039",
    "Timezone": "1504",
    "DST": "1",
    "destinations": ["1229"]
}, {
    "City": "Reus",
    "DBTZ": "E",
    "Name": "Reus",
    "Country": "Spain",
    "IATA/FAA": "REU",
    "Longitude": "1.167172",
    "ICAO": "LERS",
    "Airport ID": "1236",
    "Latitude": "41.147392",
    "Timezone": "234",
    "DST": "1",
    "destinations": ["469", "490", "304", "599", "585", "523", "355", "491", "492", "478", "537", "3998", "548", "302", "465", "521"]
}, {
    "City": "Zhongwei",
    "DBTZ": "N",
    "Name": "Zhongwei Xiangshan Airport",
    "Country": "China",
    "IATA/FAA": "ZHY",
    "Longitude": "105.1544",
    "ICAO": "ZLZW",
    "Airport ID": "8043",
    "Latitude": "37.5728",
    "Timezone": "4305",
    "DST": "8",
    "destinations": ["3364", "6366"]
}, {
    "City": "Malaga",
    "DBTZ": "E",
    "Name": "Malaga",
    "Country": "Spain",
    "IATA/FAA": "AGP",
    "Longitude": "-4.499106",
    "ICAO": "LEMG",
    "Airport ID": "1230",
    "Latitude": "36.6749",
    "Timezone": "52",
    "DST": "1",
    "destinations": ["350", "351", "507", "344", "345", "342", "346", "3998", "1613", "1382", "1074", "421", "1555", "503", "502", "552", "495", "628", "737", "636", "609", "687", "644", "657", "666", "665", "664", "679", "467", "599", "596", "603", "1194", "607", "1218", "1525", "469", "608", "1538", "494", "353", "490", "302", "1745", "1367", "1553", "304", "373", "535", "585", "523", "4166", "3986", "341", "691", "645", "355", "1225", "669", "517", "491", "478", "708", "1353", "600", "4198", "347", "699", "537", "1243", "1251", "548", "458", "1539", "733", "680", "1399", "580", "591", "1216", "488", "4029", "352", "1054", "1335", "1229", "1058", "1418", "1386", "1214", "1057", "1273", "1246", "1278", "629", "340", "465", "514", "534", "521", "1665", "1678", "1587", "1638", "2985", "1701", "492", "146", "4053", "1524", "508", "337", "2988", "1264", "1489"]
}, {
    "City": "Menorca",
    "DBTZ": "E",
    "Name": "Menorca",
    "Country": "Spain",
    "IATA/FAA": "MAH",
    "Longitude": "4.218647",
    "ICAO": "LEMH",
    "Airport ID": "1231",
    "Latitude": "39.862597",
    "Timezone": "298",
    "DST": "1",
    "destinations": ["344", "345", "346", "3998", "351", "503", "1218", "523", "1246", "302", "1222", "1229", "535", "517", "478", "521", "502", "340", "352", "342", "350", "469", "492"]
}, {
    "City": "Weeze",
    "DBTZ": "E",
    "Name": "Niederrhein",
    "Country": "Germany",
    "IATA/FAA": "NRN",
    "Longitude": "6.141944",
    "ICAO": "EDLV",
    "Airport ID": "4198",
    "Latitude": "51.602222",
    "Timezone": "106",
    "DST": "1",
    "destinations": ["1055", "1230", "1517", "1212", "4309", "1525", "1538", "1501", "1360", "1519", "1460", "1474", "1553", "535", "1626", "1066", "1051", "1222", "1225", "1213", "1054", "441", "1636", "1070", "3998", "1512", "1562", "1505", "1075", "3953", "1251", "1486", "548", "1508", "1056", "1539", "1246", "1209"]
}, {
    "City": "Busuanga",
    "DBTZ": "N",
    "Name": "Busuanga",
    "Country": "Philippines",
    "IATA/FAA": "USU",
    "Longitude": "120.100031",
    "ICAO": "RPVV",
    "Airport ID": "4199",
    "Latitude": "12.121458",
    "Timezone": "148",
    "DST": "8",
    "destinations": ["4206", "2397"]
}, {
    "City": "Kastelorizo",
    "DBTZ": "E",
    "Name": "Kastelorizo",
    "Country": "Greece",
    "IATA/FAA": "KZS",
    "Longitude": "29.566656",
    "ICAO": "LGKJ",
    "Airport ID": "4196",
    "Latitude": "36.127777",
    "Timezone": "474",
    "DST": "2",
    "destinations": ["1472"]
}, {
    "City": "Marsa Alam",
    "DBTZ": "U",
    "Name": "Marsa Alam Intl",
    "Country": "Egypt",
    "IATA/FAA": "RMF",
    "Longitude": "34.583711",
    "ICAO": "HEMA",
    "Airport ID": "4197",
    "Latitude": "25.557111",
    "Timezone": "251",
    "DST": "2",
    "destinations": ["1678", "345", "1613", "1128"]
}, {
    "City": "Cyclades Islands",
    "DBTZ": "E",
    "Name": "Naxos",
    "Country": "Greece",
    "IATA/FAA": "JNX",
    "Longitude": "25.368056",
    "ICAO": "LGNX",
    "Airport ID": "4194",
    "Latitude": "37.080556",
    "Timezone": "10",
    "DST": "2",
    "destinations": ["3941"]
}, {
    "City": "Paros",
    "DBTZ": "E",
    "Name": "Paros",
    "Country": "Greece",
    "IATA/FAA": "PAS",
    "Longitude": "25.127778",
    "ICAO": "LGPA",
    "Airport ID": "4195",
    "Latitude": "37.010278",
    "Timezone": "121",
    "DST": "2",
    "destinations": ["3941"]
}, {
    "City": "Kalymnos",
    "DBTZ": "E",
    "Name": "Kalymnos Island",
    "Country": "Greece",
    "IATA/FAA": "JKL",
    "Longitude": "26.940556",
    "ICAO": "LGKY",
    "Airport ID": "4192",
    "Latitude": "36.963333",
    "Timezone": "771",
    "DST": "2",
    "destinations": ["3941", "1458", "1464"]
}, {
    "City": "Milos",
    "DBTZ": "E",
    "Name": "Milos",
    "Country": "Greece",
    "IATA/FAA": "MLO",
    "Longitude": "24.4775",
    "ICAO": "LGML",
    "Airport ID": "4193",
    "Latitude": "36.696111",
    "Timezone": "12",
    "DST": "2",
    "destinations": ["3941"]
}, {
    "City": "Astypalaia",
    "DBTZ": "E",
    "Name": "Astypalaia",
    "Country": "Greece",
    "IATA/FAA": "JTY",
    "Longitude": "26.375822",
    "ICAO": "LGPL",
    "Airport ID": "4190",
    "Latitude": "36.579886",
    "Timezone": "165",
    "DST": "2",
    "destinations": ["3941", "1464"]
}, {
    "City": "Ikaria",
    "DBTZ": "E",
    "Name": "Ikaria",
    "Country": "Greece",
    "IATA/FAA": "JIK",
    "Longitude": "26.347061",
    "ICAO": "LGIK",
    "Airport ID": "4191",
    "Latitude": "37.682717",
    "Timezone": "79",
    "DST": "2",
    "destinations": ["3941", "1465"]
}, {
    "City": "Dakhla",
    "DBTZ": "N",
    "Name": "Dakhla Airport",
    "Country": "Western Sahara",
    "IATA/FAA": "VIL",
    "Longitude": "-15.932",
    "ICAO": "GMMH",
    "Airport ID": "5670",
    "Latitude": "23.7183",
    "Timezone": "36",
    "DST": "0",
    "destinations": ["1064", "1074", "1054"]
}, {
    "City": "Essadouira",
    "DBTZ": "N",
    "Name": "Mogador Airport",
    "Country": "Morocco",
    "IATA/FAA": "ESU",
    "Longitude": "-9.681667",
    "ICAO": "GMMI",
    "Airport ID": "5671",
    "Latitude": "31.3975",
    "Timezone": "384",
    "DST": "0",
    "destinations": ["1386", "1353"]
}, {
    "City": "El Aaiun",
    "DBTZ": "N",
    "Name": "Hassan I Airport",
    "Country": "Western Sahara",
    "IATA/FAA": "EUN",
    "Longitude": "-13.2192",
    "ICAO": "GMML",
    "Airport ID": "5672",
    "Latitude": "27.1517",
    "Timezone": "207",
    "DST": "0",
    "destinations": ["1064", "1074", "1054"]
}, {
    "City": "El Aroui",
    "DBTZ": "N",
    "Name": "El Aroui Airport",
    "Country": "Morocco",
    "IATA/FAA": "NDR",
    "Longitude": "-3.02821",
    "ICAO": "GMMW",
    "Airport ID": "5673",
    "Latitude": "34.9888",
    "Timezone": "574",
    "DST": "0",
    "destinations": ["580", "1218", "302", "344", "1359", "3998", "1074", "340", "1367", "304", "355", "1353"]
}, {
    "City": "Praia",
    "DBTZ": "-1",
    "Name": "Praia International Airport",
    "Country": " Santiago Island",
    "IATA/FAA": "Cape Verde",
    "Longitude": "14.9245",
    "ICAO": "RAI",
    "Airport ID": "5674",
    "Latitude": "GVNP",
    "Timezone": "-23.4935",
    "DST": "230",
    "destinations": ["1050", "1074", "5665", "1084", "973", "1054", "1638", "3448", "1103", "1382", "2559", "1104", "5675", "1102", "1106"]
}, {
    "City": "Sao Filipe",
    "DBTZ": "-1",
    "Name": "Sao Filipe Airport",
    "Country": " Fogo Island",
    "IATA/FAA": "Cape Verde",
    "Longitude": "14.885",
    "ICAO": "SFL",
    "Airport ID": "5675",
    "Latitude": "GVSF",
    "Timezone": "-24.48",
    "DST": "617",
    "destinations": ["5674"]
}, {
    "City": "Joplin",
    "DBTZ": "A",
    "Name": "Joplin Rgnl",
    "Country": "United States",
    "IATA/FAA": "JLN",
    "Longitude": "-94.498269",
    "ICAO": "KJLN",
    "Airport ID": "4354",
    "Latitude": "37.151814",
    "Timezone": "981",
    "DST": "-6",
    "destinations": ["3670"]
}, {
    "City": "Allentown",
    "DBTZ": "A",
    "Name": "Lehigh Valley Intl",
    "Country": "United States",
    "IATA/FAA": "ABE",
    "Longitude": "-75.440806",
    "ICAO": "KABE",
    "Airport ID": "4355",
    "Latitude": "40.652083",
    "Timezone": "393",
    "DST": "-5",
    "destinations": ["3876", "3752", "3682", "3645", "3515", "7056", "3617", "4167", "3830"]
}, {
    "City": "Bentonville",
    "DBTZ": "A",
    "Name": "NW Arkansas Regional",
    "Country": "United States",
    "IATA/FAA": "XNA",
    "Longitude": "-94.3068111",
    "ICAO": "KXNA",
    "Airport ID": "4356",
    "Latitude": "36.2818694",
    "Timezone": "1287",
    "DST": "-6",
    "destinations": ["3682", "3876", "3670", "3484", "3697", "3830", "3488", "3645", "3858", "3877", "4167", "3751", "3494", "3550"]
}, {
    "City": "Atyrau",
    "DBTZ": "U",
    "Name": "Atyrau",
    "Country": "Kazakhstan",
    "IATA/FAA": "GUW",
    "Longitude": "51.821389",
    "ICAO": "UATG",
    "Airport ID": "4357",
    "Latitude": "47.121944",
    "Timezone": "0",
    "DST": "5",
    "destinations": ["4367", "2910", "2908", "580", "1701", "2988", "2916"]
}, {
    "City": "Berlevag",
    "DBTZ": "E",
    "Name": "Berlevag",
    "Country": "Norway",
    "IATA/FAA": "BVG",
    "Longitude": "29",
    "ICAO": "ENBV",
    "Airport ID": "4350",
    "Latitude": "70.866667",
    "Timezone": "43",
    "DST": "1",
    "destinations": ["637", "4325", "4327", "4328"]
}, {
    "City": "Norilsk",
    "DBTZ": "N",
    "Name": "Alykel",
    "Country": "Russia",
    "IATA/FAA": "NSK",
    "Longitude": "87.332183",
    "ICAO": "UOOO",
    "Airport ID": "4352",
    "Latitude": "69.311053",
    "Timezone": "595",
    "DST": "8",
    "destinations": ["4029", "4078", "2975", "2988", "2955", "2965", "4274", "4374", "4118", "2948", "2992"]
}, {
    "City": "Anapa",
    "DBTZ": "N",
    "Name": "Vityazevo",
    "Country": "Russia",
    "IATA/FAA": "AAQ",
    "Longitude": "37.347272",
    "ICAO": "URKA",
    "Airport ID": "4353",
    "Latitude": "45.002097",
    "Timezone": "174",
    "DST": "4",
    "destinations": ["4029", "2948", "2985"]
}, {
    "City": "Odate Noshiro",
    "DBTZ": "U",
    "Name": "Odate Noshiro Airport",
    "Country": "Japan",
    "IATA/FAA": "ONJ",
    "Longitude": "140.371",
    "ICAO": "RJSR",
    "Airport ID": "6000",
    "Latitude": "40.1919",
    "Timezone": "292",
    "DST": "9",
    "destinations": ["2359"]
}, {
    "City": "Birdsville",
    "DBTZ": "O",
    "Name": "Birdsville Airport",
    "Country": "Australia",
    "IATA/FAA": "BVI",
    "Longitude": "139.348",
    "ICAO": "YBDV",
    "Airport ID": "6240",
    "Latitude": "-25.8975",
    "Timezone": "159",
    "DST": "10",
    "destinations": ["6243", "6333"]
}, {
    "City": "Mesa",
    "DBTZ": "N",
    "Name": "Phoenix-Mesa Gateway",
    "Country": "United States",
    "IATA/FAA": "AZA",
    "Longitude": "-111.655",
    "ICAO": "KIWA",
    "Airport ID": "6505",
    "Latitude": "33.307833",
    "Timezone": "1382",
    "DST": "-7",
    "destinations": ["4047", "4021", "4083", "3777", "4020", "4043", "3598", "4099", "4292", "4009", "4041", "3442", "5740", "3685", "3880", "3457", "4100", "3877", "4072", "3498", "4216", "3453", "4046", "6133", "7579", "4087", "4028", "4048", "4359", "4348", "4271"]
}, {
    "City": "Kittila",
    "DBTZ": "E",
    "Name": "Kittila",
    "Country": "Finland",
    "IATA/FAA": "KTT",
    "Longitude": "24.84685",
    "ICAO": "EFKT",
    "Airport ID": "438",
    "Latitude": "67.701022",
    "Timezone": "644",
    "DST": "2",
    "destinations": ["421"]
}, {
    "City": "Kuopio",
    "DBTZ": "E",
    "Name": "Kuopio",
    "Country": "Finland",
    "IATA/FAA": "KUO",
    "Longitude": "27.797756",
    "ICAO": "EFKU",
    "Airport ID": "439",
    "Latitude": "63.00715",
    "Timezone": "323",
    "DST": "2",
    "destinations": ["421"]
}, {
    "City": "Kuusamo",
    "DBTZ": "E",
    "Name": "Kuusamo",
    "Country": "Finland",
    "IATA/FAA": "KAO",
    "Longitude": "29.239381",
    "ICAO": "EFKS",
    "Airport ID": "437",
    "Latitude": "65.987575",
    "Timezone": "866",
    "DST": "2",
    "destinations": ["421"]
}, {
    "City": "Kruunupyy",
    "DBTZ": "E",
    "Name": "Kruunupyy",
    "Country": "Finland",
    "IATA/FAA": "KOK",
    "Longitude": "23.143131",
    "ICAO": "EFKK",
    "Airport ID": "435",
    "Latitude": "63.721172",
    "Timezone": "84",
    "DST": "2",
    "destinations": ["448", "421"]
}, {
    "City": "Kemi",
    "DBTZ": "E",
    "Name": "Kemi Tornio",
    "Country": "Finland",
    "IATA/FAA": "KEM",
    "Longitude": "24.5991",
    "ICAO": "EFKE",
    "Airport ID": "432",
    "Latitude": "65.781889",
    "Timezone": "61",
    "DST": "2",
    "destinations": ["421"]
}, {
    "City": "Kajaani",
    "DBTZ": "E",
    "Name": "Kajaani",
    "Country": "Finland",
    "IATA/FAA": "KAJ",
    "Longitude": "27.692414",
    "ICAO": "EFKI",
    "Airport ID": "433",
    "Latitude": "64.285472",
    "Timezone": "483",
    "DST": "2",
    "destinations": ["421"]
}, {
    "City": "Jyvaskyla",
    "DBTZ": "E",
    "Name": "Jyvaskyla",
    "Country": "Finland",
    "IATA/FAA": "JYV",
    "Longitude": "25.678253",
    "ICAO": "EFJY",
    "Airport ID": "430",
    "Latitude": "62.399453",
    "Timezone": "459",
    "DST": "2",
    "destinations": ["421", "432"]
}, {
    "City": "Broken Hill",
    "DBTZ": "O",
    "Name": "Broken Hill Airport",
    "Country": "Australia",
    "IATA/FAA": "BHQ",
    "Longitude": "141.472",
    "ICAO": "YBHI",
    "Airport ID": "6241",
    "Latitude": "-32.0014",
    "Timezone": "958",
    "DST": "9.5",
    "destinations": ["3341", "3358", "6298", "3361"]
}, {
    "City": "Stockton",
    "DBTZ": "A",
    "Name": "Stockton Metropolitan",
    "Country": "United States",
    "IATA/FAA": "SCK",
    "Longitude": "-121.238306",
    "ICAO": "KSCK",
    "Airport ID": "3805",
    "Latitude": "37.894167",
    "Timezone": "33",
    "DST": "-8",
    "destinations": ["3877"]
}, {
    "City": "Cheyenne",
    "DBTZ": "A",
    "Name": "Cheyenne Rgnl Jerry Olson Fld",
    "Country": "United States",
    "IATA/FAA": "CYS",
    "Longitude": "-104.811839",
    "ICAO": "KCYS",
    "Airport ID": "3804",
    "Latitude": "41.155722",
    "Timezone": "6156",
    "DST": "-7",
    "destinations": ["3751", "5777"]
}, {
    "City": "Reno",
    "DBTZ": "A",
    "Name": "Reno Tahoe Intl",
    "Country": "United States",
    "IATA/FAA": "RNO",
    "Longitude": "-119.768108",
    "ICAO": "KRNO",
    "Airport ID": "3807",
    "Latitude": "39.499108",
    "Timezone": "4415",
    "DST": "-8",
    "destinations": ["3670", "3484", "3830", "3462", "3720", "3577", "3748", "3536", "3751", "3747", "3877", "3469", "3731"]
}, {
    "City": "Charleston",
    "DBTZ": "A",
    "Name": "Charleston Afb Intl",
    "Country": "United States",
    "IATA/FAA": "CHS",
    "Longitude": "-80.040528",
    "ICAO": "KCHS",
    "Airport ID": "3806",
    "Latitude": "32.898647",
    "Timezone": "45",
    "DST": "-5",
    "destinations": ["3876", "3520", "3670", "3576", "3752", "3682", "3448", "3797", "3645", "3697", "3849", "3747", "3646", "3486", "3494", "3714", "3550", "3830", "3690", "3566"]
}, {
    "City": "Panzhihua",
    "DBTZ": "N",
    "Name": "Panzhihua",
    "Country": "China",
    "IATA/FAA": "PZI",
    "Longitude": "101.799",
    "ICAO": "ZUZH",
    "Airport ID": "6435",
    "Latitude": "26.54",
    "Timezone": "9186",
    "DST": "8",
    "destinations": ["3393", "3395"]
}, {
    "City": "Dongsheng",
    "DBTZ": "N",
    "Name": "Ordos Ejin Horo",
    "Country": "China",
    "IATA/FAA": "DSN",
    "Longitude": "110.033",
    "ICAO": "ZBDS",
    "Airport ID": "6434",
    "Latitude": "39.85",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3395", "4144", "3364", "3369", "3375", "3379", "3393", "3371", "6345", "3386", "4085", "3388", "6347", "3368", "3399", "3376", "6341", "3400", "3382"]
}, {
    "City": "Dandong",
    "DBTZ": "N",
    "Name": "Dandong",
    "Country": "China",
    "IATA/FAA": "DDG",
    "Longitude": "124.2866",
    "ICAO": "ZYDD",
    "Airport ID": "6433",
    "Latitude": "40.0255",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3364", "3406", "3390"]
}, {
    "City": "Arua",
    "DBTZ": "U",
    "Name": "Arua Airport",
    "Country": "Uganda",
    "IATA/FAA": "RUA",
    "Longitude": "30.917",
    "ICAO": "HUAR",
    "Airport ID": "5709",
    "Latitude": "3.05",
    "Timezone": "3951",
    "DST": "3",
    "destinations": ["1187"]
}, {
    "City": "Lanzhou",
    "DBTZ": "N",
    "Name": "Lanzhou Airport",
    "Country": "China",
    "IATA/FAA": "LHW",
    "Longitude": "103.617",
    "ICAO": "ZLAN",
    "Airport ID": "6431",
    "Latitude": "36.117",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3393", "3395", "4308", "6432", "3394", "3368", "3382", "3399", "3406", "3375", "3386", "3364", "4108", "3369", "3379", "6430", "3370", "3371", "4144", "3376", "3391", "4085", "6365", "3372", "7987", "3389", "3374", "2276", "3373", "3388", "6347", "4097"]
}, {
    "City": "Yuncheng",
    "DBTZ": "N",
    "Name": "Zhangxiao",
    "Country": "China",
    "IATA/FAA": "YCU",
    "Longitude": "110.993",
    "ICAO": "ZBYC",
    "Airport ID": "6430",
    "Latitude": "35.018",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3370", "3395", "3386", "3382", "6431", "3364", "3374", "3390", "3368", "3399", "3393", "3371", "3400", "4030", "3406", "3388", "3369", "3376"]
}, {
    "City": "Nightmute",
    "DBTZ": "A",
    "Name": "Nightmute Airport",
    "Country": "United States",
    "IATA/FAA": "NME",
    "Longitude": "-164.700833",
    "ICAO": "PAGT",
    "Airport ID": "8199",
    "Latitude": "60.471111",
    "Timezone": "4",
    "DST": "-9",
    "destinations": ["8200", "8201"]
}, {
    "City": "Nyala",
    "DBTZ": "U",
    "Name": "Nyala Airport",
    "Country": "Sudan",
    "IATA/FAA": "UYL",
    "Longitude": "24.9562",
    "ICAO": "HSNN",
    "Airport ID": "5701",
    "Latitude": "12.0535",
    "Timezone": "2106",
    "DST": "3",
    "destinations": ["1175"]
}, {
    "City": "Port Sudan",
    "DBTZ": "U",
    "Name": "Port Sudan New International Airport",
    "Country": "Sudan",
    "IATA/FAA": "PZU",
    "Longitude": "37.2341",
    "ICAO": "HSPN",
    "Airport ID": "5702",
    "Latitude": "19.4336",
    "Timezone": "135",
    "DST": "3",
    "destinations": ["1175", "1128", "2072"]
}, {
    "City": "Bukoba",
    "DBTZ": "U",
    "Name": "Bukoba Airport",
    "Country": "Tanzania",
    "IATA/FAA": "BKZ",
    "Longitude": "31.8",
    "ICAO": "HTBU",
    "Airport ID": "5703",
    "Latitude": "-1.3",
    "Timezone": "3745",
    "DST": "3",
    "destinations": ["1183"]
}, {
    "City": "Ulaangom",
    "DBTZ": "U",
    "Name": "Ulaangom Airport",
    "Country": "Mongolia",
    "IATA/FAA": "ULO",
    "Longitude": "92.079722",
    "ICAO": "ZMUG",
    "Airport ID": "7558",
    "Latitude": "49.973333",
    "Timezone": "3500",
    "DST": "7",
    "destinations": ["3380"]
}, {
    "City": "Djibouti",
    "DBTZ": "U",
    "Name": "Ambouli International Airport",
    "Country": "Djibouti",
    "IATA/FAA": "JIB",
    "Longitude": "43.1594",
    "ICAO": "HDAM",
    "Airport ID": "4119",
    "Latitude": "11.5472",
    "Timezone": "49",
    "DST": "3",
    "destinations": ["2188", "2072", "5687", "1382", "1107", "1112", "3980", "3975", "912", "1121", "4059", "1701"]
}, {
    "City": "Edmonton",
    "DBTZ": "A",
    "Name": "Edmonton Intl",
    "Country": "Canada",
    "IATA/FAA": "YEG",
    "Longitude": "-113.579722",
    "ICAO": "CYEG",
    "Airport ID": "49",
    "Latitude": "53.309723",
    "Timezone": "2373",
    "DST": "-7",
    "destinations": ["176", "196", "99", "7266", "168", "178", "3670", "3877", "3484", "3462", "3751", "3494", "3550", "507", "3830", "3469", "90", "100", "120", "122", "146", "156", "160", "166", "193", "3577", "3858", "16", "72", "136", "1852", "3839", "1836", "70", "87", "119", "175", "184"]
}, {
    "City": "Lampang",
    "DBTZ": "U",
    "Name": "Lampang",
    "Country": "Thailand",
    "IATA/FAA": "LPT",
    "Longitude": "99.504167",
    "ICAO": "VTCL",
    "Airport ID": "3163",
    "Latitude": "18.270933",
    "Timezone": "811",
    "DST": "7",
    "destinations": ["3885"]
}, {
    "City": "Walla Walla",
    "DBTZ": "A",
    "Name": "Walla Walla Regional Airport",
    "Country": "United States",
    "IATA/FAA": "ALW",
    "Longitude": "-118.288",
    "ICAO": "KALW",
    "Airport ID": "5719",
    "Latitude": "46.0949",
    "Timezone": "1191",
    "DST": "-8",
    "destinations": ["3577"]
}, {
    "City": "Nauru",
    "DBTZ": "U",
    "Name": "Nauru Intl",
    "Country": "Nauru",
    "IATA/FAA": "INU",
    "Longitude": "166.9191",
    "ICAO": "ANYN",
    "Airport ID": "4076",
    "Latitude": "-0.547458",
    "Timezone": "22",
    "DST": "12",
    "destinations": ["3320", "2249", "1965"]
}, {
    "City": "Sege",
    "DBTZ": "U",
    "Name": "Sege Airport",
    "Country": "Solomon Islands",
    "IATA/FAA": "EGM",
    "Longitude": "157.876",
    "ICAO": "AGGS",
    "Airport ID": "6758",
    "Latitude": "-8.57889",
    "Timezone": "0",
    "DST": "11",
    "destinations": ["5412", "4074", "5418", "5416"]
}, {
    "City": "Tacheng",
    "DBTZ": "U",
    "Name": "Tacheng Airport",
    "Country": "China",
    "IATA/FAA": "TCG",
    "Longitude": "83.3408",
    "ICAO": "ZWTC",
    "Airport ID": "6752",
    "Latitude": "46.6725",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3399"]
}, {
    "City": "Mardin",
    "DBTZ": "E",
    "Name": "Mardin Airport",
    "Country": "Turkey",
    "IATA/FAA": "MQM",
    "Longitude": "40.6317",
    "ICAO": "LTCR",
    "Airport ID": "6753",
    "Latitude": "37.2233",
    "Timezone": "1729",
    "DST": "2",
    "destinations": ["1706", "4317", "1682", "1701"]
}, {
    "City": "Lago Agrio",
    "DBTZ": "U",
    "Name": "Nueva Loja Airport",
    "Country": "Ecuador",
    "IATA/FAA": "LGQ",
    "Longitude": "-76.8675",
    "ICAO": "SELA",
    "Airport ID": "6750",
    "Latitude": "0.093056",
    "Timezone": "980",
    "DST": "-5",
    "destinations": ["2688"]
}, {
    "City": "Leon",
    "DBTZ": "E",
    "Name": "Leon Airport",
    "Country": "Spain",
    "IATA/FAA": "LEN",
    "Longitude": "-5.655556",
    "ICAO": "LELN",
    "Airport ID": "6756",
    "Latitude": "42.589",
    "Timezone": "3006",
    "DST": "1",
    "destinations": ["1218"]
}, {
    "City": "Burgos",
    "DBTZ": "E",
    "Name": "Burgos Airport",
    "Country": "Spain",
    "IATA/FAA": "RGS",
    "Longitude": "-3.620764",
    "ICAO": "LEBG",
    "Airport ID": "6757",
    "Latitude": "42.357628",
    "Timezone": "2945",
    "DST": "1",
    "destinations": ["1218"]
}, {
    "City": "Deering",
    "DBTZ": "A",
    "Name": "Deering Airport",
    "Country": "United States",
    "IATA/FAA": "DRG",
    "Longitude": "-162.766",
    "ICAO": "PADE",
    "Airport ID": "6755",
    "Latitude": "66.0696",
    "Timezone": "21",
    "DST": "-9",
    "destinations": ["7178", "3693"]
}, {
    "City": "Samana",
    "DBTZ": "U",
    "Name": "Samana El Catey International Airport",
    "Country": "Dominican Republic",
    "IATA/FAA": "AZS",
    "Longitude": "-69.742",
    "ICAO": "MDCY",
    "Airport ID": "5810",
    "Latitude": "19.267",
    "Timezone": "30",
    "DST": "-4",
    "destinations": ["146", "193", "3797", "2890"]
}, {
    "City": "La Isabela",
    "DBTZ": "U",
    "Name": "Dr Joaquin Balaguer International Airport",
    "Country": "Dominican Republic",
    "IATA/FAA": "JBQ",
    "Longitude": "-69.9856",
    "ICAO": "MDJB",
    "Airport ID": "5811",
    "Latitude": "18.5725",
    "Timezone": "98",
    "DST": "-4",
    "destinations": ["2895", "1897"]
}, {
    "City": "Praslin",
    "DBTZ": "U",
    "Name": "Praslin",
    "Country": "Seychelles",
    "IATA/FAA": "PRI",
    "Longitude": "55.691417",
    "ICAO": "FSPP",
    "Airport ID": "995",
    "Latitude": "-4.319292",
    "Timezone": "10",
    "DST": "4",
    "destinations": ["994"]
}, {
    "City": "Seal Bay",
    "DBTZ": "A",
    "Name": "Seal Bay Seaplane Base",
    "Country": "United States",
    "IATA/FAA": "SYB",
    "Longitude": "-152.5",
    "ICAO": "",
    "Airport ID": "7173",
    "Latitude": "58.166667",
    "Timezone": "0",
    "DST": "-9",
    "destinations": ["7172"]
}, {
    "City": "Mahe",
    "DBTZ": "U",
    "Name": "Seychelles Intl",
    "Country": "Seychelles",
    "IATA/FAA": "SEZ",
    "Longitude": "55.521839",
    "ICAO": "FSIA",
    "Airport ID": "994",
    "Latitude": "-4.674342",
    "Timezone": "10",
    "DST": "4",
    "destinations": ["2179", "340", "2188", "813", "893", "995", "4059", "3024", "2988"]
}, {
    "City": "Ji An",
    "DBTZ": "N",
    "Name": "Jing Gang Shan Airport",
    "Country": "China",
    "IATA/FAA": "JGS",
    "Longitude": "114.7375",
    "ICAO": "",
    "Airport ID": "6428",
    "Latitude": "26.8997",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3364", "3370", "3395", "3383", "3391", "3374", "3379"]
}, {
    "City": "Singapore",
    "DBTZ": "N",
    "Name": "Changi Intl",
    "Country": "Singapore",
    "IATA/FAA": "SIN",
    "Longitude": "103.994433",
    "ICAO": "WSSS",
    "Airport ID": "3316",
    "Latitude": "1.350189",
    "Timezone": "22",
    "DST": "8",
    "destinations": ["3885", "3999", "4120", "3386", "3077", "3179", "3304", "2397", "3308", "3351", "3034", "3035", "3239", "3205", "4302", "2276", "4206", "4140", "2429", "3144", "3394", "3406", "2997", "3093", "2279", "2179", "3320", "1382", "3043", "3930", "3269", "3298", "3263", "3305", "3266", "3307", "421", "507", "3361", "3076", "3272", "3395", "3364", "3374", "2264", "3928", "3024", "3370", "3385", "3383", "2430", "2188", "3339", "3341", "2006", "2009", "3157", "3174", "3302", "3299", "3408", "3896", "3919", "3275", "3940", "1701", "3898", "8401", "3250", "3273", "3289", "3929", "3901", "3240", "2983", "3152", "4013", "2359", "580", "340", "1678", "3131", "3135", "3393", "3931", "3136", "3371", "3196", "3310", "4090", "3199", "3141", "3382", "3125", "3153", "3177", "3066", "3376", "3373", "6390", "2305", "3992", "3942", "813", "5", "2241", "3120", "609", "2994", "1218", "4029", "1555", "3156", "346", "1524", "2082", "2072", "3181", "4033", "3121", "3387", "3388", "3321", "3390", "3368"]
}, {
    "City": "Pattaya",
    "DBTZ": "U",
    "Name": "U Taphao Intl",
    "Country": "Thailand",
    "IATA/FAA": "UTP",
    "Longitude": "101.005028",
    "ICAO": "VTBU",
    "Airport ID": "3161",
    "Latitude": "12.679944",
    "Timezone": "42",
    "DST": "7",
    "destinations": ["3179", "3177"]
}, {
    "City": "Kastamonu",
    "DBTZ": "E",
    "Name": "Uzunyazi",
    "Country": "Turkey",
    "IATA/FAA": "KFS",
    "Longitude": "33.796111",
    "ICAO": "LTAL",
    "Airport ID": "9273",
    "Latitude": "41.316944",
    "Timezone": "3520",
    "DST": "2",
    "destinations": ["1701"]
}, {
    "City": "Hassi Messaoud",
    "DBTZ": "N",
    "Name": "Oued Irara",
    "Country": "Algeria",
    "IATA/FAA": "HME",
    "Longitude": "6.140444",
    "ICAO": "DAUH",
    "Airport ID": "238",
    "Latitude": "31.672972",
    "Timezone": "463",
    "DST": "1",
    "destinations": ["210", "221", "224", "244", "231", "502"]
}, {
    "City": "In Salah",
    "DBTZ": "N",
    "Name": "In Salah",
    "Country": "Algeria",
    "IATA/FAA": "INZ",
    "Longitude": "2.512017",
    "ICAO": "DAUI",
    "Airport ID": "239",
    "Latitude": "27.251022",
    "Timezone": "896",
    "DST": "1",
    "destinations": ["210", "216"]
}, {
    "City": "Adrar",
    "DBTZ": "N",
    "Name": "Touat Cheikh Sidi Mohamed Belkebir",
    "Country": "Algeria",
    "IATA/FAA": "AZR",
    "Longitude": "-0.186414",
    "ICAO": "DAUA",
    "Airport ID": "234",
    "Latitude": "27.837589",
    "Timezone": "919",
    "DST": "1",
    "destinations": ["210", "5554", "221", "239", "243", "231"]
}, {
    "City": "Biskra",
    "DBTZ": "N",
    "Name": "Biskra",
    "Country": "Algeria",
    "IATA/FAA": "BSK",
    "Longitude": "5.738231",
    "ICAO": "DAUB",
    "Airport ID": "235",
    "Latitude": "34.793289",
    "Timezone": "289",
    "DST": "1",
    "destinations": ["210", "1335", "1386"]
}, {
    "City": "El Golea",
    "DBTZ": "N",
    "Name": "El Golea",
    "Country": "Algeria",
    "IATA/FAA": "ELG",
    "Longitude": "2.859586",
    "ICAO": "DAUE",
    "Airport ID": "236",
    "Latitude": "30.571294",
    "Timezone": "1306",
    "DST": "1",
    "destinations": ["243", "216"]
}, {
    "City": "Ghardaia",
    "DBTZ": "N",
    "Name": "Noumerat",
    "Country": "Algeria",
    "IATA/FAA": "GHA",
    "Longitude": "3.794114",
    "ICAO": "DAUG",
    "Airport ID": "237",
    "Latitude": "32.384106",
    "Timezone": "1512",
    "DST": "1",
    "destinations": ["210", "221", "231", "216"]
}, {
    "City": "Tlemcen",
    "DBTZ": "N",
    "Name": "Zenata",
    "Country": "Algeria",
    "IATA/FAA": "TLM",
    "Longitude": "-1.45",
    "ICAO": "DAON",
    "Airport ID": "230",
    "Latitude": "35.016667",
    "Timezone": "814",
    "DST": "1",
    "destinations": ["210", "1335", "1353", "1386"]
}, {
    "City": "Oran",
    "DBTZ": "N",
    "Name": "Es Senia",
    "Country": "Algeria",
    "IATA/FAA": "ORN",
    "Longitude": "-0.621183",
    "ICAO": "DAOO",
    "Airport ID": "231",
    "Latitude": "35.623858",
    "Timezone": "295",
    "DST": "1",
    "destinations": ["220", "1212", "210", "234", "1218", "5553", "1382", "1074", "221", "237", "238", "1701", "1399", "1335", "1353", "243", "1386", "1273", "1555", "1229", "2074", "287", "1423"]
}, {
    "City": "Ghriss",
    "DBTZ": "N",
    "Name": "Ghriss",
    "Country": "Algeria",
    "IATA/FAA": "MUW",
    "Longitude": "0.147142",
    "ICAO": "DAOV",
    "Airport ID": "233",
    "Latitude": "35.207725",
    "Timezone": "1686",
    "DST": "1",
    "destinations": ["210", "225"]
}, {
    "City": "Kiev",
    "DBTZ": "E",
    "Name": "Zhuliany Intl",
    "Country": "Ukraine",
    "IATA/FAA": "IEV",
    "Longitude": "30.449697",
    "ICAO": "UKKK",
    "Airport ID": "2944",
    "Latitude": "50.401694",
    "Timezone": "586",
    "DST": "2",
    "destinations": ["3941", "8076", "1218", "6103", "6109", "2988", "4029", "1489", "492", "1525", "344", "373", "3986", "1222", "671", "3972", "1197", "1539", "1246", "3959"]
}, {
    "City": "Sukkur",
    "DBTZ": "N",
    "Name": "Sukkur",
    "Country": "Pakistan",
    "IATA/FAA": "SKZ",
    "Longitude": "68.791683",
    "ICAO": "OPSK",
    "Airport ID": "2227",
    "Latitude": "27.721989",
    "Timezone": "196",
    "DST": "5",
    "destinations": ["2223", "5939", "2206", "2207", "2212"]
}, {
    "City": "Rahim Yar Khan",
    "DBTZ": "N",
    "Name": "Sheikh Zayed",
    "Country": "Pakistan",
    "IATA/FAA": "RYK",
    "Longitude": "70.279572",
    "ICAO": "OPRK",
    "Airport ID": "2222",
    "Latitude": "28.3839",
    "Timezone": "271",
    "DST": "5",
    "destinations": ["2179", "2206", "2207"]
}, {
    "City": "Islamabad",
    "DBTZ": "N",
    "Name": "Chaklala",
    "Country": "Pakistan",
    "IATA/FAA": "ISB",
    "Longitude": "73.099233",
    "ICAO": "OPRN",
    "Airport ID": "2223",
    "Latitude": "33.616653",
    "Timezone": "1668",
    "DST": "5",
    "destinations": ["2048", "2050", "2179", "3364", "3399", "2188", "2057", "2206", "2221", "2176", "2072", "2082", "2227", "1218", "5939", "469", "1382", "5940", "609", "2064", "2204", "5949", "517", "2207", "507", "478", "2194", "2074", "2214", "1524", "2219", "2191", "6776", "193", "2241", "3885", "1701"]
}, {
    "City": "Buckland",
    "DBTZ": "A",
    "Name": "Buckland Airport",
    "Country": "United States",
    "IATA/FAA": "BKC",
    "Longitude": "-161.149167",
    "ICAO": "PABL",
    "Airport ID": "7178",
    "Latitude": "65.981667",
    "Timezone": "31",
    "DST": "-9",
    "destinations": ["6755", "3693"]
}, {
    "City": "Goroka",
    "DBTZ": "U",
    "Name": "Goroka",
    "Country": "Papua New Guinea",
    "IATA/FAA": "GKA",
    "Longitude": "145.391881",
    "ICAO": "AYGA",
    "Airport ID": "1",
    "Latitude": "-6.081689",
    "Timezone": "5282",
    "DST": "10",
    "destinations": ["3", "4", "2", "5"]
}, {
    "City": "Al-Jawf",
    "DBTZ": "U",
    "Name": "Al-Jawf Domestic Airport",
    "Country": "Saudi Arabia",
    "IATA/FAA": "AJF",
    "Longitude": "40.100006",
    "ICAO": "OESK",
    "Airport ID": "5925",
    "Latitude": "29.785133",
    "Timezone": "2261",
    "DST": "3",
    "destinations": ["2072", "2082"]
}, {
    "City": "Masai Mara",
    "DBTZ": "U",
    "Name": "Mara Serena Airport",
    "Country": "Kenya",
    "IATA/FAA": "MRE",
    "Longitude": "35.008056",
    "ICAO": "",
    "Airport ID": "7424",
    "Latitude": "-1.406111",
    "Timezone": "500",
    "DST": "3",
    "destinations": ["1147"]
}, {
    "City": "Waala",
    "DBTZ": "U",
    "Name": "Belep Islands Airport",
    "Country": "New Caledonia",
    "IATA/FAA": "BMY",
    "Longitude": "163.661",
    "ICAO": "NWWC",
    "Airport ID": "5920",
    "Latitude": "-19.7206",
    "Timezone": "306",
    "DST": "11",
    "destinations": ["2001", "1999"]
}, {
    "City": "Ile des Pins",
    "DBTZ": "U",
    "Name": "Ile des Pins Airport",
    "Country": "New Caledonia",
    "IATA/FAA": "ILP",
    "Longitude": "167.456",
    "ICAO": "NWWE",
    "Airport ID": "5921",
    "Latitude": "-22.5889",
    "Timezone": "315",
    "DST": "11",
    "destinations": ["2001"]
}, {
    "City": "Bam",
    "DBTZ": "E",
    "Name": "Bam Airport",
    "Country": "Iran",
    "IATA/FAA": "BXR",
    "Longitude": "58.450042",
    "ICAO": "OIKM",
    "Airport ID": "5928",
    "Latitude": "29.084169",
    "Timezone": "3231",
    "DST": "3.5",
    "destinations": ["2131"]
}, {
    "City": "Montreal",
    "DBTZ": "A",
    "Name": "Pierre Elliott Trudeau Intl",
    "Country": "Canada",
    "IATA/FAA": "YUL",
    "Longitude": "-73.740833",
    "ICAO": "CYUL",
    "Airport ID": "146",
    "Latitude": "45.470556",
    "Timezone": "118",
    "DST": "-5",
    "destinations": ["62", "5496", "111", "149", "154", "2170", "3876", "3520", "3670", "3645", "3797", "3697", "507", "3576", "3830", "3752", "5810", "3825", "3448", "302", "5852", "1382", "1852", "1800", "3751", "3494", "2878", "3533", "340", "1665", "1910", "3714", "3550", "3877", "3484", "1780", "3878", "346", "1897", "2881", "1760", "3469", "1918", "1923", "27", "28", "49", "56", "73", "100", "117", "135", "144", "153", "156", "160", "178", "182", "189", "192", "193", "202", "5538", "1678", "1956", "3682", "210", "1824", "1074", "1904", "1903", "580", "3858", "287", "161", "1441", "2241", "1386", "1230", "3941", "1218", "1264", "1555", "502", "1638", "1335", "1353", "1354", "1761", "1836", "1273", "1551", "5514"]
}, {
    "City": "Repulse Bay",
    "DBTZ": "A",
    "Name": "Repulse Bay",
    "Country": "Canada",
    "IATA/FAA": "YUT",
    "Longitude": "-86.224722",
    "ICAO": "CYUT",
    "Airport ID": "147",
    "Latitude": "66.521389",
    "Timezone": "80",
    "DST": "-6",
    "destinations": ["29", "132", "41"]
}, {
    "City": "Toronto",
    "DBTZ": "A",
    "Name": "City Centre",
    "Country": "Canada",
    "IATA/FAA": "YTZ",
    "Longitude": "-79.396167",
    "ICAO": "CYTZ",
    "Airport ID": "144",
    "Latitude": "43.627499",
    "Timezone": "251",
    "DST": "-5",
    "destinations": ["146", "3448", "3494", "3714", "3747", "3515", "21", "100", "111", "113", "121", "133", "143"]
}, {
    "City": "Tuktoyaktuk",
    "DBTZ": "A",
    "Name": "Tuktoyaktuk",
    "Country": "Canada",
    "IATA/FAA": "YUB",
    "Longitude": "-133.026389",
    "ICAO": "CYUB",
    "Airport ID": "145",
    "Latitude": "69.433334",
    "Timezone": "15",
    "DST": "-7",
    "destinations": ["54"]
}, {
    "City": "Timmins",
    "DBTZ": "A",
    "Name": "Timmins",
    "Country": "Canada",
    "IATA/FAA": "YTS",
    "Longitude": "-81.376667",
    "ICAO": "CYTS",
    "Airport ID": "143",
    "Latitude": "48.569721",
    "Timezone": "967",
    "DST": "-5",
    "destinations": ["193", "133", "190", "144", "91"]
}, {
    "City": "Cape Dorset",
    "DBTZ": "A",
    "Name": "Cape Dorset",
    "Country": "Canada",
    "IATA/FAA": "YTE",
    "Longitude": "-76.526667",
    "ICAO": "CYTE",
    "Airport ID": "140",
    "Latitude": "64.23",
    "Timezone": "164",
    "DST": "-5",
    "destinations": ["55", "41"]
}, {
    "City": "Thompson",
    "DBTZ": "A",
    "Name": "Thompson",
    "Country": "Canada",
    "IATA/FAA": "YTH",
    "Longitude": "-97.864166",
    "ICAO": "CYTH",
    "Airport ID": "141",
    "Latitude": "55.801111",
    "Timezone": "729",
    "DST": "-6",
    "destinations": ["187", "58", "5497", "5524", "160"]
}, {
    "City": "N'djamena",
    "DBTZ": "N",
    "Name": "Ndjamena Hassan Djamous",
    "Country": "Chad",
    "IATA/FAA": "NDJ",
    "Longitude": "15.034019",
    "ICAO": "FTTJ",
    "Airport ID": "999",
    "Latitude": "12.133689",
    "Timezone": "968",
    "DST": "1",
    "destinations": ["1382", "260", "1107", "897", "280", "900", "4161", "270", "1701"]
}, {
    "City": "Hall Beach",
    "DBTZ": "A",
    "Name": "Hall Beach",
    "Country": "Canada",
    "IATA/FAA": "YUX",
    "Longitude": "-81.243611",
    "ICAO": "CYUX",
    "Airport ID": "148",
    "Latitude": "68.776111",
    "Timezone": "27",
    "DST": "-5",
    "destinations": ["55", "5495"]
}, {
    "City": "Rouyn",
    "DBTZ": "A",
    "Name": "Rouyn Noranda",
    "Country": "Canada",
    "IATA/FAA": "YUY",
    "Longitude": "-78.835556",
    "ICAO": "CYUY",
    "Airport ID": "149",
    "Latitude": "48.206111",
    "Timezone": "988",
    "DST": "-5",
    "destinations": ["5496", "146", "153"]
}, {
    "City": "Zonguldak",
    "DBTZ": "E",
    "Name": "Zonguldak",
    "Country": "Turkey",
    "IATA/FAA": "ONQ",
    "Longitude": "32.088611",
    "ICAO": "LTAS",
    "Airport ID": "7821",
    "Latitude": "41.506111",
    "Timezone": "44",
    "DST": "2",
    "destinations": ["373", "345"]
}, {
    "City": "Tabuk",
    "DBTZ": "U",
    "Name": "Tabuk",
    "Country": "Saudi Arabia",
    "IATA/FAA": "TUU",
    "Longitude": "36.618889",
    "ICAO": "OETB",
    "Airport ID": "2089",
    "Latitude": "28.365417",
    "Timezone": "2551",
    "DST": "3",
    "destinations": ["1128", "2188", "2059", "2064", "2072", "2074", "2082"]
}, {
    "City": "Harbin",
    "DBTZ": "U",
    "Name": "Taiping",
    "Country": "China",
    "IATA/FAA": "HRB",
    "Longitude": "126.250328",
    "ICAO": "ZYHB",
    "Airport ID": "3400",
    "Latitude": "45.623403",
    "Timezone": "457",
    "DST": "8",
    "destinations": ["3375", "4109", "3404", "4085", "3387", "3388", "6954", "6347", "4108", "3368", "6392", "3376", "3379", "4149", "7898", "3406", "6410", "8979", "6411", "8417", "2276", "3370", "3395", "3364", "3374", "3390", "3369", "3392", "2370", "3371", "3385", "3389", "3386", "3930", "3384", "4381", "3992", "6943", "6430", "6394", "6393", "8876", "3366", "4145", "6345", "3077", "2927", "2937", "6348", "6341", "6342", "6434", "6944", "2923", "2933", "4078", "2975"]
}, {
    "City": "Riyadh",
    "DBTZ": "U",
    "Name": "King Khaled Intl",
    "Country": "Saudi Arabia",
    "IATA/FAA": "RUH",
    "Longitude": "46.698776",
    "ICAO": "OERK",
    "Airport ID": "2082",
    "Latitude": "24.95764",
    "Timezone": "2049",
    "DST": "3",
    "destinations": ["2997", "1382", "3137", "3093", "3153", "1128", "1555", "1524", "507", "3076", "3077", "3410", "2188", "1107", "2179", "8076", "2191", "2057", "3980", "3975", "2176", "3930", "2072", "340", "346", "2177", "2241", "3024", "4057", "2223", "2207", "2219", "2206", "6776", "2397", "2170", "1175", "3316", "2061", "2059", "5925", "2076", "2062", "3131", "3370", "3275", "1074", "3136", "2064", "5924", "2075", "2067", "2066", "1665", "2069", "2060", "3141", "3714", "1701", "3797", "3304", "3105", "3144", "2194", "2074", "2084", "2081", "2086", "2090", "2093", "2089", "8381", "2068", "2096", "5691", "8235", "7453", "1132", "4317"]
}, {
    "City": "Rafha",
    "DBTZ": "U",
    "Name": "Rafha",
    "Country": "Saudi Arabia",
    "IATA/FAA": "RAH",
    "Longitude": "43.490614",
    "ICAO": "OERF",
    "Airport ID": "2081",
    "Latitude": "29.626419",
    "Timezone": "1474",
    "DST": "3",
    "destinations": ["2082"]
}, {
    "City": "Sharurah",
    "DBTZ": "U",
    "Name": "Sharurah",
    "Country": "Saudi Arabia",
    "IATA/FAA": "SHW",
    "Longitude": "47.121431",
    "ICAO": "OESH",
    "Airport ID": "2086",
    "Latitude": "17.466875",
    "Timezone": "2363",
    "DST": "3",
    "destinations": ["2072", "2082"]
}, {
    "City": "Arar",
    "DBTZ": "U",
    "Name": "Arar",
    "Country": "Saudi Arabia",
    "IATA/FAA": "RAE",
    "Longitude": "41.138217",
    "ICAO": "OERR",
    "Airport ID": "2084",
    "Latitude": "30.906589",
    "Timezone": "1813",
    "DST": "3",
    "destinations": ["2072", "2082"]
}, {
    "City": "Moab",
    "DBTZ": "A",
    "Name": "Canyonlands Field",
    "Country": "United States",
    "IATA/FAA": "CNY",
    "Longitude": "-109.754722",
    "ICAO": "KCNY",
    "Airport ID": "7074",
    "Latitude": "38.755",
    "Timezone": "4555",
    "DST": "-7",
    "destinations": ["3536"]
}, {
    "City": "Campo Grande",
    "DBTZ": "S",
    "Name": "Campo Grande",
    "Country": "Brazil",
    "IATA/FAA": "CGR",
    "Longitude": "-54.6725",
    "ICAO": "SBCG",
    "Airport ID": "2538",
    "Latitude": "-20.468667",
    "Timezone": "1833",
    "DST": "-4",
    "destinations": ["7380", "2548", "2544", "2564", "2578", "2531", "2618", "2560", "2586", "2545"]
}, {
    "City": "Chapeco",
    "DBTZ": "S",
    "Name": "Chapeco",
    "Country": "Brazil",
    "IATA/FAA": "XAP",
    "Longitude": "-52.656553",
    "ICAO": "SBCH",
    "Airport ID": "2539",
    "Latitude": "-27.134219",
    "Timezone": "2146",
    "DST": "-3",
    "destinations": ["2599", "2578", "2555", "2545"]
}, {
    "City": "Boa Vista",
    "DBTZ": "S",
    "Name": "Boa Vista",
    "Country": "Brazil",
    "IATA/FAA": "BVB",
    "Longitude": "-60.690069",
    "ICAO": "SBBV",
    "Airport ID": "2533",
    "Latitude": "2.846311",
    "Timezone": "276",
    "DST": "-4",
    "destinations": ["2551"]
}, {
    "City": "Brasilia",
    "DBTZ": "S",
    "Name": "Presidente Juscelino Kubitschek",
    "Country": "Brazil",
    "IATA/FAA": "BSB",
    "Longitude": "-47.918625",
    "ICAO": "SBBR",
    "Airport ID": "2531",
    "Latitude": "-15.8711",
    "Timezone": "3479",
    "DST": "-3",
    "destinations": ["7373", "7367", "2613", "3576", "7376", "2548", "2537", "2564", "2584", "4214", "2633", "2578", "1382", "3988", "1871", "3682", "2526", "2618", "2538", "2545", "2559", "2560", "2562", "2572", "2575", "2551", "2590", "2597", "2599", "2607", "2609", "2610", "2612", "2616", "2621", "2623", "2555", "2638", "2522", "2569", "6034", "2604", "1638", "2619"]
}, {
    "City": "Belo Horizonte",
    "DBTZ": "S",
    "Name": "Tancredo Neves Intl",
    "Country": "Brazil",
    "IATA/FAA": "CNF",
    "Longitude": "-43.968856",
    "ICAO": "SBCF",
    "Airport ID": "2537",
    "Latitude": "-19.63375",
    "Timezone": "2715",
    "DST": "-3",
    "destinations": ["3576", "2526", "4209", "2531", "2548", "7532", "2577", "2545", "2559", "2564", "2562", "2572", "2570", "8180", "2551", "2587", "2599", "2613", "2610", "2612", "2616", "2621", "2635", "2633", "2578", "6039", "2638", "2442", "1871", "2618", "2560", "2569", "1638"]
}, {
    "City": "Cascavel",
    "DBTZ": "S",
    "Name": "Cascavel",
    "Country": "Brazil",
    "IATA/FAA": "CAC",
    "Longitude": "-53.500764",
    "ICAO": "SBCA",
    "Airport ID": "2535",
    "Latitude": "-25.000339",
    "Timezone": "2473",
    "DST": "-3",
    "destinations": ["2564", "2545", "2578"]
}, {
    "City": "Buenaventura",
    "DBTZ": "U",
    "Name": "Gerardo Tobar Lopez",
    "Country": "Colombia",
    "IATA/FAA": "BUN",
    "Longitude": "-76.989767",
    "ICAO": "SKBU",
    "Airport ID": "2712",
    "Latitude": "3.819628",
    "Timezone": "48",
    "DST": "-5",
    "destinations": ["2709"]
}, {
    "City": "Cucuta",
    "DBTZ": "U",
    "Name": "Camilo Daza",
    "Country": "Colombia",
    "IATA/FAA": "CUC",
    "Longitude": "-72.511547",
    "ICAO": "SKCC",
    "Airport ID": "2713",
    "Latitude": "7.927567",
    "Timezone": "1096",
    "DST": "-5",
    "destinations": ["2709", "2745", "1871"]
}, {
    "City": "Barranquilla",
    "DBTZ": "U",
    "Name": "Ernesto Cortissoz",
    "Country": "Colombia",
    "IATA/FAA": "BAQ",
    "Longitude": "-74.780819",
    "ICAO": "SKBQ",
    "Airport ID": "2710",
    "Latitude": "10.889589",
    "Timezone": "98",
    "DST": "-5",
    "destinations": ["2709", "2715", "2745", "3576", "2749", "1871"]
}, {
    "City": "Bahia Solano",
    "DBTZ": "U",
    "Name": "Jose Celestino Mutis",
    "Country": "Colombia",
    "IATA/FAA": "BSC",
    "Longitude": "-77.394675",
    "ICAO": "SKBS",
    "Airport ID": "2711",
    "Latitude": "6.202917",
    "Timezone": "80",
    "DST": "-5",
    "destinations": ["2729"]
}, {
    "City": "Tumaco",
    "DBTZ": "U",
    "Name": "La Florida",
    "Country": "Colombia",
    "IATA/FAA": "TCO",
    "Longitude": "-78.749228",
    "ICAO": "SKCO",
    "Airport ID": "2716",
    "Latitude": "1.814417",
    "Timezone": "8",
    "DST": "-5",
    "destinations": ["2715"]
}, {
    "City": "Corozal",
    "DBTZ": "U",
    "Name": "Las Brujas",
    "Country": "Colombia",
    "IATA/FAA": "CZU",
    "Longitude": "-75.285594",
    "ICAO": "SKCZ",
    "Airport ID": "2717",
    "Latitude": "9.332742",
    "Timezone": "528",
    "DST": "-5",
    "destinations": ["2709"]
}, {
    "City": "Cartagena",
    "DBTZ": "U",
    "Name": "Rafael Nunez",
    "Country": "Colombia",
    "IATA/FAA": "CTG",
    "Longitude": "-75.512961",
    "ICAO": "SKCG",
    "Airport ID": "2714",
    "Latitude": "10.442381",
    "Timezone": "4",
    "DST": "-5",
    "destinations": ["2709", "2715", "2745", "3576", "3797", "2749", "1871", "3533"]
}, {
    "City": "Cali",
    "DBTZ": "U",
    "Name": "Alfonso Bonilla Aragon Intl",
    "Country": "Colombia",
    "IATA/FAA": "CLO",
    "Longitude": "-76.381583",
    "ICAO": "SKCL",
    "Airport ID": "2715",
    "Latitude": "3.543222",
    "Timezone": "3162",
    "DST": "-5",
    "destinations": ["2673", "2722", "2706", "2753", "3576", "2710", "2709", "2714", "2789", "1229", "2745", "2742", "1892", "2716", "2749", "1871", "6047", "2688"]
}, {
    "City": "Glendive",
    "DBTZ": "A",
    "Name": "Dawson Community Airport",
    "Country": "United States",
    "IATA/FAA": "GDV",
    "Longitude": "-104.807222",
    "ICAO": "KGDV",
    "Airport ID": "7070",
    "Latitude": "47.138611",
    "Timezone": "749",
    "DST": "-7",
    "destinations": ["4021"]
}, {
    "City": "Barrancabermeja",
    "DBTZ": "U",
    "Name": "Yariguies",
    "Country": "Colombia",
    "IATA/FAA": "EJA",
    "Longitude": "-73.8068",
    "ICAO": "SKEJ",
    "Airport ID": "2718",
    "Latitude": "7.024331",
    "Timezone": "412",
    "DST": "-5",
    "destinations": ["2709"]
}, {
    "City": "Florencia",
    "DBTZ": "U",
    "Name": "Gustavo Artunduaga Paredes",
    "Country": "Colombia",
    "IATA/FAA": "FLA",
    "Longitude": "-75.564372",
    "ICAO": "SKFL",
    "Airport ID": "2719",
    "Latitude": "1.589189",
    "Timezone": "803",
    "DST": "-5",
    "destinations": ["2709"]
}, {
    "City": "Kununurra",
    "DBTZ": "O",
    "Name": "Kununurra",
    "Country": "Australia",
    "IATA/FAA": "KNX",
    "Longitude": "128.7075",
    "ICAO": "YPKU",
    "Airport ID": "3347",
    "Latitude": "-15.778056",
    "Timezone": "145",
    "DST": "8",
    "destinations": ["3351", "4319", "3999"]
}, {
    "City": "Wainwright",
    "DBTZ": "A",
    "Name": "Wainwright Airport",
    "Country": "United States",
    "IATA/FAA": "AIN",
    "Longitude": "-159.994722",
    "ICAO": "PAWI",
    "Airport ID": "7220",
    "Latitude": "70.638056",
    "Timezone": "41",
    "DST": "-9",
    "destinations": ["6714", "3571"]
}, {
    "City": "Karratha",
    "DBTZ": "O",
    "Name": "Karratha",
    "Country": "Australia",
    "IATA/FAA": "KTA",
    "Longitude": "116.773333",
    "ICAO": "YPKA",
    "Airport ID": "3345",
    "Latitude": "-20.712222",
    "Timezone": "29",
    "DST": "8",
    "destinations": ["3320", "3339", "3351", "3349", "3361"]
}, {
    "City": "Poza Rico",
    "DBTZ": "S",
    "Name": "Tajin",
    "Country": "Mexico",
    "IATA/FAA": "PAZ",
    "Longitude": "-97.460839",
    "ICAO": "MMPA",
    "Airport ID": "1830",
    "Latitude": "20.602671",
    "Timezone": "497",
    "DST": "-6",
    "destinations": ["1824", "1839", "1853"]
}, {
    "City": "Puebla",
    "DBTZ": "S",
    "Name": "Hermanos Serdan Intl",
    "Country": "Mexico",
    "IATA/FAA": "PBC",
    "Longitude": "-98.371447",
    "ICAO": "MMPB",
    "Airport ID": "1831",
    "Latitude": "19.158144",
    "Timezone": "7361",
    "DST": "-6",
    "destinations": ["3670", "1825", "3550", "1852", "1804", "1847"]
}, {
    "City": "Puerto Vallarta",
    "DBTZ": "S",
    "Name": "Licenciado Gustavo Diaz Ordaz Intl",
    "Country": "Mexico",
    "IATA/FAA": "PVR",
    "Longitude": "-105.254167",
    "ICAO": "MMPR",
    "Airport ID": "1836",
    "Latitude": "20.680083",
    "Timezone": "23",
    "DST": "-6",
    "destinations": ["1824", "1850", "3670", "3830", "3462", "3731", "3469", "156", "178", "3682", "1804", "3484", "3536", "3751", "3678", "146", "3494", "3550", "1825", "49", "1847"]
}, {
    "City": "Puerto Escondido",
    "DBTZ": "S",
    "Name": "Puerto Escondido Intl",
    "Country": "Mexico",
    "IATA/FAA": "PXM",
    "Longitude": "-97.089117",
    "ICAO": "MMPS",
    "Airport ID": "1837",
    "Latitude": "15.876861",
    "Timezone": "294",
    "DST": "-6",
    "destinations": ["1824"]
}, {
    "City": "Piedras Negras",
    "DBTZ": "S",
    "Name": "Piedras Negras Intl",
    "Country": "Mexico",
    "IATA/FAA": "PDS",
    "Longitude": "-100.535211",
    "ICAO": "MMPG",
    "Airport ID": "1834",
    "Latitude": "28.627394",
    "Timezone": "901",
    "DST": "-6",
    "destinations": ["1824"]
}, {
    "City": "Uruapan",
    "DBTZ": "S",
    "Name": "Licenciado Y Gen Ignacio Lopez Rayon",
    "Country": "Mexico",
    "IATA/FAA": "UPN",
    "Longitude": "-102.039056",
    "ICAO": "MMPN",
    "Airport ID": "1835",
    "Latitude": "19.396692",
    "Timezone": "5258",
    "DST": "-6",
    "destinations": ["3484", "1847"]
}, {
    "City": "King Salmon",
    "DBTZ": "A",
    "Name": "King Salmon",
    "Country": "United States",
    "IATA/FAA": "AKN",
    "Longitude": "-156.649278",
    "ICAO": "PAKN",
    "Airport ID": "3794",
    "Latitude": "58.676778",
    "Timezone": "68",
    "DST": "-9",
    "destinations": ["3774", "3460", "7102", "7158", "7159"]
}, {
    "City": "Queretaro",
    "DBTZ": "S",
    "Name": "Queretaro Intercontinental",
    "Country": "Mexico",
    "IATA/FAA": "QRO",
    "Longitude": "-100.185658",
    "ICAO": "MMQT",
    "Airport ID": "1838",
    "Latitude": "20.617289",
    "Timezone": "6296",
    "DST": "-6",
    "destinations": ["3670", "1825", "3550", "1852", "1847"]
}, {
    "City": "Lahania-kapalua",
    "DBTZ": "N",
    "Name": "Kapalua",
    "Country": "United States",
    "IATA/FAA": "JHM",
    "Longitude": "-156.673031",
    "ICAO": "PHJH",
    "Airport ID": "3796",
    "Latitude": "20.962936",
    "Timezone": "256",
    "DST": "-10",
    "destinations": ["3728", "3514"]
}, {
    "City": "Belleville",
    "DBTZ": "A",
    "Name": "Scott Afb Midamerica",
    "Country": "United States",
    "IATA/FAA": "BLV",
    "Longitude": "-89.835183",
    "ICAO": "KBLV",
    "Airport ID": "3790",
    "Latitude": "38.545178",
    "Timezone": "459",
    "DST": "-6",
    "destinations": ["4167"]
}, {
    "City": "Fort Myers",
    "DBTZ": "A",
    "Name": "Southwest Florida Intl",
    "Country": "United States",
    "IATA/FAA": "RSW",
    "Longitude": "-81.755167",
    "ICAO": "KRSW",
    "Airport ID": "3793",
    "Latitude": "26.536167",
    "Timezone": "30",
    "DST": "-5",
    "destinations": ["3875", "3878", "3876", "3520", "3670", "345", "3830", "3752", "193", "3682", "3448", "3494", "3589", "3797", "3697", "3759", "3488", "3645", "3585", "3858", "3751", "3859", "3447", "3849", "3747", "3524", "3486", "3550", "3825", "3717", "3570", "3641", "3678"]
}, {
    "City": "Boulia",
    "DBTZ": "O",
    "Name": "Boulia Airport",
    "Country": "Australia",
    "IATA/FAA": "BQL",
    "Longitude": "139.9",
    "ICAO": "YBOU",
    "Airport ID": "6248",
    "Latitude": "-22.9133",
    "Timezone": "542",
    "DST": "10",
    "destinations": ["6243", "3324"]
}, {
    "City": "Bathurst",
    "DBTZ": "O",
    "Name": "Bathurst Airport",
    "Country": "Australia",
    "IATA/FAA": "BHS",
    "Longitude": "149.652",
    "ICAO": "YBTH",
    "Airport ID": "6249",
    "Latitude": "-33.4094",
    "Timezone": "2435",
    "DST": "10",
    "destinations": ["6317", "3361"]
}, {
    "City": "Zamboanga",
    "DBTZ": "N",
    "Name": "Zamboanga Intl",
    "Country": "Philippines",
    "IATA/FAA": "ZAM",
    "Longitude": "122.059633",
    "ICAO": "RPMZ",
    "Airport ID": "2404",
    "Latitude": "6.922419",
    "Timezone": "33",
    "DST": "8",
    "destinations": ["2397", "4206", "4090", "6013"]
}, {
    "City": "Shiraz",
    "DBTZ": "E",
    "Name": "Shiraz Shahid Dastghaib Intl",
    "Country": "Iran",
    "IATA/FAA": "SYZ",
    "Longitude": "52.589786",
    "ICAO": "OISS",
    "Airport ID": "2157",
    "Latitude": "29.539242",
    "Timezone": "4920",
    "DST": "3.5",
    "destinations": ["2104", "2109", "4331", "2131", "2097", "2134", "2188", "5712", "2136", "2176", "5934", "2194", "7546", "2123", "5933", "2162", "2167", "2191", "2110", "2106", "2241", "4150", "2074", "1701"]
}, {
    "City": "Ramsar",
    "DBTZ": "E",
    "Name": "Ramsar",
    "Country": "Iran",
    "IATA/FAA": "RZR",
    "Longitude": "50.679589",
    "ICAO": "OINR",
    "Airport ID": "2151",
    "Latitude": "36.909908",
    "Timezone": "-70",
    "DST": "3.5",
    "destinations": ["4331", "2131"]
}, {
    "City": "Ladag",
    "DBTZ": "N",
    "Name": "Cagayan De Oro",
    "Country": "Philippines",
    "IATA/FAA": "CGY",
    "Longitude": "124.611219",
    "ICAO": "RPML",
    "Airport ID": "2400",
    "Latitude": "8.415619",
    "Timezone": "601",
    "DST": "8",
    "destinations": ["2397", "2423", "4206", "4090", "2429"]
}, {
    "City": "Pagadian",
    "DBTZ": "N",
    "Name": "Pagadian",
    "Country": "Philippines",
    "IATA/FAA": "PAG",
    "Longitude": "123.458294",
    "ICAO": "RPMP",
    "Airport ID": "2401",
    "Latitude": "7.827197",
    "Timezone": "5",
    "DST": "8",
    "destinations": ["4206", "2397"]
}, {
    "City": "Sambava",
    "DBTZ": "U",
    "Name": "Sambava",
    "Country": "Madagascar",
    "IATA/FAA": "SVB",
    "Longitude": "50.174721",
    "ICAO": "FMNS",
    "Airport ID": "933",
    "Latitude": "-14.278611",
    "Timezone": "20",
    "DST": "3",
    "destinations": ["923", "918", "932"]
}, {
    "City": "Maroantsetra",
    "DBTZ": "U",
    "Name": "Maroantsetra",
    "Country": "Madagascar",
    "IATA/FAA": "WMN",
    "Longitude": "49.688332",
    "ICAO": "FMNR",
    "Airport ID": "932",
    "Latitude": "-15.436666",
    "Timezone": "13",
    "DST": "3",
    "destinations": ["927", "933", "921", "918"]
}, {
    "City": "Hamilton Island",
    "DBTZ": "O",
    "Name": "Hamilton Island Airport",
    "Country": "Australia",
    "IATA/FAA": "HTI",
    "Longitude": "148.952",
    "ICAO": "YBHM",
    "Airport ID": "6242",
    "Latitude": "-20.3581",
    "Timezone": "15",
    "DST": "10",
    "destinations": ["3320", "3339", "3361", "3322"]
}, {
    "City": "Nosy-be",
    "DBTZ": "U",
    "Name": "Fascene",
    "Country": "Madagascar",
    "IATA/FAA": "NOS",
    "Longitude": "48.314822",
    "ICAO": "FMNN",
    "Airport ID": "930",
    "Latitude": "-13.312067",
    "Timezone": "36",
    "DST": "3",
    "destinations": ["923", "918", "916", "915"]
}, {
    "City": "Tolagnaro",
    "DBTZ": "U",
    "Name": "Tolagnaro",
    "Country": "Madagascar",
    "IATA/FAA": "FTU",
    "Longitude": "46.956111",
    "ICAO": "FMSD",
    "Airport ID": "937",
    "Latitude": "-25.038056",
    "Timezone": "29",
    "DST": "3",
    "destinations": ["943", "918"]
}, {
    "City": "Burketown",
    "DBTZ": "O",
    "Name": "Burketown Airport",
    "Country": "Australia",
    "IATA/FAA": "BUC",
    "Longitude": "139.534",
    "ICAO": "YBKT",
    "Airport ID": "6245",
    "Latitude": "-17.7486",
    "Timezone": "21",
    "DST": "10",
    "destinations": ["6262", "6304"]
}, {
    "City": "Fort Smith",
    "DBTZ": "A",
    "Name": "Fort Smith",
    "Country": "Canada",
    "IATA/FAA": "YSM",
    "Longitude": "-111.961944",
    "ICAO": "CYSM",
    "Airport ID": "136",
    "Latitude": "60.020278",
    "Timezone": "671",
    "DST": "-7",
    "destinations": ["49", "72", "109", "196"]
}, {
    "City": "Belize City",
    "DBTZ": "U",
    "Name": "Philip S W Goldson Intl",
    "Country": "Belize",
    "IATA/FAA": "BZE",
    "Longitude": "-88.308203",
    "ICAO": "MZBZ",
    "Airport ID": "1957",
    "Latitude": "17.539144",
    "Timezone": "15",
    "DST": "-6",
    "destinations": ["4024", "8227", "5454", "1852", "7287", "1819", "7288", "1774", "1772", "4279", "6463", "3876", "3670", "3576", "1892", "3682", "3484", "3494", "3550"]
}, {
    "City": "Cockburn Town",
    "DBTZ": "U",
    "Name": "San Salvador",
    "Country": "Bahamas",
    "IATA/FAA": "ZSA",
    "Longitude": "-74.523967",
    "ICAO": "MYSM",
    "Airport ID": "1956",
    "Latitude": "24.063275",
    "Timezone": "24",
    "DST": "-5",
    "destinations": ["1754", "3533", "1953"]
}, {
    "City": "Stella Maris",
    "DBTZ": "U",
    "Name": "Stella Maris",
    "Country": "Bahamas",
    "IATA/FAA": "SML",
    "Longitude": "-75.270475",
    "ICAO": "MYLS",
    "Airport ID": "1951",
    "Latitude": "23.581444",
    "Timezone": "10",
    "DST": "-5",
    "destinations": ["1953"]
}, {
    "City": "Dead Man's Cay",
    "DBTZ": "U",
    "Name": "Deadmans Cay",
    "Country": "Bahamas",
    "IATA/FAA": "LGI",
    "Longitude": "-75.093597",
    "ICAO": "MYLD",
    "Airport ID": "1950",
    "Latitude": "23.179014",
    "Timezone": "9",
    "DST": "-5",
    "destinations": ["1953"]
}, {
    "City": "Nassau",
    "DBTZ": "U",
    "Name": "Lynden Pindling Intl",
    "Country": "Bahamas",
    "IATA/FAA": "NAS",
    "Longitude": "-77.466231",
    "ICAO": "MYNN",
    "Airport ID": "1953",
    "Latitude": "25.038958",
    "Timezone": "16",
    "DST": "-5",
    "destinations": ["3876", "3520", "3670", "3576", "3752", "193", "3682", "3448", "3533", "3797", "3878", "1926", "507", "1754", "1779", "1871", "1909", "3645", "3849", "5857", "1943", "1944", "1950", "1946", "1951", "1956", "1948", "1941", "1930", "3494", "3550", "3830", "1932", "5859", "1949", "1952", "3722"]
}, {
    "City": "Mayaguana",
    "DBTZ": "U",
    "Name": "Mayaguana",
    "Country": "Bahamas",
    "IATA/FAA": "MYG",
    "Longitude": "-73.0135",
    "ICAO": "MYMM",
    "Airport ID": "1952",
    "Latitude": "22.379528",
    "Timezone": "11",
    "DST": "-5",
    "destinations": ["1949", "1953"]
}, {
    "City": "Avarua",
    "DBTZ": "U",
    "Name": "Rarotonga Intl",
    "Country": "Cook Islands",
    "IATA/FAA": "RAR",
    "Longitude": "-159.805556",
    "ICAO": "NCRG",
    "Airport ID": "1959",
    "Latitude": "-21.202739",
    "Timezone": "19",
    "DST": "-10",
    "destinations": ["1958", "5861", "5862", "5865", "5864", "4075", "2006", "3484", "3361"]
}, {
    "City": "Aitutaki",
    "DBTZ": "U",
    "Name": "Aitutaki",
    "Country": "Cook Islands",
    "IATA/FAA": "AIT",
    "Longitude": "-159.764233",
    "ICAO": "NCAI",
    "Airport ID": "1958",
    "Latitude": "-18.830922",
    "Timezone": "14",
    "DST": "-10",
    "destinations": ["5861", "1959"]
}, {
    "City": "Palu",
    "DBTZ": "N",
    "Name": "Mutiara",
    "Country": "Indonesia",
    "IATA/FAA": "PLW",
    "Longitude": "119.909642",
    "ICAO": "WAML",
    "Airport ID": "3249",
    "Latitude": "-0.918542",
    "Timezone": "284",
    "DST": "8",
    "destinations": ["3275", "3240", "3919", "3928"]
}, {
    "City": "Yangzhou",
    "DBTZ": "N",
    "Name": "Yangzhou Taizhou Airport",
    "Country": "China",
    "IATA/FAA": "YTY",
    "Longitude": "119.715",
    "ICAO": "ZSYA",
    "Airport ID": "8876",
    "Latitude": "32.5617",
    "Timezone": "7",
    "DST": "8",
    "destinations": ["3395", "3393", "3364", "3379", "3383", "3370", "3375", "3371", "3404", "3400", "4030", "3376", "3374"]
}, {
    "City": "Timika",
    "DBTZ": "N",
    "Name": "Moses Kilangin",
    "Country": "Indonesia",
    "IATA/FAA": "TIM",
    "Longitude": "136.887375",
    "ICAO": "WABP",
    "Airport ID": "3243",
    "Latitude": "-4.528275",
    "Timezone": "103",
    "DST": "9",
    "destinations": ["3244", "3940", "3240"]
}, {
    "City": "Nabire",
    "DBTZ": "N",
    "Name": "Nabire",
    "Country": "Indonesia",
    "IATA/FAA": "NBX",
    "Longitude": "135.496406",
    "ICAO": "WABI",
    "Airport ID": "3242",
    "Latitude": "-3.368183",
    "Timezone": "20",
    "DST": "9",
    "destinations": ["3256", "3244", "3258"]
}, {
    "City": "Biak",
    "DBTZ": "N",
    "Name": "Frans Kaisiepo",
    "Country": "Indonesia",
    "IATA/FAA": "BIK",
    "Longitude": "136.107997",
    "ICAO": "WABB",
    "Airport ID": "3241",
    "Latitude": "-1.190017",
    "Timezone": "46",
    "DST": "9",
    "destinations": ["3244", "3240"]
}, {
    "City": "Ujung Pandang",
    "DBTZ": "N",
    "Name": "Hasanuddin",
    "Country": "Indonesia",
    "IATA/FAA": "UPG",
    "Longitude": "119.554042",
    "ICAO": "WAAA",
    "Airport ID": "3240",
    "Latitude": "-5.061631",
    "Timezone": "47",
    "DST": "8",
    "destinations": ["3304", "3256", "3241", "3919", "3275", "3244", "3940", "3247", "3898", "3890", "8401", "3250", "3249", "3316", "3261", "3928", "3253", "6231", "3254", "9181", "3251", "8735", "3920", "3260", "3243"]
}, {
    "City": "Gorontalo",
    "DBTZ": "N",
    "Name": "Jalaluddin",
    "Country": "Indonesia",
    "IATA/FAA": "GTO",
    "Longitude": "122.849858",
    "ICAO": "WAMG",
    "Airport ID": "3247",
    "Latitude": "0.637119",
    "Timezone": "105",
    "DST": "8",
    "destinations": ["3240", "3250"]
}, {
    "City": "Merauke",
    "DBTZ": "N",
    "Name": "Mopah",
    "Country": "Indonesia",
    "IATA/FAA": "MKQ",
    "Longitude": "140.418453",
    "ICAO": "WAKK",
    "Airport ID": "3246",
    "Latitude": "-8.520294",
    "Timezone": "10",
    "DST": "9",
    "destinations": ["3244"]
}, {
    "City": "Wamena",
    "DBTZ": "N",
    "Name": "Wamena",
    "Country": "Indonesia",
    "IATA/FAA": "WMX",
    "Longitude": "138.957372",
    "ICAO": "WAJW",
    "Airport ID": "3245",
    "Latitude": "-4.102511",
    "Timezone": "5085",
    "DST": "9",
    "destinations": ["3244"]
}, {
    "City": "Jayapura",
    "DBTZ": "N",
    "Name": "Sentani",
    "Country": "Indonesia",
    "IATA/FAA": "DJJ",
    "Longitude": "140.516372",
    "ICAO": "WAJJ",
    "Airport ID": "3244",
    "Latitude": "-2.576953",
    "Timezone": "289",
    "DST": "9",
    "destinations": ["3241", "3275", "3246", "3260", "3243", "3240", "3245", "3242"]
}, {
    "City": "Margate",
    "DBTZ": "U",
    "Name": "Margate",
    "Country": "South Africa",
    "IATA/FAA": "MGH",
    "Longitude": "30.343019",
    "ICAO": "FAMG",
    "Airport ID": "827",
    "Latitude": "-30.857408",
    "Timezone": "495",
    "DST": "2",
    "destinations": ["813"]
}, {
    "City": "Johannesburg",
    "DBTZ": "U",
    "Name": "Lanseria",
    "Country": "South Africa",
    "IATA/FAA": "HLA",
    "Longitude": "27.926133",
    "ICAO": "FALA",
    "Airport ID": "820",
    "Latitude": "-25.938514",
    "Timezone": "4517",
    "DST": "2",
    "destinations": ["797", "799"]
}, {
    "City": "Ronne",
    "DBTZ": "E",
    "Name": "Bornholm Ronne",
    "Country": "Denmark",
    "IATA/FAA": "RNN",
    "Longitude": "14.759558",
    "ICAO": "EKRN",
    "Airport ID": "618",
    "Latitude": "55.063267",
    "Timezone": "52",
    "DST": "1",
    "destinations": ["609"]
}, {
    "City": "Skiros",
    "DBTZ": "E",
    "Name": "Skiros",
    "Country": "Greece",
    "IATA/FAA": "SKU",
    "Longitude": "24.487228",
    "ICAO": "LGSY",
    "Airport ID": "1482",
    "Latitude": "38.967553",
    "Timezone": "44",
    "DST": "2",
    "destinations": ["3941", "1486"]
}, {
    "City": "Sitia",
    "DBTZ": "E",
    "Name": "Sitia",
    "Country": "Greece",
    "IATA/FAA": "JSH",
    "Longitude": "26.101325",
    "ICAO": "LGST",
    "Airport ID": "1480",
    "Latitude": "35.216108",
    "Timezone": "376",
    "DST": "2",
    "destinations": ["3941", "1461", "1446", "1470"]
}, {
    "City": "Grise Fiord",
    "DBTZ": "A",
    "Name": "Grise Fiord Airport",
    "Country": "Canada",
    "IATA/FAA": "YGZ",
    "Longitude": "-82.9092",
    "ICAO": "CYGZ",
    "Airport ID": "5498",
    "Latitude": "76.4261",
    "Timezone": "146",
    "DST": "-5",
    "destinations": ["128"]
}, {
    "City": "Quaqtaq",
    "DBTZ": "A",
    "Name": "Quaqtaq Airport",
    "Country": "Canada",
    "IATA/FAA": "YQC",
    "Longitude": "-69.6178",
    "ICAO": "CYHA",
    "Airport ID": "5499",
    "Latitude": "61.0464",
    "Timezone": "103",
    "DST": "-5",
    "destinations": ["5481", "7253"]
}, {
    "City": "Penang",
    "DBTZ": "N",
    "Name": "Penang Intl",
    "Country": "Malaysia",
    "IATA/FAA": "PEN",
    "Longitude": "100.276864",
    "ICAO": "WMKP",
    "Airport ID": "3308",
    "Latitude": "5.297139",
    "Timezone": "11",
    "DST": "8",
    "destinations": ["3316", "3304", "3269", "3303", "3263", "3305", "3266", "2276", "3077", "3370", "3157", "3294", "3179", "3298", "3299", "3408", "3177", "3885", "3275", "3928"]
}, {
    "City": "Igloolik",
    "DBTZ": "A",
    "Name": "Igloolik Airport",
    "Country": "Canada",
    "IATA/FAA": "YGT",
    "Longitude": "-81.8161",
    "ICAO": "CYGT",
    "Airport ID": "5495",
    "Latitude": "69.3647",
    "Timezone": "174",
    "DST": "-5",
    "destinations": ["55", "75", "148"]
}, {
    "City": "Kuujjuarapik",
    "DBTZ": "A",
    "Name": "Kuujjuarapik Airport",
    "Country": "Canada",
    "IATA/FAA": "YGW",
    "Longitude": "-77.7653",
    "ICAO": "CYGW",
    "Airport ID": "5496",
    "Latitude": "55.2819",
    "Timezone": "34",
    "DST": "-5",
    "destinations": ["62", "5520", "5529", "5515", "146", "149", "5472"]
}, {
    "City": "Gillam",
    "DBTZ": "A",
    "Name": "Gillam Airport",
    "Country": "Canada",
    "IATA/FAA": "YGX",
    "Longitude": "-94.7106",
    "ICAO": "CYGX",
    "Airport ID": "5497",
    "Latitude": "56.3575",
    "Timezone": "476",
    "DST": "-6",
    "destinations": ["141", "160"]
}, {
    "City": "Ipoh",
    "DBTZ": "N",
    "Name": "Sultan Azlan Shah",
    "Country": "Malaysia",
    "IATA/FAA": "IPH",
    "Longitude": "101.092194",
    "ICAO": "WMKI",
    "Airport ID": "3302",
    "Latitude": "4.567972",
    "Timezone": "130",
    "DST": "8",
    "destinations": ["3316"]
}, {
    "City": "Johor Bahru",
    "DBTZ": "N",
    "Name": "Sultan Ismail",
    "Country": "Malaysia",
    "IATA/FAA": "JHB",
    "Longitude": "103.669619",
    "ICAO": "WMKJ",
    "Airport ID": "3303",
    "Latitude": "1.641308",
    "Timezone": "135",
    "DST": "8",
    "destinations": ["3269", "3298", "3263", "3304", "3266", "3308", "3267", "3271", "3408", "3928", "3896", "3275"]
}, {
    "City": "Kerteh",
    "DBTZ": "N",
    "Name": "Kerteh",
    "Country": "Malaysia",
    "IATA/FAA": "KTE",
    "Longitude": "103.426756",
    "ICAO": "WMKE",
    "Airport ID": "3300",
    "Latitude": "4.537222",
    "Timezone": "18",
    "DST": "8",
    "destinations": ["3408"]
}, {
    "City": "Cape Town",
    "DBTZ": "U",
    "Name": "Cape Town Intl",
    "Country": "South Africa",
    "IATA/FAA": "CPT",
    "Longitude": "18.601667",
    "ICAO": "FACT",
    "Airport ID": "797",
    "Latitude": "-33.964806",
    "Timezone": "151",
    "DST": "2",
    "destinations": ["507", "580", "799", "813", "838", "881", "880", "951", "2188", "794", "820", "893", "2241", "800", "804", "811", "815", "979", "5604", "865", "4105", "6778"]
}, {
    "City": "Guriat",
    "DBTZ": "U",
    "Name": "Guriat",
    "Country": "Saudi Arabia",
    "IATA/FAA": "URY",
    "Longitude": "37.279469",
    "ICAO": "OEGT",
    "Airport ID": "2068",
    "Latitude": "31.411942",
    "Timezone": "1672",
    "DST": "3",
    "destinations": ["2072", "2082"]
}, {
    "City": "Bloemfontein",
    "DBTZ": "U",
    "Name": "Bloemfontein Intl",
    "Country": "South Africa",
    "IATA/FAA": "BFN",
    "Longitude": "26.302444",
    "ICAO": "FABL",
    "Airport ID": "794",
    "Latitude": "-29.092722",
    "Timezone": "4458",
    "DST": "2",
    "destinations": ["797", "799", "813"]
}, {
    "City": "Dalaman",
    "DBTZ": "E",
    "Name": "Dalaman",
    "Country": "Turkey",
    "IATA/FAA": "DLM",
    "Longitude": "28.7925",
    "ICAO": "LTBS",
    "Airport ID": "1715",
    "Latitude": "36.713056",
    "Timezone": "20",
    "DST": "2",
    "destinations": ["1701", "345", "340", "352", "342", "346", "350", "337", "580", "591", "514", "523", "534", "517", "478", "521", "1613", "4317", "465", "469", "490", "502", "488", "492", "548", "535", "4029", "2988"]
}, {
    "City": "Hail",
    "DBTZ": "U",
    "Name": "Hail",
    "Country": "Saudi Arabia",
    "IATA/FAA": "HAS",
    "Longitude": "41.686292",
    "ICAO": "OEHL",
    "Airport ID": "2069",
    "Latitude": "27.437917",
    "Timezone": "3331",
    "DST": "3",
    "destinations": ["2188", "2191", "2064", "2072", "2082"]
}, {
    "City": "Imphal",
    "DBTZ": "N",
    "Name": "Imphal",
    "Country": "India",
    "IATA/FAA": "IMF",
    "Longitude": "93.896697",
    "ICAO": "VEIM",
    "Airport ID": "3050",
    "Latitude": "24.75995",
    "Timezone": "2540",
    "DST": "5.5",
    "destinations": ["3043", "6173", "3038", "3039", "3055"]
}, {
    "City": "Durban",
    "DBTZ": "U",
    "Name": "Durban Intl",
    "Country": "South Africa",
    "IATA/FAA": "DUR",
    "Longitude": "30.950519",
    "ICAO": "FADN",
    "Airport ID": "799",
    "Latitude": "-29.970089",
    "Timezone": "33",
    "DST": "2",
    "destinations": ["797", "813", "838", "2188", "893", "820", "794", "800", "804", "1005", "907", "979", "5604"]
}, {
    "City": "State College Pennsylvania",
    "DBTZ": "A",
    "Name": "University Park Airport",
    "Country": "United States",
    "IATA/FAA": "SCE",
    "Longitude": "-77.848694",
    "ICAO": "KUNV",
    "Airport ID": "4318",
    "Latitude": "40.849278",
    "Timezone": "1239",
    "DST": "-5",
    "destinations": ["3752", "3645", "3714", "3830"]
}, {
    "City": "Arkhangelsk",
    "DBTZ": "N",
    "Name": "Talagi",
    "Country": "Russia",
    "IATA/FAA": "ARH",
    "Longitude": "40.430167",
    "ICAO": "ULAA",
    "Airport ID": "4362",
    "Latitude": "64.360281",
    "Timezone": "19",
    "DST": "4",
    "destinations": ["6110", "2948", "2949", "4371", "2985", "4369"]
}, {
    "City": "Eldoret",
    "DBTZ": "U",
    "Name": "Eldoret Intl",
    "Country": "Kenya",
    "IATA/FAA": "EDL",
    "Longitude": "35.238928",
    "ICAO": "HKEL",
    "Airport ID": "1138",
    "Latitude": "0.404458",
    "Timezone": "6941",
    "DST": "3",
    "destinations": ["1143", "4059"]
}, {
    "City": "Luxor",
    "DBTZ": "U",
    "Name": "Luxor Intl",
    "Country": "Egypt",
    "IATA/FAA": "LXR",
    "Longitude": "32.706583",
    "ICAO": "HELX",
    "Airport ID": "1132",
    "Latitude": "25.671028",
    "Timezone": "294",
    "DST": "2",
    "destinations": ["2176", "1128", "1382", "2072", "507", "2241", "2082"]
}, {
    "City": "Hurghada",
    "DBTZ": "U",
    "Name": "Hurghada Intl",
    "Country": "Egypt",
    "IATA/FAA": "HRG",
    "Longitude": "33.799436",
    "ICAO": "HEGN",
    "Airport ID": "1130",
    "Latitude": "27.178317",
    "Timezone": "52",
    "DST": "2",
    "destinations": ["4053", "1678", "344", "338", "345", "348", "347", "351", "340", "342", "346", "1612", "1613", "1128", "4057", "3410", "1701", "502", "478", "1665", "4029", "4078", "2985", "2975", "2988", "469"]
}, {
    "City": "Aswan",
    "DBTZ": "U",
    "Name": "Aswan Intl",
    "Country": "Egypt",
    "IATA/FAA": "ASW",
    "Longitude": "32.819975",
    "ICAO": "HESN",
    "Airport ID": "1136",
    "Latitude": "23.964356",
    "Timezone": "662",
    "DST": "2",
    "destinations": ["1128", "1127", "2072"]
}, {
    "City": "Sainte Marie",
    "DBTZ": "U",
    "Name": "Sainte Marie",
    "Country": "Madagascar",
    "IATA/FAA": "SMS",
    "Longitude": "49.815834",
    "ICAO": "FMMS",
    "Airport ID": "920",
    "Latitude": "-17.093889",
    "Timezone": "7",
    "DST": "3",
    "destinations": ["916", "921", "918"]
}, {
    "City": "Toamasina",
    "DBTZ": "U",
    "Name": "Toamasina",
    "Country": "Madagascar",
    "IATA/FAA": "TMM",
    "Longitude": "49.392536",
    "ICAO": "FMMT",
    "Airport ID": "921",
    "Latitude": "-18.109517",
    "Timezone": "22",
    "DST": "3",
    "destinations": ["927", "916", "920", "918", "932", "924"]
}, {
    "City": "Port Lincoln",
    "DBTZ": "O",
    "Name": "Port Lincoln Airport",
    "Country": "Australia",
    "IATA/FAA": "PLO",
    "Longitude": "135.88",
    "ICAO": "YPLC",
    "Airport ID": "6318",
    "Latitude": "-34.6053",
    "Timezone": "36",
    "DST": "9.5",
    "destinations": ["3341"]
}, {
    "City": "Dera Ismael Khan",
    "DBTZ": "N",
    "Name": "Dera Ismael Khan Airport",
    "Country": "Pakistan",
    "IATA/FAA": "DSK",
    "Longitude": "70.896639",
    "ICAO": "OPDI",
    "Airport ID": "5943",
    "Latitude": "31.909422",
    "Timezone": "594",
    "DST": "5",
    "destinations": ["2219", "2233"]
}, {
    "City": "Porbandar",
    "DBTZ": "N",
    "Name": "Porbandar",
    "Country": "India",
    "IATA/FAA": "PBD",
    "Longitude": "69.657219",
    "ICAO": "VAPR",
    "Airport ID": "3018",
    "Latitude": "21.648675",
    "Timezone": "23",
    "DST": "5.5",
    "destinations": ["2997"]
}, {
    "City": "Rajkot",
    "DBTZ": "N",
    "Name": "Rajkot",
    "Country": "India",
    "IATA/FAA": "RAJ",
    "Longitude": "70.779525",
    "ICAO": "VARK",
    "Airport ID": "3019",
    "Latitude": "22.309183",
    "Timezone": "441",
    "DST": "5.5",
    "destinations": ["2997"]
}, {
    "City": "Hydaburg",
    "DBTZ": "A",
    "Name": "Hydaburg Seaplane Base",
    "Country": "United States",
    "IATA/FAA": "HYG",
    "Longitude": "-132.828333",
    "ICAO": "PAHY",
    "Airport ID": "7148",
    "Latitude": "55.206389",
    "Timezone": "0",
    "DST": "-9",
    "destinations": ["3808"]
}, {
    "City": "Chitral",
    "DBTZ": "N",
    "Name": "Chitral Airport",
    "Country": "Pakistan",
    "IATA/FAA": "CJL",
    "Longitude": "71.800578",
    "ICAO": "OPCH",
    "Airport ID": "5940",
    "Latitude": "35.886592",
    "Timezone": "4920",
    "DST": "5",
    "destinations": ["2223", "2219"]
}, {
    "City": "Montpellier",
    "DBTZ": "E",
    "Name": "Mediterranee",
    "Country": "France",
    "IATA/FAA": "MPL",
    "Longitude": "3.963014",
    "ICAO": "LFMT",
    "Airport ID": "1359",
    "Latitude": "43.576194",
    "Timezone": "17",
    "DST": "1",
    "destinations": ["1074", "1066", "5673", "345", "1399", "1335", "1418", "1435", "1382", "1555", "1386", "304", "355", "517", "591", "340", "4053", "502", "492", "1324"]
}, {
    "City": "Craig",
    "DBTZ": "A",
    "Name": "Craig Seaplane Base",
    "Country": "United States",
    "IATA/FAA": "CGA",
    "Longitude": "-133.147778",
    "ICAO": "",
    "Airport ID": "7144",
    "Latitude": "55.478889",
    "Timezone": "0",
    "DST": "-9",
    "destinations": ["7145", "3808", "7205"]
}, {
    "City": "Hollis",
    "DBTZ": "A",
    "Name": "Hollis Seaplane Base",
    "Country": "United States",
    "IATA/FAA": "HYL",
    "Longitude": "-132.646111",
    "ICAO": "",
    "Airport ID": "7145",
    "Latitude": "55.481667",
    "Timezone": "0",
    "DST": "-9",
    "destinations": ["7144", "7147", "3808"]
}, {
    "City": "Metakatla",
    "DBTZ": "A",
    "Name": "Metlakatla Seaplane Base",
    "Country": "United States",
    "IATA/FAA": "MTM",
    "Longitude": "-131.578056",
    "ICAO": "PAMM",
    "Airport ID": "7146",
    "Latitude": "55.131111",
    "Timezone": "0",
    "DST": "-9",
    "destinations": ["3808"]
}, {
    "City": "Thorne Bay",
    "DBTZ": "A",
    "Name": "Thorne Bay Seaplane Base",
    "Country": "United States",
    "IATA/FAA": "KTB",
    "Longitude": "-132.536667",
    "ICAO": "",
    "Airport ID": "7147",
    "Latitude": "55.688056",
    "Timezone": "0",
    "DST": "-9",
    "destinations": ["7145", "3808", "7144"]
}, {
    "City": "Excursion Inlet",
    "DBTZ": "A",
    "Name": "Excursion Inlet Seaplane Base",
    "Country": "United States",
    "IATA/FAA": "EXI",
    "Longitude": "-135.449167",
    "ICAO": "",
    "Airport ID": "7141",
    "Latitude": "58.420556",
    "Timezone": "0",
    "DST": "-9",
    "destinations": ["3492"]
}, {
    "City": "Hoonah",
    "DBTZ": "A",
    "Name": "Hoonah Airport",
    "Country": "United States",
    "IATA/FAA": "HNH",
    "Longitude": "-135.409722",
    "ICAO": "PAOH",
    "Airport ID": "7142",
    "Latitude": "58.096111",
    "Timezone": "19",
    "DST": "-9",
    "destinations": ["3492", "5960"]
}, {
    "City": "Nagpur",
    "DBTZ": "N",
    "Name": "Dr Ambedkar Intl",
    "Country": "India",
    "IATA/FAA": "NAG",
    "Longitude": "79.047183",
    "ICAO": "VANP",
    "Airport ID": "3015",
    "Latitude": "21.092192",
    "Timezone": "1033",
    "DST": "5.5",
    "destinations": ["3131", "2997", "3043", "3093", "3008", "3017", "3020", "2191"]
}, {
    "City": "Hawarden",
    "DBTZ": "E",
    "Name": "Hawarden",
    "Country": "United Kingdom",
    "IATA/FAA": "CEG",
    "Longitude": "-2.977778",
    "ICAO": "EGNR",
    "Airport ID": "519",
    "Latitude": "53.178056",
    "Timezone": "45",
    "DST": "0",
    "destinations": ["490", "1273"]
}, {
    "City": "Harare",
    "DBTZ": "U",
    "Name": "Harare Intl",
    "Country": "Zimbabwe",
    "IATA/FAA": "HRE",
    "Longitude": "31.092847",
    "ICAO": "FVHA",
    "Airport ID": "1005",
    "Latitude": "-17.931806",
    "Timezone": "4887",
    "DST": "2",
    "destinations": ["907", "4059", "813", "881", "1107", "1016", "1177", "799", "4105", "1001", "1004"]
}, {
    "City": "Victoria Falls",
    "DBTZ": "U",
    "Name": "Victoria Falls Intl",
    "Country": "Zimbabwe",
    "IATA/FAA": "VFA",
    "Longitude": "25.839006",
    "ICAO": "FVFA",
    "Airport ID": "1004",
    "Latitude": "-18.095881",
    "Timezone": "3490",
    "DST": "2",
    "destinations": ["813", "880", "4105", "1001", "1005"]
}, {
    "City": "Humberside",
    "DBTZ": "E",
    "Name": "Humberside",
    "Country": "United Kingdom",
    "IATA/FAA": "HUY",
    "Longitude": "-0.350833",
    "ICAO": "EGNJ",
    "Airport ID": "515",
    "Latitude": "53.574444",
    "Timezone": "121",
    "DST": "0",
    "destinations": ["580", "499", "532", "547"]
}, {
    "City": "Blackpool",
    "DBTZ": "E",
    "Name": "Blackpool",
    "Country": "United Kingdom",
    "IATA/FAA": "BLK",
    "Longitude": "-3.028611",
    "ICAO": "EGNH",
    "Airport ID": "514",
    "Latitude": "53.771667",
    "Timezone": "34",
    "DST": "0",
    "destinations": ["599", "1055", "1230", "1212", "1715", "1626", "3998", "1056", "520"]
}, {
    "City": "Leeds",
    "DBTZ": "E",
    "Name": "Leeds Bradford",
    "Country": "United Kingdom",
    "IATA/FAA": "LBA",
    "Longitude": "-1.660569",
    "ICAO": "EGNM",
    "Airport ID": "517",
    "Latitude": "53.865897",
    "Timezone": "681",
    "DST": "0",
    "destinations": ["507", "580", "467", "534", "1055", "1230", "1212", "1525", "1460", "1474", "599", "1626", "1051", "668", "1222", "1225", "1458", "669", "1270", "1227", "1606", "1359", "3998", "1562", "1056", "1539", "1218", "4315", "1489", "1382", "1200", "1715", "345", "1555", "4091", "1452", "499", "1197", "1054", "1231", "1354", "1198", "1587", "1472", "1551", "1488", "2223", "609", "532", "495", "1688"]
}, {
    "City": "Murcia",
    "DBTZ": "E",
    "Name": "Murcia San Javier",
    "Country": "Spain",
    "IATA/FAA": "MJV",
    "Longitude": "-0.812389",
    "ICAO": "LELC",
    "Airport ID": "1227",
    "Latitude": "37.774972",
    "Timezone": "11",
    "DST": "1",
    "destinations": ["644", "469", "494", "599", "585", "523", "517", "492", "478", "537", "548", "1229", "465", "535", "521", "490", "502"]
}, {
    "City": "Jerez",
    "DBTZ": "E",
    "Name": "Jerez",
    "Country": "Spain",
    "IATA/FAA": "XRY",
    "Longitude": "-6.060111",
    "ICAO": "LEJR",
    "Airport ID": "1226",
    "Latitude": "36.744622",
    "Timezone": "93",
    "DST": "1",
    "destinations": ["344", "3998", "345", "340", "342", "346", "350", "337", "1218", "355", "548", "1229", "1230", "629", "352"]
}, {
    "City": "Ibiza",
    "DBTZ": "E",
    "Name": "Ibiza",
    "Country": "Spain",
    "IATA/FAA": "IBZ",
    "Longitude": "1.373117",
    "ICAO": "LEIB",
    "Airport ID": "1225",
    "Latitude": "38.872858",
    "Timezone": "20",
    "DST": "1",
    "destinations": ["344", "345", "350", "351", "4053", "382", "341", "340", "352", "342", "346", "347", "371", "3998", "1678", "1229", "503", "507", "478", "502", "599", "1230", "1218", "1525", "469", "1538", "494", "490", "1553", "304", "585", "523", "1222", "355", "517", "491", "1353", "4198", "537", "1562", "548", "1246", "302", "1231", "580", "591", "1212", "1216", "1386", "629", "534", "521", "353", "1676", "492", "1665", "1524", "508"]
}, {
    "City": "Granada",
    "DBTZ": "E",
    "Name": "Granada",
    "Country": "Spain",
    "IATA/FAA": "GRX",
    "Longitude": "-3.777356",
    "ICAO": "LEGR",
    "Airport ID": "1223",
    "Latitude": "37.188731",
    "Timezone": "1860",
    "DST": "1",
    "destinations": ["503", "1218", "1229", "1058", "3998"]
}, {
    "City": "Gerona",
    "DBTZ": "E",
    "Name": "Girona",
    "Country": "Spain",
    "IATA/FAA": "GRO",
    "Longitude": "2.760547",
    "ICAO": "LEGE",
    "Airport ID": "1222",
    "Latitude": "41.900969",
    "Timezone": "468",
    "DST": "1",
    "destinations": ["1517", "1506", "1525", "608", "494", "353", "490", "1745", "1367", "1519", "344", "304", "373", "599", "585", "523", "4166", "3986", "341", "586", "355", "1225", "669", "517", "441", "492", "478", "1606", "582", "521", "4198", "3998", "674", "1562", "1505", "1075", "1072", "726", "1486", "548", "415", "458", "1515", "680", "302", "580", "591", "2944", "2945"]
}, {
    "City": "Vagar",
    "DBTZ": "E",
    "Name": "Vagar",
    "Country": "Faroe Islands",
    "IATA/FAA": "FAE",
    "Longitude": "-7.277219",
    "ICAO": "EKVG",
    "Airport ID": "625",
    "Latitude": "62.063628",
    "Timezone": "280",
    "DST": "0",
    "destinations": ["636", "608", "609", "666"]
}, {
    "City": "La Coruna",
    "DBTZ": "E",
    "Name": "A Coruna",
    "Country": "Spain",
    "IATA/FAA": "LCG",
    "Longitude": "-8.377256",
    "ICAO": "LECO",
    "Airport ID": "1220",
    "Latitude": "43.302061",
    "Timezone": "326",
    "DST": "1",
    "destinations": ["1229", "1218", "1216", "507", "1253", "1638"]
}, {
    "City": "Luxemburg",
    "DBTZ": "E",
    "Name": "Luxembourg",
    "Country": "Luxembourg",
    "IATA/FAA": "LUX",
    "Longitude": "6.211517",
    "ICAO": "ELLX",
    "Airport ID": "629",
    "Latitude": "49.626575",
    "Timezone": "1234",
    "DST": "1",
    "destinations": ["507", "1382", "1555", "1524", "345", "1218", "580", "1055", "1706", "1064", "1230", "1324", "737", "1688", "1321", "1103", "1519", "1460", "1322", "609", "1509", "1200", "293", "599", "1626", "4091", "340", "1323", "1051", "1665", "1452", "1225", "1458", "503", "1638", "1054", "1229", "1606", "346", "1561", "1354", "1636", "3998", "1075", "1472", "349", "1056", "351", "1196", "1613", "1226", "1678", "1701", "502"]
}, {
    "City": "Aalborg",
    "DBTZ": "E",
    "Name": "Aalborg",
    "Country": "Denmark",
    "IATA/FAA": "AAL",
    "Longitude": "9.849164",
    "ICAO": "EKYT",
    "Airport ID": "628",
    "Latitude": "57.092789",
    "Timezone": "10",
    "DST": "1",
    "destinations": ["580", "607", "644", "608", "666", "1230", "1212", "609", "502", "3998", "1218", "737", "1701"]
}, {
    "City": "Madrid",
    "DBTZ": "E",
    "Name": "Barajas",
    "Country": "Spain",
    "IATA/FAA": "MAD",
    "Longitude": "-3.566764",
    "ICAO": "LEMD",
    "Airport ID": "1229",
    "Latitude": "40.493556",
    "Timezone": "2000",
    "DST": "1",
    "destinations": ["345", "342", "3941", "3448", "3670", "3797", "3484", "3576", "3830", "3752", "340", "1665", "346", "351", "1613", "1678", "3682", "1382", "1386", "210", "302", "1852", "1824", "3988", "1074", "1075", "1080", "2709", "2715", "2745", "421", "1250", "1246", "1555", "1051", "1225", "1220", "1054", "3998", "1057", "503", "507", "2564", "3364", "1909", "1905", "2241", "609", "1760", "1218", "599", "2188", "1194", "1055", "1517", "1525", "1538", "1489", "1367", "1519", "1553", "304", "1509", "669", "478", "1606", "1353", "1636", "1512", "1562", "1072", "657", "1243", "548", "1056", "585", "591", "248", "1230", "1212", "2170", "737", "1216", "1264", "2851", "1084", "4029", "1242", "1563", "2560", "1223", "1767", "2673", "2939", "951", "1276", "1213", "2789", "1529", "1638", "273", "1335", "1231", "1227", "1058", "1524", "1354", "1418", "231", "1214", "1234", "1587", "1871", "5781", "2650", "1251", "1885", "1053", "891", "1253", "1435", "1273", "1590", "1526", "2688", "1551", "1226", "3930", "580", "629", "3494", "1157", "679", "1128", "2771", "4317", "1657", "2985", "2072", "3885", "1701", "293", "287", "490", "4053", "535", "502", "491", "492", "337", "2988", "1219", "1759", "2816", "1762", "2621", "1652", "1663"]
}, {
    "City": "Bissau",
    "DBTZ": "N",
    "Name": "Osvaldo Vieira International Airport",
    "Country": "Guinea-Bissau",
    "IATA/FAA": "OXB",
    "Longitude": "-15.653681",
    "ICAO": "GGOV",
    "Airport ID": "5665",
    "Latitude": "11.89485",
    "Timezone": "129",
    "DST": "0",
    "destinations": ["4162", "1084", "1050", "1074", "5674"]
}, {
    "City": "Springfield",
    "DBTZ": "A",
    "Name": "Springfield Branson Natl",
    "Country": "United States",
    "IATA/FAA": "SGF",
    "Longitude": "-93.388639",
    "ICAO": "KSGF",
    "Airport ID": "4348",
    "Latitude": "37.245667",
    "Timezone": "1268",
    "DST": "-6",
    "destinations": ["3670", "3830", "3682", "6505", "3877", "7056", "3617", "4167", "3751"]
}, {
    "City": "ST MARY\\\\'S",
    "DBTZ": "E",
    "Name": "ISLES OF SCILLY",
    "Country": "United Kingdom",
    "IATA/FAA": "ISC",
    "Longitude": "-6.3075",
    "ICAO": "",
    "Airport ID": "4347",
    "Latitude": "49.919",
    "Timezone": "70",
    "DST": "0",
    "destinations": ["552", "5572", "481"]
}, {
    "City": "Orsta-Volda",
    "DBTZ": "E",
    "Name": "Hovden",
    "Country": "Norway",
    "IATA/FAA": "HOV",
    "Longitude": "6.0742",
    "ICAO": "ENOV",
    "Airport ID": "4345",
    "Latitude": "62.18",
    "Timezone": "242",
    "DST": "1",
    "destinations": ["636", "643", "644", "5586"]
}, {
    "City": "Cody",
    "DBTZ": "A",
    "Name": "Yellowstone Rgnl",
    "Country": "United States",
    "IATA/FAA": "COD",
    "Longitude": "-109.023806",
    "ICAO": "KCOD",
    "Airport ID": "4344",
    "Latitude": "44.520194",
    "Timezone": "5102",
    "DST": "-7",
    "destinations": ["3536", "3751"]
}, {
    "City": "Liberal",
    "DBTZ": "A",
    "Name": "Liberal Muni",
    "Country": "United States",
    "IATA/FAA": "LBL",
    "Longitude": "-100.95986",
    "ICAO": "KLBL",
    "Airport ID": "4341",
    "Latitude": "37.044222",
    "Timezone": "2885",
    "DST": "-6",
    "destinations": ["5733", "3751"]
}, {
    "City": "Point Salines",
    "DBTZ": "U",
    "Name": "Point Salines Intl",
    "Country": "Grenada",
    "IATA/FAA": "GND",
    "Longitude": "-61.786192",
    "ICAO": "TGPY",
    "Airport ID": "2882",
    "Latitude": "12.004247",
    "Timezone": "41",
    "DST": "-4",
    "destinations": ["3576", "193", "2894", "3797", "2902", "2875", "2907", "2850"]
}, {
    "City": "St. Thomas",
    "DBTZ": "U",
    "Name": "Cyril E King",
    "Country": "Virgin Islands",
    "IATA/FAA": "STT",
    "Longitude": "-64.973361",
    "ICAO": "TIST",
    "Airport ID": "2883",
    "Latitude": "18.337306",
    "Timezone": "23",
    "DST": "-4",
    "destinations": ["2903", "2890", "2884", "3876", "3797", "3576", "3752", "3682", "2900", "2899", "3533", "6460", "3494", "3714", "3830", "2877", "6079", "6080"]
}, {
    "City": "Pointe-a-pitre",
    "DBTZ": "U",
    "Name": "Le Raizet",
    "Country": "Guadeloupe",
    "IATA/FAA": "PTP",
    "Longitude": "-61.531806",
    "ICAO": "TFFR",
    "Airport ID": "2881",
    "Latitude": "16.265306",
    "Timezone": "36",
    "DST": "-4",
    "destinations": ["2874", "2877", "2878", "6460", "1762", "2879", "2899", "3576", "2890", "146", "1386", "1897"]
}, {
    "City": "St. Croix Island",
    "DBTZ": "U",
    "Name": "Henry E Rohlsen",
    "Country": "Virgin Islands",
    "IATA/FAA": "STX",
    "Longitude": "-64.798556",
    "ICAO": "TISX",
    "Airport ID": "2884",
    "Latitude": "17.701889",
    "Timezone": "74",
    "DST": "-4",
    "destinations": ["2890", "2883", "6788", "3576", "2899"]
}, {
    "City": "Aguadilla",
    "DBTZ": "U",
    "Name": "Rafael Hernandez",
    "Country": "Puerto Rico",
    "IATA/FAA": "BQN",
    "Longitude": "-67.129444",
    "ICAO": "TJBQ",
    "Airport ID": "2885",
    "Latitude": "18.494861",
    "Timezone": "237",
    "DST": "-4",
    "destinations": ["3797", "3878", "3533", "3494"]
}, {
    "City": "Mayaguez",
    "DBTZ": "U",
    "Name": "Eugenio Maria De Hostos",
    "Country": "Puerto Rico",
    "IATA/FAA": "MAZ",
    "Longitude": "-67.148472",
    "ICAO": "TJMZ",
    "Airport ID": "2888",
    "Latitude": "18.255694",
    "Timezone": "28",
    "DST": "-4",
    "destinations": ["2890"]
}, {
    "City": "Ponce",
    "DBTZ": "U",
    "Name": "Mercedita",
    "Country": "Puerto Rico",
    "IATA/FAA": "PSE",
    "Longitude": "-66.563028",
    "ICAO": "TJPS",
    "Airport ID": "2889",
    "Latitude": "18.008306",
    "Timezone": "29",
    "DST": "-4",
    "destinations": ["3797", "3878"]
}, {
    "City": "Aberdeen",
    "DBTZ": "A",
    "Name": "Aberdeen Regional Airport",
    "Country": "United States",
    "IATA/FAA": "ABR",
    "Longitude": "-98.4218",
    "ICAO": "KABR",
    "Airport ID": "5714",
    "Latitude": "45.4491",
    "Timezone": "1302",
    "DST": "-6",
    "destinations": ["3858"]
}, {
    "City": "Madang",
    "DBTZ": "U",
    "Name": "Madang",
    "Country": "Papua New Guinea",
    "IATA/FAA": "MAG",
    "Longitude": "145.7887",
    "ICAO": "AYMD",
    "Airport ID": "2",
    "Latitude": "-5.207083",
    "Timezone": "20",
    "DST": "10",
    "destinations": ["1", "3", "4", "6", "5430", "5", "5436"]
}, {
    "City": "Orlando",
    "DBTZ": "A",
    "Name": "Orlando Intl",
    "Country": "United States",
    "IATA/FAA": "MCO",
    "Longitude": "-81.308994",
    "ICAO": "KMCO",
    "Airport ID": "3878",
    "Latitude": "28.429394",
    "Timezone": "96",
    "DST": "-5",
    "destinations": ["3533", "3472", "3754", "1930", "3564", "3793", "3698", "3876", "3520", "3670", "2564", "3797", "3484", "502", "3576", "3830", "3752", "3462", "3731", "3577", "193", "146", "3682", "1852", "1824", "2709", "1892", "3673", "3825", "3448", "2885", "3820", "3494", "3589", "3697", "1780", "1953", "6426", "2889", "3641", "3608", "1762", "1885", "2890", "3661", "3745", "1779", "2902", "1871", "534", "478", "3811", "3690", "3759", "3488", "3645", "3685", "3585", "3877", "3458", "3473", "3717", "3858", "3454", "3611", "3570", "3626", "3621", "4014", "3536", "599", "4037", "3751", "3859", "3542", "3447", "3864", "2895", "3849", "4112", "3566", "3857", "3580", "3747", "3861", "3678", "340", "3524", "3550", "5747", "73", "117", "189", "3486", "3714", "3469", "3627", "3875", "4089", "4011", "3622", "178"]
}, {
    "City": "Florence",
    "DBTZ": "A",
    "Name": "Florence Rgnl",
    "Country": "United States",
    "IATA/FAA": "FLO",
    "Longitude": "-79.723889",
    "ICAO": "KFLO",
    "Airport ID": "3879",
    "Latitude": "34.185361",
    "Timezone": "146",
    "DST": "-5",
    "destinations": ["3876"]
}, {
    "City": "Kuala Terengganu",
    "DBTZ": "N",
    "Name": "Sultan Mahmud",
    "Country": "Malaysia",
    "IATA/FAA": "TGG",
    "Longitude": "103.10336",
    "ICAO": "WMKN",
    "Airport ID": "3307",
    "Latitude": "5.382639",
    "Timezone": "21",
    "DST": "8",
    "destinations": ["3304", "3266", "3316", "3408"]
}, {
    "City": "Casper",
    "DBTZ": "A",
    "Name": "Natrona Co Intl",
    "Country": "United States",
    "IATA/FAA": "CPR",
    "Longitude": "-106.464417",
    "ICAO": "KCPR",
    "Airport ID": "3872",
    "Latitude": "42.908",
    "Timezone": "5347",
    "DST": "-7",
    "destinations": ["3536", "3877", "3751"]
}, {
    "City": "Valparaiso",
    "DBTZ": "A",
    "Name": "Eglin Afb",
    "Country": "United States",
    "IATA/FAA": "VPS",
    "Longitude": "-86.5254",
    "ICAO": "KVPS",
    "Airport ID": "3873",
    "Latitude": "30.48325",
    "Timezone": "87",
    "DST": "-6",
    "destinations": ["3876", "3520", "3670", "3682", "3550"]
}, {
    "City": "Key West",
    "DBTZ": "A",
    "Name": "Key West Intl",
    "Country": "United States",
    "IATA/FAA": "EYW",
    "Longitude": "-81.759556",
    "ICAO": "KEYW",
    "Airport ID": "3875",
    "Latitude": "24.556111",
    "Timezone": "3",
    "DST": "-5",
    "destinations": ["3533", "3793", "3646", "3576", "3682", "3697", "3878", "3861"]
}, {
    "City": "Charlotte",
    "DBTZ": "A",
    "Name": "Charlotte Douglas Intl",
    "Country": "United States",
    "IATA/FAA": "CLT",
    "Longitude": "-80.943139",
    "ICAO": "KCLT",
    "Airport ID": "3876",
    "Latitude": "35.214",
    "Timezone": "748",
    "DST": "-5",
    "destinations": ["2897", "4355", "3658", "3864", "2874", "3682", "2895", "3673", "4007", "4286", "3825", "3811", "3690", "3448", "3846", "3820", "3849", "1957", "3561", "4112", "1382", "3578", "4015", "3806", "3486", "3759", "4285", "1852", "3488", "1800", "3950", "3627", "3520", "3751", "3670", "3729", "3645", "599", "3730", "3494", "5738", "1555", "3533", "3879", "1948", "340", "1926", "3472", "4038", "2564", "4008", "4034", "7002", "3589", "4113", "5742", "3714", "3550", "3845", "3585", "3580", "3712", "3797", "3877", "3484", "4017", "3697", "507", "1881", "3660", "5753", "1780", "3458", "3878", "3542", "3473", "1824", "4115", "4011", "3576", "3717", "3671", "3782", "3858", "3861", "3515", "1953", "4386", "3454", "3830", "3611", "3722", "3720", "6959", "3633", "3752", "3462", "3570", "1754", "3564", "1760", "3641", "3862", "3626", "3608", "4016", "3622", "3793", "3731", "3621", "3613", "3642", "4014", "3577", "3469", "1840", "1885", "2890", "3536", "3817", "4067", "3678", "2883", "2899", "3745", "3698", "3646", "4116", "3676", "2894", "3873", "4356", "100", "146", "193", "3447", "3747", "346", "3681", "2560", "3566"]
}, {
    "City": "Las Vegas",
    "DBTZ": "A",
    "Name": "Mc Carran Intl",
    "Country": "United States",
    "IATA/FAA": "LAS",
    "Longitude": "-115.15225",
    "ICAO": "KLAS",
    "Airport ID": "3877",
    "Latitude": "36.080056",
    "Timezone": "2141",
    "DST": "-8",
    "destinations": ["3484", "1825", "1850", "3876", "3520", "3670", "3797", "502", "507", "3576", "3830", "3752", "3462", "49", "156", "178", "193", "146", "3682", "1824", "3774", "3777", "3720", "3577", "3448", "3582", "1871", "340", "478", "3488", "3645", "3930", "3878", "3473", "3858", "3626", "3536", "3751", "3673", "3644", "3849", "3566", "3585", "3747", "3717", "3453", "3641", "3807", "3731", "3621", "3817", "3867", "3646", "3636", "4047", "6505", "4021", "4083", "3495", "4020", "4043", "3819", "3872", "3598", "3729", "4099", "4292", "3687", "4127", "4009", "3442", "4063", "5740", "3685", "3880", "3728", "3457", "4100", "3691", "3826", "4101", "4072", "3498", "3948", "4216", "4046", "6133", "4087", "4028", "4359", "3805", "4348", "3609", "5772", "4356", "3469", "1678", "3533", "3550", "3486", "3494", "3714", "3839", "4019", "3864", "3840", "3825", "3811", "3690", "3820", "3759", "3559", "4089", "3467", "3712", "3829", "3660", "3652", "3458", "3861", "3863", "3454", "3734", "3570", "4014", "3748", "3678", "3855", "120", "160", "166", "184", "1804"]
}, {
    "City": "Nyingchi",
    "DBTZ": "U",
    "Name": "Nyingchi Airport",
    "Country": "China",
    "IATA/FAA": "LZY",
    "Longitude": "94.3353",
    "ICAO": "ZUNZ",
    "Airport ID": "6402",
    "Latitude": "29.3033",
    "Timezone": "9675",
    "DST": "8",
    "destinations": ["3395", "4097"]
}, {
    "City": "Thule",
    "DBTZ": "E",
    "Name": "Thule Air Base",
    "Country": "Greenland",
    "IATA/FAA": "THU",
    "Longitude": "-68.703161",
    "ICAO": "BGTL",
    "Airport ID": "10",
    "Latitude": "76.531203",
    "Timezone": "251",
    "DST": "-4",
    "destinations": ["5446"]
}, {
    "City": "Mianyang",
    "DBTZ": "U",
    "Name": "Mianyang Airport",
    "Country": "China",
    "IATA/FAA": "MIG",
    "Longitude": "104.741",
    "ICAO": "ZUMY",
    "Airport ID": "6400",
    "Latitude": "31.4281",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["4033", "3388", "4030", "6366", "3382", "3391", "4144", "3375", "3364", "3374", "3370", "3371", "3406", "3394", "3379", "4097"]
}, {
    "City": "Egilsstadir",
    "DBTZ": "N",
    "Name": "Egilsstadir",
    "Country": "Iceland",
    "IATA/FAA": "EGS",
    "Longitude": "-14.401389",
    "ICAO": "BIEG",
    "Airport ID": "12",
    "Latitude": "65.283333",
    "Timezone": "76",
    "DST": "0",
    "destinations": ["18"]
}, {
    "City": "Dickwella",
    "DBTZ": "U",
    "Name": "Dickwella Airport",
    "Country": "Sri Lanka",
    "IATA/FAA": "DIW",
    "Longitude": "80.683333",
    "ICAO": "",
    "Airport ID": "7562",
    "Latitude": "5.966667",
    "Timezone": "100",
    "DST": "5.5",
    "destinations": ["6169"]
}, {
    "City": "Kulyab",
    "DBTZ": "U",
    "Name": "Kulob Airport",
    "Country": "Tajikistan",
    "IATA/FAA": "TJU",
    "Longitude": "69.799444",
    "ICAO": "UTDK",
    "Airport ID": "7563",
    "Latitude": "37.981667",
    "Timezone": "2293",
    "DST": "5",
    "destinations": ["2914", "4029", "2975", "2948"]
}, {
    "City": "Aksu",
    "DBTZ": "U",
    "Name": "Aksu Airport",
    "Country": "China",
    "IATA/FAA": "AKU",
    "Longitude": "80.2917",
    "ICAO": "ZWAK",
    "Airport ID": "6404",
    "Latitude": "41.2625",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3395", "3399"]
}, {
    "City": "Keflavik",
    "DBTZ": "N",
    "Name": "Keflavik International Airport",
    "Country": "Iceland",
    "IATA/FAA": "KEF",
    "Longitude": "-22.605556",
    "ICAO": "BIKF",
    "Airport ID": "16",
    "Latitude": "63.985",
    "Timezone": "171",
    "DST": "0",
    "destinations": ["421", "636", "644", "580", "737", "608", "3448", "302", "1382", "609", "3751", "3494", "340", "534", "3714", "3797", "502", "507", "478", "346", "3577", "4167", "49", "193", "1678", "8", "490", "4053", "535", "492", "1212", "337"]
}, {
    "City": "Reykjavik",
    "DBTZ": "N",
    "Name": "Reykjavik",
    "Country": "Iceland",
    "IATA/FAA": "RKV",
    "Longitude": "-21.940556",
    "ICAO": "BIRK",
    "Airport ID": "18",
    "Latitude": "64.13",
    "Timezone": "48",
    "DST": "0",
    "destinations": ["11", "12", "8", "15", "4248"]
}, {
    "City": "Karamay",
    "DBTZ": "U",
    "Name": "Karamay Airport",
    "Country": "China",
    "IATA/FAA": "KRY",
    "Longitude": "84.883",
    "ICAO": "ZWKM",
    "Airport ID": "6408",
    "Latitude": "45.617",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3399"]
}, {
    "City": "Yining",
    "DBTZ": "U",
    "Name": "Yining Airport",
    "Country": "China",
    "IATA/FAA": "YIN",
    "Longitude": "81.3303",
    "ICAO": "ZWYN",
    "Airport ID": "6409",
    "Latitude": "43.9558",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3395", "3399"]
}, {
    "City": "Juiz de Fora",
    "DBTZ": "S",
    "Name": "Zona da Mata Regional Airport",
    "Country": "Brazil",
    "IATA/FAA": "IZA",
    "Longitude": "-43.1730575562",
    "ICAO": "SDZY",
    "Airport ID": "8180",
    "Latitude": "-21.5130558014",
    "Timezone": "1348",
    "DST": "-3",
    "destinations": ["2537", "2578"]
}, {
    "City": "Diu",
    "DBTZ": "N",
    "Name": "Diu Airport",
    "Country": "India",
    "IATA/FAA": "DIU",
    "Longitude": "70.9211",
    "ICAO": "VA1P",
    "Airport ID": "5711",
    "Latitude": "20.7131",
    "Timezone": "31",
    "DST": "5.5",
    "destinations": ["3018"]
}, {
    "City": "Jakarta",
    "DBTZ": "N",
    "Name": "Soekarno Hatta Intl",
    "Country": "Indonesia",
    "IATA/FAA": "CGK",
    "Longitude": "106.655897",
    "ICAO": "WIII",
    "Airport ID": "3275",
    "Latitude": "-6.125567",
    "Timezone": "34",
    "DST": "7",
    "destinations": ["2397", "3304", "3269", "2179", "3272", "2276", "3383", "3077", "3370", "3385", "2188", "3351", "3256", "3908", "3885", "3288", "3919", "3903", "3294", "3287", "3244", "3940", "2359", "3930", "2072", "3898", "3992", "8401", "3250", "3339", "3894", "2279", "3278", "3364", "3905", "3273", "3911", "3289", "3249", "3284", "3406", "3316", "3929", "3901", "3928", "3361", "3904", "6223", "3906", "3253", "3240", "3910", "3890", "3920", "3024", "1128", "2241", "3157", "3179", "3303", "3308", "2074", "2082", "3205"]
}, {
    "City": "Pekanbaru",
    "DBTZ": "N",
    "Name": "Sultan Syarif Kasim Ii",
    "Country": "Indonesia",
    "IATA/FAA": "PKU",
    "Longitude": "101.444539",
    "ICAO": "WIBB",
    "Airport ID": "3273",
    "Latitude": "0.460786",
    "Timezone": "102",
    "DST": "7",
    "destinations": ["3304", "3408", "3275", "3316", "3896", "3903", "3898"]
}, {
    "City": "Tanjung Pandan",
    "DBTZ": "N",
    "Name": "H As Hanandjoeddin",
    "Country": "Indonesia",
    "IATA/FAA": "TJQ",
    "Longitude": "107.754917",
    "ICAO": "WIOD",
    "Airport ID": "3904",
    "Latitude": "-2.745722",
    "Timezone": "164",
    "DST": "7",
    "destinations": ["3275", "3905"]
}, {
    "City": "Pangkal Pinang",
    "DBTZ": "N",
    "Name": "Depati Amir",
    "Country": "Indonesia",
    "IATA/FAA": "PGK",
    "Longitude": "106.139064",
    "ICAO": "WIPK",
    "Airport ID": "3905",
    "Latitude": "-2.1622",
    "Timezone": "109",
    "DST": "7",
    "destinations": ["3275", "3903", "3904", "3289"]
}, {
    "City": "Tanjung Pinang",
    "DBTZ": "N",
    "Name": "Kijang",
    "Country": "Indonesia",
    "IATA/FAA": "TNJ",
    "Longitude": "104.532311",
    "ICAO": "WIDN",
    "Airport ID": "3906",
    "Latitude": "0.922683",
    "Timezone": "52",
    "DST": "7",
    "destinations": ["3275"]
}, {
    "City": "Semarang",
    "DBTZ": "N",
    "Name": "Achmad Yani",
    "Country": "Indonesia",
    "IATA/FAA": "SRG",
    "Longitude": "110.374122",
    "ICAO": "WARS",
    "Airport ID": "3901",
    "Latitude": "-6.971447",
    "Timezone": "13",
    "DST": "7",
    "destinations": ["3304", "3275", "3940", "3316", "3928", "3910", "3908", "3919", "3903"]
}, {
    "City": "Batam",
    "DBTZ": "N",
    "Name": "Hang Nadim",
    "Country": "Indonesia",
    "IATA/FAA": "BTH",
    "Longitude": "104.118753",
    "ICAO": "WIDD",
    "Airport ID": "3903",
    "Latitude": "1.121028",
    "Timezone": "126",
    "DST": "7",
    "destinations": ["3408", "3275", "6223", "3896", "3288", "3919", "3287", "3940", "3898", "6225", "3278", "3905", "3273", "3289", "3284", "3901", "3928"]
}, {
    "City": "Tawau",
    "DBTZ": "N",
    "Name": "Tawau",
    "Country": "Malaysia",
    "IATA/FAA": "TWU",
    "Longitude": "118.121953",
    "ICAO": "WBKW",
    "Airport ID": "3271",
    "Latitude": "4.313369",
    "Timezone": "57",
    "DST": "8",
    "destinations": ["3269", "3303", "3304", "4141", "3920"]
}, {
    "City": "Banjarmasin",
    "DBTZ": "N",
    "Name": "Syamsudin Noor",
    "Country": "Indonesia",
    "IATA/FAA": "BDJ",
    "Longitude": "114.762553",
    "ICAO": "WAOO",
    "Airport ID": "3908",
    "Latitude": "-3.442356",
    "Timezone": "66",
    "DST": "8",
    "destinations": ["3275", "3928", "3910", "3896", "3919", "3898", "6231", "3901"]
}, {
    "City": "Wuzhou",
    "DBTZ": "U",
    "Name": "Changzhoudao Airport",
    "Country": "China",
    "IATA/FAA": "WUZ",
    "Longitude": "111.248",
    "ICAO": "ZGWZ",
    "Airport ID": "6744",
    "Latitude": "23.4567",
    "Timezone": "89",
    "DST": "8",
    "destinations": ["3394", "3373", "6355"]
}, {
    "City": "Gorgan",
    "DBTZ": "E",
    "Name": "Gorgan Airport",
    "Country": "Iran",
    "IATA/FAA": "GBT",
    "Longitude": "54.401339",
    "ICAO": "OING",
    "Airport ID": "6747",
    "Latitude": "36.909381",
    "Timezone": "-24",
    "DST": "3.5",
    "destinations": ["4331", "2131", "2167", "2074"]
}, {
    "City": "Tampico",
    "DBTZ": "S",
    "Name": "General Francisco Javier Mina Intl",
    "Country": "Mexico",
    "IATA/FAA": "TAM",
    "Longitude": "-97.865931",
    "ICAO": "MMTM",
    "Airport ID": "1848",
    "Latitude": "22.29645",
    "Timezone": "80",
    "DST": "-6",
    "destinations": ["1824", "1825", "1854", "3550", "1852"]
}, {
    "City": "Gorakhpur",
    "DBTZ": "N",
    "Name": "Gorakhpur Airport",
    "Country": "India",
    "IATA/FAA": "GOP",
    "Longitude": "83.449708",
    "ICAO": "VEGK",
    "Airport ID": "6741",
    "Latitude": "26.739708",
    "Timezone": "259",
    "DST": "5.5",
    "destinations": ["3093"]
}, {
    "City": "Araracuara",
    "DBTZ": "U",
    "Name": "Araracuara Airport",
    "Country": "Colombia",
    "IATA/FAA": "ACR",
    "Longitude": "-72.41",
    "ICAO": "SKAC",
    "Airport ID": "6740",
    "Latitude": "-0.58",
    "Timezone": "1250",
    "DST": "-5",
    "destinations": ["7356", "2750"]
}, {
    "City": "Hami",
    "DBTZ": "U",
    "Name": "Hami Airport",
    "Country": "China",
    "IATA/FAA": "HMI",
    "Longitude": "93.6692",
    "ICAO": "ZWHM",
    "Airport ID": "6743",
    "Latitude": "42.8414",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["6407", "3364", "3375", "3399", "3379", "3406"]
}, {
    "City": "Sand Point",
    "DBTZ": "A",
    "Name": "Sand Point Airport",
    "Country": "United States",
    "IATA/FAA": "SDP",
    "Longitude": "-160.523",
    "ICAO": "PASD",
    "Airport ID": "6742",
    "Latitude": "55.315",
    "Timezone": "21",
    "DST": "-9",
    "destinations": ["3774"]
}, {
    "City": "Parsabad",
    "DBTZ": "E",
    "Name": "Parsabade Moghan Airport",
    "Country": "Iran",
    "IATA/FAA": "PFQ",
    "Longitude": "47.8815",
    "ICAO": "OITP",
    "Airport ID": "6749",
    "Latitude": "39.603606",
    "Timezone": "251",
    "DST": "3.5",
    "destinations": ["2131"]
}, {
    "City": "Ilam",
    "DBTZ": "E",
    "Name": "Ilam Airport",
    "Country": "Iran",
    "IATA/FAA": "IIL",
    "Longitude": "46.404842",
    "ICAO": "OICI",
    "Airport ID": "6748",
    "Latitude": "33.586606",
    "Timezone": "4404",
    "DST": "3.5",
    "destinations": ["2131"]
}, {
    "City": "Sanford",
    "DBTZ": "A",
    "Name": "Orlando Sanford Intl",
    "Country": "United States",
    "IATA/FAA": "SFB",
    "Longitude": "-81.237489",
    "ICAO": "KSFB",
    "Airport ID": "4167",
    "Latitude": "28.777639",
    "Timezone": "55",
    "DST": "-5",
    "destinations": ["16", "4355", "4047", "4007", "4286", "3463", "4083", "3790", "4037", "3711", "3578", "4043", "5730", "3488", "3729", "3946", "4292", "4009", "4041", "3442", "3685", "4008", "4034", "6739", "5742", "3630", "3544", "3674", "4017", "3660", "3542", "4072", "3863", "5758", "3771", "3633", "5763", "4028", "4016", "4359", "4348", "4336", "4040", "4116", "3855", "3676", "4356", "3881", "3576"]
}, {
    "City": "Karlsruhe/Baden-Baden",
    "DBTZ": "E",
    "Name": "Baden Airpark",
    "Country": "Germany",
    "IATA/FAA": "FKB",
    "Longitude": "8.08048",
    "ICAO": "EDSB",
    "Airport ID": "4166",
    "Latitude": "48.7793",
    "Timezone": "407",
    "DST": "1",
    "destinations": ["342", "3998", "351", "1055", "1230", "1212", "1501", "1519", "1553", "1222", "1054", "1636", "1486", "548", "1056", "1515", "1209", "4029"]
}, {
    "City": "Conakry",
    "DBTZ": "N",
    "Name": "Conakry",
    "Country": "Guinea",
    "IATA/FAA": "CKY",
    "Longitude": "-13.611961",
    "ICAO": "GUCY",
    "Airport ID": "4162",
    "Latitude": "9.576889",
    "Timezone": "72",
    "DST": "0",
    "destinations": ["5665", "1084", "1382", "1094", "1074", "1044", "253", "298"]
}, {
    "City": "Yaounde",
    "DBTZ": "N",
    "Name": "Yaounde Nsimalen",
    "Country": "Cameroon",
    "IATA/FAA": "NSI",
    "Longitude": "11.553269",
    "ICAO": "FKYS",
    "Airport ID": "4161",
    "Latitude": "3.722556",
    "Timezone": "2278",
    "DST": "1",
    "destinations": ["1382", "1074", "897", "970", "4059", "302", "901"]
}, {
    "City": "Brac",
    "DBTZ": "E",
    "Name": "Brac",
    "Country": "Croatia",
    "IATA/FAA": "BWK",
    "Longitude": "16.679719",
    "ICAO": "LDSB",
    "Airport ID": "4160",
    "Latitude": "43.285719",
    "Timezone": "1776",
    "DST": "1",
    "destinations": ["1208"]
}, {
    "City": "Dijon",
    "DBTZ": "E",
    "Name": "Longvic",
    "Country": "France",
    "IATA/FAA": "DIJ",
    "Longitude": "5.09",
    "ICAO": "LFSD",
    "Airport ID": "1425",
    "Latitude": "47.26889",
    "Timezone": "726",
    "DST": "1",
    "destinations": ["1273"]
}, {
    "City": "Johnstown",
    "DBTZ": "A",
    "Name": "John Murtha Johnstown-Cambria County Airport",
    "Country": "United States",
    "IATA/FAA": "JST",
    "Longitude": "-78.833889",
    "ICAO": "KJST",
    "Airport ID": "4169",
    "Latitude": "40.316111",
    "Timezone": "2284",
    "DST": "-5",
    "destinations": ["3694", "3714"]
}, {
    "City": "Phu Quoc",
    "DBTZ": "U",
    "Name": "Duong Dong Airport",
    "Country": "Vietnam",
    "IATA/FAA": "PQC",
    "Longitude": "103.967169",
    "ICAO": "",
    "Airport ID": "4168",
    "Latitude": "10.227025",
    "Timezone": "23",
    "DST": "7",
    "destinations": ["3205", "3199", "6191"]
}, {
    "City": "Salt Cay",
    "DBTZ": "U",
    "Name": "Salt Cay Airport",
    "Country": "Turks and Caicos Islands",
    "IATA/FAA": "SLX",
    "Longitude": "-71.2",
    "ICAO": "MBSY",
    "Airport ID": "5809",
    "Latitude": "21.333",
    "Timezone": "3",
    "DST": "-5",
    "destinations": ["5807"]
}, {
    "City": "Padang",
    "DBTZ": "N",
    "Name": "Minangkabau",
    "Country": "Indonesia",
    "IATA/FAA": "PDG",
    "Longitude": "100.351881",
    "ICAO": "WIPT",
    "Airport ID": "3278",
    "Latitude": "-0.874989",
    "Timezone": "9",
    "DST": "7",
    "destinations": ["3304", "3275", "3903"]
}, {
    "City": "Balikesir Korfez",
    "DBTZ": "E",
    "Name": "Balikesir Korfez Airport",
    "Country": "Turkey",
    "IATA/FAA": "EDO",
    "Longitude": "27.0138",
    "ICAO": "LTFD",
    "Airport ID": "5802",
    "Latitude": "39.5546",
    "Timezone": "50",
    "DST": "2",
    "destinations": ["4317"]
}, {
    "City": "Isparta",
    "DBTZ": "E",
    "Name": "Isparta Suleyman Demirel Airport",
    "Country": "Turkey",
    "IATA/FAA": "ISE",
    "Longitude": "30.3684",
    "ICAO": "LTFC",
    "Airport ID": "5801",
    "Latitude": "37.8554",
    "Timezone": "2835",
    "DST": "2",
    "destinations": ["1701"]
}, {
    "City": "Adiyaman",
    "DBTZ": "E",
    "Name": "Adiyaman Airport",
    "Country": "Turkey",
    "IATA/FAA": "ADF",
    "Longitude": "38.4689",
    "ICAO": "LTCP",
    "Airport ID": "5800",
    "Latitude": "37.7314",
    "Timezone": "2216",
    "DST": "2",
    "destinations": ["1682", "1701"]
}, {
    "City": "Cockburn Town",
    "DBTZ": "U",
    "Name": "JAGS McCartney International Airport",
    "Country": "Turks and Caicos Islands",
    "IATA/FAA": "GDT",
    "Longitude": "-71.1423",
    "ICAO": "MBGT",
    "Airport ID": "5807",
    "Latitude": "21.4445",
    "Timezone": "13",
    "DST": "-5",
    "destinations": ["1754", "5809", "1755"]
}, {
    "City": "Ubari",
    "DBTZ": "N",
    "Name": "Ubari Airport",
    "Country": "Libya",
    "IATA/FAA": "QUB",
    "Longitude": "12.8231",
    "ICAO": "",
    "Airport ID": "5805",
    "Latitude": "26.5675",
    "Timezone": "1387",
    "DST": "2",
    "destinations": ["1157"]
}, {
    "City": "Misratah",
    "DBTZ": "N",
    "Name": "Misratah Airport",
    "Country": "Libya",
    "IATA/FAA": "MRA",
    "Longitude": "15.061",
    "ICAO": "",
    "Airport ID": "5804",
    "Latitude": "32.325",
    "Timezone": "60",
    "DST": "2",
    "destinations": ["1154", "1701", "295", "287", "1128", "3410", "2170"]
}, {
    "City": "Port Hardy",
    "DBTZ": "A",
    "Name": "Port Hardy",
    "Country": "Canada",
    "IATA/FAA": "YZT",
    "Longitude": "-127.366667",
    "ICAO": "CYZT",
    "Airport ID": "200",
    "Latitude": "50.680556",
    "Timezone": "71",
    "DST": "-8",
    "destinations": ["156", "7275"]
}, {
    "City": "Sept-iles",
    "DBTZ": "A",
    "Name": "Sept Iles",
    "Country": "Canada",
    "IATA/FAA": "YZV",
    "Longitude": "-66.265556",
    "ICAO": "CYZV",
    "Airport ID": "202",
    "Latitude": "50.223333",
    "Timezone": "180",
    "DST": "-5",
    "destinations": ["80", "161", "111", "146", "28", "7255", "71", "192", "94", "106"]
}, {
    "City": "Fort Mcpherson",
    "DBTZ": "A",
    "Name": "Fort Mcpherson",
    "Country": "Canada",
    "IATA/FAA": "ZFM",
    "Longitude": "-134.860556",
    "ICAO": "CZFM",
    "Airport ID": "206",
    "Latitude": "67.4075",
    "Timezone": "116",
    "DST": "-7",
    "destinations": ["54"]
}, {
    "City": "Bejaja",
    "DBTZ": "N",
    "Name": "Soummam",
    "Country": "Algeria",
    "IATA/FAA": "BJA",
    "Longitude": "5.069922",
    "ICAO": "DAAE",
    "Airport ID": "209",
    "Latitude": "36.711997",
    "Timezone": "20",
    "DST": "1",
    "destinations": ["210", "1335", "1353", "1386"]
}, {
    "City": "Skiathos",
    "DBTZ": "E",
    "Name": "Alexandros Papadiamantis",
    "Country": "Greece",
    "IATA/FAA": "JSI",
    "Longitude": "23.503675",
    "ICAO": "LGSK",
    "Airport ID": "1475",
    "Latitude": "39.1771",
    "Timezone": "54",
    "DST": "2",
    "destinations": ["3941"]
}, {
    "City": "Ronneby",
    "DBTZ": "E",
    "Name": "Ronneby",
    "Country": "Sweden",
    "IATA/FAA": "RNB",
    "Longitude": "15.265",
    "ICAO": "ESDF",
    "Airport ID": "685",
    "Latitude": "56.266667",
    "Timezone": "191",
    "DST": "1",
    "destinations": ["738", "737"]
}, {
    "City": "Gothenborg",
    "DBTZ": "E",
    "Name": "Landvetter",
    "Country": "Sweden",
    "IATA/FAA": "GOT",
    "Longitude": "12.279819",
    "ICAO": "ESGG",
    "Airport ID": "687",
    "Latitude": "57.662836",
    "Timezone": "506",
    "DST": "1",
    "destinations": ["1335", "507", "351", "1382", "738", "421", "607", "478", "469", "666", "3953", "737", "1230", "1212", "1218", "1555", "502", "1354", "340", "739", "721", "4330", "580", "345", "346", "609", "1701", "644", "3998", "1206", "302", "3989"]
}, {
    "City": "Chattanooga",
    "DBTZ": "A",
    "Name": "Lovell Fld",
    "Country": "United States",
    "IATA/FAA": "CHA",
    "Longitude": "-85.203808",
    "ICAO": "KCHA",
    "Airport ID": "3578",
    "Latitude": "35.035278",
    "Timezone": "683",
    "DST": "-5",
    "destinations": ["3682", "3876", "3520", "3670", "3830", "3645", "3617", "4167"]
}, {
    "City": "Thira",
    "DBTZ": "E",
    "Name": "Santorini",
    "Country": "Greece",
    "IATA/FAA": "JTR",
    "Longitude": "25.479333",
    "ICAO": "LGSR",
    "Airport ID": "1479",
    "Latitude": "36.399169",
    "Timezone": "127",
    "DST": "2",
    "destinations": ["344", "3941", "1613", "507", "502", "302", "580", "1448", "478", "1551"]
}, {
    "City": "Zhob",
    "DBTZ": "N",
    "Name": "Zhob",
    "Country": "Pakistan",
    "IATA/FAA": "PZH",
    "Longitude": "69.463606",
    "ICAO": "OPZB",
    "Airport ID": "2233",
    "Latitude": "31.358381",
    "Timezone": "4728",
    "DST": "5",
    "destinations": ["5943", "2221"]
}, {
    "City": "Basrah",
    "DBTZ": "U",
    "Name": "Basrah Intl",
    "Country": "Iraq",
    "IATA/FAA": "BSR",
    "Longitude": "47.662142",
    "ICAO": "ORMM",
    "Airport ID": "2234",
    "Latitude": "30.549069",
    "Timezone": "11",
    "DST": "3",
    "destinations": ["2188", "2179", "2191", "2177", "2241", "2170", "1701"]
}, {
    "City": "Wroclaw",
    "DBTZ": "E",
    "Name": "Strachowice",
    "Country": "Poland",
    "IATA/FAA": "WRO",
    "Longitude": "16.885836",
    "ICAO": "EPWR",
    "Airport ID": "680",
    "Latitude": "51.102683",
    "Timezone": "404",
    "DST": "1",
    "destinations": ["345", "1230", "1212", "1525", "1538", "490", "1474", "1553", "599", "523", "1222", "491", "1606", "596", "537", "657", "603", "548", "8414", "302", "1382", "668", "1678", "340", "346", "679", "609", "1367", "5562", "492", "664"]
}, {
    "City": "Al Ain",
    "DBTZ": "U",
    "Name": "Al Ain International Airport",
    "Country": "United Arab Emirates",
    "IATA/FAA": "AAN",
    "Longitude": "55.609167",
    "ICAO": "OMAL",
    "Airport ID": "5937",
    "Latitude": "24.261667",
    "Timezone": "869",
    "DST": "4",
    "destinations": ["3137", "2219"]
}, {
    "City": "Uromiyeh",
    "DBTZ": "E",
    "Name": "Uromiyeh Airport",
    "Country": "Iran",
    "IATA/FAA": "OMH",
    "Longitude": "45.0687",
    "ICAO": "OITR",
    "Airport ID": "5936",
    "Latitude": "37.6681",
    "Timezone": "4343",
    "DST": "3.5",
    "destinations": ["2131", "2074"]
}, {
    "City": "Ardabil",
    "DBTZ": "E",
    "Name": "Ardabil Airport",
    "Country": "Iran",
    "IATA/FAA": "ADU",
    "Longitude": "48.424356",
    "ICAO": "OITL",
    "Airport ID": "5935",
    "Latitude": "38.325678",
    "Timezone": "4315",
    "DST": "3.5",
    "destinations": ["2131", "4331"]
}, {
    "City": "Lar",
    "DBTZ": "E",
    "Name": "Lar Airport",
    "Country": "Iran",
    "IATA/FAA": "LRR",
    "Longitude": "54.383278",
    "ICAO": "OISL",
    "Airport ID": "5934",
    "Latitude": "27.674725",
    "Timezone": "2641",
    "DST": "3.5",
    "destinations": ["2188", "2157", "2191", "2241", "2176", "2131"]
}, {
    "City": "Dasht-e-naz",
    "DBTZ": "E",
    "Name": "Sari Dasht E Naz Airport",
    "Country": "Iran",
    "IATA/FAA": "SRY",
    "Longitude": "53.193611",
    "ICAO": "OINZ",
    "Airport ID": "5933",
    "Latitude": "36.635833",
    "Timezone": "35",
    "DST": "3.5",
    "destinations": ["2104", "4331", "2157", "2134"]
}, {
    "City": "Noshahr",
    "DBTZ": "E",
    "Name": "Noshahr Airport",
    "Country": "Iran",
    "IATA/FAA": "NSH",
    "Longitude": "51.464722",
    "ICAO": "OINN",
    "Airport ID": "5932",
    "Latitude": "36.663333",
    "Timezone": "-61",
    "DST": "3.5",
    "destinations": ["4331", "2131"]
}, {
    "City": "Sabzevar",
    "DBTZ": "E",
    "Name": "Sabzevar National Airport",
    "Country": "Iran",
    "IATA/FAA": "AFZ",
    "Longitude": "57.595183",
    "ICAO": "OIMS",
    "Airport ID": "5931",
    "Latitude": "36.168083",
    "Timezone": "3010",
    "DST": "3.5",
    "destinations": ["2131"]
}, {
    "City": "Bojnourd",
    "DBTZ": "E",
    "Name": "Bojnourd Airport",
    "Country": "Iran",
    "IATA/FAA": "BJB",
    "Longitude": "57.308219",
    "ICAO": "OIMN",
    "Airport ID": "5930",
    "Latitude": "37.492958",
    "Timezone": "3499",
    "DST": "3.5",
    "destinations": ["2131"]
}, {
    "City": "Bahawalpur",
    "DBTZ": "N",
    "Name": "Bahawalpur Airport",
    "Country": "Pakistan",
    "IATA/FAA": "BHV",
    "Longitude": "71.717981",
    "ICAO": "OPBW",
    "Airport ID": "5939",
    "Latitude": "29.3481",
    "Timezone": "392",
    "DST": "5",
    "destinations": ["2206", "2207", "2223"]
}, {
    "City": "Minot",
    "DBTZ": "A",
    "Name": "Minot Intl",
    "Country": "United States",
    "IATA/FAA": "MOT",
    "Longitude": "-101.280333",
    "ICAO": "KMOT",
    "Airport ID": "3498",
    "Latitude": "48.259378",
    "Timezone": "1716",
    "DST": "-6",
    "destinations": ["3858", "3751", "6505", "3877"]
}, {
    "City": "Garden City",
    "DBTZ": "A",
    "Name": "Garden City Rgnl",
    "Country": "United States",
    "IATA/FAA": "GCK",
    "Longitude": "-100.724417",
    "ICAO": "KGCK",
    "Airport ID": "3497",
    "Latitude": "37.927528",
    "Timezone": "2891",
    "DST": "-6",
    "destinations": ["3670"]
}, {
    "City": "Newark",
    "DBTZ": "A",
    "Name": "Newark Liberty Intl",
    "Country": "United States",
    "IATA/FAA": "EWR",
    "Longitude": "-74.168667",
    "ICAO": "KEWR",
    "Airport ID": "3494",
    "Latitude": "40.6925",
    "Timezone": "18",
    "DST": "-5",
    "destinations": ["302", "3876", "3670", "3077", "507", "3576", "3830", "1386", "3752", "3462", "3577", "3861", "49", "146", "156", "178", "193", "3682", "1382", "2997", "2709", "1892", "3448", "3533", "3878", "3722", "3793", "2890", "1764", "3646", "3364", "3406", "1871", "580", "3488", "3645", "3858", "3536", "16", "3690", "3747", "1218", "465", "469", "599", "345", "535", "1555", "340", "534", "342", "1229", "478", "346", "1524", "603", "350", "351", "1665", "1678", "1590", "2279", "144", "737", "609", "644", "1638", "1636", "3864", "2874", "2895", "3673", "4069", "2896", "2885", "3711", "3820", "3849", "1957", "3806", "3486", "3759", "1852", "3627", "3520", "3093", "3751", "3729", "1926", "3685", "2564", "4008", "4034", "1767", "3728", "3714", "3550", "3585", "3947", "3712", "3877", "3484", "2789", "1881", "1780", "3458", "3473", "1824", "4011", "3717", "3459", "3515", "1953", "3863", "3454", "3611", "3720", "3570", "1754", "1761", "2902", "1760", "3641", "1836", "3862", "3626", "3608", "3622", "3731", "1772", "3621", "3613", "4014", "1762", "3469", "1840", "1885", "3867", "3678", "2883", "2899", "3745", "3855", "3676", "2894", "4356", "73", "100", "111", "117", "189", "1948", "3566"]
}, {
    "City": "Boise",
    "DBTZ": "A",
    "Name": "Boise Air Terminal",
    "Country": "United States",
    "IATA/FAA": "BOI",
    "Longitude": "-116.222861",
    "ICAO": "KBOI",
    "Airport ID": "3495",
    "Latitude": "43.564361",
    "Timezone": "2871",
    "DST": "-7",
    "destinations": ["3462", "3945", "3720", "3731", "3577", "3748", "3817", "3858", "3536", "3751", "3877", "3484", "3830", "3469", "3467", "3453"]
}, {
    "City": "Juneau",
    "DBTZ": "A",
    "Name": "Juneau Intl",
    "Country": "United States",
    "IATA/FAA": "JNU",
    "Longitude": "-134.576278",
    "ICAO": "PAJN",
    "Airport ID": "3492",
    "Latitude": "58.354972",
    "Timezone": "21",
    "DST": "-9",
    "destinations": ["3808", "3774", "4147", "3577", "3856", "6803", "7135", "5960", "7142", "5963", "7138", "5961", "7141", "7243"]
}, {
    "City": "Lafayette",
    "DBTZ": "A",
    "Name": "Lafayette Rgnl",
    "Country": "United States",
    "IATA/FAA": "LFT",
    "Longitude": "-91.987611",
    "ICAO": "KLFT",
    "Airport ID": "3493",
    "Latitude": "30.205278",
    "Timezone": "43",
    "DST": "-6",
    "destinations": ["3670", "3682", "3550"]
}, {
    "City": "Huron",
    "DBTZ": "A",
    "Name": "Huron Rgnl",
    "Country": "United States",
    "IATA/FAA": "HON",
    "Longitude": "-98.228542",
    "ICAO": "KHON",
    "Airport ID": "3491",
    "Latitude": "44.3852",
    "Timezone": "1289",
    "DST": "-6",
    "destinations": ["5761"]
}, {
    "City": "Wejh",
    "DBTZ": "U",
    "Name": "Wejh",
    "Country": "Saudi Arabia",
    "IATA/FAA": "EJH",
    "Longitude": "36.476381",
    "ICAO": "OEWJ",
    "Airport ID": "2095",
    "Latitude": "26.198553",
    "Timezone": "66",
    "DST": "3",
    "destinations": ["2072"]
}, {
    "City": "Abadan",
    "DBTZ": "E",
    "Name": "Abadan",
    "Country": "Iran",
    "IATA/FAA": "ABD",
    "Longitude": "48.228333",
    "ICAO": "OIAA",
    "Airport ID": "2097",
    "Latitude": "30.371111",
    "Timezone": "19",
    "DST": "3.5",
    "destinations": ["4331", "2131", "2157"]
}, {
    "City": "Yenbo",
    "DBTZ": "U",
    "Name": "Yenbo",
    "Country": "Saudi Arabia",
    "IATA/FAA": "YNB",
    "Longitude": "38.06335",
    "ICAO": "OEYN",
    "Airport ID": "2096",
    "Latitude": "24.144244",
    "Timezone": "26",
    "DST": "3",
    "destinations": ["1128", "2188", "2191", "2072", "2082", "1701", "2064"]
}, {
    "City": "Bush Field",
    "DBTZ": "A",
    "Name": "Augusta Rgnl At Bush Fld",
    "Country": "United States",
    "IATA/FAA": "AGS",
    "Longitude": "-81.9645",
    "ICAO": "KAGS",
    "Airport ID": "3658",
    "Latitude": "33.369944",
    "Timezone": "144",
    "DST": "-5",
    "destinations": ["3876", "3520", "3682"]
}, {
    "City": "Taif",
    "DBTZ": "U",
    "Name": "Taif",
    "Country": "Saudi Arabia",
    "IATA/FAA": "TIF",
    "Longitude": "40.544334",
    "ICAO": "OETF",
    "Airport ID": "2090",
    "Latitude": "21.483418",
    "Timezone": "4848",
    "DST": "3",
    "destinations": ["1128", "2188", "2191", "2241", "2059", "2064", "2082", "1701", "2176"]
}, {
    "City": "Turaif",
    "DBTZ": "U",
    "Name": "Turaif",
    "Country": "Saudi Arabia",
    "IATA/FAA": "TUI",
    "Longitude": "38.7312",
    "ICAO": "OETR",
    "Airport ID": "2093",
    "Latitude": "31.692683",
    "Timezone": "2803",
    "DST": "3",
    "destinations": ["2082"]
}, {
    "City": "Hibbing",
    "DBTZ": "A",
    "Name": "Chisholm Hibbing",
    "Country": "United States",
    "IATA/FAA": "HIB",
    "Longitude": "-92.838994",
    "ICAO": "KHIB",
    "Airport ID": "3650",
    "Latitude": "47.3866",
    "Timezone": "1353",
    "DST": "-6",
    "destinations": ["3858"]
}, {
    "City": "Tiaret",
    "DBTZ": "N",
    "Name": "Bou Chekif",
    "Country": "Algeria",
    "IATA/FAA": "TID",
    "Longitude": "1.463147",
    "ICAO": "DAOB",
    "Airport ID": "225",
    "Latitude": "35.341136",
    "Timezone": "3245",
    "DST": "1",
    "destinations": ["210"]
}, {
    "City": "Midland",
    "DBTZ": "A",
    "Name": "Midland Intl",
    "Country": "United States",
    "IATA/FAA": "MAF",
    "Longitude": "-102.201914",
    "ICAO": "KMAF",
    "Airport ID": "3652",
    "Latitude": "31.942528",
    "Timezone": "2871",
    "DST": "-6",
    "destinations": ["3670", "3502", "3751", "3550", "3566", "3877"]
}, {
    "City": "Green Bay",
    "DBTZ": "A",
    "Name": "Austin Straubel Intl",
    "Country": "United States",
    "IATA/FAA": "GRB",
    "Longitude": "-88.129589",
    "ICAO": "KGRB",
    "Airport ID": "3653",
    "Latitude": "44.485072",
    "Timezone": "695",
    "DST": "-6",
    "destinations": ["3830", "3682", "3645", "3858"]
}, {
    "City": "Aracatuba",
    "DBTZ": "S",
    "Name": "Aracatuba",
    "Country": "Brazil",
    "IATA/FAA": "ARU",
    "Longitude": "-50.424722",
    "ICAO": "SBAU",
    "Airport ID": "2525",
    "Latitude": "-21.141342",
    "Timezone": "1361",
    "DST": "-3",
    "destinations": ["2564", "2578"]
}, {
    "City": "Alta Floresta",
    "DBTZ": "S",
    "Name": "Alta Floresta",
    "Country": "Brazil",
    "IATA/FAA": "AFL",
    "Longitude": "-56.106206",
    "ICAO": "SBAT",
    "Airport ID": "2524",
    "Latitude": "-9.866092",
    "Timezone": "947",
    "DST": "-4",
    "destinations": ["2548"]
}, {
    "City": "Tilrempt",
    "DBTZ": "N",
    "Name": "Hassi R Mel",
    "Country": "Algeria",
    "IATA/FAA": "HRM",
    "Longitude": "3.311542",
    "ICAO": "DAFH",
    "Airport ID": "224",
    "Latitude": "32.930431",
    "Timezone": "2540",
    "DST": "1",
    "destinations": ["210", "238"]
}, {
    "City": "Araracuara",
    "DBTZ": "S",
    "Name": "Araraquara",
    "Country": "Brazil",
    "IATA/FAA": "AQA",
    "Longitude": "-48.133028",
    "ICAO": "SBAQ",
    "Airport ID": "2521",
    "Latitude": "-21.812",
    "Timezone": "2334",
    "DST": "-3",
    "destinations": ["2578"]
}, {
    "City": "Aracaju",
    "DBTZ": "S",
    "Name": "Santa Maria",
    "Country": "Brazil",
    "IATA/FAA": "AJU",
    "Longitude": "-37.070333",
    "ICAO": "SBAR",
    "Airport ID": "2522",
    "Latitude": "-10.984",
    "Timezone": "23",
    "DST": "-3",
    "destinations": ["2590", "2610", "2621", "2578", "2560", "2564", "2612", "2531"]
}, {
    "City": "Belo Horizonte",
    "DBTZ": "S",
    "Name": "Pampulha Carlos Drummond De Andrade",
    "Country": "Brazil",
    "IATA/FAA": "PLU",
    "Longitude": "-43.950628",
    "ICAO": "SBBH",
    "Airport ID": "2528",
    "Latitude": "-19.851181",
    "Timezone": "2589",
    "DST": "-3",
    "destinations": ["2613", "7395", "7364", "2564", "6735", "2570", "2587", "8237", "2615", "2633", "2578", "6039"]
}, {
    "City": "Bogota",
    "DBTZ": "U",
    "Name": "Eldorado Intl",
    "Country": "Colombia",
    "IATA/FAA": "BOG",
    "Longitude": "-74.146947",
    "ICAO": "SKBO",
    "Airport ID": "2709",
    "Latitude": "4.701594",
    "Timezone": "8361",
    "DST": "-5",
    "destinations": ["2673", "2688", "1824", "5476", "2752", "2712", "2717", "2729", "2719", "2725", "2732", "2738", "6054", "2742", "2706", "7355", "2747", "2750", "2751", "2753", "7359", "2756", "3670", "3576", "193", "1382", "3988", "2749", "2895", "2705", "2710", "1218", "2708", "2851", "2715", "2714", "2713", "1852", "2897", "2718", "3494", "6055", "3533", "340", "2560", "2564", "1767", "1909", "3714", "3550", "2724", "3797", "2728", "2789", "2762", "1229", "3878", "2745", "2731", "2733", "2734", "2739", "2741", "1871", "1760", "2746", "1892", "2650", "1762", "1885", "2890", "2748", "2755", "3682"]
}, {
    "City": "Bucaramanga",
    "DBTZ": "U",
    "Name": "Palonegro",
    "Country": "Colombia",
    "IATA/FAA": "BGA",
    "Longitude": "-73.184778",
    "ICAO": "SKBG",
    "Airport ID": "2708",
    "Latitude": "7.1265",
    "Timezone": "3897",
    "DST": "-5",
    "destinations": ["2709", "2745", "1871"]
}, {
    "City": "Armenia",
    "DBTZ": "U",
    "Name": "El Eden",
    "Country": "Colombia",
    "IATA/FAA": "AXM",
    "Longitude": "-75.766447",
    "ICAO": "SKAR",
    "Airport ID": "2705",
    "Latitude": "4.452775",
    "Timezone": "3990",
    "DST": "-5",
    "destinations": ["2709", "3533"]
}, {
    "City": "Puerto Asis",
    "DBTZ": "U",
    "Name": "Tres De Mayo",
    "Country": "Colombia",
    "IATA/FAA": "PUU",
    "Longitude": "-76.500836",
    "ICAO": "SKAS",
    "Airport ID": "2706",
    "Latitude": "0.505228",
    "Timezone": "815",
    "DST": "-5",
    "destinations": ["2709", "2715", "6051"]
}, {
    "City": "Puerto Obaldia",
    "DBTZ": "U",
    "Name": "Puerto Obaldia",
    "Country": "Panama",
    "IATA/FAA": "PUE",
    "Longitude": "-77.5333",
    "ICAO": "MPOA",
    "Airport ID": "7962",
    "Latitude": "8.68333",
    "Timezone": "223",
    "DST": "-5",
    "destinations": ["1869"]
}, {
    "City": "Oaxaca",
    "DBTZ": "S",
    "Name": "Xoxocotlan Intl",
    "Country": "Mexico",
    "IATA/FAA": "OAX",
    "Longitude": "-96.726639",
    "ICAO": "MMOX",
    "Airport ID": "1829",
    "Latitude": "16.999906",
    "Timezone": "4989",
    "DST": "-6",
    "destinations": ["1824", "3550", "1852", "1825", "1847"]
}, {
    "City": "Nuevo Laredo",
    "DBTZ": "S",
    "Name": "Quetzalcoatl Intl",
    "Country": "Mexico",
    "IATA/FAA": "NLD",
    "Longitude": "-99.57046",
    "ICAO": "MMNL",
    "Airport ID": "1828",
    "Latitude": "27.443918",
    "Timezone": "484",
    "DST": "-6",
    "destinations": ["1824"]
}, {
    "City": "Monterrey",
    "DBTZ": "S",
    "Name": "General Mariano Escobedo Intl",
    "Country": "Mexico",
    "IATA/FAA": "MTY",
    "Longitude": "-100.106878",
    "ICAO": "MMMY",
    "Airport ID": "1825",
    "Latitude": "25.778489",
    "Timezone": "1278",
    "DST": "-6",
    "destinations": ["1852", "1804", "3877", "1824", "3621", "1850", "3670", "3682", "1785", "1815", "1796", "1792", "1797", "3645", "1807", "1831", "1871", "1838", "1848", "1854", "3550", "3830", "1783", "1816", "1819", "1826", "1829", "1836", "1840", "1846", "1853", "1847"]
}, {
    "City": "Mexico City",
    "DBTZ": "S",
    "Name": "Licenciado Benito Juarez Intl",
    "Country": "Mexico",
    "IATA/FAA": "MEX",
    "Longitude": "-99.072097",
    "ICAO": "MMMX",
    "Airport ID": "1824",
    "Latitude": "19.436303",
    "Timezone": "7316",
    "DST": "-6",
    "destinations": ["1783", "1785", "2709", "1794", "1796", "1789", "1795", "1793", "1792", "1852", "1797", "1800", "1804", "1767", "1909", "1807", "1786", "3797", "1816", "3576", "1819", "1822", "1825", "1826", "1829", "1836", "1839", "3621", "1840", "1885", "3867", "1848", "1846", "1847", "1845", "1854", "1853", "1855", "1856", "1858", "3876", "3670", "3484", "3830", "3462", "156", "193", "3682", "1382", "1815", "2851", "1808", "1798", "1801", "3645", "3988", "2564", "3714", "3550", "5837", "3877", "507", "2789", "1814", "1813", "1229", "1818", "3878", "1821", "1820", "1828", "1830", "1834", "1871", "1837", "1892", "1772", "2650", "3469", "1842", "1810", "1851", "1802", "2688", "146", "580", "340", "346", "3751", "3494", "3673", "3826", "3731"]
}, {
    "City": "Mazatlan",
    "DBTZ": "S",
    "Name": "General Rafael Buelna Intl",
    "Country": "Mexico",
    "IATA/FAA": "MZT",
    "Longitude": "-106.266072",
    "ICAO": "MMMZ",
    "Airport ID": "1826",
    "Latitude": "23.161356",
    "Timezone": "38",
    "DST": "-7",
    "destinations": ["1824", "3670", "3462", "1804", "3484", "1814", "1816", "1825", "1847"]
}, {
    "City": "Morelia",
    "DBTZ": "S",
    "Name": "General Francisco J Mujica Intl",
    "Country": "Mexico",
    "IATA/FAA": "MLM",
    "Longitude": "-101.0255",
    "ICAO": "MMMM",
    "Airport ID": "1821",
    "Latitude": "19.849944",
    "Timezone": "6033",
    "DST": "-6",
    "destinations": ["3670", "1824", "3469", "3550", "3484", "3747", "1847"]
}, {
    "City": "Mexicali",
    "DBTZ": "S",
    "Name": "General Rodolfo Sanchez Taboada Intl",
    "Country": "Mexico",
    "IATA/FAA": "MXL",
    "Longitude": "-115.241637",
    "ICAO": "MMML",
    "Airport ID": "1820",
    "Latitude": "32.630634",
    "Timezone": "74",
    "DST": "-8",
    "destinations": ["1807", "1824", "1804"]
}, {
    "City": "Minatitlan",
    "DBTZ": "S",
    "Name": "Minatitlan",
    "Country": "Mexico",
    "IATA/FAA": "MTT",
    "Longitude": "-94.580681",
    "ICAO": "MMMT",
    "Airport ID": "1822",
    "Latitude": "18.103419",
    "Timezone": "36",
    "DST": "-6",
    "destinations": ["1824"]
}, {
    "City": "Mahajanga",
    "DBTZ": "U",
    "Name": "Philibert Tsiranana",
    "Country": "Madagascar",
    "IATA/FAA": "MJN",
    "Longitude": "46.351828",
    "ICAO": "FMNM",
    "Airport ID": "929",
    "Latitude": "-15.667144",
    "Timezone": "87",
    "DST": "3",
    "destinations": ["915", "918"]
}, {
    "City": "Cunnamulla",
    "DBTZ": "O",
    "Name": "Cunnamulla Airport",
    "Country": "Australia",
    "IATA/FAA": "CMA",
    "Longitude": "145.622",
    "ICAO": "YCMU",
    "Airport ID": "6258",
    "Latitude": "-28.03",
    "Timezone": "630",
    "DST": "10",
    "destinations": ["6325", "6329"]
}, {
    "City": "Birjand",
    "DBTZ": "E",
    "Name": "Birjand",
    "Country": "Iran",
    "IATA/FAA": "XBJ",
    "Longitude": "59.266111",
    "ICAO": "OIMB",
    "Airport ID": "2142",
    "Latitude": "32.898056",
    "Timezone": "4952",
    "DST": "3.5",
    "destinations": ["4331", "2131"]
}, {
    "City": "Coober Pedy",
    "DBTZ": "O",
    "Name": "Coober Pedy Airport",
    "Country": "Australia",
    "IATA/FAA": "CPD",
    "Longitude": "134.721",
    "ICAO": "YCBP",
    "Airport ID": "6253",
    "Latitude": "-29.04",
    "Timezone": "740",
    "DST": "9.5",
    "destinations": ["3341"]
}, {
    "City": "Kirksville",
    "DBTZ": "A",
    "Name": "Kirksville Regional Airport",
    "Country": "United States",
    "IATA/FAA": "IRK",
    "Longitude": "-92.5449",
    "ICAO": "KIRK",
    "Airport ID": "5744",
    "Latitude": "40.0935",
    "Timezone": "966",
    "DST": "-6",
    "destinations": ["3678"]
}, {
    "City": "Morondava",
    "DBTZ": "U",
    "Name": "Morondava",
    "Country": "Madagascar",
    "IATA/FAA": "MOQ",
    "Longitude": "44.317614",
    "ICAO": "FMMV",
    "Airport ID": "922",
    "Latitude": "-20.28475",
    "Timezone": "30",
    "DST": "3",
    "destinations": ["943", "918"]
}, {
    "City": "Antsiranana",
    "DBTZ": "U",
    "Name": "Arrachart",
    "Country": "Madagascar",
    "IATA/FAA": "DIE",
    "Longitude": "49.291747",
    "ICAO": "FMNA",
    "Airport ID": "923",
    "Latitude": "-12.3494",
    "Timezone": "374",
    "DST": "3",
    "destinations": ["915", "930", "916", "933", "918"]
}, {
    "City": "Mananara",
    "DBTZ": "U",
    "Name": "Avaratra",
    "Country": "Madagascar",
    "IATA/FAA": "WMR",
    "Longitude": "49.773753",
    "ICAO": "FMNC",
    "Airport ID": "924",
    "Latitude": "-16.1639",
    "Timezone": "9",
    "DST": "3",
    "destinations": ["921"]
}, {
    "City": "Ceduna",
    "DBTZ": "O",
    "Name": "Ceduna Airport",
    "Country": "Australia",
    "IATA/FAA": "CED",
    "Longitude": "133.71",
    "ICAO": "YCDU",
    "Airport ID": "6256",
    "Latitude": "-32.1306",
    "Timezone": "77",
    "DST": "9.5",
    "destinations": ["3341"]
}, {
    "City": "Cloncurry",
    "DBTZ": "O",
    "Name": "Cloncurry Airport",
    "Country": "Australia",
    "IATA/FAA": "CNJ",
    "Longitude": "140.504",
    "ICAO": "YCCY",
    "Airport ID": "6255",
    "Latitude": "-20.6686",
    "Timezone": "616",
    "DST": "10",
    "destinations": ["3320", "3324", "3330"]
}, {
    "City": "Antalaha",
    "DBTZ": "U",
    "Name": "Antsirabato",
    "Country": "Madagascar",
    "IATA/FAA": "ANM",
    "Longitude": "50.320233",
    "ICAO": "FMNH",
    "Airport ID": "927",
    "Latitude": "-14.999411",
    "Timezone": "20",
    "DST": "3",
    "destinations": ["921", "918", "932"]
}, {
    "City": "Rach Gia",
    "DBTZ": "U",
    "Name": "Rach Gia",
    "Country": "Vietnam",
    "IATA/FAA": "VKG",
    "Longitude": "105.133659",
    "ICAO": "VVRG",
    "Airport ID": "4155",
    "Latitude": "9.949676",
    "Timezone": "7",
    "DST": "7",
    "destinations": ["3205"]
}, {
    "City": "Bournemouth",
    "DBTZ": "E",
    "Name": "Bournemouth",
    "Country": "United Kingdom",
    "IATA/FAA": "BOH",
    "Longitude": "-1.8425",
    "ICAO": "EGHH",
    "Airport ID": "494",
    "Latitude": "50.78",
    "Timezone": "38",
    "DST": "0",
    "destinations": ["599", "1055", "1230", "1212", "1352", "1474", "1626", "1051", "1222", "1225", "1054", "1227", "1606", "3998", "1562", "1472", "1056"]
}, {
    "City": "Varadero",
    "DBTZ": "U",
    "Name": "Juan Gualberto Gomez Intl",
    "Country": "Cuba",
    "IATA/FAA": "VRA",
    "Longitude": "-81.435278",
    "ICAO": "MUVR",
    "Airport ID": "1923",
    "Latitude": "23.034445",
    "Timezone": "210",
    "DST": "-5",
    "destinations": ["345", "146", "178", "193", "340", "1852", "1918", "73", "4029"]
}, {
    "City": "Bandar Seri Begawan",
    "DBTZ": "U",
    "Name": "Brunei Intl",
    "Country": "Brunei",
    "IATA/FAA": "BWN",
    "Longitude": "114.928353",
    "ICAO": "WBSB",
    "Airport ID": "3272",
    "Latitude": "4.9442",
    "Timezone": "73",
    "DST": "8",
    "destinations": ["2397", "3304", "3269", "3885", "3275", "8076", "3077", "2072", "3339", "4056", "3406", "3316", "3928"]
}, {
    "City": "Cayman Barac",
    "DBTZ": "U",
    "Name": "Gerrard Smith Intl",
    "Country": "Cayman Islands",
    "IATA/FAA": "CYB",
    "Longitude": "-79.882789",
    "ICAO": "MWCB",
    "Airport ID": "1925",
    "Latitude": "19.686981",
    "Timezone": "8",
    "DST": "-5",
    "destinations": ["1926", "4314"]
}, {
    "City": "Georgetown",
    "DBTZ": "U",
    "Name": "Owen Roberts Intl",
    "Country": "Cayman Islands",
    "IATA/FAA": "GCM",
    "Longitude": "-81.35775",
    "ICAO": "MWCR",
    "Airport ID": "1926",
    "Latitude": "19.292778",
    "Timezone": "8",
    "DST": "-5",
    "destinations": ["3876", "3576", "3752", "193", "3797", "1953", "3682", "3645", "1925", "1909", "1779", "1771", "4314", "3830", "3646", "3494", "3714", "3550"]
}, {
    "City": "Lebanon",
    "DBTZ": "A",
    "Name": "Lebanon Municipal Airport",
    "Country": "United States",
    "IATA/FAA": "LEB",
    "Longitude": "-72.3042",
    "ICAO": "KLEB",
    "Airport ID": "5749",
    "Latitude": "43.6261",
    "Timezone": "603",
    "DST": "-5",
    "destinations": ["3448", "3589"]
}, {
    "City": "Chittagong",
    "DBTZ": "U",
    "Name": "Shah Amanat Intl",
    "Country": "Bangladesh",
    "IATA/FAA": "CGP",
    "Longitude": "91.813286",
    "ICAO": "VGEG",
    "Airport ID": "3069",
    "Latitude": "22.249611",
    "Timezone": "12",
    "DST": "6",
    "destinations": ["3043", "3076", "2194", "2179", "2188", "2072", "8076", "2191", "3304", "3885"]
}, {
    "City": "Gurupi",
    "DBTZ": "S",
    "Name": "Gurupi Airport",
    "Country": "Brazil",
    "IATA/FAA": "GRP",
    "Longitude": "-49.068889",
    "ICAO": "SWGI",
    "Airport ID": "7368",
    "Latitude": "-11.728889",
    "Timezone": "10",
    "DST": "-3",
    "destinations": ["7375"]
}, {
    "City": "Gainesville",
    "DBTZ": "A",
    "Name": "Gainesville Rgnl",
    "Country": "United States",
    "IATA/FAA": "GNV",
    "Longitude": "-82.271778",
    "ICAO": "KGNV",
    "Airport ID": "3472",
    "Latitude": "29.690056",
    "Timezone": "152",
    "DST": "-5",
    "destinations": ["3878", "3646", "3876", "3576", "3682"]
}, {
    "City": "Memphis",
    "DBTZ": "A",
    "Name": "Memphis Intl",
    "Country": "United States",
    "IATA/FAA": "MEM",
    "Longitude": "-89.976667",
    "ICAO": "KMEM",
    "Airport ID": "3473",
    "Latitude": "35.042417",
    "Timezone": "341",
    "DST": "-6",
    "destinations": ["3861", "3876", "3520", "3670", "3576", "3830", "3682", "3673", "3488", "3751", "3645", "3550", "3585", "3877", "3484", "3697", "3458", "3878", "3858", "3570", "3626", "3621", "3536", "3849", "3566", "3747", "3646", "3696", "5741", "3461", "3704", "3494"]
}, {
    "City": "Port Elizabeth",
    "DBTZ": "U",
    "Name": "Port Elizabeth Intl",
    "Country": "South Africa",
    "IATA/FAA": "PLZ",
    "Longitude": "25.617275",
    "ICAO": "FAPE",
    "Airport ID": "838",
    "Latitude": "-33.984919",
    "Timezone": "226",
    "DST": "2",
    "destinations": ["797", "799", "813", "800"]
}, {
    "City": "Cabo Frio",
    "DBTZ": "S",
    "Name": "Cabo Frio International Airport",
    "Country": "Brazil",
    "IATA/FAA": "CFB",
    "Longitude": "-42.074167",
    "ICAO": "SBCB",
    "Airport ID": "7364",
    "Latitude": "-22.921667",
    "Timezone": "14",
    "DST": "-3",
    "destinations": ["2528", "2578", "2612"]
}, {
    "City": "Tumbes",
    "DBTZ": "U",
    "Name": "Pedro Canga",
    "Country": "Peru",
    "IATA/FAA": "TBP",
    "Longitude": "-80.381356",
    "ICAO": "SPME",
    "Airport ID": "2796",
    "Latitude": "-3.552528",
    "Timezone": "115",
    "DST": "-5",
    "destinations": ["2789"]
}, {
    "City": "Sinop",
    "DBTZ": "S",
    "Name": "Sinop Airport",
    "Country": "Brazil",
    "IATA/FAA": "OPS",
    "Longitude": "-55.586",
    "ICAO": "SWSI",
    "Airport ID": "7367",
    "Latitude": "-11.885",
    "Timezone": "10",
    "DST": "-4",
    "destinations": ["2531", "2548"]
}, {
    "City": "Mount Hagen",
    "DBTZ": "U",
    "Name": "Mount Hagen",
    "Country": "Papua New Guinea",
    "IATA/FAA": "HGU",
    "Longitude": "144.295861",
    "ICAO": "AYMH",
    "Airport ID": "3",
    "Latitude": "-5.826789",
    "Timezone": "5388",
    "DST": "10",
    "destinations": ["1", "4", "2", "5431", "5", "5434", "5433", "6"]
}, {
    "City": "Kulusuk",
    "DBTZ": "E",
    "Name": "Kulusuk",
    "Country": "Greenland",
    "IATA/FAA": "KUS",
    "Longitude": "-37.1166667",
    "ICAO": "",
    "Airport ID": "4248",
    "Latitude": "65.566667",
    "Timezone": "112",
    "DST": "-3",
    "destinations": ["7642", "5439", "8", "9", "18"]
}, {
    "City": "Punta Gorda",
    "DBTZ": "U",
    "Name": "Punta Gorda Airport",
    "Country": "Belize",
    "IATA/FAA": "PND",
    "Longitude": "-88.8083",
    "ICAO": "",
    "Airport ID": "5455",
    "Latitude": "16.1024",
    "Timezone": "7",
    "DST": "-6",
    "destinations": ["7288"]
}, {
    "City": "St. Anthony",
    "DBTZ": "A",
    "Name": "St Anthony",
    "Country": "Canada",
    "IATA/FAA": "YAY",
    "Longitude": "-56.083056",
    "ICAO": "CYAY",
    "Airport ID": "24",
    "Latitude": "51.391944",
    "Timezone": "108",
    "DST": "-3.5",
    "destinations": ["5485", "188", "189"]
}, {
    "City": "Alice Springs",
    "DBTZ": "N",
    "Name": "Alice Springs",
    "Country": "Australia",
    "IATA/FAA": "ASP",
    "Longitude": "133.902222",
    "ICAO": "YBAS",
    "Airport ID": "3319",
    "Latitude": "-23.806667",
    "Timezone": "1789",
    "DST": "9.5",
    "destinations": ["3361", "3341", "4010", "3320", "3322", "3999", "3339", "3351"]
}, {
    "City": "Amberley",
    "DBTZ": "O",
    "Name": "Bamaga Injinoo",
    "Country": "Australia",
    "IATA/FAA": "ABM",
    "Longitude": "142.459444",
    "ICAO": "YBAM",
    "Airport ID": "3318",
    "Latitude": "-10.950833",
    "Timezone": "34",
    "DST": "10",
    "destinations": ["3322"]
}, {
    "City": "Zakynthos",
    "DBTZ": "E",
    "Name": "Dionysios Solomos",
    "Country": "Greece",
    "IATA/FAA": "ZTH",
    "Longitude": "20.88425",
    "ICAO": "LGZA",
    "Airport ID": "1488",
    "Latitude": "37.750853",
    "Timezone": "15",
    "DST": "2",
    "destinations": ["3941", "345", "346", "1613", "478", "304", "1455", "1454", "302", "580", "517", "502"]
}, {
    "City": "Baie Comeau",
    "DBTZ": "A",
    "Name": "Baie Comeau",
    "Country": "Canada",
    "IATA/FAA": "YBC",
    "Longitude": "-68.204444",
    "ICAO": "CYBC",
    "Airport ID": "27",
    "Latitude": "49.1325",
    "Timezone": "71",
    "DST": "-5",
    "destinations": ["146", "192", "111", "161"]
}, {
    "City": "Saba",
    "DBTZ": "U",
    "Name": "Juancho E. Yrausquin",
    "Country": "Netherlands Antilles",
    "IATA/FAA": "SAB",
    "Longitude": "-63.220556",
    "ICAO": "TNCS",
    "Airport ID": "4249",
    "Latitude": "17.645278",
    "Timezone": "60",
    "DST": "-4",
    "destinations": ["2899"]
}, {
    "City": "Fort Severn",
    "DBTZ": "A",
    "Name": "Fort Severn Airport",
    "Country": "Canada",
    "IATA/FAA": "YER",
    "Longitude": "-87.6761",
    "ICAO": "CYER",
    "Airport ID": "5489",
    "Latitude": "56.0189",
    "Timezone": "48",
    "DST": "-5",
    "destinations": ["5465", "5531", "169"]
}, {
    "City": "Nain",
    "DBTZ": "A",
    "Name": "Nain Airport",
    "Country": "Canada",
    "IATA/FAA": "YDP",
    "Longitude": "-61.6803",
    "ICAO": "CYDP",
    "Airport ID": "5488",
    "Latitude": "56.5492",
    "Timezone": "22",
    "DST": "-4",
    "destinations": ["188"]
}, {
    "City": "Chesterfield Inlet",
    "DBTZ": "A",
    "Name": "Chesterfield Inlet Airport",
    "Country": "Canada",
    "IATA/FAA": "YCS",
    "Longitude": "-90.7311",
    "ICAO": "CYCS",
    "Airport ID": "5487",
    "Latitude": "63.3469",
    "Timezone": "32",
    "DST": "-6",
    "destinations": ["29", "132", "41"]
}, {
    "City": "Dili",
    "DBTZ": "U",
    "Name": "Presidente Nicolau Lobato Intl",
    "Country": "East Timor",
    "IATA/FAA": "DIL",
    "Longitude": "125.524719",
    "ICAO": "WPDL",
    "Airport ID": "3310",
    "Latitude": "-8.546553",
    "Timezone": "25",
    "DST": "9",
    "destinations": ["3316", "3999", "3940"]
}, {
    "City": "Corozal",
    "DBTZ": "N",
    "Name": "Municipal",
    "Country": "Belize",
    "IATA/FAA": "CZH",
    "Longitude": "-88.4119",
    "ICAO": "",
    "Airport ID": "7029",
    "Latitude": "18.3822",
    "Timezone": "40",
    "DST": "-6",
    "destinations": ["4279"]
}, {
    "City": "Attawapiskat",
    "DBTZ": "A",
    "Name": "Attawapiskat Airport",
    "Country": "Canada",
    "IATA/FAA": "YAT",
    "Longitude": "-82.4319",
    "ICAO": "CYAT",
    "Airport ID": "5482",
    "Latitude": "52.9275",
    "Timezone": "31",
    "DST": "-5",
    "destinations": ["5522", "5543"]
}, {
    "City": "Kangirsuk",
    "DBTZ": "A",
    "Name": "Kangirsuk Airport",
    "Country": "Canada",
    "IATA/FAA": "YKG",
    "Longitude": "-69.9992",
    "ICAO": "CYAS",
    "Airport ID": "5481",
    "Latitude": "60.0272",
    "Timezone": "403",
    "DST": "-5",
    "destinations": ["5508", "5499", "154"]
}, {
    "City": "Kasabonika",
    "DBTZ": "A",
    "Name": "Kasabonika Airport",
    "Country": "Canada",
    "IATA/FAA": "XKS",
    "Longitude": "-88.6428",
    "ICAO": "CYAQ",
    "Airport ID": "5480",
    "Latitude": "53.5247",
    "Timezone": "672",
    "DST": "-5",
    "destinations": ["5531", "169"]
}, {
    "City": "Van",
    "DBTZ": "E",
    "Name": "Van",
    "Country": "Turkey",
    "IATA/FAA": "VAN",
    "Longitude": "43.3323",
    "ICAO": "LTCI",
    "Airport ID": "1728",
    "Latitude": "38.468219",
    "Timezone": "5480",
    "DST": "2",
    "destinations": ["1685", "4317", "1682", "1701"]
}, {
    "City": "Batman",
    "DBTZ": "E",
    "Name": "Batman",
    "Country": "Turkey",
    "IATA/FAA": "BAL",
    "Longitude": "41.116583",
    "ICAO": "LTCJ",
    "Airport ID": "1729",
    "Latitude": "37.928969",
    "Timezone": "1822",
    "DST": "2",
    "destinations": ["4317", "1682", "1701"]
}, {
    "City": "Trabzon",
    "DBTZ": "E",
    "Name": "Trabzon",
    "Country": "Turkey",
    "IATA/FAA": "TZX",
    "Longitude": "39.789728",
    "ICAO": "LTCG",
    "Airport ID": "1726",
    "Latitude": "40.995108",
    "Timezone": "104",
    "DST": "2",
    "destinations": ["1701", "1685", "1706", "2965", "1688", "4317", "1682"]
}, {
    "City": "Erzurum",
    "DBTZ": "E",
    "Name": "Erzurum",
    "Country": "Turkey",
    "IATA/FAA": "ERZ",
    "Longitude": "41.170166",
    "ICAO": "LTCE",
    "Airport ID": "1724",
    "Latitude": "39.956501",
    "Timezone": "5763",
    "DST": "2",
    "destinations": ["1701", "4317", "1682"]
}, {
    "City": "Diyabakir",
    "DBTZ": "E",
    "Name": "Diyarbakir",
    "Country": "Turkey",
    "IATA/FAA": "DIY",
    "Longitude": "40.201019",
    "ICAO": "LTCC",
    "Airport ID": "1722",
    "Latitude": "37.893897",
    "Timezone": "2251",
    "DST": "2",
    "destinations": ["1701", "1706", "4317", "1682"]
}, {
    "City": "Erzincan",
    "DBTZ": "E",
    "Name": "Erzincan",
    "Country": "Turkey",
    "IATA/FAA": "ERC",
    "Longitude": "39.527003",
    "ICAO": "LTCD",
    "Airport ID": "1723",
    "Latitude": "39.710203",
    "Timezone": "3783",
    "DST": "2",
    "destinations": ["4317", "1682", "1701"]
}, {
    "City": "Elazig",
    "DBTZ": "E",
    "Name": "Elazig",
    "Country": "Turkey",
    "IATA/FAA": "EZS",
    "Longitude": "39.291417",
    "ICAO": "LTCA",
    "Airport ID": "1721",
    "Latitude": "38.606925",
    "Timezone": "2927",
    "DST": "2",
    "destinations": ["1701", "1706", "4317", "1682"]
}, {
    "City": "Vernal",
    "DBTZ": "A",
    "Name": "Vernal Regional Airport",
    "Country": "United States",
    "IATA/FAA": "VEL",
    "Longitude": "-109.51",
    "ICAO": "KVEL",
    "Airport ID": "7076",
    "Latitude": "40.440833",
    "Timezone": "5278",
    "DST": "-7",
    "destinations": ["3536"]
}, {
    "City": "Mangalore",
    "DBTZ": "N",
    "Name": "Mangalore",
    "Country": "India",
    "IATA/FAA": "IXE",
    "Longitude": "74.890069",
    "ICAO": "VOML",
    "Airport ID": "3143",
    "Latitude": "12.961267",
    "Timezone": "337",
    "DST": "5.5",
    "destinations": ["3131", "2997", "2188", "2179", "2064", "2241", "2191"]
}, {
    "City": "Bagotville",
    "DBTZ": "A",
    "Name": "Bagotville",
    "Country": "Canada",
    "IATA/FAA": "YBG",
    "Longitude": "-70.996391",
    "ICAO": "CYBG",
    "Airport ID": "28",
    "Latitude": "48.330555",
    "Timezone": "522",
    "DST": "-5",
    "destinations": ["146", "71", "111", "202"]
}, {
    "City": "Parana",
    "DBTZ": "N",
    "Name": "General Urquiza",
    "Country": "Argentina",
    "IATA/FAA": "PRA",
    "Longitude": "-60.480361",
    "ICAO": "SAAP",
    "Airport ID": "2439",
    "Latitude": "-31.794778",
    "Timezone": "243",
    "DST": "-3",
    "destinations": ["2442"]
}, {
    "City": "Baker Lake",
    "DBTZ": "A",
    "Name": "Baker Lake",
    "Country": "Canada",
    "IATA/FAA": "YBK",
    "Longitude": "-96.077778",
    "ICAO": "CYBK",
    "Airport ID": "29",
    "Latitude": "64.298889",
    "Timezone": "59",
    "DST": "-6",
    "destinations": ["5487", "5534", "132", "147"]
}, {
    "City": "Fort Simpson",
    "DBTZ": "A",
    "Name": "Fort Simpson",
    "Country": "Canada",
    "IATA/FAA": "YFS",
    "Longitude": "-121.236525",
    "ICAO": "CYFS",
    "Airport ID": "60",
    "Latitude": "61.760153",
    "Timezone": "555",
    "DST": "-7",
    "destinations": ["196"]
}, {
    "City": "Marktoberdorf",
    "DBTZ": "E",
    "Name": "Marktoberdorf BF",
    "Country": "Germany",
    "IATA/FAA": "OAL",
    "Longitude": "10.627",
    "ICAO": "MARK",
    "Airport ID": "7798",
    "Latitude": "47.78",
    "Timezone": "1200",
    "DST": "1",
    "destinations": ["2548"]
}, {
    "City": "Goa",
    "DBTZ": "N",
    "Name": "Goa",
    "Country": "India",
    "IATA/FAA": "GOI",
    "Longitude": "73.831422",
    "ICAO": "VAGO",
    "Airport ID": "3007",
    "Latitude": "15.380833",
    "Timezone": "184",
    "DST": "5.5",
    "destinations": ["2994", "3131", "2997", "3093", "3141", "3144", "2188", "2176", "3017", "2191", "2241", "4029"]
}, {
    "City": "Gaspe",
    "DBTZ": "A",
    "Name": "Gaspe",
    "Country": "Canada",
    "IATA/FAA": "YGP",
    "Longitude": "-64.478611",
    "ICAO": "CYGP",
    "Airport ID": "63",
    "Latitude": "48.775278",
    "Timezone": "108",
    "DST": "-5",
    "destinations": ["65", "111"]
}, {
    "City": "Baroda",
    "DBTZ": "N",
    "Name": "Vadodara",
    "Country": "India",
    "IATA/FAA": "BDQ",
    "Longitude": "73.226289",
    "ICAO": "VABO",
    "Airport ID": "3001",
    "Latitude": "22.336164",
    "Timezone": "129",
    "DST": "5.5",
    "destinations": ["2997", "3093"]
}, {
    "City": "Belgaum",
    "DBTZ": "N",
    "Name": "Belgaum",
    "Country": "India",
    "IATA/FAA": "IXG",
    "Longitude": "74.618292",
    "ICAO": "VABM",
    "Airport ID": "3000",
    "Latitude": "15.859286",
    "Timezone": "2487",
    "DST": "5.5",
    "destinations": ["3131"]
}, {
    "City": "Bhaunagar",
    "DBTZ": "N",
    "Name": "Bhavnagar",
    "Country": "India",
    "IATA/FAA": "BHU",
    "Longitude": "72.185181",
    "ICAO": "VABV",
    "Airport ID": "3003",
    "Latitude": "21.752206",
    "Timezone": "44",
    "DST": "5.5",
    "destinations": ["2997"]
}, {
    "City": "Bhopal",
    "DBTZ": "N",
    "Name": "Bhopal",
    "Country": "India",
    "IATA/FAA": "BHO",
    "Longitude": "77.337375",
    "ICAO": "VABP",
    "Airport ID": "3002",
    "Latitude": "23.287467",
    "Timezone": "1719",
    "DST": "5.5",
    "destinations": ["2997", "3093"]
}, {
    "City": "Holman Island",
    "DBTZ": "A",
    "Name": "Ulukhaktok Holman",
    "Country": "Canada",
    "IATA/FAA": "YHI",
    "Longitude": "-117.806111",
    "ICAO": "CYHI",
    "Airport ID": "68",
    "Latitude": "70.762778",
    "Timezone": "117",
    "DST": "-7",
    "destinations": ["54", "139", "37"]
}, {
    "City": "Gjoa Haven",
    "DBTZ": "A",
    "Name": "Gjoa Haven",
    "Country": "Canada",
    "IATA/FAA": "YHK",
    "Longitude": "-95.849722",
    "ICAO": "CYHK",
    "Airport ID": "69",
    "Latitude": "68.635556",
    "Timezone": "152",
    "DST": "-7",
    "destinations": ["183", "196", "32"]
}, {
    "City": "Arcata CA",
    "DBTZ": "A",
    "Name": "Arcata",
    "Country": "United States",
    "IATA/FAA": "ACV",
    "Longitude": "-124.108611",
    "ICAO": "KACV",
    "Airport ID": "4384",
    "Latitude": "40.978111",
    "Timezone": "221",
    "DST": "-8",
    "destinations": ["5727", "3469", "3817"]
}, {
    "City": "Jabalpur",
    "DBTZ": "N",
    "Name": "Jabalpur",
    "Country": "India",
    "IATA/FAA": "JLR",
    "Longitude": "80.052047",
    "ICAO": "VAJB",
    "Airport ID": "3009",
    "Latitude": "23.177817",
    "Timezone": "1624",
    "DST": "5.5",
    "destinations": ["3093", "2997"]
}, {
    "City": "Indore",
    "DBTZ": "N",
    "Name": "Devi Ahilyabai Holkar",
    "Country": "India",
    "IATA/FAA": "IDR",
    "Longitude": "75.801086",
    "ICAO": "VAID",
    "Airport ID": "3008",
    "Latitude": "22.721786",
    "Timezone": "1850",
    "DST": "5.5",
    "destinations": ["2997", "3093", "3015", "3020", "3131", "3017", "3141"]
}, {
    "City": "Brno",
    "DBTZ": "E",
    "Name": "Turany",
    "Country": "Czech Republic",
    "IATA/FAA": "BRQ",
    "Longitude": "16.694433",
    "ICAO": "LKTB",
    "Airport ID": "1588",
    "Latitude": "49.151269",
    "Timezone": "778",
    "DST": "1",
    "destinations": ["548"]
}, {
    "City": "South Naknek",
    "DBTZ": "A",
    "Name": "South Naknek Airport",
    "Country": "United States",
    "IATA/FAA": "WSN",
    "Longitude": "-157.0025",
    "ICAO": "PFWS",
    "Airport ID": "7159",
    "Latitude": "58.702222",
    "Timezone": "162",
    "DST": "-9",
    "destinations": ["7154"]
}, {
    "City": "Latacunga",
    "DBTZ": "S",
    "Name": "Cotopaxi International Airport",
    "Country": "Ecuador",
    "IATA/FAA": "LTX",
    "Longitude": "-78.3657",
    "ICAO": "",
    "Airport ID": "7695",
    "Latitude": "-0.5425",
    "Timezone": "9205",
    "DST": "-5",
    "destinations": ["2673", "2670"]
}, {
    "City": "Ostrava",
    "DBTZ": "E",
    "Name": "Mosnov",
    "Country": "Czech Republic",
    "IATA/FAA": "OSR",
    "Longitude": "18.111053",
    "ICAO": "LKMT",
    "Airport ID": "1582",
    "Latitude": "49.696292",
    "Timezone": "844",
    "DST": "1",
    "destinations": ["548", "1587", "1382"]
}, {
    "City": "Chignik Lagoon",
    "DBTZ": "A",
    "Name": "Chignik Lagoon Airport",
    "Country": "United States",
    "IATA/FAA": "KCL",
    "Longitude": "-158.534167",
    "ICAO": "",
    "Airport ID": "7155",
    "Latitude": "56.311111",
    "Timezone": "25",
    "DST": "-9",
    "destinations": ["7153"]
}, {
    "City": "Egegik",
    "DBTZ": "A",
    "Name": "Egegik Airport",
    "Country": "United States",
    "IATA/FAA": "EGX",
    "Longitude": "-157.375556",
    "ICAO": "PAII",
    "Airport ID": "7154",
    "Latitude": "58.185556",
    "Timezone": "92",
    "DST": "-9",
    "destinations": ["3794"]
}, {
    "City": "Chignik Lake",
    "DBTZ": "A",
    "Name": "Chignik Lake Airport",
    "Country": "United States",
    "IATA/FAA": "KCQ",
    "Longitude": "-158.775278",
    "ICAO": "",
    "Airport ID": "7153",
    "Latitude": "56.255",
    "Timezone": "50",
    "DST": "-9",
    "destinations": ["7157"]
}, {
    "City": "Prague",
    "DBTZ": "E",
    "Name": "Ruzyne",
    "Country": "Czech Republic",
    "IATA/FAA": "PRG",
    "Longitude": "14.26",
    "ICAO": "LKPR",
    "Airport ID": "1587",
    "Latitude": "50.100833",
    "Timezone": "1247",
    "DST": "1",
    "destinations": ["344", "345", "342", "4363", "3941", "1335", "507", "1382", "1353", "1562", "1551", "421", "1555", "1529", "2954", "3953", "737", "636", "609", "644", "599", "2188", "340", "1194", "548", "591", "1218", "1229", "2922", "1569", "1739", "3930", "580", "1606", "346", "679", "535", "523", "517", "478", "521", "3959", "4053", "1665", "1678", "1590", "1230", "2908", "302", "1745", "1489", "2968", "1652", "3964", "4274", "1452", "2939", "1746", "4118", "2948", "1524", "1354", "1582", "1657", "2973", "3998", "1472", "2964", "2985", "2975", "1435", "2983", "351", "2992", "1246", "1613", "1688", "4091", "1054", "1206", "1056", "2960", "4078", "1701", "1386", "1638", "490", "502", "1418", "1525", "1501", "492", "1561", "1539"]
}, {
    "City": "Pardubice",
    "DBTZ": "E",
    "Name": "Pardubice",
    "Country": "Czech Republic",
    "IATA/FAA": "PED",
    "Longitude": "15.738647",
    "ICAO": "LKPD",
    "Airport ID": "1584",
    "Latitude": "50.013419",
    "Timezone": "741",
    "DST": "1",
    "destinations": ["4029", "2948"]
}, {
    "City": "Point Baker",
    "DBTZ": "A",
    "Name": "Point Baker Seaplane Base",
    "Country": "United States",
    "IATA/FAA": "KPB",
    "Longitude": "-133.6225",
    "ICAO": "",
    "Airport ID": "7150",
    "Latitude": "56.351944",
    "Timezone": "0",
    "DST": "-9",
    "destinations": ["7151"]
}, {
    "City": "Lubumashi",
    "DBTZ": "U",
    "Name": "Lubumbashi Intl",
    "Country": "Congo (Kinshasa)",
    "IATA/FAA": "FBM",
    "Longitude": "27.530889",
    "ICAO": "FZQA",
    "Airport ID": "1038",
    "Latitude": "-11.591333",
    "Timezone": "4295",
    "DST": "2",
    "destinations": ["1020", "813", "1042", "1043", "910", "4059"]
}, {
    "City": "Southend",
    "DBTZ": "E",
    "Name": "Southend",
    "Country": "United Kingdom",
    "IATA/FAA": "SEN",
    "Longitude": "0.695556",
    "ICAO": "EGMC",
    "Airport ID": "508",
    "Latitude": "51.571389",
    "Timezone": "49",
    "DST": "0",
    "destinations": ["599", "1230", "1212", "580", "1218", "535", "1626", "1665", "1225", "499", "669", "3998", "337", "1551"]
}, {
    "City": "Isiro",
    "DBTZ": "U",
    "Name": "Matari",
    "Country": "Congo (Kinshasa)",
    "IATA/FAA": "IRP",
    "Longitude": "27.588253",
    "ICAO": "FZJH",
    "Airport ID": "1032",
    "Latitude": "2.827606",
    "Timezone": "2438",
    "DST": "2",
    "destinations": ["1031"]
}, {
    "City": "Bunia",
    "DBTZ": "U",
    "Name": "Bunia",
    "Country": "Congo (Kinshasa)",
    "IATA/FAA": "BUX",
    "Longitude": "30.220833",
    "ICAO": "FZKA",
    "Airport ID": "1033",
    "Latitude": "1.565719",
    "Timezone": "4045",
    "DST": "2",
    "destinations": ["1036", "1032"]
}, {
    "City": "Kisangani",
    "DBTZ": "U",
    "Name": "Kisangani Simisini",
    "Country": "Congo (Kinshasa)",
    "IATA/FAA": "FKI",
    "Longitude": "25.155014",
    "ICAO": "FZIA",
    "Airport ID": "1031",
    "Latitude": "0.5175",
    "Timezone": "1289",
    "DST": "2",
    "destinations": ["1020", "1036"]
}, {
    "City": "Goma",
    "DBTZ": "U",
    "Name": "Goma",
    "Country": "Congo (Kinshasa)",
    "IATA/FAA": "GOM",
    "Longitude": "29.238464",
    "ICAO": "FZNA",
    "Airport ID": "1036",
    "Latitude": "-1.670814",
    "Timezone": "5089",
    "DST": "2",
    "destinations": ["1035", "1031"]
}, {
    "City": "Kindu",
    "DBTZ": "U",
    "Name": "Kindu",
    "Country": "Congo (Kinshasa)",
    "IATA/FAA": "KND",
    "Longitude": "25.915361",
    "ICAO": "FZOA",
    "Airport ID": "1037",
    "Latitude": "-2.919178",
    "Timezone": "1630",
    "DST": "2",
    "destinations": ["1035", "1020"]
}, {
    "City": "Bukavu/kavumu",
    "DBTZ": "U",
    "Name": "Bukavu Kavumu",
    "Country": "Congo (Kinshasa)",
    "IATA/FAA": "BKY",
    "Longitude": "28.808803",
    "ICAO": "FZMA",
    "Airport ID": "1035",
    "Latitude": "-2.308978",
    "Timezone": "5643",
    "DST": "2",
    "destinations": ["1037"]
}, {
    "City": "Alesund",
    "DBTZ": "E",
    "Name": "Vigra",
    "Country": "Norway",
    "IATA/FAA": "AES",
    "Longitude": "6.110164",
    "ICAO": "ENAL",
    "Airport ID": "630",
    "Latitude": "62.560372",
    "Timezone": "69",
    "DST": "1",
    "destinations": ["580", "3953", "1212", "502", "644", "636", "609", "665", "668", "3959"]
}, {
    "City": "Andoya",
    "DBTZ": "E",
    "Name": "Andenes",
    "Country": "Norway",
    "IATA/FAA": "ANX",
    "Longitude": "16.144167",
    "ICAO": "ENAN",
    "Airport ID": "631",
    "Latitude": "69.2925",
    "Timezone": "43",
    "DST": "1",
    "destinations": ["635", "641", "663"]
}, {
    "City": "Alta",
    "DBTZ": "E",
    "Name": "Alta",
    "Country": "Norway",
    "IATA/FAA": "ALF",
    "Longitude": "23.371667",
    "ICAO": "ENAT",
    "Airport ID": "632",
    "Latitude": "69.976111",
    "Timezone": "9",
    "DST": "1",
    "destinations": ["644", "663", "4325", "649", "653", "5588", "4328"]
}, {
    "City": "Kalibo",
    "DBTZ": "N",
    "Name": "Kalibo",
    "Country": "Philippines",
    "IATA/FAA": "KLO",
    "Longitude": "122.376294",
    "ICAO": "RPVK",
    "Airport ID": "2430",
    "Latitude": "11.679431",
    "Timezone": "14",
    "DST": "8",
    "destinations": ["2397", "4206", "3930", "3304", "4140", "3316", "2372", "2276"]
}, {
    "City": "Bronnoysund",
    "DBTZ": "E",
    "Name": "Bronnoy",
    "Country": "Norway",
    "IATA/FAA": "BNN",
    "Longitude": "12.2175",
    "ICAO": "ENBN",
    "Airport ID": "634",
    "Latitude": "65.461111",
    "Timezone": "25",
    "DST": "1",
    "destinations": ["636", "5582", "644", "662", "665"]
}, {
    "City": "Bodo",
    "DBTZ": "E",
    "Name": "Bodo",
    "Country": "Norway",
    "IATA/FAA": "BOO",
    "Longitude": "14.365278",
    "ICAO": "ENBO",
    "Airport ID": "635",
    "Latitude": "67.269167",
    "Timezone": "42",
    "DST": "1",
    "destinations": ["644", "663", "665", "631", "636", "634", "641", "5580", "652", "5582", "4349", "5584", "4252", "662", "5587"]
}, {
    "City": "Aviles",
    "DBTZ": "E",
    "Name": "Asturias",
    "Country": "Spain",
    "IATA/FAA": "OVD",
    "Longitude": "-6.034622",
    "ICAO": "LEAS",
    "Airport ID": "1214",
    "Latitude": "43.563567",
    "Timezone": "416",
    "DST": "1",
    "destinations": ["1230", "1218", "1382", "1229", "3998", "1253", "1246", "548", "1055", "1056"]
}, {
    "City": "Batsfjord",
    "DBTZ": "E",
    "Name": "Batsfjord",
    "Country": "Norway",
    "IATA/FAA": "BJF",
    "Longitude": "29.6925",
    "ICAO": "ENBS",
    "Airport ID": "637",
    "Latitude": "70.600278",
    "Timezone": "490",
    "DST": "1",
    "destinations": ["4350", "4325", "4327", "5589", "4328"]
}, {
    "City": "Kristiansand",
    "DBTZ": "E",
    "Name": "Kjevik",
    "Country": "Norway",
    "IATA/FAA": "KRS",
    "Longitude": "8.085369",
    "ICAO": "ENCN",
    "Airport ID": "638",
    "Latitude": "58.204214",
    "Timezone": "57",
    "DST": "1",
    "destinations": ["580", "644", "1212", "609", "668", "636", "666", "665"]
}, {
    "City": "Barcelona",
    "DBTZ": "E",
    "Name": "Barcelona",
    "Country": "Spain",
    "IATA/FAA": "BCN",
    "Longitude": "2.078464",
    "ICAO": "LEBL",
    "Airport ID": "1218",
    "Latitude": "41.297078",
    "Timezone": "12",
    "DST": "1",
    "destinations": ["1050", "1074", "5673", "1080", "344", "345", "352", "342", "350", "3941", "3797", "507", "3576", "3752", "351", "1613", "193", "1382", "210", "231", "3988", "2709", "421", "1555", "1529", "3998", "1057", "2954", "502", "3953", "2241", "737", "636", "609", "687", "644", "337", "664", "679", "599", "596", "2188", "340", "1194", "1055", "1230", "1525", "469", "1538", "302", "1489", "1367", "1553", "304", "535", "523", "1066", "1051", "1225", "1054", "491", "1231", "478", "699", "1636", "537", "657", "1243", "1251", "548", "1253", "1056", "1526", "1539", "1247", "3959", "8414", "1226", "580", "585", "591", "628", "1212", "2170", "1403", "1216", "1264", "1501", "1509", "488", "1200", "1084", "4029", "338", "373", "1242", "1563", "1528", "1223", "2944", "2939", "1220", "2948", "1213", "348", "6756", "1399", "1638", "629", "1335", "1229", "1606", "1353", "346", "1524", "1561", "1354", "1418", "347", "1386", "1657", "1214", "1512", "1587", "1562", "1075", "6757", "1415", "1238", "666", "1273", "1590", "1551", "1250", "1208", "1678", "3494", "534", "517", "4053", "1665", "1128", "4317", "2223", "2207", "2564", "3316", "2985", "1676", "1701", "146", "287", "465", "490", "492", "521", "508", "1219", "1652", "668", "671", "674", "1663"]
}, {
    "City": "Kassel",
    "DBTZ": "E",
    "Name": "Kassel Calden",
    "Country": "Germany",
    "IATA/FAA": "KSF",
    "Longitude": "9.377631",
    "ICAO": "EDVK",
    "Airport ID": "400",
    "Latitude": "51.408394",
    "Timezone": "908",
    "DST": "1",
    "destinations": ["1688", "3998"]
}, {
    "City": "La Gomera",
    "DBTZ": "E",
    "Name": "La Gomera Airport",
    "Country": "Spain",
    "IATA/FAA": "GMZ",
    "Longitude": "-17.2146",
    "ICAO": "GCGM",
    "Airport ID": "5659",
    "Latitude": "28.0296",
    "Timezone": "718",
    "DST": "0",
    "destinations": ["1057"]
}, {
    "City": "Kogalym",
    "DBTZ": "N",
    "Name": "Kogalym International",
    "Country": "Russia",
    "IATA/FAA": "KGP",
    "Longitude": "74.53",
    "ICAO": "USRK",
    "Airport ID": "4373",
    "Latitude": "62.18",
    "Timezone": "220",
    "DST": "6",
    "destinations": ["2975"]
}, {
    "City": "Alanya",
    "DBTZ": "E",
    "Name": "Gazipasa Airport",
    "Country": "Turkey",
    "IATA/FAA": "GZP",
    "Longitude": "32.3014",
    "ICAO": "LTFG",
    "Airport ID": "8266",
    "Latitude": "36.2993",
    "Timezone": "92",
    "DST": "2",
    "destinations": ["1701", "421", "4317", "609", "644", "1682"]
}, {
    "City": "Naryan-Mar",
    "DBTZ": "N",
    "Name": "Naryan-Mar",
    "Country": "Russia",
    "IATA/FAA": "NNM",
    "Longitude": "53.051016",
    "ICAO": "ULAM",
    "Airport ID": "4371",
    "Latitude": "67.380537",
    "Timezone": "20",
    "DST": "4",
    "destinations": ["4362", "6142", "2989"]
}, {
    "City": "Tshikapa",
    "DBTZ": "N",
    "Name": "Tshikapa Airport",
    "Country": "Congo (Kinshasa)",
    "IATA/FAA": "TSH",
    "Longitude": "20.7947",
    "ICAO": "FZUK",
    "Airport ID": "5652",
    "Latitude": "-6.43833",
    "Timezone": "1595",
    "DST": "2",
    "destinations": ["1020", "1042"]
}, {
    "City": "Uraj",
    "DBTZ": "N",
    "Name": "Uraj",
    "Country": "Russia",
    "IATA/FAA": "URJ",
    "Longitude": "64.83",
    "ICAO": "USHU",
    "Airport ID": "4377",
    "Latitude": "60.1",
    "Timezone": "186",
    "DST": "6",
    "destinations": ["2975", "6138", "4111"]
}, {
    "City": "Krasnoyarsk",
    "DBTZ": "N",
    "Name": "Emelyanovo",
    "Country": "Russia",
    "IATA/FAA": "KJA",
    "Longitude": "92.475",
    "ICAO": "UNKL",
    "Airport ID": "4374",
    "Latitude": "56.18",
    "Timezone": "940",
    "DST": "8",
    "destinations": ["2925", "3364", "2983", "2937", "6954", "4078", "2912", "2913", "2923", "4029", "2927", "2948", "2985", "2979", "6147", "6150", "2981", "2975", "2988", "2965", "2188", "3964", "2922", "6121", "2960", "4352", "4297", "2938"]
}, {
    "City": "Karaganda",
    "DBTZ": "U",
    "Name": "Sary-Arka",
    "Country": "Kazakhstan",
    "IATA/FAA": "KGF",
    "Longitude": "73.334444",
    "ICAO": "UAKK",
    "Airport ID": "4375",
    "Latitude": "49.670833",
    "Timezone": "1765",
    "DST": "6",
    "destinations": ["2908", "4358", "6086", "2948", "2985", "4029"]
}, {
    "City": "Belfast",
    "DBTZ": "E",
    "Name": "Belfast Intl",
    "Country": "United Kingdom",
    "IATA/FAA": "BFS",
    "Longitude": "-6.215833",
    "ICAO": "EGAA",
    "Airport ID": "465",
    "Latitude": "54.6575",
    "Timezone": "268",
    "DST": "0",
    "destinations": ["3494", "1055", "1230", "1212", "1200", "1626", "1227", "3998", "1236", "1056", "1715", "1197", "7447", "580", "1218", "469", "490", "1382", "535", "534", "669", "502", "491", "492", "478", "1606", "1354", "521", "548"]
}, {
    "City": "Oklahoma City",
    "DBTZ": "A",
    "Name": "Will Rogers World",
    "Country": "United States",
    "IATA/FAA": "OKC",
    "Longitude": "-97.600733",
    "ICAO": "KOKC",
    "Airport ID": "3863",
    "Latitude": "35.393089",
    "Timezone": "1295",
    "DST": "-6",
    "destinations": ["3670", "3484", "3830", "3682", "3645", "3858", "3536", "3751", "3849", "3502", "3566", "3877", "3747", "3678", "4167", "3494", "3714", "3550", "3469", "3462"]
}, {
    "City": "Philipsburg",
    "DBTZ": "U",
    "Name": "Princess Juliana Intl",
    "Country": "Netherlands Antilles",
    "IATA/FAA": "SXM",
    "Longitude": "-63.1089",
    "ICAO": "TNCM",
    "Airport ID": "2899",
    "Latitude": "18.040953",
    "Timezone": "14",
    "DST": "-4",
    "destinations": ["2877", "2881", "1762", "2897", "1897", "3876", "3797", "3576", "3752", "1382", "3448", "2890", "2875", "1779", "1871", "3682", "2874", "2903", "2891", "2893", "2883", "2884", "3533", "6460", "2900", "1386", "3494", "3714", "3830", "2898", "6079", "4249", "193"]
}, {
    "City": "Oranjestad",
    "DBTZ": "U",
    "Name": "F D Roosevelt",
    "Country": "Netherlands Antilles",
    "IATA/FAA": "EUX",
    "Longitude": "-62.979439",
    "ICAO": "TNCE",
    "Airport ID": "2898",
    "Latitude": "17.496492",
    "Timezone": "129",
    "DST": "-4",
    "destinations": ["2899"]
}, {
    "City": "Oranjestad",
    "DBTZ": "U",
    "Name": "Reina Beatrix Intl",
    "Country": "Aruba",
    "IATA/FAA": "AUA",
    "Longitude": "-70.015221",
    "ICAO": "TNCA",
    "Airport ID": "2895",
    "Latitude": "12.501389",
    "Timezone": "60",
    "DST": "-4",
    "destinations": ["2844", "2848", "2851", "2897", "1759", "3576", "2868", "3876", "3752", "193", "2709", "3448", "3797", "1871", "3682", "3697", "3849", "3878", "2896", "5811", "3533", "580", "2773", "3494", "3714"]
}, {
    "City": "Hewandorra",
    "DBTZ": "U",
    "Name": "Hewanorra Intl",
    "Country": "Saint Lucia",
    "IATA/FAA": "UVF",
    "Longitude": "-60.952597",
    "ICAO": "TLPL",
    "Airport ID": "2894",
    "Latitude": "13.733194",
    "Timezone": "14",
    "DST": "-4",
    "destinations": ["3876", "3576", "193", "3797", "2882", "502", "2902", "2875", "3682", "3494", "2874"]
}, {
    "City": "Willemstad",
    "DBTZ": "U",
    "Name": "Hato",
    "Country": "Netherlands Antilles",
    "IATA/FAA": "CUR",
    "Longitude": "-68.959803",
    "ICAO": "TNCC",
    "Airport ID": "2897",
    "Latitude": "12.188853",
    "Timezone": "29",
    "DST": "-4",
    "destinations": ["2895", "1762", "2896", "2824", "2851", "3876", "1779", "2844", "2848", "2745", "3576", "2773", "2902", "2899", "2868", "345", "2709", "1871", "580"]
}, {
    "City": "Kralendijk",
    "DBTZ": "U",
    "Name": "Flamingo",
    "Country": "Netherlands Antilles",
    "IATA/FAA": "BON",
    "Longitude": "-68.268511",
    "ICAO": "TNCB",
    "Airport ID": "2896",
    "Latitude": "12.131044",
    "Timezone": "20",
    "DST": "-4",
    "destinations": ["2897", "2895", "3682", "580", "3494", "3550"]
}, {
    "City": "Basse Terre",
    "DBTZ": "U",
    "Name": "Robert L Bradshaw",
    "Country": "Saint Kitts and Nevis",
    "IATA/FAA": "SKB",
    "Longitude": "-62.718667",
    "ICAO": "TKPK",
    "Airport ID": "2891",
    "Latitude": "17.311194",
    "Timezone": "170",
    "DST": "-4",
    "destinations": ["3576", "2874", "6079", "2890", "2899", "1953"]
}, {
    "City": "San Juan",
    "DBTZ": "U",
    "Name": "Luis Munoz Marin Intl",
    "Country": "Puerto Rico",
    "IATA/FAA": "SJU",
    "Longitude": "-66.001833",
    "ICAO": "TJSJ",
    "Airport ID": "2890",
    "Latitude": "18.439417",
    "Timezone": "9",
    "DST": "-4",
    "destinations": ["2900", "6814", "2903", "2888", "6079", "2883", "2884", "6080", "6788", "2851", "3876", "3670", "2877", "2878", "3797", "3576", "3830", "3752", "2881", "3682", "2709", "3825", "3448", "3520", "3494", "3533", "3712", "3878", "3722", "1760", "1762", "1764", "2899", "3646", "1759", "2891", "1871", "340", "3645", "3734", "5810", "1754", "1761", "2874", "6460", "3714", "3550", "3849", "193"]
}, {
    "City": "Castries",
    "DBTZ": "U",
    "Name": "George F L Charles",
    "Country": "Saint Lucia",
    "IATA/FAA": "SLU",
    "Longitude": "-60.992936",
    "ICAO": "TLPC",
    "Airport ID": "2893",
    "Latitude": "14.020228",
    "Timezone": "22",
    "DST": "-4",
    "destinations": ["2878", "2902", "2874", "2875", "2877", "2907"]
}, {
    "City": "Kithira",
    "DBTZ": "E",
    "Name": "Kithira",
    "Country": "Greece",
    "IATA/FAA": "KIT",
    "Longitude": "23.016978",
    "ICAO": "LGKC",
    "Airport ID": "1454",
    "Latitude": "36.274258",
    "Timezone": "1045",
    "DST": "2",
    "destinations": ["3941", "1488", "1456"]
}, {
    "City": "Keffallinia",
    "DBTZ": "E",
    "Name": "Kefallinia",
    "Country": "Greece",
    "IATA/FAA": "EFL",
    "Longitude": "20.500481",
    "ICAO": "LGKF",
    "Airport ID": "1455",
    "Latitude": "38.120069",
    "Timezone": "59",
    "DST": "2",
    "destinations": ["3941", "548", "1470", "580", "502"]
}, {
    "City": "Maio",
    "DBTZ": "U",
    "Name": "Maio",
    "Country": "Cape Verde",
    "IATA/FAA": "MMO",
    "Longitude": "-23.213703",
    "ICAO": "GVMA",
    "Airport ID": "1104",
    "Latitude": "15.155928",
    "Timezone": "36",
    "DST": "-1",
    "destinations": ["5674"]
}, {
    "City": "Sao Nocolau Island",
    "DBTZ": "U",
    "Name": "Preguica",
    "Country": "Cape Verde",
    "IATA/FAA": "SNE",
    "Longitude": "-24.284656",
    "ICAO": "GVSN",
    "Airport ID": "1105",
    "Latitude": "16.588356",
    "Timezone": "669",
    "DST": "-1",
    "destinations": ["1102", "1106"]
}, {
    "City": "Amilcar Cabral",
    "DBTZ": "U",
    "Name": "Amilcar Cabral Intl",
    "Country": "Cape Verde",
    "IATA/FAA": "SID",
    "Longitude": "-22.949444",
    "ICAO": "GVAC",
    "Airport ID": "1102",
    "Latitude": "16.741389",
    "Timezone": "177",
    "DST": "-1",
    "destinations": ["580", "629", "1382", "502", "478", "1638", "1103", "5674", "1105", "1106", "344", "340", "352", "346", "350"]
}, {
    "City": "Boa Vista",
    "DBTZ": "U",
    "Name": "Rabil",
    "Country": "Cape Verde",
    "IATA/FAA": "BVC",
    "Longitude": "-22.888897",
    "ICAO": "GVBA",
    "Airport ID": "1103",
    "Latitude": "16.136531",
    "Timezone": "69",
    "DST": "-1",
    "destinations": ["1102", "469", "502", "478", "1638", "5674"]
}, {
    "City": "Heraklion",
    "DBTZ": "E",
    "Name": "Nikos Kazantzakis",
    "Country": "Greece",
    "IATA/FAA": "HER",
    "Longitude": "25.180297",
    "ICAO": "LGIR",
    "Airport ID": "1452",
    "Latitude": "35.339719",
    "Timezone": "115",
    "DST": "2",
    "destinations": ["344", "345", "352", "342", "350", "351", "3941", "1264", "302", "1382", "4029", "340", "2948", "1353", "346", "1472", "1486", "1590", "1613", "4053", "338", "1609", "348", "1611", "347", "1612", "1678", "1197", "337", "4191", "1458", "1469", "1399", "580", "586", "591", "629", "523", "534", "517", "478", "521", "1665", "1587", "2939", "2985", "469", "502", "1335", "1418", "1386", "490", "535", "492", "1524", "2988", "3953", "415", "3959"]
}, {
    "City": "Kastoria",
    "DBTZ": "E",
    "Name": "Aristotelis",
    "Country": "Greece",
    "IATA/FAA": "KSO",
    "Longitude": "21.282186",
    "ICAO": "LGKA",
    "Airport ID": "1453",
    "Latitude": "40.446294",
    "Timezone": "2167",
    "DST": "2",
    "destinations": ["3941"]
}, {
    "City": "Kos",
    "DBTZ": "E",
    "Name": "Kos",
    "Country": "Greece",
    "IATA/FAA": "KGS",
    "Longitude": "27.091667",
    "ICAO": "LGKO",
    "Airport ID": "1458",
    "Latitude": "36.793335",
    "Timezone": "412",
    "DST": "2",
    "destinations": ["344", "3941", "4029", "4192", "1472", "345", "342", "348", "346", "351", "340", "352", "350", "337", "1525", "669", "3956", "517", "491", "657", "548", "302", "580", "629", "478", "502", "534", "4053"]
}, {
    "City": "Karpathos",
    "DBTZ": "E",
    "Name": "Karpathos",
    "Country": "Greece",
    "IATA/FAA": "AOK",
    "Longitude": "27.146008",
    "ICAO": "LGKP",
    "Airport ID": "1459",
    "Latitude": "35.421408",
    "Timezone": "66",
    "DST": "2",
    "destinations": ["3941", "1461", "1472"]
}, {
    "City": "Arba Minch",
    "DBTZ": "U",
    "Name": "Arba Minch",
    "Country": "Ethiopia",
    "IATA/FAA": "AMH",
    "Longitude": "37.590453",
    "ICAO": "HAAM",
    "Airport ID": "1109",
    "Latitude": "6.039389",
    "Timezone": "3895",
    "DST": "3",
    "destinations": ["1107"]
}, {
    "City": "Portland",
    "DBTZ": "A",
    "Name": "Portland Intl Jetport",
    "Country": "United States",
    "IATA/FAA": "PWM",
    "Longitude": "-70.309281",
    "ICAO": "KPWM",
    "Airport ID": "3862",
    "Latitude": "43.646161",
    "Timezone": "77",
    "DST": "-5",
    "destinations": ["3797", "3849"]
}, {
    "City": "Phon Savan",
    "DBTZ": "U",
    "Name": "Xieng Khouang",
    "Country": "Laos",
    "IATA/FAA": "XKH",
    "Longitude": "103.158333",
    "ICAO": "VLXK",
    "Airport ID": "4081",
    "Latitude": "19.449997",
    "Timezone": "3445",
    "DST": "7",
    "destinations": ["3120"]
}, {
    "City": "Hue",
    "DBTZ": "U",
    "Name": "Phu Bai",
    "Country": "Vietnam",
    "IATA/FAA": "HUI",
    "Longitude": "107.702614",
    "ICAO": "",
    "Airport ID": "4082",
    "Latitude": "16.401499",
    "Timezone": "48",
    "DST": "7",
    "destinations": ["3199", "3205"]
}, {
    "City": "Bismarck",
    "DBTZ": "A",
    "Name": "Bismarck Municipal Airport",
    "Country": "United States",
    "IATA/FAA": "BIS",
    "Longitude": "-100.757931",
    "ICAO": "KBIS",
    "Airport ID": "4083",
    "Latitude": "46.775842",
    "Timezone": "1661",
    "DST": "-6",
    "destinations": ["3858", "3751", "6505", "3877", "4167"]
}, {
    "City": "Yinchuan",
    "DBTZ": "U",
    "Name": "Yinchuan",
    "Country": "China",
    "IATA/FAA": "INC",
    "Longitude": "106.009167",
    "ICAO": "ZLIC",
    "Airport ID": "4085",
    "Latitude": "38.481944",
    "Timezone": "3600",
    "DST": "8",
    "destinations": ["3393", "3395", "3400", "3372", "4380", "3386", "3364", "3406", "6347", "4108", "3399", "3376", "3379", "6366", "3375", "3371", "6345", "3388", "3369", "4149", "2276", "6432", "6434", "3394", "6431", "8630", "3077", "3382", "4097"]
}, {
    "City": "Mae Hong Son",
    "DBTZ": "U",
    "Name": "Mae Hong Son",
    "Country": "Thailand",
    "IATA/FAA": "HGN",
    "Longitude": "97.975",
    "ICAO": "VTCH",
    "Airport ID": "4086",
    "Latitude": "19.301667",
    "Timezone": "929",
    "DST": "7",
    "destinations": ["3931"]
}, {
    "City": "Rapid City",
    "DBTZ": "A",
    "Name": "Rapid City Regional Airport",
    "Country": "United States",
    "IATA/FAA": "RAP",
    "Longitude": "-103.057222",
    "ICAO": "KRAP",
    "Airport ID": "4087",
    "Latitude": "44.045278",
    "Timezone": "3204",
    "DST": "-7",
    "destinations": ["3670", "3858", "3536", "6505", "3877", "3751", "3830"]
}, {
    "City": "Carlsbad",
    "DBTZ": "A",
    "Name": "McClellan-Palomar Airport",
    "Country": "United States",
    "IATA/FAA": "CLD",
    "Longitude": "-117.1648",
    "ICAO": "KCRQ",
    "Airport ID": "4088",
    "Latitude": "33.0742",
    "Timezone": "328",
    "DST": "-8",
    "destinations": ["3484"]
}, {
    "City": "Flint",
    "DBTZ": "A",
    "Name": "Bishop International",
    "Country": "United States",
    "IATA/FAA": "FNT",
    "Longitude": "-83.743629",
    "ICAO": "KFNT",
    "Airport ID": "4089",
    "Latitude": "42.965424",
    "Timezone": "782",
    "DST": "-5",
    "destinations": ["3830", "3682", "3858", "3849", "3878", "3646", "3486", "3877"]
}, {
    "City": "New Orleans",
    "DBTZ": "A",
    "Name": "Louis Armstrong New Orleans Intl",
    "Country": "United States",
    "IATA/FAA": "MSY",
    "Longitude": "-90.258028",
    "ICAO": "KMSY",
    "Airport ID": "3861",
    "Latitude": "29.993389",
    "Timezone": "4",
    "DST": "-6",
    "destinations": ["3645", "3797", "3697", "3473", "3858", "3673", "3876", "3520", "3670", "3576", "3830", "3752", "3751", "3494", "3714", "3550", "193", "3682", "3484", "3536", "3448", "3488", "3849", "3502", "3875", "3533", "3566", "3877", "3458", "3878", "3747", "3717", "3731", "3678", "3646", "3486", "3469", "3690", "3462"]
}, {
    "City": "Unalaska",
    "DBTZ": "A",
    "Name": "Unalaska",
    "Country": "United States",
    "IATA/FAA": "DUT",
    "Longitude": "-166.5435",
    "ICAO": "PADU",
    "Airport ID": "3860",
    "Latitude": "53.900139",
    "Timezone": "22",
    "DST": "-9",
    "destinations": ["7195", "7196", "6134"]
}, {
    "City": "Santa Ana",
    "DBTZ": "A",
    "Name": "John Wayne Arpt Orange Co",
    "Country": "United States",
    "IATA/FAA": "SNA",
    "Longitude": "-117.868222",
    "ICAO": "KSNA",
    "Airport ID": "3867",
    "Latitude": "33.675667",
    "Timezone": "56",
    "DST": "-8",
    "destinations": ["1804", "1824", "3670", "3830", "3720", "3462", "3577", "3682", "3858", "3536", "156", "3751", "3877", "3453", "3469", "1840", "3494", "3550", "3566", "3748", "3817"]
}, {
    "City": "Valdez",
    "DBTZ": "A",
    "Name": "Valdez Pioneer Fld",
    "Country": "United States",
    "IATA/FAA": "VDZ",
    "Longitude": "-146.248342",
    "ICAO": "PAVD",
    "Airport ID": "3865",
    "Latitude": "61.133949",
    "Timezone": "118",
    "DST": "-9",
    "destinations": ["3774"]
}, {
    "City": "Albany",
    "DBTZ": "A",
    "Name": "Albany Intl",
    "Country": "United States",
    "IATA/FAA": "ALB",
    "Longitude": "-73.801692",
    "ICAO": "KALB",
    "Airport ID": "3864",
    "Latitude": "42.748267",
    "Timezone": "285",
    "DST": "-5",
    "destinations": ["3448", "3546", "3724", "3876", "3520", "3752", "3682", "3645", "3858", "3849", "3494", "3533", "3747", "3646", "3486", "3714", "3830", "3877", "3878"]
}, {
    "City": "Alamosa",
    "DBTZ": "A",
    "Name": "San Luis Valley Regional Airport",
    "Country": "United States",
    "IATA/FAA": "ALS",
    "Longitude": "-105.866667",
    "ICAO": "KALS",
    "Airport ID": "7073",
    "Latitude": "37.435",
    "Timezone": "7539",
    "DST": "-7",
    "destinations": ["3751", "3743"]
}, {
    "City": "Cap Haitien",
    "DBTZ": "U",
    "Name": "Cap Haitien Intl",
    "Country": "Haiti",
    "IATA/FAA": "CAP",
    "Longitude": "-72.194739",
    "ICAO": "MTCH",
    "Airport ID": "1895",
    "Latitude": "19.732989",
    "Timezone": "10",
    "DST": "-5",
    "destinations": ["1754", "1897"]
}, {
    "City": "Praya",
    "DBTZ": "N",
    "Name": "Lombok International Airport",
    "Country": "Indonesia",
    "IATA/FAA": "LOP",
    "Longitude": "116.276675",
    "ICAO": "WADL",
    "Airport ID": "8401",
    "Latitude": "-8.7573222",
    "Timezone": "52",
    "DST": "8",
    "destinations": ["3304", "3924", "3275", "3940", "3916", "3316", "3928", "3240", "3351", "3898"]
}, {
    "City": "Handan",
    "DBTZ": "N",
    "Name": "Hebei Handan Airport",
    "Country": "China",
    "IATA/FAA": "HDG",
    "Longitude": "114.43",
    "ICAO": "ZBHD",
    "Airport ID": "8407",
    "Latitude": "36.524",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3395", "3370", "3393", "3404", "3406", "3391"]
}, {
    "City": "Cape Girardeau",
    "DBTZ": "A",
    "Name": "Cape Girardeau Regional Airport",
    "Country": "United States",
    "IATA/FAA": "CGI",
    "Longitude": "-89.5708",
    "ICAO": "KCGI",
    "Airport ID": "5728",
    "Latitude": "37.2253",
    "Timezone": "342",
    "DST": "-6",
    "destinations": ["3678"]
}, {
    "City": "Sault Ste Marie",
    "DBTZ": "A",
    "Name": "Chippewa County International Airport",
    "Country": "United States",
    "IATA/FAA": "CIU",
    "Longitude": "-84.4724",
    "ICAO": "KCIU",
    "Airport ID": "5729",
    "Latitude": "46.2508",
    "Timezone": "800",
    "DST": "-5",
    "destinations": ["3645"]
}, {
    "City": "Ravensthorpe",
    "DBTZ": "U",
    "Name": "Ravensthorpe Airport",
    "Country": "Australia",
    "IATA/FAA": "RVT",
    "Longitude": "120.208056",
    "ICAO": "YNRV",
    "Airport ID": "7577",
    "Latitude": "-33.797222",
    "Timezone": "209",
    "DST": "8",
    "destinations": ["3351"]
}, {
    "City": "Bradford",
    "DBTZ": "A",
    "Name": "Bradford Regional Airport",
    "Country": "United States",
    "IATA/FAA": "BFD",
    "Longitude": "-78.6401",
    "ICAO": "KBFD",
    "Airport ID": "5722",
    "Latitude": "41.8031",
    "Timezone": "2143",
    "DST": "-5",
    "destinations": ["3486", "7059"]
}, {
    "City": "Scottsbluff",
    "DBTZ": "A",
    "Name": "Western Nebraska Regional Airport",
    "Country": "United States",
    "IATA/FAA": "BFF",
    "Longitude": "-103.596",
    "ICAO": "KBFF",
    "Airport ID": "5723",
    "Latitude": "41.874",
    "Timezone": "3967",
    "DST": "-7",
    "destinations": ["3751"]
}, {
    "City": "Alpena",
    "DBTZ": "A",
    "Name": "Alpena County Regional Airport",
    "Country": "United States",
    "IATA/FAA": "APN",
    "Longitude": "-83.5603",
    "ICAO": "KAPN",
    "Airport ID": "5720",
    "Latitude": "45.0781",
    "Timezone": "689",
    "DST": "-5",
    "destinations": ["3645", "3858"]
}, {
    "City": "Watertown",
    "DBTZ": "A",
    "Name": "Watertown Regional Airport",
    "Country": "United States",
    "IATA/FAA": "ATY",
    "Longitude": "-97.1547",
    "ICAO": "KATY",
    "Airport ID": "5721",
    "Latitude": "44.914",
    "Timezone": "1748",
    "DST": "-6",
    "destinations": ["5761", "3858"]
}, {
    "City": "Burlington",
    "DBTZ": "A",
    "Name": "Southeast Iowa Regional Airport",
    "Country": "United States",
    "IATA/FAA": "BRL",
    "Longitude": "-91.1255",
    "ICAO": "KBRL",
    "Airport ID": "5726",
    "Latitude": "40.7832",
    "Timezone": "698",
    "DST": "-6",
    "destinations": ["3830", "3678"]
}, {
    "City": "Crescent City",
    "DBTZ": "A",
    "Name": "Del Norte County Airport",
    "Country": "United States",
    "IATA/FAA": "CEC",
    "Longitude": "-124.237",
    "ICAO": "KCEC",
    "Airport ID": "5727",
    "Latitude": "41.7802",
    "Timezone": "57",
    "DST": "-8",
    "destinations": ["4384", "3469"]
}, {
    "City": "Qiqihar",
    "DBTZ": "U",
    "Name": "Qiqihar Sanjiazi Airport",
    "Country": "China",
    "IATA/FAA": "NDG",
    "Longitude": "123.918131",
    "ICAO": "ZYQQ",
    "Airport ID": "6413",
    "Latitude": "47.239628",
    "Timezone": "477",
    "DST": "8",
    "destinations": ["3364", "3406", "3390", "6341"]
}, {
    "City": "Brunswick",
    "DBTZ": "A",
    "Name": "Brunswick Golden Isles Airport",
    "Country": "United States",
    "IATA/FAA": "BQK",
    "Longitude": "-81.4665",
    "ICAO": "KBQK",
    "Airport ID": "5725",
    "Latitude": "31.2588",
    "Timezone": "26",
    "DST": "-5",
    "destinations": ["3682"]
}, {
    "City": "Mysore",
    "DBTZ": "U",
    "Name": "Mysore Airport",
    "Country": "India",
    "IATA/FAA": "MYQ",
    "Longitude": "76.6497",
    "ICAO": "VOMY",
    "Airport ID": "7774",
    "Latitude": "12.3072",
    "Timezone": "2349",
    "DST": "5.5",
    "destinations": ["3131"]
}, {
    "City": "San Salvador",
    "DBTZ": "U",
    "Name": "El Salvador Intl",
    "Country": "El Salvador",
    "IATA/FAA": "SAL",
    "Longitude": "-89.055728",
    "ICAO": "MSLP",
    "Airport ID": "1892",
    "Latitude": "13.440947",
    "Timezone": "101",
    "DST": "-6",
    "destinations": ["3670", "3576", "1824", "2709", "1957", "2715", "1852", "3494", "1767", "2673", "3714", "3550", "3797", "3484", "2789", "1881", "3878", "2745", "3830", "1871", "1774", "1772", "3469", "1885", "1776", "2688", "193", "1229", "3682", "1863", "3533", "1909"]
}, {
    "City": "Labuhan Bajo",
    "DBTZ": "N",
    "Name": "Mutiara Ii",
    "Country": "Indonesia",
    "IATA/FAA": "LBJ",
    "Longitude": "119.88905",
    "ICAO": "WATO",
    "Airport ID": "3917",
    "Latitude": "-8.486656",
    "Timezone": "66",
    "DST": "8",
    "destinations": ["3940", "3914"]
}, {
    "City": "Kupang",
    "DBTZ": "N",
    "Name": "El Tari",
    "Country": "Indonesia",
    "IATA/FAA": "KOE",
    "Longitude": "123.671136",
    "ICAO": "WATT",
    "Airport ID": "3916",
    "Latitude": "-10.171583",
    "Timezone": "335",
    "DST": "8",
    "destinations": ["3940", "3914", "8401", "6204", "3913", "3928"]
}, {
    "City": "Ende",
    "DBTZ": "N",
    "Name": "H Hasan Aroeboesman",
    "Country": "Indonesia",
    "IATA/FAA": "ENE",
    "Longitude": "121.660644",
    "ICAO": "WATE",
    "Airport ID": "3914",
    "Latitude": "-8.849294",
    "Timezone": "49",
    "DST": "8",
    "destinations": ["3916", "3917"]
}, {
    "City": "Maumere",
    "DBTZ": "N",
    "Name": "Wai Oti",
    "Country": "Indonesia",
    "IATA/FAA": "MOF",
    "Longitude": "122.236889",
    "ICAO": "WATC",
    "Airport ID": "3913",
    "Latitude": "-8.640647",
    "Timezone": "115",
    "DST": "8",
    "destinations": ["3940", "3916"]
}, {
    "City": "Palangkaraya",
    "DBTZ": "N",
    "Name": "Tjilik Riwut",
    "Country": "Indonesia",
    "IATA/FAA": "PKY",
    "Longitude": "113.942661",
    "ICAO": "WAOP",
    "Airport ID": "3911",
    "Latitude": "-2.225128",
    "Timezone": "82",
    "DST": "7",
    "destinations": ["3275", "3928"]
}, {
    "City": "Pangkalan Bun",
    "DBTZ": "N",
    "Name": "Iskandar",
    "Country": "Indonesia",
    "IATA/FAA": "PKN",
    "Longitude": "111.673208",
    "ICAO": "WAOI",
    "Airport ID": "3910",
    "Latitude": "-2.705197",
    "Timezone": "75",
    "DST": "7",
    "destinations": ["3908", "3275", "3282", "3910", "3929", "3901", "3928"]
}, {
    "City": "Balikpapan",
    "DBTZ": "N",
    "Name": "Sepinggan",
    "Country": "Indonesia",
    "IATA/FAA": "BPN",
    "Longitude": "116.894478",
    "ICAO": "WALL",
    "Airport ID": "3919",
    "Latitude": "-1.268272",
    "Timezone": "12",
    "DST": "8",
    "destinations": ["3304", "6209", "3275", "3898", "3250", "3316", "3928", "3920", "3240", "3908", "3903", "3249", "3901", "3940"]
}, {
    "City": "Imo",
    "DBTZ": "N",
    "Name": "Imo Airport",
    "Country": "Nigeria",
    "IATA/FAA": "QOW",
    "Longitude": "7.20603",
    "ICAO": "DNIM",
    "Airport ID": "6730",
    "Latitude": "5.42706",
    "Timezone": "373",
    "DST": "1",
    "destinations": ["260", "273"]
}, {
    "City": "La Fortuna/San Carlos",
    "DBTZ": "U",
    "Name": "Arenal Airport",
    "Country": "Costa Rica",
    "IATA/FAA": "FON",
    "Longitude": "-84.6345",
    "ICAO": "MRAN",
    "Airport ID": "6731",
    "Latitude": "10.478",
    "Timezone": "342",
    "DST": "-6",
    "destinations": ["1889", "1885"]
}, {
    "City": "Nicoya",
    "DBTZ": "U",
    "Name": "Tambor Airport",
    "Country": "Costa Rica",
    "IATA/FAA": "TMU",
    "Longitude": "-85.0138",
    "ICAO": "MRTR",
    "Airport ID": "6732",
    "Latitude": "9.73852",
    "Timezone": "33",
    "DST": "-6",
    "destinations": ["1885"]
}, {
    "City": "Cauayan",
    "DBTZ": "N",
    "Name": "Cauayan Airport",
    "Country": "Philippines",
    "IATA/FAA": "CYZ",
    "Longitude": "121.753036",
    "ICAO": "RPUY",
    "Airport ID": "6733",
    "Latitude": "16.929861",
    "Timezone": "200",
    "DST": "8",
    "destinations": ["2397"]
}, {
    "City": "Apatity",
    "DBTZ": "N",
    "Name": "Kirovsk-Apatity Airport",
    "Country": "Russia",
    "IATA/FAA": "KVK",
    "Longitude": "33.5883",
    "ICAO": "ULMK",
    "Airport ID": "6734",
    "Latitude": "67.4633",
    "Timezone": "515",
    "DST": "4",
    "destinations": ["4029"]
}, {
    "City": "Governador Valadares",
    "DBTZ": "S",
    "Name": "Governador Valadares Airport",
    "Country": "Brazil",
    "IATA/FAA": "GVR",
    "Longitude": "-41.9822",
    "ICAO": "SBGV",
    "Airport ID": "6735",
    "Latitude": "-18.8952",
    "Timezone": "561",
    "DST": "-3",
    "destinations": ["2528"]
}, {
    "City": "Pajala",
    "DBTZ": "E",
    "Name": "Pajala Airport",
    "Country": "Sweden",
    "IATA/FAA": "PJA",
    "Longitude": "23.0689",
    "ICAO": "ESUP",
    "Airport ID": "6737",
    "Latitude": "67.2456",
    "Timezone": "542",
    "DST": "1",
    "destinations": ["734", "415"]
}, {
    "City": "Bella Coola",
    "DBTZ": "A",
    "Name": "Bella Coola Airport",
    "Country": "Canada",
    "IATA/FAA": "QBC",
    "Longitude": "-126.596",
    "ICAO": "CYBD",
    "Airport ID": "6738",
    "Latitude": "52.3875",
    "Timezone": "117",
    "DST": "-8",
    "destinations": ["5457", "156"]
}, {
    "City": "Hagerstown",
    "DBTZ": "A",
    "Name": "Hagerstown Regional Richard A Henson Field",
    "Country": "United States",
    "IATA/FAA": "HGR",
    "Longitude": "-77.7295",
    "ICAO": "KHGR",
    "Airport ID": "6739",
    "Latitude": "39.7079",
    "Timezone": "704",
    "DST": "-5",
    "destinations": ["4167", "3714"]
}, {
    "City": "Jalapa",
    "DBTZ": "S",
    "Name": "Lencero Airport",
    "Country": "Mexico",
    "IATA/FAA": "JAL",
    "Longitude": "-96.797506",
    "ICAO": "MMJA",
    "Airport ID": "5837",
    "Latitude": "19.475083",
    "Timezone": "3127",
    "DST": "-6",
    "destinations": ["1824"]
}, {
    "City": "Isfahan",
    "DBTZ": "E",
    "Name": "Esfahan Shahid Beheshti Intl",
    "Country": "Iran",
    "IATA/FAA": "IFN",
    "Longitude": "51.861267",
    "ICAO": "",
    "Airport ID": "4150",
    "Latitude": "32.750836",
    "Timezone": "5059",
    "DST": "3.5",
    "destinations": ["2104", "4331", "2134", "7546", "2131", "2188", "2136", "2109", "2176", "2157", "2162", "2074", "1701", "2103"]
}, {
    "City": "Puerto Lempira",
    "DBTZ": "U",
    "Name": "Puerto Lempira Airport",
    "Country": "Honduras",
    "IATA/FAA": "PEU",
    "Longitude": "-83.7812",
    "ICAO": "MHPL",
    "Airport ID": "5832",
    "Latitude": "15.2622",
    "Timezone": "25",
    "DST": "-6",
    "destinations": ["1776", "1771"]
}, {
    "City": "Chu Lai",
    "DBTZ": "U",
    "Name": "Chu Lai",
    "Country": "Vietnam",
    "IATA/FAA": "VCL",
    "Longitude": "108.705889",
    "ICAO": "VVCA",
    "Airport ID": "4157",
    "Latitude": "15.405944",
    "Timezone": "10",
    "DST": "7",
    "destinations": ["3199", "3205"]
}, {
    "City": "Baise",
    "DBTZ": "N",
    "Name": "Tianyang",
    "Country": "China",
    "IATA/FAA": "AEB",
    "Longitude": "106.96",
    "ICAO": "",
    "Airport ID": "8082",
    "Latitude": "23.72",
    "Timezone": "485",
    "DST": "8",
    "destinations": ["3393", "3372", "3370"]
}, {
    "City": "Sanya",
    "DBTZ": "U",
    "Name": "Phoenix International",
    "Country": "China",
    "IATA/FAA": "SYX",
    "Longitude": "109.412272",
    "ICAO": "ZJSY",
    "Airport ID": "4030",
    "Latitude": "18.302897",
    "Timezone": "92",
    "DST": "8",
    "destinations": ["3393", "3395", "3394", "3372", "6400", "3387", "3388", "4108", "6392", "4110", "3382", "3386", "3406", "3391", "3371", "6355", "3373", "3364", "3374", "3369", "6390", "3383", "2276", "3077", "3370", "3375", "3385", "4302", "3376", "3379", "6430", "8876", "6357", "3368", "3389", "3384", "6347", "6361", "6356", "4029"]
}, {
    "City": "Tuy Hoa",
    "DBTZ": "U",
    "Name": "Dong Tac",
    "Country": "Vietnam",
    "IATA/FAA": "TBB",
    "Longitude": "109.333706",
    "ICAO": "VVTH",
    "Airport ID": "4158",
    "Latitude": "13.04955",
    "Timezone": "20",
    "DST": "7",
    "destinations": ["3199", "3205"]
}, {
    "City": "Pai",
    "DBTZ": "U",
    "Name": "Pai",
    "Country": "Thailand",
    "IATA/FAA": "PYY",
    "Longitude": "98.437",
    "ICAO": "VTCI",
    "Airport ID": "4159",
    "Latitude": "19.372",
    "Timezone": "1271",
    "DST": "7",
    "destinations": ["3931"]
}, {
    "City": "Lijiang",
    "DBTZ": "U",
    "Name": "Lijiang Airport",
    "Country": "China",
    "IATA/FAA": "LJG",
    "Longitude": "100.23333",
    "ICAO": "ZPLJ",
    "Airport ID": "4033",
    "Latitude": "26.883333",
    "Timezone": "7923",
    "DST": "8",
    "destinations": ["3393", "3395", "6400", "3375", "3371", "3389", "4055", "3382", "3394", "3387", "3388", "3374", "3369", "3376", "3379", "2276", "3386", "3364", "3370", "3372", "6347", "4108", "3373", "3077", "3391", "7862", "3930", "3316"]
}, {
    "City": "Sault Sainte Marie",
    "DBTZ": "A",
    "Name": "Sault Ste Marie",
    "Country": "Canada",
    "IATA/FAA": "YAM",
    "Longitude": "-84.509445",
    "ICAO": "CYAM",
    "Airport ID": "21",
    "Latitude": "46.485001",
    "Timezone": "630",
    "DST": "-5",
    "destinations": ["193", "121", "133", "144"]
}, {
    "City": "Tongliao",
    "DBTZ": "U",
    "Name": "Tongliao Airport",
    "Country": "China",
    "IATA/FAA": "TGO",
    "Longitude": "122.2",
    "ICAO": "ZBTL",
    "Airport ID": "6348",
    "Latitude": "43.5567",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3364", "6345", "4108", "3366", "3406", "3386", "3400", "3388"]
}, {
    "City": "Santo Angelo",
    "DBTZ": "S",
    "Name": "Santo Angelo",
    "Country": "Brazil",
    "IATA/FAA": "GEL",
    "Longitude": "-54.169139",
    "ICAO": "SBNM",
    "Airport ID": "2596",
    "Latitude": "-28.281683",
    "Timezone": "1056",
    "DST": "-3",
    "destinations": ["2599", "6040"]
}, {
    "City": "Tamanrasset",
    "DBTZ": "N",
    "Name": "Tamanrasset",
    "Country": "Algeria",
    "IATA/FAA": "TMR",
    "Longitude": "5.451075",
    "ICAO": "DAAT",
    "Airport ID": "216",
    "Latitude": "22.811461",
    "Timezone": "4518",
    "DST": "1",
    "destinations": ["210", "236", "237", "239"]
}, {
    "City": "Algier",
    "DBTZ": "N",
    "Name": "Houari Boumediene",
    "Country": "Algeria",
    "IATA/FAA": "ALG",
    "Longitude": "3.215408",
    "ICAO": "DAAG",
    "Airport ID": "210",
    "Latitude": "36.691014",
    "Timezone": "82",
    "DST": "1",
    "destinations": ["344", "1382", "1353", "220", "253", "1212", "2170", "234", "1218", "2177", "209", "1044", "5552", "1264", "302", "235", "1128", "1074", "221", "211", "1084", "2188", "5555", "1320", "1555", "340", "237", "1665", "238", "224", "239", "1701", "507", "1399", "1638", "1335", "1229", "233", "1524", "1354", "280", "243", "231", "1386", "246", "3364", "3998", "6492", "2985", "222", "240", "225", "1157", "230", "1273", "287", "1613", "146", "502", "1606", "2241", "2074", "1423"]
}, {
    "City": "Djanet",
    "DBTZ": "N",
    "Name": "Tiska",
    "Country": "Algeria",
    "IATA/FAA": "DJG",
    "Longitude": "9.452444",
    "ICAO": "DAAJ",
    "Airport ID": "211",
    "Latitude": "24.292767",
    "Timezone": "3176",
    "DST": "1",
    "destinations": ["243", "216"]
}, {
    "City": "Kostanay",
    "DBTZ": "U",
    "Name": "Kostanay West Airport",
    "Country": "Kazakhstan",
    "IATA/FAA": "KSN",
    "Longitude": "63.550278",
    "ICAO": "UAUU",
    "Airport ID": "6088",
    "Latitude": "53.206944",
    "Timezone": "595",
    "DST": "6",
    "destinations": ["2908", "2910", "2988"]
}, {
    "City": "Ganja",
    "DBTZ": "E",
    "Name": "Ganja Airport",
    "Country": "Azerbaijan",
    "IATA/FAA": "KVD",
    "Longitude": "46.3176",
    "ICAO": "UBBG",
    "Airport ID": "6089",
    "Latitude": "40.7377",
    "Timezone": "1083",
    "DST": "4",
    "destinations": ["2922", "2948", "1701", "4029"]
}, {
    "City": "Ust Kamenogorsk",
    "DBTZ": "U",
    "Name": "Ust Kamenogorsk Airport",
    "Country": "Kazakhstan",
    "IATA/FAA": "UKK",
    "Longitude": "82.4942",
    "ICAO": "UASK",
    "Airport ID": "6086",
    "Latitude": "50.0366",
    "Timezone": "939",
    "DST": "6",
    "destinations": ["2908", "4375", "2918", "2910", "4029"]
}, {
    "City": "Petropavlosk",
    "DBTZ": "U",
    "Name": "Petropavlosk South Airport",
    "Country": "Kazakhstan",
    "IATA/FAA": "PPK",
    "Longitude": "69.1839",
    "ICAO": "UACP",
    "Airport ID": "6084",
    "Latitude": "54.7747",
    "Timezone": "453",
    "DST": "6",
    "destinations": ["2908", "2910"]
}, {
    "City": "Zhezkazgan",
    "DBTZ": "U",
    "Name": "Zhezkazgan Airport",
    "Country": "Kazakhstan",
    "IATA/FAA": "DZN",
    "Longitude": "67.733333",
    "ICAO": "UAKD",
    "Airport ID": "6085",
    "Latitude": "47.708333",
    "Timezone": "1250",
    "DST": "6",
    "destinations": ["2908", "2910"]
}, {
    "City": "Kokshetau",
    "DBTZ": "U",
    "Name": "Kokshetau Airport",
    "Country": "Kazakhstan",
    "IATA/FAA": "KOV",
    "Longitude": "69.5946",
    "ICAO": "UACK",
    "Airport ID": "6083",
    "Latitude": "53.3291",
    "Timezone": "900",
    "DST": "6",
    "destinations": ["2908", "4029"]
}, {
    "City": "Spanish Town",
    "DBTZ": "U",
    "Name": "Virgin Gorda Airport",
    "Country": "British Virgin Islands",
    "IATA/FAA": "VIJ",
    "Longitude": "-64.4275",
    "ICAO": "TUPW",
    "Airport ID": "6080",
    "Latitude": "18.4464",
    "Timezone": "9",
    "DST": "-4",
    "destinations": ["2890", "2883"]
}, {
    "City": "Lamen Bay",
    "DBTZ": "U",
    "Name": "Lamen Bay Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "LNB",
    "Longitude": "168.159",
    "ICAO": "NVSM",
    "Airport ID": "5902",
    "Latitude": "-16.5842",
    "Timezone": "7",
    "DST": "11",
    "destinations": ["5897", "1997"]
}, {
    "City": "Maewo Island",
    "DBTZ": "U",
    "Name": "Naone Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "MWF",
    "Longitude": "168.083",
    "ICAO": "NVSN",
    "Airport ID": "5903",
    "Latitude": "-15",
    "Timezone": "0",
    "DST": "11",
    "destinations": ["5908"]
}, {
    "City": "Paama Island",
    "DBTZ": "U",
    "Name": "Tavie Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "PBJ",
    "Longitude": "168.257",
    "ICAO": "NVSI",
    "Airport ID": "5900",
    "Latitude": "-16.439",
    "Timezone": "0",
    "DST": "11",
    "destinations": ["5910", "1997"]
}, {
    "City": "Lamap",
    "DBTZ": "U",
    "Name": "Lamap Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "LPM",
    "Longitude": "167.823",
    "ICAO": "NVSL",
    "Airport ID": "5901",
    "Latitude": "-16.454",
    "Timezone": "7",
    "DST": "11",
    "destinations": ["5905", "1997"]
}, {
    "City": "Gaua Island",
    "DBTZ": "U",
    "Name": "Gaua Island Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "ZGU",
    "Longitude": "167.587",
    "ICAO": "NVSQ",
    "Airport ID": "5906",
    "Latitude": "-14.2181",
    "Timezone": "0",
    "DST": "11",
    "destinations": ["5893", "5908", "5895"]
}, {
    "City": "Nadzab",
    "DBTZ": "U",
    "Name": "Nadzab",
    "Country": "Papua New Guinea",
    "IATA/FAA": "LAE",
    "Longitude": "146.726242",
    "ICAO": "AYNZ",
    "Airport ID": "4",
    "Latitude": "-6.569828",
    "Timezone": "239",
    "DST": "10",
    "destinations": ["5421", "1", "3", "5424", "2", "5430", "5423", "5", "5435"]
}, {
    "City": "Lonorore",
    "DBTZ": "U",
    "Name": "Lonorore Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "LNE",
    "Longitude": "168.172",
    "ICAO": "NVSO",
    "Airport ID": "5904",
    "Latitude": "-15.8656",
    "Timezone": "43",
    "DST": "11",
    "destinations": ["5898", "5908", "1997"]
}, {
    "City": "Norsup",
    "DBTZ": "U",
    "Name": "Norsup Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "NUS",
    "Longitude": "167.401",
    "ICAO": "NVSP",
    "Airport ID": "5905",
    "Latitude": "-16.0797",
    "Timezone": "23",
    "DST": "11",
    "destinations": ["5897", "5901", "5908", "5912", "1997"]
}, {
    "City": "Santo",
    "DBTZ": "U",
    "Name": "Santo Pekoa International Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "SON",
    "Longitude": "167.219742",
    "ICAO": "NVSS",
    "Airport ID": "5908",
    "Latitude": "-15.505033",
    "Timezone": "184",
    "DST": "11",
    "destinations": ["3320", "5904", "5898", "5903", "5905", "5894", "5899", "1997", "6771", "5906"]
}, {
    "City": "Tongoa Island",
    "DBTZ": "U",
    "Name": "Tongoa Island Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "TGH",
    "Longitude": "168.551",
    "ICAO": "NVST",
    "Airport ID": "5909",
    "Latitude": "-16.8911",
    "Timezone": "443",
    "DST": "11",
    "destinations": ["1997"]
}, {
    "City": "Cincinnati",
    "DBTZ": "A",
    "Name": "Cincinnati Northern Kentucky Intl",
    "Country": "United States",
    "IATA/FAA": "CVG",
    "Longitude": "-84.667822",
    "ICAO": "KCVG",
    "Airport ID": "3488",
    "Latitude": "39.048836",
    "Timezone": "896",
    "DST": "-5",
    "destinations": ["3682", "3876", "3520", "3670", "3797", "3576", "3830", "3752", "193", "1382", "1852", "3825", "3690", "3448", "3849", "3751", "3645", "3494", "3533", "3685", "3550", "3712", "3877", "3484", "3697", "3458", "3878", "3473", "3717", "3459", "3858", "3861", "3570", "1760", "3626", "3608", "3793", "3469", "3536", "3678", "3646", "4356", "3447", "7056", "4167", "7657", "3714"]
}, {
    "City": "Neuquen",
    "DBTZ": "N",
    "Name": "Presidente Peron",
    "Country": "Argentina",
    "IATA/FAA": "NQN",
    "Longitude": "-68.155711",
    "ICAO": "SAZN",
    "Airport ID": "2509",
    "Latitude": "-38.949",
    "Timezone": "895",
    "DST": "-3",
    "destinations": ["2487", "2452", "2442"]
}, {
    "City": "Mar Del Plata",
    "DBTZ": "N",
    "Name": "Mar Del Plata",
    "Country": "Argentina",
    "IATA/FAA": "MDQ",
    "Longitude": "-57.573333",
    "ICAO": "SAZM",
    "Airport ID": "2508",
    "Latitude": "-37.934167",
    "Timezone": "71",
    "DST": "-3",
    "destinations": ["2442", "2501"]
}, {
    "City": "Los Angeles",
    "DBTZ": "A",
    "Name": "Los Angeles Intl",
    "Country": "United States",
    "IATA/FAA": "LAX",
    "Longitude": "-118.408075",
    "ICAO": "KLAX",
    "Airport ID": "3484",
    "Latitude": "33.942536",
    "Timezone": "126",
    "DST": "-8",
    "destinations": ["3877", "4019", "3774", "3673", "3825", "3690", "3320", "3448", "3876", "3759", "3520", "3751", "3670", "345", "3559", "4099", "3687", "1804", "2564", "3077", "3728", "3714", "3550", "3585", "3797", "3514", "507", "3602", "2789", "1229", "3878", "3339", "1824", "4101", "3576", "7081", "3948", "1960", "2279", "3456", "3863", "3830", "3722", "3720", "3752", "3462", "3570", "4075", "3406", "4103", "3626", "3807", "3784", "3731", "3577", "3469", "3748", "1840", "3536", "3817", "3678", "6992", "3361", "3636", "4356", "49", "156", "178", "193", "87", "146", "3682", "1382", "3858", "1852", "1807", "1826", "1836", "1856", "1858", "1817", "3861", "1767", "1892", "1555", "2188", "3533", "2276", "3364", "1871", "3370", "580", "1815", "1957", "3488", "3645", "3467", "2359", "3930", "1881", "3458", "3473", "3453", "1885", "2985", "3646", "737", "609", "6132", "3566", "3747", "3717", "3862", "3777", "7579", "340", "346", "1678", "1590", "2006", "1959", "2397", "2072", "1701", "7001", "3558", "3495", "3849", "4088", "3486", "3819", "1801", "3494", "3457", "3415", "3839", "3621", "3949", "5768", "5772", "3596", "2988", "1785", "1821", "1835", "1855", "7122", "3446", "7121"]
}, {
    "City": "Cleveland",
    "DBTZ": "A",
    "Name": "Cleveland Hopkins Intl",
    "Country": "United States",
    "IATA/FAA": "CLE",
    "Longitude": "-81.849794",
    "ICAO": "KCLE",
    "Airport ID": "3486",
    "Latitude": "41.411689",
    "Timezone": "791",
    "DST": "-5",
    "destinations": ["3876", "3670", "3797", "3576", "3830", "3752", "193", "3682", "3645", "3697", "3858", "1852", "3751", "1760", "3447", "3747", "3864", "3673", "5722", "3690", "3448", "3820", "3849", "3806", "3759", "3627", "3520", "5734", "4276", "3494", "4058", "3533", "4089", "3685", "4034", "3714", "3550", "3585", "7059", "3877", "3484", "3458", "3878", "3717", "3861", "3722", "3462", "4114", "3608", "3622", "3793", "4014", "3469", "3678", "3745", "3646"]
}, {
    "City": "Tampa",
    "DBTZ": "A",
    "Name": "Tampa Intl",
    "Country": "United States",
    "IATA/FAA": "TPA",
    "Longitude": "-82.53325",
    "ICAO": "KTPA",
    "Airport ID": "3646",
    "Latitude": "27.975472",
    "Timezone": "26",
    "DST": "-5",
    "destinations": ["3875", "3533", "3472", "3712", "3722", "3564", "3698", "3876", "3520", "3670", "3797", "502", "3576", "3830", "3752", "3462", "193", "3682", "3825", "3448", "3494", "3589", "3697", "2890", "1871", "3759", "3488", "3645", "3585", "3484", "3858", "3626", "3751", "3859", "3447", "3864", "3811", "3849", "4112", "3627", "3566", "3857", "3458", "3747", "3717", "3861", "3720", "3570", "3641", "3621", "3577", "3678", "1926", "1678", "3524", "3486", "3714", "3550", "3673", "3690", "3820", "4089", "3685", "3877", "3473", "4011", "3622", "4014"]
}, {
    "City": "Detroit",
    "DBTZ": "A",
    "Name": "Detroit Metro Wayne Co",
    "Country": "United States",
    "IATA/FAA": "DTW",
    "Longitude": "-83.353389",
    "ICAO": "KDTW",
    "Airport ID": "3645",
    "Latitude": "42.212444",
    "Timezone": "645",
    "DST": "-5",
    "destinations": ["3861", "3876", "3520", "3670", "3697", "3576", "3830", "3752", "3462", "146", "193", "3682", "1382", "340", "507", "1852", "1824", "1825", "3720", "3731", "3448", "3364", "3406", "4355", "3864", "580", "5720", "4047", "3673", "4007", "4286", "4039", "3825", "4129", "3463", "3811", "4037", "3690", "3711", "3820", "3849", "3561", "4112", "3578", "3806", "4043", "5729", "3486", "3759", "4285", "3488", "4045", "3627", "3751", "3729", "3946", "4276", "6800", "4018", "3494", "3533", "4041", "1926", "3653", "3685", "2564", "4008", "4034", "3589", "4113", "3714", "3550", "3930", "3585", "3947", "3712", "3797", "3544", "3877", "3484", "4017", "3660", "1780", "4128", "3458", "3878", "3542", "3747", "3473", "4011", "3717", "4072", "6838", "3459", "3858", "3515", "1953", "3942", "2279", "3863", "3454", "3611", "3722", "4046", "3570", "5762", "1760", "3641", "3862", "3626", "3608", "4016", "3622", "3793", "3621", "3613", "4359", "4318", "4014", "3577", "3469", "2890", "3536", "3678", "3661", "3745", "3646", "3855", "4023", "3676", "4356", "100", "3859", "3447", "3524"]
}, {
    "City": "Burbank",
    "DBTZ": "A",
    "Name": "Bob Hope",
    "Country": "United States",
    "IATA/FAA": "BUR",
    "Longitude": "-118.358667",
    "ICAO": "KBUR",
    "Airport ID": "3644",
    "Latitude": "34.200667",
    "Timezone": "778",
    "DST": "-8",
    "destinations": ["3720", "3462", "3577", "3797", "3536", "3877", "3689", "3751", "3469", "3453", "3748", "3817"]
}, {
    "City": "Salisbury",
    "DBTZ": "A",
    "Name": "Salisbury Ocean City Wicomico Rgnl",
    "Country": "United States",
    "IATA/FAA": "SBY",
    "Longitude": "-75.510289",
    "ICAO": "KSBY",
    "Airport ID": "3642",
    "Latitude": "38.340525",
    "Timezone": "52",
    "DST": "-5",
    "destinations": ["3876", "3752"]
}, {
    "City": "Providence",
    "DBTZ": "A",
    "Name": "Theodore Francis Green State",
    "Country": "United States",
    "IATA/FAA": "PVD",
    "Longitude": "-71.420383",
    "ICAO": "KPVD",
    "Airport ID": "3641",
    "Latitude": "41.732581",
    "Timezone": "55",
    "DST": "-5",
    "destinations": ["3876", "3520", "3752", "3682", "3533", "3878", "3645", "3849", "3877", "3747", "3731", "3536", "3494", "3714", "3830", "3793", "3646"]
}, {
    "City": "President Prudente",
    "DBTZ": "S",
    "Name": "Presidente Prudente",
    "Country": "Brazil",
    "IATA/FAA": "PPB",
    "Longitude": "-51.424639",
    "ICAO": "SBDN",
    "Airport ID": "2550",
    "Latitude": "-22.175056",
    "Timezone": "1477",
    "DST": "-3",
    "destinations": ["2578", "2618"]
}, {
    "City": "Manaus",
    "DBTZ": "S",
    "Name": "Eduardo Gomes Intl",
    "Country": "Brazil",
    "IATA/FAA": "MAO",
    "Longitude": "-60.049721",
    "ICAO": "SBEG",
    "Airport ID": "2551",
    "Latitude": "-3.038611",
    "Timezone": "264",
    "DST": "-4",
    "destinations": ["3576", "7399", "2526", "2533", "7398", "2537", "7372", "2607", "4092", "2628", "2624", "2578", "2564", "1871", "2531", "2560", "2559", "2850"]
}, {
    "City": "Guangyuan",
    "DBTZ": "U",
    "Name": "Guangyuan Airport",
    "Country": "China",
    "IATA/FAA": "GYS",
    "Longitude": "105.702",
    "ICAO": "ZUGU",
    "Airport ID": "6398",
    "Latitude": "32.3911",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3370", "3386", "3364", "3374"]
}, {
    "City": "Luzhou",
    "DBTZ": "U",
    "Name": "Luzhou Airport",
    "Country": "China",
    "IATA/FAA": "LZO",
    "Longitude": "105.393",
    "ICAO": "ZULZ",
    "Airport ID": "6399",
    "Latitude": "28.8522",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3370", "3371", "3374", "3394", "4120", "3373", "3379", "3386", "3382", "3364", "3406"]
}, {
    "City": "Foz Do Iguacu",
    "DBTZ": "S",
    "Name": "Cataratas Intl",
    "Country": "Brazil",
    "IATA/FAA": "IGU",
    "Longitude": "-54.487206",
    "ICAO": "SBFI",
    "Airport ID": "2554",
    "Latitude": "-25.59615",
    "Timezone": "787",
    "DST": "-3",
    "destinations": ["2545", "2599", "2578", "2560", "2564", "2618", "2789"]
}, {
    "City": "Florianopolis",
    "DBTZ": "S",
    "Name": "Hercilio Luz",
    "Country": "Brazil",
    "IATA/FAA": "FLN",
    "Longitude": "-48.547181",
    "ICAO": "SBFL",
    "Airport ID": "2555",
    "Latitude": "-27.670489",
    "Timezone": "20",
    "DST": "-3",
    "destinations": ["2578", "2442", "2618", "3988", "2560", "2564", "2599", "2539", "2531", "2541", "2545", "7384"]
}, {
    "City": "Fernando Do Noronha",
    "DBTZ": "S",
    "Name": "Fernando De Noronha",
    "Country": "Brazil",
    "IATA/FAA": "FEN",
    "Longitude": "-32.423336",
    "ICAO": "SBFN",
    "Airport ID": "2556",
    "Latitude": "-3.854928",
    "Timezone": "193",
    "DST": "-3",
    "destinations": ["2597", "2610"]
}, {
    "City": "Kingston",
    "DBTZ": "A",
    "Name": "Kingston",
    "Country": "Canada",
    "IATA/FAA": "YGK",
    "Longitude": "-76.596944",
    "ICAO": "CYGK",
    "Airport ID": "61",
    "Latitude": "44.225277",
    "Timezone": "305",
    "DST": "-5",
    "destinations": ["193"]
}, {
    "City": "Wenzhou",
    "DBTZ": "U",
    "Name": "Wenzhou Yongqiang Airport",
    "Country": "China",
    "IATA/FAA": "WNZ",
    "Longitude": "120.852",
    "ICAO": "ZSWZ",
    "Airport ID": "6392",
    "Latitude": "27.9122",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3371", "3395", "3400", "3394", "4030", "2275", "3370", "3393", "3382", "3364", "3406", "4144", "3374", "3390", "4108", "2276", "3379", "6355", "3077", "3375", "3372", "3376", "3391", "7503", "7898", "3384", "6385", "3373", "6341", "2370", "4120", "6347", "3196"]
}, {
    "City": "Yancheng",
    "DBTZ": "U",
    "Name": "Yancheng Airport",
    "Country": "China",
    "IATA/FAA": "YNZ",
    "Longitude": "120.125",
    "ICAO": "ZSYN",
    "Airport ID": "6393",
    "Latitude": "33.3856",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["2276", "3364", "3404", "3400", "5475", "4144", "3376", "3383", "3371", "3077", "3391", "3379"]
}, {
    "City": "Wuxi",
    "DBTZ": "U",
    "Name": "Wuxi Airport",
    "Country": "China",
    "IATA/FAA": "WUX",
    "Longitude": "120.429",
    "ICAO": "ZSWX",
    "Airport ID": "6390",
    "Latitude": "31.4944",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3393", "3395", "3370", "3404", "3385", "3364", "4144", "4030", "3374", "3376", "3379", "3383", "2276", "3371", "3077", "3316", "3390", "3992", "3121"]
}, {
    "City": "Wuyishan",
    "DBTZ": "U",
    "Name": "Nanping Wuyishan Airport",
    "Country": "China",
    "IATA/FAA": "WUS",
    "Longitude": "118.001",
    "ICAO": "ZSWY",
    "Airport ID": "6391",
    "Latitude": "27.7019",
    "Timezone": "614",
    "DST": "8",
    "destinations": ["3370", "3393", "3390", "3383", "3364", "3077", "3391", "4108", "3379"]
}, {
    "City": "Bangda",
    "DBTZ": "U",
    "Name": "Qamdo Bangda Airport",
    "Country": "China",
    "IATA/FAA": "BPX",
    "Longitude": "97.1083",
    "ICAO": "ZUBD",
    "Airport ID": "6396",
    "Latitude": "30.5536",
    "Timezone": "14219",
    "DST": "8",
    "destinations": ["3395", "4097"]
}, {
    "City": "Dazhou",
    "DBTZ": "U",
    "Name": "Dachuan Airport",
    "Country": "China",
    "IATA/FAA": "DAX",
    "Longitude": "107.5",
    "ICAO": "ZUDX",
    "Airport ID": "6397",
    "Latitude": "31.3",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3370", "3382", "3364", "3374", "3406"]
}, {
    "City": "Yiwu",
    "DBTZ": "U",
    "Name": "Yiwu Airport",
    "Country": "China",
    "IATA/FAA": "YIW",
    "Longitude": "120.032",
    "ICAO": "ZSYW",
    "Airport ID": "6394",
    "Latitude": "29.3447",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3395", "3364", "3370", "3375", "3400", "3382", "4144", "4302", "3399", "3383", "6355", "6386", "6385", "3379"]
}, {
    "City": "Zhoushan",
    "DBTZ": "U",
    "Name": "Zhoushan Airport",
    "Country": "China",
    "IATA/FAA": "HSN",
    "Longitude": "122.362",
    "ICAO": "ZSZS",
    "Airport ID": "6395",
    "Latitude": "29.9342",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["6386", "6383", "3364", "3374", "3383", "6341", "3406"]
}, {
    "City": "Manila",
    "DBTZ": "N",
    "Name": "Ninoy Aquino Intl",
    "Country": "Philippines",
    "IATA/FAA": "MNL",
    "Longitude": "121.019581",
    "ICAO": "RPLL",
    "Airport ID": "2397",
    "Latitude": "14.508647",
    "Timezone": "75",
    "DST": "8",
    "destinations": ["6019", "4200", "2399", "2400", "6024", "2425", "8076", "6011", "2430", "4203", "4002", "4204", "2433", "6026", "6016", "6021", "2404", "3992", "3316", "2423", "3269", "3885", "3272", "3370", "4206", "3275", "6733", "4201", "3940", "4090", "2188", "3199", "3077", "3179", "3930", "2429", "3304", "4202", "3121", "3942", "2279", "2401", "3364", "2372", "3406", "3035", "3205", "2435", "2422", "3994", "2276", "4199", "6022", "6018", "3383", "2264", "2179", "2057", "2359", "6023", "2064", "2241", "3999", "2305", "2246", "3728", "3484", "507", "6025", "3339", "2082", "3469", "3361", "156", "5", "2072", "2256", "3266"]
}, {
    "City": "Bahia Blanca",
    "DBTZ": "N",
    "Name": "Comandante Espora",
    "Country": "Argentina",
    "IATA/FAA": "BHI",
    "Longitude": "-62.169317",
    "ICAO": "SAZB",
    "Airport ID": "2501",
    "Latitude": "-38.724967",
    "Timezone": "246",
    "DST": "-3",
    "destinations": ["2508", "2490", "2442"]
}, {
    "City": "Miyako",
    "DBTZ": "U",
    "Name": "Miyako",
    "Country": "Japan",
    "IATA/FAA": "MMY",
    "Longitude": "125.295111",
    "ICAO": "ROMY",
    "Airport ID": "2390",
    "Latitude": "24.782833",
    "Timezone": "150",
    "DST": "9",
    "destinations": ["2384", "2387", "2359"]
}, {
    "City": "Nan",
    "DBTZ": "U",
    "Name": "Nan",
    "Country": "Thailand",
    "IATA/FAA": "NNT",
    "Longitude": "100.783419",
    "ICAO": "VTCN",
    "Airport ID": "4131",
    "Latitude": "18.807914",
    "Timezone": "685",
    "DST": "7",
    "destinations": ["3157", "3931"]
}, {
    "City": "Cotabato",
    "DBTZ": "N",
    "Name": "Cotabato",
    "Country": "Philippines",
    "IATA/FAA": "CBO",
    "Longitude": "124.209619",
    "ICAO": "RPMC",
    "Airport ID": "2399",
    "Latitude": "7.165242",
    "Timezone": "189",
    "DST": "8",
    "destinations": ["2397"]
}, {
    "City": "Iquique",
    "DBTZ": "S",
    "Name": "Diego Aracena Intl",
    "Country": "Chile",
    "IATA/FAA": "IQQ",
    "Longitude": "-70.181275",
    "ICAO": "SCDA",
    "Airport ID": "2649",
    "Latitude": "-20.535222",
    "Timezone": "155",
    "DST": "-4",
    "destinations": ["2650", "2651", "2641", "2762", "2771"]
}, {
    "City": "Santa Cruz",
    "DBTZ": "U",
    "Name": "Viru Viru Intl",
    "Country": "Bolivia",
    "IATA/FAA": "VVI",
    "Longitude": "-63.135364",
    "ICAO": "SLVR",
    "Airport ID": "2771",
    "Latitude": "-17.644756",
    "Timezone": "1225",
    "DST": "-4",
    "destinations": ["3576", "2789", "3988", "1871", "2564", "2649", "2762", "2759", "1229", "2767", "2768", "2699"]
}, {
    "City": "Yacuiba",
    "DBTZ": "U",
    "Name": "Yacuiba",
    "Country": "Bolivia",
    "IATA/FAA": "BYC",
    "Longitude": "-63.651669",
    "ICAO": "SLYA",
    "Airport ID": "2772",
    "Latitude": "-21.960925",
    "Timezone": "2116",
    "DST": "-4",
    "destinations": ["4247", "2768"]
}, {
    "City": "Zandery",
    "DBTZ": "U",
    "Name": "Johan A Pengel Intl",
    "Country": "Suriname",
    "IATA/FAA": "PBM",
    "Longitude": "-55.187783",
    "ICAO": "SMJP",
    "Airport ID": "2773",
    "Latitude": "5.452831",
    "Timezone": "59",
    "DST": "-3",
    "destinations": ["2897", "2902", "580", "2895", "2526", "2774", "4304"]
}, {
    "City": "Cayenne",
    "DBTZ": "U",
    "Name": "Rochambeau",
    "Country": "French Guiana",
    "IATA/FAA": "CAY",
    "Longitude": "-52.360447",
    "ICAO": "SOCA",
    "Airport ID": "2774",
    "Latitude": "4.819808",
    "Timezone": "26",
    "DST": "-3",
    "destinations": ["2878", "1386", "2526", "2773"]
}, {
    "City": "Dhaka",
    "DBTZ": "U",
    "Name": "Zia Intl",
    "Country": "Bangladesh",
    "IATA/FAA": "DAC",
    "Longitude": "90.397781",
    "ICAO": "VGZR",
    "Airport ID": "3076",
    "Latitude": "23.843333",
    "Timezone": "30",
    "DST": "6",
    "destinations": ["3885", "6176", "3043", "3069", "3068", "2188", "2072", "3071", "3125", "3304", "2194", "3073", "3074", "2997", "3093", "2064", "2241", "1555", "3077", "2176", "507", "3239", "3316", "3370", "2179", "2191", "3155", "3024", "3382", "2206", "3144", "2082", "1701"]
}, {
    "City": "Ukunda",
    "DBTZ": "U",
    "Name": "Ukunda Airport",
    "Country": "Kenya",
    "IATA/FAA": "UKA",
    "Longitude": "39.5714",
    "ICAO": "HKUK",
    "Airport ID": "7976",
    "Latitude": "-4.29694",
    "Timezone": "0",
    "DST": "3",
    "destinations": ["1147"]
}, {
    "City": "Manzanillo",
    "DBTZ": "S",
    "Name": "Playa De Oro Intl",
    "Country": "Mexico",
    "IATA/FAA": "ZLO",
    "Longitude": "-104.558631",
    "ICAO": "MMZO",
    "Airport ID": "1858",
    "Latitude": "19.144778",
    "Timezone": "30",
    "DST": "-6",
    "destinations": ["1824", "3484", "3550", "1847"]
}, {
    "City": "Flinders Island",
    "DBTZ": "O",
    "Name": "Flinders Island Airport",
    "Country": "Australia",
    "IATA/FAA": "FLS",
    "Longitude": "147.993",
    "ICAO": "YFLI",
    "Airport ID": "6267",
    "Latitude": "-40.0917",
    "Timezone": "10",
    "DST": "10",
    "destinations": ["3337"]
}, {
    "City": "Devonport",
    "DBTZ": "O",
    "Name": "Devonport Airport",
    "Country": "Australia",
    "IATA/FAA": "DPO",
    "Longitude": "146.43",
    "ICAO": "YDPO",
    "Airport ID": "6264",
    "Latitude": "-41.1697",
    "Timezone": "33",
    "DST": "10",
    "destinations": ["3339"]
}, {
    "City": "Elcho Island",
    "DBTZ": "O",
    "Name": "Elcho Island Airport",
    "Country": "Australia",
    "IATA/FAA": "ELC",
    "Longitude": "135.571",
    "ICAO": "YELD",
    "Airport ID": "6265",
    "Latitude": "-12.0194",
    "Timezone": "101",
    "DST": "9.5",
    "destinations": ["6296"]
}, {
    "City": "Doomadgee",
    "DBTZ": "O",
    "Name": "Doomadgee Airport",
    "Country": "Australia",
    "IATA/FAA": "DMD",
    "Longitude": "138.822",
    "ICAO": "YDMG",
    "Airport ID": "6262",
    "Latitude": "-17.9403",
    "Timezone": "153",
    "DST": "10",
    "destinations": ["6245", "3324", "6304"]
}, {
    "City": "Coen",
    "DBTZ": "O",
    "Name": "Coen Airport",
    "Country": "Australia",
    "IATA/FAA": "CUQ",
    "Longitude": "143.114",
    "ICAO": "YCOE",
    "Airport ID": "6260",
    "Latitude": "-13.7608",
    "Timezone": "532",
    "DST": "10",
    "destinations": ["6286"]
}, {
    "City": "Lokichoggio",
    "DBTZ": "U",
    "Name": "Lokichoggio Airport",
    "Country": "Kenya",
    "IATA/FAA": "LKG",
    "Longitude": "34.348186",
    "ICAO": "HKLK",
    "Airport ID": "5693",
    "Latitude": "4.204117",
    "Timezone": "2074",
    "DST": "3",
    "destinations": ["1147"]
}, {
    "City": "Toluca",
    "DBTZ": "S",
    "Name": "Licenciado Adolfo Lopez Mateos Intl",
    "Country": "Mexico",
    "IATA/FAA": "TLC",
    "Longitude": "-99.566008",
    "ICAO": "MMTO",
    "Airport ID": "1850",
    "Latitude": "19.337072",
    "Timezone": "8466",
    "DST": "-6",
    "destinations": ["1783", "1852", "3877", "1825", "1836", "3621", "1840", "1856", "1804", "3533"]
}, {
    "City": "Tapachula",
    "DBTZ": "S",
    "Name": "Tapachula Intl",
    "Country": "Mexico",
    "IATA/FAA": "TAP",
    "Longitude": "-92.370025",
    "ICAO": "MMTP",
    "Airport ID": "1851",
    "Latitude": "14.794339",
    "Timezone": "97",
    "DST": "-6",
    "destinations": ["1767", "1824"]
}, {
    "City": "Cancun",
    "DBTZ": "S",
    "Name": "Cancun Intl",
    "Country": "Mexico",
    "IATA/FAA": "CUN",
    "Longitude": "-86.877083",
    "ICAO": "MMUN",
    "Airport ID": "1852",
    "Latitude": "21.036528",
    "Timezone": "20",
    "DST": "-6",
    "destinations": ["1824", "1825", "1850", "1800", "1819", "1957", "3448", "3876", "3670", "3797", "3576", "3830", "3752", "3462", "345", "146", "178", "193", "3682", "3645", "1909", "3585", "3484", "1229", "3878", "3858", "1871", "3626", "3536", "3988", "2709", "1892", "3533", "502", "1555", "1524", "3550", "340", "478", "3488", "3486", "3751", "3458", "3570", "3678", "3673", "3849", "3747", "3717", "3621", "2789", "2650", "580", "1382", "73", "111", "156", "3494", "3714", "3469", "2988", "1804", "1829", "1839", "1848", "1846", "1845", "1854", "1853", "49", "1785", "1815", "1797", "1807", "1831", "1838", "1842", "1847"]
}, {
    "City": "Villahermosa",
    "DBTZ": "S",
    "Name": "C P A Carlos Rovirosa Intl",
    "Country": "Mexico",
    "IATA/FAA": "VSA",
    "Longitude": "-92.817361",
    "ICAO": "MMVA",
    "Airport ID": "1853",
    "Latitude": "17.997",
    "Timezone": "46",
    "DST": "-6",
    "destinations": ["1824", "1819", "1854", "1830", "3550", "1852", "1804", "1825"]
}, {
    "City": "Vera Cruz",
    "DBTZ": "S",
    "Name": "General Heriberto Jara Intl",
    "Country": "Mexico",
    "IATA/FAA": "VER",
    "Longitude": "-96.187267",
    "ICAO": "MMVR",
    "Airport ID": "1854",
    "Latitude": "19.145931",
    "Timezone": "90",
    "DST": "-6",
    "destinations": ["1824", "1853", "1825", "1848", "3550", "1852", "1804", "1839", "1847"]
}, {
    "City": "Zacatecas",
    "DBTZ": "S",
    "Name": "General Leobardo C Ruiz Intl",
    "Country": "Mexico",
    "IATA/FAA": "ZCL",
    "Longitude": "-102.68689",
    "ICAO": "MMZC",
    "Airport ID": "1855",
    "Latitude": "22.897112",
    "Timezone": "7141",
    "DST": "-6",
    "destinations": ["1824", "3670", "3484", "3747", "1847"]
}, {
    "City": "Zihuatanejo",
    "DBTZ": "S",
    "Name": "Ixtapa Zihuatanejo Intl",
    "Country": "Mexico",
    "IATA/FAA": "ZIH",
    "Longitude": "-101.460536",
    "ICAO": "MMZH",
    "Airport ID": "1856",
    "Latitude": "17.601569",
    "Timezone": "26",
    "DST": "-6",
    "destinations": ["1824", "1850", "3462", "3484", "3550"]
}, {
    "City": "Gladstone",
    "DBTZ": "O",
    "Name": "Gladstone Airport",
    "Country": "Australia",
    "IATA/FAA": "GLT",
    "Longitude": "151.223",
    "ICAO": "YGLA",
    "Airport ID": "6269",
    "Latitude": "-23.8697",
    "Timezone": "64",
    "DST": "10",
    "destinations": ["3320", "3329", "3361"]
}, {
    "City": "Aqaba",
    "DBTZ": "E",
    "Name": "Aqaba King Hussein Intl",
    "Country": "Jordan",
    "IATA/FAA": "AQJ",
    "Longitude": "35.018067",
    "ICAO": "OJAQ",
    "Airport ID": "2172",
    "Latitude": "29.611619",
    "Timezone": "175",
    "DST": "2",
    "destinations": ["2170", "1701"]
}, {
    "City": "Amman",
    "DBTZ": "E",
    "Name": "Queen Alia Intl",
    "Country": "Jordan",
    "IATA/FAA": "AMM",
    "Longitude": "35.993214",
    "ICAO": "OJAI",
    "Airport ID": "2170",
    "Latitude": "31.722556",
    "Timezone": "2395",
    "DST": "2",
    "destinations": ["1154", "1157", "3797", "3830", "146", "340", "346", "351", "1613", "1382", "210", "1555", "507", "3410", "2188", "2179", "8076", "2191", "2057", "1218", "1229", "3977", "2177", "3976", "3980", "1590", "2176", "3885", "1128", "2241", "580", "2172", "3941", "4130", "2997", "2234", "3093", "4029", "2064", "3989", "1665", "1701", "5952", "2072", "2939", "1175", "1197", "273", "2194", "2074", "5804", "1657", "2082", "4057", "287", "1678"]
}, {
    "City": "Huanuco",
    "DBTZ": "U",
    "Name": "Alferez Fap David Figueroa Fernandini Airport",
    "Country": "Peru",
    "IATA/FAA": "HUU",
    "Longitude": "-76.204797",
    "ICAO": "SPNC",
    "Airport ID": "6067",
    "Latitude": "-9.878811",
    "Timezone": "6070",
    "DST": "-5",
    "destinations": ["2789"]
}, {
    "City": "Kuwait",
    "DBTZ": "U",
    "Name": "Kuwait Intl",
    "Country": "Kuwait",
    "IATA/FAA": "KWI",
    "Longitude": "47.968928",
    "ICAO": "OKBK",
    "Airport ID": "2176",
    "Latitude": "29.226567",
    "Timezone": "206",
    "DST": "3",
    "destinations": ["2179", "2997", "507", "580", "2064", "2994", "3007", "1555", "3069", "3076", "3410", "2188", "2104", "4331", "2157", "1107", "2072", "2050", "8076", "2191", "2057", "4150", "5934", "3137", "2241", "3974", "2170", "5691", "2177", "1128", "8235", "1132", "7490", "2082", "4317", "3885", "3024", "3136", "3093", "340", "4330", "2223", "1701", "3797", "3304", "2207", "3144", "2194", "3153", "4057", "2219", "6776", "2074", "287", "3714", "2090"]
}, {
    "City": "Beirut",
    "DBTZ": "E",
    "Name": "Rafic Hariri Intl",
    "Country": "Lebanon",
    "IATA/FAA": "BEY",
    "Longitude": "35.488389",
    "ICAO": "OLBA",
    "Airport ID": "2177",
    "Latitude": "33.820931",
    "Timezone": "87",
    "DST": "2",
    "destinations": ["1382", "210", "1074", "1555", "1524", "2954", "507", "1197", "2188", "1107", "2179", "8076", "2191", "2057", "4330", "4331", "2170", "3980", "2176", "340", "248", "3941", "4130", "302", "2234", "1128", "2064", "2241", "3989", "3964", "1665", "1701", "2072", "270", "273", "2074", "7490", "1657", "2082", "2985", "287", "3410", "4317", "337", "2194"]
}, {
    "City": "Dzaoudzi",
    "DBTZ": "U",
    "Name": "Dzaoudzi Pamandzi",
    "Country": "Mayotte",
    "IATA/FAA": "DZA",
    "Longitude": "45.281113",
    "ICAO": "FMCZ",
    "Airport ID": "915",
    "Latitude": "-12.804722",
    "Timezone": "23",
    "DST": "3",
    "destinations": ["912", "923", "929", "918", "916", "1177", "930"]
}, {
    "City": "Wilmington",
    "DBTZ": "A",
    "Name": "Wilmington Intl",
    "Country": "United States",
    "IATA/FAA": "ILM",
    "Longitude": "-77.902569",
    "ICAO": "KILM",
    "Airport ID": "3845",
    "Latitude": "34.270615",
    "Timezone": "32",
    "DST": "-5",
    "destinations": ["3876", "3520", "3697", "3752", "3682"]
}, {
    "City": "St.-pierre",
    "DBTZ": "U",
    "Name": "St Pierre Pierrefonds",
    "Country": "Reunion",
    "IATA/FAA": "ZSE",
    "Longitude": "55.423581",
    "ICAO": "FMEP",
    "Airport ID": "917",
    "Latitude": "-21.320039",
    "Timezone": "56",
    "DST": "4",
    "destinations": ["893"]
}, {
    "City": "Abu Dhabi",
    "DBTZ": "U",
    "Name": "Abu Dhabi Intl",
    "Country": "United Arab Emirates",
    "IATA/FAA": "AUH",
    "Longitude": "54.651138",
    "ICAO": "OMAA",
    "Airport ID": "2179",
    "Latitude": "24.432972",
    "Timezone": "88",
    "DST": "4",
    "destinations": ["2994", "3131", "2997", "3137", "3136", "3093", "2064", "3141", "2176", "3144", "3153", "3941", "3024", "345", "3714", "2223", "3797", "2207", "507", "2194", "3830", "2057", "340", "3179", "346", "3364", "3316", "351", "193", "580", "1382", "1074", "3885", "3275", "3395", "1555", "813", "2206", "1175", "3304", "3339", "3156", "1524", "4059", "994", "3361", "2954", "3074", "302", "1197", "599", "2908", "2170", "1739", "2177", "4130", "2234", "1128", "3076", "4029", "2241", "3989", "2564", "1665", "3077", "3930", "4330", "1701", "3098", "2072", "3125", "273", "478", "2074", "2397", "2279", "2219", "3406", "2082", "3980", "3205", "2910", "3977", "5954", "3974", "2222", "2109"]
}, {
    "City": "Ndola",
    "DBTZ": "U",
    "Name": "Ndola",
    "Country": "Zambia",
    "IATA/FAA": "NLA",
    "Longitude": "28.664944",
    "ICAO": "FLND",
    "Airport ID": "910",
    "Latitude": "-12.998139",
    "Timezone": "4167",
    "DST": "2",
    "destinations": ["813", "1107", "1038", "4059", "907", "5613"]
}, {
    "City": "Moroni",
    "DBTZ": "U",
    "Name": "Prince Said Ibrahim",
    "Country": "Comoros",
    "IATA/FAA": "HAH",
    "Longitude": "43.27185",
    "ICAO": "FMCH",
    "Airport ID": "912",
    "Latitude": "-11.533661",
    "Timezone": "93",
    "DST": "3",
    "destinations": ["4059", "1145", "4119", "918", "1177", "915", "916"]
}, {
    "City": "Petrolina",
    "DBTZ": "S",
    "Name": "Senador Nilo Coelho",
    "Country": "Brazil",
    "IATA/FAA": "PNZ",
    "Longitude": "-40.569097",
    "ICAO": "SBPL",
    "Airport ID": "2604",
    "Latitude": "-9.362411",
    "Timezone": "1263",
    "DST": "-3",
    "destinations": ["2537", "2610", "2531", "2621"]
}, {
    "City": "Kwigillingok",
    "DBTZ": "A",
    "Name": "Kwigillingok Airport",
    "Country": "United States",
    "IATA/FAA": "KWK",
    "Longitude": "-163.168611",
    "ICAO": "PAGG",
    "Airport ID": "7096",
    "Latitude": "59.876389",
    "Timezone": "18",
    "DST": "-9",
    "destinations": ["3599", "7213", "7201"]
}, {
    "City": "Porto Velho",
    "DBTZ": "S",
    "Name": "Governador Jorge Teixeira De Oliveira",
    "Country": "Brazil",
    "IATA/FAA": "PVH",
    "Longitude": "-63.902281",
    "ICAO": "SBPV",
    "Airport ID": "2607",
    "Latitude": "-8.709294",
    "Timezone": "294",
    "DST": "-4",
    "destinations": ["2548", "2551", "2609", "2531"]
}, {
    "City": "Passo Fundo",
    "DBTZ": "S",
    "Name": "Lauro Kurtz",
    "Country": "Brazil",
    "IATA/FAA": "PFB",
    "Longitude": "-52.326558",
    "ICAO": "SBPF",
    "Airport ID": "2602",
    "Latitude": "-28.243989",
    "Timezone": "2376",
    "DST": "-3",
    "destinations": ["2599", "2578", "6477", "2564"]
}, {
    "City": "Marshall",
    "DBTZ": "A",
    "Name": "Marshall Don Hunter Sr. Airport",
    "Country": "United States",
    "IATA/FAA": "MLL",
    "Longitude": "-162.026111",
    "ICAO": "PADM",
    "Airport ID": "7097",
    "Latitude": "61.8646418",
    "Timezone": "103",
    "DST": "-9",
    "destinations": ["7216", "7098"]
}, {
    "City": "Baton Rouge",
    "DBTZ": "A",
    "Name": "Baton Rouge Metro Ryan Fld",
    "Country": "United States",
    "IATA/FAA": "BTR",
    "Longitude": "-91.149639",
    "ICAO": "KBTR",
    "Airport ID": "3846",
    "Latitude": "30.533167",
    "Timezone": "70",
    "DST": "-6",
    "destinations": ["3876", "3670", "3682", "3550"]
}, {
    "City": "Momote",
    "DBTZ": "U",
    "Name": "Momote Airport",
    "Country": "Papua New Guinea",
    "IATA/FAA": "MAS",
    "Longitude": "147.424",
    "ICAO": "AYMO",
    "Airport ID": "5430",
    "Latitude": "-2.06189",
    "Timezone": "12",
    "DST": "10",
    "destinations": ["4", "5428", "2", "5", "5435"]
}, {
    "City": "Sorong",
    "DBTZ": "N",
    "Name": "Jefman",
    "Country": "Indonesia",
    "IATA/FAA": "SOQ",
    "Longitude": "131.121194",
    "ICAO": "WASS",
    "Airport ID": "3261",
    "Latitude": "-0.926358",
    "Timezone": "10",
    "DST": "9",
    "destinations": ["3260", "3240", "3256", "3259", "3257", "3250"]
}, {
    "City": "Spring Point",
    "DBTZ": "U",
    "Name": "Spring Point",
    "Country": "Bahamas",
    "IATA/FAA": "AXP",
    "Longitude": "-73.970858",
    "ICAO": "MYAP",
    "Airport ID": "1932",
    "Latitude": "22.441828",
    "Timezone": "11",
    "DST": "-5",
    "destinations": ["5859", "1953"]
}, {
    "City": "Kuching",
    "DBTZ": "N",
    "Name": "Kuching Intl",
    "Country": "Malaysia",
    "IATA/FAA": "KCH",
    "Longitude": "110.346933",
    "ICAO": "WBGG",
    "Airport ID": "3263",
    "Latitude": "1.484697",
    "Timezone": "89",
    "DST": "8",
    "destinations": ["3269", "3262", "3303", "3298", "3304", "3305", "3266", "3308", "3267", "3316", "4056", "6217", "3284", "6770"]
}, {
    "City": "Marsh Harbor",
    "DBTZ": "U",
    "Name": "Marsh Harbour",
    "Country": "Bahamas",
    "IATA/FAA": "MHH",
    "Longitude": "-77.083472",
    "ICAO": "MYAM",
    "Airport ID": "1930",
    "Latitude": "26.511406",
    "Timezone": "6",
    "DST": "-5",
    "destinations": ["3533", "3712", "3878", "3722", "3576", "1953"]
}, {
    "City": "Marudi",
    "DBTZ": "N",
    "Name": "Marudi",
    "Country": "Malaysia",
    "IATA/FAA": "MUR",
    "Longitude": "114.321944",
    "ICAO": "WBGM",
    "Airport ID": "3265",
    "Latitude": "4.1775",
    "Timezone": "103",
    "DST": "8",
    "destinations": ["6220", "5855", "6215", "6769", "3266", "6216"]
}, {
    "City": "Limbang",
    "DBTZ": "N",
    "Name": "Limbang",
    "Country": "Malaysia",
    "IATA/FAA": "LMN",
    "Longitude": "115.010439",
    "ICAO": "WBGJ",
    "Airport ID": "3264",
    "Latitude": "4.808303",
    "Timezone": "14",
    "DST": "8",
    "destinations": ["3266"]
}, {
    "City": "Sibu",
    "DBTZ": "N",
    "Name": "Sibu",
    "Country": "Malaysia",
    "IATA/FAA": "SBW",
    "Longitude": "111.985322",
    "ICAO": "WBGS",
    "Airport ID": "3267",
    "Latitude": "2.261603",
    "Timezone": "122",
    "DST": "8",
    "destinations": ["3303", "3263", "3304", "3269", "3262", "3266"]
}, {
    "City": "Treasure Cay",
    "DBTZ": "U",
    "Name": "Treasure Cay",
    "Country": "Bahamas",
    "IATA/FAA": "TCB",
    "Longitude": "-77.391269",
    "ICAO": "MYAT",
    "Airport ID": "1934",
    "Latitude": "26.745336",
    "Timezone": "8",
    "DST": "-5",
    "destinations": ["3533"]
}, {
    "City": "Kota Kinabalu",
    "DBTZ": "N",
    "Name": "Kota Kinabalu Intl",
    "Country": "Malaysia",
    "IATA/FAA": "BKI",
    "Longitude": "116.051181",
    "ICAO": "WBKK",
    "Airport ID": "3269",
    "Latitude": "5.937208",
    "Timezone": "10",
    "DST": "8",
    "destinations": ["2397", "3406", "3370", "3275", "3386", "3077", "3303", "3298", "3263", "3304", "3266", "3308", "4141", "3316", "3374", "2276", "3271", "3272", "2279", "3930", "3262", "6222", "3270", "3268", "6219", "4056", "3351", "2433", "3267", "3940"]
}, {
    "City": "Lahad Datu",
    "DBTZ": "N",
    "Name": "Lahad Datu",
    "Country": "Malaysia",
    "IATA/FAA": "LDU",
    "Longitude": "118.324036",
    "ICAO": "WBKD",
    "Airport ID": "3268",
    "Latitude": "5.032247",
    "Timezone": "45",
    "DST": "8",
    "destinations": ["3269"]
}, {
    "City": "Wenshan",
    "DBTZ": "U",
    "Name": "Wenshan Airport",
    "Country": "China",
    "IATA/FAA": "WNH",
    "Longitude": "104.243056",
    "ICAO": "",
    "Airport ID": "7528",
    "Latitude": "23.375833",
    "Timezone": "1000",
    "DST": "8",
    "destinations": ["3382"]
}, {
    "City": "Wapenamanda",
    "DBTZ": "U",
    "Name": "Wapenamanda Airport",
    "Country": "Papua New Guinea",
    "IATA/FAA": "WBM",
    "Longitude": "143.895",
    "ICAO": "AYWD",
    "Airport ID": "5437",
    "Latitude": "-5.6433",
    "Timezone": "5889",
    "DST": "10",
    "destinations": ["5"]
}, {
    "City": "Spokane",
    "DBTZ": "A",
    "Name": "Spokane Intl",
    "Country": "United States",
    "IATA/FAA": "GEG",
    "Longitude": "-117.533833",
    "ICAO": "KGEG",
    "Airport ID": "3467",
    "Latitude": "47.619861",
    "Timezone": "2376",
    "DST": "-8",
    "destinations": ["3462", "3720", "3577", "3484", "3858", "3536", "3751", "3495", "3877", "3453"]
}, {
    "City": "Longview",
    "DBTZ": "A",
    "Name": "East Texas Rgnl",
    "Country": "United States",
    "IATA/FAA": "GGG",
    "Longitude": "-94.711486",
    "ICAO": "KGGG",
    "Airport ID": "3465",
    "Latitude": "32.384014",
    "Timezone": "365",
    "DST": "-6",
    "destinations": ["3670"]
}, {
    "City": "Pietermaritzburg",
    "DBTZ": "U",
    "Name": "Pietermaritzburg",
    "Country": "South Africa",
    "IATA/FAA": "PZB",
    "Longitude": "30.398667",
    "ICAO": "FAPM",
    "Airport ID": "843",
    "Latitude": "-29.648975",
    "Timezone": "2423",
    "DST": "2",
    "destinations": ["813"]
}, {
    "City": "Phoenix",
    "DBTZ": "N",
    "Name": "Phoenix Sky Harbor Intl",
    "Country": "United States",
    "IATA/FAA": "PHX",
    "Longitude": "-112.011583",
    "ICAO": "KPHX",
    "Airport ID": "3462",
    "Latitude": "33.434278",
    "Timezone": "1135",
    "DST": "-7",
    "destinations": ["4019", "3774", "3682", "3673", "3558", "3495", "3448", "3644", "3849", "3876", "3759", "1852", "3520", "3751", "3670", "3713", "3729", "3645", "3559", "3494", "3687", "4261", "3533", "1804", "3467", "4063", "3728", "3550", "3585", "3797", "3514", "3877", "3484", "3582", "507", "3602", "3458", "3878", "1824", "3576", "3717", "3948", "3858", "1826", "3453", "3456", "3454", "3734", "3830", "3720", "3752", "3570", "3839", "1836", "3807", "3731", "3621", "3949", "5768", "3577", "3469", "3748", "1840", "3536", "3817", "3867", "3678", "3646", "3636", "49", "3596", "156", "178", "1856", "193", "1807", "7078", "3566", "3486", "3714", "3690", "3820", "3660", "3747", "3861", "3863", "3626", "4014", "3855", "4124", "6135"]
}, {
    "City": "Harrison",
    "DBTZ": "A",
    "Name": "Boone Co",
    "Country": "United States",
    "IATA/FAA": "HRO",
    "Longitude": "-93.154728",
    "ICAO": "KHRO",
    "Airport ID": "3461",
    "Latitude": "36.261519",
    "Timezone": "1365",
    "DST": "-6",
    "destinations": ["3458", "3473"]
}, {
    "City": "Dillingham",
    "DBTZ": "A",
    "Name": "Dillingham",
    "Country": "United States",
    "IATA/FAA": "DLG",
    "Longitude": "-158.5055",
    "ICAO": "PADL",
    "Airport ID": "3460",
    "Latitude": "59.044667",
    "Timezone": "74",
    "DST": "-9",
    "destinations": ["3774", "3794"]
}, {
    "City": "Barreiras",
    "DBTZ": "S",
    "Name": "Barreiras Airport",
    "Country": "Brazil",
    "IATA/FAA": "BRA",
    "Longitude": "-45",
    "ICAO": "SNBR",
    "Airport ID": "7373",
    "Latitude": "-12.083333",
    "Timezone": "1356",
    "DST": "-3",
    "destinations": ["2531", "2621"]
}, {
    "City": "Parintins",
    "DBTZ": "S",
    "Name": "Julio Belem Airport",
    "Country": "Brazil",
    "IATA/FAA": "PIN",
    "Longitude": "-56.735833",
    "ICAO": "SWPI",
    "Airport ID": "7372",
    "Latitude": "-2.627778",
    "Timezone": "500",
    "DST": "-4",
    "destinations": ["2551"]
}, {
    "City": "Chuathbaluk",
    "DBTZ": "A",
    "Name": "Chuathbaluk Airport",
    "Country": "United States",
    "IATA/FAA": "CHU",
    "Longitude": "-159.215556",
    "ICAO": "PACH",
    "Airport ID": "7091",
    "Latitude": "61.579167",
    "Timezone": "243",
    "DST": "-9",
    "destinations": ["7238"]
}, {
    "City": "Kooddoo",
    "DBTZ": "U",
    "Name": "Kooddoo",
    "Country": "Maldives",
    "IATA/FAA": "GKK",
    "Longitude": "73.433",
    "ICAO": "VRMO",
    "Airport ID": "8892",
    "Latitude": "0.7308",
    "Timezone": "10",
    "DST": "5",
    "destinations": ["6185", "3156"]
}, {
    "City": "Richard's Bay",
    "DBTZ": "U",
    "Name": "Richards Bay",
    "Country": "South Africa",
    "IATA/FAA": "RCB",
    "Longitude": "32.092111",
    "ICAO": "FARB",
    "Airport ID": "849",
    "Latitude": "-28.741039",
    "Timezone": "109",
    "DST": "2",
    "destinations": ["813"]
}, {
    "City": "Santa Terezinha",
    "DBTZ": "S",
    "Name": "Confresa Airport",
    "Country": "Brazil",
    "IATA/FAA": "STZ",
    "Longitude": "-50.502778",
    "ICAO": "SWST",
    "Airport ID": "7374",
    "Latitude": "-10.47",
    "Timezone": "650",
    "DST": "-4",
    "destinations": ["8243"]
}, {
    "City": "Perryville",
    "DBTZ": "A",
    "Name": "Perryville Airport",
    "Country": "United States",
    "IATA/FAA": "KPV",
    "Longitude": "-159.160833",
    "ICAO": "PAPE",
    "Airport ID": "7157",
    "Latitude": "55.906667",
    "Timezone": "29",
    "DST": "-9",
    "destinations": ["3794"]
}, {
    "City": "Trelew",
    "DBTZ": "N",
    "Name": "Almirante Zar",
    "Country": "Argentina",
    "IATA/FAA": "REL",
    "Longitude": "-65.270319",
    "ICAO": "SAVT",
    "Airport ID": "2490",
    "Latitude": "-43.2105",
    "Timezone": "141",
    "DST": "-3",
    "destinations": ["2501", "2513", "2487", "2488", "2442", "3988"]
}, {
    "City": "Alluitsup Paa",
    "DBTZ": "E",
    "Name": "Alluitsup Paa Heliport",
    "Country": "Greenland",
    "IATA/FAA": "LLU",
    "Longitude": "-45.5778",
    "ICAO": "BGAP",
    "Airport ID": "5438",
    "Latitude": "60.4644",
    "Timezone": "89",
    "DST": "-3",
    "destinations": ["5442", "5444"]
}, {
    "City": "Provo",
    "DBTZ": "A",
    "Name": "Provo Municipal Airport",
    "Country": "United States",
    "IATA/FAA": "PVU",
    "Longitude": "-111.72222222",
    "ICAO": "KPVU",
    "Airport ID": "7579",
    "Latitude": "40.21805555",
    "Timezone": "4497",
    "DST": "-7",
    "destinations": ["6505", "3484", "3453"]
}, {
    "City": "Port Protection",
    "DBTZ": "A",
    "Name": "Port Protection Seaplane Base",
    "Country": "United States",
    "IATA/FAA": "PPV",
    "Longitude": "-133.61",
    "ICAO": "",
    "Airport ID": "7151",
    "Latitude": "56.328889",
    "Timezone": "0",
    "DST": "-9",
    "destinations": ["3808"]
}, {
    "City": "Thief River Falls",
    "DBTZ": "A",
    "Name": "Thief River Falls",
    "Country": "United States",
    "IATA/FAA": "TVF",
    "Longitude": "-96.185",
    "ICAO": "KTVF",
    "Airport ID": "7018",
    "Latitude": "48.065556",
    "Timezone": "1116",
    "DST": "-6",
    "destinations": ["3442"]
}, {
    "City": "Tuntutuliak",
    "DBTZ": "A",
    "Name": "Tuntutuliak Airport",
    "Country": "United States",
    "IATA/FAA": "WTL",
    "Longitude": "-162.666944",
    "ICAO": "",
    "Airport ID": "7099",
    "Latitude": "60.335278",
    "Timezone": "16",
    "DST": "-9",
    "destinations": ["7213", "3599"]
}, {
    "City": "Belgrade",
    "DBTZ": "E",
    "Name": "Beograd",
    "Country": "Serbia",
    "IATA/FAA": "BEG",
    "Longitude": "20.309139",
    "ICAO": "LYBE",
    "Airport ID": "1739",
    "Latitude": "44.818444",
    "Timezone": "335",
    "DST": "1",
    "destinations": ["350", "3941", "345", "340", "351", "1382", "1555", "1524", "1489", "737", "644", "580", "2179", "507", "1665", "2188", "1646", "5794", "302", "609", "1197", "1569", "1657", "1587", "4317", "1486", "1737", "1194", "2985", "1741", "1743", "1590", "1196", "1613", "679", "1678", "346", "1206", "1682", "1701", "287", "1367", "304", "373", "585", "3986", "691", "492", "1423", "708", "699", "664"]
}, {
    "City": "Gibraltar",
    "DBTZ": "N",
    "Name": "Gibraltar",
    "Country": "Gibraltar",
    "IATA/FAA": "GIB",
    "Longitude": "-5.349664",
    "ICAO": "LXGB",
    "Airport ID": "1738",
    "Latitude": "36.151219",
    "Timezone": "15",
    "DST": "1",
    "destinations": ["507", "502", "469", "492", "478"]
}, {
    "City": "Maceio",
    "DBTZ": "S",
    "Name": "Zumbi Dos Palmares",
    "Country": "Brazil",
    "IATA/FAA": "MCZ",
    "Longitude": "-35.791678",
    "ICAO": "SBMO",
    "Airport ID": "2590",
    "Latitude": "-9.510808",
    "Timezone": "387",
    "DST": "-3",
    "destinations": ["2522", "2610", "2578", "2531", "2560", "2564", "2621"]
}, {
    "City": "Chisinau",
    "DBTZ": "E",
    "Name": "Chisinau Intl",
    "Country": "Moldova",
    "IATA/FAA": "KIV",
    "Longitude": "28.930978",
    "ICAO": "LUKK",
    "Airport ID": "1735",
    "Latitude": "46.927744",
    "Timezone": "399",
    "DST": "2",
    "destinations": ["2965", "3941", "1538", "1367", "4029", "599", "1555", "340", "1701", "2939", "1197", "2948", "1638", "1524", "1657", "2974", "548", "1551", "1550", "3953", "8076", "346", "1613", "1553", "1539"]
}, {
    "City": "Skopje",
    "DBTZ": "E",
    "Name": "Skopje",
    "Country": "Macedonia",
    "IATA/FAA": "SKP",
    "Longitude": "21.621381",
    "ICAO": "LWSK",
    "Airport ID": "1737",
    "Latitude": "41.961622",
    "Timezone": "781",
    "DST": "1",
    "destinations": ["1678", "1555", "2188", "1569", "1739", "1613", "1208", "4317", "337", "1701", "1525", "344", "304", "373", "585", "3986", "691", "355", "492", "1423", "708", "699", "1539"]
}, {
    "City": "Caye Caulker",
    "DBTZ": "U",
    "Name": "Caye Caulker Airport",
    "Country": "Belize",
    "IATA/FAA": "CUK",
    "Longitude": "-88.0325",
    "ICAO": "",
    "Airport ID": "5454",
    "Latitude": "17.7347",
    "Timezone": "1",
    "DST": "-6",
    "destinations": ["8227", "1957", "4279", "6463"]
}, {
    "City": "Hermosillo",
    "DBTZ": "S",
    "Name": "General Ignacio P Garcia Intl",
    "Country": "Mexico",
    "IATA/FAA": "HMO",
    "Longitude": "-111.047858",
    "ICAO": "MMHO",
    "Airport ID": "1807",
    "Latitude": "29.095858",
    "Timezone": "627",
    "DST": "-7",
    "destinations": ["1824", "3462", "1794", "1796", "1792", "1797", "1804", "3484", "1814", "1825", "1820", "1847", "1852"]
}, {
    "City": "Sondrestrom",
    "DBTZ": "E",
    "Name": "Sondre Stromfjord",
    "Country": "Greenland",
    "IATA/FAA": "SFJ",
    "Longitude": "-50.689325",
    "ICAO": "BGSF",
    "Airport ID": "9",
    "Latitude": "67.016969",
    "Timezone": "165",
    "DST": "-3",
    "destinations": ["609", "8", "3995", "3997", "5447", "5443", "4248", "7"]
}, {
    "City": "Unalakleet",
    "DBTZ": "A",
    "Name": "Unalakleet Airport",
    "Country": "United States",
    "IATA/FAA": "UNK",
    "Longitude": "-160.798889",
    "ICAO": "PAUN",
    "Airport ID": "7088",
    "Latitude": "63.888333",
    "Timezone": "21",
    "DST": "-9",
    "destinations": ["3774", "7192", "7191", "3615"]
}, {
    "City": "Jessore",
    "DBTZ": "U",
    "Name": "Jessore",
    "Country": "Bangladesh",
    "IATA/FAA": "JSR",
    "Longitude": "89.160833",
    "ICAO": "VGJR",
    "Airport ID": "3071",
    "Latitude": "23.1838",
    "Timezone": "20",
    "DST": "6",
    "destinations": ["3076"]
}, {
    "City": "Rajshahi",
    "DBTZ": "U",
    "Name": "Shah Mokhdum",
    "Country": "Bangladesh",
    "IATA/FAA": "RJH",
    "Longitude": "88.616511",
    "ICAO": "VGRJ",
    "Airport ID": "3072",
    "Latitude": "24.437219",
    "Timezone": "64",
    "DST": "6",
    "destinations": ["3076"]
}, {
    "City": "Saidpur",
    "DBTZ": "U",
    "Name": "Saidpur",
    "Country": "Bangladesh",
    "IATA/FAA": "SPD",
    "Longitude": "88.908869",
    "ICAO": "VGSD",
    "Airport ID": "3073",
    "Latitude": "25.759228",
    "Timezone": "125",
    "DST": "6",
    "destinations": ["3076", "3072"]
}, {
    "City": "Sylhet Osmani",
    "DBTZ": "U",
    "Name": "Osmany Intl",
    "Country": "Bangladesh",
    "IATA/FAA": "ZYL",
    "Longitude": "91.866783",
    "ICAO": "VGSY",
    "Airport ID": "3074",
    "Latitude": "24.963242",
    "Timezone": "50",
    "DST": "6",
    "destinations": ["3076"]
}, {
    "City": "Pendleton",
    "DBTZ": "A",
    "Name": "Eastern Oregon Regional Airport",
    "Country": "United States",
    "IATA/FAA": "PDT",
    "Longitude": "-118.841389",
    "ICAO": "KPDT",
    "Airport ID": "6883",
    "Latitude": "45.695",
    "Timezone": "1497",
    "DST": "-8",
    "destinations": ["3720"]
}, {
    "City": "Montrose CO",
    "DBTZ": "A",
    "Name": "Montrose Regional Airport",
    "Country": "United States",
    "IATA/FAA": "MTJ",
    "Longitude": "-107.894242",
    "ICAO": "KMTJ",
    "Airport ID": "6880",
    "Latitude": "38.509794",
    "Timezone": "5759",
    "DST": "-7",
    "destinations": ["3751"]
}, {
    "City": "Riverton WY",
    "DBTZ": "A",
    "Name": "Riverton Regional",
    "Country": "United States",
    "IATA/FAA": "RIW",
    "Longitude": "-108.459722",
    "ICAO": "KRIW",
    "Airport ID": "6881",
    "Latitude": "43.064167",
    "Timezone": "5525",
    "DST": "-7",
    "destinations": ["3751"]
}, {
    "City": "Minsk 2",
    "DBTZ": "E",
    "Name": "Minsk 2",
    "Country": "Belarus",
    "IATA/FAA": "MSQ",
    "Longitude": "28.030731",
    "ICAO": "UMMS",
    "Airport ID": "2954",
    "Latitude": "53.882469",
    "Timezone": "670",
    "DST": "3",
    "destinations": ["421", "2965", "580", "737", "2976", "2179", "1218", "2177", "1489", "3971", "1382", "4029", "3964", "1555", "340", "1665", "2922", "352", "4330", "1701", "2939", "2952", "2960", "4118", "3972", "1197", "2948", "502", "1524", "1354", "1587", "3953", "2975", "337", "3973", "1590", "2910", "1613", "3959", "679", "2985"]
}, {
    "City": "Abakan",
    "DBTZ": "N",
    "Name": "Abakan",
    "Country": "Russia",
    "IATA/FAA": "ABA",
    "Longitude": "91.385",
    "ICAO": "UNAA",
    "Airport ID": "2955",
    "Latitude": "53.74",
    "Timezone": "831",
    "DST": "8",
    "destinations": ["2937", "4029", "2985", "4352"]
}, {
    "City": "Barnaul",
    "DBTZ": "N",
    "Name": "Barnaul",
    "Country": "Russia",
    "IATA/FAA": "BAX",
    "Longitude": "83.538533",
    "ICAO": "UNBB",
    "Airport ID": "2956",
    "Latitude": "53.363775",
    "Timezone": "838",
    "DST": "7",
    "destinations": ["4029", "2985"]
}, {
    "City": "Kemorovo",
    "DBTZ": "N",
    "Name": "Kemerovo",
    "Country": "Russia",
    "IATA/FAA": "KEJ",
    "Longitude": "86.107208",
    "ICAO": "UNEE",
    "Airport ID": "2957",
    "Latitude": "55.270094",
    "Timezone": "863",
    "DST": "7",
    "destinations": ["4029", "2985"]
}, {
    "City": "Kaliningrad",
    "DBTZ": "N",
    "Name": "Khrabrovo",
    "Country": "Russia",
    "IATA/FAA": "KGD",
    "Longitude": "20.592633",
    "ICAO": "UMKK",
    "Airport ID": "2952",
    "Latitude": "54.89005",
    "Timezone": "42",
    "DST": "3",
    "destinations": ["6156", "351", "2954", "3953", "6111", "2983", "2939", "4029", "2948", "2985"]
}, {
    "City": "Kitoi Bay",
    "DBTZ": "A",
    "Name": "Kitoi Bay Seaplane Base",
    "Country": "United States",
    "IATA/FAA": "KKB",
    "Longitude": "-152.370556",
    "ICAO": "",
    "Airport ID": "7168",
    "Latitude": "58.190833",
    "Timezone": "0",
    "DST": "-9",
    "destinations": ["7173"]
}, {
    "City": "Mbandaka",
    "DBTZ": "N",
    "Name": "Mbandaka",
    "Country": "Congo (Kinshasa)",
    "IATA/FAA": "MDK",
    "Longitude": "18.288744",
    "ICAO": "FZEA",
    "Airport ID": "1026",
    "Latitude": "0.0226",
    "Timezone": "1040",
    "DST": "1",
    "destinations": ["1020", "1028"]
}, {
    "City": "Kinshasa",
    "DBTZ": "N",
    "Name": "Ndjili Intl",
    "Country": "Congo (Kinshasa)",
    "IATA/FAA": "FIH",
    "Longitude": "15.444569",
    "ICAO": "FZAA",
    "Airport ID": "1020",
    "Latitude": "-4.38575",
    "Timezone": "1027",
    "DST": "1",
    "destinations": ["302", "897", "1382", "883", "1038", "1031", "1042", "1037", "1026", "1043", "5652", "970", "4059", "813", "1701"]
}, {
    "City": "Larsen Bay",
    "DBTZ": "A",
    "Name": "Larsen Bay Airport",
    "Country": "United States",
    "IATA/FAA": "KLN",
    "Longitude": "-153.976667",
    "ICAO": "PALB",
    "Airport ID": "7162",
    "Latitude": "57.535",
    "Timezone": "87",
    "DST": "-9",
    "destinations": ["7161"]
}, {
    "City": "Old Harbor",
    "DBTZ": "A",
    "Name": "Old Harbor Airport",
    "Country": "United States",
    "IATA/FAA": "OLH",
    "Longitude": "-153.269722",
    "ICAO": "",
    "Airport ID": "7163",
    "Latitude": "57.218056",
    "Timezone": "55",
    "DST": "-9",
    "destinations": ["3531"]
}, {
    "City": "Akhiok",
    "DBTZ": "A",
    "Name": "Akhiok Airport",
    "Country": "United States",
    "IATA/FAA": "AKK",
    "Longitude": "-154.1825",
    "ICAO": "PAKH",
    "Airport ID": "7160",
    "Latitude": "56.938611",
    "Timezone": "44",
    "DST": "-9",
    "destinations": ["3531"]
}, {
    "City": "Amook Bay",
    "DBTZ": "A",
    "Name": "Amook Bay Seaplane Base",
    "Country": "United States",
    "IATA/FAA": "AOS",
    "Longitude": "-153.815278",
    "ICAO": "",
    "Airport ID": "7167",
    "Latitude": "57.471389",
    "Timezone": "0",
    "DST": "-9",
    "destinations": ["7176"]
}, {
    "City": "Ouzinkie",
    "DBTZ": "A",
    "Name": "Ouzinkie Airport",
    "Country": "United States",
    "IATA/FAA": "KOZ",
    "Longitude": "-152.500511",
    "ICAO": "",
    "Airport ID": "7164",
    "Latitude": "57.922876",
    "Timezone": "55",
    "DST": "-9",
    "destinations": ["7165", "3531"]
}, {
    "City": "Port Lions",
    "DBTZ": "A",
    "Name": "Port Lions Airport",
    "Country": "United States",
    "IATA/FAA": "ORI",
    "Longitude": "-152.846111",
    "ICAO": "",
    "Airport ID": "7165",
    "Latitude": "57.885278",
    "Timezone": "52",
    "DST": "-9",
    "destinations": ["3531"]
}, {
    "City": "Santa Maria",
    "DBTZ": "S",
    "Name": "Santa Maria Airport",
    "Country": "Brazil",
    "IATA/FAA": "RIA",
    "Longitude": "-53.688153",
    "ICAO": "SBSM",
    "Airport ID": "6040",
    "Latitude": "-29.711358",
    "Timezone": "287",
    "DST": "-3",
    "destinations": ["2599", "2596", "2632"]
}, {
    "City": "Waterford",
    "DBTZ": "E",
    "Name": "Waterford",
    "Country": "Ireland",
    "IATA/FAA": "WAT",
    "Longitude": "-7.086964",
    "ICAO": "EIWF",
    "Airport ID": "605",
    "Latitude": "52.1872",
    "Timezone": "119",
    "DST": "0",
    "destinations": ["469", "478"]
}, {
    "City": "Alicante",
    "DBTZ": "E",
    "Name": "Alicante",
    "Country": "Spain",
    "IATA/FAA": "ALC",
    "Longitude": "-0.558156",
    "ICAO": "LEAL",
    "Airport ID": "1212",
    "Latitude": "38.282169",
    "Timezone": "142",
    "DST": "1",
    "destinations": ["345", "340", "342", "346", "3998", "350", "351", "1678", "210", "231", "502", "469", "552", "495", "628", "630", "737", "636", "608", "344", "609", "641", "687", "421", "5595", "651", "644", "446", "657", "666", "460", "663", "665", "664", "728", "599", "596", "1525", "1538", "494", "353", "490", "302", "1367", "304", "535", "585", "523", "4166", "3986", "691", "645", "355", "601", "669", "671", "3956", "517", "491", "478", "582", "600", "4198", "347", "699", "537", "1243", "603", "548", "458", "733", "711", "8414", "680", "580", "591", "1218", "1216", "488", "4029", "1555", "1225", "1054", "1229", "1386", "465", "514", "534", "521", "638", "4053", "1665", "492", "508", "1056", "16"]
}, {
    "City": "Aarhus",
    "DBTZ": "E",
    "Name": "Aarhus",
    "Country": "Denmark",
    "IATA/FAA": "AAR",
    "Longitude": "10.619008",
    "ICAO": "EKAH",
    "Airport ID": "607",
    "Latitude": "56.300017",
    "Timezone": "82",
    "DST": "1",
    "destinations": ["628", "738", "687", "644", "1230", "3998", "548", "609"]
}, {
    "City": "Kerry",
    "DBTZ": "E",
    "Name": "Kerry",
    "Country": "Ireland",
    "IATA/FAA": "KIR",
    "Longitude": "-9.523783",
    "ICAO": "EIKY",
    "Airport ID": "601",
    "Latitude": "52.180878",
    "Timezone": "112",
    "DST": "0",
    "destinations": ["599", "1212", "1626", "355", "492", "548"]
}, {
    "City": "Connaught",
    "DBTZ": "E",
    "Name": "Ireland West Knock",
    "Country": "Ireland",
    "IATA/FAA": "NOC",
    "Longitude": "-8.818492",
    "ICAO": "EIKN",
    "Airport ID": "600",
    "Latitude": "53.910297",
    "Timezone": "665",
    "DST": "0",
    "destinations": ["469", "478", "502", "1055", "1230", "1212", "490", "585", "523", "1626", "3956", "491", "492", "548", "1056"]
}, {
    "City": "Shannon",
    "DBTZ": "E",
    "Name": "Shannon",
    "Country": "Ireland",
    "IATA/FAA": "SNN",
    "Longitude": "-8.924817",
    "ICAO": "EINN",
    "Airport ID": "603",
    "Latitude": "52.701978",
    "Timezone": "46",
    "DST": "0",
    "destinations": ["1230", "469", "3448", "490", "535", "1626", "3797", "507", "478", "1055", "1212", "1367", "3986", "1051", "669", "502", "491", "1354", "1268", "3998", "548", "337", "1056", "8414", "680", "3494"]
}, {
    "City": "Almeria",
    "DBTZ": "E",
    "Name": "Almeria",
    "Country": "Spain",
    "IATA/FAA": "LEI",
    "Longitude": "-2.370097",
    "ICAO": "LEAM",
    "Airport ID": "1213",
    "Latitude": "36.843936",
    "Timezone": "70",
    "DST": "1",
    "destinations": ["3998", "304", "599", "523", "4198", "548", "302", "580", "1218", "1229", "1058", "478", "502", "1253", "469"]
}, {
    "City": "Rijeka",
    "DBTZ": "E",
    "Name": "Rijeka",
    "Country": "Croatia",
    "IATA/FAA": "RJK",
    "Longitude": "14.570267",
    "ICAO": "LDRI",
    "Airport ID": "1205",
    "Latitude": "45.216889",
    "Timezone": "278",
    "DST": "1",
    "destinations": ["344", "342", "350", "699", "507", "1206", "1208"]
}, {
    "City": "Split",
    "DBTZ": "E",
    "Name": "Split",
    "Country": "Croatia",
    "IATA/FAA": "SPU",
    "Longitude": "16.297964",
    "ICAO": "LDSP",
    "Airport ID": "1206",
    "Latitude": "43.538944",
    "Timezone": "79",
    "DST": "1",
    "destinations": ["344", "373", "345", "352", "342", "350", "351", "347", "340", "737", "609", "421", "502", "644", "1399", "591", "679", "346", "478", "1678", "1587", "1613", "3941", "1739", "1382", "1200", "1555", "507", "1335", "1202", "1208", "415", "687", "4053", "1665", "469"]
}, {
    "City": "Copenhagen",
    "DBTZ": "E",
    "Name": "Kastrup",
    "Country": "Denmark",
    "IATA/FAA": "CPH",
    "Longitude": "12.655972",
    "ICAO": "EKCH",
    "Airport ID": "609",
    "Latitude": "55.617917",
    "Timezone": "17",
    "DST": "1",
    "destinations": ["619", "1486", "507", "345", "3998", "351", "1613", "580", "644", "3959", "679", "193", "1382", "1551", "1074", "421", "1555", "3953", "340", "1665", "3364", "618", "628", "1230", "1212", "737", "3941", "1218", "636", "1191", "1489", "1474", "1509", "599", "535", "1626", "3533", "3797", "669", "612", "3484", "502", "1638", "1054", "1229", "1606", "1354", "1386", "1587", "1562", "1075", "1206", "337", "1590", "665", "1208", "2188", "16", "9", "302", "585", "1563", "1646", "1569", "1739", "629", "346", "1678", "1128", "415", "1688", "4317", "2207", "2241", "625", "607", "532", "630", "469", "1280", "3885", "608", "1538", "353", "3494", "668", "687", "8266", "352", "342", "645", "3714", "1701", "638", "517", "2948", "1529", "742", "478", "1524", "521", "2279", "3830", "1657", "3958", "674", "1742", "3406", "3469", "3316", "350", "666", "2985", "460", "664", "680", "490", "4053", "548", "651", "1706"]
}, {
    "City": "Dubrovnik",
    "DBTZ": "E",
    "Name": "Dubrovnik",
    "Country": "Croatia",
    "IATA/FAA": "DBV",
    "Longitude": "18.268244",
    "ICAO": "LDDU",
    "Airport ID": "1200",
    "Latitude": "42.561353",
    "Timezone": "527",
    "DST": "1",
    "destinations": ["344", "345", "352", "342", "350", "351", "421", "502", "469", "737", "636", "644", "665", "664", "599", "302", "1399", "1218", "679", "629", "340", "346", "465", "535", "523", "517", "478", "521", "1678", "1613", "580", "3941", "1382", "1555", "1202", "1205", "1551", "1208", "1386", "1665", "548"]
}, {
    "City": "Pula",
    "DBTZ": "E",
    "Name": "Pula",
    "Country": "Croatia",
    "IATA/FAA": "PUY",
    "Longitude": "13.922192",
    "ICAO": "LDPL",
    "Airport ID": "1203",
    "Latitude": "44.893533",
    "Timezone": "274",
    "DST": "1",
    "destinations": ["344", "644", "599", "1367", "304", "355", "657", "548", "478", "340", "1209", "1208", "4029", "737"]
}, {
    "City": "Osijek",
    "DBTZ": "E",
    "Name": "Osijek",
    "Country": "Croatia",
    "IATA/FAA": "OSI",
    "Longitude": "18.810156",
    "ICAO": "LDOS",
    "Airport ID": "1202",
    "Latitude": "45.462667",
    "Timezone": "290",
    "DST": "1",
    "destinations": ["548", "1200", "1206", "1208"]
}, {
    "City": "Anahim Lake",
    "DBTZ": "A",
    "Name": "Anahim Lake Airport",
    "Country": "Canada",
    "IATA/FAA": "YAA",
    "Longitude": "-125.303",
    "ICAO": "CAJ4",
    "Airport ID": "5457",
    "Latitude": "52.4525",
    "Timezone": "3635",
    "DST": "-8",
    "destinations": ["156"]
}, {
    "City": "Luderitz",
    "DBTZ": "S",
    "Name": "Luderitz Airport",
    "Country": "Namibia",
    "IATA/FAA": "LUD",
    "Longitude": "15.2429",
    "ICAO": "FYLZ",
    "Airport ID": "5641",
    "Latitude": "-26.6874",
    "Timezone": "457",
    "DST": "1",
    "destinations": ["5643"]
}, {
    "City": "Bilbao",
    "DBTZ": "E",
    "Name": "Bilbao",
    "Country": "Spain",
    "IATA/FAA": "BIO",
    "Longitude": "-2.910608",
    "ICAO": "LEBB",
    "Airport ID": "1216",
    "Latitude": "43.301097",
    "Timezone": "138",
    "DST": "1",
    "destinations": ["345", "350", "3998", "1382", "1555", "644", "599", "302", "1055", "1230", "1212", "580", "1218", "1051", "1225", "1220", "507", "1054", "1229", "1524", "1386", "1243", "1253", "1057", "351", "1250", "1246", "340", "346", "1638", "1701", "1665", "478", "548", "1551"]
}, {
    "City": "Oranjemund",
    "DBTZ": "S",
    "Name": "Oranjemund Airport",
    "Country": "Namibia",
    "IATA/FAA": "OMD",
    "Longitude": "16.4467",
    "ICAO": "FYOG",
    "Airport ID": "5643",
    "Latitude": "-28.5847",
    "Timezone": "14",
    "DST": "1",
    "destinations": ["4105"]
}, {
    "City": "Ondangwa",
    "DBTZ": "S",
    "Name": "Ondangwa Airport",
    "Country": "Namibia",
    "IATA/FAA": "OND",
    "Longitude": "15.9526",
    "ICAO": "FYOA",
    "Airport ID": "5642",
    "Latitude": "-17.8782",
    "Timezone": "3599",
    "DST": "1",
    "destinations": ["5645"]
}, {
    "City": "Windhoek",
    "DBTZ": "S",
    "Name": "Eros Airport",
    "Country": "Namibia",
    "IATA/FAA": "ERS",
    "Longitude": "17.0804",
    "ICAO": "FYWE",
    "Airport ID": "5645",
    "Latitude": "-22.6122",
    "Timezone": "5575",
    "DST": "1",
    "destinations": ["6779", "7634", "5642"]
}, {
    "City": "Londonderry",
    "DBTZ": "E",
    "Name": "City of Derry",
    "Country": "United Kingdom",
    "IATA/FAA": "LDY",
    "Longitude": "-7.161111",
    "ICAO": "EGAE",
    "Airport ID": "468",
    "Latitude": "55.042778",
    "Timezone": "22",
    "DST": "0",
    "destinations": ["469", "1626", "491", "537", "548"]
}, {
    "City": "Mare",
    "DBTZ": "U",
    "Name": "Mare",
    "Country": "New Caledonia",
    "IATA/FAA": "MEE",
    "Longitude": "168.037508",
    "ICAO": "NWWR",
    "Airport ID": "2002",
    "Latitude": "-21.481678",
    "Timezone": "141",
    "DST": "11",
    "destinations": ["2001"]
}, {
    "City": "Bergen",
    "DBTZ": "E",
    "Name": "Flesland",
    "Country": "Norway",
    "IATA/FAA": "BGO",
    "Longitude": "5.218142",
    "ICAO": "ENBR",
    "Airport ID": "636",
    "Latitude": "60.293386",
    "Timezone": "170",
    "DST": "1",
    "destinations": ["342", "507", "580", "608", "3953", "1230", "1212", "737", "1688", "1218", "1474", "609", "1200", "535", "1555", "3797", "16", "669", "502", "1054", "1354", "1386", "644", "3998", "1587", "666", "337", "665", "664", "340", "625", "532", "630", "478", "668", "671", "3959", "634", "635", "4389", "643", "645", "4345", "638", "647", "651", "659", "5586", "663"]
}, {
    "City": "Esmeraldas",
    "DBTZ": "U",
    "Name": "General Rivadeneira Airport",
    "Country": "Ecuador",
    "IATA/FAA": "ESM",
    "Longitude": "-79.6266",
    "ICAO": "SETN",
    "Airport ID": "6047",
    "Latitude": "0.978519",
    "Timezone": "32",
    "DST": "-5",
    "destinations": ["2715", "2673", "2688"]
}, {
    "City": "Leh",
    "DBTZ": "N",
    "Name": "Leh",
    "Country": "India",
    "IATA/FAA": "IXL",
    "Longitude": "77.546514",
    "ICAO": "VILH",
    "Airport ID": "3104",
    "Latitude": "34.135872",
    "Timezone": "10682",
    "DST": "5.5",
    "destinations": ["3093", "3100", "3112"]
}, {
    "City": "Lucknow",
    "DBTZ": "N",
    "Name": "Lucknow",
    "Country": "India",
    "IATA/FAA": "LKO",
    "Longitude": "80.889339",
    "ICAO": "VILK",
    "Airport ID": "3105",
    "Latitude": "26.760594",
    "Timezone": "410",
    "DST": "5.5",
    "destinations": ["3131", "2997", "3093", "3061", "3092", "2188", "2072", "2082", "2194"]
}, {
    "City": "Jammu",
    "DBTZ": "N",
    "Name": "Jammu",
    "Country": "India",
    "IATA/FAA": "IXJ",
    "Longitude": "74.837389",
    "ICAO": "VIJU",
    "Airport ID": "3100",
    "Latitude": "32.689142",
    "Timezone": "1029",
    "DST": "5.5",
    "destinations": ["3093", "3112", "3104", "2997"]
}, {
    "City": "Kanpur",
    "DBTZ": "N",
    "Name": "Kanpur",
    "Country": "India",
    "IATA/FAA": "KNU",
    "Longitude": "80.364864",
    "ICAO": "VIKA",
    "Airport ID": "3101",
    "Latitude": "26.441444",
    "Timezone": "411",
    "DST": "5.5",
    "destinations": ["3093"]
}, {
    "City": "Ludhiaha",
    "DBTZ": "N",
    "Name": "Ludhiana",
    "Country": "India",
    "IATA/FAA": "LUH",
    "Longitude": "75.952592",
    "ICAO": "VILD",
    "Airport ID": "3103",
    "Latitude": "30.854681",
    "Timezone": "834",
    "DST": "5.5",
    "destinations": ["3093"]
}, {
    "City": "Bahar Dar",
    "DBTZ": "U",
    "Name": "Bahir Dar",
    "Country": "Ethiopia",
    "IATA/FAA": "BJR",
    "Longitude": "37.321644",
    "ICAO": "HABD",
    "Airport ID": "1111",
    "Latitude": "11.608075",
    "Timezone": "5976",
    "DST": "3",
    "destinations": ["1107", "1117"]
}, {
    "City": "Axum",
    "DBTZ": "U",
    "Name": "Axum",
    "Country": "Ethiopia",
    "IATA/FAA": "AXU",
    "Longitude": "38.772833",
    "ICAO": "HAAX",
    "Airport ID": "1110",
    "Latitude": "14.14675",
    "Timezone": "6915",
    "DST": "3",
    "destinations": ["1107", "1117"]
}, {
    "City": "Gambella",
    "DBTZ": "U",
    "Name": "Gambella",
    "Country": "Ethiopia",
    "IATA/FAA": "GMB",
    "Longitude": "34.563131",
    "ICAO": "HAGM",
    "Airport ID": "1113",
    "Latitude": "8.128764",
    "Timezone": "1771",
    "DST": "3",
    "destinations": ["1107", "1119"]
}, {
    "City": "Dire Dawa",
    "DBTZ": "U",
    "Name": "Dire Dawa Intl",
    "Country": "Ethiopia",
    "IATA/FAA": "DIR",
    "Longitude": "41.854203",
    "ICAO": "HADR",
    "Airport ID": "1112",
    "Latitude": "9.6247",
    "Timezone": "3829",
    "DST": "3",
    "destinations": ["1107", "4119", "7417"]
}, {
    "City": "Port Moresby",
    "DBTZ": "U",
    "Name": "Port Moresby Jacksons Intl",
    "Country": "Papua New Guinea",
    "IATA/FAA": "POM",
    "Longitude": "147.22005",
    "ICAO": "AYPY",
    "Airport ID": "5",
    "Latitude": "-9.443383",
    "Timezone": "146",
    "DST": "10",
    "destinations": ["3320", "5421", "1", "5422", "3", "5424", "4", "5979", "5431", "5423", "5435", "5434", "5984", "5433", "5425", "5419", "4206", "5420", "3322", "3940", "4074", "3077", "5428", "2", "5430", "5429", "2397", "1960", "2279", "3316", "3361", "5436", "5437", "6"]
}, {
    "City": "Gondar",
    "DBTZ": "U",
    "Name": "Gondar",
    "Country": "Ethiopia",
    "IATA/FAA": "GDQ",
    "Longitude": "37.434047",
    "ICAO": "HAGN",
    "Airport ID": "1114",
    "Latitude": "12.5199",
    "Timezone": "6541",
    "DST": "3",
    "destinations": ["1107", "1117"]
}, {
    "City": "Lalibella",
    "DBTZ": "U",
    "Name": "Lalibella",
    "Country": "Ethiopia",
    "IATA/FAA": "LLI",
    "Longitude": "38.979969",
    "ICAO": "HALL",
    "Airport ID": "1117",
    "Latitude": "11.975014",
    "Timezone": "6423",
    "DST": "3",
    "destinations": ["1107", "1110", "1114"]
}, {
    "City": "Jimma",
    "DBTZ": "U",
    "Name": "Jimma",
    "Country": "Ethiopia",
    "IATA/FAA": "JIM",
    "Longitude": "36.816639",
    "ICAO": "HAJM",
    "Airport ID": "1116",
    "Latitude": "7.666094",
    "Timezone": "5587",
    "DST": "3",
    "destinations": ["1107", "1109"]
}, {
    "City": "Asosa",
    "DBTZ": "U",
    "Name": "Asosa",
    "Country": "Ethiopia",
    "IATA/FAA": "ASO",
    "Longitude": "34.586253",
    "ICAO": "HASO",
    "Airport ID": "1119",
    "Latitude": "10.01855",
    "Timezone": "5120",
    "DST": "3",
    "destinations": ["1107"]
}, {
    "City": "Makale",
    "DBTZ": "U",
    "Name": "Makale",
    "Country": "Ethiopia",
    "IATA/FAA": "MQX",
    "Longitude": "39.533464",
    "ICAO": "HAMK",
    "Airport ID": "1118",
    "Latitude": "13.467367",
    "Timezone": "7406",
    "DST": "3",
    "destinations": ["1107", "5601"]
}, {
    "City": "Belfast",
    "DBTZ": "E",
    "Name": "Belfast City",
    "Country": "United Kingdom",
    "IATA/FAA": "BHD",
    "Longitude": "-5.8725",
    "ICAO": "EGAC",
    "Airport ID": "467",
    "Latitude": "54.618056",
    "Timezone": "15",
    "DST": "0",
    "destinations": ["507", "532", "469", "488", "535", "523", "552", "534", "533", "517", "478", "521", "495", "1230", "1626", "502", "3998", "520"]
}, {
    "City": "Nea Anghialos",
    "DBTZ": "E",
    "Name": "Nea Anchialos",
    "Country": "Greece",
    "IATA/FAA": "VOL",
    "Longitude": "22.794339",
    "ICAO": "LGBL",
    "Airport ID": "1448",
    "Latitude": "39.219619",
    "Timezone": "83",
    "DST": "2",
    "destinations": ["1613", "304", "355", "580"]
}, {
    "City": "Sihanoukville",
    "DBTZ": "N",
    "Name": "Sihanoukville",
    "Country": "Cambodia",
    "IATA/FAA": "KOS",
    "Longitude": "103.636828",
    "ICAO": "VDSV",
    "Airport ID": "4093",
    "Latitude": "10.579686",
    "Timezone": "33",
    "DST": "7",
    "destinations": ["3035"]
}, {
    "City": "Santarem",
    "DBTZ": "S",
    "Name": "Santarem",
    "Country": "Brazil",
    "IATA/FAA": "STM",
    "Longitude": "-54.792789",
    "ICAO": "SBSN",
    "Airport ID": "4092",
    "Latitude": "-2.422431",
    "Timezone": "198",
    "DST": "-4",
    "destinations": ["2566", "2526", "7534", "2551", "2622", "8259"]
}, {
    "City": "Funchal",
    "DBTZ": "E",
    "Name": "Madeira",
    "Country": "Portugal",
    "IATA/FAA": "FNC",
    "Longitude": "-16.774453",
    "ICAO": "LPMA",
    "Airport ID": "4091",
    "Latitude": "32.697889",
    "Timezone": "192",
    "DST": "0",
    "destinations": ["345", "348", "346", "351", "1613", "1678", "340", "342", "350", "302", "580", "629", "523", "517", "478", "1054", "1633", "1587", "1638", "1636", "353", "469", "502", "534", "2851", "490", "4053", "344", "352", "1386"]
}, {
    "City": "Barnstable",
    "DBTZ": "A",
    "Name": "Barnstable Muni Boardman Polando Fld",
    "Country": "United States",
    "IATA/FAA": "HYA",
    "Longitude": "-70.280356",
    "ICAO": "KHYA",
    "Airport ID": "4278",
    "Latitude": "41.669336",
    "Timezone": "55",
    "DST": "-5",
    "destinations": ["3517", "3448"]
}, {
    "City": "Lhasa",
    "DBTZ": "U",
    "Name": "Lhasa-Gonggar",
    "Country": "China",
    "IATA/FAA": "LXA",
    "Longitude": "90.911944",
    "ICAO": "ZULS",
    "Airport ID": "4097",
    "Latitude": "29.297778",
    "Timezone": "13136",
    "DST": "8",
    "destinations": ["3393", "3395", "9311", "3379", "6366", "3125", "3364", "6375", "3382", "6396", "4085", "6431", "6402", "6400", "7932"]
}, {
    "City": "Rerrenabaque",
    "DBTZ": "U",
    "Name": "Rurrenabaque Airport",
    "Country": "Bolivia",
    "IATA/FAA": "RBQ",
    "Longitude": "-67.498056",
    "ICAO": "SLRQ",
    "Airport ID": "7317",
    "Latitude": "-14.4275",
    "Timezone": "898",
    "DST": "-4",
    "destinations": ["2762", "2769"]
}, {
    "City": "NAPIER",
    "DBTZ": "Z",
    "Name": "Napier",
    "Country": "New Zealand",
    "IATA/FAA": "NPE",
    "Longitude": "176.87",
    "ICAO": "NZNR",
    "Airport ID": "4095",
    "Latitude": "-39.465833",
    "Timezone": "6",
    "DST": "12",
    "destinations": ["2006", "2009", "2042"]
}, {
    "City": "Eskissehir",
    "DBTZ": "E",
    "Name": "Anadolu Airport",
    "Country": "Turkey",
    "IATA/FAA": "AOE",
    "Longitude": "30.519378",
    "ICAO": "LTBY",
    "Airport ID": "6780",
    "Latitude": "39.809858",
    "Timezone": "2588",
    "DST": "2",
    "destinations": ["302"]
}, {
    "City": "Eugene",
    "DBTZ": "A",
    "Name": "Mahlon Sweet Fld",
    "Country": "United States",
    "IATA/FAA": "EUG",
    "Longitude": "-123.211972",
    "ICAO": "KEUG",
    "Airport ID": "4099",
    "Latitude": "44.124583",
    "Timezone": "374",
    "DST": "-8",
    "destinations": ["3484", "3720", "3577", "3536", "3751", "6505", "3877", "3453", "3469"]
}, {
    "City": "Noumea",
    "DBTZ": "U",
    "Name": "La Tontouta",
    "Country": "New Caledonia",
    "IATA/FAA": "NOU",
    "Longitude": "166.212972",
    "ICAO": "NWWW",
    "Airport ID": "2005",
    "Latitude": "-22.014553",
    "Timezone": "52",
    "DST": "11",
    "destinations": ["1997", "2006", "3320", "3361", "3992", "1960", "2279", "4075", "1968"]
}, {
    "City": "Perpignan",
    "DBTZ": "E",
    "Name": "Rivesaltes",
    "Country": "France",
    "IATA/FAA": "PGF",
    "Longitude": "2.870667",
    "ICAO": "LFMP",
    "Airport ID": "1356",
    "Latitude": "42.740442",
    "Timezone": "144",
    "DST": "1",
    "destinations": ["1386", "599", "304", "548", "1418"]
}, {
    "City": "Eau Claire",
    "DBTZ": "A",
    "Name": "Chippewa Valley Regional Airport",
    "Country": "United States",
    "IATA/FAA": "EAU",
    "Longitude": "-91.4843",
    "ICAO": "KEAU",
    "Airport ID": "5735",
    "Latitude": "44.8658",
    "Timezone": "913",
    "DST": "-6",
    "destinations": ["3830"]
}, {
    "City": "Du Bois",
    "DBTZ": "A",
    "Name": "DuBois Regional Airport",
    "Country": "United States",
    "IATA/FAA": "DUJ",
    "Longitude": "-78.8987",
    "ICAO": "KDUJ",
    "Airport ID": "5734",
    "Latitude": "41.1783",
    "Timezone": "1817",
    "DST": "-5",
    "destinations": ["3486", "4058"]
}, {
    "City": "New Bedford",
    "DBTZ": "A",
    "Name": "New Bedford Regional Airport",
    "Country": "United States",
    "IATA/FAA": "EWB",
    "Longitude": "-70.9569",
    "ICAO": "KEWB",
    "Airport ID": "5737",
    "Latitude": "41.6761",
    "Timezone": "80",
    "DST": "-5",
    "destinations": ["3517", "3589", "4265"]
}, {
    "City": "Elko",
    "DBTZ": "A",
    "Name": "Elko Regional Airport",
    "Country": "United States",
    "IATA/FAA": "EKO",
    "Longitude": "-115.792",
    "ICAO": "KEKO",
    "Airport ID": "5736",
    "Latitude": "40.8249",
    "Timezone": "5140",
    "DST": "-8",
    "destinations": ["3536"]
}, {
    "City": "Port Angeles",
    "DBTZ": "A",
    "Name": "William R Fairchild International Airport",
    "Country": "United States",
    "IATA/FAA": "CLM",
    "Longitude": "-123.5",
    "ICAO": "KCLM",
    "Airport ID": "5731",
    "Latitude": "48.1202",
    "Timezone": "291",
    "DST": "-8",
    "destinations": ["3726"]
}, {
    "City": "Clarksburg",
    "DBTZ": "A",
    "Name": "Harrison Marion Regional Airport",
    "Country": "United States",
    "IATA/FAA": "CKB",
    "Longitude": "-80.2281",
    "ICAO": "KCKB",
    "Airport ID": "5730",
    "Latitude": "39.2966",
    "Timezone": "1217",
    "DST": "-5",
    "destinations": ["4167", "3714", "4284"]
}, {
    "City": "Dodge City",
    "DBTZ": "A",
    "Name": "Dodge City Regional Airport",
    "Country": "United States",
    "IATA/FAA": "DDC",
    "Longitude": "-99.9656",
    "ICAO": "KDDC",
    "Airport ID": "5733",
    "Latitude": "37.7634",
    "Timezone": "2594",
    "DST": "-6",
    "destinations": ["3751", "4341"]
}, {
    "City": "Cardiff",
    "DBTZ": "E",
    "Name": "Cardiff",
    "Country": "United Kingdom",
    "IATA/FAA": "CWL",
    "Longitude": "-3.343333",
    "ICAO": "EGFF",
    "Airport ID": "488",
    "Latitude": "51.396667",
    "Timezone": "220",
    "DST": "0",
    "destinations": ["345", "535", "534", "499", "1386", "580", "467", "599", "1230", "1212", "1218", "1606", "521", "1715", "1452", "1197", "3998", "1472", "1056"]
}, {
    "City": "Jackson",
    "DBTZ": "A",
    "Name": "Mc Kellar Sipes Rgnl",
    "Country": "United States",
    "IATA/FAA": "MKL",
    "Longitude": "-88.915611",
    "ICAO": "KMKL",
    "Airport ID": "3704",
    "Latitude": "35.599889",
    "Timezone": "434",
    "DST": "-6",
    "destinations": ["3690", "3473"]
}, {
    "City": "Vaasa",
    "DBTZ": "E",
    "Name": "Vaasa",
    "Country": "Finland",
    "IATA/FAA": "VAA",
    "Longitude": "21.762175",
    "ICAO": "EFVA",
    "Airport ID": "462",
    "Latitude": "63.05065",
    "Timezone": "19",
    "DST": "2",
    "destinations": ["421", "737"]
}, {
    "City": "Jixi",
    "DBTZ": "N",
    "Name": "Jixi Airport",
    "Country": "China",
    "IATA/FAA": "JXA",
    "Longitude": "130.99667",
    "ICAO": "ZYJX",
    "Airport ID": "8417",
    "Latitude": "45.30611",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3400", "4144", "3390", "3364"]
}, {
    "City": "Fayetteville",
    "DBTZ": "A",
    "Name": "Fayetteville Regional Grannis Field",
    "Country": "United States",
    "IATA/FAA": "FAY",
    "Longitude": "-78.8803",
    "ICAO": "KFAY",
    "Airport ID": "5738",
    "Latitude": "34.9912",
    "Timezone": "189",
    "DST": "-5",
    "destinations": ["3876", "3520", "3682", "3714"]
}, {
    "City": "Newquai",
    "DBTZ": "E",
    "Name": "St Mawgan",
    "Country": "United Kingdom",
    "IATA/FAA": "NQY",
    "Longitude": "-4.995408",
    "ICAO": "EGDG",
    "Airport ID": "481",
    "Latitude": "50.440558",
    "Timezone": "390",
    "DST": "0",
    "destinations": ["345", "502", "478", "552", "4347"]
}, {
    "City": "Warsaw",
    "DBTZ": "E",
    "Name": "Warsaw Modlin",
    "Country": "Poland",
    "IATA/FAA": "WMI",
    "Longitude": "20.651667",
    "ICAO": "EPMO",
    "Airport ID": "8414",
    "Latitude": "52.451111",
    "Timezone": "341",
    "DST": "1",
    "destinations": ["1212", "1218", "1525", "1538", "490", "1367", "1474", "1553", "304", "599", "585", "523", "668", "491", "478", "1353", "699", "537", "3998", "1562", "657", "1486", "603", "548", "1515", "680"]
}, {
    "City": "Lewisburg",
    "DBTZ": "U",
    "Name": "Greenbrier Valley Airport",
    "Country": "United States",
    "IATA/FAA": "LWB",
    "Longitude": "-80.399444",
    "ICAO": "KLWB",
    "Airport ID": "6958",
    "Latitude": "37.858333",
    "Timezone": "2302",
    "DST": "-5",
    "destinations": ["3682", "3714"]
}, {
    "City": "Greenville",
    "DBTZ": "A",
    "Name": "Pitt-Greenville Airport",
    "Country": "United States",
    "IATA/FAA": "PGV",
    "Longitude": "-77.385278",
    "ICAO": "KPGV",
    "Airport ID": "6959",
    "Latitude": "35.635278",
    "Timezone": "27",
    "DST": "-5",
    "destinations": ["3876"]
}, {
    "City": "Los Alamos",
    "DBTZ": "A",
    "Name": "Los Alamos Airport",
    "Country": "United States",
    "IATA/FAA": "LAM",
    "Longitude": "-106.2694153",
    "ICAO": "KLAM",
    "Airport ID": "7584",
    "Latitude": "35.8798019",
    "Timezone": "7171",
    "DST": "-7",
    "destinations": ["4019"]
}, {
    "City": "Belize",
    "DBTZ": "N",
    "Name": "Belize City Municipal Airport",
    "Country": "Belize",
    "IATA/FAA": "TZA",
    "Longitude": "-88.298",
    "ICAO": "",
    "Airport ID": "6463",
    "Latitude": "17.5344",
    "Timezone": "15",
    "DST": "-6",
    "destinations": ["8227", "1957", "5454", "7287", "4279"]
}, {
    "City": "Florence",
    "DBTZ": "E",
    "Name": "Firenze",
    "Country": "Italy",
    "IATA/FAA": "FLR",
    "Longitude": "11.2051",
    "ICAO": "LIRQ",
    "Airport ID": "1563",
    "Latitude": "43.809953",
    "Timezone": "142",
    "DST": "1",
    "destinations": ["1335", "345", "350", "1613", "1382", "503", "580", "1555", "1665", "469", "346", "1678", "1218", "1501", "1519", "609", "1509", "502", "1229", "1386", "1512", "351", "340", "302", "1264", "1418"]
}, {
    "City": "Tambow",
    "DBTZ": "N",
    "Name": "Tambow",
    "Country": "Russia",
    "IATA/FAA": "TBW",
    "Longitude": "41.48",
    "ICAO": "UUOT",
    "Airport ID": "6466",
    "Latitude": "52.81",
    "Timezone": "126",
    "DST": "4",
    "destinations": ["2988"]
}, {
    "City": "Vilankulu",
    "DBTZ": "U",
    "Name": "Vilankulo",
    "Country": "Mozambique",
    "IATA/FAA": "VNX",
    "Longitude": "35.313297",
    "ICAO": "FQVL",
    "Airport ID": "990",
    "Latitude": "-22.018431",
    "Timezone": "46",
    "DST": "2",
    "destinations": ["813", "976", "979"]
}, {
    "City": "Chumphon",
    "DBTZ": "U",
    "Name": "Chumphon",
    "Country": "Thailand",
    "IATA/FAA": "CJM",
    "Longitude": "99.361706",
    "ICAO": "VTSE",
    "Airport ID": "4300",
    "Latitude": "10.7112",
    "Timezone": "18",
    "DST": "7",
    "destinations": ["3157"]
}, {
    "City": "Taraken",
    "DBTZ": "N",
    "Name": "Juwata",
    "Country": "Indonesia",
    "IATA/FAA": "TRK",
    "Longitude": "117.565569",
    "ICAO": "WALR",
    "Airport ID": "3920",
    "Latitude": "3.326694",
    "Timezone": "20",
    "DST": "8",
    "destinations": ["3919", "3275", "3928", "3240", "3271"]
}, {
    "City": "Kuantan",
    "DBTZ": "N",
    "Name": "Kuantan",
    "Country": "Malaysia",
    "IATA/FAA": "KUA",
    "Longitude": "103.209056",
    "ICAO": "WMKD",
    "Airport ID": "3299",
    "Latitude": "3.775389",
    "Timezone": "58",
    "DST": "8",
    "destinations": ["3308", "3316", "3304"]
}, {
    "City": "Waingapu",
    "DBTZ": "N",
    "Name": "Mau Hau",
    "Country": "Indonesia",
    "IATA/FAA": "WGP",
    "Longitude": "120.302006",
    "ICAO": "WADW",
    "Airport ID": "3927",
    "Latitude": "-9.669217",
    "Timezone": "33",
    "DST": "8",
    "destinations": ["3940"]
}, {
    "City": "Bima",
    "DBTZ": "N",
    "Name": "Muhammad Salahuddin",
    "Country": "Indonesia",
    "IATA/FAA": "BMU",
    "Longitude": "118.687322",
    "ICAO": "WADB",
    "Airport ID": "3924",
    "Latitude": "-8.539647",
    "Timezone": "3",
    "DST": "8",
    "destinations": ["3940", "8401"]
}, {
    "City": "Surabaya",
    "DBTZ": "N",
    "Name": "Juanda",
    "Country": "Indonesia",
    "IATA/FAA": "SUB",
    "Longitude": "112.786858",
    "ICAO": "WARR",
    "Airport ID": "3928",
    "Latitude": "-7.379831",
    "Timezone": "9",
    "DST": "7",
    "destinations": ["3304", "3272", "2276", "3316", "3077", "3256", "3908", "3896", "3919", "3275", "3940", "2072", "8401", "3901", "3240", "3910", "3903", "3898", "3916", "3250", "3911", "3920", "3303", "3157", "3308"]
}, {
    "City": "Solo City",
    "DBTZ": "N",
    "Name": "Adi Sumarmo Wiryokusumo",
    "Country": "Indonesia",
    "IATA/FAA": "SOC",
    "Longitude": "110.756892",
    "ICAO": "WARQ",
    "Airport ID": "3929",
    "Latitude": "-7.516089",
    "Timezone": "421",
    "DST": "7",
    "destinations": ["3304", "3275", "3316", "3910"]
}, {
    "City": "Shishmaref",
    "DBTZ": "A",
    "Name": "Shishmaref Airport",
    "Country": "United States",
    "IATA/FAA": "SHH",
    "Longitude": "-166.089",
    "ICAO": "PASH",
    "Airport ID": "6723",
    "Latitude": "66.2496",
    "Timezone": "12",
    "DST": "-9",
    "destinations": ["3615", "7187"]
}, {
    "City": "Ruby",
    "DBTZ": "A",
    "Name": "Ruby Airport",
    "Country": "United States",
    "IATA/FAA": "RBY",
    "Longitude": "-155.47",
    "ICAO": "PARY",
    "Airport ID": "6722",
    "Latitude": "64.7272",
    "Timezone": "653",
    "DST": "-9",
    "destinations": ["3832", "3764", "7113"]
}, {
    "City": "Point Hope",
    "DBTZ": "A",
    "Name": "Point Hope Airport",
    "Country": "United States",
    "IATA/FAA": "PHO",
    "Longitude": "-166.799",
    "ICAO": "PPHO",
    "Airport ID": "6721",
    "Latitude": "68.3488",
    "Timezone": "12",
    "DST": "-9",
    "destinations": ["3693", "3414", "3413"]
}, {
    "City": "Mekoryuk",
    "DBTZ": "A",
    "Name": "Mekoryuk Airport",
    "Country": "United States",
    "IATA/FAA": "MYU",
    "Longitude": "-166.271",
    "ICAO": "PAMY",
    "Airport ID": "6720",
    "Latitude": "60.3714",
    "Timezone": "48",
    "DST": "-9",
    "destinations": ["3599"]
}, {
    "City": "Puvirnituq",
    "DBTZ": "A",
    "Name": "Puvirnituq Airport",
    "Country": "Canada",
    "IATA/FAA": "YPX",
    "Longitude": "-77.2869",
    "ICAO": "CYPX",
    "Airport ID": "6727",
    "Latitude": "60.0506",
    "Timezone": "74",
    "DST": "-5",
    "destinations": ["5506", "62", "5520", "154"]
}, {
    "City": "Noatak",
    "DBTZ": "A",
    "Name": "Noatak Airport",
    "Country": "United States",
    "IATA/FAA": "WTK",
    "Longitude": "-162.975",
    "ICAO": "PAWN",
    "Airport ID": "6725",
    "Latitude": "67.5661",
    "Timezone": "88",
    "DST": "-9",
    "destinations": ["6719", "3693"]
}, {
    "City": "Savoonga",
    "DBTZ": "A",
    "Name": "Savoonga Airport",
    "Country": "United States",
    "IATA/FAA": "SVA",
    "Longitude": "-170.493",
    "ICAO": "PASA",
    "Airport ID": "6724",
    "Latitude": "63.6864",
    "Timezone": "53",
    "DST": "-9",
    "destinations": ["6715", "3615"]
}, {
    "City": "Arctic Village",
    "DBTZ": "A",
    "Name": "Arctic Village Airport",
    "Country": "United States",
    "IATA/FAA": "ARC",
    "Longitude": "-145.579",
    "ICAO": "PARC",
    "Airport ID": "6729",
    "Latitude": "68.1147",
    "Timezone": "2092",
    "DST": "-9",
    "destinations": ["7114"]
}, {
    "City": "Tasiujaq",
    "DBTZ": "A",
    "Name": "Tasiujaq Airport",
    "Country": "Canada",
    "IATA/FAA": "YTQ",
    "Longitude": "-69.9558",
    "ICAO": "CYTQ",
    "Airport ID": "6728",
    "Latitude": "58.6678",
    "Timezone": "122",
    "DST": "-5",
    "destinations": ["5508", "154"]
}, {
    "City": "Christiansted",
    "DBTZ": "A",
    "Name": "Christiansted Harbor Seaplane Base",
    "Country": "Virgin Islands",
    "IATA/FAA": "SSB",
    "Longitude": "-64.705",
    "ICAO": "",
    "Airport ID": "7310",
    "Latitude": "17.747222",
    "Timezone": "0",
    "DST": "-4",
    "destinations": ["3616"]
}, {
    "City": "Xuzhou",
    "DBTZ": "U",
    "Name": "Xuzhou Guanyin Airport",
    "Country": "China",
    "IATA/FAA": "XUZ",
    "Longitude": "117.11",
    "ICAO": "ZSXZ",
    "Airport ID": "4149",
    "Latitude": "34.16",
    "Timezone": "140",
    "DST": "8",
    "destinations": ["3395", "3404", "3400", "3382", "3394", "4144", "3393", "3370", "4085", "6383", "2264", "2276", "3371", "3374", "3383", "3077", "3385", "3391"]
}, {
    "City": "Luoyang",
    "DBTZ": "U",
    "Name": "Luoyang Airport",
    "Country": "China",
    "IATA/FAA": "LYA",
    "Longitude": "112.28",
    "ICAO": "ZHLY",
    "Airport ID": "4148",
    "Latitude": "34.41",
    "Timezone": "210",
    "DST": "8",
    "destinations": ["3393", "4144", "3370", "3384", "6351", "3404", "3382", "3364", "3391"]
}, {
    "City": "Dongying",
    "DBTZ": "U",
    "Name": "Dongying Airport",
    "Country": "China",
    "IATA/FAA": "DOY",
    "Longitude": "118.2819",
    "ICAO": "ZSDY",
    "Airport ID": "4145",
    "Latitude": "37.2716",
    "Timezone": "86",
    "DST": "8",
    "destinations": ["3393", "3404", "3406", "3370", "3400", "3364"]
}, {
    "City": "Shenyang",
    "DBTZ": "U",
    "Name": "Shenyang Taoxian International Airport",
    "Country": "China",
    "IATA/FAA": "SHE",
    "Longitude": "123.2901",
    "ICAO": "ZYTX",
    "Airport ID": "4144",
    "Latitude": "41.3824",
    "Timezone": "198",
    "DST": "8",
    "destinations": ["3371", "3395", "6434", "3387", "3369", "4149", "156", "3386", "4148", "6400", "3388", "3406", "6347", "3374", "3379", "2276", "3370", "8826", "3390", "3375", "4109", "340", "6345", "6385", "3121", "2279", "5475", "3364", "4108", "6392", "3376", "6390", "6346", "2370", "3393", "2305", "3077", "3930", "8417", "3992", "3372", "6431", "3942", "2372", "3383", "6394", "3392", "6393", "6944", "6383", "3391", "6342", "6344", "6349", "6411", "4334", "3368", "3384", "6388", "3377", "3389", "6008"]
}, {
    "City": "Petersburg",
    "DBTZ": "A",
    "Name": "Petersburg James A. Johnson",
    "Country": "United States",
    "IATA/FAA": "PSG",
    "Longitude": "-132.945278",
    "ICAO": "PAPG",
    "Airport ID": "4147",
    "Latitude": "56.801667",
    "Timezone": "105",
    "DST": "-9",
    "destinations": ["3492", "5969"]
}, {
    "City": "Sandakan",
    "DBTZ": "N",
    "Name": "Sandakan",
    "Country": "Malaysia",
    "IATA/FAA": "SDK",
    "Longitude": "118.059486",
    "ICAO": "WBKS",
    "Airport ID": "4141",
    "Latitude": "5.900897",
    "Timezone": "46",
    "DST": "8",
    "destinations": ["3269", "3304", "6222", "3271"]
}, {
    "City": "Angeles City",
    "DBTZ": "N",
    "Name": "Diosdado Macapagal International",
    "Country": "Philippines",
    "IATA/FAA": "CRK",
    "Longitude": "120.560278",
    "ICAO": "RPLC",
    "Airport ID": "4140",
    "Latitude": "15.185833",
    "Timezone": "484",
    "DST": "8",
    "destinations": ["4206", "3077", "3121", "3316", "3885", "2430", "3930", "2241"]
}, {
    "City": "Muang Xay",
    "DBTZ": "N",
    "Name": "Oudomxay",
    "Country": "Laos",
    "IATA/FAA": "ODY",
    "Longitude": "101.994",
    "ICAO": "VLOS",
    "Airport ID": "4143",
    "Latitude": "20.6827",
    "Timezone": "1380",
    "DST": "7",
    "destinations": ["3120"]
}, {
    "City": "Luang Namtha",
    "DBTZ": "U",
    "Name": "Luang Namtha",
    "Country": "Laos",
    "IATA/FAA": "LXG",
    "Longitude": "101.4025",
    "ICAO": "VLLN",
    "Airport ID": "4142",
    "Latitude": "20.960556",
    "Timezone": "1968",
    "DST": "7",
    "destinations": ["3120"]
}, {
    "City": "Sitka",
    "DBTZ": "A",
    "Name": "Sitka Rocky Gutierrez",
    "Country": "United States",
    "IATA/FAA": "SIT",
    "Longitude": "-135.361611",
    "ICAO": "PASI",
    "Airport ID": "3856",
    "Latitude": "57.047138",
    "Timezone": "21",
    "DST": "-9",
    "destinations": ["3492", "3808"]
}, {
    "City": "Islip",
    "DBTZ": "A",
    "Name": "Long Island Mac Arthur",
    "Country": "United States",
    "IATA/FAA": "ISP",
    "Longitude": "-73.100222",
    "ICAO": "KISP",
    "Airport ID": "3857",
    "Latitude": "40.79525",
    "Timezone": "99",
    "DST": "-5",
    "destinations": ["3520", "3752", "3448", "3849", "3533", "3878", "3867", "3646", "7056", "3722"]
}, {
    "City": "Cold Bay",
    "DBTZ": "A",
    "Name": "Cold Bay",
    "Country": "United States",
    "IATA/FAA": "CDB",
    "Longitude": "-162.725436",
    "ICAO": "PACD",
    "Airport ID": "3854",
    "Latitude": "55.206061",
    "Timezone": "96",
    "DST": "-9",
    "destinations": ["3774", "7202", "6765", "7203"]
}, {
    "City": "Tulsa",
    "DBTZ": "A",
    "Name": "Tulsa Intl",
    "Country": "United States",
    "IATA/FAA": "TUL",
    "Longitude": "-95.888111",
    "ICAO": "KTUL",
    "Airport ID": "3855",
    "Latitude": "36.198389",
    "Timezone": "677",
    "DST": "-6",
    "destinations": ["3670", "3576", "3830", "3682", "3645", "3858", "3536", "3751", "4167", "3494", "3714", "3550", "3502", "3566", "3877", "3747", "3462", "3678"]
}, {
    "City": "Alexandria",
    "DBTZ": "A",
    "Name": "Alexandria Intl",
    "Country": "United States",
    "IATA/FAA": "AEX",
    "Longitude": "-92.549833",
    "ICAO": "KAEX",
    "Airport ID": "3852",
    "Latitude": "31.3274",
    "Timezone": "89",
    "DST": "-6",
    "destinations": ["3670", "3682", "3550"]
}, {
    "City": "Lanai",
    "DBTZ": "N",
    "Name": "Lanai",
    "Country": "United States",
    "IATA/FAA": "LNY",
    "Longitude": "-156.951419",
    "ICAO": "PHNY",
    "Airport ID": "3851",
    "Latitude": "20.785611",
    "Timezone": "1308",
    "DST": "-10",
    "destinations": ["3728", "3705", "3456"]
}, {
    "City": "Durango",
    "DBTZ": "S",
    "Name": "Durango Intl",
    "Country": "Mexico",
    "IATA/FAA": "DGO",
    "Longitude": "-104.528014",
    "ICAO": "MMDO",
    "Airport ID": "1801",
    "Latitude": "24.124194",
    "Timezone": "6104",
    "DST": "-6",
    "destinations": ["1824", "1847", "3550", "3484"]
}, {
    "City": "Navoi",
    "DBTZ": "E",
    "Name": "Navoi Airport",
    "Country": "Uzbekistan",
    "IATA/FAA": "NVI",
    "Longitude": "65.1708",
    "ICAO": "UTSA",
    "Airport ID": "6485",
    "Latitude": "40.1172",
    "Timezone": "0",
    "DST": "5",
    "destinations": ["4029", "2948", "2983"]
}, {
    "City": "Minneapolis",
    "DBTZ": "A",
    "Name": "Minneapolis St Paul Intl",
    "Country": "United States",
    "IATA/FAA": "MSP",
    "Longitude": "-93.221767",
    "ICAO": "KMSP",
    "Airport ID": "3858",
    "Latitude": "44.881956",
    "Timezone": "841",
    "DST": "-6",
    "destinations": ["3682", "3861", "3876", "3520", "3670", "3576", "3830", "3752", "3462", "193", "1382", "3484", "507", "3577", "3469", "1852", "3731", "4019", "5714", "3864", "580", "3774", "5720", "4047", "3673", "4039", "3825", "3811", "4021", "4083", "4287", "4037", "3690", "3495", "3448", "6957", "3820", "3849", "4020", "4043", "3486", "3759", "3488", "4045", "3627", "3751", "7064", "3598", "3729", "3645", "3494", "4292", "4127", "3533", "4089", "4009", "4041", "3467", "3442", "3653", "3685", "3880", "3650", "3504", "3714", "3550", "3457", "6837", "3585", "3535", "3659", "3712", "3797", "3544", "3877", "4017", "3697", "3543", "4044", "4128", "3458", "3878", "3747", "3473", "3717", "4072", "3498", "3459", "4216", "2279", "3863", "3454", "3611", "3720", "4046", "3570", "6133", "3839", "4087", "3626", "5765", "3608", "3622", "4048", "3793", "3621", "4359", "4014", "3748", "3536", "3817", "3867", "3678", "3745", "3646", "3855", "3636", "4023", "3676", "4356", "49", "120", "146", "156", "160", "166", "178", "3453", "1760", "5721"]
}, {
    "City": "Wilmington",
    "DBTZ": "A",
    "Name": "New Castle",
    "Country": "United States",
    "IATA/FAA": "ILG",
    "Longitude": "-75.606528",
    "ICAO": "KILG",
    "Airport ID": "3859",
    "Latitude": "39.678722",
    "Timezone": "79",
    "DST": "-5",
    "destinations": ["3751", "3645", "3878", "3747", "3793", "3646"]
}, {
    "City": "Wenatchee",
    "DBTZ": "A",
    "Name": "Pangborn Field",
    "Country": "United States",
    "IATA/FAA": "EAT",
    "Longitude": "-120.206",
    "ICAO": "KEAT",
    "Airport ID": "6916",
    "Latitude": "47.398",
    "Timezone": "1249",
    "DST": "-8",
    "destinations": ["3577"]
}, {
    "City": "Anadyr",
    "DBTZ": "N",
    "Name": "Ugolny Airport",
    "Country": "Russia",
    "IATA/FAA": "DYR",
    "Longitude": "177.741483",
    "ICAO": "UHMA",
    "Airport ID": "6098",
    "Latitude": "64.73495",
    "Timezone": "194",
    "DST": "12",
    "destinations": ["2927", "4029"]
}, {
    "City": "Nakhchivan",
    "DBTZ": "E",
    "Name": "Nakhchivan Airport",
    "Country": "Azerbaijan",
    "IATA/FAA": "NAJ",
    "Longitude": "45.4584",
    "ICAO": "UBBN",
    "Airport ID": "6090",
    "Latitude": "39.1888",
    "Timezone": "2863",
    "DST": "4",
    "destinations": ["6089"]
}, {
    "City": "Chokurdah",
    "DBTZ": "N",
    "Name": "Chokurdakh Airport",
    "Country": "Russia",
    "IATA/FAA": "CKH",
    "Longitude": "147.902",
    "ICAO": "UESO",
    "Airport ID": "6093",
    "Latitude": "70.6231",
    "Timezone": "151",
    "DST": "12",
    "destinations": ["2923"]
}, {
    "City": "Yakutia",
    "DBTZ": "N",
    "Name": "Poliarny Airport",
    "Country": "Russia",
    "IATA/FAA": "PYJ",
    "Longitude": "112.030325",
    "ICAO": "UERP",
    "Airport ID": "6092",
    "Latitude": "66.400431",
    "Timezone": "1660",
    "DST": "10",
    "destinations": ["4029", "2925", "4078", "2923"]
}, {
    "City": "Tiksi",
    "DBTZ": "N",
    "Name": "Tiksi Airport",
    "Country": "Russia",
    "IATA/FAA": "IKS",
    "Longitude": "128.903",
    "ICAO": "UEST",
    "Airport ID": "6095",
    "Latitude": "71.6977",
    "Timezone": "26",
    "DST": "10",
    "destinations": ["2923"]
}, {
    "City": "Cherskiy",
    "DBTZ": "N",
    "Name": "Cherskiy Airport",
    "Country": "Russia",
    "IATA/FAA": "CYX",
    "Longitude": "161.338",
    "ICAO": "UESS",
    "Airport ID": "6094",
    "Latitude": "68.7406",
    "Timezone": "20",
    "DST": "12",
    "destinations": ["2923"]
}, {
    "City": "Tiga",
    "DBTZ": "U",
    "Name": "Tiga Airport",
    "Country": "New Caledonia",
    "IATA/FAA": "TGJ",
    "Longitude": "167.804",
    "ICAO": "NWWA",
    "Airport ID": "5919",
    "Latitude": "-21.0961",
    "Timezone": "128",
    "DST": "11",
    "destinations": ["2001", "2000"]
}, {
    "City": "Ipota",
    "DBTZ": "U",
    "Name": "Ipota Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "IPA",
    "Longitude": "169.308",
    "ICAO": "NVVI",
    "Airport ID": "5918",
    "Latitude": "-18.8783",
    "Timezone": "23",
    "DST": "11",
    "destinations": ["5916", "4242", "1997"]
}, {
    "City": "Aniwa",
    "DBTZ": "U",
    "Name": "Aniwa Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "AWD",
    "Longitude": "169.605",
    "ICAO": "NVVB",
    "Airport ID": "5915",
    "Latitude": "-19.24",
    "Timezone": "69",
    "DST": "11",
    "destinations": ["5917", "4242"]
}, {
    "City": "Anelghowhat",
    "DBTZ": "U",
    "Name": "Anelghowhat Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "AUY",
    "Longitude": "169.771",
    "ICAO": "NVVA",
    "Airport ID": "5914",
    "Latitude": "-20.2492",
    "Timezone": "7",
    "DST": "11",
    "destinations": ["4242"]
}, {
    "City": "Futuna Island",
    "DBTZ": "U",
    "Name": "Futuna Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "FTA",
    "Longitude": "170.232",
    "ICAO": "NVVF",
    "Airport ID": "5917",
    "Latitude": "-19.5164",
    "Timezone": "0",
    "DST": "11",
    "destinations": ["5915", "4242"]
}, {
    "City": "Dillon's Bay",
    "DBTZ": "U",
    "Name": "Dillon's Bay Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "DLY",
    "Longitude": "169.001",
    "ICAO": "NVVD",
    "Airport ID": "5916",
    "Latitude": "-18.7694",
    "Timezone": "538",
    "DST": "11",
    "destinations": ["5918", "1997"]
}, {
    "City": "Valesdir",
    "DBTZ": "U",
    "Name": "Valesdir Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "VLS",
    "Longitude": "168.177",
    "ICAO": "NVSV",
    "Airport ID": "5911",
    "Latitude": "-16.7961",
    "Timezone": "10",
    "DST": "11",
    "destinations": ["1997"]
}, {
    "City": "Ambryn Island",
    "DBTZ": "U",
    "Name": "Ulei Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "ULB",
    "Longitude": "168.283",
    "ICAO": "NVSU",
    "Airport ID": "5910",
    "Latitude": "-16.333",
    "Timezone": "0",
    "DST": "11",
    "destinations": ["5897", "5900", "1997"]
}, {
    "City": "Malekula Island",
    "DBTZ": "U",
    "Name": "Southwest Bay Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "SWJ",
    "Longitude": "167.438",
    "ICAO": "NVSX",
    "Airport ID": "5912",
    "Latitude": "-16.495",
    "Timezone": "0",
    "DST": "11",
    "destinations": ["5905", "1997"]
}, {
    "City": "Hakodate",
    "DBTZ": "U",
    "Name": "Hakodate",
    "Country": "Japan",
    "IATA/FAA": "HKD",
    "Longitude": "140.821944",
    "ICAO": "RJCH",
    "Airport ID": "2288",
    "Latitude": "41.77",
    "Timezone": "151",
    "DST": "9",
    "destinations": ["2276", "2359", "2334", "2287", "3992", "3942"]
}, {
    "City": "Sarnia",
    "DBTZ": "A",
    "Name": "Chris Hadfield",
    "Country": "Canada",
    "IATA/FAA": "YZR",
    "Longitude": "-82.308889",
    "ICAO": "CYZR",
    "Airport ID": "199",
    "Latitude": "42.999444",
    "Timezone": "594",
    "DST": "-5",
    "destinations": ["193"]
}, {
    "City": "Sandspit",
    "DBTZ": "A",
    "Name": "Sandspit",
    "Country": "Canada",
    "IATA/FAA": "YZP",
    "Longitude": "-131.813833",
    "ICAO": "CYZP",
    "Airport ID": "198",
    "Latitude": "53.254333",
    "Timezone": "21",
    "DST": "-8",
    "destinations": ["156"]
}, {
    "City": "Yellowknife",
    "DBTZ": "A",
    "Name": "Yellowknife",
    "Country": "Canada",
    "IATA/FAA": "YZF",
    "Longitude": "-114.440278",
    "ICAO": "CYZF",
    "Airport ID": "196",
    "Latitude": "62.462778",
    "Timezone": "675",
    "DST": "-7",
    "destinations": ["100", "176", "32", "37", "49", "69", "132", "155", "183", "60", "72", "5463", "5526", "5511", "178", "4237", "136"]
}, {
    "City": "Kapuskasing",
    "DBTZ": "A",
    "Name": "Kapuskasing",
    "Country": "Canada",
    "IATA/FAA": "YYU",
    "Longitude": "-82.4675",
    "ICAO": "CYYU",
    "Airport ID": "190",
    "Latitude": "49.413889",
    "Timezone": "743",
    "DST": "-5",
    "destinations": ["121", "143"]
}, {
    "City": "Toronto",
    "DBTZ": "A",
    "Name": "Lester B Pearson Intl",
    "Country": "Canada",
    "IATA/FAA": "YYZ",
    "Longitude": "-79.630556",
    "ICAO": "CYYZ",
    "Airport ID": "193",
    "Latitude": "43.677223",
    "Timezone": "569",
    "DST": "-5",
    "destinations": ["302", "3876", "3520", "3670", "3797", "3877", "3484", "3697", "507", "3878", "3576", "3830", "3752", "2874", "3682", "2895", "2179", "5810", "1218", "4069", "3825", "2875", "3690", "2709", "3448", "3849", "5852", "1382", "3486", "3759", "609", "1852", "3488", "3751", "3645", "599", "3494", "1555", "3533", "340", "1926", "1941", "2882", "2564", "1909", "3077", "1910", "3714", "3550", "3585", "1701", "1779", "2789", "1881", "1780", "3458", "3542", "1824", "3717", "3858", "3861", "346", "1953", "2279", "3364", "3462", "3570", "1754", "1761", "1760", "3406", "3626", "3622", "3793", "3731", "2650", "3577", "3469", "1885", "1918", "3678", "3745", "1590", "3646", "2894", "1613", "1923", "679", "21", "45", "49", "56", "61", "73", "90", "100", "111", "113", "117", "120", "121", "126", "133", "135", "143", "146", "156", "160", "166", "174", "177", "178", "182", "184", "189", "199", "1678", "1892", "2276", "4304", "2902", "1871", "1904", "580", "2188", "1107", "16", "3930", "1128", "2223", "2206", "2207", "1638", "1636", "1633", "2985", "2072", "3941", "534", "502", "478", "1551", "2988", "3515", "2890", "2899", "87"]
}, {
    "City": "Mont Joli",
    "DBTZ": "A",
    "Name": "Mont Joli",
    "Country": "Canada",
    "IATA/FAA": "YYY",
    "Longitude": "-68.208056",
    "ICAO": "CYYY",
    "Airport ID": "192",
    "Latitude": "48.608612",
    "Timezone": "172",
    "DST": "-5",
    "destinations": ["27", "146", "111", "6775", "202", "161"]
}, {
    "City": "Linyi",
    "DBTZ": "U",
    "Name": "Shubuling Airport",
    "Country": "China",
    "IATA/FAA": "LYI",
    "Longitude": "118.412",
    "ICAO": "ZSLY",
    "Airport ID": "6385",
    "Latitude": "35.0461",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3379", "3370", "4144", "3391", "3371", "3404", "6392", "3376", "6394", "3374", "6341", "3386", "3364", "3406"]
}, {
    "City": "Huangyan",
    "DBTZ": "U",
    "Name": "Huangyan Luqiao Airport",
    "Country": "China",
    "IATA/FAA": "HYN",
    "Longitude": "121.429",
    "ICAO": "ZSLQ",
    "Airport ID": "6384",
    "Latitude": "28.5622",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3370", "3364", "3374", "6355", "3376", "3393", "3371"]
}, {
    "City": "St. Louis",
    "DBTZ": "A",
    "Name": "Lambert St Louis Intl",
    "Country": "United States",
    "IATA/FAA": "STL",
    "Longitude": "-90.370028",
    "ICAO": "KSTL",
    "Airport ID": "3678",
    "Latitude": "38.748697",
    "Timezone": "618",
    "DST": "-6",
    "destinations": ["5726", "4042", "3591", "5728", "5744", "6825", "5758", "3665", "5774", "3876", "3520", "3670", "3484", "3697", "3576", "3830", "3752", "3462", "3570", "3577", "193", "3682", "3488", "3645", "3797", "3858", "3536", "1852", "3751", "1780", "1760", "1836", "3690", "3849", "3759", "3502", "3533", "3685", "3877", "3458", "3747", "3717", "3861", "3863", "3454", "3626", "3621", "3646", "3486", "3494", "3714", "3550", "3469", "3448", "7669", "3566", "3878", "3793", "3731", "3855"]
}, {
    "City": "Quanzhou",
    "DBTZ": "U",
    "Name": "Quanzhou Airport",
    "Country": "China",
    "IATA/FAA": "JJN",
    "Longitude": "118.59",
    "ICAO": "ZSQZ",
    "Airport ID": "6386",
    "Latitude": "24.7964",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3394", "3376", "3391", "3370", "3386", "6395", "3406", "3374", "3371", "3121", "3388", "3364", "3373", "6394", "3077", "2276", "3375", "3395"]
}, {
    "City": "Jiujiang",
    "DBTZ": "U",
    "Name": "Jiujiang Lushan Airport",
    "Country": "China",
    "IATA/FAA": "JIU",
    "Longitude": "115.983",
    "ICAO": "ZSJJ",
    "Airport ID": "6381",
    "Latitude": "29.733",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3364", "3383", "3370", "3391"]
}, {
    "City": "Jingdezhen",
    "DBTZ": "U",
    "Name": "Jingdezhen Airport",
    "Country": "China",
    "IATA/FAA": "JDZ",
    "Longitude": "117.176",
    "ICAO": "ZSJD",
    "Airport ID": "6380",
    "Latitude": "29.3386",
    "Timezone": "112",
    "DST": "8",
    "destinations": ["3370", "3395", "3385", "3364", "3391", "3374", "3379", "3383"]
}, {
    "City": "Lianyungang",
    "DBTZ": "U",
    "Name": "Lianyungang Airport",
    "Country": "China",
    "IATA/FAA": "LYG",
    "Longitude": "119.25",
    "ICAO": "ZSLG",
    "Airport ID": "6383",
    "Latitude": "34.55",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3370", "6395", "4149", "3395", "4144", "3391", "3404", "3385", "6341", "3386", "3077", "3364", "3376"]
}, {
    "City": "Quzhou",
    "DBTZ": "U",
    "Name": "Quzhou Airport",
    "Country": "China",
    "IATA/FAA": "JUZ",
    "Longitude": "118.899",
    "ICAO": "ZSJU",
    "Airport ID": "6382",
    "Latitude": "28.9658",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3374", "3383", "6341"]
}, {
    "City": "Austin",
    "DBTZ": "A",
    "Name": "Austin Bergstrom Intl",
    "Country": "United States",
    "IATA/FAA": "AUS",
    "Longitude": "-97.669889",
    "ICAO": "KAUS",
    "Airport ID": "3673",
    "Latitude": "30.194528",
    "Timezone": "542",
    "DST": "-6",
    "destinations": ["3876", "3670", "3797", "3484", "507", "3861", "3830", "3752", "3462", "3682", "3577", "3448", "3533", "3582", "3878", "3469", "3645", "3473", "3858", "3536", "3751", "1852", "3559", "3560", "3747", "3453", "3748", "3817", "3877", "3486", "3494", "3714", "3550", "1824", "3690", "3849", "3502", "3520", "3566", "3829", "3731", "3646"]
}, {
    "City": "Dallas-Fort Worth",
    "DBTZ": "A",
    "Name": "Dallas Fort Worth Intl",
    "Country": "United States",
    "IATA/FAA": "DFW",
    "Longitude": "-97.037997",
    "ICAO": "KDFW",
    "Airport ID": "3670",
    "Latitude": "32.896828",
    "Timezone": "607",
    "DST": "-6",
    "destinations": ["3988", "3718", "4019", "3700", "3852", "1785", "3840", "3682", "3673", "3825", "3811", "1815", "4037", "3690", "3320", "2709", "3448", "3612", "3738", "3846", "3849", "1957", "3561", "2851", "1382", "3578", "3806", "4043", "3486", "3715", "3876", "3759", "4049", "3819", "3719", "3744", "4285", "1852", "1797", "3488", "1800", "3627", "3520", "3751", "3713", "3729", "3645", "3559", "4018", "3494", "4292", "3687", "3533", "340", "4009", "3437", "4041", "3497", "1804", "3465", "2560", "4063", "4038", "5740", "3775", "3685", "2564", "4008", "4034", "1767", "3728", "3566", "4113", "3714", "3550", "3930", "3457", "3585", "3580", "3712", "3797", "4354", "3877", "4256", "3484", "3829", "3513", "4017", "3493", "3697", "507", "2789", "1881", "3660", "3691", "1229", "3652", "1780", "3458", "3878", "3473", "1824", "3826", "4115", "4050", "3576", "3717", "4072", "1821", "3732", "3782", "3459", "3858", "3861", "1825", "1826", "1953", "2279", "3456", "3863", "3454", "3734", "3830", "3611", "1831", "3722", "3720", "3752", "3462", "4046", "3570", "1754", "3564", "3839", "1871", "1836", "1838", "4087", "3626", "3608", "3807", "3736", "3793", "1774", "3784", "1892", "3731", "3621", "3613", "2650", "4014", "3577", "3469", "4348", "3609", "3748", "1840", "1885", "3708", "2890", "3536", "1842", "3817", "3867", "4336", "3680", "3678", "3698", "3646", "1845", "3855", "3636", "3770", "3848", "3676", "3873", "4356", "49", "146", "156", "178", "193", "1855", "2188", "580", "3515", "3453"]
}, {
    "City": "Melbourne",
    "DBTZ": "A",
    "Name": "Melbourne Intl",
    "Country": "United States",
    "IATA/FAA": "MLB",
    "Longitude": "-80.645258",
    "ICAO": "KMLB",
    "Airport ID": "3671",
    "Latitude": "28.102753",
    "Timezone": "33",
    "DST": "-5",
    "destinations": ["3876", "3682"]
}, {
    "City": "Knoxville",
    "DBTZ": "A",
    "Name": "Mc Ghee Tyson",
    "Country": "United States",
    "IATA/FAA": "TYS",
    "Longitude": "-83.994028",
    "ICAO": "KTYS",
    "Airport ID": "3676",
    "Latitude": "35.810972",
    "Timezone": "981",
    "DST": "-5",
    "destinations": ["3682", "3876", "3520", "3670", "3697", "3830", "3752", "3645", "3858", "3751", "3533", "7056", "3617", "4167", "3494", "3714", "3550"]
}, {
    "City": "Weifang",
    "DBTZ": "U",
    "Name": "Weifang Airport",
    "Country": "China",
    "IATA/FAA": "WEF",
    "Longitude": "119.119",
    "ICAO": "ZSWF",
    "Airport ID": "6388",
    "Latitude": "36.6467",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3370", "3404", "3386", "3387", "3364", "3406", "4144"]
}, {
    "City": "Columbus",
    "DBTZ": "A",
    "Name": "Rickenbacker Intl",
    "Country": "United States",
    "IATA/FAA": "LCK",
    "Longitude": "-82.927822",
    "ICAO": "KLCK",
    "Airport ID": "3674",
    "Latitude": "39.813786",
    "Timezone": "744",
    "DST": "-5",
    "destinations": ["3617", "4167"]
}, {
    "City": "Campos",
    "DBTZ": "S",
    "Name": "Bartolomeu Lisandro",
    "Country": "Brazil",
    "IATA/FAA": "CAW",
    "Longitude": "-41.301669",
    "ICAO": "SBCP",
    "Airport ID": "2543",
    "Latitude": "-21.698333",
    "Timezone": "57",
    "DST": "-3",
    "destinations": ["2612", "6037", "2638"]
}, {
    "City": "Sao Vicente Island",
    "DBTZ": "U",
    "Name": "Sao Pedro",
    "Country": "Cape Verde",
    "IATA/FAA": "VXE",
    "Longitude": "-25.054661",
    "ICAO": "GVSV",
    "Airport ID": "1106",
    "Latitude": "16.833689",
    "Timezone": "66",
    "DST": "-1",
    "destinations": ["1638", "5674", "1102", "1105"]
}, {
    "City": "Criciuma",
    "DBTZ": "S",
    "Name": "Forquilhinha",
    "Country": "Brazil",
    "IATA/FAA": "CCM",
    "Longitude": "-49.424739",
    "ICAO": "SBCM",
    "Airport ID": "2541",
    "Latitude": "-28.725817",
    "Timezone": "93",
    "DST": "-3",
    "destinations": ["2578", "2555"]
}, {
    "City": "Caxias Do Sul",
    "DBTZ": "S",
    "Name": "Campo Dos Bugres",
    "Country": "Brazil",
    "IATA/FAA": "CXJ",
    "Longitude": "-51.187536",
    "ICAO": "SBCX",
    "Airport ID": "2547",
    "Latitude": "-29.197064",
    "Timezone": "2472",
    "DST": "-3",
    "destinations": ["2545", "2578", "2618"]
}, {
    "City": "Curitiba",
    "DBTZ": "S",
    "Name": "Afonso Pena",
    "Country": "Brazil",
    "IATA/FAA": "CWB",
    "Longitude": "-49.175775",
    "ICAO": "SBCT",
    "Airport ID": "2545",
    "Latitude": "-25.528475",
    "Timezone": "2988",
    "DST": "-3",
    "destinations": ["2816", "2599", "2535", "2537", "2547", "2564", "2554", "2581", "2586", "2612", "2578", "2531", "2442", "2618", "3988", "2560", "2621", "8244", "2555", "7383", "2539", "2538", "2613"]
}, {
    "City": "Addis Ababa",
    "DBTZ": "U",
    "Name": "Bole Intl",
    "Country": "Ethiopia",
    "IATA/FAA": "ADD",
    "Longitude": "38.799319",
    "ICAO": "HAAB",
    "Airport ID": "1107",
    "Latitude": "8.977889",
    "Timezone": "7656",
    "DST": "3",
    "destinations": ["2072", "2997", "3093", "3370", "3364", "2188", "260", "248", "1109", "1119", "1110", "2177", "1111", "3885", "1044", "1128", "1382", "245", "1177", "1112", "897", "2064", "1187", "264", "1038", "1555", "1020", "340", "1114", "1113", "1121", "3077", "1005", "4119", "7417", "1116", "813", "1180", "1173", "1165", "1175", "2176", "951", "970", "298", "507", "1016", "273", "907", "1145", "2194", "979", "1118", "4059", "999", "280", "246", "886", "3406", "2082", "891", "1590", "2057", "3980", "2241", "1701"]
}, {
    "City": "Cruzeiro do Sul",
    "DBTZ": "S",
    "Name": "Cruzeiro do Sul",
    "Country": "Brazil",
    "IATA/FAA": "CZS",
    "Longitude": "-72.769489",
    "ICAO": "SBCZ",
    "Airport ID": "2549",
    "Latitude": "-7.599906",
    "Timezone": "637",
    "DST": "-5",
    "destinations": ["2609"]
}, {
    "City": "Cuiaba",
    "DBTZ": "S",
    "Name": "Marechal Rondon",
    "Country": "Brazil",
    "IATA/FAA": "CGB",
    "Longitude": "-56.116719",
    "ICAO": "SBCY",
    "Airport ID": "2548",
    "Latitude": "-15.652931",
    "Timezone": "617",
    "DST": "-4",
    "destinations": ["2562", "7367", "2524", "2531", "2637", "2538", "2537", "2564", "6073", "2581", "2586", "7798", "2607", "7382", "2619", "2578", "2618", "2560"]
}, {
    "City": "Kalamata",
    "DBTZ": "E",
    "Name": "Kalamata",
    "Country": "Greece",
    "IATA/FAA": "KLX",
    "Longitude": "22.025525",
    "ICAO": "LGKL",
    "Airport ID": "1456",
    "Latitude": "37.068319",
    "Timezone": "26",
    "DST": "2",
    "destinations": ["1486", "737", "345", "346", "1613", "580", "502"]
}, {
    "City": "Taegu",
    "DBTZ": "U",
    "Name": "Daegu Ab",
    "Country": "South Korea",
    "IATA/FAA": "TAE",
    "Longitude": "128.658856",
    "ICAO": "RKTN",
    "Airport ID": "2381",
    "Latitude": "35.894108",
    "Timezone": "116",
    "DST": "9",
    "destinations": ["3364", "2370", "3930", "3406"]
}, {
    "City": "Pohang",
    "DBTZ": "U",
    "Name": "Pohang",
    "Country": "South Korea",
    "IATA/FAA": "KPO",
    "Longitude": "129.420486",
    "ICAO": "RKTH",
    "Airport ID": "2380",
    "Latitude": "35.987858",
    "Timezone": "70",
    "DST": "9",
    "destinations": ["2378", "2370"]
}, {
    "City": "Okinawa",
    "DBTZ": "N",
    "Name": "Naha",
    "Country": "Japan",
    "IATA/FAA": "OKA",
    "Longitude": "127.645869",
    "ICAO": "ROAH",
    "Airport ID": "2384",
    "Latitude": "26.195814",
    "Timezone": "12",
    "DST": "9",
    "destinations": ["2268", "2305", "2359", "2387", "2390", "3942", "2279", "3943", "2330", "3364", "2264", "2276", "3077", "3992", "3406", "2334", "3930", "2287", "6476", "2326", "4381", "2308", "2312", "2307", "2333", "2313", "2347", "2337", "2322", "2327", "2388", "2372"]
}, {
    "City": "Ishigaki",
    "DBTZ": "U",
    "Name": "Ishigaki",
    "Country": "Japan",
    "IATA/FAA": "ISG",
    "Longitude": "124.186983",
    "ICAO": "ROIG",
    "Airport ID": "2387",
    "Latitude": "24.344525",
    "Timezone": "93",
    "DST": "9",
    "destinations": ["2384", "2276", "3992", "2305", "2359", "2390", "3942"]
}, {
    "City": "Chios",
    "DBTZ": "E",
    "Name": "Chios",
    "Country": "Greece",
    "IATA/FAA": "JKH",
    "Longitude": "26.140572",
    "ICAO": "LGHI",
    "Airport ID": "1450",
    "Latitude": "38.343175",
    "Timezone": "15",
    "DST": "2",
    "destinations": ["1486", "3941", "1469", "1472"]
}, {
    "City": "Ioannina",
    "DBTZ": "E",
    "Name": "Ioannina",
    "Country": "Greece",
    "IATA/FAA": "IOA",
    "Longitude": "20.8225",
    "ICAO": "LGIO",
    "Airport ID": "1451",
    "Latitude": "39.696388",
    "Timezone": "1558",
    "DST": "2",
    "destinations": ["3941"]
}, {
    "City": "Jackson",
    "DBTZ": "A",
    "Name": "Jackson Evers Intl",
    "Country": "United States",
    "IATA/FAA": "JAN",
    "Longitude": "-90.075889",
    "ICAO": "KJAN",
    "Airport ID": "3580",
    "Latitude": "32.311167",
    "Timezone": "346",
    "DST": "-6",
    "destinations": ["3876", "3520", "3670", "3682", "3566", "3878", "3550", "3830", "3747"]
}, {
    "City": "Trinidad",
    "DBTZ": "U",
    "Name": "Tte Av Jorge Henrich Arauz",
    "Country": "Bolivia",
    "IATA/FAA": "TDD",
    "Longitude": "-64.918019",
    "ICAO": "SLTR",
    "Airport ID": "2769",
    "Latitude": "-14.818739",
    "Timezone": "509",
    "DST": "-4",
    "destinations": ["2759", "2761", "6056", "2762", "6058", "4247", "7317"]
}, {
    "City": "Tarija",
    "DBTZ": "U",
    "Name": "Capitan Oriel Lea Plaza",
    "Country": "Bolivia",
    "IATA/FAA": "TJA",
    "Longitude": "-64.701325",
    "ICAO": "SLTJ",
    "Airport ID": "2768",
    "Latitude": "-21.555736",
    "Timezone": "6084",
    "DST": "-4",
    "destinations": ["2772", "2759", "2762", "2771", "2767"]
}, {
    "City": "Williamsport",
    "DBTZ": "A",
    "Name": "Williamsport Rgnl",
    "Country": "United States",
    "IATA/FAA": "IPT",
    "Longitude": "-76.921094",
    "ICAO": "KIPT",
    "Airport ID": "3584",
    "Latitude": "41.241836",
    "Timezone": "529",
    "DST": "-5",
    "destinations": ["3752"]
}, {
    "City": "Indianapolis",
    "DBTZ": "A",
    "Name": "Indianapolis Intl",
    "Country": "United States",
    "IATA/FAA": "IND",
    "Longitude": "-86.294383",
    "ICAO": "KIND",
    "Airport ID": "3585",
    "Latitude": "39.717331",
    "Timezone": "797",
    "DST": "-5",
    "destinations": ["3876", "3520", "3670", "3797", "3484", "3576", "3830", "3752", "3462", "193", "3682", "1852", "3448", "3645", "3697", "1780", "3878", "3473", "3858", "3626", "3793", "3536", "3646", "3751", "3447", "3849", "3877", "3458", "3486", "3494", "3714", "3550", "3469", "3533", "3566"]
}, {
    "City": "La Paz",
    "DBTZ": "U",
    "Name": "El Alto Intl",
    "Country": "Bolivia",
    "IATA/FAA": "LPB",
    "Longitude": "-68.192256",
    "ICAO": "SLLP",
    "Airport ID": "2762",
    "Latitude": "-16.513339",
    "Timezone": "13325",
    "DST": "-4",
    "destinations": ["2764", "4247", "2769", "2771", "2789", "2709", "2641", "2649", "2759", "2761", "2767", "2768", "2802", "2812", "7317", "7313"]
}, {
    "City": "Cobija",
    "DBTZ": "U",
    "Name": "Heroes Del Acre",
    "Country": "Bolivia",
    "IATA/FAA": "CIJ",
    "Longitude": "-68.782972",
    "ICAO": "SLCO",
    "Airport ID": "2761",
    "Latitude": "-11.040436",
    "Timezone": "892",
    "DST": "-4",
    "destinations": ["2769", "2759"]
}, {
    "City": "Sucre",
    "DBTZ": "U",
    "Name": "Juana Azurduy De Padilla",
    "Country": "Bolivia",
    "IATA/FAA": "SRE",
    "Longitude": "-65.288747",
    "ICAO": "SLSU",
    "Airport ID": "2767",
    "Latitude": "-19.007083",
    "Timezone": "9527",
    "DST": "-4",
    "destinations": ["2759", "2762", "2771", "2768"]
}, {
    "City": "Potosi",
    "DBTZ": "U",
    "Name": "Capitan Nicolas Rojas",
    "Country": "Bolivia",
    "IATA/FAA": "POI",
    "Longitude": "-65.723706",
    "ICAO": "SLPO",
    "Airport ID": "2764",
    "Latitude": "-19.543069",
    "Timezone": "12913",
    "DST": "-4",
    "destinations": ["2762"]
}, {
    "City": "Matthew Town",
    "DBTZ": "U",
    "Name": "Matthew Town",
    "Country": "Bahamas",
    "IATA/FAA": "IGA",
    "Longitude": "-73.666862",
    "ICAO": "MYIG",
    "Airport ID": "1949",
    "Latitude": "20.975",
    "Timezone": "8",
    "DST": "-5",
    "destinations": ["1952", "1953"]
}, {
    "City": "Thessaloniki",
    "DBTZ": "E",
    "Name": "Makedonia",
    "Country": "Greece",
    "IATA/FAA": "SKG",
    "Longitude": "22.97095",
    "ICAO": "LGTS",
    "Airport ID": "1486",
    "Latitude": "40.519725",
    "Timezone": "22",
    "DST": "2",
    "destinations": ["344", "345", "352", "342", "350", "1460", "1450", "1456", "1476", "737", "3941", "1382", "1474", "609", "4029", "340", "1452", "1197", "2948", "1469", "346", "347", "1472", "351", "1613", "1555", "502", "2940", "2964", "1525", "353", "1553", "304", "4166", "1222", "355", "4198", "699", "1198", "1562", "657", "548", "8414", "1465", "1482", "580", "1739", "1657", "2985", "1701", "4053", "478", "337", "1489"]
}, {
    "City": "Griffith",
    "DBTZ": "O",
    "Name": "Griffith Airport",
    "Country": "Australia",
    "IATA/FAA": "GFF",
    "Longitude": "146.067",
    "ICAO": "YGTH",
    "Airport ID": "6271",
    "Latitude": "-34.2508",
    "Timezone": "439",
    "DST": "10",
    "destinations": ["6307", "3361"]
}, {
    "City": "Groote Eylandt",
    "DBTZ": "O",
    "Name": "Groote Eylandt Airport",
    "Country": "Australia",
    "IATA/FAA": "GTE",
    "Longitude": "136.46",
    "ICAO": "YGTE",
    "Airport ID": "6270",
    "Latitude": "-13.975",
    "Timezone": "53",
    "DST": "9.5",
    "destinations": ["3322", "3999", "6316"]
}, {
    "City": "N'gaoundere",
    "DBTZ": "N",
    "Name": "Ngaoundere",
    "Country": "Cameroon",
    "IATA/FAA": "NGE",
    "Longitude": "13.559242",
    "ICAO": "FKKN",
    "Airport ID": "900",
    "Latitude": "7.357011",
    "Timezone": "3655",
    "DST": "1",
    "destinations": ["4161"]
}, {
    "City": "Horn Island",
    "DBTZ": "O",
    "Name": "Horn Island Airport",
    "Country": "Australia",
    "IATA/FAA": "HID",
    "Longitude": "142.29",
    "ICAO": "YHID",
    "Airport ID": "6272",
    "Latitude": "-10.5864",
    "Timezone": "43",
    "DST": "10",
    "destinations": ["3322"]
}, {
    "City": "Livingstone",
    "DBTZ": "U",
    "Name": "Livingstone",
    "Country": "Zambia",
    "IATA/FAA": "LVI",
    "Longitude": "25.822692",
    "ICAO": "FLLI",
    "Airport ID": "906",
    "Latitude": "-17.821756",
    "Timezone": "3302",
    "DST": "2",
    "destinations": ["1005", "813", "907", "5604"]
}, {
    "City": "Lusaka",
    "DBTZ": "U",
    "Name": "Lusaka Intl",
    "Country": "Zambia",
    "IATA/FAA": "LUN",
    "Longitude": "28.452628",
    "ICAO": "FLLS",
    "Airport ID": "907",
    "Latitude": "-15.330817",
    "Timezone": "3779",
    "DST": "2",
    "destinations": ["580", "1005", "1016", "4059", "881", "951", "2188", "1107", "1013", "4105", "5612", "906", "908", "910", "5613", "799", "813"]
}, {
    "City": "Julia Creek",
    "DBTZ": "O",
    "Name": "Julia Creek Airport",
    "Country": "Australia",
    "IATA/FAA": "JCK",
    "Longitude": "141.723",
    "ICAO": "YJLC",
    "Airport ID": "6276",
    "Latitude": "-20.6683",
    "Timezone": "404",
    "DST": "10",
    "destinations": ["3324", "3360"]
}, {
    "City": "King Island",
    "DBTZ": "O",
    "Name": "King Island Airport",
    "Country": "Australia",
    "IATA/FAA": "KNS",
    "Longitude": "143.878",
    "ICAO": "YKII",
    "Airport ID": "6278",
    "Latitude": "-39.8775",
    "Timezone": "132",
    "DST": "10",
    "destinations": ["6338", "3339"]
}, {
    "City": "Mfuwe",
    "DBTZ": "U",
    "Name": "Mfuwe",
    "Country": "Zambia",
    "IATA/FAA": "MFU",
    "Longitude": "31.936581",
    "ICAO": "FLMF",
    "Airport ID": "908",
    "Latitude": "-13.258878",
    "Timezone": "1853",
    "DST": "2",
    "destinations": ["907"]
}, {
    "City": "Kasigluk",
    "DBTZ": "A",
    "Name": "Kasigluk Airport",
    "Country": "United States",
    "IATA/FAA": "KUK",
    "Longitude": "-162.524444",
    "ICAO": "PFKA",
    "Airport ID": "7094",
    "Latitude": "60.873333",
    "Timezone": "40",
    "DST": "-9",
    "destinations": ["7212"]
}, {
    "City": "Tijuana",
    "DBTZ": "S",
    "Name": "General Abelardo L Rodriguez Intl",
    "Country": "Mexico",
    "IATA/FAA": "TIJ",
    "Longitude": "-116.970158",
    "ICAO": "MMTJ",
    "Airport ID": "1847",
    "Latitude": "32.541064",
    "Timezone": "489",
    "DST": "-8",
    "destinations": ["1804", "1824", "1815", "1796", "1792", "1801", "1807", "2279", "3406", "1783", "1785", "1794", "1808", "1852", "1797", "1816", "1814", "1821", "1825", "1826", "1829", "1831", "1836", "1838", "1840", "1842", "1846", "1802", "1835", "1854", "1855", "1858"]
}, {
    "City": "Tuxtla Gutierrez",
    "DBTZ": "S",
    "Name": "Angel Albino Corzo",
    "Country": "Mexico",
    "IATA/FAA": "TGZ",
    "Longitude": "-93.026081",
    "ICAO": "MMTG",
    "Airport ID": "1846",
    "Latitude": "16.561822",
    "Timezone": "1491",
    "DST": "-6",
    "destinations": ["1824", "1852", "1804", "1825", "1847"]
}, {
    "City": "Torreon",
    "DBTZ": "S",
    "Name": "Torreon Intl",
    "Country": "Mexico",
    "IATA/FAA": "TRC",
    "Longitude": "-103.410583",
    "ICAO": "MMTC",
    "Airport ID": "1845",
    "Latitude": "25.568278",
    "Timezone": "3688",
    "DST": "-6",
    "destinations": ["1824", "3670", "1797", "1804", "3550", "1852"]
}, {
    "City": "Dunhuang",
    "DBTZ": "U",
    "Name": "Dunhuang Airport",
    "Country": "China",
    "IATA/FAA": "DNH",
    "Longitude": "94.4818",
    "ICAO": "ZLDH",
    "Airport ID": "4308",
    "Latitude": "40.094",
    "Timezone": "3688",
    "DST": "8",
    "destinations": ["6431", "3399", "3379", "3386"]
}, {
    "City": "Ji-Parana",
    "DBTZ": "S",
    "Name": "Ji-Parana Airport",
    "Country": "Brazil",
    "IATA/FAA": "JPR",
    "Longitude": "-61.8465",
    "ICAO": "SWJI",
    "Airport ID": "6073",
    "Latitude": "-10.8708",
    "Timezone": "598",
    "DST": "-4",
    "destinations": ["2548"]
}, {
    "City": "El Vigia",
    "DBTZ": "U",
    "Name": "Juan Pablo Perez Alfonso Airport",
    "Country": "Venezuela",
    "IATA/FAA": "VIG",
    "Longitude": "-71.672819",
    "ICAO": "SVVG",
    "Airport ID": "6072",
    "Latitude": "8.6241",
    "Timezone": "250",
    "DST": "-4.5",
    "destinations": ["2851", "2850"]
}, {
    "City": "Poso",
    "DBTZ": "N",
    "Name": "Kasiguncu",
    "Country": "Indonesia",
    "IATA/FAA": "PSJ",
    "Longitude": "120.657669",
    "ICAO": "WAMP",
    "Airport ID": "3251",
    "Latitude": "-1.416753",
    "Timezone": "174",
    "DST": "8",
    "destinations": ["3240"]
}, {
    "City": "Yazd",
    "DBTZ": "E",
    "Name": "Yazd Shahid Sadooghi",
    "Country": "Iran",
    "IATA/FAA": "AZD",
    "Longitude": "54.276503",
    "ICAO": "OIYY",
    "Airport ID": "2164",
    "Latitude": "31.904908",
    "Timezone": "4054",
    "DST": "3.5",
    "destinations": ["4331", "2134", "2131", "2074"]
}, {
    "City": "Zahedan",
    "DBTZ": "E",
    "Name": "Zahedan Intl",
    "Country": "Iran",
    "IATA/FAA": "ZAH",
    "Longitude": "60.906189",
    "ICAO": "OIZH",
    "Airport ID": "2167",
    "Latitude": "29.475686",
    "Timezone": "4564",
    "DST": "3.5",
    "destinations": ["4331", "2188", "6747", "2157", "2131", "2136", "2166"]
}, {
    "City": "Chah Bahar",
    "DBTZ": "E",
    "Name": "Chah Bahar",
    "Country": "Iran",
    "IATA/FAA": "ZBR",
    "Longitude": "60.382114",
    "ICAO": "OIZC",
    "Airport ID": "2166",
    "Latitude": "25.44335",
    "Timezone": "43",
    "DST": "3.5",
    "destinations": ["2194", "4331", "2131", "2134", "2167"]
}, {
    "City": "Nice",
    "DBTZ": "E",
    "Name": "Cote D\\\\'Azur",
    "Country": "France",
    "IATA/FAA": "NCE",
    "Longitude": "7.215872",
    "ICAO": "LFMN",
    "Airport ID": "1354",
    "Latitude": "43.658411",
    "Timezone": "12",
    "DST": "1",
    "destinations": ["344", "342", "351", "1590", "1403", "1280", "1264", "1330", "1412", "1320", "1415", "1273", "507", "345", "1613", "1324", "580", "1321", "1382", "1322", "1555", "1323", "3797", "1399", "1335", "1418", "1386", "495", "1435", "1551", "210", "221", "1074", "1075", "421", "2954", "503", "502", "3953", "737", "636", "609", "687", "644", "665", "664", "599", "596", "2188", "603", "585", "591", "1218", "1229", "629", "340", "346", "679", "517", "478", "1665", "1678", "1587", "415", "2939", "1657", "302", "4029", "2948", "2985", "1270", "1701", "1638", "146", "293", "286", "287", "465", "490", "4053", "535", "491", "492", "1561", "521", "1520", "548", "337", "4264", "469"]
}, {
    "City": "Charlestown",
    "DBTZ": "U",
    "Name": "Vance Winkworth Amory International Airport",
    "Country": "Saint Kitts and Nevis",
    "IATA/FAA": "NEV",
    "Longitude": "-62.589869",
    "ICAO": "TKPN",
    "Airport ID": "6079",
    "Latitude": "17.205678",
    "Timezone": "14",
    "DST": "-4",
    "destinations": ["2874", "2890", "2891", "2899", "2883"]
}, {
    "City": "Tabriz",
    "DBTZ": "E",
    "Name": "Tabriz Intl",
    "Country": "Iran",
    "IATA/FAA": "TBZ",
    "Longitude": "46.235",
    "ICAO": "OITT",
    "Airport ID": "2162",
    "Latitude": "38.133889",
    "Timezone": "4459",
    "DST": "3.5",
    "destinations": ["4331", "2131", "2188", "2157", "2104", "2134", "4150", "2922", "2074", "1701"]
}, {
    "City": "Sao Luis",
    "DBTZ": "S",
    "Name": "Marechal Cunha Machado Intl",
    "Country": "Brazil",
    "IATA/FAA": "SLZ",
    "Longitude": "-44.234139",
    "ICAO": "SBSL",
    "Airport ID": "2616",
    "Latitude": "-2.585361",
    "Timezone": "178",
    "DST": "-3",
    "destinations": ["2537", "2559", "2623", "2526", "2531", "2560", "2564", "2572"]
}, {
    "City": "Sao Jose Dos Campos",
    "DBTZ": "S",
    "Name": "Professor Urbano Ernesto Stumpf",
    "Country": "Brazil",
    "IATA/FAA": "SJK",
    "Longitude": "-45.862739",
    "ICAO": "SBSJ",
    "Airport ID": "2615",
    "Latitude": "-23.228172",
    "Timezone": "2120",
    "DST": "-3",
    "destinations": ["2528", "2612"]
}, {
    "City": "Ternate",
    "DBTZ": "N",
    "Name": "Sultan Babullah",
    "Country": "Indonesia",
    "IATA/FAA": "TTE",
    "Longitude": "127.381486",
    "ICAO": "WAMT",
    "Airport ID": "3253",
    "Latitude": "0.831414",
    "Timezone": "49",
    "DST": "9",
    "destinations": ["3275", "3250", "3240"]
}, {
    "City": "Ribeirao Preto",
    "DBTZ": "S",
    "Name": "Leite Lopes",
    "Country": "Brazil",
    "IATA/FAA": "RAO",
    "Longitude": "-47.774189",
    "ICAO": "SBRP",
    "Airport ID": "2613",
    "Latitude": "-21.134167",
    "Timezone": "1802",
    "DST": "-3",
    "destinations": ["2531", "2560", "2564", "2562", "2528", "2619", "2633", "2537", "2578", "2618", "2545", "2612"]
}, {
    "City": "Rio De Janeiro",
    "DBTZ": "S",
    "Name": "Santos Dumont",
    "Country": "Brazil",
    "IATA/FAA": "SDU",
    "Longitude": "-43.163133",
    "ICAO": "SBRJ",
    "Airport ID": "2612",
    "Latitude": "-22.910461",
    "Timezone": "11",
    "DST": "-3",
    "destinations": ["4209", "2543", "2618", "2537", "2545", "2564", "2562", "6037", "2599", "2615", "2578", "2638", "2531", "2522", "2569", "2621", "7364", "2613"]
}, {
    "City": "Rio Grande",
    "DBTZ": "S",
    "Name": "Rio Grande",
    "Country": "Brazil",
    "IATA/FAA": "RIG",
    "Longitude": "-52.166542",
    "ICAO": "SBRG",
    "Airport ID": "2611",
    "Latitude": "-32.082617",
    "Timezone": "27",
    "DST": "-3",
    "destinations": ["2603", "2599"]
}, {
    "City": "Recife",
    "DBTZ": "S",
    "Name": "Guararapes Gilberto Freyre Intl",
    "Country": "Brazil",
    "IATA/FAA": "REC",
    "Longitude": "-34.923039",
    "ICAO": "SBRF",
    "Airport ID": "2610",
    "Latitude": "-8.126794",
    "Timezone": "33",
    "DST": "-3",
    "destinations": ["3576", "2522", "2537", "2556", "2559", "2564", "2590", "2597", "2621", "2623", "2578", "1871", "340", "2531", "2560", "6034", "2604", "1638"]
}, {
    "City": "Kwethluk",
    "DBTZ": "A",
    "Name": "Kwethluk Airport",
    "Country": "United States",
    "IATA/FAA": "KWT",
    "Longitude": "-161.443611",
    "ICAO": "PFKW",
    "Airport ID": "7095",
    "Latitude": "60.790278",
    "Timezone": "30",
    "DST": "-9",
    "destinations": ["3599"]
}, {
    "City": "Quilpie",
    "DBTZ": "O",
    "Name": "Quilpie Airport",
    "Country": "Australia",
    "IATA/FAA": "ULP",
    "Longitude": "144.253",
    "ICAO": "YQLP",
    "Airport ID": "6322",
    "Latitude": "-26.6122",
    "Timezone": "655",
    "DST": "10",
    "destinations": ["3323", "6333"]
}, {
    "City": "Sao Jose Do Rio Preto",
    "DBTZ": "S",
    "Name": "Sao Jose Do Rio Preto",
    "Country": "Brazil",
    "IATA/FAA": "SJP",
    "Longitude": "-49.406511",
    "ICAO": "SBSR",
    "Airport ID": "2619",
    "Latitude": "-20.816567",
    "Timezone": "1784",
    "DST": "-3",
    "destinations": ["2613", "2548", "2578", "2618", "2564", "2531"]
}, {
    "City": "Sao Paulo",
    "DBTZ": "S",
    "Name": "Congonhas",
    "Country": "Brazil",
    "IATA/FAA": "CGH",
    "Longitude": "-46.655375",
    "ICAO": "SBSP",
    "Airport ID": "2618",
    "Latitude": "-23.626692",
    "Timezone": "2631",
    "DST": "-3",
    "destinations": ["2612", "2531", "2548", "2538", "4215", "2537", "2545", "2547", "2555", "2560", "2562", "2576", "8238", "2581", "2586", "2595", "2599", "2550", "2621", "2633", "2638", "4209", "2554", "2569", "2613", "2619"]
}, {
    "City": "Kota Bahru",
    "DBTZ": "N",
    "Name": "Sultan Ismail Petra",
    "Country": "Malaysia",
    "IATA/FAA": "KBR",
    "Longitude": "102.293014",
    "ICAO": "WMKC",
    "Airport ID": "3298",
    "Latitude": "6.16685",
    "Timezone": "16",
    "DST": "8",
    "destinations": ["3269", "3303", "3263", "3304", "3316", "3308", "3408"]
}, {
    "City": "Governor's Harbor",
    "DBTZ": "U",
    "Name": "Governors Harbour",
    "Country": "Bahamas",
    "IATA/FAA": "GHB",
    "Longitude": "-76.331011",
    "ICAO": "MYEM",
    "Airport ID": "1944",
    "Latitude": "25.284706",
    "Timezone": "26",
    "DST": "-5",
    "destinations": ["3533", "1953"]
}, {
    "City": "Guantanamo",
    "DBTZ": "U",
    "Name": "Mariana Grajales",
    "Country": "Cuba",
    "IATA/FAA": "GAO",
    "Longitude": "-75.158328",
    "ICAO": "MUGT",
    "Airport ID": "1908",
    "Latitude": "20.085419",
    "Timezone": "56",
    "DST": "-5",
    "destinations": ["1909"]
}, {
    "City": "Havana",
    "DBTZ": "U",
    "Name": "Jose Marti Intl",
    "Country": "Cuba",
    "IATA/FAA": "HAV",
    "Longitude": "-82.409086",
    "ICAO": "MUHA",
    "Airport ID": "1909",
    "Latitude": "22.989153",
    "Timezone": "210",
    "DST": "-5",
    "destinations": ["1824", "193", "1382", "1852", "2709", "1903", "1910", "1524", "1905", "1871", "2851", "1904", "3988", "2878", "1908", "1229", "1953", "1386", "2850", "1762", "1918", "2985", "340", "951", "2688", "580", "1926", "2789", "1678", "1892", "502"]
}, {
    "City": "Banda Aceh",
    "DBTZ": "N",
    "Name": "Sultan Iskandarmuda",
    "Country": "Indonesia",
    "IATA/FAA": "BTJ",
    "Longitude": "95.420372",
    "ICAO": "WITT",
    "Airport ID": "3294",
    "Latitude": "5.523522",
    "Timezone": "65",
    "DST": "7",
    "destinations": ["3304", "3308", "3275"]
}, {
    "City": "Camaguey",
    "DBTZ": "U",
    "Name": "Ignacio Agramonte Intl",
    "Country": "Cuba",
    "IATA/FAA": "CMW",
    "Longitude": "-77.847433",
    "ICAO": "MUCM",
    "Airport ID": "1904",
    "Latitude": "21.420428",
    "Timezone": "413",
    "DST": "-5",
    "destinations": ["1909", "146", "193"]
}, {
    "City": "Santiago De Cuba",
    "DBTZ": "U",
    "Name": "Antonio Maceo Intl",
    "Country": "Cuba",
    "IATA/FAA": "SCU",
    "Longitude": "-75.835414",
    "ICAO": "MUCU",
    "Airport ID": "1905",
    "Latitude": "19.969769",
    "Timezone": "249",
    "DST": "-5",
    "destinations": ["1555", "1909", "1762", "146"]
}, {
    "City": "Cayo Largo del Sur",
    "DBTZ": "U",
    "Name": "Vilo Acuna Intl",
    "Country": "Cuba",
    "IATA/FAA": "CYO",
    "Longitude": "-81.545989",
    "ICAO": "MUCL",
    "Airport ID": "1903",
    "Latitude": "21.616453",
    "Timezone": "10",
    "DST": "-5",
    "destinations": ["1524", "1909", "1905"]
}, {
    "City": "Oakland",
    "DBTZ": "A",
    "Name": "Metropolitan Oakland Intl",
    "Country": "United States",
    "IATA/FAA": "OAK",
    "Longitude": "-122.220722",
    "ICAO": "KOAK",
    "Airport ID": "3453",
    "Latitude": "37.721278",
    "Timezone": "9",
    "DST": "-8",
    "destinations": ["3728", "3514", "3602", "3456", "3720", "3462", "3577", "3797", "3582", "3484", "3536", "737", "3759", "3751", "3877", "3747", "3863", "3867", "6505", "3777", "4099", "7579", "3670", "3830", "4019", "3673", "3495", "3644", "3467", "3566", "3458", "3734", "3731", "1804"]
}, {
    "City": "Kahului",
    "DBTZ": "N",
    "Name": "Kahului",
    "Country": "United States",
    "IATA/FAA": "OGG",
    "Longitude": "-156.430458",
    "ICAO": "PHOG",
    "Airport ID": "3456",
    "Latitude": "20.89865",
    "Timezone": "54",
    "DST": "-10",
    "destinations": ["3670", "3484", "3453", "3462", "3748", "3817", "156", "3720", "3731", "3577", "3728", "3415", "3514", "3602", "3445", "3851", "3705", "3545", "3751", "3830", "3469"]
}, {
    "City": "Wichita",
    "DBTZ": "A",
    "Name": "Wichita Mid Continent",
    "Country": "United States",
    "IATA/FAA": "ICT",
    "Longitude": "-97.433056",
    "ICAO": "KICT",
    "Airport ID": "3457",
    "Latitude": "37.649944",
    "Timezone": "1333",
    "DST": "-6",
    "destinations": ["3670", "3830", "3682", "3858", "3502", "3747", "6505", "3877", "3751", "3550", "3484"]
}, {
    "City": "Omaha",
    "DBTZ": "A",
    "Name": "Eppley Afld",
    "Country": "United States",
    "IATA/FAA": "OMA",
    "Longitude": "-95.894069",
    "ICAO": "KOMA",
    "Airport ID": "3454",
    "Latitude": "41.303167",
    "Timezone": "984",
    "DST": "-6",
    "destinations": ["3876", "3520", "3670", "3830", "3462", "3682", "3577", "3645", "3697", "3878", "3858", "3536", "3751", "3747", "3731", "3678", "3494", "3550", "3877"]
}, {
    "City": "Nuiqsut",
    "DBTZ": "A",
    "Name": "Nuiqsut Airport",
    "Country": "United States",
    "IATA/FAA": "NUI",
    "Longitude": "-151.005556",
    "ICAO": "PAQT",
    "Airport ID": "7092",
    "Latitude": "70.21",
    "Timezone": "38",
    "DST": "-9",
    "destinations": ["3571", "3620"]
}, {
    "City": "Kansas City",
    "DBTZ": "A",
    "Name": "Kansas City Intl",
    "Country": "United States",
    "IATA/FAA": "MCI",
    "Longitude": "-94.713905",
    "ICAO": "KMCI",
    "Airport ID": "3458",
    "Latitude": "39.297606",
    "Timezone": "1026",
    "DST": "-6",
    "destinations": ["3876", "3520", "3670", "3830", "3752", "3462", "3577", "193", "3682", "3488", "3645", "3484", "3697", "3878", "3473", "3858", "3536", "1852", "3751", "3849", "3502", "3585", "3877", "3747", "3717", "3861", "3720", "3469", "3678", "3646", "3461", "5771", "3486", "3494", "3714", "3550", "4019", "3690", "3448", "3533", "3566", "3453", "3731"]
}, {
    "City": "Madison",
    "DBTZ": "A",
    "Name": "Dane Co Rgnl Truax Fld",
    "Country": "United States",
    "IATA/FAA": "MSN",
    "Longitude": "-89.337514",
    "ICAO": "KMSN",
    "Airport ID": "3459",
    "Latitude": "43.139858",
    "Timezone": "887",
    "DST": "-6",
    "destinations": ["3670", "3830", "3682", "3488", "3520", "3645", "3697", "3858", "3536", "3751", "3494"]
}, {
    "City": "Kiunga",
    "DBTZ": "U",
    "Name": "Kiunga Airport",
    "Country": "Papua New Guinea",
    "IATA/FAA": "UNG",
    "Longitude": "141.282",
    "ICAO": "AYKI",
    "Airport ID": "5425",
    "Latitude": "-6.12571",
    "Timezone": "88",
    "DST": "10",
    "destinations": ["5421", "3", "5", "5434"]
}, {
    "City": "Wewak",
    "DBTZ": "U",
    "Name": "Wewak Intl",
    "Country": "Papua New Guinea",
    "IATA/FAA": "WWK",
    "Longitude": "143.669186",
    "ICAO": "AYWK",
    "Airport ID": "6",
    "Latitude": "-3.583828",
    "Timezone": "19",
    "DST": "10",
    "destinations": ["3", "2", "5", "5436"]
}, {
    "City": "Ca Mau",
    "DBTZ": "U",
    "Name": "Ca Mau",
    "Country": "Vietnam",
    "IATA/FAA": "CAH",
    "Longitude": "105.174721",
    "ICAO": "VVCM",
    "Airport ID": "4156",
    "Latitude": "9.188049",
    "Timezone": "50",
    "DST": "7",
    "destinations": ["3205"]
}, {
    "City": "Eek",
    "DBTZ": "A",
    "Name": "Eek Airport",
    "Country": "United States",
    "IATA/FAA": "EEK",
    "Longitude": "-162.043889",
    "ICAO": "PAEE",
    "Airport ID": "7093",
    "Latitude": "60.213611",
    "Timezone": "15",
    "DST": "-9",
    "destinations": ["3599", "7206"]
}, {
    "City": "Hoskins",
    "DBTZ": "U",
    "Name": "Kimbe Airport",
    "Country": "Papua New Guinea",
    "IATA/FAA": "HKN",
    "Longitude": "150.405",
    "ICAO": "AYHK",
    "Airport ID": "5424",
    "Latitude": "-5.46217",
    "Timezone": "66",
    "DST": "10",
    "destinations": ["4", "5", "5435"]
}, {
    "City": "Bijie",
    "DBTZ": "N",
    "Name": "Bijie Feixiong Airport",
    "Country": "China",
    "IATA/FAA": "BFJ",
    "Longitude": "105.426",
    "ICAO": "",
    "Airport ID": "9025",
    "Latitude": "27.253",
    "Timezone": "5250",
    "DST": "8",
    "destinations": ["3370", "3374", "3393", "3394", "3382", "3391", "6341"]
}, {
    "City": "Ulyanovsk",
    "DBTZ": "N",
    "Name": "Barataevka",
    "Country": "Russia",
    "IATA/FAA": "ULV",
    "Longitude": "48.2267",
    "ICAO": "UWLL",
    "Airport ID": "7003",
    "Latitude": "54.268299",
    "Timezone": "463",
    "DST": "4",
    "destinations": ["4029", "2975"]
}, {
    "City": "Hilton Head",
    "DBTZ": "U",
    "Name": "Hilton Head",
    "Country": "United States",
    "IATA/FAA": "HHH",
    "Longitude": "-80.752",
    "ICAO": "KHHH",
    "Airport ID": "7002",
    "Latitude": "32.216",
    "Timezone": "10",
    "DST": "-5",
    "destinations": ["3876", "3520"]
}, {
    "City": "Shageluk",
    "DBTZ": "A",
    "Name": "Shageluk Airport",
    "Country": "United States",
    "IATA/FAA": "SHX",
    "Longitude": "-159.569167",
    "ICAO": "PAHX",
    "Airport ID": "7090",
    "Latitude": "62.692222",
    "Timezone": "79",
    "DST": "-9",
    "destinations": ["5967", "7219"]
}, {
    "City": "Linkoeping",
    "DBTZ": "E",
    "Name": "Saab",
    "Country": "Sweden",
    "IATA/FAA": "LPI",
    "Longitude": "15.680508",
    "ICAO": "ESSL",
    "Airport ID": "742",
    "Latitude": "58.40615",
    "Timezone": "172",
    "DST": "1",
    "destinations": ["580", "609"]
}, {
    "City": "Norrkoeping",
    "DBTZ": "E",
    "Name": "Kungsangen",
    "Country": "Sweden",
    "IATA/FAA": "NRK",
    "Longitude": "16.250622",
    "ICAO": "ESSP",
    "Airport ID": "743",
    "Latitude": "58.586253",
    "Timezone": "32",
    "DST": "1",
    "destinations": ["421"]
}, {
    "City": "Visby",
    "DBTZ": "E",
    "Name": "Visby",
    "Country": "Sweden",
    "IATA/FAA": "VBY",
    "Longitude": "18.346211",
    "ICAO": "ESSV",
    "Airport ID": "746",
    "Latitude": "57.662797",
    "Timezone": "164",
    "DST": "1",
    "destinations": ["737", "738", "691", "708"]
}, {
    "City": "Ranchi",
    "DBTZ": "N",
    "Name": "Birsa Munda",
    "Country": "India",
    "IATA/FAA": "IXR",
    "Longitude": "85.321675",
    "ICAO": "VERC",
    "Airport ID": "3063",
    "Latitude": "23.31425",
    "Timezone": "2148",
    "DST": "5.5",
    "destinations": ["2997", "3043", "3093", "3061"]
}, {
    "City": "Patina",
    "DBTZ": "N",
    "Name": "Patna",
    "Country": "India",
    "IATA/FAA": "PAT",
    "Longitude": "85.087992",
    "ICAO": "VEPT",
    "Airport ID": "3061",
    "Latitude": "25.591317",
    "Timezone": "170",
    "DST": "5.5",
    "destinations": ["3043", "3093", "3063", "3105"]
}, {
    "City": "Osh",
    "DBTZ": "U",
    "Name": "Osh",
    "Country": "Kyrgyzstan",
    "IATA/FAA": "OSS",
    "Longitude": "72.793269",
    "ICAO": "UAFO",
    "Airport ID": "2913",
    "Latitude": "40.608989",
    "Timezone": "2927",
    "DST": "6",
    "destinations": ["3399", "4029", "2937", "4078", "2975", "2968", "2912", "1701", "4374", "2974", "2948", "4118", "2990"]
}, {
    "City": "Vishakhapatnam",
    "DBTZ": "N",
    "Name": "Vishakhapatnam",
    "Country": "India",
    "IATA/FAA": "VTZ",
    "Longitude": "83.224483",
    "ICAO": "VEVZ",
    "Airport ID": "3066",
    "Latitude": "17.721167",
    "Timezone": "15",
    "DST": "5.5",
    "destinations": ["3042", "3131", "3141", "3144", "2997", "3043", "3093", "3316"]
}, {
    "City": "Murmansk",
    "DBTZ": "N",
    "Name": "Murmansk",
    "Country": "Russia",
    "IATA/FAA": "MMK",
    "Longitude": "32.750822",
    "ICAO": "ULMM",
    "Airport ID": "2949",
    "Latitude": "68.781672",
    "Timezone": "266",
    "DST": "4",
    "destinations": ["4362", "2948", "2985", "663", "6111"]
}, {
    "City": "St. Petersburg",
    "DBTZ": "N",
    "Name": "Pulkovo",
    "Country": "Russia",
    "IATA/FAA": "LED",
    "Longitude": "30.262503",
    "ICAO": "ULLI",
    "Airport ID": "2948",
    "Latitude": "59.800292",
    "Timezone": "78",
    "DST": "4",
    "destinations": ["2990", "6969", "6160", "2966", "4362", "2949", "2989", "4029", "4078", "2979", "6147", "6162", "6940", "6142", "2961", "6166", "2967", "2987", "1735", "3941", "1452", "1472", "1486", "351", "1382", "1551", "421", "1555", "1524", "2954", "507", "7447", "3953", "6111", "4368", "644", "7563", "2188", "1191", "345", "342", "3364", "6148", "2980", "6149", "6153", "6150", "6485", "2981", "2983", "6152", "6141", "1218", "2922", "6089", "2908", "2910", "3930", "580", "1606", "340", "346", "679", "1665", "1678", "1590", "1579", "1587", "1613", "415", "2939", "3964", "2991", "2923", "737", "609", "4353", "2965", "1688", "2968", "2912", "1701", "2952", "4375", "4374", "2960", "4118", "1197", "2962", "1354", "2958", "2913", "2973", "2964", "2942", "2985", "2975", "337", "1056", "4111", "2992", "2988", "8774", "1743", "1584", "1760", "4352", "6156", "3959"]
}, {
    "City": "Odessa",
    "DBTZ": "E",
    "Name": "Odesa Intl",
    "Country": "Ukraine",
    "IATA/FAA": "ODS",
    "Longitude": "30.676464",
    "ICAO": "UKOO",
    "Airport ID": "2947",
    "Latitude": "46.426767",
    "Timezone": "172",
    "DST": "2",
    "destinations": ["1701", "8076", "2191", "346", "679", "1613", "4029", "2939", "1197", "1590", "2985", "2988"]
}, {
    "City": "Lvov",
    "DBTZ": "E",
    "Name": "Lviv Intl",
    "Country": "Ukraine",
    "IATA/FAA": "LWO",
    "Longitude": "23.956111",
    "ICAO": "UKLL",
    "Airport ID": "2945",
    "Latitude": "49.8125",
    "Timezone": "1071",
    "DST": "2",
    "destinations": ["346", "679", "1613", "4317", "2939", "1197", "2988", "1701", "1525", "373", "1555", "1222", "1561", "1590", "1539", "1246"]
}, {
    "City": "Cox's Bazar",
    "DBTZ": "U",
    "Name": "Coxs Bazar",
    "Country": "Bangladesh",
    "IATA/FAA": "CXB",
    "Longitude": "91.963889",
    "ICAO": "VGCB",
    "Airport ID": "3068",
    "Latitude": "21.452194",
    "Timezone": "12",
    "DST": "6",
    "destinations": ["3076"]
}, {
    "City": "Kiana",
    "DBTZ": "A",
    "Name": "Bob Baker Memorial Airport",
    "Country": "United States",
    "IATA/FAA": "IAN",
    "Longitude": "-160.436667",
    "ICAO": "PAIK",
    "Airport ID": "7179",
    "Latitude": "66.975833",
    "Timezone": "166",
    "DST": "-9",
    "destinations": ["7181", "3693"]
}, {
    "City": "Simferopol",
    "DBTZ": "E",
    "Name": "Simferopol Intl",
    "Country": "Ukraine",
    "IATA/FAA": "SIP",
    "Longitude": "33.975139",
    "ICAO": "UKFF",
    "Airport ID": "2942",
    "Latitude": "45.052222",
    "Timezone": "639",
    "DST": "2",
    "destinations": ["4029", "2948", "2964", "2985", "2939"]
}, {
    "City": "Dnepropetrovsk",
    "DBTZ": "E",
    "Name": "Dnipropetrovsk Intl",
    "Country": "Ukraine",
    "IATA/FAA": "DNK",
    "Longitude": "35.100556",
    "ICAO": "UKDD",
    "Airport ID": "2941",
    "Latitude": "48.357222",
    "Timezone": "481",
    "DST": "2",
    "destinations": ["1613", "1701", "2939", "1590", "2985", "4029", "3971", "3964", "3973"]
}, {
    "City": "Donetsk",
    "DBTZ": "E",
    "Name": "Donetsk Intl",
    "Country": "Ukraine",
    "IATA/FAA": "DOK",
    "Longitude": "37.739722",
    "ICAO": "UKCC",
    "Airport ID": "2940",
    "Latitude": "48.073611",
    "Timezone": "791",
    "DST": "2",
    "destinations": ["1486", "8076", "2191", "346", "4317", "1701", "2939", "1197", "1590", "2985", "4029", "3972"]
}, {
    "City": "Banjul",
    "DBTZ": "N",
    "Name": "Banjul Intl",
    "Country": "Gambia",
    "IATA/FAA": "BJL",
    "Longitude": "-16.652206",
    "ICAO": "GBYD",
    "Airport ID": "1050",
    "Latitude": "13.337961",
    "Timezone": "95",
    "DST": "0",
    "destinations": ["1218", "1084", "1059", "1074", "5665", "5674", "1054"]
}, {
    "City": "Fuerteventura",
    "DBTZ": "E",
    "Name": "Fuerteventura",
    "Country": "Spain",
    "IATA/FAA": "FUE",
    "Longitude": "-13.863761",
    "ICAO": "GCFV",
    "Airport ID": "1051",
    "Latitude": "28.452717",
    "Timezone": "83",
    "DST": "0",
    "destinations": ["344", "345", "342", "348", "346", "347", "1612", "351", "1613", "1678", "340", "352", "478", "371", "350", "337", "502", "599", "1218", "1525", "469", "494", "353", "304", "535", "523", "517", "491", "492", "1229", "4198", "596", "537", "1562", "603", "548", "302", "1056", "1055", "580", "1216", "1555", "1524", "1550", "629", "534", "1054", "1057", "339", "490", "4053", "1418", "6510"]
}, {
    "City": "Hierro",
    "DBTZ": "E",
    "Name": "Hierro",
    "Country": "Spain",
    "IATA/FAA": "VDE",
    "Longitude": "-17.887056",
    "ICAO": "GCHI",
    "Airport ID": "1052",
    "Latitude": "27.814847",
    "Timezone": "103",
    "DST": "0",
    "destinations": ["1054", "1057", "1056"]
}, {
    "City": "Santa Cruz De La Palma",
    "DBTZ": "E",
    "Name": "La Palma",
    "Country": "Spain",
    "IATA/FAA": "SPC",
    "Longitude": "-17.755611",
    "ICAO": "GCLA",
    "Airport ID": "1053",
    "Latitude": "28.626478",
    "Timezone": "107",
    "DST": "0",
    "destinations": ["345", "340", "346", "580", "1229", "1054", "1057"]
}, {
    "City": "Gran Canaria",
    "DBTZ": "E",
    "Name": "Gran Canaria",
    "Country": "Spain",
    "IATA/FAA": "LPA",
    "Longitude": "-15.386586",
    "ICAO": "GCLP",
    "Airport ID": "1054",
    "Latitude": "27.931886",
    "Timezone": "78",
    "DST": "0",
    "destinations": ["1678", "344", "1229", "4053", "345", "342", "346", "347", "1612", "351", "1613", "1074", "1057", "340", "348", "371", "350", "337", "737", "636", "609", "502", "644", "664", "599", "1218", "1525", "469", "494", "353", "490", "304", "535", "585", "523", "4166", "491", "492", "478", "4198", "1636", "596", "537", "1562", "1243", "1251", "548", "1253", "1246", "302", "1055", "580", "1230", "1212", "1216", "1084", "1094", "1386", "1097", "629", "534", "517", "521", "1064", "1050", "5672", "4091", "1051", "1638", "5674", "1075", "1053", "1056", "1052", "5670", "1587", "1382", "339", "352"]
}, {
    "City": "Las Palmas",
    "DBTZ": "E",
    "Name": "Lanzarote",
    "Country": "Spain",
    "IATA/FAA": "ACE",
    "Longitude": "-13.605225",
    "ICAO": "GCRR",
    "Airport ID": "1055",
    "Latitude": "28.945464",
    "Timezone": "47",
    "DST": "0",
    "destinations": ["344", "345", "1051", "342", "348", "346", "351", "1678", "502", "469", "340", "534", "352", "478", "371", "350", "337", "599", "596", "1218", "1525", "1538", "494", "490", "1367", "304", "535", "585", "523", "4166", "355", "517", "491", "492", "1229", "600", "4198", "537", "1243", "1251", "603", "548", "1253", "1252", "580", "1216", "629", "465", "514", "521", "1054", "1057", "353", "1214"]
}, {
    "City": "Tenerife",
    "DBTZ": "E",
    "Name": "Tenerife Sur",
    "Country": "Spain",
    "IATA/FAA": "TFS",
    "Longitude": "-16.572489",
    "ICAO": "GCTS",
    "Airport ID": "1056",
    "Latitude": "28.044475",
    "Timezone": "209",
    "DST": "0",
    "destinations": ["1665", "3953", "4053", "344", "338", "345", "1609", "342", "348", "346", "347", "1612", "351", "1613", "1678", "502", "340", "371", "350", "337", "644", "599", "1218", "1525", "469", "608", "1538", "494", "353", "490", "1367", "304", "535", "523", "4166", "3986", "355", "517", "491", "492", "1229", "478", "582", "600", "4198", "1636", "596", "537", "1562", "1243", "1251", "603", "548", "1253", "1246", "302", "1399", "580", "1054", "1386", "1250", "1555", "1524", "629", "465", "514", "534", "521", "1052", "2939", "1587", "339", "2948", "2985", "488", "1382", "4029", "1212", "1214", "1264", "1418", "352", "6510"]
}, {
    "City": "Tenerife",
    "DBTZ": "E",
    "Name": "Tenerife Norte",
    "Country": "Spain",
    "IATA/FAA": "TFN",
    "Longitude": "-16.341536",
    "ICAO": "GCXO",
    "Airport ID": "1057",
    "Latitude": "28.482653",
    "Timezone": "2073",
    "DST": "0",
    "destinations": ["1074", "1229", "1218", "1230", "1216", "1386", "1243", "1253", "1246", "1055", "1084", "1051", "5659", "1054", "1053", "1052", "2851"]
}, {
    "City": "Melilla",
    "DBTZ": "E",
    "Name": "Melilla",
    "Country": "Spain",
    "IATA/FAA": "MLN",
    "Longitude": "-2.956256",
    "ICAO": "GEML",
    "Airport ID": "1058",
    "Latitude": "35.279817",
    "Timezone": "156",
    "DST": "1",
    "destinations": ["1230", "1223", "1213", "1229"]
}, {
    "City": "Freetown",
    "DBTZ": "N",
    "Name": "Freetown Lungi",
    "Country": "Sierra Leone",
    "IATA/FAA": "FNA",
    "Longitude": "-13.195489",
    "ICAO": "GFLL",
    "Airport ID": "1059",
    "Latitude": "8.616444",
    "Timezone": "84",
    "DST": "0",
    "destinations": ["1050", "1084", "502", "1063", "1382", "1074", "507", "253", "248", "1062", "273"]
}, {
    "City": "Pavlodar",
    "DBTZ": "U",
    "Name": "Pavlodar",
    "Country": "Kazakhstan",
    "IATA/FAA": "PWQ",
    "Longitude": "77.073889",
    "ICAO": "UASP",
    "Airport ID": "2917",
    "Latitude": "52.195",
    "Timezone": "410",
    "DST": "6",
    "destinations": ["2908", "2910", "4029"]
}, {
    "City": "Kayseri",
    "DBTZ": "E",
    "Name": "Erkilet",
    "Country": "Turkey",
    "IATA/FAA": "ASR",
    "Longitude": "35.495428",
    "ICAO": "LTAU",
    "Airport ID": "1696",
    "Latitude": "38.770386",
    "Timezone": "3463",
    "DST": "2",
    "destinations": ["1706", "345", "4317", "1701", "580"]
}, {
    "City": "Semiplatinsk",
    "DBTZ": "U",
    "Name": "Semipalatinsk",
    "Country": "Kazakhstan",
    "IATA/FAA": "PLX",
    "Longitude": "80.2344",
    "ICAO": "UASS",
    "Airport ID": "2918",
    "Latitude": "50.3513",
    "Timezone": "761",
    "DST": "6",
    "destinations": ["2908", "2910", "6086"]
}, {
    "City": "Sivas",
    "DBTZ": "E",
    "Name": "Sivas",
    "Country": "Turkey",
    "IATA/FAA": "VAS",
    "Longitude": "36.903497",
    "ICAO": "LTAR",
    "Airport ID": "1694",
    "Latitude": "39.813828",
    "Timezone": "5236",
    "DST": "2",
    "destinations": ["1706", "4317", "1701"]
}, {
    "City": "Malatya",
    "DBTZ": "E",
    "Name": "Erhac",
    "Country": "Turkey",
    "IATA/FAA": "MLX",
    "Longitude": "38.091006",
    "ICAO": "LTAT",
    "Airport ID": "1695",
    "Latitude": "38.435347",
    "Timezone": "2828",
    "DST": "2",
    "destinations": ["1701", "4317", "1682"]
}, {
    "City": "Merzifon",
    "DBTZ": "E",
    "Name": "Merzifon",
    "Country": "Turkey",
    "IATA/FAA": "MZH",
    "Longitude": "35.521992",
    "ICAO": "LTAP",
    "Airport ID": "1693",
    "Latitude": "40.829375",
    "Timezone": "1758",
    "DST": "2",
    "destinations": ["4317", "1701"]
}, {
    "City": "Konya",
    "DBTZ": "E",
    "Name": "Konya",
    "Country": "Turkey",
    "IATA/FAA": "KYA",
    "Longitude": "32.561861",
    "ICAO": "LTAN",
    "Airport ID": "1691",
    "Latitude": "37.979",
    "Timezone": "3381",
    "DST": "2",
    "destinations": ["4317", "1701"]
}, {
    "City": "Denizli",
    "DBTZ": "E",
    "Name": "Cardak",
    "Country": "Turkey",
    "IATA/FAA": "DNZ",
    "Longitude": "29.701297",
    "ICAO": "LTAY",
    "Airport ID": "1699",
    "Latitude": "37.785567",
    "Timezone": "2795",
    "DST": "2",
    "destinations": ["4317", "1701"]
}, {
    "City": "Brive",
    "DBTZ": "E",
    "Name": "La Roche",
    "Country": "France",
    "IATA/FAA": "BVE",
    "Longitude": "1.469167",
    "ICAO": "LFSL",
    "Airport ID": "1278",
    "Latitude": "45.150833",
    "Timezone": "379",
    "DST": "1",
    "destinations": ["1324", "1386", "503", "548"]
}, {
    "City": "Perigueux",
    "DBTZ": "E",
    "Name": "Bassillac",
    "Country": "France",
    "IATA/FAA": "PGX",
    "Longitude": "0.815556",
    "ICAO": "LFBX",
    "Airport ID": "1279",
    "Latitude": "45.198055",
    "Timezone": "328",
    "DST": "1",
    "destinations": ["1265", "1386"]
}, {
    "City": "Copiapo",
    "DBTZ": "S",
    "Name": "Copiapo",
    "Country": "Chile",
    "IATA/FAA": "CPO",
    "Longitude": "-70",
    "ICAO": "SCHA",
    "Airport ID": "4312",
    "Latitude": "-27",
    "Timezone": "1000",
    "DST": "-4",
    "destinations": ["2645", "2662", "2650"]
}, {
    "City": "Soenderborg",
    "DBTZ": "E",
    "Name": "Sonderborg",
    "Country": "Denmark",
    "IATA/FAA": "SGD",
    "Longitude": "9.791731",
    "ICAO": "EKSB",
    "Airport ID": "619",
    "Latitude": "54.964367",
    "Timezone": "24",
    "DST": "1",
    "destinations": ["609"]
}, {
    "City": "Little Cayman",
    "DBTZ": "U",
    "Name": "Edward Bodden Airfield",
    "Country": "Cayman Islands",
    "IATA/FAA": "LYB",
    "Longitude": "-80.09083333",
    "ICAO": "MWCL",
    "Airport ID": "4314",
    "Latitude": "19.6591666667",
    "Timezone": "1",
    "DST": "-5",
    "destinations": ["1925", "1926"]
}, {
    "City": "Bodrum",
    "DBTZ": "E",
    "Name": "Bodrum - Milas",
    "Country": "Turkey",
    "IATA/FAA": "BJV",
    "Longitude": "27.667",
    "ICAO": "LTFE",
    "Airport ID": "4315",
    "Latitude": "37.249",
    "Timezone": "19",
    "DST": "2",
    "destinations": ["1701", "1706", "580", "585", "591", "523", "534", "517", "478", "521", "302", "1682", "4317", "502", "1386", "490", "535", "491", "548", "469", "492"]
}, {
    "City": "Istanbul",
    "DBTZ": "E",
    "Name": "Sabiha Gokcen",
    "Country": "Turkey",
    "IATA/FAA": "SAW",
    "Longitude": "29.309219",
    "ICAO": "LTFJ",
    "Airport ID": "4317",
    "Latitude": "40.898553",
    "Timezone": "312",
    "DST": "2",
    "destinations": ["1074", "344", "373", "352", "342", "350", "351", "2962", "345", "346", "347", "337", "3953", "2188", "2191", "580", "585", "591", "2922", "2176", "1739", "1606", "1685", "1706", "2908", "737", "1696", "3941", "1688", "1729", "1218", "2177", "1525", "4315", "1538", "4053", "609", "304", "1722", "1715", "4029", "1699", "2241", "2940", "1350", "5780", "5802", "1723", "1724", "1682", "1721", "1555", "340", "2912", "9044", "8266", "1689", "6105", "7453", "4330", "5798", "2960", "1691", "2945", "1229", "1695", "6753", "1353", "6782", "1693", "3987", "2958", "1386", "1657", "1742", "1646", "1737", "548", "3973", "1190", "1590", "1726", "1728", "1694", "1613", "1678", "2939", "2966", "302", "1382", "5796", "502", "1524", "2963", "1489", "2072", "2074", "2082"]
}, {
    "City": "Karup",
    "DBTZ": "E",
    "Name": "Karup",
    "Country": "Denmark",
    "IATA/FAA": "KRP",
    "Longitude": "9.124628",
    "ICAO": "EKKA",
    "Airport ID": "612",
    "Latitude": "56.297458",
    "Timezone": "170",
    "DST": "1",
    "destinations": ["609"]
}, {
    "City": "Broome",
    "DBTZ": "O",
    "Name": "Broome",
    "Country": "Australia",
    "IATA/FAA": "BME",
    "Longitude": "122.2",
    "ICAO": "YPBR",
    "Airport ID": "4319",
    "Latitude": "-17.8",
    "Timezone": "56",
    "DST": "8",
    "destinations": ["3320", "3999", "3347", "3345", "3339", "3351", "3361", "3349"]
}, {
    "City": "Esbjerg",
    "DBTZ": "E",
    "Name": "Esbjerg",
    "Country": "Denmark",
    "IATA/FAA": "EBJ",
    "Longitude": "8.553403",
    "ICAO": "EKEB",
    "Airport ID": "610",
    "Latitude": "55.525942",
    "Timezone": "97",
    "DST": "1",
    "destinations": ["532", "608"]
}, {
    "City": "Toulouse",
    "DBTZ": "E",
    "Name": "Blagnac",
    "Country": "France",
    "IATA/FAA": "TLS",
    "Longitude": "1.363819",
    "ICAO": "LFBO",
    "Airport ID": "1273",
    "Latitude": "43.629075",
    "Timezone": "499",
    "DST": "1",
    "destinations": ["1074", "342", "1399", "1353", "1354", "1418", "1415", "507", "1324", "580", "3941", "1382", "1555", "1335", "1606", "1386", "1075", "1253", "1435", "1551", "210", "231", "353", "599", "340", "1665", "1230", "1218", "1229", "3998", "346", "478", "302", "490", "519", "362", "1425", "1320", "1524", "1701", "1638", "146", "287", "1321", "4053", "502"]
}, {
    "City": "Pau",
    "DBTZ": "E",
    "Name": "Pau Pyrenees",
    "Country": "France",
    "IATA/FAA": "PUF",
    "Longitude": "-0.418611",
    "ICAO": "LFBP",
    "Airport ID": "1274",
    "Latitude": "43.38",
    "Timezone": "616",
    "DST": "1",
    "destinations": ["1335", "1382", "1386", "1353"]
}, {
    "City": "Tarbes",
    "DBTZ": "E",
    "Name": "Lourdes",
    "Country": "France",
    "IATA/FAA": "LDE",
    "Longitude": "-0.006439",
    "ICAO": "LFBT",
    "Airport ID": "1276",
    "Latitude": "43.178675",
    "Timezone": "1260",
    "DST": "1",
    "destinations": ["1386", "1525", "548", "302", "1229"]
}, {
    "City": "Angouleme",
    "DBTZ": "E",
    "Name": "Brie Champniers",
    "Country": "France",
    "IATA/FAA": "ANG",
    "Longitude": "0.221456",
    "ICAO": "LFBU",
    "Airport ID": "1277",
    "Latitude": "45.729247",
    "Timezone": "436",
    "DST": "1",
    "destinations": ["1323"]
}, {
    "City": "Vaxjo",
    "DBTZ": "E",
    "Name": "Kronoberg",
    "Country": "Sweden",
    "IATA/FAA": "VXO",
    "Longitude": "14.727994",
    "ICAO": "ESMX",
    "Airport ID": "711",
    "Latitude": "56.929144",
    "Timezone": "610",
    "DST": "1",
    "destinations": ["737", "644", "738", "1212", "580"]
}, {
    "City": "Bointo",
    "DBTZ": "S",
    "Name": "Bonito Airport",
    "Country": "Brazil",
    "IATA/FAA": "BYO",
    "Longitude": "-56.4525",
    "ICAO": "SJDB",
    "Airport ID": "8242",
    "Latitude": "-21.247222",
    "Timezone": "1180",
    "DST": "-4",
    "destinations": ["2578"]
}, {
    "City": "Sao Felix do Araguaia",
    "DBTZ": "S",
    "Name": "Sao Felix do Araguaia Airport",
    "Country": "Brazil",
    "IATA/FAA": "SXO",
    "Longitude": "-50.689444",
    "ICAO": "SWFX",
    "Airport ID": "8243",
    "Latitude": "-11.6325",
    "Timezone": "650",
    "DST": "-4",
    "destinations": ["7368"]
}, {
    "City": "Redencao",
    "DBTZ": "S",
    "Name": "Redencao Airport",
    "Country": "Brazil",
    "IATA/FAA": "RDC",
    "Longitude": "-49.98",
    "ICAO": "SNDC",
    "Airport ID": "8240",
    "Latitude": "-8.033333",
    "Timezone": "669",
    "DST": "-4",
    "destinations": ["2518"]
}, {
    "City": "Sao Felix do Xingu",
    "DBTZ": "S",
    "Name": "Sao Felix do Xingu Airport",
    "Country": "Brazil",
    "IATA/FAA": "SXX",
    "Longitude": "-51.952222",
    "ICAO": "SNFX",
    "Airport ID": "8241",
    "Latitude": "-6.641389",
    "Timezone": "656",
    "DST": "-4",
    "destinations": ["8239"]
}, {
    "City": "Eirunepe",
    "DBTZ": "S",
    "Name": "Amaury Feitosa Tomaz Airport",
    "Country": "Brazil",
    "IATA/FAA": "ERN",
    "Longitude": "-69.879722",
    "ICAO": "SWEI",
    "Airport ID": "8247",
    "Latitude": "-6.639444",
    "Timezone": "412",
    "DST": "-4",
    "destinations": ["2624"]
}, {
    "City": "Cacador",
    "DBTZ": "S",
    "Name": "Carlos Alberto da Costa Neves Airport",
    "Country": "Brazil",
    "IATA/FAA": "CFC",
    "Longitude": "-50.939722",
    "ICAO": "SBCD",
    "Airport ID": "8244",
    "Latitude": "-26.788333",
    "Timezone": "3376",
    "DST": "-3",
    "destinations": ["2545", "7384"]
}, {
    "City": "Rigolet",
    "DBTZ": "A",
    "Name": "Rigolet Airport",
    "Country": "Canada",
    "IATA/FAA": "YRG",
    "Longitude": "-58.4575",
    "ICAO": "CCZ2",
    "Airport ID": "5461",
    "Latitude": "54.1797",
    "Timezone": "180",
    "DST": "-4",
    "destinations": ["5492", "188"]
}, {
    "City": "Girua",
    "DBTZ": "U",
    "Name": "Girua Airport",
    "Country": "Papua New Guinea",
    "IATA/FAA": "PNP",
    "Longitude": "148.309",
    "ICAO": "AYGR",
    "Airport ID": "5423",
    "Latitude": "-8.80454",
    "Timezone": "311",
    "DST": "10",
    "destinations": ["5422", "4", "5", "5984"]
}, {
    "City": "Savannakhet",
    "DBTZ": "U",
    "Name": "Savannakhet",
    "Country": "Laos",
    "IATA/FAA": "ZVK",
    "Longitude": "104.759531",
    "ICAO": "VLSK",
    "Airport ID": "3118",
    "Latitude": "16.556594",
    "Timezone": "509",
    "DST": "7",
    "destinations": ["3885", "3116", "3120"]
}, {
    "City": "Pakse",
    "DBTZ": "U",
    "Name": "Pakse",
    "Country": "Laos",
    "IATA/FAA": "PKZ",
    "Longitude": "105.781417",
    "ICAO": "VLPS",
    "Airport ID": "3116",
    "Latitude": "15.132053",
    "Timezone": "351",
    "DST": "7",
    "destinations": ["3115", "3035", "3205", "3120", "3118"]
}, {
    "City": "Luang Prabang",
    "DBTZ": "U",
    "Name": "Luang Phabang Intl",
    "Country": "Laos",
    "IATA/FAA": "LPQ",
    "Longitude": "102.160764",
    "ICAO": "VLLB",
    "Airport ID": "3115",
    "Latitude": "19.897914",
    "Timezone": "955",
    "DST": "7",
    "destinations": ["3885", "3120", "3931", "3199", "4055", "3116", "3035"]
}, {
    "City": "Srinagar",
    "DBTZ": "N",
    "Name": "Srinagar",
    "Country": "India",
    "IATA/FAA": "SXR",
    "Longitude": "74.77425",
    "ICAO": "VISR",
    "Airport ID": "3112",
    "Latitude": "33.987139",
    "Timezone": "5429",
    "DST": "5.5",
    "destinations": ["2997", "3093", "3100", "3104", "3081", "3089"]
}, {
    "City": "Rhodos",
    "DBTZ": "E",
    "Name": "Rhodes Diagoras",
    "Country": "Greece",
    "IATA/FAA": "RHO",
    "Longitude": "28.086192",
    "ICAO": "LGRP",
    "Airport ID": "1472",
    "Latitude": "36.405419",
    "Timezone": "17",
    "DST": "2",
    "destinations": ["344", "1459", "3941", "1382", "4029", "1452", "1458", "4196", "2948", "1486", "338", "345", "346", "347", "351", "1613", "340", "352", "342", "348", "350", "337", "1525", "494", "304", "523", "3956", "491", "699", "657", "548", "1450", "1476", "302", "309", "580", "585", "629", "534", "517", "478", "521", "1587", "2939", "502", "2985", "4053"]
}, {
    "City": "Patras",
    "DBTZ": "E",
    "Name": "Araxos",
    "Country": "Greece",
    "IATA/FAA": "GPA",
    "Longitude": "21.425556",
    "ICAO": "LGRX",
    "Airport ID": "1473",
    "Latitude": "38.151111",
    "Timezone": "46",
    "DST": "2",
    "destinations": ["1198", "345", "340"]
}, {
    "City": "Preveza",
    "DBTZ": "E",
    "Name": "Aktio",
    "Country": "Greece",
    "IATA/FAA": "PVK",
    "Longitude": "20.765311",
    "ICAO": "LGPZ",
    "Airport ID": "1470",
    "Latitude": "38.925467",
    "Timezone": "11",
    "DST": "2",
    "destinations": ["345", "346", "1613", "1460", "1455", "1480", "580", "502", "478"]
}, {
    "City": "Samos",
    "DBTZ": "E",
    "Name": "Samos",
    "Country": "Greece",
    "IATA/FAA": "SMI",
    "Longitude": "26.911667",
    "ICAO": "LGSM",
    "Airport ID": "1476",
    "Latitude": "37.689999",
    "Timezone": "19",
    "DST": "2",
    "destinations": ["1486", "3941", "345", "346", "347", "351", "1472"]
}, {
    "City": "Chania",
    "DBTZ": "E",
    "Name": "Souda",
    "Country": "Greece",
    "IATA/FAA": "CHQ",
    "Longitude": "24.149678",
    "ICAO": "LGSA",
    "Airport ID": "1474",
    "Latitude": "35.531747",
    "Timezone": "490",
    "DST": "2",
    "destinations": ["3941", "1486", "1613", "345", "737", "636", "609", "421", "644", "1525", "608", "494", "353", "490", "1553", "304", "599", "585", "523", "3986", "355", "671", "517", "1353", "4198", "699", "1198", "657", "548", "1539", "3959", "8414", "680", "302", "580", "1488", "502"]
}, {
    "City": "Yanji",
    "DBTZ": "U",
    "Name": "Yanji Airport",
    "Country": "China",
    "IATA/FAA": "YNJ",
    "Longitude": "129.451258",
    "ICAO": "ZYYJ",
    "Airport ID": "6414",
    "Latitude": "42.8828",
    "Timezone": "624",
    "DST": "8",
    "destinations": ["3930", "3364", "3390", "4380", "3404", "3406"]
}, {
    "City": "Launceston",
    "DBTZ": "O",
    "Name": "Launceston",
    "Country": "Australia",
    "IATA/FAA": "LST",
    "Longitude": "147.214167",
    "ICAO": "YMLT",
    "Airport ID": "3337",
    "Latitude": "-41.545278",
    "Timezone": "562",
    "DST": "10",
    "destinations": ["3320", "3339", "3361", "6338", "6267"]
}, {
    "City": "Ambler",
    "DBTZ": "A",
    "Name": "Ambler Airport",
    "Country": "United States",
    "IATA/FAA": "ABL",
    "Longitude": "-157.8575",
    "ICAO": "PAFM",
    "Airport ID": "7177",
    "Latitude": "67.106389",
    "Timezone": "334",
    "DST": "-9",
    "destinations": ["3693", "7117"]
}, {
    "City": "Summer Beaver",
    "DBTZ": "A",
    "Name": "Summer Beaver Airport",
    "Country": "Canada",
    "IATA/FAA": "SUR",
    "Longitude": "-88.5419",
    "ICAO": "CJV7",
    "Airport ID": "5464",
    "Latitude": "52.7086",
    "Timezone": "832",
    "DST": "-5",
    "destinations": ["169"]
}, {
    "City": "Jiamusi",
    "DBTZ": "U",
    "Name": "Jiamusi Airport",
    "Country": "China",
    "IATA/FAA": "JMU",
    "Longitude": "130.465389",
    "ICAO": "ZYJM",
    "Airport ID": "6411",
    "Latitude": "46.843394",
    "Timezone": "262",
    "DST": "8",
    "destinations": ["3400", "3364", "3406", "3390", "4144", "3404", "2927"]
}, {
    "City": "Melbourne",
    "DBTZ": "O",
    "Name": "Melbourne Essendon",
    "Country": "Australia",
    "IATA/FAA": "MEB",
    "Longitude": "144.901944",
    "ICAO": "YMEN",
    "Airport ID": "3334",
    "Latitude": "-37.728056",
    "Timezone": "282",
    "DST": "10",
    "destinations": ["6267"]
}, {
    "City": "Dole",
    "DBTZ": "E",
    "Name": "Tavaux",
    "Country": "France",
    "IATA/FAA": "DLE",
    "Longitude": "5.42725",
    "ICAO": "LFGJ",
    "Airport ID": "1309",
    "Latitude": "47.039014",
    "Timezone": "645",
    "DST": "1",
    "destinations": ["1638", "1636", "1075"]
}, {
    "City": "Beloyarsky",
    "DBTZ": "E",
    "Name": "Beloyarsky",
    "Country": "Russia",
    "IATA/FAA": "EYK",
    "Longitude": "66.683056",
    "ICAO": "USHQ",
    "Airport ID": "8428",
    "Latitude": "63.683056",
    "Timezone": "25",
    "DST": "6",
    "destinations": ["2988", "6139", "6140", "2975"]
}, {
    "City": "Heihe",
    "DBTZ": "U",
    "Name": "Heihe Airport",
    "Country": "China",
    "IATA/FAA": "HEK",
    "Longitude": "127.3",
    "ICAO": "ZYHE",
    "Airport ID": "6410",
    "Latitude": "50.25",
    "Timezone": "8530",
    "DST": "8",
    "destinations": ["3400", "6943"]
}, {
    "City": "Grand Island",
    "DBTZ": "A",
    "Name": "Central Nebraska Regional Airport",
    "Country": "United States",
    "IATA/FAA": "GRI",
    "Longitude": "-98.3096",
    "ICAO": "KGRI",
    "Airport ID": "5740",
    "Latitude": "40.9675",
    "Timezone": "1847",
    "DST": "-6",
    "destinations": ["3670", "6505", "3877"]
}, {
    "City": "Hot Springs",
    "DBTZ": "A",
    "Name": "Memorial Field",
    "Country": "United States",
    "IATA/FAA": "HOT",
    "Longitude": "-93.0962",
    "ICAO": "KHOT",
    "Airport ID": "5741",
    "Latitude": "34.478",
    "Timezone": "540",
    "DST": "-6",
    "destinations": ["3696", "3473"]
}, {
    "City": "Huntington",
    "DBTZ": "A",
    "Name": "Tri State Milton J Ferguson Field",
    "Country": "United States",
    "IATA/FAA": "HTS",
    "Longitude": "-82.558",
    "ICAO": "KHTS",
    "Airport ID": "5742",
    "Latitude": "38.3667",
    "Timezone": "828",
    "DST": "-5",
    "destinations": ["3876", "3515", "3617", "4167"]
}, {
    "City": "Guernsey",
    "DBTZ": "E",
    "Name": "Guernsey",
    "Country": "Guernsey",
    "IATA/FAA": "GCI",
    "Longitude": "-2.601969",
    "ICAO": "EGJB",
    "Airport ID": "498",
    "Latitude": "49.434956",
    "Timezone": "336",
    "DST": "0",
    "destinations": ["345", "499", "469", "552", "547", "495", "497", "490", "1405", "523", "502", "478", "548", "580"]
}, {
    "City": "Jersey",
    "DBTZ": "E",
    "Name": "Jersey",
    "Country": "Jersey",
    "IATA/FAA": "JER",
    "Longitude": "-2.195508",
    "ICAO": "EGJJ",
    "Airport ID": "499",
    "Latitude": "49.207947",
    "Timezone": "277",
    "DST": "0",
    "destinations": ["345", "340", "352", "350", "488", "502", "469", "5562", "535", "523", "552", "498", "534", "515", "478", "547", "495", "599", "596", "548", "517", "580", "490", "1382", "1665", "503", "1678", "491", "521", "508"]
}, {
    "City": "Laramie",
    "DBTZ": "A",
    "Name": "Laramie Regional Airport",
    "Country": "United States",
    "IATA/FAA": "LAR",
    "Longitude": "-105.675",
    "ICAO": "KLAR",
    "Airport ID": "5746",
    "Latitude": "41.3121",
    "Timezone": "7284",
    "DST": "-7",
    "destinations": ["3751"]
}, {
    "City": "Latrobe",
    "DBTZ": "A",
    "Name": "Arnold Palmer Regional Airport",
    "Country": "United States",
    "IATA/FAA": "LBE",
    "Longitude": "-79.4048",
    "ICAO": "KLBE",
    "Airport ID": "5747",
    "Latitude": "40.2759",
    "Timezone": "1185",
    "DST": "-5",
    "destinations": ["3533", "3878", "3515"]
}, {
    "City": "North Platte",
    "DBTZ": "A",
    "Name": "North Platte Regional Airport Lee Bird Field",
    "Country": "United States",
    "IATA/FAA": "LBF",
    "Longitude": "-100.684",
    "ICAO": "KLBF",
    "Airport ID": "5748",
    "Latitude": "41.1262",
    "Timezone": "2776",
    "DST": "-6",
    "destinations": ["3751"]
}, {
    "City": "Southampton",
    "DBTZ": "E",
    "Name": "Southampton",
    "Country": "United Kingdom",
    "IATA/FAA": "SOU",
    "Longitude": "-1.356803",
    "ICAO": "EGHI",
    "Airport ID": "495",
    "Latitude": "50.950261",
    "Timezone": "44",
    "DST": "0",
    "destinations": ["1403", "1265", "1270", "6493", "1354", "1418", "1386", "1415", "1230", "1212", "580", "467", "599", "535", "1626", "498", "534", "499", "478", "521", "3998", "497", "517"]
}, {
    "City": "Alderney",
    "DBTZ": "E",
    "Name": "Alderney",
    "Country": "Guernsey",
    "IATA/FAA": "ACI",
    "Longitude": "-2.214722",
    "ICAO": "EGJA",
    "Airport ID": "497",
    "Latitude": "49.706111",
    "Timezone": "290",
    "DST": "0",
    "destinations": ["498", "495"]
}, {
    "City": "Bristol",
    "DBTZ": "E",
    "Name": "Bristol",
    "Country": "United Kingdom",
    "IATA/FAA": "BRS",
    "Longitude": "-2.719089",
    "ICAO": "EGGD",
    "Airport ID": "490",
    "Latitude": "51.382669",
    "Timezone": "622",
    "DST": "0",
    "destinations": ["1678", "580", "532", "340", "342", "346", "1524", "599", "596", "603", "1055", "1230", "1212", "1525", "1538", "1489", "1360", "1474", "1265", "1626", "668", "1222", "1225", "3956", "1270", "1054", "1606", "600", "3998", "674", "1236", "675", "1056", "1539", "1246", "8414", "680", "498", "499", "302", "519", "1273", "1688", "1715", "7447", "1460", "1051", "1231", "1198", "1218", "465", "4315", "1264", "1382", "609", "535", "1555", "4091", "534", "1665", "1452", "533", "16", "669", "1638", "1229", "1227", "1354", "521", "1587", "1562", "1075", "337"]
}, {
    "City": "Liverpool",
    "DBTZ": "E",
    "Name": "Liverpool",
    "Country": "United Kingdom",
    "IATA/FAA": "LPL",
    "Longitude": "-2.849722",
    "ICAO": "EGGP",
    "Airport ID": "491",
    "Latitude": "53.333611",
    "Timezone": "80",
    "DST": "0",
    "destinations": ["520", "1055", "1230", "1212", "1218", "1745", "1352", "599", "1265", "1626", "1439", "1051", "1225", "1458", "669", "468", "1270", "1054", "1606", "600", "1636", "596", "3998", "674", "1562", "1236", "1472", "603", "676", "1056", "664", "3959", "8414", "680", "580", "465", "4315", "1382", "1665", "499", "1197", "1638", "1229", "1561", "1354", "337", "668", "679"]
}, {
    "City": "London",
    "DBTZ": "E",
    "Name": "Luton",
    "Country": "United Kingdom",
    "IATA/FAA": "LTN",
    "Longitude": "-0.368333",
    "ICAO": "EGGW",
    "Airport ID": "492",
    "Latitude": "51.874722",
    "Timezone": "526",
    "DST": "0",
    "destinations": ["1055", "1517", "1745", "1360", "599", "1439", "1051", "1222", "601", "3956", "1054", "1227", "1606", "600", "1075", "1236", "675", "1056", "1701", "1590", "1655", "1230", "1688", "1715", "1626", "1225", "1197", "1198", "3998", "4057", "532", "1212", "580", "1218", "465", "1264", "1489", "1382", "1460", "1509", "373", "535", "534", "1665", "342", "1452", "533", "16", "1638", "1229", "1359", "1524", "1354", "1562", "337", "1551", "1678", "1739", "1191", "1652", "1654", "1490", "668", "2944", "1746", "671", "1569", "8832", "1657", "674", "1587", "3953", "1737", "1194", "1662", "1663", "1196", "3959", "679", "680", "4315", "1555", "1738", "1231", "1561"]
}, {
    "City": "Macapa",
    "DBTZ": "S",
    "Name": "Macapa",
    "Country": "Brazil",
    "IATA/FAA": "MCP",
    "Longitude": "-51.072178",
    "ICAO": "SBMQ",
    "Airport ID": "2591",
    "Latitude": "0.050664",
    "Timezone": "56",
    "DST": "-3",
    "destinations": ["2526"]
}, {
    "City": "Sogndal",
    "DBTZ": "E",
    "Name": "Sogndal Airport",
    "Country": "Norway",
    "IATA/FAA": "SOG",
    "Longitude": "7.13778",
    "ICAO": "ENSG",
    "Airport ID": "5586",
    "Latitude": "61.1561",
    "Timezone": "0",
    "DST": "1",
    "destinations": ["636", "4345", "644", "5585"]
}, {
    "City": "Svolvaer",
    "DBTZ": "E",
    "Name": "Svolvaer Helle Airport",
    "Country": "Norway",
    "IATA/FAA": "SVJ",
    "Longitude": "14.6692",
    "ICAO": "ENSH",
    "Airport ID": "5587",
    "Latitude": "68.2433",
    "Timezone": "27",
    "DST": "1",
    "destinations": ["635", "5580", "4252"]
}, {
    "City": "Rost",
    "DBTZ": "E",
    "Name": "Rost Airport",
    "Country": "Norway",
    "IATA/FAA": "RET",
    "Longitude": "12.1033",
    "ICAO": "ENRS",
    "Airport ID": "5584",
    "Latitude": "67.5278",
    "Timezone": "7",
    "DST": "1",
    "destinations": ["635", "5580"]
}, {
    "City": "Sandane",
    "DBTZ": "E",
    "Name": "Anda Airport",
    "Country": "Norway",
    "IATA/FAA": "SDN",
    "Longitude": "6.10583",
    "ICAO": "ENSD",
    "Airport ID": "5585",
    "Latitude": "61.83",
    "Timezone": "196",
    "DST": "1",
    "destinations": ["644", "5586"]
}, {
    "City": "Mo i Rana",
    "DBTZ": "E",
    "Name": "Rossvoll Airport",
    "Country": "Norway",
    "IATA/FAA": "MQN",
    "Longitude": "14.3014",
    "ICAO": "ENRA",
    "Airport ID": "5582",
    "Latitude": "66.3639",
    "Timezone": "229",
    "DST": "1",
    "destinations": ["635", "652", "5583", "665"]
}, {
    "City": "Rorvik",
    "DBTZ": "E",
    "Name": "Ryum Airport",
    "Country": "Norway",
    "IATA/FAA": "RVK",
    "Longitude": "11.1461",
    "ICAO": "ENRM",
    "Airport ID": "5583",
    "Latitude": "64.8383",
    "Timezone": "14",
    "DST": "1",
    "destinations": ["5582", "5581", "665"]
}, {
    "City": "Leknes",
    "DBTZ": "E",
    "Name": "Leknes Airport",
    "Country": "Norway",
    "IATA/FAA": "LKN",
    "Longitude": "13.6094",
    "ICAO": "ENLK",
    "Airport ID": "5580",
    "Latitude": "68.1525",
    "Timezone": "78",
    "DST": "1",
    "destinations": ["635", "5584", "5587"]
}, {
    "City": "Namsos",
    "DBTZ": "E",
    "Name": "Namsos Hoknesora Airport",
    "Country": "Norway",
    "IATA/FAA": "OSY",
    "Longitude": "11.5786",
    "ICAO": "ENNM",
    "Airport ID": "5581",
    "Latitude": "64.4722",
    "Timezone": "7",
    "DST": "1",
    "destinations": ["652", "5583", "665"]
}, {
    "City": "Erechim",
    "DBTZ": "S",
    "Name": "Erechim Airport",
    "Country": "Brazil",
    "IATA/FAA": "ERM",
    "Longitude": "-52.2683",
    "ICAO": "SSER",
    "Airport ID": "6477",
    "Latitude": "-27.6619",
    "Timezone": "2498",
    "DST": "-3",
    "destinations": ["7384", "2602"]
}, {
    "City": "Shizuoka",
    "DBTZ": "N",
    "Name": "Mt. Fuji Shizuoka Airport",
    "Country": "Japan",
    "IATA/FAA": "FSZ",
    "Longitude": "138.189444",
    "ICAO": "RJNS",
    "Airport ID": "6476",
    "Latitude": "34.796111",
    "Timezone": "433",
    "DST": "9",
    "destinations": ["2276", "2287", "2305", "2307", "3406", "3930", "2384"]
}, {
    "City": "Sorkjosen",
    "DBTZ": "E",
    "Name": "Sorkjosen Airport",
    "Country": "Norway",
    "IATA/FAA": "SOJ",
    "Longitude": "20.9594",
    "ICAO": "ENSR",
    "Airport ID": "5588",
    "Latitude": "69.7868",
    "Timezone": "16",
    "DST": "1",
    "destinations": ["4325", "663"]
}, {
    "City": "Vardo",
    "DBTZ": "E",
    "Name": "Svartnes Airport",
    "Country": "Norway",
    "IATA/FAA": "VAW",
    "Longitude": "31.044889",
    "ICAO": "ENSS",
    "Airport ID": "5589",
    "Latitude": "70.355392",
    "Timezone": "42",
    "DST": "1",
    "destinations": ["637", "649", "4328"]
}, {
    "City": "Sukhothai",
    "DBTZ": "U",
    "Name": "Sukhothai",
    "Country": "Thailand",
    "IATA/FAA": "THS",
    "Longitude": "99.818183",
    "ICAO": "VTPO",
    "Airport ID": "3939",
    "Latitude": "17.237992",
    "Timezone": "179",
    "DST": "7",
    "destinations": ["3885"]
}, {
    "City": "Khon Kaen",
    "DBTZ": "U",
    "Name": "Khon Kaen",
    "Country": "Thailand",
    "IATA/FAA": "KKC",
    "Longitude": "102.783661",
    "ICAO": "VTUK",
    "Airport ID": "3938",
    "Latitude": "16.466628",
    "Timezone": "670",
    "DST": "7",
    "destinations": ["3157", "3885"]
}, {
    "City": "Ubon Ratchathani",
    "DBTZ": "U",
    "Name": "Ubon Ratchathani",
    "Country": "Thailand",
    "IATA/FAA": "UBP",
    "Longitude": "104.870231",
    "ICAO": "VTUU",
    "Airport ID": "3937",
    "Latitude": "15.251278",
    "Timezone": "406",
    "DST": "7",
    "destinations": ["3157", "3885"]
}, {
    "City": "Nakhon Phanom",
    "DBTZ": "U",
    "Name": "Nakhon Phanom",
    "Country": "Thailand",
    "IATA/FAA": "KOP",
    "Longitude": "104.643022",
    "ICAO": "VTUW",
    "Airport ID": "3936",
    "Latitude": "17.383794",
    "Timezone": "587",
    "DST": "7",
    "destinations": ["3157"]
}, {
    "City": "Chiang Mai",
    "DBTZ": "U",
    "Name": "Chiang Mai Intl",
    "Country": "Thailand",
    "IATA/FAA": "CNX",
    "Longitude": "98.962644",
    "ICAO": "VTCC",
    "Airport ID": "3931",
    "Latitude": "18.766847",
    "Timezone": "1036",
    "DST": "7",
    "destinations": ["3930", "3406", "3885", "3304", "3364", "3376", "3077", "3370", "3157", "3183", "3181", "3386", "3179", "3174", "3121", "2276", "4086", "4131", "3170", "4159", "3316", "3382", "3177", "3115", "3393", "3239"]
}, {
    "City": "Seoul",
    "DBTZ": "U",
    "Name": "Incheon Intl",
    "Country": "South Korea",
    "IATA/FAA": "ICN",
    "Longitude": "126.450517",
    "ICAO": "RKSI",
    "Airport ID": "3930",
    "Latitude": "37.469075",
    "Timezone": "23",
    "DST": "9",
    "destinations": ["3395", "4206", "2430", "2397", "3885", "2305", "2246", "3077", "3992", "3942", "2279", "3390", "3931", "3179", "3239", "3670", "156", "2264", "1382", "3093", "3469", "3316", "421", "1524", "507", "2276", "3393", "3404", "3389", "3386", "3364", "4108", "3368", "6414", "3392", "3370", "3375", "4380", "3371", "3400", "4334", "3406", "4144", "3376", "3304", "3682", "3645", "3728", "3714", "3797", "3877", "3484", "3830", "3035", "3577", "2188", "2179", "3275", "3940", "2983", "2933", "2287", "4381", "2322", "2307", "2908", "2006", "580", "2340", "2345", "3269", "3320", "2997", "2370", "3024", "3196", "340", "3199", "2359", "3550", "1701", "3382", "3125", "2948", "1229", "1960", "4059", "2310", "2327", "3034", "1587", "2256", "2082", "3205", "2985", "3361", "3374", "2381", "1590", "6387", "3380", "1613", "2934", "3379", "3383", "193", "346", "4140", "3121", "2313", "2384", "3120", "3372", "3388", "6476", "2326", "2308", "2312", "2333", "2347", "2337", "2324", "2330", "2241", "3384", "2927", "4033", "3387", "2244", "2296", "5996"]
}, {
    "City": "Nakhon Si Thammarat",
    "DBTZ": "U",
    "Name": "Nakhon Si Thammarat",
    "Country": "Thailand",
    "IATA/FAA": "NST",
    "Longitude": "99.944725",
    "ICAO": "VTSF",
    "Airport ID": "3933",
    "Latitude": "8.539617",
    "Timezone": "13",
    "DST": "7",
    "destinations": ["3157"]
}, {
    "City": "Chiang Rai",
    "DBTZ": "U",
    "Name": "Chiang Rai Intl",
    "Country": "Thailand",
    "IATA/FAA": "CEI",
    "Longitude": "99.882928",
    "ICAO": "VTCT",
    "Airport ID": "3932",
    "Latitude": "19.952342",
    "Timezone": "1280",
    "DST": "7",
    "destinations": ["3157", "3885", "3382"]
}, {
    "City": "Russian Mission",
    "DBTZ": "A",
    "Name": "Russian Mission Airport",
    "Country": "United States",
    "IATA/FAA": "RSH",
    "Longitude": "-161.319444",
    "ICAO": "PARS",
    "Airport ID": "7098",
    "Latitude": "61.775",
    "Timezone": "51",
    "DST": "-9",
    "destinations": ["5967", "3599", "5964"]
}, {
    "City": "St Mary's",
    "DBTZ": "A",
    "Name": "St Marys Airport",
    "Country": "United States",
    "IATA/FAA": "KSM",
    "Longitude": "-163.302",
    "ICAO": "PASM",
    "Airport ID": "6718",
    "Latitude": "62.0605",
    "Timezone": "311",
    "DST": "-9",
    "destinations": ["3774", "7207", "5966", "7200"]
}, {
    "City": "Kivalina",
    "DBTZ": "A",
    "Name": "Kivalina Airport",
    "Country": "United States",
    "IATA/FAA": "KVL",
    "Longitude": "-164.563",
    "ICAO": "PAVL",
    "Airport ID": "6719",
    "Latitude": "67.7362",
    "Timezone": "13",
    "DST": "-9",
    "destinations": ["3693", "6721"]
}, {
    "City": "Hooper Bay",
    "DBTZ": "A",
    "Name": "Hooper Bay Airport",
    "Country": "United States",
    "IATA/FAA": "HPB",
    "Longitude": "-166.147",
    "ICAO": "PAHP",
    "Airport ID": "6716",
    "Latitude": "61.5239",
    "Timezone": "7",
    "DST": "-9",
    "destinations": ["7209", "5968"]
}, {
    "City": "Kaltag",
    "DBTZ": "A",
    "Name": "Kaltag Airport",
    "Country": "United States",
    "IATA/FAA": "KAL",
    "Longitude": "-158.741",
    "ICAO": "PAKV",
    "Airport ID": "6717",
    "Latitude": "64.3191",
    "Timezone": "187",
    "DST": "-9",
    "destinations": ["3764", "7111", "7108"]
}, {
    "City": "Atqasuk",
    "DBTZ": "A",
    "Name": "Atqasuk Edward Burnell Sr Memorial Airport",
    "Country": "United States",
    "IATA/FAA": "ATK",
    "Longitude": "-157.436",
    "ICAO": "PATQ",
    "Airport ID": "6714",
    "Latitude": "70.4673",
    "Timezone": "96",
    "DST": "-9",
    "destinations": ["7220", "3571"]
}, {
    "City": "Gambell",
    "DBTZ": "A",
    "Name": "Gambell Airport",
    "Country": "United States",
    "IATA/FAA": "GAM",
    "Longitude": "-171.733",
    "ICAO": "PAGM",
    "Airport ID": "6715",
    "Latitude": "63.7668",
    "Timezone": "27",
    "DST": "-9",
    "destinations": ["3615", "6724"]
}, {
    "City": "Anaktuvuk Pass",
    "DBTZ": "A",
    "Name": "Anaktuvuk Pass Airport",
    "Country": "United States",
    "IATA/FAA": "AKP",
    "Longitude": "-151.743",
    "ICAO": "PAKP",
    "Airport ID": "6712",
    "Latitude": "68.1336",
    "Timezone": "2103",
    "DST": "-9",
    "destinations": ["3832", "3417"]
}, {
    "City": "Anvik",
    "DBTZ": "A",
    "Name": "Anvik Airport",
    "Country": "United States",
    "IATA/FAA": "ANV",
    "Longitude": "-160.191",
    "ICAO": "PANV",
    "Airport ID": "6713",
    "Latitude": "62.6467",
    "Timezone": "309",
    "DST": "-9",
    "destinations": ["5962", "7219"]
}, {
    "City": "Baghdad",
    "DBTZ": "U",
    "Name": "Baghdad International Airport",
    "Country": "Iraq",
    "IATA/FAA": "BGW",
    "Longitude": "44.234578",
    "ICAO": "ORBI",
    "Airport ID": "4130",
    "Latitude": "33.262539",
    "Timezone": "114",
    "DST": "3",
    "destinations": ["2188", "2179", "2191", "2057", "2177", "1128", "2241", "2170", "1688", "1682", "1701", "3973", "737"]
}, {
    "City": "Dong Hoi",
    "DBTZ": "U",
    "Name": "Dong Hoi",
    "Country": "Vietnam",
    "IATA/FAA": "VDH",
    "Longitude": "106.590556",
    "ICAO": "",
    "Airport ID": "4154",
    "Latitude": "17.515",
    "Timezone": "50",
    "DST": "7",
    "destinations": ["3199", "3205"]
}, {
    "City": "Roi Et",
    "DBTZ": "U",
    "Name": "Roi Et",
    "Country": "Thailand",
    "IATA/FAA": "ROI",
    "Longitude": "103.773797",
    "ICAO": "VTUV",
    "Airport ID": "4132",
    "Latitude": "16.116761",
    "Timezone": "451",
    "DST": "7",
    "destinations": ["3157"]
}, {
    "City": "Buri Ram",
    "DBTZ": "U",
    "Name": "Buri Ram",
    "Country": "Thailand",
    "IATA/FAA": "BFV",
    "Longitude": "103.253231",
    "ICAO": "VTUO",
    "Airport ID": "4133",
    "Latitude": "15.229539",
    "Timezone": "590",
    "DST": "7",
    "destinations": ["3157"]
}, {
    "City": "Ranong",
    "DBTZ": "U",
    "Name": "Ranong",
    "Country": "Thailand",
    "IATA/FAA": "UNN",
    "Longitude": "98.585483",
    "ICAO": "",
    "Airport ID": "4134",
    "Latitude": "9.777622",
    "Timezone": "57",
    "DST": "7",
    "destinations": ["3157"]
}, {
    "City": "Trat",
    "DBTZ": "U",
    "Name": "Trat",
    "Country": "Thailand",
    "IATA/FAA": "TDX",
    "Longitude": "102.318958",
    "ICAO": "VTBO",
    "Airport ID": "4135",
    "Latitude": "12.274572",
    "Timezone": "105",
    "DST": "7",
    "destinations": ["3885"]
}, {
    "City": "Amboseli National Park",
    "DBTZ": "U",
    "Name": "Amboseli Airport",
    "Country": "Kenya",
    "IATA/FAA": "ASV",
    "Longitude": "37.2531",
    "ICAO": "HKAM",
    "Airport ID": "5692",
    "Latitude": "-2.64505",
    "Timezone": "3755",
    "DST": "3",
    "destinations": ["1147"]
}, {
    "City": "Amarillo",
    "DBTZ": "A",
    "Name": "Rick Husband Amarillo Intl",
    "Country": "United States",
    "IATA/FAA": "AMA",
    "Longitude": "-101.705931",
    "ICAO": "KAMA",
    "Airport ID": "3840",
    "Latitude": "35.219369",
    "Timezone": "3607",
    "DST": "-6",
    "destinations": ["3670", "3502", "3751", "3747", "3550", "3877"]
}, {
    "City": "Topeka",
    "DBTZ": "A",
    "Name": "Forbes Fld",
    "Country": "United States",
    "IATA/FAA": "FOE",
    "Longitude": "-95.663611",
    "ICAO": "KFOE",
    "Airport ID": "3843",
    "Latitude": "38.950944",
    "Timezone": "1078",
    "DST": "-6",
    "destinations": ["3830"]
}, {
    "City": "Asyut",
    "DBTZ": "U",
    "Name": "Asyut International Airport",
    "Country": "Egypt",
    "IATA/FAA": "ATZ",
    "Longitude": "31.011983",
    "ICAO": "HEAT",
    "Airport ID": "5691",
    "Latitude": "27.046508",
    "Timezone": "772",
    "DST": "2",
    "destinations": ["2191", "2176", "1128", "2072", "2082"]
}, {
    "City": "Sirt",
    "DBTZ": "N",
    "Name": "Gardabya Airport",
    "Country": "Libya",
    "IATA/FAA": "SRX",
    "Longitude": "16.595",
    "ICAO": "HLGD",
    "Airport ID": "5696",
    "Latitude": "31.0635",
    "Timezone": "267",
    "DST": "2",
    "destinations": ["1157", "287"]
}, {
    "City": "Tobruk",
    "DBTZ": "N",
    "Name": "Gamal Abdel Nasser Airport",
    "Country": "Libya",
    "IATA/FAA": "TOB",
    "Longitude": "23.907",
    "ICAO": "HLGN",
    "Airport ID": "5697",
    "Latitude": "31.861",
    "Timezone": "519",
    "DST": "2",
    "destinations": ["3410", "1157", "287"]
}, {
    "City": "Malindi",
    "DBTZ": "U",
    "Name": "Malindi Airport",
    "Country": "Kenya",
    "IATA/FAA": "MYD",
    "Longitude": "40.1017",
    "ICAO": "HKML",
    "Airport ID": "5694",
    "Latitude": "-3.22931",
    "Timezone": "80",
    "DST": "3",
    "destinations": ["1144", "4059", "1147"]
}, {
    "City": "Nanyuki",
    "DBTZ": "U",
    "Name": "Nanyuki Civil Airport",
    "Country": "Kenya",
    "IATA/FAA": "NYK",
    "Longitude": "37.033",
    "ICAO": "HKNY",
    "Airport ID": "5695",
    "Latitude": "-0.067",
    "Timezone": "6250",
    "DST": "3",
    "destinations": ["6773", "1147", "7424"]
}, {
    "City": "Baltimore",
    "DBTZ": "A",
    "Name": "Baltimore Washington Intl",
    "Country": "United States",
    "IATA/FAA": "BWI",
    "Longitude": "-76.668333",
    "ICAO": "KBWI",
    "Airport ID": "3849",
    "Latitude": "39.175361",
    "Timezone": "146",
    "DST": "-5",
    "destinations": ["3876", "3670", "3797", "507", "3576", "3830", "3752", "3462", "193", "3682", "3448", "3488", "3645", "3858", "3626", "3536", "4019", "3864", "2895", "3673", "3825", "3690", "3820", "3486", "3759", "1852", "3627", "3751", "3533", "4089", "3585", "3857", "3877", "3484", "1780", "3878", "3747", "3473", "4011", "3861", "1953", "3611", "3570", "1760", "3641", "3862", "3622", "3793", "3731", "3621", "4014", "2890", "3678", "3646", "3515", "3494", "3550", "3469", "1948", "3811", "3806", "7669", "3685", "4034", "3566", "3712", "3660", "3458", "3717", "3863", "3722", "3577"]
}, {
    "City": "Tyler",
    "DBTZ": "A",
    "Name": "Tyler Pounds Rgnl",
    "Country": "United States",
    "IATA/FAA": "TYR",
    "Longitude": "-95.402386",
    "ICAO": "KTYR",
    "Airport ID": "3848",
    "Latitude": "32.354139",
    "Timezone": "544",
    "DST": "-6",
    "destinations": ["3670", "3550"]
}, {
    "City": "Al Bayda'",
    "DBTZ": "N",
    "Name": "La Abraq Airport",
    "Country": "Libya",
    "IATA/FAA": "LAQ",
    "Longitude": "21.964333",
    "ICAO": "HLLQ",
    "Airport ID": "5699",
    "Latitude": "32.788673",
    "Timezone": "2157",
    "DST": "2",
    "destinations": ["1157", "287"]
}, {
    "City": "Kerman",
    "DBTZ": "E",
    "Name": "Kerman",
    "Country": "Iran",
    "IATA/FAA": "KER",
    "Longitude": "56.951111",
    "ICAO": "OIKK",
    "Airport ID": "2136",
    "Latitude": "30.274444",
    "Timezone": "5741",
    "DST": "3.5",
    "destinations": ["2157", "4150", "2131", "2167"]
}, {
    "City": "Wolf Point",
    "DBTZ": "A",
    "Name": "LM Clayton Airport",
    "Country": "United States",
    "IATA/FAA": "OLF",
    "Longitude": "-105.575",
    "ICAO": "KOLF",
    "Airport ID": "7071",
    "Latitude": "48.094444",
    "Timezone": "1986",
    "DST": "-7",
    "destinations": ["4021"]
}, {
    "City": "Iturup Island",
    "DBTZ": "N",
    "Name": "Burevestnik Airport",
    "Country": "Russia",
    "IATA/FAA": "BVV",
    "Longitude": "147.621667",
    "ICAO": "",
    "Airport ID": "7478",
    "Latitude": "44.92",
    "Timezone": "79",
    "DST": "11",
    "destinations": ["2933"]
}, {
    "City": "Fes",
    "DBTZ": "N",
    "Name": "Saiss",
    "Country": "Morocco",
    "IATA/FAA": "FEZ",
    "Longitude": "-4.977958",
    "ICAO": "GMFF",
    "Airport ID": "1066",
    "Latitude": "33.927261",
    "Timezone": "1900",
    "DST": "0",
    "destinations": ["1359", "1074", "1386", "1218", "1525", "1367", "304", "1350", "585", "1439", "355", "1353", "4198", "1418", "1562", "548"]
}, {
    "City": "La Crosse",
    "DBTZ": "A",
    "Name": "La Crosse Municipal",
    "Country": "United States",
    "IATA/FAA": "LSE",
    "Longitude": "-91.256711",
    "ICAO": "KLSE",
    "Airport ID": "4044",
    "Latitude": "43.878986",
    "Timezone": "654",
    "DST": "-6",
    "destinations": ["3830", "3858"]
}, {
    "City": "Wassau",
    "DBTZ": "A",
    "Name": "Central Wisconsin",
    "Country": "United States",
    "IATA/FAA": "CWA",
    "Longitude": "-89.646635",
    "ICAO": "KCWA",
    "Airport ID": "4045",
    "Latitude": "44.772726",
    "Timezone": "840",
    "DST": "-6",
    "destinations": ["3830", "3645", "3858"]
}, {
    "City": "Peoria",
    "DBTZ": "A",
    "Name": "Peoria Regional",
    "Country": "United States",
    "IATA/FAA": "PIA",
    "Longitude": "-89.693258",
    "ICAO": "KPIA",
    "Airport ID": "4046",
    "Latitude": "40.664203",
    "Timezone": "660",
    "DST": "-6",
    "destinations": ["3670", "3830", "3682", "3645", "3858", "6505", "3877", "7056", "3617", "3751"]
}, {
    "City": "Appleton",
    "DBTZ": "A",
    "Name": "Appleton",
    "Country": "United States",
    "IATA/FAA": "ATW",
    "Longitude": "-88.507576",
    "ICAO": "KATW",
    "Airport ID": "4047",
    "Latitude": "44.257526",
    "Timezone": "680",
    "DST": "-6",
    "destinations": ["3682", "3645", "3858", "6505", "3877", "4167", "3830"]
}, {
    "City": "Toledo",
    "DBTZ": "A",
    "Name": "Toledo",
    "Country": "United States",
    "IATA/FAA": "TOL",
    "Longitude": "-83.807833",
    "ICAO": "KTOL",
    "Airport ID": "4040",
    "Latitude": "41.586806",
    "Timezone": "684",
    "DST": "-5",
    "destinations": ["3830", "7056", "3617", "4167"]
}, {
    "City": "Fort Wayne",
    "DBTZ": "A",
    "Name": "Fort Wayne",
    "Country": "United States",
    "IATA/FAA": "FWA",
    "Longitude": "-85.195139",
    "ICAO": "KFWA",
    "Airport ID": "4041",
    "Latitude": "40.978472",
    "Timezone": "815",
    "DST": "-5",
    "destinations": ["3682", "3670", "3830", "3645", "3858", "6505", "3515", "7056", "3617", "4167"]
}, {
    "City": "Decatur",
    "DBTZ": "A",
    "Name": "Decatur",
    "Country": "United States",
    "IATA/FAA": "DEC",
    "Longitude": "-88.865689",
    "ICAO": "KDEC",
    "Airport ID": "4042",
    "Latitude": "39.834564",
    "Timezone": "682",
    "DST": "-6",
    "destinations": ["3830", "3678"]
}, {
    "City": "Cedar Rapids",
    "DBTZ": "A",
    "Name": "Cedar Rapids",
    "Country": "United States",
    "IATA/FAA": "CID",
    "Longitude": "-91.710806",
    "ICAO": "KCID",
    "Airport ID": "4043",
    "Latitude": "41.884694",
    "Timezone": "869",
    "DST": "-6",
    "destinations": ["3670", "3830", "3682", "3645", "3858", "3751", "6505", "3877", "7056", "3617", "4167"]
}, {
    "City": "Rochester",
    "DBTZ": "A",
    "Name": "Rochester",
    "Country": "United States",
    "IATA/FAA": "RST",
    "Longitude": "-92.500014",
    "ICAO": "KRST",
    "Airport ID": "4048",
    "Latitude": "43.908283",
    "Timezone": "1317",
    "DST": "-6",
    "destinations": ["3830", "3858", "6505"]
}, {
    "City": "Champaign",
    "DBTZ": "A",
    "Name": "Champaign",
    "Country": "United States",
    "IATA/FAA": "CMI",
    "Longitude": "-88.278056",
    "ICAO": "KCMI",
    "Airport ID": "4049",
    "Latitude": "40.03925",
    "Timezone": "754",
    "DST": "-6",
    "destinations": ["3670", "3830"]
}, {
    "City": "Chevak",
    "DBTZ": "A",
    "Name": "Chevak Airport",
    "Country": "United States",
    "IATA/FAA": "VAK",
    "Longitude": "-165.584",
    "ICAO": "PAVA",
    "Airport ID": "5968",
    "Latitude": "61.5338",
    "Timezone": "75",
    "DST": "-9",
    "destinations": ["6716", "7209"]
}, {
    "City": "Wrangell",
    "DBTZ": "A",
    "Name": "Wrangell Airport",
    "Country": "United States",
    "IATA/FAA": "WRG",
    "Longitude": "-132.37",
    "ICAO": "PAWG",
    "Airport ID": "5969",
    "Latitude": "56.4843",
    "Timezone": "44",
    "DST": "-9",
    "destinations": ["3808", "4147"]
}, {
    "City": "Narssarssuaq",
    "DBTZ": "E",
    "Name": "Narsarsuaq",
    "Country": "Greenland",
    "IATA/FAA": "UAK",
    "Longitude": "-45.425978",
    "ICAO": "BGBW",
    "Airport ID": "7",
    "Latitude": "61.160517",
    "Timezone": "112",
    "DST": "-3",
    "destinations": ["8", "5440", "5442", "5445", "9"]
}, {
    "City": "Gustavus",
    "DBTZ": "A",
    "Name": "Gustavus Airport",
    "Country": "United States",
    "IATA/FAA": "GST",
    "Longitude": "-135.707",
    "ICAO": "PAGS",
    "Airport ID": "5960",
    "Latitude": "58.4253",
    "Timezone": "34",
    "DST": "-9",
    "destinations": ["3492", "7142"]
}, {
    "City": "Skagway",
    "DBTZ": "A",
    "Name": "Skagway Airport",
    "Country": "United States",
    "IATA/FAA": "SGY",
    "Longitude": "-135.316",
    "ICAO": "PAGY",
    "Airport ID": "5961",
    "Latitude": "59.4601",
    "Timezone": "44",
    "DST": "-9",
    "destinations": ["3492", "5963"]
}, {
    "City": "Holy Cross",
    "DBTZ": "A",
    "Name": "Holy Cross Airport",
    "Country": "United States",
    "IATA/FAA": "HCR",
    "Longitude": "-159.775",
    "ICAO": "PAHC",
    "Airport ID": "5962",
    "Latitude": "62.1883",
    "Timezone": "70",
    "DST": "-9",
    "destinations": ["5967", "6713"]
}, {
    "City": "Haines",
    "DBTZ": "A",
    "Name": "Haines Airport",
    "Country": "United States",
    "IATA/FAA": "HNS",
    "Longitude": "-135.524",
    "ICAO": "PAHN",
    "Airport ID": "5963",
    "Latitude": "59.2438",
    "Timezone": "15",
    "DST": "-9",
    "destinations": ["3492", "5961"]
}, {
    "City": "Kalskag",
    "DBTZ": "A",
    "Name": "Kalskag Airport",
    "Country": "United States",
    "IATA/FAA": "KLG",
    "Longitude": "-160.341",
    "ICAO": "PALG",
    "Airport ID": "5964",
    "Latitude": "61.5363",
    "Timezone": "55",
    "DST": "-9",
    "destinations": ["5967", "3599", "7098"]
}, {
    "City": "Mcgrath",
    "DBTZ": "A",
    "Name": "McGrath Airport",
    "Country": "United States",
    "IATA/FAA": "MCG",
    "Longitude": "-155.606",
    "ICAO": "PAMC",
    "Airport ID": "5965",
    "Latitude": "62.9529",
    "Timezone": "338",
    "DST": "-9",
    "destinations": ["7214", "7215", "3774", "5967"]
}, {
    "City": "Mountain Village",
    "DBTZ": "A",
    "Name": "Mountain Village Airport",
    "Country": "United States",
    "IATA/FAA": "MOU",
    "Longitude": "-163.682",
    "ICAO": "PAMO",
    "Airport ID": "5966",
    "Latitude": "62.0954",
    "Timezone": "337",
    "DST": "-9",
    "destinations": ["6718", "7216"]
}, {
    "City": "Aniak",
    "DBTZ": "A",
    "Name": "Aniak Airport",
    "Country": "United States",
    "IATA/FAA": "ANI",
    "Longitude": "-159.543",
    "ICAO": "PANI",
    "Airport ID": "5967",
    "Latitude": "61.5816",
    "Timezone": "88",
    "DST": "-9",
    "destinations": ["3774", "7091", "5962", "5964", "7098", "7090", "5965"]
}, {
    "City": "Fort Leonardwood",
    "DBTZ": "A",
    "Name": "Waynesville Rgnl Arpt At Forney Fld",
    "Country": "United States",
    "IATA/FAA": "TBN",
    "Longitude": "-92.140736",
    "ICAO": "KTBN",
    "Airport ID": "3665",
    "Latitude": "37.741631",
    "Timezone": "1159",
    "DST": "-6",
    "destinations": ["3678"]
}, {
    "City": "Homer",
    "DBTZ": "A",
    "Name": "Homer",
    "Country": "United States",
    "IATA/FAA": "HOM",
    "Longitude": "-151.476583",
    "ICAO": "PAHO",
    "Airport ID": "3664",
    "Latitude": "59.645556",
    "Timezone": "84",
    "DST": "-9",
    "destinations": ["3774"]
}, {
    "City": "Newburgh",
    "DBTZ": "A",
    "Name": "Stewart Intl",
    "Country": "United States",
    "IATA/FAA": "SWF",
    "Longitude": "-74.104839",
    "ICAO": "KSWF",
    "Airport ID": "3661",
    "Latitude": "41.504094",
    "Timezone": "491",
    "DST": "-5",
    "destinations": ["3752", "3533", "3878", "3645", "3617"]
}, {
    "City": "Little Rock",
    "DBTZ": "A",
    "Name": "Adams Fld",
    "Country": "United States",
    "IATA/FAA": "LIT",
    "Longitude": "-92.224306",
    "ICAO": "KLIT",
    "Airport ID": "3660",
    "Latitude": "34.729444",
    "Timezone": "262",
    "DST": "-6",
    "destinations": ["3876", "3520", "3670", "3697", "3830", "3682", "3645", "3751", "3849", "3502", "3678", "4167", "3550", "3566", "3877", "3747", "3462"]
}, {
    "City": "Matsumoto",
    "DBTZ": "U",
    "Name": "Matsumoto",
    "Country": "Japan",
    "IATA/FAA": "MMJ",
    "Longitude": "137.922669",
    "ICAO": "RJAF",
    "Airport ID": "2280",
    "Latitude": "36.166758",
    "Timezone": "2182",
    "DST": "9",
    "destinations": ["2287", "2305"]
}, {
    "City": "Ibaraki",
    "DBTZ": "U",
    "Name": "Hyakuri",
    "Country": "Japan",
    "IATA/FAA": "IBR",
    "Longitude": "140.415444",
    "ICAO": "RJAH",
    "Airport ID": "2281",
    "Latitude": "36.181083",
    "Timezone": "105",
    "DST": "9",
    "destinations": ["3406", "2287", "2305", "3942", "3943"]
}, {
    "City": "Nanki-shirahama",
    "DBTZ": "U",
    "Name": "Nanki Shirahama",
    "Country": "Japan",
    "IATA/FAA": "SHM",
    "Longitude": "135.364444",
    "ICAO": "RJBD",
    "Airport ID": "2284",
    "Latitude": "33.662222",
    "Timezone": "298",
    "DST": "9",
    "destinations": ["2359"]
}, {
    "City": "Obihiro",
    "DBTZ": "U",
    "Name": "Obihiro",
    "Country": "Japan",
    "IATA/FAA": "OBO",
    "Longitude": "143.217222",
    "ICAO": "RJCB",
    "Airport ID": "2286",
    "Latitude": "42.733333",
    "Timezone": "505",
    "DST": "9",
    "destinations": ["2359"]
}, {
    "City": "Sapporo",
    "DBTZ": "U",
    "Name": "New Chitose",
    "Country": "Japan",
    "IATA/FAA": "CTS",
    "Longitude": "141.692283",
    "ICAO": "RJCC",
    "Airport ID": "2287",
    "Latitude": "42.7752",
    "Timezone": "82",
    "DST": "9",
    "destinations": ["2279", "2305", "2359", "2281", "3942", "2347", "3943", "2330", "2276", "3364", "3077", "2334", "3992", "3728", "5999", "4381", "2322", "2327", "2324", "2933", "6476", "2280", "2340", "2345", "2326", "2344", "3930", "2290", "3406", "2372", "2246", "2288", "5994", "2384", "2291", "2294", "3885"]
}, {
    "City": "Campinas",
    "DBTZ": "S",
    "Name": "Viracopos",
    "Country": "Brazil",
    "IATA/FAA": "VCP",
    "Longitude": "-47.134444",
    "ICAO": "SBKP",
    "Airport ID": "2578",
    "Latitude": "-23.0075",
    "Timezone": "2170",
    "DST": "-3",
    "destinations": ["2522", "2521", "2525", "4209", "2531", "8242", "2535", "2541", "7364", "2548", "2538", "4215", "2537", "2545", "2547", "7380", "2555", "2559", "2560", "2562", "2554", "2569", "8180", "6034", "2576", "2575", "8238", "2581", "2551", "2590", "6037", "2586", "6038", "2597", "2595", "2602", "2528", "2599", "2550", "2613", "2610", "7394", "2612", "2619", "2621", "2623", "2635", "2633", "2638", "2539", "1638"]
}, {
    "City": "Joinville",
    "DBTZ": "S",
    "Name": "Lauro Carneiro De Loyola",
    "Country": "Brazil",
    "IATA/FAA": "JOI",
    "Longitude": "-48.797364",
    "ICAO": "SBJV",
    "Airport ID": "2576",
    "Latitude": "-26.224453",
    "Timezone": "15",
    "DST": "-3",
    "destinations": ["2599", "2578", "2618", "2564"]
}, {
    "City": "Campina Grande",
    "DBTZ": "S",
    "Name": "Presidente Joao Suassuna",
    "Country": "Brazil",
    "IATA/FAA": "CPV",
    "Longitude": "-35.896364",
    "ICAO": "SBKG",
    "Airport ID": "2577",
    "Latitude": "-7.269917",
    "Timezone": "1646",
    "DST": "-3",
    "destinations": ["2604", "2621"]
}, {
    "City": "Joao Pessoa",
    "DBTZ": "S",
    "Name": "Presidente Castro Pinto",
    "Country": "Brazil",
    "IATA/FAA": "JPA",
    "Longitude": "-34.950681",
    "ICAO": "SBJP",
    "Airport ID": "2575",
    "Latitude": "-7.148381",
    "Timezone": "215",
    "DST": "-3",
    "destinations": ["2559", "2621", "2578", "2531", "2560", "2564"]
}, {
    "City": "Imperatriz",
    "DBTZ": "S",
    "Name": "Prefeito Renato Moreira",
    "Country": "Brazil",
    "IATA/FAA": "IMP",
    "Longitude": "-47.46005",
    "ICAO": "SBIZ",
    "Airport ID": "2572",
    "Latitude": "-5.531292",
    "Timezone": "431",
    "DST": "-3",
    "destinations": ["2526", "2537", "2531", "2616"]
}, {
    "City": "Ipatinga",
    "DBTZ": "S",
    "Name": "Usiminas",
    "Country": "Brazil",
    "IATA/FAA": "IPN",
    "Longitude": "-42.487583",
    "ICAO": "SBIP",
    "Airport ID": "2570",
    "Latitude": "-19.470722",
    "Timezone": "784",
    "DST": "-3",
    "destinations": ["2537", "2564", "2528"]
}, {
    "City": "Nashville",
    "DBTZ": "A",
    "Name": "Nashville Intl",
    "Country": "United States",
    "IATA/FAA": "BNA",
    "Longitude": "-86.678194",
    "ICAO": "KBNA",
    "Airport ID": "3690",
    "Latitude": "36.124472",
    "Timezone": "599",
    "DST": "-6",
    "destinations": ["3876", "3520", "3670", "3797", "3484", "3697", "3576", "3830", "3752", "193", "3682", "3488", "3645", "3878", "3858", "3536", "3751", "3447", "3849", "3806", "3566", "3747", "3570", "3641", "3646", "5716", "3704", "3486", "3494", "3714", "3550", "3673", "3448", "3759", "7669", "3533", "4034", "3712", "3877", "3458", "3861", "3462", "3564", "3626", "3731", "3621", "3678"]
}, {
    "City": "Seoul",
    "DBTZ": "U",
    "Name": "Gimpo",
    "Country": "South Korea",
    "IATA/FAA": "GMP",
    "Longitude": "126.790586",
    "ICAO": "RKSS",
    "Airport ID": "2378",
    "Latitude": "37.558311",
    "Timezone": "58",
    "DST": "9",
    "destinations": ["2370", "3992", "3942", "2275", "3364", "3391", "2359", "6007", "2380", "2361", "2372", "2365", "2374"]
}, {
    "City": "Ulsan",
    "DBTZ": "U",
    "Name": "Ulsan",
    "Country": "South Korea",
    "IATA/FAA": "USN",
    "Longitude": "129.351722",
    "ICAO": "RKPU",
    "Airport ID": "2374",
    "Latitude": "35.593494",
    "Timezone": "45",
    "DST": "9",
    "destinations": ["2370", "2378"]
}, {
    "City": "Cheju",
    "DBTZ": "U",
    "Name": "Jeju Intl",
    "Country": "South Korea",
    "IATA/FAA": "CJU",
    "Longitude": "126.493028",
    "ICAO": "RKPC",
    "Airport ID": "2370",
    "Latitude": "33.511306",
    "Timezone": "118",
    "DST": "9",
    "destinations": ["6008", "2378", "2372", "3406", "3371", "3368", "3077", "3375", "4380", "3404", "3400", "4144", "3374", "2276", "3992", "2279", "6007", "3930", "6003", "2361", "3942", "3364", "2365", "2381", "2374", "6005", "3385", "3383", "3387", "6392", "2305", "2380", "4189"]
}, {
    "City": "Busan",
    "DBTZ": "U",
    "Name": "Gimhae Intl",
    "Country": "South Korea",
    "IATA/FAA": "PUS",
    "Longitude": "128.938222",
    "ICAO": "RKPK",
    "Airport ID": "2372",
    "Latitude": "35.179528",
    "Timezone": "6",
    "DST": "9",
    "destinations": ["4206", "2397", "3885", "2370", "2279", "3371", "2305", "3077", "2264", "3992", "3121", "3035", "3390", "2276", "3369", "3376", "3379", "3386", "3364", "4144", "3304", "3406", "3942", "2287", "2378", "2246", "3199", "3388", "3205", "2384", "3370", "3372", "2244", "6387", "2430", "2934"]
}, {
    "City": "Navegantes",
    "DBTZ": "S",
    "Name": "Ministro Victor Konder Intl",
    "Country": "Brazil",
    "IATA/FAA": "NVT",
    "Longitude": "-48.65139",
    "ICAO": "SBNF",
    "Airport ID": "2595",
    "Latitude": "-26.879999",
    "Timezone": "18",
    "DST": "-3",
    "destinations": ["2564", "2599", "2578", "2618", "2560"]
}, {
    "City": "Jonesboro",
    "DBTZ": "A",
    "Name": "Jonesboro Muni",
    "Country": "United States",
    "IATA/FAA": "JBR",
    "Longitude": "-90.646417",
    "ICAO": "KJBR",
    "Airport ID": "3591",
    "Latitude": "35.831708",
    "Timezone": "262",
    "DST": "-6",
    "destinations": ["3678"]
}, {
    "City": "Natal",
    "DBTZ": "S",
    "Name": "Augusto Severo",
    "Country": "Brazil",
    "IATA/FAA": "NAT",
    "Longitude": "-35.247717",
    "ICAO": "SBNT",
    "Airport ID": "2597",
    "Latitude": "-5.911417",
    "Timezone": "169",
    "DST": "-3",
    "destinations": ["2537", "2556", "2610", "2578", "2531", "2559", "2560", "2564", "2621", "1638"]
}, {
    "City": "Carlsbad",
    "DBTZ": "A",
    "Name": "Cavern City Air Terminal",
    "Country": "United States",
    "IATA/FAA": "CNM",
    "Longitude": "-104.263278",
    "ICAO": "KCNM",
    "Airport ID": "3597",
    "Latitude": "32.337472",
    "Timezone": "3295",
    "DST": "-7",
    "destinations": ["4019"]
}, {
    "City": "Yuma",
    "DBTZ": "N",
    "Name": "Yuma Mcas Yuma Intl",
    "Country": "United States",
    "IATA/FAA": "YUM",
    "Longitude": "-114.60598",
    "ICAO": "KYUM",
    "Airport ID": "3596",
    "Latitude": "32.656578",
    "Timezone": "216",
    "DST": "-7",
    "destinations": ["3462", "3484"]
}, {
    "City": "Bethel",
    "DBTZ": "A",
    "Name": "Bethel",
    "Country": "United States",
    "IATA/FAA": "BET",
    "Longitude": "-161.838",
    "ICAO": "PABE",
    "Airport ID": "3599",
    "Latitude": "60.779778",
    "Timezone": "121",
    "DST": "-9",
    "destinations": ["4385", "7093", "8202", "7201", "7206", "7095", "7097", "8199", "7099", "3774", "6960", "6716", "7213", "5964", "7094", "7096", "8200", "7216", "7218", "5968", "7087"]
}, {
    "City": "Duluth",
    "DBTZ": "A",
    "Name": "Duluth Intl",
    "Country": "United States",
    "IATA/FAA": "DLH",
    "Longitude": "-92.193649",
    "ICAO": "KDLH",
    "Airport ID": "3598",
    "Latitude": "46.842091",
    "Timezone": "1428",
    "DST": "-6",
    "destinations": ["3858", "6505", "3877", "3830"]
}, {
    "City": "Porto Alegre",
    "DBTZ": "S",
    "Name": "Salgado Filho",
    "Country": "Brazil",
    "IATA/FAA": "POA",
    "Longitude": "-51.171428",
    "ICAO": "SBPA",
    "Airport ID": "2599",
    "Latitude": "-29.994428",
    "Timezone": "11",
    "DST": "-3",
    "destinations": ["2816", "3576", "2537", "2545", "2564", "2554", "2576", "2581", "2586", "2595", "2603", "2602", "6040", "2612", "2578", "2539", "2442", "2789", "1871", "2531", "2618", "3988", "2555", "2560", "6477", "6069", "1638"]
}, {
    "City": "Panama City",
    "DBTZ": "U",
    "Name": "Tocumen Intl",
    "Country": "Panama",
    "IATA/FAA": "PTY",
    "Longitude": "-79.383453",
    "ICAO": "MPTO",
    "Airport ID": "1871",
    "Latitude": "9.071364",
    "Timezone": "135",
    "DST": "-5",
    "destinations": ["2821", "3670", "3576", "3682", "1382", "1852", "1804", "1824", "1825", "1897", "2902", "2709", "1892", "1885", "580", "2749", "2699", "2895", "2710", "2708", "3448", "2531", "2851", "2715", "2537", "2443", "2714", "2713", "2897", "3494", "3988", "2560", "2564", "1767", "2673", "1909", "3714", "3550", "2801", "3797", "1779", "3877", "3484", "2789", "1881", "2551", "2848", "1780", "3878", "2745", "1863", "2816", "1953", "3830", "2739", "2599", "1760", "2610", "1772", "2650", "1762", "2890", "1764", "2899", "1776", "3646", "2688", "2868", "2771", "193", "1229", "3533"]
}, {
    "City": "Golfito",
    "DBTZ": "U",
    "Name": "Golfito",
    "Country": "Costa Rica",
    "IATA/FAA": "GLF",
    "Longitude": "-83.180544",
    "ICAO": "MRGF",
    "Airport ID": "1879",
    "Latitude": "8.653775",
    "Timezone": "49",
    "DST": "-6",
    "destinations": ["5847", "1885"]
}, {
    "City": "Lichinga",
    "DBTZ": "U",
    "Name": "Lichinga",
    "Country": "Mozambique",
    "IATA/FAA": "VXC",
    "Longitude": "35.266262",
    "ICAO": "FQLC",
    "Airport ID": "977",
    "Latitude": "-13.273986",
    "Timezone": "4505",
    "DST": "2",
    "destinations": ["984", "988"]
}, {
    "City": "Inhambane",
    "DBTZ": "U",
    "Name": "Inhambane",
    "Country": "Mozambique",
    "IATA/FAA": "INH",
    "Longitude": "35.408544",
    "ICAO": "FQIN",
    "Airport ID": "976",
    "Latitude": "-23.876431",
    "Timezone": "30",
    "DST": "2",
    "destinations": ["813", "979", "990"]
}, {
    "City": "Beira",
    "DBTZ": "U",
    "Name": "Beira",
    "Country": "Mozambique",
    "IATA/FAA": "BEW",
    "Longitude": "34.907556",
    "ICAO": "FQBR",
    "Airport ID": "974",
    "Latitude": "-19.796419",
    "Timezone": "33",
    "DST": "2",
    "destinations": ["813", "984", "979", "985", "988", "986"]
}, {
    "City": "Sao Tome",
    "DBTZ": "N",
    "Name": "Sao Tome Intl",
    "Country": "Sao Tome and Principe",
    "IATA/FAA": "TMS",
    "Longitude": "6.712153",
    "ICAO": "FPST",
    "Airport ID": "973",
    "Latitude": "0.378175",
    "Timezone": "33",
    "DST": "0",
    "destinations": ["951", "5674", "1638"]
}, {
    "City": "Longreach",
    "DBTZ": "O",
    "Name": "Longreach Airport",
    "Country": "Australia",
    "IATA/FAA": "LRE",
    "Longitude": "144.28",
    "ICAO": "YLRE",
    "Airport ID": "6289",
    "Latitude": "-23.4342",
    "Timezone": "627",
    "DST": "10",
    "destinations": ["3320", "6337"]
}, {
    "City": "Libreville",
    "DBTZ": "N",
    "Name": "Leon M Ba",
    "Country": "Gabon",
    "IATA/FAA": "LBV",
    "Longitude": "9.412283",
    "ICAO": "FOOL",
    "Airport ID": "970",
    "Latitude": "0.4586",
    "Timezone": "39",
    "DST": "1",
    "destinations": ["1382", "1074", "891", "245", "273", "1107", "1020", "298", "4161", "886", "253", "883", "897", "813", "1701", "1165"]
}, {
    "City": "Lord Howe Island",
    "DBTZ": "O",
    "Name": "Lord Howe Island Airport",
    "Country": "Australia",
    "IATA/FAA": "LDH",
    "Longitude": "159.077",
    "ICAO": "YLHI",
    "Airport ID": "6285",
    "Latitude": "-31.5383",
    "Timezone": "5",
    "DST": "10.5",
    "destinations": ["3320", "6320", "3361"]
}, {
    "City": "Lockhart River",
    "DBTZ": "O",
    "Name": "Lockhart River Airport",
    "Country": "Australia",
    "IATA/FAA": "IRG",
    "Longitude": "143.305",
    "ICAO": "YLHR",
    "Airport ID": "6286",
    "Latitude": "-12.7869",
    "Timezone": "77",
    "DST": "10",
    "destinations": ["6237", "3322"]
}, {
    "City": "Lismore",
    "DBTZ": "O",
    "Name": "Lismore Airport",
    "Country": "Australia",
    "IATA/FAA": "LSY",
    "Longitude": "153.26",
    "ICAO": "YLIS",
    "Airport ID": "6287",
    "Latitude": "-28.8303",
    "Timezone": "35",
    "DST": "10",
    "destinations": ["3361"]
}, {
    "City": "Kowanyama",
    "DBTZ": "O",
    "Name": "Kowanyama Airport",
    "Country": "Australia",
    "IATA/FAA": "KWM",
    "Longitude": "141.751",
    "ICAO": "YKOW",
    "Airport ID": "6281",
    "Latitude": "-15.4856",
    "Timezone": "35",
    "DST": "10",
    "destinations": ["3322", "6319"]
}, {
    "City": "Maputo",
    "DBTZ": "U",
    "Name": "Maputo",
    "Country": "Mozambique",
    "IATA/FAA": "MPM",
    "Longitude": "32.572606",
    "ICAO": "FQMA",
    "Airport ID": "979",
    "Latitude": "-25.920836",
    "Timezone": "145",
    "DST": "2",
    "destinations": ["1107", "4059", "813", "797", "799", "984", "974", "976", "951", "985", "988", "986", "990", "5637", "1638"]
}, {
    "City": "Charlottetown",
    "DBTZ": "A",
    "Name": "Charlottetown",
    "Country": "Canada",
    "IATA/FAA": "YYG",
    "Longitude": "-63.121111",
    "ICAO": "CYYG",
    "Airport ID": "182",
    "Latitude": "46.290001",
    "Timezone": "160",
    "DST": "-4",
    "destinations": ["73", "146", "193"]
}, {
    "City": "Spence Bay",
    "DBTZ": "A",
    "Name": "Taloyoak",
    "Country": "Canada",
    "IATA/FAA": "YYH",
    "Longitude": "-93.576667",
    "ICAO": "CYYH",
    "Airport ID": "183",
    "Latitude": "69.546667",
    "Timezone": "92",
    "DST": "-7",
    "destinations": ["32", "69", "196"]
}, {
    "City": "Fort Nelson",
    "DBTZ": "A",
    "Name": "Fort Nelson",
    "Country": "Canada",
    "IATA/FAA": "YYE",
    "Longitude": "-122.596944",
    "ICAO": "CYYE",
    "Airport ID": "180",
    "Latitude": "58.836389",
    "Timezone": "1253",
    "DST": "-8",
    "destinations": ["48", "168", "172"]
}, {
    "City": "Penticton",
    "DBTZ": "A",
    "Name": "Penticton",
    "Country": "Canada",
    "IATA/FAA": "YYF",
    "Longitude": "-119.602222",
    "ICAO": "CYYF",
    "Airport ID": "181",
    "Latitude": "49.463056",
    "Timezone": "1129",
    "DST": "-8",
    "destinations": ["156"]
}, {
    "City": "La Toma (Catamayo)",
    "DBTZ": "U",
    "Name": "Camilo Ponce Enriquez Airport",
    "Country": "Ecuador",
    "IATA/FAA": "LOH",
    "Longitude": "-79.3719",
    "ICAO": "SETM",
    "Airport ID": "6046",
    "Latitude": "-3.99589",
    "Timezone": "4056",
    "DST": "-5",
    "destinations": ["2673", "2688"]
}, {
    "City": "Churchill",
    "DBTZ": "A",
    "Name": "Churchill",
    "Country": "Canada",
    "IATA/FAA": "YYQ",
    "Longitude": "-94.065",
    "ICAO": "CYYQ",
    "Airport ID": "187",
    "Latitude": "58.739167",
    "Timezone": "94",
    "DST": "-6",
    "destinations": ["50", "132", "141", "160"]
}, {
    "City": "Victoria",
    "DBTZ": "A",
    "Name": "Victoria Intl",
    "Country": "Canada",
    "IATA/FAA": "YYJ",
    "Longitude": "-123.425833",
    "ICAO": "CYYJ",
    "Airport ID": "184",
    "Latitude": "48.646944",
    "Timezone": "63",
    "DST": "-8",
    "destinations": ["156", "3469", "178", "193", "3577", "3877", "49", "87"]
}, {
    "City": "Malta",
    "DBTZ": "E",
    "Name": "Luqa",
    "Country": "Malta",
    "IATA/FAA": "MLA",
    "Longitude": "14.4775",
    "ICAO": "LMML",
    "Airport ID": "1606",
    "Latitude": "35.857497",
    "Timezone": "300",
    "DST": "1",
    "destinations": ["1613", "1382", "1335", "1386", "1273", "1555", "502", "3953", "609", "644", "1197", "1157", "1525", "469", "608", "1538", "494", "1501", "490", "599", "535", "585", "1222", "691", "669", "3956", "517", "491", "492", "1229", "1353", "699", "537", "1562", "657", "548", "1515", "1526", "1539", "680", "580", "7447", "1218", "1509", "1529", "210", "3941", "1154", "302", "1489", "488", "4029", "345", "552", "340", "534", "1665", "342", "1701", "2948", "507", "478", "346", "521", "547", "1587", "4317", "2985", "351", "1678", "629", "737", "1418", "465", "287", "523"]
}, {
    "City": "Bandar Lengeh",
    "DBTZ": "E",
    "Name": "Bandar Lengeh",
    "Country": "Iran",
    "IATA/FAA": "BDH",
    "Longitude": "54.824847",
    "ICAO": "OIBL",
    "Airport ID": "2110",
    "Latitude": "26.532",
    "Timezone": "67",
    "DST": "3.5",
    "destinations": ["2188", "2157"]
}, {
    "City": "Goose Bay",
    "DBTZ": "A",
    "Name": "Goose Bay",
    "Country": "Canada",
    "IATA/FAA": "YYR",
    "Longitude": "-60.425833",
    "ICAO": "CYYR",
    "Airport ID": "188",
    "Latitude": "53.319168",
    "Timezone": "160",
    "DST": "-4",
    "destinations": ["45", "73", "125", "161", "189", "24", "5485", "5488", "7252", "5550", "5461"]
}, {
    "City": "St. John's",
    "DBTZ": "A",
    "Name": "St Johns Intl",
    "Country": "Canada",
    "IATA/FAA": "YYT",
    "Longitude": "-52.751945",
    "ICAO": "CYYT",
    "Airport ID": "189",
    "Latitude": "47.61861",
    "Timezone": "461",
    "DST": "-3.5",
    "destinations": ["507", "45", "73", "100", "125", "146", "188", "193", "24", "77", "1441", "3878", "3494"]
}, {
    "City": "Bakhtaran",
    "DBTZ": "E",
    "Name": "Shahid Ashrafi Esfahani",
    "Country": "Iran",
    "IATA/FAA": "KSH",
    "Longitude": "47.158128",
    "ICAO": "OICC",
    "Airport ID": "2114",
    "Latitude": "34.345853",
    "Timezone": "4301",
    "DST": "3.5",
    "destinations": ["4331", "2131", "1701"]
}, {
    "City": "Sanandaj",
    "DBTZ": "E",
    "Name": "Sanandaj",
    "Country": "Iran",
    "IATA/FAA": "SDG",
    "Longitude": "47.009247",
    "ICAO": "OICS",
    "Airport ID": "2117",
    "Latitude": "35.245856",
    "Timezone": "4522",
    "DST": "3.5",
    "destinations": ["2131"]
}, {
    "City": "Oriximina",
    "DBTZ": "S",
    "Name": "Trombetas",
    "Country": "Brazil",
    "IATA/FAA": "TMT",
    "Longitude": "-56.396803",
    "ICAO": "SBTB",
    "Airport ID": "2622",
    "Latitude": "-1.4896",
    "Timezone": "287",
    "DST": "-4",
    "destinations": ["4092"]
}, {
    "City": "Teresina",
    "DBTZ": "S",
    "Name": "Senador Petronio Portella",
    "Country": "Brazil",
    "IATA/FAA": "THE",
    "Longitude": "-42.823478",
    "ICAO": "SBTE",
    "Airport ID": "2623",
    "Latitude": "-5.059942",
    "Timezone": "219",
    "DST": "-3",
    "destinations": ["2559", "2610", "2616", "2578", "2531", "2564"]
}, {
    "City": "Salvador",
    "DBTZ": "S",
    "Name": "Deputado Luis Eduardo Magalhaes",
    "Country": "Brazil",
    "IATA/FAA": "SSA",
    "Longitude": "-38.331044",
    "ICAO": "SBSV",
    "Airport ID": "2621",
    "Latitude": "-12.910994",
    "Timezone": "64",
    "DST": "-3",
    "destinations": ["7373", "6039", "3576", "2582", "2522", "4209", "2537", "2564", "2569", "2575", "6036", "2631", "2610", "2578", "2638", "340", "2531", "2618", "2577", "2559", "2560", "2590", "2597", "2545", "2604", "2612", "1638", "1229", "2650"]
}, {
    "City": "Tefe",
    "DBTZ": "S",
    "Name": "Tefe",
    "Country": "Brazil",
    "IATA/FAA": "TFF",
    "Longitude": "-64.724056",
    "ICAO": "SBTF",
    "Airport ID": "2624",
    "Latitude": "-3.382944",
    "Timezone": "184",
    "DST": "-4",
    "destinations": ["8247", "8258", "2551", "8256", "7530"]
}, {
    "City": "Dalat",
    "DBTZ": "U",
    "Name": "Dalat",
    "Country": "Vietnam",
    "IATA/FAA": "DLI",
    "Longitude": "108.367",
    "ICAO": "VVDL",
    "Airport ID": "4153",
    "Latitude": "11.75",
    "Timezone": "3156",
    "DST": "7",
    "destinations": ["3199", "3205", "3196"]
}, {
    "City": "Tabatinga",
    "DBTZ": "S",
    "Name": "Tabatinga",
    "Country": "Brazil",
    "IATA/FAA": "TBT",
    "Longitude": "-69.935828",
    "ICAO": "SBTT",
    "Airport ID": "2628",
    "Latitude": "-4.255669",
    "Timezone": "279",
    "DST": "-4",
    "destinations": ["2551"]
}, {
    "City": "San Cristobal",
    "DBTZ": "U",
    "Name": "San Cristobal Airport",
    "Country": "Ecuador",
    "IATA/FAA": "SCY",
    "Longitude": "-89.61745",
    "ICAO": "SEST",
    "Airport ID": "6045",
    "Latitude": "-0.910206",
    "Timezone": "62",
    "DST": "-6",
    "destinations": ["2673"]
}, {
    "City": "Santa Clara",
    "DBTZ": "U",
    "Name": "Abel Santamaria",
    "Country": "Cuba",
    "IATA/FAA": "SNU",
    "Longitude": "-79.943611",
    "ICAO": "MUSC",
    "Airport ID": "1918",
    "Latitude": "22.492192",
    "Timezone": "338",
    "DST": "-5",
    "destinations": ["73", "146", "193", "5852"]
}, {
    "City": "Saravena",
    "DBTZ": "U",
    "Name": "Los Colonizadores Airport",
    "Country": "Colombia",
    "IATA/FAA": "RVE",
    "Longitude": "-71.9",
    "ICAO": "SKSA",
    "Airport ID": "7355",
    "Latitude": "6.916667",
    "Timezone": "10",
    "DST": "-5",
    "destinations": ["2709", "2751"]
}, {
    "City": "Palembang",
    "DBTZ": "N",
    "Name": "Sultan Mahmud Badaruddin Ii",
    "Country": "Indonesia",
    "IATA/FAA": "PLM",
    "Longitude": "104.699903",
    "ICAO": "WIPP",
    "Airport ID": "3289",
    "Latitude": "-2.89825",
    "Timezone": "49",
    "DST": "7",
    "destinations": ["3304", "3275", "3940", "3316", "3903", "6223", "3898", "3905"]
}, {
    "City": "Bengkulu",
    "DBTZ": "N",
    "Name": "Fatmawati Soekarno",
    "Country": "Indonesia",
    "IATA/FAA": "BKS",
    "Longitude": "102.339036",
    "ICAO": "WIPL",
    "Airport ID": "3288",
    "Latitude": "-3.8637",
    "Timezone": "50",
    "DST": "7",
    "destinations": ["3275", "3903"]
}, {
    "City": "Jambi",
    "DBTZ": "N",
    "Name": "Sultan Thaha",
    "Country": "Indonesia",
    "IATA/FAA": "DJB",
    "Longitude": "103.644378",
    "ICAO": "WIPA",
    "Airport ID": "3287",
    "Latitude": "-1.638017",
    "Timezone": "82",
    "DST": "7",
    "destinations": ["3275", "3903"]
}, {
    "City": "Holguin",
    "DBTZ": "U",
    "Name": "Frank Pais Intl",
    "Country": "Cuba",
    "IATA/FAA": "HOG",
    "Longitude": "-76.315108",
    "ICAO": "MUHG",
    "Airport ID": "1910",
    "Latitude": "20.785589",
    "Timezone": "361",
    "DST": "-5",
    "destinations": ["146", "193", "1524", "1909", "340", "1780", "1852", "478"]
}, {
    "City": "Pontianak",
    "DBTZ": "N",
    "Name": "Supadio",
    "Country": "Indonesia",
    "IATA/FAA": "PNK",
    "Longitude": "109.403892",
    "ICAO": "WIOO",
    "Airport ID": "3284",
    "Latitude": "-0.150711",
    "Timezone": "10",
    "DST": "7",
    "destinations": ["3275", "3282", "3903", "3263"]
}, {
    "City": "Ketapang",
    "DBTZ": "N",
    "Name": "Rahadi Usman",
    "Country": "Indonesia",
    "IATA/FAA": "KTG",
    "Longitude": "109.963483",
    "ICAO": "WIOK",
    "Airport ID": "3282",
    "Latitude": "-1.816639",
    "Timezone": "46",
    "DST": "7",
    "destinations": ["3910", "3284"]
}, {
    "City": "Boston",
    "DBTZ": "A",
    "Name": "General Edward Lawrence Logan Intl",
    "Country": "United States",
    "IATA/FAA": "BOS",
    "Longitude": "-71.005181",
    "ICAO": "KBOS",
    "Airport ID": "3448",
    "Latitude": "42.364347",
    "Timezone": "19",
    "DST": "-5",
    "destinations": ["3517", "3864", "3701", "4025", "4278", "5749", "4265", "6456", "4026", "5767", "5770", "3820", "3876", "1852", "3520", "3670", "3797", "3484", "3697", "507", "1229", "3542", "3576", "2279", "3830", "3720", "3752", "3462", "3570", "3608", "3622", "3731", "3577", "3745", "73", "100", "146", "193", "3682", "1382", "3857", "3771", "3528", "1555", "2895", "3673", "3849", "3806", "3751", "3645", "2188", "3494", "3533", "3566", "3714", "3712", "3877", "3582", "3878", "3861", "1953", "3722", "1754", "1760", "3626", "3793", "3613", "1762", "3469", "3748", "2890", "1764", "2883", "2899", "3646", "1871", "580", "4069", "3759", "3488", "3585", "3858", "3515", "3536", "599", "603", "16", "4112", "3747", "340", "346", "1678", "3524", "144", "1638", "1633", "3486", "3550", "5674", "3690", "3458", "3717", "3678"]
}, {
    "City": "Hana",
    "DBTZ": "A",
    "Name": "Hana",
    "Country": "United States",
    "IATA/FAA": "HNM",
    "Longitude": "-156.014439",
    "ICAO": "PHHN",
    "Airport ID": "3445",
    "Latitude": "20.795636",
    "Timezone": "78",
    "DST": "-10",
    "destinations": ["3456"]
}, {
    "City": "Trenton",
    "DBTZ": "A",
    "Name": "Trenton Mercer",
    "Country": "United States",
    "IATA/FAA": "TTN",
    "Longitude": "-74.813469",
    "ICAO": "KTTN",
    "Airport ID": "3447",
    "Latitude": "40.276692",
    "Timezone": "213",
    "DST": "-5",
    "destinations": ["3682", "3690", "3486", "3876", "3488", "3645", "3533", "3585", "3878", "3747", "3626", "3793", "3646", "6989"]
}, {
    "City": "Prescott",
    "DBTZ": "A",
    "Name": "Ernest A Love Fld",
    "Country": "United States",
    "IATA/FAA": "PRC",
    "Longitude": "-112.419583",
    "ICAO": "KPRC",
    "Airport ID": "3446",
    "Latitude": "34.654472",
    "Timezone": "5045",
    "DST": "-7",
    "destinations": ["3484"]
}, {
    "City": "Upington",
    "DBTZ": "U",
    "Name": "Upington",
    "Country": "South Africa",
    "IATA/FAA": "UTN",
    "Longitude": "21.260239",
    "ICAO": "FAUP",
    "Airport ID": "865",
    "Latitude": "-28.399097",
    "Timezone": "2782",
    "DST": "2",
    "destinations": ["797", "813"]
}, {
    "City": "Umtata",
    "DBTZ": "U",
    "Name": "Mthatha",
    "Country": "South Africa",
    "IATA/FAA": "UTT",
    "Longitude": "28.674289",
    "ICAO": "FAUT",
    "Airport ID": "866",
    "Latitude": "-31.547903",
    "Timezone": "2400",
    "DST": "2",
    "destinations": ["813"]
}, {
    "City": "Nelson",
    "DBTZ": "Z",
    "Name": "Nelson",
    "Country": "New Zealand",
    "IATA/FAA": "NSN",
    "Longitude": "173.221111",
    "ICAO": "NZNS",
    "Airport ID": "2024",
    "Latitude": "-41.298333",
    "Timezone": "17",
    "DST": "12",
    "destinations": ["2006", "2009", "2028", "2042"]
}, {
    "City": "Invercargill",
    "DBTZ": "Z",
    "Name": "Invercargill",
    "Country": "New Zealand",
    "IATA/FAA": "IVC",
    "Longitude": "168.312992",
    "ICAO": "NZNV",
    "Airport ID": "2025",
    "Latitude": "-46.412408",
    "Timezone": "5",
    "DST": "12",
    "destinations": ["2009", "2042"]
}, {
    "City": "Valledupar",
    "DBTZ": "U",
    "Name": "Alfonso Lopez Pumarejo",
    "Country": "Colombia",
    "IATA/FAA": "VUP",
    "Longitude": "-73.249506",
    "ICAO": "SKVP",
    "Airport ID": "2755",
    "Latitude": "10.435042",
    "Timezone": "456",
    "DST": "-5",
    "destinations": ["2709"]
}, {
    "City": "Arauca",
    "DBTZ": "U",
    "Name": "Santiago Perez",
    "Country": "Colombia",
    "IATA/FAA": "AUC",
    "Longitude": "-70.736925",
    "ICAO": "SKUC",
    "Airport ID": "2752",
    "Latitude": "7.068881",
    "Timezone": "420",
    "DST": "-5",
    "destinations": ["2709"]
}, {
    "City": "Quibdo",
    "DBTZ": "U",
    "Name": "El Carano",
    "Country": "Colombia",
    "IATA/FAA": "UIB",
    "Longitude": "-76.641181",
    "ICAO": "SKUI",
    "Airport ID": "2753",
    "Latitude": "5.690758",
    "Timezone": "204",
    "DST": "-5",
    "destinations": ["2709", "2715", "2729"]
}, {
    "City": "San Vincente De Caguan",
    "DBTZ": "U",
    "Name": "Eduardo Falla Solano",
    "Country": "Colombia",
    "IATA/FAA": "SVI",
    "Longitude": "-74.76635",
    "ICAO": "SKSV",
    "Airport ID": "2750",
    "Latitude": "2.152175",
    "Timezone": "920",
    "DST": "-5",
    "destinations": ["6740", "2709"]
}, {
    "City": "New Plymouth",
    "DBTZ": "Z",
    "Name": "New Plymouth",
    "Country": "New Zealand",
    "IATA/FAA": "NPL",
    "Longitude": "174.179167",
    "ICAO": "NZNP",
    "Airport ID": "2023",
    "Latitude": "-39.008611",
    "Timezone": "97",
    "DST": "12",
    "destinations": ["2006", "2009", "2042"]
}, {
    "City": "Palmerston North",
    "DBTZ": "Z",
    "Name": "Palmerston North",
    "Country": "New Zealand",
    "IATA/FAA": "PMR",
    "Longitude": "175.616944",
    "ICAO": "NZPM",
    "Airport ID": "2028",
    "Latitude": "-40.320556",
    "Timezone": "151",
    "DST": "12",
    "destinations": ["2006", "2009", "2015", "2024", "2042"]
}, {
    "City": "Paraparaumu",
    "DBTZ": "Z",
    "Name": "Paraparaumu",
    "Country": "New Zealand",
    "IATA/FAA": "PPQ",
    "Longitude": "174.989167",
    "ICAO": "NZPP",
    "Airport ID": "2029",
    "Latitude": "-40.904722",
    "Timezone": "22",
    "DST": "12",
    "destinations": ["2006", "2009"]
}, {
    "City": "Cochabamba",
    "DBTZ": "U",
    "Name": "Jorge Wilsterman",
    "Country": "Bolivia",
    "IATA/FAA": "CBB",
    "Longitude": "-66.177114",
    "ICAO": "SLCB",
    "Airport ID": "2759",
    "Latitude": "-17.421058",
    "Timezone": "8360",
    "DST": "-4",
    "destinations": ["2769", "2761", "2762", "2767", "2768", "2771"]
}, {
    "City": "Brazzaville",
    "DBTZ": "N",
    "Name": "Maya Maya",
    "Country": "Congo (Brazzaville)",
    "IATA/FAA": "BZV",
    "Longitude": "15.253031",
    "ICAO": "FCBB",
    "Airport ID": "883",
    "Latitude": "-4.2517",
    "Timezone": "1048",
    "DST": "1",
    "destinations": ["1382", "1074", "886", "951", "1107", "897", "970", "1020", "4059", "245", "813", "1165"]
}, {
    "City": "Gaberone",
    "DBTZ": "U",
    "Name": "Sir Seretse Khama Intl",
    "Country": "Botswana",
    "IATA/FAA": "GBE",
    "Longitude": "25.918208",
    "ICAO": "FBSK",
    "Airport ID": "881",
    "Latitude": "-24.555225",
    "Timezone": "3299",
    "DST": "2",
    "destinations": ["879", "797", "877", "1005", "813", "907", "880", "4059"]
}, {
    "City": "Maun",
    "DBTZ": "U",
    "Name": "Maun",
    "Country": "Botswana",
    "IATA/FAA": "MUB",
    "Longitude": "23.431086",
    "ICAO": "FBMN",
    "Airport ID": "880",
    "Latitude": "-19.972564",
    "Timezone": "3093",
    "DST": "2",
    "destinations": ["879", "797", "881", "813", "1004", "4105"]
}, {
    "City": "Manzini",
    "DBTZ": "U",
    "Name": "Matsapha",
    "Country": "Swaziland",
    "IATA/FAA": "MTS",
    "Longitude": "31.307519",
    "ICAO": "FDMS",
    "Airport ID": "887",
    "Latitude": "-26.529022",
    "Timezone": "2075",
    "DST": "2",
    "destinations": ["813"]
}, {
    "City": "Pointe-noire",
    "DBTZ": "N",
    "Name": "Pointe Noire",
    "Country": "Congo (Brazzaville)",
    "IATA/FAA": "PNR",
    "Longitude": "11.886597",
    "ICAO": "FCPP",
    "Airport ID": "886",
    "Latitude": "-4.816028",
    "Timezone": "55",
    "DST": "1",
    "destinations": ["1382", "883", "1074", "967", "1107", "970", "245", "897", "813"]
}, {
    "City": "Lexington KY",
    "DBTZ": "A",
    "Name": "Blue Grass",
    "Country": "United States",
    "IATA/FAA": "LEX",
    "Longitude": "-84.605889",
    "ICAO": "KLEX",
    "Airport ID": "4017",
    "Latitude": "38.0365",
    "Timezone": "979",
    "DST": "-5",
    "destinations": ["3682", "3876", "3670", "3830", "3520", "3645", "3697", "3858", "3533", "7056", "3617", "4167", "3550"]
}, {
    "City": "La Grande Riviere",
    "DBTZ": "A",
    "Name": "La Grande Riviere",
    "Country": "Canada",
    "IATA/FAA": "YGL",
    "Longitude": "-77.704167",
    "ICAO": "CYGL",
    "Airport ID": "62",
    "Latitude": "53.625278",
    "Timezone": "639",
    "DST": "-5",
    "destinations": ["5496", "6727", "146"]
}, {
    "City": "Bangui",
    "DBTZ": "N",
    "Name": "Bangui M Poko",
    "Country": "Central African Republic",
    "IATA/FAA": "BGF",
    "Longitude": "18.518786",
    "ICAO": "FEFF",
    "Airport ID": "888",
    "Latitude": "4.398475",
    "Timezone": "1208",
    "DST": "1",
    "destinations": ["4161", "1074", "897"]
}, {
    "City": "Rajahmundry",
    "DBTZ": "N",
    "Name": "Rajahmundry",
    "Country": "India",
    "IATA/FAA": "RJA",
    "Longitude": "81.818208",
    "ICAO": "VORY",
    "Airport ID": "3148",
    "Latitude": "17.110361",
    "Timezone": "151",
    "DST": "5.5",
    "destinations": ["3141"]
}, {
    "City": "Mannheim",
    "DBTZ": "E",
    "Name": "Mannheim City",
    "Country": "Germany",
    "IATA/FAA": "MHG",
    "Longitude": "8.514264",
    "ICAO": "EDFM",
    "Airport ID": "356",
    "Latitude": "49.472706",
    "Timezone": "309",
    "DST": "1",
    "destinations": ["351"]
}, {
    "City": "Xingyi",
    "DBTZ": "U",
    "Name": "Xingyi Airport",
    "Country": "China",
    "IATA/FAA": "ACX",
    "Longitude": "104.9587",
    "ICAO": "",
    "Airport ID": "7506",
    "Latitude": "25.0882",
    "Timezone": "500",
    "DST": "8",
    "destinations": ["3370", "3393", "3394", "6341", "3406"]
}, {
    "City": "Veliky Ustyug",
    "DBTZ": "N",
    "Name": "Veliky Ustyug",
    "Country": "Russia",
    "IATA/FAA": "VUS",
    "Longitude": "46.26",
    "ICAO": "ULWU",
    "Airport ID": "8924",
    "Latitude": "60.788333",
    "Timezone": "331",
    "DST": "4",
    "destinations": ["6111"]
}, {
    "City": "Kangding",
    "DBTZ": "N",
    "Name": "Kangding Airport",
    "Country": "China",
    "IATA/FAA": "KGT",
    "Longitude": "101.734722",
    "ICAO": "ZUKD",
    "Airport ID": "8921",
    "Latitude": "30.1575",
    "Timezone": "14042",
    "DST": "8",
    "destinations": ["3395"]
}, {
    "City": "Buenos Aires",
    "DBTZ": "N",
    "Name": "Ministro Pistarini",
    "Country": "Argentina",
    "IATA/FAA": "EZE",
    "Longitude": "-58.535833",
    "ICAO": "SAEZ",
    "Airport ID": "3988",
    "Latitude": "-34.822222",
    "Timezone": "67",
    "DST": "-3",
    "destinations": ["3670", "3797", "3576", "1760", "2816", "2650", "1382", "1824", "2699", "1218", "2709", "2513", "2531", "2851", "2443", "1852", "1555", "4061", "2560", "2564", "2471", "2789", "1229", "2452", "2490", "2440", "2497", "2771", "507", "1871", "5852", "3682", "2545", "2555", "2559", "2599", "580", "2673", "340", "3550"]
}, {
    "City": "Erbil",
    "DBTZ": "U",
    "Name": "Erbil Intl",
    "Country": "Iraq",
    "IATA/FAA": "EBL",
    "Longitude": "43.963158",
    "ICAO": "ORER",
    "Airport ID": "3989",
    "Latitude": "36.237611",
    "Timezone": "1341",
    "DST": "3",
    "destinations": ["2188", "2179", "2191", "580", "1685", "1682", "1701", "340", "2177", "1128", "1613", "2241", "2170", "737", "345", "346", "1688", "3973", "687", "4330"]
}, {
    "City": "Rennell Island",
    "DBTZ": "U",
    "Name": "Rennell/Tingoa Airport",
    "Country": "Solomon Islands",
    "IATA/FAA": "RNL",
    "Longitude": "160.063",
    "ICAO": "AGGR",
    "Airport ID": "5414",
    "Latitude": "-11.5339",
    "Timezone": "0",
    "DST": "11",
    "destinations": ["6919", "4074"]
}, {
    "City": "Tucurui",
    "DBTZ": "S",
    "Name": "Tucurui",
    "Country": "Brazil",
    "IATA/FAA": "TUR",
    "Longitude": "-49.720267",
    "ICAO": "SBTU",
    "Airport ID": "2629",
    "Latitude": "-3.786008",
    "Timezone": "830",
    "DST": "-4",
    "destinations": ["2526"]
}, {
    "City": "Marau",
    "DBTZ": "U",
    "Name": "Marau Airport",
    "Country": "Solomon Islands",
    "IATA/FAA": "RUS",
    "Longitude": "160.825",
    "ICAO": "AGGU",
    "Airport ID": "5415",
    "Latitude": "-9.86167",
    "Timezone": "0",
    "DST": "11",
    "destinations": ["4074"]
}, {
    "City": "Stuttgart",
    "DBTZ": "E",
    "Name": "Stuttgart",
    "Country": "Germany",
    "IATA/FAA": "STR",
    "Longitude": "9.221964",
    "ICAO": "EDDS",
    "Airport ID": "350",
    "Latitude": "48.689878",
    "Timezone": "1276",
    "DST": "1",
    "destinations": ["1230", "3941", "1218", "1506", "1739", "1321", "1216", "353", "1501", "302", "1489", "1519", "1460", "1509", "1200", "338", "1626", "1555", "352", "342", "5557", "1452", "1225", "669", "1462", "348", "507", "1638", "1524", "1561", "1520", "3998", "1742", "1205", "772", "4317", "1646", "1486", "1206", "548", "1508", "351", "1613", "1209", "1208", "1212", "1688", "345", "1563", "410", "499", "1551", "3682", "1382", "469", "1055", "1715", "4091", "1051", "1458", "1054", "1472", "1056", "1226", "599", "1665", "580", "3494", "340", "1609", "346", "1678", "1706", "1682", "609", "2985", "1701", "341", "1103", "1231"]
}, {
    "City": "Suavanao",
    "DBTZ": "U",
    "Name": "Suavanao Airport",
    "Country": "Solomon Islands",
    "IATA/FAA": "VAO",
    "Longitude": "158.731",
    "ICAO": "AGGV",
    "Airport ID": "5416",
    "Latitude": "-7.58556",
    "Timezone": "0",
    "DST": "11",
    "destinations": ["4074"]
}, {
    "City": "Whati",
    "DBTZ": "A",
    "Name": "Whati Airport",
    "Country": "Canada",
    "IATA/FAA": "YLE",
    "Longitude": "-117.246",
    "ICAO": "CEM3",
    "Airport ID": "5463",
    "Latitude": "63.1317",
    "Timezone": "882",
    "DST": "-7",
    "destinations": ["196"]
}, {
    "City": "Stephenville",
    "DBTZ": "A",
    "Name": "Stephenville",
    "Country": "Canada",
    "IATA/FAA": "YJT",
    "Longitude": "-58.549999",
    "ICAO": "CYJT",
    "Airport ID": "77",
    "Latitude": "48.544167",
    "Timezone": "80",
    "DST": "-3.5",
    "destinations": ["45"]
}, {
    "City": "Levelock",
    "DBTZ": "A",
    "Name": "Levelock Airport",
    "Country": "United States",
    "IATA/FAA": "KLL",
    "Longitude": "-156.858611",
    "ICAO": "",
    "Airport ID": "7102",
    "Latitude": "59.128056",
    "Timezone": "39",
    "DST": "-9",
    "destinations": ["6763"]
}, {
    "City": "Kagau Island",
    "DBTZ": "U",
    "Name": "Kagau Island Airport",
    "Country": "Solomon Islands",
    "IATA/FAA": "KGE",
    "Longitude": "157.583",
    "ICAO": "AGKG",
    "Airport ID": "5417",
    "Latitude": "-7.333",
    "Timezone": "0",
    "DST": "11",
    "destinations": ["5412", "5411"]
}, {
    "City": "Halifax",
    "DBTZ": "A",
    "Name": "Halifax Intl",
    "Country": "Canada",
    "IATA/FAA": "YHZ",
    "Longitude": "-63.50861",
    "ICAO": "CYHZ",
    "Airport ID": "73",
    "Latitude": "44.880833",
    "Timezone": "477",
    "DST": "-4",
    "destinations": ["3520", "3752", "3448", "507", "3830", "45", "56", "100", "117", "125", "126", "135", "146", "182", "188", "189", "193", "340", "3697", "36", "1441", "3878", "3494", "1852", "1780", "1760", "70", "178"]
}, {
    "City": "Chalkyitsik",
    "DBTZ": "A",
    "Name": "Chalkyitsik Airport",
    "Country": "United States",
    "IATA/FAA": "CIK",
    "Longitude": "-143.74",
    "ICAO": "PACI",
    "Airport ID": "7105",
    "Latitude": "66.645",
    "Timezone": "544",
    "DST": "-9",
    "destinations": ["3832", "3420"]
}, {
    "City": "Montreal",
    "DBTZ": "A",
    "Name": "St Hubert",
    "Country": "Canada",
    "IATA/FAA": "YHU",
    "Longitude": "-73.416944",
    "ICAO": "CYHU",
    "Airport ID": "71",
    "Latitude": "45.5175",
    "Timezone": "90",
    "DST": "-5",
    "destinations": ["28", "111", "153", "202"]
}, {
    "City": "Hughes",
    "DBTZ": "A",
    "Name": "Hughes Airport",
    "Country": "United States",
    "IATA/FAA": "HUS",
    "Longitude": "-154.264722",
    "ICAO": "PAHU",
    "Airport ID": "7107",
    "Latitude": "66.039167",
    "Timezone": "299",
    "DST": "-9",
    "destinations": ["3832", "7113"]
}, {
    "City": "Huslia",
    "DBTZ": "A",
    "Name": "Huslia Airport",
    "Country": "United States",
    "IATA/FAA": "HSL",
    "Longitude": "-156.351389",
    "ICAO": "PAHL",
    "Airport ID": "7108",
    "Latitude": "65.697778",
    "Timezone": "213",
    "DST": "-9",
    "destinations": ["3764", "7208", "7107", "6722", "7113"]
}, {
    "City": "Santa Cruz/Graciosa Bay/Luova",
    "DBTZ": "U",
    "Name": "Santa Cruz/Graciosa Bay/Luova Airport",
    "Country": "Solomon Islands",
    "IATA/FAA": "SCZ",
    "Longitude": "165.795",
    "ICAO": "AGGL",
    "Airport ID": "5410",
    "Latitude": "-10.7203",
    "Timezone": "18",
    "DST": "11",
    "destinations": ["4074"]
}, {
    "City": "Silchar",
    "DBTZ": "N",
    "Name": "Silchar",
    "Country": "India",
    "IATA/FAA": "IXS",
    "Longitude": "92.978742",
    "ICAO": "VEKU",
    "Airport ID": "3055",
    "Latitude": "24.912928",
    "Timezone": "352",
    "DST": "5.5",
    "destinations": ["3043", "6173", "3050"]
}, {
    "City": "Ashkhabad",
    "DBTZ": "U",
    "Name": "Ashgabat",
    "Country": "Turkmenistan",
    "IATA/FAA": "ASB",
    "Longitude": "58.360967",
    "ICAO": "UTAA",
    "Airport ID": "2976",
    "Latitude": "37.986814",
    "Timezone": "692",
    "DST": "5",
    "destinations": ["2954", "3399", "2188", "2922", "4029", "3081", "469", "3093", "340", "1701", "507"]
}, {
    "City": "Waterloo",
    "DBTZ": "A",
    "Name": "Waterloo",
    "Country": "Canada",
    "IATA/FAA": "YKF",
    "Longitude": "-80.378611",
    "ICAO": "CYKF",
    "Airport ID": "79",
    "Latitude": "43.460833",
    "Timezone": "1054",
    "DST": "-5",
    "destinations": ["3830", "178"]
}, {
    "City": "Xiamen",
    "DBTZ": "U",
    "Name": "Gaoqi",
    "Country": "China",
    "IATA/FAA": "XMN",
    "Longitude": "118.127739",
    "ICAO": "ZSAM",
    "Airport ID": "3383",
    "Latitude": "24.544036",
    "Timezone": "59",
    "DST": "8",
    "destinations": ["3371", "3395", "4109", "3382", "6379", "3379", "2397", "3372", "3406", "3391", "6347", "2264", "2276", "2268", "2275", "3368", "3370", "3275", "3375", "3393", "3389", "3386", "6380", "6381", "6382", "3394", "3121", "3388", "3373", "2279", "5475", "3364", "4030", "3374", "3390", "4108", "3376", "6391", "6390", "8876", "6355", "3077", "580", "3885", "4120", "6395", "3384", "3304", "3387", "4144", "3316", "6387", "3369", "6403", "6360", "6361", "6394", "6393", "6428", "6356", "6427", "6352", "7503", "6343", "4149", "3930", "6341", "2370", "3404", "3196"]
}, {
    "City": "Mbuji-mayi",
    "DBTZ": "U",
    "Name": "Mbuji Mayi",
    "Country": "Congo (Kinshasa)",
    "IATA/FAA": "MJM",
    "Longitude": "23.569008",
    "ICAO": "FZWA",
    "Airport ID": "1043",
    "Latitude": "-6.121236",
    "Timezone": "2221",
    "DST": "2",
    "destinations": ["1038", "1020"]
}, {
    "City": "Kananga",
    "DBTZ": "U",
    "Name": "Kananga",
    "Country": "Congo (Kinshasa)",
    "IATA/FAA": "KGA",
    "Longitude": "22.469166",
    "ICAO": "FZUA",
    "Airport ID": "1042",
    "Latitude": "-5.900055",
    "Timezone": "2139",
    "DST": "2",
    "destinations": ["1038", "1020", "5652"]
}, {
    "City": "Iles De La Madeleine",
    "DBTZ": "A",
    "Name": "Iles De La Madeleine",
    "Country": "Canada",
    "IATA/FAA": "YGR",
    "Longitude": "-61.778056",
    "ICAO": "CYGR",
    "Airport ID": "65",
    "Latitude": "47.424721",
    "Timezone": "35",
    "DST": "-5",
    "destinations": ["63", "111", "6775"]
}, {
    "City": "Ulan Bator",
    "DBTZ": "U",
    "Name": "Chinggis Khaan Intl",
    "Country": "Mongolia",
    "IATA/FAA": "ULN",
    "Longitude": "106.766639",
    "ICAO": "ZMUB",
    "Airport ID": "3380",
    "Latitude": "47.843056",
    "Timezone": "4364",
    "DST": "8",
    "destinations": ["3364", "6795", "6374", "7470", "7558", "3930", "3885", "6371", "6372", "3077", "6373", "2279", "2985", "2912"]
}, {
    "City": "Bamako",
    "DBTZ": "N",
    "Name": "Senou",
    "Country": "Mali",
    "IATA/FAA": "BKO",
    "Longitude": "-7.949944",
    "ICAO": "GABS",
    "Airport ID": "1044",
    "Latitude": "12.533544",
    "Timezone": "1247",
    "DST": "0",
    "destinations": ["253", "1084", "246", "1382", "210", "1074", "4162", "245", "273", "1107", "298", "4059", "1094", "1638", "287", "1386"]
}, {
    "City": "Sanaa",
    "DBTZ": "U",
    "Name": "Sanaa Intl",
    "Country": "Yemen",
    "IATA/FAA": "SAH",
    "Longitude": "44.219739",
    "ICAO": "OYSN",
    "Airport ID": "3980",
    "Latitude": "15.476258",
    "Timezone": "7216",
    "DST": "3",
    "destinations": ["2188", "2179", "3979", "3977", "3978", "5954", "3976", "3974", "3975", "2057", "1107", "2170", "3967", "2177", "1128", "2072", "4119", "1175", "2176", "4059", "2082", "2241"]
}, {
    "City": "Ankara",
    "DBTZ": "E",
    "Name": "Esenboga",
    "Country": "Turkey",
    "IATA/FAA": "ESB",
    "Longitude": "32.995083",
    "ICAO": "LTAC",
    "Airport ID": "1682",
    "Latitude": "40.128082",
    "Timezone": "3125",
    "DST": "2",
    "destinations": ["1701", "4330", "2922", "3989", "346", "1706", "4315", "344", "345", "5780", "4317", "350", "1613", "1739", "2241", "1685", "5800", "5799", "1688", "1729", "9272", "4130", "6781", "1722", "1723", "1724", "1721", "340", "9044", "8266", "1689", "7453", "9043", "5798", "5796", "1695", "6753", "6782", "6784", "1726", "1728", "2988"]
}, {
    "City": "Adana",
    "DBTZ": "E",
    "Name": "Adana",
    "Country": "Turkey",
    "IATA/FAA": "ADA",
    "Longitude": "35.280388",
    "ICAO": "LTAF",
    "Airport ID": "1685",
    "Latitude": "36.982166",
    "Timezone": "65",
    "DST": "2",
    "destinations": ["1701", "3989", "5780", "1706", "1688", "4317", "1726", "1728", "1682", "2072"]
}, {
    "City": "Brindisi",
    "DBTZ": "E",
    "Name": "Casale",
    "Country": "Italy",
    "IATA/FAA": "BDS",
    "Longitude": "17.947033",
    "ICAO": "LIBR",
    "Airport ID": "1506",
    "Latitude": "40.657633",
    "Timezone": "47",
    "DST": "1",
    "destinations": ["1678", "350", "346", "1555", "1529", "1525", "1538", "1367", "1553", "304", "585", "1222", "1562", "548", "1526", "1539", "4053", "1665", "1524"]
}, {
    "City": "Gaziantep",
    "DBTZ": "E",
    "Name": "Oguzeli",
    "Country": "Turkey",
    "IATA/FAA": "GZT",
    "Longitude": "37.478683",
    "ICAO": "LTAJ",
    "Airport ID": "1689",
    "Latitude": "36.947183",
    "Timezone": "2315",
    "DST": "2",
    "destinations": ["1701", "1706", "5780", "4317", "1682"]
}, {
    "City": "Antalya",
    "DBTZ": "E",
    "Name": "Antalya",
    "Country": "Turkey",
    "IATA/FAA": "AYT",
    "Longitude": "30.800461",
    "ICAO": "LTAI",
    "Airport ID": "1688",
    "Latitude": "36.898731",
    "Timezone": "177",
    "DST": "2",
    "destinations": ["1678", "344", "1701", "2990", "2985", "338", "345", "341", "342", "348", "346", "347", "371", "350", "351", "421", "340", "352", "337", "636", "644", "665", "664", "1609", "1612", "1613", "302", "580", "585", "586", "591", "629", "1685", "1706", "608", "609", "5780", "1665", "4317", "1726", "1587", "353", "339", "382", "400", "772", "2948", "2964", "469", "534", "502", "478", "4130", "3989", "1682", "2988", "490", "492", "1386", "4053", "6510", "1611", "517"]
}, {
    "City": "Ogdensburg",
    "DBTZ": "A",
    "Name": "Ogdensburg Intl",
    "Country": "United States",
    "IATA/FAA": "OGS",
    "Longitude": "-75.4655",
    "ICAO": "KOGS",
    "Airport ID": "3724",
    "Latitude": "44.681854",
    "Timezone": "297",
    "DST": "-5",
    "destinations": ["3864"]
}, {
    "City": "Shantou",
    "DBTZ": "U",
    "Name": "Wai Sha Airport",
    "Country": "China",
    "IATA/FAA": "SWA",
    "Longitude": "116.683",
    "ICAO": "ZGOW",
    "Airport ID": "4302",
    "Latitude": "23.4",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3316", "3393", "3391", "3395", "3364", "3885", "3370", "3371", "3077", "3394", "3372", "3387", "3388", "3373", "4030", "3376", "6394", "6355", "3375", "4120", "3389", "3386", "6357"]
}, {
    "City": "Jiuzhaigou",
    "DBTZ": "U",
    "Name": "Jiuzhaigou Huanglong",
    "Country": "China",
    "IATA/FAA": "JZH",
    "Longitude": "103.683",
    "ICAO": "ZUJZ",
    "Airport ID": "4301",
    "Latitude": "32.857",
    "Timezone": "11311",
    "DST": "8",
    "destinations": ["3395", "3370", "3393", "3406", "3379"]
}, {
    "City": "Bukhara",
    "DBTZ": "U",
    "Name": "Bukhara",
    "Country": "Uzbekistan",
    "IATA/FAA": "BHK",
    "Longitude": "64.483333",
    "ICAO": "UTSB",
    "Airport ID": "2980",
    "Latitude": "39.775",
    "Timezone": "751",
    "DST": "5",
    "destinations": ["4029", "2948", "2983"]
}, {
    "City": "Georgetown",
    "DBTZ": "U",
    "Name": "Ogle",
    "Country": "Guyana",
    "IATA/FAA": "OGL",
    "Longitude": "-58.104444",
    "ICAO": "SYGO",
    "Airport ID": "4306",
    "Latitude": "6.806944",
    "Timezone": "10",
    "DST": "-4",
    "destinations": ["2875"]
}, {
    "City": "Krakow",
    "DBTZ": "E",
    "Name": "Balice",
    "Country": "Poland",
    "IATA/FAA": "KRK",
    "Longitude": "19.784836",
    "ICAO": "EPKK",
    "Airport ID": "669",
    "Latitude": "50.077731",
    "Timezone": "791",
    "DST": "1",
    "destinations": ["350", "351", "421", "737", "636", "609", "644", "666", "665", "1230", "1212", "1525", "469", "1538", "1367", "1519", "1553", "304", "373", "599", "535", "585", "523", "1222", "1458", "517", "491", "1229", "478", "1606", "699", "1198", "1562", "657", "603", "548", "1515", "580", "668", "1678", "340", "346", "1613", "679", "521", "2985", "465", "490", "4053", "1382", "502", "508"]
}, {
    "City": "Gdansk",
    "DBTZ": "E",
    "Name": "Lech Walesa",
    "Country": "Poland",
    "IATA/FAA": "GDN",
    "Longitude": "18.466222",
    "ICAO": "EPGD",
    "Airport ID": "668",
    "Latitude": "54.377569",
    "Timezone": "489",
    "DST": "1",
    "destinations": ["351", "609", "644", "469", "490", "599", "535", "517", "478", "596", "1562", "657", "548", "8414", "580", "302", "421", "669", "8832", "680", "1678", "340", "346", "679", "630", "1218", "636", "1525", "1367", "344", "5562", "373", "585", "1555", "534", "691", "645", "638", "364", "491", "492", "708", "699", "666", "460", "665", "664"]
}, {
    "City": "Agen",
    "DBTZ": "E",
    "Name": "La Garenne",
    "Country": "France",
    "IATA/FAA": "AGF",
    "Longitude": "0.590556",
    "ICAO": "LFBA",
    "Airport ID": "1262",
    "Latitude": "44.174721",
    "Timezone": "204",
    "DST": "1",
    "destinations": ["1285", "1386"]
}, {
    "City": "Trondheim",
    "DBTZ": "E",
    "Name": "Vaernes",
    "Country": "Norway",
    "IATA/FAA": "TRD",
    "Longitude": "10.92425",
    "ICAO": "ENVA",
    "Airport ID": "665",
    "Latitude": "63.457556",
    "Timezone": "56",
    "DST": "1",
    "destinations": ["609", "580", "1230", "1212", "1688", "636", "1200", "641", "669", "502", "1354", "644", "3953", "337", "663", "664", "16", "415", "630", "737", "635", "666", "668", "634", "638", "647", "652", "5582", "5581", "5583", "662"]
}, {
    "City": "Sandefjord",
    "DBTZ": "E",
    "Name": "Torp",
    "Country": "Norway",
    "IATA/FAA": "TRF",
    "Longitude": "10.258628",
    "ICAO": "ENTO",
    "Airport ID": "664",
    "Latitude": "59.186703",
    "Timezone": "286",
    "DST": "1",
    "destinations": ["737", "580", "1230", "1212", "1688", "1218", "636", "1200", "502", "1054", "1354", "3998", "665", "1517", "491", "1562", "548", "609", "666", "1739", "668", "671", "8832", "1657", "674", "3953", "676", "3959", "679", "680"]
}, {
    "City": "Tromso",
    "DBTZ": "E",
    "Name": "Langnes",
    "Country": "Norway",
    "IATA/FAA": "TOS",
    "Longitude": "18.918919",
    "ICAO": "ENTC",
    "Airport ID": "663",
    "Latitude": "69.683333",
    "Timezone": "31",
    "DST": "1",
    "destinations": ["2949", "1212", "632", "502", "644", "665", "737", "635", "658", "666", "631", "636", "641", "646", "4325", "649", "653", "4252", "5588", "4328"]
}, {
    "City": "Sandnessjoen",
    "DBTZ": "E",
    "Name": "Stokka",
    "Country": "Norway",
    "IATA/FAA": "SSJ",
    "Longitude": "12.468944",
    "ICAO": "ENST",
    "Airport ID": "662",
    "Latitude": "65.956828",
    "Timezone": "56",
    "DST": "1",
    "destinations": ["634", "635", "644", "665"]
}, {
    "City": "Bergerac",
    "DBTZ": "E",
    "Name": "Roumaniere",
    "Country": "France",
    "IATA/FAA": "EGC",
    "Longitude": "0.518611",
    "ICAO": "LFBE",
    "Airport ID": "1265",
    "Latitude": "44.825279",
    "Timezone": "171",
    "DST": "1",
    "destinations": ["552", "495", "490", "304", "523", "491", "548", "591", "1279"]
}, {
    "City": "Bordeaux",
    "DBTZ": "E",
    "Name": "Merignac",
    "Country": "France",
    "IATA/FAA": "BOD",
    "Longitude": "-0.715556",
    "ICAO": "LFBD",
    "Airport ID": "1264",
    "Latitude": "44.828335",
    "Timezone": "162",
    "DST": "1",
    "destinations": ["1678", "1452", "1555", "1399", "1335", "1354", "1418", "1435", "580", "1382", "1353", "1386", "210", "1074", "1075", "502", "1403", "1415", "599", "1665", "1538", "1553", "304", "535", "1636", "548", "1253", "1218", "1229", "3998", "1638", "146", "287", "490", "302", "4053", "492", "1524", "1230", "1324", "1321", "1322", "1563", "1323", "346", "1520", "1512", "1056", "1551"]
}, {
    "City": "Villa Garzon",
    "DBTZ": "U",
    "Name": "Villa Garzon Airport",
    "Country": "Colombia",
    "IATA/FAA": "VGZ",
    "Longitude": "-76.605556",
    "ICAO": "SKVG",
    "Airport ID": "7359",
    "Latitude": "0.978889",
    "Timezone": "1248",
    "DST": "-5",
    "destinations": ["2709"]
}, {
    "City": "Lodja",
    "DBTZ": "N",
    "Name": "Lodja Airport",
    "Country": "Congo (Kinshasa)",
    "IATA/FAA": "LJA",
    "Longitude": "23.45",
    "ICAO": "FZVA",
    "Airport ID": "5653",
    "Latitude": "-3.417",
    "Timezone": "1647",
    "DST": "2",
    "destinations": ["1020"]
}, {
    "City": "Rundu",
    "DBTZ": "S",
    "Name": "Rundu",
    "Country": "Namibia",
    "IATA/FAA": "NDU",
    "Longitude": "19.719439",
    "ICAO": "FYRU",
    "Airport ID": "7634",
    "Latitude": "-17.956461",
    "Timezone": "3627",
    "DST": "1",
    "destinations": ["5645", "6779"]
}, {
    "City": "La Macarena",
    "DBTZ": "U",
    "Name": "La Macarena",
    "Country": "Colombia",
    "IATA/FAA": "LMC",
    "Longitude": "-73.7875",
    "ICAO": "",
    "Airport ID": "7358",
    "Latitude": "2.179167",
    "Timezone": "10",
    "DST": "-5",
    "destinations": ["2756"]
}, {
    "City": "Yuzhno-Kurilsk",
    "DBTZ": "N",
    "Name": "Mendeleevo",
    "Country": "Russia",
    "IATA/FAA": "DEE",
    "Longitude": "145.4107",
    "ICAO": "YXCM",
    "Airport ID": "8782",
    "Latitude": "43.5739",
    "Timezone": "50",
    "DST": "12",
    "destinations": ["2933"]
}, {
    "City": "Qianjiang",
    "DBTZ": "N",
    "Name": "Zhoubai",
    "Country": "China",
    "IATA/FAA": "JIQ",
    "Longitude": "108.83",
    "ICAO": "",
    "Airport ID": "8781",
    "Latitude": "29.515",
    "Timezone": "2461",
    "DST": "8",
    "destinations": ["3395", "3382", "3391", "3370", "3393", "3364"]
}, {
    "City": "Dryden",
    "DBTZ": "A",
    "Name": "Dryden Rgnl",
    "Country": "Canada",
    "IATA/FAA": "YHD",
    "Longitude": "-92.744167",
    "ICAO": "CYHD",
    "Airport ID": "67",
    "Latitude": "49.831667",
    "Timezone": "1354",
    "DST": "-6",
    "destinations": ["115", "121", "5527", "169"]
}, {
    "City": "Biratnagar",
    "DBTZ": "N",
    "Name": "Biratnagar",
    "Country": "Nepal",
    "IATA/FAA": "BIR",
    "Longitude": "87.264036",
    "ICAO": "VNVT",
    "Airport ID": "3129",
    "Latitude": "26.481453",
    "Timezone": "236",
    "DST": "5.75",
    "destinations": ["3125"]
}, {
    "City": "Santa Ana",
    "DBTZ": "U",
    "Name": "Santa Ana Airport",
    "Country": "Solomon Islands",
    "IATA/FAA": "NNB",
    "Longitude": "162.454167",
    "ICAO": "AGGT",
    "Airport ID": "7618",
    "Latitude": "-10.848056",
    "Timezone": "6",
    "DST": "11",
    "destinations": ["4074", "5409"]
}, {
    "City": "Bhairawa",
    "DBTZ": "N",
    "Name": "Bhairahawa",
    "Country": "Nepal",
    "IATA/FAA": "BWA",
    "Longitude": "83.41625",
    "ICAO": "VNBW",
    "Airport ID": "3122",
    "Latitude": "27.505703",
    "Timezone": "358",
    "DST": "5.75",
    "destinations": ["3125"]
}, {
    "City": "Bardufoss",
    "DBTZ": "E",
    "Name": "Bardufoss",
    "Country": "Norway",
    "IATA/FAA": "BDU",
    "Longitude": "18.540356",
    "ICAO": "ENDU",
    "Airport ID": "640",
    "Latitude": "69.055758",
    "Timezone": "252",
    "DST": "1",
    "destinations": ["644"]
}, {
    "City": "Ulawa",
    "DBTZ": "U",
    "Name": "Ulawa Airport",
    "Country": "Solomon Islands",
    "IATA/FAA": "RNA",
    "Longitude": "161.979167",
    "ICAO": "AGAR",
    "Airport ID": "7616",
    "Latitude": "-9.854722",
    "Timezone": "5",
    "DST": "11",
    "destinations": ["4074", "5409"]
}, {
    "City": "Choiseul Bay",
    "DBTZ": "U",
    "Name": "Choiseul Bay Airport",
    "Country": "Solomon Islands",
    "IATA/FAA": "CHY",
    "Longitude": "156.396111",
    "ICAO": "AGGC",
    "Airport ID": "7617",
    "Latitude": "-6.711944",
    "Timezone": "5",
    "DST": "11",
    "destinations": ["5412"]
}, {
    "City": "Pokhara",
    "DBTZ": "N",
    "Name": "Pokhara",
    "Country": "Nepal",
    "IATA/FAA": "PKR",
    "Longitude": "83.982056",
    "ICAO": "VNPK",
    "Airport ID": "3127",
    "Latitude": "28.200881",
    "Timezone": "2712",
    "DST": "5.75",
    "destinations": ["3125"]
}, {
    "City": "Kathmandu",
    "DBTZ": "N",
    "Name": "Tribhuvan Intl",
    "Country": "Nepal",
    "IATA/FAA": "KTM",
    "Longitude": "85.3591",
    "ICAO": "VNKT",
    "Airport ID": "3125",
    "Latitude": "27.696583",
    "Timezone": "4390",
    "DST": "5.75",
    "destinations": ["3076", "3093", "2997", "3043", "3084", "3395", "4097", "3370", "3304", "2179", "8076", "2188", "2191", "3155", "3930", "3316", "3382", "2206", "2241", "3885", "3077", "1701", "2194", "6180", "6179", "3129", "3122", "4188", "6182", "3127", "4183"]
}, {
    "City": "Mytilini",
    "DBTZ": "E",
    "Name": "Mitilini",
    "Country": "Greece",
    "IATA/FAA": "MJT",
    "Longitude": "26.598333",
    "ICAO": "LGMT",
    "Airport ID": "1469",
    "Latitude": "39.056667",
    "Timezone": "60",
    "DST": "2",
    "destinations": ["3941", "1486", "345", "346", "1450", "1465", "1472", "580"]
}, {
    "City": "Mykonos",
    "DBTZ": "E",
    "Name": "Mikonos",
    "Country": "Greece",
    "IATA/FAA": "JMK",
    "Longitude": "25.348103",
    "ICAO": "LGMK",
    "Airport ID": "1468",
    "Latitude": "37.435128",
    "Timezone": "405",
    "DST": "2",
    "destinations": ["344", "3941", "1613", "507", "1479", "502", "1665", "478", "1524"]
}, {
    "City": "Limnos",
    "DBTZ": "E",
    "Name": "Limnos",
    "Country": "Greece",
    "IATA/FAA": "LXS",
    "Longitude": "25.236308",
    "ICAO": "LGLM",
    "Airport ID": "1465",
    "Latitude": "39.917072",
    "Timezone": "14",
    "DST": "2",
    "destinations": ["3941", "4191", "1469", "1486"]
}, {
    "City": "Leros",
    "DBTZ": "E",
    "Name": "Leros",
    "Country": "Greece",
    "IATA/FAA": "LRS",
    "Longitude": "26.800289",
    "ICAO": "LGLE",
    "Airport ID": "1464",
    "Latitude": "37.184903",
    "Timezone": "39",
    "DST": "2",
    "destinations": ["3941", "4192", "4190"]
}, {
    "City": "Kasos",
    "DBTZ": "E",
    "Name": "Kasos",
    "Country": "Greece",
    "IATA/FAA": "KSJ",
    "Longitude": "26.910047",
    "ICAO": "LGKS",
    "Airport ID": "1461",
    "Latitude": "35.421358",
    "Timezone": "35",
    "DST": "2",
    "destinations": ["1459", "1480"]
}, {
    "City": "Kerkyra/corfu",
    "DBTZ": "E",
    "Name": "Ioannis Kapodistrias Intl",
    "Country": "Greece",
    "IATA/FAA": "CFU",
    "Longitude": "19.911667",
    "ICAO": "LGKR",
    "Airport ID": "1460",
    "Latitude": "39.601944",
    "Timezone": "6",
    "DST": "2",
    "destinations": ["344", "338", "350", "1486", "3941", "4029", "345", "341", "340", "342", "346", "351", "1613", "599", "608", "353", "1553", "304", "535", "585", "523", "517", "478", "4198", "537", "657", "548", "1470", "302", "1399", "580", "1455", "629", "490", "502", "492", "352"]
}, {
    "City": "Kavala",
    "DBTZ": "E",
    "Name": "Megas Alexandros Intl",
    "Country": "Greece",
    "IATA/FAA": "KVA",
    "Longitude": "24.619223",
    "ICAO": "LGKV",
    "Airport ID": "1462",
    "Latitude": "40.913306",
    "Timezone": "18",
    "DST": "2",
    "destinations": ["344", "350", "3941", "345", "346"]
}, {
    "City": "Le Puy",
    "DBTZ": "E",
    "Name": "Loudes",
    "Country": "France",
    "IATA/FAA": "LPY",
    "Longitude": "3.762889",
    "ICAO": "LFHP",
    "Airport ID": "1313",
    "Latitude": "45.080689",
    "Timezone": "2731",
    "DST": "1",
    "destinations": ["1386"]
}, {
    "City": "Lynchburg",
    "DBTZ": "A",
    "Name": "Lynchburg Regional Preston Glenn Field",
    "Country": "United States",
    "IATA/FAA": "LYH",
    "Longitude": "-79.2004",
    "ICAO": "KLYH",
    "Airport ID": "5753",
    "Latitude": "37.3267",
    "Timezone": "938",
    "DST": "-5",
    "destinations": ["3876"]
}, {
    "City": "Lancaster",
    "DBTZ": "A",
    "Name": "Lancaster Airport",
    "Country": "United States",
    "IATA/FAA": "LNS",
    "Longitude": "-76.2961",
    "ICAO": "KLNS",
    "Airport ID": "5751",
    "Latitude": "40.1217",
    "Timezone": "403",
    "DST": "-5",
    "destinations": ["3714"]
}, {
    "City": "Klamath Falls",
    "DBTZ": "A",
    "Name": "Klamath Falls Airport",
    "Country": "United States",
    "IATA/FAA": "LMT",
    "Longitude": "-121.733",
    "ICAO": "KLMT",
    "Airport ID": "5750",
    "Latitude": "42.1561",
    "Timezone": "4095",
    "DST": "-8",
    "destinations": ["3720", "3469"]
}, {
    "City": "North Bend",
    "DBTZ": "A",
    "Name": "Southwest Oregon Regional Airport",
    "Country": "United States",
    "IATA/FAA": "OTH",
    "Longitude": "-124.246",
    "ICAO": "KOTH",
    "Airport ID": "5757",
    "Latitude": "43.4171",
    "Timezone": "17",
    "DST": "-8",
    "destinations": ["3720", "3469"]
}, {
    "City": "Dibrugarh",
    "DBTZ": "N",
    "Name": "Dibrugarh Airport",
    "Country": "India",
    "IATA/FAA": "DIB",
    "Longitude": "95.0169",
    "ICAO": "",
    "Airport ID": "6443",
    "Latitude": "27.4839",
    "Timezone": "362",
    "DST": "5.5",
    "destinations": ["3043", "6173", "6174"]
}, {
    "City": "Fort Good Hope",
    "DBTZ": "A",
    "Name": "Fort Good Hope",
    "Country": "Canada",
    "IATA/FAA": "YGH",
    "Longitude": "-128.65",
    "ICAO": "CYGH",
    "Airport ID": "4239",
    "Latitude": "66.26666667",
    "Timezone": "215",
    "DST": "-7",
    "destinations": ["54", "155"]
}, {
    "City": "Tulita",
    "DBTZ": "A",
    "Name": "Tulita",
    "Country": "Canada",
    "IATA/FAA": "ZFN",
    "Longitude": "-125.5833333",
    "ICAO": "CZFN",
    "Airport ID": "4238",
    "Latitude": "64.0833333",
    "Timezone": "320",
    "DST": "-7",
    "destinations": ["155", "4237"]
}, {
    "City": "Deline",
    "DBTZ": "A",
    "Name": "Deline",
    "Country": "Canada",
    "IATA/FAA": "YWJ",
    "Longitude": "-125.41666667",
    "ICAO": "CYWJ",
    "Airport ID": "4237",
    "Latitude": "65.1833333",
    "Timezone": "550",
    "DST": "-7",
    "destinations": ["196", "4238"]
}, {
    "City": "Kaadedhdhoo",
    "DBTZ": "U",
    "Name": "Kaadedhdhoo",
    "Country": "Maldives",
    "IATA/FAA": "KDM",
    "Longitude": "72.995556",
    "ICAO": "",
    "Airport ID": "4235",
    "Latitude": "0.4880555",
    "Timezone": "3",
    "DST": "5",
    "destinations": ["6183", "3156"]
}, {
    "City": "Owensboro",
    "DBTZ": "A",
    "Name": "Owensboro Daviess County Airport",
    "Country": "United States",
    "IATA/FAA": "OWB",
    "Longitude": "-87.1668",
    "ICAO": "KOWB",
    "Airport ID": "5758",
    "Latitude": "37.7401",
    "Timezone": "406",
    "DST": "-6",
    "destinations": ["3678", "4167"]
}, {
    "City": "Bellona",
    "DBTZ": "U",
    "Name": "Bellona",
    "Country": "Solomon Islands",
    "IATA/FAA": "BNY",
    "Longitude": "159.8",
    "ICAO": "",
    "Airport ID": "6919",
    "Latitude": "-11.302",
    "Timezone": "3",
    "DST": "11",
    "destinations": ["4074", "5414"]
}, {
    "City": "Grand Canyon West",
    "DBTZ": "U",
    "Name": "Grand Canyon West Airport",
    "Country": "United States",
    "IATA/FAA": "GCW",
    "Longitude": "-113.4859",
    "ICAO": "",
    "Airport ID": "6448",
    "Latitude": "35.5925",
    "Timezone": "4825",
    "DST": "-7",
    "destinations": ["6449"]
}, {
    "City": "Boulder City",
    "DBTZ": "U",
    "Name": "Boulder City Municipal Airport",
    "Country": "United States",
    "IATA/FAA": "BLD",
    "Longitude": "-114.514",
    "ICAO": "KBVU",
    "Airport ID": "6449",
    "Latitude": "35.5651",
    "Timezone": "2201",
    "DST": "-7",
    "destinations": ["6448"]
}, {
    "City": "Bydgoszcz",
    "DBTZ": "E",
    "Name": "Bydgoszcz Ignacy Jan Paderewski Airport",
    "Country": "Poland",
    "IATA/FAA": "BZG",
    "Longitude": "17.9777",
    "ICAO": "EPBY",
    "Airport ID": "5591",
    "Latitude": "53.0968",
    "Timezone": "235",
    "DST": "1",
    "destinations": ["469", "599", "548"]
}, {
    "City": "Ostersund",
    "DBTZ": "E",
    "Name": "Ostersund Airport",
    "Country": "Sweden",
    "IATA/FAA": "OSD",
    "Longitude": "14.5003",
    "ICAO": "ESNZ",
    "Airport ID": "5593",
    "Latitude": "63.1944",
    "Timezone": "1233",
    "DST": "1",
    "destinations": ["728", "737", "738"]
}, {
    "City": "Karlstad",
    "DBTZ": "E",
    "Name": "Karlstad Airport",
    "Country": "Sweden",
    "IATA/FAA": "KSD",
    "Longitude": "13.3374",
    "ICAO": "ESOK",
    "Airport ID": "5595",
    "Latitude": "59.4447",
    "Timezone": "352",
    "DST": "1",
    "destinations": ["737", "1212"]
}, {
    "City": "Hagfors",
    "DBTZ": "E",
    "Name": "Hagfors Airport",
    "Country": "Sweden",
    "IATA/FAA": "HFS",
    "Longitude": "13.578908",
    "ICAO": "ESOH",
    "Airport ID": "5594",
    "Latitude": "60.020064",
    "Timezone": "474",
    "DST": "1",
    "destinations": ["737", "5596"]
}, {
    "City": "Angelholm",
    "DBTZ": "E",
    "Name": "Angelholm-Helsingborg Airport",
    "Country": "Sweden",
    "IATA/FAA": "AGH",
    "Longitude": "12.8471",
    "ICAO": "ESTA",
    "Airport ID": "5597",
    "Latitude": "56.2961",
    "Timezone": "68",
    "DST": "1",
    "destinations": ["738", "737"]
}, {
    "City": "Torsby",
    "DBTZ": "E",
    "Name": "Torsby Airport",
    "Country": "Sweden",
    "IATA/FAA": "TYF",
    "Longitude": "12.991269",
    "ICAO": "ESST",
    "Airport ID": "5596",
    "Latitude": "60.157622",
    "Timezone": "393",
    "DST": "1",
    "destinations": ["5594", "415"]
}, {
    "City": "Hemavan",
    "DBTZ": "E",
    "Name": "Hemavan Airport",
    "Country": "Sweden",
    "IATA/FAA": "HMV",
    "Longitude": "15.082778",
    "ICAO": "ESUT",
    "Airport ID": "5599",
    "Latitude": "65.806111",
    "Timezone": "1503",
    "DST": "1",
    "destinations": ["729"]
}, {
    "City": "Kuala Lumpur",
    "DBTZ": "N",
    "Name": "Kuala Lumpur Intl",
    "Country": "Malaysia",
    "IATA/FAA": "KUL",
    "Longitude": "101.709917",
    "ICAO": "WMKK",
    "Airport ID": "3304",
    "Latitude": "2.745578",
    "Timezone": "69",
    "DST": "8",
    "destinations": ["3316", "3076", "2397", "3239", "3131", "2997", "3093", "3141", "3144", "3885", "1382", "340", "3305", "507", "2279", "3308", "580", "3275", "3296", "3896", "3269", "3919", "3294", "3262", "3272", "3370", "3043", "3931", "3136", "3157", "3940", "3199", "3181", "3077", "3179", "3303", "3898", "3298", "3174", "3263", "2430", "3382", "3372", "3270", "8401", "3121", "3266", "3373", "3278", "3273", "3289", "3034", "3035", "3267", "4141", "3205", "3929", "3901", "3928", "3374", "3307", "3152", "3271", "3240", "4000", "3120", "2179", "2276", "3385", "3406", "3383", "3341", "3024", "3395", "3386", "2359", "3930", "2072", "3992", "3125", "3339", "3942", "3321", "3364", "3351", "2372", "3361", "2188", "2983", "4330", "3377", "2908", "2176", "2006", "3320", "2241", "3999", "8076", "1701", "3299", "2194", "3156", "893", "3177", "2994", "3069", "2206", "2207", "2219", "2074", "2082", "4206"]
}, {
    "City": "Monterey",
    "DBTZ": "A",
    "Name": "Monterey Peninsula",
    "Country": "United States",
    "IATA/FAA": "MRY",
    "Longitude": "-121.842944",
    "ICAO": "KMRY",
    "Airport ID": "3948",
    "Latitude": "36.587",
    "Timezone": "257",
    "DST": "-8",
    "destinations": ["3484", "3462", "3731", "3877", "3751", "3469"]
}, {
    "City": "Santa Barbara",
    "DBTZ": "A",
    "Name": "Santa Barbara Muni",
    "Country": "United States",
    "IATA/FAA": "SBA",
    "Longitude": "-119.840372",
    "ICAO": "KSBA",
    "Airport ID": "3949",
    "Latitude": "34.426211",
    "Timezone": "10",
    "DST": "-8",
    "destinations": ["3462", "3720", "3577", "3751", "3484", "3469"]
}, {
    "City": "Denpasar",
    "DBTZ": "N",
    "Name": "Bali Ngurah Rai",
    "Country": "Indonesia",
    "IATA/FAA": "DPS",
    "Longitude": "115.167172",
    "ICAO": "WADD",
    "Airport ID": "3940",
    "Latitude": "-8.748169",
    "Timezone": "14",
    "DST": "8",
    "destinations": ["2397", "3304", "2276", "3077", "3370", "3374", "3157", "3896", "3924", "3320", "3275", "2359", "3930", "3898", "3992", "3916", "3917", "8401", "3339", "2279", "3351", "3289", "3316", "3901", "3928", "3361", "3243", "6204", "3240", "3341", "3999", "3903", "3894", "3913", "3927", "3406", "5", "3919", "3269", "3310", "3885", "3349"]
}, {
    "City": "Athens",
    "DBTZ": "E",
    "Name": "Eleftherios Venizelos Intl",
    "Country": "Greece",
    "IATA/FAA": "ATH",
    "Longitude": "23.944467",
    "ICAO": "LGAV",
    "Airport ID": "3941",
    "Latitude": "37.936358",
    "Timezone": "308",
    "DST": "2",
    "destinations": ["344", "350", "1735", "1480", "1459", "2179", "1446", "1218", "1739", "302", "1489", "1128", "1382", "1460", "1474", "4029", "345", "1455", "1555", "340", "1665", "3410", "1452", "2944", "1451", "1701", "4191", "1450", "4192", "1468", "4194", "1475", "5787", "1479", "4190", "1458", "1454", "1462", "1197", "2948", "507", "1464", "1465", "1335", "1229", "478", "1469", "4193", "1353", "346", "1524", "1418", "644", "1657", "4195", "3752", "1587", "1472", "1486", "1482", "1476", "1194", "3973", "1190", "1590", "351", "1613", "679", "1488", "1273", "1551", "3953", "2241", "737", "609", "421", "599", "2188", "1525", "1198", "548", "1453", "1463", "580", "585", "1606", "1678", "2177", "1200", "1206", "1706", "4317", "2939", "3964", "2170", "2985", "1386", "146", "193", "535", "342", "502", "337", "2960"]
}, {
    "City": "Nagoya",
    "DBTZ": "U",
    "Name": "Chubu Centrair Intl",
    "Country": "Japan",
    "IATA/FAA": "NGO",
    "Longitude": "136.805408",
    "ICAO": "RJGG",
    "Airport ID": "3942",
    "Latitude": "34.858414",
    "Timezone": "15",
    "DST": "9",
    "destinations": ["2397", "2378", "3930", "3728", "2279", "421", "2287", "2281", "2384", "3364", "3406", "2276", "3077", "3404", "4144", "3304", "3645", "2246", "2305", "2310", "2347", "2307", "3885", "3199", "2359", "2372", "3390", "3368", "2370", "340", "2299", "2345", "2288", "2387", "4381", "2308", "2312", "2290", "2333", "2313", "3316", "3205"]
}, {
    "City": "Ostend",
    "DBTZ": "E",
    "Name": "Oostende",
    "Country": "Belgium",
    "IATA/FAA": "OST",
    "Longitude": "2.862222",
    "ICAO": "EBOS",
    "Airport ID": "310",
    "Latitude": "51.198889",
    "Timezone": "13",
    "DST": "1",
    "destinations": ["309"]
}, {
    "City": "Pullman",
    "DBTZ": "A",
    "Name": "Pullman-Moscow Rgnl",
    "Country": "United States",
    "IATA/FAA": "PUW",
    "Longitude": "-117.109583",
    "ICAO": "KPUW",
    "Airport ID": "3944",
    "Latitude": "46.743861",
    "Timezone": "2556",
    "DST": "-8",
    "destinations": ["3945", "3577"]
}, {
    "City": "Lewiston",
    "DBTZ": "A",
    "Name": "Lewiston Nez Perce Co",
    "Country": "United States",
    "IATA/FAA": "LWS",
    "Longitude": "-117.015389",
    "ICAO": "KLWS",
    "Airport ID": "3945",
    "Latitude": "46.3745",
    "Timezone": "1442",
    "DST": "-8",
    "destinations": ["3495", "3944", "3577", "3536"]
}, {
    "City": "Elmira",
    "DBTZ": "A",
    "Name": "Elmira Corning Rgnl",
    "Country": "United States",
    "IATA/FAA": "ELM",
    "Longitude": "-76.891611",
    "ICAO": "KELM",
    "Airport ID": "3946",
    "Latitude": "42.159889",
    "Timezone": "954",
    "DST": "-5",
    "destinations": ["3752", "3645", "3617", "4167", "3830"]
}, {
    "City": "Ithaca",
    "DBTZ": "A",
    "Name": "Ithaca Tompkins Rgnl",
    "Country": "United States",
    "IATA/FAA": "ITH",
    "Longitude": "-76.458444",
    "ICAO": "KITH",
    "Airport ID": "3947",
    "Latitude": "42.491028",
    "Timezone": "1099",
    "DST": "-5",
    "destinations": ["3752", "3645", "3494"]
}, {
    "City": "Pori",
    "DBTZ": "E",
    "Name": "Pori",
    "Country": "Finland",
    "IATA/FAA": "POR",
    "Longitude": "21.799983",
    "ICAO": "EFPO",
    "Airport ID": "448",
    "Latitude": "61.461686",
    "Timezone": "44",
    "DST": "2",
    "destinations": ["737", "435", "421"]
}, {
    "City": "Grand Forks",
    "DBTZ": "A",
    "Name": "Grand Forks Intl",
    "Country": "United States",
    "IATA/FAA": "GFK",
    "Longitude": "-97.176111",
    "ICAO": "KGFK",
    "Airport ID": "3442",
    "Latitude": "47.949256",
    "Timezone": "845",
    "DST": "-6",
    "destinations": ["7018", "3858", "6505", "3877", "4167"]
}, {
    "City": "Villavicencio",
    "DBTZ": "U",
    "Name": "Vanguardia",
    "Country": "Colombia",
    "IATA/FAA": "VVC",
    "Longitude": "-73.613761",
    "ICAO": "SKVV",
    "Airport ID": "2756",
    "Latitude": "4.167875",
    "Timezone": "1394",
    "DST": "-5",
    "destinations": ["2709", "7358", "2732", "2738", "6054"]
}, {
    "City": "Binghamton",
    "DBTZ": "A",
    "Name": "Greater Binghamton Edwin A Link Fld",
    "Country": "United States",
    "IATA/FAA": "BGM",
    "Longitude": "-75.979839",
    "ICAO": "KBGM",
    "Airport ID": "4129",
    "Latitude": "42.208689",
    "Timezone": "1636",
    "DST": "-5",
    "destinations": ["3752", "3645", "3714"]
}, {
    "City": "Saginaw",
    "DBTZ": "A",
    "Name": "Mbs Intl",
    "Country": "United States",
    "IATA/FAA": "MBS",
    "Longitude": "-84.079647",
    "ICAO": "KMBS",
    "Airport ID": "4128",
    "Latitude": "43.532913",
    "Timezone": "668",
    "DST": "-5",
    "destinations": ["3682", "3645", "3858", "3830"]
}, {
    "City": "Huangshan",
    "DBTZ": "U",
    "Name": "Tunxi International Airport",
    "Country": "China",
    "IATA/FAA": "TXN",
    "Longitude": "118.256",
    "ICAO": "ZSTX",
    "Airport ID": "6387",
    "Latitude": "29.7333",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["2276", "3364", "3370", "3371", "3404", "3374", "3390", "3369", "3383", "3393", "3395", "3391", "4120", "3368", "3379", "3930", "3389", "2372"]
}, {
    "City": "Haikou",
    "DBTZ": "U",
    "Name": "Meilan",
    "Country": "China",
    "IATA/FAA": "HAK",
    "Longitude": "110.458961",
    "ICAO": "ZJHK",
    "Airport ID": "4120",
    "Latitude": "19.934856",
    "Timezone": "75",
    "DST": "8",
    "destinations": ["3316", "3393", "3395", "3386", "3387", "6355", "3382", "3370", "3375", "3385", "3388", "3364", "3374", "3368", "2276", "3077", "3371", "3373", "3406", "3376", "3383", "6427", "6351", "6352", "3394", "3372", "6399", "4302", "6387", "6357", "3391", "6343", "3389", "3384", "3369", "3379", "6392"]
}, {
    "City": "Kalispell",
    "DBTZ": "A",
    "Name": "Glacier Park Intl",
    "Country": "United States",
    "IATA/FAA": "FCA",
    "Longitude": "-114.256",
    "ICAO": "KFCA",
    "Airport ID": "4127",
    "Latitude": "48.310472",
    "Timezone": "2977",
    "DST": "-7",
    "destinations": ["3577", "3858", "3536", "3877", "3751"]
}, {
    "City": "Santa Elena de Uairen",
    "DBTZ": "U",
    "Name": "Santa Elena Airport",
    "Country": "Venezuela",
    "IATA/FAA": "SNV",
    "Longitude": "-61.144922",
    "ICAO": "",
    "Airport ID": "4126",
    "Latitude": "4.554722",
    "Timezone": "3585",
    "DST": "-4.5",
    "destinations": ["2856"]
}, {
    "City": "Page",
    "DBTZ": "A",
    "Name": "Page Municipal Airport",
    "Country": "United States",
    "IATA/FAA": "PGA",
    "Longitude": "-111.4483",
    "ICAO": "KPGA",
    "Airport ID": "4124",
    "Latitude": "36.9261",
    "Timezone": "4316",
    "DST": "-7",
    "destinations": ["3751", "4219", "6132", "3462"]
}, {
    "City": "Galcaio",
    "DBTZ": "U",
    "Name": "Galcaio Airport",
    "Country": "Somalia",
    "IATA/FAA": "GLK",
    "Longitude": "47.4547",
    "ICAO": "HCMR",
    "Airport ID": "5688",
    "Latitude": "6.78083",
    "Timezone": "975",
    "DST": "3",
    "destinations": ["5686", "5687", "1149"]
}, {
    "City": "Mogadishu",
    "DBTZ": "U",
    "Name": "Aden Adde International Airport",
    "Country": "Somalia",
    "IATA/FAA": "MGQ",
    "Longitude": "45.3047",
    "ICAO": "HCMM",
    "Airport ID": "5687",
    "Latitude": "2.01444",
    "Timezone": "29",
    "DST": "3",
    "destinations": ["4059", "1149", "4119", "1122", "1187", "5688", "1121"]
}, {
    "City": "Bosaso",
    "DBTZ": "U",
    "Name": "Bosaso Airport",
    "Country": "Somalia",
    "IATA/FAA": "BSA",
    "Longitude": "49.1494",
    "ICAO": "HCMF",
    "Airport ID": "5686",
    "Latitude": "11.2753",
    "Timezone": "3",
    "DST": "3",
    "destinations": ["1122", "5688"]
}, {
    "City": "Gode",
    "DBTZ": "U",
    "Name": "Gode Airport",
    "Country": "Ethiopia",
    "IATA/FAA": "GDE",
    "Longitude": "43.578567",
    "ICAO": "HAGO",
    "Airport ID": "5680",
    "Latitude": "5.935128",
    "Timezone": "834",
    "DST": "3",
    "destinations": ["7417"]
}, {
    "City": "Salamanca",
    "DBTZ": "E",
    "Name": "Salamanca",
    "Country": "Spain",
    "IATA/FAA": "SLM",
    "Longitude": "-5.501986",
    "ICAO": "LESA",
    "Airport ID": "1238",
    "Latitude": "40.952117",
    "Timezone": "2595",
    "DST": "1",
    "destinations": ["1218"]
}, {
    "City": "Tame",
    "DBTZ": "U",
    "Name": "Tame",
    "Country": "Colombia",
    "IATA/FAA": "TME",
    "Longitude": "-71.760261",
    "ICAO": "SKTM",
    "Airport ID": "2751",
    "Latitude": "6.451081",
    "Timezone": "1050",
    "DST": "-5",
    "destinations": ["2709", "7355"]
}, {
    "City": "Bundaberg",
    "DBTZ": "O",
    "Name": "Bundaberg",
    "Country": "Australia",
    "IATA/FAA": "BDB",
    "Longitude": "152.318611",
    "ICAO": "YBUD",
    "Airport ID": "4218",
    "Latitude": "-24.903889",
    "Timezone": "107",
    "DST": "10",
    "destinations": ["3320"]
}, {
    "City": "Dalian",
    "DBTZ": "U",
    "Name": "Zhoushuizi",
    "Country": "China",
    "IATA/FAA": "DLC",
    "Longitude": "121.5386",
    "ICAO": "ZYTL",
    "Airport ID": "3404",
    "Latitude": "38.965667",
    "Timezone": "107",
    "DST": "8",
    "destinations": ["3393", "3386", "3400", "4108", "4149", "3368", "3376", "3406", "6347", "2276", "3392", "3375", "2305", "3389", "2326", "3077", "3930", "3992", "3388", "2279", "5475", "3364", "3374", "3390", "3369", "6390", "3379", "3370", "2370", "3371", "6345", "3384", "4334", "3387", "3942", "2324", "6387", "6414", "6393", "8876", "6342", "4145", "7503", "8407", "7898", "6944", "6383", "6429", "8858", "6385", "6411", "6388", "3385", "3383", "6772", "4109", "3382", "4148"]
}, {
    "City": "Sharm El Sheikh",
    "DBTZ": "N",
    "Name": "Sharm El Sheikh Intl",
    "Country": "Egypt",
    "IATA/FAA": "SSH",
    "Longitude": "34.394722",
    "ICAO": "HESH",
    "Airport ID": "4057",
    "Latitude": "27.977222",
    "Timezone": "143",
    "DST": "2",
    "destinations": ["1128", "3410", "1701", "2072", "2176", "2082", "2170", "469", "502", "478", "521", "492", "548", "1524", "4029", "2985", "2975", "2988"]
}, {
    "City": "Mulu",
    "DBTZ": "N",
    "Name": "Mulu",
    "Country": "Malaysia",
    "IATA/FAA": "MZV",
    "Longitude": "114.805",
    "ICAO": "",
    "Airport ID": "4056",
    "Latitude": "4.048333",
    "Timezone": "80",
    "DST": "8",
    "destinations": ["3272", "3263", "3269", "3266"]
}, {
    "City": "Jinghong",
    "DBTZ": "U",
    "Name": "Jinghong",
    "Country": "China",
    "IATA/FAA": "JHG",
    "Longitude": "100.759611",
    "ICAO": "",
    "Airport ID": "4055",
    "Latitude": "21.973914",
    "Timezone": "1815",
    "DST": "8",
    "destinations": ["3395", "3382", "4054", "4033", "3393", "3386", "6375", "7862", "3115"]
}, {
    "City": "Dali",
    "DBTZ": "U",
    "Name": "Dali",
    "Country": "China",
    "IATA/FAA": "DLU",
    "Longitude": "100.319444",
    "ICAO": "ZPDL",
    "Airport ID": "4054",
    "Latitude": "25.649444",
    "Timezone": "6978",
    "DST": "8",
    "destinations": ["4055", "3382", "3394", "3395"]
}, {
    "City": "Basel",
    "DBTZ": "E",
    "Name": "EuroAirport Basel-Mulhouse-Freiburg",
    "Country": "Switzerland",
    "IATA/FAA": "BSL",
    "Longitude": "7.529167",
    "ICAO": "",
    "Airport ID": "4053",
    "Latitude": "47.59",
    "Timezone": "885",
    "DST": "1",
    "destinations": ["1130", "345", "507", "1509", "1452", "1225", "1054", "3998", "1056", "580", "1382", "599", "548", "340", "342", "346", "1218", "302", "1555", "503", "1587", "1613", "1706", "4317", "1701", "293", "1230", "1324", "1212", "1688", "1506", "1264", "1489", "1519", "609", "338", "535", "1626", "1051", "16", "669", "1197", "502", "1638", "1229", "478", "1359", "1561", "1354", "1418", "1520", "1636", "1742", "1075", "1243", "1486", "1206", "1253", "337", "1273", "1590", "1551", "4091", "1458", "1472", "6510"]
}, {
    "City": "Hervey Bay",
    "DBTZ": "O",
    "Name": "Hervey Bay Airport",
    "Country": "Australia",
    "IATA/FAA": "HVB",
    "Longitude": "152.880278",
    "ICAO": "YHBA",
    "Airport ID": "4052",
    "Latitude": "-25.318889",
    "Timezone": "60",
    "DST": "10",
    "destinations": ["3320", "3361"]
}, {
    "City": "Kingscote",
    "DBTZ": "O",
    "Name": "Kingscote Airport",
    "Country": "Australia",
    "IATA/FAA": "KGC",
    "Longitude": "137.521389",
    "ICAO": "YKSC",
    "Airport ID": "4051",
    "Latitude": "-35.713889",
    "Timezone": "24",
    "DST": "9.5",
    "destinations": ["3341"]
}, {
    "City": "Manhattan",
    "DBTZ": "A",
    "Name": "Manhattan Reigonal",
    "Country": "United States",
    "IATA/FAA": "MHK",
    "Longitude": "-96.670833",
    "ICAO": "KMHK",
    "Airport ID": "4050",
    "Latitude": "39.140972",
    "Timezone": "1057",
    "DST": "-6",
    "destinations": ["3670", "3830"]
}, {
    "City": "Toyama",
    "DBTZ": "U",
    "Name": "Toyama",
    "Country": "Japan",
    "IATA/FAA": "TOY",
    "Longitude": "137.1875",
    "ICAO": "RJNT",
    "Airport ID": "2324",
    "Latitude": "36.648333",
    "Timezone": "95",
    "DST": "9",
    "destinations": ["2276", "3404", "3406", "2287", "2359", "3930"]
}, {
    "City": "Nairobi",
    "DBTZ": "N",
    "Name": "Jomo Kenyatta International",
    "Country": "Kenya",
    "IATA/FAA": "NBO",
    "Longitude": "36.9275",
    "ICAO": "HKJK",
    "Airport ID": "4059",
    "Latitude": "-1.319167",
    "Timezone": "5327",
    "DST": "3",
    "destinations": ["5687", "1138", "1173", "1140", "1143", "1145", "5694", "2997", "507", "302", "1382", "915", "1005", "1165", "1016", "907", "906", "580", "2179", "1149", "3885", "3370", "2188", "1107", "2191", "3980", "3930", "1120", "883", "1038", "1020", "4119", "910", "253", "248", "984", "1044", "1013", "1177", "3093", "897", "1187", "881", "2072", "813", "1180", "1175", "951", "273", "979", "893", "1183", "4161", "985", "994", "918", "1186", "1678", "1128", "1701", "2241", "3967", "5688", "1121"]
}, {
    "City": "Franklin",
    "DBTZ": "A",
    "Name": "Franklin",
    "Country": "United States",
    "IATA/FAA": "FKL",
    "Longitude": "-79.860362",
    "ICAO": "KFKL",
    "Airport ID": "4058",
    "Latitude": "41.377874",
    "Timezone": "1540",
    "DST": "-5",
    "destinations": ["3486", "5734"]
}, {
    "City": "Londolovit",
    "DBTZ": "U",
    "Name": "Londolovit Airport",
    "Country": "Papua New Guinea",
    "IATA/FAA": "LNV",
    "Longitude": "152.629",
    "ICAO": "",
    "Airport ID": "5979",
    "Latitude": "-3.04361",
    "Timezone": "167",
    "DST": "10",
    "destinations": ["5", "5435"]
}, {
    "City": "Kzyl-Orda",
    "DBTZ": "U",
    "Name": "Kzyl-Orda",
    "Country": "Kazakhstan",
    "IATA/FAA": "KZO",
    "Longitude": "65.591",
    "ICAO": "UAOO",
    "Airport ID": "4358",
    "Latitude": "44.709",
    "Timezone": "0",
    "DST": "6",
    "destinations": ["4375", "4367", "2908", "2910"]
}, {
    "City": "Labuan",
    "DBTZ": "N",
    "Name": "Labuan",
    "Country": "Malaysia",
    "IATA/FAA": "LBU",
    "Longitude": "115.250181",
    "ICAO": "WBKL",
    "Airport ID": "3270",
    "Latitude": "5.300683",
    "Timezone": "101",
    "DST": "8",
    "destinations": ["3304", "3269", "3266"]
}, {
    "City": "South Bend",
    "DBTZ": "A",
    "Name": "South Bend Rgnl",
    "Country": "United States",
    "IATA/FAA": "SBN",
    "Longitude": "-86.31725",
    "ICAO": "KSBN",
    "Airport ID": "4359",
    "Latitude": "41.708661",
    "Timezone": "799",
    "DST": "-5",
    "destinations": ["3682", "3645", "3858", "6505", "3877", "7056", "3617", "4167", "3830"]
}, {
    "City": "Cordova",
    "DBTZ": "A",
    "Name": "Merle K Mudhole Smith",
    "Country": "United States",
    "IATA/FAA": "CDV",
    "Longitude": "-145.477556",
    "ICAO": "PACV",
    "Airport ID": "3610",
    "Latitude": "60.491778",
    "Timezone": "48",
    "DST": "-9",
    "destinations": ["3774", "6803"]
}, {
    "City": "Norfolk",
    "DBTZ": "A",
    "Name": "Norfolk Intl",
    "Country": "United States",
    "IATA/FAA": "ORF",
    "Longitude": "-76.201222",
    "ICAO": "KORF",
    "Airport ID": "3611",
    "Latitude": "36.894611",
    "Timezone": "26",
    "DST": "-5",
    "destinations": ["3876", "3520", "3670", "3797", "3697", "3576", "3752", "3682", "3645", "3878", "3858", "3849", "3747", "3494", "3714", "3550", "3830"]
}, {
    "City": "Asahikawa",
    "DBTZ": "U",
    "Name": "Asahikawa",
    "Country": "Japan",
    "IATA/FAA": "AKJ",
    "Longitude": "142.4475",
    "ICAO": "RJEC",
    "Airport ID": "2299",
    "Latitude": "43.670833",
    "Timezone": "721",
    "DST": "9",
    "destinations": ["2276", "2359", "3942"]
}, {
    "City": "Monbetsu",
    "DBTZ": "U",
    "Name": "Monbetsu",
    "Country": "Japan",
    "IATA/FAA": "MBE",
    "Longitude": "143.404028",
    "ICAO": "RJEB",
    "Airport ID": "2298",
    "Latitude": "44.303914",
    "Timezone": "80",
    "DST": "9",
    "destinations": ["2359"]
}, {
    "City": "Nome",
    "DBTZ": "A",
    "Name": "Nome",
    "Country": "United States",
    "IATA/FAA": "OME",
    "Longitude": "-165.445247",
    "ICAO": "PAOM",
    "Airport ID": "3615",
    "Latitude": "64.512203",
    "Timezone": "37",
    "DST": "-9",
    "destinations": ["7184", "6715", "7185", "7183", "6723", "7192", "6724", "7186", "7088", "7187", "7193", "7188", "7190", "3693", "7194", "3774"]
}, {
    "City": "San Luis",
    "DBTZ": "A",
    "Name": "Scappoose Industrial Airpark",
    "Country": "United States",
    "IATA/FAA": "SPB",
    "Longitude": "-122.861833",
    "ICAO": "KSPB",
    "Airport ID": "3616",
    "Latitude": "45.771028",
    "Timezone": "58",
    "DST": "-8",
    "destinations": ["7310"]
}, {
    "City": "St. Petersburg",
    "DBTZ": "A",
    "Name": "St Petersburg Clearwater Intl",
    "Country": "United States",
    "IATA/FAA": "PIE",
    "Longitude": "-82.687389",
    "ICAO": "KPIE",
    "Airport ID": "3617",
    "Latitude": "27.910167",
    "Timezone": "10",
    "DST": "-5",
    "destinations": ["4355", "4007", "3463", "3578", "4043", "3729", "3946", "4292", "4009", "4041", "3685", "4008", "4034", "5742", "3630", "3674", "4017", "3542", "4072", "3771", "4046", "4028", "4016", "4359", "4348", "3661", "3745", "4040", "4116", "3676", "3881"]
}, {
    "City": "Nakashibetsu",
    "DBTZ": "U",
    "Name": "Nakashibetsu",
    "Country": "Japan",
    "IATA/FAA": "SHB",
    "Longitude": "144.96",
    "ICAO": "RJCN",
    "Airport ID": "2291",
    "Latitude": "43.5775",
    "Timezone": "234",
    "DST": "9",
    "destinations": ["2287", "2359"]
}, {
    "City": "Memanbetsu",
    "DBTZ": "U",
    "Name": "Memanbetsu",
    "Country": "Japan",
    "IATA/FAA": "MMB",
    "Longitude": "144.164053",
    "ICAO": "RJCM",
    "Airport ID": "2290",
    "Latitude": "43.880606",
    "Timezone": "135",
    "DST": "9",
    "destinations": ["2359", "2287", "3942"]
}, {
    "City": "Tsushima",
    "DBTZ": "U",
    "Name": "Tsushima",
    "Country": "Japan",
    "IATA/FAA": "TSJ",
    "Longitude": "129.33055",
    "ICAO": "RJDT",
    "Airport ID": "2297",
    "Latitude": "34.284889",
    "Timezone": "213",
    "DST": "9",
    "destinations": ["2305", "2313"]
}, {
    "City": "Yamaguchi",
    "DBTZ": "U",
    "Name": "Yamaguchi Ube",
    "Country": "Japan",
    "IATA/FAA": "UBJ",
    "Longitude": "131.278611",
    "ICAO": "RJDC",
    "Airport ID": "2296",
    "Latitude": "33.93",
    "Timezone": "23",
    "DST": "9",
    "destinations": ["2359", "3930"]
}, {
    "City": "Iki",
    "DBTZ": "U",
    "Name": "Iki",
    "Country": "Japan",
    "IATA/FAA": "IKI",
    "Longitude": "129.785417",
    "ICAO": "RJDB",
    "Airport ID": "2295",
    "Latitude": "33.749027",
    "Timezone": "41",
    "DST": "9",
    "destinations": ["2313"]
}, {
    "City": "Wakkanai",
    "DBTZ": "U",
    "Name": "Wakkanai",
    "Country": "Japan",
    "IATA/FAA": "WKJ",
    "Longitude": "141.800833",
    "ICAO": "RJCW",
    "Airport ID": "2294",
    "Latitude": "45.404167",
    "Timezone": "30",
    "DST": "9",
    "destinations": ["2287", "2359"]
}, {
    "City": "Shanghai",
    "DBTZ": "U",
    "Name": "Pudong",
    "Country": "China",
    "IATA/FAA": "PVG",
    "Longitude": "121.805214",
    "ICAO": "ZSPD",
    "Airport ID": "3406",
    "Latitude": "31.143378",
    "Timezone": "13",
    "DST": "8",
    "destinations": ["3393", "3395", "2244", "2397", "3389", "3382", "3269", "3885", "4380", "2370", "3931", "3404", "6353", "3077", "3179", "3400", "2281", "2264", "3992", "3372", "3121", "3373", "3035", "4144", "3316", "4030", "2337", "3390", "2276", "3383", "6357", "3484", "3830", "156", "193", "1382", "3093", "1847", "421", "1555", "2275", "507", "3272", "2006", "3370", "3494", "340", "2305", "6345", "4085", "1701", "6386", "4301", "3384", "3394", "3339", "346", "1524", "3942", "2279", "3364", "2347", "3469", "3361", "3374", "6392", "3379", "3392", "580", "3375", "3371", "6433", "3645", "4120", "3930", "6411", "3304", "4334", "6413", "3399", "3376", "6414", "6355", "3797", "3577", "2188", "1107", "2179", "6412", "6400", "2372", "6429", "4302", "2324", "3368", "8858", "6403", "6430", "3275", "2268", "6346", "6351", "4145", "3385", "2384", "6348", "6360", "6006", "6431", "3369", "6388", "2287", "6476", "2326", "4381", "2322", "2307", "2333", "2313", "2327", "3034", "3156", "1678", "893", "7506", "6344", "6397", "3940", "3199", "8407", "6743", "3728", "6395", "6385", "6356", "6399", "4189", "6401", "3388", "3205", "6347", "2985", "2381", "4108", "6366", "4110", "2241", "609", "3024", "3196", "4330"]
}, {
    "City": "Ilheus",
    "DBTZ": "S",
    "Name": "Ilheus",
    "Country": "Brazil",
    "IATA/FAA": "IOS",
    "Longitude": "-39.033197",
    "ICAO": "SBIL",
    "Airport ID": "2569",
    "Latitude": "-14.815964",
    "Timezone": "15",
    "DST": "-3",
    "destinations": ["2564", "2621", "2578", "2537", "2618", "2612", "2531"]
}, {
    "City": "Rio De Janeiro",
    "DBTZ": "S",
    "Name": "Galeao Antonio Carlos Jobim",
    "Country": "Brazil",
    "IATA/FAA": "GIG",
    "Longitude": "-43.243647",
    "ICAO": "SBGL",
    "Airport ID": "2560",
    "Latitude": "-22.808903",
    "Timezone": "28",
    "DST": "-3",
    "destinations": ["2613", "2816", "3876", "3670", "3797", "3576", "2578", "1382", "2545", "2442", "3988", "2709", "2789", "1555", "507", "1871", "3682", "1762", "951", "2188", "2564", "2522", "2526", "2531", "2548", "2618", "2538", "2537", "2555", "2559", "2562", "2554", "2575", "2551", "2590", "2597", "2595", "2599", "2610", "2616", "2621", "2638", "1229", "340", "2650", "580", "1638", "1636", "3550"]
}, {
    "City": "Goiania",
    "DBTZ": "S",
    "Name": "Santa Genoveva",
    "Country": "Brazil",
    "IATA/FAA": "GYN",
    "Longitude": "-49.220686",
    "ICAO": "SBGO",
    "Airport ID": "2562",
    "Latitude": "-16.632033",
    "Timezone": "2450",
    "DST": "-3",
    "destinations": ["2548", "4214", "2613", "2633", "2537", "2564", "2612", "2578", "2531", "2618", "2560"]
}, {
    "City": "Sao Paulo",
    "DBTZ": "S",
    "Name": "Guarulhos Gov Andre Franco Montouro",
    "Country": "Brazil",
    "IATA/FAA": "GRU",
    "Longitude": "-46.469511",
    "ICAO": "SBGR",
    "Airport ID": "2564",
    "Latitude": "-23.432075",
    "Timezone": "2459",
    "DST": "-3",
    "destinations": ["2535", "7380", "2613", "2635", "2633", "6039", "2816", "3876", "3670", "3797", "3484", "3878", "3576", "193", "2525", "2531", "2548", "2538", "2537", "2545", "2562", "2569", "2570", "2595", "2528", "2599", "2610", "2612", "2621", "1382", "1824", "2442", "3988", "1074", "2709", "2789", "1555", "507", "1229", "1871", "3682", "3645", "1762", "951", "2188", "2688", "298", "2179", "2522", "2699", "2526", "2875", "4209", "2851", "2443", "2555", "2559", "2560", "2554", "2575", "2581", "2551", "2590", "2586", "2597", "2440", "2616", "2623", "2638", "2771", "2650", "4305", "340", "813", "2576", "346", "1524", "2619", "580", "1678", "6034", "2602", "2759", "2241", "1218", "1701", "1638", "1636", "3494", "3714", "3550", "3830"]
}, {
    "City": "Altamira",
    "DBTZ": "S",
    "Name": "Altamira",
    "Country": "Brazil",
    "IATA/FAA": "ATM",
    "Longitude": "-52.253978",
    "ICAO": "SBHT",
    "Airport ID": "2566",
    "Latitude": "-3.253906",
    "Timezone": "368",
    "DST": "-4",
    "destinations": ["2526", "4092", "8259"]
}, {
    "City": "Saratov",
    "DBTZ": "N",
    "Name": "Central",
    "Country": "Russia",
    "IATA/FAA": "RTW",
    "Longitude": "46.022952",
    "ICAO": "UWSS",
    "Airport ID": "4363",
    "Latitude": "51.334366",
    "Timezone": "152",
    "DST": "4",
    "destinations": ["4029", "3964", "2962", "1587", "2975"]
}, {
    "City": "Wajima",
    "DBTZ": "U",
    "Name": "Noto",
    "Country": "Japan",
    "IATA/FAA": "NTQ",
    "Longitude": "136.961853",
    "ICAO": "RJNW",
    "Airport ID": "3409",
    "Latitude": "37.293097",
    "Timezone": "718",
    "DST": "9",
    "destinations": ["2359"]
}, {
    "City": "Yeosu",
    "DBTZ": "U",
    "Name": "Yeosu",
    "Country": "South Korea",
    "IATA/FAA": "RSU",
    "Longitude": "127.61685",
    "ICAO": "RKJY",
    "Airport ID": "2365",
    "Latitude": "34.842328",
    "Timezone": "53",
    "DST": "9",
    "destinations": ["2370", "2378"]
}, {
    "City": "Kwangju",
    "DBTZ": "U",
    "Name": "Gwangju",
    "Country": "South Korea",
    "IATA/FAA": "KWJ",
    "Longitude": "126.808889",
    "ICAO": "RKJJ",
    "Airport ID": "2361",
    "Latitude": "35.126389",
    "Timezone": "39",
    "DST": "9",
    "destinations": ["2370", "2378"]
}, {
    "City": "Montes Claros",
    "DBTZ": "S",
    "Name": "Mario Ribeiro",
    "Country": "Brazil",
    "IATA/FAA": "MOC",
    "Longitude": "-43.8189",
    "ICAO": "SBMK",
    "Airport ID": "2587",
    "Latitude": "-16.706925",
    "Timezone": "2191",
    "DST": "-3",
    "destinations": ["2537", "2528"]
}, {
    "City": "Maringa",
    "DBTZ": "S",
    "Name": "Regional De Maringa Silvio Name Junior",
    "Country": "Brazil",
    "IATA/FAA": "MGF",
    "Longitude": "-52.016406",
    "ICAO": "SBMG",
    "Airport ID": "2586",
    "Latitude": "-23.476392",
    "Timezone": "1788",
    "DST": "-3",
    "destinations": ["2548", "2545", "2581", "2599", "2578", "2618", "2538", "2564"]
}, {
    "City": "Maraba",
    "DBTZ": "S",
    "Name": "Maraba",
    "Country": "Brazil",
    "IATA/FAA": "MAB",
    "Longitude": "-49.138025",
    "ICAO": "SBMA",
    "Airport ID": "2584",
    "Latitude": "-5.368589",
    "Timezone": "357",
    "DST": "-4",
    "destinations": ["7376", "2526", "2531", "7532", "2629", "2572", "8239", "8240"]
}, {
    "City": "Bom Jesus Da Lapa",
    "DBTZ": "S",
    "Name": "Bom Jesus Da Lapa",
    "Country": "Brazil",
    "IATA/FAA": "LAZ",
    "Longitude": "-43.408114",
    "ICAO": "SBLP",
    "Airport ID": "2582",
    "Latitude": "-13.262086",
    "Timezone": "1454",
    "DST": "-3",
    "destinations": ["7407"]
}, {
    "City": "Londrina",
    "DBTZ": "S",
    "Name": "Londrina",
    "Country": "Brazil",
    "IATA/FAA": "LDB",
    "Longitude": "-51.130072",
    "ICAO": "SBLO",
    "Airport ID": "2581",
    "Latitude": "-23.333625",
    "Timezone": "1867",
    "DST": "-3",
    "destinations": ["2548", "2545", "2586", "2599", "2578", "2618", "2564"]
}, {
    "City": "Managua",
    "DBTZ": "U",
    "Name": "Managua Intl",
    "Country": "Nicaragua",
    "IATA/FAA": "MGA",
    "Longitude": "-86.168178",
    "ICAO": "MNMG",
    "Airport ID": "1863",
    "Latitude": "12.141494",
    "Timezone": "194",
    "DST": "-6",
    "destinations": ["1885", "3576", "1767", "1871", "3682", "1892", "3533", "3550"]
}, {
    "City": "Honolulu",
    "DBTZ": "N",
    "Name": "Honolulu Intl",
    "Country": "United States",
    "IATA/FAA": "HNL",
    "Longitude": "-157.922428",
    "ICAO": "PHNL",
    "Airport ID": "3728",
    "Latitude": "21.318681",
    "Timezone": "13",
    "DST": "-10",
    "destinations": ["3670", "2359", "3992", "3484", "3942", "2279", "3453", "3462", "3748", "3361", "156", "3774", "3777", "3720", "3731", "3577", "3364", "2276", "3682", "2305", "3930", "3469", "3536", "1969", "2252", "1960", "3877", "2006", "3320", "3415", "3797", "3514", "3602", "3851", "3705", "3456", "1970", "4075", "2347", "3817", "3339", "3406", "3796", "2397", "3751", "3494", "2246", "3714", "3550", "2249", "3830"]
}, {
    "City": "Des Moines",
    "DBTZ": "A",
    "Name": "Des Moines Intl",
    "Country": "United States",
    "IATA/FAA": "DSM",
    "Longitude": "-93.663083",
    "ICAO": "KDSM",
    "Airport ID": "3729",
    "Latitude": "41.533972",
    "Timezone": "958",
    "DST": "-6",
    "destinations": ["3876", "3520", "3670", "3830", "3462", "3682", "3645", "3697", "3858", "3751", "3747", "3877", "7056", "3617", "4167", "3494", "3550"]
}, {
    "City": "David",
    "DBTZ": "U",
    "Name": "Enrique Malek Intl",
    "Country": "Panama",
    "IATA/FAA": "DAV",
    "Longitude": "-82.434992",
    "ICAO": "MPDA",
    "Airport ID": "1867",
    "Latitude": "8.391003",
    "Timezone": "89",
    "DST": "-5",
    "destinations": ["1869", "1885"]
}, {
    "City": "Changuinola",
    "DBTZ": "U",
    "Name": "Cap Manuel Nino Intl",
    "Country": "Panama",
    "IATA/FAA": "CHX",
    "Longitude": "-82.516806",
    "ICAO": "MPCH",
    "Airport ID": "1866",
    "Latitude": "9.458636",
    "Timezone": "19",
    "DST": "-5",
    "destinations": ["1865"]
}, {
    "City": "Panama",
    "DBTZ": "U",
    "Name": "Marcos A Gelabert Intl",
    "Country": "Panama",
    "IATA/FAA": "PAC",
    "Longitude": "-79.555583",
    "ICAO": "MPMG",
    "Airport ID": "1869",
    "Latitude": "8.973339",
    "Timezone": "31",
    "DST": "-5",
    "destinations": ["5955", "1865", "1866", "1867", "5957", "7962", "7901", "7895", "5958"]
}, {
    "City": "Seattle",
    "DBTZ": "A",
    "Name": "Boeing Fld King Co Intl",
    "Country": "United States",
    "IATA/FAA": "BFI",
    "Longitude": "-122.301947",
    "ICAO": "KBFI",
    "Airport ID": "3726",
    "Latitude": "47.53",
    "Timezone": "21",
    "DST": "-8",
    "destinations": ["5731", "7083"]
}, {
    "City": "Portland",
    "DBTZ": "A",
    "Name": "Portland Intl",
    "Country": "United States",
    "IATA/FAA": "PDX",
    "Longitude": "-122.5975",
    "ICAO": "KPDX",
    "Airport ID": "3720",
    "Latitude": "45.588722",
    "Timezone": "30",
    "DST": "-8",
    "destinations": ["3448", "3644", "3876", "3520", "3670", "3484", "3582", "3453", "3734", "3830", "3752", "3462", "3731", "3469", "3748", "3867", "156", "178", "3682", "3774", "4021", "3777", "3495", "3645", "4099", "3687", "3467", "3728", "3877", "4101", "4216", "3456", "6133", "3839", "4103", "3807", "3949", "3577", "3536", "3817", "6992", "3636", "3797", "580", "3858", "2279", "3751", "3747", "3863", "5757", "6883", "3494", "3714", "3550", "5750", "4019", "3458"]
}, {
    "City": "West Palm Beach",
    "DBTZ": "A",
    "Name": "Palm Beach Intl",
    "Country": "United States",
    "IATA/FAA": "PBI",
    "Longitude": "-80.095589",
    "ICAO": "KPBI",
    "Airport ID": "3722",
    "Latitude": "26.683161",
    "Timezone": "19",
    "DST": "-5",
    "destinations": ["1943", "1930", "3646", "3876", "3520", "3670", "3484", "3697", "3830", "3752", "3682", "3825", "3448", "3494", "3589", "3797", "2890", "3645", "3486", "3550", "1953", "3849", "3857"]
}, {
    "City": "Port Gentil",
    "DBTZ": "N",
    "Name": "Port Gentil",
    "Country": "Gabon",
    "IATA/FAA": "POG",
    "Longitude": "8.754383",
    "ICAO": "FOOG",
    "Airport ID": "967",
    "Latitude": "-0.711739",
    "Timezone": "13",
    "DST": "1",
    "destinations": ["886"]
}, {
    "City": "Luena",
    "DBTZ": "N",
    "Name": "Luena",
    "Country": "Angola",
    "IATA/FAA": "LUO",
    "Longitude": "19.897672",
    "ICAO": "FNUE",
    "Airport ID": "960",
    "Latitude": "-11.768086",
    "Timezone": "4360",
    "DST": "1",
    "destinations": ["951"]
}, {
    "City": "Mildura",
    "DBTZ": "O",
    "Name": "Mildura Airport",
    "Country": "Australia",
    "IATA/FAA": "MQL",
    "Longitude": "142.086",
    "ICAO": "YMIA",
    "Airport ID": "6298",
    "Latitude": "-34.2292",
    "Timezone": "167",
    "DST": "10",
    "destinations": ["3339", "3341", "6241", "3361"]
}, {
    "City": "McArthur River Mine",
    "DBTZ": "O",
    "Name": "McArthur River Mine Airport",
    "Country": "Australia",
    "IATA/FAA": "MCV",
    "Longitude": "136.084",
    "ICAO": "YMHU",
    "Airport ID": "6297",
    "Latitude": "-16.4425",
    "Timezone": "131",
    "DST": "9.5",
    "destinations": ["3999"]
}, {
    "City": "Maningrida",
    "DBTZ": "O",
    "Name": "Maningrida Airport",
    "Country": "Australia",
    "IATA/FAA": "MNG",
    "Longitude": "134.234",
    "ICAO": "YMGD",
    "Airport ID": "6296",
    "Latitude": "-12.0561",
    "Timezone": "123",
    "DST": "9.5",
    "destinations": ["3999", "6265", "6295"]
}, {
    "City": "Milingimbi",
    "DBTZ": "O",
    "Name": "Milingimbi Airport",
    "Country": "Australia",
    "IATA/FAA": "MGT",
    "Longitude": "134.894",
    "ICAO": "YMGB",
    "Airport ID": "6295",
    "Latitude": "-12.0944",
    "Timezone": "53",
    "DST": "9.5",
    "destinations": ["6296"]
}, {
    "City": "Merimbula",
    "DBTZ": "O",
    "Name": "Merimbula Airport",
    "Country": "Australia",
    "IATA/FAA": "MIM",
    "Longitude": "149.901",
    "ICAO": "YMER",
    "Airport ID": "6294",
    "Latitude": "-36.9086",
    "Timezone": "7",
    "DST": "10",
    "destinations": ["3339", "6302", "3361"]
}, {
    "City": "Nanaimo",
    "DBTZ": "A",
    "Name": "Nanaimo",
    "Country": "Canada",
    "IATA/FAA": "YCD",
    "Longitude": "-123.870167",
    "ICAO": "CYCD",
    "Airport ID": "33",
    "Latitude": "49.052333",
    "Timezone": "93",
    "DST": "-8",
    "destinations": ["156", "178"]
}, {
    "City": "Kish Island",
    "DBTZ": "E",
    "Name": "Kish Island",
    "Country": "Iran",
    "IATA/FAA": "KIH",
    "Longitude": "53.980211",
    "ICAO": "OIBK",
    "Airport ID": "2109",
    "Latitude": "26.526156",
    "Timezone": "101",
    "DST": "3.5",
    "destinations": ["2104", "2157", "2134", "4150", "2131", "5712", "2179", "2188"]
}, {
    "City": "Voronezh",
    "DBTZ": "N",
    "Name": "Chertovitskoye",
    "Country": "Russia",
    "IATA/FAA": "VOZ",
    "Longitude": "39.229589",
    "ICAO": "UUOO",
    "Airport ID": "2987",
    "Latitude": "51.814211",
    "Timezone": "514",
    "DST": "4",
    "destinations": ["4029", "2960", "2948", "2988", "3964", "346"]
}, {
    "City": "Riberalta",
    "DBTZ": "U",
    "Name": "Capitan Av. Selin Zeitun Lopez Airport",
    "Country": "Bolivia",
    "IATA/FAA": "RIB",
    "Longitude": "-66",
    "ICAO": "SLRI",
    "Airport ID": "6058",
    "Latitude": "-11",
    "Timezone": "462",
    "DST": "-4",
    "destinations": ["2769"]
}, {
    "City": "Atoifi",
    "DBTZ": "U",
    "Name": "Uru Harbour Airport",
    "Country": "Solomon Islands",
    "IATA/FAA": "ATD",
    "Longitude": "161.011",
    "ICAO": "AGAT",
    "Airport ID": "5404",
    "Latitude": "-8.87333",
    "Timezone": "0",
    "DST": "11",
    "destinations": ["4074"]
}, {
    "City": "Yopal",
    "DBTZ": "U",
    "Name": "El Alcaravan Airport",
    "Country": "Colombia",
    "IATA/FAA": "EYP",
    "Longitude": "-72.383975",
    "ICAO": "SKYP",
    "Airport ID": "6055",
    "Latitude": "5.319114",
    "Timezone": "1028",
    "DST": "-5",
    "destinations": ["2709"]
}, {
    "City": "Puerto Inirida",
    "DBTZ": "U",
    "Name": "Obando Airport",
    "Country": "Colombia",
    "IATA/FAA": "PDA",
    "Longitude": "-67.91",
    "ICAO": "SKPD",
    "Airport ID": "6054",
    "Latitude": "3.85",
    "Timezone": "460",
    "DST": "-5",
    "destinations": ["2709", "2756"]
}, {
    "City": "Guayaramerin",
    "DBTZ": "U",
    "Name": "Capitan de Av. Emilio Beltran Airport",
    "Country": "Bolivia",
    "IATA/FAA": "GYA",
    "Longitude": "-65.3456",
    "ICAO": "SLGY",
    "Airport ID": "6056",
    "Latitude": "-10.8206",
    "Timezone": "557",
    "DST": "-4",
    "destinations": ["2769"]
}, {
    "City": "Puerto Leguizamo",
    "DBTZ": "U",
    "Name": "Caucaya Airport",
    "Country": "Colombia",
    "IATA/FAA": "LQM",
    "Longitude": "-74.77",
    "ICAO": "SKLG",
    "Airport ID": "6051",
    "Latitude": "-0.18",
    "Timezone": "573",
    "DST": "-5",
    "destinations": ["2706"]
}, {
    "City": "Brandon",
    "DBTZ": "A",
    "Name": "Brandon Muni",
    "Country": "Canada",
    "IATA/FAA": "YBR",
    "Longitude": "-99.951944",
    "ICAO": "CYBR",
    "Airport ID": "31",
    "Latitude": "49.91",
    "Timezone": "1343",
    "DST": "-6",
    "destinations": ["178"]
}, {
    "City": "Nuqui",
    "DBTZ": "U",
    "Name": "Reyes Murillo Airport",
    "Country": "Colombia",
    "IATA/FAA": "NQU",
    "Longitude": "-77.28",
    "ICAO": "SKNQ",
    "Airport ID": "6053",
    "Latitude": "5.7",
    "Timezone": "12",
    "DST": "-5",
    "destinations": ["2729"]
}, {
    "City": "La Pedrera",
    "DBTZ": "U",
    "Name": "La Pedrera Airport",
    "Country": "Colombia",
    "IATA/FAA": "LPD",
    "Longitude": "-69.58",
    "ICAO": "SKLP",
    "Airport ID": "6052",
    "Latitude": "-1.33",
    "Timezone": "590",
    "DST": "-5",
    "destinations": ["2728"]
}, {
    "City": "Vitoria",
    "DBTZ": "S",
    "Name": "Goiabeiras",
    "Country": "Brazil",
    "IATA/FAA": "VIX",
    "Longitude": "-40.286389",
    "ICAO": "SBVT",
    "Airport ID": "2638",
    "Latitude": "-20.258056",
    "Timezone": "11",
    "DST": "-3",
    "destinations": ["2537", "2612", "2621", "2578", "2618", "2560", "2564", "2531", "2543"]
}, {
    "City": "Paamiut",
    "DBTZ": "E",
    "Name": "Paamiut Heliport",
    "Country": "Greenland",
    "IATA/FAA": "JFR",
    "Longitude": "-49.6625",
    "ICAO": "BGFH",
    "Airport ID": "5440",
    "Latitude": "61.9922",
    "Timezone": "0",
    "DST": "-3",
    "destinations": ["8", "7"]
}, {
    "City": "Uberaba",
    "DBTZ": "S",
    "Name": "Uberaba",
    "Country": "Brazil",
    "IATA/FAA": "UBA",
    "Longitude": "-47.964778",
    "ICAO": "SBUR",
    "Airport ID": "2635",
    "Latitude": "-19.765",
    "Timezone": "2655",
    "DST": "-3",
    "destinations": ["2564", "2633", "2537", "2578"]
}, {
    "City": "Vilhena",
    "DBTZ": "S",
    "Name": "Vilhena",
    "Country": "Brazil",
    "IATA/FAA": "BVH",
    "Longitude": "-60.098269",
    "ICAO": "SBVH",
    "Airport ID": "2637",
    "Latitude": "-12.694375",
    "Timezone": "2018",
    "DST": "-4",
    "destinations": ["2548"]
}, {
    "City": "Paulo Alfonso",
    "DBTZ": "S",
    "Name": "Paulo Afonso",
    "Country": "Brazil",
    "IATA/FAA": "PAV",
    "Longitude": "-38.250575",
    "ICAO": "SBUF",
    "Airport ID": "2631",
    "Latitude": "-9.400878",
    "Timezone": "883",
    "DST": "-3",
    "destinations": ["2621"]
}, {
    "City": "Uberlandia",
    "DBTZ": "S",
    "Name": "Ten Cel Av Cesar Bombonato",
    "Country": "Brazil",
    "IATA/FAA": "UDI",
    "Longitude": "-48.225594",
    "ICAO": "SBUL",
    "Airport ID": "2633",
    "Latitude": "-18.882844",
    "Timezone": "3094",
    "DST": "-3",
    "destinations": ["2564", "2562", "2613", "2635", "2531", "2537", "2528", "2578", "2618"]
}, {
    "City": "Uruguaiana",
    "DBTZ": "S",
    "Name": "Rubem Berta",
    "Country": "Brazil",
    "IATA/FAA": "URG",
    "Longitude": "-57.038189",
    "ICAO": "SBUG",
    "Airport ID": "2632",
    "Latitude": "-29.782178",
    "Timezone": "256",
    "DST": "-3",
    "destinations": ["6040"]
}, {
    "City": "Sigiriya",
    "DBTZ": "N",
    "Name": "Sigiriya Airport",
    "Country": "Sri Lanka",
    "IATA/FAA": "GIU",
    "Longitude": "80.43412",
    "ICAO": "VCCS",
    "Airport ID": "9229",
    "Latitude": "7.57212",
    "Timezone": "630",
    "DST": "5.5",
    "destinations": ["3024", "3030"]
}, {
    "City": "Harstad/Narvik",
    "DBTZ": "E",
    "Name": "Evenes",
    "Country": "Norway",
    "IATA/FAA": "EVE",
    "Longitude": "16.678108",
    "ICAO": "ENEV",
    "Airport ID": "641",
    "Latitude": "68.4913",
    "Timezone": "84",
    "DST": "1",
    "destinations": ["666", "1212", "644", "665", "631", "635", "663"]
}, {
    "City": "Linz",
    "DBTZ": "E",
    "Name": "Linz",
    "Country": "Austria",
    "IATA/FAA": "LNZ",
    "Longitude": "14.187511",
    "ICAO": "LOWL",
    "Airport ID": "1611",
    "Latitude": "48.233219",
    "Timezone": "978",
    "DST": "1",
    "destinations": ["1452", "3998", "548", "345", "340", "1613", "1688", "1612"]
}, {
    "City": "Kasane",
    "DBTZ": "U",
    "Name": "Kasane",
    "Country": "Botswana",
    "IATA/FAA": "BBK",
    "Longitude": "25.1624",
    "ICAO": "FBKE",
    "Airport ID": "879",
    "Latitude": "-17.832875",
    "Timezone": "3289",
    "DST": "2",
    "destinations": ["881", "813", "880"]
}, {
    "City": "Port Moller",
    "DBTZ": "A",
    "Name": "Platinum",
    "Country": "United States",
    "IATA/FAA": "PTU",
    "Longitude": "-161.819664",
    "ICAO": "PAPM",
    "Airport ID": "3430",
    "Latitude": "59.011356",
    "Timezone": "15",
    "DST": "-9",
    "destinations": ["3599"]
}, {
    "City": "Francistown",
    "DBTZ": "U",
    "Name": "Francistown",
    "Country": "Botswana",
    "IATA/FAA": "FRW",
    "Longitude": "27.474525",
    "ICAO": "FBFT",
    "Airport ID": "877",
    "Latitude": "-21.159597",
    "Timezone": "3283",
    "DST": "2",
    "destinations": ["881", "813"]
}, {
    "City": "Floro",
    "DBTZ": "E",
    "Name": "Floro",
    "Country": "Norway",
    "IATA/FAA": "FRO",
    "Longitude": "5.024722",
    "ICAO": "ENFL",
    "Airport ID": "643",
    "Latitude": "61.583611",
    "Timezone": "37",
    "DST": "1",
    "destinations": ["636", "4389", "4345", "647", "644"]
}, {
    "City": "Fort Smith",
    "DBTZ": "A",
    "Name": "Fort Smith Rgnl",
    "Country": "United States",
    "IATA/FAA": "FSM",
    "Longitude": "-94.367444",
    "ICAO": "KFSM",
    "Airport ID": "3437",
    "Latitude": "35.336583",
    "Timezone": "469",
    "DST": "-6",
    "destinations": ["3670", "3682"]
}, {
    "City": "Popayan",
    "DBTZ": "U",
    "Name": "Guillermo Leon Valencia",
    "Country": "Colombia",
    "IATA/FAA": "PPN",
    "Longitude": "-76.609319",
    "ICAO": "SKPP",
    "Airport ID": "2741",
    "Latitude": "2.4544",
    "Timezone": "5687",
    "DST": "-5",
    "destinations": ["2709"]
}, {
    "City": "Providencia",
    "DBTZ": "U",
    "Name": "El Embrujo",
    "Country": "Colombia",
    "IATA/FAA": "PVA",
    "Longitude": "-81.35833",
    "ICAO": "SKPV",
    "Airport ID": "2743",
    "Latitude": "13.356944",
    "Timezone": "10",
    "DST": "-5",
    "destinations": ["2749"]
}, {
    "City": "Tauranga",
    "DBTZ": "Z",
    "Name": "Tauranga",
    "Country": "New Zealand",
    "IATA/FAA": "TRG",
    "Longitude": "176.19611",
    "ICAO": "NZTG",
    "Airport ID": "2034",
    "Latitude": "-37.671944",
    "Timezone": "13",
    "DST": "12",
    "destinations": ["2006", "2009", "2042"]
}, {
    "City": "Rio Negro",
    "DBTZ": "U",
    "Name": "Jose Maria Cordova",
    "Country": "Colombia",
    "IATA/FAA": "MDE",
    "Longitude": "-75.423119",
    "ICAO": "SKRG",
    "Airport ID": "2745",
    "Latitude": "6.164536",
    "Timezone": "6955",
    "DST": "-5",
    "destinations": ["2897", "3576", "2710", "2708", "2709", "2715", "2714", "2713", "3797", "2789", "1229", "1892", "2748", "3533", "2749", "2851", "1871", "2688"]
}, {
    "City": "San Jose Del Guaviare",
    "DBTZ": "U",
    "Name": "Jorge E Gonzalez Torres",
    "Country": "Colombia",
    "IATA/FAA": "SJE",
    "Longitude": "-72.639358",
    "ICAO": "SKSJ",
    "Airport ID": "2747",
    "Latitude": "2.579694",
    "Timezone": "605",
    "DST": "-5",
    "destinations": ["2709"]
}, {
    "City": "Rio Hacha",
    "DBTZ": "U",
    "Name": "Almirante Padilla",
    "Country": "Colombia",
    "IATA/FAA": "RCH",
    "Longitude": "-72.925958",
    "ICAO": "SKRH",
    "Airport ID": "2746",
    "Latitude": "11.526222",
    "Timezone": "43",
    "DST": "-5",
    "destinations": ["2709"]
}, {
    "City": "San Andres Island",
    "DBTZ": "U",
    "Name": "Gustavo Rojas Pinilla",
    "Country": "Colombia",
    "IATA/FAA": "ADZ",
    "Longitude": "-81.711192",
    "ICAO": "SKSP",
    "Airport ID": "2749",
    "Latitude": "12.583594",
    "Timezone": "19",
    "DST": "-5",
    "destinations": ["2743", "2709", "2710", "2715", "2714", "2745", "2739", "1871"]
}, {
    "City": "Santa Marta",
    "DBTZ": "U",
    "Name": "Simon Bolivar",
    "Country": "Colombia",
    "IATA/FAA": "SMR",
    "Longitude": "-74.230647",
    "ICAO": "SKSM",
    "Airport ID": "2748",
    "Latitude": "11.11965",
    "Timezone": "22",
    "DST": "-5",
    "destinations": ["2709", "2745"]
}, {
    "City": "Oslo",
    "DBTZ": "E",
    "Name": "Gardermoen",
    "Country": "Norway",
    "IATA/FAA": "OSL",
    "Longitude": "11.100361",
    "ICAO": "ENGM",
    "Airport ID": "644",
    "Latitude": "60.193917",
    "Timezone": "681",
    "DST": "1",
    "destinations": ["711", "342", "3941", "507", "351", "1382", "628", "421", "607", "608", "532", "3953", "660", "630", "1230", "1324", "1212", "632", "580", "737", "1688", "1218", "640", "1739", "636", "1216", "3885", "1191", "635", "1745", "1489", "344", "1474", "609", "1200", "599", "535", "641", "1626", "1555", "3533", "668", "1665", "645", "3797", "2939", "16", "649", "669", "638", "1197", "2948", "502", "1638", "1054", "658", "478", "1227", "1606", "651", "346", "1524", "1354", "1386", "3958", "3998", "1587", "1562", "1203", "1075", "1206", "666", "337", "676", "1056", "415", "663", "665", "1551", "1613", "3959", "679", "340", "345", "1678", "655", "642", "2223", "2241", "302", "3494", "687", "8266", "1701", "647", "2985", "634", "4389", "643", "4345", "656", "5585", "5586", "662"]
}, {
    "City": "Bata",
    "DBTZ": "N",
    "Name": "Bata",
    "Country": "Equatorial Guinea",
    "IATA/FAA": "BSG",
    "Longitude": "9.805681",
    "ICAO": "FGBT",
    "Airport ID": "890",
    "Latitude": "1.905469",
    "Timezone": "13",
    "DST": "1",
    "destinations": ["245", "891"]
}, {
    "City": "Malabo",
    "DBTZ": "N",
    "Name": "Malabo",
    "Country": "Equatorial Guinea",
    "IATA/FAA": "SSG",
    "Longitude": "8.708717",
    "ICAO": "FGSL",
    "Airport ID": "891",
    "Latitude": "3.755267",
    "Timezone": "76",
    "DST": "1",
    "destinations": ["897", "1074", "970", "890", "245", "1107", "1229", "260"]
}, {
    "City": "Plaisance",
    "DBTZ": "N",
    "Name": "Sir Seewoosagur Ramgoolam Intl",
    "Country": "Mauritius",
    "IATA/FAA": "MRU",
    "Longitude": "57.6836",
    "ICAO": "FIMP",
    "Airport ID": "893",
    "Latitude": "-20.430235",
    "Timezone": "186",
    "DST": "4",
    "destinations": ["1382", "916", "917", "3131", "2997", "3093", "813", "502", "340", "2188", "994", "1555", "4059", "918", "3304", "797", "799", "3077", "507", "3364", "3351", "3406", "894", "1386", "2988"]
}, {
    "City": "Rodriguez Island",
    "DBTZ": "N",
    "Name": "Plaine Corail",
    "Country": "Mauritius",
    "IATA/FAA": "RRG",
    "Longitude": "63.360983",
    "ICAO": "FIMR",
    "Airport ID": "894",
    "Latitude": "-19.757658",
    "Timezone": "95",
    "DST": "4",
    "destinations": ["893"]
}, {
    "City": "Gwalior",
    "DBTZ": "N",
    "Name": "Gwalior",
    "Country": "India",
    "IATA/FAA": "GWL",
    "Longitude": "78.227753",
    "ICAO": "VIGR",
    "Airport ID": "3094",
    "Latitude": "26.293336",
    "Timezone": "617",
    "DST": "5.5",
    "destinations": ["2997"]
}, {
    "City": "Douala",
    "DBTZ": "N",
    "Name": "Douala",
    "Country": "Cameroon",
    "IATA/FAA": "DLA",
    "Longitude": "9.719481",
    "ICAO": "FKKD",
    "Airport ID": "897",
    "Latitude": "4.006081",
    "Timezone": "33",
    "DST": "1",
    "destinations": ["1084", "273", "302", "1020", "1382", "891", "888", "1074", "4161", "245", "1107", "883", "298", "999", "253", "4059", "886", "901", "970", "898", "1701"]
}, {
    "City": "Maroua",
    "DBTZ": "N",
    "Name": "Salak",
    "Country": "Cameroon",
    "IATA/FAA": "MVR",
    "Longitude": "14.257361",
    "ICAO": "FKKL",
    "Airport ID": "898",
    "Latitude": "10.451392",
    "Timezone": "1390",
    "DST": "1",
    "destinations": ["4161"]
}, {
    "City": "Hasvik",
    "DBTZ": "E",
    "Name": "Hasvik",
    "Country": "Norway",
    "IATA/FAA": "HAA",
    "Longitude": "22.139744",
    "ICAO": "ENHK",
    "Airport ID": "646",
    "Latitude": "70.486675",
    "Timezone": "21",
    "DST": "1",
    "destinations": ["4325", "663"]
}, {
    "City": "Valladolid",
    "DBTZ": "E",
    "Name": "Valladolid",
    "Country": "Spain",
    "IATA/FAA": "VLL",
    "Longitude": "-4.851944",
    "ICAO": "LEVD",
    "Airport ID": "1247",
    "Latitude": "41.706111",
    "Timezone": "2775",
    "DST": "1",
    "destinations": ["1218", "3998"]
}, {
    "City": "Igdir",
    "DBTZ": "E",
    "Name": "Igdir",
    "Country": "Turkey",
    "IATA/FAA": "IGD",
    "Longitude": "43.866389",
    "ICAO": "LTCT",
    "Airport ID": "9043",
    "Latitude": "39.983056",
    "Timezone": "3100",
    "DST": "2",
    "destinations": ["1682", "1701"]
}, {
    "City": "Wallis",
    "DBTZ": "U",
    "Name": "Wallis",
    "Country": "Wallis and Futuna",
    "IATA/FAA": "WLS",
    "Longitude": "-176.199228",
    "ICAO": "NLWW",
    "Airport ID": "1968",
    "Latitude": "-13.238281",
    "Timezone": "79",
    "DST": "12",
    "destinations": ["5885", "1960", "2005"]
}, {
    "City": "Kutahya",
    "DBTZ": "E",
    "Name": "Zafer",
    "Country": "Turkey",
    "IATA/FAA": "KZR",
    "Longitude": "30.13",
    "ICAO": "LTBZ",
    "Airport ID": "9045",
    "Latitude": "39.111389",
    "Timezone": "3327",
    "DST": "2",
    "destinations": ["1701"]
}, {
    "City": "Sanliurfa",
    "DBTZ": "E",
    "Name": "Sanliurfa GAP",
    "Country": "Turkey",
    "IATA/FAA": "GNY",
    "Longitude": "38.9",
    "ICAO": "LTCS",
    "Airport ID": "9044",
    "Latitude": "37.45",
    "Timezone": "2700",
    "DST": "2",
    "destinations": ["1701", "1706", "4317", "1682"]
}, {
    "City": "Lansing",
    "DBTZ": "A",
    "Name": "Capital City",
    "Country": "United States",
    "IATA/FAA": "LAN",
    "Longitude": "-84.587357",
    "ICAO": "KLAN",
    "Airport ID": "3544",
    "Latitude": "42.7787",
    "Timezone": "861",
    "DST": "-5",
    "destinations": ["3682", "3645", "3858", "4167", "3520", "3830"]
}, {
    "City": "Kamuela",
    "DBTZ": "A",
    "Name": "Waimea Kohala",
    "Country": "United States",
    "IATA/FAA": "MUE",
    "Longitude": "-155.668108",
    "ICAO": "PHMU",
    "Airport ID": "3545",
    "Latitude": "20.001328",
    "Timezone": "2671",
    "DST": "-10",
    "destinations": ["3456"]
}, {
    "City": "Massena",
    "DBTZ": "A",
    "Name": "Massena Intl Richards Fld",
    "Country": "United States",
    "IATA/FAA": "MSS",
    "Longitude": "-74.845547",
    "ICAO": "KMSS",
    "Airport ID": "3546",
    "Latitude": "44.935833",
    "Timezone": "215",
    "DST": "-5",
    "destinations": ["3864"]
}, {
    "City": "Harrisburg",
    "DBTZ": "A",
    "Name": "Harrisburg Intl",
    "Country": "United States",
    "IATA/FAA": "MDT",
    "Longitude": "-76.763403",
    "ICAO": "KMDT",
    "Airport ID": "3542",
    "Latitude": "40.193494",
    "Timezone": "310",
    "DST": "-5",
    "destinations": ["3682", "3448", "3876", "3830", "3752", "193", "3645", "3751", "3878", "3617", "4167", "3714"]
}, {
    "City": "Lincoln",
    "DBTZ": "A",
    "Name": "Lincoln",
    "Country": "United States",
    "IATA/FAA": "LNK",
    "Longitude": "-96.75925",
    "ICAO": "KLNK",
    "Airport ID": "3543",
    "Latitude": "40.850971",
    "Timezone": "1219",
    "DST": "-6",
    "destinations": ["3858", "3751", "3830"]
}, {
    "City": "Usinsk",
    "DBTZ": "N",
    "Name": "Usinsk",
    "Country": "Russia",
    "IATA/FAA": "USK",
    "Longitude": "57.221113",
    "ICAO": "",
    "Airport ID": "4369",
    "Latitude": "66.00077",
    "Timezone": "20",
    "DST": "4",
    "destinations": ["4362", "4368"]
}, {
    "City": "Rio Hondo",
    "DBTZ": "S",
    "Name": "Termal",
    "Country": "Argentina",
    "IATA/FAA": "RHD",
    "Longitude": "-64.936666",
    "ICAO": "SANH",
    "Airport ID": "8914",
    "Latitude": "-27.509946",
    "Timezone": "1181",
    "DST": "-3",
    "destinations": ["2442"]
}, {
    "City": "Santa Maria (island)",
    "DBTZ": "E",
    "Name": "Santa Maria",
    "Country": "Portugal",
    "IATA/FAA": "SMA",
    "Longitude": "-25.170639",
    "ICAO": "LPAZ",
    "Airport ID": "1617",
    "Latitude": "36.97139",
    "Timezone": "308",
    "DST": "-1",
    "destinations": ["1633"]
}, {
    "City": "Sochi",
    "DBTZ": "N",
    "Name": "Sochi",
    "Country": "Russia",
    "IATA/FAA": "AER",
    "Longitude": "39.956589",
    "ICAO": "URSS",
    "Airport ID": "2965",
    "Latitude": "43.449928",
    "Timezone": "89",
    "DST": "4",
    "destinations": ["2990", "2979", "1735", "2954", "2983", "1726", "3964", "2960", "4029", "1701", "2948", "2958", "2985", "2975", "6147", "2988", "4374"]
}, {
    "City": "Rostov",
    "DBTZ": "N",
    "Name": "Rostov Na Donu",
    "Country": "Russia",
    "IATA/FAA": "ROV",
    "Longitude": "39.818089",
    "ICAO": "URRR",
    "Airport ID": "2964",
    "Latitude": "47.258208",
    "Timezone": "280",
    "DST": "4",
    "destinations": ["6156", "2967", "1486", "2188", "2191", "2983", "1587", "1613", "2939", "4029", "1688", "3964", "1701", "6147", "2948", "2942", "2985", "2975", "1590", "1489", "2979", "4118"]
}, {
    "City": "Volgograd",
    "DBTZ": "N",
    "Name": "Gumrak",
    "Country": "Russia",
    "IATA/FAA": "VOG",
    "Longitude": "44.345544",
    "ICAO": "URWW",
    "Airport ID": "2967",
    "Latitude": "48.782528",
    "Timezone": "482",
    "DST": "4",
    "destinations": ["3964", "2960", "2948", "2964", "2188", "2983", "6152", "4029", "2985", "2979"]
}, {
    "City": "Astrakhan",
    "DBTZ": "N",
    "Name": "Astrakhan",
    "Country": "Russia",
    "IATA/FAA": "ASF",
    "Longitude": "48.006278",
    "ICAO": "URWA",
    "Airport ID": "2966",
    "Latitude": "46.283333",
    "Timezone": "-65",
    "DST": "4",
    "destinations": ["2990", "2962", "4029", "2948", "4367", "2985", "4317"]
}, {
    "City": "Makhachkala",
    "DBTZ": "N",
    "Name": "Uytash",
    "Country": "Russia",
    "IATA/FAA": "MCX",
    "Longitude": "47.652294",
    "ICAO": "URML",
    "Airport ID": "2961",
    "Latitude": "42.816822",
    "Timezone": "12",
    "DST": "4",
    "destinations": ["4029", "2948", "4367", "2960"]
}, {
    "City": "Krasnodar",
    "DBTZ": "N",
    "Name": "Pashkovskiy",
    "Country": "Russia",
    "IATA/FAA": "KRR",
    "Longitude": "39.170539",
    "ICAO": "URKK",
    "Airport ID": "2960",
    "Latitude": "45.034689",
    "Timezone": "118",
    "DST": "4",
    "destinations": ["4029", "4078", "2967", "2987", "2954", "4367", "2188", "2983", "1613", "4317", "2912", "2965", "2979", "6156", "6149", "1701", "1197", "2961", "4364", "1587", "1546", "1590", "2988", "3964", "2948", "2985", "2975", "6147", "1489", "346", "6150", "1198", "1486", "4374"]
}, {
    "City": "Stavropol",
    "DBTZ": "N",
    "Name": "Shpakovskoye",
    "Country": "Russia",
    "IATA/FAA": "STW",
    "Longitude": "42.112778",
    "ICAO": "URMT",
    "Airport ID": "2963",
    "Latitude": "45.109165",
    "Timezone": "1486",
    "DST": "4",
    "destinations": ["4029", "2985", "4317"]
}, {
    "City": "Mineralnye Vody",
    "DBTZ": "N",
    "Name": "Mineralnyye Vody",
    "Country": "Russia",
    "IATA/FAA": "MRV",
    "Longitude": "43.081889",
    "ICAO": "URMM",
    "Airport ID": "2962",
    "Latitude": "44.225072",
    "Timezone": "1054",
    "DST": "4",
    "destinations": ["2966", "4363", "4317", "4367", "2188", "2983", "2922", "1590", "4029", "3964", "2948", "2985", "2975", "8076", "2979", "2988"]
}, {
    "City": "Ukhta",
    "DBTZ": "N",
    "Name": "Ukhta",
    "Country": "Russia",
    "IATA/FAA": "UCT",
    "Longitude": "53.482592",
    "ICAO": "",
    "Airport ID": "4368",
    "Latitude": "63.340297",
    "Timezone": "100",
    "DST": "4",
    "destinations": ["2988", "2948", "2989", "4369"]
}, {
    "City": "Salzburg",
    "DBTZ": "E",
    "Name": "Salzburg",
    "Country": "Austria",
    "IATA/FAA": "SZG",
    "Longitude": "13.004333",
    "ICAO": "LOWS",
    "Airport ID": "1612",
    "Latitude": "47.793304",
    "Timezone": "1411",
    "DST": "1",
    "destinations": ["1678", "344", "1055", "1322", "345", "342", "1452", "502", "1054", "3998", "1056", "351", "1613", "548", "1688", "1130", "340", "1701", "337"]
}, {
    "City": "Bolzano",
    "DBTZ": "E",
    "Name": "Bolzano",
    "Country": "Italy",
    "IATA/FAA": "BZO",
    "Longitude": "11.326383",
    "ICAO": "LIPB",
    "Airport ID": "1536",
    "Latitude": "46.460194",
    "Timezone": "789",
    "DST": "1",
    "destinations": ["1555"]
}, {
    "City": "Magnetiogorsk",
    "DBTZ": "N",
    "Name": "Magnitogorsk",
    "Country": "Russia",
    "IATA/FAA": "MQF",
    "Longitude": "58.755661",
    "ICAO": "USCM",
    "Airport ID": "2969",
    "Latitude": "53.393131",
    "Timezone": "1431",
    "DST": "6",
    "destinations": ["2975", "2985"]
}, {
    "City": "Chelyabinsk",
    "DBTZ": "N",
    "Name": "Balandino",
    "Country": "Russia",
    "IATA/FAA": "CEK",
    "Longitude": "61.503333",
    "ICAO": "USCC",
    "Airport ID": "2968",
    "Latitude": "55.305836",
    "Timezone": "769",
    "DST": "6",
    "destinations": ["2990", "4078", "1587", "2912", "2913", "4029", "2979", "3964", "6147", "2948", "2985", "8076", "6150", "2983"]
}, {
    "City": "Huatulco",
    "DBTZ": "S",
    "Name": "Bahias De Huatulco Intl",
    "Country": "Mexico",
    "IATA/FAA": "HUX",
    "Longitude": "-96.262572",
    "ICAO": "MMBT",
    "Airport ID": "1786",
    "Latitude": "15.775317",
    "Timezone": "464",
    "DST": "-6",
    "destinations": ["1824", "3550"]
}, {
    "City": "Vienna",
    "DBTZ": "E",
    "Name": "Schwechat",
    "Country": "Austria",
    "IATA/FAA": "VIE",
    "Longitude": "16.569722",
    "ICAO": "LOWW",
    "Airport ID": "1613",
    "Latitude": "48.110278",
    "Timezone": "600",
    "DST": "1",
    "destinations": ["344", "352", "342", "350", "351", "3941", "1452", "3973", "507", "1055", "1230", "2170", "737", "1218", "1382", "1460", "1474", "609", "345", "1555", "1563", "4091", "340", "421", "1468", "1479", "1456", "1197", "1054", "1229", "1606", "346", "1524", "1354", "347", "1520", "3998", "1470", "1472", "1486", "1612", "1056", "1246", "1448", "1678", "1488", "193", "1335", "210", "3093", "1509", "1529", "2954", "3885", "3953", "3364", "2276", "644", "599", "2188", "1194", "1688", "4029", "1130", "4197", "1590", "591", "4330", "2922", "1569", "1739", "2910", "580", "629", "348", "669", "679", "478", "4053", "1665", "1128", "2279", "1538", "302", "1489", "1652", "1200", "1715", "2941", "3989", "3964", "1609", "6105", "3714", "1655", "1610", "1701", "3797", "2939", "1735", "4322", "2960", "1746", "2948", "1638", "1611", "2945", "1561", "2947", "3830", "1657", "1512", "1587", "1742", "2964", "1658", "1646", "1737", "1206", "1508", "1741", "1190", "1196", "1551", "2988", "3959", "1208", "1682", "4317", "1679", "2241", "353", "2985", "1676", "287", "502", "1706"]
}, {
    "City": "Tanana",
    "DBTZ": "A",
    "Name": "Tanana Airport",
    "Country": "United States",
    "IATA/FAA": "TAL",
    "Longitude": "-152.075833",
    "ICAO": "",
    "Airport ID": "7113",
    "Latitude": "65.179556",
    "Timezone": "207",
    "DST": "-9",
    "destinations": ["3832", "7108", "6722"]
}, {
    "City": "Rampart",
    "DBTZ": "A",
    "Name": "Rampart Airport",
    "Country": "United States",
    "IATA/FAA": "RMP",
    "Longitude": "-150.140833",
    "ICAO": "",
    "Airport ID": "7112",
    "Latitude": "65.507778",
    "Timezone": "302",
    "DST": "-9",
    "destinations": ["3832"]
}, {
    "City": "Nulato",
    "DBTZ": "A",
    "Name": "Nulato Airport",
    "Country": "United States",
    "IATA/FAA": "NUL",
    "Longitude": "-158.074167",
    "ICAO": "PANU",
    "Airport ID": "7111",
    "Latitude": "64.729444",
    "Timezone": "399",
    "DST": "-9",
    "destinations": ["3764", "6717", "7208"]
}, {
    "City": "Minto",
    "DBTZ": "A",
    "Name": "Minto Airport",
    "Country": "United States",
    "IATA/FAA": "MNT",
    "Longitude": "-149.37",
    "ICAO": "",
    "Airport ID": "7110",
    "Latitude": "65.143611",
    "Timezone": "460",
    "DST": "-9",
    "destinations": ["3832"]
}, {
    "City": "Shungnak",
    "DBTZ": "A",
    "Name": "Shungnak Airport",
    "Country": "United States",
    "IATA/FAA": "SHG",
    "Longitude": "-157.1625",
    "ICAO": "PAGH",
    "Airport ID": "7117",
    "Latitude": "66.888056",
    "Timezone": "197",
    "DST": "-9",
    "destinations": ["7180"]
}, {
    "City": "Central",
    "DBTZ": "A",
    "Name": "Central Airport",
    "Country": "United States",
    "IATA/FAA": "CEM",
    "Longitude": "-144.780833",
    "ICAO": "PACE",
    "Airport ID": "7116",
    "Latitude": "65.573889",
    "Timezone": "937",
    "DST": "-9",
    "destinations": ["7236"]
}, {
    "City": "Beaver",
    "DBTZ": "A",
    "Name": "Beaver Airport",
    "Country": "United States",
    "IATA/FAA": "WBQ",
    "Longitude": "-147.406667",
    "ICAO": "PAWB",
    "Airport ID": "7115",
    "Latitude": "66.362222",
    "Timezone": "359",
    "DST": "-9",
    "destinations": ["3832"]
}, {
    "City": "Venetie",
    "DBTZ": "A",
    "Name": "Venetie Airport",
    "Country": "United States",
    "IATA/FAA": "VEE",
    "Longitude": "-146.366389",
    "ICAO": "PAVE",
    "Airport ID": "7114",
    "Latitude": "67.008611",
    "Timezone": "574",
    "DST": "-9",
    "destinations": ["6729", "3420"]
}, {
    "City": "Baghdogra",
    "DBTZ": "N",
    "Name": "Bagdogra",
    "Country": "India",
    "IATA/FAA": "IXB",
    "Longitude": "88.328567",
    "ICAO": "VEBD",
    "Airport ID": "3040",
    "Latitude": "26.681206",
    "Timezone": "412",
    "DST": "5.5",
    "destinations": ["3043", "3093", "6173", "3885", "3155"]
}, {
    "City": "Kolkata",
    "DBTZ": "N",
    "Name": "Netaji Subhash Chandra Bose Intl",
    "Country": "India",
    "IATA/FAA": "CCU",
    "Longitude": "88.446722",
    "ICAO": "VECC",
    "Airport ID": "3043",
    "Latitude": "22.654739",
    "Timezone": "16",
    "DST": "5.5",
    "destinations": ["3069", "3076", "2994", "3042", "3885", "3131", "2997", "3093", "6443", "6173", "3141", "3050", "3038", "3040", "3063", "3098", "3144", "3015", "3061", "3017", "3020", "3039", "3055", "3146", "3084", "3066", "6174", "3125", "3239", "6172", "3316", "3304", "3077", "2188", "3155", "3382", "2241"]
}, {
    "City": "Brich Creek",
    "DBTZ": "A",
    "Name": "Birch Creek Airport",
    "Country": "United States",
    "IATA/FAA": "KBC",
    "Longitude": "-145.815319",
    "ICAO": "",
    "Airport ID": "7118",
    "Latitude": "66.256708",
    "Timezone": "450",
    "DST": "-9",
    "destinations": ["3420"]
}, {
    "City": "Ketchikan",
    "DBTZ": "A",
    "Name": "Ketchikan Intl",
    "Country": "United States",
    "IATA/FAA": "KTN",
    "Longitude": "-131.71375",
    "ICAO": "PAKT",
    "Airport ID": "3808",
    "Latitude": "55.355556",
    "Timezone": "88",
    "DST": "-9",
    "destinations": ["7144", "7145", "7147", "7146", "3492", "3577", "3856", "5969", "7205", "7148", "7237", "7150"]
}, {
    "City": "Pune",
    "DBTZ": "N",
    "Name": "Pune",
    "Country": "India",
    "IATA/FAA": "PNQ",
    "Longitude": "73.919697",
    "ICAO": "VAPO",
    "Airport ID": "3017",
    "Latitude": "18.582111",
    "Timezone": "1942",
    "DST": "5.5",
    "destinations": ["2994", "3131", "3043", "3093", "3141", "3144", "3015", "2997", "3008", "3007", "2191", "3136"]
}, {
    "City": "Kalkmar",
    "DBTZ": "E",
    "Name": "Kalmar",
    "Country": "Sweden",
    "IATA/FAA": "KLR",
    "Longitude": "16.287578",
    "ICAO": "ESMQ",
    "Airport ID": "707",
    "Latitude": "56.685531",
    "Timezone": "17",
    "DST": "1",
    "destinations": ["738", "737"]
}, {
    "City": "Al Hociema",
    "DBTZ": "N",
    "Name": "Cherif El Idrissi",
    "Country": "Morocco",
    "IATA/FAA": "AHU",
    "Longitude": "-3.839525",
    "ICAO": "GMTA",
    "Airport ID": "1078",
    "Latitude": "35.177103",
    "Timezone": "95",
    "DST": "0",
    "destinations": ["1074"]
}, {
    "City": "Tetouan",
    "DBTZ": "N",
    "Name": "Saniat Rmel",
    "Country": "Morocco",
    "IATA/FAA": "TTU",
    "Longitude": "-5.320019",
    "ICAO": "GMTN",
    "Airport ID": "1079",
    "Latitude": "35.594333",
    "Timezone": "10",
    "DST": "0",
    "destinations": ["1078"]
}, {
    "City": "Ouarzazate",
    "DBTZ": "N",
    "Name": "Ouarzazate",
    "Country": "Morocco",
    "IATA/FAA": "OZZ",
    "Longitude": "-6.909431",
    "ICAO": "GMMZ",
    "Airport ID": "1077",
    "Latitude": "30.939053",
    "Timezone": "3782",
    "DST": "0",
    "destinations": ["1074", "1386"]
}, {
    "City": "Casablanca",
    "DBTZ": "N",
    "Name": "Mohammed V Intl",
    "Country": "Morocco",
    "IATA/FAA": "CMN",
    "Longitude": "-7.589967",
    "ICAO": "GMMN",
    "Airport ID": "1074",
    "Latitude": "33.367467",
    "Timezone": "656",
    "DST": "0",
    "destinations": ["1218", "1525", "1538", "302", "1534", "502", "1335", "1359", "4317", "1273", "1539", "1154", "1157", "1382", "210", "231", "253", "1064", "1230", "580", "737", "2179", "2177", "1050", "1044", "1264", "883", "1128", "4162", "245", "1084", "897", "5672", "1555", "1066", "1020", "1059", "340", "2564", "1665", "1701", "2072", "3797", "951", "970", "298", "507", "1638", "1054", "1229", "1353", "346", "1524", "1354", "5673", "280", "1094", "4161", "1418", "1386", "246", "1070", "5665", "1077", "886", "5674", "1075", "1072", "1063", "891", "2985", "1435", "1057", "1080", "1526", "1079", "287", "351", "5670", "1246", "146", "1678", "2188", "2241", "2082"]
}, {
    "City": "Marrakech",
    "DBTZ": "N",
    "Name": "Menara",
    "Country": "Morocco",
    "IATA/FAA": "RAK",
    "Longitude": "-8.0363",
    "ICAO": "GMMX",
    "Airport ID": "1075",
    "Latitude": "31.606886",
    "Timezone": "1545",
    "DST": "0",
    "destinations": ["344", "1353", "1273", "1264", "302", "1074", "502", "1335", "1229", "346", "1524", "1354", "1418", "1386", "609", "644", "1525", "1367", "1553", "304", "1309", "599", "585", "1222", "355", "492", "4198", "1562", "548", "1253", "1376", "1374", "1064", "580", "591", "1218", "629", "1665", "1678", "1054", "469", "1399", "478", "1435", "1638", "490", "4053", "1382", "337"]
}, {
    "City": "Rabat",
    "DBTZ": "N",
    "Name": "Sale",
    "Country": "Morocco",
    "IATA/FAA": "RBA",
    "Longitude": "-6.751519",
    "ICAO": "GMME",
    "Airport ID": "1072",
    "Latitude": "34.051467",
    "Timezone": "276",
    "DST": "0",
    "destinations": ["1382", "1074", "1386", "1367", "304", "1222", "1229", "1353", "548"]
}, {
    "City": "Oujda",
    "DBTZ": "N",
    "Name": "Angads",
    "Country": "Morocco",
    "IATA/FAA": "OUD",
    "Longitude": "-1.923986",
    "ICAO": "GMFO",
    "Airport ID": "1070",
    "Latitude": "34.78715",
    "Timezone": "1535",
    "DST": "0",
    "destinations": ["302", "1074", "1386", "1367", "304", "1353", "4198", "1399", "1335"]
}, {
    "City": "Zurich",
    "DBTZ": "E",
    "Name": "Zurich",
    "Country": "Switzerland",
    "IATA/FAA": "ZRH",
    "Longitude": "8.549167",
    "ICAO": "LSZH",
    "Airport ID": "1678",
    "Latitude": "47.464722",
    "Timezone": "1416",
    "DST": "1",
    "destinations": ["1506", "1264", "490", "1197", "1054", "4197", "338", "1558", "1609", "1612", "1688", "1130", "1742", "1737", "344", "342", "1638", "3797", "507", "3752", "1055", "1212", "1509", "345", "4091", "1051", "410", "421", "1452", "1225", "503", "1229", "1561", "1520", "3998", "1508", "1056", "351", "1613", "146", "193", "1382", "2997", "3093", "1074", "1555", "608", "3953", "3682", "599", "2188", "340", "1563", "1665", "348", "1526", "1194", "16", "1218", "5794", "1569", "1739", "668", "669", "680", "3930", "580", "1606", "352", "346", "4059", "347", "350", "679", "1230", "737", "3941", "469", "3885", "3448", "302", "1489", "1128", "609", "1200", "4029", "3494", "2564", "1909", "3077", "3714", "1701", "813", "2939", "3877", "3484", "2948", "1675", "629", "1335", "478", "3576", "1524", "1354", "2279", "1636", "3830", "644", "1657", "3364", "1587", "1760", "3406", "1075", "3469", "3316", "1206", "1590", "3646", "1551", "1246", "1208", "4317", "2241", "2170", "499", "2985", "293", "7447", "287", "502", "492", "2194", "353", "341", "1706", "1741"]
}, {
    "City": "Altenrhein",
    "DBTZ": "E",
    "Name": "St Gallen Altenrhein",
    "Country": "Switzerland",
    "IATA/FAA": "ACH",
    "Longitude": "9.560775",
    "ICAO": "LSZR",
    "Airport ID": "1679",
    "Latitude": "47.485033",
    "Timezone": "1306",
    "DST": "1",
    "destinations": ["1558", "1613"]
}, {
    "City": "Lugano",
    "DBTZ": "E",
    "Name": "Lugano",
    "Country": "Switzerland",
    "IATA/FAA": "LUG",
    "Longitude": "8.910578",
    "ICAO": "LSZA",
    "Airport ID": "1675",
    "Latitude": "46.004275",
    "Timezone": "915",
    "DST": "1",
    "destinations": ["1665", "1678"]
}, {
    "City": "Bern",
    "DBTZ": "E",
    "Name": "Bern Belp",
    "Country": "Switzerland",
    "IATA/FAA": "BRN",
    "Longitude": "7.497153",
    "ICAO": "LSZB",
    "Airport ID": "1676",
    "Latitude": "46.9141",
    "Timezone": "1674",
    "DST": "1",
    "destinations": ["580", "1218", "344", "1558", "342", "1225", "503", "346", "1520", "3998", "351", "1613"]
}, {
    "City": "Nouakschott",
    "DBTZ": "N",
    "Name": "Nouakchott",
    "Country": "Mauritania",
    "IATA/FAA": "NKC",
    "Longitude": "-15.947956",
    "ICAO": "GQNN",
    "Airport ID": "1094",
    "Latitude": "18.097856",
    "Timezone": "7",
    "DST": "0",
    "destinations": ["1382", "4162", "1074", "1084", "1054", "1044", "1097", "287"]
}, {
    "City": "Nouadhibou",
    "DBTZ": "N",
    "Name": "Nouadhibou",
    "Country": "Mauritania",
    "IATA/FAA": "NDB",
    "Longitude": "-17.029956",
    "ICAO": "GQPP",
    "Airport ID": "1097",
    "Latitude": "20.933067",
    "Timezone": "16",
    "DST": "0",
    "destinations": ["1054", "1094"]
}, {
    "City": "Arctic Bay",
    "DBTZ": "A",
    "Name": "Arctic Bay Airport",
    "Country": "Canada",
    "IATA/FAA": "YAB",
    "Longitude": "-85.047222",
    "ICAO": "CJX7",
    "Airport ID": "8225",
    "Latitude": "73.006389",
    "Timezone": "72",
    "DST": "-6",
    "destinations": ["55", "128"]
}, {
    "City": "Cortez",
    "DBTZ": "A",
    "Name": "Cortez Muni",
    "Country": "United States",
    "IATA/FAA": "CEZ",
    "Longitude": "-108.628056",
    "ICAO": "KCEZ",
    "Airport ID": "4338",
    "Latitude": "37.303",
    "Timezone": "5918",
    "DST": "-7",
    "destinations": ["3751"]
}, {
    "City": "Hayden",
    "DBTZ": "A",
    "Name": "Yampa Valley",
    "Country": "United States",
    "IATA/FAA": "HDN",
    "Longitude": "-107.21766",
    "ICAO": "KHDN",
    "Airport ID": "4339",
    "Latitude": "40.481181",
    "Timezone": "6602",
    "DST": "-7",
    "destinations": ["3751"]
}, {
    "City": "Poznan",
    "DBTZ": "E",
    "Name": "Lawica",
    "Country": "Poland",
    "IATA/FAA": "POZ",
    "Longitude": "16.826325",
    "ICAO": "EPPO",
    "Airport ID": "674",
    "Latitude": "52.421031",
    "Timezone": "308",
    "DST": "1",
    "destinations": ["345", "490", "1553", "599", "535", "1222", "491", "657", "548", "340", "346", "679", "609", "1218", "1367", "5562", "492", "699", "664"]
}, {
    "City": "Rzeszow",
    "DBTZ": "E",
    "Name": "Jasionka",
    "Country": "Poland",
    "IATA/FAA": "RZE",
    "Longitude": "22.019",
    "ICAO": "EPRZ",
    "Airport ID": "675",
    "Latitude": "50.109958",
    "Timezone": "675",
    "DST": "1",
    "destinations": ["490", "599", "523", "492", "478", "657", "548", "1367", "1555", "340", "679"]
}, {
    "City": "Mudanjiang",
    "DBTZ": "U",
    "Name": "Mudanjiang",
    "Country": "China",
    "IATA/FAA": "MDG",
    "Longitude": "129.568889",
    "ICAO": "",
    "Airport ID": "4334",
    "Latitude": "44.523889",
    "Timezone": "883",
    "DST": "8",
    "destinations": ["3364", "3404", "3930", "3406", "4144", "3390"]
}, {
    "City": "Meridian",
    "DBTZ": "A",
    "Name": "Key Field",
    "Country": "United States",
    "IATA/FAA": "MEI",
    "Longitude": "-88.751868",
    "ICAO": "KMEI",
    "Airport ID": "4335",
    "Latitude": "32.332624",
    "Timezone": "297",
    "DST": "-6",
    "destinations": ["3682"]
}, {
    "City": "Katowice",
    "DBTZ": "E",
    "Name": "Pyrzowice",
    "Country": "Poland",
    "IATA/FAA": "KTW",
    "Longitude": "19.080019",
    "ICAO": "EPKT",
    "Airport ID": "671",
    "Latitude": "50.474253",
    "Timezone": "995",
    "DST": "1",
    "destinations": ["345", "1212", "469", "1474", "599", "548", "340", "679", "1218", "636", "1525", "1367", "344", "1553", "5562", "373", "585", "355", "3972", "492", "708", "1561", "699", "666", "1590", "664", "2944"]
}, {
    "City": "Tehran",
    "DBTZ": "E",
    "Name": "Imam Khomeini",
    "Country": "Iran",
    "IATA/FAA": "IKA",
    "Longitude": "51.152222",
    "ICAO": "OIIE",
    "Airport ID": "4330",
    "Latitude": "35.416111",
    "Timezone": "3305",
    "DST": "3.5",
    "destinations": ["2979", "1555", "2954", "3399", "2188", "737", "3964", "1701", "5952", "2050", "2179", "2191", "2057", "580", "2177", "2997", "344", "1682", "340", "687", "2922", "342", "2206", "3304", "507", "1524", "7490", "1386", "3364", "2983", "1613", "2176", "4317", "2241", "2985", "2908", "3885", "3370", "3093", "345", "3989", "3406", "2194"]
}, {
    "City": "Mashhad",
    "DBTZ": "E",
    "Name": "Mashhad",
    "Country": "Iran",
    "IATA/FAA": "MHD",
    "Longitude": "59.643",
    "ICAO": "OIMM",
    "Airport ID": "4331",
    "Latitude": "36.234",
    "Timezone": "3263",
    "DST": "3.5",
    "destinations": ["2097", "2104", "2164", "2134", "5712", "4150", "2114", "2123", "5933", "2157", "2162", "2131", "2167", "2106", "2188", "2979", "6747", "6790", "2048", "2050", "2051", "2176", "2053", "5932", "2151", "2166", "2191", "2057", "5935", "2177", "2064", "2142", "2207", "2221", "2241", "2074", "1701", "2109"]
}, {
    "City": "Coimbatore",
    "DBTZ": "N",
    "Name": "Coimbatore",
    "Country": "India",
    "IATA/FAA": "CJB",
    "Longitude": "77.043383",
    "ICAO": "VOCB",
    "Airport ID": "3135",
    "Latitude": "11.030031",
    "Timezone": "1324",
    "DST": "5.5",
    "destinations": ["2997", "3093", "3144", "3131", "3137", "2191", "3316", "3141"]
}, {
    "City": "Vijayawada",
    "DBTZ": "N",
    "Name": "Vijayawada",
    "Country": "India",
    "IATA/FAA": "VGA",
    "Longitude": "80.796847",
    "ICAO": "VOBZ",
    "Airport ID": "3134",
    "Latitude": "16.530433",
    "Timezone": "82",
    "DST": "5.5",
    "destinations": ["3141", "3131"]
}, {
    "City": "Calicut",
    "DBTZ": "N",
    "Name": "Calicut",
    "Country": "India",
    "IATA/FAA": "CCJ",
    "Longitude": "75.9553",
    "ICAO": "VOCL",
    "Airport ID": "3137",
    "Latitude": "11.136839",
    "Timezone": "342",
    "DST": "5.5",
    "destinations": ["2179", "2997", "3135", "3136", "2188", "2072", "2082", "2191", "5937", "2057", "2064", "3143", "2176", "2194", "2195", "2241", "3131", "3144"]
}, {
    "City": "Kochi",
    "DBTZ": "N",
    "Name": "Cochin",
    "Country": "India",
    "IATA/FAA": "COK",
    "Longitude": "76.391389",
    "ICAO": "VOCI",
    "Airport ID": "3136",
    "Latitude": "10.155556",
    "Timezone": "8",
    "DST": "5.5",
    "destinations": ["3131", "2997", "3093", "2188", "3141", "3144", "3153", "2179", "2064", "2241", "2194", "2191", "3130", "3137", "3304", "2057", "2176", "3316", "3024", "3156", "3017", "2082"]
}, {
    "City": "Bangalore",
    "DBTZ": "N",
    "Name": "Bangalore",
    "Country": "India",
    "IATA/FAA": "BLR",
    "Longitude": "77.668206",
    "ICAO": "VOBL",
    "Airport ID": "3131",
    "Latitude": "12.949986",
    "Timezone": "2912",
    "DST": "5.5",
    "destinations": ["2994", "3042", "2997", "3043", "3136", "3093", "6173", "3007", "3141", "3098", "3105", "3144", "3015", "3017", "3153", "3066", "2179", "3135", "3008", "3143", "3304", "507", "1382", "340", "3077", "2188", "2191", "3316", "3024", "2241", "3137", "6168", "3000", "7774", "3134", "2064", "2072", "2082", "3885", "2194"]
}, {
    "City": "Agatti Island",
    "DBTZ": "N",
    "Name": "Agatti",
    "Country": "India",
    "IATA/FAA": "AGX",
    "Longitude": "72.176042",
    "ICAO": "VOAT",
    "Airport ID": "3130",
    "Latitude": "10.823656",
    "Timezone": "14",
    "DST": "5.5",
    "destinations": ["3136"]
}, {
    "City": "Cuneo",
    "DBTZ": "E",
    "Name": "Levaldigi",
    "Country": "Italy",
    "IATA/FAA": "CUF",
    "Longitude": "7.623217",
    "ICAO": "LIMZ",
    "Airport ID": "1534",
    "Latitude": "44.547019",
    "Timezone": "1267",
    "DST": "1",
    "destinations": ["1074", "1517", "1519", "1515", "1657"]
}, {
    "City": "Nantes",
    "DBTZ": "E",
    "Name": "Nantes Atlantique",
    "Country": "France",
    "IATA/FAA": "NTE",
    "Longitude": "-1.610725",
    "ICAO": "LFRS",
    "Airport ID": "1418",
    "Latitude": "47.153189",
    "Timezone": "90",
    "DST": "1",
    "destinations": ["3941", "1264", "302", "1322", "345", "1323", "1399", "1335", "1359", "1524", "1435", "1273", "1324", "580", "1321", "1382", "503", "478", "1353", "1354", "1386", "495", "1074", "1075", "293", "287", "599", "1066", "1230", "1218", "1555", "1229", "1253", "1452", "1638", "286", "1606", "1636", "351", "1551", "4053", "1665", "502", "1563", "1051", "346", "1561", "1520", "1356", "3998", "1512", "1587", "1056", "1246", "1706"]
}, {
    "City": "Viedma",
    "DBTZ": "N",
    "Name": "Gobernador Castello",
    "Country": "Argentina",
    "IATA/FAA": "VDM",
    "Longitude": "-63.000389",
    "ICAO": "SAVV",
    "Airport ID": "2491",
    "Latitude": "-40.869222",
    "Timezone": "20",
    "DST": "-3",
    "destinations": ["2490"]
}, {
    "City": "Fortaleza",
    "DBTZ": "S",
    "Name": "Pinto Martins Intl",
    "Country": "Brazil",
    "IATA/FAA": "FOR",
    "Longitude": "-38.532556",
    "ICAO": "SBFZ",
    "Airport ID": "2559",
    "Latitude": "-3.776283",
    "Timezone": "82",
    "DST": "-3",
    "destinations": ["2526", "2537", "2575", "2597", "2610", "2616", "2623", "2578", "2531", "3988", "2560", "2564", "2621", "1524", "2551", "6034", "1638", "5674"]
}, {
    "City": "Caen",
    "DBTZ": "E",
    "Name": "Carpiquet",
    "Country": "France",
    "IATA/FAA": "CFR",
    "Longitude": "-0.45",
    "ICAO": "LFRK",
    "Airport ID": "1412",
    "Latitude": "49.173333",
    "Timezone": "256",
    "DST": "1",
    "destinations": ["1335", "1354", "1386", "1324"]
}, {
    "City": "Rennes",
    "DBTZ": "E",
    "Name": "St Jacques",
    "Country": "France",
    "IATA/FAA": "RNS",
    "Longitude": "-1.734794",
    "ICAO": "LFRN",
    "Airport ID": "1415",
    "Latitude": "48.069508",
    "Timezone": "124",
    "DST": "1",
    "destinations": ["1335", "1354", "1273", "1382", "552", "1353", "495", "1264", "1218"]
}, {
    "City": "Lannion",
    "DBTZ": "E",
    "Name": "Lannion",
    "Country": "France",
    "IATA/FAA": "LAI",
    "Longitude": "-3.471656",
    "ICAO": "LFRO",
    "Airport ID": "1416",
    "Latitude": "48.754378",
    "Timezone": "290",
    "DST": "1",
    "destinations": ["1386"]
}, {
    "City": "Quimper",
    "DBTZ": "E",
    "Name": "Pluguffan",
    "Country": "France",
    "IATA/FAA": "UIP",
    "Longitude": "-4.167786",
    "ICAO": "LFRQ",
    "Airport ID": "1417",
    "Latitude": "47.974981",
    "Timezone": "297",
    "DST": "1",
    "destinations": ["1386"]
}, {
    "City": "Calvi",
    "DBTZ": "E",
    "Name": "Saint Catherine",
    "Country": "France",
    "IATA/FAA": "CLY",
    "Longitude": "8.793189",
    "ICAO": "LFKC",
    "Airport ID": "1322",
    "Latitude": "42.530753",
    "Timezone": "209",
    "DST": "1",
    "destinations": ["344", "1418", "345", "3986", "1612", "1382", "1353", "1354", "1386", "629", "1264"]
}, {
    "City": "Figari",
    "DBTZ": "E",
    "Name": "Sud Corse",
    "Country": "France",
    "IATA/FAA": "FSC",
    "Longitude": "9.097777",
    "ICAO": "LFKF",
    "Airport ID": "1323",
    "Latitude": "41.500557",
    "Timezone": "87",
    "DST": "1",
    "destinations": ["1399", "1418", "1353", "1354", "304", "1277", "629", "1264", "1386"]
}, {
    "City": "Metz",
    "DBTZ": "E",
    "Name": "Metz Nancy Lorraine",
    "Country": "France",
    "IATA/FAA": "ETZ",
    "Longitude": "6.251319",
    "ICAO": "LFJL",
    "Airport ID": "1320",
    "Latitude": "48.982142",
    "Timezone": "870",
    "DST": "1",
    "destinations": ["1335", "1354", "210", "1353", "1273"]
}, {
    "City": "Bastia",
    "DBTZ": "E",
    "Name": "Poretta",
    "Country": "France",
    "IATA/FAA": "BIA",
    "Longitude": "9.483731",
    "ICAO": "LFKB",
    "Airport ID": "1321",
    "Latitude": "42.552664",
    "Timezone": "26",
    "DST": "1",
    "destinations": ["344", "345", "342", "350", "351", "1399", "1350", "309", "1335", "1353", "1354", "1418", "1386", "629", "340", "302", "1382", "1665", "502", "1273", "1403", "1264", "1435"]
}, {
    "City": "Ajaccio",
    "DBTZ": "E",
    "Name": "Campo Dell Oro",
    "Country": "France",
    "IATA/FAA": "AJA",
    "Longitude": "8.802917",
    "ICAO": "LFKJ",
    "Airport ID": "1324",
    "Latitude": "41.923637",
    "Timezone": "18",
    "DST": "1",
    "destinations": ["1278", "1285", "1268", "309", "1335", "1353", "1354", "1418", "1386", "1273", "1361", "737", "644", "1399", "1520", "629", "4053", "1382", "1665", "502", "1403", "1264", "1412", "1359", "1435"]
}, {
    "City": "Porto Seguro",
    "DBTZ": "S",
    "Name": "Aeroporto de Porto Seguro",
    "Country": "Brazil",
    "IATA/FAA": "BPS",
    "Longitude": "-39.080833",
    "ICAO": "SBPS",
    "Airport ID": "4209",
    "Latitude": "-16.438611",
    "Timezone": "167",
    "DST": "-3",
    "destinations": ["2537", "2612", "2621", "2578", "2564", "2618"]
}, {
    "City": "San Luis Obispo",
    "DBTZ": "A",
    "Name": "San Luis County Regional Airport",
    "Country": "United States",
    "IATA/FAA": "SBP",
    "Longitude": "-120.642",
    "ICAO": "KSBP",
    "Airport ID": "5768",
    "Latitude": "35.2368",
    "Timezone": "212",
    "DST": "-8",
    "destinations": ["3462", "3484", "3469"]
}, {
    "City": "Provincetown",
    "DBTZ": "A",
    "Name": "Provincetown Muni",
    "Country": "United States",
    "IATA/FAA": "PVC",
    "Longitude": "-70.22139",
    "ICAO": "KPVC",
    "Airport ID": "6456",
    "Latitude": "42.071945",
    "Timezone": "9",
    "DST": "-5",
    "destinations": ["3448"]
}, {
    "City": "Rock Springs",
    "DBTZ": "A",
    "Name": "Rock Springs Sweetwater County Airport",
    "Country": "United States",
    "IATA/FAA": "RKS",
    "Longitude": "-109.065",
    "ICAO": "KRKS",
    "Airport ID": "5766",
    "Latitude": "41.5942",
    "Timezone": "6760",
    "DST": "-7",
    "destinations": ["4296", "3536", "3751"]
}, {
    "City": "Rutland",
    "DBTZ": "A",
    "Name": "Rutland State Airport",
    "Country": "United States",
    "IATA/FAA": "RUT",
    "Longitude": "-72.9496",
    "ICAO": "KRUT",
    "Airport ID": "5767",
    "Latitude": "43.5294",
    "Timezone": "787",
    "DST": "-5",
    "destinations": ["3448"]
}, {
    "City": "Butuan",
    "DBTZ": "N",
    "Name": "Butuan",
    "Country": "Philippines",
    "IATA/FAA": "BXU",
    "Longitude": "125.477972",
    "ICAO": "RPME",
    "Airport ID": "4200",
    "Latitude": "8.951322",
    "Timezone": "141",
    "DST": "8",
    "destinations": ["2397", "4206"]
}, {
    "City": "Rhinelander",
    "DBTZ": "A",
    "Name": "Rhinelander Oneida County Airport",
    "Country": "United States",
    "IATA/FAA": "RHI",
    "Longitude": "-89.4675",
    "ICAO": "KRHI",
    "Airport ID": "5765",
    "Latitude": "45.6312",
    "Timezone": "1624",
    "DST": "-6",
    "destinations": ["6837", "3858"]
}, {
    "City": "NAYPYITAW",
    "DBTZ": "N",
    "Name": "NAYPYITAW",
    "Country": "Burma",
    "IATA/FAA": "NYT",
    "Longitude": "96.121",
    "ICAO": "VYNT",
    "Airport ID": "8130",
    "Latitude": "19.374",
    "Timezone": "294",
    "DST": "6.5",
    "destinations": ["3382", "3885"]
}, {
    "City": "Portsmouth",
    "DBTZ": "A",
    "Name": "Pease International Tradeport",
    "Country": "United States",
    "IATA/FAA": "PSM",
    "Longitude": "-70.8233",
    "ICAO": "KPSM",
    "Airport ID": "5763",
    "Latitude": "43.0779",
    "Timezone": "100",
    "DST": "-5",
    "destinations": ["4167"]
}, {
    "City": "Pocatello",
    "DBTZ": "A",
    "Name": "Pocatello Regional Airport",
    "Country": "United States",
    "IATA/FAA": "PIH",
    "Longitude": "-112.596",
    "ICAO": "KPIH",
    "Airport ID": "5760",
    "Latitude": "42.9098",
    "Timezone": "4452",
    "DST": "-7",
    "destinations": ["3536"]
}, {
    "City": "Pierre",
    "DBTZ": "A",
    "Name": "Pierre Regional Airport",
    "Country": "United States",
    "IATA/FAA": "PIR",
    "Longitude": "-100.286",
    "ICAO": "KPIR",
    "Airport ID": "5761",
    "Latitude": "44.3827",
    "Timezone": "1742",
    "DST": "-6",
    "destinations": ["5721", "3751", "3491"]
}, {
    "City": "Kristiansund",
    "DBTZ": "E",
    "Name": "Kvernberget",
    "Country": "Norway",
    "IATA/FAA": "KSU",
    "Longitude": "7.824522",
    "ICAO": "ENKB",
    "Airport ID": "647",
    "Latitude": "63.111781",
    "Timezone": "204",
    "DST": "1",
    "destinations": ["532", "666", "644", "636", "651", "665"]
}, {
    "City": "Stronsay",
    "DBTZ": "E",
    "Name": "Stronsay Airport",
    "Country": "United Kingdom",
    "IATA/FAA": "SOY",
    "Longitude": "-2.64139",
    "ICAO": "EGER",
    "Airport ID": "5568",
    "Latitude": "59.1553",
    "Timezone": "39",
    "DST": "0",
    "destinations": ["529", "5569"]
}, {
    "City": "Sanday",
    "DBTZ": "E",
    "Name": "Sanday Airport",
    "Country": "United Kingdom",
    "IATA/FAA": "NDY",
    "Longitude": "-2.57667",
    "ICAO": "EGES",
    "Airport ID": "5569",
    "Latitude": "59.2503",
    "Timezone": "68",
    "DST": "0",
    "destinations": ["529", "5568"]
}, {
    "City": "Eday",
    "DBTZ": "E",
    "Name": "Eday Airport",
    "Country": "United Kingdom",
    "IATA/FAA": "EOI",
    "Longitude": "-2.77222",
    "ICAO": "EGED",
    "Airport ID": "5564",
    "Latitude": "59.1906",
    "Timezone": "10",
    "DST": "0",
    "destinations": ["529"]
}, {
    "City": "North Ronaldsay",
    "DBTZ": "E",
    "Name": "North Ronaldsay Airport",
    "Country": "United Kingdom",
    "IATA/FAA": "NRL",
    "Longitude": "-2.43444",
    "ICAO": "EGEN",
    "Airport ID": "5566",
    "Latitude": "59.3675",
    "Timezone": "40",
    "DST": "0",
    "destinations": ["5564", "529", "5569", "5567"]
}, {
    "City": "Papa Westray",
    "DBTZ": "E",
    "Name": "Papa Westray Airport",
    "Country": "United Kingdom",
    "IATA/FAA": "PPW",
    "Longitude": "-2.90028",
    "ICAO": "EGEP",
    "Airport ID": "5567",
    "Latitude": "59.3517",
    "Timezone": "91",
    "DST": "0",
    "destinations": ["529", "5566", "5571"]
}, {
    "City": "Doncaster",
    "DBTZ": "0",
    "Name": "Robin Hood Doncaster Sheffield Airport",
    "Country": " Sheffield",
    "IATA/FAA": "United Kingdom",
    "Longitude": "53.474722",
    "ICAO": "DSA",
    "Airport ID": "5562",
    "Latitude": "EGCN",
    "Timezone": "-1.004444",
    "DST": "55",
    "destinations": ["499", "3998", "668", "671", "674", "3959", "679", "680"]
}, {
    "City": "Campbeltown",
    "DBTZ": "E",
    "Name": "Campbeltown Airport",
    "Country": "United Kingdom",
    "IATA/FAA": "CAL",
    "Longitude": "-5.68639",
    "ICAO": "EGEC",
    "Airport ID": "5563",
    "Latitude": "55.4372",
    "Timezone": "42",
    "DST": "0",
    "destinations": ["534"]
}, {
    "City": "Milano",
    "DBTZ": "E",
    "Name": "Malpensa",
    "Country": "Italy",
    "IATA/FAA": "MXP",
    "Longitude": "8.728111",
    "ICAO": "LIMC",
    "Airport ID": "1524",
    "Latitude": "45.630606",
    "Timezone": "767",
    "DST": "1",
    "destinations": ["344", "345", "352", "342", "350", "1735", "3941", "1335", "1418", "3797", "507", "3576", "351", "1613", "1353", "210", "1509", "1190", "1074", "1075", "421", "2179", "1739", "2177", "1128", "1555", "2922", "2072", "2948", "1638", "629", "2279", "1636", "3953", "2082", "1194", "2985", "287", "2954", "2188", "469", "478", "490", "1852", "1909", "1759", "3364", "3406", "3077", "644", "599", "340", "302", "6152", "2559", "1218", "1216", "1229", "1084", "1051", "893", "4057", "1056", "1590", "4330", "2564", "8832", "3494", "346", "679", "1678", "1587", "1382", "2223", "2939", "2241", "609", "3316", "1273", "3885", "1701", "4317", "1230", "1517", "580", "1506", "1264", "1501", "1519", "535", "1452", "1225", "1468", "1197", "502", "492", "1561", "1520", "3998", "1512", "1508", "337", "4029", "2988", "1489", "1657", "2194"]
}, {
    "City": "Bergamo",
    "DBTZ": "E",
    "Name": "Bergamo Orio Al Serio",
    "Country": "Italy",
    "IATA/FAA": "BGY",
    "Longitude": "9.704166",
    "ICAO": "LIME",
    "Airport ID": "1525",
    "Latitude": "45.673889",
    "Timezone": "782",
    "DST": "1",
    "destinations": ["1074", "1190", "1055", "1230", "1517", "1212", "3941", "1218", "1506", "608", "353", "1501", "490", "1745", "1489", "1367", "1519", "1474", "304", "1509", "599", "585", "523", "1066", "1051", "1222", "691", "355", "1225", "1458", "669", "517", "364", "1276", "1054", "441", "1229", "478", "1606", "4198", "699", "1636", "3998", "1512", "1505", "1075", "1472", "3953", "657", "1243", "1251", "1486", "548", "1508", "1253", "337", "1056", "415", "458", "1515", "1246", "3959", "8414", "680", "1252", "1645", "4317", "1647", "1652", "1654", "668", "671", "1657", "1587", "1737", "1194", "1663", "679", "2944", "2945"]
}, {
    "City": "Torino",
    "DBTZ": "E",
    "Name": "Torino",
    "Country": "Italy",
    "IATA/FAA": "TRN",
    "Longitude": "7.649631",
    "ICAO": "LIMF",
    "Airport ID": "1526",
    "Latitude": "45.200761",
    "Timezone": "989",
    "DST": "1",
    "destinations": ["345", "1382", "1509", "1512", "1508", "1074", "1517", "1501", "1555", "1655", "1561", "1514", "1190", "502", "1678", "1218", "1506", "304", "1606", "548", "1515", "580", "1229", "1519", "1520", "340", "346", "302", "1701"]
}, {
    "City": "Olbia",
    "DBTZ": "E",
    "Name": "Olbia Costa Smeralda",
    "Country": "Italy",
    "IATA/FAA": "OLB",
    "Longitude": "9.517628",
    "ICAO": "LIEO",
    "Airport ID": "1520",
    "Latitude": "40.898661",
    "Timezone": "37",
    "DST": "1",
    "destinations": ["382", "344", "345", "342", "350", "346", "347", "351", "1613", "1678", "3953", "502", "737", "580", "1538", "1382", "1555", "1529", "1561", "1526", "1550", "340", "1665", "1676", "4053", "1524", "1354", "337", "1264", "1528", "1418", "1551"]
}, {
    "City": "Sevilla",
    "DBTZ": "E",
    "Name": "Sevilla",
    "Country": "Spain",
    "IATA/FAA": "SVQ",
    "Longitude": "-5.893106",
    "ICAO": "LEZL",
    "Airport ID": "1253",
    "Latitude": "37.418",
    "Timezone": "111",
    "DST": "1",
    "destinations": ["3998", "1273", "1055", "1218", "1525", "1538", "1264", "1501", "1367", "1553", "304", "599", "585", "502", "1054", "1353", "1512", "1562", "1075", "1243", "1251", "548", "1056", "580", "1213", "1216", "1220", "1335", "1229", "1418", "1386", "1214", "1057", "1246", "1638", "302", "4053", "1665"]
}, {
    "City": "Vigo",
    "DBTZ": "E",
    "Name": "Vigo",
    "Country": "Spain",
    "IATA/FAA": "VGO",
    "Longitude": "-8.626775",
    "ICAO": "LEVX",
    "Airport ID": "1250",
    "Latitude": "42.2318",
    "Timezone": "855",
    "DST": "1",
    "destinations": ["1382", "1229", "1218", "1216", "1056"]
}, {
    "City": "Santander",
    "DBTZ": "E",
    "Name": "Santander",
    "Country": "Spain",
    "IATA/FAA": "SDR",
    "Longitude": "-3.820006",
    "ICAO": "LEXJ",
    "Airport ID": "1251",
    "Latitude": "43.427064",
    "Timezone": "16",
    "DST": "1",
    "destinations": ["1055", "1230", "1218", "1525", "1553", "304", "599", "535", "355", "1054", "4198", "3998", "548", "1253", "1056", "1246", "1229"]
}, {
    "City": "Genoa",
    "DBTZ": "E",
    "Name": "Genova Sestri",
    "Country": "Italy",
    "IATA/FAA": "GOA",
    "Longitude": "8.8375",
    "ICAO": "LIMJ",
    "Airport ID": "1528",
    "Latitude": "44.413333",
    "Timezone": "13",
    "DST": "1",
    "destinations": ["1382", "1190", "1555", "502", "1501", "1519", "304", "548", "1515", "1218", "346", "4029", "1701", "1509", "1561", "1520", "1512"]
}, {
    "City": "Milan",
    "DBTZ": "E",
    "Name": "Linate",
    "Country": "Italy",
    "IATA/FAA": "LIN",
    "Longitude": "9.276739",
    "ICAO": "LIML",
    "Airport ID": "1529",
    "Latitude": "45.445103",
    "Timezone": "353",
    "DST": "1",
    "destinations": ["351", "507", "1382", "503", "1386", "580", "1501", "1509", "1561", "1512", "1517", "1218", "1506", "302", "1519", "9327", "1555", "340", "1657", "1587", "1505", "1514", "1508", "1545", "1613", "679", "599", "1229", "1606", "1520", "737", "609", "502"]
}, {
    "City": "Liege",
    "DBTZ": "E",
    "Name": "Liege",
    "Country": "Belgium",
    "IATA/FAA": "LGG",
    "Longitude": "5.443222",
    "ICAO": "EBLG",
    "Airport ID": "309",
    "Latitude": "50.637417",
    "Timezone": "659",
    "DST": "1",
    "destinations": ["1324", "1321", "1064", "1452", "1399", "3998", "1472"]
}, {
    "City": "Vilnius",
    "DBTZ": "E",
    "Name": "Vilnius Intl",
    "Country": "Lithuania",
    "IATA/FAA": "VNO",
    "Longitude": "25.285767",
    "ICAO": "EYVI",
    "Airport ID": "3959",
    "Latitude": "54.634133",
    "Timezone": "646",
    "DST": "2",
    "destinations": ["609", "340", "421", "2954", "302", "3953", "737", "644", "1218", "1525", "353", "1367", "1474", "1553", "304", "599", "491", "596", "657", "548", "679", "1382", "346", "1587", "415", "351", "1613", "2939", "2985", "1701", "4029", "2988", "1452", "630", "636", "5562", "373", "585", "1555", "3972", "492", "666", "1590", "664", "2944", "2948"]
}, {
    "City": "Palanga",
    "DBTZ": "E",
    "Name": "Palanga Intl",
    "Country": "Lithuania",
    "IATA/FAA": "PLQ",
    "Longitude": "21.093856",
    "ICAO": "EYPA",
    "Airport ID": "3958",
    "Latitude": "55.973228",
    "Timezone": "33",
    "DST": "2",
    "destinations": ["3953", "644", "609"]
}, {
    "City": "Huai An",
    "DBTZ": "N",
    "Name": "Huai An Lianshui Airport",
    "Country": "China",
    "IATA/FAA": "HIA",
    "Longitude": "119.1478",
    "ICAO": "ZSSH",
    "Airport ID": "7898",
    "Latitude": "33.7772",
    "Timezone": "23",
    "DST": "8",
    "destinations": ["3400", "3374", "3393", "3404", "3385", "3368", "6392", "3379", "3370", "3077", "3364", "3391"]
}, {
    "City": "Riga",
    "DBTZ": "E",
    "Name": "Riga Intl",
    "Country": "Latvia",
    "IATA/FAA": "RIX",
    "Longitude": "23.971111",
    "ICAO": "EVRA",
    "Airport ID": "3953",
    "Latitude": "56.923611",
    "Timezone": "34",
    "DST": "2",
    "destinations": ["1056", "3973", "345", "340", "342", "346", "351", "1382", "421", "1555", "1524", "1520", "1551", "2954", "532", "630", "580", "737", "3941", "1218", "636", "608", "302", "1489", "609", "4029", "687", "2922", "2939", "2952", "1735", "1197", "2948", "502", "1606", "1354", "644", "3958", "1587", "4317", "666", "2985", "2983", "460", "415", "1590", "1613", "2988", "3959", "679", "1678", "665", "1525", "353", "599", "523", "355", "478", "4198", "537", "657", "548", "3797", "1701", "1452", "585", "492", "664"]
}, {
    "City": "Brussels",
    "DBTZ": "E",
    "Name": "Brussels Natl",
    "Country": "Belgium",
    "IATA/FAA": "BRU",
    "Longitude": "4.484444",
    "ICAO": "EBBR",
    "Airport ID": "302",
    "Latitude": "50.901389",
    "Timezone": "184",
    "DST": "1",
    "destinations": ["1074", "5673", "1080", "350", "2997", "3093", "3494", "193", "3941", "1452", "1335", "1418", "507", "3752", "1120", "245", "1084", "897", "1165", "951", "246", "146", "3682", "3797", "210", "1229", "1064", "1070", "1075", "421", "1638", "1555", "1529", "608", "523", "521", "2179", "3953", "3959", "599", "596", "1382", "340", "1194", "16", "1230", "1212", "1218", "1636", "3998", "1539", "1246", "1055", "1688", "1191", "1460", "1474", "1200", "4091", "1225", "1468", "1458", "1276", "1213", "1054", "1231", "7447", "1198", "1236", "1472", "1056", "1743", "1196", "1488", "1216", "609", "1665", "1524", "3364", "351", "1243", "1551", "1569", "1739", "668", "680", "580", "1509", "1606", "253", "1059", "352", "342", "3714", "346", "4161", "679", "4053", "1678", "1590", "2177", "1128", "1587", "1613", "1208", "415", "1706", "4315", "2939", "2241", "1657", "737", "644", "469", "1321", "3885", "1538", "738", "490", "1489", "4029", "535", "1626", "1020", "1563", "687", "478", "1353", "1561", "1354", "3830", "1512", "1253", "1435", "1273", "1526", "2985", "6780", "1701", "4317", "293", "286", "287", "1264", "502", "337"]
}, {
    "City": "Daytona Beach",
    "DBTZ": "A",
    "Name": "Daytona Beach Intl",
    "Country": "United States",
    "IATA/FAA": "DAB",
    "Longitude": "-81.058056",
    "ICAO": "KDAB",
    "Airport ID": "3950",
    "Latitude": "29.179917",
    "Timezone": "34",
    "DST": "-5",
    "destinations": ["3876", "3682"]
}, {
    "City": "Charleroi",
    "DBTZ": "E",
    "Name": "Brussels South",
    "Country": "Belgium",
    "IATA/FAA": "CRL",
    "Longitude": "4.453817",
    "ICAO": "EBCI",
    "Airport ID": "304",
    "Latitude": "50.459197",
    "Timezone": "614",
    "DST": "1",
    "destinations": ["1055", "1064", "1230", "1517", "1212", "4309", "1218", "1506", "1525", "1280", "1538", "1264", "1501", "1745", "1489", "1519", "1352", "1460", "1474", "1553", "9327", "599", "535", "1265", "1626", "1066", "1439", "1323", "1051", "1528", "1222", "1225", "669", "1213", "1054", "6493", "1229", "478", "1359", "1353", "5673", "699", "1636", "1070", "1567", "1198", "1356", "3998", "1562", "1505", "1203", "1075", "1072", "1289", "1236", "1472", "657", "1251", "1486", "1508", "1253", "1056", "1741", "1080", "1515", "1526", "1545", "1539", "1246", "3959", "1448", "8414", "1209", "1252", "1488", "4317", "1739", "1652", "1569", "1657", "1737", "1194", "679"]
}, {
    "City": "Kaunas",
    "DBTZ": "E",
    "Name": "Kaunas Intl",
    "Country": "Lithuania",
    "IATA/FAA": "KUN",
    "Longitude": "24.084778",
    "ICAO": "EYKA",
    "Airport ID": "3956",
    "Latitude": "54.963919",
    "Timezone": "256",
    "DST": "2",
    "destinations": ["1212", "469", "490", "1519", "9327", "599", "535", "355", "1458", "502", "492", "1606", "600", "1198", "3998", "1472", "657", "548", "1515"]
}, {
    "City": "BRISTOL",
    "DBTZ": "A",
    "Name": "Tri-Cities Regional Airport",
    "Country": "United States",
    "IATA/FAA": "TRI",
    "Longitude": "-82.4074167",
    "ICAO": "KTRI",
    "Airport ID": "4116",
    "Latitude": "36.4752222",
    "Timezone": "1519",
    "DST": "-5",
    "destinations": ["3876", "3682", "3617", "4167"]
}, {
    "City": "PADUCAH",
    "DBTZ": "A",
    "Name": "Barkley Regional Airport",
    "Country": "United States",
    "IATA/FAA": "PAH",
    "Longitude": "-88.7729583",
    "ICAO": "KPAH",
    "Airport ID": "4117",
    "Latitude": "37.0602875",
    "Timezone": "410",
    "DST": "-6",
    "destinations": ["3830"]
}, {
    "City": "PARKERSBURG",
    "DBTZ": "A",
    "Name": "Mid-Ohio Valley Regional Airport",
    "Country": "United States",
    "IATA/FAA": "PKB",
    "Longitude": "-81.4392031",
    "ICAO": "KPKB",
    "Airport ID": "4114",
    "Latitude": "39.3451039",
    "Timezone": "858",
    "DST": "-5",
    "destinations": ["3486"]
}, {
    "City": "MONTGOMERY",
    "DBTZ": "A",
    "Name": "Montgomery Regional Airport ",
    "Country": "United States",
    "IATA/FAA": "MGM",
    "Longitude": "-86.3939722",
    "ICAO": "KMGM",
    "Airport ID": "4115",
    "Latitude": "32.3006389",
    "Timezone": "221",
    "DST": "-6",
    "destinations": ["3876", "3670", "3682"]
}, {
    "City": "Akron",
    "DBTZ": "A",
    "Name": "Akron Canton Regional Airport",
    "Country": "United States",
    "IATA/FAA": "CAK",
    "Longitude": "-81.4421944",
    "ICAO": "KCAK",
    "Airport ID": "4112",
    "Latitude": "40.9160833",
    "Timezone": "1228",
    "DST": "-5",
    "destinations": ["3876", "3520", "3752", "3682", "3645", "3448", "3697", "3878", "3646", "3830", "3751"]
}, {
    "City": "Huntsville",
    "DBTZ": "A",
    "Name": "Huntsville International Airport-Carl T Jones Field",
    "Country": "United States",
    "IATA/FAA": "HSV",
    "Longitude": "-86.7750556",
    "ICAO": "KHSV",
    "Airport ID": "4113",
    "Latitude": "34.6371944",
    "Timezone": "629",
    "DST": "-6",
    "destinations": ["3876", "3520", "3670", "3830", "3682", "3645", "3751", "3714", "3550"]
}, {
    "City": "Yibin",
    "DBTZ": "U",
    "Name": "Yibin",
    "Country": "China",
    "IATA/FAA": "YBP",
    "Longitude": "104.544444",
    "ICAO": "ZUYB",
    "Airport ID": "4110",
    "Latitude": "28.800278",
    "Timezone": "1000",
    "DST": "8",
    "destinations": ["4030", "3379", "3370", "3364", "3374", "3382", "3406"]
}, {
    "City": "Tyumen",
    "DBTZ": "N",
    "Name": "Roschino",
    "Country": "Russia",
    "IATA/FAA": "TJM",
    "Longitude": "65.3243",
    "ICAO": "USTR",
    "Airport ID": "4111",
    "Latitude": "57.189567",
    "Timezone": "378",
    "DST": "6",
    "destinations": ["4364", "2983", "4029", "2948", "2985", "6147", "6138", "2972", "6139", "6140", "2974", "2992", "4377", "3964", "4365", "6143", "4078", "6137"]
}, {
    "City": "Keewaywin",
    "DBTZ": "A",
    "Name": "Keewaywin",
    "Country": "Canada",
    "IATA/FAA": "KEW",
    "Longitude": "-92.836389",
    "ICAO": "CPV8",
    "Airport ID": "8355",
    "Latitude": "52.991111",
    "Timezone": "988",
    "DST": "-6",
    "destinations": ["5527"]
}, {
    "City": "Samara",
    "DBTZ": "N",
    "Name": "Kurumoch",
    "Country": "Russia",
    "IATA/FAA": "KUF",
    "Longitude": "50.15",
    "ICAO": "",
    "Airport ID": "4118",
    "Latitude": "53.5",
    "Timezone": "0",
    "DST": "4",
    "destinations": ["6156", "2975", "2954", "8076", "2983", "2908", "340", "1587", "3964", "4029", "2948", "2985", "2188", "2979", "6147", "2913", "2988", "4274", "4352", "2964"]
}, {
    "City": "Jiayuguan",
    "DBTZ": "N",
    "Name": "Jiayuguan Airport",
    "Country": "China",
    "IATA/FAA": "JGN",
    "Longitude": "98.3414",
    "ICAO": "ZLJQ",
    "Airport ID": "6432",
    "Latitude": "39.8569",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["6431", "4085", "3379"]
}, {
    "City": "Canberra",
    "DBTZ": "O",
    "Name": "Canberra",
    "Country": "Australia",
    "IATA/FAA": "CBR",
    "Longitude": "149.195",
    "ICAO": "YSCB",
    "Airport ID": "3355",
    "Latitude": "-35.306944",
    "Timezone": "1886",
    "DST": "10",
    "destinations": ["3320", "3339", "3361", "3341", "3351", "3321"]
}, {
    "City": "Playon Chico",
    "DBTZ": "U",
    "Name": "Playon Chico",
    "Country": "Panama",
    "IATA/FAA": "PYC",
    "Longitude": "-78.236111",
    "ICAO": "",
    "Airport ID": "7895",
    "Latitude": "9.303333",
    "Timezone": "10",
    "DST": "-5",
    "destinations": ["8204"]
}, {
    "City": "Pond Inlet",
    "DBTZ": "A",
    "Name": "Pond Inlet",
    "Country": "Canada",
    "IATA/FAA": "YIO",
    "Longitude": "-77.966667",
    "ICAO": "CYIO",
    "Airport ID": "75",
    "Latitude": "72.683334",
    "Timezone": "181",
    "DST": "-5",
    "destinations": ["40", "5495"]
}, {
    "City": "Dushanbe",
    "DBTZ": "U",
    "Name": "Dushanbe",
    "Country": "Tajikistan",
    "IATA/FAA": "DYU",
    "Longitude": "68.825",
    "ICAO": "UTDD",
    "Airport ID": "2979",
    "Latitude": "38.543333",
    "Timezone": "2575",
    "DST": "5",
    "destinations": ["2965", "2908", "3093", "4029", "2912", "4330", "1701", "2948", "4078", "2974", "2191", "2975", "3399", "4331", "2188", "2958", "2937", "2960", "2968", "340", "4374", "2990", "2991", "4274", "4118", "2962", "6969", "2964", "2992", "2967"]
}, {
    "City": "Hay River",
    "DBTZ": "A",
    "Name": "Hay River",
    "Country": "Canada",
    "IATA/FAA": "YHY",
    "Longitude": "-115.782778",
    "ICAO": "CYHY",
    "Airport ID": "72",
    "Latitude": "60.839722",
    "Timezone": "543",
    "DST": "-7",
    "destinations": ["196", "49", "136"]
}, {
    "City": "Hamilton",
    "DBTZ": "A",
    "Name": "Hamilton",
    "Country": "Canada",
    "IATA/FAA": "YHM",
    "Longitude": "-79.935",
    "ICAO": "CYHM",
    "Airport ID": "70",
    "Latitude": "43.173611",
    "Timezone": "780",
    "DST": "-5",
    "destinations": ["49", "73", "117", "178"]
}, {
    "City": "Nizhnevartovsk",
    "DBTZ": "N",
    "Name": "Nizhnevartovsk",
    "Country": "Russia",
    "IATA/FAA": "NJC",
    "Longitude": "76.483617",
    "ICAO": "USNN",
    "Airport ID": "2972",
    "Latitude": "60.949272",
    "Timezone": "177",
    "DST": "6",
    "destinations": ["2975", "6160", "2958", "4078", "4029", "2985", "6147", "6138", "4111"]
}, {
    "City": "Perm",
    "DBTZ": "N",
    "Name": "Bolshoye Savino",
    "Country": "Russia",
    "IATA/FAA": "PEE",
    "Longitude": "56.021214",
    "ICAO": "USPP",
    "Airport ID": "2973",
    "Latitude": "57.914517",
    "Timezone": "404",
    "DST": "6",
    "destinations": ["1587", "4029", "2948", "2985", "8076"]
}, {
    "City": "Armidale",
    "DBTZ": "O",
    "Name": "Armidale",
    "Country": "Australia",
    "IATA/FAA": "ARM",
    "Longitude": "151.617222",
    "ICAO": "YARM",
    "Airport ID": "4062",
    "Latitude": "-30.528056",
    "Timezone": "3556",
    "DST": "10",
    "destinations": ["3361"]
}, {
    "City": "Grand Junction",
    "DBTZ": "A",
    "Name": "Grand Junction Regional",
    "Country": "United States",
    "IATA/FAA": "GJT",
    "Longitude": "-108.526735",
    "ICAO": "KGJT",
    "Airport ID": "4063",
    "Latitude": "39.122413",
    "Timezone": "4858",
    "DST": "-7",
    "destinations": ["3670", "3462", "3536", "3877", "3751", "3550"]
}, {
    "City": "El Calafate",
    "DBTZ": "N",
    "Name": "El Calafate",
    "Country": "Argentina",
    "IATA/FAA": "FTE",
    "Longitude": "-72.053103",
    "ICAO": "SAWC",
    "Airport ID": "4061",
    "Latitude": "-50.280322",
    "Timezone": "669",
    "DST": "-3",
    "destinations": ["2442", "3988", "2497"]
}, {
    "City": "Dera Ghazi Khan",
    "DBTZ": "N",
    "Name": "Dera Ghazi Khan Airport",
    "Country": "Pakistan",
    "IATA/FAA": "DEA",
    "Longitude": "70.485925",
    "ICAO": "OPDG",
    "Airport ID": "5942",
    "Latitude": "29.961011",
    "Timezone": "492",
    "DST": "5",
    "destinations": ["2206", "2191"]
}, {
    "City": "Sarasota",
    "DBTZ": "A",
    "Name": "Sarasota Bradenton Intl",
    "Country": "United States",
    "IATA/FAA": "SRQ",
    "Longitude": "-82.554389",
    "ICAO": "KSRQ",
    "Airport ID": "4067",
    "Latitude": "27.395444",
    "Timezone": "30",
    "DST": "-5",
    "destinations": ["3876", "3520", "3682", "3797", "3697", "3830"]
}, {
    "City": "Saint George",
    "DBTZ": "A",
    "Name": "St George Muni",
    "Country": "United States",
    "IATA/FAA": "SGU",
    "Longitude": "-113.593056",
    "ICAO": "KSGU",
    "Airport ID": "4064",
    "Latitude": "37.090583",
    "Timezone": "2941",
    "DST": "-7",
    "destinations": ["3536", "3751"]
}, {
    "City": "Dalbandin",
    "DBTZ": "N",
    "Name": "Dalbandin Airport",
    "Country": "Pakistan",
    "IATA/FAA": "DBA",
    "Longitude": "64.3998",
    "ICAO": "OPDB",
    "Airport ID": "5941",
    "Latitude": "28.8783",
    "Timezone": "2800",
    "DST": "5",
    "destinations": ["2206"]
}, {
    "City": "Bermuda",
    "DBTZ": "A",
    "Name": "Bermuda Intl",
    "Country": "Bermuda",
    "IATA/FAA": "BDA",
    "Longitude": "-64.678703",
    "ICAO": "TXKF",
    "Airport ID": "4069",
    "Latitude": "32.364042",
    "Timezone": "12",
    "DST": "-4",
    "destinations": ["3797", "3576", "3752", "193", "502", "3682", "3448", "3494"]
}, {
    "City": "Skardu",
    "DBTZ": "N",
    "Name": "Skardu Airport",
    "Country": "Pakistan",
    "IATA/FAA": "KDU",
    "Longitude": "75.536047",
    "ICAO": "OPSD",
    "Airport ID": "5949",
    "Latitude": "35.335508",
    "Timezone": "7316",
    "DST": "5",
    "destinations": ["2223"]
}, {
    "City": "Syros Island",
    "DBTZ": "E",
    "Name": "Syros Airport",
    "Country": "Greece",
    "IATA/FAA": "JSY",
    "Longitude": "24.950936",
    "ICAO": "LGSO",
    "Airport ID": "5787",
    "Latitude": "37.422792",
    "Timezone": "236",
    "DST": "2",
    "destinations": ["3941"]
}, {
    "City": "Nicosia",
    "DBTZ": "E",
    "Name": "Ercan International Airport",
    "Country": "Cyprus",
    "IATA/FAA": "ECN",
    "Longitude": "33.4961",
    "ICAO": "LCEN",
    "Airport ID": "5780",
    "Latitude": "35.1547",
    "Timezone": "404",
    "DST": "2",
    "destinations": ["1701", "1685", "1706", "1688", "1682", "1689", "7453", "4317"]
}, {
    "City": "Logrono-Agoncillo",
    "DBTZ": "E",
    "Name": "Logrono-Agoncillo Airport",
    "Country": "Spain",
    "IATA/FAA": "RJL",
    "Longitude": "-2.32083",
    "ICAO": "LELO",
    "Airport ID": "5781",
    "Latitude": "42.4542",
    "Timezone": "1161",
    "DST": "1",
    "destinations": ["1229"]
}, {
    "City": "Yekaterinburg",
    "DBTZ": "N",
    "Name": "Koltsovo",
    "Country": "Russia",
    "IATA/FAA": "SVX",
    "Longitude": "60.802728",
    "ICAO": "USSS",
    "Airport ID": "2975",
    "Latitude": "56.743108",
    "Timezone": "764",
    "DST": "6",
    "destinations": ["2990", "6969", "2972", "4364", "4078", "6140", "4363", "2979", "6146", "4118", "2969", "2989", "2992", "7003", "4377", "421", "2954", "7563", "8076", "2191", "6150", "2983", "6141", "2922", "2910", "2912", "2913", "1587", "2939", "3964", "4029", "3364", "2965", "2960", "2948", "2962", "2964", "2985", "6147", "1701", "1382", "2188", "1555", "3400", "4374", "1579", "1197", "346", "4352", "1590", "3885", "3179", "1130", "4057", "2988", "8428", "4274", "6138", "2974", "4373", "4365", "6139", "6143", "6137"]
}, {
    "City": "Lihue",
    "DBTZ": "N",
    "Name": "Lihue",
    "Country": "United States",
    "IATA/FAA": "LIH",
    "Longitude": "-159.338958",
    "ICAO": "PHLI",
    "Airport ID": "3602",
    "Latitude": "21.975983",
    "Timezone": "153",
    "DST": "-10",
    "destinations": ["3484", "3453", "3462", "3748", "3731", "3577", "3728", "3456", "3751", "3469", "156"]
}, {
    "City": "Havre",
    "DBTZ": "A",
    "Name": "Havre City Co",
    "Country": "United States",
    "IATA/FAA": "HVR",
    "Longitude": "-109.762342",
    "ICAO": "KHVR",
    "Airport ID": "3604",
    "Latitude": "48.542983",
    "Timezone": "2590",
    "DST": "-7",
    "destinations": ["4021"]
}, {
    "City": "Shreveport",
    "DBTZ": "A",
    "Name": "Shreveport Rgnl",
    "Country": "United States",
    "IATA/FAA": "SHV",
    "Longitude": "-93.8256",
    "ICAO": "KSHV",
    "Airport ID": "3609",
    "Latitude": "32.446629",
    "Timezone": "258",
    "DST": "-6",
    "destinations": ["3670", "3682", "3877", "3751", "3550"]
}, {
    "City": "Richmond",
    "DBTZ": "A",
    "Name": "Richmond Intl",
    "Country": "United States",
    "IATA/FAA": "RIC",
    "Longitude": "-77.319667",
    "ICAO": "KRIC",
    "Airport ID": "3608",
    "Latitude": "37.505167",
    "Timezone": "167",
    "DST": "-5",
    "destinations": ["3448", "3876", "3670", "3697", "3576", "3830", "3752", "3682", "3533", "3878", "3488", "3645", "3797", "3858", "3486", "3494", "3714", "3550", "1948"]
}, {
    "City": "Kyzyl",
    "DBTZ": "N",
    "Name": "Kyzyl Airport",
    "Country": "Russia",
    "IATA/FAA": "KYZ",
    "Longitude": "94.4006",
    "ICAO": "UNKY",
    "Airport ID": "6118",
    "Latitude": "51.6694",
    "Timezone": "2123",
    "DST": "8",
    "destinations": ["2937"]
}, {
    "City": "Novokuznetsk",
    "DBTZ": "N",
    "Name": "Spichenkovo Airport",
    "Country": "Russia",
    "IATA/FAA": "NOZ",
    "Longitude": "86.8772",
    "ICAO": "UNWW",
    "Airport ID": "6119",
    "Latitude": "53.8114",
    "Timezone": "1024",
    "DST": "7",
    "destinations": ["4029", "2985"]
}, {
    "City": "Budapest",
    "DBTZ": "E",
    "Name": "Ferihegy",
    "Country": "Hungary",
    "IATA/FAA": "BUD",
    "Longitude": "19.255592",
    "ICAO": "LHBP",
    "Airport ID": "1489",
    "Latitude": "47.436933",
    "Timezone": "495",
    "DST": "1",
    "destinations": ["344", "342", "350", "3941", "507", "351", "1382", "421", "1555", "1739", "2954", "3953", "737", "609", "502", "644", "599", "340", "1218", "1525", "608", "490", "1367", "1553", "304", "1229", "478", "1562", "548", "458", "1539", "591", "580", "1606", "345", "346", "679", "535", "523", "517", "1678", "1590", "1128", "1587", "1613", "2241", "1208", "1657", "302", "2985", "1701", "1386", "1638", "4053", "1665", "492", "337", "2960", "2964", "1230", "1501", "1509", "373", "8076", "585", "691", "2922", "355", "2944", "1197", "708", "1524", "1561", "699", "4317", "1486", "2988"]
}, {
    "City": "Petrozavodsk",
    "DBTZ": "N",
    "Name": "Petrozavodsk Airport",
    "Country": "Russia",
    "IATA/FAA": "PES",
    "Longitude": "34.1547",
    "ICAO": "ULPB",
    "Airport ID": "6114",
    "Latitude": "61.8852",
    "Timezone": "151",
    "DST": "4",
    "destinations": ["4029"]
}, {
    "City": "Kazan",
    "DBTZ": "N",
    "Name": "Kazan",
    "Country": "Russia",
    "IATA/FAA": "KZN",
    "Longitude": "49.278728",
    "ICAO": "UWKD",
    "Airport ID": "2990",
    "Latitude": "55.606186",
    "Timezone": "411",
    "DST": "4",
    "destinations": ["2965", "2966", "2968", "4029", "6156", "2948", "2975", "1688", "8076", "2191", "6149", "2981", "2983", "2908", "2985", "2979", "6147", "1701", "2992", "2912", "2913"]
}, {
    "City": "Solovetsky Islands",
    "DBTZ": "N",
    "Name": "Solovki Airport",
    "Country": "Russia",
    "IATA/FAA": "CSH",
    "Longitude": "35.7333",
    "ICAO": "ULAS",
    "Airport ID": "6110",
    "Latitude": "65.03",
    "Timezone": "0",
    "DST": "4",
    "destinations": ["4362"]
}, {
    "City": "Cherepovets",
    "DBTZ": "N",
    "Name": "Cherepovets Airport",
    "Country": "Russia",
    "IATA/FAA": "CEE",
    "Longitude": "38.0158",
    "ICAO": "ULBC",
    "Airport ID": "6111",
    "Latitude": "59.2736",
    "Timezone": "377",
    "DST": "4",
    "destinations": ["4029", "421", "2952", "2948", "2949", "2988", "8924"]
}, {
    "City": "Maniitsoq",
    "DBTZ": "E",
    "Name": "Maniitsoq Airport",
    "Country": "Greenland",
    "IATA/FAA": "JSU",
    "Longitude": "-52.9394",
    "ICAO": "BGMQ",
    "Airport ID": "5443",
    "Latitude": "65.4125",
    "Timezone": "91",
    "DST": "-3",
    "destinations": ["8", "9"]
}, {
    "City": "Tokyo",
    "DBTZ": "U",
    "Name": "Tokyo Intl",
    "Country": "Japan",
    "IATA/FAA": "HND",
    "Longitude": "139.779694",
    "ICAO": "RJTT",
    "Airport ID": "2359",
    "Latitude": "35.552258",
    "Timezone": "35",
    "DST": "9",
    "destinations": ["2305", "3992", "2311", "3728", "3469", "156", "1382", "507", "2287", "2307", "2384", "3943", "2330", "2275", "3364", "3391", "3077", "3304", "3484", "3577", "2188", "3275", "3940", "2299", "2288", "5994", "2290", "2286", "2340", "2316", "2345", "3885", "2341", "2378", "2326", "2334", "2328", "2332", "2308", "2312", "2322", "2346", "2333", "3942", "2313", "2310", "2327", "3205", "2284", "3316", "2337", "2336", "2296", "3930", "340", "346", "2352", "3199", "5996", "2387", "5998", "2298", "2397", "3409", "2356", "6000", "2291", "6001", "2324", "2335", "2294", "2390"]
}, {
    "City": "Qaqortoq",
    "DBTZ": "E",
    "Name": "Qaqortoq Heliport",
    "Country": "Greenland",
    "IATA/FAA": "JJU",
    "Longitude": "-46.0294",
    "ICAO": "BGJH",
    "Airport ID": "5442",
    "Latitude": "60.7158",
    "Timezone": "0",
    "DST": "-3",
    "destinations": ["5444", "5445", "5438", "7"]
}, {
    "City": "Hachijojima",
    "DBTZ": "U",
    "Name": "Hachijojima",
    "Country": "Japan",
    "IATA/FAA": "HAC",
    "Longitude": "139.785833",
    "ICAO": "RJTH",
    "Airport ID": "2352",
    "Latitude": "33.115",
    "Timezone": "303",
    "DST": "9",
    "destinations": ["2359"]
}, {
    "City": "Oshima",
    "DBTZ": "U",
    "Name": "Oshima",
    "Country": "Japan",
    "IATA/FAA": "OIM",
    "Longitude": "139.360306",
    "ICAO": "RJTO",
    "Airport ID": "2356",
    "Latitude": "34.782033",
    "Timezone": "130",
    "DST": "9",
    "destinations": ["2359"]
}, {
    "City": "Shiquanhe",
    "DBTZ": "N",
    "Name": "Gunsa",
    "Country": "China",
    "IATA/FAA": "NGQ",
    "Longitude": "80.052778",
    "ICAO": "ZUAL",
    "Airport ID": "7932",
    "Latitude": "32.10027",
    "Timezone": "13780",
    "DST": "8",
    "destinations": ["3397", "4097"]
}, {
    "City": "Dangriga",
    "DBTZ": "U",
    "Name": "Dangriga Airport",
    "Country": "Belize",
    "IATA/FAA": "DGA",
    "Longitude": "-88.216667",
    "ICAO": "",
    "Airport ID": "7287",
    "Latitude": "16.966667",
    "Timezone": "10",
    "DST": "-6",
    "destinations": ["1957", "7288", "6463"]
}, {
    "City": "Orenburg",
    "DBTZ": "N",
    "Name": "Orenburg",
    "Country": "Russia",
    "IATA/FAA": "REN",
    "Longitude": "55.456744",
    "ICAO": "UWOO",
    "Airport ID": "2991",
    "Latitude": "51.795786",
    "Timezone": "387",
    "DST": "6",
    "destinations": ["4029", "2979", "6147", "2948", "2985"]
}, {
    "City": "Yichun",
    "DBTZ": "U",
    "Name": "Yichun Mingyueshan Airport",
    "Country": "China",
    "IATA/FAA": "YIC",
    "Longitude": "114.3081",
    "ICAO": "ZSYC",
    "Airport ID": "9107",
    "Latitude": "27.8033",
    "Timezone": "131",
    "DST": "8",
    "destinations": ["3382", "3391", "3364", "3374"]
}, {
    "City": "Placencia",
    "DBTZ": "U",
    "Name": "Placencia Airport",
    "Country": "Belize",
    "IATA/FAA": "PLJ",
    "Longitude": "-88.361667",
    "ICAO": "",
    "Airport ID": "7288",
    "Latitude": "16.536944",
    "Timezone": "42",
    "DST": "-6",
    "destinations": ["1957", "7287", "5455"]
}, {
    "City": "Qaanaaq",
    "DBTZ": "E",
    "Name": "Qaanaaq Airport",
    "Country": "Greenland",
    "IATA/FAA": "NAQ",
    "Longitude": "-69.3887",
    "ICAO": "BGQQ",
    "Airport ID": "5446",
    "Latitude": "77.4886",
    "Timezone": "51",
    "DST": "-4",
    "destinations": ["5448", "10"]
}, {
    "City": "Massawa",
    "DBTZ": "U",
    "Name": "Massawa Intl",
    "Country": "Eritrea",
    "IATA/FAA": "MSW",
    "Longitude": "39.370103",
    "ICAO": "HHMS",
    "Airport ID": "3968",
    "Latitude": "15.669989",
    "Timezone": "194",
    "DST": "3",
    "destinations": ["3967"]
}, {
    "City": "Dothan",
    "DBTZ": "A",
    "Name": "Dothan Rgnl",
    "Country": "United States",
    "IATA/FAA": "DHN",
    "Longitude": "-85.449628",
    "ICAO": "KDHN",
    "Airport ID": "3739",
    "Latitude": "31.321339",
    "Timezone": "401",
    "DST": "-6",
    "destinations": ["3682"]
}, {
    "City": "Brownsville",
    "DBTZ": "A",
    "Name": "Brownsville South Padre Island Intl",
    "Country": "United States",
    "IATA/FAA": "BRO",
    "Longitude": "-97.425861",
    "ICAO": "KBRO",
    "Airport ID": "3738",
    "Latitude": "25.906833",
    "Timezone": "22",
    "DST": "-6",
    "destinations": ["3670", "3550"]
}, {
    "City": "Roswell",
    "DBTZ": "A",
    "Name": "Roswell Intl Air Center",
    "Country": "United States",
    "IATA/FAA": "ROW",
    "Longitude": "-104.530556",
    "ICAO": "KROW",
    "Airport ID": "3736",
    "Latitude": "33.301556",
    "Timezone": "3671",
    "DST": "-7",
    "destinations": ["3670"]
}, {
    "City": "Ontario",
    "DBTZ": "A",
    "Name": "Ontario Intl",
    "Country": "United States",
    "IATA/FAA": "ONT",
    "Longitude": "-117.601194",
    "ICAO": "KONT",
    "Airport ID": "3734",
    "Latitude": "34.056",
    "Timezone": "944",
    "DST": "-8",
    "destinations": ["3670", "3720", "3462", "3577", "1804", "3536", "3751", "3877", "3550", "3469", "3747", "3453", "3748", "3817"]
}, {
    "City": "Monroe",
    "DBTZ": "A",
    "Name": "Monroe Rgnl",
    "Country": "United States",
    "IATA/FAA": "MLU",
    "Longitude": "-92.037689",
    "ICAO": "KMLU",
    "Airport ID": "3732",
    "Latitude": "32.510864",
    "Timezone": "79",
    "DST": "-6",
    "destinations": ["3670", "3682", "3550"]
}, {
    "City": "San Diego",
    "DBTZ": "A",
    "Name": "San Diego Intl",
    "Country": "United States",
    "IATA/FAA": "SAN",
    "Longitude": "-117.189667",
    "ICAO": "KSAN",
    "Airport ID": "3731",
    "Latitude": "32.733556",
    "Timezone": "17",
    "DST": "-8",
    "destinations": ["3448", "3876", "3520", "3670", "3797", "3484", "507", "3878", "3576", "2279", "3830", "3720", "3752", "3462", "1836", "3577", "6992", "178", "193", "3682", "3495", "3645", "3687", "3728", "3602", "3948", "3858", "3456", "1840", "3536", "3751", "3849", "3566", "3877", "3458", "3807", "3621", "4014", "3748", "3777", "3689", "3494", "3714", "3550", "3469", "4019", "3673", "3690", "3747", "3861", "3453", "3817", "3678", "3636", "1824"]
}, {
    "City": "New Bern",
    "DBTZ": "A",
    "Name": "Craven Co Rgnl",
    "Country": "United States",
    "IATA/FAA": "EWN",
    "Longitude": "-77.042944",
    "ICAO": "KEWN",
    "Airport ID": "3730",
    "Latitude": "35.072972",
    "Timezone": "18",
    "DST": "-5",
    "destinations": ["3876", "3682"]
}, {
    "City": "Lubango",
    "DBTZ": "N",
    "Name": "Lubango",
    "Country": "Angola",
    "IATA/FAA": "SDD",
    "Longitude": "13.575022",
    "ICAO": "FNUB",
    "Airport ID": "959",
    "Latitude": "-14.924733",
    "Timezone": "5778",
    "DST": "1",
    "destinations": ["951", "5632", "4105"]
}, {
    "City": "Soyo",
    "DBTZ": "N",
    "Name": "Soyo",
    "Country": "Angola",
    "IATA/FAA": "SZA",
    "Longitude": "12.371764",
    "ICAO": "FNSO",
    "Airport ID": "958",
    "Latitude": "-6.141086",
    "Timezone": "15",
    "DST": "1",
    "destinations": ["946", "951"]
}, {
    "City": "Bandar Abbas",
    "DBTZ": "E",
    "Name": "Bandar Abbass Intl",
    "Country": "Iran",
    "IATA/FAA": "BND",
    "Longitude": "56.37785",
    "ICAO": "OIKB",
    "Airport ID": "2134",
    "Latitude": "27.218317",
    "Timezone": "22",
    "DST": "3.5",
    "destinations": ["4331", "2164", "2188", "4150", "2109", "2123", "2157", "2131", "2104", "5933", "2162", "2166"]
}, {
    "City": "Teheran",
    "DBTZ": "E",
    "Name": "Mehrabad Intl",
    "Country": "Iran",
    "IATA/FAA": "THR",
    "Longitude": "51.313416",
    "ICAO": "OIII",
    "Airport ID": "2131",
    "Latitude": "35.689167",
    "Timezone": "3962",
    "DST": "3.5",
    "destinations": ["2097", "2104", "2114", "4331", "2157", "2162", "5935", "5931", "2164", "5930", "2134", "2106", "5928", "6747", "4150", "6748", "5932", "5936", "6749", "7546", "2123", "2151", "2117", "7547", "2167", "2166", "5712", "2136", "5927", "2109", "5934", "2142", "2074", "2103"]
}, {
    "City": "Luanda",
    "DBTZ": "N",
    "Name": "Luanda 4 De Fevereiro",
    "Country": "Angola",
    "IATA/FAA": "LAD",
    "Longitude": "13.231178",
    "ICAO": "FNLU",
    "Airport ID": "951",
    "Latitude": "-8.858375",
    "Timezone": "243",
    "DST": "1",
    "destinations": ["1020", "1382", "1074", "507", "883", "946", "5630", "797", "2188", "2560", "2564", "1909", "1005", "813", "1638", "960", "952", "5633", "948", "1636", "3364", "959", "953", "949", "958", "973", "957", "5632", "4105", "1107", "944", "1229", "580", "4059", "340", "979"]
}, {
    "City": "Menongue",
    "DBTZ": "N",
    "Name": "Menongue",
    "Country": "Angola",
    "IATA/FAA": "SPP",
    "Longitude": "17.719833",
    "ICAO": "FNME",
    "Airport ID": "953",
    "Latitude": "-14.657583",
    "Timezone": "4469",
    "DST": "1",
    "destinations": ["951", "948", "5632"]
}, {
    "City": "Malanje",
    "DBTZ": "N",
    "Name": "Malanje",
    "Country": "Angola",
    "IATA/FAA": "MEG",
    "Longitude": "16.312406",
    "ICAO": "FNMA",
    "Airport ID": "952",
    "Latitude": "-9.525086",
    "Timezone": "3868",
    "DST": "1",
    "destinations": ["951", "957"]
}, {
    "City": "Saurimo",
    "DBTZ": "N",
    "Name": "Saurimo",
    "Country": "Angola",
    "IATA/FAA": "VHC",
    "Longitude": "20.431875",
    "ICAO": "FNSA",
    "Airport ID": "957",
    "Latitude": "-9.689067",
    "Timezone": "3584",
    "DST": "1",
    "destinations": ["951", "952"]
}, {
    "City": "Tuguegarao",
    "DBTZ": "N",
    "Name": "Tuguegarao Airport",
    "Country": "Philippines",
    "IATA/FAA": "TUG",
    "Longitude": "121.730614",
    "ICAO": "RPUT",
    "Airport ID": "6021",
    "Latitude": "17.638311",
    "Timezone": "70",
    "DST": "8",
    "destinations": ["2397"]
}, {
    "City": "Virac",
    "DBTZ": "N",
    "Name": "Virac Airport",
    "Country": "Philippines",
    "IATA/FAA": "VRC",
    "Longitude": "124.205672",
    "ICAO": "RPUV",
    "Airport ID": "6022",
    "Latitude": "13.576439",
    "Timezone": "121",
    "DST": "8",
    "destinations": ["2397"]
}, {
    "City": "Calbayog City",
    "DBTZ": "N",
    "Name": "Calbayog Airport",
    "Country": "Philippines",
    "IATA/FAA": "CYP",
    "Longitude": "124.545092",
    "ICAO": "RPVC",
    "Airport ID": "6023",
    "Latitude": "12.072706",
    "Timezone": "12",
    "DST": "8",
    "destinations": ["2397"]
}, {
    "City": "Catarman",
    "DBTZ": "N",
    "Name": "Catarman National Airport",
    "Country": "Philippines",
    "IATA/FAA": "CRM",
    "Longitude": "124.635778",
    "ICAO": "RPVF",
    "Airport ID": "6024",
    "Latitude": "12.502417",
    "Timezone": "6",
    "DST": "8",
    "destinations": ["2397"]
}, {
    "City": "Masbate",
    "DBTZ": "N",
    "Name": "Masbate Airport",
    "Country": "Philippines",
    "IATA/FAA": "MBT",
    "Longitude": "123.629",
    "ICAO": "RPVJ",
    "Airport ID": "6025",
    "Latitude": "12.3694",
    "Timezone": "26",
    "DST": "8",
    "destinations": ["2397"]
}, {
    "City": "Roxas City",
    "DBTZ": "N",
    "Name": "Roxas Airport",
    "Country": "Philippines",
    "IATA/FAA": "RXS",
    "Longitude": "122.751669",
    "ICAO": "RPVR",
    "Airport ID": "6026",
    "Latitude": "11.597669",
    "Timezone": "10",
    "DST": "8",
    "destinations": ["2397"]
}, {
    "City": "Esperance",
    "DBTZ": "O",
    "Name": "Esperance Airport",
    "Country": "Australia",
    "IATA/FAA": "EPR",
    "Longitude": "121.823",
    "ICAO": "YESP",
    "Airport ID": "6266",
    "Latitude": "-33.6844",
    "Timezone": "470",
    "DST": "8",
    "destinations": ["3351", "7577"]
}, {
    "City": "Arica",
    "DBTZ": "S",
    "Name": "Chacalluta",
    "Country": "Chile",
    "IATA/FAA": "ARI",
    "Longitude": "-70.338742",
    "ICAO": "SCAR",
    "Airport ID": "2641",
    "Latitude": "-18.348531",
    "Timezone": "167",
    "DST": "-4",
    "destinations": ["2651", "2649", "2762", "2650"]
}, {
    "City": "Balmaceda",
    "DBTZ": "S",
    "Name": "Balmaceda",
    "Country": "Chile",
    "IATA/FAA": "BBA",
    "Longitude": "-71.689475",
    "ICAO": "SCBA",
    "Airport ID": "2642",
    "Latitude": "-45.916058",
    "Timezone": "1722",
    "DST": "-4",
    "destinations": ["2665", "2647", "2650"]
}, {
    "City": "Calama",
    "DBTZ": "S",
    "Name": "El Loa",
    "Country": "Chile",
    "IATA/FAA": "CJC",
    "Longitude": "-68.903575",
    "ICAO": "SCCF",
    "Airport ID": "2645",
    "Latitude": "-22.498175",
    "Timezone": "7543",
    "DST": "-4",
    "destinations": ["2651", "4312", "2662", "2650"]
}, {
    "City": "Punta Arenas",
    "DBTZ": "S",
    "Name": "Carlos Ibanez Del Campo Intl",
    "Country": "Chile",
    "IATA/FAA": "PUQ",
    "Longitude": "-70.854586",
    "ICAO": "SCCI",
    "Airport ID": "2647",
    "Latitude": "-53.002642",
    "Timezone": "139",
    "DST": "-4",
    "destinations": ["2642", "2665", "2650", "2496"]
}, {
    "City": "Iliamna",
    "DBTZ": "A",
    "Name": "Iliamna",
    "Country": "United States",
    "IATA/FAA": "ILI",
    "Longitude": "-154.910961",
    "ICAO": "PAIL",
    "Airport ID": "3429",
    "Latitude": "59.754356",
    "Timezone": "186",
    "DST": "-9",
    "destinations": ["3774"]
}, {
    "City": "Tatalina",
    "DBTZ": "A",
    "Name": "Tatalina Lrrs",
    "Country": "United States",
    "IATA/FAA": "TLJ",
    "Longitude": "-155.976525",
    "ICAO": "PATL",
    "Airport ID": "3423",
    "Latitude": "62.894369",
    "Timezone": "964",
    "DST": "-9",
    "destinations": ["5965"]
}, {
    "City": "Fort Yukon",
    "DBTZ": "A",
    "Name": "Fort Yukon",
    "Country": "United States",
    "IATA/FAA": "FYU",
    "Longitude": "-145.250417",
    "ICAO": "PFYU",
    "Airport ID": "3420",
    "Latitude": "66.571492",
    "Timezone": "433",
    "DST": "-9",
    "destinations": ["7105", "3832", "3411", "7114"]
}, {
    "City": "St. Paul Island",
    "DBTZ": "A",
    "Name": "St Paul Island",
    "Country": "United States",
    "IATA/FAA": "SNP",
    "Longitude": "-170.220444",
    "ICAO": "PASN",
    "Airport ID": "3426",
    "Latitude": "57.167333",
    "Timezone": "63",
    "DST": "-9",
    "destinations": ["3774", "7247"]
}, {
    "City": "Whyalla",
    "DBTZ": "O",
    "Name": "Whyalla Airport",
    "Country": "Australia",
    "IATA/FAA": "WYA",
    "Longitude": "137.514",
    "ICAO": "YWHA",
    "Airport ID": "6334",
    "Latitude": "-33.0589",
    "Timezone": "41",
    "DST": "9.5",
    "destinations": ["3341"]
}, {
    "City": "Touho",
    "DBTZ": "U",
    "Name": "Touho",
    "Country": "New Caledonia",
    "IATA/FAA": "TOU",
    "Longitude": "165.259486",
    "ICAO": "NWWU",
    "Airport ID": "2003",
    "Latitude": "-20.790028",
    "Timezone": "10",
    "DST": "11",
    "destinations": ["2001"]
}, {
    "City": "Lifou",
    "DBTZ": "U",
    "Name": "Lifou",
    "Country": "New Caledonia",
    "IATA/FAA": "LIF",
    "Longitude": "167.239864",
    "ICAO": "NWWL",
    "Airport ID": "2000",
    "Latitude": "-20.7748",
    "Timezone": "92",
    "DST": "11",
    "destinations": ["2001", "5919", "2004"]
}, {
    "City": "Winton",
    "DBTZ": "O",
    "Name": "Winton Airport",
    "Country": "Australia",
    "IATA/FAA": "WIN",
    "Longitude": "143.086",
    "ICAO": "YWTN",
    "Airport ID": "6337",
    "Latitude": "-22.3636",
    "Timezone": "638",
    "DST": "10",
    "destinations": ["6289", "3330"]
}, {
    "City": "Auckland",
    "DBTZ": "Z",
    "Name": "Auckland Intl",
    "Country": "New Zealand",
    "IATA/FAA": "AKL",
    "Longitude": "174.791667",
    "ICAO": "NZAA",
    "Airport ID": "2006",
    "Latitude": "-37.008056",
    "Timezone": "23",
    "DST": "12",
    "destinations": ["3320", "4075", "3361", "3406", "3077", "3370", "3339", "3316", "1960", "3341", "2009", "2011", "3321", "2042", "2030", "3930", "2650", "3304", "1997", "2279", "1969", "2037", "3322", "2012", "2015", "2018", "2017", "3484", "2005", "4095", "2023", "2024", "3351", "2028", "2029", "1959", "2031", "3469", "1963", "2034", "2007", "2047", "2041", "2045", "3885"]
}, {
    "City": "Taupo",
    "DBTZ": "Z",
    "Name": "Taupo",
    "Country": "New Zealand",
    "IATA/FAA": "TUO",
    "Longitude": "176.084444",
    "ICAO": "NZAP",
    "Airport ID": "2007",
    "Latitude": "-38.739723",
    "Timezone": "1335",
    "DST": "12",
    "destinations": ["2006", "2042"]
}, {
    "City": "Ouvea",
    "DBTZ": "U",
    "Name": "Ouvea",
    "Country": "New Caledonia",
    "IATA/FAA": "UVE",
    "Longitude": "166.572778",
    "ICAO": "NWWV",
    "Airport ID": "2004",
    "Latitude": "-20.640556",
    "Timezone": "23",
    "DST": "11",
    "destinations": ["2001", "2000"]
}, {
    "City": "Windorah",
    "DBTZ": "O",
    "Name": "Windorah Airport",
    "Country": "Australia",
    "IATA/FAA": "WNR",
    "Longitude": "142.667",
    "ICAO": "YWDH",
    "Airport ID": "6333",
    "Latitude": "-25.4131",
    "Timezone": "452",
    "DST": "10",
    "destinations": ["6240", "6322"]
}, {
    "City": "Christchurch",
    "DBTZ": "Z",
    "Name": "Christchurch Intl",
    "Country": "New Zealand",
    "IATA/FAA": "CHC",
    "Longitude": "172.532225",
    "ICAO": "NZCH",
    "Airport ID": "2009",
    "Latitude": "-43.489358",
    "Timezone": "123",
    "DST": "12",
    "destinations": ["3361", "3316", "2006", "3339", "3321", "2042", "2037", "3320", "2011", "2014", "2015", "2025", "4095", "2023", "2024", "2028", "2029", "2031", "2034", "2030"]
}, {
    "City": "Burnie",
    "DBTZ": "O",
    "Name": "Wynyard Airport",
    "Country": "Australia",
    "IATA/FAA": "BWT",
    "Longitude": "145.731",
    "ICAO": "YWYY",
    "Airport ID": "6338",
    "Latitude": "-40.9989",
    "Timezone": "62",
    "DST": "10",
    "destinations": ["6278", "3337", "3339"]
}, {
    "City": "Lodwar",
    "DBTZ": "U",
    "Name": "Lodwar",
    "Country": "Kenya",
    "IATA/FAA": "LOK",
    "Longitude": "35.608692",
    "ICAO": "HKLO",
    "Airport ID": "1143",
    "Latitude": "3.121967",
    "Timezone": "1715",
    "DST": "3",
    "destinations": ["1138"]
}, {
    "City": "Kisumu",
    "DBTZ": "U",
    "Name": "Kisumu",
    "Country": "Kenya",
    "IATA/FAA": "KIS",
    "Longitude": "34.728892",
    "ICAO": "HKKI",
    "Airport ID": "1140",
    "Latitude": "-0.086139",
    "Timezone": "3796",
    "DST": "3",
    "destinations": ["1138", "4059"]
}, {
    "City": "Poitiers",
    "DBTZ": "E",
    "Name": "Biard",
    "Country": "France",
    "IATA/FAA": "PIS",
    "Longitude": "0.306666",
    "ICAO": "LFBI",
    "Airport ID": "1268",
    "Latitude": "46.587745",
    "Timezone": "423",
    "DST": "1",
    "destinations": ["1324", "6493", "1335", "603", "548"]
}, {
    "City": "Geraldton",
    "DBTZ": "O",
    "Name": "Geraldton Airport",
    "Country": "Australia",
    "IATA/FAA": "GET",
    "Longitude": "114.707",
    "ICAO": "YGEL",
    "Airport ID": "6268",
    "Latitude": "-28.7961",
    "Timezone": "121",
    "DST": "8",
    "destinations": ["3351"]
}, {
    "City": "London",
    "DBTZ": "E",
    "Name": "Heathrow",
    "Country": "United Kingdom",
    "IATA/FAA": "LHR",
    "Longitude": "-0.461389",
    "ICAO": "EGLL",
    "Airport ID": "507",
    "Latitude": "51.4775",
    "Timezone": "83",
    "DST": "0",
    "destinations": ["344", "342", "350", "351", "2997", "3093", "3941", "532", "248", "1230", "580", "737", "3682", "2179", "3673", "2057", "1218", "636", "467", "1538", "3131", "3448", "302", "4053", "1489", "3849", "1382", "3876", "609", "797", "3751", "3670", "599", "345", "2188", "1187", "535", "3494", "1555", "340", "1738", "534", "687", "1665", "352", "421", "3141", "3714", "3550", "1701", "3797", "813", "3304", "2176", "3877", "3484", "517", "1197", "1529", "1638", "629", "1335", "3144", "478", "3576", "1353", "346", "1524", "4059", "1354", "521", "3830", "644", "1657", "3752", "3462", "1587", "1562", "3626", "591", "3731", "3577", "3469", "1194", "666", "1273", "1551", "1613", "679", "146", "156", "178", "193", "1208", "1678", "1386", "49", "73", "100", "189", "3645", "3858", "210", "1824", "1074", "1080", "260", "2908", "2170", "2177", "3885", "1128", "3395", "4029", "3988", "1626", "1059", "2560", "2564", "2922", "3077", "2359", "1225", "3930", "2072", "1468", "1479", "2939", "951", "2948", "273", "1229", "1953", "2279", "1636", "3364", "3998", "3406", "2082", "3316", "1157", "1590", "3076", "3074", "3370", "596", "603", "1107", "16", "2983", "1216", "1220", "4330", "1739", "2910", "1606", "893", "1132", "1205", "1206", "2223", "2206", "2207", "2397", "2241", "2985", "2976", "287", "3024", "2988", "2194"]
}, {
    "City": "Ciudad del Este",
    "DBTZ": "S",
    "Name": "Ciudad del Este",
    "Country": "Paraguay",
    "IATA/FAA": "AGT",
    "Longitude": "-54.843592",
    "ICAO": "SGES",
    "Airport ID": "4305",
    "Latitude": "-25.4555",
    "Timezone": "846",
    "DST": "-4",
    "destinations": ["2699", "2564"]
}, {
    "City": "Houston",
    "DBTZ": "A",
    "Name": "George Bush Intercontinental",
    "Country": "United States",
    "IATA/FAA": "IAH",
    "Longitude": "-95.341442",
    "ICAO": "KIAH",
    "Airport ID": "3550",
    "Latitude": "29.984433",
    "Timezone": "97",
    "DST": "-6",
    "destinations": ["3876", "3670", "2241", "3484", "507", "3576", "3830", "3752", "3462", "3861", "49", "146", "178", "193", "3682", "1382", "1824", "3577", "2709", "1892", "2188", "1852", "3364", "1871", "3469", "580", "3488", "3645", "3697", "3473", "3858", "3536", "3751", "3930", "340", "5775", "2279", "3877", "3878", "4029", "1701", "4019", "1783", "3524", "3852", "1785", "3840", "3673", "3558", "3811", "1815", "3690", "2896", "3448", "3738", "3846", "3849", "1957", "3561", "2851", "3806", "3486", "3715", "1789", "3759", "3819", "3744", "4285", "1797", "1800", "3502", "3520", "1801", "3729", "3559", "3494", "3988", "3533", "1926", "1804", "2560", "4063", "4038", "3775", "3685", "2564", "4034", "1767", "3728", "3749", "3560", "4113", "1786", "3714", "3457", "3585", "3580", "3712", "3829", "3513", "4017", "3493", "2789", "1881", "3660", "273", "3691", "3652", "1780", "3458", "3826", "1863", "1819", "3717", "1821", "3732", "3782", "1825", "346", "1953", "1829", "3863", "3454", "3734", "3611", "1831", "3722", "3720", "3570", "3564", "2902", "3839", "1836", "1838", "3626", "3608", "3793", "1774", "3731", "1772", "3621", "3613", "4014", "3609", "3748", "1840", "1885", "2890", "1842", "1810", "3817", "3867", "3678", "1848", "1776", "3646", "1845", "3855", "3636", "3848", "3676", "2688", "1854", "3873", "1853", "4356", "156", "1856", "1858"]
}, {
    "City": "Georgetown",
    "DBTZ": "U",
    "Name": "Cheddi Jagan Intl",
    "Country": "Guyana",
    "IATA/FAA": "GEO",
    "Longitude": "-58.254119",
    "ICAO": "SYCJ",
    "Airport ID": "4304",
    "Latitude": "6.498553",
    "Timezone": "95",
    "DST": "-4",
    "destinations": ["3797", "2902", "193", "1779", "3576", "2773", "2856"]
}, {
    "City": "Cajamarca",
    "DBTZ": "U",
    "Name": "Mayor General FAP Armando Revoredo Iglesias Airport",
    "Country": "Peru",
    "IATA/FAA": "CJA",
    "Longitude": "-78.4894",
    "ICAO": "SPJR",
    "Airport ID": "6066",
    "Latitude": "-7.139183",
    "Timezone": "8781",
    "DST": "-5",
    "destinations": ["2789"]
}, {
    "City": "El Paso",
    "DBTZ": "A",
    "Name": "El Paso Intl",
    "Country": "United States",
    "IATA/FAA": "ELP",
    "Longitude": "-106.377583",
    "ICAO": "KELP",
    "Airport ID": "3559",
    "Latitude": "31.80725",
    "Timezone": "3958",
    "DST": "-7",
    "destinations": ["3670", "3484", "3830", "3462", "3682", "3752", "3621", "3751", "3550", "3673", "3502", "3566", "3877"]
}, {
    "City": "Bakersfield",
    "DBTZ": "A",
    "Name": "Meadows Fld",
    "Country": "United States",
    "IATA/FAA": "BFL",
    "Longitude": "-119.05677",
    "ICAO": "KBFL",
    "Airport ID": "3558",
    "Latitude": "35.433598",
    "Timezone": "507",
    "DST": "-8",
    "destinations": ["3462", "3751", "3550", "3484", "3469"]
}, {
    "City": "Stavanger",
    "DBTZ": "E",
    "Name": "Sola",
    "Country": "Norway",
    "IATA/FAA": "SVG",
    "Longitude": "5.637856",
    "ICAO": "ENZV",
    "Airport ID": "666",
    "Latitude": "58.876778",
    "Timezone": "29",
    "DST": "1",
    "destinations": ["507", "1382", "580", "641", "687", "647", "3953", "628", "608", "660", "1230", "1212", "636", "669", "502", "478", "644", "337", "679", "16", "1218", "340", "625", "532", "737", "609", "535", "521", "663", "665", "668", "671", "676", "3959", "643", "638", "664"]
}, {
    "City": "Ancona",
    "DBTZ": "E",
    "Name": "Falconara",
    "Country": "Italy",
    "IATA/FAA": "AOI",
    "Longitude": "13.362222",
    "ICAO": "LIPY",
    "Airport ID": "4309",
    "Latitude": "43.616389",
    "Timezone": "10",
    "DST": "1",
    "destinations": ["345", "351", "1555", "1517", "304", "4198", "548", "1515", "346", "1509"]
}, {
    "City": "Antananarivo",
    "DBTZ": "U",
    "Name": "Ivato",
    "Country": "Madagascar",
    "IATA/FAA": "TNR",
    "Longitude": "47.478806",
    "ICAO": "FMMI",
    "Airport ID": "918",
    "Latitude": "-18.79695",
    "Timezone": "4198",
    "DST": "3",
    "destinations": ["1382", "4059", "927", "3885", "923", "915", "937", "912", "813", "929", "922", "1353", "893", "930", "916", "920", "933", "943", "921", "932", "1386"]
}, {
    "City": "Tselinograd",
    "DBTZ": "U",
    "Name": "Astana Intl",
    "Country": "Kazakhstan",
    "IATA/FAA": "TSE",
    "Longitude": "71.466944",
    "ICAO": "UACC",
    "Airport ID": "2910",
    "Latitude": "51.022222",
    "Timezone": "1165",
    "DST": "6",
    "destinations": ["2954", "3399", "2920", "2908", "2914", "2911", "4357", "2918", "6084", "4367", "6086", "2179", "2191", "2983", "6085", "340", "2912", "2922", "1701", "2939", "6088", "4358", "2948", "507", "2958", "4078", "3364", "2917", "2985", "2975", "1613", "4029", "2988", "2916"]
}, {
    "City": "Dzhambul",
    "DBTZ": "U",
    "Name": "Taraz",
    "Country": "Kazakhstan",
    "IATA/FAA": "DMB",
    "Longitude": "71.303611",
    "ICAO": "UADD",
    "Airport ID": "2911",
    "Latitude": "42.853611",
    "Timezone": "2184",
    "DST": "6",
    "destinations": ["2908", "2910", "4029", "2975"]
}, {
    "City": "Bishkek",
    "DBTZ": "U",
    "Name": "Manas",
    "Country": "Kyrgyzstan",
    "IATA/FAA": "FRU",
    "Longitude": "74.477556",
    "ICAO": "UAFM",
    "Airport ID": "2912",
    "Latitude": "43.061306",
    "Timezone": "2058",
    "DST": "6",
    "destinations": ["2979", "2985", "3399", "2188", "4029", "2983", "2908", "2910", "6156", "2937", "4078", "2974", "2975", "4317", "2968", "1701", "4374", "2960", "2913", "2948", "3380", "2990"]
}, {
    "City": "Ciudad Victoria",
    "DBTZ": "S",
    "Name": "General Pedro Jose Mendez Intl",
    "Country": "Mexico",
    "IATA/FAA": "CVM",
    "Longitude": "-98.956486",
    "ICAO": "MMCV",
    "Airport ID": "1798",
    "Latitude": "23.703336",
    "Timezone": "761",
    "DST": "-6",
    "destinations": ["1824"]
}, {
    "City": "Chimkent",
    "DBTZ": "U",
    "Name": "Shymkent",
    "Country": "Kazakhstan",
    "IATA/FAA": "CIT",
    "Longitude": "69.478889",
    "ICAO": "UAII",
    "Airport ID": "2914",
    "Latitude": "42.364167",
    "Timezone": "1385",
    "DST": "6",
    "destinations": ["2908", "4029", "4367", "2910", "2948", "2191", "4078", "2988"]
}, {
    "City": "Uralsk",
    "DBTZ": "U",
    "Name": "Uralsk",
    "Country": "Kazakhstan",
    "IATA/FAA": "URA",
    "Longitude": "51.543056",
    "ICAO": "UARR",
    "Airport ID": "2916",
    "Latitude": "51.150833",
    "Timezone": "125",
    "DST": "5",
    "destinations": ["4367", "4357", "2910"]
}, {
    "City": "Kramfors",
    "DBTZ": "E",
    "Name": "Kramfors Solleftea",
    "Country": "Sweden",
    "IATA/FAA": "KRF",
    "Longitude": "17.768856",
    "ICAO": "ESNK",
    "Airport ID": "718",
    "Latitude": "63.048597",
    "Timezone": "34",
    "DST": "1",
    "destinations": ["737", "715"]
}, {
    "City": "Chetumal",
    "DBTZ": "S",
    "Name": "Chetumal Intl",
    "Country": "Mexico",
    "IATA/FAA": "CTM",
    "Longitude": "-88.326847",
    "ICAO": "MMCM",
    "Airport ID": "1793",
    "Latitude": "18.504667",
    "Timezone": "39",
    "DST": "-6",
    "destinations": ["1824"]
}, {
    "City": "Culiacan",
    "DBTZ": "S",
    "Name": "Culiacan Intl",
    "Country": "Mexico",
    "IATA/FAA": "CUL",
    "Longitude": "-107.474717",
    "ICAO": "MMCL",
    "Airport ID": "1792",
    "Latitude": "24.764547",
    "Timezone": "108",
    "DST": "-7",
    "destinations": ["1824", "1804", "1807", "1825", "1847", "1816", "1840"]
}, {
    "City": "Gallivare",
    "DBTZ": "E",
    "Name": "Gallivare",
    "Country": "Sweden",
    "IATA/FAA": "GEV",
    "Longitude": "20.814636",
    "ICAO": "ESNG",
    "Airport ID": "715",
    "Latitude": "67.132408",
    "Timezone": "1027",
    "DST": "1",
    "destinations": ["737", "718", "724", "734"]
}, {
    "City": "Sveg",
    "DBTZ": "E",
    "Name": "Sveg",
    "Country": "Sweden",
    "IATA/FAA": "EVG",
    "Longitude": "14.42295",
    "ICAO": "ESND",
    "Airport ID": "714",
    "Latitude": "62.047811",
    "Timezone": "1178",
    "DST": "1",
    "destinations": ["737", "698"]
}, {
    "City": "Chihuahua",
    "DBTZ": "S",
    "Name": "General R Fierro Villalobos Intl",
    "Country": "Mexico",
    "IATA/FAA": "CUU",
    "Longitude": "-105.964567",
    "ICAO": "MMCU",
    "Airport ID": "1797",
    "Latitude": "28.702875",
    "Timezone": "4462",
    "DST": "-7",
    "destinations": ["1824", "3670", "1796", "1804", "1807", "1825", "1845", "3550", "1852", "1847"]
}, {
    "City": "Ciudad Juarez",
    "DBTZ": "S",
    "Name": "Abraham Gonzalez Intl",
    "Country": "Mexico",
    "IATA/FAA": "CJS",
    "Longitude": "-106.428667",
    "ICAO": "MMCS",
    "Airport ID": "1796",
    "Latitude": "31.636133",
    "Timezone": "3904",
    "DST": "-7",
    "destinations": ["1824", "1797", "1807", "1825", "1847", "1804"]
}, {
    "City": "Campeche",
    "DBTZ": "S",
    "Name": "Ingeniero Alberto Acuna Ongay Intl",
    "Country": "Mexico",
    "IATA/FAA": "CPE",
    "Longitude": "-90.500314",
    "ICAO": "MMCP",
    "Airport ID": "1795",
    "Latitude": "19.816794",
    "Timezone": "34",
    "DST": "-6",
    "destinations": ["1824"]
}, {
    "City": "Ciudad Obregon",
    "DBTZ": "S",
    "Name": "Ciudad Obregon Intl",
    "Country": "Mexico",
    "IATA/FAA": "CEN",
    "Longitude": "-109.833111",
    "ICAO": "MMCN",
    "Airport ID": "1794",
    "Latitude": "27.392639",
    "Timezone": "205",
    "DST": "-7",
    "destinations": ["1824", "1804", "1807", "1847"]
}, {
    "City": "Toowoomba",
    "DBTZ": "N",
    "Name": "Toowoomba",
    "Country": "Australia",
    "IATA/FAA": "TWB",
    "Longitude": "151.916389",
    "ICAO": "YTWB",
    "Airport ID": "7128",
    "Latitude": "-27.542778",
    "Timezone": "2086",
    "DST": "10",
    "destinations": ["3320", "3323", "6325", "3361"]
}, {
    "City": "Mornington Island",
    "DBTZ": "O",
    "Name": "Mornington Island Airport",
    "Country": "Australia",
    "IATA/FAA": "ONG",
    "Longitude": "139.178",
    "ICAO": "YMTI",
    "Airport ID": "6304",
    "Latitude": "-16.6625",
    "Timezone": "33",
    "DST": "10",
    "destinations": ["6245", "6262", "6309"]
}, {
    "City": "Stord",
    "DBTZ": "E",
    "Name": "Sorstokken",
    "Country": "Norway",
    "IATA/FAA": "SRP",
    "Longitude": "5.34085",
    "ICAO": "ENSO",
    "Airport ID": "660",
    "Latitude": "59.791925",
    "Timezone": "160",
    "DST": "1",
    "destinations": ["651", "644", "666"]
}, {
    "City": "Merced",
    "DBTZ": "A",
    "Name": "Merced Municipal Airport",
    "Country": "United States",
    "IATA/FAA": "MCE",
    "Longitude": "-120.513889",
    "ICAO": "KMCE",
    "Airport ID": "7122",
    "Latitude": "37.284722",
    "Timezone": "156",
    "DST": "-8",
    "destinations": ["3484"]
}, {
    "City": "Visalia",
    "DBTZ": "A",
    "Name": "Visalia Municipal Airport",
    "Country": "United States",
    "IATA/FAA": "VIS",
    "Longitude": "-119.392778",
    "ICAO": "KVIS",
    "Airport ID": "7121",
    "Latitude": "36.318611",
    "Timezone": "295",
    "DST": "-8",
    "destinations": ["3484"]
}, {
    "City": "St.-denis",
    "DBTZ": "U",
    "Name": "St Denis Gillot",
    "Country": "Reunion",
    "IATA/FAA": "RUN",
    "Longitude": "55.510308",
    "ICAO": "FMEE",
    "Airport ID": "916",
    "Latitude": "-20.8871",
    "Timezone": "66",
    "DST": "4",
    "destinations": ["893", "1386", "923", "920", "918", "1353", "1382", "915", "912", "813", "3144", "930", "921"]
}, {
    "City": "Monrovia",
    "DBTZ": "N",
    "Name": "Monrovia Roberts Intl",
    "Country": "Liberia",
    "IATA/FAA": "ROB",
    "Longitude": "-10.362311",
    "ICAO": "GLRB",
    "Airport ID": "1063",
    "Latitude": "6.233789",
    "Timezone": "31",
    "DST": "0",
    "destinations": ["248", "1059", "273", "1074", "253", "302"]
}, {
    "City": "Monrovia",
    "DBTZ": "N",
    "Name": "Monrovia Spriggs Payne",
    "Country": "Liberia",
    "IATA/FAA": "MLW",
    "Longitude": "-10.758722",
    "ICAO": "GLMR",
    "Airport ID": "1062",
    "Latitude": "6.289061",
    "Timezone": "25",
    "DST": "0",
    "destinations": ["248", "1059"]
}, {
    "City": "Tan Tan",
    "DBTZ": "N",
    "Name": "Plage Blanche",
    "Country": "Morocco",
    "IATA/FAA": "TTA",
    "Longitude": "-11.161347",
    "ICAO": "GMAT",
    "Airport ID": "1065",
    "Latitude": "28.448194",
    "Timezone": "653",
    "DST": "0",
    "destinations": ["1074"]
}, {
    "City": "Agadir",
    "DBTZ": "N",
    "Name": "Inezgane",
    "Country": "Morocco",
    "IATA/FAA": "AGA",
    "Longitude": "-9.546311",
    "ICAO": "GMAA",
    "Airport ID": "1064",
    "Latitude": "30.381353",
    "Timezone": "89",
    "DST": "0",
    "destinations": ["302", "1074", "5672", "1386", "5670", "345", "340", "348", "346", "304", "1353", "1399", "580", "629", "1054", "502", "478", "1382", "1335", "337"]
}, {
    "City": "Moree",
    "DBTZ": "O",
    "Name": "Moree Airport",
    "Country": "Australia",
    "IATA/FAA": "MRZ",
    "Longitude": "149.845",
    "ICAO": "YMOR",
    "Airport ID": "6300",
    "Latitude": "-29.4989",
    "Timezone": "701",
    "DST": "10",
    "destinations": ["3361"]
}, {
    "City": "Papeete",
    "DBTZ": "U",
    "Name": "Faa\\\\'a International",
    "Country": "French Polynesia",
    "IATA/FAA": "PPT",
    "Longitude": "-149.611389",
    "ICAO": "NTAA",
    "Airport ID": "4075",
    "Latitude": "-17.556667",
    "Timezone": "7",
    "DST": "-10",
    "destinations": ["2006", "3484", "1959", "3728", "2279", "2657", "2005", "5889", "5890", "1984", "1989", "1977", "1993", "1991", "1994", "1992", "1985", "1987", "1995", "1990", "6926", "1971", "7456", "1975", "1986", "1972", "1978"]
}, {
    "City": "Mount Gambier",
    "DBTZ": "O",
    "Name": "Mount Gambier Airport",
    "Country": "Australia",
    "IATA/FAA": "MGB",
    "Longitude": "140.785",
    "ICAO": "YMTG",
    "Airport ID": "6303",
    "Latitude": "-37.7456",
    "Timezone": "212",
    "DST": "9.5",
    "destinations": ["3341", "3339"]
}, {
    "City": "Honiara",
    "DBTZ": "U",
    "Name": "Honiara International",
    "Country": "Solomon Islands",
    "IATA/FAA": "HIR",
    "Longitude": "160.054789",
    "ICAO": "AGGH",
    "Airport ID": "4074",
    "Latitude": "-9.428",
    "Timezone": "28",
    "DST": "11",
    "destinations": ["1997", "5404", "3320", "6919", "7617", "6758", "5407", "5412", "5409", "5417", "5411", "1960", "7618", "5418", "7616", "5414", "5415", "5410", "5416", "5"]
}, {
    "City": "Geneva",
    "DBTZ": "E",
    "Name": "Geneve Cointrin",
    "Country": "Switzerland",
    "IATA/FAA": "GVA",
    "Longitude": "6.10895",
    "ICAO": "LSGG",
    "Airport ID": "1665",
    "Latitude": "46.238064",
    "Timezone": "1411",
    "DST": "1",
    "destinations": ["1054", "345", "342", "3941", "1636", "1280", "507", "1229", "146", "1382", "210", "1074", "421", "1638", "1555", "1563", "2954", "737", "609", "3364", "2241", "644", "599", "2188", "340", "2179", "1739", "1264", "1675", "350", "1273", "1246", "1678", "302", "580", "1606", "629", "346", "679", "1230", "1218", "1128", "1509", "4029", "3494", "1452", "3714", "3797", "503", "2948", "1354", "1520", "3998", "1587", "1742", "1075", "1590", "1613", "2177", "1688", "2939", "2170", "1657", "499", "2985", "2072", "2082", "1353", "1701", "293", "286", "287", "1324", "1212", "1506", "1321", "1216", "490", "1489", "1519", "1200", "535", "1626", "1130", "1225", "1468", "502", "1399", "491", "492", "478", "1561", "1418", "1386", "1243", "508", "1206", "1253", "337", "1056", "1551"]
}, {
    "City": "Moruya",
    "DBTZ": "O",
    "Name": "Moruya Airport",
    "Country": "Australia",
    "IATA/FAA": "MYA",
    "Longitude": "150.144",
    "ICAO": "YMRY",
    "Airport ID": "6302",
    "Latitude": "-35.8978",
    "Timezone": "14",
    "DST": "10",
    "destinations": ["6294", "3361"]
}, {
    "City": "Timisoara",
    "DBTZ": "E",
    "Name": "Traian Vuia",
    "Country": "Romania",
    "IATA/FAA": "TSR",
    "Longitude": "21.337861",
    "ICAO": "LRTR",
    "Airport ID": "1663",
    "Latitude": "45.809861",
    "Timezone": "348",
    "DST": "2",
    "destinations": ["1657", "346", "1218", "1525", "1538", "1367", "1553", "373", "492", "1229", "1539", "1246"]
}, {
    "City": "Tirgu Mures",
    "DBTZ": "E",
    "Name": "Transilvania Targu Mures",
    "Country": "Romania",
    "IATA/FAA": "TGM",
    "Longitude": "24.412525",
    "ICAO": "LRTM",
    "Airport ID": "1662",
    "Latitude": "46.467714",
    "Timezone": "963",
    "DST": "2",
    "destinations": ["1657", "1367", "373", "492"]
}, {
    "City": "Rotterdam",
    "DBTZ": "E",
    "Name": "Rotterdam",
    "Country": "Netherlands",
    "IATA/FAA": "RTM",
    "Longitude": "4.437222",
    "ICAO": "EHRD",
    "Airport ID": "591",
    "Latitude": "51.956944",
    "Timezone": "-15",
    "DST": "1",
    "destinations": ["507", "503", "1230", "1212", "1688", "1218", "4315", "1489", "1265", "585", "1626", "1555", "1222", "1452", "1225", "1229", "1359", "1354", "3998", "1587", "1075", "1472", "4317", "1206", "1438", "1613", "1246", "346", "1701"]
}, {
    "City": "Vadso",
    "DBTZ": "E",
    "Name": "Airport",
    "Country": "Norway",
    "IATA/FAA": "VDS",
    "Longitude": "29.844",
    "ICAO": "ENVD",
    "Airport ID": "4328",
    "Latitude": "70.065",
    "Timezone": "127",
    "DST": "1",
    "destinations": ["632", "637", "4325", "649", "4327", "663", "5589"]
}, {
    "City": "Dakar",
    "DBTZ": "N",
    "Name": "Leopold Sedar Senghor Intl",
    "Country": "Senegal",
    "IATA/FAA": "DKR",
    "Longitude": "-17.490225",
    "ICAO": "GOOY",
    "Airport ID": "1084",
    "Latitude": "14.739708",
    "Timezone": "85",
    "DST": "0",
    "destinations": ["1044", "246", "248", "1050", "897", "1059", "273", "5665", "1054", "302", "4162", "1382", "210", "1074", "3797", "253", "245", "1094", "5674", "1081", "2188", "1218", "1229", "1524", "1057", "3714", "813", "1386", "1701", "1638", "287"]
}, {
    "City": "Patos de Minas",
    "DBTZ": "S",
    "Name": "Patos de Minas Airport",
    "Country": "Brazil",
    "IATA/FAA": "POJ",
    "Longitude": "-46.491111",
    "ICAO": "SNPD",
    "Airport ID": "8237",
    "Latitude": "-18.672778",
    "Timezone": "851",
    "DST": "-3",
    "destinations": ["2528"]
}, {
    "City": "Cap Skiring",
    "DBTZ": "N",
    "Name": "Cap Skiring",
    "Country": "Senegal",
    "IATA/FAA": "CSK",
    "Longitude": "-16.746125",
    "ICAO": "GOGS",
    "Airport ID": "1082",
    "Latitude": "12.4102",
    "Timezone": "52",
    "DST": "0",
    "destinations": ["1084"]
}, {
    "City": "Sohag",
    "DBTZ": "E",
    "Name": "Sohag International",
    "Country": "Egypt",
    "IATA/FAA": "HMB",
    "Longitude": "31.742983",
    "ICAO": "HEMK",
    "Airport ID": "8235",
    "Latitude": "26.341189",
    "Timezone": "859",
    "DST": "2",
    "destinations": ["2191", "2176", "1128", "2072", "2082"]
}, {
    "City": "Cork",
    "DBTZ": "E",
    "Name": "Cork",
    "Country": "Ireland",
    "IATA/FAA": "ORK",
    "Longitude": "-8.491111",
    "ICAO": "EICK",
    "Airport ID": "596",
    "Latitude": "51.841269",
    "Timezone": "502",
    "DST": "0",
    "destinations": ["1055", "1230", "1212", "580", "1218", "469", "490", "302", "1382", "535", "1626", "534", "499", "507", "1638", "478", "346", "1354", "521", "3998", "523", "1051", "668", "502", "1054", "491", "548", "1056", "3959", "680"]
}, {
    "City": "Dublin",
    "DBTZ": "E",
    "Name": "Dublin",
    "Country": "Ireland",
    "IATA/FAA": "DUB",
    "Longitude": "-6.270075",
    "ICAO": "EIDW",
    "Airport ID": "599",
    "Latitude": "53.421333",
    "Timezone": "242",
    "DST": "0",
    "destinations": ["344", "345", "1735", "3876", "3797", "507", "3830", "3752", "193", "3682", "1382", "503", "5577", "552", "495", "609", "421", "644", "532", "1055", "1706", "1230", "1212", "580", "737", "3941", "2179", "1218", "469", "1216", "514", "1538", "1264", "494", "1191", "3448", "490", "302", "1489", "1460", "1509", "488", "1200", "535", "1626", "1555", "340", "1051", "534", "1665", "352", "342", "3714", "1225", "520", "499", "601", "502", "1529", "1638", "1054", "1335", "1229", "478", "3878", "1353", "346", "1524", "1561", "1354", "521", "1356", "3998", "1587", "1203", "1243", "508", "3469", "350", "337", "1056", "1273", "1551", "1613", "1550", "679", "1678", "2188", "1517", "1525", "1280", "353", "1501", "4053", "1745", "1367", "5591", "1352", "1474", "1553", "9327", "304", "585", "523", "3986", "668", "1222", "355", "669", "671", "3956", "517", "1213", "491", "6493", "492", "1227", "1606", "1418", "1636", "1657", "537", "674", "1562", "1075", "1289", "1236", "3953", "657", "675", "1251", "548", "1253", "676", "1539", "1376", "1246", "3959", "8414", "680", "1209", "629", "3494", "1701"]
}, {
    "City": "Newcastle",
    "DBTZ": "O",
    "Name": "Newcastle Airport",
    "Country": "Australia",
    "IATA/FAA": "NTL",
    "Longitude": "151.83",
    "ICAO": "YWLM",
    "Airport ID": "4320",
    "Latitude": "-32.78",
    "Timezone": "31",
    "DST": "10",
    "destinations": ["3320", "3339", "3321", "4291", "3361"]
}, {
    "City": "Ourilandia do Norte",
    "DBTZ": "S",
    "Name": "Ourilandia do Norte Airport",
    "Country": "Brazil",
    "IATA/FAA": "OIA",
    "Longitude": "-51.05",
    "ICAO": "SDOW",
    "Airport ID": "8239",
    "Latitude": "-6.763056",
    "Timezone": "229",
    "DST": "-4",
    "destinations": ["7532"]
}, {
    "City": "Bauru",
    "DBTZ": "S",
    "Name": "Bauru-Arealva",
    "Country": "Brazil",
    "IATA/FAA": "JTC",
    "Longitude": "-49.068333",
    "ICAO": "SJTC",
    "Airport ID": "8238",
    "Latitude": "-22.157778",
    "Timezone": "594",
    "DST": "-3",
    "destinations": ["6038", "2578", "2618"]
}, {
    "City": "Hammerfest",
    "DBTZ": "E",
    "Name": "Hammerfest Airport",
    "Country": "Norway",
    "IATA/FAA": "HFT",
    "Longitude": "23.668889",
    "ICAO": "ENHF",
    "Airport ID": "4325",
    "Latitude": "70.679722",
    "Timezone": "799",
    "DST": "1",
    "destinations": ["632", "4350", "646", "4326", "4327", "5588", "663"]
}, {
    "City": "Mehamn",
    "DBTZ": "E",
    "Name": "Mehamn",
    "Country": "Norway",
    "IATA/FAA": "MEH",
    "Longitude": "27.826667",
    "ICAO": "ENMR",
    "Airport ID": "4327",
    "Latitude": "71.029722",
    "Timezone": "41",
    "DST": "1",
    "destinations": ["632", "4350", "4325", "4326", "4328"]
}, {
    "City": "Honningsvag",
    "DBTZ": "E",
    "Name": "Valan",
    "Country": "Norway",
    "IATA/FAA": "HVG",
    "Longitude": "25.83",
    "ICAO": "ENHV",
    "Airport ID": "4326",
    "Latitude": "70.99",
    "Timezone": "46",
    "DST": "1",
    "destinations": ["4350", "4325", "4327"]
}, {
    "City": "Hyderabad",
    "DBTZ": "N",
    "Name": "Hyderabad",
    "Country": "India",
    "IATA/FAA": "HYD",
    "Longitude": "78.467586",
    "ICAO": "VOHY",
    "Airport ID": "3141",
    "Latitude": "17.453117",
    "Timezone": "1742",
    "DST": "5.5",
    "destinations": ["2994", "3042", "3131", "2997", "3043", "3136", "3093", "2188", "3007", "3098", "3144", "3017", "3020", "3066", "2179", "3304", "3148", "507", "2072", "2194", "2191", "3151", "3134", "3077", "3316", "2241", "3135", "3008", "3142", "2064", "2082", "3885"]
}, {
    "City": "Madurai",
    "DBTZ": "N",
    "Name": "Madurai",
    "Country": "India",
    "IATA/FAA": "IXM",
    "Longitude": "78.093378",
    "ICAO": "VOMD",
    "Airport ID": "3142",
    "Latitude": "9.834508",
    "Timezone": "459",
    "DST": "5.5",
    "destinations": ["3144", "3024", "3141"]
}, {
    "City": "Ahwaz",
    "DBTZ": "E",
    "Name": "Ahwaz",
    "Country": "Iran",
    "IATA/FAA": "AWZ",
    "Longitude": "48.76195",
    "ICAO": "OIAW",
    "Airport ID": "2104",
    "Latitude": "31.337431",
    "Timezone": "66",
    "DST": "3.5",
    "destinations": ["4150", "2109", "4331", "5933", "2157", "2131", "2176", "7546", "2134", "2188", "2123", "2162", "2074"]
}, {
    "City": "Madras",
    "DBTZ": "N",
    "Name": "Chennai Intl",
    "Country": "India",
    "IATA/FAA": "MAA",
    "Longitude": "80.180517",
    "ICAO": "VOMM",
    "Airport ID": "3144",
    "Latitude": "12.994414",
    "Timezone": "52",
    "DST": "5.5",
    "destinations": ["2994", "3131", "2997", "3043", "3135", "3136", "3093", "2188", "3141", "3017", "3316", "3153", "3066", "2179", "3007", "3142", "3146", "3304", "3152", "507", "3024", "340", "2194", "893", "2191", "3077", "3157", "2057", "2176", "8949", "3076", "3156", "2241", "3137", "2064", "2072", "2082", "3885", "916"]
}, {
    "City": "London",
    "DBTZ": "E",
    "Name": "City",
    "Country": "United Kingdom",
    "IATA/FAA": "LCY",
    "Longitude": "0.055278",
    "ICAO": "EGLC",
    "Airport ID": "503",
    "Latitude": "51.505278",
    "Timezone": "19",
    "DST": "0",
    "destinations": ["340", "1678", "580", "299", "1403", "1278", "1408", "338", "599", "1555", "1563", "1529", "1418", "1386", "591", "532", "1230", "737", "608", "345", "535", "534", "1223", "1225", "520", "1229", "1231", "1354", "3998", "1551", "629", "4053", "1665", "499", "1676"]
}, {
    "City": "Port Blair",
    "DBTZ": "N",
    "Name": "Port Blair",
    "Country": "India",
    "IATA/FAA": "IXZ",
    "Longitude": "92.729744",
    "ICAO": "VOPB",
    "Airport ID": "3146",
    "Latitude": "11.641161",
    "Timezone": "14",
    "DST": "5.5",
    "destinations": ["3043", "3144", "3093"]
}, {
    "City": "Barquisimeto",
    "DBTZ": "U",
    "Name": "Barquisimeto Intl",
    "Country": "Venezuela",
    "IATA/FAA": "BRM",
    "Longitude": "-69.358619",
    "ICAO": "SVBM",
    "Airport ID": "2824",
    "Latitude": "10.042747",
    "Timezone": "2042",
    "DST": "-4.5",
    "destinations": ["2897", "2851", "2850"]
}, {
    "City": "Barcelona",
    "DBTZ": "U",
    "Name": "General Jose Antonio Anzoategui Intl",
    "Country": "Venezuela",
    "IATA/FAA": "BLA",
    "Longitude": "-64.689161",
    "ICAO": "SVBC",
    "Airport ID": "2821",
    "Latitude": "10.107139",
    "Timezone": "26",
    "DST": "-4.5",
    "destinations": ["2851", "3576", "2850", "1871", "2856", "2868"]
}, {
    "City": "Barinas",
    "DBTZ": "U",
    "Name": "Barinas",
    "Country": "Venezuela",
    "IATA/FAA": "BNS",
    "Longitude": "-70.220825",
    "ICAO": "SVBI",
    "Airport ID": "2822",
    "Latitude": "8.619575",
    "Timezone": "666",
    "DST": "-4.5",
    "destinations": ["2851"]
}, {
    "City": "Hangzhou",
    "DBTZ": "U",
    "Name": "Xiaoshan",
    "Country": "China",
    "IATA/FAA": "HGH",
    "Longitude": "120.434453",
    "ICAO": "ZSHC",
    "Airport ID": "3386",
    "Latitude": "30.229503",
    "Timezone": "23",
    "DST": "8",
    "destinations": ["3316", "3375", "3393", "3395", "3404", "4120", "3394", "3368", "3369", "3382", "6379", "3372", "4144", "6347", "4030", "3374", "2264", "2268", "3269", "3371", "2276", "6351", "3370", "6398", "6345", "3077", "3930", "4085", "6386", "3992", "6431", "4033", "3121", "3373", "2279", "3364", "2372", "3390", "4108", "3379", "3383", "6430", "3392", "580", "4380", "3385", "3400", "6359", "3399", "3376", "6355", "3304", "3931", "3157", "7503", "6360", "2275", "6434", "4302", "6412", "6388", "4308", "4055", "6348", "6361", "6008", "3179", "6383", "6385", "6399", "2241", "3196"]
}, {
    "City": "Ninbo",
    "DBTZ": "U",
    "Name": "Lishe",
    "Country": "China",
    "IATA/FAA": "NGB",
    "Longitude": "121.461906",
    "ICAO": "ZSNB",
    "Airport ID": "3387",
    "Latitude": "29.826683",
    "Timezone": "13",
    "DST": "8",
    "destinations": ["3393", "4120", "3400", "3384", "3382", "4144", "4030", "4033", "6361", "2268", "2276", "3368", "2264", "3395", "3372", "3121", "3364", "3390", "3077", "3370", "4380", "3371", "3404", "4302", "3374", "3376", "3383", "3157", "6388", "6341", "3885", "3375", "2370", "3379", "6347", "6355", "3930", "3316"]
}, {
    "City": "Nanchang",
    "DBTZ": "U",
    "Name": "Changbei Intl",
    "Country": "China",
    "IATA/FAA": "KHN",
    "Longitude": "115.9",
    "ICAO": "ZSCN",
    "Airport ID": "3384",
    "Latitude": "28.865",
    "Timezone": "143",
    "DST": "8",
    "destinations": ["3393", "3395", "3382", "3387", "3370", "3394", "3364", "3406", "3374", "3390", "4108", "6355", "2264", "2276", "4380", "3404", "3400", "4148", "3388", "3373", "3369", "3379", "3383", "3157", "3391", "3375", "6379", "6347", "3368", "6392", "4120", "4144", "6345", "4030", "6341", "3077", "3376", "3930"]
}, {
    "City": "Fuzhou",
    "DBTZ": "U",
    "Name": "Changle",
    "Country": "China",
    "IATA/FAA": "FOC",
    "Longitude": "119.663272",
    "ICAO": "ZSFZ",
    "Airport ID": "3385",
    "Latitude": "25.935064",
    "Timezone": "46",
    "DST": "8",
    "destinations": ["3371", "3379", "3373", "2275", "2264", "2276", "3395", "4120", "6380", "3372", "3388", "3364", "4108", "6390", "6355", "3077", "3885", "3370", "3275", "3375", "3393", "4109", "3386", "3400", "3382", "3304", "3394", "3121", "3391", "3316", "4030", "3374", "3390", "3376", "6383", "2268", "3389", "7898", "3406", "4380", "4149", "2370", "3404", "3368", "3369", "3992", "3205"]
}, {
    "City": "Kunming",
    "DBTZ": "U",
    "Name": "Wujiaba",
    "Country": "China",
    "IATA/FAA": "KMG",
    "Longitude": "102.743536",
    "ICAO": "ZPPP",
    "Airport ID": "3382",
    "Latitude": "24.992364",
    "Timezone": "6217",
    "DST": "8",
    "destinations": ["3375", "3393", "3371", "3395", "4055", "3384", "3394", "6376", "3387", "3388", "3390", "4108", "2276", "6403", "3379", "3383", "4149", "6375", "4054", "4120", "3389", "3386", "6379", "3372", "6431", "4033", "3373", "3364", "3406", "6377", "4030", "7862", "3369", "3376", "6366", "9107", "6361", "6352", "8781", "6400", "3391", "6347", "3370", "3304", "2264", "6368", "6397", "3239", "3374", "3368", "6392", "6430", "3077", "6351", "7505", "3385", "7527", "7528", "6394", "6378", "6355", "6356", "3157", "6357", "9025", "3930", "4109", "3316", "3885", "3043", "3932", "3024", "3931", "3076", "3196", "3404", "6434", "2188", "3179", "4085", "6944", "3125", "8845", "4097", "4148", "6399", "3222", "3121", "6401", "8130", "3035", "3120", "3396", "4110"]
}, {
    "City": "Munda",
    "DBTZ": "U",
    "Name": "Munda Airport",
    "Country": "Solomon Islands",
    "IATA/FAA": "MUA",
    "Longitude": "157.263",
    "ICAO": "AGGM",
    "Airport ID": "5411",
    "Latitude": "-8.32797",
    "Timezone": "10",
    "DST": "11",
    "destinations": ["6758", "5412", "4074", "5417"]
}, {
    "City": "Gizo",
    "DBTZ": "U",
    "Name": "Nusatupe Airport",
    "Country": "Solomon Islands",
    "IATA/FAA": "GZO",
    "Longitude": "156.864",
    "ICAO": "AGGN",
    "Airport ID": "5412",
    "Latitude": "-8.09778",
    "Timezone": "13",
    "DST": "11",
    "destinations": ["7617", "6758", "4074", "5411"]
}, {
    "City": "Asmara",
    "DBTZ": "U",
    "Name": "Asmara Intl",
    "Country": "Eritrea",
    "IATA/FAA": "ASM",
    "Longitude": "38.910667",
    "ICAO": "HHAS",
    "Airport ID": "3967",
    "Latitude": "15.291853",
    "Timezone": "7661",
    "DST": "3",
    "destinations": ["3980", "1128", "3977", "2072", "1173", "1175", "4059"]
}, {
    "City": "Debrecen",
    "DBTZ": "E",
    "Name": "Debrecen",
    "Country": "Hungary",
    "IATA/FAA": "DEB",
    "Longitude": "21.615333",
    "ICAO": "LHDC",
    "Airport ID": "1490",
    "Latitude": "47.488917",
    "Timezone": "359",
    "DST": "1",
    "destinations": ["585", "492"]
}, {
    "City": "Pelotas",
    "DBTZ": "S",
    "Name": "Pelotas",
    "Country": "Brazil",
    "IATA/FAA": "PET",
    "Longitude": "-52.327689",
    "ICAO": "SBPK",
    "Airport ID": "2603",
    "Latitude": "-31.718353",
    "Timezone": "59",
    "DST": "-3",
    "destinations": ["2599", "2611"]
}, {
    "City": "Ramata",
    "DBTZ": "U",
    "Name": "Ramata Airport",
    "Country": "Solomon Islands",
    "IATA/FAA": "RBV",
    "Longitude": "157.643",
    "ICAO": "AGRM",
    "Airport ID": "5418",
    "Latitude": "-8.16806",
    "Timezone": "0",
    "DST": "11",
    "destinations": ["4074", "5411"]
}, {
    "City": "Buka Island",
    "DBTZ": "U",
    "Name": "Buka Airport",
    "Country": "Papua New Guinea",
    "IATA/FAA": "BUA",
    "Longitude": "154.673",
    "ICAO": "AYBK",
    "Airport ID": "5419",
    "Latitude": "-5.42232",
    "Timezone": "11",
    "DST": "10",
    "destinations": ["5435", "5979", "5"]
}, {
    "City": "Nanjing",
    "DBTZ": "U",
    "Name": "Lukou",
    "Country": "China",
    "IATA/FAA": "NKG",
    "Longitude": "118.862025",
    "ICAO": "ZSNJ",
    "Airport ID": "3388",
    "Latitude": "31.742042",
    "Timezone": "49",
    "DST": "8",
    "destinations": ["4380", "3393", "3395", "6353", "3400", "3382", "6400", "4030", "3371", "3394", "4033", "4144", "2276", "2268", "3370", "3404", "3385", "340", "4120", "3372", "3121", "3373", "3364", "3374", "3390", "3369", "3379", "3383", "3392", "3077", "6345", "4085", "6386", "3384", "4302", "3399", "6434", "3992", "2279", "2372", "6346", "3375", "6342", "3930", "2264", "6431", "3406", "6347", "3361", "6348", "6430", "6366", "3316"]
}, {
    "City": "Hefei",
    "DBTZ": "U",
    "Name": "Luogang",
    "Country": "China",
    "IATA/FAA": "HFE",
    "Longitude": "117.298436",
    "ICAO": "ZSOF",
    "Airport ID": "3389",
    "Latitude": "31.780019",
    "Timezone": "108",
    "DST": "8",
    "destinations": ["3395", "3382", "4033", "3406", "3370", "3371", "3404", "3930", "3394", "3121", "3364", "3374", "3390", "3383", "4380", "3400", "3369", "3379", "6355", "2276", "2264", "2275", "3375", "3385", "3373", "4302", "4120", "6431", "4030", "3393", "3077", "3372", "6387", "4144", "3392"]
}, {
    "City": "Lorient",
    "DBTZ": "E",
    "Name": "Lann Bihoue",
    "Country": "France",
    "IATA/FAA": "LRT",
    "Longitude": "-3.44",
    "ICAO": "LFRH",
    "Airport ID": "1409",
    "Latitude": "47.760555",
    "Timezone": "160",
    "DST": "1",
    "destinations": ["1386", "1335"]
}, {
    "City": "Deauville",
    "DBTZ": "E",
    "Name": "St Gatien",
    "Country": "France",
    "IATA/FAA": "DOL",
    "Longitude": "0.154306",
    "ICAO": "LFRG",
    "Airport ID": "1408",
    "Latitude": "49.365339",
    "Timezone": "479",
    "DST": "1",
    "destinations": ["503"]
}, {
    "City": "Brest",
    "DBTZ": "E",
    "Name": "Guipavas",
    "Country": "France",
    "IATA/FAA": "BES",
    "Longitude": "-4.418539",
    "ICAO": "LFRB",
    "Airport ID": "1403",
    "Latitude": "48.447911",
    "Timezone": "325",
    "DST": "1",
    "destinations": ["1335", "1354", "1382", "503", "1353", "1386", "495", "1264", "1218", "1324", "1321"]
}, {
    "City": "Dinard",
    "DBTZ": "E",
    "Name": "Pleurtuit",
    "Country": "France",
    "IATA/FAA": "DNR",
    "Longitude": "-2.079958",
    "ICAO": "LFRD",
    "Airport ID": "1405",
    "Latitude": "48.587683",
    "Timezone": "219",
    "DST": "1",
    "destinations": ["523", "548", "498"]
}, {
    "City": "Caldas Novas",
    "DBTZ": "S",
    "Name": "Caldas Novas",
    "Country": "Brazil",
    "IATA/FAA": "CLV",
    "Longitude": "-48.6114",
    "ICAO": "SBCN",
    "Airport ID": "4215",
    "Latitude": "-17.7267",
    "Timezone": "2247",
    "DST": "-3",
    "destinations": ["2578", "2618"]
}, {
    "City": "Palmas",
    "DBTZ": "S",
    "Name": "Palmas",
    "Country": "Brazil",
    "IATA/FAA": "PMW",
    "Longitude": "-48.35278",
    "ICAO": "SBPJ",
    "Airport ID": "4214",
    "Latitude": "-10.241667",
    "Timezone": "774",
    "DST": "-3",
    "destinations": ["7376", "2562", "2531"]
}, {
    "City": "Blackall",
    "DBTZ": "O",
    "Name": "Blackall",
    "Country": "Australia",
    "IATA/FAA": "BKQ",
    "Longitude": "145.428611",
    "ICAO": "YBCK",
    "Airport ID": "4217",
    "Latitude": "-24.427778",
    "Timezone": "928",
    "DST": "10",
    "destinations": ["6289"]
}, {
    "City": "Missoula",
    "DBTZ": "A",
    "Name": "Missoula Intl",
    "Country": "United States",
    "IATA/FAA": "MSO",
    "Longitude": "-114.090556",
    "ICAO": "KMSO",
    "Airport ID": "4216",
    "Latitude": "46.916306",
    "Timezone": "3205",
    "DST": "-7",
    "destinations": ["3720", "3577", "3858", "3536", "6505", "3877", "3751"]
}, {
    "City": "Lyon",
    "DBTZ": "E",
    "Name": "Saint Exupery",
    "Country": "France",
    "IATA/FAA": "LYS",
    "Longitude": "5.090833",
    "ICAO": "LFLL",
    "Airport ID": "1335",
    "Latitude": "45.726387",
    "Timezone": "821",
    "DST": "1",
    "destinations": ["1074", "345", "3941", "1403", "1280", "1538", "1264", "302", "1330", "1412", "1320", "1555", "1563", "687", "1399", "1423", "1359", "1353", "1524", "1418", "1268", "1587", "1274", "1415", "1435", "1551", "507", "1324", "580", "469", "1321", "1382", "1606", "1354", "1386", "1273", "1613", "220", "210", "209", "5552", "235", "221", "231", "6492", "230", "1509", "1075", "293", "286", "287", "1270", "599", "2188", "340", "1230", "1218", "1229", "3998", "1253", "346", "1678", "1206", "1409", "1371", "1701", "1452", "1636", "1070", "1638", "146", "1064", "535", "502", "548", "337", "1706"]
}, {
    "City": "Mariehamn",
    "DBTZ": "E",
    "Name": "Mariehamn",
    "Country": "Finland",
    "IATA/FAA": "MHQ",
    "Longitude": "19.898156",
    "ICAO": "EFMA",
    "Airport ID": "442",
    "Latitude": "60.122203",
    "Timezone": "17",
    "DST": "2",
    "destinations": ["737", "460", "421"]
}, {
    "City": "Lappeenranta",
    "DBTZ": "E",
    "Name": "Lappeenranta",
    "Country": "Finland",
    "IATA/FAA": "LPP",
    "Longitude": "28.144397",
    "ICAO": "EFLP",
    "Airport ID": "441",
    "Latitude": "61.044553",
    "Timezone": "349",
    "DST": "2",
    "destinations": ["1525", "1222", "4198"]
}, {
    "City": "Grand Canyon",
    "DBTZ": "N",
    "Name": "Grand Canyon National Park Airport",
    "Country": "United States",
    "IATA/FAA": "GCN",
    "Longitude": "-112.1469722",
    "ICAO": "KGCN",
    "Airport ID": "4219",
    "Latitude": "35.9523611",
    "Timezone": "6609",
    "DST": "-7",
    "destinations": ["4124"]
}, {
    "City": "Oulu",
    "DBTZ": "E",
    "Name": "Oulu",
    "Country": "Finland",
    "IATA/FAA": "OUL",
    "Longitude": "25.354564",
    "ICAO": "EFOU",
    "Airport ID": "446",
    "Latitude": "64.930061",
    "Timezone": "47",
    "DST": "2",
    "destinations": ["421", "1212", "737"]
}, {
    "City": "Rio Branco",
    "DBTZ": "S",
    "Name": "PlA_cido de Castro",
    "Country": "Brazil",
    "IATA/FAA": "RBR",
    "Longitude": "-67.4836",
    "ICAO": "SBRB",
    "Airport ID": "2609",
    "Latitude": "-9.583",
    "Timezone": "633",
    "DST": "-5",
    "destinations": ["2607", "2531", "2549"]
}, {
    "City": "Yakima",
    "DBTZ": "A",
    "Name": "Yakima Air Terminal McAllister Field",
    "Country": "United States",
    "IATA/FAA": "YKM",
    "Longitude": "-120.544",
    "ICAO": "KYKM",
    "Airport ID": "5779",
    "Latitude": "46.5682",
    "Timezone": "1095",
    "DST": "-8",
    "destinations": ["3577"]
}, {
    "City": "Gothenborg",
    "DBTZ": "E",
    "Name": "Save",
    "Country": "Sweden",
    "IATA/FAA": "GSE",
    "Longitude": "11.870372",
    "ICAO": "ESGP",
    "Airport ID": "691",
    "Latitude": "57.774722",
    "Timezone": "59",
    "DST": "1",
    "destinations": ["746", "1230", "1517", "1212", "1525", "1367", "1553", "535", "1606", "1353", "3998", "1562", "548", "1209", "1739", "1489", "668", "1737", "679"]
}, {
    "City": "Salina",
    "DBTZ": "A",
    "Name": "Salina Municipal Airport",
    "Country": "United States",
    "IATA/FAA": "SLN",
    "Longitude": "-97.6522",
    "ICAO": "KSLN",
    "Airport ID": "5771",
    "Latitude": "38.791",
    "Timezone": "1288",
    "DST": "-6",
    "destinations": ["3458"]
}, {
    "City": "Saranac Lake",
    "DBTZ": "A",
    "Name": "Adirondack Regional Airport",
    "Country": "United States",
    "IATA/FAA": "SLK",
    "Longitude": "-74.2062",
    "ICAO": "KSLK",
    "Airport ID": "5770",
    "Latitude": "44.3853",
    "Timezone": "1663",
    "DST": "-5",
    "destinations": ["3448"]
}, {
    "City": "Tupelo",
    "DBTZ": "A",
    "Name": "Tupelo Regional Airport",
    "Country": "United States",
    "IATA/FAA": "TUP",
    "Longitude": "-88.7699",
    "ICAO": "KTUP",
    "Airport ID": "5773",
    "Latitude": "34.2681",
    "Timezone": "346",
    "DST": "-6",
    "destinations": ["3682", "6130"]
}, {
    "City": "Santa Maria",
    "DBTZ": "A",
    "Name": "Santa Maria Pub Cpt G Allan Hancock Airport",
    "Country": "United States",
    "IATA/FAA": "SMX",
    "Longitude": "-120.457",
    "ICAO": "KSMX",
    "Airport ID": "5772",
    "Latitude": "34.8989",
    "Timezone": "261",
    "DST": "-8",
    "destinations": ["3877", "3484"]
}, {
    "City": "Victoria",
    "DBTZ": "A",
    "Name": "Victoria Regional Airport",
    "Country": "United States",
    "IATA/FAA": "VCT",
    "Longitude": "-96.9185",
    "ICAO": "KVCT",
    "Airport ID": "5775",
    "Latitude": "28.8526",
    "Timezone": "115",
    "DST": "-6",
    "destinations": ["3550"]
}, {
    "City": "Quincy",
    "DBTZ": "A",
    "Name": "Quincy Regional Baldwin Field",
    "Country": "United States",
    "IATA/FAA": "UIN",
    "Longitude": "-91.1946",
    "ICAO": "KUIN",
    "Airport ID": "5774",
    "Latitude": "39.9427",
    "Timezone": "769",
    "DST": "-6",
    "destinations": ["3678"]
}, {
    "City": "Worland",
    "DBTZ": "A",
    "Name": "Worland Municipal Airport",
    "Country": "United States",
    "IATA/FAA": "WRL",
    "Longitude": "-107.951",
    "ICAO": "KWRL",
    "Airport ID": "5777",
    "Latitude": "43.9657",
    "Timezone": "4227",
    "DST": "-7",
    "destinations": ["3804"]
}, {
    "City": "Valdosta",
    "DBTZ": "A",
    "Name": "Valdosta Regional Airport",
    "Country": "United States",
    "IATA/FAA": "VLD",
    "Longitude": "-83.2767",
    "ICAO": "KVLD",
    "Airport ID": "5776",
    "Latitude": "30.7825",
    "Timezone": "203",
    "DST": "-5",
    "destinations": ["3682"]
}, {
    "City": "Santa Isabel do Rio Negro",
    "DBTZ": "S",
    "Name": "Tapuruquara Airport",
    "Country": "Brazil",
    "IATA/FAA": "IRZ",
    "Longitude": "-65.033889",
    "ICAO": "SWTP",
    "Airport ID": "8258",
    "Latitude": "-0.416944",
    "Timezone": "223",
    "DST": "-4",
    "destinations": ["2624"]
}, {
    "City": "Manokwari",
    "DBTZ": "N",
    "Name": "Rendani",
    "Country": "Indonesia",
    "IATA/FAA": "MKW",
    "Longitude": "134.049183",
    "ICAO": "WASR",
    "Airport ID": "3260",
    "Latitude": "-0.891833",
    "Timezone": "23",
    "DST": "9",
    "destinations": ["3244", "3261", "3256", "3257", "3240"]
}, {
    "City": "Dongloe",
    "DBTZ": "E",
    "Name": "Donegal Airport",
    "Country": "Ireland",
    "IATA/FAA": "CFN",
    "Longitude": "-8.341",
    "ICAO": "EIDL",
    "Airport ID": "5577",
    "Latitude": "55.044192",
    "Timezone": "30",
    "DST": "0",
    "destinations": ["599", "534"]
}, {
    "City": "Cambridge Bay",
    "DBTZ": "A",
    "Name": "Cambridge Bay",
    "Country": "Canada",
    "IATA/FAA": "YCB",
    "Longitude": "-105.138333",
    "ICAO": "CYCB",
    "Airport ID": "32",
    "Latitude": "69.108055",
    "Timezone": "90",
    "DST": "-7",
    "destinations": ["37", "183", "196", "69"]
}, {
    "City": "Barra",
    "DBTZ": "E",
    "Name": "Barra Airport",
    "Country": "United Kingdom",
    "IATA/FAA": "BRR",
    "Longitude": "-7.44306",
    "ICAO": "EGPR",
    "Airport ID": "5575",
    "Latitude": "57.0228",
    "Timezone": "5",
    "DST": "0",
    "destinations": ["534"]
}, {
    "City": "Campbell River",
    "DBTZ": "A",
    "Name": "Campbell River",
    "Country": "Canada",
    "IATA/FAA": "YBL",
    "Longitude": "-125.270833",
    "ICAO": "CYBL",
    "Airport ID": "30",
    "Latitude": "49.950832",
    "Timezone": "346",
    "DST": "-8",
    "destinations": ["119", "156", "7275"]
}, {
    "City": "Coppermine",
    "DBTZ": "A",
    "Name": "Kugluktuk",
    "Country": "Canada",
    "IATA/FAA": "YCO",
    "Longitude": "-115.143889",
    "ICAO": "CYCO",
    "Airport ID": "37",
    "Latitude": "67.816667",
    "Timezone": "74",
    "DST": "-7",
    "destinations": ["32", "196", "68"]
}, {
    "City": "Land's End",
    "DBTZ": "E",
    "Name": "Land's End / St. Just Airport",
    "Country": "United Kingdom",
    "IATA/FAA": "LEQ",
    "Longitude": "-5.67056",
    "ICAO": "EGHC",
    "Airport ID": "5572",
    "Latitude": "50.1028",
    "Timezone": "401",
    "DST": "0",
    "destinations": ["4347"]
}, {
    "City": "Westray",
    "DBTZ": "E",
    "Name": "Westray Airport",
    "Country": "United Kingdom",
    "IATA/FAA": "WRY",
    "Longitude": "-2.95",
    "ICAO": "EGEW",
    "Airport ID": "5571",
    "Latitude": "59.3503",
    "Timezone": "29",
    "DST": "0",
    "destinations": ["529", "5567"]
}, {
    "City": "Castlegar",
    "DBTZ": "A",
    "Name": "Castlegar",
    "Country": "Canada",
    "IATA/FAA": "YCG",
    "Longitude": "-117.6325",
    "ICAO": "CYCG",
    "Airport ID": "34",
    "Latitude": "49.296389",
    "Timezone": "1624",
    "DST": "-8",
    "destinations": ["156", "178"]
}, {
    "City": "Dehra Dun",
    "DBTZ": "N",
    "Name": "Dehradun",
    "Country": "India",
    "IATA/FAA": "DED",
    "Longitude": "78.180256",
    "ICAO": "VIDN",
    "Airport ID": "3092",
    "Latitude": "30.189689",
    "Timezone": "1831",
    "DST": "5.5",
    "destinations": ["3093", "3105"]
}, {
    "City": "Delhi",
    "DBTZ": "N",
    "Name": "Indira Gandhi Intl",
    "Country": "India",
    "IATA/FAA": "DEL",
    "Longitude": "77.103088",
    "ICAO": "VIDP",
    "Airport ID": "3093",
    "Latitude": "28.5665",
    "Timezone": "777",
    "DST": "5.5",
    "destinations": ["2048", "2050", "2994", "3042", "3001", "3885", "3131", "2997", "3043", "3135", "3136", "6443", "2188", "6173", "3007", "3141", "3008", "3040", "3100", "3063", "3125", "3105", "3144", "3015", "3061", "3017", "3020", "3112", "3084", "2979", "3081", "2179", "3002", "302", "3076", "3092", "2064", "2241", "6741", "3077", "3089", "3104", "3098", "3097", "3304", "507", "3316", "3023", "1382", "1107", "2057", "469", "3024", "6760", "340", "3930", "1701", "3080", "2996", "2072", "3797", "3009", "3101", "3085", "3103", "2194", "3339", "893", "346", "2279", "3830", "3406", "2082", "3022", "3361", "1613", "3066", "1678", "421", "1555", "2276", "3364", "3370", "2051", "2191", "2983", "2908", "580", "4059", "2176", "2206", "2207", "2170", "2985", "2976", "3494", "4330"]
}, {
    "City": "Santiago",
    "DBTZ": "E",
    "Name": "Santiago",
    "Country": "Spain",
    "IATA/FAA": "SCQ",
    "Longitude": "-8.415144",
    "ICAO": "LEST",
    "Airport ID": "1243",
    "Latitude": "42.896333",
    "Timezone": "1213",
    "DST": "1",
    "destinations": ["599", "1055", "1230", "1212", "1218", "1525", "355", "1054", "1229", "3998", "548", "1253", "1056", "1246", "1216", "302", "1382", "1057", "4053", "1665", "502", "1225"]
}, {
    "City": "Bintulu",
    "DBTZ": "N",
    "Name": "Bintulu",
    "Country": "Malaysia",
    "IATA/FAA": "BTU",
    "Longitude": "113.020472",
    "ICAO": "WBGB",
    "Airport ID": "3262",
    "Latitude": "3.12385",
    "Timezone": "74",
    "DST": "8",
    "destinations": ["3263", "3304", "3269", "3266", "3267"]
}, {
    "City": "Haugesund",
    "DBTZ": "E",
    "Name": "Karmoy",
    "Country": "Norway",
    "IATA/FAA": "HAU",
    "Longitude": "5.208364",
    "ICAO": "ENHD",
    "Airport ID": "645",
    "Latitude": "59.345267",
    "Timezone": "86",
    "DST": "1",
    "destinations": ["644", "1230", "1212", "353", "1562", "548", "1209", "609", "668", "636"]
}, {
    "City": "Jodhpur",
    "DBTZ": "N",
    "Name": "Jodhpur",
    "Country": "India",
    "IATA/FAA": "JDH",
    "Longitude": "73.048869",
    "ICAO": "VIJO",
    "Airport ID": "3097",
    "Latitude": "26.251092",
    "Timezone": "717",
    "DST": "5.5",
    "destinations": ["2997", "3093"]
}, {
    "City": "Parma",
    "DBTZ": "E",
    "Name": "Parma",
    "Country": "Italy",
    "IATA/FAA": "PMF",
    "Longitude": "10.296367",
    "ICAO": "LIMP",
    "Airport ID": "1531",
    "Latitude": "44.824483",
    "Timezone": "161",
    "DST": "1",
    "destinations": ["1517", "1519", "548", "1515"]
}, {
    "City": "Valencia",
    "DBTZ": "E",
    "Name": "Valencia",
    "Country": "Spain",
    "IATA/FAA": "VLC",
    "Longitude": "-0.481625",
    "ICAO": "LEVC",
    "Airport ID": "1246",
    "Latitude": "39.489314",
    "Timezone": "225",
    "DST": "1",
    "destinations": ["3998", "1613", "340", "1382", "1074", "1229", "1555", "1665", "1525", "1538", "1501", "490", "302", "1367", "1553", "304", "599", "523", "355", "1225", "1054", "1231", "478", "1353", "4198", "1636", "1562", "1243", "1251", "548", "1056", "1545", "1539", "580", "585", "591", "1230", "1216", "1386", "1214", "1253", "1057", "345", "346", "1678", "1587", "1638", "4029", "1701", "502", "1418", "1652", "1657", "1194", "1663", "2944", "2945"]
}, {
    "City": "Kirkenes",
    "DBTZ": "E",
    "Name": "Hoybuktmoen",
    "Country": "Norway",
    "IATA/FAA": "KKN",
    "Longitude": "29.891295",
    "ICAO": "ENKR",
    "Airport ID": "649",
    "Latitude": "69.725781",
    "Timezone": "283",
    "DST": "1",
    "destinations": ["644", "632", "653", "663", "5589", "4328"]
}, {
    "City": "Alice Town",
    "DBTZ": "U",
    "Name": "South Bimini",
    "Country": "Bahamas",
    "IATA/FAA": "BIM",
    "Longitude": "-79.264656",
    "ICAO": "MYBS",
    "Airport ID": "1937",
    "Latitude": "25.699881",
    "Timezone": "10",
    "DST": "-5",
    "destinations": ["3533"]
}, {
    "City": "Jaipur",
    "DBTZ": "N",
    "Name": "Jaipur",
    "Country": "India",
    "IATA/FAA": "JAI",
    "Longitude": "75.812161",
    "ICAO": "VIJP",
    "Airport ID": "3098",
    "Latitude": "26.824192",
    "Timezone": "1263",
    "DST": "5.5",
    "destinations": ["2994", "3131", "2997", "3043", "3141", "3093", "2179", "2191", "2194"]
}, {
    "City": "Vientiane",
    "DBTZ": "U",
    "Name": "Wattay Intl",
    "Country": "Laos",
    "IATA/FAA": "VTE",
    "Longitude": "102.563256",
    "ICAO": "VLVT",
    "Airport ID": "3120",
    "Latitude": "17.988322",
    "Timezone": "564",
    "DST": "7",
    "destinations": ["3304", "3885", "3115", "3930", "3382", "3373", "3370", "3199", "4142", "4143", "3116", "3034", "3316", "4081", "3118", "6195"]
}, {
    "City": "Treviso",
    "DBTZ": "E",
    "Name": "Treviso",
    "Country": "Italy",
    "IATA/FAA": "TSF",
    "Longitude": "12.194422",
    "ICAO": "LIPH",
    "Airport ID": "1539",
    "Latitude": "45.6484",
    "Timezone": "59",
    "DST": "1",
    "destinations": ["1074", "1230", "1517", "1218", "1506", "608", "353", "1501", "490", "302", "1489", "1367", "1519", "1474", "304", "1509", "599", "585", "523", "355", "517", "1606", "4198", "699", "1512", "657", "548", "1508", "1515", "1246", "1652", "1735", "1657", "1587", "1737", "1663", "2944", "2945"]
}, {
    "City": "Bologna",
    "DBTZ": "E",
    "Name": "Bologna",
    "Country": "Italy",
    "IATA/FAA": "BLQ",
    "Longitude": "11.288667",
    "ICAO": "LIPE",
    "Airport ID": "1538",
    "Latitude": "44.535444",
    "Timezone": "123",
    "DST": "1",
    "destinations": ["1074", "344", "351", "1735", "1335", "507", "1382", "1509", "1190", "580", "1555", "1655", "1638", "2985", "599", "1055", "1230", "1517", "1212", "1218", "1506", "1264", "1501", "490", "1367", "304", "535", "1225", "669", "1229", "478", "1606", "4198", "1636", "3998", "1512", "657", "548", "1508", "1253", "1056", "1515", "1246", "8414", "680", "585", "1386", "1519", "1520", "340", "346", "1613", "1208", "4317", "737", "609", "302", "1701", "287", "502", "1652", "1657", "1194", "1663"]
}, {
    "City": "Cebu",
    "DBTZ": "N",
    "Name": "Mactan Cebu Intl",
    "Country": "Philippines",
    "IATA/FAA": "CEB",
    "Longitude": "123.979439",
    "ICAO": "RPVM",
    "Airport ID": "4206",
    "Latitude": "10.307542",
    "Timezone": "31",
    "DST": "8",
    "destinations": ["2423", "4200", "6012", "2400", "4140", "2425", "4201", "4090", "6011", "3077", "3930", "2429", "2430", "4203", "2397", "4002", "4204", "2401", "2433", "2372", "3316", "6016", "2422", "4199", "2404", "2279", "5", "3304"]
}, {
    "City": "Macau",
    "DBTZ": "U",
    "Name": "Macau Intl",
    "Country": "Macau",
    "IATA/FAA": "MFM",
    "Longitude": "113.591558",
    "ICAO": "VMMC",
    "Airport ID": "3121",
    "Latitude": "22.149556",
    "Timezone": "20",
    "DST": "8",
    "destinations": ["4140", "2397", "3406", "3304", "2264", "2268", "2276", "2372", "3375", "3393", "3395", "3389", "3386", "3387", "3388", "3373", "3364", "3391", "4144", "3369", "3376", "3383", "3385", "6386", "3931", "3157", "3930", "3382", "3992", "2279", "3885", "3196", "2305", "3199", "4189", "2327", "3316", "6390"]
}, {
    "City": "Erfurt",
    "DBTZ": "E",
    "Name": "Erfurt",
    "Country": "Germany",
    "IATA/FAA": "ERF",
    "Longitude": "10.958106",
    "ICAO": "EDDE",
    "Airport ID": "339",
    "Latitude": "50.979811",
    "Timezone": "1036",
    "DST": "1",
    "destinations": ["1688", "1051", "502", "1054", "3998", "1056"]
}, {
    "City": "Dresden",
    "DBTZ": "E",
    "Name": "Dresden",
    "Country": "Germany",
    "IATA/FAA": "DRS",
    "Longitude": "13.767161",
    "ICAO": "EDDC",
    "Airport ID": "338",
    "Latitude": "51.132767",
    "Timezone": "755",
    "DST": "1",
    "destinations": ["1678", "1460", "344", "345", "350", "1688", "1452", "1130", "7447", "3998", "1472", "1056", "503", "1218", "340", "346", "2985", "4053"]
}, {
    "City": "Berlin",
    "DBTZ": "E",
    "Name": "Schonefeld",
    "Country": "Germany",
    "IATA/FAA": "SXF",
    "Longitude": "13.5225",
    "ICAO": "EDDB",
    "Airport ID": "337",
    "Latitude": "52.380001",
    "Timezone": "157",
    "DST": "1",
    "destinations": ["1590", "4317", "2954", "1055", "1688", "1460", "1715", "1051", "1452", "1458", "1197", "1054", "3998", "1472", "1056", "1226", "737", "1218", "636", "609", "644", "666", "665", "599", "1525", "523", "657", "603", "548", "1128", "2177", "1737", "2948", "2985", "1638", "293", "7447", "1064", "1230", "580", "3941", "490", "302", "4053", "1489", "1519", "535", "1555", "534", "1665", "502", "491", "492", "1335", "1229", "478", "1524", "1561", "1354", "1520", "1386", "1562", "1075", "508", "1486", "1194", "1612", "1551", "16"]
}, {
    "City": "Miri",
    "DBTZ": "N",
    "Name": "Miri",
    "Country": "Malaysia",
    "IATA/FAA": "MYY",
    "Longitude": "113.986806",
    "ICAO": "WBGR",
    "Airport ID": "3266",
    "Latitude": "4.322014",
    "Timezone": "59",
    "DST": "8",
    "destinations": ["3269", "3303", "3263", "3304", "3308", "3316", "3307", "6220", "3262", "3270", "6215", "6769", "3264", "6219", "6217", "3265", "4056", "6216", "3267", "2397"]
}, {
    "City": "Medford",
    "DBTZ": "A",
    "Name": "Rogue Valley Intl Medford",
    "Country": "United States",
    "IATA/FAA": "MFR",
    "Longitude": "-122.8735",
    "ICAO": "KMFR",
    "Airport ID": "4101",
    "Latitude": "42.374228",
    "Timezone": "1335",
    "DST": "-8",
    "destinations": ["3484", "3720", "3577", "3536", "3877", "3751", "3469"]
}, {
    "City": "Idaho Falls",
    "DBTZ": "A",
    "Name": "Idaho Falls Rgnl",
    "Country": "United States",
    "IATA/FAA": "IDA",
    "Longitude": "-112.07075",
    "ICAO": "KIDA",
    "Airport ID": "4100",
    "Latitude": "43.514556",
    "Timezone": "4744",
    "DST": "-7",
    "destinations": ["3536", "6505", "3877", "3751"]
}, {
    "City": "Redmond-Bend",
    "DBTZ": "A",
    "Name": "Roberts Fld",
    "Country": "United States",
    "IATA/FAA": "RDM",
    "Longitude": "-121.149964",
    "ICAO": "KRDM",
    "Airport ID": "4103",
    "Latitude": "44.254066",
    "Timezone": "3077",
    "DST": "-8",
    "destinations": ["3484", "3720", "3577", "3536", "3751", "3469"]
}, {
    "City": "Windhoek",
    "DBTZ": "S",
    "Name": "Windhoek Hosea Kutako International Airport ",
    "Country": "Namibia",
    "IATA/FAA": "WDH",
    "Longitude": "17.4625",
    "ICAO": "FYWV",
    "Airport ID": "4105",
    "Latitude": "-22.486667",
    "Timezone": "5640",
    "DST": "1",
    "destinations": ["813", "951", "959", "907", "797", "248", "340", "1005", "5641", "880", "1004", "6778"]
}, {
    "City": "Vancouver",
    "DBTZ": "A",
    "Name": "Vancouver Coal Harbour",
    "Country": "Canada",
    "IATA/FAA": "CXH",
    "Longitude": "-123.115833",
    "ICAO": "CAQ3",
    "Airport ID": "4107",
    "Latitude": "49.289722",
    "Timezone": "0",
    "DST": "-8",
    "destinations": ["4106"]
}, {
    "City": "Victoria",
    "DBTZ": "A",
    "Name": "Victoria Inner Harbour Airport",
    "Country": "Canada",
    "IATA/FAA": "YWH",
    "Longitude": "-123.3875",
    "ICAO": "CYWH",
    "Airport ID": "4106",
    "Latitude": "48.422778",
    "Timezone": "0",
    "DST": "-8",
    "destinations": ["4107", "6457"]
}, {
    "City": "Changzhou",
    "DBTZ": "U",
    "Name": "Changzhou",
    "Country": "China",
    "IATA/FAA": "CZX",
    "Longitude": "119.711667",
    "ICAO": "ZSCG",
    "Airport ID": "4109",
    "Latitude": "31.941667",
    "Timezone": "39",
    "DST": "8",
    "destinations": ["3393", "3400", "3383", "6355", "3370", "3395", "3364", "4144", "3374", "3371", "3385", "3369", "6341", "3382", "3404", "6353", "3372", "3379"]
}, {
    "City": "Jinan",
    "DBTZ": "U",
    "Name": "Jinan",
    "Country": "China",
    "IATA/FAA": "TNA",
    "Longitude": "117.215992",
    "ICAO": "ZSJN",
    "Airport ID": "4108",
    "Latitude": "36.857214",
    "Timezone": "76",
    "DST": "8",
    "destinations": ["4380", "3393", "3395", "3404", "3400", "3382", "4030", "3379", "2264", "2276", "3370", "3375", "3371", "3385", "6345", "3386", "3930", "4085", "3384", "3992", "3394", "6431", "3364", "3391", "4144", "3374", "3369", "3399", "6392", "3376", "3383", "3392", "3372", "6348", "4033", "3077", "3406", "3885", "2278", "2268", "6391"]
}, {
    "City": "Dundee",
    "DBTZ": "E",
    "Name": "Dundee",
    "Country": "United Kingdom",
    "IATA/FAA": "DND",
    "Longitude": "-3.025833",
    "ICAO": "EGPN",
    "Airport ID": "540",
    "Latitude": "56.452499",
    "Timezone": "17",
    "DST": "0",
    "destinations": ["548"]
}, {
    "City": "Yogyakarta",
    "DBTZ": "N",
    "Name": "Adi Sutjipto",
    "Country": "Indonesia",
    "IATA/FAA": "JOG",
    "Longitude": "110.431758",
    "ICAO": "WARJ",
    "Airport ID": "3898",
    "Latitude": "-7.788181",
    "Timezone": "350",
    "DST": "7",
    "destinations": ["3304", "3919", "3275", "3940", "3316", "3240", "3908", "3896", "3903", "8401", "3273", "3928", "3289"]
}, {
    "City": "Jijiga",
    "DBTZ": "U",
    "Name": "Jijiga Airport",
    "Country": "Ethiopia",
    "IATA/FAA": "JIJ",
    "Longitude": "42.7875",
    "ICAO": "HAJJ",
    "Airport ID": "7417",
    "Latitude": "9.359722",
    "Timezone": "2000",
    "DST": "3",
    "destinations": ["1107", "1112", "5680"]
}, {
    "City": "Kendari",
    "DBTZ": "N",
    "Name": "Wolter Monginsidi",
    "Country": "Indonesia",
    "IATA/FAA": "KDI",
    "Longitude": "122.418231",
    "ICAO": "WAWW",
    "Airport ID": "3890",
    "Latitude": "-4.081608",
    "Timezone": "538",
    "DST": "8",
    "destinations": ["3240", "3275"]
}, {
    "City": "Bandung",
    "DBTZ": "N",
    "Name": "Husein Sastranegara",
    "Country": "Indonesia",
    "IATA/FAA": "BDO",
    "Longitude": "107.576294",
    "ICAO": "WICC",
    "Airport ID": "3896",
    "Latitude": "-6.900625",
    "Timezone": "2436",
    "DST": "7",
    "destinations": ["3304", "3940", "3316", "3928", "3908", "3903", "3898", "3273", "3303"]
}, {
    "City": "Malang",
    "DBTZ": "N",
    "Name": "Abdul Rachman Saleh",
    "Country": "Indonesia",
    "IATA/FAA": "MLG",
    "Longitude": "112.714514",
    "ICAO": "WARA",
    "Airport ID": "3894",
    "Latitude": "-7.926556",
    "Timezone": "1726",
    "DST": "7",
    "destinations": ["3275", "3940"]
}, {
    "City": "Wuhan",
    "DBTZ": "U",
    "Name": "Tianhe",
    "Country": "China",
    "IATA/FAA": "WUH",
    "Longitude": "114.2081",
    "ICAO": "ZHHH",
    "Airport ID": "3376",
    "Latitude": "30.783758",
    "Timezone": "113",
    "DST": "8",
    "destinations": ["3393", "3395", "3400", "6386", "3373", "5475", "3404", "3382", "4033", "3390", "3370", "1382", "6358", "2372", "4380", "3931", "6345", "3077", "4085", "3394", "3121", "3364", "4144", "3374", "4108", "6390", "3383", "6366", "3392", "2276", "6346", "3885", "3385", "4120", "3386", "3930", "2264", "3372", "6431", "3387", "3406", "3391", "4302", "4030", "3368", "3369", "3399", "6392", "3379", "6393", "6355", "6384", "3157", "2275", "6434", "6385", "8876", "3316", "6427", "6343", "3384", "6383", "6356", "6360", "6430"]
}, {
    "City": "Godthaab",
    "DBTZ": "E",
    "Name": "Nuuk",
    "Country": "Greenland",
    "IATA/FAA": "GOH",
    "Longitude": "-51.678064",
    "ICAO": "BGGH",
    "Airport ID": "8",
    "Latitude": "64.190922",
    "Timezone": "283",
    "DST": "-3",
    "destinations": ["3995", "5440", "5447", "5443", "16", "4248", "9", "7", "18"]
}, {
    "City": "Bangor",
    "DBTZ": "A",
    "Name": "Bangor Intl",
    "Country": "United States",
    "IATA/FAA": "BGR",
    "Longitude": "-68.828139",
    "ICAO": "KBGR",
    "Airport ID": "3463",
    "Latitude": "44.807444",
    "Timezone": "192",
    "DST": "-5",
    "destinations": ["3520", "3752", "3645", "3697", "3617", "4167"]
}, {
    "City": "London",
    "DBTZ": "E",
    "Name": "Stansted",
    "Country": "United Kingdom",
    "IATA/FAA": "STN",
    "Longitude": "0.235",
    "ICAO": "EGSS",
    "Airport ID": "548",
    "Latitude": "51.885",
    "Timezone": "348",
    "DST": "0",
    "destinations": ["344", "352", "350", "1735", "345", "540", "607", "1055", "1230", "1517", "1212", "4309", "3941", "1218", "1506", "1525", "1280", "608", "1538", "1264", "353", "1501", "1588", "4053", "1745", "1489", "1278", "5591", "1352", "1460", "1474", "1553", "9327", "1405", "373", "599", "1455", "1265", "585", "1626", "1066", "4166", "3986", "1051", "668", "1528", "1222", "691", "645", "355", "1225", "1458", "601", "669", "671", "3956", "1276", "468", "1213", "348", "1270", "1638", "1611", "1054", "6493", "8832", "1229", "1227", "1606", "708", "1353", "600", "4198", "347", "699", "1636", "596", "1202", "1582", "1657", "1193", "1567", "1198", "1356", "1268", "1531", "3998", "1512", "674", "1587", "1562", "1505", "1203", "1075", "1072", "1289", "1236", "1472", "3953", "657", "675", "1243", "1251", "726", "1486", "603", "1508", "1253", "1435", "337", "1612", "676", "1056", "1741", "415", "1438", "458", "664", "1526", "1545", "1539", "1376", "1246", "3959", "733", "8414", "680", "1226", "1209", "1252", "498", "499", "1706", "4317", "1715", "1231", "4057", "580", "465", "1216", "4315", "1519", "609", "1200", "535", "534", "1569", "1335", "346", "1561", "1354", "1214", "1194"]
}, {
    "City": "Turbat",
    "DBTZ": "N",
    "Name": "Turbat International Airport",
    "Country": "Pakistan",
    "IATA/FAA": "TUK",
    "Longitude": "63.030167",
    "ICAO": "OPTU",
    "Airport ID": "5951",
    "Latitude": "25.986369",
    "Timezone": "498",
    "DST": "5",
    "destinations": ["5941", "2203", "2206", "2194", "2191", "2221"]
}, {
    "City": "Sulaymaniyah",
    "DBTZ": "U",
    "Name": "Sulaymaniyah International Airport",
    "Country": "Iraq",
    "IATA/FAA": "ISU",
    "Longitude": "45.3147",
    "ICAO": "ORSU",
    "Airport ID": "5952",
    "Latitude": "35.5608",
    "Timezone": "2494",
    "DST": "3",
    "destinations": ["4330", "2188", "1701", "2241", "2170", "737", "345", "346"]
}, {
    "City": "Bahia Pina",
    "DBTZ": "U",
    "Name": "Bahia Pina Airport",
    "Country": "Panama",
    "IATA/FAA": "BFQ",
    "Longitude": "-78.2",
    "ICAO": "",
    "Airport ID": "5955",
    "Latitude": "7.583",
    "Timezone": "14",
    "DST": "-5",
    "destinations": ["5840"]
}, {
    "City": "Sayun Intl",
    "DBTZ": "U",
    "Name": "Sayun International Airport",
    "Country": "Yemen",
    "IATA/FAA": "GXF",
    "Longitude": "48.7883",
    "ICAO": "OYSY",
    "Airport ID": "5954",
    "Latitude": "15.966111",
    "Timezone": "2097",
    "DST": "3",
    "destinations": ["3977", "3980", "2179", "2072"]
}, {
    "City": "Contadora Island",
    "DBTZ": "U",
    "Name": "Contadora Airport",
    "Country": "Panama",
    "IATA/FAA": "OTD",
    "Longitude": "-79.0347",
    "ICAO": "",
    "Airport ID": "5957",
    "Latitude": "8.62876",
    "Timezone": "14",
    "DST": "-5",
    "destinations": ["6783"]
}, {
    "City": "Adak Island",
    "DBTZ": "A",
    "Name": "Adak Airport",
    "Country": "United States",
    "IATA/FAA": "ADK",
    "Longitude": "-176.646",
    "ICAO": "PADK",
    "Airport ID": "5959",
    "Latitude": "51.878",
    "Timezone": "18",
    "DST": "-9",
    "destinations": ["3774"]
}, {
    "City": "Boca de Sabalo",
    "DBTZ": "U",
    "Name": "Sambu Airport",
    "Country": "Panama",
    "IATA/FAA": "SAX",
    "Longitude": "-78.2",
    "ICAO": "",
    "Airport ID": "5958",
    "Latitude": "8.017",
    "Timezone": "32",
    "DST": "-5",
    "destinations": ["8206"]
}, {
    "City": "Funafuti",
    "DBTZ": "U",
    "Name": "Funafuti International",
    "Country": "Tuvalu",
    "IATA/FAA": "FUN",
    "Longitude": "179.196389",
    "ICAO": "NGFU",
    "Airport ID": "4077",
    "Latitude": "-8.525",
    "Timezone": "9",
    "DST": "12",
    "destinations": ["1961"]
}, {
    "City": "Phalaborwa",
    "DBTZ": "U",
    "Name": "Phalaborwa",
    "Country": "South Africa",
    "IATA/FAA": "PHW",
    "Longitude": "31.15539",
    "ICAO": "FAPH",
    "Airport ID": "840",
    "Latitude": "-23.937166",
    "Timezone": "1432",
    "DST": "2",
    "destinations": ["813"]
}, {
    "City": "Moline",
    "DBTZ": "A",
    "Name": "Quad City Intl",
    "Country": "United States",
    "IATA/FAA": "MLI",
    "Longitude": "-90.507539",
    "ICAO": "KMLI",
    "Airport ID": "4072",
    "Latitude": "41.448528",
    "Timezone": "590",
    "DST": "-6",
    "destinations": ["3670", "3830", "3682", "3645", "3858", "6505", "3877", "3617", "4167", "3751"]
}, {
    "City": "Polokwane",
    "DBTZ": "U",
    "Name": "Polokwane International",
    "Country": "South Africa",
    "IATA/FAA": "PTG",
    "Longitude": "29.484422",
    "ICAO": "FAPI",
    "Airport ID": "841",
    "Latitude": "-23.926089",
    "Timezone": "4354",
    "DST": "2",
    "destinations": ["813"]
}, {
    "City": "Yerevan",
    "DBTZ": "E",
    "Name": "Zvartnots",
    "Country": "Armenia",
    "IATA/FAA": "EVN",
    "Longitude": "44.395881",
    "ICAO": "UDYZ",
    "Airport ID": "3964",
    "Latitude": "40.147275",
    "Timezone": "2838",
    "DST": "4",
    "destinations": ["4363", "2967", "1382", "1555", "2954", "4367", "4330", "2188", "2191", "2958", "679", "2177", "1587", "1613", "2939", "2965", "3941", "4274", "4118", "2948", "2975", "2988", "2968", "4029", "4078", "2960", "2962", "2964", "2985", "4374", "4111", "2987", "2941"]
}, {
    "City": "Gyumri",
    "DBTZ": "E",
    "Name": "Gyumri",
    "Country": "Armenia",
    "IATA/FAA": "LWN",
    "Longitude": "43.859342",
    "ICAO": "UDSG",
    "Airport ID": "3965",
    "Latitude": "40.750369",
    "Timezone": "5000",
    "DST": "4",
    "destinations": ["4029"]
}, {
    "City": "Corvo",
    "DBTZ": "E",
    "Name": "Corvo Airport",
    "Country": "Portugal",
    "IATA/FAA": "CVU",
    "Longitude": "-31.1136",
    "ICAO": "LPCR",
    "Airport ID": "5793",
    "Latitude": "39.6715",
    "Timezone": "0",
    "DST": "-1",
    "destinations": ["1625", "1628"]
}, {
    "City": "Agri",
    "DBTZ": "E",
    "Name": "Agri Airport",
    "Country": "Turkey",
    "IATA/FAA": "AJI",
    "Longitude": "43.0271",
    "ICAO": "LTCO",
    "Airport ID": "5799",
    "Latitude": "39.6546",
    "Timezone": "5462",
    "DST": "2",
    "destinations": ["1682", "1701"]
}, {
    "City": "Kahramanmaras",
    "DBTZ": "E",
    "Name": "Kahramanmaras Airport",
    "Country": "Turkey",
    "IATA/FAA": "KCM",
    "Longitude": "36.9534",
    "ICAO": "LTCN",
    "Airport ID": "5798",
    "Latitude": "37.539",
    "Timezone": "1723",
    "DST": "2",
    "destinations": ["4317", "1682", "1701"]
}, {
    "City": "Puerto Cabello",
    "DBTZ": "U",
    "Name": "General Bartolome Salom Intl",
    "Country": "Venezuela",
    "IATA/FAA": "PBL",
    "Longitude": "-68.073025",
    "ICAO": "SVPC",
    "Airport ID": "2854",
    "Latitude": "10.4805",
    "Timezone": "32",
    "DST": "-4.5",
    "destinations": ["2848", "2850"]
}, {
    "City": "Tucson",
    "DBTZ": "N",
    "Name": "Tucson Intl",
    "Country": "United States",
    "IATA/FAA": "TUS",
    "Longitude": "-110.941028",
    "ICAO": "KTUS",
    "Airport ID": "3636",
    "Latitude": "32.116083",
    "Timezone": "2643",
    "DST": "-7",
    "destinations": ["3670", "3484", "3830", "3462", "3682", "3720", "3577", "3858", "3536", "3877", "3747", "3748", "3751", "3550", "3469", "3731"]
}, {
    "City": "Newport News",
    "DBTZ": "A",
    "Name": "Newport News Williamsburg Intl",
    "Country": "United States",
    "IATA/FAA": "PHF",
    "Longitude": "-76.492989",
    "ICAO": "KPHF",
    "Airport ID": "3633",
    "Latitude": "37.131894",
    "Timezone": "43",
    "DST": "-5",
    "destinations": ["3876", "3752", "3682", "3751", "4167"]
}, {
    "City": "Niagara Falls",
    "DBTZ": "A",
    "Name": "Niagara Falls Intl",
    "Country": "United States",
    "IATA/FAA": "IAG",
    "Longitude": "-78.946194",
    "ICAO": "KIAG",
    "Airport ID": "3630",
    "Latitude": "43.107333",
    "Timezone": "589",
    "DST": "-5",
    "destinations": ["7056", "3617", "4167", "3533", "3515"]
}, {
    "City": "Araguaina",
    "DBTZ": "S",
    "Name": "Araguaina Airport",
    "Country": "Brazil",
    "IATA/FAA": "AUX",
    "Longitude": "-48.240833",
    "ICAO": "SWGN",
    "Airport ID": "7376",
    "Latitude": "-7.228333",
    "Timezone": "771",
    "DST": "-3",
    "destinations": ["4214", "2531", "2584"]
}, {
    "City": "Porto Santo",
    "DBTZ": "E",
    "Name": "Porto Santo",
    "Country": "Portugal",
    "IATA/FAA": "PXO",
    "Longitude": "-16.349975",
    "ICAO": "LPPS",
    "Airport ID": "1637",
    "Latitude": "33.073386",
    "Timezone": "341",
    "DST": "0",
    "destinations": ["1638"]
}, {
    "City": "San Francisco",
    "DBTZ": "A",
    "Name": "San Francisco Intl",
    "Country": "United States",
    "IATA/FAA": "SFO",
    "Longitude": "-122.374889",
    "ICAO": "KSFO",
    "Airport ID": "3469",
    "Latitude": "37.618972",
    "Timezone": "13",
    "DST": "-8",
    "destinations": ["3876", "3670", "3077", "2359", "3797", "3484", "507", "3576", "3830", "3720", "3752", "3462", "3839", "1836", "3577", "49", "146", "156", "178", "184", "193", "3682", "1382", "3728", "3930", "1815", "1804", "1824", "1821", "1840", "1892", "3673", "3448", "2188", "3533", "3582", "2276", "3364", "3406", "580", "3488", "3645", "3858", "3536", "599", "3751", "3747", "3717", "3611", "3877", "340", "346", "1678", "3992", "2279", "2006", "3361", "2397", "609", "4019", "4384", "3558", "3495", "3644", "3849", "5727", "3710", "3486", "3819", "1852", "3520", "4099", "3494", "3687", "3714", "3550", "3585", "3514", "3602", "5750", "3458", "3878", "4101", "3816", "3948", "3861", "3456", "3863", "3734", "5757", "3570", "6133", "4098", "4103", "3626", "3807", "3731", "3621", "3949", "5768", "3817", "3867", "3678", "3636"]
}, {
    "City": "Akita",
    "DBTZ": "U",
    "Name": "Akita",
    "Country": "Japan",
    "IATA/FAA": "AXT",
    "Longitude": "140.218611",
    "ICAO": "RJSK",
    "Airport ID": "2345",
    "Latitude": "39.615556",
    "Timezone": "313",
    "DST": "9",
    "destinations": ["2287", "2359", "2334", "3930", "3942"]
}, {
    "City": "Hanamaki",
    "DBTZ": "U",
    "Name": "Hanamaki",
    "Country": "Japan",
    "IATA/FAA": "HNA",
    "Longitude": "141.135278",
    "ICAO": "RJSI",
    "Airport ID": "2344",
    "Latitude": "39.428611",
    "Timezone": "297",
    "DST": "9",
    "destinations": ["5997", "2287", "2305", "2334"]
}, {
    "City": "Sendai",
    "DBTZ": "U",
    "Name": "Sendai",
    "Country": "Japan",
    "IATA/FAA": "SDJ",
    "Longitude": "140.916944",
    "ICAO": "RJSS",
    "Airport ID": "2347",
    "Latitude": "38.139722",
    "Timezone": "15",
    "DST": "9",
    "destinations": ["2287", "2305", "3943", "2276", "3406", "2326", "2334", "2322", "3942", "3992", "2246", "3930", "2279", "2384"]
}, {
    "City": "Misawa",
    "DBTZ": "U",
    "Name": "Misawa Ab",
    "Country": "Japan",
    "IATA/FAA": "MSJ",
    "Longitude": "141.368364",
    "ICAO": "RJSM",
    "Airport ID": "2346",
    "Latitude": "40.703222",
    "Timezone": "119",
    "DST": "9",
    "destinations": ["2359", "2334"]
}, {
    "City": "Yamagata",
    "DBTZ": "U",
    "Name": "Yamagata",
    "Country": "Japan",
    "IATA/FAA": "GAJ",
    "Longitude": "140.371331",
    "ICAO": "RJSC",
    "Airport ID": "2341",
    "Latitude": "38.411894",
    "Timezone": "353",
    "DST": "9",
    "destinations": ["5997", "2359", "2334"]
}, {
    "City": "Aomori",
    "DBTZ": "U",
    "Name": "Aomori",
    "Country": "Japan",
    "IATA/FAA": "AOJ",
    "Longitude": "140.690833",
    "ICAO": "RJSA",
    "Airport ID": "2340",
    "Latitude": "40.734722",
    "Timezone": "664",
    "DST": "9",
    "destinations": ["5997", "2287", "2359", "2334", "3930"]
}, {
    "City": "Uzhgorod",
    "DBTZ": "E",
    "Name": "Uzhhorod International Airport",
    "Country": "Ukraine",
    "IATA/FAA": "UDJ",
    "Longitude": "22.263356",
    "ICAO": "UKLU",
    "Airport ID": "6109",
    "Latitude": "48.634278",
    "Timezone": "383",
    "DST": "2",
    "destinations": ["2944"]
}, {
    "City": "Ivano-Frankivsk",
    "DBTZ": "E",
    "Name": "Ivano Frankivsk International Airport",
    "Country": "Ukraine",
    "IATA/FAA": "IFO",
    "Longitude": "24.686111",
    "ICAO": "UKLI",
    "Airport ID": "6106",
    "Latitude": "48.884167",
    "Timezone": "919",
    "DST": "2",
    "destinations": ["2939"]
}, {
    "City": "Kharkov",
    "DBTZ": "E",
    "Name": "Osnova International Airport",
    "Country": "Ukraine",
    "IATA/FAA": "HRK",
    "Longitude": "36.289986",
    "ICAO": "UKHH",
    "Airport ID": "6105",
    "Latitude": "49.924786",
    "Timezone": "508",
    "DST": "2",
    "destinations": ["2188", "2191", "1613", "4317", "2939", "1197", "2985", "679", "3972"]
}, {
    "City": "Zaporozhye",
    "DBTZ": "E",
    "Name": "Zaporizhzhia International Airport",
    "Country": "Ukraine",
    "IATA/FAA": "OZH",
    "Longitude": "35.3157",
    "ICAO": "UKDE",
    "Airport ID": "6103",
    "Latitude": "47.867",
    "Timezone": "373",
    "DST": "2",
    "destinations": ["2944", "2988"]
}, {
    "City": "San Angelo",
    "DBTZ": "A",
    "Name": "San Angelo Rgnl Mathis Fld",
    "Country": "United States",
    "IATA/FAA": "SJT",
    "Longitude": "-100.496306",
    "ICAO": "KSJT",
    "Airport ID": "3708",
    "Latitude": "31.35775",
    "Timezone": "1919",
    "DST": "-6",
    "destinations": ["3670"]
}, {
    "City": "Quepos",
    "DBTZ": "U",
    "Name": "La Managua",
    "Country": "Costa Rica",
    "IATA/FAA": "XQP",
    "Longitude": "-84.129772",
    "ICAO": "MRQP",
    "Airport ID": "1889",
    "Latitude": "9.443164",
    "Timezone": "85",
    "DST": "-6",
    "destinations": ["1887", "1885"]
}, {
    "City": "Palmar Sur",
    "DBTZ": "U",
    "Name": "Palmar Sur",
    "Country": "Costa Rica",
    "IATA/FAA": "PMZ",
    "Longitude": "-83.468583",
    "ICAO": "MRPM",
    "Airport ID": "1887",
    "Latitude": "8.951025",
    "Timezone": "49",
    "DST": "-6",
    "destinations": ["1885"]
}, {
    "City": "Waco",
    "DBTZ": "A",
    "Name": "Waco Rgnl",
    "Country": "United States",
    "IATA/FAA": "ACT",
    "Longitude": "-97.230519",
    "ICAO": "KACT",
    "Airport ID": "3700",
    "Latitude": "31.611289",
    "Timezone": "516",
    "DST": "-6",
    "destinations": ["3670"]
}, {
    "City": "Augusta",
    "DBTZ": "A",
    "Name": "Augusta State",
    "Country": "United States",
    "IATA/FAA": "AUG",
    "Longitude": "-69.797317",
    "ICAO": "KAUG",
    "Airport ID": "3701",
    "Latitude": "44.320647",
    "Timezone": "352",
    "DST": "-5",
    "destinations": ["3448"]
}, {
    "City": "Liberia",
    "DBTZ": "U",
    "Name": "Daniel Oduber Quiros Intl",
    "Country": "Costa Rica",
    "IATA/FAA": "LIR",
    "Longitude": "-85.544408",
    "ICAO": "MRLB",
    "Airport ID": "1881",
    "Latitude": "10.593289",
    "Timezone": "270",
    "DST": "-6",
    "destinations": ["1885", "5848", "5844", "3876", "3670", "3576", "193", "3682", "1892", "3797", "1871", "3484", "3751", "3494", "3550"]
}, {
    "City": "Molokai",
    "DBTZ": "N",
    "Name": "Molokai",
    "Country": "United States",
    "IATA/FAA": "MKK",
    "Longitude": "-157.096256",
    "ICAO": "PHMK",
    "Airport ID": "3705",
    "Latitude": "21.152886",
    "Timezone": "454",
    "DST": "-10",
    "destinations": ["3728", "3456"]
}, {
    "City": "Rasht",
    "DBTZ": "E",
    "Name": "Rasht",
    "Country": "Iran",
    "IATA/FAA": "RAS",
    "Longitude": "49.605817",
    "ICAO": "OIGG",
    "Airport ID": "2123",
    "Latitude": "37.325314",
    "Timezone": "-40",
    "DST": "3.5",
    "destinations": ["4331", "2134", "7546", "2157", "2131", "2104", "2074"]
}, {
    "City": "Huambo",
    "DBTZ": "N",
    "Name": "Huambo",
    "Country": "Angola",
    "IATA/FAA": "NOV",
    "Longitude": "15.760547",
    "ICAO": "FNHU",
    "Airport ID": "948",
    "Latitude": "-12.808878",
    "Timezone": "5587",
    "DST": "1",
    "destinations": ["951", "953", "5632"]
}, {
    "City": "Kuito",
    "DBTZ": "N",
    "Name": "Kuito",
    "Country": "Angola",
    "IATA/FAA": "SVP",
    "Longitude": "16.947414",
    "ICAO": "FNKU",
    "Airport ID": "949",
    "Latitude": "-12.404633",
    "Timezone": "5618",
    "DST": "1",
    "destinations": ["951", "5632"]
}, {
    "City": "Cabinda",
    "DBTZ": "N",
    "Name": "Cabinda",
    "Country": "Angola",
    "IATA/FAA": "CAB",
    "Longitude": "12.188353",
    "ICAO": "FNCA",
    "Airport ID": "946",
    "Latitude": "-5.596992",
    "Timezone": "66",
    "DST": "1",
    "destinations": ["951", "958"]
}, {
    "City": "M'banza-congo",
    "DBTZ": "N",
    "Name": "Mbanza Congo",
    "Country": "Angola",
    "IATA/FAA": "SSY",
    "Longitude": "14.247025",
    "ICAO": "FNBC",
    "Airport ID": "944",
    "Latitude": "-6.269897",
    "Timezone": "1860",
    "DST": "1",
    "destinations": ["951"]
}, {
    "City": "Toliara",
    "DBTZ": "U",
    "Name": "Toliara",
    "Country": "Madagascar",
    "IATA/FAA": "TLE",
    "Longitude": "43.728453",
    "ICAO": "FMST",
    "Airport ID": "943",
    "Latitude": "-23.383369",
    "Timezone": "29",
    "DST": "3",
    "destinations": ["937", "922", "918"]
}, {
    "City": "Vitoria Da Conquista",
    "DBTZ": "S",
    "Name": "Vitoria da Conquista Airport",
    "Country": "Brazil",
    "IATA/FAA": "VDC",
    "Longitude": "-40.863106",
    "ICAO": "SBQV",
    "Airport ID": "6039",
    "Latitude": "-14.862761",
    "Timezone": "2998",
    "DST": "-3",
    "destinations": ["2564", "2621", "2537", "2528"]
}, {
    "City": "Marilia",
    "DBTZ": "S",
    "Name": "Marilia Airport",
    "Country": "Brazil",
    "IATA/FAA": "MII",
    "Longitude": "-49.9264",
    "ICAO": "SBML",
    "Airport ID": "6038",
    "Latitude": "-22.196892",
    "Timezone": "2122",
    "DST": "-3",
    "destinations": ["8238", "2578"]
}, {
    "City": "Macae",
    "DBTZ": "S",
    "Name": "Macae Airport",
    "Country": "Brazil",
    "IATA/FAA": "MEA",
    "Longitude": "-41.766",
    "ICAO": "SBME",
    "Airport ID": "6037",
    "Latitude": "-22.343",
    "Timezone": "8",
    "DST": "-3",
    "destinations": ["2612", "2578", "2543"]
}, {
    "City": "Lencois",
    "DBTZ": "S",
    "Name": "Chapada Diamantina Airport",
    "Country": "Brazil",
    "IATA/FAA": "LEC",
    "Longitude": "-41.277",
    "ICAO": "SBLE",
    "Airport ID": "6036",
    "Latitude": "-12.4823",
    "Timezone": "1676",
    "DST": "-3",
    "destinations": ["2621"]
}, {
    "City": "Elat",
    "DBTZ": "U",
    "Name": "Eilat",
    "Country": "Israel",
    "IATA/FAA": "ETH",
    "Longitude": "34.960081",
    "ICAO": "LLET",
    "Airport ID": "1594",
    "Latitude": "29.561281",
    "Timezone": "42",
    "DST": "2",
    "destinations": ["1605", "1590", "1596"]
}, {
    "City": "Juazeiro Do Norte",
    "DBTZ": "S",
    "Name": "Orlando Bezerra de Menezes Airport",
    "Country": "Brazil",
    "IATA/FAA": "JDO",
    "Longitude": "-39.2701",
    "ICAO": "SBJU",
    "Airport ID": "6034",
    "Latitude": "-7.218958",
    "Timezone": "1392",
    "DST": "-3",
    "destinations": ["2578", "2610", "2531", "2559", "2564"]
}, {
    "City": "Osorno",
    "DBTZ": "S",
    "Name": "Canal Bajo Carlos Hott Siebert",
    "Country": "Chile",
    "IATA/FAA": "ZOS",
    "Longitude": "-73.061042",
    "ICAO": "SCJO",
    "Airport ID": "2658",
    "Latitude": "-40.611208",
    "Timezone": "187",
    "DST": "-4",
    "destinations": ["2667"]
}, {
    "City": "Antofagasta",
    "DBTZ": "S",
    "Name": "Cerro Moreno Intl",
    "Country": "Chile",
    "IATA/FAA": "ANF",
    "Longitude": "-70.4451",
    "ICAO": "SCFA",
    "Airport ID": "2651",
    "Latitude": "-23.444478",
    "Timezone": "455",
    "DST": "-4",
    "destinations": ["2649", "2789", "2650", "2645", "2662"]
}, {
    "City": "Santiago",
    "DBTZ": "S",
    "Name": "Arturo Merino Benitez Intl",
    "Country": "Chile",
    "IATA/FAA": "SCL",
    "Longitude": "-70.785803",
    "ICAO": "SCEL",
    "Airport ID": "2650",
    "Latitude": "-33.392975",
    "Timezone": "1555",
    "DST": "-4",
    "destinations": ["2651", "2816", "3670", "3797", "2789", "3576", "2665", "3988", "193", "1382", "1824", "2442", "2709", "1871", "3682", "2656", "2645", "4312", "2564", "2649", "2662", "2664", "1229", "2560", "2006", "2641", "2699", "2642", "2851", "2443", "1852", "2673", "2657", "2452", "1760", "2647", "3361", "2688", "2658", "2621"]
}, {
    "City": "Easter Island",
    "DBTZ": "S",
    "Name": "Mataveri Intl",
    "Country": "Chile",
    "IATA/FAA": "IPC",
    "Longitude": "-109.421831",
    "ICAO": "SCIP",
    "Airport ID": "2657",
    "Latitude": "-27.164792",
    "Timezone": "227",
    "DST": "-6",
    "destinations": ["4075", "2650"]
}, {
    "City": "Concepcion",
    "DBTZ": "S",
    "Name": "Carriel Sur Intl",
    "Country": "Chile",
    "IATA/FAA": "CCP",
    "Longitude": "-73.063106",
    "ICAO": "SCIE",
    "Airport ID": "2656",
    "Latitude": "-36.77265",
    "Timezone": "26",
    "DST": "-4",
    "destinations": ["2650", "2665", "2667", "2664"]
}, {
    "City": "Sudbury",
    "DBTZ": "A",
    "Name": "Sudbury",
    "Country": "Canada",
    "IATA/FAA": "YSB",
    "Longitude": "-80.798889",
    "ICAO": "CYSB",
    "Airport ID": "133",
    "Latitude": "46.625",
    "Timezone": "1141",
    "DST": "-5",
    "destinations": ["193", "21", "121", "143", "177", "144"]
}, {
    "City": "Bettles",
    "DBTZ": "A",
    "Name": "Bettles",
    "Country": "United States",
    "IATA/FAA": "BTT",
    "Longitude": "-151.529056",
    "ICAO": "PABT",
    "Airport ID": "3417",
    "Latitude": "66.913944",
    "Timezone": "644",
    "DST": "-9",
    "destinations": ["6712", "7119", "6839", "3832"]
}, {
    "City": "Point Lay",
    "DBTZ": "A",
    "Name": "Point Lay Lrrs",
    "Country": "United States",
    "IATA/FAA": "PIZ",
    "Longitude": "-163.005342",
    "ICAO": "PPIZ",
    "Airport ID": "3414",
    "Latitude": "69.732875",
    "Timezone": "25",
    "DST": "-9",
    "destinations": ["3571", "6721"]
}, {
    "City": "Hilo",
    "DBTZ": "N",
    "Name": "Hilo Intl",
    "Country": "United States",
    "IATA/FAA": "ITO",
    "Longitude": "-155.048469",
    "ICAO": "PHTO",
    "Airport ID": "3415",
    "Latitude": "19.721375",
    "Timezone": "38",
    "DST": "-10",
    "destinations": ["3728", "3456", "3484"]
}, {
    "City": "Cape Lisburne",
    "DBTZ": "A",
    "Name": "Cape Lisburne Lrrs",
    "Country": "United States",
    "IATA/FAA": "LUR",
    "Longitude": "-166.110022",
    "ICAO": "PALU",
    "Airport ID": "3413",
    "Latitude": "68.875133",
    "Timezone": "12",
    "DST": "-9",
    "destinations": ["3693"]
}, {
    "City": "Alexandria",
    "DBTZ": "U",
    "Name": "Borg El Arab Intl",
    "Country": "Egypt",
    "IATA/FAA": "HBE",
    "Longitude": "29.696408",
    "ICAO": "HEBA",
    "Airport ID": "3410",
    "Latitude": "30.917669",
    "Timezone": "177",
    "DST": "2",
    "destinations": ["1157", "3941", "2170", "2064", "2072", "2176", "2082", "2188", "2191", "1154", "5804", "5697", "1128", "4057", "2177", "1130", "1701", "2074", "2067", "2096", "2241"]
}, {
    "City": "Barter Island",
    "DBTZ": "A",
    "Name": "Barter Island Lrrs",
    "Country": "United States",
    "IATA/FAA": "BTI",
    "Longitude": "-143.581867",
    "ICAO": "PABA",
    "Airport ID": "3411",
    "Latitude": "70.133989",
    "Timezone": "2",
    "DST": "-9",
    "destinations": ["3420", "3620"]
}, {
    "City": "Rondonopolis",
    "DBTZ": "S",
    "Name": "Rondonopolis Airport",
    "Country": "Brazil",
    "IATA/FAA": "ROO",
    "Longitude": "-54.633333",
    "ICAO": "SWRD",
    "Airport ID": "7382",
    "Latitude": "-16.466667",
    "Timezone": "500",
    "DST": "-4",
    "destinations": ["2548"]
}, {
    "City": "Guarapuava",
    "DBTZ": "S",
    "Name": "Tancredo Thomaz de Faria Airport",
    "Country": "Brazil",
    "IATA/FAA": "GPB",
    "Longitude": "-51.45",
    "ICAO": "SBGU",
    "Airport ID": "7383",
    "Latitude": "-25.383333",
    "Timezone": "200",
    "DST": "-3",
    "destinations": ["2545"]
}, {
    "City": "Dourados",
    "DBTZ": "S",
    "Name": "Dourados Airport",
    "Country": "Brazil",
    "IATA/FAA": "DOU",
    "Longitude": "-54.805833",
    "ICAO": "SSDO",
    "Airport ID": "7380",
    "Latitude": "-22.220833",
    "Timezone": "1433",
    "DST": "-4",
    "destinations": ["2538", "2564", "2578"]
}, {
    "City": "Quetta",
    "DBTZ": "N",
    "Name": "Quetta",
    "Country": "Pakistan",
    "IATA/FAA": "UET",
    "Longitude": "66.937764",
    "ICAO": "OPQT",
    "Airport ID": "2221",
    "Latitude": "30.251369",
    "Timezone": "5267",
    "DST": "5",
    "destinations": ["2223", "2206", "2207", "4331", "2214", "2233", "2191", "5951"]
}, {
    "City": "Hattiesburg/Laurel",
    "DBTZ": "A",
    "Name": "Hattiesburg Laurel Regional Airport",
    "Country": "United States",
    "IATA/FAA": "PIB",
    "Longitude": "-89.3371",
    "ICAO": "KPIB",
    "Airport ID": "5759",
    "Latitude": "31.4671",
    "Timezone": "298",
    "DST": "-6",
    "destinations": ["3682"]
}, {
    "City": "Joacaba",
    "DBTZ": "S",
    "Name": "Joacaba Airport",
    "Country": "Brazil",
    "IATA/FAA": "JCB",
    "Longitude": "-51.500833",
    "ICAO": "SSJA",
    "Airport ID": "7384",
    "Latitude": "-27.172778",
    "Timezone": "1713",
    "DST": "-3",
    "destinations": ["8244", "6477", "2555"]
}, {
    "City": "Dawadmi",
    "DBTZ": "U",
    "Name": "Dawadmi Domestic Airport",
    "Country": "Saudi Arabia",
    "IATA/FAA": "DWD",
    "Longitude": "44.4",
    "ICAO": "OEDW",
    "Airport ID": "5924",
    "Latitude": "24.5",
    "Timezone": "3429",
    "DST": "3",
    "destinations": ["2072", "2082"]
}, {
    "City": "St George",
    "DBTZ": "O",
    "Name": "St George Airport",
    "Country": "Australia",
    "IATA/FAA": "SGO",
    "Longitude": "148.595",
    "ICAO": "YSGE",
    "Airport ID": "6325",
    "Latitude": "-28.0497",
    "Timezone": "656",
    "DST": "10",
    "destinations": ["6258", "7128"]
}, {
    "City": "Roma",
    "DBTZ": "O",
    "Name": "Roma Airport",
    "Country": "Australia",
    "IATA/FAA": "RMA",
    "Longitude": "148.775",
    "ICAO": "YROM",
    "Airport ID": "6324",
    "Latitude": "-26.545",
    "Timezone": "1032",
    "DST": "10",
    "destinations": ["3320", "3323"]
}, {
    "City": "Kaitaia",
    "DBTZ": "Z",
    "Name": "Kaitaia",
    "Country": "New Zealand",
    "IATA/FAA": "KAT",
    "Longitude": "173.285278",
    "ICAO": "NZKT",
    "Airport ID": "2018",
    "Latitude": "-35.07",
    "Timezone": "270",
    "DST": "12",
    "destinations": ["2006"]
}, {
    "City": "Portland",
    "DBTZ": "O",
    "Name": "Portland Airport",
    "Country": "Australia",
    "IATA/FAA": "PTJ",
    "Longitude": "141.471",
    "ICAO": "YPOD",
    "Airport ID": "6321",
    "Latitude": "-38.3181",
    "Timezone": "265",
    "DST": "10",
    "destinations": ["3334"]
}, {
    "City": "Port Macquarie",
    "DBTZ": "O",
    "Name": "Port Macquarie Airport",
    "Country": "Australia",
    "IATA/FAA": "PQQ",
    "Longitude": "152.863",
    "ICAO": "YPMQ",
    "Airport ID": "6320",
    "Latitude": "-31.4358",
    "Timezone": "12",
    "DST": "10",
    "destinations": ["3361", "3320", "6285"]
}, {
    "City": "Hamilton",
    "DBTZ": "Z",
    "Name": "Hamilton",
    "Country": "New Zealand",
    "IATA/FAA": "HLZ",
    "Longitude": "175.332056",
    "ICAO": "NZHN",
    "Airport ID": "2015",
    "Latitude": "-37.866661",
    "Timezone": "172",
    "DST": "12",
    "destinations": ["2006", "2009", "2028", "2042"]
}, {
    "City": "Hokitika",
    "DBTZ": "Z",
    "Name": "Hokitika",
    "Country": "New Zealand",
    "IATA/FAA": "HKK",
    "Longitude": "170.985278",
    "ICAO": "NZHK",
    "Airport ID": "2014",
    "Latitude": "-42.713611",
    "Timezone": "146",
    "DST": "12",
    "destinations": ["2009"]
}, {
    "City": "Kerikeri",
    "DBTZ": "Z",
    "Name": "Kerikeri",
    "Country": "New Zealand",
    "IATA/FAA": "KKE",
    "Longitude": "173.911944",
    "ICAO": "NZKK",
    "Airport ID": "2017",
    "Latitude": "-35.262779",
    "Timezone": "492",
    "DST": "12",
    "destinations": ["2006"]
}, {
    "City": "Dunedin",
    "DBTZ": "Z",
    "Name": "Dunedin",
    "Country": "New Zealand",
    "IATA/FAA": "DUD",
    "Longitude": "170.198333",
    "ICAO": "NZDN",
    "Airport ID": "2011",
    "Latitude": "-45.928055",
    "Timezone": "4",
    "DST": "12",
    "destinations": ["2006", "2009", "2042"]
}, {
    "City": "Allakaket",
    "DBTZ": "A",
    "Name": "Allakaket Airport",
    "Country": "United States",
    "IATA/FAA": "AET",
    "Longitude": "-152.6222",
    "ICAO": "PFAL",
    "Airport ID": "6839",
    "Latitude": "66.5519",
    "Timezone": "441",
    "DST": "-9",
    "destinations": ["3417", "3832", "7107"]
}, {
    "City": "Thargomindah",
    "DBTZ": "O",
    "Name": "Thargomindah Airport",
    "Country": "Australia",
    "IATA/FAA": "XTG",
    "Longitude": "143.811",
    "ICAO": "YTGM",
    "Airport ID": "6329",
    "Latitude": "-27.9864",
    "Timezone": "433",
    "DST": "10",
    "destinations": ["6258"]
}, {
    "City": "Gisborne",
    "DBTZ": "Z",
    "Name": "Gisborne",
    "Country": "New Zealand",
    "IATA/FAA": "GIS",
    "Longitude": "177.978333",
    "ICAO": "NZGS",
    "Airport ID": "2012",
    "Latitude": "-38.663333",
    "Timezone": "15",
    "DST": "12",
    "destinations": ["2006", "2042"]
}, {
    "City": "Marquette",
    "DBTZ": "A",
    "Name": "Sawyer International Airport",
    "Country": "United States",
    "IATA/FAA": "MQT",
    "Longitude": "-87.395278",
    "ICAO": "KMQT",
    "Airport ID": "6838",
    "Latitude": "46.353611",
    "Timezone": "1221",
    "DST": "-5",
    "destinations": ["3830", "3645"]
}, {
    "City": "Gemena",
    "DBTZ": "N",
    "Name": "Gemena",
    "Country": "Congo (Kinshasa)",
    "IATA/FAA": "GMA",
    "Longitude": "19.771258",
    "ICAO": "FZFK",
    "Airport ID": "1028",
    "Latitude": "3.235369",
    "Timezone": "1378",
    "DST": "1",
    "destinations": ["1026"]
}, {
    "City": "Seattle",
    "DBTZ": "A",
    "Name": "Kenmore Air Harbor Seaplane Base",
    "Country": "United States",
    "IATA/FAA": "LKE",
    "Longitude": "-122.339",
    "ICAO": "KW55",
    "Airport ID": "6457",
    "Latitude": "47.629",
    "Timezone": "14",
    "DST": "-8",
    "destinations": ["8174", "3577", "4106"]
}, {
    "City": "Havre-Saint-Pierre",
    "DBTZ": "A",
    "Name": "Havre Saint-Pierre Airport",
    "Country": "Canada",
    "IATA/FAA": "YGV",
    "Longitude": "-63.611389",
    "ICAO": "CYGV",
    "Airport ID": "7255",
    "Latitude": "50.281944",
    "Timezone": "124",
    "DST": "-5",
    "destinations": ["202", "106"]
}, {
    "City": "Harlingen",
    "DBTZ": "A",
    "Name": "Valley Intl",
    "Country": "United States",
    "IATA/FAA": "HRL",
    "Longitude": "-97.654389",
    "ICAO": "KHRL",
    "Airport ID": "3560",
    "Latitude": "26.2285",
    "Timezone": "36",
    "DST": "-6",
    "destinations": ["3566", "3652", "3550", "3673", "3502", "3621"]
}, {
    "City": "Columbia",
    "DBTZ": "A",
    "Name": "Columbia Metropolitan",
    "Country": "United States",
    "IATA/FAA": "CAE",
    "Longitude": "-81.119528",
    "ICAO": "KCAE",
    "Airport ID": "3561",
    "Latitude": "33.938833",
    "Timezone": "236",
    "DST": "-5",
    "destinations": ["3876", "3520", "3670", "3752", "3682", "3645", "3697", "3714", "3550", "3830"]
}, {
    "City": "Houston",
    "DBTZ": "A",
    "Name": "William P Hobby",
    "Country": "United States",
    "IATA/FAA": "HOU",
    "Longitude": "-95.278889",
    "ICAO": "KHOU",
    "Airport ID": "3566",
    "Latitude": "29.645419",
    "Timezone": "46",
    "DST": "-6",
    "destinations": ["3670", "3682", "3448", "3797", "3673", "3849", "3806", "3876", "3744", "3502", "3751", "4034", "3560", "3857", "3877", "3484", "3660", "3458", "3878", "3747", "3861", "3863", "3462", "3641", "3626", "3621", "3867", "3646", "3855", "4019", "3811", "6962", "3690", "3520", "7669", "3559", "3494", "3533", "3585", "3580", "3712", "3697", "3652", "3473", "3453", "3570", "3564", "3731", "3678"]
}, {
    "City": "Pensacola",
    "DBTZ": "A",
    "Name": "Pensacola Rgnl",
    "Country": "United States",
    "IATA/FAA": "PNS",
    "Longitude": "-87.186611",
    "ICAO": "KPNS",
    "Airport ID": "3564",
    "Latitude": "30.473425",
    "Timezone": "121",
    "DST": "-6",
    "destinations": ["3878", "3646", "3876", "3520", "3670", "3576", "3682", "3550", "3830", "3690", "3566"]
}, {
    "City": "Kangiqsujuaq",
    "DBTZ": "A",
    "Name": "Kangiqsujuaq - Wakeham Bay Airport",
    "Country": "Canada",
    "IATA/FAA": "YWB",
    "Longitude": "-71.929444",
    "ICAO": "CYKG",
    "Airport ID": "7253",
    "Latitude": "61.588611",
    "Timezone": "501",
    "DST": "-5",
    "destinations": ["5499", "5535"]
}, {
    "City": "Jiagedaqi District",
    "DBTZ": "N",
    "Name": "Jiagedaqi Airport",
    "Country": "China",
    "IATA/FAA": "JGD",
    "Longitude": "124.117",
    "ICAO": "",
    "Airport ID": "8979",
    "Latitude": "50.375",
    "Timezone": "1205",
    "DST": "8",
    "destinations": ["3400", "6943", "3366"]
}, {
    "City": "Tortola",
    "DBTZ": "U",
    "Name": "Terrance B Lettsome Intl",
    "Country": "British Virgin Islands",
    "IATA/FAA": "EIS",
    "Longitude": "-64.542975",
    "ICAO": "TUPJ",
    "Airport ID": "2903",
    "Latitude": "18.444834",
    "Timezone": "15",
    "DST": "-4",
    "destinations": ["2890", "2883", "2899", "2877", "6079"]
}, {
    "City": "Port-of-spain",
    "DBTZ": "U",
    "Name": "Piarco",
    "Country": "Trinidad and Tobago",
    "IATA/FAA": "POS",
    "Longitude": "-61.337242",
    "ICAO": "TTPP",
    "Airport ID": "2902",
    "Latitude": "10.595369",
    "Timezone": "58",
    "DST": "-4",
    "destinations": ["2897", "2773", "3576", "1871", "3533", "3797", "2894", "2875", "2851", "4304", "2882", "1779", "502", "3878", "2893", "2901", "193", "2907", "3494", "3550", "2850"]
}, {
    "City": "Scarborough",
    "DBTZ": "U",
    "Name": "Crown Point",
    "Country": "Trinidad and Tobago",
    "IATA/FAA": "TAB",
    "Longitude": "-60.832194",
    "ICAO": "TTCP",
    "Airport ID": "2901",
    "Latitude": "11.149658",
    "Timezone": "38",
    "DST": "-4",
    "destinations": ["2874", "3797", "2902", "2875"]
}, {
    "City": "The Valley",
    "DBTZ": "U",
    "Name": "Wallblake",
    "Country": "Anguilla",
    "IATA/FAA": "AXA",
    "Longitude": "-63.055084",
    "ICAO": "TQPF",
    "Airport ID": "2900",
    "Latitude": "18.204834",
    "Timezone": "127",
    "DST": "-4",
    "destinations": ["2890", "2874", "2883", "2899"]
}, {
    "City": "Malmoe",
    "DBTZ": "E",
    "Name": "Sturup",
    "Country": "Sweden",
    "IATA/FAA": "MMX",
    "Longitude": "13.371639",
    "ICAO": "ESMS",
    "Airport ID": "708",
    "Latitude": "55.530193",
    "Timezone": "236",
    "DST": "1",
    "destinations": ["738", "746", "737", "1230", "548", "731", "1739", "1489", "668", "671", "1737", "679"]
}, {
    "City": "Halmstad",
    "DBTZ": "E",
    "Name": "Halmstad",
    "Country": "Sweden",
    "IATA/FAA": "HAD",
    "Longitude": "12.820211",
    "ICAO": "ESMT",
    "Airport ID": "709",
    "Latitude": "56.691128",
    "Timezone": "101",
    "DST": "1",
    "destinations": ["737", "688", "711", "738"]
}, {
    "City": "Alma-ata",
    "DBTZ": "U",
    "Name": "Almaty",
    "Country": "Kazakhstan",
    "IATA/FAA": "ALA",
    "Longitude": "77.040508",
    "ICAO": "UAAA",
    "Airport ID": "2908",
    "Latitude": "43.352072",
    "Timezone": "2234",
    "DST": "6",
    "destinations": ["2979", "507", "3399", "2920", "2914", "2911", "6085", "4375", "6083", "6088", "2918", "6084", "4367", "2910", "6086", "2179", "2191", "3364", "2983", "3885", "3093", "2912", "4357", "2922", "3077", "3930", "1701", "2939", "4118", "3304", "2990", "4358", "2948", "2917", "3205", "2985", "3973", "580", "340", "1587", "4317", "4078", "4029", "2988", "4330"]
}, {
    "City": "Kristianstad",
    "DBTZ": "E",
    "Name": "Kristianstad",
    "Country": "Sweden",
    "IATA/FAA": "KID",
    "Longitude": "14.085536",
    "ICAO": "ESMK",
    "Airport ID": "703",
    "Latitude": "55.921686",
    "Timezone": "76",
    "DST": "1",
    "destinations": ["737"]
}, {
    "City": "Pelican",
    "DBTZ": "A",
    "Name": "Pelican Seaplane Base",
    "Country": "United States",
    "IATA/FAA": "PEC",
    "Longitude": "-136.236389",
    "ICAO": "",
    "Airport ID": "7138",
    "Latitude": "57.955278",
    "Timezone": "0",
    "DST": "-9",
    "destinations": ["7136"]
}, {
    "City": "Schefferville",
    "DBTZ": "A",
    "Name": "Schefferville",
    "Country": "Canada",
    "IATA/FAA": "YKL",
    "Longitude": "-66.805278",
    "ICAO": "CYKL",
    "Airport ID": "80",
    "Latitude": "54.805278",
    "Timezone": "1709",
    "DST": "-5",
    "destinations": ["111", "154", "161", "202"]
}, {
    "City": "Angoon",
    "DBTZ": "A",
    "Name": "Angoon Seaplane Base",
    "Country": "United States",
    "IATA/FAA": "AGN",
    "Longitude": "-134.585",
    "ICAO": "PAGN",
    "Airport ID": "7135",
    "Latitude": "57.503611",
    "Timezone": "0",
    "DST": "-9",
    "destinations": ["7137"]
}, {
    "City": "Kelowna",
    "DBTZ": "A",
    "Name": "Kelowna",
    "Country": "Canada",
    "IATA/FAA": "YLW",
    "Longitude": "-119.377778",
    "ICAO": "CYLW",
    "Airport ID": "87",
    "Latitude": "49.956112",
    "Timezone": "1409",
    "DST": "-8",
    "destinations": ["156", "176", "164", "172", "3484", "178", "3577", "112", "49", "184", "193"]
}, {
    "City": "Tenakee Springs",
    "DBTZ": "A",
    "Name": "Tenakee Seaplane Base",
    "Country": "United States",
    "IATA/FAA": "TKE",
    "Longitude": "-135.218333",
    "ICAO": "",
    "Airport ID": "7137",
    "Latitude": "57.779722",
    "Timezone": "0",
    "DST": "-9",
    "destinations": ["3492"]
}, {
    "City": "Elfin Cove",
    "DBTZ": "A",
    "Name": "Elfin Cove Seaplane Base",
    "Country": "United States",
    "IATA/FAA": "ELV",
    "Longitude": "-136.3475",
    "ICAO": "PAEL",
    "Airport ID": "7136",
    "Latitude": "58.195278",
    "Timezone": "0",
    "DST": "-9",
    "destinations": ["3492"]
}, {
    "City": "Sibiu",
    "DBTZ": "E",
    "Name": "Sibiu",
    "Country": "Romania",
    "IATA/FAA": "SBZ",
    "Longitude": "24.091342",
    "ICAO": "LRSB",
    "Airport ID": "1658",
    "Latitude": "45.785597",
    "Timezone": "1496",
    "DST": "2",
    "destinations": ["346", "1613", "1657"]
}, {
    "City": "Satu Mare",
    "DBTZ": "E",
    "Name": "Satu Mare",
    "Country": "Romania",
    "IATA/FAA": "SUJ",
    "Longitude": "22.8857",
    "ICAO": "LRSM",
    "Airport ID": "1659",
    "Latitude": "47.703275",
    "Timezone": "405",
    "DST": "2",
    "destinations": ["1657"]
}, {
    "City": "Cluj-napoca",
    "DBTZ": "E",
    "Name": "Cluj Napoca",
    "Country": "Romania",
    "IATA/FAA": "CLJ",
    "Longitude": "23.686167",
    "ICAO": "LRCL",
    "Airport ID": "1652",
    "Latitude": "46.785167",
    "Timezone": "1036",
    "DST": "2",
    "destinations": ["1657", "346", "1587", "1613", "1218", "1525", "1538", "1367", "1553", "304", "373", "585", "492", "1229", "1590", "1539", "1246", "1252"]
}, {
    "City": "Constanta",
    "DBTZ": "E",
    "Name": "Mihail Kogalniceanu",
    "Country": "Romania",
    "IATA/FAA": "CND",
    "Longitude": "28.488333",
    "ICAO": "LRCK",
    "Airport ID": "1651",
    "Latitude": "44.362222",
    "Timezone": "353",
    "DST": "2",
    "destinations": ["1701"]
}, {
    "City": "Oradea",
    "DBTZ": "E",
    "Name": "Oradea",
    "Country": "Romania",
    "IATA/FAA": "OMR",
    "Longitude": "21.9025",
    "ICAO": "LROD",
    "Airport ID": "1656",
    "Latitude": "47.025278",
    "Timezone": "465",
    "DST": "2",
    "destinations": ["1657"]
}, {
    "City": "Bucharest",
    "DBTZ": "E",
    "Name": "Henri Coanda",
    "Country": "Romania",
    "IATA/FAA": "OTP",
    "Longitude": "26.102178",
    "ICAO": "LROP",
    "Airport ID": "1657",
    "Latitude": "44.572161",
    "Timezone": "314",
    "DST": "2",
    "destinations": ["1735", "3941", "507", "351", "1382", "1649", "1652", "1555", "1655", "1529", "1656", "1659", "1663", "1197", "340", "1194", "599", "548", "2188", "1218", "1739", "580", "345", "346", "679", "1678", "1590", "2177", "1587", "1613", "4317", "2241", "2170", "302", "1489", "1665", "1701", "1229", "1354", "1486", "2985", "609", "1638", "502", "1517", "1525", "1538", "1501", "1367", "1553", "304", "1509", "1534", "373", "8076", "585", "492", "1524", "1561", "1567", "1562", "664", "1539", "1246", "1550", "1252"]
}, {
    "City": "Craiova",
    "DBTZ": "E",
    "Name": "Craiova",
    "Country": "Romania",
    "IATA/FAA": "CRA",
    "Longitude": "23.888611",
    "ICAO": "LRCV",
    "Airport ID": "1654",
    "Latitude": "44.318139",
    "Timezone": "626",
    "DST": "2",
    "destinations": ["1525", "492"]
}, {
    "City": "Iasi",
    "DBTZ": "E",
    "Name": "Iasi",
    "Country": "Romania",
    "IATA/FAA": "IAS",
    "Longitude": "27.620631",
    "ICAO": "LRIA",
    "Airport ID": "1655",
    "Latitude": "47.178492",
    "Timezone": "397",
    "DST": "2",
    "destinations": ["1538", "1555", "1657", "1526", "1613", "492", "1590"]
}, {
    "City": "Groningen",
    "DBTZ": "E",
    "Name": "Eelde",
    "Country": "Netherlands",
    "IATA/FAA": "GRQ",
    "Longitude": "6.579444",
    "ICAO": "EHGG",
    "Airport ID": "586",
    "Latitude": "53.11972",
    "Timezone": "17",
    "DST": "1",
    "destinations": ["1222", "3998", "1626", "1054", "582"]
}, {
    "City": "Eindhoven",
    "DBTZ": "E",
    "Name": "Eindhoven",
    "Country": "Netherlands",
    "IATA/FAA": "EIN",
    "Longitude": "5.374528",
    "ICAO": "EHEH",
    "Airport ID": "585",
    "Latitude": "51.450139",
    "Timezone": "74",
    "DST": "1",
    "destinations": ["1055", "1230", "1517", "1212", "1506", "1525", "1352", "1460", "1474", "1553", "1509", "599", "1626", "1066", "1222", "1225", "669", "1054", "1227", "1606", "1353", "600", "1636", "3998", "1562", "1075", "1236", "548", "1253", "1515", "1539", "8414", "3941", "1688", "1218", "4315", "1538", "609", "1715", "586", "1610", "1638", "1229", "1561", "1354", "591", "4317", "351", "1551", "1246", "1739", "1489", "1652", "1490", "668", "671", "1657", "3953", "1737", "1194", "3959", "679"]
}, {
    "City": "Maastricht",
    "DBTZ": "E",
    "Name": "Maastricht",
    "Country": "Netherlands",
    "IATA/FAA": "MST",
    "Longitude": "5.770144",
    "ICAO": "EHBK",
    "Airport ID": "582",
    "Latitude": "50.911658",
    "Timezone": "375",
    "DST": "1",
    "destinations": ["1212", "1501", "1626", "1222", "1636", "3998", "1056", "1515", "1688", "1452"]
}, {
    "City": "Amsterdam",
    "DBTZ": "E",
    "Name": "Schiphol",
    "Country": "Netherlands",
    "IATA/FAA": "AMS",
    "Longitude": "4.763889",
    "ICAO": "EHAM",
    "Airport ID": "580",
    "Latitude": "52.308613",
    "Timezone": "-11",
    "DST": "1",
    "destinations": ["5673", "1080", "342", "1330", "1435", "3973", "507", "3752", "609", "2179", "1264", "4053", "1382", "2064", "2241", "1005", "1180", "1165", "3304", "2176", "503", "1335", "1353", "1354", "1418", "1273", "2276", "2688", "1509", "1529", "1512", "1074", "421", "1638", "1636", "628", "630", "737", "636", "608", "1538", "353", "490", "344", "797", "488", "345", "2188", "1555", "1563", "534", "352", "515", "638", "517", "2789", "522", "4059", "547", "1871", "666", "665", "664", "351", "1551", "2954", "502", "469", "523", "552", "533", "495", "3885", "3953", "478", "3077", "1197", "3370", "3395", "3386", "3364", "3406", "3383", "3682", "2997", "3448", "3670", "3645", "3494", "3714", "3550", "3797", "3484", "3858", "3830", "3720", "3577", "3469", "146", "156", "178", "193", "644", "599", "596", "340", "1739", "348", "1194", "16", "1055", "1706", "1230", "1324", "1212", "3941", "1688", "1218", "4315", "1501", "1460", "1474", "1715", "3989", "1626", "4091", "1051", "1222", "1452", "1225", "1610", "1450", "1468", "1479", "1458", "1454", "1054", "1606", "1561", "1198", "3998", "1562", "1470", "1075", "1472", "4317", "1486", "1476", "1053", "1508", "1253", "1056", "1590", "1526", "1246", "1550", "1488", "1216", "4330", "1569", "668", "669", "4357", "3930", "532", "248", "2908", "2895", "302", "1489", "1128", "2897", "3093", "535", "3988", "2305", "2560", "687", "2564", "1665", "1909", "1701", "813", "2939", "3992", "951", "2948", "273", "742", "629", "1229", "1824", "346", "521", "2279", "347", "1657", "2773", "1587", "3316", "350", "2985", "2899", "415", "1613", "679", "1678", "2896", "1910", "1761", "1760", "4167", "1923", "1200", "1208", "711", "1696", "1691", "2170", "1633", "498", "499", "1676", "287", "465", "491", "492", "1524", "508", "548", "337", "1106"]
}, {
    "City": "Kengtung",
    "DBTZ": "U",
    "Name": "Kengtung",
    "Country": "Burma",
    "IATA/FAA": "KET",
    "Longitude": "99.635997",
    "ICAO": "VYKG",
    "Airport ID": "3215",
    "Latitude": "21.301611",
    "Timezone": "2798",
    "DST": "6.5",
    "destinations": ["3239"]
}, {
    "City": "Garachine",
    "DBTZ": "U",
    "Name": "Garachine Airport",
    "Country": "Panama",
    "IATA/FAA": "GHE",
    "Longitude": "-78.36",
    "ICAO": "",
    "Airport ID": "8206",
    "Latitude": "8.06",
    "Timezone": "0",
    "DST": "-5",
    "destinations": ["1869"]
}, {
    "City": "Mulatupo",
    "DBTZ": "U",
    "Name": "Mulatupo Airport",
    "Country": "Panama",
    "IATA/FAA": "MPP",
    "Longitude": "-77.75",
    "ICAO": "",
    "Airport ID": "8207",
    "Latitude": "8.95",
    "Timezone": "0",
    "DST": "-5",
    "destinations": ["1869"]
}, {
    "City": "Achutupo",
    "DBTZ": "U",
    "Name": "Achutupo Airport",
    "Country": "Panama",
    "IATA/FAA": "ACU",
    "Longitude": "-77.98",
    "ICAO": "",
    "Airport ID": "8204",
    "Latitude": "9.2",
    "Timezone": "0",
    "DST": "-5",
    "destinations": ["1869"]
}, {
    "City": "Goodnews Bay",
    "DBTZ": "A",
    "Name": "Goodnews Airport",
    "Country": "United States",
    "IATA/FAA": "GNU",
    "Longitude": "-161.5775",
    "ICAO": "",
    "Airport ID": "8202",
    "Latitude": "59.1175",
    "Timezone": "15",
    "DST": "-9",
    "destinations": ["3430"]
}, {
    "City": "Newtok",
    "DBTZ": "U",
    "Name": "Newtok Airport",
    "Country": "United States",
    "IATA/FAA": "WWT",
    "Longitude": "-164.641111",
    "ICAO": "",
    "Airport ID": "8203",
    "Latitude": "60.939167",
    "Timezone": "25",
    "DST": "-9",
    "destinations": ["3599"]
}, {
    "City": "Toksook Bay",
    "DBTZ": "A",
    "Name": "Toksook Bay Airport",
    "Country": "United States",
    "IATA/FAA": "OOK",
    "Longitude": "-165.087222",
    "ICAO": "PAOO",
    "Airport ID": "8200",
    "Latitude": "60.541389",
    "Timezone": "59",
    "DST": "-9",
    "destinations": ["8201", "6720"]
}, {
    "City": "Tununak",
    "DBTZ": "A",
    "Name": "Tununak Airport",
    "Country": "United States",
    "IATA/FAA": "TNK",
    "Longitude": "-165.271667",
    "ICAO": "",
    "Airport ID": "8201",
    "Latitude": "60.575556",
    "Timezone": "14",
    "DST": "-9",
    "destinations": ["8203"]
}, {
    "City": "Trivandrum",
    "DBTZ": "N",
    "Name": "Thiruvananthapuram Intl",
    "Country": "India",
    "IATA/FAA": "TRV",
    "Longitude": "76.920114",
    "ICAO": "VOTV",
    "Airport ID": "3153",
    "Latitude": "8.482122",
    "Timezone": "15",
    "DST": "5.5",
    "destinations": ["3131", "2997", "3136", "2188", "3144", "2179", "2064", "2194", "3156", "2082", "2191", "2057", "2176", "3316", "3024", "6184", "2241"]
}, {
    "City": "Tiruchirappalli",
    "DBTZ": "N",
    "Name": "Trichy",
    "Country": "India",
    "IATA/FAA": "TRZ",
    "Longitude": "78.709722",
    "ICAO": "VOTR",
    "Airport ID": "3152",
    "Latitude": "10.765364",
    "Timezone": "288",
    "DST": "5.5",
    "destinations": ["3144", "3304", "3316", "3024"]
}, {
    "City": "Tirupeti",
    "DBTZ": "N",
    "Name": "Tirupati",
    "Country": "India",
    "IATA/FAA": "TIR",
    "Longitude": "79.543256",
    "ICAO": "VOTP",
    "Airport ID": "3151",
    "Latitude": "13.632492",
    "Timezone": "350",
    "DST": "5.5",
    "destinations": ["3141"]
}, {
    "City": "Kyaukpyu",
    "DBTZ": "U",
    "Name": "Kyaukpyu",
    "Country": "Burma",
    "IATA/FAA": "KYP",
    "Longitude": "93.534836",
    "ICAO": "VYKP",
    "Airport ID": "3217",
    "Latitude": "19.426447",
    "Timezone": "20",
    "DST": "6.5",
    "destinations": ["3239"]
}, {
    "City": "Bangkok",
    "DBTZ": "U",
    "Name": "Don Muang Intl",
    "Country": "Thailand",
    "IATA/FAA": "DMK",
    "Longitude": "100.60675",
    "ICAO": "VTBD",
    "Airport ID": "3157",
    "Latitude": "13.912583",
    "Timezone": "9",
    "DST": "7",
    "destinations": ["3304", "4133", "3932", "4300", "3931", "3181", "3179", "3174", "3936", "3186", "6186", "4131", "3933", "3170", "3164", "3239", "4132", "3184", "3182", "3937", "4134", "4000", "3183", "3371", "3384", "3370", "3393", "3940", "3199", "3386", "3077", "3938", "3382", "3144", "3222", "3121", "3173", "3387", "3308", "3034", "3035", "3205", "3316", "3374", "3376", "3379", "3275", "3928"]
}, {
    "City": "Male",
    "DBTZ": "U",
    "Name": "Male Intl",
    "Country": "Maldives",
    "IATA/FAA": "MLE",
    "Longitude": "73.529128",
    "ICAO": "VRMM",
    "Airport ID": "3156",
    "Latitude": "4.191833",
    "Timezone": "6",
    "DST": "5",
    "destinations": ["3153", "2179", "3024", "502", "3077", "3370", "340", "2188", "8949", "8076", "3364", "1524", "3406", "3304", "3885", "6183", "8892", "4235", "6185", "3144", "2241", "3136", "3316", "2985", "1701", "2988", "2194"]
}, {
    "City": "Thimphu",
    "DBTZ": "N",
    "Name": "Paro",
    "Country": "Bhutan",
    "IATA/FAA": "PBH",
    "Longitude": "89.424606",
    "ICAO": "VQPR",
    "Airport ID": "3155",
    "Latitude": "27.403192",
    "Timezone": "7332",
    "DST": "6",
    "destinations": ["3043", "3076", "6173", "3040", "3125"]
}, {
    "City": "Cumana",
    "DBTZ": "U",
    "Name": "Antonio Jose De Sucre",
    "Country": "Venezuela",
    "IATA/FAA": "CUM",
    "Longitude": "-64.130472",
    "ICAO": "SVCU",
    "Airport ID": "2835",
    "Latitude": "10.450333",
    "Timezone": "25",
    "DST": "-4.5",
    "destinations": ["2851"]
}, {
    "City": "Coro",
    "DBTZ": "U",
    "Name": "Jose Leonardo Chirinos",
    "Country": "Venezuela",
    "IATA/FAA": "CZE",
    "Longitude": "-69.681656",
    "ICAO": "SVCR",
    "Airport ID": "2833",
    "Latitude": "11.414867",
    "Timezone": "52",
    "DST": "-4.5",
    "destinations": ["2851"]
}, {
    "City": "Canaima",
    "DBTZ": "U",
    "Name": "Canaima",
    "Country": "Venezuela",
    "IATA/FAA": "CAJ",
    "Longitude": "-62.854433",
    "ICAO": "SVCN",
    "Airport ID": "2830",
    "Latitude": "6.231989",
    "Timezone": "1450",
    "DST": "-4.5",
    "destinations": ["2856"]
}, {
    "City": "Fera Island",
    "DBTZ": "U",
    "Name": "Fera/Maringe Airport",
    "Country": "Solomon Islands",
    "IATA/FAA": "FRE",
    "Longitude": "159.577",
    "ICAO": "AGGF",
    "Airport ID": "5407",
    "Latitude": "-8.1075",
    "Timezone": "0",
    "DST": "11",
    "destinations": ["4074", "5416"]
}, {
    "City": "Qingdao",
    "DBTZ": "U",
    "Name": "Liuting",
    "Country": "China",
    "IATA/FAA": "TAO",
    "Longitude": "120.374436",
    "ICAO": "ZSQD",
    "Airport ID": "3390",
    "Latitude": "36.266108",
    "Timezone": "33",
    "DST": "8",
    "destinations": ["3395", "3382", "3930", "3376", "3391", "2264", "2276", "3371", "4144", "2372", "3370", "3375", "4380", "3393", "3404", "3389", "3386", "3400", "6412", "3384", "3992", "3387", "3388", "2279", "3364", "3406", "6347", "3374", "3368", "3369", "6392", "6391", "3379", "3383", "6430", "6414", "3077", "3385", "6345", "3394", "6387", "6433", "6411", "8417", "6413", "2305", "3942", "4334", "6390", "6429", "3885", "2268", "3316"]
}, {
    "City": "Chongqing",
    "DBTZ": "U",
    "Name": "Jiangbei",
    "Country": "China",
    "IATA/FAA": "CKG",
    "Longitude": "106.641678",
    "ICAO": "ZUCK",
    "Airport ID": "3393",
    "Latitude": "29.719217",
    "Timezone": "1365",
    "DST": "8",
    "destinations": ["3370", "3375", "3371", "3395", "4109", "3404", "6353", "4120", "3386", "3179", "4085", "3384", "3382", "6431", "4033", "4097", "3387", "3388", "3373", "3364", "3406", "6435", "4302", "3361", "4030", "3374", "4108", "2275", "3368", "3369", "3399", "3376", "6390", "3379", "6366", "6361", "6357", "3885", "4149", "4148", "3391", "6347", "421", "2276", "3077", "3930", "4301", "3394", "3372", "3121", "3390", "6392", "6391", "3383", "8876", "6355", "2264", "6351", "4380", "3385", "4055", "4144", "6430", "3157", "6387", "7506", "8082", "6427", "9025", "4145", "6434", "8407", "7898", "8781", "6356", "6360", "3396", "6367", "6341", "6384", "5475", "3316", "3389", "6378", "6345", "6407", "2241", "3931"]
}, {
    "City": "Strasbourg",
    "DBTZ": "E",
    "Name": "Entzheim",
    "Country": "France",
    "IATA/FAA": "SXB",
    "Longitude": "7.628233",
    "ICAO": "LFST",
    "Airport ID": "1435",
    "Latitude": "48.538319",
    "Timezone": "505",
    "DST": "1",
    "destinations": ["580", "1264", "1555", "1399", "1335", "1359", "1353", "1418", "1354", "1386", "1273", "1074", "1636", "548", "1229", "1587", "302", "1075", "293", "287", "502", "1324", "1321", "1280", "1512", "1706"]
}, {
    "City": "Chengdu",
    "DBTZ": "U",
    "Name": "Shuangliu",
    "Country": "China",
    "IATA/FAA": "CTU",
    "Longitude": "103.947086",
    "ICAO": "ZUUU",
    "Airport ID": "3395",
    "Latitude": "30.578528",
    "Timezone": "1625",
    "DST": "8",
    "destinations": ["3370", "3375", "3393", "3371", "9310", "6434", "4120", "8407", "6345", "3389", "3386", "3077", "3179", "3930", "4085", "4055", "8781", "4301", "3384", "3382", "6379", "3394", "6431", "4033", "6376", "4097", "6402", "3339", "3388", "3373", "3364", "3406", "6435", "4144", "6347", "4030", "3374", "3390", "4108", "2275", "3368", "3369", "3399", "6392", "3376", "6390", "6403", "3396", "3379", "3383", "6366", "4149", "6361", "8876", "7862", "3391", "2179", "507", "2276", "6404", "2997", "6396", "4380", "6343", "4109", "3385", "340", "3400", "6380", "3397", "2206", "6407", "3125", "3372", "3121", "3387", "2279", "3316", "4302", "6368", "6430", "6409", "6394", "3392", "6357", "6355", "580", "6346", "6359", "3304", "6351", "6428", "6944", "6383", "6356", "2264", "6387", "6341", "3885", "6375", "4054", "8921", "2241", "3196", "3199", "6386"]
}, {
    "City": "Guiyang",
    "DBTZ": "U",
    "Name": "Longdongbao",
    "Country": "China",
    "IATA/FAA": "KWE",
    "Longitude": "106.800703",
    "ICAO": "ZUGY",
    "Airport ID": "3394",
    "Latitude": "26.538522",
    "Timezone": "3736",
    "DST": "8",
    "destinations": ["3395", "3386", "6386", "3382", "6431", "4030", "6392", "4149", "4033", "3388", "3316", "3391", "3370", "3375", "3393", "3389", "3384", "3364", "3406", "3374", "4108", "3376", "3379", "3383", "6355", "3371", "4054", "3385", "3179", "3373", "4302", "3390", "2276", "3399", "6366", "7506", "9025", "7508", "3372", "6399", "6744", "4120", "4085", "6400", "6963", "3369", "3077", "6361"]
}, {
    "City": "Kashi",
    "DBTZ": "U",
    "Name": "Kashi",
    "Country": "China",
    "IATA/FAA": "KHG",
    "Longitude": "76.019956",
    "ICAO": "ZWSH",
    "Airport ID": "3397",
    "Latitude": "39.542922",
    "Timezone": "4529",
    "DST": "8",
    "destinations": ["3399", "3395", "7932", "3379"]
}, {
    "City": "Xichang",
    "DBTZ": "U",
    "Name": "Qingshan",
    "Country": "China",
    "IATA/FAA": "XIC",
    "Longitude": "102.184361",
    "ICAO": "ZUXC",
    "Airport ID": "3396",
    "Latitude": "27.989083",
    "Timezone": "5112",
    "DST": "8",
    "destinations": ["3395", "3393", "3382"]
}, {
    "City": "Urumqi",
    "DBTZ": "U",
    "Name": "Diwopu",
    "Country": "China",
    "IATA/FAA": "URC",
    "Longitude": "87.474244",
    "ICAO": "ZWWW",
    "Airport ID": "3399",
    "Latitude": "43.907106",
    "Timezone": "2125",
    "DST": "8",
    "destinations": ["3375", "3393", "3395", "3397", "3379", "2979", "6431", "6366", "3391", "3398", "4085", "3364", "4108", "3369", "6430", "2276", "6946", "6404", "2908", "2976", "6346", "3370", "3371", "4308", "2912", "2922", "6345", "3386", "6743", "4330", "2223", "1701", "6406", "6407", "6408", "3394", "6147", "3388", "2913", "4078", "3406", "6347", "2985", "2983", "3973", "6752", "2910", "3376", "6409", "6394", "6434", "3368", "3374"]
}, {
    "City": "Hotan",
    "DBTZ": "U",
    "Name": "Hotan",
    "Country": "China",
    "IATA/FAA": "HTN",
    "Longitude": "79.864933",
    "ICAO": "ZWTN",
    "Airport ID": "3398",
    "Latitude": "37.038522",
    "Timezone": "4672",
    "DST": "8",
    "destinations": ["3399"]
}, {
    "City": "Heho",
    "DBTZ": "U",
    "Name": "Heho",
    "Country": "Burma",
    "IATA/FAA": "HEH",
    "Longitude": "96.792044",
    "ICAO": "VYHH",
    "Airport ID": "3213",
    "Latitude": "20.747036",
    "Timezone": "3858",
    "DST": "6.5",
    "destinations": ["3222", "3239", "3237"]
}, {
    "City": "Hyeres",
    "DBTZ": "E",
    "Name": "Le Palyvestre",
    "Country": "France",
    "IATA/FAA": "TLN",
    "Longitude": "6.14603",
    "ICAO": "LFTH",
    "Airport ID": "1438",
    "Latitude": "43.0973",
    "Timezone": "7",
    "DST": "1",
    "destinations": ["1386", "548", "591"]
}, {
    "City": "Nimes",
    "DBTZ": "E",
    "Name": "Garons",
    "Country": "France",
    "IATA/FAA": "FNI",
    "Longitude": "4.416347",
    "ICAO": "LFTW",
    "Airport ID": "1439",
    "Latitude": "43.757444",
    "Timezone": "309",
    "DST": "1",
    "destinations": ["304", "1066", "491", "492"]
}, {
    "City": "Tampere",
    "DBTZ": "E",
    "Name": "Tampere Pirkkala",
    "Country": "Finland",
    "IATA/FAA": "TMP",
    "Longitude": "23.604392",
    "ICAO": "EFTP",
    "Airport ID": "458",
    "Latitude": "61.414147",
    "Timezone": "390",
    "DST": "2",
    "destinations": ["421", "737", "1230", "1212", "1525", "353", "1489", "1222", "355", "548"]
}, {
    "City": "Flagstaff",
    "DBTZ": "N",
    "Name": "Flagstaff Pulliam Airport",
    "Country": "United States",
    "IATA/FAA": "FLG",
    "Longitude": "-111.6692392",
    "ICAO": "KFLG",
    "Airport ID": "4261",
    "Latitude": "35.140318",
    "Timezone": "7015",
    "DST": "-7",
    "destinations": ["3462"]
}, {
    "City": "Twin Falls",
    "DBTZ": "A",
    "Name": "Magic Valley Regional Airport",
    "Country": "United States",
    "IATA/FAA": "TWF",
    "Longitude": "-114.487733",
    "ICAO": "KTWF",
    "Airport ID": "4263",
    "Latitude": "42.481803",
    "Timezone": "4151",
    "DST": "-7",
    "destinations": ["3536"]
}, {
    "City": "Monaco",
    "DBTZ": "E",
    "Name": "Monaco",
    "Country": "Monaco",
    "IATA/FAA": "MCM",
    "Longitude": "7.41666666",
    "ICAO": "",
    "Airport ID": "4264",
    "Latitude": "43.73333333",
    "Timezone": "0",
    "DST": "1",
    "destinations": ["1354"]
}, {
    "City": "Vineyard Haven MA",
    "DBTZ": "A",
    "Name": "Martha\\\\'s Vineyard",
    "Country": "United States",
    "IATA/FAA": "MVY",
    "Longitude": "-70.615278",
    "ICAO": "KMVY",
    "Airport ID": "4265",
    "Latitude": "41.391667",
    "Timezone": "67",
    "DST": "-5",
    "destinations": ["3517", "3448", "5737", "3589"]
}, {
    "City": "Rovaniemi",
    "DBTZ": "E",
    "Name": "Rovaniemi",
    "Country": "Finland",
    "IATA/FAA": "RVN",
    "Longitude": "25.830411",
    "ICAO": "EFRO",
    "Airport ID": "453",
    "Latitude": "66.564822",
    "Timezone": "642",
    "DST": "2",
    "destinations": ["421"]
}, {
    "City": "Aurillac",
    "DBTZ": "E",
    "Name": "Aurillac",
    "Country": "France",
    "IATA/FAA": "AUR",
    "Longitude": "2.421944",
    "ICAO": "LFLW",
    "Airport ID": "1344",
    "Latitude": "44.891388",
    "Timezone": "2096",
    "DST": "1",
    "destinations": ["1386"]
}, {
    "City": "Savonlinna",
    "DBTZ": "E",
    "Name": "Savonlinna",
    "Country": "Finland",
    "IATA/FAA": "SVL",
    "Longitude": "28.945136",
    "ICAO": "EFSA",
    "Airport ID": "455",
    "Latitude": "61.943064",
    "Timezone": "311",
    "DST": "2",
    "destinations": ["421"]
}, {
    "City": "Flin Flon",
    "DBTZ": "A",
    "Name": "Flin Flon",
    "Country": "Canada",
    "IATA/FAA": "YFO",
    "Longitude": "-101.681667",
    "ICAO": "CYFO",
    "Airport ID": "58",
    "Latitude": "54.678055",
    "Timezone": "997",
    "DST": "-6",
    "destinations": ["5524", "141", "160"]
}, {
    "City": "Ust-Kut",
    "DBTZ": "N",
    "Name": "Ust-Kut",
    "Country": "Russia",
    "IATA/FAA": "UKX",
    "Longitude": "105.7167",
    "ICAO": "UITT",
    "Airport ID": "6924",
    "Latitude": "56.85",
    "Timezone": "2033",
    "DST": "9",
    "destinations": ["2937"]
}, {
    "City": "Rimatara",
    "DBTZ": "U",
    "Name": "Rimatara",
    "Country": "French Polynesia",
    "IATA/FAA": "RMT",
    "Longitude": "-152.805192",
    "ICAO": "NTAM",
    "Airport ID": "6926",
    "Latitude": "-22.637253",
    "Timezone": "56",
    "DST": "-10",
    "destinations": ["4075", "1971"]
}, {
    "City": "San Jose",
    "DBTZ": "U",
    "Name": "Juan Santamaria Intl",
    "Country": "Costa Rica",
    "IATA/FAA": "SJO",
    "Longitude": "-84.208806",
    "ICAO": "MROC",
    "Airport ID": "1885",
    "Latitude": "9.993861",
    "Timezone": "3021",
    "DST": "-6",
    "destinations": ["1824", "1865", "6945", "6731", "1879", "1863", "1884", "5847", "6732", "5844", "5842", "1889", "1867", "3876", "3670", "3797", "3576", "193", "3682", "2709", "1767", "2789", "1871", "1892", "1772", "1776", "3533", "3878", "1762", "3484", "3751", "1229", "1881", "1887", "3494", "3714", "3550", "3830"]
}, {
    "City": "Sandy Lake",
    "DBTZ": "A",
    "Name": "Sandy Lake Airport",
    "Country": "Canada",
    "IATA/FAA": "ZSJ",
    "Longitude": "-93.3444",
    "ICAO": "CZSJ",
    "Airport ID": "5548",
    "Latitude": "53.0642",
    "Timezone": "951",
    "DST": "-6",
    "destinations": ["8355", "5521", "5527"]
}, {
    "City": "Nosara Beach",
    "DBTZ": "U",
    "Name": "Nosara",
    "Country": "Costa Rica",
    "IATA/FAA": "NOB",
    "Longitude": "-85.653",
    "ICAO": "MRNS",
    "Airport ID": "1884",
    "Latitude": "9.97649",
    "Timezone": "33",
    "DST": "-6",
    "destinations": ["6732"]
}, {
    "City": "Tianjin",
    "DBTZ": "U",
    "Name": "Binhai",
    "Country": "China",
    "IATA/FAA": "TSN",
    "Longitude": "117.346183",
    "ICAO": "ZBTJ",
    "Airport ID": "3368",
    "Latitude": "39.124353",
    "Timezone": "10",
    "DST": "8",
    "destinations": ["4380", "3393", "3395", "3386", "3400", "6431", "3404", "3370", "2370", "3371", "6353", "6345", "3372", "3387", "3374", "6368", "3379", "3383", "6366", "3392", "6355", "2264", "2276", "6346", "3375", "4120", "3077", "3930", "3382", "5475", "3391", "3390", "2275", "6430", "3376", "3406", "6342", "6434", "7503", "7898", "3384", "4189", "4144", "4030", "6387", "3369", "3399", "6343", "3942", "3385", "8826", "3316"]
}, {
    "City": "Kashechewan",
    "DBTZ": "A",
    "Name": "Kashechewan Airport",
    "Country": "Canada",
    "IATA/FAA": "ZKE",
    "Longitude": "-81.6778",
    "ICAO": "CZKE",
    "Airport ID": "5543",
    "Latitude": "52.2825",
    "Timezone": "35",
    "DST": "-5",
    "destinations": ["5482", "5490"]
}, {
    "City": "Sachigo Lake",
    "DBTZ": "A",
    "Name": "Sachigo Lake Airport",
    "Country": "Canada",
    "IATA/FAA": "ZPB",
    "Longitude": "-92.1964",
    "ICAO": "CZPB",
    "Airport ID": "5546",
    "Latitude": "53.8911",
    "Timezone": "876",
    "DST": "-6",
    "destinations": ["5544", "169"]
}, {
    "City": "Round Lake",
    "DBTZ": "A",
    "Name": "Round Lake (Weagamow Lake) Airport",
    "Country": "Canada",
    "IATA/FAA": "ZRJ",
    "Longitude": "-91.3128",
    "ICAO": "CZRJ",
    "Airport ID": "5547",
    "Latitude": "52.9436",
    "Timezone": "974",
    "DST": "-6",
    "destinations": ["5544", "5478"]
}, {
    "City": "Muskrat Dam",
    "DBTZ": "A",
    "Name": "Muskrat Dam Airport",
    "Country": "Canada",
    "IATA/FAA": "MSA",
    "Longitude": "-91.7628",
    "ICAO": "CZMD",
    "Airport ID": "5544",
    "Latitude": "53.4414",
    "Timezone": "911",
    "DST": "-6",
    "destinations": ["5468", "169", "5546", "5547"]
}, {
    "City": "Masset",
    "DBTZ": "A",
    "Name": "Masset Airport",
    "Country": "Canada",
    "IATA/FAA": "ZMT",
    "Longitude": "-132.125",
    "ICAO": "CZMT",
    "Airport ID": "5545",
    "Latitude": "54.0275",
    "Timezone": "25",
    "DST": "-8",
    "destinations": ["156"]
}, {
    "City": "Roros",
    "DBTZ": "E",
    "Name": "Roros",
    "Country": "Norway",
    "IATA/FAA": "RRS",
    "Longitude": "11.342347",
    "ICAO": "ENRO",
    "Airport ID": "656",
    "Latitude": "62.578411",
    "Timezone": "2054",
    "DST": "1",
    "destinations": ["644"]
}, {
    "City": "Rygge",
    "DBTZ": "E",
    "Name": "Moss",
    "Country": "Norway",
    "IATA/FAA": "RYG",
    "Longitude": "10.785389",
    "ICAO": "ENRY",
    "Airport ID": "657",
    "Latitude": "59.378933",
    "Timezone": "174",
    "DST": "1",
    "destinations": ["1230", "1212", "1218", "1525", "1538", "1367", "1360", "1460", "1474", "1553", "304", "599", "535", "668", "1458", "669", "3956", "1229", "478", "1606", "3998", "674", "1203", "1472", "3953", "675", "1486", "548", "337", "415", "1539", "3959", "8414", "680", "1209"]
}, {
    "City": "Orland",
    "DBTZ": "E",
    "Name": "Orland",
    "Country": "Norway",
    "IATA/FAA": "OLA",
    "Longitude": "9.604003",
    "ICAO": "ENOL",
    "Airport ID": "655",
    "Latitude": "63.698908",
    "Timezone": "28",
    "DST": "1",
    "destinations": ["644"]
}, {
    "City": "Chandigarh",
    "DBTZ": "N",
    "Name": "Chandigarh",
    "Country": "India",
    "IATA/FAA": "IXC",
    "Longitude": "76.788542",
    "ICAO": "VICG",
    "Airport ID": "3089",
    "Latitude": "30.673469",
    "Timezone": "1012",
    "DST": "5.5",
    "destinations": ["2997", "3093", "3112"]
}, {
    "City": "Lakselv",
    "DBTZ": "E",
    "Name": "Banak",
    "Country": "Norway",
    "IATA/FAA": "LKL",
    "Longitude": "24.973489",
    "ICAO": "ENNA",
    "Airport ID": "653",
    "Latitude": "70.068814",
    "Timezone": "25",
    "DST": "1",
    "destinations": ["663"]
}, {
    "City": "Molde",
    "DBTZ": "E",
    "Name": "Aro",
    "Country": "Norway",
    "IATA/FAA": "MOL",
    "Longitude": "7.2625",
    "ICAO": "ENML",
    "Airport ID": "651",
    "Latitude": "62.744722",
    "Timezone": "10",
    "DST": "1",
    "destinations": ["660", "1212", "644", "636", "609", "647"]
}, {
    "City": "Kulu",
    "DBTZ": "N",
    "Name": "Kullu Manali",
    "Country": "India",
    "IATA/FAA": "KUU",
    "Longitude": "77.154367",
    "ICAO": "VIBR",
    "Airport ID": "3085",
    "Latitude": "31.876706",
    "Timezone": "3573",
    "DST": "5.5",
    "destinations": ["3093"]
}, {
    "City": "Varanasi",
    "DBTZ": "N",
    "Name": "Varanasi",
    "Country": "India",
    "IATA/FAA": "VNS",
    "Longitude": "82.859342",
    "ICAO": "VIBN",
    "Airport ID": "3084",
    "Latitude": "25.452358",
    "Timezone": "266",
    "DST": "5.5",
    "destinations": ["3093", "3043", "3079", "2997", "3125"]
}, {
    "City": "Lamezia",
    "DBTZ": "E",
    "Name": "Lamezia Terme",
    "Country": "Italy",
    "IATA/FAA": "SUF",
    "Longitude": "16.242269",
    "ICAO": "LICA",
    "Airport ID": "1508",
    "Latitude": "38.905394",
    "Timezone": "39",
    "DST": "1",
    "destinations": ["344", "345", "352", "350", "1678", "1526", "1551", "1555", "1529", "1525", "1538", "304", "355", "4198", "1562", "548", "1539", "1509", "346", "1613", "1524"]
}, {
    "City": "Catania",
    "DBTZ": "E",
    "Name": "Catania Fontanarossa",
    "Country": "Italy",
    "IATA/FAA": "CTA",
    "Longitude": "15.0664",
    "ICAO": "LICC",
    "Airport ID": "1509",
    "Latitude": "37.466781",
    "Timezone": "39",
    "DST": "1",
    "destinations": ["344", "345", "352", "342", "350", "351", "4053", "340", "346", "1678", "580", "1538", "1382", "502", "1529", "1335", "1524", "1562", "2985", "1526", "1551", "1613", "1550", "1555", "1561", "1501", "737", "609", "599", "1510", "1525", "585", "1229", "1353", "1539", "1218", "1563", "1606", "302", "1665", "478", "1386", "492", "4309", "1528", "1489", "1657"]
}, {
    "City": "Amritsar",
    "DBTZ": "N",
    "Name": "Amritsar",
    "Country": "India",
    "IATA/FAA": "ATQ",
    "Longitude": "74.797264",
    "ICAO": "VIAR",
    "Airport ID": "3081",
    "Latitude": "31.709594",
    "Timezone": "756",
    "DST": "5.5",
    "destinations": ["3093", "2983", "2241", "2997", "3112", "2976"]
}, {
    "City": "Allahabad",
    "DBTZ": "N",
    "Name": "Allahabad",
    "Country": "India",
    "IATA/FAA": "IXD",
    "Longitude": "81.733872",
    "ICAO": "VIAL",
    "Airport ID": "3080",
    "Latitude": "25.440064",
    "Timezone": "322",
    "DST": "5.5",
    "destinations": ["2997", "3093"]
}, {
    "City": "Svalbard",
    "DBTZ": "E",
    "Name": "Longyear",
    "Country": "Norway",
    "IATA/FAA": "LYR",
    "Longitude": "15.465556",
    "ICAO": "ENSB",
    "Airport ID": "658",
    "Latitude": "78.246111",
    "Timezone": "88",
    "DST": "1",
    "destinations": ["644", "663"]
}, {
    "City": "Skien",
    "DBTZ": "E",
    "Name": "Geiteryggen",
    "Country": "Norway",
    "IATA/FAA": "SKE",
    "Longitude": "9.566944",
    "ICAO": "ENSN",
    "Airport ID": "659",
    "Latitude": "59.185",
    "Timezone": "463",
    "DST": "1",
    "destinations": ["636"]
}, {
    "City": "Kobe",
    "DBTZ": "U",
    "Name": "Kobe",
    "Country": "Japan",
    "IATA/FAA": "UKB",
    "Longitude": "135.223889",
    "ICAO": "RJBE",
    "Airport ID": "3943",
    "Latitude": "34.632778",
    "Timezone": "22",
    "DST": "9",
    "destinations": ["2287", "2359", "2281", "2307", "2313", "2384", "2347", "2330"]
}, {
    "City": "Hiva-oa",
    "DBTZ": "U",
    "Name": "Hiva Oa-Atuona Airport",
    "Country": "French Polynesia",
    "IATA/FAA": "AUQ",
    "Longitude": "-139.011256",
    "ICAO": "NTMN",
    "Airport ID": "5890",
    "Latitude": "-9.768794",
    "Timezone": "1481",
    "DST": "-9.5",
    "destinations": ["1987", "4075", "5892", "5891"]
}, {
    "City": "Ua Pou",
    "DBTZ": "U",
    "Name": "Ua Pou Airport",
    "Country": "French Polynesia",
    "IATA/FAA": "UAP",
    "Longitude": "-140.078",
    "ICAO": "NTMP",
    "Airport ID": "5891",
    "Latitude": "-9.35167",
    "Timezone": "16",
    "DST": "-9.5",
    "destinations": ["5890", "1987", "5892"]
}, {
    "City": "Ua Huka",
    "DBTZ": "U",
    "Name": "Ua Huka Airport",
    "Country": "French Polynesia",
    "IATA/FAA": "UAH",
    "Longitude": "-139.552",
    "ICAO": "NTMU",
    "Airport ID": "5892",
    "Latitude": "-8.93611",
    "Timezone": "160",
    "DST": "-9.5",
    "destinations": ["5890", "1987", "5891"]
}, {
    "City": "Ablow",
    "DBTZ": "U",
    "Name": "Mota Lava Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "MTV",
    "Longitude": "167.712",
    "ICAO": "NVSA",
    "Airport ID": "5893",
    "Latitude": "-13.666",
    "Timezone": "63",
    "DST": "11",
    "destinations": ["5894", "5895", "5906"]
}, {
    "City": "Sola",
    "DBTZ": "U",
    "Name": "Sola Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "SLH",
    "Longitude": "167.537",
    "ICAO": "NVSC",
    "Airport ID": "5894",
    "Latitude": "-13.8517",
    "Timezone": "7",
    "DST": "11",
    "destinations": ["5893", "5908", "5895"]
}, {
    "City": "Loh/Linua",
    "DBTZ": "U",
    "Name": "Torres Airstrip",
    "Country": "Vanuatu",
    "IATA/FAA": "TOH",
    "Longitude": "166.638",
    "ICAO": "NVSD",
    "Airport ID": "5895",
    "Latitude": "-13.328",
    "Timezone": "0",
    "DST": "11",
    "destinations": ["5893", "5894", "5906"]
}, {
    "City": "Sangafa",
    "DBTZ": "U",
    "Name": "Sangafa Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "EAE",
    "Longitude": "168.343",
    "ICAO": "NVSE",
    "Airport ID": "5896",
    "Latitude": "-17.0903",
    "Timezone": "7",
    "DST": "11",
    "destinations": ["1997"]
}, {
    "City": "Craig Cove",
    "DBTZ": "U",
    "Name": "Craig Cove Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "CCV",
    "Longitude": "167.924",
    "ICAO": "NVSF",
    "Airport ID": "5897",
    "Latitude": "-16.265",
    "Timezone": "69",
    "DST": "11",
    "destinations": ["5902", "5905", "5910"]
}, {
    "City": "Longana",
    "DBTZ": "U",
    "Name": "Longana Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "LOD",
    "Longitude": "167.967",
    "ICAO": "NVSG",
    "Airport ID": "5898",
    "Latitude": "-15.3067",
    "Timezone": "167",
    "DST": "11",
    "destinations": ["5904", "5908"]
}, {
    "City": "Pentecost Island",
    "DBTZ": "U",
    "Name": "Sara Airport",
    "Country": "Vanuatu",
    "IATA/FAA": "SSR",
    "Longitude": "168.152",
    "ICAO": "NVSH",
    "Airport ID": "5899",
    "Latitude": "-15.4708",
    "Timezone": "0",
    "DST": "11",
    "destinations": ["5908"]
}, {
    "City": "Bangkok",
    "DBTZ": "U",
    "Name": "Suvarnabhumi Intl",
    "Country": "Thailand",
    "IATA/FAA": "BKK",
    "Longitude": "100.747283",
    "ICAO": "VTBS",
    "Airport ID": "3885",
    "Latitude": "13.681108",
    "Timezone": "5",
    "DST": "7",
    "destinations": ["3316", "3076", "2397", "3043", "3093", "3930", "2372", "3393", "3179", "3239", "3406", "2997", "3304", "2279", "3931", "1382", "3174", "3115", "421", "2179", "507", "3272", "580", "2276", "1613", "3364", "3374", "4029", "2264", "3024", "3077", "2206", "3370", "3375", "3371", "3385", "4059", "3373", "4302", "3376", "3383", "4140", "737", "644", "2188", "3361", "1107", "3222", "3275", "2057", "2983", "3932", "2305", "2359", "3992", "3339", "3034", "3035", "6173", "3040", "2908", "2176", "3120", "340", "346", "1678", "1590", "918", "2170", "1128", "3308", "3380", "3395", "3382", "3387", "3942", "3121", "3163", "3156", "8130", "4135", "3939", "3177", "3183", "2939", "2241", "3199", "3118", "3125", "3069", "2937", "4078", "813", "3390", "4108", "609", "302", "2985", "2006", "3131", "3320", "2287", "3940", "1555", "3181", "3141", "2223", "1701", "3938", "2207", "3144", "1229", "1524", "3351", "3205", "3937", "4000", "4374", "2975", "4330", "2194"]
}, {
    "City": "Naha",
    "DBTZ": "N",
    "Name": "Naha",
    "Country": "Indonesia",
    "IATA/FAA": "NAH",
    "Longitude": "125.528019",
    "ICAO": "WAMH",
    "Airport ID": "3886",
    "Latitude": "3.683214",
    "Timezone": "16",
    "DST": "8",
    "destinations": ["3250"]
}, {
    "City": "Youngstown",
    "DBTZ": "A",
    "Name": "Youngstown Warren Rgnl",
    "Country": "United States",
    "IATA/FAA": "YNG",
    "Longitude": "-80.679097",
    "ICAO": "KYNG",
    "Airport ID": "3881",
    "Latitude": "41.260736",
    "Timezone": "1196",
    "DST": "-5",
    "destinations": ["3515", "7056", "3617", "4167"]
}, {
    "City": "Great Falls",
    "DBTZ": "A",
    "Name": "Great Falls Intl",
    "Country": "United States",
    "IATA/FAA": "GTF",
    "Longitude": "-111.370689",
    "ICAO": "KGTF",
    "Airport ID": "3880",
    "Latitude": "47.482",
    "Timezone": "3677",
    "DST": "-7",
    "destinations": ["3504", "3577", "3858", "3536", "6505", "3877", "3751"]
}, {
    "City": "Iqaluit",
    "DBTZ": "A",
    "Name": "Iqaluit",
    "Country": "Canada",
    "IATA/FAA": "YFB",
    "Longitude": "-68.555832",
    "ICAO": "CYFB",
    "Airport ID": "55",
    "Latitude": "63.75639",
    "Timezone": "110",
    "DST": "-5",
    "destinations": ["40", "5495", "100", "132", "140", "148", "170", "8225", "5509", "154"]
}, {
    "City": "Guanambi",
    "DBTZ": "S",
    "Name": "Guanambi Airport",
    "Country": "Brazil",
    "IATA/FAA": "GNM",
    "Longitude": "-42.783333",
    "ICAO": "SNGI",
    "Airport ID": "7407",
    "Latitude": "-14.216667",
    "Timezone": "500",
    "DST": "-3",
    "destinations": ["2621"]
}, {
    "City": "Nyagan",
    "DBTZ": "N",
    "Name": "Nyagan Airport",
    "Country": "Russia",
    "IATA/FAA": "NYA",
    "Longitude": "65.615",
    "ICAO": "USHN",
    "Airport ID": "6139",
    "Latitude": "62.11",
    "Timezone": "361",
    "DST": "6",
    "destinations": ["8428", "4111", "2975"]
}, {
    "City": "Inuvik",
    "DBTZ": "A",
    "Name": "Inuvik Mike Zubko",
    "Country": "Canada",
    "IATA/FAA": "YEV",
    "Longitude": "-133.482778",
    "ICAO": "CYEV",
    "Airport ID": "54",
    "Latitude": "68.304167",
    "Timezone": "224",
    "DST": "-7",
    "destinations": ["155", "68", "4244", "139", "145", "206", "4239"]
}, {
    "City": "Greensboro",
    "DBTZ": "A",
    "Name": "Piedmont Triad",
    "Country": "United States",
    "IATA/FAA": "GSO",
    "Longitude": "-79.937306",
    "ICAO": "KGSO",
    "Airport ID": "4008",
    "Latitude": "36.09775",
    "Timezone": "925",
    "DST": "-5",
    "destinations": ["3682", "3876", "3520", "3670", "3697", "3576", "3752", "3645", "3751", "3617", "4167", "3494", "3714", "3830"]
}, {
    "City": "Sioux Falls",
    "DBTZ": "A",
    "Name": "Sioux Falls",
    "Country": "United States",
    "IATA/FAA": "FSD",
    "Longitude": "-96.741914",
    "ICAO": "KFSD",
    "Airport ID": "4009",
    "Latitude": "43.582014",
    "Timezone": "1429",
    "DST": "-6",
    "destinations": ["3670", "3830", "3682", "3858", "3751", "6505", "3877", "3617", "4167"]
}, {
    "City": "Surat Thani",
    "DBTZ": "U",
    "Name": "Surat Thani",
    "Country": "Thailand",
    "IATA/FAA": "URT",
    "Longitude": "99.135556",
    "ICAO": "VTSB",
    "Airport ID": "4000",
    "Latitude": "9.1325",
    "Timezone": "286",
    "DST": "7",
    "destinations": ["3304", "3157", "3885"]
}, {
    "City": "Nyuang U",
    "DBTZ": "U",
    "Name": "Bagan Intl",
    "Country": "Burma",
    "IATA/FAA": "NYU",
    "Longitude": "94.9246666",
    "ICAO": "VYBR",
    "Airport ID": "4001",
    "Latitude": "21.173833266",
    "Timezone": "290",
    "DST": "6.5",
    "destinations": ["3222", "3239", "3213"]
}, {
    "City": "Caticlan",
    "DBTZ": "N",
    "Name": "Godofredo P",
    "Country": "Philippines",
    "IATA/FAA": "MPH",
    "Longitude": "121.953",
    "ICAO": "RPXE",
    "Airport ID": "4002",
    "Latitude": "11.9214999",
    "Timezone": "20",
    "DST": "8",
    "destinations": ["2397", "4206"]
}, {
    "City": "New Haven",
    "DBTZ": "A",
    "Name": "Tweed-New Haven Airport",
    "Country": "United States",
    "IATA/FAA": "HVN",
    "Longitude": "-72.886806",
    "ICAO": "KHVN",
    "Airport ID": "4006",
    "Latitude": "41.26375",
    "Timezone": "14",
    "DST": "-5",
    "destinations": ["3752"]
}, {
    "City": "Asheville",
    "DBTZ": "A",
    "Name": "Asheville Regional Airport",
    "Country": "United States",
    "IATA/FAA": "AVL",
    "Longitude": "-82.541806",
    "ICAO": "KAVL",
    "Airport ID": "4007",
    "Latitude": "35.436194",
    "Timezone": "2165",
    "DST": "-5",
    "destinations": ["3876", "3682", "3645", "3533", "7056", "3617", "4167", "3830"]
}, {
    "City": "Batumi",
    "DBTZ": "N",
    "Name": "Batumi",
    "Country": "Georgia",
    "IATA/FAA": "BUS",
    "Longitude": "41.599694",
    "ICAO": "UGSB",
    "Airport ID": "3971",
    "Latitude": "41.610278",
    "Timezone": "105",
    "DST": "4",
    "destinations": ["4029", "3973", "2954", "1701", "2941"]
}, {
    "City": "Tbilisi",
    "DBTZ": "N",
    "Name": "Tbilisi",
    "Country": "Georgia",
    "IATA/FAA": "TBS",
    "Longitude": "44.954722",
    "ICAO": "UGTB",
    "Airport ID": "3973",
    "Latitude": "41.669167",
    "Timezone": "1624",
    "DST": "4",
    "destinations": ["1590", "3941", "580", "3971", "1382", "3953", "1613", "2988", "1555", "2954", "3399", "4367", "2188", "2922", "2908", "1701", "2939", "346", "679", "4317", "4029", "4130", "3989", "2941"]
}, {
    "City": "Kutaisi",
    "DBTZ": "N",
    "Name": "Kopitnari",
    "Country": "Georgia",
    "IATA/FAA": "KUT",
    "Longitude": "42.482583",
    "ICAO": "UGKO",
    "Airport ID": "3972",
    "Latitude": "42.176653",
    "Timezone": "223",
    "DST": "4",
    "destinations": ["3973", "2954", "671", "3959", "679", "2940", "6105", "2944"]
}, {
    "City": "Taiz",
    "DBTZ": "U",
    "Name": "Taiz Intl",
    "Country": "Yemen",
    "IATA/FAA": "TAI",
    "Longitude": "44.139056",
    "ICAO": "OYTZ",
    "Airport ID": "3975",
    "Latitude": "13.685964",
    "Timezone": "4838",
    "DST": "3",
    "destinations": ["2064", "4119", "3980", "1128", "2072", "2082"]
}, {
    "City": "Mukalla",
    "DBTZ": "U",
    "Name": "Riyan",
    "Country": "Yemen",
    "IATA/FAA": "RIY",
    "Longitude": "49.375028",
    "ICAO": "OYRN",
    "Airport ID": "3974",
    "Latitude": "14.662639",
    "Timezone": "54",
    "DST": "3",
    "destinations": ["3977", "3980", "3984", "2191", "2179", "1128", "2188", "2072", "2176"]
}, {
    "City": "Aden",
    "DBTZ": "U",
    "Name": "Aden Intl",
    "Country": "Yemen",
    "IATA/FAA": "ADE",
    "Longitude": "45.028792",
    "ICAO": "OYAA",
    "Airport ID": "3977",
    "Latitude": "12.829542",
    "Timezone": "7",
    "DST": "3",
    "destinations": ["2059", "2064", "5954", "5687", "3974", "3980", "2191", "2170", "2179", "2997", "1128", "2241", "2188", "2072", "2082", "1701", "3967", "1122"]
}, {
    "City": "Hodeidah",
    "DBTZ": "U",
    "Name": "Hodeidah Intl",
    "Country": "Yemen",
    "IATA/FAA": "HOD",
    "Longitude": "42.976336",
    "ICAO": "OYHD",
    "Airport ID": "3976",
    "Latitude": "14.753",
    "Timezone": "41",
    "DST": "3",
    "destinations": ["3980", "2170", "1128", "2072"]
}, {
    "City": "Al Ghaidah Intl",
    "DBTZ": "U",
    "Name": "Al Ghaidah Intl",
    "Country": "Yemen",
    "IATA/FAA": "AAY",
    "Longitude": "52.175",
    "ICAO": "OYGD",
    "Airport ID": "3979",
    "Latitude": "16.191667",
    "Timezone": "134",
    "DST": "3",
    "destinations": ["3980"]
}, {
    "City": "Ataq",
    "DBTZ": "U",
    "Name": "Ataq",
    "Country": "Yemen",
    "IATA/FAA": "AXK",
    "Longitude": "46.826183",
    "ICAO": "OYAT",
    "Airport ID": "3978",
    "Latitude": "14.551322",
    "Timezone": "3735",
    "DST": "3",
    "destinations": ["3980"]
}, {
    "City": "Kamloops",
    "DBTZ": "A",
    "Name": "Kamloops",
    "Country": "Canada",
    "IATA/FAA": "YKA",
    "Longitude": "-120.444444",
    "ICAO": "CYKA",
    "Airport ID": "78",
    "Latitude": "50.702222",
    "Timezone": "1133",
    "DST": "-8",
    "destinations": ["156", "172", "178"]
}, {
    "City": "Kenai",
    "DBTZ": "A",
    "Name": "Kenai Muni",
    "Country": "United States",
    "IATA/FAA": "ENA",
    "Longitude": "-151.245",
    "ICAO": "PAEN",
    "Airport ID": "3628",
    "Latitude": "60.573111",
    "Timezone": "99",
    "DST": "-9",
    "destinations": ["3774"]
}, {
    "City": "San Antonio",
    "DBTZ": "A",
    "Name": "San Antonio Intl",
    "Country": "United States",
    "IATA/FAA": "SAT",
    "Longitude": "-98.469778",
    "ICAO": "KSAT",
    "Airport ID": "3621",
    "Latitude": "29.533694",
    "Timezone": "809",
    "DST": "-6",
    "destinations": ["1804", "1824", "1825", "1850", "3876", "3670", "3830", "3752", "3462", "3682", "3577", "3645", "3797", "3878", "3473", "3858", "3536", "3849", "1852", "3502", "3751", "3566", "3877", "3484", "3747", "3678", "3646", "3494", "3714", "3550", "3469", "3690", "3559", "3560", "3731"]
}, {
    "City": "Deadhorse",
    "DBTZ": "A",
    "Name": "Deadhorse",
    "Country": "United States",
    "IATA/FAA": "SCC",
    "Longitude": "-148.465167",
    "ICAO": "PASC",
    "Airport ID": "3620",
    "Latitude": "70.19475",
    "Timezone": "64",
    "DST": "-9",
    "destinations": ["3411", "3832", "7092", "3774", "3571"]
}, {
    "City": "Rochester",
    "DBTZ": "A",
    "Name": "Greater Rochester Intl",
    "Country": "United States",
    "IATA/FAA": "ROC",
    "Longitude": "-77.672389",
    "ICAO": "KROC",
    "Airport ID": "3622",
    "Latitude": "43.118866",
    "Timezone": "559",
    "DST": "-5",
    "destinations": ["3448", "3876", "3520", "3830", "3752", "193", "3682", "3797", "3645", "3697", "3858", "3878", "3747", "3486", "3494", "3714", "3849", "3646"]
}, {
    "City": "Teterboro",
    "DBTZ": "A",
    "Name": "Teterboro",
    "Country": "United States",
    "IATA/FAA": "TEB",
    "Longitude": "-74.060837",
    "ICAO": "KTEB",
    "Airport ID": "3624",
    "Latitude": "40.850103",
    "Timezone": "9",
    "DST": "-5",
    "destinations": ["3517"]
}, {
    "City": "Dayton",
    "DBTZ": "A",
    "Name": "James M Cox Dayton Intl",
    "Country": "United States",
    "IATA/FAA": "DAY",
    "Longitude": "-84.219375",
    "ICAO": "KDAY",
    "Airport ID": "3627",
    "Latitude": "39.902375",
    "Timezone": "1009",
    "DST": "-5",
    "destinations": ["3876", "3520", "3670", "3697", "3830", "3752", "3682", "3645", "3858", "3849", "3646", "3486", "3751", "3494", "3714", "3878"]
}, {
    "City": "Raleigh-durham",
    "DBTZ": "A",
    "Name": "Raleigh Durham Intl",
    "Country": "United States",
    "IATA/FAA": "RDU",
    "Longitude": "-78.787472",
    "ICAO": "KRDU",
    "Airport ID": "3626",
    "Latitude": "35.877639",
    "Timezone": "435",
    "DST": "-5",
    "destinations": ["3876", "3520", "3670", "3797", "3484", "3697", "507", "3576", "3830", "3752", "3570", "193", "3682", "1852", "3448", "3533", "3825", "3849", "3759", "3488", "3645", "3585", "3877", "3878", "3473", "3858", "3536", "3646", "3447", "3690", "3747", "3469", "3494", "3714", "3550", "3751", "3566", "3462", "3678"]
}, {
    "City": "Miho",
    "DBTZ": "U",
    "Name": "Yonago Kitaro",
    "Country": "Japan",
    "IATA/FAA": "YGJ",
    "Longitude": "133.236389",
    "ICAO": "RJOH",
    "Airport ID": "2330",
    "Latitude": "35.492222",
    "Timezone": "20",
    "DST": "9",
    "destinations": ["2287", "2359", "2279", "2384", "3943", "3930"]
}, {
    "City": "Kochi",
    "DBTZ": "U",
    "Name": "Kochi",
    "Country": "Japan",
    "IATA/FAA": "KCZ",
    "Longitude": "133.669444",
    "ICAO": "RJOK",
    "Airport ID": "2332",
    "Latitude": "33.546111",
    "Timezone": "42",
    "DST": "9",
    "destinations": ["5997", "2359", "2334"]
}, {
    "City": "Matsuyama",
    "DBTZ": "U",
    "Name": "Matsuyama",
    "Country": "Japan",
    "IATA/FAA": "MYJ",
    "Longitude": "132.699722",
    "ICAO": "RJOM",
    "Airport ID": "2333",
    "Latitude": "33.827222",
    "Timezone": "25",
    "DST": "9",
    "destinations": ["2279", "2359", "2334", "3406", "3992", "3930", "3942", "2384"]
}, {
    "City": "Osaka",
    "DBTZ": "U",
    "Name": "Osaka Intl",
    "Country": "Japan",
    "IATA/FAA": "ITM",
    "Longitude": "135.438222",
    "ICAO": "RJOO",
    "Airport ID": "2334",
    "Latitude": "34.785528",
    "Timezone": "50",
    "DST": "9",
    "destinations": ["2279", "2287", "5999", "2305", "2310", "2347", "2340", "2316", "2345", "2341", "2288", "2344", "2359", "4381", "2308", "2312", "2307", "2346", "2333", "2313", "2384", "2332"]
}, {
    "City": "Tottori",
    "DBTZ": "U",
    "Name": "Tottori",
    "Country": "Japan",
    "IATA/FAA": "TTJ",
    "Longitude": "134.166553",
    "ICAO": "RJOR",
    "Airport ID": "2335",
    "Latitude": "35.530069",
    "Timezone": "65",
    "DST": "9",
    "destinations": ["2359"]
}, {
    "City": "Tokushima",
    "DBTZ": "U",
    "Name": "Tokushima",
    "Country": "Japan",
    "IATA/FAA": "TKS",
    "Longitude": "134.606639",
    "ICAO": "RJOS",
    "Airport ID": "2336",
    "Latitude": "34.132808",
    "Timezone": "26",
    "DST": "9",
    "destinations": ["2359"]
}, {
    "City": "Takamatsu",
    "DBTZ": "U",
    "Name": "Takamatsu",
    "Country": "Japan",
    "IATA/FAA": "TAK",
    "Longitude": "134.015556",
    "ICAO": "RJOT",
    "Airport ID": "2337",
    "Latitude": "34.214167",
    "Timezone": "607",
    "DST": "9",
    "destinations": ["3406", "2276", "2279", "2359", "3930", "2384"]
}, {
    "City": "Kingman",
    "DBTZ": "A",
    "Name": "Kingman Airport",
    "Country": "United States",
    "IATA/FAA": "IGM",
    "Longitude": "-113.938",
    "ICAO": "IGM",
    "Airport ID": "6132",
    "Latitude": "35.2595",
    "Timezone": "3449",
    "DST": "-7",
    "destinations": ["4124", "3484"]
}, {
    "City": "Pasco",
    "DBTZ": "A",
    "Name": "Tri Cities Airport",
    "Country": "United States",
    "IATA/FAA": "PSC",
    "Longitude": "-119.119",
    "ICAO": "PSC",
    "Airport ID": "6133",
    "Latitude": "46.2647",
    "Timezone": "410",
    "DST": "-8",
    "destinations": ["3720", "3577", "3858", "3536", "6505", "3877", "3751", "3469"]
}, {
    "City": "Greenville",
    "DBTZ": "A",
    "Name": "Mid Delta Regional Airport",
    "Country": "United States",
    "IATA/FAA": "GLH",
    "Longitude": "-90.9856",
    "ICAO": "GLH",
    "Airport ID": "6130",
    "Latitude": "33.4829",
    "Timezone": "131",
    "DST": "-6",
    "destinations": ["5773"]
}, {
    "City": "Lopez",
    "DBTZ": "A",
    "Name": "Lopez Island Airport",
    "Country": "United States",
    "IATA/FAA": "LPS",
    "Longitude": "-122.938",
    "ICAO": "S31",
    "Airport ID": "6136",
    "Latitude": "48.4839",
    "Timezone": "209",
    "DST": "-8",
    "destinations": ["8173"]
}, {
    "City": "Salekhard",
    "DBTZ": "N",
    "Name": "Salekhard Airport",
    "Country": "Russia",
    "IATA/FAA": "SLY",
    "Longitude": "66.611042",
    "ICAO": "USDD",
    "Airport ID": "6137",
    "Latitude": "66.590753",
    "Timezone": "218",
    "DST": "6",
    "destinations": ["4078", "4029", "4365", "4364", "6143", "2975", "4111"]
}, {
    "City": "Akutan",
    "DBTZ": "A",
    "Name": "Akutan Seaplane Base",
    "Country": "United States",
    "IATA/FAA": "KQA",
    "Longitude": "-165.785",
    "ICAO": "KQA",
    "Airport ID": "6134",
    "Latitude": "54.1325",
    "Timezone": "0",
    "DST": "-9",
    "destinations": ["3860"]
}, {
    "City": "Silver City",
    "DBTZ": "A",
    "Name": "Grant County Airport",
    "Country": "United States",
    "IATA/FAA": "SVC",
    "Longitude": "-108.156",
    "ICAO": "SVC",
    "Airport ID": "6135",
    "Latitude": "32.6365",
    "Timezone": "5446",
    "DST": "-7",
    "destinations": ["3462"]
}, {
    "City": "Haifa",
    "DBTZ": "U",
    "Name": "Haifa",
    "Country": "Israel",
    "IATA/FAA": "HFA",
    "Longitude": "35.043056",
    "ICAO": "LLHA",
    "Airport ID": "1596",
    "Latitude": "32.809444",
    "Timezone": "28",
    "DST": "2",
    "destinations": ["1594", "1605"]
}, {
    "City": "Omsk",
    "DBTZ": "N",
    "Name": "Omsk",
    "Country": "Russia",
    "IATA/FAA": "OMS",
    "Longitude": "73.310514",
    "ICAO": "UNOO",
    "Airport ID": "2958",
    "Latitude": "54.967042",
    "Timezone": "311",
    "DST": "7",
    "destinations": ["4029", "2979", "3964", "2922", "2972", "4364", "4078", "2983", "2910", "4317", "2965", "2948", "2985", "8076", "6138"]
}, {
    "City": "Tel-aviv",
    "DBTZ": "U",
    "Name": "Ben Gurion",
    "Country": "Israel",
    "IATA/FAA": "TLV",
    "Longitude": "34.886667",
    "ICAO": "LLBG",
    "Airport ID": "1590",
    "Latitude": "32.011389",
    "Timezone": "135",
    "DST": "2",
    "destinations": ["1128", "344", "1594", "1555", "1354", "337", "3973", "3941", "1452", "3752", "345", "346", "351", "146", "193", "1382", "1353", "421", "2954", "507", "3953", "3364", "1197", "3797", "737", "609", "1107", "1194", "1613", "580", "2983", "1218", "1229", "1524", "1561", "2170", "2922", "1739", "3930", "340", "679", "1665", "1678", "3885", "2997", "302", "1489", "4029", "3494", "3077", "813", "2939", "3484", "2948", "492", "1657", "1587", "1551", "4317", "2941", "2940", "2947", "2960", "2962", "1655", "2964", "2985", "1701", "1386", "4053", "502", "478", "2975", "2988", "8209", "1652", "671", "3959", "2945"]
}, {
    "City": "College Station",
    "DBTZ": "A",
    "Name": "Easterwood Fld",
    "Country": "United States",
    "IATA/FAA": "CLL",
    "Longitude": "-96.363833",
    "ICAO": "KCLL",
    "Airport ID": "3715",
    "Latitude": "30.588583",
    "Timezone": "321",
    "DST": "-6",
    "destinations": ["3670", "3550"]
}, {
    "City": "Washington",
    "DBTZ": "A",
    "Name": "Washington Dulles Intl",
    "Country": "United States",
    "IATA/FAA": "IAD",
    "Longitude": "-77.455811",
    "ICAO": "KIAD",
    "Airport ID": "3714",
    "Latitude": "38.944533",
    "Timezone": "313",
    "DST": "-5",
    "destinations": ["2179", "3876", "3670", "2241", "3484", "507", "3576", "3861", "100", "146", "193", "3682", "1382", "1824", "2709", "1892", "3448", "2188", "3797", "3582", "3364", "1871", "580", "3645", "3930", "3858", "2985", "599", "1107", "16", "3751", "3747", "3646", "302", "340", "346", "6739", "5751", "1665", "1678", "2279", "1613", "144", "1084", "609", "2072", "2082", "1701", "3864", "3694", "2895", "3673", "3825", "4129", "5724", "3690", "3711", "3820", "3561", "4015", "3806", "3486", "3759", "3819", "4285", "1852", "3488", "3627", "3494", "5738", "1555", "3533", "1926", "3685", "2564", "4008", "4034", "1767", "3728", "4113", "3550", "3585", "3712", "4169", "2176", "3877", "3697", "6958", "478", "1780", "3458", "3878", "3542", "4284", "3863", "3454", "3830", "3611", "3720", "3752", "3462", "3570", "1760", "3641", "3862", "3626", "3608", "4016", "3622", "3731", "3621", "3613", "4318", "4014", "3577", "3469", "7062", "1885", "2890", "3817", "3678", "2883", "2899", "3745", "3855", "3676"]
}, {
    "City": "Milwaukee",
    "DBTZ": "A",
    "Name": "General Mitchell Intl",
    "Country": "United States",
    "IATA/FAA": "MKE",
    "Longitude": "-87.896583",
    "ICAO": "KMKE",
    "Airport ID": "3717",
    "Latitude": "42.947222",
    "Timezone": "723",
    "DST": "-6",
    "destinations": ["3876", "3670", "3830", "3752", "3462", "193", "3682", "3488", "3645", "3697", "3878", "3858", "3751", "3448", "3849", "1852", "3520", "3533", "3877", "3484", "3458", "3861", "3793", "3577", "3469", "3678", "3646", "3486", "3494", "3550"]
}, {
    "City": "Burlington",
    "DBTZ": "A",
    "Name": "Burlington Intl",
    "Country": "United States",
    "IATA/FAA": "BTV",
    "Longitude": "-73.153278",
    "ICAO": "KBTV",
    "Airport ID": "3711",
    "Latitude": "44.471861",
    "Timezone": "335",
    "DST": "-5",
    "destinations": ["3520", "3752", "3797", "3682", "3645", "3697", "4167", "3494", "3714", "3830"]
}, {
    "City": "Chico",
    "DBTZ": "A",
    "Name": "Chico Muni",
    "Country": "United States",
    "IATA/FAA": "CIC",
    "Longitude": "-121.858422",
    "ICAO": "KCIC",
    "Airport ID": "3710",
    "Latitude": "39.795383",
    "Timezone": "238",
    "DST": "-8",
    "destinations": ["3469"]
}, {
    "City": "Durango",
    "DBTZ": "A",
    "Name": "Durango La Plata Co",
    "Country": "United States",
    "IATA/FAA": "DRO",
    "Longitude": "-107.75377",
    "ICAO": "KDRO",
    "Airport ID": "3713",
    "Latitude": "37.151516",
    "Timezone": "6685",
    "DST": "-7",
    "destinations": ["3670", "3462", "3751"]
}, {
    "City": "Jacksonville",
    "DBTZ": "A",
    "Name": "Jacksonville Intl",
    "Country": "United States",
    "IATA/FAA": "JAX",
    "Longitude": "-81.687861",
    "ICAO": "KJAX",
    "Airport ID": "3712",
    "Latitude": "30.494056",
    "Timezone": "30",
    "DST": "-5",
    "destinations": ["1930", "3646", "3876", "3520", "3670", "3576", "3830", "3752", "3682", "3448", "3797", "2890", "3488", "3645", "3697", "3858", "3849", "3494", "3714", "3550", "3690", "3751", "3533", "3566", "3877", "3747"]
}, {
    "City": "Hong Kong",
    "DBTZ": "U",
    "Name": "Hong Kong Intl",
    "Country": "Hong Kong",
    "IATA/FAA": "HKG",
    "Longitude": "113.914603",
    "ICAO": "VHHH",
    "Airport ID": "3077",
    "Latitude": "22.308919",
    "Timezone": "28",
    "DST": "8",
    "destinations": ["3316", "3395", "6361", "6357", "4206", "4140", "2429", "2397", "3930", "3406", "2997", "3093", "3494", "3797", "3484", "2279", "3830", "3469", "156", "193", "2268", "1382", "3992", "3269", "3304", "3320", "3322", "421", "3339", "3361", "507", "3076", "3272", "2276", "2372", "3393", "3404", "3386", "3364", "3368", "3376", "3275", "2264", "2270", "3341", "2006", "580", "2179", "3885", "3131", "3370", "3043", "3375", "2370", "3931", "3371", "2287", "3196", "4029", "2241", "3940", "2188", "1555", "3385", "340", "2305", "4120", "3199", "3179", "2359", "3141", "813", "2927", "3382", "3372", "3144", "3156", "1524", "1960", "3387", "3942", "3388", "2384", "3308", "3351", "2082", "3205", "3391", "3928", "4030", "3390", "3177", "2934", "6392", "3379", "3383", "6354", "4144", "4302", "1107", "3157", "2307", "3394", "3373", "3369", "4149", "3034", "3035", "3239", "2908", "346", "1678", "1590", "6386", "6391", "893", "3380", "3389", "7898", "4085", "3384", "4033", "6383", "4108", "6390", "3392", "6393", "5", "3125", "4078", "2985", "1701", "2246"]
}, {
    "City": "Columbia",
    "DBTZ": "A",
    "Name": "Columbia Rgnl",
    "Country": "United States",
    "IATA/FAA": "COU",
    "Longitude": "-92.219631",
    "ICAO": "KCOU",
    "Airport ID": "3719",
    "Latitude": "38.818094",
    "Timezone": "889",
    "DST": "-6",
    "destinations": ["3670", "3830"]
}, {
    "City": "Abilene",
    "DBTZ": "A",
    "Name": "Abilene Rgnl",
    "Country": "United States",
    "IATA/FAA": "ABI",
    "Longitude": "-99.681897",
    "ICAO": "KABI",
    "Airport ID": "3718",
    "Latitude": "32.411319",
    "Timezone": "1791",
    "DST": "-6",
    "destinations": ["3670"]
}, {
    "City": "Agra",
    "DBTZ": "N",
    "Name": "Agra",
    "Country": "India",
    "IATA/FAA": "AGR",
    "Longitude": "77.960892",
    "ICAO": "VIAG",
    "Airport ID": "3079",
    "Latitude": "27.155831",
    "Timezone": "551",
    "DST": "5.5",
    "destinations": ["2997", "3012"]
}, {
    "City": "Sokcho / Gangneung",
    "DBTZ": "U",
    "Name": "Yangyang International Airport",
    "Country": "South Korea",
    "IATA/FAA": "YNY",
    "Longitude": "128.669164",
    "ICAO": "RKNY",
    "Airport ID": "6006",
    "Latitude": "38.061311",
    "Timezone": "241",
    "DST": "9",
    "destinations": ["3406"]
}, {
    "City": "Sacheon",
    "DBTZ": "U",
    "Name": "Sacheon Air Base",
    "Country": "South Korea",
    "IATA/FAA": "HIN",
    "Longitude": "128.07037",
    "ICAO": "RKPS",
    "Airport ID": "6007",
    "Latitude": "35.088543",
    "Timezone": "25",
    "DST": "9",
    "destinations": ["2370", "2378"]
}, {
    "City": "Wonju",
    "DBTZ": "U",
    "Name": "Wonju Airport",
    "Country": "South Korea",
    "IATA/FAA": "WJU",
    "Longitude": "127.960383",
    "ICAO": "RKNW",
    "Airport ID": "6005",
    "Latitude": "37.438081",
    "Timezone": "329",
    "DST": "9",
    "destinations": ["2370"]
}, {
    "City": "Kunsan",
    "DBTZ": "U",
    "Name": "Kunsan Air Base",
    "Country": "South Korea",
    "IATA/FAA": "KUV",
    "Longitude": "126.615906",
    "ICAO": "RKJK",
    "Airport ID": "6003",
    "Latitude": "35.903756",
    "Timezone": "29",
    "DST": "9",
    "destinations": ["2370"]
}, {
    "City": "Esquel",
    "DBTZ": "N",
    "Name": "Esquel",
    "Country": "Argentina",
    "IATA/FAA": "EQS",
    "Longitude": "-71.139472",
    "ICAO": "SAVE",
    "Airport ID": "2488",
    "Latitude": "-42.90795",
    "Timezone": "2621",
    "DST": "-3",
    "destinations": ["2513", "2487", "2442"]
}, {
    "City": "Shonai",
    "DBTZ": "U",
    "Name": "Shonai Airport",
    "Country": "Japan",
    "IATA/FAA": "SYO",
    "Longitude": "139.787222",
    "ICAO": "RJSY",
    "Airport ID": "6001",
    "Latitude": "38.812222",
    "Timezone": "86",
    "DST": "9",
    "destinations": ["2359"]
}, {
    "City": "Comodoro Rivadavia",
    "DBTZ": "N",
    "Name": "Comodoro Rivadavia",
    "Country": "Argentina",
    "IATA/FAA": "CRD",
    "Longitude": "-67.465508",
    "ICAO": "SAVC",
    "Airport ID": "2487",
    "Latitude": "-45.785347",
    "Timezone": "190",
    "DST": "-3",
    "destinations": ["2509", "2490", "2496", "2442"]
}, {
    "City": "Chongju",
    "DBTZ": "U",
    "Name": "Cheongju International Airport",
    "Country": "South Korea",
    "IATA/FAA": "CJJ",
    "Longitude": "127.499119",
    "ICAO": "RKTU",
    "Airport ID": "6008",
    "Latitude": "36.7166",
    "Timezone": "191",
    "DST": "9",
    "destinations": ["2370", "3364", "3386", "4144"]
}, {
    "City": "Majuro",
    "DBTZ": "U",
    "Name": "Marshall Islands Intl",
    "Country": "Marshall Islands",
    "IATA/FAA": "MAJ",
    "Longitude": "171.272022",
    "ICAO": "PKMJ",
    "Airport ID": "2249",
    "Latitude": "7.064758",
    "Timezone": "6",
    "DST": "12",
    "destinations": ["4076", "1965", "3728", "2251"]
}, {
    "City": "Saipan",
    "DBTZ": "U",
    "Name": "Francisco C Ada Saipan Intl",
    "Country": "Northern Mariana Islands",
    "IATA/FAA": "SPN",
    "Longitude": "145.729356",
    "ICAO": "PGSN",
    "Airport ID": "2244",
    "Latitude": "15.119003",
    "Timezone": "215",
    "DST": "10",
    "destinations": ["3370", "3406", "3942", "2279", "3364", "3930", "3992", "2372", "2246", "2243"]
}, {
    "City": "Agana",
    "DBTZ": "U",
    "Name": "Guam Intl",
    "Country": "Guam",
    "IATA/FAA": "GUM",
    "Longitude": "144.795983",
    "ICAO": "PGUM",
    "Airport ID": "2246",
    "Latitude": "13.48345",
    "Timezone": "298",
    "DST": "10",
    "destinations": ["3930", "2276", "3992", "3942", "2279", "2372", "2287", "2305", "2327", "2347", "2397", "3322", "3077", "3728", "2243", "2256", "2244", "2254", "2258"]
}, {
    "City": "Doha",
    "DBTZ": "U",
    "Name": "Doha Intl",
    "Country": "Qatar",
    "IATA/FAA": "DOH",
    "Longitude": "51.565056",
    "ICAO": "OTBD",
    "Airport ID": "2241",
    "Latitude": "25.261125",
    "Timezone": "35",
    "DST": "3",
    "destinations": ["2997", "3136", "3093", "2057", "3714", "3550", "3797", "3830", "3752", "340", "580", "2194", "2188", "3941", "1218", "1665", "3077", "1229", "1551", "2179", "8076", "2191", "5934", "2157", "3137", "3977", "3980", "2922", "3992", "2279", "2176", "2177", "3304", "2082", "1128", "3930", "4317", "2219", "2397", "1107", "210", "2994", "2170", "737", "3081", "4130", "3885", "3131", "302", "2234", "1489", "3370", "3043", "1382", "3275", "3393", "3024", "1074", "609", "4140", "3395", "3076", "1177", "4029", "2064", "1187", "3989", "2067", "1682", "1555", "3007", "2564", "3410", "3386", "3141", "4330", "2223", "1701", "5952", "2072", "813", "2206", "1175", "3125", "1197", "2207", "507", "273", "1132", "3144", "478", "2074", "3339", "4331", "3156", "346", "1524", "4059", "7490", "644", "1657", "3364", "3351", "3406", "3239", "3205", "3316", "2195", "2090", "3153", "287", "351", "1613", "679", "146", "1678"]
}, {
    "City": "Rota",
    "DBTZ": "U",
    "Name": "Rota Intl",
    "Country": "Northern Mariana Islands",
    "IATA/FAA": "ROP",
    "Longitude": "145.242536",
    "ICAO": "PGRO",
    "Airport ID": "2243",
    "Latitude": "14.174308",
    "Timezone": "607",
    "DST": "10",
    "destinations": ["2246", "2244"]
}, {
    "City": "Araxa",
    "DBTZ": "S",
    "Name": "Araxa Airport",
    "Country": "Brazil",
    "IATA/FAA": "AAX",
    "Longitude": "-46.960278",
    "ICAO": "SBAX",
    "Airport ID": "7395",
    "Latitude": "-19.563056",
    "Timezone": "3276",
    "DST": "-3",
    "destinations": ["8237"]
}, {
    "City": "Rio Verde",
    "DBTZ": "S",
    "Name": "General leite de Castro Airport",
    "Country": "Brazil",
    "IATA/FAA": "RVD",
    "Longitude": "-50.918333",
    "ICAO": "SWLC",
    "Airport ID": "7394",
    "Latitude": "-17.790278",
    "Timezone": "2244",
    "DST": "-3",
    "destinations": ["2578"]
}, {
    "City": "Bingol",
    "DBTZ": "E",
    "Name": "Bingol",
    "Country": "Turkey",
    "IATA/FAA": "BGG",
    "Longitude": "40.5925",
    "ICAO": "LTCU",
    "Airport ID": "9272",
    "Latitude": "38.86111",
    "Timezone": "3490",
    "DST": "2",
    "destinations": ["1682", "1701"]
}, {
    "City": "San Luis",
    "DBTZ": "N",
    "Name": "San Luis",
    "Country": "Argentina",
    "IATA/FAA": "LUQ",
    "Longitude": "-66.356422",
    "ICAO": "SAOU",
    "Airport ID": "2467",
    "Latitude": "-33.273192",
    "Timezone": "2329",
    "DST": "-3",
    "destinations": ["2442"]
}, {
    "City": "Tucuman",
    "DBTZ": "N",
    "Name": "Teniente Benjamin Matienzo",
    "Country": "Argentina",
    "IATA/FAA": "TUC",
    "Longitude": "-65.104944",
    "ICAO": "SANT",
    "Airport ID": "2460",
    "Latitude": "-26.840861",
    "Timezone": "1495",
    "DST": "-3",
    "destinations": ["2442"]
}, {
    "City": "San Julian",
    "DBTZ": "N",
    "Name": "San Juan",
    "Country": "Argentina",
    "IATA/FAA": "UAQ",
    "Longitude": "-68.418194",
    "ICAO": "SANU",
    "Airport ID": "2461",
    "Latitude": "-31.571472",
    "Timezone": "1959",
    "DST": "-3",
    "destinations": ["2442", "2452"]
}, {
    "City": "Barcelos",
    "DBTZ": "S",
    "Name": "Barcelos Airport",
    "Country": "Brazil",
    "IATA/FAA": "BAZ",
    "Longitude": "-62.923889",
    "ICAO": "SWBC",
    "Airport ID": "7399",
    "Latitude": "-0.975",
    "Timezone": "500",
    "DST": "-4",
    "destinations": ["2551"]
}, {
    "City": "Resistencia",
    "DBTZ": "N",
    "Name": "Resistencia",
    "Country": "Argentina",
    "IATA/FAA": "RES",
    "Longitude": "-59.056125",
    "ICAO": "SARE",
    "Airport ID": "2469",
    "Latitude": "-27.449986",
    "Timezone": "173",
    "DST": "-3",
    "destinations": ["2442"]
}, {
    "City": "Regina",
    "DBTZ": "N",
    "Name": "Regina Intl",
    "Country": "Canada",
    "IATA/FAA": "YQR",
    "Longitude": "-104.665833",
    "ICAO": "CYQR",
    "Airport ID": "120",
    "Latitude": "50.431944",
    "Timezone": "1894",
    "DST": "-6",
    "destinations": ["3751", "3830", "49", "156", "160", "178", "193", "3858", "3877"]
}, {
    "City": "Thunder Bay",
    "DBTZ": "A",
    "Name": "Thunder Bay",
    "Country": "Canada",
    "IATA/FAA": "YQT",
    "Longitude": "-89.323889",
    "ICAO": "CYQT",
    "Airport ID": "121",
    "Latitude": "48.371944",
    "Timezone": "653",
    "DST": "-5",
    "destinations": ["160", "193", "5479", "21", "143", "169", "144", "5491", "5533"]
}, {
    "City": "Grande Prairie",
    "DBTZ": "A",
    "Name": "Grande Prairie",
    "Country": "Canada",
    "IATA/FAA": "YQU",
    "Longitude": "-118.885",
    "ICAO": "CYQU",
    "Airport ID": "122",
    "Latitude": "55.179722",
    "Timezone": "2195",
    "DST": "-7",
    "destinations": ["49", "178"]
}, {
    "City": "Gander",
    "DBTZ": "A",
    "Name": "Gander Intl",
    "Country": "Canada",
    "IATA/FAA": "YQX",
    "Longitude": "-54.568056",
    "ICAO": "CYQX",
    "Airport ID": "125",
    "Latitude": "48.936944",
    "Timezone": "496",
    "DST": "-3.5",
    "destinations": ["73", "188", "189"]
}, {
    "City": "Sydney",
    "DBTZ": "A",
    "Name": "Sydney",
    "Country": "Canada",
    "IATA/FAA": "YQY",
    "Longitude": "-60.047779",
    "ICAO": "CYQY",
    "Airport ID": "126",
    "Latitude": "46.161388",
    "Timezone": "203",
    "DST": "-4",
    "destinations": ["73", "193"]
}, {
    "City": "Quesnel",
    "DBTZ": "A",
    "Name": "Quesnel",
    "Country": "Canada",
    "IATA/FAA": "YQZ",
    "Longitude": "-122.510278",
    "ICAO": "CYQZ",
    "Airport ID": "127",
    "Latitude": "53.026112",
    "Timezone": "1789",
    "DST": "-8",
    "destinations": ["156", "162"]
}, {
    "City": "Resolute",
    "DBTZ": "A",
    "Name": "Resolute Bay",
    "Country": "Canada",
    "IATA/FAA": "YRB",
    "Longitude": "-94.969444",
    "ICAO": "CYRB",
    "Airport ID": "128",
    "Latitude": "74.716944",
    "Timezone": "215",
    "DST": "-6",
    "destinations": ["5498", "8225"]
}, {
    "City": "Kuala Lumpur",
    "DBTZ": "N",
    "Name": "Subang-Sultan Abdul Aziz Shah Intl",
    "Country": "Malaysia",
    "IATA/FAA": "SZB",
    "Longitude": "101.549333",
    "ICAO": "WMSA",
    "Airport ID": "3408",
    "Latitude": "3.130583",
    "Timezone": "90",
    "DST": "8",
    "destinations": ["3296", "3903", "3303", "3298", "3300", "3305", "3308", "3273", "3316", "3307", "3177", "4013"]
}, {
    "City": "Isle Of Man",
    "DBTZ": "E",
    "Name": "Isle Of Man",
    "Country": "Isle of Man",
    "IATA/FAA": "IOM",
    "Longitude": "-4.623889",
    "ICAO": "EGNS",
    "Airport ID": "520",
    "Latitude": "54.083333",
    "Timezone": "52",
    "DST": "0",
    "destinations": ["503", "469", "491", "478", "599", "502", "467", "514", "472", "521"]
}, {
    "City": "Xi'an",
    "DBTZ": "U",
    "Name": "Xianyang",
    "Country": "China",
    "IATA/FAA": "XIY",
    "Longitude": "108.751592",
    "ICAO": "ZLXY",
    "Airport ID": "3379",
    "Latitude": "34.447119",
    "Timezone": "1572",
    "DST": "8",
    "destinations": ["6351", "3393", "3395", "3385", "9311", "3400", "3382", "4097", "6385", "4108", "3399", "6368", "3383", "6366", "4110", "6346", "4033", "3391", "4144", "421", "2276", "3371", "3373", "3368", "2372", "3370", "3404", "3386", "4085", "6380", "3394", "3372", "6431", "3388", "3364", "3406", "3374", "3390", "6392", "6390", "3392", "8876", "3077", "4308", "6367", "3389", "3384", "4030", "3369", "3376", "6403", "3157", "6434", "6944", "6352", "6344", "7503", "6345", "7898", "6743", "6365", "6399", "6401", "8630", "6361", "7987", "6955", "6394", "6353", "4120", "6347", "6387", "3930", "4380", "4109", "6363", "6432", "6428", "4301", "3397", "3387", "6393", "6400", "6391"]
}, {
    "City": "Rankin Inlet",
    "DBTZ": "A",
    "Name": "Rankin Inlet",
    "Country": "Canada",
    "IATA/FAA": "YRT",
    "Longitude": "-92.115833",
    "ICAO": "CYRT",
    "Airport ID": "132",
    "Latitude": "62.81139",
    "Timezone": "94",
    "DST": "-6",
    "destinations": ["29", "5487", "50", "55", "147", "187", "196", "41", "160", "5534", "141"]
}, {
    "City": "Luwuk",
    "DBTZ": "N",
    "Name": "Bubung",
    "Country": "Indonesia",
    "IATA/FAA": "LUW",
    "Longitude": "122.771906",
    "ICAO": "WAMW",
    "Airport ID": "3254",
    "Latitude": "-1.038919",
    "Timezone": "56",
    "DST": "8",
    "destinations": ["3240"]
}, {
    "City": "Argyle",
    "DBTZ": "O",
    "Name": "Port Augusta Airport",
    "Country": "Australia",
    "IATA/FAA": "PUG",
    "Longitude": "137.716667",
    "ICAO": "YPAG",
    "Airport ID": "6312",
    "Latitude": "-32.506944",
    "Timezone": "56",
    "DST": "9.5",
    "destinations": ["3341"]
}, {
    "City": "Pyongyang",
    "DBTZ": "U",
    "Name": "Pyongyang Intl",
    "Country": "Korea",
    "IATA/FAA": "FNJ",
    "Longitude": "125.67015",
    "ICAO": "ZKPY",
    "Airport ID": "3377",
    "Latitude": "39.224061",
    "Timezone": "117",
    "DST": "9",
    "destinations": ["3364", "3304", "4144", "2934"]
}, {
    "City": "Newman",
    "DBTZ": "O",
    "Name": "Newman Airport",
    "Country": "Australia",
    "IATA/FAA": "ZNE",
    "Longitude": "119.803",
    "ICAO": "YNWN",
    "Airport ID": "6310",
    "Latitude": "-23.4178",
    "Timezone": "1724",
    "DST": "8",
    "destinations": ["3351"]
}, {
    "City": "Olympic Dam",
    "DBTZ": "O",
    "Name": "Olympic Dam Airport",
    "Country": "Australia",
    "IATA/FAA": "OLP",
    "Longitude": "136.877",
    "ICAO": "YOLD",
    "Airport ID": "6311",
    "Latitude": "-30.485",
    "Timezone": "343",
    "DST": "9.5",
    "destinations": ["3341"]
}, {
    "City": "Gove",
    "DBTZ": "O",
    "Name": "Gove Airport",
    "Country": "Australia",
    "IATA/FAA": "GOV",
    "Longitude": "136.818",
    "ICAO": "YPGV",
    "Airport ID": "6316",
    "Latitude": "-12.2694",
    "Timezone": "192",
    "DST": "9.5",
    "destinations": ["3322", "3999", "6270"]
}, {
    "City": "Parkes",
    "DBTZ": "O",
    "Name": "Parkes Airport",
    "Country": "Australia",
    "IATA/FAA": "PKE",
    "Longitude": "148.239",
    "ICAO": "YPKS",
    "Airport ID": "6317",
    "Latitude": "-33.1314",
    "Timezone": "1069",
    "DST": "10",
    "destinations": ["6249", "3361"]
}, {
    "City": "Paraburdoo",
    "DBTZ": "O",
    "Name": "Paraburdoo Airport",
    "Country": "Australia",
    "IATA/FAA": "PBO",
    "Longitude": "117.745",
    "ICAO": "YPBO",
    "Airport ID": "6314",
    "Latitude": "-23.1711",
    "Timezone": "1406",
    "DST": "8",
    "destinations": ["3351"]
}, {
    "City": "Cocos Keeling Island",
    "DBTZ": "U",
    "Name": "Cocos Keeling Island Airport",
    "Country": "Cocos (Keeling) Islands",
    "IATA/FAA": "CCK",
    "Longitude": "96.8339",
    "ICAO": "YPCC",
    "Airport ID": "6315",
    "Latitude": "-12.1883",
    "Timezone": "10",
    "DST": "6.5",
    "destinations": ["3353"]
}, {
    "City": "Al-ahsa",
    "DBTZ": "U",
    "Name": "Al Ahsa",
    "Country": "Saudi Arabia",
    "IATA/FAA": "HOF",
    "Longitude": "49.485189",
    "ICAO": "OEAH",
    "Airport ID": "2060",
    "Latitude": "25.285306",
    "Timezone": "588",
    "DST": "3",
    "destinations": ["2191", "2072", "2082"]
}, {
    "City": "El-baha",
    "DBTZ": "U",
    "Name": "Al Baha",
    "Country": "Saudi Arabia",
    "IATA/FAA": "ABT",
    "Longitude": "41.634277",
    "ICAO": "OEBA",
    "Airport ID": "2061",
    "Latitude": "20.296139",
    "Timezone": "5486",
    "DST": "3",
    "destinations": ["2064", "2072", "2082"]
}, {
    "City": "Bisha",
    "DBTZ": "U",
    "Name": "Bisha",
    "Country": "Saudi Arabia",
    "IATA/FAA": "BHH",
    "Longitude": "42.620881",
    "ICAO": "OEBH",
    "Airport ID": "2062",
    "Latitude": "19.98435",
    "Timezone": "3887",
    "DST": "3",
    "destinations": ["2064", "2072", "2082"]
}, {
    "City": "Pormpuraaw",
    "DBTZ": "O",
    "Name": "Pormpuraaw Airport",
    "Country": "Australia",
    "IATA/FAA": "EDR",
    "Longitude": "141.609",
    "ICAO": "YPMP",
    "Airport ID": "6319",
    "Latitude": "-14.8967",
    "Timezone": "10",
    "DST": "10",
    "destinations": ["3322", "6281"]
}, {
    "City": "Dammam",
    "DBTZ": "U",
    "Name": "King Fahd Intl",
    "Country": "Saudi Arabia",
    "IATA/FAA": "DMM",
    "Longitude": "49.79789",
    "ICAO": "OEDF",
    "Airport ID": "2064",
    "Latitude": "26.471161",
    "Timezone": "72",
    "DST": "3",
    "destinations": ["2179", "2997", "3136", "3093", "3153", "580", "2176", "2082", "3410", "2188", "1107", "3977", "3975", "8076", "2191", "2057", "4331", "3137", "3143", "340", "2177", "3024", "1128", "2223", "2207", "2206", "6776", "2397", "2241", "2170", "2061", "2059", "2062", "3131", "3076", "2075", "2067", "2066", "2069", "3141", "1701", "2072", "3144", "2074", "2090", "2089", "2194", "2096"]
}, {
    "City": "Gizan",
    "DBTZ": "U",
    "Name": "King Abdullah Bin Abdulaziz",
    "Country": "Saudi Arabia",
    "IATA/FAA": "GIZ",
    "Longitude": "42.585833",
    "ICAO": "OEGN",
    "Airport ID": "2066",
    "Latitude": "16.901111",
    "Timezone": "20",
    "DST": "3",
    "destinations": ["2064", "2072", "2082", "2074"]
}, {
    "City": "Gassim",
    "DBTZ": "U",
    "Name": "Gassim",
    "Country": "Saudi Arabia",
    "IATA/FAA": "ELQ",
    "Longitude": "43.773911",
    "ICAO": "OEGS",
    "Airport ID": "2067",
    "Latitude": "26.302822",
    "Timezone": "2126",
    "DST": "3",
    "destinations": ["1128", "2188", "2191", "2241", "2064", "2072", "2074", "2082", "1701"]
}, {
    "City": "St. John",
    "DBTZ": "A",
    "Name": "Saint John",
    "Country": "Canada",
    "IATA/FAA": "YSJ",
    "Longitude": "-65.890278",
    "ICAO": "CYSJ",
    "Airport ID": "135",
    "Latitude": "45.316111",
    "Timezone": "357",
    "DST": "-4",
    "destinations": ["73", "146", "193"]
}, {
    "City": "Nanning",
    "DBTZ": "U",
    "Name": "Wuxu",
    "Country": "China",
    "IATA/FAA": "NNG",
    "Longitude": "108.172442",
    "ICAO": "ZGNN",
    "Airport ID": "3373",
    "Latitude": "22.608267",
    "Timezone": "421",
    "DST": "8",
    "destinations": ["3393", "3395", "3205", "3376", "3385", "3382", "3391", "3304", "3374", "3379", "3370", "3375", "3386", "3121", "3388", "3364", "4030", "3383", "6355", "3885", "3371", "4120", "3384", "3394", "3372", "3406", "4302", "2276", "2264", "2275", "6744", "2268", "3389", "6386", "6399", "6392", "3077", "6431", "4033", "3199", "3034", "3239", "3316", "3120"]
}, {
    "City": "Brusselton",
    "DBTZ": "O",
    "Name": "Brusselton",
    "Country": "Australia",
    "IATA/FAA": "BQB",
    "Longitude": "115.400278",
    "ICAO": "YBLN",
    "Airport ID": "9090",
    "Latitude": "-33.687222",
    "Timezone": "55",
    "DST": "8",
    "destinations": ["3351", "6235"]
}, {
    "City": "Guilin",
    "DBTZ": "U",
    "Name": "Liangjiang",
    "Country": "China",
    "IATA/FAA": "KWL",
    "Longitude": "110.039197",
    "ICAO": "ZGKL",
    "Airport ID": "3372",
    "Latitude": "25.218106",
    "Timezone": "570",
    "DST": "8",
    "destinations": ["4085", "6347", "4030", "3382", "3383", "3386", "3406", "3304", "3368", "2276", "3375", "3393", "3395", "3385", "3387", "3388", "3364", "3374", "3379", "3077", "3370", "3373", "4144", "4302", "4108", "6392", "3376", "3391", "8082", "6351", "3394", "4120", "6431", "6355", "3369", "4033", "4109", "3389", "3930", "2372", "3885"]
}, {
    "City": "Changcha",
    "DBTZ": "U",
    "Name": "Huanghua",
    "Country": "China",
    "IATA/FAA": "CSX",
    "Longitude": "113.219633",
    "ICAO": "ZGHA",
    "Airport ID": "3371",
    "Latitude": "28.189158",
    "Timezone": "217",
    "DST": "8",
    "destinations": ["3393", "3395", "3385", "3382", "4144", "6392", "3383", "4033", "3388", "3391", "2264", "2276", "2268", "2370", "6353", "3386", "7519", "8845", "4030", "3390", "6963", "3368", "3379", "6357", "2372", "3389", "3364", "4108", "3077", "6346", "6351", "3885", "3370", "4109", "3404", "4120", "6345", "3400", "3930", "4085", "6386", "3394", "6431", "6399", "6400", "3387", "3373", "3406", "6347", "4302", "3374", "6387", "3369", "3399", "6366", "6430", "3392", "8876", "6355", "3157", "6434", "6385", "4149", "6341", "6384", "3316", "6356", "6390", "6393"]
}, {
    "City": "Gurney",
    "DBTZ": "U",
    "Name": "Gurney Airport",
    "Country": "Papua New Guinea",
    "IATA/FAA": "GUR",
    "Longitude": "150.334",
    "ICAO": "AYGN",
    "Airport ID": "5422",
    "Latitude": "-10.3115",
    "Timezone": "88",
    "DST": "10",
    "destinations": ["5980", "5432", "5423", "5"]
}, {
    "City": "Sachs Harbour",
    "DBTZ": "A",
    "Name": "Sachs Harbour",
    "Country": "Canada",
    "IATA/FAA": "YSY",
    "Longitude": "-125.2425",
    "ICAO": "CYSY",
    "Airport ID": "139",
    "Latitude": "71.993889",
    "Timezone": "282",
    "DST": "-7",
    "destinations": ["54", "68"]
}, {
    "City": "St. George",
    "DBTZ": "A",
    "Name": "St. George Airport",
    "Country": "United States",
    "IATA/FAA": "STG",
    "Longitude": "-169.663611",
    "ICAO": "",
    "Airport ID": "7247",
    "Latitude": "56.577222",
    "Timezone": "125",
    "DST": "-9",
    "destinations": ["3774", "3426"]
}, {
    "City": "Manley Hot Springs",
    "DBTZ": "A",
    "Name": "Manley Hot Springs Airport",
    "Country": "United States",
    "IATA/FAA": "MLY",
    "Longitude": "-150.644167",
    "ICAO": "PAML",
    "Airport ID": "7246",
    "Latitude": "64.9975",
    "Timezone": "270",
    "DST": "-9",
    "destinations": ["7110"]
}, {
    "City": "Kake",
    "DBTZ": "A",
    "Name": "Kake Seaplane Base",
    "Country": "United States",
    "IATA/FAA": "KAE",
    "Longitude": "-133.945556",
    "ICAO": "",
    "Airport ID": "7243",
    "Latitude": "56.973056",
    "Timezone": "0",
    "DST": "-9",
    "destinations": ["3492"]
}, {
    "City": "Healy",
    "DBTZ": "A",
    "Name": "Healy River Airport",
    "Country": "United States",
    "IATA/FAA": "HKB",
    "Longitude": "-148.968889",
    "ICAO": "PAHV",
    "Airport ID": "7242",
    "Latitude": "63.8675",
    "Timezone": "1263",
    "DST": "-9",
    "destinations": ["3832"]
}, {
    "City": "Stony River",
    "DBTZ": "A",
    "Name": "Stony River 2 Airport",
    "Country": "United States",
    "IATA/FAA": "SRV",
    "Longitude": "-156.591111",
    "ICAO": "",
    "Airport ID": "7241",
    "Latitude": "61.7875",
    "Timezone": "230",
    "DST": "-9",
    "destinations": ["5967"]
}, {
    "City": "Sleetmute",
    "DBTZ": "A",
    "Name": "Sleetmute Airport",
    "Country": "United States",
    "IATA/FAA": "SLQ",
    "Longitude": "-157.165833",
    "ICAO": "PASL",
    "Airport ID": "7240",
    "Latitude": "61.700566",
    "Timezone": "190",
    "DST": "-9",
    "destinations": ["5967", "7241"]
}, {
    "City": "Seattle",
    "DBTZ": "A",
    "Name": "Seattle Tacoma Intl",
    "Country": "United States",
    "IATA/FAA": "SEA",
    "Longitude": "-122.309306",
    "ICAO": "KSEA",
    "Airport ID": "3577",
    "Latitude": "47.449",
    "Timezone": "433",
    "DST": "-8",
    "destinations": ["3448", "3644", "3876", "3751", "3670", "3494", "3797", "3808", "3484", "3582", "507", "3458", "3878", "3576", "3453", "3734", "3830", "3364", "3752", "3462", "3731", "3469", "3748", "3817", "3867", "3678", "156", "193", "3682", "1382", "3728", "5719", "3774", "3673", "4021", "3777", "3495", "4020", "3819", "3520", "6916", "4099", "3832", "3687", "4127", "3533", "3467", "3880", "3550", "3492", "3514", "3877", "3602", "3945", "4101", "4216", "3858", "3456", "3454", "3720", "6133", "3839", "3944", "4103", "3807", "3621", "3949", "3536", "6992", "3636", "49", "5779", "87", "178", "184", "2188", "2276", "340", "580", "3645", "2359", "3930", "2279", "3406", "16", "3849", "3747", "3717", "3714", "4019"]
}, {
    "City": "Miami",
    "DBTZ": "A",
    "Name": "Miami Intl",
    "Country": "United States",
    "IATA/FAA": "MIA",
    "Longitude": "-80.290556",
    "ICAO": "KMIA",
    "Airport ID": "3576",
    "Latitude": "25.79325",
    "Timezone": "8",
    "DST": "-5",
    "destinations": ["3988", "1760", "1824", "2895", "2897", "2821", "2874", "2699", "3682", "1218", "4069", "3825", "2875", "3811", "3690", "2709", "3448", "2531", "3849", "1957", "2851", "1382", "3806", "3486", "2715", "3876", "3759", "2537", "1852", "3488", "2545", "1800", "3520", "3751", "3670", "3645", "345", "1943", "3494", "3875", "2878", "1948", "1926", "1941", "2560", "2882", "3472", "2564", "4008", "1767", "2673", "3714", "3550", "3585", "3712", "3797", "1779", "3877", "3484", "3697", "507", "2789", "1881", "2762", "1759", "1229", "2551", "2848", "1780", "3878", "2745", "3473", "1863", "1930", "3858", "3861", "2816", "1524", "1953", "3830", "3611", "1897", "3752", "3462", "3570", "1754", "3564", "1761", "2902", "2881", "1871", "3626", "2610", "3608", "1774", "1892", "3731", "1772", "2650", "4014", "1762", "3577", "3469", "1885", "2890", "2891", "2621", "1764", "3678", "2883", "2884", "2899", "1776", "3698", "3646", "3855", "351", "2688", "2894", "146", "193", "1819", "2710", "2714", "1555", "2985", "2526", "1925", "340", "1678", "580", "4304", "1638", "2988", "4012"]
}, {
    "City": "Barrow",
    "DBTZ": "A",
    "Name": "Wiley Post Will Rogers Mem",
    "Country": "United States",
    "IATA/FAA": "BRW",
    "Longitude": "-156.766003",
    "ICAO": "PABR",
    "Airport ID": "3571",
    "Latitude": "71.285446",
    "Timezone": "44",
    "DST": "-9",
    "destinations": ["7220", "6714", "7092", "3414", "3774", "3832", "3620"]
}, {
    "City": "Pittsburgh",
    "DBTZ": "A",
    "Name": "Pittsburgh Intl",
    "Country": "United States",
    "IATA/FAA": "PIT",
    "Longitude": "-80.232872",
    "ICAO": "KPIT",
    "Airport ID": "3570",
    "Latitude": "40.491467",
    "Timezone": "1204",
    "DST": "-5",
    "destinations": ["3825", "3448", "3876", "3520", "3670", "3797", "3484", "3697", "3576", "3830", "3752", "3462", "3626", "3678", "193", "3682", "1382", "3488", "3645", "3878", "3473", "3858", "1760", "1852", "3849", "3747", "3646", "3751", "3494", "3714", "3550", "3469", "3690", "3533", "3566", "3877", "3793"]
}, {
    "City": "Valdivia",
    "DBTZ": "S",
    "Name": "Pichoy",
    "Country": "Chile",
    "IATA/FAA": "ZAL",
    "Longitude": "-73.086111",
    "ICAO": "SCVD",
    "Airport ID": "2667",
    "Latitude": "-39.649956",
    "Timezone": "59",
    "DST": "-4",
    "destinations": ["2656", "2650"]
}, {
    "City": "Temuco",
    "DBTZ": "S",
    "Name": "Maquehue",
    "Country": "Chile",
    "IATA/FAA": "ZCO",
    "Longitude": "-72.637097",
    "ICAO": "SCTC",
    "Airport ID": "2664",
    "Latitude": "-38.766819",
    "Timezone": "304",
    "DST": "-4",
    "destinations": ["2656", "2650"]
}, {
    "City": "Puerto Montt",
    "DBTZ": "S",
    "Name": "El Tepual Intl",
    "Country": "Chile",
    "IATA/FAA": "PMC",
    "Longitude": "-73.093953",
    "ICAO": "SCTE",
    "Airport ID": "2665",
    "Latitude": "-41.438886",
    "Timezone": "294",
    "DST": "-4",
    "destinations": ["2647", "2642", "2656", "2650"]
}, {
    "City": "La Serena",
    "DBTZ": "S",
    "Name": "La Florida",
    "Country": "Chile",
    "IATA/FAA": "LSC",
    "Longitude": "-71.199522",
    "ICAO": "SCSE",
    "Airport ID": "2662",
    "Latitude": "-29.916233",
    "Timezone": "481",
    "DST": "-4",
    "destinations": ["2651", "2645", "4312", "2650"]
}, {
    "City": "Dalma Island",
    "DBTZ": "U",
    "Name": "Dalma Airport",
    "Country": "United Arab Emirates",
    "IATA/FAA": "ZDY",
    "Longitude": "52.3360528",
    "ICAO": "",
    "Airport ID": "8962",
    "Latitude": "24.5033833",
    "Timezone": "8",
    "DST": "4",
    "destinations": ["2180"]
}, {
    "City": "Sir Bani Yas Island",
    "DBTZ": "U",
    "Name": "Sir Bani Yas Island",
    "Country": "United Arab Emirates",
    "IATA/FAA": "XSB",
    "Longitude": "52.5783472",
    "ICAO": "",
    "Airport ID": "8961",
    "Latitude": "24.2856083",
    "Timezone": "5",
    "DST": "4",
    "destinations": ["2180", "2188"]
}, {
    "City": "Marion",
    "DBTZ": "A",
    "Name": "Williamson Country Regional Airport",
    "Country": "United States",
    "IATA/FAA": "MWA",
    "Longitude": "-89.0110936",
    "ICAO": "KMWA",
    "Airport ID": "6825",
    "Latitude": "37.7549569",
    "Timezone": "472",
    "DST": "-6",
    "destinations": ["3678"]
}, {
    "City": "Ulan-ude",
    "DBTZ": "N",
    "Name": "Mukhino",
    "Country": "Russia",
    "IATA/FAA": "UUD",
    "Longitude": "107.437644",
    "ICAO": "UIUU",
    "Airport ID": "2938",
    "Latitude": "51.807764",
    "Timezone": "1690",
    "DST": "9",
    "destinations": ["2937", "2927", "6954", "2923", "4029", "4078", "3364", "2985", "2935", "4374"]
}, {
    "City": "Kiev",
    "DBTZ": "E",
    "Name": "Boryspil Intl",
    "Country": "Ukraine",
    "IATA/FAA": "KBP",
    "Longitude": "30.894722",
    "ICAO": "UKBB",
    "Airport ID": "2939",
    "Latitude": "50.345",
    "Timezone": "427",
    "DST": "2",
    "destinations": ["1735", "1382", "1555", "2954", "507", "3953", "644", "2188", "2191", "2983", "1218", "1229", "1561", "2922", "2908", "2910", "580", "3973", "340", "346", "679", "1678", "1590", "1701", "1587", "1613", "415", "3941", "3885", "302", "4029", "2941", "2940", "3964", "1665", "421", "1452", "6105", "6106", "3797", "2952", "1197", "2948", "502", "1638", "2945", "1524", "1354", "2947", "4078", "1472", "2964", "4317", "2975", "1056", "351", "3959", "2170", "2985", "1688", "2942", "7863"]
}, {
    "City": "Bratsk",
    "DBTZ": "N",
    "Name": "Bratsk",
    "Country": "Russia",
    "IATA/FAA": "BTK",
    "Longitude": "101.698331",
    "ICAO": "UIBB",
    "Airport ID": "2936",
    "Latitude": "56.370556",
    "Timezone": "1610",
    "DST": "9",
    "destinations": ["2937", "4078", "2988", "4029"]
}, {
    "City": "Irkutsk",
    "DBTZ": "N",
    "Name": "Irkutsk",
    "Country": "Russia",
    "IATA/FAA": "IKT",
    "Longitude": "104.388975",
    "ICAO": "UIII",
    "Airport ID": "2937",
    "Latitude": "52.268028",
    "Timezone": "1675",
    "DST": "9",
    "destinations": ["2936", "2935", "8944", "4078", "6924", "9026", "2923", "2925", "6954", "2955", "3400", "4374", "6118", "2938", "2912", "2913", "2979", "2983", "3885", "4029", "2930", "3364", "2934", "2927", "2985", "2988"]
}, {
    "City": "Vladivostok",
    "DBTZ": "N",
    "Name": "Knevichi",
    "Country": "Russia",
    "IATA/FAA": "VVO",
    "Longitude": "132.148017",
    "ICAO": "UHWW",
    "Airport ID": "2934",
    "Latitude": "43.398953",
    "Timezone": "46",
    "DST": "11",
    "destinations": ["3077", "2926", "2279", "3377", "3930", "2923", "2937", "4078", "3364", "2932", "2933", "2927", "2372", "2985", "4029"]
}, {
    "City": "Chita",
    "DBTZ": "N",
    "Name": "Kadala",
    "Country": "Russia",
    "IATA/FAA": "HTA",
    "Longitude": "113.305556",
    "ICAO": "UIAA",
    "Airport ID": "2935",
    "Latitude": "52.026317",
    "Timezone": "2272",
    "DST": "10",
    "destinations": ["2937", "3366", "6954", "2926", "2927", "4029", "4078", "2985", "2938"]
}, {
    "City": "Petropavlovsk",
    "DBTZ": "N",
    "Name": "Yelizovo",
    "Country": "Russia",
    "IATA/FAA": "PKC",
    "Longitude": "158.453669",
    "ICAO": "UHPP",
    "Airport ID": "2932",
    "Latitude": "53.167889",
    "Timezone": "131",
    "DST": "12",
    "destinations": ["2927", "2934", "2985", "4029"]
}, {
    "City": "Yuzhno-sakhalinsk",
    "DBTZ": "N",
    "Name": "Khomutovo",
    "Country": "Russia",
    "IATA/FAA": "UUS",
    "Longitude": "142.717531",
    "ICAO": "UHSS",
    "Airport ID": "2933",
    "Latitude": "46.888672",
    "Timezone": "59",
    "DST": "11",
    "destinations": ["7478", "2287", "8782", "3400", "2279", "7479", "3930", "2927", "2934", "2985", "4029"]
}, {
    "City": "Magadan",
    "DBTZ": "N",
    "Name": "Sokol",
    "Country": "Russia",
    "IATA/FAA": "GDX",
    "Longitude": "150.720439",
    "ICAO": "UHMM",
    "Airport ID": "2930",
    "Latitude": "59.910989",
    "Timezone": "574",
    "DST": "12",
    "destinations": ["2927", "2923", "2937", "4029"]
}, {
    "City": "Pevek",
    "DBTZ": "N",
    "Name": "Pevek",
    "Country": "Russia",
    "IATA/FAA": "PWE",
    "Longitude": "170.597006",
    "ICAO": "UHMP",
    "Airport ID": "2931",
    "Latitude": "69.783283",
    "Timezone": "11",
    "DST": "12",
    "destinations": ["2988"]
}, {
    "City": "Mostar",
    "DBTZ": "E",
    "Name": "Mostar",
    "Country": "Bosnia and Herzegovina",
    "IATA/FAA": "OMO",
    "Longitude": "17.845878",
    "ICAO": "LQMO",
    "Airport ID": "1645",
    "Latitude": "43.2829",
    "Timezone": "156",
    "DST": "1",
    "destinations": ["1525"]
}, {
    "City": "Arad",
    "DBTZ": "E",
    "Name": "Arad",
    "Country": "Romania",
    "IATA/FAA": "ARW",
    "Longitude": "21.262022",
    "ICAO": "LRAR",
    "Airport ID": "1647",
    "Latitude": "46.17655",
    "Timezone": "352",
    "DST": "2",
    "destinations": ["1525"]
}, {
    "City": "Sarajevo",
    "DBTZ": "E",
    "Name": "Sarajevo",
    "Country": "Bosnia and Herzegovina",
    "IATA/FAA": "SJJ",
    "Longitude": "18.331467",
    "ICAO": "LQSA",
    "Airport ID": "1646",
    "Latitude": "43.824583",
    "Timezone": "1708",
    "DST": "1",
    "destinations": ["344", "350", "351", "737", "1739", "5794", "609", "1701", "1569", "346", "1613", "1208", "4317"]
}, {
    "City": "Baia Mare",
    "DBTZ": "E",
    "Name": "Tautii Magheraus",
    "Country": "Romania",
    "IATA/FAA": "BAY",
    "Longitude": "23.470022",
    "ICAO": "LRBM",
    "Airport ID": "1649",
    "Latitude": "47.658389",
    "Timezone": "605",
    "DST": "2",
    "destinations": ["1657"]
}, {
    "City": "Pristina",
    "DBTZ": "E",
    "Name": "Pristina",
    "Country": "Serbia",
    "IATA/FAA": "PRN",
    "Longitude": "21.035833",
    "ICAO": "LYPR",
    "Airport ID": "1742",
    "Latitude": "42.572778",
    "Timezone": "1789",
    "DST": "1",
    "destinations": ["1678", "345", "352", "342", "350", "351", "340", "1569", "346", "1665", "1613", "1208", "4317", "609", "502", "1701", "4053"]
}, {
    "City": "Laage",
    "DBTZ": "E",
    "Name": "Laage",
    "Country": "Germany",
    "IATA/FAA": "RLG",
    "Longitude": "12.278333",
    "ICAO": "ETNL",
    "Airport ID": "772",
    "Latitude": "53.918167",
    "Timezone": "138",
    "DST": "1",
    "destinations": ["344", "350", "346", "1688", "3998"]
}, {
    "City": "Tivat",
    "DBTZ": "E",
    "Name": "Tivat",
    "Country": "Montenegro",
    "IATA/FAA": "TIV",
    "Longitude": "18.723286",
    "ICAO": "LYTV",
    "Airport ID": "1743",
    "Latitude": "42.404664",
    "Timezone": "20",
    "DST": "1",
    "destinations": ["4029", "302", "1739", "2948", "1382", "502"]
}, {
    "City": "Zaragoza",
    "DBTZ": "E",
    "Name": "Zaragoza Ab",
    "Country": "Spain",
    "IATA/FAA": "ZAZ",
    "Longitude": "-1.041553",
    "ICAO": "LEZG",
    "Airport ID": "1252",
    "Latitude": "41.666242",
    "Timezone": "863",
    "DST": "1",
    "destinations": ["1055", "1525", "1367", "304", "548", "3998", "1652", "1657"]
}, {
    "City": "Noyabrsk",
    "DBTZ": "N",
    "Name": "Noyabrsk",
    "Country": "Russia",
    "IATA/FAA": "NOJ",
    "Longitude": "75.162243",
    "ICAO": "USRO",
    "Airport ID": "4365",
    "Latitude": "63.110079",
    "Timezone": "20",
    "DST": "6",
    "destinations": ["4029", "6137", "2975", "4111", "2992"]
}, {
    "City": "Mount Pleasant",
    "DBTZ": "U",
    "Name": "Mount Pleasant",
    "Country": "Falkland Islands",
    "IATA/FAA": "MPN",
    "Longitude": "-58.447222",
    "ICAO": "EGYP",
    "Airport ID": "579",
    "Latitude": "-51.822777",
    "Timezone": "244",
    "DST": "-3",
    "destinations": ["2647"]
}, {
    "City": "Reynosa",
    "DBTZ": "S",
    "Name": "General Lucio Blanco Intl",
    "Country": "Mexico",
    "IATA/FAA": "REX",
    "Longitude": "-98.228513",
    "ICAO": "MMRX",
    "Airport ID": "1839",
    "Latitude": "26.008908",
    "Timezone": "139",
    "DST": "-6",
    "destinations": ["1824", "1830", "1852", "1804", "1854"]
}, {
    "City": "Limoges",
    "DBTZ": "E",
    "Name": "Bellegarde",
    "Country": "France",
    "IATA/FAA": "LIG",
    "Longitude": "1.179444",
    "ICAO": "LFBL",
    "Airport ID": "1270",
    "Latitude": "45.862778",
    "Timezone": "1300",
    "DST": "1",
    "destinations": ["495", "1361", "1335", "490", "523", "517", "491", "548", "1354", "1386"]
}, {
    "City": "Novy Urengoy",
    "DBTZ": "N",
    "Name": "Novyi Urengoy",
    "Country": "Russia",
    "IATA/FAA": "NUX",
    "Longitude": "76.313938",
    "ICAO": "USMU",
    "Airport ID": "4364",
    "Latitude": "66.041811",
    "Timezone": "20",
    "DST": "6",
    "destinations": ["2975", "4111", "2992", "2988", "2958", "2960", "4029", "2985", "4078", "6137"]
}, {
    "City": "Aktau",
    "DBTZ": "U",
    "Name": "Aktau",
    "Country": "Kazakhstan",
    "IATA/FAA": "SCO",
    "Longitude": "51.091978",
    "ICAO": "UATE",
    "Airport ID": "4367",
    "Latitude": "43.86005",
    "Timezone": "21",
    "DST": "5",
    "destinations": ["2920", "2908", "2966", "2914", "4029", "3964", "4357", "2922", "2960", "4358", "2961", "2962", "3973", "2910", "2916", "1701", "2988"]
}, {
    "City": "Kegaska",
    "DBTZ": "A",
    "Name": "Kegaska Airport",
    "Country": "Canada",
    "IATA/FAA": "ZKG",
    "Longitude": "-61.265833",
    "ICAO": "CTK6",
    "Airport ID": "8217",
    "Latitude": "50.195833",
    "Timezone": "32",
    "DST": "-4",
    "destinations": ["94"]
}, {
    "City": "Gorno-Altaysk",
    "DBTZ": "U",
    "Name": "Gorno-Altaysk Airport",
    "Country": "Russia",
    "IATA/FAA": "RGK",
    "Longitude": "85.833333",
    "ICAO": "UNBG",
    "Airport ID": "8740",
    "Latitude": "51.966667",
    "Timezone": "965",
    "DST": "7",
    "destinations": ["4029"]
}, {
    "City": "Puerto Maldonado",
    "DBTZ": "U",
    "Name": "Padre Aldamiz",
    "Country": "Peru",
    "IATA/FAA": "PEM",
    "Longitude": "-69.228611",
    "ICAO": "SPTU",
    "Airport ID": "2808",
    "Latitude": "-12.613611",
    "Timezone": "659",
    "DST": "-5",
    "destinations": ["2812", "2789"]
}, {
    "City": "Piura",
    "DBTZ": "U",
    "Name": "Capitan Fap Guillermo Concha Iberico",
    "Country": "Peru",
    "IATA/FAA": "PIU",
    "Longitude": "-80.616444",
    "ICAO": "SPUR",
    "Airport ID": "2809",
    "Latitude": "-5.20575",
    "Timezone": "120",
    "DST": "-5",
    "destinations": ["2789"]
}, {
    "City": "Morristown",
    "DBTZ": "A",
    "Name": "Morristown Municipal Airport",
    "Country": "United States",
    "IATA/FAA": "MMU",
    "Longitude": "-74.4148747",
    "ICAO": "KMMU",
    "Airport ID": "7657",
    "Latitude": "40.79935",
    "Timezone": "187",
    "DST": "-5",
    "destinations": ["3488", "3681"]
}, {
    "City": "Arequipa",
    "DBTZ": "U",
    "Name": "Rodriguez Ballon",
    "Country": "Peru",
    "IATA/FAA": "AQP",
    "Longitude": "-71.583083",
    "ICAO": "SPQU",
    "Airport ID": "2802",
    "Latitude": "-16.341072",
    "Timezone": "8405",
    "DST": "-5",
    "destinations": ["2789", "2792", "2812", "2807", "2762"]
}, {
    "City": "Zadar",
    "DBTZ": "E",
    "Name": "Zadar",
    "Country": "Croatia",
    "IATA/FAA": "ZAD",
    "Longitude": "15.346697",
    "ICAO": "LDZD",
    "Airport ID": "1209",
    "Latitude": "44.108269",
    "Timezone": "289",
    "DST": "1",
    "destinations": ["382", "344", "345", "350", "351", "608", "1367", "304", "599", "4166", "691", "645", "355", "478", "1353", "4198", "699", "657", "548", "679", "340", "346", "1203", "1208"]
}, {
    "City": "Phrae",
    "DBTZ": "U",
    "Name": "Phrae",
    "Country": "Thailand",
    "IATA/FAA": "PRH",
    "Longitude": "100.164664",
    "ICAO": "VTCP",
    "Airport ID": "3164",
    "Latitude": "18.132169",
    "Timezone": "538",
    "DST": "7",
    "destinations": ["3157"]
}, {
    "City": "Iquitos",
    "DBTZ": "U",
    "Name": "Coronel Francisco Secada Vignetta Intl",
    "Country": "Peru",
    "IATA/FAA": "IQT",
    "Longitude": "-73.308806",
    "ICAO": "SPQT",
    "Airport ID": "2801",
    "Latitude": "-3.784739",
    "Timezone": "306",
    "DST": "-5",
    "destinations": ["2781", "2806", "1871", "2789"]
}, {
    "City": "Tarapoto",
    "DBTZ": "U",
    "Name": "Cadete Guillermo Del Castillo Paredes",
    "Country": "Peru",
    "IATA/FAA": "TPP",
    "Longitude": "-76.373247",
    "ICAO": "SPST",
    "Airport ID": "2806",
    "Latitude": "-6.508742",
    "Timezone": "869",
    "DST": "-5",
    "destinations": ["2801", "2789", "2781"]
}, {
    "City": "Tacna",
    "DBTZ": "U",
    "Name": "Coronel Carlos Ciriani Santa Rosa Intl",
    "Country": "Peru",
    "IATA/FAA": "TCQ",
    "Longitude": "-70.275833",
    "ICAO": "SPTN",
    "Airport ID": "2807",
    "Latitude": "-18.053333",
    "Timezone": "1538",
    "DST": "-5",
    "destinations": ["2789", "2802"]
}, {
    "City": "Trujillo",
    "DBTZ": "U",
    "Name": "Capitan Carlos Martinez De Pinillos",
    "Country": "Peru",
    "IATA/FAA": "TRU",
    "Longitude": "-79.108761",
    "ICAO": "SPRU",
    "Airport ID": "2804",
    "Latitude": "-8.081411",
    "Timezone": "106",
    "DST": "-5",
    "destinations": ["2789"]
}, {
    "City": "Zagreb",
    "DBTZ": "E",
    "Name": "Zagreb",
    "Country": "Croatia",
    "IATA/FAA": "ZAG",
    "Longitude": "16.068778",
    "ICAO": "LDZA",
    "Airport ID": "1208",
    "Latitude": "45.742931",
    "Timezone": "353",
    "DST": "1",
    "destinations": ["344", "350", "507", "1382", "609", "1218", "340", "346", "351", "1678", "1613", "580", "1538", "302", "4160", "1200", "1701", "1202", "1742", "1205", "1646", "1737", "1206", "1209", "1489", "2985", "502"]
}, {
    "City": "Misima Island",
    "DBTZ": "U",
    "Name": "Misima Island Airport",
    "Country": "Papua New Guinea",
    "IATA/FAA": "MIS",
    "Longitude": "152.838",
    "ICAO": "AYMS",
    "Airport ID": "5432",
    "Latitude": "-10.6892",
    "Timezone": "26",
    "DST": "10",
    "destinations": ["5422", "5419"]
}, {
    "City": "Tari",
    "DBTZ": "U",
    "Name": "Tari Airport",
    "Country": "Papua New Guinea",
    "IATA/FAA": "TIZ",
    "Longitude": "142.948",
    "ICAO": "AYTA",
    "Airport ID": "5433",
    "Latitude": "-5.845",
    "Timezone": "5500",
    "DST": "10",
    "destinations": ["3", "5431", "5"]
}, {
    "City": "Mulhouse",
    "DBTZ": "E",
    "Name": "Bale Mulhouse",
    "Country": "France",
    "IATA/FAA": "MLH",
    "Longitude": "7.529914",
    "ICAO": "LFSB",
    "Airport ID": "1423",
    "Latitude": "47.589583",
    "Timezone": "885",
    "DST": "1",
    "destinations": ["1335", "1386", "221", "1353", "1739", "1737", "210", "231", "6492"]
}, {
    "City": "Moro",
    "DBTZ": "U",
    "Name": "Moro Airport",
    "Country": "Papua New Guinea",
    "IATA/FAA": "MXH",
    "Longitude": "143.238",
    "ICAO": "AYMR",
    "Airport ID": "5431",
    "Latitude": "-6.36333",
    "Timezone": "2740",
    "DST": "10",
    "destinations": ["3", "5"]
}, {
    "City": "Vanimo",
    "DBTZ": "U",
    "Name": "Vanimo Airport",
    "Country": "Papua New Guinea",
    "IATA/FAA": "VAI",
    "Longitude": "141.302",
    "ICAO": "AYVN",
    "Airport ID": "5436",
    "Latitude": "-2.69717",
    "Timezone": "10",
    "DST": "10",
    "destinations": ["2", "5", "6"]
}, {
    "City": "Taiyuan",
    "DBTZ": "U",
    "Name": "Wusu",
    "Country": "China",
    "IATA/FAA": "TYN",
    "Longitude": "112.628428",
    "ICAO": "ZBYN",
    "Airport ID": "3369",
    "Latitude": "37.746897",
    "Timezone": "2575",
    "DST": "8",
    "destinations": ["3393", "3395", "6344", "3386", "4144", "8858", "6345", "3382", "4033", "2372", "6346", "3404", "6434", "3400", "6431", "3121", "3388", "3364", "4030", "3374", "3390", "4108", "3399", "3370", "4380", "3371", "4109", "6353", "3389", "4085", "3384", "6387", "3376", "3379", "3383", "2275", "3391", "6343", "3394", "3368", "4120", "3372", "3406", "3077", "3385", "2276", "6430"]
}, {
    "City": "Tabubil",
    "DBTZ": "U",
    "Name": "Tabubil Airport",
    "Country": "Papua New Guinea",
    "IATA/FAA": "TBG",
    "Longitude": "141.226",
    "ICAO": "AYTB",
    "Airport ID": "5434",
    "Latitude": "-5.27861",
    "Timezone": "1570",
    "DST": "10",
    "destinations": ["3", "5", "5425"]
}, {
    "City": "Tokua",
    "DBTZ": "U",
    "Name": "Tokua Airport",
    "Country": "Papua New Guinea",
    "IATA/FAA": "RAB",
    "Longitude": "152.38",
    "ICAO": "AYTK",
    "Airport ID": "5435",
    "Latitude": "-4.34046",
    "Timezone": "32",
    "DST": "10",
    "destinations": ["5419", "5424", "5428", "4", "5979", "5", "3322", "5430"]
}, {
    "City": "Beijing",
    "DBTZ": "U",
    "Name": "Capital Intl",
    "Country": "China",
    "IATA/FAA": "PEK",
    "Longitude": "116.584556",
    "ICAO": "ZBAA",
    "Airport ID": "3364",
    "Latitude": "40.080111",
    "Timezone": "116",
    "DST": "8",
    "destinations": ["3393", "3395", "6403", "8043", "2397", "3382", "6376", "3830", "3577", "3942", "351", "156", "193", "1382", "210", "421", "2179", "507", "2276", "1107", "737", "6346", "6351", "3885", "1128", "3370", "3375", "4380", "6772", "6342", "6008", "3931", "609", "3371", "2287", "4109", "6344", "6397", "6433", "3093", "3404", "6434", "345", "2188", "6353", "3494", "1555", "3377", "3385", "340", "7503", "2378", "1665", "6398", "4120", "6345", "3389", "3386", "3077", "3179", "3366", "6349", "6743", "2359", "3728", "3400", "6384", "3714", "3550", "3930", "4085", "1701", "6380", "3797", "6428", "6381", "6411", "813", "3384", "3992", "6379", "3394", "3372", "3484", "502", "6431", "4033", "4097", "6356", "1229", "4334", "3121", "6400", "346", "4189", "1524", "6413", "3387", "3388", "3373", "2279", "5475", "2384", "2372", "3406", "3035", "3239", "8630", "3469", "3205", "3391", "4144", "3316", "2985", "4302", "3361", "4030", "3374", "2381", "3390", "6348", "1590", "4108", "6387", "3369", "3380", "3399", "1613", "679", "6392", "6955", "3376", "6390", "6360", "6350", "3379", "3383", "6366", "4110", "6430", "9107", "6361", "6394", "6414", "3392", "6393", "8876", "6357", "6355", "2264", "580", "6352", "6343", "3645", "3199", "6395", "2223", "6386", "6401", "8826", "2983", "6963", "6391", "3304", "951", "3275", "6427", "2908", "302", "4145", "6367", "2927", "4374", "2948", "3156", "6954", "4078", "6368", "6388", "4330", "2922", "8417", "2910", "2370", "1678", "893", "7898", "8781", "6944", "4148", "6383", "6385", "6399", "2244", "2241", "2923", "2937", "2975", "2938", "2934", "3024", "2988"]
}, {
    "City": "Hailar",
    "DBTZ": "U",
    "Name": "Dongshan",
    "Country": "China",
    "IATA/FAA": "HLD",
    "Longitude": "119.825",
    "ICAO": "ZBLA",
    "Airport ID": "3366",
    "Latitude": "49.204997",
    "Timezone": "2169",
    "DST": "8",
    "destinations": ["6345", "2935", "3364", "6342", "3400", "8979", "6348", "6341", "6795"]
}, {
    "City": "Neerlerit Inaat",
    "DBTZ": "E",
    "Name": "Neerlerit Inaat Airport",
    "Country": "Greenland",
    "IATA/FAA": "CNP",
    "Longitude": "-22.6606",
    "ICAO": "BGCO",
    "Airport ID": "5439",
    "Latitude": "70.7433",
    "Timezone": "45",
    "DST": "-1",
    "destinations": ["4248", "8208"]
}, {
    "City": "Richmond",
    "DBTZ": "O",
    "Name": "Richmond",
    "Country": "Australia",
    "IATA/FAA": "RCM",
    "Longitude": "150.780833",
    "ICAO": "YSRI",
    "Airport ID": "3360",
    "Latitude": "-33.600556",
    "Timezone": "67",
    "DST": "10",
    "destinations": ["6276"]
}, {
    "City": "Sydney",
    "DBTZ": "O",
    "Name": "Sydney Intl",
    "Country": "Australia",
    "IATA/FAA": "SYD",
    "Longitude": "151.177222",
    "ICAO": "YSSY",
    "Airport ID": "3361",
    "Latitude": "-33.946111",
    "Timezone": "21",
    "DST": "10",
    "destinations": ["3393", "3341", "2006", "3319", "3320", "3355", "2009", "3322", "3670", "3999", "3728", "3484", "3339", "3351", "156", "3093", "3077", "2179", "3316", "3364", "3406", "2276", "3370", "3304", "3885", "2188", "2042", "2030", "1960", "1961", "3275", "3940", "2279", "3332", "4010", "4291", "3336", "3179", "6242", "3337", "3325", "3321", "3330", "3930", "2650", "3388", "1997", "3359", "6320", "1959", "3469", "2397", "5", "7128", "3333", "4062", "4319", "3356", "3358", "6269", "813", "3345", "6285", "6300", "2005", "3205", "3362", "3363", "3328", "1969", "4052", "3326", "1963", "6241", "6249", "6271", "6287", "6294", "6298", "6302", "6307", "4320", "6793", "6317", "6794"]
}, {
    "City": "Tamworth",
    "DBTZ": "O",
    "Name": "Tamworth",
    "Country": "Australia",
    "IATA/FAA": "TMW",
    "Longitude": "150.846667",
    "ICAO": "YSTW",
    "Airport ID": "3362",
    "Latitude": "-31.083889",
    "Timezone": "1334",
    "DST": "10",
    "destinations": ["3361"]
}, {
    "City": "Wagga Wagga",
    "DBTZ": "O",
    "Name": "Wagga Wagga",
    "Country": "Australia",
    "IATA/FAA": "WGA",
    "Longitude": "147.466389",
    "ICAO": "YSWG",
    "Airport ID": "3363",
    "Latitude": "-35.165278",
    "Timezone": "724",
    "DST": "10",
    "destinations": ["3361", "3339"]
}, {
    "City": "Orebro",
    "DBTZ": "E",
    "Name": "Orebro",
    "Country": "Sweden",
    "IATA/FAA": "ORB",
    "Longitude": "15.037956",
    "ICAO": "ESOE",
    "Airport ID": "731",
    "Latitude": "59.223733",
    "Timezone": "188",
    "DST": "1",
    "destinations": ["739", "708"]
}, {
    "City": "Arvidsjaur",
    "DBTZ": "E",
    "Name": "Arvidsjaur",
    "Country": "Sweden",
    "IATA/FAA": "AJR",
    "Longitude": "19.281944",
    "ICAO": "ESNX",
    "Airport ID": "730",
    "Latitude": "65.590278",
    "Timezone": "1245",
    "DST": "1",
    "destinations": ["719"]
}, {
    "City": "Vasteras",
    "DBTZ": "E",
    "Name": "Vasteras",
    "Country": "Sweden",
    "IATA/FAA": "VST",
    "Longitude": "16.633611",
    "ICAO": "ESOW",
    "Airport ID": "733",
    "Latitude": "59.589444",
    "Timezone": "21",
    "DST": "1",
    "destinations": ["1230", "1212", "548"]
}, {
    "City": "Lulea",
    "DBTZ": "E",
    "Name": "Kallax",
    "Country": "Sweden",
    "IATA/FAA": "LLA",
    "Longitude": "22.121989",
    "ICAO": "ESPA",
    "Airport ID": "734",
    "Latitude": "65.543758",
    "Timezone": "65",
    "DST": "1",
    "destinations": ["737", "715", "721", "728", "6737"]
}, {
    "City": "Stockholm",
    "DBTZ": "E",
    "Name": "Arlanda",
    "Country": "Sweden",
    "IATA/FAA": "ARN",
    "Longitude": "17.918611",
    "ICAO": "ESSA",
    "Airport ID": "737",
    "Latitude": "59.651944",
    "Timezone": "137",
    "DST": "1",
    "destinations": ["715", "709", "688", "718", "5595", "719", "442", "722", "448", "664", "746", "729", "711", "344", "345", "342", "1456", "1486", "507", "351", "1613", "1382", "1074", "421", "580", "2954", "503", "687", "3953", "1665", "3364", "458", "462", "1230", "1324", "1212", "3941", "1218", "1739", "636", "3885", "1489", "1474", "609", "1509", "1200", "535", "1626", "1555", "3533", "3797", "669", "724", "3484", "1197", "502", "734", "1054", "478", "708", "346", "1354", "3453", "1520", "1386", "644", "3998", "1512", "1587", "1562", "1646", "1206", "337", "1590", "728", "1551", "3959", "599", "2188", "4330", "1107", "340", "16", "1229", "629", "679", "1678", "415", "4317", "2241", "1638", "628", "5597", "469", "608", "1538", "302", "3494", "1701", "707", "2948", "1529", "1606", "3830", "5593", "446", "1203", "685", "721", "726", "666", "2985", "460", "663", "665", "3989", "5952", "4130", "714", "5594", "703", "698", "1706"]
}, {
    "City": "Borlange",
    "DBTZ": "E",
    "Name": "Borlange",
    "Country": "Sweden",
    "IATA/FAA": "BLE",
    "Longitude": "15.515211",
    "ICAO": "ESSD",
    "Airport ID": "739",
    "Latitude": "60.422017",
    "Timezone": "503",
    "DST": "1",
    "destinations": ["687", "731"]
}, {
    "City": "Stockholm",
    "DBTZ": "E",
    "Name": "Bromma",
    "Country": "Sweden",
    "IATA/FAA": "BMA",
    "Longitude": "17.94165",
    "ICAO": "ESSB",
    "Airport ID": "738",
    "Latitude": "59.354372",
    "Timezone": "47",
    "DST": "1",
    "destinations": ["687", "421", "708", "728", "607", "5597", "709", "707", "685", "721", "693", "746", "711", "302", "5593"]
}, {
    "City": "Noumea",
    "DBTZ": "U",
    "Name": "Magenta",
    "Country": "New Caledonia",
    "IATA/FAA": "GEA",
    "Longitude": "166.472806",
    "ICAO": "NWWM",
    "Airport ID": "2001",
    "Latitude": "-22.258278",
    "Timezone": "10",
    "DST": "11",
    "destinations": ["5921", "1998", "1999", "2000", "2002", "5919", "2003", "2004"]
}, {
    "City": "Columbus Mississippi",
    "DBTZ": "A",
    "Name": "Golden Triangle Regional Airport",
    "Country": "United States",
    "IATA/FAA": "GTR",
    "Longitude": "-88.591361",
    "ICAO": "KGTR",
    "Airport ID": "4273",
    "Latitude": "33.450333",
    "Timezone": "264",
    "DST": "-6",
    "destinations": ["3682"]
}, {
    "City": "Saint Cloud",
    "DBTZ": "A",
    "Name": "Saint Cloud Regional Airport",
    "Country": "United States",
    "IATA/FAA": "STC",
    "Longitude": "-94.059889",
    "ICAO": "KSTC",
    "Airport ID": "4271",
    "Latitude": "45.546556",
    "Timezone": "1031",
    "DST": "-6",
    "destinations": ["6505", "3830"]
}, {
    "City": "Birmingham",
    "DBTZ": "E",
    "Name": "Birmingham",
    "Country": "United Kingdom",
    "IATA/FAA": "BHX",
    "Longitude": "-1.748028",
    "ICAO": "EGBB",
    "Airport ID": "469",
    "Latitude": "52.453856",
    "Timezone": "327",
    "DST": "0",
    "destinations": ["342", "351", "1382", "1335", "3093", "532", "1212", "580", "467", "344", "1200", "345", "535", "1563", "498", "534", "352", "533", "520", "499", "1524", "600", "1636", "350", "605", "687", "1055", "599", "596", "603", "2188", "1230", "1218", "1745", "5591", "1626", "1051", "668", "1225", "669", "671", "3956", "468", "1054", "1227", "1606", "3998", "1236", "1056", "3494", "340", "346", "1678", "2223", "737", "609", "302", "521", "2976", "1688", "1715", "1197", "7447", "1701", "1706", "4315", "1103", "4091", "1198", "1075", "4057", "465", "1555", "1738", "1452", "1130", "1213", "1231", "1354", "1206", "1551"]
}, {
    "City": "Erie",
    "DBTZ": "A",
    "Name": "Erie Intl Tom Ridge Fld",
    "Country": "United States",
    "IATA/FAA": "ERI",
    "Longitude": "-80.176217",
    "ICAO": "KERI",
    "Airport ID": "4276",
    "Latitude": "42.082022",
    "Timezone": "733",
    "DST": "-5",
    "destinations": ["3752", "3645", "3486"]
}, {
    "City": "Nizhniy Novgorod",
    "DBTZ": "N",
    "Name": "Nizhny Novgorod",
    "Country": "Russia",
    "IATA/FAA": "GOJ",
    "Longitude": "43.784042",
    "ICAO": "UWGG",
    "Airport ID": "4274",
    "Latitude": "56.230119",
    "Timezone": "256",
    "DST": "4",
    "destinations": ["340", "1587", "3964", "4029", "2985", "8076", "2979", "6147", "6150", "2983", "4118", "2975", "2988", "6156", "4352"]
}, {
    "City": "Marseille",
    "DBTZ": "E",
    "Name": "Provence",
    "Country": "France",
    "IATA/FAA": "MRS",
    "Longitude": "5.213611",
    "ICAO": "LFML",
    "Airport ID": "1353",
    "Latitude": "43.435555",
    "Timezone": "74",
    "DST": "1",
    "destinations": ["3941", "1452", "1335", "1435", "1273", "507", "1324", "210", "580", "1403", "1321", "1280", "1264", "1382", "1322", "1555", "1323", "1701", "1399", "1524", "1418", "1386", "1587", "1075", "1415", "2985", "1590", "287", "351", "1551", "220", "209", "5552", "221", "231", "6492", "230", "1074", "293", "599", "340", "1064", "1230", "1519", "1474", "1553", "304", "1509", "535", "585", "5671", "1066", "691", "1225", "1638", "1229", "1606", "5673", "1636", "1070", "3998", "1512", "1072", "548", "1253", "1080", "1376", "1246", "8414", "1209", "1218", "346", "918", "4317", "915", "916", "302", "374", "1320", "1665", "1423", "1274", "146", "286", "502"]
}, {
    "City": "Carcassonne",
    "DBTZ": "E",
    "Name": "Salvaza",
    "Country": "France",
    "IATA/FAA": "CCF",
    "Longitude": "2.306317",
    "ICAO": "LFMK",
    "Airport ID": "1352",
    "Latitude": "43.215978",
    "Timezone": "433",
    "DST": "1",
    "destinations": ["608", "494", "304", "599", "585", "523", "491", "1636", "537", "548"]
}, {
    "City": "San Pedro",
    "DBTZ": "U",
    "Name": "San Pedro",
    "Country": "Belize",
    "IATA/FAA": "SPR",
    "Longitude": "-87.971075",
    "ICAO": "MZ10",
    "Airport ID": "4279",
    "Latitude": "17.913936",
    "Timezone": "4",
    "DST": "-6",
    "destinations": ["8227", "1957", "5454", "7029", "6463"]
}, {
    "City": "St-Etienne",
    "DBTZ": "E",
    "Name": "Boutheon",
    "Country": "France",
    "IATA/FAA": "EBU",
    "Longitude": "4.296389",
    "ICAO": "LFMH",
    "Airport ID": "1350",
    "Latitude": "45.540554",
    "Timezone": "1325",
    "DST": "1",
    "destinations": ["1321", "1066", "1636", "4317"]
}, {
    "City": "Turku",
    "DBTZ": "E",
    "Name": "Turku",
    "Country": "Finland",
    "IATA/FAA": "TKU",
    "Longitude": "22.262808",
    "ICAO": "EFTU",
    "Airport ID": "460",
    "Latitude": "60.514142",
    "Timezone": "161",
    "DST": "2",
    "destinations": ["442", "421", "3953", "1212", "737", "609", "668"]
}, {
    "City": "Billund",
    "DBTZ": "E",
    "Name": "Billund",
    "Country": "Denmark",
    "IATA/FAA": "BLL",
    "Longitude": "9.151778",
    "ICAO": "EKBI",
    "Airport ID": "608",
    "Latitude": "55.740322",
    "Timezone": "247",
    "DST": "1",
    "destinations": ["1382", "580", "636", "302", "345", "503", "478", "346", "644", "1678", "3953", "628", "610", "666", "1212", "16", "1230", "1517", "1525", "1489", "1352", "1460", "1474", "1553", "1626", "1222", "1606", "3998", "1562", "548", "1056", "1539", "1209", "340", "1688", "625", "737", "609", "1701"]
}, {
    "City": "Guemar",
    "DBTZ": "N",
    "Name": "Guemar Airport",
    "Country": "Algeria",
    "IATA/FAA": "ELU",
    "Longitude": "6.77679",
    "ICAO": "DAUO",
    "Airport ID": "5555",
    "Latitude": "33.5114",
    "Timezone": "203",
    "DST": "1",
    "destinations": ["210"]
}, {
    "City": "Bordj Badji Mokhtar",
    "DBTZ": "N",
    "Name": "Bordj Badji Mokhtar Airport",
    "Country": "Algeria",
    "IATA/FAA": "BMW",
    "Longitude": "0.923889",
    "ICAO": "DATM",
    "Airport ID": "5554",
    "Latitude": "21.375",
    "Timezone": "1303",
    "DST": "1",
    "destinations": ["234"]
}, {
    "City": "Heringsdorf",
    "DBTZ": "E",
    "Name": "Heringsdorf Airport",
    "Country": "Germany",
    "IATA/FAA": "HDF",
    "Longitude": "14.152347",
    "ICAO": "EDAH",
    "Airport ID": "5557",
    "Latitude": "53.878706",
    "Timezone": "93",
    "DST": "1",
    "destinations": ["344", "373", "345", "340", "350"]
}, {
    "City": "Kumasi",
    "DBTZ": "N",
    "Name": "Kumasi Airport",
    "Country": "Ghana",
    "IATA/FAA": "KMS",
    "Longitude": "-1.59082",
    "ICAO": "DGSI",
    "Airport ID": "5556",
    "Latitude": "6.71456",
    "Timezone": "942",
    "DST": "0",
    "destinations": ["248"]
}, {
    "City": "Churchill Falls",
    "DBTZ": "A",
    "Name": "Churchill Falls Airport",
    "Country": "Canada",
    "IATA/FAA": "ZUM",
    "Longitude": "-64.1064",
    "ICAO": "CZUM",
    "Airport ID": "5550",
    "Latitude": "53.5619",
    "Timezone": "1442",
    "DST": "-4",
    "destinations": ["161", "188"]
}, {
    "City": "Bechar",
    "DBTZ": "N",
    "Name": "Bechar Boudghene Ben Ali Lotfi Airport",
    "Country": "Algeria",
    "IATA/FAA": "CBH",
    "Longitude": "-2.26986",
    "ICAO": "DAOR",
    "Airport ID": "5553",
    "Latitude": "31.6457",
    "Timezone": "2661",
    "DST": "1",
    "destinations": ["231"]
}, {
    "City": "Batna",
    "DBTZ": "N",
    "Name": "Batna Airport",
    "Country": "Algeria",
    "IATA/FAA": "BLJ",
    "Longitude": "6.308589",
    "ICAO": "DABT",
    "Airport ID": "5552",
    "Latitude": "35.752106",
    "Timezone": "2697",
    "DST": "1",
    "destinations": ["210", "1335", "1353", "1386"]
}, {
    "City": "Dubai",
    "DBTZ": "U",
    "Name": "Dubai Al Maktoum",
    "Country": "United Arab Emirates",
    "IATA/FAA": "DWC",
    "Longitude": "55.103174",
    "ICAO": "OMDW",
    "Airport ID": "8076",
    "Latitude": "24.55056",
    "Timezone": "170",
    "DST": "4",
    "destinations": ["2397", "507", "2994", "2170", "2057", "2177", "3069", "3024", "2064", "2241", "2940", "2944", "2072", "2050", "1735", "1175", "3125", "4118", "2176", "2990", "2194", "3156", "2947", "2082", "2975", "2992", "3304", "2968", "4274", "2962", "2973", "4029", "2948", "2958", "4078", "4111", "2988", "1489", "1657", "1194"]
}, {
    "City": "Manzhouli",
    "DBTZ": "U",
    "Name": "Manzhouli",
    "Country": "China",
    "IATA/FAA": "NZH",
    "Longitude": "117.329444",
    "ICAO": "",
    "Airport ID": "6954",
    "Latitude": "49.566667",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3400", "6345", "2935", "2937", "3364", "4374", "4078", "2938", "6341", "6795"]
}, {
    "City": "Wuhai",
    "DBTZ": "U",
    "Name": "Wuhai",
    "Country": "China",
    "IATA/FAA": "WUA",
    "Longitude": "106.799444",
    "ICAO": "ZBUH",
    "Airport ID": "6955",
    "Latitude": "39.794444",
    "Timezone": "0",
    "DST": "8",
    "destinations": ["3364", "6345", "3379", "6341"]
}, {
    "City": "Brainerd",
    "DBTZ": "U",
    "Name": "Brainerd Lakes Rgnl",
    "Country": "United States",
    "IATA/FAA": "BRD",
    "Longitude": "-94.138078",
    "ICAO": "KBRD",
    "Airport ID": "6957",
    "Latitude": "46.398308",
    "Timezone": "1226",
    "DST": "-6",
    "destinations": ["3535", "3858"]
}, {
    "City": "Bedourie",
    "DBTZ": "O",
    "Name": "Bedourie Airport",
    "Country": "Australia",
    "IATA/FAA": "BEU",
    "Longitude": "139.46",
    "ICAO": "YBIE",
    "Airport ID": "6243",
    "Latitude": "-24.3461",
    "Timezone": "300",
    "DST": "10",
    "destinations": ["6248", "6240"]
}, {
    "City": "Cagliari",
    "DBTZ": "E",
    "Name": "Elmas",
    "Country": "Italy",
    "IATA/FAA": "CAG",
    "Longitude": "9.054283",
    "ICAO": "LIEE",
    "Airport ID": "1519",
    "Latitude": "39.251469",
    "Timezone": "13",
    "DST": "1",
    "destinations": ["344", "345", "350", "346", "1555", "1529", "1525", "1501", "1367", "1553", "304", "1534", "4166", "1528", "1222", "355", "669", "3956", "1229", "1353", "4198", "1567", "1531", "1562", "1505", "1515", "1539", "1563", "1538", "1561", "1526", "1550", "629", "4053", "1665", "1524", "548", "337"]
}, {
    "City": "Trapani",
    "DBTZ": "E",
    "Name": "Trapani Birgi",
    "Country": "Italy",
    "IATA/FAA": "TPS",
    "Longitude": "12.487961",
    "ICAO": "LICT",
    "Airport ID": "1515",
    "Latitude": "37.911403",
    "Timezone": "24",
    "DST": "1",
    "destinations": ["1511", "4309", "1525", "1538", "1745", "1367", "1519", "1553", "304", "1534", "585", "4166", "3986", "1528", "1222", "355", "669", "3956", "478", "1606", "582", "1567", "1531", "1562", "1526", "1545", "1539", "8414"]
}, {
    "City": "Reggio Calabria",
    "DBTZ": "E",
    "Name": "Reggio Calabria",
    "Country": "Italy",
    "IATA/FAA": "REG",
    "Longitude": "15.651556",
    "ICAO": "LICR",
    "Airport ID": "1514",
    "Latitude": "38.071206",
    "Timezone": "96",
    "DST": "1",
    "destinations": ["1555", "1529", "1526", "1551"]
}, {
    "City": "Alghero",
    "DBTZ": "E",
    "Name": "Alghero",
    "Country": "Italy",
    "IATA/FAA": "AHO",
    "Longitude": "8.290772",
    "ICAO": "LIEA",
    "Airport ID": "1517",
    "Latitude": "40.632133",
    "Timezone": "87",
    "DST": "1",
    "destinations": ["1529", "1526", "4309", "1525", "608", "1538", "1745", "1367", "1553", "304", "1534", "373", "599", "585", "3986", "1222", "691", "355", "492", "1229", "4198", "699", "1531", "1562", "548", "664", "1545", "1539", "1555", "1524", "1657"]
}, {
    "City": "Pantelleria",
    "DBTZ": "E",
    "Name": "Pantelleria",
    "Country": "Italy",
    "IATA/FAA": "PNL",
    "Longitude": "11.968864",
    "ICAO": "LICG",
    "Airport ID": "1511",
    "Latitude": "36.816519",
    "Timezone": "635",
    "DST": "1",
    "destinations": ["1512", "1515", "1551"]
}, {
    "City": "Lampedusa",
    "DBTZ": "E",
    "Name": "Lampedusa",
    "Country": "Italy",
    "IATA/FAA": "LMP",
    "Longitude": "12.618083",
    "ICAO": "LICD",
    "Airport ID": "1510",
    "Latitude": "35.497914",
    "Timezone": "70",
    "DST": "1",
    "destinations": ["1509", "1512", "1551"]
}, {
    "City": "Palermo",
    "DBTZ": "E",
    "Name": "Palermo",
    "Country": "Italy",
    "IATA/FAA": "PMO",
    "Longitude": "13.091019",
    "ICAO": "LICJ",
    "Airport ID": "1512",
    "Latitude": "38.175958",
    "Timezone": "65",
    "DST": "1",
    "destinations": ["344", "580", "1382", "502", "1529", "2985", "1526", "351", "1551", "1550", "1555", "1561", "1501", "737", "1510", "1511", "1525", "1538", "1229", "1353", "4198", "1562", "548", "1253", "1539", "1509", "1218", "1563", "340", "346", "1613", "302", "1386", "287", "1524", "1264", "1528", "1418", "1435"]
}, {
    "City": "Vanua Balavu",
    "DBTZ": "U",
    "Name": "Vanua Balavu Airport",
    "Country": "Fiji",
    "IATA/FAA": "VBV",
    "Longitude": "-178.976",
    "ICAO": "NFVB",
    "Airport ID": "5883",
    "Latitude": "-17.269",
    "Timezone": "76",
    "DST": "12",
    "destinations": ["1961"]
}, {
    "City": "Futuna Island",
    "DBTZ": "U",
    "Name": "Pointe Vele Airport",
    "Country": "Wallis and Futuna",
    "IATA/FAA": "FUT",
    "Longitude": "-178.066",
    "ICAO": "NLWF",
    "Airport ID": "5885",
    "Latitude": "-14.3114",
    "Timezone": "20",
    "DST": "12",
    "destinations": ["1968"]
}, {
    "City": "Alofi",
    "DBTZ": "U",
    "Name": "Niue International Airport",
    "Country": "Niue",
    "IATA/FAA": "IUE",
    "Longitude": "-169.925639",
    "ICAO": "NIUE",
    "Airport ID": "5884",
    "Latitude": "-19.080028",
    "Timezone": "209",
    "DST": "-11",
    "destinations": ["2006"]
}, {
    "City": "Ahe",
    "DBTZ": "U",
    "Name": "Ahe Airport",
    "Country": "French Polynesia",
    "IATA/FAA": "AHE",
    "Longitude": "-146.257",
    "ICAO": "NTHE",
    "Airport ID": "5889",
    "Latitude": "-14.4281",
    "Timezone": "11",
    "DST": "-10",
    "destinations": ["4075", "1978"]
}, {
    "City": "Muscle Shoals",
    "DBTZ": "A",
    "Name": "Northwest Alabama Regional Airport",
    "Country": "United States",
    "IATA/FAA": "MSL",
    "Longitude": "-87.6102",
    "ICAO": "KMSL",
    "Airport ID": "5756",
    "Latitude": "34.7453",
    "Timezone": "550",
    "DST": "-6",
    "destinations": ["3682"]
}, {
    "City": "Sakon Nakhon",
    "DBTZ": "U",
    "Name": "Sakon Nakhon",
    "Country": "Thailand",
    "IATA/FAA": "SNO",
    "Longitude": "104.118625",
    "ICAO": "VTUI",
    "Airport ID": "3184",
    "Latitude": "17.195142",
    "Timezone": "529",
    "DST": "7",
    "destinations": ["3157"]
}, {
    "City": "Okha",
    "DBTZ": "N",
    "Name": "Okha Airport",
    "Country": "Russia",
    "IATA/FAA": "OHH",
    "Longitude": "142.933333",
    "ICAO": "",
    "Airport ID": "7479",
    "Latitude": "53.583333",
    "Timezone": "100",
    "DST": "11",
    "destinations": ["2933", "2927"]
}, {
    "City": "Loei",
    "DBTZ": "U",
    "Name": "Loei",
    "Country": "Thailand",
    "IATA/FAA": "LOE",
    "Longitude": "101.722064",
    "ICAO": "VTUL",
    "Airport ID": "3186",
    "Latitude": "17.439133",
    "Timezone": "860",
    "DST": "7",
    "destinations": ["3157"]
}, {
    "City": "Hat Yai",
    "DBTZ": "U",
    "Name": "Hat Yai Intl",
    "Country": "Thailand",
    "IATA/FAA": "HDY",
    "Longitude": "100.392975",
    "ICAO": "VTSS",
    "Airport ID": "3181",
    "Latitude": "6.933206",
    "Timezone": "90",
    "DST": "7",
    "destinations": ["3304", "3157", "3931", "3885", "3316"]
}, {
    "City": "Trang",
    "DBTZ": "U",
    "Name": "Trang",
    "Country": "Thailand",
    "IATA/FAA": "TST",
    "Longitude": "99.616578",
    "ICAO": "VTST",
    "Airport ID": "3182",
    "Latitude": "7.508744",
    "Timezone": "67",
    "DST": "7",
    "destinations": ["3157"]
}, {
    "City": "Udon Thani",
    "DBTZ": "U",
    "Name": "Udon Thani",
    "Country": "Thailand",
    "IATA/FAA": "UTH",
    "Longitude": "102.788247",
    "ICAO": "VTUD",
    "Airport ID": "3183",
    "Latitude": "17.386436",
    "Timezone": "579",
    "DST": "7",
    "destinations": ["3931", "3157", "3179", "3885"]
}, {
    "City": "Olgii",
    "DBTZ": "U",
    "Name": "Olgii Airport",
    "Country": "Mongolia",
    "IATA/FAA": "ULG",
    "Longitude": "89.919722",
    "ICAO": "ZMUL",
    "Airport ID": "7470",
    "Latitude": "48.991667",
    "Timezone": "5610",
    "DST": "7",
    "destinations": ["3380"]
}, {
    "City": "Muskegon",
    "DBTZ": "A",
    "Name": "Muskegon County Airport",
    "Country": "United States",
    "IATA/FAA": "MKG",
    "Longitude": "-86.2382",
    "ICAO": "KMKG",
    "Airport ID": "5754",
    "Latitude": "43.1695",
    "Timezone": "628",
    "DST": "-5",
    "destinations": ["3830"]
}, {
    "City": "Albuquerque",
    "DBTZ": "A",
    "Name": "Albuquerque International Sunport",
    "Country": "United States",
    "IATA/FAA": "ABQ",
    "Longitude": "-106.6091944",
    "ICAO": "KABQ",
    "Airport ID": "4019",
    "Latitude": "35.0402222",
    "Timezone": "5355",
    "DST": "-7",
    "destinations": ["3670", "3484", "3830", "3462", "3682", "3797", "3858", "3536", "3751", "3877", "3458", "3747", "3720", "3597", "7584", "3550", "3469", "3849", "3502", "3566", "3453", "3731", "3577"]
}, {
    "City": "Evansville",
    "DBTZ": "A",
    "Name": "Evansville Regional",
    "Country": "United States",
    "IATA/FAA": "EVV",
    "Longitude": "-87.532364",
    "ICAO": "KEVV",
    "Airport ID": "4018",
    "Latitude": "38.036997",
    "Timezone": "418",
    "DST": "-6",
    "destinations": ["3682", "3670", "3830", "3645"]
}, {
    "City": "Redang",
    "DBTZ": "N",
    "Name": "Redang",
    "Country": "Malaysia",
    "IATA/FAA": "RDN",
    "Longitude": "103.007",
    "ICAO": "WMPR",
    "Airport ID": "4013",
    "Latitude": "5.76528",
    "Timezone": "10",
    "DST": "8",
    "destinations": ["3316", "3408"]
}, {
    "City": "Naples",
    "DBTZ": "A",
    "Name": "Naples Muni",
    "Country": "United States",
    "IATA/FAA": "APF",
    "Longitude": "-81.775294",
    "ICAO": "KAPF",
    "Airport ID": "4012",
    "Latitude": "26.152619",
    "Timezone": "8",
    "DST": "-5",
    "destinations": ["3576"]
}, {
    "City": "Manchester NH",
    "DBTZ": "A",
    "Name": "Manchester Regional Airport",
    "Country": "United States",
    "IATA/FAA": "MHT",
    "Longitude": "-71.435667",
    "ICAO": "KMHT",
    "Airport ID": "4011",
    "Latitude": "42.932556",
    "Timezone": "266",
    "DST": "-5",
    "destinations": ["3876", "3520", "3752", "3682", "3645", "3697", "3849", "3747", "3858", "3646", "3494", "3830", "3878"]
}, {
    "City": "Uluru",
    "DBTZ": "N",
    "Name": "Ayers Rock",
    "Country": "Australia",
    "IATA/FAA": "AYQ",
    "Longitude": "130.975556",
    "ICAO": "YAYE",
    "Airport ID": "4010",
    "Latitude": "-25.186111",
    "Timezone": "1626",
    "DST": "9.5",
    "destinations": ["3361", "3319", "3322"]
}, {
    "City": "Port-au-prince",
    "DBTZ": "U",
    "Name": "Toussaint Louverture Intl",
    "Country": "Haiti",
    "IATA/FAA": "PAP",
    "Longitude": "-72.292542",
    "ICAO": "MTPP",
    "Airport ID": "1897",
    "Latitude": "18.58005",
    "Timezone": "122",
    "DST": "-5",
    "destinations": ["2899", "3533", "3797", "3576", "146", "2881", "1871", "3682", "1754", "5811", "1895", "1762"]
}, {
    "City": "Roanoke VA",
    "DBTZ": "A",
    "Name": "Roanoke Regional",
    "Country": "United States",
    "IATA/FAA": "ROA",
    "Longitude": "-79.975417",
    "ICAO": "KROA",
    "Airport ID": "4016",
    "Latitude": "37.325472",
    "Timezone": "1175",
    "DST": "-5",
    "destinations": ["3682", "3876", "3697", "3752", "3645", "3617", "4167", "3714", "3830"]
}, {
    "City": "Charlottesville VA",
    "DBTZ": "A",
    "Name": "Charlottesville-Albemarle",
    "Country": "United States",
    "IATA/FAA": "CHO",
    "Longitude": "-78.452861",
    "ICAO": "KCHO",
    "Airport ID": "4015",
    "Latitude": "38.138639",
    "Timezone": "639",
    "DST": "-5",
    "destinations": ["3876", "3697", "3830", "3752", "3682", "3714"]
}, {
    "City": "Louisville",
    "DBTZ": "A",
    "Name": "Louisville International Airport",
    "Country": "United States",
    "IATA/FAA": "SDF",
    "Longitude": "-85.7364989",
    "ICAO": "KSDF",
    "Airport ID": "4014",
    "Latitude": "38.1740858",
    "Timezone": "501",
    "DST": "-5",
    "destinations": ["3876", "3520", "3670", "3697", "3576", "3830", "3752", "3682", "3645", "3797", "3878", "3858", "3849", "3747", "3486", "3751", "3494", "3714", "3550", "3877", "3462", "3646"]
}, {
    "City": "Longyan",
    "DBTZ": "U",
    "Name": "Longyan Airport",
    "Country": "China",
    "IATA/FAA": "LCX",
    "Longitude": "116.746389",
    "ICAO": "",
    "Airport ID": "7504",
    "Latitude": "25.674167",
    "Timezone": "300",
    "DST": "8",
    "destinations": ["3391"]
}, {
    "City": "Baoshan",
    "DBTZ": "U",
    "Name": "Baoshan Airport",
    "Country": "China",
    "IATA/FAA": "BSD",
    "Longitude": "99.168333",
    "ICAO": "ZPBS",
    "Airport ID": "7505",
    "Latitude": "25.053333",
    "Timezone": "500",
    "DST": "8",
    "destinations": ["3382"]
}, {
    "City": "Hahn",
    "DBTZ": "E",
    "Name": "Frankfurt Hahn",
    "Country": "Germany",
    "IATA/FAA": "HHN",
    "Longitude": "7.263892",
    "ICAO": "EDFH",
    "Airport ID": "355",
    "Latitude": "49.948672",
    "Timezone": "1649",
    "DST": "1",
    "destinations": ["1055", "1230", "1517", "1212", "1525", "1501", "1519", "1474", "1553", "9327", "599", "535", "1626", "1066", "1222", "1225", "601", "3956", "1638", "1359", "5673", "1636", "1193", "3998", "1562", "1505", "1203", "1075", "1236", "3953", "1243", "1251", "1486", "548", "1508", "1056", "458", "1515", "1539", "1246", "1448", "1226", "1209", "1489", "671", "1737", "1194"]
}, {
    "City": "Bremen",
    "DBTZ": "E",
    "Name": "Neuenland",
    "Country": "Germany",
    "IATA/FAA": "BRE",
    "Longitude": "8.786667",
    "ICAO": "EDDW",
    "Airport ID": "353",
    "Latitude": "53.0475",
    "Timezone": "14",
    "DST": "1",
    "destinations": ["350", "3998", "1382", "580", "1273", "1230", "1212", "1525", "1360", "1460", "1474", "599", "535", "1626", "1051", "1222", "645", "1054", "478", "699", "1636", "3953", "1486", "548", "1056", "415", "458", "1539", "3959", "340", "346", "609", "1055", "1688", "4091", "1225", "348", "7447", "1613", "1701", "341", "1678", "1706"]
}, {
    "City": "Hannover",
    "DBTZ": "E",
    "Name": "Hannover",
    "Country": "Germany",
    "IATA/FAA": "HAJ",
    "Longitude": "9.685078",
    "ICAO": "EDDV",
    "Airport ID": "352",
    "Latitude": "52.461056",
    "Timezone": "183",
    "DST": "1",
    "destinations": ["1218", "1509", "1200", "1555", "1452", "1524", "1561", "3998", "1742", "4317", "1486", "1206", "548", "350", "1508", "1551", "1613", "507", "1225", "499", "1382", "580", "2954", "469", "478", "1055", "1688", "1715", "1051", "1458", "1197", "1472", "599", "1230", "302", "340", "346", "1678", "609", "2985", "1701", "1103", "1460", "1626", "4091", "1054", "1231", "1056", "1226", "1706"]
}, {
    "City": "Berlin",
    "DBTZ": "E",
    "Name": "Tegel",
    "Country": "Germany",
    "IATA/FAA": "TXL",
    "Longitude": "13.287711",
    "ICAO": "EDDT",
    "Airport ID": "351",
    "Latitude": "52.559686",
    "Timezone": "122",
    "DST": "1",
    "destinations": ["382", "1230", "4309", "469", "1321", "1538", "344", "1509", "1200", "1555", "3986", "421", "1452", "1225", "4322", "507", "1529", "1354", "347", "3998", "1742", "4317", "1646", "1206", "350", "1613", "1550", "1209", "3941", "3797", "3576", "3830", "1055", "1212", "2170", "737", "2179", "1688", "1218", "1739", "1501", "1489", "1460", "609", "4029", "345", "1626", "4166", "4091", "340", "1051", "668", "687", "1609", "410", "1130", "2952", "1458", "669", "2948", "1054", "1229", "1231", "346", "1524", "1561", "7447", "1520", "1386", "644", "1657", "3364", "1472", "3953", "349", "1486", "1476", "1194", "1612", "1056", "1590", "1551", "679", "1678", "1382", "1353", "1512", "1562", "580", "302", "585", "1216", "1563", "1606", "629", "3494", "2988", "1208", "3959", "1587", "2985", "2939", "2241", "1676", "728", "1701", "1418", "356", "1706"]
}, {
    "City": "Fuyang",
    "DBTZ": "U",
    "Name": "Fuyang Airport",
    "Country": "China",
    "IATA/FAA": "FUG",
    "Longitude": "115.816667",
    "ICAO": "ZSFY",
    "Airport ID": "7503",
    "Latitude": "32.9",
    "Timezone": "500",
    "DST": "8",
    "destinations": ["3364", "3370", "3391", "3404", "3386", "3368", "6392", "3379", "3383"]
}, {
    "City": "Socotra",
    "DBTZ": "U",
    "Name": "Socotra Intl",
    "Country": "Yemen",
    "IATA/FAA": "SCT",
    "Longitude": "53.905778",
    "ICAO": "OYSQ",
    "Airport ID": "3984",
    "Latitude": "12.630672",
    "Timezone": "146",
    "DST": "3",
    "destinations": ["3974"]
}, {
    "City": "Memmingen",
    "DBTZ": "E",
    "Name": "Allgau",
    "Country": "Germany",
    "IATA/FAA": "FMM",
    "Longitude": "10.2395",
    "ICAO": "EDJA",
    "Airport ID": "3986",
    "Latitude": "47.988758",
    "Timezone": "2077",
    "DST": "1",
    "destinations": ["1561", "342", "351", "1322", "1230", "1517", "1212", "1474", "1553", "599", "1626", "1222", "1636", "3998", "603", "548", "1056", "1515", "1739", "1737", "2944"]
}, {
    "City": "Nevsehir",
    "DBTZ": "E",
    "Name": "Kapadokya",
    "Country": "Turkey",
    "IATA/FAA": "NAV",
    "Longitude": "34.53455",
    "ICAO": "LTAZ",
    "Airport ID": "3987",
    "Latitude": "38.771867",
    "Timezone": "3100",
    "DST": "2",
    "destinations": ["4317", "1701"]
}, {
    "City": "Liping",
    "DBTZ": "U",
    "Name": "Liping Airport",
    "Country": "China",
    "IATA/FAA": "HZH",
    "Longitude": "109.039",
    "ICAO": "",
    "Airport ID": "7508",
    "Latitude": "26.206",
    "Timezone": "500",
    "DST": "8",
    "destinations": ["3370", "3394"]
}, {
    "City": "Beaumont",
    "DBTZ": "A",
    "Name": "Southeast Texas Rgnl",
    "Country": "United States",
    "IATA/FAA": "BPT",
    "Longitude": "-94.020694",
    "ICAO": "KBPT",
    "Airport ID": "3612",
    "Latitude": "29.950833",
    "Timezone": "15",
    "DST": "-6",
    "destinations": ["3670"]
}, {
    "City": "Kars",
    "DBTZ": "E",
    "Name": "Kars Airport",
    "Country": "Turkey",
    "IATA/FAA": "KSY",
    "Longitude": "43.115002",
    "ICAO": "LTCF",
    "Airport ID": "5796",
    "Latitude": "40.562222",
    "Timezone": "5889",
    "DST": "2",
    "destinations": ["1682", "1701", "4317"]
}, {
    "City": "Elista",
    "DBTZ": "N",
    "Name": "Elista Airport",
    "Country": "Russia",
    "IATA/FAA": "ESL",
    "Longitude": "44.3309",
    "ICAO": "URWI",
    "Airport ID": "6125",
    "Latitude": "46.3739",
    "Timezone": "501",
    "DST": "4",
    "destinations": ["4029"]
}, {
    "City": "Beslan",
    "DBTZ": "N",
    "Name": "Beslan Airport",
    "Country": "Russia",
    "IATA/FAA": "OGZ",
    "Longitude": "44.6066",
    "ICAO": "URMO",
    "Airport ID": "6124",
    "Latitude": "43.2051",
    "Timezone": "1673",
    "DST": "4",
    "destinations": ["4029"]
}, {
    "City": "Savannah",
    "DBTZ": "A",
    "Name": "Savannah Hilton Head Intl",
    "Country": "United States",
    "IATA/FAA": "SAV",
    "Longitude": "-81.202139",
    "ICAO": "KSAV",
    "Airport ID": "3613",
    "Latitude": "32.127583",
    "Timezone": "51",
    "DST": "-5",
    "destinations": ["3876", "3520", "3670", "3752", "3682", "3448", "3797", "3645", "3697", "3494", "3714", "3550", "3830"]
}, {
    "City": "Igarka",
    "DBTZ": "N",
    "Name": "Igarka Airport",
    "Country": "Russia",
    "IATA/FAA": "IAA",
    "Longitude": "86.6219",
    "ICAO": "UOII",
    "Airport ID": "6121",
    "Latitude": "67.4372",
    "Timezone": "82",
    "DST": "8",
    "destinations": ["4374"]
}, {
    "City": "Grozny",
    "DBTZ": "N",
    "Name": "Grozny Airport",
    "Country": "Russia",
    "IATA/FAA": "GRV",
    "Longitude": "45.7841",
    "ICAO": "URMG",
    "Airport ID": "6122",
    "Latitude": "43.2981",
    "Timezone": "548",
    "DST": "4",
    "destinations": ["4029"]
}, {
    "City": "Kearney",
    "DBTZ": "A",
    "Name": "Kearney Municipal Airport",
    "Country": "United States",
    "IATA/FAA": "EAR",
    "Longitude": "-99.0068",
    "ICAO": "EAR",
    "Airport ID": "6129",
    "Latitude": "40.727",
    "Timezone": "2131",
    "DST": "-6",
    "destinations": ["3751"]
}, {
    "City": "Corumba",
    "DBTZ": "S",
    "Name": "Corumba Intl",
    "Country": "Brazil",
    "IATA/FAA": "CMG",
    "Longitude": "-57.673053",
    "ICAO": "SBCR",
    "Airport ID": "2544",
    "Latitude": "-19.011931",
    "Timezone": "461",
    "DST": "-4",
    "destinations": ["2538"]
}, {
    "City": "Kanazawa",
    "DBTZ": "U",
    "Name": "Komatsu",
    "Country": "Japan",
    "IATA/FAA": "KMQ",
    "Longitude": "136.406544",
    "ICAO": "RJNK",
    "Airport ID": "2322",
    "Latitude": "36.394611",
    "Timezone": "36",
    "DST": "9",
    "destinations": ["2276", "2305", "2279", "2347", "2287", "2359", "3930", "3406", "2384"]
}, {
    "City": "Okayama",
    "DBTZ": "U",
    "Name": "Okayama",
    "Country": "Japan",
    "IATA/FAA": "OKJ",
    "Longitude": "133.855278",
    "ICAO": "RJOB",
    "Airport ID": "2327",
    "Latitude": "34.756944",
    "Timezone": "806",
    "DST": "9",
    "destinations": ["2276", "2287", "2359", "3406", "3930", "2246", "2384", "3121"]
}, {
    "City": "Hiroshima",
    "DBTZ": "U",
    "Name": "Hiroshima",
    "Country": "Japan",
    "IATA/FAA": "HIJ",
    "Longitude": "132.919444",
    "ICAO": "RJOA",
    "Airport ID": "2326",
    "Latitude": "34.436111",
    "Timezone": "1088",
    "DST": "9",
    "destinations": ["3404", "2276", "2279", "2347", "2287", "2359", "3406", "3930", "2384"]
}, {
    "City": "Alexandroupolis",
    "DBTZ": "E",
    "Name": "Dimokritos",
    "Country": "Greece",
    "IATA/FAA": "AXD",
    "Longitude": "25.956264",
    "ICAO": "LGAL",
    "Airport ID": "1446",
    "Latitude": "40.855869",
    "Timezone": "24",
    "DST": "2",
    "destinations": ["3941", "1480"]
}, {
    "City": "Izumo",
    "DBTZ": "U",
    "Name": "Izumo",
    "Country": "Japan",
    "IATA/FAA": "IZO",
    "Longitude": "132.89",
    "ICAO": "RJOC",
    "Airport ID": "2328",
    "Latitude": "35.413611",
    "Timezone": "15",
    "DST": "9",
    "destinations": ["2359"]
}, {
    "City": "Niamey",
    "DBTZ": "N",
    "Name": "Diori Hamani",
    "Country": "Niger",
    "IATA/FAA": "NIM",
    "Longitude": "2.183614",
    "ICAO": "DRRN",
    "Airport ID": "280",
    "Latitude": "13.481547",
    "Timezone": "732",
    "DST": "1",
    "destinations": ["246", "1382", "210", "1074", "260", "1107", "245", "999"]
}, {
    "City": "Tunis",
    "DBTZ": "E",
    "Name": "Carthage",
    "Country": "Tunisia",
    "IATA/FAA": "TUN",
    "Longitude": "10.227217",
    "ICAO": "DTTA",
    "Airport ID": "287",
    "Latitude": "36.851033",
    "Timezone": "22",
    "DST": "1",
    "destinations": ["344", "1154", "5804", "1157", "1382", "1353", "210", "1074", "1555", "1524", "1335", "286", "1418", "2188", "340", "5699", "5696", "5697", "2177", "1128", "2241", "2170", "2072", "1701", "1386", "253", "580", "1218", "1739", "1044", "1538", "1264", "302", "1084", "4029", "345", "1665", "2176", "507", "1638", "1229", "2074", "346", "1354", "1094", "231", "246", "1512", "1435", "1273", "1551", "1613", "1678", "293", "291", "290", "1606", "1561", "295", "296"]
}, {
    "City": "Monastir",
    "DBTZ": "E",
    "Name": "Habib Bourguiba Intl",
    "Country": "Tunisia",
    "IATA/FAA": "MIR",
    "Longitude": "10.754722",
    "ICAO": "DTMB",
    "Airport ID": "286",
    "Latitude": "35.758056",
    "Timezone": "9",
    "DST": "1",
    "destinations": ["1382", "287", "1157", "1335", "1418", "1386", "302", "1665", "1353", "1354"]
}, {
    "City": "Galena",
    "DBTZ": "A",
    "Name": "Edward G Pitka Sr",
    "Country": "United States",
    "IATA/FAA": "GAL",
    "Longitude": "-156.937417",
    "ICAO": "PAGA",
    "Airport ID": "3764",
    "Latitude": "64.736178",
    "Timezone": "152",
    "DST": "-9",
    "destinations": ["3832", "7108", "6717", "7111", "6722"]
}, {
    "City": "St.-pierre",
    "DBTZ": "U",
    "Name": "St Pierre",
    "Country": "Saint Pierre and Miquelon",
    "IATA/FAA": "FSP",
    "Longitude": "-56.173088",
    "ICAO": "LFVP",
    "Airport ID": "1441",
    "Latitude": "46.762904",
    "Timezone": "27",
    "DST": "-3",
    "destinations": ["73", "146", "189"]
}, {
    "City": "Banja Luka",
    "DBTZ": "E",
    "Name": "Banja Luka International Airport",
    "Country": "Bosnia and Herzegovina",
    "IATA/FAA": "BNX",
    "Longitude": "17.297501",
    "ICAO": "LQBK",
    "Airport ID": "5794",
    "Latitude": "44.941444",
    "Timezone": "400",
    "DST": "1",
    "destinations": ["1646", "1678", "1739"]
}, {
    "City": "Khorram Abad",
    "DBTZ": "E",
    "Name": "Khoram Abad Airport",
    "Country": "Iran",
    "IATA/FAA": "KHD",
    "Longitude": "48.282889",
    "ICAO": "OICK",
    "Airport ID": "5927",
    "Latitude": "33.435378",
    "Timezone": "3782",
    "DST": "3.5",
    "destinations": ["2131"]
}, {
    "City": "Calabar",
    "DBTZ": "N",
    "Name": "Calabar",
    "Country": "Nigeria",
    "IATA/FAA": "CBQ",
    "Longitude": "8.347197",
    "ICAO": "DNCA",
    "Airport ID": "263",
    "Latitude": "4.976019",
    "Timezone": "210",
    "DST": "1",
    "destinations": ["260", "273"]
}, {
    "City": "Benin",
    "DBTZ": "N",
    "Name": "Benin",
    "Country": "Nigeria",
    "IATA/FAA": "BNI",
    "Longitude": "5.599503",
    "ICAO": "DNBE",
    "Airport ID": "262",
    "Latitude": "6.316981",
    "Timezone": "258",
    "DST": "1",
    "destinations": ["260", "273"]
}, {
    "City": "Abuja",
    "DBTZ": "N",
    "Name": "Nnamdi Azikiwe Intl",
    "Country": "Nigeria",
    "IATA/FAA": "ABV",
    "Longitude": "7.263172",
    "ICAO": "DNAA",
    "Airport ID": "260",
    "Latitude": "9.006792",
    "Timezone": "1123",
    "DST": "1",
    "destinations": ["1382", "275", "507", "1107", "245", "298", "999", "280", "340", "891", "1128", "248", "262", "263", "264", "266", "267", "270", "273", "6730", "6972", "276", "277"]
}, {
    "City": "Ilorin",
    "DBTZ": "N",
    "Name": "Ilorin",
    "Country": "Nigeria",
    "IATA/FAA": "ILR",
    "Longitude": "4.493919",
    "ICAO": "DNIL",
    "Airport ID": "267",
    "Latitude": "8.440211",
    "Timezone": "1126",
    "DST": "1",
    "destinations": ["260"]
}, {
    "City": "Ibadan",
    "DBTZ": "N",
    "Name": "Ibadan",
    "Country": "Nigeria",
    "IATA/FAA": "IBA",
    "Longitude": "3.978333",
    "ICAO": "DNIB",
    "Airport ID": "266",
    "Latitude": "7.362458",
    "Timezone": "725",
    "DST": "1",
    "destinations": ["260"]
}, {
    "City": "Enugu",
    "DBTZ": "N",
    "Name": "Enugu",
    "Country": "Nigeria",
    "IATA/FAA": "ENU",
    "Longitude": "7.561961",
    "ICAO": "DNEN",
    "Airport ID": "264",
    "Latitude": "6.474272",
    "Timezone": "466",
    "DST": "1",
    "destinations": ["1107", "260", "273"]
}, {
    "City": "Kaduna",
    "DBTZ": "N",
    "Name": "Kaduna",
    "Country": "Nigeria",
    "IATA/FAA": "KAD",
    "Longitude": "7.320114",
    "ICAO": "DNKA",
    "Airport ID": "269",
    "Latitude": "10.696025",
    "Timezone": "2073",
    "DST": "1",
    "destinations": ["273"]
}, {
    "City": "Jos",
    "DBTZ": "N",
    "Name": "Yakubu Gowon",
    "Country": "Nigeria",
    "IATA/FAA": "JOS",
    "Longitude": "8.86905",
    "ICAO": "DNJO",
    "Airport ID": "268",
    "Latitude": "9.639828",
    "Timezone": "4232",
    "DST": "1",
    "destinations": ["273"]
}, {
    "City": "General Santos City",
    "DBTZ": "N",
    "Name": "General Santos International Airport",
    "Country": "Philippines",
    "IATA/FAA": "GES",
    "Longitude": "125.2353",
    "ICAO": "RPMB",
    "Airport ID": "6011",
    "Latitude": "6.106439",
    "Timezone": "28",
    "DST": "8",
    "destinations": ["2397", "4206", "2429"]
}, {
    "City": "Jolo",
    "DBTZ": "N",
    "Name": "Jolo Airport",
    "Country": "Philippines",
    "IATA/FAA": "JOL",
    "Longitude": "121.011",
    "ICAO": "RPMJ",
    "Airport ID": "6013",
    "Latitude": "6.05367",
    "Timezone": "118",
    "DST": "8",
    "destinations": ["2404"]
}, {
    "City": "Camiguin",
    "DBTZ": "N",
    "Name": "Camiguin Airport",
    "Country": "Philippines",
    "IATA/FAA": "CGM",
    "Longitude": "124.707",
    "ICAO": "RPMH",
    "Airport ID": "6012",
    "Latitude": "9.25352",
    "Timezone": "53",
    "DST": "8",
    "destinations": ["4206"]
}, {
    "City": "Kaukura Atoll",
    "DBTZ": "U",
    "Name": "Kaukura",
    "Country": "French Polynesia",
    "IATA/FAA": "KKR",
    "Longitude": "-146.884769",
    "ICAO": "NTGK",
    "Airport ID": "1980",
    "Latitude": "-15.663333",
    "Timezone": "11",
    "DST": "-10",
    "destinations": ["4075"]
}, {
    "City": "Sangley Point",
    "DBTZ": "N",
    "Name": "Surigao Airport",
    "Country": "Philippines",
    "IATA/FAA": "SUG",
    "Longitude": "125.479328",
    "ICAO": "RPMS",
    "Airport ID": "6016",
    "Latitude": "9.757567",
    "Timezone": "20",
    "DST": "8",
    "destinations": ["2397", "4206"]
}, {
    "City": "Basco",
    "DBTZ": "N",
    "Name": "Basco Airport",
    "Country": "Philippines",
    "IATA/FAA": "BSO",
    "Longitude": "121.979883",
    "ICAO": "RPUO",
    "Airport ID": "6019",
    "Latitude": "20.451322",
    "Timezone": "291",
    "DST": "8",
    "destinations": ["2397"]
}, {
    "City": "Naga",
    "DBTZ": "N",
    "Name": "Naga Airport",
    "Country": "Philippines",
    "IATA/FAA": "WNP",
    "Longitude": "123.270239",
    "ICAO": "RPUN",
    "Airport ID": "6018",
    "Latitude": "13.584886",
    "Timezone": "142",
    "DST": "8",
    "destinations": ["2397"]
}, {
    "City": "Puerto Madryn",
    "DBTZ": "N",
    "Name": "El Tehuelche",
    "Country": "Argentina",
    "IATA/FAA": "PMY",
    "Longitude": "-65.102725",
    "ICAO": "SAVY",
    "Airport ID": "2492",
    "Latitude": "-42.759161",
    "Timezone": "426",
    "DST": "-3",
    "destinations": ["2442"]
}, {
    "City": "Rio Grande",
    "DBTZ": "N",
    "Name": "Rio Grande",
    "Country": "Argentina",
    "IATA/FAA": "RGA",
    "Longitude": "-67.749389",
    "ICAO": "SAWE",
    "Airport ID": "2495",
    "Latitude": "-53.777667",
    "Timezone": "65",
    "DST": "-3",
    "destinations": ["2496", "2442"]
}, {
    "City": "Ushuaia",
    "DBTZ": "N",
    "Name": "Ushuaia Malvinas Argentinas",
    "Country": "Argentina",
    "IATA/FAA": "USH",
    "Longitude": "-68.29575",
    "ICAO": "SAWH",
    "Airport ID": "2497",
    "Latitude": "-54.843278",
    "Timezone": "71",
    "DST": "-3",
    "destinations": ["2442", "3988", "4061"]
}, {
    "City": "Rio Gallegos",
    "DBTZ": "N",
    "Name": "Rio Gallegos",
    "Country": "Argentina",
    "IATA/FAA": "RGL",
    "Longitude": "-69.312636",
    "ICAO": "SAWG",
    "Airport ID": "2496",
    "Latitude": "-51.608875",
    "Timezone": "61",
    "DST": "-3",
    "destinations": ["2487", "2495", "2497", "2442", "579"]
}, {
    "City": "Kinmen",
    "DBTZ": "U",
    "Name": "Shang Yi",
    "Country": "Taiwan",
    "IATA/FAA": "KNH",
    "Longitude": "118.359197",
    "ICAO": "RCBS",
    "Airport ID": "2259",
    "Latitude": "24.427892",
    "Timezone": "93",
    "DST": "8",
    "destinations": ["2268", "2275", "2264", "2272"]
}, {
    "City": "Yap",
    "DBTZ": "U",
    "Name": "Yap Intl",
    "Country": "Micronesia",
    "IATA/FAA": "YAP",
    "Longitude": "138.082497",
    "ICAO": "PTYA",
    "Airport ID": "2258",
    "Latitude": "9.498911",
    "Timezone": "91",
    "DST": "10",
    "destinations": ["2246", "2256"]
}, {
    "City": "Kosrae",
    "DBTZ": "U",
    "Name": "Kosrae",
    "Country": "Micronesia",
    "IATA/FAA": "KSA",
    "Longitude": "162.958386",
    "ICAO": "PTSA",
    "Airport ID": "2257",
    "Latitude": "5.356975",
    "Timezone": "11",
    "DST": "11",
    "destinations": ["2251", "2255"]
}, {
    "City": "Babelthuap",
    "DBTZ": "U",
    "Name": "Babelthuap",
    "Country": "Palau",
    "IATA/FAA": "ROR",
    "Longitude": "134.544278",
    "ICAO": "PTRO",
    "Airport ID": "2256",
    "Latitude": "7.367303",
    "Timezone": "176",
    "DST": "9",
    "destinations": ["2279", "2276", "3930", "2246", "2397", "2258"]
}, {
    "City": "Pohnpei",
    "DBTZ": "U",
    "Name": "Pohnpei Intl",
    "Country": "Micronesia",
    "IATA/FAA": "PNI",
    "Longitude": "158.208989",
    "ICAO": "PTPN",
    "Airport ID": "2255",
    "Latitude": "6.9851",
    "Timezone": "10",
    "DST": "11",
    "destinations": ["2257", "2251", "2254"]
}, {
    "City": "Chuuk",
    "DBTZ": "U",
    "Name": "Chuuk Intl",
    "Country": "Micronesia",
    "IATA/FAA": "TKK",
    "Longitude": "151.843006",
    "ICAO": "PTKK",
    "Airport ID": "2254",
    "Latitude": "7.461869",
    "Timezone": "11",
    "DST": "10",
    "destinations": ["2246", "2255"]
}, {
    "City": "Kiritimati",
    "DBTZ": "U",
    "Name": "Cassidy Intl",
    "Country": "Kiribati",
    "IATA/FAA": "CXI",
    "Longitude": "-157.349778",
    "ICAO": "PLCH",
    "Airport ID": "2252",
    "Latitude": "1.986161",
    "Timezone": "5",
    "DST": "-12",
    "destinations": ["3728", "1960"]
}, {
    "City": "Kwajalein",
    "DBTZ": "U",
    "Name": "Bucholz Aaf",
    "Country": "Marshall Islands",
    "IATA/FAA": "KWA",
    "Longitude": "167.731661",
    "ICAO": "PKWA",
    "Airport ID": "2251",
    "Latitude": "8.720122",
    "Timezone": "9",
    "DST": "12",
    "destinations": ["2257", "2249", "2255"]
}, {
    "City": "Jujuy",
    "DBTZ": "N",
    "Name": "Jujuy",
    "Country": "Argentina",
    "IATA/FAA": "JUJ",
    "Longitude": "-65.097778",
    "ICAO": "SASJ",
    "Airport ID": "2477",
    "Latitude": "-24.392778",
    "Timezone": "3019",
    "DST": "-3",
    "destinations": ["2442", "2443"]
}, {
    "City": "Salta",
    "DBTZ": "N",
    "Name": "Salta",
    "Country": "Argentina",
    "IATA/FAA": "SLA",
    "Longitude": "-65.486169",
    "ICAO": "SASA",
    "Airport ID": "2476",
    "Latitude": "-24.855978",
    "Timezone": "4088",
    "DST": "-3",
    "destinations": ["2442", "2443", "2471", "2452"]
}, {
    "City": "Tete",
    "DBTZ": "U",
    "Name": "Tete Chingodzi",
    "Country": "Mozambique",
    "IATA/FAA": "TET",
    "Longitude": "33.640181",
    "ICAO": "FQTT",
    "Airport ID": "988",
    "Latitude": "-16.104817",
    "Timezone": "525",
    "DST": "2",
    "destinations": ["813", "984", "974", "979", "986", "977"]
}, {
    "City": "Posadas",
    "DBTZ": "N",
    "Name": "Posadas",
    "Country": "Argentina",
    "IATA/FAA": "PSS",
    "Longitude": "-55.970728",
    "ICAO": "SARP",
    "Airport ID": "2474",
    "Latitude": "-27.385839",
    "Timezone": "430",
    "DST": "-3",
    "destinations": ["2442"]
}, {
    "City": "Iguazu Falls",
    "DBTZ": "N",
    "Name": "Cataratas Del Iguazu",
    "Country": "Argentina",
    "IATA/FAA": "IGR",
    "Longitude": "-54.473444",
    "ICAO": "SARI",
    "Airport ID": "2471",
    "Latitude": "-25.737281",
    "Timezone": "916",
    "DST": "-3",
    "destinations": ["2442", "3988", "2476"]
}, {
    "City": "Formosa",
    "DBTZ": "N",
    "Name": "Formosa",
    "Country": "Argentina",
    "IATA/FAA": "FMA",
    "Longitude": "-58.228111",
    "ICAO": "SARF",
    "Airport ID": "2470",
    "Latitude": "-26.212722",
    "Timezone": "194",
    "DST": "-3",
    "destinations": ["2442"]
}, {
    "City": "Quelimane",
    "DBTZ": "U",
    "Name": "Quelimane",
    "Country": "Mozambique",
    "IATA/FAA": "UEL",
    "Longitude": "36.869106",
    "ICAO": "FQQL",
    "Airport ID": "986",
    "Latitude": "-17.8555",
    "Timezone": "36",
    "DST": "2",
    "destinations": ["974", "979", "988"]
}, {
    "City": "Nampula",
    "DBTZ": "U",
    "Name": "Nampula",
    "Country": "Mozambique",
    "IATA/FAA": "APL",
    "Longitude": "39.2818",
    "ICAO": "FQNP",
    "Airport ID": "984",
    "Latitude": "-15.105611",
    "Timezone": "1444",
    "DST": "2",
    "destinations": ["4059", "985", "813", "974", "979", "988", "977"]
}, {
    "City": "Pemba",
    "DBTZ": "U",
    "Name": "Pemba",
    "Country": "Mozambique",
    "IATA/FAA": "POL",
    "Longitude": "40.522492",
    "ICAO": "FQPB",
    "Airport ID": "985",
    "Latitude": "-12.986753",
    "Timezone": "331",
    "DST": "2",
    "destinations": ["984", "4059", "813", "974", "1177", "979"]
}, {
    "City": "Kenora",
    "DBTZ": "A",
    "Name": "Kenora",
    "Country": "Canada",
    "IATA/FAA": "YQK",
    "Longitude": "-94.363056",
    "ICAO": "CYQK",
    "Airport ID": "115",
    "Latitude": "49.788334",
    "Timezone": "1332",
    "DST": "-6",
    "destinations": ["5479", "67", "160"]
}, {
    "City": "Moncton",
    "DBTZ": "A",
    "Name": "Greater Moncton Intl",
    "Country": "Canada",
    "IATA/FAA": "YQM",
    "Longitude": "-64.678611",
    "ICAO": "CYQM",
    "Airport ID": "117",
    "Latitude": "46.112221",
    "Timezone": "232",
    "DST": "-4",
    "destinations": ["73", "100", "146", "193", "3878", "3494", "70"]
}, {
    "City": "Davao",
    "DBTZ": "N",
    "Name": "Francisco Bangoy International",
    "Country": "Philippines",
    "IATA/FAA": "DVO",
    "Longitude": "125.645778",
    "ICAO": "RPMD",
    "Airport ID": "4090",
    "Latitude": "7.125522",
    "Timezone": "59",
    "DST": "8",
    "destinations": ["2423", "4206", "2400", "4201", "2429", "2397", "2433", "2404", "3316"]
}, {
    "City": "Quebec",
    "DBTZ": "A",
    "Name": "Quebec Jean Lesage Intl",
    "Country": "Canada",
    "IATA/FAA": "YQB",
    "Longitude": "-71.393333",
    "ICAO": "CYQB",
    "Airport ID": "111",
    "Latitude": "46.791111",
    "Timezone": "244",
    "DST": "-5",
    "destinations": ["80", "146", "3752", "3830", "63", "100", "193", "202", "27", "65", "71", "6775", "161", "192", "144", "1382", "1760", "3494"]
}, {
    "City": "Uyuni",
    "DBTZ": "U",
    "Name": "Uyuni Airport",
    "Country": "Bolivia",
    "IATA/FAA": "UYU",
    "Longitude": "-66.833333",
    "ICAO": "SLUY",
    "Airport ID": "7313",
    "Latitude": "-20.466667",
    "Timezone": "11990",
    "DST": "-4",
    "destinations": ["2762"]
}, {
    "City": "Windsor",
    "DBTZ": "A",
    "Name": "Windsor",
    "Country": "Canada",
    "IATA/FAA": "YQG",
    "Longitude": "-82.955556",
    "ICAO": "CYQG",
    "Airport ID": "113",
    "Latitude": "42.275556",
    "Timezone": "622",
    "DST": "-5",
    "destinations": ["193", "144", "178"]
}, {
    "City": "Red Deer Industrial",
    "DBTZ": "A",
    "Name": "Red Deer Regional",
    "Country": "Canada",
    "IATA/FAA": "YQF",
    "Longitude": "-113.894444",
    "ICAO": "CYQF",
    "Airport ID": "112",
    "Latitude": "52.182222",
    "Timezone": "2968",
    "DST": "-7",
    "destinations": ["178", "87", "90"]
}, {
    "City": "Comox",
    "DBTZ": "A",
    "Name": "Comox",
    "Country": "Canada",
    "IATA/FAA": "YQQ",
    "Longitude": "-124.886667",
    "ICAO": "CYQQ",
    "Airport ID": "119",
    "Latitude": "49.710833",
    "Timezone": "84",
    "DST": "-8",
    "destinations": ["30", "156", "49", "178"]
}, {
    "City": "Chiclayo",
    "DBTZ": "U",
    "Name": "Capt Jose A Quinones Gonzales Intl",
    "Country": "Peru",
    "IATA/FAA": "CIX",
    "Longitude": "-79.828097",
    "ICAO": "SPHI",
    "Airport ID": "2785",
    "Latitude": "-6.787475",
    "Timezone": "97",
    "DST": "-5",
    "destinations": ["2789"]
}, {
    "City": "Andahuaylas",
    "DBTZ": "U",
    "Name": "Andahuaylas",
    "Country": "Peru",
    "IATA/FAA": "ANS",
    "Longitude": "-73.350378",
    "ICAO": "SPHY",
    "Airport ID": "2787",
    "Latitude": "-13.706408",
    "Timezone": "11300",
    "DST": "-5",
    "destinations": ["2789"]
}, {
    "City": "Ayacucho",
    "DBTZ": "U",
    "Name": "Coronel Fap Alfredo Mendivil Duarte",
    "Country": "Peru",
    "IATA/FAA": "AYP",
    "Longitude": "-74.204417",
    "ICAO": "SPHO",
    "Airport ID": "2786",
    "Latitude": "-13.154819",
    "Timezone": "8917",
    "DST": "-5",
    "destinations": ["2789"]
}, {
    "City": "Pucallpa",
    "DBTZ": "U",
    "Name": "Cap Fap David Abenzur Rengifo Intl",
    "Country": "Peru",
    "IATA/FAA": "PCL",
    "Longitude": "-74.574297",
    "ICAO": "SPCL",
    "Airport ID": "2781",
    "Latitude": "-8.377939",
    "Timezone": "513",
    "DST": "-5",
    "destinations": ["2801", "2789", "2806"]
}, {
    "City": "Lima",
    "DBTZ": "U",
    "Name": "Jorge Chavez Intl",
    "Country": "Peru",
    "IATA/FAA": "LIM",
    "Longitude": "-77.114319",
    "ICAO": "SPIM",
    "Airport ID": "2789",
    "Latitude": "-12.021889",
    "Timezone": "113",
    "DST": "-5",
    "destinations": ["2786", "2812", "6067", "2781", "2806", "3670", "3797", "3484", "3576", "2650", "193", "1382", "2802", "2762", "1824", "2771", "3988", "2651", "2699", "2709", "2851", "2715", "2560", "2564", "2673", "2745", "2816", "2599", "1892", "1762", "1885", "2688", "580", "3533", "1871", "3682", "1229", "2554", "2785", "6066", "2443", "1852", "1909", "2801", "2792", "2808", "2809", "1760", "2796", "2807", "2804", "3494", "3550", "2787", "2788"]
}, {
    "City": "Anta",
    "DBTZ": "U",
    "Name": "Comandante Fap German Arias Graziani",
    "Country": "Peru",
    "IATA/FAA": "ATA",
    "Longitude": "-77.598392",
    "ICAO": "SPHZ",
    "Airport ID": "2788",
    "Latitude": "-9.347444",
    "Timezone": "9097",
    "DST": "-5",
    "destinations": ["2789"]
}, {
    "City": "Normanton",
    "DBTZ": "O",
    "Name": "Normanton Airport",
    "Country": "Australia",
    "IATA/FAA": "NTN",
    "Longitude": "141.07",
    "ICAO": "YNTN",
    "Airport ID": "6309",
    "Latitude": "-17.6836",
    "Timezone": "73",
    "DST": "10",
    "destinations": ["3322", "6304"]
}, {
    "City": "Jeddah",
    "DBTZ": "U",
    "Name": "King Abdulaziz Intl",
    "Country": "Saudi Arabia",
    "IATA/FAA": "JED",
    "Longitude": "39.156536",
    "ICAO": "OEJN",
    "Airport ID": "2072",
    "Latitude": "21.679564",
    "Timezone": "48",
    "DST": "3",
    "destinations": ["3076", "1154", "1157", "999", "2997", "1107", "340", "1701", "1382", "3137", "3093", "3141", "1074", "1555", "1524", "507", "3069", "3272", "3304", "3410", "2188", "2179", "2176", "8076", "2191", "3275", "3928", "2057", "3977", "5954", "3976", "3974", "3980", "3975", "2082", "4059", "8235", "2177", "3024", "1128", "1132", "4057", "2223", "2207", "2219", "2206", "2214", "6776", "2241", "2170", "1175", "5702", "2061", "2059", "5925", "210", "2076", "2104", "2164", "2062", "3131", "3370", "2064", "5924", "2075", "2095", "2067", "6747", "2066", "1665", "2069", "2060", "3714", "4150", "3797", "813", "270", "3484", "3105", "3144", "1229", "478", "2194", "2074", "4331", "2397", "5936", "231", "2084", "2123", "2086", "3316", "2157", "2162", "2131", "287", "2089", "2068", "2096", "193", "3967", "2195", "1685", "1136", "5691", "7453", "502", "4317"]
}, {
    "City": "Narrandera",
    "DBTZ": "O",
    "Name": "Narrandera Airport",
    "Country": "Australia",
    "IATA/FAA": "NRA",
    "Longitude": "146.512",
    "ICAO": "YNAR",
    "Airport ID": "6307",
    "Latitude": "-34.7022",
    "Timezone": "474",
    "DST": "10",
    "destinations": ["6271", "3361"]
}, {
    "City": "Moranbah",
    "DBTZ": "O",
    "Name": "Moranbah Airport",
    "Country": "Australia",
    "IATA/FAA": "MOV",
    "Longitude": "148.077",
    "ICAO": "YMRB",
    "Airport ID": "6301",
    "Latitude": "-22.0578",
    "Timezone": "770",
    "DST": "10",
    "destinations": ["3320", "3330"]
}, {
    "City": "Hafr Al-batin",
    "DBTZ": "U",
    "Name": "Qaisumah",
    "Country": "Saudi Arabia",
    "IATA/FAA": "AQI",
    "Longitude": "46.125069",
    "ICAO": "OEPA",
    "Airport ID": "2076",
    "Latitude": "28.335192",
    "Timezone": "1174",
    "DST": "3",
    "destinations": ["2072", "2082"]
}, {
    "City": "Nejran",
    "DBTZ": "U",
    "Name": "Nejran",
    "Country": "Saudi Arabia",
    "IATA/FAA": "EAM",
    "Longitude": "44.419169",
    "ICAO": "OENG",
    "Airport ID": "2075",
    "Latitude": "17.611436",
    "Timezone": "3982",
    "DST": "3",
    "destinations": ["2064", "2072", "2082"]
}, {
    "City": "Madinah",
    "DBTZ": "U",
    "Name": "Prince Mohammad Bin Abdulaziz",
    "Country": "Saudi Arabia",
    "IATA/FAA": "MED",
    "Longitude": "39.705061",
    "ICAO": "OEMA",
    "Airport ID": "2074",
    "Latitude": "24.553422",
    "Timezone": "2151",
    "DST": "3",
    "destinations": ["2188", "2179", "2191", "2057", "1128", "3410", "2214", "2223", "2206", "2207", "2241", "2170", "2059", "2064", "2067", "1682", "1701", "2072", "2082", "2089", "8381", "287", "2194", "2066", "7453", "1175", "2176", "4317"]
}, {
    "City": "Redding",
    "DBTZ": "A",
    "Name": "Redding Muni",
    "Country": "United States",
    "IATA/FAA": "RDD",
    "Longitude": "-122.293389",
    "ICAO": "KRDD",
    "Airport ID": "4098",
    "Latitude": "40.509",
    "Timezone": "504",
    "DST": "-8",
    "destinations": ["3469"]
}, {
    "City": "Clermont-Ferrand",
    "DBTZ": "E",
    "Name": "Auvergne",
    "Country": "France",
    "IATA/FAA": "CFE",
    "Longitude": "3.169169",
    "ICAO": "LFLC",
    "Airport ID": "1330",
    "Latitude": "45.786661",
    "Timezone": "1090",
    "DST": "1",
    "destinations": ["580", "1335", "1354", "1382", "1386", "1636"]
}, {
    "City": "Xiahe city",
    "DBTZ": "N",
    "Name": "Gannan",
    "Country": "China",
    "IATA/FAA": "GXH",
    "Longitude": "102.3719",
    "ICAO": "ZLXH",
    "Airport ID": "9311",
    "Latitude": "34.4909",
    "Timezone": "10466",
    "DST": "8",
    "destinations": ["4097", "3379"]
}, {
    "City": "Daocheng",
    "DBTZ": "N",
    "Name": "Yading Daocheng",
    "Country": "China",
    "IATA/FAA": "DCY",
    "Longitude": "100.0533",
    "ICAO": "ZUDC",
    "Airport ID": "9310",
    "Latitude": "29.3231",
    "Timezone": "14472",
    "DST": "8",
    "destinations": ["3395"]
}, {
    "City": "Bella Bella",
    "DBTZ": "A",
    "Name": "Bella Bella Airport",
    "Country": "Canada",
    "IATA/FAA": "ZEL",
    "Longitude": "-128.063611",
    "ICAO": "CYJQ",
    "Airport ID": "7275",
    "Latitude": "52.139722",
    "Timezone": "162",
    "DST": "-8",
    "destinations": ["30", "200"]
}, {
    "City": "Dallas",
    "DBTZ": "A",
    "Name": "Dallas Love Fld",
    "Country": "United States",
    "IATA/FAA": "DAL",
    "Longitude": "-96.851778",
    "ICAO": "KDAL",
    "Airport ID": "3502",
    "Latitude": "32.847111",
    "Timezone": "487",
    "DST": "-6",
    "destinations": ["3682", "3840", "3673", "3811", "3566", "3829", "3660", "3652", "3458", "3861", "3863", "3621", "3577", "3469", "3536", "3678", "3696", "3550", "4019", "6962", "3559", "3560", "3457", "3855"]
}, {
    "City": "Helena",
    "DBTZ": "A",
    "Name": "Helena Rgnl",
    "Country": "United States",
    "IATA/FAA": "HLN",
    "Longitude": "-111.98275",
    "ICAO": "KHLN",
    "Airport ID": "3504",
    "Latitude": "46.606806",
    "Timezone": "3877",
    "DST": "-7",
    "destinations": ["3880", "3577", "3858", "3536", "3751"]
}, {
    "City": "Cuenca",
    "DBTZ": "U",
    "Name": "Mariscal Lamar",
    "Country": "Ecuador",
    "IATA/FAA": "CUE",
    "Longitude": "-78.984397",
    "ICAO": "SECU",
    "Airport ID": "2671",
    "Latitude": "-2.889467",
    "Timezone": "8306",
    "DST": "-5",
    "destinations": ["2673", "2688"]
}, {
    "City": "Coca",
    "DBTZ": "U",
    "Name": "Francisco De Orellana",
    "Country": "Ecuador",
    "IATA/FAA": "OCC",
    "Longitude": "-76.986842",
    "ICAO": "SECO",
    "Airport ID": "2670",
    "Latitude": "-0.462886",
    "Timezone": "834",
    "DST": "-5",
    "destinations": ["2688", "7695"]
}, {
    "City": "Guayaquil",
    "DBTZ": "U",
    "Name": "Jose Joaquin De Olmedo Intl",
    "Country": "Ecuador",
    "IATA/FAA": "GYE",
    "Longitude": "-79.883558",
    "ICAO": "SEGU",
    "Airport ID": "2673",
    "Latitude": "-2.157419",
    "Timezone": "19",
    "DST": "-5",
    "destinations": ["2709", "2715", "6045", "2688", "3797", "3576", "580", "2789", "1892", "1871", "2671", "6047", "2672", "6046", "7695", "1229", "2851", "3988", "2650"]
}, {
    "City": "Galapagos",
    "DBTZ": "U",
    "Name": "Seymour",
    "Country": "Ecuador",
    "IATA/FAA": "GPS",
    "Longitude": "-90.265914",
    "ICAO": "SEGS",
    "Airport ID": "2672",
    "Latitude": "-0.453758",
    "Timezone": "207",
    "DST": "-6",
    "destinations": ["2673"]
}, {
    "City": "Iron Mountain",
    "DBTZ": "A",
    "Name": "Ford Airport",
    "Country": "United States",
    "IATA/FAA": "IMT",
    "Longitude": "-88.1145556",
    "ICAO": "KIMT",
    "Airport ID": "6837",
    "Latitude": "45.8183611",
    "Timezone": "1182",
    "DST": "-6",
    "destinations": ["3858", "5765"]
}, {
    "City": "Aktyubinsk",
    "DBTZ": "U",
    "Name": "Aktyubinsk",
    "Country": "Kazakhstan",
    "IATA/FAA": "AKX",
    "Longitude": "57.206667",
    "ICAO": "UATT",
    "Airport ID": "2920",
    "Latitude": "50.245833",
    "Timezone": "738",
    "DST": "5",
    "destinations": ["4029", "2908", "4367", "2910"]
}, {
    "City": "Yakutsk",
    "DBTZ": "N",
    "Name": "Yakutsk",
    "Country": "Russia",
    "IATA/FAA": "YKS",
    "Longitude": "129.770672",
    "ICAO": "UEEE",
    "Airport ID": "2923",
    "Latitude": "62.09325",
    "Timezone": "325",
    "DST": "10",
    "destinations": ["2937", "2925", "6092", "9026", "2930", "6093", "6094", "6095", "2926", "3400", "2927", "4374", "2948", "3364", "2938", "2988", "2934", "4029", "4078", "2985"]
}, {
    "City": "Baku",
    "DBTZ": "E",
    "Name": "Heydar Aliyev",
    "Country": "Azerbaijan",
    "IATA/FAA": "GYD",
    "Longitude": "50.046667",
    "ICAO": "UBBB",
    "Airport ID": "2922",
    "Latitude": "40.4675",
    "Timezone": "10",
    "DST": "4",
    "destinations": ["6969", "1382", "1555", "1524", "2954", "507", "3953", "3399", "4367", "2050", "2985", "2188", "2983", "2958", "4330", "4029", "2241", "1682", "340", "1701", "2939", "6089", "2948", "2962", "4078", "3364", "1587", "4317", "2975", "3973", "2162", "1590", "1613", "2908", "2910", "2976", "1489", "4374", "2992"]
}, {
    "City": "Mirnyj",
    "DBTZ": "N",
    "Name": "Mirny",
    "Country": "Russia",
    "IATA/FAA": "MJZ",
    "Longitude": "114.038928",
    "ICAO": "UERR",
    "Airport ID": "2925",
    "Latitude": "62.534689",
    "Timezone": "1156",
    "DST": "10",
    "destinations": ["4078", "4029", "2937", "4374", "6092", "2923"]
}, {
    "City": "Khabarovsk",
    "DBTZ": "N",
    "Name": "Novy",
    "Country": "Russia",
    "IATA/FAA": "KHV",
    "Longitude": "135.188361",
    "ICAO": "UHHH",
    "Airport ID": "2927",
    "Latitude": "48.528044",
    "Timezone": "244",
    "DST": "11",
    "destinations": ["3077", "3364", "3400", "2933", "2930", "2935", "6411", "2938", "2279", "3930", "2926", "6098", "7479", "2923", "4078", "2932", "2937", "4374", "2985", "2934", "4029"]
}, {
    "City": "Blagoveschensk",
    "DBTZ": "N",
    "Name": "Ignatyevo",
    "Country": "Russia",
    "IATA/FAA": "BQS",
    "Longitude": "127.412478",
    "ICAO": "UHBB",
    "Airport ID": "2926",
    "Latitude": "50.425394",
    "Timezone": "638",
    "DST": "10",
    "destinations": ["2935", "2934", "2927", "2923", "4029"]
}, {
    "City": "Grand Rapids",
    "DBTZ": "A",
    "Name": "Gerald R Ford Intl",
    "Country": "United States",
    "IATA/FAA": "GRR",
    "Longitude": "-85.522806",
    "ICAO": "KGRR",
    "Airport ID": "3685",
    "Latitude": "42.880833",
    "Timezone": "794",
    "DST": "-5",
    "destinations": ["3670", "3830", "3682", "3488", "3645", "3697", "3878", "3858", "3678", "6505", "3877", "3617", "4167", "3486", "3751", "3494", "3714", "3550", "3849", "3646"]
}, {
    "City": "Kingstown",
    "DBTZ": "U",
    "Name": "E T Joshua",
    "Country": "Saint Vincent and the Grenadines",
    "IATA/FAA": "SVD",
    "Longitude": "-61.210861",
    "ICAO": "TVSV",
    "Airport ID": "2907",
    "Latitude": "13.144306",
    "Timezone": "66",
    "DST": "-4",
    "destinations": ["2875", "2882", "2902", "2893"]
}, {
    "City": "Takaroa",
    "DBTZ": "U",
    "Name": "Takaroa",
    "Country": "French Polynesia",
    "IATA/FAA": "TKX",
    "Longitude": "-145.024542",
    "ICAO": "NTKR",
    "Airport ID": "1986",
    "Latitude": "-14.455781",
    "Timezone": "13",
    "DST": "-10",
    "destinations": ["5889", "4075", "1983"]
}, {
    "City": "Nuku Hiva",
    "DBTZ": "U",
    "Name": "Nuku Hiva",
    "Country": "French Polynesia",
    "IATA/FAA": "NHV",
    "Longitude": "-140.228789",
    "ICAO": "NTMD",
    "Airport ID": "1987",
    "Latitude": "-8.795603",
    "Timezone": "220",
    "DST": "-9.5",
    "destinations": ["5890", "4075", "5892", "5891"]
}, {
    "City": "Arutua",
    "DBTZ": "U",
    "Name": "Arutua",
    "Country": "French Polynesia",
    "IATA/FAA": "AXR",
    "Longitude": "-146.616708",
    "ICAO": "NTGU",
    "Airport ID": "1984",
    "Latitude": "-15.248289",
    "Timezone": "9",
    "DST": "-10",
    "destinations": ["1980"]
}, {
    "City": "Mataiva",
    "DBTZ": "U",
    "Name": "Mataiva",
    "Country": "French Polynesia",
    "IATA/FAA": "MVT",
    "Longitude": "-148.717225",
    "ICAO": "NTGV",
    "Airport ID": "1985",
    "Latitude": "-14.868055",
    "Timezone": "11",
    "DST": "-10",
    "destinations": ["4075", "1990"]
}, {
    "City": "Pico",
    "DBTZ": "E",
    "Name": "Pico",
    "Country": "Portugal",
    "IATA/FAA": "PIX",
    "Longitude": "-28.441333",
    "ICAO": "LPPI",
    "Airport ID": "1634",
    "Latitude": "38.554333",
    "Timezone": "109",
    "DST": "-1",
    "destinations": ["1629", "1633"]
}, {
    "City": "Takapoto",
    "DBTZ": "U",
    "Name": "Takapoto",
    "Country": "French Polynesia",
    "IATA/FAA": "TKP",
    "Longitude": "-145.245814",
    "ICAO": "NTGT",
    "Airport ID": "1983",
    "Latitude": "-14.709544",
    "Timezone": "12",
    "DST": "-10",
    "destinations": ["1986"]
}, {
    "City": "Porto",
    "DBTZ": "E",
    "Name": "Porto",
    "Country": "Portugal",
    "IATA/FAA": "OPO",
    "Longitude": "-8.681389",
    "ICAO": "LPPR",
    "Airport ID": "1636",
    "Latitude": "41.248055",
    "Timezone": "228",
    "DST": "0",
    "destinations": ["1678", "1555", "1524", "507", "469", "951", "580", "1218", "1525", "1538", "1264", "353", "302", "1367", "1352", "1330", "1553", "304", "1309", "373", "599", "1350", "585", "1626", "4166", "3986", "355", "1399", "1638", "1054", "491", "6493", "1229", "1353", "582", "4198", "3998", "548", "1435", "1056", "1376", "1246", "1374", "1386", "629", "340", "1665", "4091", "346", "1633", "1629", "193", "1335", "1418", "2851", "3494", "2560", "2564", "502", "4053", "1382"]
}, {
    "City": "Makemo",
    "DBTZ": "U",
    "Name": "Makemo",
    "Country": "French Polynesia",
    "IATA/FAA": "MKP",
    "Longitude": "-143.658369",
    "ICAO": "NTGM",
    "Airport ID": "1981",
    "Latitude": "-16.583919",
    "Timezone": "3",
    "DST": "-10",
    "destinations": ["4075"]
}, {
    "City": "Lisbon",
    "DBTZ": "E",
    "Name": "Lisboa",
    "Country": "Portugal",
    "IATA/FAA": "LIS",
    "Longitude": "-9.135919",
    "ICAO": "LPPT",
    "Airport ID": "1638",
    "Latitude": "38.781311",
    "Timezone": "374",
    "DST": "0",
    "destinations": ["344", "350", "1735", "507", "3752", "1382", "210", "1074", "580", "302", "1665", "421", "1678", "1538", "1555", "1524", "1551", "951", "609", "644", "599", "596", "2188", "340", "1230", "1637", "1367", "1309", "1626", "355", "478", "1353", "1636", "1562", "548", "585", "1218", "1229", "1386", "629", "342", "346", "679", "1054", "1613", "2939", "737", "1216", "3448", "4091", "1628", "502", "1633", "1634", "1617", "1253", "1629", "1246", "193", "4029", "1701", "1418", "248", "1044", "1264", "2531", "1489", "1103", "2851", "2537", "1084", "345", "3494", "2559", "2560", "2564", "1220", "1335", "3576", "979", "2597", "1354", "1657", "2599", "1587", "5674", "1075", "2610", "1102", "2621", "337", "1273", "973", "1080", "2578", "1106", "146", "287", "490", "4053", "535", "491", "492"]
}, {
    "City": "Sao Jorge Island",
    "DBTZ": "E",
    "Name": "Sao Jorge",
    "Country": "Portugal",
    "IATA/FAA": "SJZ",
    "Longitude": "-28.175817",
    "ICAO": "LPSJ",
    "Airport ID": "1639",
    "Latitude": "38.6655",
    "Timezone": "311",
    "DST": "-1",
    "destinations": ["1633", "1629"]
}, {
    "City": "Bora Bora",
    "DBTZ": "U",
    "Name": "Bora Bora",
    "Country": "French Polynesia",
    "IATA/FAA": "BOB",
    "Longitude": "-151.751286",
    "ICAO": "NTTB",
    "Airport ID": "1989",
    "Latitude": "-16.444378",
    "Timezone": "10",
    "DST": "-10",
    "destinations": ["1991", "1994", "1992", "4075", "1995", "1990", "1975"]
}, {
    "City": "Baishan",
    "DBTZ": "N",
    "Name": "Changbaishan Airport",
    "Country": "China",
    "IATA/FAA": "NBS",
    "Longitude": "127.548889",
    "ICAO": "",
    "Airport ID": "8826",
    "Latitude": "42.088056",
    "Timezone": "2762",
    "DST": "8",
    "destinations": ["4144", "4380", "3364", "3368"]
}, {
    "City": "Montevideo",
    "DBTZ": "S",
    "Name": "Carrasco Intl",
    "Country": "Uruguay",
    "IATA/FAA": "MVD",
    "Longitude": "-56.030806",
    "ICAO": "SUMU",
    "Airport ID": "2816",
    "Latitude": "-34.838417",
    "Timezone": "105",
    "DST": "-3",
    "destinations": ["2442", "2699", "2545", "3988", "2560", "2564", "2599", "2650", "3576", "2789", "1871", "1229"]
}, {
    "City": "Cuzco",
    "DBTZ": "U",
    "Name": "Teniente Alejandro Velasco Astete Intl",
    "Country": "Peru",
    "IATA/FAA": "CUZ",
    "Longitude": "-71.938781",
    "ICAO": "SPZO",
    "Airport ID": "2812",
    "Latitude": "-13.535722",
    "Timezone": "10860",
    "DST": "-5",
    "destinations": ["2789", "2808", "2792", "2802", "2762"]
}, {
    "City": "Lankaran",
    "DBTZ": "U",
    "Name": "Lankaran International Airport",
    "Country": "Azerbaijan",
    "IATA/FAA": "LLK",
    "Longitude": "48.817778",
    "ICAO": "UBBL",
    "Airport ID": "8774",
    "Latitude": "38.746389",
    "Timezone": "30",
    "DST": "4",
    "destinations": ["4029", "2948"]
}, {
    "City": "Qabala",
    "DBTZ": "E",
    "Name": "Qabala Airport",
    "Country": "Azerbaijan",
    "IATA/FAA": "GBB",
    "Longitude": "47.7125",
    "ICAO": "UBBQ",
    "Airport ID": "8775",
    "Latitude": "40.826667",
    "Timezone": "100",
    "DST": "4",
    "destinations": ["4029"]
}, {
    "City": "Phuket",
    "DBTZ": "U",
    "Name": "Phuket Intl",
    "Country": "Thailand",
    "IATA/FAA": "HKT",
    "Longitude": "98.316872",
    "ICAO": "VTSP",
    "Airport ID": "3179",
    "Latitude": "8.1132",
    "Timezone": "82",
    "DST": "7",
    "destinations": ["3316", "3393", "3395", "2397", "3885", "3930", "3406", "2179", "3304", "3364", "3077", "3370", "3394", "3374", "3157", "2188", "3931", "3183", "3308", "3339", "3361", "3386", "3382", "3177", "3161", "3275", "4029", "2948", "4078", "2975", "2988", "3351"]
}, {
    "City": "Angmagssalik",
    "DBTZ": "U",
    "Name": "Tasiilaq",
    "Country": "Greenland",
    "IATA/FAA": "AGM",
    "Longitude": "-37.618333",
    "ICAO": "BGAM",
    "Airport ID": "7642",
    "Latitude": "65.612222",
    "Timezone": "24",
    "DST": "-3",
    "destinations": ["4248"]
}, {
    "City": "Woodbourne",
    "DBTZ": "Z",
    "Name": "Woodbourne",
    "Country": "New Zealand",
    "IATA/FAA": "BHE",
    "Longitude": "173.870278",
    "ICAO": "NZWB",
    "Airport ID": "2037",
    "Latitude": "-41.518333",
    "Timezone": "109",
    "DST": "12",
    "destinations": ["2006", "2009", "2042"]
}, {
    "City": "Phitsanulok",
    "DBTZ": "U",
    "Name": "Phitsanulok",
    "Country": "Thailand",
    "IATA/FAA": "PHS",
    "Longitude": "100.279122",
    "ICAO": "VTPP",
    "Airport ID": "3170",
    "Latitude": "16.782939",
    "Timezone": "154",
    "DST": "7",
    "destinations": ["3157", "3931"]
}, {
    "City": "Narathiwat",
    "DBTZ": "U",
    "Name": "Narathiwat",
    "Country": "Thailand",
    "IATA/FAA": "NAW",
    "Longitude": "101.7434",
    "ICAO": "VTSC",
    "Airport ID": "3173",
    "Latitude": "6.519922",
    "Timezone": "16",
    "DST": "7",
    "destinations": ["3157"]
}, {
    "City": "Krabi",
    "DBTZ": "U",
    "Name": "Krabi",
    "Country": "Thailand",
    "IATA/FAA": "KBV",
    "Longitude": "98.988764",
    "ICAO": "VTSG",
    "Airport ID": "3174",
    "Latitude": "8.095969",
    "Timezone": "93",
    "DST": "7",
    "destinations": ["3885", "3304", "3157", "3931", "3316", "3177"]
}, {
    "City": "Ko Samui",
    "DBTZ": "U",
    "Name": "Samui",
    "Country": "Thailand",
    "IATA/FAA": "USM",
    "Longitude": "100.062272",
    "ICAO": "VTSM",
    "Airport ID": "3177",
    "Latitude": "9.547794",
    "Timezone": "64",
    "DST": "7",
    "destinations": ["3077", "3308", "3408", "3304", "3316", "3885", "3931", "3179", "3174", "3161"]
}, {
    "City": "Mammoth Lakes",
    "DBTZ": "A",
    "Name": "Mammoth Yosemite Airport",
    "Country": "United States",
    "IATA/FAA": "MMH",
    "Longitude": "-118.837772",
    "ICAO": "KMMH",
    "Airport ID": "7081",
    "Latitude": "37.624049",
    "Timezone": "7128",
    "DST": "-8",
    "destinations": ["3484"]
}, {
    "City": "Eastsound",
    "DBTZ": "A",
    "Name": "Orcas Island Airport",
    "Country": "United States",
    "IATA/FAA": "ESD",
    "Longitude": "-122.910556",
    "ICAO": "KORS",
    "Airport ID": "7083",
    "Latitude": "48.708056",
    "Timezone": "31",
    "DST": "-8",
    "destinations": ["7082"]
}, {
    "City": "Friday Harbor",
    "DBTZ": "A",
    "Name": "Friday Harbor Airport",
    "Country": "United States",
    "IATA/FAA": "FRD",
    "Longitude": "-123.024444",
    "ICAO": "KFHR",
    "Airport ID": "7082",
    "Latitude": "48.521944",
    "Timezone": "113",
    "DST": "-8",
    "destinations": ["3726"]
}, {
    "City": "Mendi",
    "DBTZ": "U",
    "Name": "Mendi Airport",
    "Country": "Papua New Guinea",
    "IATA/FAA": "MDU",
    "Longitude": "143.657",
    "ICAO": "AYMN",
    "Airport ID": "5429",
    "Latitude": "-6.14774",
    "Timezone": "5680",
    "DST": "10",
    "destinations": ["5"]
}, {
    "City": "Kavieng",
    "DBTZ": "U",
    "Name": "Kavieng Airport",
    "Country": "Papua New Guinea",
    "IATA/FAA": "KVG",
    "Longitude": "150.808",
    "ICAO": "AYKV",
    "Airport ID": "5428",
    "Latitude": "-2.5794",
    "Timezone": "7",
    "DST": "10",
    "destinations": ["5435", "5430", "5"]
}, {
    "City": "Emmonak",
    "DBTZ": "A",
    "Name": "Emmonak Airport",
    "Country": "United States",
    "IATA/FAA": "EMK",
    "Longitude": "-164.490833",
    "ICAO": "PAEM",
    "Airport ID": "7087",
    "Latitude": "62.786111",
    "Timezone": "13",
    "DST": "-9",
    "destinations": ["7199", "7207", "3599"]
}, {
    "City": "Zanzibar",
    "DBTZ": "U",
    "Name": "Zanzibar",
    "Country": "Tanzania",
    "IATA/FAA": "ZNZ",
    "Longitude": "39.224886",
    "ICAO": "HTZA",
    "Airport ID": "1186",
    "Latitude": "-6.222025",
    "Timezone": "54",
    "DST": "3",
    "destinations": ["1145", "340", "1107", "1180", "813", "1177", "4059"]
}, {
    "City": "Entebbe",
    "DBTZ": "U",
    "Name": "Entebbe Intl",
    "Country": "Uganda",
    "IATA/FAA": "EBB",
    "Longitude": "32.443503",
    "ICAO": "HUEN",
    "Airport ID": "1187",
    "Latitude": "0.042386",
    "Timezone": "3782",
    "DST": "3",
    "destinations": ["7450", "507", "302", "580", "2188", "1107", "1165", "5709", "4059", "1128", "1177", "1180", "2241", "813", "1701", "1120", "1173", "5687", "3967"]
}, {
    "City": "Zhengzhou",
    "DBTZ": "U",
    "Name": "Xinzheng",
    "Country": "China",
    "IATA/FAA": "CGO",
    "Longitude": "113.840889",
    "ICAO": "ZHCC",
    "Airport ID": "3375",
    "Latitude": "34.519672",
    "Timezone": "495",
    "DST": "8",
    "destinations": ["4380", "3393", "3395", "3386", "3400", "3382", "3399", "4033", "2268", "2276", "2264", "3370", "3404", "4120", "3394", "3372", "6431", "3121", "6400", "3373", "3364", "4144", "3374", "3390", "4108", "3368", "3383", "6366", "3077", "6346", "6351", "3885", "2370", "6434", "3385", "6743", "3930", "4085", "6359", "3406", "3391", "4030", "6368", "6392", "6394", "8876", "6355", "6345", "3389", "3384", "4302", "6407", "3387", "3388", "6386"]
}, {
    "City": "Shenzhen",
    "DBTZ": "U",
    "Name": "Baoan Intl",
    "Country": "China",
    "IATA/FAA": "SZX",
    "Longitude": "113.810664",
    "ICAO": "ZGSZ",
    "Airport ID": "3374",
    "Latitude": "22.639258",
    "Timezone": "13",
    "DST": "8",
    "destinations": ["3393", "3395", "6403", "4033", "3386", "7898", "3391", "4144", "6347", "3269", "3304", "2268", "2276", "3373", "3368", "3885", "3375", "4380", "4109", "6397", "3404", "6398", "4120", "3389", "3400", "6384", "6380", "6386", "6382", "3384", "3382", "3394", "3372", "6400", "3388", "5475", "3364", "3406", "3316", "4030", "3390", "4108", "3369", "6392", "3376", "6390", "6360", "3379", "3383", "4110", "6430", "9107", "6361", "3392", "6357", "2264", "9025", "6352", "2370", "3371", "3940", "6353", "3385", "3179", "6395", "6379", "6399", "6354", "6401", "3387", "6359", "6963", "6387", "6356", "3157", "6351", "6431", "6385", "3399", "4149", "3930", "6341", "6428", "8845", "8876"]
}, {
    "City": "Mtwara",
    "DBTZ": "U",
    "Name": "Mtwara",
    "Country": "Tanzania",
    "IATA/FAA": "MYW",
    "Longitude": "40.181781",
    "ICAO": "HTMT",
    "Airport ID": "1182",
    "Latitude": "-10.339058",
    "Timezone": "371",
    "DST": "3",
    "destinations": ["1177"]
}, {
    "City": "Mwanza",
    "DBTZ": "U",
    "Name": "Mwanza",
    "Country": "Tanzania",
    "IATA/FAA": "MWZ",
    "Longitude": "32.932667",
    "ICAO": "HTMW",
    "Airport ID": "1183",
    "Latitude": "-2.444486",
    "Timezone": "3763",
    "DST": "3",
    "destinations": ["1180", "4059", "5703", "1177"]
}, {
    "City": "Kilimanjaro",
    "DBTZ": "U",
    "Name": "Kilimanjaro Intl",
    "Country": "Tanzania",
    "IATA/FAA": "JRO",
    "Longitude": "37.074461",
    "ICAO": "HTKJ",
    "Airport ID": "1180",
    "Latitude": "-3.429406",
    "Timezone": "2932",
    "DST": "3",
    "destinations": ["1177", "1107", "1186", "1147", "1183", "4059", "1187", "1145", "1165"]
}, {
    "City": "Guangzhou",
    "DBTZ": "U",
    "Name": "Baiyun Intl",
    "Country": "China",
    "IATA/FAA": "CAN",
    "Longitude": "113.298786",
    "ICAO": "ZGGG",
    "Airport ID": "3370",
    "Latitude": "23.392436",
    "Timezone": "50",
    "DST": "8",
    "destinations": ["3393", "3395", "2244", "2397", "3239", "3391", "6347", "1382", "3386", "4144", "3316", "3376", "3269", "3304", "2268", "3368", "2264", "2276", "1107", "6346", "1128", "3375", "4380", "4109", "6397", "6398", "4120", "6345", "3389", "3400", "6384", "1701", "6380", "6386", "4301", "3384", "3382", "3394", "6385", "6399", "3388", "3373", "2279", "5475", "3364", "3406", "3390", "4108", "6392", "6391", "6390", "6403", "3379", "3383", "4110", "6430", "3392", "3077", "7506", "2006", "580", "6427", "9025", "6351", "3885", "3320", "6352", "3275", "6343", "3931", "3371", "3076", "3196", "3093", "6375", "3404", "3940", "2188", "6353", "3385", "7503", "2305", "3199", "8407", "7519", "3179", "7508", "3930", "6428", "8781", "6381", "6944", "3992", "6379", "3125", "3372", "3484", "507", "6431", "4148", "6383", "6356", "3339", "6400", "3156", "6354", "6401", "4059", "3387", "6359", "3308", "3351", "3034", "3035", "3205", "2985", "4302", "3361", "4030", "6963", "6387", "3369", "3399", "6360", "4149", "6361", "6394", "6393", "8876", "156", "6357", "3157", "8082", "4145", "8858", "6388", "4033", "6341", "7898", "6376", "2372", "2241", "3120", "2072", "2082", "4330"]
}, {
    "City": "Skelleftea",
    "DBTZ": "E",
    "Name": "Skelleftea",
    "Country": "Sweden",
    "IATA/FAA": "SFT",
    "Longitude": "21.076892",
    "ICAO": "ESNS",
    "Airport ID": "726",
    "Latitude": "64.624772",
    "Timezone": "157",
    "DST": "1",
    "destinations": ["1222", "548", "737"]
}, {
    "City": "Gustavia",
    "DBTZ": "E",
    "Name": "Saint Barthelemy",
    "Country": "France",
    "IATA/FAA": "SBH",
    "Longitude": "-62.8324",
    "ICAO": "TFFJ",
    "Airport ID": "6460",
    "Latitude": "17.9023",
    "Timezone": "50",
    "DST": "-4",
    "destinations": ["2881", "2874", "2879", "2899", "2890", "2883", "4249"]
}, {
    "City": "Kiruna",
    "DBTZ": "E",
    "Name": "Kiruna",
    "Country": "Sweden",
    "IATA/FAA": "KRN",
    "Longitude": "20.336764",
    "ICAO": "ESNQ",
    "Airport ID": "724",
    "Latitude": "67.821986",
    "Timezone": "1508",
    "DST": "1",
    "destinations": ["737", "715"]
}, {
    "City": "Ornskoldsvik",
    "DBTZ": "E",
    "Name": "Ornskoldsvik",
    "Country": "Sweden",
    "IATA/FAA": "OER",
    "Longitude": "18.990039",
    "ICAO": "ESNO",
    "Airport ID": "722",
    "Latitude": "63.408339",
    "Timezone": "354",
    "DST": "1",
    "destinations": ["737"]
}, {
    "City": "Sundsvall",
    "DBTZ": "E",
    "Name": "Sundsvall Harnosand",
    "Country": "Sweden",
    "IATA/FAA": "SDL",
    "Longitude": "17.443928",
    "ICAO": "ESNN",
    "Airport ID": "721",
    "Latitude": "62.528125",
    "Timezone": "16",
    "DST": "1",
    "destinations": ["738", "687", "734", "737"]
}, {
    "City": "Bratislava",
    "DBTZ": "E",
    "Name": "M R Stefanik",
    "Country": "Slovakia",
    "IATA/FAA": "BTS",
    "Longitude": "17.212667",
    "ICAO": "LZIB",
    "Airport ID": "1745",
    "Latitude": "48.170167",
    "Timezone": "436",
    "DST": "1",
    "destinations": ["1587", "644", "1230", "1517", "1525", "469", "1367", "1553", "304", "599", "535", "1222", "491", "492", "3998", "548", "1515", "1746"]
}, {
    "City": "Kosice",
    "DBTZ": "E",
    "Name": "Kosice",
    "Country": "Slovakia",
    "IATA/FAA": "KSC",
    "Longitude": "21.241112",
    "ICAO": "LZKZ",
    "Airport ID": "1746",
    "Latitude": "48.663055",
    "Timezone": "755",
    "DST": "1",
    "destinations": ["1745", "1587", "1613", "492"]
}, {
    "City": "Podgorica",
    "DBTZ": "E",
    "Name": "Podgorica",
    "Country": "Montenegro",
    "IATA/FAA": "TGD",
    "Longitude": "19.251894",
    "ICAO": "LYPG",
    "Airport ID": "1741",
    "Latitude": "42.359392",
    "Timezone": "141",
    "DST": "1",
    "destinations": ["1555", "304", "548", "1569", "1739", "1613", "1701", "1382", "4029", "345", "340", "1678"]
}, {
    "City": "Umea",
    "DBTZ": "E",
    "Name": "Umea",
    "Country": "Sweden",
    "IATA/FAA": "UME",
    "Longitude": "20.282758",
    "ICAO": "ESNU",
    "Airport ID": "728",
    "Latitude": "63.791828",
    "Timezone": "24",
    "DST": "1",
    "destinations": ["738", "1212", "737", "734", "5593", "351"]
}, {
    "City": "Vilhelmina",
    "DBTZ": "E",
    "Name": "Vilhelmina",
    "Country": "Sweden",
    "IATA/FAA": "VHM",
    "Longitude": "16.833575",
    "ICAO": "ESNV",
    "Airport ID": "729",
    "Latitude": "64.579083",
    "Timezone": "1140",
    "DST": "1",
    "destinations": ["737", "5599"]
}, {
    "City": "Roche Harbor",
    "DBTZ": "A",
    "Name": "Roche Harbor Seaplane Base",
    "Country": "United States",
    "IATA/FAA": "RCE",
    "Longitude": "-123.159722",
    "ICAO": "",
    "Airport ID": "8170",
    "Latitude": "48.608056",
    "Timezone": "0",
    "DST": "-8",
    "destinations": ["8174"]
}, {
    "City": "Kigali",
    "DBTZ": "U",
    "Name": "Kigali Intl",
    "Country": "Rwanda",
    "IATA/FAA": "KGL",
    "Longitude": "30.13945",
    "ICAO": "HRYR",
    "Airport ID": "1165",
    "Latitude": "-1.968628",
    "Timezone": "4859",
    "DST": "2",
    "destinations": ["1187", "4059", "1120", "1107", "813", "302", "883", "1177", "2188", "1701", "1180", "1173", "1166", "970", "273", "1145"]
}, {
    "City": "Kamembe",
    "DBTZ": "U",
    "Name": "Kamembe",
    "Country": "Rwanda",
    "IATA/FAA": "KME",
    "Longitude": "28.90795",
    "ICAO": "HRZA",
    "Airport ID": "1166",
    "Latitude": "-2.462242",
    "Timezone": "5192",
    "DST": "2",
    "destinations": ["1165"]
}, {
    "City": "Westsound",
    "DBTZ": "A",
    "Name": "Westsound Seaplane Base",
    "Country": "United States",
    "IATA/FAA": "WSX",
    "Longitude": "-122.952778",
    "ICAO": "",
    "Airport ID": "8173",
    "Latitude": "48.617778",
    "Timezone": "0",
    "DST": "-8",
    "destinations": ["6136"]
}, {
    "City": "Friday Harbor",
    "DBTZ": "A",
    "Name": "Friday Harbor Seaplane Base",
    "Country": "United States",
    "IATA/FAA": "FBS",
    "Longitude": "-123.009722",
    "ICAO": "",
    "Airport ID": "8174",
    "Latitude": "48.537222",
    "Timezone": "0",
    "DST": "-8",
    "destinations": ["6457", "8170"]
}, {
    "City": "La Rochelle",
    "DBTZ": "E",
    "Name": "La Rochelle-Ile de Re",
    "Country": "France",
    "IATA/FAA": "LRH",
    "Longitude": "-1.19528",
    "ICAO": "LFBH",
    "Airport ID": "6493",
    "Latitude": "46.1792",
    "Timezone": "74",
    "DST": "1",
    "destinations": ["1386", "1268", "495", "304", "599", "523", "1636", "548"]
}, {
    "City": "Setif",
    "DBTZ": "U",
    "Name": "Ain Arnat Airport",
    "Country": "Algeria",
    "IATA/FAA": "QSF",
    "Longitude": "5.32449",
    "ICAO": "DAAS",
    "Airport ID": "6492",
    "Latitude": "36.1781",
    "Timezone": "3360",
    "DST": "1",
    "destinations": ["210", "1335", "1353", "1386", "1423"]
}, {
    "City": "Santa Cruz",
    "DBTZ": "U",
    "Name": "Santa Cruz",
    "Country": "Bolivia",
    "IATA/FAA": "SRZ",
    "Longitude": "-63.166667",
    "ICAO": "",
    "Airport ID": "4247",
    "Latitude": "-17.8",
    "Timezone": "1224",
    "DST": "-4",
    "destinations": ["2772", "2762", "2769"]
}, {
    "City": "Paulatuk",
    "DBTZ": "A",
    "Name": "Paulatuk",
    "Country": "Canada",
    "IATA/FAA": "YPC",
    "Longitude": "-124.3333",
    "ICAO": "CYPC",
    "Airport ID": "4244",
    "Latitude": "62.35",
    "Timezone": "50",
    "DST": "-7",
    "destinations": ["54"]
}, {
    "City": "Tanna",
    "DBTZ": "U",
    "Name": "Tanna island",
    "Country": "Vanuatu",
    "IATA/FAA": "TAH",
    "Longitude": "169.22394",
    "ICAO": "",
    "Airport ID": "4242",
    "Latitude": "-19.455198",
    "Timezone": "3",
    "DST": "11",
    "destinations": ["5914", "5915", "5917", "5918", "1997"]
}, {
    "City": "El Fasher",
    "DBTZ": "U",
    "Name": "El Fashir",
    "Country": "Sudan",
    "IATA/FAA": "ELF",
    "Longitude": "25.32465",
    "ICAO": "HSFS",
    "Airport ID": "1169",
    "Latitude": "13.614892",
    "Timezone": "2393",
    "DST": "3",
    "destinations": ["1175"]
}, {
    "City": "Inukjuak",
    "DBTZ": "A",
    "Name": "Inukjuak Airport",
    "Country": "Canada",
    "IATA/FAA": "YPH",
    "Longitude": "-78.0769",
    "ICAO": "CYPH",
    "Airport ID": "5520",
    "Latitude": "58.4719",
    "Timezone": "83",
    "DST": "-5",
    "destinations": ["6727", "5515", "154"]
}, {
    "City": "Pikangikum",
    "DBTZ": "A",
    "Name": "Pikangikum Airport",
    "Country": "Canada",
    "IATA/FAA": "YPM",
    "Longitude": "-93.9733",
    "ICAO": "CYPM",
    "Airport ID": "5521",
    "Latitude": "51.8197",
    "Timezone": "1114",
    "DST": "-6",
    "destinations": ["5471", "5467", "5527", "5532", "169"]
}, {
    "City": "Peawanuck",
    "DBTZ": "A",
    "Name": "Peawanuck Airport",
    "Country": "Canada",
    "IATA/FAA": "YPO",
    "Longitude": "-85.4433",
    "ICAO": "CYPO",
    "Airport ID": "5522",
    "Latitude": "54.9881",
    "Timezone": "173",
    "DST": "-5",
    "destinations": ["5482"]
}, {
    "City": "Powell River",
    "DBTZ": "A",
    "Name": "Powell River Airport",
    "Country": "Canada",
    "IATA/FAA": "YPW",
    "Longitude": "-124.5",
    "ICAO": "CYPW",
    "Airport ID": "5523",
    "Latitude": "49.8342",
    "Timezone": "425",
    "DST": "-8",
    "destinations": ["156"]
}, {
    "City": "The Pas",
    "DBTZ": "A",
    "Name": "The Pas Airport",
    "Country": "Canada",
    "IATA/FAA": "YQD",
    "Longitude": "-101.091",
    "ICAO": "CYQD",
    "Airport ID": "5524",
    "Latitude": "53.9714",
    "Timezone": "887",
    "DST": "-6",
    "destinations": ["58", "160", "141"]
}, {
    "City": "Gameti",
    "DBTZ": "A",
    "Name": "Rae Lakes Airport",
    "Country": "Canada",
    "IATA/FAA": "YRA",
    "Longitude": "-117.31",
    "ICAO": "CYRA",
    "Airport ID": "5526",
    "Latitude": "64.1161",
    "Timezone": "723",
    "DST": "-7",
    "destinations": ["196"]
}, {
    "City": "Red Lake",
    "DBTZ": "A",
    "Name": "Red Lake Airport",
    "Country": "Canada",
    "IATA/FAA": "YRL",
    "Longitude": "-93.7931",
    "ICAO": "CYRL",
    "Airport ID": "5527",
    "Latitude": "51.0669",
    "Timezone": "1265",
    "DST": "-6",
    "destinations": ["67", "160", "169", "5521", "5532", "5548"]
}, {
    "City": "Mohe County",
    "DBTZ": "U",
    "Name": "Heilongjiang Mohe Airport",
    "Country": "China",
    "IATA/FAA": "OHE",
    "Longitude": "122.427",
    "ICAO": "",
    "Airport ID": "6943",
    "Latitude": "52.915",
    "Timezone": "1500",
    "DST": "8",
    "destinations": ["6410", "8979", "3400"]
}, {
    "City": "Sanikiluaq",
    "DBTZ": "A",
    "Name": "Sanikiluaq Airport",
    "Country": "Canada",
    "IATA/FAA": "YSK",
    "Longitude": "-79.2467",
    "ICAO": "CYSK",
    "Airport ID": "5529",
    "Latitude": "56.5378",
    "Timezone": "104",
    "DST": "-5",
    "destinations": ["5496", "5515", "160"]
}, {
    "City": "Yaroslavl",
    "DBTZ": "N",
    "Name": "Tunoshna",
    "Country": "Russia",
    "IATA/FAA": "IAR",
    "Longitude": "40.157369454444",
    "ICAO": "UUDL",
    "Airport ID": "6940",
    "Latitude": "57.560666676667",
    "Timezone": "270",
    "DST": "4",
    "destinations": ["2948"]
}, {
    "City": "Altay",
    "DBTZ": "U",
    "Name": "Altay Airport",
    "Country": "China",
    "IATA/FAA": "AAT",
    "Longitude": "88.116667",
    "ICAO": "ZWAT",
    "Airport ID": "6946",
    "Latitude": "47.866667",
    "Timezone": "2491",
    "DST": "8",
    "destinations": ["3399"]
}, {
    "City": "Puntarenas",
    "DBTZ": "N",
    "Name": "Drake Bay Airport",
    "Country": "Costa Rica",
    "IATA/FAA": "DRK",
    "Longitude": "-83.6417",
    "ICAO": "MRDK",
    "Airport ID": "6945",
    "Latitude": "8.71889",
    "Timezone": "12",
    "DST": "-6",
    "destinations": ["1885"]
}, {
    "City": "Jining",
    "DBTZ": "U",
    "Name": "Jining Airport ",
    "Country": "China",
    "IATA/FAA": "JNG",
    "Longitude": "116.533",
    "ICAO": "",
    "Airport ID": "6944",
    "Latitude": "35.417",
    "Timezone": "1540",
    "DST": "8",
    "destinations": ["3370", "3395", "4144", "3391", "3404", "3379", "3400", "3382", "3364"]
}, {
    "City": "Pulau",
    "DBTZ": "N",
    "Name": "Langkawi Intl",
    "Country": "Malaysia",
    "IATA/FAA": "LGK",
    "Longitude": "99.728667",
    "ICAO": "WMKL",
    "Airport ID": "3305",
    "Latitude": "6.329728",
    "Timezone": "29",
    "DST": "8",
    "destinations": ["3304", "3263", "3308", "3316", "3408"]
}, {
    "City": "Thanh Hoa",
    "DBTZ": "N",
    "Name": "Tho Xuan Airport",
    "Country": "Vietnam",
    "IATA/FAA": "THD",
    "Longitude": "105.46778",
    "ICAO": "",
    "Airport ID": "9135",
    "Latitude": "19.90167",
    "Timezone": "0",
    "DST": "7",
    "destinations": ["3205"]
}, {
    "City": "Dawson Creek",
    "DBTZ": "A",
    "Name": "Dawson Creek",
    "Country": "Canada",
    "IATA/FAA": "YDQ",
    "Longitude": "-120.183",
    "ICAO": "CYDQ",
    "Airport ID": "48",
    "Latitude": "55.742333",
    "Timezone": "2148",
    "DST": "-7",
    "destinations": ["156", "180"]
}, {
    "City": "Beauvais",
    "DBTZ": "E",
    "Name": "Tille",
    "Country": "France",
    "IATA/FAA": "BVA",
    "Longitude": "2.112778",
    "ICAO": "LFOB",
    "Airport ID": "1367",
    "Latitude": "49.454444",
    "Timezone": "359",
    "DST": "1",
    "destinations": ["1735", "1055", "1230", "1517", "1212", "1218", "1506", "1525", "1538", "1501", "1745", "1489", "1360", "1519", "1553", "599", "1626", "1066", "1222", "691", "669", "1638", "1229", "478", "5673", "699", "1636", "1070", "3998", "1562", "1505", "1203", "1075", "1072", "657", "603", "1253", "1056", "1080", "1515", "1545", "1539", "1246", "3959", "8414", "1209", "1252", "675", "1739", "1652", "668", "671", "1657", "674", "1194", "1662", "1663", "679", "680"]
}, {
    "City": "Fort Albany",
    "DBTZ": "A",
    "Name": "Fort Albany Airport",
    "Country": "Canada",
    "IATA/FAA": "YFA",
    "Longitude": "-81.6969",
    "ICAO": "CYFA",
    "Airport ID": "5490",
    "Latitude": "52.2014",
    "Timezone": "48",
    "DST": "-5",
    "destinations": ["91", "5543"]
}, {
    "City": "Deer Lake",
    "DBTZ": "A",
    "Name": "Deer Lake",
    "Country": "Canada",
    "IATA/FAA": "YDF",
    "Longitude": "-57.391388",
    "ICAO": "CYDF",
    "Airport ID": "45",
    "Latitude": "49.210833",
    "Timezone": "72",
    "DST": "-3.5",
    "destinations": ["73", "188", "189", "193"]
}, {
    "City": "Clyde River",
    "DBTZ": "A",
    "Name": "Clyde River",
    "Country": "Canada",
    "IATA/FAA": "YCY",
    "Longitude": "-68.516667",
    "ICAO": "CYCY",
    "Airport ID": "40",
    "Latitude": "70.486111",
    "Timezone": "87",
    "DST": "-5",
    "destinations": ["55", "75"]
}, {
    "City": "Coral Harbour",
    "DBTZ": "A",
    "Name": "Fairmont Hot Springs",
    "Country": "Canada",
    "IATA/FAA": "YZS",
    "Longitude": "-83.359444",
    "ICAO": "CYCZ",
    "Airport ID": "41",
    "Latitude": "64.193333",
    "Timezone": "2661",
    "DST": "-5",
    "destinations": ["132", "147", "5487", "140"]
}, {
    "City": "Ljubljana",
    "DBTZ": "E",
    "Name": "Ljubljana",
    "Country": "Slovenia",
    "IATA/FAA": "LJU",
    "Longitude": "14.457611",
    "ICAO": "LJLJ",
    "Airport ID": "1569",
    "Latitude": "46.223686",
    "Timezone": "1273",
    "DST": "1",
    "destinations": ["1382", "421", "580", "302", "609", "340", "1701", "346", "1587", "1742", "1646", "1737", "2985", "1741", "1190", "1613", "679", "1678", "1739", "548", "304", "492"]
}, {
    "City": "Makkovik",
    "DBTZ": "A",
    "Name": "Makkovik Airport",
    "Country": "Canada",
    "IATA/FAA": "YMN",
    "Longitude": "-59.1864",
    "ICAO": "CYFT",
    "Airport ID": "5492",
    "Latitude": "55.0769",
    "Timezone": "234",
    "DST": "-4",
    "destinations": ["5502", "7252", "5461"]
}, {
    "City": "Naples",
    "DBTZ": "E",
    "Name": "Capodichino",
    "Country": "Italy",
    "IATA/FAA": "NAP",
    "Longitude": "14.290781",
    "ICAO": "LIRN",
    "Airport ID": "1561",
    "Latitude": "40.886033",
    "Timezone": "294",
    "DST": "1",
    "destinations": ["3986", "344", "352", "342", "350", "345", "346", "351", "1678", "340", "1382", "1529", "1509", "1555", "1512", "1526", "1545", "502", "599", "580", "585", "1218", "1519", "2939", "1520", "1590", "1551", "1550", "629", "1613", "302", "1701", "478", "1386", "4053", "535", "1665", "491", "1524", "1354", "548", "337", "287", "1528", "1418", "1489", "671", "1657", "1587", "2945", "492"]
}, {
    "City": "Pisa",
    "DBTZ": "E",
    "Name": "Pisa",
    "Country": "Italy",
    "IATA/FAA": "PSA",
    "Longitude": "10.39275",
    "ICAO": "LIRP",
    "Airport ID": "1562",
    "Latitude": "43.683917",
    "Timezone": "6",
    "DST": "1",
    "destinations": ["344", "507", "1509", "1587", "2985", "1190", "351", "1555", "502", "737", "609", "644", "1517", "1506", "608", "494", "1501", "1489", "1367", "1519", "9327", "304", "599", "535", "585", "523", "1066", "1051", "668", "1222", "691", "645", "355", "1225", "669", "517", "364", "1638", "1054", "491", "1229", "1606", "4198", "699", "1512", "1075", "1486", "548", "1508", "1253", "1056", "1515", "664", "1246", "8414", "580", "1218", "346", "478", "521", "1386", "490", "492", "337", "1657"]
}, {
    "City": "Lloydminster",
    "DBTZ": "A",
    "Name": "Lloydminster",
    "Country": "Canada",
    "IATA/FAA": "YLL",
    "Longitude": "-110.0725",
    "ICAO": "CYLL",
    "Airport ID": "85",
    "Latitude": "53.309166",
    "Timezone": "2193",
    "DST": "-7",
    "destinations": ["178"]
}, {
    "City": "Perugia",
    "DBTZ": "E",
    "Name": "Perugia",
    "Country": "Italy",
    "IATA/FAA": "PEG",
    "Longitude": "12.513222",
    "ICAO": "LIRZ",
    "Airport ID": "1567",
    "Latitude": "43.095906",
    "Timezone": "693",
    "DST": "1",
    "destinations": ["1519", "304", "548", "1515", "1657"]
}, {
    "City": "Ciudad Del Carmen",
    "DBTZ": "S",
    "Name": "Ciudad Del Carmen Intl",
    "Country": "Mexico",
    "IATA/FAA": "CME",
    "Longitude": "-91.799017",
    "ICAO": "MMCE",
    "Airport ID": "1789",
    "Latitude": "18.653739",
    "Timezone": "10",
    "DST": "-6",
    "destinations": ["1824", "3550"]
}, {
    "City": "Tallahassee",
    "DBTZ": "A",
    "Name": "Tallahassee Rgnl",
    "Country": "United States",
    "IATA/FAA": "TLH",
    "Longitude": "-84.350333",
    "ICAO": "KTLH",
    "Airport ID": "3698",
    "Latitude": "30.396528",
    "Timezone": "81",
    "DST": "-5",
    "destinations": ["3878", "3646", "3876", "3520", "3670", "3576", "3682"]
}, {
    "City": "Chimoio",
    "DBTZ": "U",
    "Name": "Chimoio Airport",
    "Country": "Mozambique",
    "IATA/FAA": "VPY",
    "Longitude": "33.428958",
    "ICAO": "FQCH",
    "Airport ID": "5637",
    "Latitude": "-19.151267",
    "Timezone": "2287",
    "DST": "2",
    "destinations": ["979"]
}, {
    "City": "Catumbela",
    "DBTZ": "N",
    "Name": "Catumbela Airport",
    "Country": "Angola",
    "IATA/FAA": "CBT",
    "Longitude": "13.4869",
    "ICAO": "FNCT",
    "Airport ID": "5630",
    "Latitude": "-12.4792",
    "Timezone": "0",
    "DST": "1",
    "destinations": ["951", "5632"]
}, {
    "City": "Ondjiva",
    "DBTZ": "N",
    "Name": "Ondjiva Pereira Airport",
    "Country": "Angola",
    "IATA/FAA": "VPE",
    "Longitude": "15.683822",
    "ICAO": "FNGI",
    "Airport ID": "5632",
    "Latitude": "-17.043464",
    "Timezone": "3566",
    "DST": "1",
    "destinations": ["5630", "951", "948", "959", "953", "949"]
}, {
    "City": "Mocamedes",
    "DBTZ": "N",
    "Name": "Namibe Airport",
    "Country": "Angola",
    "IATA/FAA": "MSZ",
    "Longitude": "12.146756",
    "ICAO": "FNMO",
    "Airport ID": "5633",
    "Latitude": "-15.261222",
    "Timezone": "210",
    "DST": "1",
    "destinations": ["951"]
}, {
    "City": "Golouchestershire",
    "DBTZ": "E",
    "Name": "Gloucestershire",
    "Country": "United Kingdom",
    "IATA/FAA": "GLO",
    "Longitude": "-2.167222",
    "ICAO": "EGBJ",
    "Airport ID": "472",
    "Latitude": "51.894167",
    "Timezone": "101",
    "DST": "0",
    "destinations": ["520"]
}, {
    "City": "Danang",
    "DBTZ": "U",
    "Name": "Danang Intl",
    "Country": "Vietnam",
    "IATA/FAA": "DAD",
    "Longitude": "108.19937",
    "ICAO": "VVDN",
    "Airport ID": "3196",
    "Latitude": "16.043917",
    "Timezone": "33",
    "DST": "7",
    "destinations": ["3199", "3205", "3077", "3370", "3930", "3035", "3316", "3382", "3121", "6187", "3395", "6189", "4153", "3386", "6188", "3406", "6194", "6195", "6392", "3383"]
}, {
    "City": "Beziers",
    "DBTZ": "E",
    "Name": "Vias",
    "Country": "France",
    "IATA/FAA": "BZR",
    "Longitude": "3.353903",
    "ICAO": "LFMU",
    "Airport ID": "1360",
    "Latitude": "43.323522",
    "Timezone": "56",
    "DST": "1",
    "destinations": ["353", "490", "1367", "535", "492", "478", "4198", "699", "657"]
}, {
    "City": "Avignon",
    "DBTZ": "E",
    "Name": "Caumont",
    "Country": "France",
    "IATA/FAA": "AVN",
    "Longitude": "4.901831",
    "ICAO": "LFMV",
    "Airport ID": "1361",
    "Latitude": "43.9073",
    "Timezone": "124",
    "DST": "1",
    "destinations": ["1324", "1270"]
}, {
    "City": "Bocas Del Toro",
    "DBTZ": "U",
    "Name": "Bocas Del Toro Intl",
    "Country": "Panama",
    "IATA/FAA": "BOC",
    "Longitude": "-82.250842",
    "ICAO": "MPBO",
    "Airport ID": "1865",
    "Latitude": "9.340853",
    "Timezone": "10",
    "DST": "-5",
    "destinations": ["1885", "1869"]
}, {
    "City": "Manchester",
    "DBTZ": "E",
    "Name": "Manchester",
    "Country": "United Kingdom",
    "IATA/FAA": "MAN",
    "Longitude": "-2.27495",
    "ICAO": "EGCC",
    "Airport ID": "478",
    "Latitude": "53.353744",
    "Timezone": "257",
    "DST": "0",
    "destinations": ["344", "342", "3941", "421", "3797", "507", "3830", "3752", "3682", "1382", "1418", "608", "687", "532", "467", "345", "535", "552", "534", "352", "533", "520", "499", "1524", "600", "481", "547", "495", "605", "1055", "1852", "1051", "1225", "3877", "1780", "3878", "3998", "1056", "2894", "1488", "737", "644", "666", "599", "596", "603", "2188", "2179", "16", "1230", "1212", "1218", "1525", "1538", "353", "1489", "1367", "1360", "1460", "1553", "304", "1626", "668", "1222", "669", "1638", "1054", "1229", "1227", "1198", "1236", "3953", "657", "675", "415", "1515", "1246", "8414", "1209", "498", "580", "1606", "3494", "340", "346", "1157", "4315", "1200", "1715", "1555", "4091", "1452", "1458", "1197", "1231", "1354", "1587", "1562", "1203", "1472", "1206", "1273", "1551", "1613", "1678", "1128", "2223", "2207", "2241", "636", "609", "302", "2072", "1688", "5852", "1130", "7447", "4057", "1701", "1706", "1064", "1103", "1509", "1479", "1213", "1561", "1760", "1470", "1075", "1102", "1550", "156", "178", "193", "465", "1216", "4053", "4029", "1665", "1468", "1486", "1194", "337", "1590", "2875", "1738"]
}, {
    "City": "Hanoi",
    "DBTZ": "U",
    "Name": "Noibai Intl",
    "Country": "Vietnam",
    "IATA/FAA": "HAN",
    "Longitude": "105.807178",
    "ICAO": "VVNB",
    "Airport ID": "3199",
    "Latitude": "21.221192",
    "Timezone": "39",
    "DST": "7",
    "destinations": ["2397", "2279", "2268", "1382", "3035", "3304", "340", "2264", "3196", "3205", "2276", "3077", "3370", "3364", "3157", "2305", "3992", "3942", "3034", "3930", "2372", "3316", "3373", "3406", "2359", "3121", "3885", "3115", "3120", "2985", "6187", "6189", "4153", "4082", "3395", "6192", "4029", "4168", "6194", "3239", "4158", "6193", "6191", "4157", "4154", "6195"]
}]

export { airports }