const airlines = [
  {id: 1, simple_code: "M3", icao_code: "TUS", name: "ABSA Cargo Airline", callsign: "ABSA Cargo", country: "Brazil"},
  {id: 2, simple_code: "GB", icao_code: "ABX", name: "ABX Air", callsign: "ABEX", country: "United States", country_alternate: "United States"},
  {id: 3, simple_code: "9T", icao_code: "RUN", name: "ACT Airlines", callsign: "CARGO TURK", country: "Turkey"},
  {id: 4, simple_code: "9N", icao_code: "AJV", name: "ANA & JP Express", callsign: "AYJAY CARGO", country: "Japan"},
  {id: 5, simple_code: "V8", icao_code: "VAS", name: "ATRAN Cargo Airlines", callsign: "ATRAN", country: "Russia"},
  {id: 6, simple_code: "2E", icao_code: "PHW", name: "AVE.com", callsign: "PHOENIX SHARJAH", country: "United Arab Emirates"},
  {id: 7, simple_code: "K5", icao_code: "ABE", name: "Aban Air", callsign: "ABAN", country: "Iran"},
  {id: 8, simple_code: "W9", icao_code: "AAB", name: "Abelag Aviation", callsign: "ABG", country: "Belgium"},
  {id: 9, simple_code: "MO", icao_code: "AUH", name: "Abu Dhabi Amiri Flight", callsign: "SULTAN", country: "United Arab Emirates"},
  {id: 10, simple_code: "ZY", icao_code: "ADE", name: "Ada Air", callsign: "ADA AIR", country: "Albania"},
  {id: 11, simple_code: "JP", icao_code: "ADR", name: "Adria Airways", callsign: "ADRIA", country: "Slovenia"},
  {id: 12, simple_code: "A3", icao_code: "AEE", name: "Aegean Airlines", callsign: "AEGEAN", country: "Greece"},
  {id: 13, simple_code: "RE", icao_code: "REA", name: "Aer Arann", callsign: "AER ARANN", country: "Ireland"},
  {id: 14, simple_code: "EI", icao_code: "EIN", name: "Aer Lingus", callsign: "SHAMROCK", country: "Ireland"},
  {id: 15, simple_code: "E4", icao_code: "RSO", name: "Aero Asia International", callsign: "AERO ASIA", country: "Pakistan"},
  {id: 16, simple_code: "EM", icao_code: "AEB", name: "Aero Benin", callsign: "AEROBEN", country: "Benin"},
  {id: 17, simple_code: "7L", icao_code: "CRN", name: "Aero Caribbean", callsign: "AEROCARIBBEAN", country: "Cuba"},
  {id: 18, simple_code: "Q6", icao_code: "CDP", name: "Aero Condor Peru", callsign: "CONDOR-PERU", country: "Peru"},
  {id: 19, simple_code: "AJ", icao_code: "NIG", name: "Aero Contractors", callsign: "AEROLINE", country: "Nigeria"},
  {id: 20, simple_code: "QL", icao_code: "RLN", name: "Aero Lanka", callsign: "AERO LANKA", country: "Sri Lanka"},
  {id: 21, simple_code: "M0", icao_code: "MNG", name: "Aero Mongolia", callsign: "AERO MONGOLIA", country: "Mongolia"},
  {id: 22, simple_code: "W4", icao_code: "BES", name: "Aero Services Executive", callsign: "BIRD EXPRESS", country: "France"},
  {id: 23, simple_code: "DW", icao_code: "UCR", name: "Aero-Charter Ukraine", callsign: "CHARTER UKRAINE", country: "Ukraine"},
  {id: 24, simple_code: "BF", icao_code: "RSR", name: "Aero-Service", callsign: "CONGOSERV", country: "Congo, Republic of"},
  {id: 25, simple_code: "HC", icao_code: "ATI", name: "Aero-Tropics Air Services", callsign: "AEROTROPICS", country: "Australia"},
  {id: 26, simple_code: "3S", icao_code: "BOX", name: "AeroLogic", callsign: "GERMAN CARGO", country: "Germany"},
  {id: 27, simple_code: "AM", icao_code: "AMX", name: "Aeroméxico", callsign: "AEROMEXICO", country: "Mexico"},
  {id: 28, simple_code: "P5", icao_code: "RPB", name: "AeroRepublica", callsign: "AEROREPUBLICA", country: "Colombia"},
  {id: 29, simple_code: "6N", icao_code: "KRE", name: "AeroSucre", callsign: "AEROSUCRE", country: "Colombia"},
  {id: 30, simple_code: "2B", icao_code: "ARD", name: "Aerocondor", callsign: "AEROCONDOR", country: "Portugal"},
  {id: 31, simple_code: "SU", icao_code: "AFL", name: "Aeroflot Russian Airlines", callsign: "AEROFLOT", country: "Russia"},
  {id: 32, simple_code: "SU", icao_code: "RCF", name: "Aeroflot-Cargo", callsign: "AEROFLOT CARGO", country: "Russia"},
  {id: 33, simple_code: "D9", icao_code: "DNV", name: "Aeroflot-Don", callsign: "DONAVIA", country: "Russia"},
  {id: 34, simple_code: "5N", icao_code: "AUL", name: "Aeroflot-Nord", callsign: "DVINA", country: "Russia"},
  {id: 35, simple_code: "KG", icao_code: "GTV", name: "Aerogaviota", callsign: "GAVIOTA", country: "Cuba"},
  {id: 36, simple_code: "AR", icao_code: "ARG", name: "Aerolineas Argentinas", callsign: "ARGENTINA", country: "Argentina"},
  {id: 37, simple_code: "2K", icao_code: "GLG", name: "Aerolineas Galapagos [Aerogal]", callsign: "AEROGAL", country: "Ecuador"},
  {id: 38, simple_code: "P4", icao_code: "NSO", name: "Aerolineas Sosa", callsign: "SOSA", country: "Honduras"},
  {id: 39, simple_code: "VW", icao_code: "TAO", name: "Aeromar", callsign: "TRANS-AEROMAR", country: "Mexico"},
  {id: 40, simple_code: "QO", icao_code: "MPX", name: "Aeromexpress", callsign: "AEROMEXPRESS", country: "Mexico"},
  {id: 41, simple_code: "HT", icao_code: "AHW", name: "Aeromist-Kharkiv", callsign: "AEROMIST", country: "Ukraine"},
  {id: 42, simple_code: "5D", icao_code: "SLI", name: "Aeroméxico Connect", callsign: "COSTERA", country: "Mexico"},
  {id: 43, simple_code: "OT", icao_code: "PEL", name: "Aeropelican Air Services", callsign: "PELICAN", country: "Australia"},
  {id: 44, simple_code: "WL", icao_code: "APP", name: "Aeroperlas", callsign: "AEROPERLAS", country: "Panama"},
  {id: 45, simple_code: "VH", icao_code: "LAV", name: "Aeropostal Alas de Venezuela", callsign: "AEROPOSTAL", country: "Venezuela"},
  {id: 46, simple_code: "5L", icao_code: "RSU", name: "Aerosur", callsign: "AEROSUR", country: "Bolivia"},
  {id: 47, simple_code: "VV", icao_code: "AEW", name: "Aerosvit Airlines", callsign: "AEROSVIT", country: "Ukraine"},
  {id: 48, simple_code: "FK", icao_code: "WTA", name: "Africa West", callsign: "WEST TOGO", country: "Togo"},
  {id: 49, simple_code: "XU", icao_code: "AXK", name: "African Express Airways", callsign: "EXPRESS JET", country: "Kenya"},
  {id: 50, simple_code: "6F", icao_code: "FRJ", name: "Afrijet Airlines", callsign: "AFRIJET", country: "Nigeria"},
  {id: 51, simple_code: "Q9", icao_code: "AFU", name: "Afrinat International Airlines", callsign: "", country: "Gambia"},
  {id: 52, simple_code: "8U", icao_code: "AAW", name: "Afriqiyah Airways", callsign: "AFRIQIYAH", country: "Libya"},
  {id: 53, simple_code: "ZI", icao_code: "AAF", name: "Aigle Azur", callsign: "AIGLE AZUR", country: "France"},
  {id: 54, simple_code: "AH", icao_code: "DAH", name: "Air Algerie", callsign: "AIR ALGERIE", country: "Algeria"},
  {id: 55, simple_code: "GD", icao_code: "AHA", name: "Air Alpha Greenland", callsign: "AIR ALPHA", country: "Denmark"},
  {id: 56, simple_code: "A6", icao_code: "LPV", name: "Air Alps Aviation", callsign: "AIR ALPS", country: "Austria"},
  {id: 57, simple_code: "G9", icao_code: "ABY", name: "Air Arabia", callsign: "ARABIA", country: "United Arab Emirates"},
  {id: 58, simple_code: "QN", icao_code: "ARR", name: "Air Armenia", callsign: "AIR ARMENIA", country: "Armenia"},
  {id: 59, simple_code: "KC", icao_code: "KZR", name: "Air Astana", callsign: "ASTANALINE", country: "Kazakhstan"},
  {id: 60, simple_code: "CC", icao_code: "ABD", name: "Air Atlanta Icelandic", callsign: "ATLANTA", country: "Iceland"},
  {id: 61, simple_code: "KI", icao_code: "AAG", name: "Air Atlantique", callsign: "ATLANTIC", country: "United Kingdom"},
  {id: 62, simple_code: "UU", icao_code: "REU", name: "Air Austral", callsign: "REUNION", country: "Reunion"},
  {id: 63, simple_code: "W9", icao_code: "JAB", name: "Air Bagan", callsign: "AIR BAGAN", country: "Myanmar [Burma]"},
  {id: 64, simple_code: "BT", icao_code: "BTI", name: "Air Baltic", callsign: "AIRBALTIC", country: "Latvia"},
  {id: 65, simple_code: "AB", icao_code: "BER", name: "Air Berlin", callsign: "AIR BERLIN", country: "Germany"},
  {id: 66, simple_code: "BP", icao_code: "BOT", name: "Air Botswana", callsign: "BOTSWANA", country: "Botsuana"},
  {id: 67, simple_code: "2J", icao_code: "VBW", name: "Air Burkina", callsign: "BURKINA", country: "Burkina Faso"},
  {id: 68, simple_code: "TY", icao_code: "TPC", name: "Air Caledonie", callsign: "AIRCAL", country: "New Caledonia [France]"},
  {id: 69, simple_code: "AC", icao_code: "ACA", name: "Air Canada", callsign: "AIR CANADA", country: "Canada"},
  {id: 70, simple_code: "QK", icao_code: "JZA", name: "Air Canada Jazz", callsign: "JAZZ", country: "Canada"},
  {id: 71, simple_code: "TX", icao_code: "FWI", name: "Air Caraibes", callsign: "FRENCH WEST", country: "Guadeloupe - French Antilles"},
  {id: 72, simple_code: "2Q", icao_code: "SNC", name: "Air Cargo Carriers", callsign: "NIGHT CARGO", country: "United States", country_alternate: "United States"},
  {id: 73, simple_code: "NV", icao_code: "CRF", name: "Air Central", callsign: "AIR CENTRAL", country: "Japan"},
  {id: 74, simple_code: "CV", icao_code: "CVA", name: "Air Chathams", callsign: "CHATHAM", country: "New Zealand"},
  {id: 75, simple_code: "CA", icao_code: "CCA", name: "Air China", callsign: "AIR CHINA", country: "China"},
  {id: 76, simple_code: "CA", icao_code: "CAO", name: "Air China Cargo", callsign: "AIRCHINA FREIGHT", country: "China"},
  {id: 77, simple_code: "QD", icao_code: "QCL", name: "Air Class Lineas Aereas", callsign: "ACLA", country: "Uruguay"},
  {id: 78, simple_code: "A7", icao_code: "MPD", name: "Air Comet", callsign: "RED COMET", country: "Spain"},
  {id: 79, simple_code: "AG", icao_code: "ABR", name: "Air Contractors", callsign: "CONTRACT", country: "Ireland"},
  {id: 80, simple_code: "QC", icao_code: "CRD", name: "Air Corridor", callsign: "AIR CORRIDOR", country: "Mozambique"},
  {id: 81, simple_code: "YN", icao_code: "CRQ", name: "Air Creebec", callsign: "CREE", country: "Canada"},
  {id: 82, simple_code: "EN", icao_code: "DLA", name: "Air Dolomiti", callsign: "DOLOMOTI", country: "Italy"},
  {id: 83, simple_code: "UX", icao_code: "AEA", name: "Air Europa", callsign: "EUROPA", country: "Spain"},
  {id: 84, simple_code: "PC", icao_code: "FAJ", name: "Air Fiji", callsign: "FIJIAIR", country: "Fiji"},
  {id: 85, simple_code: "OF", icao_code: "FIF", name: "Air Finland", callsign: "AIR FINLAND", country: "Finland"},
  {id: 86, simple_code: "AF", icao_code: "AFR", name: "Air France", callsign: "AIRFRANS", country: "France"},
  {id: 87, simple_code: "AF", icao_code: "AFN", name: "Air Freight NZ", callsign: "AIR FREIGHT", country: "New Zealand"},
  {id: 88, simple_code: "ZX", icao_code: "GGN", name: "Air Georgian", callsign: "GEORGIAN", country: "Canada"},
  {id: 89, simple_code: "7T", icao_code: "AGV", name: "Air Glaciers", callsign: "AIR GLACIERS", country: "Switzerland"},
  {id: 90, simple_code: "GL", icao_code: "GRL", name: "Air Greenland", callsign: "GREENLAND", country: "Denmark"},
  {id: 91, simple_code: "LQ", icao_code: "GNC", name: "Air Guinea Cargo", callsign: "GUINEA CARGO", country: "Equatorial Guinea"},
  {id: 92, simple_code: "GG", icao_code: "GUY", name: "Air Guyane Express", callsign: "GREEN BIRD", country: "French Guiana"},
  {id: 93, simple_code: "LD", icao_code: "AHK", name: "Air Hong Kong", callsign: "AIR HONG KONG", country: "Hong Kong"},
  {id: 94, simple_code: "8C", icao_code: "HZT", name: "Air Horizon", callsign: "HORIZON TOGO", country: "Togo"},
  {id: 95, simple_code: "NY", icao_code: "FXI", name: "Air Iceland Connect", callsign: "FAXI", country: "Iceland"},
  {id: 96, simple_code: "AI", icao_code: "AIC", name: "Air India", callsign: "AIRINDIA", country: "India"},
  {id: 97, simple_code: "IX", icao_code: "AXB", name: "Air India Express", callsign: "EXPRESS INDIA", country: "India"},
  {id: 98, simple_code: "CD", icao_code: "LLR", name: "Air India Regional", callsign: "ALLIED", country: "India"},
  {id: 99, simple_code: "3H", icao_code: "AIE", name: "Air Inuit", callsign: "AIR INUIT", country: "Canada"},
  {id: 100, simple_code: "I9", icao_code: "AEY", name: "Air Italy", callsign: "AIR ITALY", country: "Italy"},
  {id: 101, simple_code: "4Q", icao_code: "AEI", name: "Air Italy Polska", callsign: "POLISH BIRD", country: "Poland"},
  {id: 102, simple_code: "VU", icao_code: "VUN", name: "Air Ivoire", callsign: "AIRIVOIRE", country: "Ivory Coast"},
  {id: 103, simple_code: "JM", icao_code: "AJM", name: "Air Jamaica", callsign: "JAMAICA", country: "Jamaica"},
  {id: 104, simple_code: "NQ", icao_code: "AJX", name: "Air Japan", callsign: "AIR JAPAN", country: "Japan"},
  {id: 105, simple_code: "4A", icao_code: "AKL", name: "Air Kiribati", callsign: "", country: "Kiribati"},
  {id: 106, simple_code: "0K", icao_code: "KRT", name: "Air Kokshetau", callsign: "KOKTA", country: "Kazakhstan"},
  {id: 107, simple_code: "JS", icao_code: "KOR", name: "Air Koryo", callsign: "AIR KORYO", country: "North Korea"},
  {id: 108, simple_code: "WJ", icao_code: "LAL", name: "Air Labrador", callsign: "LAB AIR", country: "Canada"},
  {id: 109, simple_code: "7Q", icao_code: "TLR", name: "Air Libya", callsign: "AIR LIBYA", country: "Libya"},
  {id: 110, simple_code: "NX", icao_code: "AMU", name: "Air Macao", callsign: "AIR MACAO", country: "China"},
  {id: 111, simple_code: "MD", icao_code: "MDG", name: "Air Madagascar", callsign: "AIR MADAGASCAR", country: "Madagascar"},
  {id: 112, simple_code: "QM", icao_code: "AML", name: "Air Malawi", callsign: "MALAWI", country: "Malawi"},
  {id: 113, simple_code: "XG", icao_code: "KLB", name: "Air Mali International", callsign: "TRANS MALI", country: "Mali"},
  {id: 114, simple_code: "KM", icao_code: "AMC", name: "Air Malta", callsign: "AIR MALTA", country: "Malta"},
  {id: 115, simple_code: "CW", icao_code: "CWM", name: "Air Marshall Islands", callsign: "AIR MARSHALLS", country: "Marshall Islands"},
  {id: 116, simple_code: "MK", icao_code: "MAU", name: "Air Mauritius", callsign: "AIRMAURITIUS", country: "Mauritius"},
  {id: 117, simple_code: "DR", icao_code: "BIE", name: "Air Mediterranee", callsign: "MEDITERRANEE", country: "France"},
  {id: 118, simple_code: "MC", icao_code: "RCH", name: "Air Mobility Command", callsign: "REACH", country: "United States", country_alternate: "United States"},
  {id: 119, simple_code: "9U", icao_code: "MLD", name: "Air Moldova", callsign: "AIR MOLDOVA", country: "Moldova"},
  {id: 120, simple_code: "SW", icao_code: "NMB", name: "Air Namibia", callsign: "NAMIBIA", country: "Namibia"},
  {id: 121, simple_code: "NZ", icao_code: "RLK", name: "Air Nelson", callsign: "LINK", country: "New Zealand"},
  {id: 122, simple_code: "NZ", icao_code: "ANZ", name: "Air New Zealand", callsign: "NEW ZEALAND", country: "New Zealand"},
  {id: 123, simple_code: "7A", icao_code: "NXA", name: "Air Next", callsign: "BLUE-DOLPHIN", country: "Japan"},
  {id: 124, simple_code: "EL", icao_code: "ANK", name: "Air Nippon", callsign: "ANK AIR", country: "Japan"},
  {id: 125, simple_code: "EH", icao_code: "AKX", name: "Air Nippon Network Co. Ltd.", callsign: "ALFA WING", country: "Japan"},
  {id: 126, simple_code: "PX", icao_code: "ANG", name: "Air Niugini", callsign: "NUIGINI", country: "Papua New Guinea"},
  {id: 127, simple_code: "4N", icao_code: "ANT", name: "Air North", callsign: "AIR NORTH", country: "Canada"},
  {id: 128, simple_code: "M3", icao_code: "NFA", name: "Air Norway", callsign: "NORTH FLYING", country: "Norway"},
  {id: 129, simple_code: "YW", icao_code: "ANE", name: "Air Nostrum", callsign: "AIR NOSTRUM", country: "Spain"},
  {id: 130, simple_code: "AP", icao_code: "ADH", name: "Air One", callsign: "HERON", country: "Italy"},
  {id: 131, simple_code: "FJ", icao_code: "FJI", name: "Air Pacific", callsign: "PACIFIC", country: "Fiji"},
  {id: 132, simple_code: "3D", icao_code: "APG", name: "Air People International", callsign: "AIR PEOPLE", country: "Thailand"},
  {id: 133, simple_code: "2P", icao_code: "GAP", name: "Air Philippines", callsign: "ORIENT PACIFIC", country: "Philippines"},
  {id: 134, simple_code: "EB", icao_code: "PLM", name: "Air Pullmantur", callsign: "PULLMANTUR", country: "Spain"},
  {id: 135, simple_code: "PJ", icao_code: "SPM", name: "Air Saint Pierre", callsign: "SAINT PIERRE", country: "Saint-Pierre and Miquelon"},
  {id: 136, simple_code: "6O", icao_code: "ASJ", name: "Air Satellite", callsign: "SATELLITE", country: "Canada"},
  {id: 137, simple_code: "V7", icao_code: "SNG", name: "Air Senegal International", callsign: "AIR SENEGAL", country: "Senegal"},
  {id: 138, simple_code: "G8", icao_code: "AGB", name: "Air Service Gabon", callsign: "", country: "Gabon"},
  {id: 139, simple_code: "HM", icao_code: "SEY", name: "Air Seychelles", callsign: "SEYCHELLES", country: "Seychelles"},
  {id: 140, simple_code: "4D", icao_code: "ASD", name: "Air Sinai", callsign: "AIR SINAI", country: "Egypt"},
  {id: 141, simple_code: "GM", icao_code: "SVK", name: "Air Slovakia", callsign: "SLOVAKIA", country: "Slovakia"},
  {id: 142, simple_code: "WO", icao_code: "WOW", name: "Air Southwest", callsign: "SWALLOW", country: "United Kingdom"},
  {id: 143, simple_code: "YI", icao_code: "RSI", name: "Air Sunshine", callsign: "AIR SUNSHINE", country: "United States", country_alternate: "United States"},
  {id: 144, simple_code: "VT", icao_code: "VTA", name: "Air Tahiti", callsign: "AIR TAHITI", country: "French Polynesia"},
  {id: 145, simple_code: "TN", icao_code: "THT", name: "Air Tahiti Nui", callsign: "TAHITI AIRLINES", country: "French Polynesia"},
  {id: 146, simple_code: "TS", icao_code: "TSC", name: "Air Transat", callsign: "TRANSAT", country: "Canada"},
  {id: 147, simple_code: "8C", icao_code: "ATN", name: "Air Transport International LLC", callsign: "AIR TRANSPORT", country: "United States", country_alternate: "United States"},
  {id: 148, simple_code: "JY", icao_code: "TCI", name: "Air Turks and Caicos", callsign: "KERRMONT", country: "Turks & Caicos"},
  {id: 149, simple_code: "U7", icao_code: "UGA", name: "Air Uganda", callsign: "UGANDA", country: "Uganda"},
  {id: 150, simple_code: "3N", icao_code: "URG", name: "Air Urga", callsign: "URGA", country: "Ukraine"},
  {id: 151, simple_code: "VL", icao_code: "VIM", name: "Air VIA", callsign: "VIA AIRWAYS", country: "Bulgaria"},
  {id: 152, simple_code: "DO", icao_code: "RVL", name: "Air Vall", callsign: "AIR VALLEE", country: "Italy"},
  {id: 153, simple_code: "NF", icao_code: "AVN", name: "Air Vanuatu", callsign: "AIR VAN", country: "Vanuatu"},
  {id: 154, simple_code: "ZW", icao_code: "AWI", name: "Air Wisconsin", callsign: "AIR WISCONSIN", country: "United States", country_alternate: "United States"},
  {id: 155, simple_code: "ZT", icao_code: "TZT", name: "Air Zambezi", callsign: "ZAMBESI", country: "Zimbabwe"},
  {id: 156, simple_code: "UM", icao_code: "AZW", name: "Air Zimbabwe", callsign: "AIR ZIMBABWE", country: "Zimbabwe"},
  {id: 157, simple_code: "F8", icao_code: "AHR", name: "Air2there", callsign: "", country: "New Zealand"},
  {id: 158, simple_code: "AK", icao_code: "AXM", name: "AirAsia", callsign: "ASIAN EXPRESS", country: "Malaysia"},
  {id: 159, simple_code: "D7", icao_code: "XAX", name: "AirAsia X", callsign: "XANADU", country: "Malaysia"},
  {id: 160, simple_code: "RU", icao_code: "ABW", name: "AirBridgeCargo Airlines", callsign: "AIRBRIDGE CARGO", country: "Russia"},
  {id: 161, simple_code: "FL", icao_code: "TRS", name: "AirTran Airways", callsign: "CITRUS", country: "United States", country_alternate: "United States"},
  {id: 162, simple_code: "AP", icao_code: "AIB", name: "Airbus Industrie", callsign: "AIRBUS INDUSTRIE", country: "France"},
  {id: 163, simple_code: "SB", icao_code: "ACI", name: "Aircalin [Air Caledonie International]", callsign: "AIRCALIN", country: "New Caledonia [France]"},
  {id: 164, simple_code: "4C", icao_code: "ARE", name: "Aires, Aerovias de Integracion Regional, S.A.", callsign: "AIRES", country: "Colombia"},
  {id: 165, simple_code: "P2", icao_code: "XAK", name: "Airkenya Express", callsign: "AIRKENYA", country: "Kenya"},
  {id: 166, simple_code: "A5", icao_code: "RLA", name: "Airlinair", callsign: "AIRLINAIR", country: "France"},
  {id: 167, simple_code: "FO", icao_code: "ATM", name: "Airlines Of Tasmania", callsign: "AIRTAS", country: "Australia"},
  {id: 168, simple_code: "CG", icao_code: "TOK", name: "Airlines PNG", callsign: "BALUS", country: "Papua New Guinea"},
  {id: 169, simple_code: "4Z", icao_code: "LNK", name: "Airlink", callsign: "LINK", country: "South Africa"},
  {id: 170, simple_code: "TL", icao_code: "ANO", name: "Airnorth", callsign: "TOPEND", country: "Australia"},
  {id: 171, simple_code: "PL", icao_code: "ASE", name: "Airstars", callsign: "MOROZOV", country: "Russia"},
  {id: 172, simple_code: "6L", icao_code: "AKK", name: "Aklak Air", callsign: "AKLAK", country: "Canada"},
  {id: 173, simple_code: "2D", icao_code: "OST", name: "Alania Airlines", callsign: "ALANIA", country: "Russia"},
  {id: 174, simple_code: "AS", icao_code: "ASA", name: "Alaska Airlines", callsign: "ALASKA", country: "United States", country_alternate: "United States"},
  {id: 175, simple_code: "KO", icao_code: "AER", name: "Alaska Central Express", callsign: "ACE AIR", country: "United States", country_alternate: "United States"},
  {id: 176, simple_code: "LV", icao_code: "LBC", name: "Albanian Airlines", callsign: "ALBANIAN", country: "Albania"},
  {id: 177, simple_code: "D4", icao_code: "LID", name: "Alidaunia", callsign: "ALIDA", country: "Italy"},
  {id: 178, simple_code: "AZ", icao_code: "AZA", name: "Alitalia", callsign: "ALITALIA", country: "Italy"},
  {id: 179, simple_code: "XM", icao_code: "SMX", name: "Alitalia Express", callsign: "ALIEXPRESS", country: "Italy"},
  {id: 180, simple_code: "NH", icao_code: "ANA", name: "All Nippon Airways", callsign: "ALL NIPPON", country: "Japan"},
  {id: 181, simple_code: "G4", icao_code: "AAY", name: "Allegiant Air", callsign: "ALLEGIANT", country: "United States", country_alternate: "United States"},
  {id: 182, simple_code: "LL", icao_code: "GRO", name: "Allegro", callsign: "ALLEGRO", country: "Mexico"},
  {id: 183, simple_code: "QQ", icao_code: "UTY", name: "Alliance Airlines", callsign: "UNITY", country: "Australia"},
  {id: 184, simple_code: "6T", icao_code: "LMT", name: "Almaty Aviation", callsign: "ALMATY", country: "Kazakhstan"},
  {id: 185, simple_code: "KH", icao_code: "AAH", name: "Aloha Air Cargo", callsign: "ALOHA", country: "United States", country_alternate: "United States"},
  {id: 186, simple_code: "5A", icao_code: "AIP", name: "Alpine Air Express", callsign: "ALPINE AIR", country: "United States", country_alternate: "United States"},
  {id: 187, simple_code: "6R", icao_code: "DRU", name: "Alrosa Mirny Air Enterprise", callsign: "MIRNY", country: "Russia"},
  {id: 188, simple_code: "Z8", icao_code: "AZN", name: "Amaszonas", callsign: "AMASZONAS", country: "Bolivia"},
  {id: 189, simple_code: "AA", icao_code: "AAL", name: "American Airlines", callsign: "AMERICAN", country: "United States", country_alternate: "United States"},
  {id: 190, simple_code: "MQ", icao_code: "EGF", name: "American Eagle Airlines", callsign: "EAGLE FLIGHT", country: "United States", country_alternate: "United States"},
  {id: 191, simple_code: "A8", icao_code: "AMF", name: "Ameriflight", callsign: "AMFLIGHT", country: "United States", country_alternate: "United States"},
  {id: 192, simple_code: "M6", icao_code: "AJT", name: "Amerijet International", callsign: "AMERIJET", country: "United States", country_alternate: "United States"},
  {id: 193, simple_code: "WD", icao_code: "AAN", name: "Amsterdam Airlines", callsign: "AMSTEL", country: "Netherlands"},
  {id: 194, simple_code: "OY", icao_code: "ANS", name: "Andes Líneas Aéreas", callsign: "AEROANDES", country: "Argentina"},
  {id: 195, simple_code: "8G", icao_code: "NGE", name: "Angel Airlines", callsign: "ANGEL AIR", country: "Thailand"},
  {id: 196, simple_code: "4", icao_code: "ABV", name: "Antrak Air", callsign: "ANTRAK", country: "Ghana"},
  {id: 197, simple_code: "5F", icao_code: "CIR", name: "Arctic Circle Air Service", callsign: "AIR ARCTIC", country: "United States", country_alternate: "United States"},
  {id: 198, simple_code: "ZE", icao_code: "AZE", name: "Arcus-Air Logistic", callsign: "ARCUS AIR", country: "Germany"},
  {id: 199, simple_code: "FG", icao_code: "AFG", name: "Ariana Afghan Airlines", callsign: "ARIANA", country: "Afghanistan"},
  {id: 200, simple_code: "W3", icao_code: "ARA", name: "Arik Air", callsign: "ARIK AIR", country: "Nigeria"},
  {id: 201, simple_code: "OR", icao_code: "TFL", name: "Arkefly", callsign: "ARKEFLY", country: "Netherlands"},
  {id: 202, simple_code: "IZ", icao_code: "AIZ", name: "Arkia Israel Airlines", callsign: "ARKIA", country: "Israel"},
  {id: 203, simple_code: "U8", icao_code: "RNV", name: "Armavia", callsign: "ARMAVIA", country: "Armenia"},
  {id: 204, simple_code: "JW", icao_code: "APW", name: "Arrow Air", callsign: "BIG A", country: "United States", country_alternate: "United States"},
  {id: 205, simple_code: "R7", icao_code: "OCA", name: "Aserca Airlines", callsign: "AROSCA", country: "Venezuela"},
  {id: 206, simple_code: "HJ", icao_code: "AXF", name: "Asian Express Airlines", callsign: "FREIGHTEXPRESS", country: "Australia"},
  {id: 207, simple_code: "OZ", icao_code: "AAR", name: "Asiana Airlines", callsign: "ASIANA", country: "South Korea"},
  {id: 208, simple_code: "4K", icao_code: "AAS", name: "Askari Aviation", callsign: "AL-AAS", country: "Pakistan"},
  {id: 209, simple_code: "ER", icao_code: "DHL", name: "Astar Air Cargo", callsign: "DHL", country: "United States", country_alternate: "United States"},
  {id: 210, simple_code: "5W", icao_code: "AEU", name: "Astraeus", callsign: "FLYSTAR", country: "United Kingdom"},
  {id: 211, simple_code: "OB", icao_code: "ASZ", name: "Astrakhan Airlines", callsign: "AIR ASTRAKHAN", country: "Russia"},
  {id: 212, simple_code: "8V", icao_code: "ACP", name: "Astral Aviation", callsign: "ASTRAL CARGO", country: "Kenya"},
  {id: 213, simple_code: "3G", icao_code: "AYZ", name: "Atlant-Soyuz Airlines", callsign: "ATLANT-SOYUZ", country: "Russia"},
  {id: 214, simple_code: "ZF", icao_code: "HHA", name: "Atlantic Airlines de Honduras", callsign: "ATLANTIC HONDURAS", country: "Honduras"},
  {id: 215, simple_code: "RC", icao_code: "FLI", name: "Atlantic Airways", callsign: "FAROELINE", country: "Faroe Islands [Denmark]"},
  {id: 216, simple_code: "EV", icao_code: "ASQ", name: "Atlantic Southeast Airlines", callsign: "ACEY", country: "United States", country_alternate: "United States"},
  {id: 217, simple_code: "TD", icao_code: "LUR", name: "Atlantis European Airways", callsign: "ATLANTIS LINER", country: "Armenia"},
  {id: 218, simple_code: "5Y", icao_code: "GTI", name: "Atlas Air", callsign: "GIANT", country: "United States", country_alternate: "United States"},
  {id: 219, simple_code: "8A", icao_code: "BMM", name: "Atlas Blue", callsign: "ATLAS BLUE", country: "Morocco"},
  {id: 220, simple_code: "KK", icao_code: "KKK", name: "Atlasjet", callsign: "ATLASJET", country: "Turkey"},
  {id: 221, simple_code: "IP", icao_code: "JOL", name: "Atyrau Air Ways", callsign: "EDIL", country: "Kazakhstan"},
  {id: 222, simple_code: "IQ", icao_code: "AUB", name: "Augsburg Airways GmbH", callsign: "AUGSBURG-AIR", country: "Germany"},
  {id: 223, simple_code: "GR", icao_code: "AUR", name: "Aurigny Air Services", callsign: "AYLINE", country: "United Kingdom"},
  {id: 224, simple_code: "AU", icao_code: "AUT", name: "Austral Lineas Aereas", callsign: "AUSTRAL", country: "Argentina"},
  {id: 225, simple_code: "XM", icao_code: "XME", name: "Australian air Express", callsign: "JETEX", country: "Australia"},
  {id: 226, simple_code: "OS", icao_code: "AUA", name: "Austrian Airlines", callsign: "AUSTRIAN", country: "Austria"},
  {id: 227, simple_code: "VO", icao_code: "TYR", name: "Austrian Arrows [Tyrolean Airways]", callsign: "TYROLEAN", country: "Austria"},
  {id: 228, simple_code: "TF", icao_code: "AVJ", name: "Avia Traffic Company", callsign: "ATOMIC", country: "Kyrgyzstan"},
  {id: 229, simple_code: "ZR", icao_code: "AZS", name: "Aviacon Zitotrans", callsign: "ZITOTRANS", country: "Russia"},
  {id: 230, simple_code: "6A", icao_code: "CHP", name: "Aviacsa", callsign: "AVIACSA", country: "Mexico"},
  {id: 231, simple_code: "7U", icao_code: "ERG", name: "Aviaenergo", callsign: "AVIAENERGO", country: "Russia"},
  {id: 232, simple_code: "EC", icao_code: "TWN", name: "Avialeasing Aviation Company", callsign: "TWINARROW", country: "Uzbekistan"},
  {id: 233, simple_code: "AV", icao_code: "AVA", name: "Avianca - Aerovias Nacionales de Colombia, S.A.", callsign: "AVIANCA", country: "Colombia"},
  {id: 234, simple_code: "4B", icao_code: "TUP", name: "Aviastar-TU", callsign: "TUPOLEVAIR", country: "Russia"},
  {id: 235, simple_code: "Z3", icao_code: "SMJ", name: "Avient Aviation", callsign: "AVAVIA", country: "Zimbabwe"},
  {id: 236, simple_code: "U3", icao_code: "AIA", name: "Avies", callsign: "AVIES", country: "Estonia"},
  {id: 237, simple_code: "JJ", icao_code: "AGX", name: "Aviogenex", callsign: "GENEX", country: "Serbia"},
  {id: 238, simple_code: "9V", icao_code: "ROI", name: "Avior Airlines", callsign: "AVIOR", country: "Venezuela"},
  {id: 239, simple_code: "G2", icao_code: "VXG", name: "Avirex", callsign: "AVIREX-GABON", country: "Gabon"},
  {id: 240, simple_code: "2Q", icao_code: "ETS", name: "Avitrans", callsign: "EXTRANS", country: "Sweden"},
  {id: 241, simple_code: "6V", icao_code: "AXY", name: "Axis Airways", callsign: "AXIS", country: "France"},
  {id: 242, simple_code: "0", icao_code: "AYT", name: "Ayit Aviation and Tourism", callsign: "AYEET", country: "Israel"},
  {id: 243, simple_code: "J2", icao_code: "AHY", name: "Azerbaijan Airlines", callsign: "AZAL", country: "Azerbaijan"},
  {id: 244, simple_code: "AD", icao_code: "AZU", name: "Azul Brazilian Airlines", callsign: "AZUL", country: "Brazil"},
  {id: 245, simple_code: "JA", icao_code: "BON", name: "B&H Airlines", callsign: "AIR BOSNA", country: "Bosnia-Hercegovina"},
  {id: 246, simple_code: "CJ", icao_code: "CFE", name: "BA CityFlyer", callsign: "FLYER", country: "United Kingdom"},
  {id: 247, simple_code: "B4", icao_code: "BCF", name: "BACH Flugbetriebsgesellschaft mbH", callsign: "BACH", country: "Austria"},
  {id: 248, simple_code: "1B", icao_code: "BGH", name: "BH Air [Balkan Holidays]", callsign: "BALKAN HOLIDAYS", country: "Bulgaria"},
  {id: 249, simple_code: "BD", icao_code: "BMR", name: "BMI Regional", callsign: "", country: "United Kingdom"},
  {id: 250, simple_code: "UP", icao_code: "BHS", name: "Bahamasair", callsign: "BAHAMAS", country: "Bahamas"},
  {id: 251, simple_code: "2B", icao_code: "BAB", name: "Bahrain Air", callsign: "BAHRAIN AIR", country: "Bahrain"},
  {id: 252, simple_code: "PG", icao_code: "BKP", name: "Bangkok Airways", callsign: "BANGKOK AIR", country: "Thailand"},
  {id: 253, simple_code: "B4", icao_code: "BKA", name: "Bankair", callsign: "BANKAIR", country: "United States", country_alternate: "United States"},
  {id: 254, simple_code: "8N", icao_code: "NKF", name: "Barents AirLink", callsign: "NORDFLIGHT", country: "Sweden"},
  {id: 255, simple_code: "7P", icao_code: "BTV", name: "Batavia Air", callsign: "BATAVIA", country: "Indonesia"},
  {id: 256, simple_code: "JV", icao_code: "BLS", name: "Bearskin Lake Air Service", callsign: "BEARSKIN", country: "Canada"},
  {id: 257, simple_code: "4T", icao_code: "BHP", name: "Belair Airlines", callsign: "BELAIR", country: "Switzerland"},
  {id: 258, simple_code: "B2", icao_code: "BRU", name: "Belavia Belarusian Airlines", callsign: "BELARUS AVIA", country: "Belarus"},
  {id: 259, simple_code: "LZ", icao_code: "LBY", name: "Belle Air", callsign: "ALBAN-BELLE", country: "Albania"},
  {id: 260, simple_code: "B3", icao_code: "BLV", name: "Bellview Airlines", callsign: "BELLVIEW AIRLINES", country: "Nigeria"},
  {id: 261, simple_code: "CH", icao_code: "BMJ", name: "Bemidji Airlines", callsign: "BEMIDJI", country: "United States", country_alternate: "United States"},
  {id: 262, simple_code: "A8", icao_code: "BGL", name: "Benin Golf Air", callsign: "BENIN GOLF", country: "Benin"},
  {id: 263, simple_code: "8E", icao_code: "BRG", name: "Bering Air", callsign: "BERING AIR", country: "United States", country_alternate: "United States"},
  {id: 264, simple_code: "J8", icao_code: "BVT", name: "Berjaya Air", callsign: "BERJAYA", country: "Malaysia"},
  {id: 265, simple_code: "5Q", icao_code: "BEA", name: "Best Air", callsign: "BEST AIR", country: "Bangladesh"},
  {id: 266, simple_code: "T1", icao_code: "BST", name: "Bestair", callsign: "BARBIE", country: "Turkey"},
  {id: 267, simple_code: "BG", icao_code: "BBC", name: "Biman Bangladesh Airlines", callsign: "BANGLADESH", country: "Bangladesh"},
  {id: 268, simple_code: "NT", icao_code: "IBB", name: "Binter Canarias", callsign: "BINTER", country: "Spain"},
  {id: 269, simple_code: "5Z", icao_code: "BML", name: "Bismillah Airlines", callsign: "BISMILLAH", country: "Bangladesh"},
  {id: 270, simple_code: "0B", icao_code: "JOR", name: "Blue Air", callsign: "BLUE TRANSPORT", country: "Romania"},
  {id: 271, simple_code: "BZ", icao_code: "BDA", name: "Blue Dart Aviation", callsign: "BLUE DART", country: "India"},
  {id: 272, simple_code: "SI", icao_code: "BCI", name: "Blue Islands", callsign: "BLUE ISLAND", country: "United Kingdom"},
  {id: 273, simple_code: "4Y", icao_code: "BLE", name: "Blue Line", callsign: "BLUE BERRY", country: "France"},
  {id: 274, simple_code: "BV", icao_code: "BPA", name: "Blue Panorama Airlines", callsign: "BLUE PANOROMA", country: "Italy"},
  {id: 275, simple_code: "QW", icao_code: "BWG", name: "Blue Wings", callsign: "BLUE WINGS", country: "Germany"},
  {id: 276, simple_code: "KF", icao_code: "BLF", name: "Blue1", callsign: "BLUEFIN", country: "Finland"},
  {id: 277, simple_code: "BF", icao_code: "BBD", name: "Bluebird Cargo", callsign: "BLUE CARGO", country: "Iceland"},
  {id: 278, simple_code: "B8", icao_code: "BTR", name: "Botir-Avia", callsign: "BOTIR-AVIA", country: "Kyrgyzstan"},
  {id: 279, simple_code: "K6", icao_code: "BRV", name: "Bravo Air Congo", callsign: "BRAVO", country: "Congo, Democratic Republic of the"},
  {id: 280, simple_code: "FQ", icao_code: "BRI", name: "Brindabella Airlines", callsign: "BRINDABELLA", country: "Australia"},
  {id: 281, simple_code: "DB", icao_code: "BZH", name: "Brit Air", callsign: "BRITAIR", country: "France"},
  {id: 282, simple_code: "BA", icao_code: "BAW", name: "British Airways", callsign: "SPEEDBIRD", country: "United Kingdom"},
  {id: 283, simple_code: "BS", icao_code: "BIH", name: "British International Helicopters", callsign: "BRINTEL", country: "United Kingdom"},
  {id: 284, simple_code: "SN", icao_code: "BEL", name: "Brussels Airlines", callsign: "BEE-LINE", country: "Belgium"},
  {id: 285, simple_code: "J4", icao_code: "BFL", name: "Buffalo Airways", callsign: "BUFFALO", country: "Canada"},
  {id: 286, simple_code: "FB", icao_code: "LZB", name: "Bulgaria Air", callsign: "FLYING BULGARIA", country: "Bulgaria"},
  {id: 287, simple_code: "1T", icao_code: "BUC", name: "Bulgarian Air Charter", callsign: "BULGARIAN CHARTER", country: "Nigeria"},
  {id: 288, simple_code: "UZ", icao_code: "BRQ", name: "Buraq Air", callsign: "BURAQAIR", country: "Libya"},
  {id: 289, simple_code: "4P", icao_code: "ABB", name: "Business Aviation", callsign: "AFRICAN BUSINESS", country: "Congo, Democratic Republic of the"},
  {id: 290, simple_code: "5C", icao_code: "ICL", name: "CAL Cargo Air Lines", callsign: "CAL", country: "Israel"},
  {id: 291, simple_code: "XK", icao_code: "CCM", name: "CCM Airlines", callsign: "CORSICA", country: "France"},
  {id: 292, simple_code: "AW", icao_code: "SCH", name: "CHC Airways", callsign: "SCHREINER", country: "Netherlands"},
  {id: 293, simple_code: "L5", icao_code: "HKS", name: "CHC Helikopter Service", callsign: "HELIBUS", country: "Norway"},
  {id: 294, simple_code: "MO", icao_code: "CAV", name: "Calm Air", callsign: "CALM AIR", country: "Canada"},
  {id: 295, simple_code: "C6", icao_code: "CJA", name: "CanJet", callsign: "CANJET", country: "Canada"},
  {id: 296, simple_code: "5T", icao_code: "MPE", name: "Canadian North", callsign: "EMPRESS", country: "Canada"},
  {id: 297, simple_code: "9K", icao_code: "KAP", name: "Cape Air", callsign: "CAIR", country: "United States", country_alternate: "United States"},
  {id: 298, simple_code: "PT", icao_code: "CCI", name: "Capital Cargo International Airlines", callsign: "CAPPY", country: "United States", country_alternate: "United States"},
  {id: 299, simple_code: "BB", icao_code: "CBB", name: "Cargo B Airlines", callsign: "CARGO-BEE", country: "Belgium"},
  {id: 300, simple_code: "8L", icao_code: "CGP", name: "Cargo Plus Aviation", callsign: "CARGO PLUS", country: "United Arab Emirates"},
  {id: 301, simple_code: "2G", icao_code: "CRG", name: "Cargoitalia", callsign: "WHITE PELICAN", country: "Italy"},
  {id: 302, simple_code: "W8", icao_code: "CJT", name: "Cargojet Airways", callsign: "CARGOJET", country: "Canada"},
  {id: 303, simple_code: "CV", icao_code: "CLX", name: "Cargolux", callsign: "CARGOLUX", country: "Luxembourg"},
  {id: 304, simple_code: "B9", icao_code: "CBC", name: "Caribair", callsign: "CARIBAIR", country: "Dominican Republic"},
  {id: 305, simple_code: "BW", icao_code: "BWA", name: "Caribbean Airlines", callsign: "CARIBBEAN AIRLINES", country: "Trinidad & Tobago"},
  {id: 306, simple_code: "V3", icao_code: "KRP", name: "Carpatair", callsign: "CARPATAIR", country: "Romania"},
  {id: 307, simple_code: "RV", icao_code: "CPN", name: "Caspian Airlines", callsign: "CASPIAN", country: "Iran"},
  {id: 308, simple_code: "CX", icao_code: "CPA", name: "Cathay Pacific", callsign: "CATHAY", country: "China"},
  {id: 309, simple_code: "KX", icao_code: "CAY", name: "Cayman Airways", callsign: "CAYMAN", country: "Cayman Islands"},
  {id: 310, simple_code: "5J", icao_code: "CEB", name: "Cebu Pacific", callsign: "CEBU AIR", country: "Philippines"},
  {id: 311, simple_code: "9M", icao_code: "GLR", name: "Central Mountain Air", callsign: "GLACIER", country: "Canada"},
  {id: 312, simple_code: "C0", icao_code: "CLW", name: "Centralwings", callsign: "CENTRALWINGS", country: "Poland"},
  {id: 313, simple_code: "J7", icao_code: "CVC", name: "Centre-Avia", callsign: "AVIACENTRE", country: "Russia"},
  {id: 314, simple_code: "WE", icao_code: "CWC", name: "Centurion Air Cargo", callsign: "CHALLENGE CARGO", country: "United States", country_alternate: "United States"},
  {id: 315, simple_code: "3U", icao_code: "NCH", name: "Chanchangi Airlines", callsign: "CHANCHANGI", country: "Nigeria"},
  {id: 316, simple_code: "2Z", icao_code: "CGN", name: "Chang'an Airlines", callsign: "CHANGAN", country: "China"},
  {id: 317, simple_code: "RP", icao_code: "CHQ", name: "Chautauqua Airlines", callsign: "CHAUTAUQUA", country: "United States", country_alternate: "United States"},
  {id: 318, simple_code: "CI", icao_code: "CAL", name: "China Airlines", callsign: "DYNASTY", country: "Taiwan"},
  {id: 319, simple_code: "CK", icao_code: "CKK", name: "China Cargo Airlines", callsign: "CARGO KING", country: "China"},
  {id: 320, simple_code: "MU", icao_code: "CES", name: "China Eastern Airlines", callsign: "CHINA EASTERN", country: "China"},
  {id: 321, simple_code: "8Y", icao_code: "CYZ", name: "China Postal Airlines", callsign: "CHINA POST", country: "China"},
  {id: 322, simple_code: "CZ", icao_code: "CSN", name: "China Southern Airlines", callsign: "CHINA SOUTHERN", country: "China"},
  {id: 323, simple_code: "HR", icao_code: "CUA", name: "China United Airlines", callsign: "LIANHANG", country: "China"},
  {id: 324, simple_code: "PN", icao_code: "CHB", name: "China West Air", callsign: "WEST CHINA", country: "China"},
  {id: 325, simple_code: "XO", icao_code: "CXH", name: "China Xinhua Airlines", callsign: "XINHUA", country: "China"},
  {id: 326, simple_code: "OQ", icao_code: "CQN", name: "Chongqing Airlines", callsign: "CHONG QING", country: "China"},
  {id: 327, simple_code: "A2", icao_code: "CIU", name: "Cielos Airlines", callsign: "CIELOS", country: "Peru"},
  {id: 328, simple_code: "QI", icao_code: "CIM", name: "Cimber Air", callsign: "CIMBER", country: "Denmark"},
  {id: 329, simple_code: "C9", icao_code: "RUS", name: "Cirrus Airlines", callsign: "CIRRUS AIR", country: "Germany"},
  {id: 10000, simple_code: "QG", icao_code: "CTV", name: "Citilink", callsign: "SUPERGREEN", country: "Indonesia"},
  {id: 10001, simple_code: "ID", icao_code: "BTK", name: "Batik Air", callsign: "BATIK", country: "Indonesia"},
  {id: 330, simple_code: "CF", icao_code: "SDR", name: "City Airline", callsign: "SWEDESTAR", country: "Sweden"},
  {id: 331, simple_code: "G3", icao_code: "CIX", name: "City Connexion Airlines", callsign: "CONNEXION", country: "Burundi"},
  {id: 332, simple_code: "WX", icao_code: "BCY", name: "CityJet", callsign: "CITY-IRELAND", country: "Ireland"},
  {id: 333, simple_code: "4C", icao_code: "CGK", name: "Click Airways", callsign: "CLICK AIR", country: "United Arab Emirates"},
  {id: 334, simple_code: "XG", icao_code: "CLI", name: "Clickair", callsign: "CLICKJET", country: "Spain"},
  {id: 335, simple_code: "9L", icao_code: "CJC", name: "Colgan Air", callsign: "COLGAN", country: "United States", country_alternate: "United States"},
  {id: 336, simple_code: "MN", icao_code: "CAW", name: "Comair", callsign: "COMMERCIAL", country: "South Africa"},
  {id: 337, simple_code: "OH", icao_code: "COM", name: "Comair", callsign: "COMAIR", country: "United States", country_alternate: "United States"},
  {id: 338, simple_code: "C5", icao_code: "UCA", name: "CommutAir", callsign: "COMMUTAIR", country: "United States", country_alternate: "United States"},
  {id: 339, simple_code: "KR", icao_code: "KMZ", name: "Comores Aviation", callsign: "", country: "Comoros"},
  {id: 340, simple_code: "E9", icao_code: "FPY", name: "Compagnie Africaine d'Aviation", callsign: "AFRICOMPANY", country: "Congo, Democratic Republic of the"},
  {id: 341, simple_code: "I5", icao_code: "CMM", name: "Air Mali", callsign: "CAMALI", country: "Mali"},
  {id: 342, simple_code: "CP", icao_code: "CPZ", name: "Compass Airlines", callsign: "COMPASS ROSE", country: "United States", country_alternate: "United States"},
  {id: 343, simple_code: "DE", icao_code: "CFG", name: "Condor Flugdienst", callsign: "CONDOR", country: "Germany"},
  {id: 344, simple_code: "C3", icao_code: "KIS", name: "Contact Air", callsign: "CONTACTAIR", country: "Germany"},
  {id: 345, simple_code: "CO", icao_code: "COA", name: "Continental Airlines", callsign: "CONTINENTAL", country: "United States", country_alternate: "United States"},
  {id: 346, simple_code: "PC", icao_code: "PVV", name: "Continental Airways", callsign: "CONTAIR", country: "Russia"},
  {id: 347, simple_code: "CS", icao_code: "CMI", name: "Continental Micronesia", callsign: "AIR MIKE", country: "Guam [United States]"},
  {id: 348, simple_code: "V0", icao_code: "VCV", name: "Conviasa", callsign: "CONVIASA", country: "Venezuela"},
  {id: 349, simple_code: "CM", icao_code: "CMP", name: "Copa Airlines", callsign: "COPA", country: "Panama"},
  {id: 350, simple_code: "2", icao_code: "CPB", name: "Corporate Express", callsign: "PENTA", country: "Canada"},
  {id: 351, simple_code: "SS", icao_code: "CRL", name: "Corsairfly", callsign: "CORSAIR", country: "France"},
  {id: 352, simple_code: "F5", icao_code: "COZ", name: "Cosmic Air", callsign: "COSMIC AIR", country: "Nepal"},
  {id: 353, simple_code: "7C", icao_code: "COY", name: "Coyne Aviation", callsign: "COYNE AIR", country: "United Kingdom"},
  {id: 354, simple_code: "OU", icao_code: "CTN", name: "Croatia Airlines", callsign: "CROATIA", country: "Croatia"},
  {id: 355, simple_code: "CU", icao_code: "CUB", name: "Cubana de Aviacion", callsign: "CUBANA", country: "Cuba"},
  {id: 356, simple_code: "5R", icao_code: "CTT", name: "Custom Air Transport", callsign: "CATT", country: "United States", country_alternate: "United States"},
  {id: 357, simple_code: "CY", icao_code: "CYP", name: "Cyprus Airways", callsign: "CYPRUS", country: "Cyprus"},
  {id: 358, simple_code: "KY", icao_code: "KYV", name: "Cyprus Turkish Airlines [KTHY Kibris Turk Hava Yollari]", callsign: "AIRKIBRIS", country: "Turkey"},
  {id: 359, simple_code: "OK", icao_code: "CSA", name: "Czech Airlines", callsign: "CSA-LINES", country: "Czech Republic"},
  {id: 360, simple_code: "DX", icao_code: "DTR", name: "DAT Danish Air Transport", callsign: "DANISH", country: "Denmark"},
  {id: 361, simple_code: "D5", icao_code: "DAE", name: "DHL Aero Expreso", callsign: "YELLOW", country: "Panama"},
  {id: 362, simple_code: "D0", icao_code: "DHK", name: "DHL Air UK", callsign: "EUROTRANS", country: "United Kingdom"},
  {id: 363, simple_code: "ES", icao_code: "DHX", name: "DHL International Aviation ME", callsign: "DILMUN", country: "Bahrain"},
  {id: 364, simple_code: "L3", icao_code: "JOS", name: "DHL de Guatemala", callsign: "", country: "Guatemala"},
  {id: 365, simple_code: "R6", icao_code: "DNU", name: "DOT LT", callsign: "DANU", country: "Lithuania"},
  {id: 366, simple_code: "1I", icao_code: "AMB", name: "DRF Luftrettung", callsign: "CIVIL AIR AMBULANCE", country: "Germany"},
  {id: 367, simple_code: "D3", icao_code: "DAO", name: "Daallo Airlines", callsign: "DAALLO AIRLINES", country: "United Arab Emirates"},
  {id: 368, simple_code: "N2", icao_code: "DAG", name: "Dagestan Airlines", callsign: "DAGAL", country: "Russia"},
  {id: 369, simple_code: "0D", icao_code: "DWT", name: "Darwin Airline", callsign: "DARWIN", country: "Switzerland"},
  {id: 370, simple_code: "JD", icao_code: "DER", name: "Deer Jet", callsign: "DEER JET", country: "China"},
  {id: 371, simple_code: "DL", icao_code: "DAL", name: "Delta Air Lines", callsign: "DELTA", country: "United States", country_alternate: "United States"},
  {id: 372, simple_code: "3D", icao_code: "DNM", name: "Denim Air", callsign: "DENIM", country: "Netherlands"},
  {id: 373, simple_code: "HS", icao_code: "HSV", name: "Direktflyg", callsign: "HIGHSWEDE", country: "Sweden"},
  {id: 374, simple_code: "AW", icao_code: "DIR", name: "Dirgantara Air Service", callsign: "DIRGANTARA", country: "Indonesia"},
  {id: 375, simple_code: "D8", icao_code: "DJB", name: "Djibouti Airlines", callsign: "DJIBOUTI AIR", country: "Djibouti"},
  {id: 376, simple_code: "Z6", icao_code: "UDN", name: "Dniproavia", callsign: "DNIEPRO", country: "Ukraine"},
  {id: 377, simple_code: "ZD", icao_code: "FDN", name: "Dolphin Air", callsign: "FLYING DOLPHIN", country: "United Arab Emirates"},
  {id: 378, simple_code: "5D", icao_code: "UDC", name: "DonbassAero", callsign: "DONBASS AERO", country: "Ukraine"},
  {id: 379, simple_code: "KA", icao_code: "HDA", name: "Dragonair, Hong Kong Dragon Airlines", callsign: "DRAGON", country: "Hong Kong"},
  {id: 380, simple_code: "KB", icao_code: "DRK", name: "Druk Air", callsign: "ROYAL BHUTAN", country: "Bhutan"},
  {id: 381, simple_code: "9H", icao_code: "DNL", name: "Dutch Antilles Express", callsign: "DUTCH ANTILLES", country: "Netherlands Antilles"},
  {id: 382, simple_code: "BR", icao_code: "EVA", name: "EVA Air", callsign: "EVA", country: "Taiwan"},
  {id: 383, simple_code: "H7", icao_code: "EGU", name: "Eagle Air", callsign: "AFRICAN EAGLE", country: "Uganda"},
  {id: 384, simple_code: "NZ", icao_code: "EAG", name: "Eagle Airways", callsign: "EAGLE", country: "New Zealand"},
  {id: 385, simple_code: "QU", icao_code: "UGX", name: "East African", callsign: "CRANE", country: "Uganda"},
  {id: 386, simple_code: "S9", icao_code: "HSA", name: "East African Safari Air Express", callsign: "DUMA", country: "Kenya"},
  {id: 387, simple_code: "8C", icao_code: "DXH", name: "East Star Air", callsign: "EAST STAR", country: "China"},
  {id: 388, simple_code: "T3", icao_code: "EZE", name: "Eastern Airways", callsign: "EASTFLIGHT", country: "United Kingdom"},
  {id: 389, simple_code: "QF", icao_code: "EAQ", name: "Eastern Australia Airlines", callsign: "EASTERN", country: "Australia"},
  {id: 390, simple_code: "EF", icao_code: "ESY", name: "EasyFly", callsign: "EASYFLY", country: "Colombia"},
  {id: 391, simple_code: "8Y", icao_code: "ECV", name: "Ecuato Guineana", callsign: "ECUATOGUINEA", country: "Equatorial Guinea"},
  {id: 392, simple_code: "WK", icao_code: "EDW", name: "Edelweiss Air", callsign: "EDELWEISS", country: "Switzerland"},
  {id: 393, simple_code: "MS", icao_code: "MSX", name: "EgyptAir Cargo", callsign: "EGYPTAIR CARGO", country: "Egypt"},
  {id: 394, simple_code: "MS", icao_code: "MSE", name: "EgyptAir Express", callsign: "EGYPTAIR EXPRESS", country: "Egypt"},
  {id: 395, simple_code: "MS", icao_code: "MSR", name: "Egyptair", callsign: "EGYPTAIR", country: "Egypt"},
  {id: 396, simple_code: "LY", icao_code: "ELY", name: "El Al Israel Airlines", callsign: "ELAL", country: "Israel"},
  {id: 397, simple_code: "EK", icao_code: "UAE", name: "Emirates Airline", callsign: "EMIRATES", country: "United Arab Emirates"},
  {id: 398, simple_code: "EM", icao_code: "CFS", name: "Empire Airlines", callsign: "EMPIRE", country: "United States", country_alternate: "United States"},
  {id: 399, simple_code: "EU", icao_code: "EEA", name: "Empresa Ecuatoriana De Aviacion", callsign: "ECUATORIANA", country: "Ecuador"},
  {id: 400, simple_code: "G8", icao_code: "ENK", name: "Enkor", callsign: "ENKOR", country: "Russia"},
  {id: 401, simple_code: "7H", icao_code: "ERH", name: "Era Aviation", callsign: "ERAH", country: "United States", country_alternate: "United States"},
  {id: 402, simple_code: "B8", icao_code: "ERT", name: "Eritrean Airlines", callsign: "ERITREAN", country: "Eritrea"},
  {id: 403, simple_code: "E7", icao_code: "ESF", name: "Estafeta Carga Aerea", callsign: "ESTAFETA", country: "Mexico"},
  {id: 404, simple_code: "OV", icao_code: "ELL", name: "Estonian Air", callsign: "ESTONIAN", country: "Estonia"},
  {id: 405, simple_code: "ET", icao_code: "ETH", name: "Ethiopian Airlines", callsign: "ETHIOPIAN", country: "Ethiopia"},
  {id: 406, simple_code: "EY", icao_code: "ETD", name: "Etihad Airways", callsign: "ETIHAD", country: "United Arab Emirates"},
  {id: 407, simple_code: "5B", icao_code: "EAK", name: "Euro-Asia Air", callsign: "EAKAZ", country: "Kazakhstan"},
  {id: 408, simple_code: "6M", icao_code: "EUP", name: "EuroAir", callsign: "EUROSTAR", country: "Greece"},
  {id: 409, simple_code: "UI", icao_code: "ECA", name: "Eurocypria Airlines", callsign: "EUROCYPRIA", country: "Cyprus"},
  {id: 410, simple_code: "GJ", icao_code: "EEU", name: "Eurofly", callsign: "EUROFLY", country: "Italy"},
  {id: 411, simple_code: "K2", icao_code: "ELO", name: "Eurolot", callsign: "EUROLOT", country: "Poland"},
  {id: 412, simple_code: "5O", icao_code: "FPO", name: "Europe Airpost", callsign: "FRENCH POST", country: "France"},
  {id: 413, simple_code: "QY", icao_code: "BCS", name: "European Air Transport", callsign: "EUROTRANS", country: "Germany"},
  {id: 414, simple_code: "EW", icao_code: "EWG", name: "Eurowings", callsign: "EUROWINGS", country: "Germany"},
  {id: 415, simple_code: "EZ", icao_code: "EIA", name: "Evergreen International Airlines", callsign: "EVERGREEN", country: "United States", country_alternate: "United States"},
  {id: 416, simple_code: "3Z", icao_code: "VTS", name: "Everts Air Cargo", callsign: "EVERTS", country: "United States", country_alternate: "United States"},
  {id: 417, simple_code: "OW", icao_code: "EXK", name: "Executive Airlines", callsign: "EXECUTIVE EAGLE", country: "Puerto Rico"},
  {id: 418, simple_code: "8D", icao_code: "EXV", name: "Expo Aviation", callsign: "EXPOAVIA", country: "Sri Lanka"},
  {id: 419, simple_code: "EO", icao_code: "LHN", name: "Express One International", callsign: "LONGHORN", country: "United States", country_alternate: "United States"},
  {id: 420, simple_code: "XE", icao_code: "BTA", name: "ExpressJet Airlines", callsign: "JET LINK", country: "United States", country_alternate: "United States"},
  {id: 421, simple_code: "ZY", icao_code: "EZA", name: "Eznis Airways", callsign: "EZNIS", country: "Mongolia"},
  {id: 422, simple_code: "4A", icao_code: "EYE", name: "FS Air Service", callsign: "SOCKEYE", country: "United States", country_alternate: "United States"},
  {id: 423, simple_code: "F3", icao_code: "FSW", name: "Faso Airways", callsign: "FASO", country: "Burkina Faso"},
  {id: 424, simple_code: "FX", icao_code: "FDX", name: "FedEx Express", callsign: "FEDEX", country: "United States", country_alternate: "United States"},
  {id: 425, simple_code: "AY", icao_code: "FIN", name: "Finnair", callsign: "FINNAIR", country: "Finland"},
  {id: 426, simple_code: "FC", icao_code: "WBA", name: "Finncomm Airlines", callsign: "WESTBIRD", country: "Finland"},
  {id: 427, simple_code: "FY", icao_code: "FFM", name: "Firefly", callsign: "FIREFLY", country: "Malaysia"},
  {id: 428, simple_code: "7F", icao_code: "FAB", name: "First Air", callsign: "FIRST AIR", country: "Canada"},
  {id: 429, simple_code: "JH", icao_code: "FFX", name: "Flex Linhas Aéreas", callsign: "FLEX", country: "Brazil"},
  {id: 430, simple_code: "4Y", icao_code: "TUD", name: "Flight Alaska", callsign: "TUNDRA", country: "United States", country_alternate: "United States"},
  {id: 431, simple_code: "RF", icao_code: "FWL", name: "Florida West International Airways", callsign: "FLO WEST", country: "United States", country_alternate: "United States"},
  {id: 432, simple_code: "6P", icao_code: "IAD", name: "Fly Wex", callsign: "FLYWEX", country: "Italy"},
  {id: 433, simple_code: "5H", icao_code: "FFV", name: "Fly540", callsign: "SWIFT TANGO", country: "Kenya"},
  {id: 434, simple_code: "TE", icao_code: "LIL", name: "FlyLal [Lithuanian Airlines]", callsign: "LITHUANIA AIR", country: "Lithuania"},
  {id: 435, simple_code: "F7", icao_code: "BBO", name: "Flybaboo", callsign: "BABOO", country: "Switzerland"},
  {id: 436, simple_code: "BE", icao_code: "BEE", name: "Flybe", callsign: "JERSEY", country: "United Kingdom"},
  {id: 437, simple_code: "B4", icao_code: "GSM", name: "Flyglobespan", callsign: "GLOBESPAN", country: "United Kingdom"},
  {id: 438, simple_code: "7Y", icao_code: "FCR", name: "Flying Carpet", callsign: "FLYING CARPET", country: "Lebanon"},
  {id: 439, simple_code: "HK", icao_code: "FSC", name: "Four Star Aviation / Four Star Cargo", callsign: "FOUR STAR", country: "Puerto Rico"},
  {id: 440, simple_code: "XD", icao_code: "FHY", name: "Freebird Airlines", callsign: "FREE BIRD", country: "Turkey"},
  {id: 441, simple_code: "FP", icao_code: "FRE", name: "Freedom Air", callsign: "FREEDOM", country: "Guam [United States]"},
  {id: 442, simple_code: "F8", icao_code: "FRL", name: "Freedom Airlines", callsign: "FREEDOM AIR", country: "United States", country_alternate: "United States"},
  {id: 443, simple_code: "F9", icao_code: "FFT", name: "Frontier Airlines", callsign: "FRONTIER FLIGHT", country: "United States", country_alternate: "United States"},
  {id: 444, simple_code: "2F", icao_code: "FTA", name: "Frontier Flying Service", callsign: "FRONTIER-AIR", country: "United States", country_alternate: "United States"},
  {id: 445, simple_code: "GY", icao_code: "GBK", name: "Gabon Airlines", callsign: "GABON AIRLINES", country: "Gabon"},
  {id: 446, simple_code: "GP", icao_code: "GDR", name: "Gadair European Airlines", callsign: "GADAIR", country: "Spain"},
  {id: 447, simple_code: "7O", icao_code: "GAL", name: "Galaxy Air", callsign: "GALAXY", country: "Kyrgyzstan"},
  {id: 448, simple_code: "GA", icao_code: "GIA", name: "Garuda Indonesia", callsign: "INDONESIA", country: "Indonesia"},
  {id: 449, simple_code: "4G", icao_code: "GZP", name: "Gazpromavia", callsign: "GAZPROMAVIA", country: "Russia"},
  {id: 450, simple_code: "A9", icao_code: "TGZ", name: "Georgian Airways", callsign: "TAMAZI", country: "Georgia"},
  {id: 451, simple_code: "QB", icao_code: "GFG", name: "Georgian National Airlines", callsign: "NATIONAL", country: "Georgia"},
  {id: 452, simple_code: "ST", icao_code: "GMI", name: "Germania", callsign: "GERMANIA", country: "Germany"},
  {id: 453, simple_code: "4U", icao_code: "GWI", name: "Germanwings", callsign: "GERMAN WINGS", country: "Germany"},
  {id: 454, simple_code: "GP", icao_code: "GES", name: "Gestair", callsign: "GESTAIR", country: "Spain"},
  {id: 455, simple_code: "G0", icao_code: "GHB", name: "Ghana International Airlines", callsign: "GHANA AIRLINES", country: "Ghana"},
  {id: 456, simple_code: "XH", icao_code: "GSS", name: "Global Supply Systems", callsign: "JET LIFT", country: "United Kingdom"},
  {id: 457, simple_code: "G8", icao_code: "GOW", name: "GoAir", callsign: "GOAIR", country: "India"},
  {id: 458, simple_code: "G7", icao_code: "GJS", name: "GoJet Airlines", callsign: "LINDBERGH", country: "United States", country_alternate: "United States"},
  {id: 459, simple_code: "G3", icao_code: "GLO", name: "Gol Transportes Aéreos", callsign: "GOL TRANSPORTE", country: "Brazil"},
  {id: 460, simple_code: "DC", icao_code: "GAO", name: "Golden Air", callsign: "GOLDEN", country: "Sweden"},
  {id: 461, simple_code: "YD", icao_code: "GOM", name: "Gomelavia", callsign: "GOMEL", country: "Belarus"},
  {id: 462, simple_code: "G1", icao_code: "IKA", name: "Gorkha Airlines", callsign: "GORKHA AIRLINES", country: "Nepal"},
  {id: 463, simple_code: "YR", icao_code: "CVU", name: "Grand Canyon Airlines", callsign: "CANYON VIEW", country: "United States", country_alternate: "United States"},
  {id: 464, simple_code: "GS", icao_code: "GCR", name: "Grand China Express Air", callsign: "CHINA DRAGON", country: "China"},
  {id: 465, simple_code: "GS", icao_code: "GUN", name: "Grant Aviation", callsign: "HOOT", country: "United States", country_alternate: "United States"},
  {id: 466, simple_code: "ZK", icao_code: "GLA", name: "Great Lakes Airlines", callsign: "LAKES AIR", country: "United States", country_alternate: "United States"},
  {id: 467, simple_code: "IJ", icao_code: "GWL", name: "Great Wall Airlines", callsign: "GREAT WALL", country: "China"},
  {id: 468, simple_code: "G6", icao_code: "BSR", name: "Guine Bissaur Airlines", callsign: "BISSAU AIRLINES", country: "Guinea Bissau"},
  {id: 469, simple_code: "J9", icao_code: "GIF", name: "Guinee Airlines", callsign: "GUINEE AIRLINES", country: "Guinea"},
  {id: 470, simple_code: "GF", icao_code: "GBA", name: "Gulf Air Bahrain", callsign: "GULF BAHRAIN", country: "Bahrain"},
  {id: 471, simple_code: "3M", icao_code: "GFT", name: "Gulfstream International Airlines", callsign: "GULF FLIGHT", country: "United States", country_alternate: "United States"},
  {id: 472, simple_code: "H6", icao_code: "HAG", name: "Hageland Aviation Services", callsign: "HAGELAND", country: "United States", country_alternate: "United States"},
  {id: 473, simple_code: "HR", icao_code: "HHN", name: "Hahn Air", callsign: "ROOSTER", country: "Germany"},
  {id: 474, simple_code: "HU", icao_code: "CHH", name: "Hainan Airlines", callsign: "HAINAN", country: "China"},
  {id: 475, simple_code: "4R", icao_code: "HHI", name: "Hamburg International", callsign: "HAMBURG JET", country: "Germany"},
  {id: 476, simple_code: "HA", icao_code: "HAL", name: "Hawaiian Airlines", callsign: "HAWAIIAN", country: "United States", country_alternate: "United States"},
  {id: 477, simple_code: "HN", icao_code: "HVY", name: "Heavylift Cargo Airlines", callsign: "HEAVY CARGO", country: "Australia"},
  {id: 478, simple_code: "YO", icao_code: "MCM", name: "Heli Air Monaco", callsign: "HELI AIR", country: "Monaco"},
  {id: 479, simple_code: "JB", icao_code: "JBA", name: "Helijet", callsign: "HELIJET", country: "Canada"},
  {id: 480, simple_code: "T4", icao_code: "HEJ", name: "Hellas Jet", callsign: "HELLAS JET", country: "Greece"},
  {id: 481, simple_code: "HT", icao_code: "IMP", name: "Hellenic Imperial Airways", callsign: "IMPERIAL", country: "Greece"},
  {id: 482, simple_code: "HW", icao_code: "FHE", name: "Hello", callsign: "FLYHELLO", country: "Switzerland"},
  {id: 483, simple_code: "2L", icao_code: "OAW", name: "Helvetic Airways", callsign: "HELVETIC", country: "Switzerland"},
  {id: 484, simple_code: "DU", icao_code: "HMS", name: "Hemus Air", callsign: "HEMUS AIR", country: "Bulgaria"},
  {id: 485, simple_code: "EO", icao_code: "ALX", name: "Hewa Bora Airways", callsign: "ALLCONGO", country: "Congo, Democratic Republic of the"},
  {id: 486, simple_code: "UD", icao_code: "HER", name: "Hex'Air", callsign: "HEX AIRLINE", country: "France"},
  {id: 487, simple_code: "5K", icao_code: "HFY", name: "Hi Fly", callsign: "SKY FLYER", country: "Portugal"},
  {id: 488, simple_code: "8H", icao_code: "HWY", name: "Highland Airways", callsign: "HIWAY", country: "United Kingdom"},
  {id: 489, simple_code: "HD", icao_code: "ADO", name: "Hokkaido International Airlines", callsign: "AIR DO", country: "Japan"},
  {id: 490, simple_code: "H5", icao_code: "HOA", name: "Hola Airlines", callsign: "HOLA", country: "Spain"},
  {id: 491, simple_code: "N8", icao_code: "CRK", name: "Hong Kong Airlines", callsign: "BAUHINIA", country: "Hong Kong"},
  {id: 492, simple_code: "UO", icao_code: "HKE", name: "Hong Kong Express Airways", callsign: "HONGKONG SHUTTLE", country: "China"},
  {id: 493, simple_code: "QX", icao_code: "QXE", name: "Horizon Air", callsign: "HORIZON AIR", country: "United States", country_alternate: "United States"},
  {id: 494, simple_code: "II", icao_code: "CSQ", name: "IBC Airways", callsign: "CHASQUI", country: "United States", country_alternate: "United States"},
  {id: 495, simple_code: "IB", icao_code: "IBE", name: "Iberia Airlines", callsign: "IBERIA", country: "Spain"},
  {id: 496, simple_code: "TY", icao_code: "IWD", name: "Iberworld", callsign: "IBERWORLD", country: "Spain"},
  {id: 497, simple_code: "FW", icao_code: "IBX", name: "Ibex Airlines", callsign: "IBEX", country: "Japan"},
  {id: 498, simple_code: "X8", icao_code: "ICD", name: "Icaro Air", callsign: "ICARO", country: "Ecuador"},
  {id: 499, simple_code: "FI", icao_code: "ICE", name: "Icelandair", callsign: "ICEAIR", country: "Iceland"},
  {id: 500, simple_code: "IK", icao_code: "ITX", name: "Imair Airlines", callsign: "IMPROTEX", country: "Azerbaijan"},
  {id: 501, simple_code: "6E", icao_code: "IGO", name: "IndiGo Airlines", callsign: "IFLY", country: "India"},
  {id: 502, simple_code: "I8", icao_code: "IDA", name: "Indonesia Air Transport", callsign: "INTRA", country: "Indonesia"},
  {id: 503, simple_code: "QZ", icao_code: "AWQ", name: "Indonesia AirAsia", callsign: "WAGON AIR", country: "Indonesia"},
  {id: 504, simple_code: "7I", icao_code: "INC", name: "Insel Air", callsign: "INSELAIR", country: "Netherlands Antilles"},
  {id: 505, simple_code: "3L", icao_code: "ISK", name: "InterSky", callsign: "INTERSKY", country: "Austria"},
  {id: 506, simple_code: "D6", icao_code: "ILN", name: "Interair South Africa", callsign: "INLINE", country: "South Africa"},
  {id: 507, simple_code: "4O", icao_code: "AIJ", name: "Interjet", callsign: "INTERJET", country: "Mexico"},
  {id: 508, simple_code: "ID", icao_code: "ITK", name: "Interlink Airlines", callsign: "INTERLINK", country: "South Africa"},
  {id: 509, simple_code: "6I", icao_code: "IBZ", name: "International Business Air", callsign: "INTERBIZ", country: "Sweden"},
  {id: 510, simple_code: "I4", icao_code: "FWA", name: "Interstate Airline", callsign: "FREEWAYAIR", country: "Netherlands"},
  {id: 511, simple_code: "IR", icao_code: "IRA", name: "Iran Air", callsign: "IRANAIR", country: "Iran"},
  {id: 512, simple_code: "B9", icao_code: "IRB", name: "Iran Air Tours", callsign: "", country: "Iran"},
  {id: 513, simple_code: "EP", icao_code: "IRC", name: "Iran Aseman Airlines", callsign: "IAA", country: "Iran"},
  {id: 514, simple_code: "IA", icao_code: "IAW", name: "Iraqi Airways", callsign: "IRAQI", country: "Iraq"},
  {id: 515, simple_code: "WP", icao_code: "MKU", name: "Island Air", callsign: "MOKU", country: "United States", country_alternate: "United States"},
  {id: 516, simple_code: "2S", icao_code: "SDY", name: "Island Express", callsign: "SANDY ISLE", country: "United States", country_alternate: "United States"},
  {id: 517, simple_code: "IF", icao_code: "ISW", name: "Islas Airways", callsign: "PINTADERA", country: "Spain"},
  {id: 518, simple_code: "WC", icao_code: "ISV", name: "Islena De Inversiones", callsign: "", country: "Honduras"},
  {id: 519, simple_code: "5Y", icao_code: "IOS", name: "Isles of Scilly Skybus", callsign: "SCILLONIA", country: "United Kingdom"},
  {id: 520, simple_code: "6H", icao_code: "ISR", name: "Israir Airlines", callsign: "ISRAIR", country: "Israel"},
  {id: 521, simple_code: "9X", icao_code: "ACL", name: "ItAli Airlines", callsign: "ITALI", country: "Italy"},
  {id: 522, simple_code: "GI", icao_code: "IKA", name: "Itek Air", callsign: "ITEK-AIR", country: "Kyrgyzstan"},
  {id: 523, simple_code: "H9", icao_code: "IZM", name: "Izair", callsign: "IZMIR", country: "Turkey"},
  {id: 524, simple_code: "XM", icao_code: "JLJ", name: "J-Air", callsign: "J-AIR", country: "Japan"},
  {id: 525, simple_code: "JC", icao_code: "JEX", name: "JAL Express", callsign: "JANEX", country: "Japan"},
  {id: 526, simple_code: "JO", icao_code: "JAZ", name: "JALways", callsign: "JALWAYS", country: "Japan"},
  {id: 527, simple_code: "JI", icao_code: "JAE", name: "Jade Cargo International", callsign: "JADE CARGO", country: "China"},
  {id: 528, simple_code: "3X", icao_code: "JAC", name: "Japan Air Commuter", callsign: "COMMUTER", country: "Japan"},
  {id: 529, simple_code: "JL", icao_code: "JAL", name: "Japan Airlines", callsign: "JAPANAIR", country: "Japan"},
  {id: 530, simple_code: "NU", icao_code: "JTA", name: "Japan Transocean Air", callsign: "JAI OCEAN", country: "Japan"},
  {id: 531, simple_code: "JU", icao_code: "JAT", name: "Jat Airways", callsign: "JAT", country: "Serbia"},
  {id: 532, simple_code: "J9", icao_code: "JZR", name: "Jazeera Airways", callsign: "JAZEERA", country: "Kuwait"},
  {id: 533, simple_code: "7C", icao_code: "JJA", name: "Jeju Air", callsign: "JEJU AIR", country: "South Korea"},
  {id: 534, simple_code: "O2", icao_code: "JEA", name: "Jet Air", callsign: "JETA", country: "Poland"},
  {id: 535, simple_code: "9W", icao_code: "JAI", name: "Jet Airways", callsign: "JET AIRWAYS", country: "India"},
  {id: 536, simple_code: "PP", icao_code: "PJS", name: "Jet Aviation", callsign: "JET AVIATION", country: "Switzerland"},
  {id: 537, simple_code: "LS", icao_code: "EXS", name: "Jet2.com", callsign: "CHANNEX", country: "United Kingdom"},
  {id: 538, simple_code: "8J", icao_code: "JFU", name: "Jet4you", callsign: "ARGAN", country: "Morocco"},
  {id: 539, simple_code: "B6", icao_code: "JBU", name: "JetBlue Airways", callsign: "JETBLUE", country: "United States", country_alternate: "United States"},
  {id: 540, simple_code: "QF", icao_code: "QNZ", name: "JetConnect", callsign: "QANTAS", country: "New Zealand"},
  {id: 541, simple_code: "S2", icao_code: "JLL", name: "JetLite", callsign: "LITE JET", country: "India"},
  {id: 542, simple_code: "JF", icao_code: "JAF", name: "Jetairfly", callsign: "BEAUTY", country: "Belgium"},
  {id: 543, simple_code: "0J", icao_code: "JCS", name: "Jetclub", callsign: "JETCLUB", country: "Switzerland"},
  {id: 544, simple_code: "JQ", icao_code: "JST", name: "Jetstar Airways", callsign: "JETSTAR", country: "Australia"},
  {id: 545, simple_code: "3K", icao_code: "JSA", name: "Jetstar Asia Airways", callsign: "JETSTAR ASIA", country: "Singapore"},
  {id: 546, simple_code: "BL", icao_code: "PIC", name: "Jetstar Pacific", callsign: "PACIFIC AIRLINES", country: "Vietnam"},
  {id: 547, simple_code: "JX", icao_code: "JEC", name: "Jett8 Airlines Cargo", callsign: "TAIPAN", country: "Singapore"},
  {id: 548, simple_code: "LJ", icao_code: "JNA", name: "Jin Air", callsign: "JIN AIR", country: "South Korea"},
  {id: 549, simple_code: "R5", icao_code: "JAV", name: "Jordan Aviation", callsign: "JORDAN AVIATION", country: "Jordan"},
  {id: 550, simple_code: "6J", icao_code: "JUB", name: "Jubba Airways", callsign: "JUBBA", country: "Somalia"},
  {id: 551, simple_code: "HO", icao_code: "DKH", name: "Juneyao Airlines", callsign: "AIR JUNEYAO", country: "China"},
  {id: 552, simple_code: "XC", icao_code: "KDC", name: "KD Air", callsign: "KAY DEE", country: "Canada"},
  {id: 553, simple_code: "KD", icao_code: "KNI", name: "KD Avia", callsign: "KALININGRAD AIR", country: "Russia"},
  {id: 554, simple_code: "WA", icao_code: "KLC", name: "KLM Cityhopper", callsign: "CITY", country: "Netherlands"},
  {id: 555, simple_code: "KL", icao_code: "KLM", name: "KLM Royal Dutch Airlines", callsign: "KLM", country: "Netherlands"},
  {id: 556, simple_code: "N2", icao_code: "QNK", name: "Kabo Air", callsign: "KABO", country: "Nigeria"},
  {id: 557, simple_code: "K4", icao_code: "CKS", name: "Kalitta Air", callsign: "CONNIE", country: "United States", country_alternate: "United States"},
  {id: 558, simple_code: "K9", icao_code: "KFS", name: "Kalitta Charters", callsign: "KALITTA", country: "United States", country_alternate: "United States"},
  {id: 559, simple_code: "RQ", icao_code: "KMF", name: "Kam Air", callsign: "KAMGAR", country: "Afghanistan"},
  {id: 560, simple_code: "E2", icao_code: "KMP", name: "Kampuchea Airlines", callsign: "KAMPUCHEA", country: "Cambodia"},
  {id: 561, simple_code: "5R", icao_code: "KAJ", name: "Karthago Airlines", callsign: "KARTHAGO", country: "Tunesia"},
  {id: 562, simple_code: "3Y", icao_code: "KAE", name: "Kartika Airlines", callsign: "KARTIKA", country: "Indonesia"},
  {id: 563, simple_code: "KV", icao_code: "MVD", name: "Kavminvodyavia", callsign: "AIR MINVODY", country: "Russia"},
  {id: 564, simple_code: "KW", icao_code: "KFA", name: "Kelowna Flightcraft Air Charter", callsign: "FLIGHTCRAFT", country: "Canada"},
  {id: 565, simple_code: "M5", icao_code: "KEN", name: "Kenmore Air", callsign: "KENMORE", country: "United States", country_alternate: "United States"},
  {id: 566, simple_code: "4K", icao_code: "KBA", name: "Kenn Borek Air", callsign: "BOREK AIR", country: "Canada"},
  {id: 567, simple_code: "KQ", icao_code: "KQA", name: "Kenya Airways", callsign: "KENYA", country: "Kenya"},
  {id: 568, simple_code: "BZ", icao_code: "KEE", name: "Keystone Air Services", callsign: "KEYSTONE", country: "Canada"},
  {id: 569, simple_code: "X9", icao_code: "KHO", name: "Khors Aircompany", callsign: "AIRCOMPANY KHORS", country: "Ukraine"},
  {id: 570, simple_code: "IT", icao_code: "KFR", name: "Kingfisher Airlines", callsign: "KINGFISHER", country: "India"},
  {id: 571, simple_code: "DN", icao_code: "DKN", name: "Kingfisher Red", callsign: "DECCAN", country: "India"},
  {id: 572, simple_code: "Y9", icao_code: "IRK", name: "Kish Air", callsign: "KISHAIR", country: "Iran"},
  {id: 573, simple_code: "7K", icao_code: "KGL", name: "Kogalymavia", callsign: "KOGALYM", country: "Russia"},
  {id: 574, simple_code: "K7", icao_code: "KBR", name: "KoralBlue Airlines", callsign: "KORAL BLUE", country: "Egypt"},
  {id: 575, simple_code: "KE", icao_code: "KAL", name: "Korean Air", callsign: "KOREANAIR", country: "South Korea"},
  {id: 576, simple_code: "GW", icao_code: "KIL", name: "Kuban Airlines", callsign: "AIR KUBAN", country: "Russia"},
  {id: 577, simple_code: "VD", icao_code: "KPA", name: "Kunpeng Airlines", callsign: "KUN PENG", country: "China"},
  {id: 578, simple_code: "KU", icao_code: "KAC", name: "Kuwait Airways", callsign: "KUWAITI", country: "Kuwait"},
  {id: 579, simple_code: "GO", icao_code: "KZU", name: "Kuzu Airlines Cargo", callsign: "KUZU CARGO", country: "Turkey"},
  {id: 580, simple_code: "QH", icao_code: "LYN", name: "Kyrgyzstan", callsign: "ALTYN AVIA", country: "Kyrgyzstan"},
  {id: 581, simple_code: "R8", icao_code: "KGA", name: "Kyrgyzstan Airlines", callsign: "KYRGYZ", country: "Kyrgyzstan"},
  {id: 582, simple_code: "A0", icao_code: "AVI", name: "L'Avion", callsign: "ELYSAIR", country: "France"},
  {id: 583, simple_code: "JF", icao_code: "LAB", name: "L.A.B. Flying Service", callsign: "LAB", country: "United States", country_alternate: "United States"},
  {id: 584, simple_code: "LR", icao_code: "LRC", name: "LACSA", callsign: "LACSA", country: "Costa Rica"},
  {id: 585, simple_code: "5U", icao_code: "LDE", name: "LADE - Líneas Aéreas del Estado", callsign: "LADE", country: "Argentina"},
  {id: 586, simple_code: "KG", icao_code: "BNX", name: "LAI - Linea Aerea IAACA", callsign: "AIR BARINAS", country: "Venezuela"},
  {id: 587, simple_code: "LM", icao_code: "LAM", name: "LAM Mozambique Airlines", callsign: "MOZAMBIQUE", country: "Mozambique"},
  {id: 588, simple_code: "LA", icao_code: "LAN", name: "LAN Airlines", callsign: "LAN", country: "Chile"},
  {id: 589, simple_code: "4M", icao_code: "DSM", name: "LAN Argentina", callsign: "LAN AIR", country: "Argentina"},
  {id: 590, simple_code: "UC", icao_code: "LCO", name: "LAN Cargo", callsign: "LAN CARGO", country: "Chile"},
  {id: 591, simple_code: "XL", icao_code: "LNE", name: "LAN Ecuador", callsign: "LAN ECUADOR", country: "Ecuador"},
  {id: 592, simple_code: "LU", icao_code: "LXP", name: "LAN Express", callsign: "LANEX", country: "Chile"},
  {id: 593, simple_code: "LP", icao_code: "LPE", name: "LAN Peru", callsign: "LANPERU", country: "Peru"},
  {id: 594, simple_code: "8z", icao_code: "LER", name: "LASER Airlines", callsign: "LASER", country: "Venezuela"},
  {id: 595, simple_code: "LO", icao_code: "LOT", name: "LOT Polish Airlines", callsign: "POLLOT", country: "Poland"},
  {id: 596, simple_code: "LT", icao_code: "LTU", name: "LTU International", callsign: "LTU", country: "Germany"},
  {id: 597, simple_code: "IK", icao_code: "LKN", name: "Lankair", callsign: "LANKAIR", country: "Sri Lanka"},
  {id: 598, simple_code: "QV", icao_code: "LAO", name: "Lao Airlines", callsign: "LAO", country: "Laos"},
  {id: 599, simple_code: "NG", icao_code: "LDA", name: "Lauda Air", callsign: "LAUDA AIR", country: "Austria"},
  {id: 600, simple_code: "LI", icao_code: "LIA", name: "Leeward Islands Air Transport", callsign: "LIAT", country: "Antigua and Barbuda"},
  {id: 601, simple_code: "LN", icao_code: "LAA", name: "Libyan Airlines", callsign: "LIBAIR", country: "Libya"},
  {id: 602, simple_code: "4V", icao_code: "LCG", name: "Lignes Aériennes Congolaises", callsign: "CONGOLAISE", country: "Congo, Democratic Republic of the"},
  {id: 603, simple_code: "LD", icao_code: "TUY", name: "Linea Turistica Aerotuy", callsign: "AEREOTUY", country: "Venezuela"},
  {id: 604, simple_code: "JT", icao_code: "LNI", name: "Lion Air [Lion Mentari Airlines ]", callsign: "LION INTER", country: "Indonesia"},
  {id: 605, simple_code: "LM", icao_code: "LVG", name: "Livingston Energy Flight", callsign: "LIVINGSTON", country: "Italy"},
  {id: 606, simple_code: "LC", icao_code: "LOG", name: "Loganair", callsign: "LOGAN", country: "United Kingdom"},
  {id: 607, simple_code: "T2", icao_code: "TAS", name: "Lotus Air", callsign: "LOTUS FLOWER", country: "Egypt"},
  {id: 608, simple_code: "8L", icao_code: "LKE", name: "Lucky Air", callsign: "LUCKY AIR", country: "China"},
  {id: 609, simple_code: "HE", icao_code: "LGW", name: "Luftfahrtgesellschaft Walter", callsign: "WALTER", country: "Germany"},
  {id: 610, simple_code: "LH", icao_code: "DLH", name: "Lufthansa", callsign: "LUFTHANSA", country: "Germany"},
  {id: 611, simple_code: "LH", icao_code: "GEC", name: "Lufthansa Cargo", callsign: "LUFTHANSA CARGO", country: "Germany"},
  {id: 612, simple_code: "CL", icao_code: "CLH", name: "Lufthansa CityLine", callsign: "HANSALINE", country: "Germany"},
  {id: 613, simple_code: "DV", icao_code: "LTF", name: "Lufttaxi Fluggesellschaft", callsign: "GARFIELD", country: "Germany"},
  {id: 614, simple_code: "L5", icao_code: "LTR", name: "Lufttransport", callsign: "LUFT TRANSPORT", country: "Norway"},
  {id: 615, simple_code: "LG", icao_code: "LGL", name: "Luxair", callsign: "LUXAIR", country: "Luxembourg"},
  {id: 616, simple_code: "5V", icao_code: "UKW", name: "Lviv Airlines", callsign: "UKRAINE WEST", country: "Ukraine"},
  {id: 617, simple_code: "L2", icao_code: "LYC", name: "Lynden Air Cargo", callsign: "LYNDEN", country: "United States", country_alternate: "United States"},
  {id: 618, simple_code: "L4", icao_code: "SSX", name: "Lynx Aviation", callsign: "SHASTA", country: "United States", country_alternate: "United States"},
  {id: 619, simple_code: "MH", icao_code: "MAS", name: "MASkargo [Malaysia Airlines Kargo]", callsign: "MALAYSIAN CARGO", country: "Malaysia"},
  {id: 620, simple_code: "MH", icao_code: "MWG", name: "MASwings", callsign: "MASWINGS", country: "Malaysia"},
  {id: 621, simple_code: "IN", icao_code: "MAK", name: "MAT Macedonian Airlines", callsign: "MAKAVIO", country: "Macedonia"},
  {id: 622, simple_code: "OM", icao_code: "MGL", name: "MIAT Mongolian Airlines", callsign: "MONGOL AIR", country: "Mongolia"},
  {id: 623, simple_code: "7G", icao_code: "MKA", name: "MK Airlines", callsign: "KRUGER AIR", country: "United Kingdom"},
  {id: 624, simple_code: "MB", icao_code: "MNB", name: "MNG Airlines", callsign: "BLACK SEA", country: "Turkey"},
  {id: 625, simple_code: "CC", icao_code: "MCK", name: "Macair Airlines", callsign: "MACAIR", country: "Australia"},
  {id: 626, simple_code: "UJ", icao_code: "GMT", name: "Magnicharters", callsign: "GRUPOMONTERREY", country: "Mexico"},
  {id: 627, simple_code: "W5", icao_code: "IRM", name: "Mahan Air", callsign: "MAHAN AIR", country: "Iran"},
  {id: 628, simple_code: "MH", icao_code: "MAS", name: "Malaysia Airlines", callsign: "MALAYSIAN", country: "Malaysia"},
  {id: 629, simple_code: "Q2", icao_code: "DQA", name: "Maldivian", callsign: "ISLAND AVIATION", country: "Maldives"},
  {id: 630, simple_code: "MV", icao_code: "MAT", name: "Maldivian Air Taxi", callsign: "MALDIVIAN", country: "Maldives"},
  {id: 631, simple_code: "MA", icao_code: "MAH", name: "Malev Hungarian Airlines", callsign: "MALEV", country: "Hungary"},
  {id: 632, simple_code: "Z8", icao_code: "MTZ", name: "Mali Airways", callsign: "MALI AIRWAYS", country: "Mali"},
  {id: 633, simple_code: "TF", icao_code: "SCW", name: "Braathens Regional Aviation", callsign: "SCANWINGS", country: "Sweden"},
  {id: 634, simple_code: "RI", icao_code: "MDL", name: "Mandala Airlines", callsign: "MANDALA", country: "Indonesia"},
  {id: 635, simple_code: "AE", icao_code: "MDA", name: "Mandarin Airlines", callsign: "MANDARIN", country: "Taiwan"},
  {id: 636, simple_code: "JE", icao_code: "MNO", name: "Mango", callsign: "TULCA", country: "South Africa"},
  {id: 637, simple_code: "6V", icao_code: "MRW", name: "Mars RK", callsign: "AVIAMARS", country: "Ukraine"},
  {id: 638, simple_code: "M7", icao_code: "MSL", name: "Marsland Aviation", callsign: "MARSLANDAIR", country: "Sudan"},
  {id: 639, simple_code: "MP", icao_code: "MPH", name: "Martinair", callsign: "MARTINAIR", country: "Netherlands"},
  {id: 640, simple_code: "M7", icao_code: "MAA", name: "MasAir", callsign: "MAS CARGA", country: "Mexico"},
  {id: 641, simple_code: "L6", icao_code: "MAI", name: "Mauritania Airlines International", callsign: "", country: "Mauritania"},
  {id: 642, simple_code: "8M", icao_code: "MXL", name: "Maxair", callsign: "MAXAIR", country: "Sweden"},
  {id: 643, simple_code: "MW", icao_code: "MYD", name: "Maya Island Air", callsign: "MYLAND", country: "Belize"},
  {id: 644, simple_code: "N5", icao_code: "MDM", name: "Medavia", callsign: "MEDAVIA", country: "Malta"},
  {id: 645, simple_code: "IM", icao_code: "MNJ", name: "Menajet", callsign: "MENAJET", country: "Lebanon"},
  {id: 646, simple_code: "IG", icao_code: "ISS", name: "Meridiana", callsign: "MERAIR", country: "Italy"},
  {id: 647, simple_code: "MZ", icao_code: "MNA", name: "Merpati NUnited Statesntara Airlines", callsign: "MERPATI", country: "Indonesia"},
  {id: 648, simple_code: "YV", icao_code: "ASH", name: "Mesa Airlines", callsign: "AIR SHUTTLE", country: "United States", country_alternate: "United States"},
  {id: 649, simple_code: "XJ", icao_code: "MES", name: "Mesaba Airlines", callsign: "MESABA", country: "United States", country_alternate: "United States"},
  {id: 650, simple_code: "MX", icao_code: "MXA", name: "Mexicana", callsign: "MEXICANA", country: "Mexico"},
  {id: 651, simple_code: "QA", icao_code: "CBE", name: "MexicanaClick", callsign: "CLICK", country: "Mexico"},
  {id: 652, simple_code: "GL", icao_code: "BSK", name: "Miami Air International", callsign: "BISCAYNE", country: "United States", country_alternate: "United States"},
  {id: 653, simple_code: "7Y", icao_code: "NYL", name: "Mid Airlines", callsign: "NILE", country: "Sudan"},
  {id: 654, simple_code: "ME", icao_code: "MEA", name: "Middle East Airlines", callsign: "CEDAR JET", country: "Lebanon"},
  {id: 655, simple_code: "JI", icao_code: "MDW", name: "Midway Airlines", callsign: "MIDWAY", country: "United States", country_alternate: "United States"},
  {id: 656, simple_code: "MY", icao_code: "MEP", name: "Midwest Airlines", callsign: "MIDEX", country: "Egypt"},
  {id: 657, simple_code: "MJ", icao_code: "MLR", name: "Mihin Lanka", callsign: "MIHIN LANKA", country: "Sri Lanka"},
  {id: 658, simple_code: "MW", icao_code: "BUG", name: "Mokulele Airlines", callsign: "SPEEDBUGGY", country: "United States", country_alternate: "United States"},
  {id: 659, simple_code: "2M", icao_code: "MDV", name: "Moldavian Airlines", callsign: "MOLDAVIAN", country: "Moldova"},
  {id: 660, simple_code: "ZB", icao_code: "MON", name: "Monarch Airlines", callsign: "MONARCH", country: "United Kingdom"},
  {id: 661, simple_code: "YM", icao_code: "MGX", name: "Montenegro Airlines", callsign: "MONTAIR", country: "Montenegro"},
  {id: 662, simple_code: "3R", icao_code: "GAI", name: "Moskovia Airlines", callsign: "GROMOV AIRLINE", country: "Russia"},
  {id: 663, simple_code: "M9", icao_code: "MSI", name: "Motor Sich", callsign: "MOTOR SICH", country: "Ukraine"},
  {id: 664, simple_code: "NM", icao_code: "NZM", name: "Mount Cook Airlines", callsign: "MOUNTCOOK", country: "New Zealand"},
  {id: 665, simple_code: "8I", icao_code: "MYW", name: "MyAir", callsign: "FRANKY", country: "Italy"},
  {id: 666, simple_code: "UB", icao_code: "UBA", name: "Myanma Airways", callsign: "UNIONAIR", country: "Myanmar [Burma]"},
  {id: 667, simple_code: "8M", icao_code: "UBA", name: "Myanmar Airways International", callsign: "UNIONAIR", country: "Myanmar [Burma]"},
  {id: 668, simple_code: "DV", icao_code: "ACK", name: "Nantucket Airlines", callsign: "ACK AIR", country: "United States", country_alternate: "United States"},
  {id: 669, simple_code: "XY", icao_code: "KNE", name: "Nas Air", callsign: "NAS EXPRESS", country: "Saudi Arabia"},
  {id: 670, simple_code: "UE", icao_code: "NAS", name: "Nasair", callsign: "NASAIRWAYS", country: "Eritrea"},
  {id: 671, simple_code: "5M", icao_code: "MUA", name: "National Airlines", callsign: "MURRAY AIR", country: "United States", country_alternate: "United States"},
  {id: 672, simple_code: "YJ", icao_code: "NTN", name: "National Airways", callsign: "NATCHAIR", country: "South Africa"},
  {id: 673, simple_code: "NC", icao_code: "NJS", name: "National Jet Systems", callsign: "NATIONAL JET", country: "Australia"},
  {id: 674, simple_code: "5C", icao_code: "NRR", name: "Nature Air", callsign: "NATUREAIR", country: "Costa Rica"},
  {id: 675, simple_code: "ON", icao_code: "RON", name: "Nauru Air Corporation", callsign: "AIR NAURU", country: "Nauru"},
  {id: 676, simple_code: "ZN", icao_code: "NAY", name: "Navegacion y Servicios Aéreos Canarios", callsign: "NAYSA", country: "Spain"},
  {id: 677, simple_code: "NO", icao_code: "NOS", name: "Neos", callsign: "MOONFLOWER", country: "Italy"},
  {id: 678, simple_code: "RA", icao_code: "RNA", name: "Nepal Airlines", callsign: "ROYAL NEPAL", country: "Nepal"},
  {id: 679, simple_code: "1I", icao_code: "EJA", name: "NetJets", callsign: "EXECJET", country: "United States", country_alternate: "United States"},
  {id: 680, simple_code: "1I", icao_code: "NJE", name: "NetJets Europe", callsign: "FRACTION", country: "Portugal"},
  {id: 681, simple_code: "EJ", icao_code: "NEA", name: "New United Kingdom Airlines", callsign: "NEW United Kingdom", country: "United States", country_alternate: "United States"},
  {id: 682, simple_code: "2N", icao_code: "NTJ", name: "Nextjet", callsign: "NEXTJET", country: "Sweden"},
  {id: 683, simple_code: "HG", icao_code: "NLY", name: "Niki", callsign: "FLYNIKI", country: "Austria"},
  {id: 684, simple_code: "KZ", icao_code: "NCA", name: "Nippon Cargo Airlines", callsign: "NIPPON CARGO", country: "Japan"},
  {id: 685, simple_code: "DD", icao_code: "NOK", name: "Nok Air", callsign: "NOK AIR", country: "Thailand"},
  {id: 686, simple_code: "6N", icao_code: "NRD", name: "Nordic Airways", callsign: "NORTH RIDER", country: "Sweden"},
  {id: 687, simple_code: "6N", icao_code: "NRD", name: "Nordic Regional", callsign: "NORTH RIDER", country: "Sweden"},
  {id: 688, simple_code: "NA", icao_code: "NAO", name: "North American Airlines", callsign: "NORTH AMERICAN", country: "United States", country_alternate: "United States"},
  {id: 689, simple_code: "M3", icao_code: "NFA", name: "North Flying", callsign: "NORTH FLYING", country: "Denmark"},
  {id: 690, simple_code: "HW", icao_code: "NWL", name: "North-Wright Airways", callsign: "NORTHWRIGHT", country: "Canada"},
  {id: 691, simple_code: "NC", icao_code: "NAC", name: "Northern Air Cargo", callsign: "YUKON", country: "United States", country_alternate: "United States"},
  {id: 692, simple_code: "NW", icao_code: "NWA", name: "Northwest Airlines", callsign: "NORTHWEST", country: "United States", country_alternate: "United States"},
  {id: 693, simple_code: "FY", icao_code: "NWR", name: "Northwest Regional Airlines", callsign: "", country: "Australia"},
  {id: 694, simple_code: "J3", icao_code: "PLR", name: "Northwestern Air", callsign: "POLARIS", country: "Canada"},
  {id: 695, simple_code: "DY", icao_code: "NAX", name: "Norwegian Air Shuttle", callsign: "NOR SHUTTLE", country: "Norway"},
  {id: 696, simple_code: "BJ", icao_code: "LBT", name: "Nouvelair", callsign: "NOUVELAIR", country: "Tunesia"},
  {id: 697, simple_code: "M4", icao_code: "NOV", name: "Nova Airline", callsign: "NOVANILE", country: "Sudan"},
  {id: 698, simple_code: "1I", icao_code: "NVR", name: "Novair", callsign: "NAVIGATOR", country: "Sweden"},
  {id: 699, simple_code: "OL", icao_code: "OLT", name: "OLT - Ostfriesische Lufttransport", callsign: "OLTRA", country: "Germany"},
  {id: 700, simple_code: "O6", icao_code: "ONE", name: "Oceanair", callsign: "OCEANAIR", country: "Brazil"},
  {id: 701, simple_code: "5K", icao_code: "ODS", name: "Odessa Airlines", callsign: "ODESSA AIR", country: "Ukraine"},
  {id: 702, simple_code: "BK", icao_code: "OKA", name: "Okay Airways", callsign: "OKAYJET", country: "China"},
  {id: 703, simple_code: "OA", icao_code: "OAL", name: "Olympic Airlines", callsign: "OLYMPIC", country: "Greece"},
  {id: 704, simple_code: "WY", icao_code: "OMA", name: "Oman Air", callsign: "OMAN AIR", country: "Oman"},
  {id: 705, simple_code: "OY", icao_code: "OAE", name: "Omni Air International", callsign: "OMNI-EXPRESS", country: "United States", country_alternate: "United States"},
  {id: 706, simple_code: "OX", icao_code: "OTG", name: "One-Two-GO Airlines", callsign: "THAI EXPRESS", country: "Thailand"},
  {id: 707, simple_code: "8Q", icao_code: "OHY", name: "Onur Air", callsign: "ONUR AIR", country: "Turkey"},
  {id: 708, simple_code: "EC", icao_code: "BOS", name: "OpenSkies", callsign: "MISTRAL", country: "United Kingdom"},
  {id: 709, simple_code: "R2", icao_code: "ORB", name: "Orenair [Orenburg Airlines]", callsign: "ORENBURG", country: "Russia"},
  {id: 710, simple_code: "OX", icao_code: "OEA", name: "Orient Thai Airlines", callsign: "ORIENT THAI", country: "Thailand"},
  {id: 711, simple_code: "OJ", icao_code: "OLA", name: "Overland Airways", callsign: "OVERLAND", country: "Nigeria"},
  {id: 712, simple_code: "O7", icao_code: "OZJ", name: "Ozjet Airlines", callsign: "AUSJET", country: "Australia"},
  {id: 713, simple_code: "PV", icao_code: "PNR", name: "PAN Air", callsign: "SKYJET", country: "Spain"},
  {id: 714, simple_code: "9Q", icao_code: "PBA", name: "PB Air", callsign: "PEEBEE AIR", country: "Thailand"},
  {id: 715, simple_code: "PU", icao_code: "PUA", name: "PLUNA", callsign: "PLUNA", country: "Uruguay"},
  {id: 716, simple_code: "U4", icao_code: "PMT", name: "PMTair", callsign: "MULTITRADE", country: "Cambodia"},
  {id: 717, simple_code: "US", icao_code: "JIA", name: "PSA Airlines", callsign: "BLUE STREAK", country: "United States", country_alternate: "United States"},
  {id: 718, simple_code: "Y5", icao_code: "PCE", name: "Pace Airlines", callsign: "PACE", country: "United States", country_alternate: "United States"},
  {id: 719, simple_code: "DJ", icao_code: "PBN", name: "Pacific Blue Airlines", callsign: "BLUEBIRD", country: "New Zealand"},
  {id: 720, simple_code: "8P", icao_code: "PCO", name: "Pacific Coastal Airline", callsign: "PASCO", country: "Canada"},
  {id: 721, simple_code: "Q8", icao_code: "PEC", name: "Pacific East Asia Cargo Airlines", callsign: "PAC-EAST CARGO", country: "Philippines"},
  {id: 722, simple_code: "LW", icao_code: "NMI", name: "Pacific Wings", callsign: "TSUNAMI", country: "United States", country_alternate: "United States"},
  {id: 723, simple_code: "PK", icao_code: "PIA", name: "Pakistan International Airlines", callsign: "PAKISTAN", country: "Pakistan"},
  {id: 724, simple_code: "PF", icao_code: "PNW", name: "Palestinian Airlines", callsign: "PALESTINIAN", country: "Egypt"},
  {id: 725, simple_code: "NR", icao_code: "PIR", name: "Pamir Airways", callsign: "PAMIR", country: "Afghanistan"},
  {id: 726, simple_code: "PQ", icao_code: "PNF", name: "Panafrican Airways", callsign: "PANWAYS", country: "Ivory Coast"},
  {id: 727, simple_code: "P8", icao_code: "PTN", name: "Pantanal Linhas Aereas Sul-Matogrossenses", callsign: "PANTANAL", country: "Brazil"},
  {id: 728, simple_code: "I7", icao_code: "PMW", name: "Paramount Airways", callsign: "PARAWAY", country: "India"},
  {id: 729, simple_code: "2F", icao_code: "IRP", name: "Payam Air", callsign: "PAYAMAIR", country: "Iran"},
  {id: 730, simple_code: "HP", icao_code: "HPA", name: "Pearl Airways", callsign: "PEARL AIRWAYS", country: "Haiti"},
  {id: 731, simple_code: "H9", icao_code: "PGT", name: "Pegasus Airlines", callsign: "SUNTURK", country: "Turkey"},
  {id: 732, simple_code: "7V", icao_code: "PDF", name: "Pelican Air Services", callsign: "PELICAN AIRWAYS", country: "South Africa"},
  {id: 733, simple_code: "6D", icao_code: "PAS", name: "Pelita Air Service", callsign: "PELITA", country: "Indonesia"},
  {id: 734, simple_code: "KS", icao_code: "PEN", name: "PenAir [Peninsula Airways]", callsign: "PENINSULA", country: "United States", country_alternate: "United States"},
  {id: 735, simple_code: "PE", icao_code: "PEV", name: "People's Viennaline", callsign: "PEOPLES", country: "Austria"},
  {id: 736, simple_code: "4B", icao_code: "PAG", name: "Perimeter Aviation [Perimeter Airlines]", callsign: "PERIMETER", country: "Canada"},
  {id: 737, simple_code: "P9", icao_code: "PGP", name: "Perm Airlines", callsign: "PERM AIR", country: "Russia"},
  {id: 738, simple_code: "PR", icao_code: "PAL", name: "Philippine Airlines", callsign: "PHILIPPINE", country: "Philippines"},
  {id: 739, simple_code: "9R", icao_code: "VAP", name: "Phuket Air", callsign: "PHUKET AIR", country: "Thailand"},
  {id: 740, simple_code: "PI", icao_code: "PDT", name: "Piedmont Airlines", callsign: "PIEDMONT", country: "United States", country_alternate: "United States"},
  {id: 741, simple_code: "9E", icao_code: "FLG", name: "Pinnacle Airlines", callsign: "FLAGSHIP", country: "United States", country_alternate: "United States"},
  {id: 742, simple_code: "PO", icao_code: "PAC", name: "Polar Air Cargo", callsign: "POLAR", country: "United States", country_alternate: "United States"},
  {id: 743, simple_code: "YQ", icao_code: "POT", name: "Polet Airlines", callsign: "POLET", country: "Russia"},
  {id: 744, simple_code: "PH", icao_code: "PAO", name: "Polynesian Airlines", callsign: "POLYNESIAN", country: "Samoa"},
  {id: 745, simple_code: "PD", icao_code: "POE", name: "Porter Airlines", callsign: "PORTER AIR", country: "Canada"},
  {id: 746, simple_code: "NI", icao_code: "PGA", name: "Portugalia", callsign: "PORTUGALIA", country: "Portugal"},
  {id: 747, simple_code: "PW", icao_code: "PRF", name: "Precision Air", callsign: "PRECISION AIR", country: "Tanzania"},
  {id: 748, simple_code: "TO", icao_code: "PSD", name: "President Airlines", callsign: "", country: "Cambodia"},
  {id: 749, simple_code: "GX", icao_code: "JXX", name: "Primera Air", callsign: "JETBIRD", country: "Iceland"},
  {id: 750, simple_code: "8W", icao_code: "PWF", name: "Private Wings Flugcharter", callsign: "PRIVATE WINGS", country: "Germany"},
  {id: 751, simple_code: "PB", icao_code: "SPR", name: "Provincial Airlines", callsign: "SPEEDAIR", country: "Canada"},
  {id: 752, simple_code: "QF", icao_code: "QFA", name: "Qantas", callsign: "QANTAS", country: "Australia"},
  {id: 753, simple_code: "QR", icao_code: "QTR", name: "Qatar Airways", callsign: "QATARI", country: "Qatar"},
  {id: 754, simple_code: "RT", icao_code: "RKM", name: "RAK Airways", callsign: "RAKAIR", country: "United Arab Emirates"},
  {id: 755, simple_code: "WZ", icao_code: "VAZ", name: "Red Wings Airlines", callsign: "REMONT AIR", country: "Russia"},
  {id: 756, simple_code: "8L", icao_code: "RHC", name: "Redhill Aviation", callsign: "REDAIR", country: "United Kingdom"},
  {id: 757, simple_code: "FN", icao_code: "RGL", name: "Regional Air Lines", callsign: "MAROC REGIONAL", country: "Morocco"},
  {id: 758, simple_code: "YS", icao_code: "RAE", name: "Regional Compagnie Aerienne Europeenne", callsign: "REGIONAL EUROPE", country: "France"},
  {id: 759, simple_code: "ZL", icao_code: "RXA", name: "Regional Express Airlines", callsign: "REX", country: "Australia"},
  {id: 760, simple_code: "RW", icao_code: "RPA", name: "Republic Airlines", callsign: "BRICKYARD", country: "United States", country_alternate: "United States"},
  {id: 761, simple_code: "RH", icao_code: "RPH", name: "Republic Express Airlines", callsign: "PUBLIC EXPRESS", country: "Indonesia"},
  {id: 762, simple_code: "PK", icao_code: "RIU", name: "Riau Airlines", callsign: "RIAU AIR", country: "Indonesia"},
  {id: 763, simple_code: "C7", icao_code: "RLE", name: "Rico Linhas Aereas", callsign: "RICO", country: "Brazil"},
  {id: 764, simple_code: "WQ", icao_code: "RMV", name: "Romavia", callsign: "AEROMAVIA", country: "Romania"},
  {id: 765, simple_code: "FV", icao_code: "SDM", name: "Rossiya - Russian Airlines", callsign: "RUSSIA", country: "Russia"},
  {id: 766, simple_code: "RR", icao_code: "RFR", name: "Royal Air Force", callsign: "RAFAIR", country: "United Kingdom"},
  {id: 767, simple_code: "RS", icao_code: "MJN", name: "Royal Air Force of Oman", callsign: "MAJAN", country: "Oman"},
  {id: 768, simple_code: "AT", icao_code: "RAM", name: "Royal Air Maroc", callsign: "ROYALAIR MAROC", country: "Morocco"},
  {id: 769, simple_code: "R0", icao_code: "RPK", name: "Royal Airlines", callsign: "ROYAL PAKISTAN", country: "Pakistan"},
  {id: 770, simple_code: "BI", icao_code: "RBA", name: "Royal Brunei Airlines", callsign: "BRUNEI", country: "Brunei Darussalam"},
  {id: 771, simple_code: "6D", icao_code: "KDR", name: "Royal Daisy Airlines", callsign: "DARLINES", country: "Uganda"},
  {id: 772, simple_code: "RJ", icao_code: "RJA", name: "Royal Jordanian", callsign: "JORDANIAN", country: "Jordan"},
  {id: 773, simple_code: "RK", icao_code: "RKH", name: "Royal Khmer Airlines", callsign: "KHMER AIR", country: "Cambodia"},
  {id: 774, simple_code: "RL", icao_code: "PPW", name: "Royal Phnom Penh Airways", callsign: "PHNOM-PENH AIR", country: "Cambodia"},
  {id: 775, simple_code: "P7", icao_code: "ESL", name: "Russian Sky Airlines", callsign: "RADUGA", country: "Russia"},
  {id: 776, simple_code: "WB", icao_code: "RWD", name: "Rwandair Express", callsign: "RWANDAIR", country: "Rwanda"},
  {id: 777, simple_code: "RD", icao_code: "RYN", name: "Ryan International Airlines", callsign: "RYAN INTERNATIONAL", country: "United States", country_alternate: "United States"},
  {id: 778, simple_code: "FR", icao_code: "RYR", name: "Ryanair", callsign: "RYANAIR", country: "Ireland"},
  {id: 779, simple_code: "S7", icao_code: "SBI", name: "S7 Airlines [Siberia Airlines]", callsign: "SIBERIAN AIRLINES", country: "Russia"},
  {id: 780, simple_code: "MM", icao_code: "SAM", name: "SAM Colombia", callsign: "SAM", country: "Colombia"},
  {id: 781, simple_code: "HZ", icao_code: "SHU", name: "SAT Airlines", callsign: "SATAIR", country: "Russia"},
  {id: 782, simple_code: "SP", icao_code: "SAT", name: "SATA Air Acores", callsign: "SATA", country: "Portugal"},
  {id: 783, simple_code: "S4", icao_code: "RZO", name: "SATA International", callsign: "AIR AZORES", country: "Portugal"},
  {id: 784, simple_code: "9N", icao_code: "NSE", name: "SATENA", callsign: "SATENA", country: "Colombia"},
  {id: 785, simple_code: "5E", icao_code: "SGN", name: "SGA Airlines", callsign: "SIAM", country: "Thailand"},
  {id: 786, simple_code: "SA", icao_code: "SAX", name: "Sabah Air", callsign: "SABAH AIR", country: "Malaysia"},
  {id: 787, simple_code: "FA", icao_code: "SFR", name: "Safair", callsign: "CARGO", country: "South Africa"},
  {id: 788, simple_code: "PV", icao_code: "SBU", name: "Saint Barth Commuter", callsign: "BLACK FIN", country: "France"},
  {id: 789, simple_code: "ZS", icao_code: "SMY", name: "Sama Airlines", callsign: "NAJIM", country: "Saudi Arabia"},
  {id: 790, simple_code: "S3", icao_code: "BBR", name: "Santa Barbara Airlines", callsign: "SANTA BARBARA", country: "Venezuela"},
  {id: 791, simple_code: "6W", icao_code: "SOV", name: "Saravia", callsign: "SARATOV AIR", country: "Russia"},
  {id: 792, simple_code: "HZ", icao_code: "SOZ", name: "Sat Airlines", callsign: "SATCO", country: "Kazakhstan"},
  {id: 793, simple_code: "SV", icao_code: "SVA", name: "Saudi Arabian Airlines", callsign: "SAUDIA", country: "Saudi Arabia"},
  {id: 794, simple_code: "W7", icao_code: "SAH", name: "Sayakhat Airlines", callsign: "SAYAKHAT", country: "Kazakhstan"},
  {id: 795, simple_code: "SK", icao_code: "SAS", name: "Scandinavian Airlines System [SAS]", callsign: "SCANDINAVIAN", country: "Sweden"},
  {id: 796, simple_code: "DV", icao_code: "VSV", name: "Scat Aircompany", callsign: "VLASTA", country: "Kazakhstan"},
  {id: 797, simple_code: "YR", icao_code: "EGJ", name: "Scenic Airlines", callsign: "SCENIC", country: "United States", country_alternate: "United States"},
  {id: 798, simple_code: "CB", icao_code: "SAY", name: "ScotAirways", callsign: "SUCKLING", country: "United Kingdom"},
  {id: 799, simple_code: "BB", icao_code: "SBS", name: "Seaborne Airlines", callsign: "SEABORNE", country: "US Virgin Islands"},
  {id: 800, simple_code: "SJ", icao_code: "CGL", name: "Seagle Air", callsign: "SEAGLE", country: "Slovakia"},
  {id: 801, simple_code: "5S", icao_code: "PSV", name: "Servicios Aereos Profesionales", callsign: "PROSERVICIOS", country: "Dominican Republic"},
  {id: 802, simple_code: "FS", icao_code: "STU", name: "Servicios de Transportes Aereos Fueguinos", callsign: "FUEGUINO", country: "Argentina"},
  {id: 803, simple_code: "NL", icao_code: "SAI", name: "Shaheen Air International", callsign: "SHAHEEN AIR", country: "Pakistan"},
  {id: 804, simple_code: "SC", icao_code: "CDG", name: "Shandong Airlines", callsign: "SHANDONG", country: "China"},
  {id: 805, simple_code: "FM", icao_code: "CSH", name: "Shanghai Airlines", callsign: "SHANGHAI AIR", country: "China"},
  {id: 806, simple_code: "F4", icao_code: "SHQ", name: "Shanghai Airlines Cargo", callsign: "SHANGHAI CARGO", country: "China"},
  {id: 807, simple_code: "8C", icao_code: "CXI", name: "Shanxi Airlines", callsign: "SHANXI", country: "China"},
  {id: 808, simple_code: "ZH", icao_code: "CSZ", name: "Shenzhen Airlines", callsign: "SHENZHEN AIR", country: "China"},
  {id: 809, simple_code: "J5", icao_code: "EPA", name: "Shenzhen Donghai Airlines", callsign: "DONGHAI AIR", country: "China"},
  {id: 810, simple_code: "S5", icao_code: "TCF", name: "Shuttle America", callsign: "MERCURY", country: "United States", country_alternate: "United States"},
  {id: 811, simple_code: "3U", icao_code: "CSC", name: "Sichuan Airlines", callsign: "SI CHUAN", country: "China"},
  {id: 812, simple_code: "LJ", icao_code: "SLA", name: "Sierra National Airlines", callsign: "SELAIR", country: "Sierra Leone"},
  {id: 813, simple_code: "SI", icao_code: "SPA", name: "Sierra Pacific Airlines", callsign: "SIERRA PACIFIC", country: "United States", country_alternate: "United States"},
  {id: 814, simple_code: "ZP", icao_code: "AZQ", name: "Silk Way Airlines", callsign: "SILK LINE", country: "Azerbaijan"},
  {id: 815, simple_code: "MI", icao_code: "SLK", name: "SilkAir", callsign: "SILKAIR", country: "Singapore"},
  {id: 816, simple_code: "SQ", icao_code: "SIA", name: "Singapore Airlines", callsign: "SINGAPORE", country: "Singapore"},
  {id: 817, simple_code: "SQ", icao_code: "SQC", name: "Singapore Airlines Cargo", callsign: "SINGCARGO", country: "Singapore"},
  {id: 818, simple_code: "H2", icao_code: "SKU", name: "Sky Airline", callsign: "AEROSKY", country: "Chile"},
  {id: 819, simple_code: "SY", icao_code: "SHY", name: "Sky Airlines", callsign: "ANTALYA BIRD", country: "Turkey"},
  {id: 820, simple_code: "G3", icao_code: "SEH", name: "Sky Express", callsign: "AIR CRETE", country: "Greece"},
  {id: 821, simple_code: "XW", icao_code: "SXR", name: "Sky Express", callsign: "SKYSTORM", country: "Russia"},
  {id: 822, simple_code: "I6", icao_code: "SEQ", name: "Sky Eyes", callsign: "SKY EYES", country: "Thailand"},
  {id: 823, simple_code: "NE", icao_code: "ESK", name: "SkyEurope", callsign: "RELAX", country: "Slovakia"},
  {id: 824, simple_code: "OO", icao_code: "SKW", name: "SkyWest Airlines", callsign: "SKYWEST", country: "United States", country_alternate: "United States"},
  {id: 825, simple_code: "BC", icao_code: "SKY", name: "Skymark Airlines", callsign: "SKYMARK", country: "Japan"},
  {id: 826, simple_code: "6J", icao_code: "SNJ", name: "Skynet Asia Airways", callsign: "NEWSKY", country: "Japan"},
  {id: 827, simple_code: "5G", icao_code: "SSV", name: "Skyservice Airlines", callsign: "SKYTOUR", country: "Canada"},
  {id: 828, simple_code: "NP", icao_code: "SKP", name: "Skytrans Airlines", callsign: "SKYTRANS", country: "Australia"},
  {id: 829, simple_code: "KI", icao_code: "SKZ", name: "Skyway Enterprises", callsign: "SKYWAY-INC", country: "United States", country_alternate: "United States"},
  {id: 830, simple_code: "JZ", icao_code: "SKX", name: "Skyways Express", callsign: "SKY EXPRESS", country: "Sweden"},
  {id: 831, simple_code: "XR", icao_code: "OZW", name: "Skywest Airlines", callsign: "OZWEST", country: "Australia"},
  {id: 832, simple_code: "QS", icao_code: "TVS", name: "Smart Wings", callsign: "SKYTRAVEL", country: "Czech Republic"},
  {id: 833, simple_code: "6Y", icao_code: "LTC", name: "SmartLynx Airlines", callsign: "LATCHARTER", country: "Latvia"},
  {id: 834, simple_code: "DA", icao_code: "SDA", name: "Sol Dominicana Airlines", callsign: "SOL AIRLINES", country: "Dominican Republic"},
  {id: 835, simple_code: "IE", icao_code: "SOL", name: "Solomon Airlines", callsign: "SOLOMON", country: "Solomon Islands"},
  {id: 836, simple_code: "SA", icao_code: "SAA", name: "South African Airways", callsign: "SPRINGBOK", country: "South Africa"},
  {id: 837, simple_code: "YB", icao_code: "EXY", name: "South African Express", callsign: "EXPRESSWAYS", country: "South Africa"},
  {id: 838, simple_code: "YG", icao_code: "OTL", name: "South Airlines", callsign: "SOUTHLINE", country: "Ukraine"},
  {id: 839, simple_code: "DG", icao_code: "SRQ", name: "South East Asian Airlines", callsign: "SEAIR", country: "Philippines"},
  {id: 840, simple_code: "9S", icao_code: "SOO", name: "Southern Air", callsign: "SOUTHERN AIR", country: "United States", country_alternate: "United States"},
  {id: 841, simple_code: "PL", icao_code: "SOA", name: "Southern Air Charter", callsign: "SOUTH AIRCHARTER", country: "Bahamas"},
  {id: 842, simple_code: "WN", icao_code: "SWA", name: "Southwest Airlines", callsign: "SOUTHWEST", country: "United States", country_alternate: "United States"},
  {id: 843, simple_code: "JK", icao_code: "JKK", name: "Spanair", callsign: "SPANAIR", country: "Spain"},
  {id: 844, simple_code: "SG", icao_code: "SEJ", name: "Spicejet", callsign: "SPICEJET", country: "India"},
  {id: 845, simple_code: "NK", icao_code: "NKS", name: "Spirit Airlines", callsign: "SPIRIT WINGS", country: "United States", country_alternate: "United States"},
  {id: 846, simple_code: "9S", icao_code: "CQH", name: "Spring Airlines", callsign: "AIR SPRING", country: "China"},
  {id: 847, simple_code: "UL", icao_code: "ALK", name: "SriLankan Airlines", callsign: "SRILANKAN", country: "Sri Lanka"},
  {id: 848, simple_code: "SJ", icao_code: "SJY", name: "Sriwijaya Air", callsign: "SRIWIJAYA", country: "Indonesia"},
  {id: 849, simple_code: "S6", icao_code: "SRR", name: "Star Air", callsign: "WHITESTAR", country: "Denmark"},
  {id: 850, simple_code: "6S", icao_code: "URJ", name: "Star Air Aviation", callsign: "STARAV", country: "Pakistan"},
  {id: 851, simple_code: "7G", icao_code: "SFJ", name: "Star Flyer", callsign: "STARFLYER", country: "Japan"},
  {id: 852, simple_code: "2I", icao_code: "SRU", name: "Star Perú", callsign: "STAR UP", country: "Peru"},
  {id: 853, simple_code: "DZ", icao_code: "LMZ", name: "Starline.kz", callsign: "ALUNK", country: "Kazakhstan"},
  {id: 854, simple_code: "SD", icao_code: "SUD", name: "Sudan Airways", callsign: "SUDANAIR", country: "Sudan"},
  {id: 855, simple_code: "EZ", icao_code: "SUS", name: "Sun Air of Scandinavia", callsign: "SUNSCAN", country: "Denmark"},
  {id: 856, simple_code: "SY", icao_code: "SCX", name: "Sun Country Airlines", callsign: "SUN COUNTRY", country: "United States", country_alternate: "United States"},
  {id: 857, simple_code: "7L", icao_code: "ERO", name: "Sun d'Or International Airlines", callsign: "ECHO ROMEO", country: "Israel"},
  {id: 858, simple_code: "XQ", icao_code: "SXS", name: "SunExpress", callsign: "SUNEXPRESS", country: "Turkey"},
  {id: 859, simple_code: "CQ", icao_code: "EXL", name: "Sunshine Express Airlines", callsign: "SEA", country: "Australia"},
  {id: 860, simple_code: "QF", icao_code: "SSQ", name: "Sunstate Airlines", callsign: "SUNSTATE", country: "Australia"},
  {id: 861, simple_code: "WG", icao_code: "SWG", name: "Sunwing Airlines", callsign: "SUNWING", country: "Canada"},
  {id: 862, simple_code: "SO", icao_code: "HKA", name: "Superior Aviation", callsign: "SPEND AIR", country: "United States", country_alternate: "United States"},
  {id: 863, simple_code: "PY", icao_code: "SLM", name: "Surinam Airways", callsign: "SURINAM", country: "Suriname"},
  {id: 864, simple_code: "SA", icao_code: "SZL", name: "Swaziland Airlink", callsign: "SWAZILINK", country: "Swaziland"},
  {id: 865, simple_code: "Q7", icao_code: "SWQ", name: "Swift Air", callsign: "SWIFTFLIGHT", country: "United States", country_alternate: "United States"},
  {id: 866, simple_code: "W3", icao_code: "SWT", name: "Swiftair", callsign: "SWIFT", country: "Spain"},
  {id: 867, simple_code: "LX", icao_code: "SWU", name: "Swiss European Air Lines", callsign: "EUROSWISS", country: "Switzerland"},
  {id: 868, simple_code: "LX", icao_code: "SWR", name: "Swiss International Air Lines", callsign: "SWISS", country: "Switzerland"},
  {id: 869, simple_code: "7E", icao_code: "AWU", name: "Sylt Air", callsign: "SYLT-AIR", country: "Germany"},
  {id: 870, simple_code: "RB", icao_code: "SYR", name: "Syrian Arab Airlines [Syrianair]", callsign: "SYRIANAIR", country: "Syria"},
  {id: 871, simple_code: "DT", icao_code: "DTA", name: "TAAG Angola Airlines [Linhas Aéreas de Angola]", callsign: "DTA", country: "Angola"},
  {id: 872, simple_code: "T0", icao_code: "TPU", name: "TACA Peru", callsign: "TRANS PERU", country: "Peru"},
  {id: 873, simple_code: "VR", icao_code: "TCV", name: "TACV", callsign: "CABOVERDE", country: "Cape Verde"},
  {id: 874, simple_code: "VR", icao_code: "TCV", name: "TACV Cabo Verde Airlines", callsign: "CABOVERDE", country: "Cape Verde"},
  {id: 875, simple_code: "R9", icao_code: "TSD", name: "TAF Linhas Aéreas", callsign: "TAFI", country: "Brazil"},
  {id: 876, simple_code: "PZ", icao_code: "LAP", name: "TAM Airlines", callsign: "PARAGUAYA", country: "Paraguay"},
  {id: 877, simple_code: "JJ", icao_code: "TAM", name: "TAM Airlines [TAM Linhas Aereas]", callsign: "TAM", country: "Brazil"},
  {id: 878, simple_code: "EQ", icao_code: "TAE", name: "TAME", callsign: "TAME", country: "Ecuador"},
  {id: 879, simple_code: "TP", icao_code: "TAP", name: "TAP Portugal", callsign: "AIR PORTUGAL", country: "Portugal"},
  {id: 880, simple_code: "3V", icao_code: "TAY", name: "TNT Airways", callsign: "QUALITY", country: "Belgium"},
  {id: 881, simple_code: "8R", icao_code: "TIB", name: "TRIP Linhas Aereas", callsign: "TRIP", country: "Brazil"},
  {id: 882, simple_code: "X3", icao_code: "TUI", name: "TUIfly", callsign: "TUIJET", country: "Germany"},
  {id: 883, simple_code: "6B", icao_code: "BLX", name: "TUIfly Nordic", callsign: "BLUESCAN", country: "Sweden"},
  {id: 884, simple_code: "7J", icao_code: "TZK", name: "Tajik Air", callsign: "TAJIKISTAN", country: "Tajikistan"},
  {id: 885, simple_code: "QT", icao_code: "TPA", name: "Tampa Cargo", callsign: "TAMPA", country: "Colombia"},
  {id: 886, simple_code: "TQ", icao_code: "TDM", name: "Tandem Aero", callsign: "TANDEM", country: "Moldova"},
  {id: 887, simple_code: "RO", icao_code: "ROT", name: "Tarom", callsign: "TAROM", country: "Romania"},
  {id: 888, simple_code: "SF", icao_code: "DTH", name: "Tassili Airlines", callsign: "TASSILI AIR", country: "Algeria"},
  {id: 889, simple_code: "U9", icao_code: "TAK", name: "Tatarstan Airlines", callsign: "TATARSTAN", country: "Russia"},
  {id: 890, simple_code: "T6", icao_code: "TVR", name: "Tavrey Airlines", callsign: "TAVREY", country: "Ukraine"},
  {id: 891, simple_code: "L6", icao_code: "VNZ", name: "Tbilaviamsheni", callsign: "TBILAVIA", country: "Georgia"},
  {id: 892, simple_code: "FD", icao_code: "AIQ", name: "Thai AirAsia", callsign: "THAI ASIA", country: "Thailand"},
  {id: 893, simple_code: "TG", icao_code: "THA", name: "Thai Airways International", callsign: "THAI", country: "Thailand"},
  {id: 894, simple_code: "MT", icao_code: "TCX", name: "Thomas Cook Airlines", callsign: "KESTREL", country: "United Kingdom"},
  {id: 895, simple_code: "FQ", icao_code: "TCW", name: "Thomas Cook Airlines", callsign: "THOMAS COOK", country: "Belgium"},
  {id: 896, simple_code: "DK", icao_code: "VKG", name: "Thomas Cook Airlines Scandinavia", callsign: "VIKING", country: "Denmark"},
  {id: 897, simple_code: "BY", icao_code: "TOM", name: "Thomson Airways", callsign: "TOMSON", country: "United Kingdom"},
  {id: 898, simple_code: "3P", icao_code: "TNM", name: "Tiara Air", callsign: "TIARA", country: "Aruba [Netherlands]"},
  {id: 899, simple_code: "TR", icao_code: "TGW", name: "Tiger Airways", callsign: "GO CAT", country: "Singapore"},
  {id: 900, simple_code: "TT", icao_code: "TGW", name: "Tiger Airways Australia", callsign: "GO CAT", country: "Australia"},
  {id: 901, simple_code: "ZT", icao_code: "AWC", name: "Titan Airways", callsign: "ZAP", country: "United Kingdom"},
  {id: 902, simple_code: "7T", icao_code: "TOB", name: "Tobruk Air", callsign: "TOBRUK AIR", country: "Libya"},
  {id: 903, simple_code: "9D", icao_code: "THE", name: "Toumaï Air Tchad", callsign: "TOUMAI AIR", country: "Chad"},
  {id: 904, simple_code: "WI", icao_code: "TDX", name: "Tradewinds Airlines", callsign: "TRADEWINDS EXPRESS", country: "United States", country_alternate: "United States"},
  {id: 905, simple_code: "N4", icao_code: "TNB", name: "Trans Air Benin", callsign: "TRANS-BENIN", country: "Benin"},
  {id: 906, simple_code: "Q8", icao_code: "TSG", name: "Trans Air Congo", callsign: "TRANS-CONGO", country: "Congo, Republic of"},
  {id: 907, simple_code: "M8", icao_code: "TMW", name: "Trans Maldivian Airways", callsign: "TRANS MALDIVIAN", country: "Maldives"},
  {id: 908, simple_code: "TL", icao_code: "TMA", name: "Trans Mediterranean Airlines", callsign: "TANGO LIMA", country: "Lebanon"},
  {id: 909, simple_code: "AX", icao_code: "LOF", name: "Trans States Airlines", callsign: "WATERSKI", country: "United States", country_alternate: "United States"},
  {id: 910, simple_code: "AL", icao_code: "TXC", name: "TransAVIAexport Airlines", callsign: "TRANSEXPORT", country: "Belarus"},
  {id: 911, simple_code: "GE", icao_code: "TNA", name: "TransAsia Airways", callsign: "TRANSASIA", country: "Taiwan"},
  {id: 912, simple_code: "UN", icao_code: "TSO", name: "Transaero Airlines", callsign: "TRANSOVIET", country: "Russia"},
  {id: 913, simple_code: "T9", icao_code: "TIW", name: "Transcarga", callsign: "TIACA", country: "Venezuela"},
  {id: 914, simple_code: "TH", icao_code: "TSE", name: "Transmile Air Services", callsign: "TRANSMILE", country: "Malaysia"},
  {id: 915, simple_code: "5T", icao_code: "TWE", name: "Transwede Airways", callsign: "TRANSWEDE", country: "Sweden"},
  {id: 916, simple_code: "9T", icao_code: "ABS", name: "Transwest Air", callsign: "ATHABASKA", country: "Canada"},
  {id: 917, simple_code: "GY", icao_code: "TMG", name: "Tri-MG Intra Asia Airlines", callsign: "TRILINES", country: "Indonesia"},
  {id: 918, simple_code: "TN", icao_code: "TGN", name: "Trigana Air Service", callsign: "TRIGANA", country: "Indonesia"},
  {id: 919, simple_code: "3A", icao_code: "CLU", name: "Triple Alpha Luftfahrtgesellschaft", callsign: "CAROLUS", country: "Germany"},
  {id: 920, simple_code: "PM", icao_code: "TOS", name: "Tropic Air", callsign: "TROPISER", country: "Belize"},
  {id: 921, simple_code: "2T", icao_code: "TUX", name: "Tulpar Air Service", callsign: "TULPA", country: "Kazakhstan"},
  {id: 922, simple_code: "UG", icao_code: "TUX", name: "TunisAir Express", callsign: "TUNEXPRESS", country: "Tunesia"},
  {id: 923, simple_code: "TU", icao_code: "TAR", name: "Tunisair", callsign: "TUNAIR", country: "Tunesia"},
  {id: 924, simple_code: "3T", icao_code: "URN", name: "Turan Air", callsign: "TURAN", country: "Azerbaijan"},
  {id: 925, simple_code: "TK", icao_code: "THY", name: "Turkish Airlines", callsign: "TURKAIR", country: "Turkey"},
  {id: 926, simple_code: "T5", icao_code: "TUA", name: "Turkmenistan Airlines", callsign: "TURKMENISTAN", country: "Turkmenistan"},
  {id: 927, simple_code: "T7", icao_code: "TJT", name: "Twin Jet", callsign: "TWINJET", country: "France"},
  {id: 928, simple_code: "UF", icao_code: "UKM", name: "UM Airlines", callsign: "UKRAINE MEDITERRANEE", country: "Ukraine"},
  {id: 929, simple_code: "US", icao_code: "AWE", name: "US Airways", callsign: "CACTUS", country: "United States", country_alternate: "United States"},
  {id: 930, simple_code: "UH", icao_code: "USH", name: "US Helicopter", callsign: "US HELI", country: "United States", country_alternate: "United States"},
  {id: 931, simple_code: "U5", icao_code: "GWY", name: "United States 3000 Airlines", callsign: "GETAWAY", country: "United States", country_alternate: "United States"},
  {id: 932, simple_code: "UJ", icao_code: "JUS", name: "United States Jet Airlines", callsign: "JET United States", country: "United States", country_alternate: "United States"},
  {id: 933, simple_code: "UT", icao_code: "UTA", name: "UTair Aviation", callsign: "UTAIR", country: "Russia"},
  {id: 934, simple_code: "PS", icao_code: "AUI", name: "Ukraine International Airlines", callsign: "UKRAINE INTERNATIONAL", country: "Ukraine"},
  {id: 935, simple_code: "6Z", icao_code: "UKS", name: "Ukrainian Cargo Airways", callsign: "CARGOTRANS", country: "Ukraine"},
  {id: 936, simple_code: "B7", icao_code: "UIA", name: "Uni Air", callsign: "GLORY", country: "Taiwan"},
  {id: 937, simple_code: "UA", icao_code: "UAL", name: "United Airlines", callsign: "UNITED", country: "United States", country_alternate: "United States"},
  {id: 938, simple_code: "4H", icao_code: "UBD", name: "United Airways [Bangladesh]", callsign: "UNITED BANGLADESH", country: "Bangladesh"},
  {id: 939, simple_code: "EU", icao_code: "UEA", name: "United Eagle Airlines", callsign: "UNITED EAGLE", country: "China"},
  {id: 940, simple_code: "5X", icao_code: "UPS", name: "United Parcel Service", callsign: "UPS", country: "United States", country_alternate: "United States"},
  {id: 941, simple_code: "U6", icao_code: "SVR", name: "Ural Airlines", callsign: "SVERDLOVSK AIR", country: "Russia"},
  {id: 942, simple_code: "HY", icao_code: "UZB", name: "Uzbekistan Airways", callsign: "UZBEK", country: "Uzbekistan"},
  {id: 943, simple_code: "NN", icao_code: "MOV", name: "VIM Airlines", callsign: "MOV AIR", country: "Russia"},
  {id: 944, simple_code: "V6", icao_code: "VUR", name: "VIP Ecuador", callsign: "VIP", country: "Ecuador"},
  {id: 945, simple_code: "VG", icao_code: "VLM", name: "VLM Airlines", callsign: "RUBENS", country: "Belgium"},
  {id: 946, simple_code: "RG", icao_code: "VRN", name: "VRG Linhas Aereas [VARIG]", callsign: "VARIG", country: "Brazil"},
  {id: 947, simple_code: "LC", icao_code: "VLO", name: "Varig Logistica [VarigLog]", callsign: "VELOG", country: "Brazil"},
  {id: 948, simple_code: "RV", icao_code: "VEC", name: "Venezolana", callsign: "VECAR", country: "Venezuela"},
  {id: 949, simple_code: "VM", icao_code: "VOA", name: "Viaggio Air", callsign: "VIAGGIO", country: "Bulgaria"},
  {id: 950, simple_code: "VI", icao_code: "VES", name: "Vieques Air Link", callsign: "VIQUES", country: "Puerto Rico"},
  {id: 951, simple_code: "VN", icao_code: "HVN", name: "Vietnam Airlines", callsign: "VIET NAM AIRLINES", country: "Vietnam"},
  {id: 952, simple_code: "4P", icao_code: "VIK", name: "Viking Airlines", callsign: "SWEDJET", country: "Sweden"},
  {id: 953, simple_code: "VQ", icao_code: "VPP", name: "Vintage Props and Jets", callsign: "VINTAGE", country: "United States", country_alternate: "United States"},
  {id: 954, simple_code: "VX", icao_code: "VRD", name: "Virgin America", callsign: "REDWOOD", country: "United States", country_alternate: "United States"},
  {id: 955, simple_code: "VS", icao_code: "VIR", name: "Virgin Atlantic Airways", callsign: "VIRGIN", country: "United Kingdom"},
  {id: 956, simple_code: "DJ", icao_code: "VOZ", name: "Virgin Blue", callsign: "VIRGIN", country: "Australia"},
  {id: 957, simple_code: "VK", icao_code: "VGN", name: "Virgin Nigeria Airways", callsign: "VIRGIN NIGERIA", country: "Nigeria"},
  {id: 958, simple_code: "ZG", icao_code: "VVM", name: "Viva Macau", callsign: "JACKPOT", country: "China"},
  {id: 959, simple_code: "VB", icao_code: "VIV", name: "VivaAerobus", callsign: "AEROENLACES", country: "Mexico"},
  {id: 960, simple_code: "XF", icao_code: "VLK", name: "Vladivostok Air", callsign: "VLADAIR", country: "Russia"},
  {id: 961, simple_code: "VA", icao_code: "VLE", name: "Volare Airlines", callsign: "VOLA", country: "Italy"},
  {id: 962, simple_code: "VI", icao_code: "VDA", name: "Volga-Dnepr Airlines", callsign: "VOLGA-DNEPR", country: "Russia"},
  {id: 963, simple_code: "VC", icao_code: "VAL", name: "Voyageur Airways", callsign: "VOYAGEUR", country: "Canada"},
  {id: 964, simple_code: "VY", icao_code: "VLG", name: "Vueling Airlines", callsign: "VUELING", country: "Spain"},
  {id: 965, simple_code: "W1", icao_code: "WDL", name: "WDL Aviation", callsign: "WDL", country: "Germany"},
  {id: 966, simple_code: "XW", icao_code: "GOT", name: "WaltAir", callsign: "GOTHIC", country: "Sweden"},
  {id: 967, simple_code: "3W", icao_code: "VNR", name: "Wan Air", callsign: "WAN AIR", country: "French Polynesia"},
  {id: 968, simple_code: "WT", icao_code: "WSG", name: "Wasaya Airways", callsign: "WASAYA", country: "Canada"},
  {id: 969, simple_code: "WJ", icao_code: "WEB", name: "WebJet Linhas Aéreas", callsign: "WEBJET", country: "Brazil"},
  {id: 970, simple_code: "2W", icao_code: "WLC", name: "Welcome Air", callsign: "WELCOMEAIR", country: "Austria"},
  {id: 971, simple_code: "PT", icao_code: "SWN", name: "West Air Sweden", callsign: "AIR SWEDEN", country: "Sweden"},
  {id: 972, simple_code: "WS", icao_code: "WJA", name: "WestJet", callsign: "WESTJET", country: "Canada"},
  {id: 973, simple_code: "WF", icao_code: "WIF", name: "Wideroe", callsign: "WIDEROE", country: "Norway"},
  {id: 974, simple_code: "WG", icao_code: "WIG", name: "Wiggins Airways", callsign: "WIGGINS AIRWAYS", country: "United States", country_alternate: "United States"},
  {id: 975, simple_code: "9C", icao_code: "WDA", name: "Wimbi Dira Airways", callsign: "WIMBI DIRA", country: "Congo, Democratic Republic of the"},
  {id: 976, simple_code: "IV", icao_code: "JET", name: "Wind Jet", callsign: "GHIBLI", country: "Italy"},
  {id: 977, simple_code: "7W", icao_code: "WRC", name: "Wind Rose Aviation", callsign: "WIND ROSE", country: "Ukraine"},
  {id: 978, simple_code: "WM", icao_code: "WIA", name: "Windward Islands Airways", callsign: "WINAIR", country: "Netherlands Antilles"},
  {id: 979, simple_code: "1W", icao_code: "WON", name: "Wings Air", callsign: "WINGS ABADI", country: "Indonesia"},
  {id: 980, simple_code: "K5", icao_code: "WAK", name: "Wings of Alaska", callsign: "WINGS ALASKA", country: "United States", country_alternate: "United States"},
  {id: 981, simple_code: "W6", icao_code: "WZZ", name: "Wizz Air", callsign: "WIZZ AIR", country: "Hungary"},
  {id: 982, simple_code: "W6", icao_code: "WVL", name: "Wizz Air", callsign: "WIZZBUL", country: "Bulgaria"},
  {id: 983, simple_code: "WO", icao_code: "WOA", name: "World Airways", callsign: "WORLD", country: "United States", country_alternate: "United States"},
  {id: 984, simple_code: "8V", icao_code: "WRF", name: "Wright Air Service", callsign: "WRIGHT FLYER", country: "United States", country_alternate: "United States"},
  {id: 985, simple_code: "SE", icao_code: "SEU", name: "XL Airways France", callsign: "STARWAY", country: "France"},
  {id: 986, simple_code: "9E", icao_code: "GXL", name: "XL Airways Germany", callsign: "STAR DUST", country: "Germany"},
  {id: 987, simple_code: "MF", icao_code: "CXA", name: "Xiamen Airlines", callsign: "XIAMEN AIR", country: "China"},
  {id: 988, simple_code: "XP", icao_code: "CXP", name: "Xtra Airways", callsign: "RUBY MOUNTAIN", country: "United States", country_alternate: "United States"},
  {id: 989, simple_code: "R3", icao_code: "SYL", name: "Yakutia Airlines", callsign: "AIR YAKUTIA", country: "Russia"},
  {id: 990, simple_code: "YL", icao_code: "LLM", name: "Yamal Airlines", callsign: "YAMAL", country: "Russia"},
  {id: 991, simple_code: "Y8", icao_code: "YZR", name: "Yangtze River Express", callsign: "YANGTZE RIVER", country: "China"},
  {id: 992, simple_code: "IY", icao_code: "IYE", name: "Yemenia - Yemen Airways", callsign: "YEMENI", country: "Yemen"},
  {id: 993, simple_code: "OE", icao_code: "ONA", name: "Yeongnam Air", callsign: "YEONGNAM AIR", country: "South Korea"},
  {id: 994, simple_code: "2N", icao_code: "UMK", name: "Yuzmashavia", callsign: "YUZMASH", country: "Ukraine"},
  {id: 995, simple_code: "K8", icao_code: "ZAK", name: "Zambia Skyways", callsign: "ZAMBIA SKIES", country: "Zambia"},
  {id: 996, simple_code: "Q3", icao_code: "MBN", name: "Zambian Airways", callsign: "ZAMBIANA", country: "Zambia"},
  {id: 997, simple_code: "6K", icao_code: "RIT", name: "Zest Airways", callsign: "ASIAN SPIRIT", country: "Philippines"},
  {id: 998, simple_code: "C4", icao_code: "IMX", name: "Zimex Aviation", callsign: "ZIMEX", country: "Switzerland"},
  {id: 1089, simple_code: "CO", icao_code: "FCB", name: "Cobalt Air", callsign: "APOLLO", country: "Cyprus"},
  {id: 1090, simple_code: "0B", icao_code: "BMS", name: "Blue Air", callsign: "BLUE MESSENGER", country: "France"},
  {id: 1091, simple_code: "HV", icao_code: "TRA", name: "Transavia", callsign: "TRANSAVIA", country: "Netherlands"},
  {id: 1092, simple_code: "JU", icao_code: "ASL", name: "Air Serbia", callsign: "AIR SERBIA", country: "Serbia"},
  {id: 1093, simple_code: "U2", icao_code: "EZY", name: "easyJet", callsign: "EASY", country: "United Kingdom"},
  {id: 1094, simple_code: "GF", icao_code: "GFA", name: "Gulf Air", callsign: "GULF AIR", country: "Bahrain"},
  {id: 1095, simple_code: "D8", icao_code: "IBK", name: "Norwegian Air International", callsign: "NORTRANS", country: "Norway"},
  {id: 1096, simple_code: "U2", icao_code: "EZY", name: "easyJet", callsign: "EASY", country: "United Kingdom"},
  {id: 1097, simple_code: "GH", icao_code: "GLP", name: "Globus Airlines", callsign: "GLOBUS", country: "Russia"}

]

export { airlines }